import React from "react"

import Status from "./Status"
import Pastdue from "./Pastdue"
import PaidOff from "./PaidOff"
import { hasPastduePayment, isPaidOff } from "../../../utils"

const OrderStatus = ({ order }) => {
  const pastdue = hasPastduePayment(order.paymentScheduleList)
  const paidOff = isPaidOff(order.paymentScheduleList)

  if (pastdue) {
    return <Pastdue />
  }

  if (paidOff) {
    return <PaidOff />
  }

  return <Status data={order} showDetails={true} />
}

export default React.memo(OrderStatus)
