import styled, { css } from "styled-components"

import { Box, FormLoadingButton, Icon } from "src/components"

export const ModalContainer = styled.div`
  width: 550px;
  max-height: 90vh;
  overflow-y: scroll;
  padding: 30px 40px;
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledButton = styled(FormLoadingButton)`
  width: 100%;
  height: 48px;
`

export const SuccessSignContainer = styled(Box)`
  width: 48px;
  height: 48px;
  margin: auto;
`

export const SuccessContainer = styled(Box)`
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;
`

export const OptionsContainer = styled.div<any>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  border-radius: 4px;
  z-index: 10;
  max-height: 320px;
  box-shadow: 0 0 24px 0 rgba(217, 222, 226, 0.76);
  background-color: ${({ theme }) => theme.colors.white};
  ${({ shouldDisplay }) => {
    if (shouldDisplay) {
      return "opacity: 1; visibility: visible;"
    }
    return "opacity: 0; visibility: hidden; transition: all .3s ease-in-out;"
  }}
`

export const InputWrapper = styled.div`
  position: relative;
`

const activeStyles = css`
  background-color: ${({ active, theme }: any) => {
    if (active) {
      return theme.colors.catskillWhite
    }
    return theme.colors.white
  }};
`

const hoverStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.catskillWhite};
`

export const Option = styled.div<any>`
  width: 100%;
  height: 56px;
  padding: 18px 25px;
  overflow: hidden;
  ${activeStyles}
  &:hover {
    ${hoverStyles}
  }
`

export const SelectedProfileContainer = styled.div`
  position: relative;
`

export const StyledArrowIcon = styled(Icon.arrowDown)`
  position: absolute;
  top: 23px;
  right: 27px;
  cursor: pointer;
`

export const CollateralContainer = styled(Box)`
  width: 250px;
  margin-right: 15px;
  padding: 11px 25px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
`

export const InputContainer = styled(Box)`
  > div {
    margin-bottom: 0;
  }
`
