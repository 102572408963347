import React, { useState, useContext } from "react"
import propTypes from "prop-types"
import onClickOutside from "react-onclickoutside"

import { CurrentProfileContext } from "src/context"
import { divideNumber } from "src/utils"

import { getProfilesType } from "../modals/CreateNewProfile"

import {
  ProfileListContainer,
  ProfileListWrap,
  ProfileName,
  ProfileNameLink,
  ProfileWallet,
  ProfileHandle,
  ProfileListItem,
  ProfileListHandleWrap,
  ProfileListInner,
  AddButton,
} from "./styles"
import { getProfileInfo } from "./utils"

function CurrentProfile(props) {
  const { data, isActive, handle } = props
  const profileInfo = getProfileInfo(data)

  const balance = typeof profileInfo.balanceRUB === "number" ? profileInfo.balanceRUB : 0

  function handleClick() {
    handle(!isActive)
  }

  return (
    <ProfileListItem>
      <ProfileName>
        <ProfileNameLink to={`/accounts/${profileInfo.id}`}>
          {profileInfo.name}
        </ProfileNameLink>
      </ProfileName>
      <ProfileListInner onClick={handleClick}>
        <ProfileWallet>
          ₽ {divideNumber(balance.toFixed(2)) || 0}
        </ProfileWallet>
        <ProfileHandle>
          <img
            style={{ transform: !isActive ? "rotate(180deg)" : "none" }}
            src="/images/icons/arrow.svg"
          />
        </ProfileHandle>
      </ProfileListInner>
    </ProfileListItem>
  )
}

function OtherProfile(props) {
  const { data, handle, setAccountLink } = props
  const currentProfile = useContext(CurrentProfileContext) as any

  const profileInfo = getProfileInfo(data)

  function handleClick() {
    currentProfile.update(data)
    window.localStorage.setItem("activeProfile", profileInfo.id)
    setAccountLink(profileInfo.id)
    handle(false)
  }

  return (
    <ProfileListItem onClick={handleClick}>
      <ProfileName>{profileInfo.name}</ProfileName>
      <ProfileListInner>
        <ProfileWallet>
          ₽ {divideNumber(profileInfo.balanceRUB.toFixed(2))}
        </ProfileWallet>
      </ProfileListInner>
    </ProfileListItem>
  )
}

const ProfileList = (props) => {
  const { openModal } = props
  const { profiles } = props.viewer
  const [profileActive, handleProfileActive] = useState(false)

  const { hasIndividual, hasEntrepreneur, hasForeign } = getProfilesType(profiles)

  const activeProfileId = window.localStorage.getItem("activeProfile") || profiles[0].id

  const otherProfiles = []
  const activeProfile = profiles.filter((profile) => {
    if (profile.id !== activeProfileId) {
      otherProfiles.push(profile)
    }

    return profile.id === activeProfileId
  })

  function CreateNewProfile() {
    const callback = () => handleProfileActive(false)
    openModal(callback)
  }

  (ProfileList as any).handleClickOutside = () => handleProfileActive(false)
  if (!activeProfile || !activeProfile.length) {
    // eslint-disable-next-line no-console
    console.warn("localStorage has wrong activeProfile")
  }
  // if user has not active profile
  // case: in localStorage wrong activeProfile
  const currentProfile = activeProfile && activeProfile.length ? activeProfile[0] : undefined
  return (
    <ProfileListContainer>
      <ProfileListWrap active={profileActive}>
        {!!currentProfile && (
          <CurrentProfile
            data={currentProfile}
            handle={handleProfileActive}
            isActive={profileActive}
          />
        )}

        {profileActive && (
          <ProfileListHandleWrap>
            {otherProfiles.map((profile) => (
              <OtherProfile
                key={profile.id}
                data={profile}
                handle={handleProfileActive}
                setAccountLink={props.setAccountLink}
              />
            ))}

            {(!hasForeign && !(hasEntrepreneur && hasIndividual)) && (
              <AddButton onClick={CreateNewProfile}>добавить профиль</AddButton>
            )}
          </ProfileListHandleWrap>
        )}
      </ProfileListWrap>
    </ProfileListContainer>
  )
}
ProfileList.propTypes = {
  viewer: propTypes.object.isRequired,
}

const clickOutsideConfig = {
  handleClickOutside: () => (ProfileList as any).handleClickOutside,
}

export default onClickOutside(ProfileList, clickOutsideConfig)
