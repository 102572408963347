import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateEntrepreneurProfileAsInvestorMutation(
    $input: UpdateEntrepreneurProfileAsInvestorInput!
  ) {
    updateEntrepreneurProfileAsInvestor(input: $input) {
      profile {
        id
      }
    }
  }
`

export default enhance({ mutation })
