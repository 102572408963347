import React from "react"

import memoize from "memoize-one"

import { Translate } from "src/components"

import {
  Bold, PreLined, Container, TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

import ComposedConsumer from "./composedConsumer"

const MinAmountNotCollected = memoize((props) => {
  const { minValue } = props

  return (
    <TextBlockWrapper>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.head" />
        <Bold>{minValue} ₽</Bold>
      </PreLined>
    </TextBlockWrapper>
  )
})

const render = memoize(({ order, relation }) => {
  const status = mapAmountToStatusFrom(order)

  return (
    <Container>
      <MinAmountNotCollected {...status} viewerRole={relation} />
    </Container>
  )
})

export default () => <ComposedConsumer>{render}</ComposedConsumer>
