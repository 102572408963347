import styled from "styled-components"
import { Box, Button, Text } from "src/components"

export const SubHeader = styled(Text)`
  font-weight: 500;
  line-height: 1.4;
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledButton = styled(Button)`
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyShuttle};
  line-height: 1.5;
`

export const WalletBalanceContainer = styled(Box)`
  height: 64px;
  padding: 20px;
  flex: 1;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 4px;
`

export const SummaryContainer = styled(Box)`
  text-align: right;
`

export const ExchangeArrowsContainer = styled(Box)`
  height: 22px;
  margin: 0 15px 22px 15px;
  cursor: pointer;
  user-select: none;
`
