/* eslint-disable max-len */

import React from "react"

export const WalletIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8802 8.88225H18.4044V6.02335C18.4044 4.85436 17.4528 3.90686 16.2879 3.90686H15.8491L15.8532 2.84451C15.8532 2.05698 15.2133 1.41711 14.4258 1.41711H1.73503C0.787531 1.41711 0.0123052 2.18004 0 3.12753C0 3.13574 0 3.14394 0 3.15214V17.1554C0 17.943 0.639869 18.5828 1.4274 18.5828H16.292C17.461 18.5828 18.4085 17.6312 18.4085 16.4663V13.6033H18.8843C19.4996 13.6033 20 13.1029 20 12.4877V9.99792C19.9959 9.38266 19.4955 8.88225 18.8802 8.88225ZM1.73503 2.40153H14.4258C14.6678 2.40153 14.8687 2.59841 14.8687 2.84041L14.8646 3.90686H1.73503C1.32075 3.90686 0.984413 3.57052 0.984413 3.15625C0.984413 2.74197 1.32075 2.40153 1.73503 2.40153ZM17.42 16.4622C17.42 17.0857 16.9114 17.5943 16.2879 17.5943H1.4233C1.1813 17.5943 0.980312 17.3974 0.980312 17.1513V4.719C1.21001 4.82975 1.46431 4.89128 1.73503 4.89128H16.2879C16.9114 4.89128 17.42 5.39989 17.42 6.02335V8.88225H14.0607C13.4454 8.88225 12.945 9.38266 12.945 9.99792V12.4877C12.945 13.1029 13.4454 13.6033 14.0607 13.6033H17.42V16.4622ZM19.0115 12.4877C19.0115 12.5615 18.9541 12.6189 18.8802 12.6189H14.0648C13.991 12.6189 13.9336 12.5615 13.9336 12.4877V9.99792C13.9336 9.92819 13.991 9.86667 14.0648 9.86667H18.8802C18.95 9.86667 19.0115 9.92409 19.0115 9.99792V12.4877Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const CoinIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0711 2.92891C15.1823 1.04016 12.6711 0 10 0C7.32891 0 4.81766 1.04016 2.92891 2.92891C1.0402 4.81766 0 7.32891 0 10C0 12.6711 1.0402 15.1823 2.92891 17.0711C4.81766 18.9598 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.81766 17.0711 2.92891ZM10 18.8281C5.13215 18.8281 1.17188 14.8679 1.17188 10C1.17188 5.13215 5.13215 1.17188 10 1.17188C14.8679 1.17188 18.8281 5.13215 18.8281 10C18.8281 14.8679 14.8679 18.8281 10 18.8281Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.6275 9.41403H9.37228C8.65716 9.41403 8.0754 8.83227 8.0754 8.11719C8.0754 7.40207 8.6572 6.82031 9.37228 6.82031H11.8827C12.2063 6.82031 12.4686 6.55797 12.4686 6.23438C12.4686 5.91078 12.2063 5.64844 11.8827 5.64844H10.5858V4.35156C10.5858 4.02797 10.3235 3.76562 9.99989 3.76562C9.6763 3.76562 9.41396 4.02797 9.41396 4.35156V5.64844H9.37232C8.01103 5.64844 6.90356 6.75594 6.90356 8.11719C6.90356 9.47844 8.01107 10.5859 9.37232 10.5859H10.6275C11.3426 10.5859 11.9244 11.1677 11.9244 11.8828C11.9244 12.5979 11.3426 13.1797 10.6275 13.1797H8.11712C7.79353 13.1797 7.53118 13.442 7.53118 13.7656C7.53118 14.0892 7.79353 14.3515 8.11712 14.3515H9.41396V15.6484C9.41396 15.972 9.6763 16.2344 9.99989 16.2344C10.3235 16.2344 10.5858 15.972 10.5858 15.6484V14.3515H10.6275C11.9888 14.3515 13.0962 13.244 13.0962 11.8828C13.0962 10.5215 11.9888 9.41403 10.6275 9.41403Z"
      fill="#4A4A4A"
    />
  </svg>
)

CoinIcon.defaultProps = {
  width: "20px",
  height: "20px",
}

WalletIcon.defaultProps = {
  width: "20px",
  height: "20px",
}
