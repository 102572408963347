import React from "react"
import memoize from "memoize-one"

import {
  Box,
  Text,
  Flex,
  Switch,
  Translate,
  TabletInput,
  CheckBoxField,
  FloatingLabelInput,
} from "src/components"

import { normalizePhone } from "src/utils"
import { RUSSIAN_PHONE_MASK } from "src/constants"
import { ErrorContainer, ErrorElement } from "./Shared"

const translate = memoize((text) => (text ? <Translate i18n={`${text}`} ns="components" /> : <Nullable />))

export const Nullable = () => null

const getError = (error) => {
  const InjectedError = error

  if (InjectedError) {
    return (
      <ErrorContainer>
        <ErrorElement>{InjectedError}</ErrorElement>
      </ErrorContainer>
    )
  }

  return <Nullable />
}

export const TextInput = memoize((props) => {
  const { hint, ...rest } = props

  return (
    <FloatingLabelInput type="text" hint={hint && translate(hint)} {...rest} />
  )
})

export const Phone = memoize((props) => {
  const {
    hint, value, staticPrefix, ...rest
  } = props

  const [mask, setMask] = React.useState(RUSSIAN_PHONE_MASK)
  const refInput = React.useRef<HTMLInputElement>()

  const onChange = React.useCallback(
    (e) => {
      let { value } = e.target
      const digits = normalizePhone(value)
      const newMask = digits?.[0] === "7" ? RUSSIAN_PHONE_MASK : null

      if (newMask !== mask) {
        setMask(newMask)
        value = `${staticPrefix || ""}${digits || ""}`
      }

      if (!staticPrefix) {
        props.onChange({ target: { name: e.target.name, value } })
        return
      }

      props.onChange({
        target: {
          name: e.target.name,
          value: value.replace(staticPrefix, ""),
        },
      })
    },
    [props.onChange],
  )

  React.useEffect(() =>
    refInput.current && refInput.current.focus(), [mask])
  // it returns focus to phone input when new input was created by mask changing

  const decoratedValue = staticPrefix ? `${staticPrefix}${value || ""}` : value

  return (
    <FloatingLabelInput
      ref={refInput}
      type="text"
      hint={hint && translate(hint)}
      mask={mask}
      {...rest}
      onChange={onChange}
      value={decoratedValue}
    />
  )
})

export const Number = memoize((props) => {
  const { hint, ...rest } = props

  return (
    <FloatingLabelInput
      type="number"
      hint={hint && translate(hint)}
      {...rest}
    />
  )
})

export const SwitchInput = memoize((props) => {
  const { leftLabel, rightLabel, ...rest } = props

  return (
    <Switch
      leftLabel={leftLabel && translate(leftLabel)}
      rightLabel={rightLabel && translate(rightLabel)}
      variant="tablet"
      {...rest}
    />
  )
})

export const SwitchSelectInput = memoize((props) => {
  const {
    error, leftLabel, rightLabel, ...rest
  } = props

  return (
    <>
      <Flex alignItems="center" mt="40px">
        <Text
          fontSize="18px"
          fontWeight="500"
          color={props.value ? "greyShuttle" : "black"}
        >
          <Translate i18n={leftLabel} />
        </Text>
        <Box mx="24px" height="32px">
          <TabletInput.SwitchSelect {...rest} />
        </Box>
        <Text
          fontSize="18px"
          fontWeight="500"
          color={props.value ? "black" : "greyShuttle"}
        >
          <Translate i18n={rightLabel} />
        </Text>
      </Flex>
      {getError(error)}
    </>
  )
})

export const CheckBoxInput = memoize((props) => {
  const handleOnChange = (e) => {
    props.onChange({
      name: e.target.name,
      value: e.target.checked,
    })
  }

  return (
    <CheckBoxField
      fill="#F70000"
      alignItems="flex-start"
      fontSize="18px"
      fontWeight="bold"
      lineHeight="1.5"
      checkBoxMargin="2px 0 0 0"
      color="#4a4a4a"
      {...props}
      checked={props.value}
      onChange={handleOnChange}
    />
  )
})
