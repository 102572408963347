import React from "react"

import { Gallery } from "src/components"

import Video from "../../Video"
import { ContentContainer } from "../styles"

import { Description, Presentations } from "./elements"

import { SpacedSubHeader, DescriptionBlock } from "./styles"

export default ({ order: { application } }) => {
  const {
    data: { description, descriptionAttachments, descriptionPresentations },
    externalMedium,
  } = application

  return (
    <ContentContainer>
      <DescriptionBlock>
        <Description>{description}</Description>
      </DescriptionBlock>

      <DescriptionBlock>
        <Presentations items={descriptionPresentations} />
      </DescriptionBlock>

      <DescriptionBlock>
        <Video.List medium={externalMedium} renderHeader={SpacedSubHeader} />
      </DescriptionBlock>

      <DescriptionBlock>
        <Gallery
          renderHeader={SpacedSubHeader}
          images={descriptionAttachments}
          header="pages:user.proposal.titles.images"
        />
      </DescriptionBlock>
    </ContentContainer>
  )
}
