import React from "react"

import { Invest, PenaltiesInfo } from "src/components"

import { ControlsContainer } from "./styles"

const InvestmentControlsSchema = new Map([
  [
    "CONFIRMED",
    (props) => {
      const { data, buttonVariant, withOutdatedMessage } = props

      return (
        <Invest
          order={data}
          withOutdatedMessage={withOutdatedMessage}
          buttonVariant={buttonVariant}
        />
      )
    },
  ],
  ["COMPLETE", ({ data }) => <PenaltiesInfo order={data} />],
])

const InvestmentControls = (props) => {
  const Controls = InvestmentControlsSchema.get(props.status)

  if (!Controls) return null

  return (
    <ControlsContainer>
      <Controls {...props} />
    </ControlsContainer>
  )
}

export default InvestmentControls
