import styled from "styled-components"

export const Container = styled.div`
  flex-grow: 1;
`

export const Devider = styled.div`
  height: 28px;
  width: 100%;
`
