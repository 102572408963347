import React from "react"

import { Translate } from "src/components"
import { MIN_INVESTMENT_AMOUNT } from "src/constants"

import { pipe, notifyGTM, createChainedFunction } from "src/utils"

const minValueError = {
  keyword: "min",
  path: "investToOrder.amount",
}

const getErrorTranslation = (error) => error && <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />

const sendGTMEvent = (order) => {
  const { cession, application, id: orderId } = order

  notifyGTM({
    event: "checkoutFunnel2",
    eventCategory: "",
    extras: {
      ecommerce: {
        checkout: {
          actionField: { step: 2 },
          products: [
            {
              id: orderId,
              quantity: 1,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
            },
          ],
        },
      },
    },
  })()
}

export const useOnSubmit = (props) => React.useCallback(() => {
  const amountAsNumber = Number(
    props.amount.replace(",", ".").replace(/\s/g, ""),
  )
  if (amountAsNumber < MIN_INVESTMENT_AMOUNT) {
    props.setManualError(minValueError)
    return
  }

  sendGTMEvent(props.order)

  props.onComplete()
}, [props.amount])

export const useOnChange = (props) => React.useCallback(
  createChainedFunction(
    () => props.clearError("investToOrder.amount"),
    props.onChange,
  ),
  [props.onChange],
)

export const useErrors = (props) => React.useCallback(
  (path) => pipe(props.getError, getErrorTranslation)(path),
  [],
)
