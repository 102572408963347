/* eslint-disable no-param-reassign */
import React from "react"
import Select from "./style"

const render = ({ options, ...props }) => {
  if (!Array.isArray(options)) return null

  return (
    <Select {...props}>
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Select>
  )
}

export default render
