/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TrustedAccountList_accounts = {
    readonly trustedUsers: ReadonlyArray<{
        readonly id: string;
        readonly email: string;
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly __typename: string;
            readonly orders: {
                readonly count: number;
            };
        }>;
    }>;
    readonly " $refType": "TrustedAccountList_accounts";
};
export type TrustedAccountList_accounts$data = TrustedAccountList_accounts;
export type TrustedAccountList_accounts$key = {
    readonly " $data"?: TrustedAccountList_accounts$data;
    readonly " $fragmentRefs": FragmentRefs<"TrustedAccountList_accounts">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrustedAccountList_accounts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "trustedUsers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profiles",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderConnection",
              "kind": "LinkedField",
              "name": "orders",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
(node as any).hash = '8846f1de42de30bd4f9e9a0c748677cc';
export default node;
