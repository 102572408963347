import React from "react"
import moment from "moment"
import { Route } from "react-router"

import { ErrorsContainer } from "src/containers"
import { notifyGTM, isRussianUser } from "src/utils"
import { ViewerDataConsumer, RelayEnvironmentConsumer } from "src/context"
import { Translate, ErrorForField, InputPassword } from "src/components"
import { SetViewerPassword } from "src/mutations"

import { Title, Button } from "../../../../molecules/NewPasswordModal/styles"

import { matchError, requiredError } from "./errors"
import { Container } from "./styles"

const sendGTMEvent = () => {
  notifyGTM({
    event: "Register",
    eventCategory: "",
    extras: {
      date: moment().format("DDMMYYYY"),
    },
  })()
}

class PasswordForm extends React.Component<any, any> {
  state = {
    busy: false,
    password: "",
    match: false,
    isLoading: false,
    retry_password: "",
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))

    if (name === "password" && value !== this.state.retry_password) {
      this.setState(() => ({ match: false }))
    }

    this.props.clearError(`setViewerPassword.${name}`)
  }

  onRetryChange = (event) => {
    const { name, value } = event.target
    let match = false
    this.setState(() => ({ [name]: value }))

    if (this.state.password === value) {
      this.props.clearError("setViewerPassword.retry_password")
      match = true
    } else {
      this.props.setManualError(matchError)
      match = false
    }

    this.setState(() => ({ match }))
  }

  onSubmit = (event) => {
    if (event) event.preventDefault()

    if (this.state.busy) {
      return
    }

    if (this.state.password.length === 0) {
      this.props.setManualError(requiredError`password`)
      return
    }

    if (this.state.match === false) {
      this.props.setManualError(matchError)
      return
    }

    this.setState(() => ({ busy: true, isLoading: true }))
    this.commit()
  }

  onError = (transaction) => {
    this.setState(() => ({ busy: false, isLoading: false }))
    this.props.setErrors(transaction)
  }

  onCompleted = () => {
    sendGTMEvent()
    this.setState(() => ({ busy: false, isLoading: false }))
    if (isRussianUser(this.props.viewer)) {
      this.props.history.push("/accounts/create/individual")
    } else {
      this.props.history.push("/accounts/create/foreign")
    }
  }

  getError = (error) => ErrorForField(this.props.getError(error))

  commit = () => {
    const variables = {
      input: {
        password: this.state.password,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    SetViewerPassword.commit(this.props.environment, variables, null, callbacks)
  }

  render() {
    return (
      <Container>
        <Title>Сменить пароль</Title>

        <InputPassword
          withoutBorder
          errorColor="mainRed"
          name="password"
          onChange={this.onChange}
          hasError={this.getError("setViewerPassword.password")}
          error={this.getError("setViewerPassword.password")}
          disabled={this.state.isLoading}
          label="components:password-reset-form.password_input.label"
          value={this.state.password}
        />

        <InputPassword
          withoutBorder
          errorColor="mainRed"
          name="retry_password"
          onChange={this.onRetryChange}
          disabled={this.state.isLoading}
          label="components:password-reset-form.password_match_input.label"
          value={this.state.retry_password}
          error={this.getError("setViewerPassword.retry_password")}
          hasError={this.getError("setViewerPassword.retry_password")}
        />

        <form onSubmit={this.onSubmit}>
          <Button
            disabled={
              this.state.isLoading
              || !this.state.password
              || !this.state.retry_password
            }
          >
            <Translate
              i18n="juristic_profile_form.buttons.save"
              ns="components"
            />
          </Button>
        </form>
      </Container>
    )
  }
}

export default (props) => (
  <ViewerDataConsumer>
    {(viewer) => (
      <ErrorsContainer>
        {(errors) => (
          <RelayEnvironmentConsumer>
            {({ environment }) => (
              <Route>
                {({ history }) => (
                  <PasswordForm
                    {...props}
                    {...errors}
                    environment={environment}
                    history={history}
                    viewer={viewer}
                  />
                )}
              </Route>
            )}
          </RelayEnvironmentConsumer>
        )}
      </ErrorsContainer>
    )}
  </ViewerDataConsumer>
)
