/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ManageIndividual_profile = {
    readonly id: string;
    readonly name: string;
    readonly phone: string | null;
    readonly approvedOnce: boolean;
    readonly _avatar: {
        readonly url: string;
    } | null;
    readonly " $refType": "ManageIndividual_profile";
};
export type ManageIndividual_profile$data = ManageIndividual_profile;
export type ManageIndividual_profile$key = {
    readonly " $data"?: ManageIndividual_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ManageIndividual_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageIndividual_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedOnce",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "_avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualProfile"
};
(node as any).hash = '01c405e3d51ff677b7b55bdb5c477440';
export default node;
