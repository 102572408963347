import React from "react"

import {
  Box, Text, Button, FlexBox, Translate,
} from "src/components"

import {
  List, MailLink, CryingDude, ReasonContainer,
} from "./styles"

const Title = () => (
  <Box>
    <Text>
      <Translate i18n="accreditation.reject_container.title" ns="components" />
    </Text>
  </Box>
)

const Reason = ({ declineReason }) => declineReason && (
    <ReasonContainer>
      <List>
        {declineReason.isArray ? (
          declineReason.map((reason) => <li key={reason}>{reason}</li>)
        ) : (
          <li>{declineReason}</li>
        )}
      </List>
    </ReasonContainer>
)

const Description = () => (
  <Box>
    <Text>
      <Translate
        i18n="accreditation.reject_container.description"
        ns="components"
      />
    </Text>
  </Box>
)

const EditButton = ({ onClick }) => (
  <Box pt={20}>
    <Button onClick={onClick}>
      <Translate
        i18n="accreditation.reject_container.button_label"
        ns="components"
      />
    </Button>
  </Box>
)

const Link = () => (
  <Box pt={36}>
    <Text fontSize="16px" color="greyDark">
      <Translate
        i18n="accreditation.reject_container.help.text"
        ns="components"
      />
      <MailLink href="mailto:help@karma.red">
        <Translate
          email="help@karma.red"
          i18n="footer.help.email"
          ns="common"
        />
      </MailLink>
    </Text>
  </Box>
)

export default ({ accreditation: { declineReason }, action }) => (
  <FlexBox
    width={760}
    display="flex"
    flexDirection="row"
    justifyContent="flex-start"
  >
    <CryingDude />
    <Box pl={52}>
      <Title />
      <Reason declineReason={declineReason} />
      <Description />
      <EditButton onClick={action} />
      <Link />
    </Box>
  </FlexBox>
)
