/* eslint-disable max-len */

import React from "react"

function Wallet(props) {
  return (
    <svg {...props} viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.6563 9.77049H22.0853V6.6257C22.0853 5.33981 20.9434 4.29756 19.5455 4.29756H19.0189L19.0238 3.12898C19.0238 2.26269 18.2559 1.55884 17.3109 1.55884H2.08203C0.945037 1.55884 0.0147662 2.39805 0 3.4403C0 3.44932 0 3.45835 0 3.46737V18.871C0 19.7373 0.767842 20.4411 1.71288 20.4411H19.5505C20.9532 20.4411 22.0902 19.3944 22.0902 18.113V14.9637H22.6612C23.3995 14.9637 24 14.4132 24 13.7365V10.9977C23.9951 10.3209 23.3946 9.77049 22.6563 9.77049ZM2.08203 2.64169H17.3109C17.6013 2.64169 17.8425 2.85826 17.8425 3.12447L17.8376 4.29756H2.08203C1.58491 4.29756 1.1813 3.92758 1.1813 3.47188C1.1813 3.01618 1.58491 2.64169 2.08203 2.64169ZM20.904 18.1085C20.904 18.7943 20.2937 19.3538 19.5455 19.3538H1.70796C1.41756 19.3538 1.17637 19.1372 1.17637 18.8665V5.19092C1.45201 5.31274 1.75718 5.38041 2.08203 5.38041H19.5455C20.2937 5.38041 20.904 5.93989 20.904 6.6257V9.77049H16.8728C16.1345 9.77049 15.534 10.3209 15.534 10.9977V13.7365C15.534 14.4132 16.1345 14.9637 16.8728 14.9637H20.904V18.1085ZM22.8138 13.7365C22.8138 13.8177 22.7449 13.8808 22.6563 13.8808H16.8778C16.7892 13.8808 16.7203 13.8177 16.7203 13.7365V10.9977C16.7203 10.921 16.7892 10.8533 16.8778 10.8533H22.6563C22.74 10.8533 22.8138 10.9165 22.8138 10.9977V13.7365Z" />
    </svg>
  )
}

Wallet.defaultProps = {
  width: "26px",
  height: "24px",
  fill: "black",
}

export default Wallet
