import { useState, useEffect } from "react"

import { useHotKey } from "src/hooks"

import useOptions from "./useOptions"

const shouldApplyValue = (activeIndex, options) =>
  options.length > 0 && activeIndex >= 0 && activeIndex < options.length

const useCountries = () => {
  const [isDirty, setDirty] = useState(false)
  const [isFocused, setFocus] = useState(false)
  const [displayOptions, setDisplayOptions] = useState(false)

  const [inputValue, setInputValue] = useState("")
  const [activeIndex, setActiveIndex] = useState(-1)

  const { options, isLoading } = useOptions()

  // :TODO refactor this EFFECTS hell, join this hook with useAutosuggestHook
  useEffect(() => {
    if (options.length === 0) {
      setActiveIndex(-1)
      setInputValue("")
    }
  }, [options])

  useEffect(() => {
    if (!setDisplayOptions) setActiveIndex(-1)
  }, [setDisplayOptions])

  useEffect(() => {
    if (isFocused) {
      setDirty(true)
    }
  }, [isFocused])

  useEffect(() => {
    if (isFocused && isDirty) {
      setDisplayOptions(true)
    } else {
      setDisplayOptions(false)
    }
  }, [isFocused, isDirty])

  useEffect(() => {
    if (shouldApplyValue(activeIndex, options)) {
      setInputValue(options[activeIndex].name)
    }
  }, [activeIndex])

  useEffect(() => {
    if (isFocused) {
      setActiveIndex(-1)
    }
  }, [isFocused])

  const incrementActiveIndex = () => {
    if (isFocused && activeIndex + 1 < options.length) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const decrementActiveIndex = () => {
    if (isFocused && activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)
  const onSelect = (i) => () => setActiveIndex(i)
  const onKeyboardSelect = () => {
    if (isFocused && displayOptions) {
      setDirty(false)
    } else if (isFocused && !isDirty) {
      setDirty(true)
    }
  }

  useHotKey(13, onKeyboardSelect)
  useHotKey(38, decrementActiveIndex)
  useHotKey(40, incrementActiveIndex)

  return {
    onBlur,
    options,
    onFocus,
    onSelect,
    isLoading,
    inputValue,
    activeIndex,
    setInputValue,
    displayOptions,
  }
}

export default useCountries
