import { useCallback } from "react"

import useEnvironment from "./useEnvironment"

const useStatelessMutation = (mutation) => {
  const { environment } = useEnvironment()

  const commit = useCallback(
    ({ variables, uploadables, callbacks }) => {
      mutation.commit(environment, variables, uploadables, {
        onError: (transaction) => {
          if (callbacks && typeof callbacks.onError === "function") {
            callbacks.onError(transaction)
          }
        },
        onCompleted: (payload) => {
          if (callbacks && typeof callbacks.onCompleted === "function") {
            callbacks.onCompleted(payload)
          }
        },
      })
    },
    [mutation],
  )

  return { commit }
}

export default useStatelessMutation
