import React from "react"

import { Route } from "react-router"

import {
  Text, Button, Template, Translate,
} from "src/components"

const handleRoute = (history) => () => history.push("/market")

const Action = () => (
  <Route>
    {({ history }) => (
      <Button variant="blueWide" onClick={handleRoute(history)}>
        <Text fontSize="16px">
          <Translate i18n="components:order_list.investments.emptyList.buttons.action" />
        </Text>
      </Button>
    )}
  </Route>
)

export default () => (
  <Template
    layout="empty"
    header="components:order_list.investments.pending.header"
    image="/images/orderLists/pending/investments.svg"
    description="components:order_list.investments.pending.description"
    action={<Action />}
  />
)
