import React from "react"

import { Box, Select } from "src/components"
import {
  SEQUENCE_OF_REDEMPTION_REST,
  SEQUENCE_OF_REDEMPTION_IN_END,
} from "src/constants"
import { getSelectedValue } from "src/utils"

const getOptionsFromState = (type) => {
  if (type === "end") {
    return SEQUENCE_OF_REDEMPTION_IN_END
  }
  return SEQUENCE_OF_REDEMPTION_REST
}

export class SequenceOfRedemption extends React.PureComponent<any, any> {
  onChange = (selectedValue) => {
    this.props.onChange({
      name: this.props.name,
      value: selectedValue.value,
    })
  }

  render() {
    const options = getOptionsFromState(this.props.repaymentSchedule.type)
    const selectedOption = getSelectedValue(options, this.props.value)

    return (
      <Box width="100%">
        {
          <Select
            value={selectedOption.value}
            onChange={this.onChange}
            options={options}
          />
        }
      </Box>
    )
  }
}

export default SequenceOfRedemption
