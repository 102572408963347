import styled from "styled-components"

export const TableContainer = styled.table`
  width: 1200px;
  margin: 0 -10px;
  border-collapse: separate;
  border-spacing: 10px 0;
  border-top: 1px solid #d9dee2;

  td,
  th {
    padding: 10px;
    background: #fff;
    border-bottom: 1px solid #d9dee2;
  }

  th {
    padding-left: 0;
  }
`

export const TableBody = styled.tbody`
  td:nth-child(even) {
    background-color: #f3f6fa;
  }

  td:first-child {
    padding-left: 0;
  }
`
