import React from "react"

import {
  Box, Label, FormRow, Translate, TextField,
} from "src/components"

class IndividualBeneficiary extends React.Component<any, any> {
  onChange = (event) => {
    const { name, value } = event.target
    const beneficiaryData = { ...this.props.value }
    beneficiaryData[name] = value

    this.props.onChange(beneficiaryData)
  }

  render() {
    return (
      <React.Fragment>
        <FormRow>
          <Box>
            <Label>
              <Translate
                i18n="legal_entity_offer_form.rows.name.label"
                ns="components"
              />
            </Label>
          </Box>
          <Box width="480px">
            <TextField
              type="text"
              name="name"
              value={this.props.value.name}
              onChange={this.onChange}
            />
          </Box>
        </FormRow>
        <FormRow>
          <Box>
            <Label>
              <Translate
                i18n="legal_entity_offer_form.rows.attitude.label"
                ns="components"
              />
            </Label>
          </Box>
          <Box width="480px">
            <TextField
              type="text"
              name="attitude"
              value={this.props.value.attitude}
              onChange={this.onChange}
            />
          </Box>
        </FormRow>
      </React.Fragment>
    )
  }
}

export default IndividualBeneficiary
