import styled, { css } from "styled-components"

import {
  Box, Text, Icon, Button, Loader, Link,
} from "src/components"

import { HeaderImage } from "../../ReferralPage/images"

export const ImageContainer = styled.div`
  max-width: 160px;
  width: 100%;
  height: auto;
  position: relative;
  margin-right: 60px;
  box-sizing: content-box;

  @media screen and (max-width: 480px) {
    max-width: 120px;
    margin: 0 auto;
  }
`

export const Image = styled(HeaderImage)`
  width: 100%;
  height: auto;
`

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 40px;

  @media screen and (max-width: 480px) {
    margin-top: 50px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

export const Title = styled.h4`
  font-family: Geometria, sans-serif;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 480px) {
    font-weight: 300;
    font-size: 28px;
    line-height: 35px;
    text-align: center;

    span {
      font-weight: normal;
    }
  }
`

export const SuccessSubTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 8px 0 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin-top: 12px;
  }
`
export const SuccessDescription = styled(SuccessSubTitle)`
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 60px;
  }
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  line-height: 18px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 520px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    margin-top: 32px;
  }
`

export const InputContainer = styled.div`
  max-width: 550px;
  width: 100%;
`

export const InputLabel = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};
  margin: 0 0 12px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const DescriptionContainer = styled(FlexContainer)`
  align-self: center;
  flex-direction: column;
  margin-left: 16px;

  @media screen and (max-width: 480px) {
    margin: 12px 0 0;
    align-self: flex-start;
  }
`

export const Description = styled(InputLabel)`
  margin: 0;
`

export const DepositButton = styled(Button)`
  align-self: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  margin-left: 16px;
  font-weight: 500;
  text-transform: none;

  @media screen and (max-width: 480px) {
    align-self: flex-start;
    margin: 0;
  }
`

export const AgreementContainer = styled(FlexContainer)`
  flex-direction: column;
  margin: 50px 0 30px;
`

export const AgreementText = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
  }
`

export const AgreementDescription = styled(AgreementText)`
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};
  margin-top: 8px;
`

export const RedButton = styled.button`
  width: 200px;
  height: 45px;
  padding: 0;
  border: none;
  background: ${({ theme }) => theme.colors.mainRed};
  box-shadow: 0px 5px 10px rgba(255, 116, 143, 0.55);
  border-radius: 4px;
  margin-top: 32px;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  font-family: Geometria, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 60px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  & > ${Loader} {
    font-size: 5px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 75px;
    margin: 40px 0 50px;
    font-size: 24px;
    line-height: 29px;

    & > ${Loader} {
      font-size: 10px;
    }
  }
`

export const ReturnButton = styled.button`
  border: none;
  padding: 8px 0;
  margin-bottom: 50px;
  font-family: Geometria, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const WithdrawProfileContainer = styled(Box)`
  padding: 11px 25px;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ bordered }) => (bordered ? "4px" : "0")};
  border: 1px solid
    ${({ theme, bordered }) => theme.colors[bordered ? "greyLight" : "white"]};
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyShuttle};
  line-height: 1.5;
`

export const OptionsContainer = styled.div<any>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  border-radius: 4px;
  z-index: 10;
  max-height: 320px;
  box-shadow: 0 0 24px 0 rgba(217, 222, 226, 0.76);
  background-color: ${({ theme }) => theme.colors.white};
  ${({ shouldDisplay }) => {
    if (shouldDisplay) {
      return "opacity: 1; visibility: visible;"
    }
    return "opacity: 0; visibility: hidden; transition: all .3s ease-in-out;"
  }}
`

const activeStyles = css`
  background-color: ${({ active, theme }: any) => {
    if (active) {
      return theme.colors.catskillWhite
    }
    return theme.colors.white
  }};
`

const hoverStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.catskillWhite};
`

export const Option = styled.div<any>`
  width: 100%;
  height: 56px;
  overflow: hidden;
  ${activeStyles}
  &:hover {
    ${hoverStyles}
  }
`

export const SelectedProfileContainer = styled.div`
  position: relative;
`

export const StyledArrowIcon = styled(Icon.arrowDown)`
  position: absolute;
  top: 23px;
  right: 27px;
  cursor: pointer;
`

export const LoaderContainer = styled.div`
  margin-top: 40px;
  width: 100%;
  text-align: center;
  display: flex;
  position: relative;
`

export const LoaderText = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryBlacks[6]};
`

export const CheckboxLabel = styled.p`
  font-size: 14px;
  font-family: Geometria, sans-serif;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;

  a {
    color: blue;
    font-size: 14px;
    font-family: Geometria, sans-serif;
    text-decoration: none;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`

export const StyledLink = styled(Link)`
  & > ${RedButton} {
    margin-top: 0;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`
