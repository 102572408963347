import styled from "styled-components"

export const Block = styled.div`
  position: relative;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 22px 4px;
  box-sizing: border-box;
  width: 130px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 8px 0;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 1180px) {
    margin: 8px 2px 0;

    &:first-of-type {
      margin-left: 2px;
    }

    &:last-of-type {
      margin-right: 2px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 104px;
    height: 104px;
    padding: 12px 4px;
  }
`

export const Title = styled.p`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #4a4a4a;
  margin: 0;
`

export const Value = styled.p`
  font-family: Geometria;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: #4a4a4a;
  margin: 0;
  font-weight: 900;

  @media screen and (max-width: 480px) {
    font-size: 19px;
    line-height: 24px;
  }
`

export const Description = styled.a`
  font-size: 12px;
  line-height: 15px;
  font-family: Geometria;
  text-align: center;
  color: #4a4a4a;
  margin: 0;
`
