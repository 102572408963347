import React from "react"

import {
  Box, Flex, Text, Loader, Translate,
} from "src/components"

import { ArrowContainer, AmountButton as Button } from "./styles"

export const AmountButton = ({ children, ...rest }) => (
  <Button width="100px" height="32px" variant="default" {...rest}>
    {children} ₽
  </Button>
)

export const ProfitabilityCell = (props) => {
  const { title, busy, amount } = props

  return (
    <Flex mt="17px" flexDirection="column" justifyContent="space-between">
      <Box>
        <Text
          fontSize="18px"
          fontWeight="500"
          lineHeight="1.33"
          whiteSpace="pre-line"
        >
          <Translate i18n={title} />
        </Text>
        <Box mb="17px" mt="10px">
          <Text fontSize="14px" lineHeight="1.14" color="greyDarker">
            <Translate i18n="modals:profitability_calc.tax_info" />
          </Text>
        </Box>
      </Box>
      <Box>
        {busy ? (
          <Box height="15px">
            <Loader fontSize="3px" />
          </Box>
        ) : (
          <Text fontSize="24px" fontWeight="500">
            {amount ? `${amount} ₽` : null}
          </Text>
        )}
      </Box>
    </Flex>
  )
}

const ArrowGlyph = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="7"
    viewBox="0 0 14 7"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#0B1016"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 .5l-6 6-6-6"
    />
  </svg>
)

export const Arrow = ({ opened }) => (
  <ArrowContainer opened={opened}>
    <ArrowGlyph />
  </ArrowContainer>
)
