import React from "react"
import { ThemeProvider } from "styled-components"
import { BrowserRouter } from "react-router-dom"

import MainPage from "./pages/Main"
import DefaultTheme from "./styles/themes"
import GlobalStyle from "./styles"
import Fonts from "./styles/fonts"

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <GlobalStyle />
      <Fonts />
      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
