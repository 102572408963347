import React from "react"
import PropTypes from "prop-types"

import { Box, Flex } from "src/components"

const render = ({
  gutter, renderLeft, renderRight, ...props
}) => (
  <Flex alignItems="center" {...props}>
    <Box mr={gutter / 2} flex={6}>
      {renderLeft()}
    </Box>
    <Box ml={gutter / 2} flex={4}>
      {renderRight()}
    </Box>
  </Flex>
)

render.propTypes = {
  gutter: PropTypes.number,
  renderLeft: PropTypes.func.isRequired,
  renderRight: PropTypes.func.isRequired,
}

render.defaultProps = {
  gutter: 60,
}

export default render
