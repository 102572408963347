import React from "react"

import {
  Box,
  Label,
  FormRow,
  FlexBox,
  Translate,
  RadioButton,
} from "src/components"

import { PLACEMENT_PURPOSE } from "src/constants"

class Purpose extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { value, checked } = event.target

    if (checked) {
      this.props.onChange({
        name: this.props.name,
        value,
      })
    }
  }

  render() {
    return (
      <FormRow>
        <Box width={280}>
          <Label>
            <Translate
              i18n="application.worksheet.rows.purpose.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box>
          {PLACEMENT_PURPOSE.map(({ label, value }, index) => (
            <FlexBox
              key={index}
              display="flex"
              flexDirection="row"
              justifyContnet="flex-start"
              alignItems="center"
              py="6px"
            >
              <RadioButton
                label={label}
                value={value}
                onChange={this.onChange}
                name={`placementPurpose${this.props.index}`}
                checked={this.props.value === value}
              />
            </FlexBox>
          ))}
        </Box>
      </FormRow>
    )
  }
}

export default Purpose
