import styled from "styled-components"
import { space } from "styled-system"

export const Container = styled.div`
  overflow-x: hidden;
  ${space};
`

export const ItemsCarousel = styled.div<any>`
  display: flex;
  align-items: center;
  width: max-content;
  transform: translate3d(-${({ offset }) => offset}px, 0, 0);
  transition: transform 200ms linear;
`

export const StyledImage = styled.img`
  max-width: 100%;
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 280px;
`

export const DefaultItemsContainer = styled.div``

export const GenericItem = styled.div<any>`
  ${space};
  float: left;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`

export const ArrowContainer = styled.div<any>`
  width: 14px;
  height: 10px;
  transform: translateY(-30%) ${({ forward }) => forward && "rotateY(180deg)"};
`
