import React from "react"

import {
  Container,
  Table,
  TH,
  FirstTH,
  TD,
  FirstTD,
  FooterTD,
  FirstFooterTD,
} from "./styles"

const tableHead = [
  "Рейтинг",
  "D",
  "C",
  "CC",
  "CCC",
  "B",
  "BB",
  "BBB",
  "A",
  "AA",
  "AAA",
]

const tableBody = [
  [
    "Ставка, ОТ",
    "-",
    "35%",
    "33%",
    "30%",
    "27%",
    "24%",
    "21%",
    "19%",
    "17%",
    "15%",
  ],
  [
    "Сумма, ДО (т.р.)",
    "-",
    "250",
    "500",
    "1000",
    "2000",
    "4000",
    "6000",
    "8000",
    "12000",
    "15000",
  ],
  ["Срок, ДО (мес.)", "-", "3", "3", "3", "6", "6", "9", "12", "12", "12"],
]

const tableFooter = [
  "Нужно баллов",
  "",
  "до 100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900+",
]

function getElementVisibility(ratingIdx, arrayIdx, length) {
  const isDisabled = arrayIdx - 1 < ratingIdx
  const isSelected = arrayIdx - 1 === ratingIdx

  const first = ratingIdx + 5 > length - 1 ? length - 5 : ratingIdx
  // eslint-disable-next-line no-nested-ternary
  const last = first === 0 ? 5 : first + 5 > length - 1 ? length : first + 4
  const isHidden = arrayIdx < first || arrayIdx > last

  return {
    isDisabled,
    isSelected,
    isHidden,
  }
}

function RatingTable(props) {
  const { ratingIndex = 0 } = props

  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            {tableHead.map((item, idx) => {
              if (idx === 0) {
                return <FirstTH key={idx}>{item}</FirstTH>
              }

              const { isDisabled, isSelected, isHidden } = getElementVisibility(
                ratingIndex,
                idx,
                tableHead.length,
              )

              return (
                <TH
                  disabled={isDisabled}
                  isHidden={isHidden}
                  isSelected={isSelected}
                  key={idx}
                >
                  {item}
                </TH>
              )
            })}
          </tr>

          {tableBody.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {row.map((item, idx) => {
                if (idx === 0) {
                  return <FirstTD key={idx}>{item}</FirstTD>
                }

                const { isDisabled, isHidden } = getElementVisibility(
                  ratingIndex,
                  idx,
                  row.length,
                )

                return (
                  <TD disabled={isDisabled} isHidden={isHidden} key={idx}>
                    {item}
                  </TD>
                )
              })}
            </tr>
          ))}

          <tr>
            {tableFooter.map((item, idx) => {
              if (idx === 0) {
                return <FirstFooterTD key={idx}>{item}</FirstFooterTD>
              }

              const { isDisabled, isHidden } = getElementVisibility(
                ratingIndex,
                idx,
                tableFooter.length,
              )

              return (
                <FooterTD disabled={isDisabled} isHidden={isHidden} key={idx}>
                  {item}
                </FooterTD>
              )
            })}
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default RatingTable
