import memoize from "memoize-one"

export const isDigit = (str) => {
  const regExp = /^(?=.*\d)[\d ]+$/g
  return !str || regExp.test(str)
}

export const formatNumber = (str) => {
  if (!str) return ""

  return String(str)
    .replace(/\D/g, "")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")
}

export const isEqualAmount = (x, y) => formatNumber(x) === formatNumber(y)

export const fitsAvailableAmount = memoize((availableAmount) => (amount) => {
  const formatted = String(amount).replace(/\D/g, "")
  return availableAmount >= Number(formatted)
})
