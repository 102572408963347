import React from "react"

import { Translate } from "src/components"

import {
  List, ListItem, Description, Rating, Text,
} from "./styles"

function ListBuilder(props) {
  const { schema } = props

  return (
    <List>
      {schema.map((item, i) => (
        <ListItem bg={item.color} key={i}>
          <Rating>
            <Text fontWeight={700}>
              <Translate i18n={item.rating} />
            </Text>
          </Rating>
          <Description>
            <Text>
              <Translate i18n={item.text} />
            </Text>
          </Description>
        </ListItem>
      ))}
    </List>
  )
}

export default ListBuilder
