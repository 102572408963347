import React from "react"
import styled from "styled-components"
import { width } from "styled-system"

const Row = styled.div`
  ${width};
  padding-bottom: 60px;
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  &:first-of-type {
    margin-top: 0;
  }
`

export default ({ hidden, children }: any) => !hidden && <Row>{children}</Row>
