import React from "react"

import { useMutation } from "src/hooks"
import { ErrorsContainer } from "src/containers"
import { PurposedMutationProvider } from "src/context"
import { AddIndividualProfile, AddEntrepreneurProfile } from "src/mutations"

import { pipe, createChainedFunction, promisifyHookedMutation } from "src/utils"

import createValidator from "./validations"
import { profileDataFromState } from "./utils"

const processRussianInvestorMutations = [
  "addIndividualProfile",
  "addEntrepreneurProfile",
]

const ProcessIndividualProfile = React.memo((props: any) => {
  const { errors } = props
  const { setErrors } = errors

  const use = pipe(useMutation, promisifyHookedMutation)
  const addIndividualProfile = use(AddIndividualProfile)
  const addEntrepreneurProfile = use(AddEntrepreneurProfile)

  const throwError = (err) => {
    throw new Error(err)
  }

  const handleErrors = createChainedFunction(setErrors, throwError)

  const createRussianInvestorProfile = (formState) => {
    const commit = formState.isEntrepreneur
      ? addEntrepreneurProfile
      : addIndividualProfile
    const mutation = formState.isEntrepreneur
      ? "addEntrepreneurProfile"
      : "addIndividualProfile"
    const input = {
      ...profileDataFromState(formState),
    }

    const validate = createValidator(mutation)

    return validate(formState, { viewer: props.viewer })
      .then(() => commit({ input }))
      .catch(handleErrors)
  }

  return (
    <PurposedMutationProvider value={processRussianInvestorMutations}>
      {props.children({
        errors,
        createRussianInvestorProfile,
      })}
    </PurposedMutationProvider>
  )
})

export default (props) => (
  <ErrorsContainer>
    {(errors) => <ProcessIndividualProfile errors={errors} {...props} />}
  </ErrorsContainer>
)
