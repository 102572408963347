import {
  fillStringToLength,
} from "src/utils"

const parseNumber = (val) => val?.replace(/\D/gim, "").trim() || ""

const dateToFormState = (date) => {
  if (typeof date === "string") {
    return date.split(" - ").reverse().join("-").replace(/_/gim, "")
      .trim()
  }

  return date
}

const formStateToScans = (scans) => scans.map((scan) => {
  if (typeof scan === "string") {
    return scan
  }

  const { id } = scan

  return id
})

const passportToState = (passport) => {
  /* eslint-disable prefer-const */

  if (passport === null) {
    return {
      number: "",
      series: "",
      issuedAt: "",
      scans: [],
      issuedBy: {
        code: "",
        name: "",
      },
      postponeScans: false,
    }
  }

  const {
    issuedAt = "",
    issuedBy = {},
    number,
    series,
    scans: injectedScans,
    postponeScans,
  } = passport

  const { code = "", name = "" } = issuedBy

  const stringedSeries = fillStringToLength(String(series), 4)
  const stringedNumber = fillStringToLength(String(number), 6)

  const numberAndSeries = `${stringedSeries} / ${stringedNumber}`

  const nextPassportData = {
    code: code?.replace(/_/gim, "").trim() || "",
    name,
    scans: [...injectedScans],
    series: numberAndSeries,
    issuedAt: issuedAt.split("-").join(" - "),
    postponeScans,
  }

  return nextPassportData
}

const stateToPassport = (formState) => {
  const { passport } = formState

  const {
    code,
    name,
    scans: statePassportScans,
    series: stringedSeries,
    issuedAt,
    postponeScans,
  } = passport

  const [splitedSeries, splitedNumber] = stringedSeries.split(" / ")

  const issuedBy = {
    code: code?.replace(/_/gim, "").trim(),
    name,
  }

  const nextPassportData = {
    issuedBy,
    number: parseNumber(splitedNumber),
    series: parseNumber(splitedSeries),
    scans: formStateToScans(statePassportScans),
    issuedAt: issuedAt.split(" - ").join("-"),
    postponeScans: !!postponeScans,
  }

  return nextPassportData
}

export function formatStrings(inputs) {
  return {
    ...Object.entries(inputs).reduce((acc, [key, value]) => {
      acc[key] = typeof value === "string" ? value.replace(/_/g, "") : value
      return acc
    }, {}),
    email: inputs.email,
    publicEmail: inputs.publicEmail,
    birthDate: dateToFormState(inputs.birthDate),
    passport: stateToPassport(inputs),
  }
}

export function getInitialValues(user: any = {}, profile: any = {}) {
  const email = user?.email || ""

  const borrower = profile.borrower || {}
  const { address, actualAddress, passport = null } = profile

  const addressIsMatch = address && actualAddress && address.raw && address.raw === actualAddress.raw

  const initialAddress = {
    country: "",
    region: "",
    area: "",
    locality: "",
    street: "",
    house: "",
    housing: "",
    structure: "",
    flat: "",
  }

  return {
    lastName: profile.lastName || "",
    firstName: profile.firstName || "",
    middleName: profile.middleName || "",
    phone: profile.phone || "",
    email: email || "",
    inn: profile.inn || "",

    ogrn: profile.ogrn || "",
    publicEmail: borrower.publicEmail || "",

    mainAddress: address || initialAddress,
    otherAddress: actualAddress || initialAddress,

    iian: profile.iian || "",
    addressMatch: !!addressIsMatch,
    passport: passportToState(passport),
    birthDate: profile.birthDate,
    birthPlace: profile.birthPlace || "",

    isRussiaTaxResident: Boolean(profile.isRussiaTaxResident),

    sex: profile.sex || "MALE",
  }
}
