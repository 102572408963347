import styled from "styled-components"
import { ArrowA, ArrowB, Document } from "./icons"

export const Container = styled.div`
  font-family: "Geometria";
  color: #4a4a4a;
  position: relative;
  padding-top: 87px;
  @media (max-width: 800px) {
    padding-top: 54px;
  }
  a {
    color: #00679c;
    cursor: pointer;
    text-decoration: none;
  }
`

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 19px;
    line-height: 24px;
    font-weight: 700;
    display: inline-block;
  }

  span {
    display: none;
    line-height: 24px;

    @media (max-width: 480px) {
      display: block;
    }
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 60px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`

export const Row = styled.li`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 60%;
  margin: 22px 0;

  span {
    width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    a {
      color: #4a4a4a;
      border-bottom: 0.5px solid #4a4a4a;
      margin-left: 13px;
    }
  }

  a span {
    @media (max-width: 480px) {
      display: none;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const ArrowIcon = styled(ArrowA)`
  margin: 0 0 -1px 8px;

  @media (max-width: 480px) {
    display: none;
  }
`

export const ArrowWithBoardersIcon = styled(ArrowB)`
  display: none;
  cursor: pointer;

  @media (max-width: 480px) {
    display: block;
  }
`

export const DocumentIcon = styled(Document)`
  margin-bottom: -5px;
`
