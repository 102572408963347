import React from "react"
import i18n from "i18next"

import { normalizePhone } from "src/utils"
import { ConfirmPhone } from "src/mutations"
import { ErrorsContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"
import { userAgreementUrl, personalDataAgreementUrl } from "src/constants"

import {
  Box,
  Text,
  Heading,
  FlexBox,
  CheckBox,
  CountDown,
  TextField,
  Translate,
  CloseIcon,
  ErrorForField,
  FormLoadingButton,
  ResendRequestButton,
} from "src/components"

import {
  Controls,
  ModalBody,
  LinkDocument,
  IconContainer,
  ModalContainer,
} from "./styles"

class PhoneConfirmModal extends React.Component<any, any> {
  constructor(props) {
    super(props)

    const components = (i18n as any).getResource(i18n.language, "components")

    if (
      components
      && components.individual_account
      && components.individual_account.update
      && components.individual_account.update.phone_confirm
    ) {
      (this as any).termsLink = components.individual_account.update.phone_confirm.terms_link;
      (this as any).personalLink = components.individual_account.update.phone_confirm.personal_link
    } else {
      (this as any).termsLink = "components.individual_account.update.phone_confirm.terms_link";
      (this as any).personalLink = "components.individual_account.update.phone_confirm.personal_link"
    }
  }

  state = {
    token: "",
    busy: false,
    isLoading: false,
    personalDataAcceptance: false,
    termsOfSeviceAcceptance: false,
  }

  get acceptedRules() {
    return (
      this.state.personalDataAcceptance && this.state.termsOfSeviceAcceptance
    )
  }

  onChange = (event) => {
    const { name, value } = event.target

    this.setState(() => ({ [name]: value }))
  }

  onCheckBoxChange = (event) => {
    const { name, checked } = event.target

    this.setState(() => ({ [name]: checked }))
  }

  commitRequest = () => {
    this.props.commitRequest()
    this.props.restartTimer()
  }

  onConfirmSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (this.state.busy) {
      return
    }

    this.setState(() => ({
      busy: true,
      isLoading: true,
    }))

    this.commit()
  }

  onError = (transaction) => {
    this.setState(() => ({
      busy: false,
      isLoading: false,
    }))
    this.props.setErrors(transaction)
  }

  onCompleted = (payload) => {
    this.setState(() => ({
      busy: false,
      isLoading: false,
    }))

    if (this.props.onCompleted) {
      this.props.onCompleted(payload)
    }

    this.props.applySignedPhone(payload.confirmPhone.signedPhone)
  }

  commit = () => {
    if (!this.acceptedRules) return

    const variables = {
      input: {
        phone: normalizePhone(this.props.phone),
        token: this.state.token,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    ConfirmPhone.commit(this.props.environment, variables, null, callbacks)
  }

  close = () => {
    this.props.close()
  }

  componentDidMount() {
    this.props.startTimer()
  }

  render() {
    const { phone } = this.props

    return (
      <ModalContainer>
        <ModalBody>
          <IconContainer onClick={this.close}>
            <CloseIcon />
          </IconContainer>
          <Box pb={24}>
            <Heading.h4>
              <Translate
                i18n="individual_account.update.phone_confirm.title"
                ns="components"
              />
            </Heading.h4>
          </Box>
          <Text whiteSpace={"pre-line"} color="greyDarkest">
            <Translate
              i18n="individual_account.update.phone_confirm.legend"
              ns="components"
              phone={phone}
            />
          </Text>
          <Box pt={16} pb={20}>
            <TextField
              type="string"
              name="token"
              value={this.state.token}
              onChange={this.onChange}
              error={ErrorForField(this.props.getError("confirmPhone.token"))}
              autoFocus
            />
          </Box>
          <FlexBox>
            <CheckBox
              name="personalDataAcceptance"
              checked={this.state.personalDataAcceptance}
              onChange={this.onCheckBoxChange}
            />
            <Text ml={10} fontSize={16}>
              <Translate
                i18n="individual_account.update.phone_confirm.personal_label"
                ns="components"
              />
              <LinkDocument href={personalDataAgreementUrl} target="_blank">
                <Translate
                  i18n="individual_account.update.phone_confirm.personal_label_highlighted"
                  ns="components"
                />
              </LinkDocument>
            </Text>
          </FlexBox>
          <FlexBox mt={10}>
            <CheckBox
              name="termsOfSeviceAcceptance"
              checked={this.state.termsOfSeviceAcceptance}
              onChange={this.onCheckBoxChange}
            />
            <Text fontSize={16} ml={10}>
              <Translate
                i18n="individual_account.update.phone_confirm.terms_label"
                ns="components"
              />
              <LinkDocument href={userAgreementUrl} target="_blank">
                <Translate
                  i18n="individual_account.update.phone_confirm.terms_label_highlighted"
                  ns="components"
                />
              </LinkDocument>
            </Text>
          </FlexBox>
          <Text mt={20} color="greyDarkest" fontSize="14px">
            <Translate
              i18n="individual_account.update.phone_confirm.terms_hint"
              ns="components"
            />
          </Text>
          <Controls>
            <FormLoadingButton
              onClick={this.onConfirmSubmit}
              isLoading={this.state.isLoading}
              fontSize="10px"
              default
              disabled={!(this.acceptedRules && this.state.token)}
            >
              <Translate
                i18n="individual_account.update.buttons.submit"
                ns="components"
              />
            </FormLoadingButton>
            <Box pl={16}>
              <ResendRequestButton
                secondsLeft={this.props.secondsLeft}
                onClick={this.commitRequest}
              />
            </Box>
          </Controls>
        </ModalBody>
      </ModalContainer>
    )
  }
}

const WithCountDown = (props) => (
  <CountDown>
    {({ start, restart, secondsLeft }) => (
      <PhoneConfirmModal
        {...props}
        startTimer={start}
        restartTimer={restart}
        secondsLeft={secondsLeft}
      />
    )}
  </CountDown>
)

const render = (props) => (
  <ErrorsContainer>
    {(errors) => (
      <RelayEnvironmentConsumer>
        {({ environment }) => (
          <WithCountDown {...props} {...errors} environment={environment} />
        )}
      </RelayEnvironmentConsumer>
    )}
  </ErrorsContainer>
)

export default render
