import styled from "styled-components"

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Modal = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding: 20px;
`

export const Container = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  max-width: 440px;
  width: 100%;
  height: auto;
  background: #fcfcfc;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 30px 40px 40px;

  @media screen and (max-width: 480px) {
    padding: 30px 16px;
  }
`

export const Title = styled.h5`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
`

export const Content = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  @media screen and (max-width: 440px) {
    flex-direction: column;
  }
`

export const Item = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const DefaultButton = styled.button`
  background-color: ${({ theme }) => theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  max-width: 140px;
  color: white;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  padding: 12px 0 11px;
  margin-top: 10px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const Button = styled(DefaultButton)`
  @media screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 14px;
    max-width: 180px;
    padding: 19px 0 17px;
  }
`

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

export const CompactButton = styled(DefaultButton)`
  max-width: 110px;

  :first-child {
    margin-right: 14px;
  }
`

export const ReturnButton = styled.button`
  position: absolute;
  left: 20px;
  top: 26px;
  margin: 0;
  padding: 0;
`
