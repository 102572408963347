import React from "react"
import { useHistory } from "react-router"

import { number, getProfileName, getProfileDetailedBalance } from "src/utils"

import {
  Box, Text, Translate, Button,
} from "src/components"

import {
  SubHeader,
  StyledLink,
  BankDataTitleContainer,
  WithdrawProfileContainer,
  StyledTooltip,
  WithdrawTitle,
  BankDataItem,
} from "./styles"

import { useBankRequisitesSchema } from "./hooks"

export const ModalHeader = (props) => (
  <>
    <WithdrawTitle>
      <Translate {...props} />
    </WithdrawTitle>
  </>
)

export const BankDataRow = ({ name, value }) => (
  <BankDataItem>
    <BankDataTitleContainer>
      <Text>
        <Translate i18n={name} />
      </Text>
    </BankDataTitleContainer>
    <Text fontSize="16px" color="primaryBlack" textTransform="uppercase">
      {value}
    </Text>
  </BankDataItem>
)

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const ProfileInfo = ({ profile }) => {
  const detailedBalances = getProfileDetailedBalance(profile)
  const balance = detailedBalances.find((item) => item.currency === "RUB")

  return (
    <WithdrawProfileContainer>
      <Text color="greyShuttle" lineHeight="1.5" fontSize="0.875rem">
        {getProfileName(profile)}
      </Text>
      <Text fontSize="0.75rem" lineHeight="1.5" color="greyDark">
        {number(balance.available, options)} ₽
      </Text>
    </WithdrawProfileContainer>
  )
}

export const ProfileRequisites = ({ profile }) => {
  const schema = useBankRequisitesSchema(profile)

  return (
    <>
      <Box mb="25px">
        <SubHeader>
          <Translate i18n="components:account.operations.withdraw.amount.to_bank_account" />
        </SubHeader>
      </Box>
      <Box>
        <Text color="primaryBlack" lineHeight="1.5">
          {getProfileName(profile)}
        </Text>
      </Box>
      {schema.map((item, idx) => (
        <BankDataRow key={idx} {...item} />
      ))}
    </>
  )
}

export const CurrencyTooltip = React.memo(() => <Text fontSize="20px">₽</Text>)

export const PassportWarning = React.memo(({ profileId }: any) => (
  <Text fontSize="14px" color="red" lineHeight="1.5">
    <Translate i18n="components:account.operations.withdraw.amount.passport_warning.start" />
    &nbsp;
    <StyledLink to={`/accounts/${profileId}/accreditation`}>
      <Translate i18n="components:account.operations.withdraw.amount.passport_warning.link" />
    </StyledLink>
    &nbsp;
    <Translate i18n="components:account.operations.withdraw.amount.passport_warning.end" />
  </Text>
))

export function Tooltip(props) {
  const {
    isAccreditationApproved, isDisabled, isAvailableBalance, changeTab, id,
  } = props
  const history = useHistory()

  function handleClick() {
    if (!!changeTab && typeof changeTab === "function") {
      changeTab("bank")
    } else {
      history.push("/accounts")
    }
  }

  return (
    <StyledTooltip
      effect="solid"
      delayHide={500}
      disable={!isAccreditationApproved || !isDisabled}
      id={id}
    >
      {isAvailableBalance ? (<>
        <p>Подтвердите реквизиты</p>
        <Button
          variant="outlinedWhite"
          fontSize="0.625rem"
          height="30px"
          width="110px"
          onClick={handleClick}
        >
          Подтвердить
        </Button>
      </>) : (
        <p>Недостаточно средств для вывода</p>
      )}
    </StyledTooltip>
  )
}
