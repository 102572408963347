import React from "react"

import { Link, Translate } from "src/components"

import {
  FlexContainer,
  TextContainer,
  Title,
  SuccessSubTitle,
  SuccessDescription,
  RedButton,
  ImageContainer,
  Image,
} from "./styles"

function Success(props) {
  const { amount, orderNumber } = props
  return (
    <FlexContainer>
      <ImageContainer>
        <Image />
      </ImageContainer>

      <TextContainer>
        <Title>
          <span>
            <Translate i18n="components:investment_modals.success.title" />
          </span>
        </Title>

        <SuccessSubTitle>
          <Translate
            i18n="components:investment_modals.success.subtitle"
            orderNumber={orderNumber}
            amount={amount}
          />{" "}
          <Translate i18n="components:investment_modals.success.my_investments.prefix" />{" "}
          <Translate i18n="components:investment_modals.success.my_investments.link" />
          <Translate i18n="components:investment_modals.success.my_investments.suffix" />
        </SuccessSubTitle>

        <SuccessDescription>
          <Translate i18n="components:investment_modals.success.info" />
        </SuccessDescription>

        <Link to="/market">
          <RedButton>
            <Translate i18n="components:investment_modals.success.market" />
          </RedButton>
        </Link>
      </TextContainer>
    </FlexContainer>
  )
}

export default Success
