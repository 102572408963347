import React from "react"

import { ViewerDataConsumer } from "src/context"

import getSchema from "./utils"
import { Table } from "./styles"
import { Row, Header } from "./elements"

const PaymentTable = (props) => {
  const {
    type, items, duration, currency,
  } = props

  const schema = getSchema(items, type, duration, currency)

  return (
    <Table>
      <thead>
        <Header schema={schema.header} />
      </thead>
      <tbody>
        {schema.body.map((item, idx) => (
          <Row key={idx} schema={item} />
        ))}
      </tbody>
    </Table>
  )
}

export default (props) => (
  <ViewerDataConsumer>
    {({ currency }) => <PaymentTable currency={currency} {...props} />}
  </ViewerDataConsumer>
)
