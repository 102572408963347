import React from "react"
import styled, { css } from "styled-components"
import { useMediaPredicate } from "react-media-hook"

export const InnerMobileButtonContainer = styled.div<any>`
  position: relative;
  height: auto;
  border-radius: 6px 0 6px;
  background: #fff;
  justify-content: center;
  padding-right: 24px;
  
  ${({ isShipped }) => isShipped
    && css`
      background: #f9f9f9;
      width: 100%;
      padding: 18px 24px;
      margin-bottom: 8px;
    `};
  display: flex;
`

export const InnerButtonContainer = styled.div<any>`
  position: relative;
  max-width: 310px;
  width: 100%;
  height: 100%;
  border-radius: 0 6px 6px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  ${({ isShipped }) => isShipped
    && css`
      background: #f9f9f9;
    `};

  @media screen and (max-width: 800px) {
    max-width: 185px;
    padding: 0 16px;
  }
`

export const MobileButtonContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 690px)")

  return mobile && <InnerMobileButtonContainer {...props}/>
}

export const ButtonContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 690px)")

  return !mobile && <InnerButtonContainer {...props}/>
}
