import { fetchQuery } from "relay-runtime"
import { graphql } from "react-relay"

import { SetViewerCurrency } from "src/mutations"

const currencyQuery = graphql`
  query currencyUtilsQuery {
    viewer {
      currency
    }
  }
`

const fetchViewerCurrency = (environment) => fetchQuery(environment, currencyQuery, {})

const handleResponse = ({ viewer }) => viewer

const requestCurrencyUpdate = (environment, currency) => new Promise((res, rej) => {
  const input = {
    currency: currency.toUpperCase(),
  }

  SetViewerCurrency.commit(environment, { input }, null, {
    onError: rej,
    onCompleted: res,
  })
})

const processResponse = ({ currency }) => currency.toLowerCase()

const fetchCurrency = (environment) => fetchViewerCurrency(environment)
  .then(handleResponse)
  .then(processResponse)
  .catch(() => "rub")

const setViewerCurrency = (currency, environment) => requestCurrencyUpdate(environment, currency).catch(() => {})

const currencyFromProps = ({ viewer }) => {
  const { currency } = viewer

  return currency || "rub"
}

export { fetchCurrency, currencyFromProps, setViewerCurrency }
