import React from "react"
import styled from "styled-components"

import { Box, Loader } from "src/components"

import { ItemContainer } from "../../entities/styles"

const StyledBox = styled(Box)`
  margin: auto;
`

const StyledItemContainer = styled(ItemContainer)<any>`
  height: 260px;
  display: flex;
`

export default ({ status, fetchError }) => (fetchError ? null : (
    <StyledItemContainer status={status}>
      <StyledBox>
        <Loader fontSize="4px" />
      </StyledBox>
    </StyledItemContainer>
))
