import React, { useState } from "react"
import Relay, { graphql } from "react-relay"
import onClickOutside from "react-onclickoutside"

import {
  Container,
  Inner,
  Header,
  ProfileContainer,
  ProfileTitle,
  ProfileEmail,
  Divider,
  Menu,
  MenuButton,
  KeyIcon,
  MailIcon,
  ExitIcon,
  ProfileIcon,
} from "./styles"

function AccountMenu(props: any) {
  const {
    viewer = {}, logout, openPasswordModal, toChangeEmail,
  } = props
  const { email } = viewer

  const [isOpened, setOpened] = useState(false)

  function handleOpen() {
    setOpened(true)
  }

  function handleClose() {
    setOpened(false)
  }

  (AccountMenu as any).handleClickOutside = handleClose

  return (
    <Container>
      <Inner active={isOpened}>
        <Header>
          {isOpened && (
            <ProfileContainer>
              <ProfileTitle>Аккаунт</ProfileTitle>
              <ProfileEmail>{email}</ProfileEmail>
            </ProfileContainer>
          )}

          <ProfileIcon onClick={handleOpen} />
        </Header>

        {isOpened && (
          <>
            <Divider />
            <Menu>
              <MenuButton onClick={toChangeEmail}>
                <MailIcon />
                Сменить E-mail
              </MenuButton>
              <MenuButton onClick={openPasswordModal}>
                <KeyIcon />
                Сменить пароль
              </MenuButton>
              <MenuButton onClick={logout}>
                <ExitIcon />
                Выйти
              </MenuButton>
            </Menu>

            {/* TODO: Позже вернуть или удалить окончательно */}
            {/* {isTrusted && ( */}
            {/*  <> */}
            {/*    <Divider /> */}
            {/*    <AccountsContainer> */}
            {/*      <TrustedAccountLink to="/trusted/list"> */}
            {/*        Доверенные аккаунты */}
            {/*      </TrustedAccountLink> */}
            {/*    </AccountsContainer> */}
            {/*  </> */}
            {/* )} */}
          </>
        )}
      </Inner>
    </Container>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => (AccountMenu as any).handleClickOutside,
}

const FragmentContainer = Relay.createFragmentContainer(
  onClickOutside(AccountMenu, clickOutsideConfig),
  {
    viewer: graphql`
      fragment AccountMenu_viewer on User {
        email
        trust {
          isAvailable
        }
      }
    `,
  },
)

export default (props) => <FragmentContainer {...props} />
