import React from "react"

import {
  Box, Label, FormRow, Tooltip, Translate,
} from "src/components"
import { getDepsState } from "src/utils"

const LabelField = ({ title, children }: any) => {
  if (title === "empty") {
    return <Box width="260px" />
  }
  return (
    <Label whiteSpace="pre-line">
      <Translate i18n={`${title}`} ns="components" />
      {children}
    </Label>
  )
}

const Row = (props) => {
  const {
    deps,
    value,
    label,
    width,
    tooltip,
    subLabel,
    children,
    emptyLabel,
  } = props
  const depsState = getDepsState(deps, value)

  return (
    <FormRow {...depsState}>
      <Box width={emptyLabel ? "260px" : "auto"}>
        {label && (
          <React.Fragment>
            <LabelField title={label}>
              <Tooltip text={tooltip} />
            </LabelField>
          </React.Fragment>
        )}
        {subLabel && <LabelField title={subLabel} />}
      </Box>
      <Box maxWidth={width || "100%"} width="100%">
        {children}
      </Box>
    </FormRow>
  )
}

export default Row
