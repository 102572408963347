/* eslint-disable max-len */

import React from "react"

function Arrow(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L7.99997 12L14 18L15.41 16.59Z" />
    </svg>
  )
}

Arrow.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Arrow
