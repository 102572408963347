/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LocaleEnum = "EN" | "KO" | "RU" | "ZH" | "%future added value";
export type localeUtilsQueryVariables = {};
export type localeUtilsQueryResponse = {
    readonly viewer: {
        readonly locale: LocaleEnum;
    } | null;
};
export type localeUtilsQuery = {
    readonly response: localeUtilsQueryResponse;
    readonly variables: localeUtilsQueryVariables;
};



/*
query localeUtilsQuery {
  viewer {
    locale
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "localeUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "localeUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "localeUtilsQuery",
    "operationKind": "query",
    "text": "query localeUtilsQuery {\n  viewer {\n    locale\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b53b695b69dcd00489ea4cf7c3d2700d';
export default node;
