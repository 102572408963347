import React from "react"
import Relay, { graphql } from "react-relay"

import { groupBy } from "src/utils"
import {
  Box, Text, Document, Translate,
} from "src/components"

import { formatDocuments } from "../utils"

const Documents = ({ docs }) => {
  const formattedDocs = formatDocuments(docs)

  return (
    <>
      {formattedDocs.map((doc, idx) => (
        <Box key={idx} mb="15px">
          <Document doc={doc} />
        </Box>
      ))}
    </>
  )
}

const Title = (props) => (
  <Text fontWeight="500" lineHeight="1.5">
    <Translate {...props} />
  </Text>
)

const FinancialStatements = ({ order }) => {
  const { financialStatements } = order
  const groups = groupBy(financialStatements, "year")
  const years = Object.keys(groups).reverse()

  if (!years.length) return null

  return (
    <>
      {years.map((year) => (
        <Box key={year} mb="25px">
          <Box mb="15px">
            <Title
              year={year}
              i18n="components:account.order_item.company_profile.year"
            />
          </Box>
          <Documents docs={groups[year]} />
        </Box>
      ))}
    </>
  )
}

export default Relay.createFragmentContainer(FinancialStatements, {
  order: graphql`
    fragment FinancialStatements_order on Order {
      financialStatements {
        name
        year
        attachment {
          url
          filename
        }
      }
    }
  `,
})
