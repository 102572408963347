export const LOAN_PRODUCT_TYPE = [
  {
    label: "legal_entity_offer_form.switch_values.loan_product_type.secured",
    value: "secured",
  },
]

export const EXTRADITION = [
  {
    label: "legal_entity_offer_form.switch_values.timetable.once",
    value: "once",
  },
]

export const REPAYMENT_SCHEDULE = [
  {
    label: "legal_entity_offer_form.switch_values.repayment_schedule.end",
    value: "end",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.repayment_schedule.equal_share",
    value: "equal_share",
  },
]

export const PAYMENT_DATE = [
  {
    label: "legal_entity_offer_form.switch_values.payment_date.certain_day",
    value: "certain_day",
  },
  {
    label: "legal_entity_offer_form.switch_values.payment_date.last_day",
    value: "last_day",
  },
  {
    label: "legal_entity_offer_form.switch_values.payment_date.equal",
    value: "equal",
  },
]

export const SEQUENCE_OF_REDEMPTION_IN_END = [
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_in_end.basic",
    value: "basic",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_in_end.rest",
    value: "rest",
  },
]

export const SEQUENCE_OF_REDEMPTION_REST = [
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_rest.basic",
    value: "basic",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_rest.rest",
    value: "rest",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_rest.percents_with_basic",
    value: "percents_with_basic",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.sequence_of_redemption_rest.percents_with_rest",
    value: "percents_with_rest",
  },
]

export const TYPE_OF_PROVISION = [
  {
    label: "legal_entity_offer_form.switch_values.type_of_provision.flat",
    value: "flat",
  },
  {
    label:
      "legal_entity_offer_form.switch_values.type_of_provision.individual_beneficiary",
    value: "individual_beneficiary",
  },
  {
    label: "legal_entity_offer_form.switch_values.type_of_provision.transport",
    value: "transport",
  },
]

export const ADDITIONAL_SERVICES = [
  {
    label: "legal_entity_offer_form.switch_values.addition_services.insurance",
    value: "insurance",
  },
  {
    label: "legal_entity_offer_form.switch_values.addition_services.evaluation",
    value: "evaluation",
  },
  {
    label: "legal_entity_offer_form.switch_values.addition_services.rest",
    value: "rest",
  },
]

export const MONTHS = [
  {
    label: "legal_entity_offer_form.switch_values.months.jan",
    value: "jan",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.feb",
    value: "feb",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.mar",
    value: "mar",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.apr",
    value: "apr",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.apr",
    value: "apr",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.may",
    value: "may",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.jun",
    value: "jun",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.jul",
    value: "jul",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.aug",
    value: "aug",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.sep",
    value: "sep",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.oct",
    value: "oct",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.nov",
    value: "nov",
  },
  {
    label: "legal_entity_offer_form.switch_values.months.dec",
    value: "dec",
  },
]

export const PLEDGOR_TYPE = [
  {
    label: "legal_entity_offer_form.switch_values.pledgor_type.borrower",
    value: "borrower",
  },
  {
    label: "legal_entity_offer_form.switch_values.pledgor_type.other",
    value: "other",
  },
]

export const FLAT_VALUE = [
  {
    label: "legal_entity_offer_form.switch_values.flat_value.cadastral",
    value: "cadastral",
  },
  {
    label: "legal_entity_offer_form.switch_values.flat_value.personal",
    value: "personal",
  },
]

export const LENDING_PURPOSE_VALUE = [
  {
    label: "application.worksheet.rows.lending_purpose.options.new_car",
    value: "new_car",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.used_car",
    value: "used_car",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.boat",
    value: "boat",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.motorcycle",
    value: "motorcycle",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.other_vehicle",
    value: "other_vehicle",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.furniture",
    value: "furniture",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.home_repairs",
    value: "home_repairs",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.appliances",
    value: "appliances",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.clothing",
    value: "clothing",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.travels",
    value: "travels",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.earth",
    value: "earth",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.house",
    value: "house",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.debt_repayment",
    value: "debt_repayment",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.wedding",
    value: "wedding",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.education",
    value: "education",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.computer_technology",
    value: "computer_technology",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.services",
    value: "services",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.cooperative_payments,_rent,_deposit",
    value: "cooperative_payments",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.investments",
    value: "investments",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.health_or_treatment_costs",
    value: "health_or_treatment_costs",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.hobby",
    value: "hobby",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.commercial,_business",
    value: "commercial",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.telecommunication_equipment",
    value: "telecommunication_equipment",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.mobile_phone",
    value: "mobile_phone",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.plumbing",
    value: "plumbing",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.electrification",
    value: "electrification",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.gasification",
    value: "gasification",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.fuel_supply",
    value: "fuel_supply",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.working_capital",
    value: "working_capital",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.fixed_capital_investments",
    value: "fixed_capital_investments",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.agricultural_loan",
    value: "agricultural_loan",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.mortgage",
    value: "mortgage",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.loan_for_construction",
    value: "loan_for_construction",
  },
  {
    label: "application.worksheet.rows.lending_purpose.options.hotel_loan",
    value: "hotel_loan",
  },
]
