import colors from "./colors"
import buttons from "./buttons"

const paddings = [20, 30, 50]
// const breakpoints = [0, 768, 1024]
const breakpoints = [481, 801]
const fontSizes = [13, 16, 18, 20, 24, 30, 35, 40, 55]
const radii = { normal: 5 }

const devices = {
  phone: `@media(max-width: ${breakpoints[1] - 1}px)`,
  tablet: `@media(max-width: ${breakpoints[2] - 1}px)`,
}

export default {
  radii,
  colors,
  devices,
  paddings,
  fontSizes,
  breakpoints,
  buttons,
}
