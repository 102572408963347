/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type PreScheduleQueryVariables = {
    orderId: string;
    amount: number;
};
export type PreScheduleQueryResponse = {
    readonly preSchedule: ReadonlyArray<{
        readonly date: string;
        readonly loan: number;
        readonly state: string | null;
        readonly total: number;
        readonly total_gross: number;
        readonly interest: number;
        readonly interest_fee: number;
        readonly personal_tax: number;
        readonly loan_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly total_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly total_gross_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly interest_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly interest_fee_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly personal_tax_details: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
        readonly exchangeRates: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly amount: number;
        }>;
    } | null>;
};
export type PreScheduleQuery = {
    readonly response: PreScheduleQueryResponse;
    readonly variables: PreScheduleQueryVariables;
};



/*
query PreScheduleQuery(
  $orderId: ID!
  $amount: Float!
) {
  preSchedule(orderId: $orderId, amount: $amount) {
    date
    loan
    state
    total
    total_gross
    interest
    interest_fee
    personal_tax
    loan_details {
      currency
      amount
    }
    total_details {
      currency
      amount
    }
    total_gross_details {
      currency
      amount
    }
    interest_details {
      currency
      amount
    }
    interest_fee_details {
      currency
      amount
    }
    personal_tax_details {
      currency
      amount
    }
    exchangeRates {
      currency
      amount
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "amount",
    "type": "Float!"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "concreteType": "PaymentScheduleItem",
    "kind": "LinkedField",
    "name": "preSchedule",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total_gross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interest_fee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personal_tax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "loan_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "total_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "total_gross_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "interest_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "interest_fee_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "personal_tax_details",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AmountWithCurrency",
        "kind": "LinkedField",
        "name": "exchangeRates",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreScheduleQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreScheduleQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PreScheduleQuery",
    "operationKind": "query",
    "text": "query PreScheduleQuery(\n  $orderId: ID!\n  $amount: Float!\n) {\n  preSchedule(orderId: $orderId, amount: $amount) {\n    date\n    loan\n    state\n    total\n    total_gross\n    interest\n    interest_fee\n    personal_tax\n    loan_details {\n      currency\n      amount\n    }\n    total_details {\n      currency\n      amount\n    }\n    total_gross_details {\n      currency\n      amount\n    }\n    interest_details {\n      currency\n      amount\n    }\n    interest_fee_details {\n      currency\n      amount\n    }\n    personal_tax_details {\n      currency\n      amount\n    }\n    exchangeRates {\n      currency\n      amount\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd4659484ae0eec024f64fce1fc0c4527';
export default node;
