import styled from "styled-components"
import { alignItems } from "styled-system"

export const AccountItem = styled.div`
  min-width: 116px;
  padding-right: 50px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const HeaderContainer = styled.div<any>`
  position: relative;
  width: 100%;
  padding: 26px 50px;
  background-color: ${({ theme }) => theme.colors.coffee};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${alignItems}
`
