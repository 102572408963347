/* eslint-disable no-param-reassign */
import React from "react"

import { Box } from "src/components"

import { Input, Error } from "./style"

const onBlur = (event) => {
  event.target.readOnly = true
}

const onFocus = (event) => {
  event.target.readOnly = false
}

const preventAutocompleteProps = {
  onBlur,
  onFocus,
  readOnly: true,
}

const render = (props) => {
  if (props.autoComplete === "off") {
    props = { ...props, ...preventAutocompleteProps }
  }

  return (
    <Box>
      <Input {...props} />
      {props.error && <Error>{props.error}</Error>}
    </Box>
  )
}

export default render
