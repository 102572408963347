import React, { useState } from "react"

import {
  ProposalMenus,
  ProposalPaymentsGraph,
  ProposalRisks,
  ProposalExpertOpinion,
  ProposalDocuments,
  ProposalDescription,
  ProposalProvision,
} from "src/components"

import { TRANSFER, CONFIRMED } from "../ProposalHeader/constants"

import {
  DESCRIPTION,
  DOCUMENTS,
  EXPERTS,
  RISKS,
  PROVISION,
  PAYMENTS,
} from "./constants"

function getMenuItems(data) {
  if (!data) return []

  const {
    otherDocuments,
    financialStatements,
    paymentScheduleList,
    expertOpinions,
    provision,
    document,
    status,
    guarantors,
  } = data

  return [
    {
      title: "описание",
      value: DESCRIPTION,
      isDisabled: false,
    },
    {
      title: "документы",
      value: DOCUMENTS,
      isDisabled:
        !guarantors.length
        && otherDocuments.length < 1
        && financialStatements.length < 1
        && !document,
    },
    {
      title: "график платежей",
      value: PAYMENTS,
      isHidden: status === CONFIRMED,
      isDisabled: paymentScheduleList.length < 1,
    },
    {
      title: "заключение экспертов",
      value: EXPERTS,
      isDisabled: expertOpinions.length < 1,
    },
    {
      title: "оценка рисков",
      value: RISKS,
      isDisabled: false,
    },
    {
      title: "обеспечение",
      value: PROVISION,
      isDisabled: !provision || provision.items.length < 1,
    },
  ]
}

export default function ProposalBody(props) {
  const {
    getQueryParam, data, isOwner, isInvestor,
  } = props
  const { status, provision } = data
  const [activeTab, setActiveTab] = useState(
    getQueryParam("step") || DESCRIPTION,
  )

  function onStepChange(step) {
    if (step === activeTab) return

    const query = new URLSearchParams(window.location.search)
    query.set("step", step)
    const params = query.toString()

    window.history.pushState("", "", `?${params}`)
    setActiveTab(step)
  }

  return (
    <>
      <ProposalMenus
        menuItems={getMenuItems(data)}
        activeTab={activeTab}
        setActiveTab={onStepChange}
        data={data}
      />
      {activeTab === DOCUMENTS && (
        <ProposalDocuments
          data={data}
          isInvestor={isInvestor}
          isOwner={isOwner}
        />
      )}
      {activeTab === PAYMENTS
        && status !== TRANSFER
        && status !== CONFIRMED && (
          <ProposalPaymentsGraph data={data} isOwner={isOwner} />
      )}
      {activeTab === RISKS && <ProposalRisks />}
      {activeTab === PROVISION && <ProposalProvision provision={provision} />}
      {activeTab === DESCRIPTION && (
        <ProposalDescription
          data={data}
          isInvestor={isInvestor}
          isOwner={isOwner}
        />
      )}
      {activeTab === EXPERTS && <ProposalExpertOpinion data={data} />}
    </>
  )
}
