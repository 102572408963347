/* eslint-disable max-len */

import React from "react"

function FormatItalic(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z" />
    </svg>
  )
}

FormatItalic.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default FormatItalic
