import React from "react"
import { LocaleConsumer } from "src/context"

const createRender = ({ render, children }) => {
  if (typeof children === "function") return children

  if (typeof render === "function") return render

  return (translation) => translation
}

const renderTranslation = ({ render, i18n, options }) => ({ translate }) => render(translate(i18n, options))

const translateRender = (props) => {
  const {
    i18n, render, children, ...options
  } = props

  const processedRender = createRender({ render, children })

  return (
    <LocaleConsumer>
      {renderTranslation({ render: processedRender, i18n, options })}
    </LocaleConsumer>
  )
}

export default translateRender
