import React, {
  useEffect, createRef, useState, useCallback,
} from "react"
import ReactDOM from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import {
  Box,
  Icons,
  CountDown,
  Button,
  FormLoadingButton,
  Translate,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"
import { MutationContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import { RequestProfilePhoneConfirmation } from "src/mutations"
import { PortfolioAutoInvestQueryResponse }
  from "src/components/organisms/PortfolioAutoInvest/__generated__/PortfolioAutoInvestQuery.graphql"

import {
  Modal,
  Container,
  CloseButton,
  ReturnButton,
  Title,
  ControlsContainer,
  StyledText,
} from "./styles"

type ConfirmationType = ConfirmationMainType & {
  clearError: (value: string) => void;
  clearTimer: () => void;
  commit: (ref: any) => void;
  environment: any;
  getError: (value: string) => {};
  restartTimer: (value?: boolean) => void;
  secondsLeft: number;
  startTimer: () => void;
}

type RateType = {
  rating: string;
  minRate: number;
}

type ConfirmationMainType = {
  isOpened: boolean;
  mutation: any;
  content?: () => void;
  tags: any;
  data: any;
  order: any;
  attachments: any;
  mutationName: string;
  onClose: () => void;
  onComplete: (value?: any) => void;
  onError: (value?: any) => void;
  profile: PortfolioAutoInvestQueryResponse["node"];
  title: string;
  variables: {
    input: {
      borrowerLimit: number;
      cessionEnable: boolean;
      isActive: string;
      limit: number;
      minRates: RateType[];
      orderLimit: number;
      profileId: string;
    };
  };
};

function ConfirmMutationModal(props: ConfirmationType) {
  const {
    title,
    content,
    tags,
    data,
    order,
    profile,
    attachments,
    mutationName,
    onClose,
    onError,
    onComplete,

    commit,
    getError,
    clearError,
    startTimer,
    clearTimer,
    restartTimer,
    secondsLeft,
  } = props

  const [code, setCode] = useState("")
  const [busy, setBusy] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const modalRef = createRef<HTMLFormElement>()

  const handleClose = useCallback(() => {
    if (!busy) {
      onClose()
    }
  }, [busy])

  const onCodeRequestSuccess = useCallback(() => {
    setBusy(false)
    setCodeSent(true)
  }, [])

  const onCodeRequestError = useCallback(() => {
    clearTimer()
    setBusy(false)
  }, [clearTimer, setBusy])

  const onCodeRequest = useCallback(() => {
    setBusy(true)

    const variables = {
      input: {
        data,
        tags,
        attachments,
        orderId: order?.id,
        profileId: profile.id,
        kind: mutationName.replace(/([A-Z][a-z])/g, "_$1").toUpperCase(),
      },
    }

    const callbacks = {
      onError: onCodeRequestError,
      onCompleted: onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      props.environment,
      variables,
      null,
      callbacks,
    )
  }, [profile.id])

  const onCodeResend = useCallback(() => {
    onCodeRequest()
    restartTimer()
  }, [])

  const handleChange = useCallback((e) => {
    setCode(e.target.value)

    clearError(`${mutationName}.token`)
  }, [])

  const onCodeConfirmError = useCallback(
    (error) => {
      setBusy(false)

      if (!getError(`${mutationName}.token`)) {
        onError(error)
      }
    },
    [onError],
  )

  const onCodeConfirmSuccess = useCallback(
    (d) => {
      onComplete(d)
    },
    [onComplete],
  )

  const onCodeConfirm = useCallback((event) => {
    event.preventDefault()
    setBusy(true)

    const variables = {
      ...props.variables,
      input: {
        ...props.variables.input,
        token: code,
      },
    }

    const callbacks = {
      onError: onCodeConfirmError,
      onCompleted: onCodeConfirmSuccess,
    }

    commit({ callbacks, variables })
  }, [props.variables, code])

  useEffect(() => {
    const target = modalRef.current
    disableBodyScroll(target)
    onCodeRequest()
    startTimer()

    return () => enableBodyScroll(target)
  }, [])

  const error = props.getError(`${mutationName}.token`)

  return (
    <Modal onSubmit={onCodeConfirm} ref={modalRef} >
      <Container>
        <ReturnButton onClick={handleClose}>
          <Icons.LeftArrow />
          <span>Назад</span>
        </ReturnButton>

        <CloseButton onClick={handleClose}>
          <Icons.Close />
        </CloseButton>

        <Title>{title}</Title>

        <Box m="20px 0">
          <StyledText>
            {content || (
              <Translate
                i18n="components:account.operations.withdraw.confirmation.to_number"
                number={profile.phone}
              />
            )}
          </StyledText>
        </Box>

        <FormInput
          autoFocus
          name="phone_confirmation"
          value={code}
          error={error}
          onChange={handleChange}
          autoComplete="off"
          label="components:account.bank_account.confirm.label"
        />
        <ResendRequestButton nowrap secondsLeft={secondsLeft} />

        <ControlsContainer>
          <Box mr="20px">
            <Button
              variant="default"
              height="48px"
              disabled={!!secondsLeft || !codeSent}
              onClick={onCodeResend}
            >
              <Translate
                ns="components"
                i18n="account.bank_account.confirm.buttons.resend"
              />
            </Button>
          </Box>
          <FormLoadingButton
            variant="blueWide"
            height="48px"
            width="10em"
            disabled={!code}
            isLoading={busy}
            type="submit"
          >
            <Translate
              ns="components"
              i18n="account.bank_account.confirm.buttons.continue"
            />
          </FormLoadingButton>
        </ControlsContainer>
      </Container>
    </Modal>
  )
}

function Render(props: ConfirmationMainType) {
  return (
    <RelayEnvironmentConsumer>
      {({ environment }) => (
        <CountDown>
          {({
            start, clear, restart, secondsLeft,
          }) => (
            <MutationContainer mutation={props.mutation}>
              {({ commit, getError, clearError }) => (
                <ConfirmMutationModal
                  {...props}
                  commit={commit}
                  getError={getError}
                  clearError={clearError}
                  startTimer={start}
                  clearTimer={clear}
                  restartTimer={restart}
                  secondsLeft={secondsLeft}
                  environment={environment}
                />
              )}
            </MutationContainer>
          )}
        </CountDown>
      )}
    </RelayEnvironmentConsumer>
  )
}

export default function (props) {
  if (!props.isOpened) {
    return null
  }
  const root = window.document.getElementById("app-root")
  return ReactDOM.createPortal(Render(props), root)
}
