import React from "react"
import { useMutation } from "src/hooks"
import { CreateProfileTransactionHistoryReport } from "src/mutations"
import { MiniLoader } from "src/components"
import { DownloadIcon } from "./icons"
import { DownloadButton } from "./styles"

const SaveAsFileButton = ({ profile }) => {
  const mutation = useMutation(CreateProfileTransactionHistoryReport)

  mutation.onCompleted((data) => {
    window.open(data.createProfileTransactionHistoryReport.url, "_blank")
  })

  mutation.onError((data) => {
    // eslint-disable-next-line no-console
    console.error(data)
  })

  const handleClick = () => {
    const input = { profileId: profile.id }

    mutation.commit({ input })
  }

  return (
    <DownloadButton onClick={handleClick} disabled={mutation.busy}>
      {mutation.busy ? (
        <MiniLoader changed fontSize="5px" margin="10px auto" />
      ) : (
        <>
          <span>Скачать</span>
          <DownloadIcon />
        </>
      )}
    </DownloadButton>
  )
}

export default SaveAsFileButton
