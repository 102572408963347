import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateProfileTransactionHistoryReportMutation(
    $input: CreateProfileTransactionHistoryReportInput!
  ) {
    createProfileTransactionHistoryReport(input: $input) {
      url
    }
  }
`

export default enhance({ mutation })
