import { graphql } from "react-relay"

export default graphql`
  query countriesQuery($locale: LocaleEnum!) {
    countries(locale: $locale) {
      code
      name
    }
  }
`
