import React from "react"

export default () => (
  <React.Fragment>
    <rect x="11" y="7" width="2" height="2" />
    <rect x="11" y="11" width="2" height="6" />
    <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,
    2z M12,20c-4.41,0-8-3.59-8-8c0-4.41,3.59-8,8-8s8,3.59,8,8C20,16.41,16.41,20,12,20z" />
  </React.Fragment>
)
