/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GuestOrdersCount_orders = {
    readonly count: number;
    readonly " $refType": "GuestOrdersCount_orders";
};
export type GuestOrdersCount_orders$data = GuestOrdersCount_orders;
export type GuestOrdersCount_orders$key = {
    readonly " $data"?: GuestOrdersCount_orders$data;
    readonly " $fragmentRefs": FragmentRefs<"GuestOrdersCount_orders">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GuestOrdersCount_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "type": "OrderConnection"
};
(node as any).hash = '321c9b0fb61080f99bdfdcc899739aa6';
export default node;
