import React from "react"

import { Box, Text, Translate } from "src/components"

import Attachments from "../Attachments"
import { ErrorElement, ErrorContainer } from "../Shared"

const Nullable = () => null

const getError = (error) => {
  const InjectedError = error

  if (InjectedError) {
    return (
      <ErrorContainer>
        <ErrorElement>{InjectedError}</ErrorElement>
      </ErrorContainer>
    )
  }

  return <Nullable />
}

const ResidenceDocument = (props) => {
  if (!props.isRussiaTaxResident) {
    return null
  }

  const { error, label, ...rest } = props

  return (
    <React.Fragment>
      {label
        && (typeof label === "function" ? (
          label()
        ) : (
          <Box mb="12px">
            <Text lineHeight="1.75">
              <Translate i18n={label} />
            </Text>
          </Box>
        ))}
      <Attachments {...rest} />
      {getError(error)}
    </React.Fragment>
  )
}

export default ResidenceDocument
