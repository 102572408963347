import styled from "styled-components"

export const InfoContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`
export const InfoLogo = styled.div`
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
  }
`
export const InfoCompany = styled.div`
  display: block;
  cursor: pointer;
`

export const InfoCompanyName = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
`

export const InfoCompanyDescr = styled.div`
  font-size: 13px;
  line-height: 16px;
  min-width: 220px;
  padding-right: 10px;
  box-sizing: border-box;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  @media screen and (max-width: 360px) {
    min-width: 185px;
  }
`
export const InfoPlay = styled.div`
  min-width: 27px;
  cursor: pointer;
`
