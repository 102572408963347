import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation LoginWithEmailAndPasswordMutation(
    $input: LoginWithEmailAndPasswordInput!
  ) {
    loginWithEmailAndPassword(input: $input) {
      token
    }
  }
`

export default enhance({ mutation })
