import React from "react"
import styled from "styled-components"

const StrictBorderWrapper = styled.div`
  overflow: hidden;
`

const Mask = styled.div<any>`
  filter: blur(${({ size }) => size});
  user-select: none;
`

const Blur = (props) => {
  const { size, strict, children } = props

  const Wrapper = strict ? StrictBorderWrapper : React.Fragment

  return (
    <Wrapper>
      <Mask size={size}>{children}</Mask>
    </Wrapper>
  )
}

Blur.defaultProps = {
  size: "4px",
  strict: false,
}

export default Blur
