export default {
  groups: [
    {
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "text",
              name: "lastName",
              label: "components:individual_profile_form.placeholders.lastName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "firstName",
              label: "components:individual_profile_form.placeholders.firstName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "middleName",
              label: "components:individual_profile_form.placeholders.middleName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "phone",
              name: "phone",
              mask: "+7 (999) 999-99-99",
              label: "components:individual_profile_form.placeholders.phone",
            },
            {
              type: "switchSelect",
              name: "isEntrepreneur",
              margin: 0,
              leftLabel:
                "components:individual_profile_form.labels.profileType.individual",
              rightLabel:
                "components:individual_profile_form.labels.profileType.entrepreneur",
              leftSwitchLabel:
                "components:individual_profile_form.labels.profileTypeShort.entrepreneur",
              rightSwitchLabel:
                "components:individual_profile_form.labels.profileTypeShort.individual",
            },
          ],
        },
      ],
    },
  ],
}
