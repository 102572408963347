import React from "react"

import { number } from "src/utils"

const formatNumber = (str) => {
  if (!str) return ""

  const formatted = String(str).replace(/\D/g, "")

  return number(formatted, {}, "ru")
}

export const useInvestmentAmount = (balance) => {
  const [value, setValue] = React.useState("")
  const onChange = React.useCallback(
    (e) => {
      const formatted = formatNumber(e.target.value)
      const formattedAsNumber = Number(String(formatted).replace(/\D/g, ""))

      if (formattedAsNumber > balance) {
        const floorBalance = Math.floor(balance || 0)
        setValue(formatNumber(String(floorBalance)))
        return
      }

      setValue(formatted)
    },
    [balance],
  )

  return [value, onChange]
}

export const useOnCompleteCallbacks = (items, setActiveModal, hideModal) => React.useMemo(
  () => Object.keys(items).reduce((memo, key) => {
    const cb = () => (items[key].next ? setActiveModal(items[key].next) : hideModal())

    return { ...memo, [key]: cb }
  }, {}),
  [items],
)
