import React from "react"
import styled from "styled-components"

import {
  Box,
  Select,
  FlexBox,
  AddButton,
  Translate,
  DateInput,
  TextField,
  IconClose,
} from "src/components"
import { EXTRADITION, SCHEDULE_ITEM } from "src/constants"

const CloseIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  box-sizing: content-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`

const Devider = styled.div`
  width: 16px;
  height: 16px;
`

const DateRow = (props) => {
  const {
    onChange,
    onRemove,
    item: { date, value },
    index,
  } = props

  return (
    <FlexBox
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box pt={27}>
        <DateInput
          name="date"
          value={date}
          onChange={onChange}
          type="text"
          hint={
            <Translate
              i18n="legal_entity_offer_form.rows.timetable.row_hints.date"
              ns="components"
            />
          }
        />
      </Box>
      <Box pt={27}>
        <TextField
          type="text"
          name="value"
          value={value}
          onChange={onChange}
          hint={
            <Translate
              i18n="legal_entity_offer_form.rows.timetable.row_hints.value"
              ns="components"
            />
          }
        />
      </Box>
      {index > 0 ? (
        <CloseIconWrapper onClick={onRemove}>
          <IconClose />
        </CloseIconWrapper>
      ) : (
        <Devider />
      )}
    </FlexBox>
  )
}

class TimeTableInput extends React.PureComponent<any, any> {
  get schedule() {
    return this.props.value.schedule ? this.props.value.schedule : []
  }

  get type() {
    return this.props.value.type
  }

  onChange = ({ value }) => {
    const schedule = [...this.schedule]

    if (value !== "schedule") {
      this.forceChange({ type: value, schedule })
      return
    }

    schedule.push(SCHEDULE_ITEM)
    this.forceChange({ type: value, schedule })
  }

  onDateAdd = () => {
    const schedule = [...this.schedule]
    if (schedule.length > 4) {
      return
    }

    schedule.push({ ...SCHEDULE_ITEM })
    this.forceChange({ type: this.type, schedule })
  }

  onDateRemove = (index) => () => {
    const schedule = [...this.schedule]
    schedule.splice(index, 1)
    this.forceChange({ type: this.type, schedule })
  }

  onScheduleItemChange = (index) => (event) => {
    const { name, value } = event.target
    const scheduleItems = [...this.schedule]
    scheduleItems[index][name] = value

    this.forceChange({ type: this.type, schedule: scheduleItems })
  }

  forceChange = (value) => {
    const selectedValue = {
      name: this.props.name,
      value: { ...value },
    }

    this.props.onChange({ ...selectedValue })
  }

  render() {
    return (
      <Box>
        <Select
          value={this.props.value.type}
          onChange={this.onChange}
          options={EXTRADITION}
        />
        {this.props.value.type === "schedule" && (
          <React.Fragment>
            {this.props.value.schedule.map((item, index) => (
              <DateRow
                key={index}
                item={item}
                onRemove={this.onDateRemove(index)}
                onChange={this.onScheduleItemChange(index)}
                index={index}
              />
            ))}
            <Box pt={18}>
              <AddButton onClick={this.onDateAdd}>Добавить</AddButton>
            </Box>
          </React.Fragment>
        )}
      </Box>
    )
  }
}

export default TimeTableInput
