/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GatheredAmountSubscriptionInput = {
    orderId: string;
};
export type OrderTableRowForGatheredAmountSubscriptionVariables = {
    input: GatheredAmountSubscriptionInput;
};
export type OrderTableRowForGatheredAmountSubscriptionResponse = {
    readonly gatheredAmount: {
        readonly order: {
            readonly chain: {
                readonly gatheredAmount: number;
            } | null;
        };
    };
};
export type OrderTableRowForGatheredAmountSubscription = {
    readonly response: OrderTableRowForGatheredAmountSubscriptionResponse;
    readonly variables: OrderTableRowForGatheredAmountSubscriptionVariables;
};



/*
subscription OrderTableRowForGatheredAmountSubscription(
  $input: GatheredAmountSubscriptionInput!
) {
  gatheredAmount(input: $input) {
    order {
      chain {
        gatheredAmount
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GatheredAmountSubscriptionInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gatheredAmount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderTableRowForGatheredAmountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GatheredAmountSubscriptionPayload",
        "kind": "LinkedField",
        "name": "gatheredAmount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderTableRowForGatheredAmountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GatheredAmountSubscriptionPayload",
        "kind": "LinkedField",
        "name": "gatheredAmount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "OrderTableRowForGatheredAmountSubscription",
    "operationKind": "subscription",
    "text": "subscription OrderTableRowForGatheredAmountSubscription(\n  $input: GatheredAmountSubscriptionInput!\n) {\n  gatheredAmount(input: $input) {\n    order {\n      chain {\n        gatheredAmount\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b773c9e0625cd199d94e6b626203b41f';
export default node;
