import React from "react"
import PropTypes from "prop-types"

import { Translate } from "src/components"

import {
  Image,
  Header,
  Description,
  ImageContainer,
  ActionContainer,
  TemplateContainer,
  Appendix,
} from "./styles"

const Nullable = () => null

const renderHeader = (header) => (header ? (
    <Header>
      <Translate i18n={header} />
    </Header>
) : (
    <Nullable />
))

const renderImage = (src) => (src ? (
    <ImageContainer>
      <Image src={src} />
    </ImageContainer>
) : (
    <Nullable />
))

const renderDescription = (description) => (description ? (
    <Description>
      <Translate i18n={description} />
    </Description>
) : (
    <Nullable />
))

const renderAppendix = (appendix) => (appendix ? (
    <Appendix>
      <Translate i18n={appendix} />
    </Appendix>
) : (
    <Nullable />
))

const renderAction = (action) => (action ? <ActionContainer>{action}</ActionContainer> : <Nullable />)

const render = (props) => {
  const {
    image, action, header, appendix, description,
  } = props

  return (
    <TemplateContainer>
      {renderHeader(header)}
      {renderImage(image)}
      {renderDescription(description)}
      {renderAppendix(appendix)}
      {renderAction(action)}
    </TemplateContainer>
  )
}

render.propTypes = {
  header: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  appendix: PropTypes.string,
  action: PropTypes.object,
}

export default render
