/* eslint-disable max-len */

import React from "react"

function LeftArrow(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4099 16.59L10.8299 12L15.4099 7.41L13.9999 6L7.99991 12L13.9999 18L15.4099 16.59Z" />
    </svg>
  )
}

LeftArrow.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default LeftArrow
