import React, { useState } from "react"
import { withRouter } from "react-router"

import { OrderTypeProvider } from "src/context"

import { ItemContainerActive, Wrap } from "./styles"

export default (elements, orderType, viewType, isCession) => withRouter((props: any) => {
  const {
    Head,
    Info,
    FooterAction,
    Body,
    Table,
    Footer,
    Description,
    Container,
  } = elements

  const { status } = props.data

  const Wrapper = Container || ItemContainerActive

  const [handleOrder, setHandleOrder] = useState(true)

  const timeoutHandleOff = () => {
    setTimeout(() => {
      setHandleOrder(true)
    }, 1000)
  }
  const timeoutHandleOn = () => {
    setTimeout(() => {
      setHandleOrder(false)
    }, 300)
  }

  switch (viewType) {
    case "Card": {
      return (
          <OrderTypeProvider value={orderType}>
            <Wrapper status={status} onMouseLeave={timeoutHandleOff}>
              {handleOrder ? (
                <Wrap>
                  {Head && <Head {...props} handleOpen={timeoutHandleOn} />}
                  {Info && <Info {...props} />}
                  {Body && <Body {...props} />}
                </Wrap>
              ) : (
                <Description
                  {...props}
                  handleClose={() => setHandleOrder(true)}
                />
              )}
              {FooterAction && <FooterAction {...props} />}
              {Footer && <Footer {...props} />}
            </Wrapper>
          </OrderTypeProvider>
      )
    }

    case "MobileCard": {
      return (
          <OrderTypeProvider value={orderType}>
            <Wrapper status={status}>
              {Head && <Head {...props} isCession={isCession} />}
              {Info && <Info {...props} isCession={isCession} />}
              {Body && <Body {...props} isCession={isCession} />}
              {FooterAction && (
                <FooterAction {...props} isCession={isCession} />
              )}
              {Footer && <Footer {...props} isCession={isCession} />}
            </Wrapper>
          </OrderTypeProvider>
      )
    }

    default: {
      return (
          <Table
            data={props.data}
            handleOrder={handleOrder}
            setHandleOrder={setHandleOrder}
            isCession={isCession}
            history={props.history}
          />
      )
    }
  }
})
