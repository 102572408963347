import React from "react"
import idx from "idx"
import moment from "moment"
import i18next from "i18next"

import { Box, Blur, Translate } from "src/components"

const Duration = ({ order, orderType }) => {
  if (!idx(order, (_) => _.offer.data.duration)) return null

  const { language } = i18next
  const isGuestMarket = orderType === "GuestOrder"
  const Wrapper = isGuestMarket ? Blur : React.Fragment
  const isActiveCession = order.cession && order.cession.isActive

  let date

  if (isActiveCession) {
    const momentDate = moment(order.cession.due)
    date = momentDate.isValid()
      ? momentDate.locale(language).format("DD MMM YYYY")
      : null
  } else {
    date = isGuestMarket ? NaN : parseFloat(order.offer.data.duration)
  }

  return (
    <Box pt="11px">
      <Wrapper>
        {isActiveCession ? (
          date
        ) : (
          <Translate i18n="models:loan.durationWithCount" count={date} />
        )}
      </Wrapper>
    </Box>
  )
}

export default (props) => <Translate render={() => <Duration {...props} />} />
