/* eslint-disable max-len */

import React from "react"

function Calculator(props) {
  return (
    <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.6667 0H4.33334C3.04666 0 2 1.04666 2 2.33334V13.6667C2 14.9533 3.04666 16 4.33334 16H11.6667C12.9533 16 14 14.9533 14 13.6667V2.33334C14 1.04666 12.9533 0 11.6667 0ZM13.3333 13.6667C13.3333 14.5853 12.5853 15.3333 11.6667 15.3333H4.33334C3.41469 15.3333 2.66669 14.5853 2.66669 13.6667V2.33334C2.66669 1.41469 3.41469 0.666687 4.33334 0.666687H11.6667C12.5853 0.666687 13.3333 1.41469 13.3333 2.33334V13.6667Z" />
      <path d="M11.6666 1.33334H4.33331C3.78197 1.33334 3.33331 1.782 3.33331 2.33334V5.66669C3.33331 6.21803 3.78197 6.66669 4.33331 6.66669H11.6667C12.218 6.66669 12.6667 6.21803 12.6667 5.66669V2.33334C12.6666 1.782 12.218 1.33334 11.6666 1.33334ZM12 5.66666C12 5.85066 11.8506 6 11.6666 6H4.33331C4.14931 6 3.99997 5.85066 3.99997 5.66666V2.33334C3.99997 2.14934 4.14931 2 4.33331 2H11.6667C11.8506 2 12 2.14934 12 2.33334V5.66666Z" />
      <path d="M5.66666 7.33334C5.48266 7.33334 5.33331 7.48269 5.33331 7.66669V10.3333C5.33331 10.5173 5.48266 10.6667 5.66666 10.6667C5.85066 10.6667 6 10.5173 6 10.3333V7.66669C6 7.48266 5.85066 7.33334 5.66666 7.33334Z" />
      <path d="M7 8.66666H4.33334C4.14934 8.66666 4 8.816 4 9C4 9.184 4.14934 9.33334 4.33334 9.33334H7C7.184 9.33334 7.33334 9.184 7.33334 9C7.33334 8.816 7.184 8.66666 7 8.66666Z" />
      <path d="M11.6667 8.66666H9.00003C8.81603 8.66666 8.66669 8.816 8.66669 9C8.66669 9.184 8.81603 9.33334 9.00003 9.33334H11.6667C11.8507 9.33334 12 9.184 12 9C12 8.816 11.8507 8.66666 11.6667 8.66666Z" />
      <path d="M6.51803 11.3894C6.36403 11.2867 6.15737 11.3287 6.05603 11.482L4.72269 13.482C4.62069 13.6354 4.66203 13.842 4.81534 13.944C4.87269 13.982 4.93669 14 5 14C5.10734 14 5.21334 13.948 5.27734 13.8514L6.61069 11.8514C6.71269 11.698 6.67137 11.4914 6.51803 11.3894Z" />
      <path d="M6.61002 13.482L5.27668 11.482C5.17533 11.3287 4.96734 11.2867 4.81468 11.3893C4.66134 11.4913 4.62002 11.698 4.72202 11.8513L6.05537 13.8514C6.12002 13.948 6.22537 14 6.33337 14C6.39737 14 6.46137 13.982 6.51737 13.944C6.67068 13.842 6.71202 13.6354 6.61002 13.482Z" />
      <path d="M11.6667 12H9.00003C8.81603 12 8.66669 12.1493 8.66669 12.3333C8.66669 12.5173 8.81603 12.6667 9.00003 12.6667H11.6667C11.8507 12.6667 12 12.5173 12 12.3333C12 12.1493 11.8507 12 11.6667 12Z" />
      <path d="M11.6667 13.3333H9.00003C8.81603 13.3333 8.66669 13.4827 8.66669 13.6667C8.66669 13.8507 8.81603 14 9.00003 14H11.6667C11.8507 14 12 13.8507 12 13.6667C12 13.4827 11.8507 13.3333 11.6667 13.3333Z" />
    </svg>
  )
}

Calculator.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Calculator
