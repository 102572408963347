import React from "react"
import { Translate } from "src/components"
import { Link, Route } from "react-router-dom"
import { removeAuthToken } from "src/utils"
import { RelayEnvironmentConsumer } from "src/context"

const handleLogout = ({ history, refreshEnvironment }) => (event) => {
  if (event) {
    event.preventDefault()
  }

  removeAuthToken()
  refreshEnvironment()
  history.push("/login")
}

const render = () => (
  <RelayEnvironmentConsumer>
    {({ refreshEnvironment }) => (
      <Route>
        {({ history }) => (
          <Link
            to={"/logout"}
            onClick={handleLogout({ history, refreshEnvironment })}
          >
            <Translate i18n="common:logout" />
          </Link>
        )}
      </Route>
    )}
  </RelayEnvironmentConsumer>
)

export default render
