import { useEffect } from "react"

const useDebounceEffect = (effect: () => any, deps: any[], delay = 300) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay)

    return () => clearTimeout(handler)
  }, [...(deps || []), delay])
}

export default useDebounceEffect
