import React from "react"

import {
  Box,
  Heading,
  Translate,
  FloatingLabelInput,
} from "src/components"

import { initialStateFromProps } from "src/utils"

import { ExecutiveContainer } from "./styles"

export class Executive extends React.Component<any, any> {
  state = {
    ...initialStateFromProps(this.props.value, ["name"]),
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state,
    })
  }

  render() {
    // TODO: Romove all commented legacy code
    return (
      <ExecutiveContainer>
        <Box pb={15}>
          <Heading.h5>
            <Translate
              i18n="accreditation.borrower.rows.manager_info.header"
              ns="components"
            />
          </Heading.h5>
        </Box>

        <FloatingLabelInput
          name="name"
          value={this.state.name}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={
            "components:accreditation.borrower.rows.manager_info.name.label"
          }
        />
        {/* <FormRow>
          <Box width={260}>
            <Label whiteSpace="pre-line">
              <Translate
                i18n="accreditation.borrower.rows.manager_info.name.label"
                ns="components"
              />
            </Label>
          </Box>
          <Box width="100%">
            <TextField
              type="string"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
            />
          </Box>
        </FormRow> */}
      </ExecutiveContainer>
    )
  }
}

export default Executive
