import React from "react"

import {
  Wrapper, Title, Description, Value,
} from "./styles"

import { displayData } from "../PaymentScheduleItem/utils"

function IncomeContainer(props) {
  const { title, value } = props
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Description>До вычета налогов и комиссии</Description>
      <Value>{displayData(value, 2)}</Value>
    </Wrapper>
  )
}

export default IncomeContainer
