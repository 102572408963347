import React from "react"
import memoize from "memoize-one"
import { Route } from "react-router"

import {
  pipe,
  notifyGTM,
  getProperty,
  getAuthToken,
  isOfferExpired,
  isRussianInvestor,
  isForeignInvestor,
  getInvestorProfiles,
  hasCollectedMaxAmount,
  getProfileDetailedBalance,
  getByCurrencyFromDetailedBalance,
} from "src/utils"

import { Modal, GuestOrderAction, VirtualAccountActions } from "src/components"

import { ViewerDataConsumer } from "src/context"
import { ViewerAccessContainer } from "src/containers"

import modals from "./Modals"
import EnsureMobile from "./EnsureMobile"
import { useOnCompleteCallbacks, useInvestmentAmount } from "./hooks"

import { ModalContainer, StyledDefaultModal } from "./styles"

const hasInvestorProfile = (viewer) => {
  if (!viewer) return false

  const foreignProfiles = getProperty(viewer, "foreignProfiles", [])
  const hasForeignInvestor = foreignProfiles.some(isForeignInvestor)
  const hasRussianInvestor = viewer.profiles.some(isRussianInvestor)

  return hasForeignInvestor || hasRussianInvestor
}

const sendGTMEvent = memoize((order, viewer) => {
  if (!order || !order.chain) return () => {}

  const { id } = order.chain

  const karmaPlatformNotifier = notifyGTM({
    eventAction: `market-${id.split(".")[2]}`,
    eventLabel: "pay-second-click",
    extras: {
      "profile-id": viewer.id,
    },
  })

  const { cession, application, id: orderId } = order

  const ecommerceNotifier = notifyGTM({
    event: "addToCart",
    eventCategory: "",
    extras: {
      ecommerce: {
        add: {
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
            },
          ],
        },
      },
      FLProfile: hasInvestorProfile(viewer),
    },
  })

  return pipe(karmaPlatformNotifier, ecommerceNotifier)
})

const getAvailableInvestmentAmount = (order) => {
  const { gatheredAmount } = order.chain
  const maxValue = Number(order.application.data.maxValue)

  return maxValue - gatheredAmount
}

const calcMaxInvestmentAmount = (profile, availableOrderInvestmentAmount) => {
  if (!profile) return 0

  const detailedBalances = getProfileDetailedBalance(profile)
  const rubleBalance = getByCurrencyFromDetailedBalance(detailedBalances, "RUB")
  return availableOrderInvestmentAmount >= rubleBalance.available
    ? rubleBalance.available
    : availableOrderInvestmentAmount
}

const Invest = (props) => {
  const { order, viewer } = props
  const profiles = getInvestorProfiles(viewer).filter(
    (item) => item.accreditation.status === "APPROVED",
  )
  const [profile, setProfile] = React.useState(profiles[0] || null)
  const orderNumber = order.chain.id.split(".")[2]
  const availableAmount = getAvailableInvestmentAmount(order)
  const sendGTM = sendGTMEvent(order, viewer)
  const maxInvestmentAmount = calcMaxInvestmentAmount(profile, availableAmount)
  const [investmentAmount, setInvestmentAmount] = useInvestmentAmount(
    maxInvestmentAmount,
  )

  const collected = hasCollectedMaxAmount(order)
  const disabled = React.useMemo(
    () => isOfferExpired(order) || order.status === "COMPLETE" || collected,
    [order],
  )

  const [depositModal, setDepositModal] = React.useState(false)
  const [activeModal, setActiveModal] = React.useState(null)
  const openModal = React.useCallback(() => {
    sendGTM()
    const modal = getAuthToken() ? "amount" : "guest"
    if (modal !== "guest") {
      props.checkAccess(() => {
        setActiveModal(modal)
      })

      return
    }

    setActiveModal(modal)
  }, [])

  const hideModal = React.useCallback(() => {
    (setInvestmentAmount as any)({ target: { value: "" } })
    const next = activeModal === "deposit" ? "amount" : null
    setActiveModal(next)
  }, [activeModal])

  const showDeposit = React.useCallback(() => {
    hideModal()
    setDepositModal(true)
  }, [])

  const closeDeposit = React.useCallback(() => {
    setDepositModal(false)
    openModal()
  }, [])

  const showError = React.useCallback(() => setActiveModal("error"), [])

  const setProfileAndAmount = React.useCallback(
    pipe(setProfile, () => (setInvestmentAmount as any)({ target: { value: "" } })),
    [],
  )

  const ActiveModal = React.useMemo(() => modals[activeModal], [activeModal])
  const callbacks = useOnCompleteCallbacks(modals, setActiveModal, hideModal)

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get("action") === "invest") {
      queryParams.delete("action")
      // props.history.push({
      //   search: `?${queryParams.toString()}`,
      // })

      const params = queryParams.toString()
      window.history.pushState("", "", `?${params}`)

      if (disabled) return

      props.checkAccess(() => {
        openModal()
      })
    }
  }, [])

  return (
    <>
      <props.render onClick={openModal} disabled={disabled} />
      {/* <ButtonContainer> */}
      {/*  <StyledButton */}
      {/*    onClick={openModal} */}
      {/*    disabled={disabled} */}
      {/*    fontSize={props.fontSize} */}
      {/*    variant={props.buttonVariant} */}
      {/*  > */}
      {/*    <Translate */}
      {/*      i18n="order_list.approval.buttons.invest" */}
      {/*      ns="components" */}
      {/*    /> */}
      {/*  </StyledButton> */}
      {/*  { */}
      {/*    props.withOutdatedMessage && disabled && !collected && ( */}
      {/*      <TextContainer> */}
      {/*        <Translate i18n="pages:user.proposal.meta.order_outdated"/> */}
      {/*      </TextContainer> */}
      {/*    ) */}
      {/*  } */}
      {/* </ButtonContainer> */}
      {!!activeModal && (
        <>
          <EnsureMobile />
          <Modal>
            <StyledDefaultModal
              padding="0"
              onClose={hideModal}
              heightRestriction={false}
              closeButtonVariant="empty"
            >
              <ModalContainer>
                {ActiveModal.component ? (
                  <ActiveModal.component
                    order={order}
                    viewer={viewer}
                    profile={profile}
                    profiles={profiles}
                    showDeposit={showDeposit}
                    setProfile={setProfileAndAmount}
                    onClose={hideModal}
                    orderNumber={orderNumber}
                    amount={investmentAmount}
                    availableAmount={availableAmount}
                    onChange={setInvestmentAmount}
                    onComplete={callbacks[activeModal]}
                    onError={showError}
                  />
                ) : (
                  <GuestOrderAction.modal onClose={hideModal} />
                )}
              </ModalContainer>
            </StyledDefaultModal>
          </Modal>
        </>
      )}
      {depositModal && (
        <>
          <EnsureMobile />
          <VirtualAccountActions.Deposit.Modal
            profileId={profile.id}
            order={order}
            onClose={closeDeposit}
          />
        </>
      )}
    </>
  )
}

Invest.defaultProps = {
  buttonVariant: "blueWide",
  fontSize: "14px",
  withOutdatedMessage: false,
}

const ComposedConsumer = (props) => {
  const { onModalOpen, onModalClose } = props

  return (
    <Route>
      {({ history }) => (
        <ViewerDataConsumer>
          {(viewer) => (
            <ViewerAccessContainer
              role="investor"
              onModalOpen={onModalOpen}
              onModalClose={onModalClose}
            >
              {({ checkAccess }) => props.children({
                viewer,
                history,
                checkAccess,
                ...props,
              })
              }
            </ViewerAccessContainer>
          )}
        </ViewerDataConsumer>
      )}
    </Route>
  )
}

export default (props) => (
  <ComposedConsumer>
    {(renderProps) => <Invest {...props} {...renderProps} />}
  </ComposedConsumer>
)
