import styled from "styled-components"

import { Icons } from "../../atoms"

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  #app-root {
    overflow: hidden;
  }
`

export const WindowContainer = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  max-width: 980px;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  padding: 30px;

  @media screen and (max-width: 790px) {
    padding: 30px;
    max-width: 100%;
    height: 100%;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    overflow: hidden auto;
  }

  @media screen and (max-width: 650px) {
    padding: 20px;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 650px) {
    display: block;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 790px) {
    margin: 45px 0 90px;
  }
`

export const Divider = styled.div`
  min-width: 20px;
  width: 20px;
`

export const IncomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 375px;
  margin-right: 60px;

  @media screen and (max-width: 790px) {
    width: calc(100% - 280px);
    margin: 0;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    margin: 0;
  }
`

export const NameWrap = styled.div`
  font-size: 16px;
  line-height: 33px;
  font-weight: bold;
  color: #4a4a4a;

  @media screen and (max-width: 790px) {
    font-size: 28px;
    line-height: 35px;
    margin-top: 80px;
  }
`

export const OrderName = styled.p`
  margin: 0;
`

export const TitleDescription = styled(OrderName)`
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  font-weight: 300;
`

export const CloseIcon = styled(Icons.Arrow)`
  width: 24px;
  height: 26px;
  padding-bottom: 2px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
`

export const MobileExitBtn = styled.button`
  border: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: Geometria, sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  background-color: inherit;
  position: absolute;
  top: 35px;
  left: 22px;
  cursor: pointer;
  z-index: 1002;
  outline: 0;
  padding: 0;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
`

export const TitleWrap = styled.div`
  font-size: 24px;
  line-height: 56px;
  color: rgba(74, 74, 74, 0.75);
  font-weight: 100;
  margin-bottom: 30px;
  margin-right: 16px;
  display: inline-block;

  @media screen and (max-width: 790px) {
    font-size: 22px;
    line-height: 28px;
    margin: 64px 0 0;
  }

  @media screen and (max-width: 650px) {
    margin-top: 0;
    text-align: center;
  }
`

export const StatusWrap = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  color: #6fa84b;

  svg path {
    fill: #6fa84b;
  }
`

export const ExitBtn = styled.button`
  border: 0;
  background-color: inherit;
  position: absolute;
  padding: 4px;
  top: 16px;
  right: 16px;

  :focus {
    outline: 0;
  }
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`

export const LoadingContainer = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.55);
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 4px;

  @media screen and (max-width: 790px) {
    position: fixed;
    height: 100%;
    top: 0;
  }
`
