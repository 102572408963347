/* eslint-disable max-len */

import React from "react"

function Telegram(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5318 3.53994C17.0272 3.73931 2.70381 9.77831 2.70381 9.77831C2.70381 9.77831 2.01012 10.0155 2.06512 10.4534C2.12012 10.8914 2.68593 11.0921 2.68593 11.0921L6.37231 12.3331C6.37231 12.3331 7.48537 15.983 7.70468 16.676C7.92399 17.3697 8.09931 17.3862 8.09931 17.3862C8.30281 17.4749 8.48912 17.3333 8.48912 17.3333L10.8713 15.1614L14.5845 18.0084C15.5882 18.4463 15.9533 17.534 15.9533 17.534L18.5115 4.4275C18.7384 3.41275 18.0646 3.33025 17.5318 3.53994Z"
        stroke="white"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7156 6.71275C15.7342 6.40268 15.3017 6.62406 15.3017 6.62406L6.29688 12.2017C6.29688 12.2017 7.48556 15.983 7.70487 16.6767C7.9235 17.3704 8.22325 17.4886 8.22325 17.4886L8.89562 13.2096C8.89562 13.2096 15.2598 7.27856 15.5334 7.02281C15.8071 6.76775 15.7156 6.71275 15.7156 6.71275Z"
        fill="white"
      />
    </svg>
  )
}

Telegram.defaultProps = {
  width: "24px",
  height: "24px",
}

export default Telegram
