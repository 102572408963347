import styled from "styled-components"

export default styled.div`
  width: 1280px;
  min-width: 320px;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  display: flex;
  min-height: 100%;
  flex: 1;
  padding-right: 60px;
  padding-left: 60px;
  box-sizing: border-box;
  @media screen and (max-width: 1279px) {
    width: 100%;
    overflow: hidden;
    padding-right: 20px;
    padding-left: 20px;
  }
`
