/* eslint-disable max-len */

import React from "react"

import {
  Text, Description, Divider, SupText,
} from "./styles"

function DescriptionText() {
  return (
    <>
      {/* <Text>
        Оборот - объем инвестиций с начала текущего календарного года. В
        соответствии с 259 ФЗ максимальный оборот для неквалифицированных
        инвесторов - 600 000 рублей в год. Если вы достигли максимального
        лимита, рекомендуем вам рассмотреть варианты получения статуса
        квалифицированного инвестора или создания ИП для инвестирования.
      </Text> */}
      <Description>
        О сравнение выгодности инвестиций от ИП и физического лица вы можете
        почитать по{" "}
        <a
          href="https://zen.yandex.ru/media/id/5d2dcde792414d00ad7ed67b/kak-vygodnee-investirovat-ip-ili-fizlico-5d8b4e6b04af1f00ae0c2103"
          target="_blank"
          rel="noreferrer"
        >
          ссылке
        </a>
      </Description>

      <Text>
        Законом предусмотрены 5 возможных условий для признания инвестора
        квалифицированным:
      </Text>
      <Description />

      <Text>
        1) Общая рыночная стоимость ценных бумаг, которыми владеет это лицо, и
        (или) общий размер обязательств из договоров, являющихся производными
        финансовыми инструментами и заключенных за счет этого лица, должны
        составлять не менее 6 миллионов рублей<sup>1</sup>.
      </Text>
      <Description>
        Это подтверждается выписками по брокерским счетам / выписками из
        реестров акционеров / по счетам депо.
      </Description>

      <Text>
        2) Имеет опыт работы в российской и (или) иностранной организации,
        которая совершала сделки с ценными бумагами и (или) заключала договоры,
        являющиеся производными финансовыми инструментами:
        <ul>
          <li>
            не менее двух лет, если такая организация (организации) является
            квалифицированным инвестором в соответствии с пунктом 2 статьи 51.2
            Федерального закона "О рынке ценных бумаг";
          </li>
          <li>не менее 3 лет в иных случаях;</li>
        </ul>
      </Text>
      <Description>
        Это подтверждается заверенными копиями трудовой книжки и лицензии
        соответствующей организации.
      </Description>

      <Text>
        3) Совершало сделки с ценными бумагами и (или) заключало договоры,
        являющиеся производными финансовыми инструментами, за последние четыре
        квартала в среднем не реже 10 раз в квартал, но не реже одного раза в
        месяц. При этом совокупная цена таких сделок (договоров) должна
        составлять не менее 6 миллионов рублей.
      </Text>
      <Description>
        Это подтверждается выписками по брокерским счетам, справками брокеров.
      </Description>

      <Text>
        4) Размер имущества, принадлежащего лицу, составляет не менее 6
        миллионов рублей<sup>2</sup>.
      </Text>
      <Description>
        Это подтверждается выписками по банковским и брокерским счетам,
        выписками реестродержателей и депозитариев, справками брокеров.
      </Description>

      <Text>
        5) Имеет высшее экономическое образование, подтвержденное документом
        государственного образца Российской Федерации о высшем образовании,
        выданным образовательной организацией высшего профессионального
        образования, которая на момент выдачи указанного документа осуществляла
        аттестацию граждан в сфере профессиональной деятельности на рынке ценных
        бумаг, или любой из следующих аттестатов и сертификатов:
        квалификационный аттестат специалиста финансового рынка,
        квалификационный аттестат аудитора, квалификационный аттестат страхового
        актуария, сертификат "Chartered Financial Analyst (CFA)", сертификат
        "Certified International Investment Analyst (CIIA)", сертификат
        "Financial Risk Manager (FRM)".
      </Text>
      <Description />

      <Text>
        Если у вас возникли вопросы, пишите нам на{" "}
        <a href="mailto:hello@karma.red" target="_blank" rel="noreferrer">
          hello@karma.red
        </a>{" "}
        или в телеграмм.
      </Text>

      <Divider />

      <SupText>
        1. Для расчета этой суммы учитываются следующие финансовые инструменты:
        <ul>
          <li>
            государственные ценные бумаги Российской Федерации, государственные
            ценные бумаги субъектов Российской Федерации и муниципальные ценные
            бумаги;
          </li>
          <li>акции и облигации российских эмитентов;</li>
          <li>государственные ценные бумаги иностранных государств;</li>
          <li>акции и облигации иностранных эмитентов;</li>
          <li>
            российские депозитарные расписки и иностранные депозитарные расписки
            на ценные бумаги;
          </li>
          <li>
            инвестиционные паи паевых инвестиционных фондов и паи (акции)
            иностранных инвестиционных фондов;
          </li>
          <li>ипотечные сертификаты участия;</li>
          <li>
            заключаемые на организованных торгах договоры, являющиеся
            производными финансовыми инструментами;
          </li>
          <li>
            учитываются также соответствующие финансовые инструменты, переданные
            физическим лицом в доверительное управление;
          </li>
        </ul>
      </SupText>

      <SupText>
        2. Учитывается следующее имущество:
        <ul>
          <li>
            денежные средства, находящиеся на счетах и (или) во вкладах
            (депозитах), открытых в кредитных организациях в соответствии с
            нормативными актами Банка России, и (или) в иностранных банках, с
            местом учреждения в государствах, являющихся членами Организации
            экономического сотрудничества и развития (ОЭСР), членами или
            наблюдателями Группы разработки финансовых мер борьбы с отмыванием
            денег (ФАТФ) и (или) членами Комитета экспертов Совета Европы по
            оценке мер противодействия отмыванию денег и финансированию
            терроризма (Манивэл), и (или) членами Евразийского экономического
            союза или в государствах, с соответствующими органами
            (соответствующими организациями) которых Банком России заключено
            соглашение, предусматривающее порядок их взаимодействия;
          </li>
          <li>
            требования к кредитной организации выплатить денежный эквивалент
            драгоценного металла по учетной цене соответствующего драгоценного
            металла;
          </li>
          <li>
            ценные бумаги (в том числе переданные физическим лицом в
            доверительное управление), указанные в п. 1 настоящей памятки;
          </li>
          <li>
            требования к кредитной организации выплатить денежный эквивалент
            драгоценного металла по учетной цене соответствующего драгоценного
            металла;
          </li>
        </ul>
      </SupText>
    </>
  )
}

export default DescriptionText
