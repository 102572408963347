export function divideTime(time) {
  function pad(n) {
    return (n < 10 ? "0" : "") + n
  }

  let secondsLeft = time / 1000

  const days = pad(parseInt(`${secondsLeft / 86400}`, 10))
  secondsLeft %= 86400

  const hours = pad(parseInt(`${secondsLeft / 3600}`, 10))
  secondsLeft %= 3600

  const minutes = pad(parseInt(`${secondsLeft / 60}`, 10))
  const seconds = pad(parseInt(`${secondsLeft % 60}`, 10))

  return `${days} : ${hours} : ${minutes} : ${seconds}`
}
