/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GetAttachmentLinkInput = {
    id: string;
};
export type getAttachmentLinkMutationVariables = {
    input: GetAttachmentLinkInput;
};
export type getAttachmentLinkMutationResponse = {
    readonly getAttachmentLink: {
        readonly url: string | null;
    };
};
export type getAttachmentLinkMutation = {
    readonly response: getAttachmentLinkMutationResponse;
    readonly variables: getAttachmentLinkMutationVariables;
};



/*
mutation getAttachmentLinkMutation(
  $input: GetAttachmentLinkInput!
) {
  getAttachmentLink(input: $input) {
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GetAttachmentLinkInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GetAttachmentLinkPayload",
    "kind": "LinkedField",
    "name": "getAttachmentLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getAttachmentLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getAttachmentLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "getAttachmentLinkMutation",
    "operationKind": "mutation",
    "text": "mutation getAttachmentLinkMutation(\n  $input: GetAttachmentLinkInput!\n) {\n  getAttachmentLink(input: $input) {\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '2d73226edc64d6c8aa6d3fbff025cf2d';
export default node;
