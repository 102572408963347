import styled from "styled-components"

import { Box, Text } from "src/components"

const padding = (props) => {
  const { inputsType, length, nestedLevel } = props

  if (inputsType && inputsType === "new") {
    return "padding: 0;"
  }

  const paddingBottom = length > 1 ? "20px" : 0
  const paddingLeft = nestedLevel ? `${nestedLevel * 25}px` : 0

  return `padding: 0 0 ${paddingBottom} ${paddingLeft};`
}

// eslint-disable-next-line
export const InputWrapper = styled(Box)`
  ${padding};
  width: 100%;
  position: relative;
`

export const ExtraText = styled(Text)`
  color: ${({ theme }) => theme.colors.textBlack};
  font-size: 1.125rem;
  line-height: 1.5;
`

const extraPadding = ({ padding: injectedPadding }) => {
  if (injectedPadding) {
    return `padding: ${injectedPadding};`
  }

  return "padding: 20px 0;"
}

export const ExtraContainer = styled.div`
  ${extraPadding};
  flex-basis: 50%;
`
