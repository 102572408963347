/* eslint-disable import/prefer-default-export */
import { ProfileType } from "src/constants"
import { getProperty, addressObjectToString } from "src/utils"

const buildInvestorGroups = (profile) => {
  const { status } = profile.accreditation

  const series = getProperty(profile, "passport.series")
  const passportNumber = getProperty(profile, "passport.number")
  const passport = series && passportNumber ? `${series} / ${passportNumber}` : ""
  const passportIssuedAt = getProperty(profile, "passport.issuedAt", "") || ""
  const registrationAddress = getProperty(
    profile,
    "registrationAddress",
    {},
  )
  const scans = getProperty(profile, "passport.scans", [])
  const supportingDocuments = getProperty(profile, "supportingDocuments", [])
  const birthDate = getProperty(profile, "birthDate", "") || ""

  const gender = getProperty(profile, "sex")

  return [
    {
      title: "components:account.accreditation_info.profile_data.title",
      status,
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.name",
          value: getProperty(profile, "name") || profile.name,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.sex.title",
          value:
            !!gender && `components:account.accreditation_info.sex.${gender}`,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.phone",
          value: profile.phone,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.inn",
          value: getProperty(profile, "inn"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.iian",
          value: getProperty(profile, "iian"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.birthDate",
          value: birthDate.split("-").reverse().join("-"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.profile_data.birthPlace",
          value: getProperty(profile, "birthPlace"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.passport_data.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.passport_data.series",
          value: passport,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.passport_data.issuedAt",
          value: passportIssuedAt.split("-").reverse().join("-"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.passport_data.issuerCode",
          value: getProperty(profile, "passport.issuedBy.code"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.passport_data.issuerName",
          value: getProperty(profile, "passport.issuedBy.name"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.passport_scans.title",
      fields: scans.length
        ? scans.map((item) => ({ type: "attachment", value: item }))
        : [{ type: "attachment" }],
    },
    {
      title: "Иные документы",
      fields: supportingDocuments.length
        ? supportingDocuments.map((item) => ({ type: "attachment", value: item.attachment }))
        : [],
    },
    {
      title: "components:account.accreditation_info.registration_address.title",
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.registration_address.label",
          value:
            !!registrationAddress && addressObjectToString(registrationAddress),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.tax_resident.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.tax_resident.label",
          // eslint-disable-next-line
          value: `components:account.accreditation_info.tax_resident.value.${
            getProperty(profile, "isRussiaTaxResident") ? "yes" : "no"
          }`,
        },
      ],
    },
    {
      title: "components:account.accreditation_info.is_public.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.is_public.label",
          // eslint-disable-next-line
          value: `components:account.accreditation_info.is_public.value.${
            getProperty(profile, "isPublicOfficial") ? "yes" : "no"
          }`,
        },
      ],
    },
    {
      title: "components:account.bank_account.info.requisites",
      fields: [
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.name",
          value: getProperty(profile, "bankAccounts[0].bank.name"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.bic",
          value: getProperty(profile, "bankAccounts[0].bank.bic"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.checking_account",
          value: getProperty(profile, "bankAccounts[0].checkingAccount"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.correspondent_account",
          value: getProperty(profile, "bankAccounts[0].correspondentAccount"),
        },
      ],
    },
  ]
}

const buildEntrepreneurGroups = (profile) => {
  const { status } = profile.accreditation

  const series = getProperty(profile, "passport.series")
  const passportNumber = getProperty(profile, "passport.number")
  const passport = series && passportNumber ? `${series} / ${passportNumber}` : ""
  const passportIssuedAt = getProperty(profile, "passport.issuedAt", "") || ""
  const registrationAddress = getProperty(
    profile,
    "registrationAddress",
    {},
  )
  const scans = getProperty(profile, "passport.scans", [])
  const birthDate = getProperty(profile, "birthDate", "") || ""

  const gender = getProperty(profile, "sex")

  return [
    {
      title: "components:account.accreditation_info.profile_data.title",
      status,
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.name",
          value: getProperty(profile, "name") || profile.name,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.sex.title",
          value:
            !!gender && `components:account.accreditation_info.sex.${gender}`,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.phone",
          value: profile.phone,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.inn",
          value: getProperty(profile, "inn"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.psrnie",
          value: getProperty(profile, "psrnie"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.iian",
          value: getProperty(profile, "iian"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.birthDate",
          value: birthDate.split("-").reverse().join("-"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.profile_data.birthPlace",
          value: getProperty(profile, "birthPlace"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.passport_data.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.passport_data.series",
          value: passport,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.passport_data.issuedAt",
          value: passportIssuedAt.split("-").reverse().join("-"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.passport_data.issuerCode",
          value: getProperty(profile, "passport.issuedBy.code"),
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.passport_data.issuerName",
          value: getProperty(profile, "passport.issuedBy.name"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.passport_scans.title",
      fields: scans.length
        ? scans.map((item) => ({ type: "attachment", value: item }))
        : [{ type: "attachment" }],
    },
    {
      title: "components:account.accreditation_info.registration_address.title",
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.registration_address.label",
          value:
            !!registrationAddress && addressObjectToString(registrationAddress),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.tax_resident.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.tax_resident.label",
          // eslint-disable-next-line
          value: `components:account.accreditation_info.tax_resident.value.${
            getProperty(profile, "isRussiaTaxResident") ? "yes" : "no"
          }`,
        },
      ],
    },
    {
      title: "components:account.accreditation_info.is_public.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.is_public.label",
          // eslint-disable-next-line
          value: `components:account.accreditation_info.is_public.value.${
            getProperty(profile, "isPublicOfficial") ? "yes" : "no"
          }`,
        },
      ],
    },
    {
      title: "components:account.bank_account.info.requisites",
      fields: [
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.name",
          value: getProperty(profile, "bankAccounts[0].bank.name"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.bic",
          value: getProperty(profile, "bankAccounts[0].bank.bic"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.checking_account",
          value: getProperty(profile, "bankAccounts[0].checkingAccount"),
        },
        {
          type: "text",
          label: "components:account.bank_account.info.account_info.correspondent_account",
          value: getProperty(profile, "bankAccounts[0].correspondentAccount"),
        },
      ],
    },
  ]
}

const buildForeignInvestorGroups = (profile) => {
  const documents = profile.documents.reduce(
    (memo, item) => [...memo, ...item.attachments],
    [],
  )

  const { translatedDocuments } = profile

  const { status } = profile.accreditation

  const isRussiaTaxResident = getProperty(profile, "isRussiaTaxResident")
  const territoryDoc = getProperty(profile, "territoryResidenceDocument")
  const migrationCard = getProperty(profile, "migrationCard")

  const residenceDocumentValue = getProperty(profile, "residenceDocument.url")
    ? {
      type: "attachment",
      value: profile.residenceDocument,
      label: "components:account.accreditation_info.tax_residence.document",
    }
    : {
      type: "attachment",
      label: "components:account.accreditation_info.tax_residence.document",
    }

  const taxResidencyApplicationValue = profile?.taxResidencyApplication?.length
    ? profile.taxResidencyApplication.map((value) => ({
      type: "attachment",
      value,
    }))
    : [{ type: "attachment" }]

  const registrationAddress = getProperty(
    profile,
    "registrationAddress",
    {},
  )

  const innDocument = getProperty(profile, "innDocument")

  return [
    {
      title: "components:account.accreditation_info.profile_data.title",
      status,
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.profile_data.name",
          value: profile.name,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.phone",
          value: profile.foreignPhone,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.tax_residence.tin",
          value: profile.tin,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.inn_document.label",
          value: `components:account.accreditation_info.inn_document.value.${
            innDocument ? "yes" : "no"
          }`,
        },
        ...(innDocument?.map((value) => ({
          type: "attachment",
          value,
        })) || []),
      ],
    },
    {
      title: "components:account.accreditation_info.identity.title",
      fields: documents.length
        ? documents.map((item) => ({ type: "attachment", value: item }))
        : [{ type: "attachment" }],
    },
    {
      title: "components:account.accreditation_info.identity.translation",
      fields: translatedDocuments?.length
        ? translatedDocuments.map((value) => ({ type: "attachment", value }))
        : [{ type: "attachment" }],
    },
    {
      title: "components:account.accreditation_info.selfie.title",
      fields: getProperty(profile, "selfie.url")
        ? [{ type: "attachment", value: profile.selfie }]
        : [{ type: "attachment" }],
    },
    {
      title: "components:account.accreditation_info.migration_card.title",
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.number",
          value: migrationCard?.number,
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.dateFrom",
          value: migrationCard?.dateFrom.split("-").reverse().join("-"),
        },
        migrationCard?.dateTo && {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.dateTo",
          value: migrationCard?.dateTo.split("-").reverse().join("-"),
        },
        ...(migrationCard?.attachments.length
          && migrationCard.attachments.map((item) => ({
            type: "attachment",
            value: item,
          }))),
      ].filter(Boolean),
    },
    {
      title:
        "components:account.accreditation_info.territory_residence_document.title",
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.type.label",
          value:
            territoryDoc?.type
            && `components:account.accreditation_info.territory_residence_document.type.${territoryDoc.type}`,
        },
        territoryDoc?.series && {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.series",
          value: territoryDoc.series,
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.number",
          value: territoryDoc?.number,
        },
        {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.dateFrom",
          value: territoryDoc?.dateFrom.split("-").reverse().join("-"),
        },
        territoryDoc?.dateTo && {
          type: "text",
          label:
            "components:account.accreditation_info.territory_residence_document.dateTo",
          value: territoryDoc.dateTo.split("-").reverse().join("-"),
        },
        ...(territoryDoc?.attachments.length
          && territoryDoc.attachments.map((item) => ({
            type: "attachment",
            value: item,
          }))),
      ].filter(Boolean),
    },
    {
      title: "components:account.accreditation_info.registration_address.title",
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.registration_address.label",
          value:
            !!registrationAddress && addressObjectToString(registrationAddress),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.tax_residence.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.tax_residence.label",
          value: `components:account.accreditation_info.tax_residence.value.${
            isRussiaTaxResident ? "yes" : "no"
          }`,
        },
        ...(isRussiaTaxResident
          ? [residenceDocumentValue, ...taxResidencyApplicationValue]
          : []),
      ],
    },
  ]
}

const buildBorrowerGroups = (profile) => {
  const { status } = profile.accreditation

  const address = getProperty(profile, "address", {})
  const actualAddress = getProperty(profile, "actualAddress", {})
  const scans = getProperty(profile, "passport.scans", [])

  return [
    {
      title: "components:account.accreditation_info.profile_data.title",
      status,
      fields: [
        {
          type: "text",
          label:
            "components:account.accreditation_info.profile_data.company_name",
          value: getProperty(profile, "name") || profile.name,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.phone",
          value: profile.phone,
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.inn",
          value: getProperty(profile, "inn"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.kpp",
          value: getProperty(profile, "kpp"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.ogrn",
          value: getProperty(profile, "ogrn"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.profile_data.publicEmail",
          value: getProperty(profile.borrower, "publicEmail"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.business.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.business.legal_form",
          value: getProperty(profile, "business.legalForm"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.business.started_at",
          value: getProperty(profile, "business.startedAt"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.business.main_activity",
          value: getProperty(profile, "business.main"),
        },
        ...(getProperty(profile, "business.aux")
          ? [
            {
              type: "text",
              label:
                  "components:account.accreditation_info.business.additional_activity",
              value: getProperty(profile, "business.aux"),
            },
          ]
          : []),
        {
          type: "text",
          label:
            "components:account.accreditation_info.business.juristic_address",
          value: address && (address.raw || addressObjectToString(address)),
        },
        ...(actualAddress
          ? [
            {
              type: "text",
              label:
                  "components:account.accreditation_info.business.actual_address",
              value: actualAddress.raw || addressObjectToString(actualAddress),
            },
          ]
          : []),
      ],
    },
    {
      title: "components:account.accreditation_info.executive.title",
      fields: [
        {
          type: "text",
          label: "components:account.accreditation_info.executive.name",
          value: getProperty(profile, "executive.name"),
        },
        {
          type: "text",
          label: "components:account.accreditation_info.executive.iian",
          value: getProperty(profile, "iian"),
        },
      ],
    },
    {
      title: "components:account.accreditation_info.passport_scans.title",
      fields: scans.length
        ? scans.map((item) => ({ type: "attachment", value: item }))
        : [{ type: "attachment" }],
    },
  ]
}

export const buildGroups = (profile) => {
  const { __typename: profileType } = profile

  switch (profileType) {
    case ProfileType.individual:
      return buildInvestorGroups(profile)
    case ProfileType.entrepreneur:
      return buildEntrepreneurGroups(profile)
    case ProfileType.juristic:
      return buildBorrowerGroups(profile)
    case ProfileType.foreignIndividual:
      return buildForeignInvestorGroups(profile)
    default:
      return []
  }
}
