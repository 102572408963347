import React from "react"
import {
  Text,
  Button,
  FlexBox,
  Tooltip,
  Translate,
} from "src/components"
import { InputsDataConsumer } from "./context"

import {
  RemoveIcon,
  StyledHeading,
  HeadingWrapper,
  TitleContainer,
  SubtitleContainer,
  RemoveButtonWrapper,
} from "./styles"

const HeadingTitle = (props) => {
  const {
    title, tooltip, subtitle, inputsType,
  } = props

  return (
    <HeadingWrapper subtitle={subtitle} inputsType={inputsType}>
      <StyledHeading inputsType={inputsType}>
        <Translate i18n={`${title}`} ns="components" />
        {tooltip && <Tooltip text={tooltip} />}
      </StyledHeading>
    </HeadingWrapper>
  )
}

const SubTitle = (props) => {
  const { subtitle, subTooltip } = props

  if (!subtitle) {
    return null
  }

  return (
    <SubtitleContainer>
      <Text>
        <Translate i18n={`${subtitle}`} ns="components" />
        {subTooltip && <Tooltip text={subTooltip} />}
      </Text>
    </SubtitleContainer>
  )
}

export const DefaultTitle = (props) => {
  const {
    title, tooltip, children, subtitle, inputsType, subTooltip,
  } = props

  return (
    <React.Fragment>
      <TitleContainer>
        <HeadingTitle
          title={title}
          tooltip={tooltip}
          inputsType={inputsType}
          subtitle={subtitle}
        />
        {children}
      </TitleContainer>
      <SubTitle subtitle={subtitle} subTooltip={subTooltip} />
    </React.Fragment>
  )
}

export const Title = (props) => {
  const { title } = props

  return title ? (
    <InputsDataConsumer>
      {({ type: inputsType }: any) => (
        <DefaultTitle {...props} inputsType={inputsType} />
      )}
    </InputsDataConsumer>
  ) : null
}

export const EmptyInput = () => null

export const RemoveButton = ({ onClick }) => (
  <RemoveButtonWrapper>
    <Button onClick={onClick} variant="defaultThin">
      <FlexBox display="flex" flexDirection="row" alignItems="center">
        <RemoveIcon />
      </FlexBox>
    </Button>
  </RemoveButtonWrapper>
)
