import React from "react"

import {
  Box, Translate, CheckBoxField, FloatingLabelInput,
} from "src/components"

import { ErrorsExecutor } from "../../Blocks/Input/utils"

import {
  PassportRowWrapper,
  Header,
  InputBox,
  Tooltip,
  PassportScansContainer,
  PassportBlockedOverlay,
} from "./styles"
import Attachments from "../Attachments"
import InputsSchema from "./schema"
import changeHandler from "./utils"

const ScansHeader = () => (
  <Box pt="60px" pb="40px">
    <Header>
      <Translate i18n="components:individual_profile_form.titles.passport.scans" />
    </Header>
  </Box>
)

const PostponeCheckbox = ({ checked, onChange }) => (
  <Box pb="20px">
    <CheckBoxField
      name="postponeScans"
      fill="#F70000"
      color="#4a4a4a"
      checked={checked}
      onChange={onChange}
      fontSize="14px"
      label={
        <Translate i18n="components:individual_profile_form.labels.passport.postponeScans" />
      }
    />
  </Box>
)

const Passport = (props) => {
  const { errors, withScans, value: injectedInputValue } = props

  const onChange = changeHandler(props)

  return (
    <React.Fragment>
      {InputsSchema.map((row: any, rowIndex) => (
        <PassportRowWrapper maxWidth="550px" key={rowIndex}>
          {row.map((input, inputIndex) => {
            const {
              type,
              mask,
              width,
              maxWidth,
              label,
              errorPath,
              errorPaths,
              rootErrorKeywords,
              name: inputName,
            } = input

            return (
              <ErrorsExecutor
                key={rowIndex.toString() + inputIndex.toString()}
                errors={errors}
                onChange={onChange()}
                pathPrefix="passport"
                fieldName={errorPath}
                fieldNames={errorPaths}
                rootErrorKeywords={rootErrorKeywords}
              >
                {(renderProps) => {
                  const { data } = renderProps

                  const { hasError, chainedOnChange, errorPresentation } = data

                  return (
                    <InputBox maxWidth={maxWidth || "100%"}>
                      <FloatingLabelInput
                        width={width}
                        type={type}
                        mask={mask}
                        name={inputName}
                        label={label}
                        error={errorPresentation}
                        value={injectedInputValue[inputName]}
                        hasError={hasError}
                        onChange={chainedOnChange}
                      />
                    </InputBox>
                  )
                }}
              </ErrorsExecutor>
            )
          })}
        </PassportRowWrapper>
      ))}
      <ScansHeader />
      <PostponeCheckbox
        checked={injectedInputValue.postponeScans}
        onChange={onChange("checkbox")}
      />
      <PassportScansContainer>
        {injectedInputValue.postponeScans && <PassportBlockedOverlay />}
        <Box width="100%" pb="12px">
          <Tooltip>
            <Translate i18n="components:individual_profile_form.extras.postponeScans" />
          </Tooltip>
          {/* <Extra */}
          {/*  padding="0 0 20px 0" */}
          {/*  extra="components:individual_profile_form.extras.postponeScans" */}
          {/* /> */}
        </Box>
        {withScans && (
          <PassportRowWrapper width="100%">
            <Attachments
              name="scans"
              value={injectedInputValue.scans}
              onChange={onChange("scans")}
              width="100%"
              padding="0 0 20px"
            />
            <Box width="100%">
              <Tooltip>
                <Translate i18n="components:individual_profile_form.extras.passport" />
              </Tooltip>
            </Box>
            {/* <Extra */}
            {/*  padding="0" */}
            {/*  extra="components:individual_profile_form.extras.passport" */}
            {/* /> */}
          </PassportRowWrapper>
        )}
      </PassportScansContainer>
    </React.Fragment>
  )
}

Passport.defaultProps = {
  withScans: false,
}

export default Passport
