import {
  useMemo, useState, useEffect, useContext,
} from "react"

import { fetchQuery } from "relay-runtime"

import { RelayEnvironmentContext } from "src/context"

import { Subject, from } from "rxjs"
import { debounceTime, switchMap, distinctUntilChanged } from "rxjs/operators"

import autoSuggestQuery from "./autoSuggestQuery"

const useSuggestions = () => {
  const [isLoading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const { environment } = useContext(RelayEnvironmentContext)

  const fetchOptions = (query) => {
    setLoading(true)
    setSuggestions([])

    return fetchQuery(environment, autoSuggestQuery, { query })
  }

  const actor: any = useMemo(
    () => new Subject().pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((query) => from(fetchOptions(query))),
    ),
    [],
  )

  const notifyActor = (value) => actor.next(value)

  useEffect(() => {
    const subscription = actor.subscribe(
      ({ address }) => {
        setLoading(false)
        setSuggestions(address)
      },
      () => {
        setLoading(false)
        setSuggestions([])
      },
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return {
    isLoading,
    notifyActor,
    suggestions,
  }
}

export default useSuggestions
