import React from "react"
import glyphs from "./glyphs"

import Container from "./style"

import { Info, ArrowDown, CurrencyIcon } from "./elements"

const emptyGlyph = () => null

const render = ({ children, size, ...props }: any) => {
  const Glyph = glyphs[children] || emptyGlyph

  return (
    <Container {...props} width={size} height={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <Glyph fill={props.fill} />
      </svg>
    </Container>
  )
}

render.defaultProps = {
  size: 20,
}

render.info = Info
render.currency = CurrencyIcon
render.arrowDown = ArrowDown

export default render
