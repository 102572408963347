import React, { useEffect } from "react"

import scrollToComponent from "react-scroll-to-component"

import i18n from "src/i18n"

import { Subject } from "rxjs"

/* eslint-disable new-parens */
const ErrorsStream = new (class {
  constructor() {
    (this as any).errors = [];
    (this as any).actor = new Subject().subscribe(this.handleError)
  }

  notifyActor = (error) => {
    (this as any).actor.next(error)
  }

  // we will work with stringified objects to compare with
  // :TODO we should handle errors order to recreate correct errorFieldFocus
  handleError = (error) => (this as any).errors.push(JSON.stringify(error))

  handleErrorIndex = (error) => (this as any).errors.indexOf(JSON.stringify(error))

  remove(error) {
    const errorIndex = this.handleErrorIndex(error)

    if (errorIndex > -1) {
      (this as any).errors.splice(errorIndex, 1)
    }
  }

  clear() {
    (this as any).errors = []
  }

  isTarget = (error) => this.handleErrorIndex(error) === 0
})()

const getErrorPath = ({ error }: any) => {
  if (error) {
    return `errors:${error.path}.${error.keyword}`
  }

  return null
}

const ReferencedTranslation = React.forwardRef((props: any, ref) => {
  const path = getErrorPath(props)

  if (!path) return null

  return (
    <span ref={ref as any} id={path}>
      {i18n.t(path)}
    </span>
  )
})

const Presentation = ({ error, scrollToError }) => {
  const ref = React.createRef()

  useEffect(() => {
    if (scrollToError) {
      ErrorsStream.notifyActor(error)

      if (ErrorsStream.isTarget(error)) {
        scrollToComponent(ref.current, {
          offset: 150,
        })

        /* :TODO quick and dirty, should find cleanest solution to handle onScrollEnd callback based library */
        setTimeout(() => {
          ErrorsStream.clear()
        }, 1000)
      }
    }
  }, [error])

  return <ReferencedTranslation ref={ref} error={error} />
}

Presentation.defaultProps = {
  scrollToError: false,
}

const ErrorForField = (error, scrollToError = false) => {
  if (!error) return null

  return <Presentation error={error} scrollToError={scrollToError} />
}

export default ErrorForField
