import React from "react"

import { getTranslationOrComponent } from "src/utils"
import {
  Box, Label, Switch, FormRow,
} from "src/components"

const renderLabel = ({ label, disabled }: any) => {
  if (!label) return null

  return (
    <Label whiteSpace="pre-line" disabled={disabled}>
      {getTranslationOrComponent(label)}
    </Label>
  )
}

export class RightSideSwitch extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { checked } = event.target

    this.props.onChange({
      name: this.props.name,
      value: checked,
    })
  }

  render() {
    const rightLabel = this.props.rightLabel || this.props.children

    return (
      <FormRow>
        <Box width={280}>{renderLabel(this.props)}</Box>
        <Box ml={-10}>
          <Switch
            id={this.props.id || "presentRegistrationMatch"}
            name={this.props.name}
            leftLabel={this.props.leftLabel}
            rightLabel={rightLabel}
            checked={this.props.checked}
            value={this.props.value}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default RightSideSwitch
