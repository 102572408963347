import React from "react"
import styled from "styled-components"

import {
  Box,
  Text,
  Select,
  FlexBox,
  AddButton,
  Translate,
  DateInput,
  TextField,
  IconClose,
} from "src/components"
import { SCHEDULE_ITEM, REPAYMENT_SCHEDULE } from "src/constants"

const CloseIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  box-sizing: content-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`

const Devider = styled.div`
  width: 16px;
  height: 16px;
`

const DateRow = (props) => {
  const {
    item: { date, value },
    index,
    onChange,
    onRemove,
  } = props

  return (
    <FlexBox
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box pt={27}>
        <DateInput
          name="date"
          value={date}
          onChange={onChange}
          type="text"
          hint={
            <Translate
              i18n="legal_entity_offer_form.rows.repayment_schedule.row_hints.date"
              ns="components"
            />
          }
        />
      </Box>
      <Box pt={27}>
        <TextField
          type="text"
          name="value"
          value={value}
          onChange={onChange}
          hint={
            <Translate
              i18n="legal_entity_offer_form.rows.repayment_schedule.row_hints.value"
              ns="components"
            />
          }
        />
      </Box>
      {index > 0 ? (
        <CloseIconWrapper onClick={onRemove}>
          <IconClose />
        </CloseIconWrapper>
      ) : (
        <Devider />
      )}
    </FlexBox>
  )
}

const defaultLabel = (idx) => (
  <Translate
    i18n="legal_entity_offer_form.rows.repayment_schedule.first_month"
    ns="components"
    count={idx + 1}
  />
)

class RepaymentSchedule extends React.PureComponent<any, any> {
  state = {
    duration: 1,
    options: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const duration = Number(nextProps.duration)
    if (duration && duration > 1 && duration !== prevState.duration) {
      let options = new Array(duration - 1).fill({})

      options = options.map((item, idx) => ({
        label: defaultLabel(idx),
        value: idx + 1,
      }))

      return { duration, options }
    }

    if (duration <= 1 && prevState.duration > 1) {
      return {
        duration: 1,
        options: [],
      }
    }

    return null
  }

  get type() {
    return this.props.value.type
  }

  get shareDate() {
    return this.props.value.shareDate
  }

  get schedule() {
    return this.props.value.schedule ? this.props.value.schedule : []
  }

  onChange = ({ value }) => {
    const schedule = [...this.schedule]

    if (value === "individual") {
      schedule.push(SCHEDULE_ITEM)
      this.forceChange({ type: value, schedule, shareDate: this.shareDate })
      return
    }

    this.forceChange({
      type: value,
      schedule,
      shareDate: this.shareDate,
    })
  }

  onDateAdd = () => {
    const schedule = [...this.schedule]
    schedule.push({ ...SCHEDULE_ITEM })

    this.forceChange({
      type: this.type,
      schedule,
      shareDate: this.shareDate,
    })
  }

  onDateRemove = (index) => () => {
    const schedule = [...this.schedule]
    schedule.splice(index, 1)

    this.forceChange({
      type: this.type,
      schedule,
      shareDate: this.shareDate,
    })
  }

  onScheduleItemChange = (index) => (event) => {
    const { name, value } = event.target
    const schedule = [...this.schedule]

    schedule[index][name] = value

    this.forceChange({
      type: this.type,
      schedule,
      shareDate: this.shareDate,
    })
  }

  onShareDateChange = ({ value }) => {
    this.forceChange({
      type: this.type,
      schedule: this.schedule,
      shareDate: value,
    })
  }

  forceChange = (value) => {
    this.props.onChange({
      name: this.props.name,
      value,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Select
          value={this.props.value.type}
          onChange={this.onChange}
          options={REPAYMENT_SCHEDULE}
        />
        {this.props.value.type === "individual" && (
          <React.Fragment>
            {this.props.value.schedule.map((item, index) => (
              <DateRow
                key={index}
                item={item}
                onRemove={this.onDateRemove(index)}
                onChange={this.onScheduleItemChange(index)}
                index={index}
              />
            ))}
            <Box pt={18}>
              <AddButton onClick={this.onDateAdd}>Добавить</AddButton>
            </Box>
          </React.Fragment>
        )}
        {this.props.value.type === "equal_share" && (
          <FlexBox
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pt={28}
          >
            <Box width={90}>
              <Text>
                <Translate
                  i18n="legal_entity_offer_form.rows.repayment_schedule.sub_label"
                  ns="components"
                />
              </Text>
            </Box>
            <Select
              value={this.props.value.shareDate}
              onChange={this.onShareDateChange}
              options={this.state.options}
            />
            <Devider />
          </FlexBox>
        )}
      </React.Fragment>
    )
  }
}

export default RepaymentSchedule
