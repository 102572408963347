import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateForeignIndividualProfileMutation(
    $input: CreateForeignIndividualProfileInput!
  ) {
    createForeignIndividualProfile(input: $input) {
      profile {
        id
        __typename
        phone
        lastName
        firstName
        middleName
        accreditation(role: INVESTOR) {
          status
        }
      }
    }
  }
`

const updater = (store) => {
  const root = store.getRoot()
  const viewer = root.getLinkedRecord("viewer")
  const foreignProfiles = viewer.getLinkedRecords("foreignProfiles") || []
  const payload = store.getRootField("createForeignIndividualProfile")
  const profile = payload.getLinkedRecord("profile")

  viewer.setLinkedRecords([...foreignProfiles, profile], "foreignProfiles")
}

export default enhance({ mutation, updater })
