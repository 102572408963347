import styled from "styled-components"
import { textAlign, color } from "styled-system"

export * from "./wrappers"

export const TDDate = styled.td`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: rgba(57, 57, 57, 0.75);
  font-weight: 300;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }
`

export const TData = styled.td<any>`
  font-family: Geometria;
  font-size: 18px;
  line-height: 23px;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
    font-size: 16px;
  }

  ${textAlign};
  ${color};
`

export const TDBold = styled.td`
  font-family: Geometria;
  font-size: 18px;
  line-height: 23px;
  color: #4a4a4a;
  font-weight: bold;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }
`

export const TDStatus = styled.td`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color || "#4a4a4a"};

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }
`
