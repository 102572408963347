import styled, { createGlobalStyle } from "styled-components"

export const BannersContainer = styled.div`
  width: 100%;
  height: 260px;
  margin-bottom: 40px;
  position: relative;
  @media screen and (max-width: 600px) {
    height: auto;
  }
`
export const BannersWrap = styled.div`
  height: 260px;
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Banner = styled.a<any>`
  width: ${(props) => (props.isOneBanner && "100%") || "560px"};
  height: 100%;
  cursor: pointer;
  display: block;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
  @media screen and (max-width: 1280px) {
    width: ${(props) => (props.isOneBanner && "100%") || "620px"};
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
  img {
    max-width: 100%;
    width: ${(props) => (props.isOneBanner && "100%") || "auto"};
    @media screen and (max-width: 600px) {
      width: 100%;
      height: auto;
      padding: 0 10px;
    }

    @media screen and (min-width: 600px) and (max-width: 1280px) {
      padding-right: ${(props) => (props.isOneBanner && "0") || "38px"};
    }
  }

  &:last-child {
    padding-right: 0;
    box-sizing: border-box;
  }
`
export const Dots = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  @media screen and (min-width: 1280px) {
    display: none;
  }
`
export const GlobalStyle = createGlobalStyle`
  .slick-dots{
    bottom: -30px;
  }
  .slick-dots li button:before{
    font-size: 9px;
    color: #F1F1F1;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before{
    color: #818181;
    opacity: 1;
  }

  .slick-slide{
    max-width: 560px;
  }
  .slick-track{
    max-height: 260px;
    overflow: hidden;
  }

@media screen and (min-width: 1280px){
  .slick-track{
    display: flex !important;
    justify-content: space-between;
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
  }
  .slick-slide{
    width: 560px !important;
  }
}
@media screen and (min-width: 1131px) and (max-width: 1280px){
   .slick-track{
    display: flex;
    justify-content: space-between;
    &::before{
      display: none;
    }
    &::after{
      display: none;
    }
      ${Banner} > img{
        padding-right: 0;
      }
      .slick-slide{
         &:first-child{
          padding-right: 40px;
          box-sizing: content-box;
        }
      }
  }
}
`
