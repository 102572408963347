import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation ConfirmForeignBankAccountWithPhoneMutation(
    $input: ConfirmBankAccountWithPhoneInput!
  ) {
    confirmBankAccountWithPhone(input: $input) {
      foreignAccount {
        id
        status
        ownerName
        IBAN
        bank {
          name
          SWIFT
          address
        }
      }
    }
  }
`
export default enhance({ mutation })
