import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateInvestorIncomeReportMutation(
    $input: CreateInvestorIncomeReportInput!
  ) {
    createInvestorIncomeReport(input: $input) {
      url
    }
  }
`

export default enhance({ mutation })
