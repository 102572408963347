import React from "react"
import i18next from "i18next"

import { Box, Text } from "src/components"

import Snow from "./Snow"
import {
  StyledLink,
  LeadingRibbon,
  BallContainer,
  TitleContainer,
  BannerContainer,
} from "./styles"

/* eslint-disable max-len */
const render = () => {
  const { language } = i18next

  return language === "ru" ? (
    <>
      <BannerContainer>
        <LeadingRibbon src="/banners/investor/ribbon1.png" />
        <Snow width={60} height={60} left={12} top={-30} />
        <Snow width={37} height={37} left={161} bottom={-15} />
        <TitleContainer>
          <Box mr={13}>
            <Text fontSize="18px" fontWeight="bold">
              Инвестиции сбываются!
            </Text>
          </Box>
          <Text fontSize="15px">
            Стань инвестором до Нового года и получай доход без комиссий!
          </Text>
          <Snow width={37} height={37} right={141} top={-15} />
        </TitleContainer>
        <BallContainer>
          <img src="/banners/investor/ball.png" />
          <Snow width={21} height={21} right={-30} top="calc(50% - 23px)" />
        </BallContainer>
        <StyledLink
          href="https://medium.com/karmatrust/%D0%B1%D0%BE%D0%BB%D1%8C%D1%88%D0%B5-%D0%B0%D0%BA%D1%86%D0%B8%D0%B9-238ed2b712c4"
          target="_blank"
        >
          ПОДРОБНЕЕ
        </StyledLink>
      </BannerContainer>
    </>
  ) : null
}

export default render
