import React from "react"

import { Translate } from "src/components"

import { Title } from "../elements"

import { ExtraText, ExtraContainer } from "./styles"

export const Extra = (props) => {
  const { extra, padding } = props

  if (!extra) {
    return null
  }

  if (typeof extra === "string") {
    return (
      <ExtraContainer padding={padding}>
        {
          <ExtraText>
            <Translate i18n={extra} />
          </ExtraText>
        }
      </ExtraContainer>
    )
  }

  const Component = extra

  return (
    <ExtraContainer padding={padding}>
      {
        <ExtraText>
          <Component />
        </ExtraText>
      }
    </ExtraContainer>
  )
}

const Group = (props) => {
  const { children, extra, ...rest } = props

  return (
    <React.Fragment>
      <Title {...rest}>
        <Extra extra={extra} />
      </Title>
      {children}
    </React.Fragment>
  )
}

export default Group
