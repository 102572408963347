/* eslint-disable max-len */
import React from "react"

const UserIcon = (props) => (
  <svg {...props} viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8033 9.99052C11.9864 9.14891 11.0141 8.52587 9.95329 8.14871C11.0895 7.34245 11.8359 5.9931 11.8359 4.46733C11.8359 2.00405 9.89083 0 7.5 0C5.10917 0 3.16406 2.00405 3.16406 4.46733C3.16406 5.9931 3.91052 7.34245 5.04674 8.14871C3.9859 8.52587 3.01359 9.14891 2.19671 9.99052C0.780147 11.45 0 13.3905 0 15.4545H1.17188C1.17188 11.8595 4.01065 8.93466 7.5 8.93466C10.9893 8.93466 13.8281 11.8595 13.8281 15.4545H15C15 13.3905 14.2199 11.45 12.8033 9.99052ZM7.5 7.72727C5.75534 7.72727 4.33594 6.26489 4.33594 4.46733C4.33594 2.66977 5.75534 1.20739 7.5 1.20739C9.24466 1.20739 10.6641 2.66977 10.6641 4.46733C10.6641 6.26489 9.24466 7.72727 7.5 7.72727Z" />
  </svg>
)

UserIcon.defaultProps = {
  width: "15",
  height: "16",
  fill: "red",
}

export default UserIcon
