import styled, { css } from "styled-components"
import { textAlign } from "styled-system"
import { NavLink } from "react-router-dom"

import { Icons } from "src/components"

export const InfoIcon = styled(Icons.Info)`
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
  margin-left: 2px;
  vertical-align: middle;
`

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Container = styled(Flex)`
  width: 100%;
  min-height: 420px;
  height: auto;
  max-width: 260px;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[1]};
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 30px;

  @media screen and (max-width: 1100px) {
    max-width: 100%;

    :nth-child(odd) {
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 0 0 20px 0;
  }
`

export const Header = styled(Flex)`
  flex-direction: column;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primaryBlacks[1]};
  margin: 26px 0 6px;
`

export const TitleContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
  font-weight: 500;
`

export const HeaderValue = styled(Title)`
  font-size: 26px;
  font-weight: 700;
  margin-top: 12px;
  line-height: 1;

  @media screen and (max-width: 1100px) {
    font-size: 35px;
  }
`

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
`

export const TD = styled.td<any>`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding-top: 10px;
  ${textAlign};

  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
`

export const TDBold = styled(TD)`
  font-weight: bold;
  text-align: right;
`

export const Annotation = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: auto 0 0;
`

export const TRWithBorder = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryBlacks[1]};

  td {
    padding-bottom: 16px;
  }
`

export const ActionContainer = styled(Flex)`
  margin-top: auto;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

const Button = styled.button<any>`
  width: 100%;
  height: 45px;
  border: none;
  padding: 0;
  position: relative;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryBlack};
  box-sizing: border-box;
  border-radius: 5px;

  ${({ compact }) => compact
    && css`
      max-width: 48%;
    `}
`

export const OutlinedButton = styled(Button)`
  background-color: inherit;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const RedButton = styled(Button)`
  background: ${({ theme }) => theme.colors.mainRed};
  box-shadow: 0 5px 10px rgba(255, 116, 143, 0.55);
  color: ${({ theme }) => theme.colors.white};
`

export const Description = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
`

export const ButtonLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
`

export const TooltipContainer = styled.div`
  width: 100%;
  max-width: 240px;
  white-space: pre-line;
`

export const RatingContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const RatingDivider = styled(Divider)`
  margin-top: 0;
`

export const RatingLabel = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: left;
  margin: 0 8px 0 0;

  @media screen and (max-width: 1100px) {
    font-size: 14px;
    line-height: 26px;
  }
`

export const RatingTotal = styled(RatingLabel)`
  margin: 0;
  text-align: right;
  line-height: 24px;
`

export const RatingRank = styled.span`
  font-size: 18px;
  font-weight: bold;
  font-family: Geometria, sans-serif;
  font-style: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 1100px) {
    line-height: 26px;
  }
`
