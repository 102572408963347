import React from "react"

import { BankAccountStatuses } from "src/constants"
import { Box, Text, Translate } from "src/components"

import { StyledText } from "../styles"

const statusColor = (status) => (status === BankAccountStatuses.approved ? "#6FA84B" : "primaryRed")

const render = ({ edit, status }) => (
  <Box mb="25px">
    <Box mb="32px">
      <Text
        color="primaryBlack"
        fontSize="1.1875rem"
        fontWeight="600"
        lineHeight="1.5"
      >
        <Translate
          i18n={`components:account.bank_account.info.${
            edit ? "requisites_edit" : "requisites"
          }`}
        />
      </Text>
      {!edit && (
        <Text color={statusColor(status)} fontSize="1rem" lineHeight="1.15">
          <Translate
            i18n={`account.bank_account.info.status.${status}`}
            ns="components"
          />
        </Text>
      )}
    </Box>
    <StyledText maxWidth="595px" mr="-7px" fontSize={["0.875rem", "1rem"]}>
      <Translate ns="components" i18n="account.bank_account.edit.info" />
    </StyledText>
  </Box>
)

export default React.memo(render)
