/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ChangePasswordModalQueryVariables = {};
export type ChangePasswordModalQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Form_viewer" | "Header_viewer">;
    } | null;
};
export type ChangePasswordModalQuery = {
    readonly response: ChangePasswordModalQueryResponse;
    readonly variables: ChangePasswordModalQueryVariables;
};



/*
query ChangePasswordModalQuery {
  viewer {
    id
    ...Form_viewer
    ...Header_viewer
  }
}

fragment Form_viewer on User {
  password {
    present
    updated_at
  }
}

fragment Header_viewer on User {
  email
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePasswordModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Form_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Header_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChangePasswordModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserPassword",
            "kind": "LinkedField",
            "name": "password",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "present",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated_at",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ChangePasswordModalQuery",
    "operationKind": "query",
    "text": "query ChangePasswordModalQuery {\n  viewer {\n    id\n    ...Form_viewer\n    ...Header_viewer\n  }\n}\n\nfragment Form_viewer on User {\n  password {\n    present\n    updated_at\n  }\n}\n\nfragment Header_viewer on User {\n  email\n}\n"
  }
};
})();
(node as any).hash = 'abf64c4168c6e558b9b5720aa75e6e65';
export default node;
