import React from "react"

import {
  Box, Text, Flex, Link, Button, Translate,
} from "src/components"

import {
  SubHeader, StyledText, StyledLink, SuccessContainer,
} from "./styles"

const render = ({ orderNumber, amount }) => (
  <SuccessContainer>
    <Box mb="10px">
      <Text
        fontSize="24px"
        fontWeight="500"
        lineHeight="1.25"
        textAlign="center"
      >
        <Translate i18n="components:investment_modals.success.title" />
      </Text>
    </Box>
    <Box mb="10px">
      <SubHeader textAlign="center">
        <Translate
          i18n="components:investment_modals.success.subtitle"
          orderNumber={orderNumber}
          amount={amount}
        />
      </SubHeader>
      <SubHeader textAlign="center">
        <Translate i18n="components:investment_modals.success.my_investments.prefix" />
        &nbsp;
        <StyledLink to="/orders?type=investments">
          <Translate i18n="components:investment_modals.success.my_investments.link" />
        </StyledLink>
        <Translate i18n="components:investment_modals.success.my_investments.suffix" />
      </SubHeader>
    </Box>
    <Box mb="30px">
      <StyledText textAlign="center">
        <Translate i18n="components:investment_modals.success.info" />
      </StyledText>
    </Box>
    <Box mb="20px">
      <SubHeader textAlign="center">
        <Translate i18n="components:investment_modals.success.more_offers" />
      </SubHeader>
    </Box>
    <Flex justifyContent="center">
      <Link to="/market">
        <Button height="48px" variant="blueWide">
          <Translate i18n="components:investment_modals.success.market" />
        </Button>
      </Link>
    </Flex>
  </SuccessContainer>
)

export default render
