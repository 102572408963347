import React, { useState, useRef, useEffect } from "react"
import { fetchQuery, graphql } from "relay-runtime"

import { useEnvironment } from "src/hooks"
import { MIN_INVESTMENT_AMOUNT } from "src/constants"

import PaymentSchedule from "./PaymentSchedule"
import MobilePaymentSchedule from "./MobilePaymentSchedule"

import { isDigit, divideNumber } from "./utils"

const query = graphql`
  query ModalPaymentScheduleQuery($orderId: ID!, $amount: Float!) {
    preSchedule(orderId: $orderId, amount: $amount) {
      date
      payDate
      loan
      state
      total
      total_gross
      interest
      interest_fee
      personal_tax
    }
  }
`

function Render(props) {
  const {
    id, chain, application,
  } = props.data

  const { environment } = useEnvironment()
  const gatheredAmount = chain?.gatheredAmount || 0
  const maxAmount = application.data.maxValue - gatheredAmount
  const initialAmount = divideNumber(maxAmount)
  const [amount, setAmount] = useState(initialAmount)
  const [schedule, setSchedule] = useState([])
  const [isFetching, setFetching] = useState(false)
  const timer = useRef(null)
  const [errorMaxSum, setErrorMaxSum] = useState("")

  const pageWidth = window.document.documentElement.clientWidth

  async function fetchSchedule(val) {
    setFetching(true)

    const variables = {
      orderId: id,
      amount: Number(val),
    }

    try {
      const { preSchedule } = await fetchQuery(environment, query, variables) as any
      setSchedule(preSchedule)
      setFetching(false)
    } catch {
      setFetching(false)
    }
  }

  function getSchedule(val?: any, isFirst?:any) {
    clearTimeout(timer.current)

    const value = val.replace(/\D/g, "")

    if (!Number(value) || Number(value) < MIN_INVESTMENT_AMOUNT) {
      return
    }

    if (isFirst) {
      fetchSchedule(value)
      return
    }

    timer.current = setTimeout(() => {
      fetchSchedule(value)
    }, 1000)
  }

  function changeAmount(e) {
    const value = e.target.value.replace(/\D/g, "")
    setErrorMaxSum("")
    if (!isDigit(value) || value === amount) return

    setAmount(divideNumber(value))

    if (Number(value) > maxAmount) {
      setErrorMaxSum(`В заявке осталось собрать ${divideNumber(maxAmount)} ₽`)
      return
    }

    getSchedule(value)
  }

  useEffect(() => {
    getSchedule(amount, true)
  }, [])

  const allProps = {
    ...props,
    schedule,
    isFetching,
    changeAmount,
    errorMaxSum,
    amount,
  }

  const RenderSchedule = pageWidth > 790 ? PaymentSchedule : MobilePaymentSchedule

  return <RenderSchedule {...allProps} />
}

export default Render
