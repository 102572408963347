import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react"

import {
  Translate,
  LoaderBox,
  MiniLoader,
  LoadMore,
  ConfirmMutationModal,
} from "src/components"
import { CurrentProfileContext } from "src/context"
import { InvestmentCompletedOrdersListQueryContainer } from "src/query"
import { getOrderTicker, toCurrencyFormat, getDebtURL } from "src/utils"
import { SellOrder } from "src/mutations"

import {
  TData,
  TDName,
  TDNameLink,
  TDBold,
  TDDate,
  CompletedStatus,
  Graph,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
  SellButton,
  DebtLink,
} from "./styles"
import { ClockIcon, GraphIcon } from "./icons"
import { Table, List, SellOrderModal } from "../Elements"
import {
  parseInvestments,
  getPaymentAmount,
  getOrderName,
  getOrderDuration,
  getOrderRate,
  getOrdernFinishDate,
} from "./utils"
import { InvestmentCompletedCard } from "../Cards"

export const statuses = new Map([
  [
    "ok",
    {
      text: "Выплаты по графику",
      color: "#6FA84B",
    },
  ],
  [
    "pastdue",
    {
      text: "Задержка платежа",
      color: "#F70000",
    },
  ],
])

export function RenderedTable(props) {
  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
    filter,
    setFilter,
  } = props

  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [isSellOpened, setSellOpened] = useState(false)
  const [isSellConfirmOpened, setSellConfirmOpened] = useState(false)
  const [sellOrder, setSellOrder] = useState<any>()

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  const openSellModal = useCallback(
    (order) => () => {
      setSellOrder(order)
      setSellOpened(true)
    },
    [setSellOpened, setSellOrder],
  )

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>
              {getOrderName(obj)}
            </TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => (
          <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>
        ),
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Срок",
        render: (i, obj) => (
          <TData key={i}>
            {getOrderDuration(obj) && (
              <>
                {getOrderDuration(obj)} <Translate i18n={"models:loan.month"} />
              </>
            )}
            {!getOrderDuration(obj) && "_"}
          </TData>
        ),
        placeholder: (i) => (
          <TData key={i}>
            0 <Translate i18n={"models:loan.month"} />
          </TData>
        ),
      },
      {
        title: "Инвестиции, ₽",
        render: (i, obj) => (
          <TDBold key={i}>{toCurrencyFormat(obj.amount)}</TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Выплачено, ₽",
        render: (i, obj) => (
          <TDBold key={i}>
            {toCurrencyFormat(getPaymentAmount(obj, profileId))}
          </TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Погашение займа",
        render: (i, obj) => (
          <TDDate key={i}>
            {ClockIcon} {getOrdernFinishDate(obj)}
          </TDDate>
        ),
        placeholder: (i) => <TDDate key={i}>{ClockIcon} -</TDDate>,
      },
      {
        title: "Статус",
        render: (i, obj) => {
          const status = statuses.get(
            obj.paymentScheduleList[0] && obj.paymentScheduleList[0].info.state,
          ) || {
            text: "Неизвестно",
            color: "grey",
          }

          const isPastDue = obj.paymentScheduleList[0]
            && obj.paymentScheduleList[0].info.state === "pastdue"

          return isPastDue ? (
            <CompletedStatus color={status.color} key={i}>
              <DebtLink
                href={getDebtURL(obj.chain?.id)}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Graph>{GraphIcon}</Graph>
                {status.text}
              </DebtLink>
            </CompletedStatus>
          ) : (
            <CompletedStatus color={status.color} key={i}>
              <Graph>{GraphIcon}</Graph>
              {status.text}
            </CompletedStatus>
          )
        },
        placeholder: (i) => (
          <CompletedStatus color={"#6FA84B"} key={i}>
            <Graph>{GraphIcon}</Graph>
          </CompletedStatus>
        ),
      },
      {
        title: "Продать заем",
        render: (i, obj) => (
          <TDDate key={i}>
            {!!obj.karma?.purchase?.isEnable && (
              <SellButton
                disabled={!obj.karma?.purchase?.isEnable}
                onClick={openSellModal(obj)}
              >
                Продать
              </SellButton>
            )}
          </TDDate>
        ),
        placeholder: (i) => <TDDate key={i} />,
      },
    ]
  }

  const { investor } = data || {}
  const orders = useMemo(() => [...parseInvestments(investor)], [investor])
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.investor?.investments?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  const sellOrderSubmit = useCallback(() => {
    setSellConfirmOpened(true)
    setSellOpened(false)
  }, [setSellConfirmOpened, setSellOpened])

  const closeSellConfirmModal = useCallback(() => {
    setSellOrder(undefined)
    setSellConfirmOpened(false)
  }, [setSellOrder, setSellConfirmOpened])

  const sellConfirmVariables = useMemo(
    () => ({
      input: {
        profileId,
        orderId: sellOrder?.id,
      },
    }),
    [profileId, sellOrder],
  )

  const hasFilter = Boolean(Object.values(filter).find(Boolean))

  return (
    <div>
      {(orders.length > 0 || isFetching || hasFilter) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="ДЕЙСТВУЮЩИЕ ЗАЙМЫ"
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
                setFilter={setFilter}
                filter={filter}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              title="ДЕЙСТВУЮЩИЕ ЗАЙМЫ"
              items={orders}
              Render={InvestmentCompletedCard}
              isFetching={isFetching}
              toProposal={toProposal}
              openSellModal={openSellModal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
              setFilter={setFilter}
              filter={filter}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}

          {isSellOpened && (
            <SellOrderModal
              isOpened={isSellOpened}
              onClose={() => setSellOpened(false)}
              order={sellOrder}
              onSubmit={sellOrderSubmit}
            />
          )}

          {isSellConfirmOpened && (
            <ConfirmMutationModal
              profile={profile}
              title="Подтвердите продажу долга"
              onClose={closeSellConfirmModal}
              mutation={SellOrder}
              mutationName="sellOrder"
              isOpened={isSellConfirmOpened}
              onError={closeSellConfirmModal}
              onComplete={closeSellConfirmModal}
              order={sellOrder}
              variables={sellConfirmVariables}
            />
          )}
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  return (
    <InvestmentCompletedOrdersListQueryContainer
      profile={profile}
      render={(data) => <RenderedTable {...rest} {...data} />}
    />
  )
}
