/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RequestOrderApprovalInput = {
    token?: string | null;
    orderId: string;
};
export type RequestOrderApprovalMutationVariables = {
    input: RequestOrderApprovalInput;
};
export type RequestOrderApprovalMutationResponse = {
    readonly requestOrderApproval: {
        readonly order: {
            readonly id: string;
        };
    };
};
export type RequestOrderApprovalMutation = {
    readonly response: RequestOrderApprovalMutationResponse;
    readonly variables: RequestOrderApprovalMutationVariables;
};



/*
mutation RequestOrderApprovalMutation(
  $input: RequestOrderApprovalInput!
) {
  requestOrderApproval(input: $input) {
    order {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestOrderApprovalInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestOrderApprovalPayload",
    "kind": "LinkedField",
    "name": "requestOrderApproval",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestOrderApprovalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestOrderApprovalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RequestOrderApprovalMutation",
    "operationKind": "mutation",
    "text": "mutation RequestOrderApprovalMutation(\n  $input: RequestOrderApprovalInput!\n) {\n  requestOrderApproval(input: $input) {\n    order {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '043457c78eaf1dc614c3fb91dd1e2a19';
export default node;
