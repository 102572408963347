import React from "react"
import {
  Box,
  Text,
  Translate,
  FormLoadingButton,
} from "src/components"

const FormControls = (props) => (
  <Box ml="auto">
    <FormLoadingButton
      green
      isLoading={props.isLoading}
      onClick={props.onSubmit}
    >
      <Translate i18n="juristic_profile_form.buttons.save" ns="components" />
    </FormLoadingButton>
  </Box>
)

const FormHeader = ({ inEdit }) => (
  <Box pb={30}>
    <Text fontSize={30}>
      {inEdit ? (
        <Translate ns="components" i18n="juristic_profile_form.title.update" />
      ) : (
        <Translate ns="components" i18n="juristic_profile_form.title.create" />
      )}
    </Text>
  </Box>
)

const Legend = (props) => (
  <Box pt={10}>
    <Text color="greyDarker" fontSize={props.fontSize} whiteSpace={"pre-line"}>
      {props.children}
    </Text>
  </Box>
)

export { Legend, FormControls, FormHeader }
