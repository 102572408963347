import memoize from "memoize-one"

const match = (fields, key) => {
  if (["string", "number"].indexOf(typeof fields) !== -1) return fields === key
  if (fields.indexOf) return fields.indexOf(key) !== -1

  return false
}

export const omit = memoize((obj, fields) => Object.keys(obj).reduce((accum, propName) => {
  if (match(fields, propName)) return accum
  return { ...accum, [propName]: obj[propName] }
}, {}))

export const pick = memoize((obj, fields) => Object.keys(obj).reduce((accum, propName) => {
  if (match(fields, propName)) return { ...accum, [propName]: obj[propName] }
  return accum
}, {}))

// клонирует значение и все его вложенные поля
// работает с простыми массивами/объектами, может неправильно работать при клонировании экземпляров классов
export const cloneDeep = (value) => {
  if (!value || typeof value !== "object") return value

  if (Array.isArray(value)) return value.map((item) => cloneDeep(item))

  return Object.keys(value).reduce((accum, propName) => {
    if (Array.isArray(value[propName])) {
      return {
        ...accum,
        [propName]: value[propName].map((item) => cloneDeep(item)),
      }
    }

    if (typeof value[propName] !== "object") return { ...accum, [propName]: value[propName] }

    return { ...accum, [propName]: cloneDeep(value[propName]) }
  }, {})
}

export const getProperty = (obj, path, defaultValue?: any) => {
  const tokens = path.split(/[.[\]]+/g).filter(Boolean)

  if (!obj || typeof obj !== "object") return defaultValue

  if (tokens.length === 1) {
    return obj[tokens[0]] === undefined ? defaultValue : obj[tokens[0]]
  }

  return getProperty(obj[tokens[0]], tokens.slice(1).join("."), defaultValue)
}

export const groupBy = (arr, path) => {
  if (!path || typeof path !== "string") return {}

  return Object.keys(arr).reduce((accum, key) => {
    const value = getProperty(arr[key], path)

    if (accum[value]) return { ...accum, [value]: [...accum[value], arr[key]] }

    return { ...accum, [value]: [arr[key]] }
  }, {})
}

export const uniqueBy = (items, path) => {
  const met = {}
  return items.reduce((memo, item) => {
    const key = getProperty(item, path)

    if (met[key]) {
      return memo
    }

    met[key] = true
    return [...memo, item]
  }, [])
}

/*
 * -> string=test, length=10, fullElement=i
 * <- test
 * */
export const fillStringToLength = (string, length, fillElement = "_") => {
  const stringArray = string.split("")

  if (stringArray >= length) {
    return stringArray.join()
  }

  for (let i = string.length - 1; i < length; i += 1) {
    stringArray.push(fillElement)
  }

  return stringArray.join("")
}

export const removeNonNumeric = (string) => {
  if (string && string.length > 0) {
    return string.replace(/\D/g, "")
  }

  return ""
}

export const removeWhiteSpaces = (string) => {
  if (string && string.length > 0) {
    return string.replace(/ /g, "")
  }

  return ""
}

export const levenshteinDistance = (str1, str2) => {
  if (!str1.length || !str2.length) {
    return str1.length || str2.length
  }

  const matrix = new Array(str2.length + 1).fill(0).map((item, idx) => [idx])

  matrix[0] = new Array(str1.length + 1).fill(0).map((item, idx) => idx)

  str2.split("").forEach((str2Char, str2Idx) => {
    str1.split("").forEach((str1Char, str1Idx) => {
      if (str1Char === str2Char) {
        matrix[str2Idx + 1][str1Idx + 1] = matrix[str2Idx][str1Idx]
      } else {
        const minCost = Math.min(
          matrix[str2Idx][str1Idx],
          matrix[str2Idx + 1][str1Idx],
          matrix[str2Idx][str1Idx + 1],
        )

        matrix[str2Idx + 1][str1Idx + 1] = minCost + 1
      }
    })
  })

  return matrix[str2.length][str1.length]
}

export function divideNumber(num) {
  return String(num)
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
    .replace(".", ",")
}

export function monthDeclension(n) {
  const months = ["месяц", "месяца", "месяцев"]

  // eslint-disable-next-line no-nested-ternary
  const plural = n % 10 === 1 && n % 100 !== 11
    ? 0
    : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? 1
      : 2

  return months[plural]
}

export function roundNumber(num) {
  return num >= 1000000
    ? `${parseFloat((num / 1000000).toFixed(1))}M`
    : `${parseFloat((num / 1000).toFixed(1))}K`
}

export const getURLSearchParams = (name) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}
