import React from "react"

import {
  ModalContainer, Box, Icons, Button,
} from "src/components"

import {
  Container,
  CloseButton,
  ReturnButton,
  Title,
  ControlsContainer,
  StyledText,
} from "./styles"

const DeleteInfo = (props) => (
  <ModalContainer {...props}>
    <Container>
      <ReturnButton onClick={props.onClose}>
        <Icons.LeftArrow />
        <span>Назад</span>
      </ReturnButton>

      <CloseButton onClick={props.onClose}>
        <Icons.Close />
      </CloseButton>

      <Title>Автоинвестирование Деактивировано</Title>

      <Box m="20px 0">
        <StyledText>Шаблон автоинвестирования удален</StyledText>
      </Box>

      <ControlsContainer>
        <Box mr="20px">
          <Button variant="primary" height="48px" onClick={props.onClose}>
            Закрыть
          </Button>
        </Box>
      </ControlsContainer>
    </Container>
  </ModalContainer>
)

export default DeleteInfo
