import styled from "styled-components"

import { Box, Button } from "src/components"

export const StyledButton = styled(Button)`
  height: 45px;
  width: 48%;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const Scrollable = styled(Box)`
  max-height: 85vh;
  overflow-y: auto;
`

export const StyledLink = styled.a`
  text-decoration: none;
`

export const ModalBox = styled(Box)`
  width: 100%;
  max-width: 600px;
  padding: 35px;
  color: initial;

  @media (max-width: 479px) {
    padding: 40px 15px 0;
  }
`

export const TextError = styled.p`
  font-family: Geometria, sans-serif;
  color: ${({ theme }) => theme.colors.mainRed};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
`
