export function getIncome(array) {
  let income = 0
  for (let i = 0; i < array.length; i += 1) {
    income += array[i].interest
  }
  return income
}

export function getAverageIncome(array) {
  if (array.length < 1) {
    return 0
  }

  return getIncome(array) / array.length
}

export function getTitle(isCession) {
  return !isCession ? "Предварительный график платежей" : "График платежей"
}

export function isDigit(str) {
  const regExp = /^(?=.*\d)[\d ]+$/g
  return !str || regExp.test(str)
}

export function divideNumber(num) {
  return String(num).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
}
