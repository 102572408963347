export function getDate(date) {
  const dateArr = date.split("-")
  return dateArr.reverse().join(".")
}

export function getStatus(payDate, state) {
  const now = Date.now()
  const date = new Date(payDate).getTime()
  const status = now > date ? "delay" : state

  switch (status) {
    case "paid": {
      return { color: "#6FA84B", text: "Выплачен" }
    }

    case "delay": {
      return { color: "#F70000", text: "Задержка платежа" }
    }

    case "new":
    default: {
      return { color: "rgba(74, 74, 74, 0.35)", text: "Планируется" }
    }
  }
}

export function displayData(data, decimal = 2) {
  const arr = `${data.toFixed(decimal)}`.split(".")
  const output = arr.join(",").replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")

  return `${output} ₽`
}

export function getMonth(index) {
  return `${index + 1}-й месяц`
}
