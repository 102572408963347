import { pipe, notifyYM, notifyGTM } from "src/utils"

const onRegisterClick = () =>
  pipe(notifyGTM({ eventLabel: "registration", eventAction: "next-step" }), notifyYM("reg-next"))

const RussianLangUI = ["BY", "RU", "UA", "KZ", "MD", "GE"]
const KR = "KR"
const LocaleEnum = {
  ru: "RU",
  ko: "KO",
  en: "EN",
}

export const getDefaultLocale = (code) => {
  if (RussianLangUI.includes(code)) {
    return LocaleEnum.ru
  }

  if (code === KR) {
    return LocaleEnum.ko
  }

  return LocaleEnum.en
}

export default onRegisterClick
