import React from "react"
import Relay, { graphql } from "react-relay"

import { Box } from "src/components"

import { Coverage, ProvisionItem } from "./Elements"

const provisionItemsMapFn = (value, coverage) => (item) => (
  <ProvisionItem
    key={item.id}
    item={item}
    overallValue={value}
    overallCoverage={coverage}
  />
)

const Provision = ({ order: { provision } }) => {
  const { items, value, coverage } = provision

  return (
    <Box width="885px">
      <Coverage coverage={coverage} />
      {items.map(provisionItemsMapFn(value, coverage))}
    </Box>
  )
}

export default Relay.createFragmentContainer(Provision, {
  order: graphql`
    fragment Provision_order on Order {
      provision {
        items {
          id
          kind
          name
          value
          description
          images {
            id
            url
            width
            height
            filename
          }
          videos {
            id
            url
            title
            description
            video {
              url
            }
          }
          documents {
            id
            url
            filename
          }
        }
        value
        coverage
      }
    }
  `,
})
