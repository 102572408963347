import styled from "styled-components"
import { NavLink } from "react-router-dom"

export * from "./wrappers"

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  font-family: Geometria, sans-serif;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background: #222222;
  position: relative;
`

export const FooterWrap = styled.div`
  width: 1280px;
  margin: 0 auto;
  min-width: 320px;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 30px 50px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  @media screen and (max-width: 1280px) {
    width: 100%;
    overflow: hidden;
  }
  @media screen and (max-width: 680px) {
    justify-content: center;
  }
`

export const HeaderLogoWrap = styled.div``
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`
export const FooterInfo = styled.div`
  margin-right: 50px;
`

export const FooterLink = styled(NavLink)`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ebebeb;
  cursor: pointer;
  text-decoration: none;
  margin: 0 16px 0 0;
`

export const Version = styled.div`
  position: absolute;
  bottom: 6px;
  right: 6px;
  opacity: 0.5;
  @media screen and (max-width: 680px) {
    width: 100%;
    text-align: center;
  }
`
