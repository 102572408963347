import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;

  :not(:first-of-type) {
    margin-top: 60px;
  }
`

export const Field = styled.div`
  position: relative;
  width: 100%;

  :not(:first-child) {
    margin-top: 10px;
  }
`

export const Title = styled.h4`
  font-family: Geometria, sans-serif;
  font-size: 19px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 0 26px;
  font-weight: 700;
  max-width: 600px;
`
