import React from "react"

import { Text, Translate, AttachmentLink } from "src/components"

import { OpenLink, SliderStateContainer } from "../styles"

export default (props) => {
  const { attachmentId, length, activeSlide } = props

  return (
    <SliderStateContainer>
      {length > 1 && (
        <Text fontSize={14}>
          {activeSlide + 1} из {length}
        </Text>
      )}
      <AttachmentLink
        component={OpenLink}
        attachmentId={attachmentId}
      >
        <Translate i18n="user.proposal.buttons.open_original_view" ns="pages" />
      </AttachmentLink>
    </SliderStateContainer>
  )
}
