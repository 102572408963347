import styled from "styled-components"

export const Window = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 94%;
  height: auto;
  background: #ffffff;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  font-family: Geometria;
  padding: 24px;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: rgba(74, 74, 74, 0.75);
  margin: 0;
  margin-top: 20px;
  font-weight: 600;
`

export const Button = styled.button`
  position: relative;
  padding: 12px 0;
  background-color: inherit;
  border: 1px solid #f70000;
  box-sizing: border-box;
  border-radius: 4px;
  color: #f70000;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  width: 165px;
  text-transform: uppercase;
  margin-top: 20px;
`

export const Closer = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 4px;
  background-color: inherit;
  border: none;
`
