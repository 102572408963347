import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetViewerLocaleMutation($input: SetViewerLocaleInput!) {
    setViewerLocale(input: $input) {
      viewer {
        id
      }
    }
  }
`

export default enhance({ mutation })
