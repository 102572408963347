import styled from "styled-components"

export const Badge = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
  max-width: max-content;
  margin-top: 11px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: ${({ theme, color }) => theme.colors[color]};
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: white;
`
export const TooltipContainer = styled.div`
  position: relative;
  top: -3px;
`

export const Container = styled.div`
  display: flex;
  min-width: 95px;
`
