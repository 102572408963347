import React from "react"

import { Translate } from "src/components"
import { WITHDRAWAL_FEE } from "src/constants"

import {
  pipe,
  number,
  getProperty,
  isForeignBankAccount,
  createChainedFunction,
  getBankAccountsFromForeignProfile,
} from "src/utils"

const options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const formatNumber = (str) => {
  if (!str) return ""

  const formatted = str
    .split(",")
    .map((item) => item.replace(/\D/g, ""))
    .join(".")

  const hasDivider = formatted.split(".").length > 1
  const fraction = formatted.split(".")[1]

  if (hasDivider && (!fraction || !Number(fraction))) {
    return `${number(formatted, options, "ru")},${fraction.slice(0, 2)}`
  }

  return number(formatted, options, "ru")
}

export const useWithdrawAmount = () => {
  const [value, setValue] = React.useState("")
  const onChange = React.useCallback((e) => {
    const formatted = formatNumber(e.target.value)

    setValue(formatted)
  }, [])

  return [value, onChange]
}

export const useOnCompleteCallbacks = (items, setActiveModal, hideModal) => React.useMemo(
  () => Object.keys(items).reduce((memo, key) => {
    const cb = () => (items[key].next ? setActiveModal(items[key].next) : hideModal())

    return { ...memo, [key]: cb }
  }, {}),
  [items],
)

export const useBankRequisitesSchema = (profile) => {
  const bankAccount = getBankAccountsFromForeignProfile(profile)[0]
  const inn = getProperty(profile, "inn")

  const schema = [
    inn && {
      name: "components:account.bank_account.info.account_info.inn",
      value: inn,
    },
  ]

  return React.useMemo(() => {
    if (isForeignBankAccount(bankAccount)) {
      const requisites = [
        {
          name: "components:account.bank_account.info.account_info.ownerName",
          value: bankAccount.ownerName,
        },
        {
          name: "components:account.bank_account.info.account_info.name",
          value: bankAccount.bank.name,
        },
        {
          name: "components:account.bank_account.info.account_info.address",
          value: bankAccount.bank.address,
        },
        {
          name: "components:account.bank_account.info.account_info.SWIFT",
          value: bankAccount.bank.SWIFT,
        },
        {
          name: "components:account.bank_account.info.account_info.IBAN",
          value: bankAccount.IBAN,
        },
      ]

      return schema.concat(requisites)
    }

    const requisites = [
      {
        name: "components:account.bank_account.info.account_info.name",
        value: bankAccount.bank.name,
      },
      {
        name: "components:account.bank_account.info.account_info.bic",
        value: bankAccount.bank.bic,
      },
      {
        name:
          "components:account.bank_account.info.account_info.checking_account",
        value: bankAccount.checkingAccount,
      },
      {
        name:
          "components:account.bank_account.info.account_info.correspondent_account",
        value: bankAccount.correspondentAccount,
      },
    ]

    return schema.concat(requisites)
  }, [profile])
}

const minValueError = {
  keyword: "min",
  path: "requestWithdrawal.amount",
}

const getErrorTranslation = (error) => error && <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />

export const useOnSubmit = (props) => React.useCallback(() => {
  const amountAsNumber = Number(
    props.withdrawAmount.replace(",", ".").replace(/\s/g, ""),
  )
  if (amountAsNumber + WITHDRAWAL_FEE > props.availableBalance) {
    props.setManualError(minValueError)
    return
  }

  props.onComplete()
}, [props.withdrawAmount])

export const useOnChange = (props) => React.useCallback(
  createChainedFunction(
    () => props.clearError("requestWithdrawal.amount"),
    props.onChange,
  ),
  [],
)

export const useErrors = (props) => React.useCallback(
  (path) => pipe(props.getError, getErrorTranslation)(path),
  [],
)
