import React from "react"
import memoize from "memoize-one"

import { OrderTypeConsumer, OrderDataConsumer } from "src/context"

import { number, getGatheredPercents } from "src/utils"

import { StyledFlex } from "./styles"

import ExhibitionItem from "../ExhibitionItem"

const ComposedConsumer = (props) => (
  <OrderTypeConsumer>
    {(type) => (
      <OrderDataConsumer>
        {(data) => props.children({
          orderType: type,
          orderData: data,
        })
        }
      </OrderDataConsumer>
    )}
  </OrderTypeConsumer>
)

const isValidOrderType = (orderType) => ["CompletedOrder", "CompletedGuestOrder"].includes(orderType)
const isValidOrderStatus = (orderStatus) => ["COMPLETE", "SUCCEEDED", "TRANSFER"].includes(orderStatus)

const shouldRenderPercents = memoize(
  (orderType, orderStatus) => isValidOrderType(orderType) && isValidOrderStatus(orderStatus),
)

const getItemValue = (guest, value) => (guest ? "sign up to check" : value)

const TargetValues = (props) => {
  const { guest, orderType, orderData } = props

  const { chain, application } = orderData

  return (
    <StyledFlex>
      <ExhibitionItem
        label="loan.gathered"
        value={`${number(chain.gatheredAmount)} ₽`}
      />
      {shouldRenderPercents(orderType, orderData.status) ? (
        <ExhibitionItem
          label="loan.from_target"
          value={getItemValue(
            guest,
            `${getGatheredPercents(
              application.data.minValue,
              chain.gatheredAmount,
            )} %`,
          )}
          alignItems="flex-end"
          pr={0}
          isLast={true}
          guest={guest}
        />
      ) : (
        <ExhibitionItem
          label="loan.target"
          value={getItemValue(guest, `${number(application.data.maxValue)} ₽`)}
          alignItems="flex-end"
          pr={0}
          isLast={true}
          guest={guest}
        />
      )}
    </StyledFlex>
  )
}

export default (props) => (
  <ComposedConsumer>
    {(renderProps) => <TargetValues {...props} {...renderProps} />}
  </ComposedConsumer>
)
