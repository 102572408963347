/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SupportingDocumentStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ProfileInvestorAccreditation_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly investor?: {
        readonly investedAmount: number;
        readonly qualified: boolean;
    } | null;
    readonly supportingDocuments?: ReadonlyArray<{
        readonly id: string;
        readonly status: SupportingDocumentStatus | null;
    }>;
    readonly " $refType": "ProfileInvestorAccreditation_profile";
};
export type ProfileInvestorAccreditation_profile$data = ProfileInvestorAccreditation_profile;
export type ProfileInvestorAccreditation_profile$key = {
    readonly " $data"?: ProfileInvestorAccreditation_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileInvestorAccreditation_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileInvestorAccreditation_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualProfileInvestor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "investedAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qualified",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SupportingDocument",
          "kind": "LinkedField",
          "name": "supportingDocuments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '56d89f74f0d75f412226176d5bc5fdda';
export default node;
