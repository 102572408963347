import styled from "styled-components"
import { Box, Text as BaseText } from "src/components"

export const Container = styled(Box)`
  margin: 10px 0;
`

export const PortfolioContainer = styled(Box)`
  padding: 15px 0;
  font-family: Geometria;
  font-size: 1rem;
  width: 100%;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`

export const Text = styled(BaseText)`
  font-size: 14px;
  white-space: pre-line;
`

export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 26px;

  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }
`

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  @media screen and (min-width: 901px) {
    flex-direction: row;

    & > * + * {
      margin-left: 16px;
    }
  }
`

export const DownloadButton = styled.a<{ disabled?: boolean, busy?: boolean }>`
  flex-shrink: 0;
  font-family: Geometria, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  color: #3a5bbc;
  text-decoration: none;
  cursor: ${({ busy }) => (busy ? "wait" : "pointer")};
  display: flex;
  align-items: flex-end;
  padding: 0;
  ${({ disabled }) => disabled && "opacity: 0.5;"}

  span {
    margin-right: 8px;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`

export const Label = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  font-family: Geometria, sans-serif;
  min-height: 1em;
`

export const Headline = styled.h3`
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 20px;
  display: inline-block;
`
