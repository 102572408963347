import React from "react"
import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  #app-root > div {
    min-width: inherit;
    overflow: hidden;
  }
  
  #modal-root {
    @media (max-width: 479px) {
      > * > * {
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }
`
/* eslint-disable quotes */
export default () => {
  React.useEffect(() => {
    const viewportMeta = document.querySelector('meta[name~="viewport"]')

    if (!viewportMeta) return

    const initialViewportContent = viewportMeta.getAttribute("content")
    viewportMeta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0",
    )

    // eslint-disable-next-line
    return () => {
      viewportMeta.setAttribute("content", initialViewportContent)
    }
  }, [])

  return <GlobalStyles />
}
