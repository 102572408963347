/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type ProfileCash_balance = {
    readonly id: string;
    readonly __typename: string;
    readonly accountBalanceDetailed: ReadonlyArray<{
        readonly currency: CurrencyEnum;
        readonly available: number;
        readonly blocked: number;
        readonly invested: number;
    }>;
    readonly investor?: {
        readonly investedAmount: number;
    } | null;
    readonly " $refType": "ProfileCash_balance";
};
export type ProfileCash_balance$data = ProfileCash_balance;
export type ProfileCash_balance$key = {
    readonly " $data"?: ProfileCash_balance$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileCash_balance">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCash_balance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileAccountBalance",
      "kind": "LinkedField",
      "name": "accountBalanceDetailed",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invested",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualProfileInvestor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "investedAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    }
  ],
  "type": "UserProfile"
};
(node as any).hash = '390bcac242e701910ff42bc25d49045d';
export default node;
