import {
  InvestmentActiveTable,
  InvestmentCompletedTable,
  InvestmentSucceededTable,
  InvestmentPaymentGraph,
  LoanDraftTable,
  LoanActiveTable,
  LoanCompletedTable,
  LoanSucceededTable,
  LoanPaymentGraph,
} from "./Tables"

export default {
  InvestmentActiveTable,
  InvestmentCompletedTable,
  InvestmentSucceededTable,
  InvestmentPaymentGraph,
  LoanDraftTable,
  LoanActiveTable,
  LoanCompletedTable,
  LoanSucceededTable,
  LoanPaymentGraph,
}
