import React from "react"

class CountDown extends React.Component<any, any> {
  state = {
    secondsLeft: 0,
    expired: true,
  }

  static defaultProps = {
    cycleTime: 60,
    maxCycleTime: 300,
    cycleIncrementer: 60,
  }

  componentDidMount() {
    (this as any).cycleTime = this.props.cycleTime
  }

  componentWillUnmount() {
    clearInterval((this as any).incrementer)
  }

  start = () => {
    this.setState(() => ({
      secondsLeft: Math.min((this as any).cycleTime, this.props.maxCycleTime),
      expired: false,
    }));

    (this as any).incrementer = setInterval(() => {
      this.setState({
        secondsLeft: this.state.secondsLeft - 1,
      })

      if (!this.state.secondsLeft) {
        clearInterval((this as any).incrementer)

        this.setState({
          expired: true,
        })
      }
    }, 1000)
  }

  restart = (increment = true) => {
    if (increment) (this as any).cycleTime += this.props.cycleIncrementer
    clearInterval((this as any).incrementer)
    this.start()
  }

  clear = () => {
    clearInterval((this as any).incrementer)
  }

  render() {
    return (this as any).props.children({
      ...this.state,
      start: this.start,
      clear: this.clear,
      restart: this.restart,
    })
  }
}

export default CountDown
