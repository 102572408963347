import React from "react"
import { TagsContainer, TagsItem } from "./styles"

export default (props) => (
  <TagsContainer>
    {props.isCession && <TagsItem>Цессия</TagsItem>}
    {props.tags.map((item, i) => (
      <TagsItem key={`tag-${props.id}-${i}`}>{item.name}</TagsItem>
    ))}
  </TagsContainer>
)
