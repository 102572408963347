import {
  hasCollectedMaxAmount,
  isOfferExpired,
} from "src/utils/investor"

export const getIsDisabledButton = (profile, data) => {
  const collected = hasCollectedMaxAmount(data)
  return !profile
    || isOfferExpired(data)
    || data.status === "COMPLETE"
    || collected
}
