import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation LoginWithAuthenticationTokenMutation(
    $input: LoginWithAuthenticationTokenInput!
  ) {
    loginWithAuthenticationToken(input: $input) {
      token
    }
  }
`

export default enhance({ mutation })
