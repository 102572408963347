import React from "react"
import styled from "styled-components"
import { Text } from "src/components"

const Container = styled.div<any>`
  align-items: center;
  display: flex;
  padding-bottom: 40px;
  width: 100%;
`

const LabelContainer = styled.aside`
  color: ${({ theme }) => theme.colors.greyDarker};
  margin: 0;
  padding: 0;
  padding-right: 40px;
  text-transform: uppercase;
  width: 260px;
`

const ContentContainer = styled.div`
  flex: 1;
`

const renderLabel = ({ label }: any) => (
  <LabelContainer>
    <Text fontSize={13}>{label}</Text>
  </LabelContainer>
)

const render = ({ first, children, ...props }) => (
  <Container first={first}>
    {renderLabel(props)}
    <ContentContainer>{children}</ContentContainer>
  </Container>
)

export default render
