import {
  Close,
  LeftArrow,
  ClerkDoubt,
  Telegram,
  Key,
  Exit,
  Profile,
  OpenedEye,
  ClosedEye,
  Success,
  Asian,
  Info,
  Arrow,
  Download,
  AsianUnderMagnifier,
  Coin,
  Wallet,
  Contact,
  Chel,
  Calculator,
  Clock,
  Calendar,
  Diagram,
  Mail,
  FormatBold,
  FormatItalic,
  FormatUnderline,
  FormatLink,
  Search,
  BuisnessAndTrade,
  User,
} from "./Glyphs"

export default {
  Close,
  LeftArrow,
  ClerkDoubt,
  Telegram,
  Key,
  Exit,
  Profile,
  OpenedEye,
  ClosedEye,
  Success,
  Asian,
  Info,
  Arrow,
  Download,
  AsianUnderMagnifier,
  Coin,
  Wallet,
  Contact,
  Chel,
  Calculator,
  Clock,
  Calendar,
  Diagram,
  Mail,
  FormatBold,
  FormatItalic,
  FormatUnderline,
  FormatLink,
  Search,
  BuisnessAndTrade,
  User,
}
