import styled from "styled-components"

import { DocumentIcon as Document, CloseIcon as Close } from "./icons"

export const AttachmentList = styled.ul`
  position: relative;
  padding: 0;
  margin: 12px 0 0;
  width: 100%;
`

export const Attachment = styled.li`
  padding: 0 0 14px 0;
  margin: 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fullBlacks[1]};

  :not(:first-child) {
    margin-top: 14px;
  }
`

export const Value = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.fullBlack};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
`

export const DocumentIcon = styled(Document)`
  fill: ${({ theme }) => theme.colors.fullBlack};
  margin-right: 8px;
`

export const CloseButton = styled.button`
  position: relative;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: auto;
`

export const CloseIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.textBlack};
  margin: auto;
  width: 9px;
  height: 9px;
`
