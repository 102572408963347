import React from "react"

import { TData, TableRow } from "./styles"

import { getDate, displayData, getMonth } from "./utils"

export default function PaymentScheduleItem(props) {
  const {
    isCession, data, debt, index,
  } = props

  return (
    <TableRow>
      {isCession && <TData>{getDate(data.date)}</TData>}
      {!isCession && <TData>{getMonth(index)}</TData>}
      <TData>{displayData(data.total_gross)}</TData>
      <TData>{displayData(data.loan)}</TData>
      <TData>{displayData(data.interest)}</TData>
      <TData>{`~ ${displayData(data.personal_tax)}`}</TData>
      <TData>{displayData(data.total)}</TData>
      <TData>{displayData(debt)}</TData>
    </TableRow>
  )
}

export function TotalScheduleItem(props) {
  const { data } = props

  return (
    <TableRow style={{ fontWeight: "bold" }}>
      <TData>{data.date}</TData>
      <TData>{displayData(data.total_gross)}</TData>
      <TData>{displayData(data.loan)}</TData>
      <TData>{displayData(data.interest)}</TData>
      <TData>{`~ ${displayData(data.personal_tax)}`}</TData>
      <TData>{displayData(data.total)}</TData>
      <TData></TData>
    </TableRow>
  )
}
