import React from "react"

import {
  Button, PlusIcon, FlexBox,
} from "src/components"

import Divider from "./styles"

export default (props) => {
  const { onClick, children } = props

  return (
    <Button onClick={onClick} variant="defaultThin">
      <FlexBox display="flex" flexDirection="row" alignItems="center">
        <PlusIcon />
        <Divider />
        {children}
      </FlexBox>
    </Button>
  )
}
