import React from "react"

/* eslint-disable max-len */

export const ArrowA = (props) => (
  <svg
    className={props.className}
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03325 6.60556L5.29158 6.60556L5.29158 0.8L4.13047 0.8L4.13047 6.60556L2.3888 6.60555L4.71102 8.92778L7.03325
 6.60556ZM9.35547 11.25L9.35547 10.0889L0.066578 10.0889L0.0665779 11.25L9.35547 11.25Z"
      fill="#00679C"
    />
  </svg>
)

export const ArrowB = (props) => (
  <svg
    className={props.className}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="18" height="18" rx="3.5" stroke="#00679C" />
    <path
      d="M12.0332 9.60556L10.2916 9.60556L10.2916 3.8L9.13047 3.8L9.13047 9.60556L7.3888 9.60555L9.71102 11.9278L12.0332
 9.60556ZM14.3555 14.25L14.3555 13.0889L5.06658 13.0889L5.06658 14.25L14.3555 14.25Z"
      fill="#00679C"
    />
  </svg>
)

export const Document = (props) => (
  <svg
    className={props.className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6562 4.65624V15.9048C14.6562 16.5098 14.1656 17 13.5611 17H3.43889C2.83396 17 2.34375 16.5098 2.34375
 15.9048V1.09514C2.34372 0.490211 2.83393 0 3.43889 0H9.99999L14.6562 4.65624Z"
      fill="#518EF8"
    />
    <path d="M11.6477 8.53516H5.35156V9.23478H11.6477V8.53516Z" fill="white" />
    <path d="M11.6477 10.0977H5.35156V10.7973H11.6477V10.0977Z" fill="white" />
    <path d="M11.6477 11.6602H5.35156V12.3598H11.6477V11.6602Z" fill="white" />
    <path d="M9.82917 13.2227H5.35156V13.9223H9.82917V13.2227Z" fill="white" />
    <path
      d="M10.6357 4.55487L14.6567 6.2114V4.65604L12.3769 3.98291L10.6357 4.55487Z"
      fill="#3A5BBC"
    />
    <path
      d="M14.6562 4.65624H11.0951C10.4902 4.65624 10 4.16603 10 3.5611V0L14.6562 4.65624Z"
      fill="#ACD1FC"
    />
  </svg>
)

export const PresentationIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6562 4.65624V15.9048C14.6562 16.5098 14.1656 17 13.5611 17H3.43889C2.83396 17 2.34375 16.5098 2.34375 15.9048V1.09514C2.34372 0.490211 2.83393 0 3.43889 0H9.99999L14.6562 4.65624Z"
      fill="#F70000"
    />
    <path d="M11.6477 8.53516H5.35156V9.23478H11.6477V8.53516Z" fill="white" />
    <path d="M11.6477 10.0977H5.35156V10.7973H11.6477V10.0977Z" fill="white" />
    <path d="M11.6477 11.6602H5.35156V12.3598H11.6477V11.6602Z" fill="white" />
    <path d="M9.82917 13.2227H5.35156V13.9223H9.82917V13.2227Z" fill="white" />
    <path
      d="M10.6353 4.55487L14.6562 6.2114V4.65604L12.3765 3.98291L10.6353 4.55487Z"
      fill="#BA3A3A"
    />
    <path
      d="M14.6562 4.65624H11.0951C10.4902 4.65624 10 4.16603 10 3.5611V0L14.6562 4.65624Z"
      fill="#FFB0B0"
    />
  </svg>
)
