import React, { useState, useCallback } from "react"

import modals from "./modals"

const CancelInvestmentDialog = (props) => {
  const { order, onComplete, onClose } = props

  const [modal, setModal] = useState("alert")
  const [error, setError] = useState()

  const Modal = modals[modal].component

  const goNext = useCallback(() => setModal((mdl) => modals[mdl].next), [])

  const handleError = useCallback((err) => {
    setError(err)
    setModal("error")
  }, [])

  return (
    <Modal
      order={order}
      onClose={onClose}
      onComplete={onComplete}
      error={error}
      goNext={goNext}
      onError={handleError}
    />
  )
}

export default ({ isOpened, ...props }) => isOpened && <CancelInvestmentDialog {...props} />
