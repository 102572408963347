import React from "react"
import { Route } from "react-router"

import {
  isOfferExpired,
  hasCollectedMaxAmount,
  getOrderModerationStatus,
} from "src/utils"

import { StatusContainer } from "src/containers"

const onModerationClick = (history) => (id) => (event) => {
  if (event) {
    event.preventDefault()
  }
  history.push(`/orders/${id}`)
}

const Status = (props) => {
  const { data, action, ...rest } = props
  const moderation = getOrderModerationStatus(data)
  const outdated = isOfferExpired(data) || hasCollectedMaxAmount(data)

  return (
    <StatusContainer
      fontSize="16px"
      action={action(data.id)}
      element="order_item"
      moderation={moderation}
      outdated={outdated}
      {...rest}
    />
  )
}

export default (props) => (
  <Route>
    {({ history }) => <Status {...props} action={onModerationClick(history)} />}
  </Route>
)
