import React from "react"

import { Translate } from "src/components"
import { notifyGTM } from "src/utils"

import { LinkLabel, ExternalLink, ItemsContainer } from "../styles"

import Link from "./Link"

const navigationLinks = [
  {
    label: () => <Translate i18n="components:user-page-header.market-link" />,
    path: "/market",
    exact: true,
  },
  {
    label: () => <Translate i18n="components:user-page-header.orders-link" />,
    path: "/orders/my",
    exact: false,
    analytics: {
      eventLabel: "click",
      eventAction: "my-portfel",
    },
  },
  {
    label: () => "Кабинет агента",
    path: "/trusted",
    forTrustee: true,
    exact: false,
  },
]

const extendedLinks = [
  ...navigationLinks,
  {
    label: () => "О компании",
    path: "/about",
  },
  {
    label: () => "FAQ",
    path: "/faq",
  },
]

/* TODO: unused - вернуть позже или удалить окончательно
  const RussianUserLinks = [
  ...extendedLinks,
  {
    label: () => <Translate i18n="components:user-page-header.borrow-link" />,
    path: "/new-order",
    component: BorrowIntentLink,
  },
] */

const ensure = (viewer, link) => {
  const props: any = {}
  const Component = link.external ? ExternalLink : link.component || Link

  if (link.external) {
    props.href = typeof link.path === "function" ? link.path() : link.path
    props.target = "_blank"
  } else {
    props.to = link.path
    props.user = viewer
    props.exact = link.exact
  }

  if (link.analytics) {
    props.onClick = notifyGTM({
      ...link.analytics,
      extras: { "profile-id": viewer.id },
    })
  }

  return [Component, props]
}

const renderItem = (viewer) => (link) => {
  const [Component, props] = ensure(viewer, link)

  return (
    <Component {...props}>
      {link.icon ? <link.icon /> : null}
      <LinkLabel>{link.label()}</LinkLabel>
    </Component>
  )
}

const NavItems = ({ viewer, vertical }) => {
  const { trust = {} } = viewer
  const links = vertical ? extendedLinks : navigationLinks

  return (
    <ItemsContainer vertical={vertical || false}>
      {links.map((item: any, idx) => {
        if (item.forTrustee && !trust.isAvailable) {
          return null
        }

        const render = renderItem(viewer)

        return <Translate key={idx} render={() => render(item)} />
      })}
    </ItemsContainer>
  )
}

export default NavItems
