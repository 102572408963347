/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ProfileMenu_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly name: string;
    readonly bankAccounts: ReadonlyArray<{
        readonly id: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly accreditation?: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly investor?: {
        readonly qualified: boolean;
    } | null;
    readonly " $refType": "ProfileMenu_profile";
};
export type ProfileMenu_profile$data = ProfileMenu_profile;
export type ProfileMenu_profile$key = {
    readonly " $data"?: ProfileMenu_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileMenu_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v5 = [
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileMenu_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkingAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correspondentAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Bank",
          "kind": "LinkedField",
          "name": "bank",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bic",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualProfileInvestor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qualified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v5/*: any*/),
      "type": "ForeignIndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v5/*: any*/),
      "type": "EntrepreneurProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        }
      ],
      "type": "LegalEntityProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '3c9581104f76d2e03a615865fe9f0ecd';
export default node;
