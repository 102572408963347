import React from "react"

import { OrderTypeConsumer, ViewerDataConsumer } from "src/context"

import OrderStatus from "./OrderStatus"
import InfoMessage from "./InfoMessage"
import ProfileName from "./ProfileName"
import CompanyName from "./CompanyName"
import TrustedStatus from "./TrustedStatus"
import InvestedByKarma from "./InvestedByKarma"

import { Container } from "./styles"
import { getLinkPath } from "../../utils"

const KarmaNameplate = ({ karmaIsInvestor }) => (karmaIsInvestor ? <InvestedByKarma /> : null)

const MiscData = (props) => {
  const {
    data, source, trusted, orderType, withLink = true,
  } = props

  const {
    id, karma, status, profile, application, externalUrl,
  } = data

  const linkPath = getLinkPath(status, source, id, orderType, externalUrl)

  return (
    <Container>
      <ProfileName profile={profile} />
      <CompanyName
        application={application}
        linkPath={withLink ? linkPath : null}
      />
      <KarmaNameplate karmaIsInvestor={karma.isInvestor} />
      <OrderStatus order={data} />
      <InfoMessage data={data} />
      {trusted && (
        <TrustedStatus
          status={data.status}
          trusted={data.karma.isTrusted}
          orderId={data.id}
        />
      )}
    </Container>
  )
}

export default (props) => (
  <OrderTypeConsumer>
    {(orderType) => (
      <ViewerDataConsumer>
        {(viewer) => (
          <MiscData orderType={orderType} viewer={viewer} {...props} />
        )}
      </ViewerDataConsumer>
    )}
  </OrderTypeConsumer>
)
