import React from "react"
import memoize from "memoize-one"

import { ViewerAccessContainer } from "src/containers"
import { QueryParamsConsumer, ViewerData } from "src/context"
import { notifyGTM, normalizeAttachments } from "src/utils"

import Nav from "./Nav"
import Body from "./Body"
import { Container } from "./styles"
import { ProposalSteps, navSchema } from "./constants"

const parse = (order) => {
  const result = { ...order }
  const { offer, application } = result

  result.offer.data = normalizeAttachments(offer.data, offer.attachments)
  result.application.data = normalizeAttachments(
    application.data,
    application.attachments,
  )

  return result
}

const gtmActionsMap = {
  [ProposalSteps.documents]: "project-doc",
  [ProposalSteps.assessment]: "risk-evaluation",
  [ProposalSteps.conclusion]: "project-expert",
  [ProposalSteps.orderDescription]: "project-info",
}

const sendGTMEvent = memoize((step, order, viewer) => {
  const eventAction = gtmActionsMap[step]

  if (!eventAction || !order || !order.chain) return () => {}

  const { id } = order.chain
  return notifyGTM({
    eventAction,
    eventLabel: id.split(".")[2],
    extras: {
      "profile-id": viewer.id,
    },
  })
})

const isAccreditationNeeded = (step) => {
  const needAccreditation = [
    ProposalSteps.documents,
    ProposalSteps.financialStatements,
    ProposalSteps.provision,
  ]
  return needAccreditation.includes(step)
}

class ProposalBody extends React.Component<any, any> {
  state = {
    order: { ...parse(this.props.data) },
    profileId: this.props.data.profile.id,
    activeStep: ProposalSteps.orderDescription,
    isOrderOwner: this.props.getViewerToOrderRelation(this.props.data).MASTER,
    step: undefined,
  }

  componentWillUnmount() {
    this.setActiveStep(null)
  }

  componentDidMount() {
    const { getQueryParam } = this.props
    const activeStep = getQueryParam("step")
    if (activeStep) {
      this.setState(() => ({ activeStep }))
    }
  }

  setActiveStep = (step) => {
    const { setQueryParam, deleteQueryParam } = this.props

    if (step) {
      setQueryParam("step", step)
    } else {
      deleteQueryParam("step")
    }
  }

  onStepChange = (step) => (event) => {
    if (event) event.preventDefault()

    if (step === this.state.step) return

    const sendGTM = sendGTMEvent(step, this.state.order, this.props.viewer)

    if (isAccreditationNeeded(step) && !this.state.isOrderOwner) {
      this.props.checkAccess(() => {
        this.setActiveStep(step)
        this.setState(() => ({ activeStep: step }), sendGTM)
      })
    } else {
      this.setActiveStep(step)
      this.setState(() => ({ activeStep: step }), sendGTM)
    }
  }

  render() {
    return (
      <Container>
        <Nav
          onClick={this.onStepChange}
          activeStep={this.state.activeStep}
          schema={navSchema(this.state.order)}
        />
        <Body
          order={this.state.order}
          viewer={this.props.viewer}
          profileId={this.state.profileId}
          activeStep={this.state.activeStep}
          isOrderOwner={this.state.isOrderOwner}
        />
      </Container>
    )
  }
}

const ComposedConsumer = (props) => (
  <QueryParamsConsumer>
    {({ getQueryParam, setQueryParam, deleteQueryParam }) => (
      <ViewerAccessContainer role="investor">
        {({ checkAccess }) => (
          <ViewerData.renderPropContainer>
            {({ getViewerToOrderRelation }) => props.children({
              checkAccess,
              getQueryParam,
              setQueryParam,
              deleteQueryParam,
              getViewerToOrderRelation,
            })
            }
          </ViewerData.renderPropContainer>
        )}
      </ViewerAccessContainer>
    )}
  </QueryParamsConsumer>
)

export default (props) => (
  <ComposedConsumer>
    {(consumerProps) => <ProposalBody {...props} {...consumerProps} />}
  </ComposedConsumer>
)
