import React from "react"
import memoize from "memoize-one"

import { notifyGTM } from "src/utils"
import { ProfilesListQueryContainer } from "src/query"

import {
  Box, Text, Flex, Translate,
} from "src/components"

import { Divider, Scrollable, TableWrapper } from "../styles"

import {
  KarmaRulesAlert,
  ListItemHeading,
  RequisitesButton,
  InvestmentAmountAlert,
} from "./elements"

import { Container } from "./styles"
import paymentRequisites from "./constants"

const sendGTMEvent = memoize((order, viewer) => (type) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  if (!order || !order.chain) return () => {}

  const { id } = order.chain
  return notifyGTM({
    eventLabel: `pay-${type}`,
    eventAction: `market-${id.split(".")[2]}`,
    extras: {
      "profile-id": viewer.id,
    },
  })
})

const Content = ({ order, viewer }) => {
  const schema = paymentRequisites(order, viewer)
  const { gatheredAmount } = order.chain
  const { maxValue } = order.application.data
  const {
    viewer: { investorPaymentRequisites },
  } = order
  const maxInvestmentAmount = Number(maxValue) - Number(gatheredAmount)
  const notifyGTMWithType = sendGTMEvent(order, viewer)

  return (
    <Container>
      <ListItemHeading number="1">
        <Translate i18n="components:bank_modal.invest.transaction.title" />
      </ListItemHeading>
      <Flex mt="20px" mb="30px">
        <KarmaRulesAlert />
        <InvestmentAmountAlert maxInvestmentAmount={maxInvestmentAmount} />
      </Flex>
      <ListItemHeading number="2">
        <Translate i18n="components:bank_modal.invest.requisites.title" />
      </ListItemHeading>
      <RequisitesButton
        doc={investorPaymentRequisites}
        onClick={notifyGTMWithType("save")}
      />
      <Divider />
      <TableWrapper onCopy={notifyGTMWithType("copy")}>
        <table>
          <tbody>
            {schema.map((item, idx) => (
              <tr key={idx}>
                <td>{item.label}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </Container>
  )
}

const ContentContainer = ProfilesListQueryContainer((props) => (
  <Content {...props} />
))

const Invest = ({ order }) => (
  <Scrollable>
    <Box width="830px">
      <Box mb="15px">
        <Text fontSize={24}>
          <Translate i18n="components:bank_modal.invest.header" />
        </Text>
      </Box>
      <Divider />
      <ContentContainer order={order} />
    </Box>
  </Scrollable>
)

export default Invest
