import React from "react"

import { FormBuilder } from "src/components"

const Form = (props) => {
  const { value, schema, onChange } = props

  return (
    <FormBuilder
      schema={schema}
      value={value}
      onChange={onChange}
      inputsSchemaType="new"
    />
  )
}

export default Form
