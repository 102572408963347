import React, { useEffect } from "react"

import { levenshteinDistance } from "src/utils"

import {
  Box, Icon, Loader, Translate, FloatingLabelInput,
} from "src/components"

import { Option, InputWrapper, OptionsContainer } from "./styles"

import useCountries from "./useCountries"

/*
 *
 * 1) Запрогать floatingLabelAutoComplete инпут (локально, только для этой задачи)
 *     1] показать autoCompleteContainer при onChange
 *     2] Реализовать обработку событий клавиатуры +
 *     3] Запрогать компонент с Mock данными +
 * 2) Запрогать адаптер stateToProps
 * 3) Запрогать адаптер propsToState
 *
 * */

const OptionsLoader = () => (
  <Box py="18px">
    <Loader fontSize="4px" />
  </Box>
)

const Options = (props) => {
  const { onSelect, options, activeIndex } = props

  if (!options || !options.length) {
    return (
      <Option onClick={() => {}}>
        <Translate i18n="common:labels.got_no_matches" />
      </Option>
    )
  }

  return options.map(({ name, index }) => (
    <Option
      key={name + index}
      active={index === activeIndex}
      onClick={onSelect(index)}
    >
      {name}
    </Option>
  ))
}

const MAX_TYPOS_NUMBER = 2
const MAX_TYPOS_PERCENTS = 35

const match = (search, item) => {
  const words = item.split(" ")

  return words.some((word) => {
    const shortenedWord = word.slice(0, search.length)
    const dist = levenshteinDistance(search, shortenedWord)
    const percentOfTypos = (dist / shortenedWord.length) * 100

    return dist <= MAX_TYPOS_NUMBER && percentOfTypos <= MAX_TYPOS_PERCENTS
  })
}

const optionsFilterFn = (search) => (item) => {
  const preparedItem = item.name.toLowerCase()
  const preparedSearch = search.toLowerCase()
  const includes = preparedItem.includes(preparedSearch)

  if (search.length <= 2 || includes) return includes

  return match(preparedSearch, preparedItem)
}

const optionsSortFn = (search) => (prev, next) => {
  const preparedPrev = prev.name.toLowerCase().slice(0, search.length)
  const preparedNext = next.name.toLowerCase().slice(0, search.length)
  const preparedSearch = search.toLowerCase()

  if (!preparedSearch) return preparedPrev > preparedNext
  if (preparedPrev === preparedSearch) return -1
  if (preparedNext === preparedSearch) return 1

  return preparedPrev > preparedNext
}

const RedesignedSelect = (props) => {
  const { onChange: injectedOnChange, ...restInputProps } = props

  const {
    onBlur,
    options,
    onFocus,
    onSelect,
    isLoading,
    inputValue,
    activeIndex,
    setInputValue,
    displayOptions,
  } = useCountries()

  useEffect(() => {
    if (activeIndex >= 0 && options.length > 0) {
      injectedOnChange(options[activeIndex])
    } else {
      injectedOnChange({ code: "", name: "" })
    }
  }, [inputValue])

  const onSearchChange = React.useCallback((e) => {
    setInputValue(e.target.value)
    onSelect(-1)()
  }, [])

  const optionsBySearch = React.useMemo(() => {
    const trimmedSearch = inputValue.trim()

    if (!trimmedSearch) return options

    const filterFn = optionsFilterFn(trimmedSearch)
    const sortFn = optionsSortFn(trimmedSearch)
    return options.filter(filterFn).sort(sortFn as any)
  }, [inputValue, options])

  return (
    <InputWrapper>
      <FloatingLabelInput
        {...restInputProps}
        value={inputValue}
        onBlur={onBlur}
        onFocus={onFocus}
        tooltip={() => <Icon.arrowDown optionsVisible={displayOptions} />}
        label={"common:labels.select_country"}
        onChange={onSearchChange}
        autoComplete="off"
      />
      <OptionsContainer shouldDisplay={displayOptions}>
        {isLoading ? (
          <OptionsLoader />
        ) : (
          <Options
            options={optionsBySearch}
            onSelect={onSelect}
            activeIndex={activeIndex}
          />
        )}
      </OptionsContainer>
    </InputWrapper>
  )
}

export default RedesignedSelect
