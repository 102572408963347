import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UploadFileMutation($input: UploadFileInput!) {
    uploadFile(input: $input) {
      id
      url
      size
      width
      height
      format
      filename
    }
  }
`

export default enhance({ mutation })
