import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { CardWrapper, Flex } from "src/components"

export const Container = styled(CardWrapper)`
  padding: 28px;
  font-family: Geometria, sans-serif;
  font-size: 1rem;
  width: 100%;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`

export const ButtonContainer = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  flex-direction: row;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const Button = styled.button`
  font-family: Geometria, sans-serif;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[3]};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  width: 180px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  padding: 15px 0 13px;
  text-transform: uppercase;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    padding: 22px 0 20px;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
  }
`

export const DeleteButton = styled(Button)`
  border: none;

  @media screen and (max-width: 480px) {
    margin-top: 40px;
  }
`

export const Link = styled(NavLink)`
  text-decoration: none;
`

export const DeclineReason = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  font-family: Geometria, sans-serif;
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.mainRed};
  font-size: 16px;
  font-weight: 600;
  padding: 16px 12px 14px;
  text-align: center;
`
