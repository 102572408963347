import React from "react"

import {
  Box,
  Label,
  Select,
  Tooltip,
  FormRow,
  Translate,
  AddButton,
} from "src/components"
import { cloneDeep } from "src/utils"
import { TYPE_OF_PROVISION } from "src/constants"

import { EMPTY_PROVISION, MOCK_PROVISION_DATA } from "./constants"

import { AddButtonWrapper } from "./styles"

import RemoveButton from "./elements"

import Flat from "./Flat"
import IndividualBeneficiary from "./IndividualBeneficiary"

export class Provision extends React.Component<any, any> {
  onProvisionRemove = (index) => () => {
    const provisions = [...this.props.value]
    provisions.splice(index, 1)

    this.forceChange(provisions)
  }

  onProvisionAdd = () => {
    const provisions = [...this.props.value, cloneDeep(EMPTY_PROVISION)]
    this.forceChange(provisions)
  }

  onProvisionTypeChange = (index) => ({ value }) => {
    const provisions = [...this.props.value]
    provisions[index] = {
      ...provisions[index],
      type: value,
      data: cloneDeep(MOCK_PROVISION_DATA[value]),
    }

    this.forceChange(provisions)
  }

  onProvisionDataChange = (index) => (value) => {
    const provisions = [...this.props.value]
    provisions[index] = {
      ...provisions[index],
      data: { ...value },
    }

    this.forceChange(provisions)
  }

  forceChange = (provisions) => {
    this.props.onChange({
      name: this.props.name,
      value: provisions,
    })
  }

  render() {
    return (
      <>
        {this.props.value.map(({ type, data }, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <RemoveButton onClick={this.onProvisionRemove(index)} />
            )}
            <FormRow>
              <Box>
                <Label>
                  <Translate
                    i18n="legal_entity_offer_form.rows.provision_type.label"
                    ns="components"
                  />
                </Label>
              </Box>
              <Box width="100%">
                <Select
                  value={type}
                  options={TYPE_OF_PROVISION}
                  onChange={this.onProvisionTypeChange(index)}
                />
                {this.props.value[index].type === "flat" && (
                  <Tooltip text="tooltips.flat_provision" />
                )}
              </Box>
            </FormRow>
            {type === "flat" && (
              <Flat value={data} onChange={this.onProvisionDataChange(index)} />
            )}
            {type === "individual_beneficiary" && (
              <IndividualBeneficiary
                value={data}
                onChange={this.onProvisionDataChange(index)}
              />
            )}
          </React.Fragment>
        ))}
        <AddButtonWrapper>
          <AddButton onClick={this.onProvisionAdd}>
            <Translate
              i18n="legal_entity_offer_form.rows.provision_type.button.label"
              ns="components"
            />
          </AddButton>
        </AddButtonWrapper>
      </>
    )
  }
}

export default Provision
