import Dropzone from "react-dropzone"

import styled from "styled-components"
import { width } from "styled-system"

export const UploadIcon = styled.div`
  width: 64px;
  height: 52px;
  background-size: contain;
  margin: 0 auto 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/icons/download.svg);
  text-align: center;
`

export const AsLink = styled.p`
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
  font-size: 14px;
  text-align: center;
  width: 100%;
  margin: 0;
  margin-top: 2px;
`

export const Container = styled(Dropzone)<any>`
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.colors.greyLight};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  ${width};
  min-height: 184px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding: 30px;
  cursor: ${({ cursor }) => cursor};
`

export const LabelContainer = styled.div`
  flex-grow: 1;
  flex-basis: 150px;
`

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 8px 0;
  width: 100%;
  padding-right: 16px;

  &:first-of-type {
    padding-bottom: 0;
  }

  &:last-of-type {
    padding-top: 0;
  }
`

export const LoaderContainer = styled.div`
  width: 64px;
  height: 52px;
  display: flex;
  margin: 0 auto 10px;
  flex-basis: 0;
  flex-grow: 1;
`

export const ListItemsContainer = styled.div`
  flex: 1;
  flex-basis: 150px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
`
