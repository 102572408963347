import React from "react"

export const ArrowIcon = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z" />
  </svg>
)

ArrowIcon.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "#4a4a4a",
}
