import React from "react"
import {
  graphql,
  createPaginationContainer,
  GraphQLTaggedNode,
} from "react-relay"

import { QueryRenderer } from "src/components"
import {
  isRussianInvestor, isRussianEntrepreneur, isForeignInvestor, isJuristicBorrower,
} from "src/utils"

import { VISIBLE_ORDER } from "./constants"
import { getInvestmentConnectionConfig, InvestorRenderComponent } from "./utils"

const queries = {
  investment: graphql`
    fragment InvestmentPunishedOrdersListFragment on InvestmentEdge {
      node {
        id
        amount
        loan
        order {
          id
          status
          confirmedAt
          punishment {
            legalStatus
            guarantorStatus
          }
          paymentScheduleList {
            profile {
              id
            }
            info {
              state
              pastdue_days
            }
            items {
              date
              state
              loan
              total
            }
          }
          offer {
            data
            receivedAt
            approvedAt
          }
          application {
            data
            shortTitle
            longTitle
          }
          chain {
            id
            gatheredAmount
          }
          profile {
            ... on UserProfile {
              id
              ... on LegalEntityProfile {
                name
                borrower {
                  ticker
                }
              }
            }
          }
        }
      }
    }
  `,
  individualQuery: graphql`
    query InvestmentPunishedOrdersListIndividualQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentPunishedOrdersList_individual
        @arguments(count: $count, cursor: $cursor, profileId: $profileId, filter: $filter)
    }
  `,
  entrepreneurQuery: graphql`
    query InvestmentPunishedOrdersListEntrepreneurQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentPunishedOrdersList_entrepreneur
        @arguments(count: $count, cursor: $cursor, profileId: $profileId, filter: $filter)
    }
  `,
  foreignQuery: graphql`
    query InvestmentPunishedOrdersListForeignQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentPunishedOrdersList_foreign
        @arguments(count: $count, cursor: $cursor, profileId: $profileId, filter: $filter)
    }
  `,
  legalEntity: graphql`
    query InvestmentPunishedOrdersListLegalEntityQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: ProfileInvestmentsFilter
    ) {
      ...InvestmentPunishedOrdersList_legalEntity
        @arguments(count: $count, cursor: $cursor, profileId: $profileId, filter: $filter)
    }
  `,
}

const IndividualPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    individual: graphql`
      fragment InvestmentPunishedOrdersList_individual on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on IndividualProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
                @connection(key: "PunishmentInvestments_investments") {
                edges {
                  ...InvestmentPunishedOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.individualQuery, "individual"),
)

const EntrepreneurPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    entrepreneur: graphql`
      fragment InvestmentPunishedOrdersList_entrepreneur on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on EntrepreneurProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
                @connection(key: "PunishmentInvestments_investments") {
                edges {
                  ...InvestmentPunishedOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.entrepreneurQuery, "entrepreneur"),
)

const ForeignPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    foreign: graphql`
      fragment InvestmentPunishedOrdersList_foreign on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on ForeignIndividualProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
                @connection(key: "PunishmentInvestments_investments") {
                edges {
                  ...InvestmentPunishedOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.foreignQuery, "foreign"),
)

const LegalEntityPaginatedOrderTable = createPaginationContainer(
  InvestorRenderComponent,
  {
    legalEntity: graphql`
      fragment InvestmentPunishedOrdersList_legalEntity on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "ProfileInvestmentsFilter" }
      ) {
        node(id: $profileId) {
          ... on LegalEntityProfile {
            investor {
              investments(first: $count, after: $cursor, filter: $filter)
                @connection(key: "PunishmentInvestments_investments") {
                edges {
                  ...InvestmentPunishedOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getInvestmentConnectionConfig(queries.legalEntity, "legalEntity"),
)

type ContainerAndQuery = [(props: any) => JSX.Element, GraphQLTaggedNode]

const getContainer = (render, profile): ContainerAndQuery | [] => {
  if (isRussianInvestor(profile)) {
    return [
      (props) => (
        <IndividualPaginatedOrderTable
          individual={props.data}
          render={render}
          filter={props.filter}
          setFilter={props.setFilter}
          profileType="individual"
        />
      ),
      queries.individualQuery,
    ]
  }

  if (isRussianEntrepreneur(profile)) {
    return [
      (props) => (
        <EntrepreneurPaginatedOrderTable
          entrepreneur={props.data}
          render={render}
          filter={props.filter}
          setFilter={props.setFilter}
          profileType="entrepreneur"
        />
      ),
      queries.entrepreneurQuery,
    ]
  }

  if (isForeignInvestor(profile)) {
    return [
      (props) => (
        <ForeignPaginatedOrderTable
          foreign={props.data}
          render={render}
          filter={props.filter}
          setFilter={props.setFilter}
          profileType="foreign"
        />
      ),
      queries.foreignQuery,
    ]
  }

  if (isJuristicBorrower(profile)) {
    return [
      (props) => (
        <LegalEntityPaginatedOrderTable
          legalEntity={props.data}
          render={render}
          filter={props.filter}
          setFilter={props.setFilter}
          profileType="legalEntity"
        />
      ),
      queries.legalEntity,
    ]
  }

  return []
}

type Props = {
  render: (props: any) => JSX.Element
  profile: any
}

const InvestmentPunishedOrdersList = ({ render, profile }: Props): JSX.Element => {
  const [container, query] = getContainer(render, profile)

  const [filter, setFilter] = React.useState({})

  if (!container) {
    return null
  }

  const paginatedRender = (props) =>
    container({ data: props.data || null, filter, setFilter })

  return (
    <QueryRenderer
      query={query}
      render={paginatedRender}
      renderNull={paginatedRender}
      variables={{
        profileId: profile.id,
        filter: {
          ...filter,
          status: ["DEFAULT"],
        },
        count: VISIBLE_ORDER,
      }}
    />
  )
}

export default InvestmentPunishedOrdersList
