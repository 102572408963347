const statuses = new Map([
  [
    "new",
    {
      text: "Ожидает оплаты",
      color: "rgba(74, 74, 74, 0.45)",
    },
  ],
  [
    "paid",
    {
      text: "Оплачен",
      color: "#6FA84B",
    },
  ],
  [
    "pastdue",
    {
      text: "Задержка платежа",
      color: "#F70000",
    },
  ],
])

export const investmentTypes = {
  INVESTMENT: "Инвестирование",
  PURCHASE: "Покупка основного долга",
  SELL: "Продажа основного долга",
}

export const investmentAmountColors = {
  INVESTMENT: "#6FA84B",
  PURCHASE: "#6FA84B",
  SELL: "#F70000",
}

export default statuses
