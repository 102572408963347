import React from "react"

import { number } from "src/utils"
import { Box, Flex, Translate } from "src/components"

import { Row, Icon, DebtAmount } from "./elements"

import { Container, StyledText } from "./styles"

const options = {
  style: "currency",
  currency: "RUB",
}

const OverdueInfo = (props) => {
  const { loan, total, interest } = props

  return (
    <Container>
      <Flex
        pb="15px"
        mb="10px"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid rgba(255, 255, 255, 0.24)"
      >
        <Box width="140px">
          <StyledText fontSize="16px" fontWeight="bold" lineHeight="1.25">
            <Translate i18n="components:account.order_item.payment_schedule.pastdue.title" />
          </StyledText>
        </Box>
        <Icon />
      </Flex>
      <StyledText shaded fontSize="12px" lineHeight="1.33">
        <Translate i18n="components:account.order_item.payment_schedule.pastdue.subtitle" />
      </StyledText>
      <DebtAmount>
        <StyledText fontSize="18px" fontWeight="bold">
          {number(total, options)}
        </StyledText>
      </DebtAmount>
      <Box mb="15px">
        <StyledText shaded fontSize="12px" lineHeight="1.33">
          <Translate i18n="components:account.order_item.payment_schedule.pastdue.support" />
        </StyledText>
      </Box>
      <Row
        label="components:account.order_item.payment_schedule.pastdue.loan"
        value={number(loan, options)}
      />
      <Row
        label="components:account.order_item.payment_schedule.pastdue.interest"
        value={number(interest, options)}
      />
      <Flex justifyContent="space-between" pt="10px">
        <StyledText fontSize="14px" fontWeight="bold">
          <Translate i18n="components:account.order_item.payment_schedule.pastdue.total" />
        </StyledText>
        <StyledText fontSize="14px" fontWeight="bold">
          {number(total, options)}
        </StyledText>
      </Flex>
    </Container>
  )
}

export default OverdueInfo
