import React from "react"

import { ogParser } from "src/utils"
import { VIDEO_META } from "src/constants"
import { ScrapExternalMedia } from "src/mutations"
import { RelayEnvironmentConsumer } from "src/context"

import { Preview, VideoInput } from "./elements"

const initialStateFromProps = ({ value: { ogUrl } }: any) => ({ ogUrl })

export class Video extends React.PureComponent<any, any> {
  source = undefined

  state = {
    ...initialStateFromProps(this.props),
    isLoading: false,
  }

  onChange = (event) => {
    const { value } = event.target
    event.persist()

    this.setState(
      () => ({ ogUrl: value, isLoading: true }),
      () => ogParser.notify(value),
    )
  }

  componentDidMount() {
    ogParser.initSubscription(
      this.changeListener,
      this.props.environment,
      ScrapExternalMedia,
    )
  }

  changeListener = (meta) => {
    this.setState(
      () => ({ isLoading: false }),
      () => this.props.onChange({
        name: this.props.name,
        value: { ...meta },
      }),
    )
  }

  onRemoveClick = (event) => {
    event.preventDefault()
    this.setState(
      () => ({ ogUrl: "" }),
      () => this.props.onChange({
        name: this.props.name,
        value: { ...VIDEO_META },
      }),
    )
  }

  render() {
    return (
      <>
        <VideoInput
          name={this.props.name}
          ogUrl={this.state.ogUrl}
          onChange={this.onChange}
          isLoading={this.state.isLoading}
          onRemoveClick={this.onRemoveClick}
        />
        <Preview {...this.props.value} />
      </>
    )
  }
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => <Video {...props} {...environment} />}
  </RelayEnvironmentConsumer>
)
