export function parseAttachments(data) {
  const images = []
  const otherAttachments = []

  for (let i = 0; i < data.length; i += 1) {
    const item = data[i]
    const parsedFilename = item.filename.split(".")
    const fileFormat = parsedFilename[parsedFilename.length - 1] || ""
    const format = fileFormat.toLowerCase()

    if (
      format === "jpeg"
      || format === "jpg"
      || format === "png"
      || format === "gif"
    ) {
      images.push(item)
    } else {
      otherAttachments.push(item)
    }
  }

  return {
    images,
    otherAttachments,
  }
}
