import React from "react"
import styled, { css } from "styled-components"
import { color, space, borderRadius } from "styled-system"

const darkThemeStyles = (props) => css`
  background-color: ${props.theme.colors.greyDarkest};
  color: white;
  padding: 30px 40px 40px 40px;
`

const Container = styled.div<any>`
  ${color}
  ${space}
  ${borderRadius}
  ${({ variant }) => variant === "dark" && darkThemeStyles}
`

const render = ({ children, variant }) => (
  <Container
    p={30}
    bg="white"
    color="black"
    variant={variant}
    borderRadius="normal"
  >
    {children}
  </Container>
)

export default render
