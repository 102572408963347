import React from "react"
import styled from "styled-components"

import { Button, Translate } from "src/components"

const Link = styled.a`
  text-decoration: none;
`

const TransferSubmit = () => (
  <Link href="mailto:help@karma.red">
    <Button variant="ginger">
      <Translate i18n="components:individual_account.update.buttons.submit" />
    </Button>
  </Link>
)

export default TransferSubmit
