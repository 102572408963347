import React from "react"

import {
  Box,
  FormRow,
  FlexBox,
  TextField,
  Translate,
} from "src/components"
import AutoSuggest from "./AutoSuggest"

class Address extends React.PureComponent<any, any> {
  static autoSuggest

  onChange = (event) => {
    if (event) {
      event.preventDefault()
    }
    const { name, value } = event.target

    const address = this.props.value
    const nextValue = { ...address, [name]: value }

    this.props.onChange({
      name: this.props.name,
      value: nextValue,
    })
  }

  render() {
    return (
      <FormRow>
        {this.props.children && <Box width="260px">{this.props.children}</Box>}
        <FlexBox
          width={480}
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box width={230} pb={40}>
            <TextField
              type="text"
              name="region"
              value={this.props.value.region}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.region"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={230} pb={40}>
            <TextField
              type="text"
              name="area"
              value={this.props.value.area}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.area"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={230} pb={40}>
            <TextField
              type="text"
              name="locality"
              value={this.props.value.locality}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.locality"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={230} pb={40}>
            <TextField
              type="text"
              name="street"
              value={this.props.value.street}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.street"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={70} mr={20} pb={40}>
            <TextField
              type="text"
              name="house"
              value={this.props.value.house}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.house"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={70} mr={20} pb={40}>
            <TextField
              type="text"
              name="housing"
              value={this.props.value.housing}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.housing"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={70} mr={20} pb={40}>
            <TextField
              type="text"
              name="structure"
              value={this.props.value.structure}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.structure"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width={70} mr="auto" pb={40}>
            <TextField
              type="text"
              name="flat"
              value={this.props.value.flat}
              onChange={this.onChange}
              disabled={this.props.disabled}
              hint={
                <Translate
                  i18n="accreditation.individual.rows.present_registration.row_hints.flat"
                  ns="components"
                />
              }
            />
          </Box>
        </FlexBox>
      </FormRow>
    )
  }
}

Address.autoSuggest = AutoSuggest

export default Address
