import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const InnerDesktopWrapper = styled.div`
  position: relative;
  margin-top: 70px;
`

const InnerMobileWrapper = styled.div`
  position: relative;
  margin-top: 40px;
`

export const DesktopContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return !mobile && <InnerDesktopWrapper {...props}/>
}

export const MobileContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return mobile && <InnerMobileWrapper {...props}/>
}
