import React from "react"

import { MobilePaymentScheduleItem } from "src/components"

import { TotalScheduleItem } from "../MobilePaymentScheduleItem"
import { getTotalData } from "../NewPaymentTable/utils"

import { ListWrap } from "./styles"

function Render(props) {
  const { schedule, isCession } = props

  let totalDebt = 0
  let balanceOwed = 0
  for (let i = 0; i < schedule.length; i += 1) {
    totalDebt += schedule[i].loan
  }

  return (
    <ListWrap>
      {schedule.map((item, index) => {
        balanceOwed += item.loan
        const debt = totalDebt - balanceOwed

        return (
          <MobilePaymentScheduleItem
            debt={debt}
            key={index}
            data={item}
            index={index}
            isCession={isCession}
          />
        )
      })}
      {schedule.length > 0 && (
        <TotalScheduleItem
          isCession={isCession}
          data={getTotalData(schedule)}
        />
      )}
    </ListWrap>
  )
}

export default Render
