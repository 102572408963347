/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderDocumentTypeEnum = "BORROWER_FEE" | "BORROWER_OFFER" | "BORROWER_PAYMENT" | "GENERATED" | "INVESTOR_PAYMENT" | "%future added value";
export type instructionsModalUtilsQueryVariables = {
    id: string;
};
export type instructionsModalUtilsQueryResponse = {
    readonly node: {
        readonly __typename: "Order";
        readonly fee: number | null;
        readonly document: {
            readonly url: string;
            readonly type: OrderDocumentTypeEnum;
        } | null;
        readonly otherDocuments: ReadonlyArray<{
            readonly attachment: {
                readonly url: string;
                readonly filename: string;
            };
            readonly description: string;
        }>;
        readonly skipOffer: boolean;
        readonly guarantors: ReadonlyArray<{
            readonly attachment: {
                readonly url: string;
                readonly filename: string;
            };
            readonly name: string | null;
            readonly description: string | null;
        }>;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type instructionsModalUtilsQuery = {
    readonly response: instructionsModalUtilsQueryResponse;
    readonly variables: instructionsModalUtilsQueryVariables;
};



/*
query instructionsModalUtilsQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Order {
      fee
      document(type: BORROWER_OFFER) {
        url
        type
        id
      }
      otherDocuments {
        attachment {
          url
          filename
          id
        }
        description
      }
      skipOffer
      guarantors {
        attachment {
          url
          filename
          id
        }
        name
        description
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "type",
    "value": "BORROWER_OFFER"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skipOffer",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "instructionsModalUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GeneratedDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": "document(type:\"BORROWER_OFFER\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderDocument",
                "kind": "LinkedField",
                "name": "otherDocuments",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGuarantor",
                "kind": "LinkedField",
                "name": "guarantors",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "instructionsModalUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v12/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GeneratedDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": "document(type:\"BORROWER_OFFER\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderDocument",
                "kind": "LinkedField",
                "name": "otherDocuments",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGuarantor",
                "kind": "LinkedField",
                "name": "guarantors",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "instructionsModalUtilsQuery",
    "operationKind": "query",
    "text": "query instructionsModalUtilsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Order {\n      fee\n      document(type: BORROWER_OFFER) {\n        url\n        type\n        id\n      }\n      otherDocuments {\n        attachment {\n          url\n          filename\n          id\n        }\n        description\n      }\n      skipOffer\n      guarantors {\n        attachment {\n          url\n          filename\n          id\n        }\n        name\n        description\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4df503859c00e92095f883ed5915e43d';
export default node;
