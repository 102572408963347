import React from "react"
import memoize from "memoize-one"

import { notifyGTM } from "src/utils"
import { ReturnButton } from "src/components"

const paths = {
  market: "/market",
  loans: "/orders?type=loans",
  investments: "/orders?type=investments",
}

export const getBackPath = (source) => {
  const path = paths[source]
  return path || paths.market
}

export const getSurroundingOrders = memoize((edges, id) => {
  const idx = edges.findIndex((edge) => edge.node.id === id)

  const next = edges[idx + 1]
  const previous = edges[idx - 1]

  return [previous && previous.node, next && next.node]
})

const sendGTMEvent = memoize((order, viewer, forward) => {
  if (!order || !order.chain) return () => {}

  const { id } = order.chain
  return notifyGTM({
    eventAction: `${forward ? "next" : "previous"}-project`,
    eventLabel: id.split(".")[2],
    extras: {
      "profile-id": viewer.id,
    },
  })
})

export const getNavButton = ({ order, forward, viewer }) => () => (
  <ReturnButton
    keepQuery
    forward={forward}
    disabled={!order}
    onClick={sendGTMEvent(order, viewer, forward)}
    path={order ? `/market/${order.id}` : ""}
  />
)
