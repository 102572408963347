import React from "react"

import { LocaleConsumer } from "src/context"

import { LocaleButton, LocaleContainer, LocalesContainer } from "./styles"

const locales = [
  { value: "ko", flag: "kr", label: "한국어" },
  { value: "ja", flag: "jp", label: "日本語" },
  { value: "zh", flag: "cn", label: "中文" },
  { value: "en", flag: "gb", label: "English" },
  { value: "ru", flag: "ru", label: "Русский" },
]

const setLocale = (props) => (event) => {
  const locale = event.currentTarget.value
  props.setLocale(locale)
}

const renderLocale = (props) => (locale) => (
  <LocaleContainer key={locale.value}>
    <LocaleButton
      flag={locale.flag}
      value={locale.value}
      onClick={setLocale(props)}
      disabled={props.locale === locale.value}
    />
  </LocaleContainer>
)

const render = (props) => (
  <LocalesContainer>{locales.map(renderLocale(props))}</LocalesContainer>
)

/* eslint-disable no-shadow */
export default (props) => (
  <LocaleConsumer>
    {({ locale, setLocale }) => render({ ...props, locale, setLocale })}
  </LocaleConsumer>
)
