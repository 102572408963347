import React from "react"

import {
  Wrapper, Label, Input, Adornment,
} from "./styles"

function TopLabelInput(props) {
  const {
    label, adornment, className, ...rest
  } = props

  return (
    <Wrapper className={className}>
      <Label>
        {label}
        <Input withAdornment={!!adornment} withLabel={!!label} {...rest} />
        {!!adornment && <Adornment>{adornment}</Adornment>}
      </Label>
    </Wrapper>
  )
}

export default TopLabelInput
