import React, { useState, useEffect, useContext } from "react"
import { withRouter } from "react-router"

import { ModalContainer, FloatingLabelInput } from "src/components"
import { useCountdown, useTokenInput } from "src/hooks"
import { DeleteProfile, RequestProfilePhoneConfirmation } from "src/mutations"
import { MutationContainer } from "src/containers"
import { RelayEnvironmentContext } from "src/context"

import {
  Title,
  Description,
  Container,
  CloseButton,
  CloseIcon,
  InputContainer,
  Timer,
  ButtonContainer,
  Button,
  PrimaryButton,
  SuccessContainer,
  SuccessTitle,
  SuccessIcon,
} from "./styles"

function DeleteProfileModal(props) {
  const {
    isOpened, onClose, getError, profileId, clearError,
  } = props

  const [isSuccessful, setSuccessful] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const token = useTokenInput(clearError, "deleteProfile.token")
  const { secondsLeft, start: startCounter } = useCountdown(120)

  const { environment, refreshEnvironment } = useContext(
    RelayEnvironmentContext,
  )

  function handleClose() {
    onClose()

    if (isSuccessful) {
      const { history } = props

      window.localStorage.removeItem("activeProfile")
      history.push("/accounts")
      refreshEnvironment()
    }
  }

  function requestPhoneConfirmation() {
    if (secondsLeft > 0) {
      return
    }

    const variables = {
      input: {
        profileId,
        kind: "DELETE_PROFILE",
      },
    }

    startCounter()
    RequestProfilePhoneConfirmation.commit(environment, variables, null, {})
  }

  function onError() {
    setLoading(false)
  }

  function onCompleted(data) {
    const { deleteProfile } = data

    if (deleteProfile?.status) {
      setSuccessful(true)
    }

    setLoading(false)
  }

  function deleteProfileById() {
    const { commit } = props

    const variables = {
      input: {
        token: token.value,
        profileId,
      },
    }

    const callbacks = {
      onError,
      onCompleted,
    }

    commit({ environment, variables, callbacks })
  }

  function handleSubmit() {
    if (!token.value || isLoading) {
      return
    }

    setLoading(true)
    deleteProfileById()
  }

  useEffect(() => {
    if (profileId) {
      requestPhoneConfirmation()
    }
  }, [])

  return (
    <ModalContainer isOpened={isOpened} onClose={handleClose}>
      <Container>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>

        {!isSuccessful && (
          <>
            <Title>Удаление профиля</Title>
            <Description>
              Для подтверждения действия введите код подтверждения из SMS.
            </Description>

            <InputContainer>
              <FloatingLabelInput
                withoutBorder
                required
                autoFocus
                type="text"
                name="token"
                errorColor="mainRed"
                onChange={token.onChange}
                value={token.value}
                disabled={isLoading}
                label="components:join.investor.phone_confirmation.placeholder"
                error={getError("deleteProfile.token")}
                hasError={getError("deleteProfile.token")}
              />
            </InputContainer>

            {secondsLeft > 0 && (
              <Timer>Выслать пароль повторно через {secondsLeft} секунд</Timer>
            )}

            <ButtonContainer>
              <Button onClick={requestPhoneConfirmation} disabled={!!secondsLeft}>
                ВЫСЛАТЬ ПОВТОРНО
              </Button>
              <PrimaryButton
                onClick={handleSubmit}
                disabled={!token.value || isLoading}
              >
                ПРОДОЛЖИТЬ
              </PrimaryButton>
            </ButtonContainer>
          </>
        )}

        {isSuccessful && (
          <SuccessContainer>
            <SuccessIcon />
            <SuccessTitle>Готово!</SuccessTitle>
          </SuccessContainer>
        )}
      </Container>
    </ModalContainer>
  )
}

function Render(props) {
  return (
    <MutationContainer mutation={DeleteProfile}>
      {({ commit, getError, clearError }) => (
        <DeleteProfileModal
          {...props}
          commit={commit}
          getError={getError}
          clearError={clearError}
        />
      )}
    </MutationContainer>
  )
}

export default withRouter(Render)
