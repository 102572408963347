import styled from "styled-components"

import { Box } from "src/components"

export const AttachmentLink = styled.a`
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.blue};
  }
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`
