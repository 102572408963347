import React from "react"
import { graphql, createPaginationContainer } from "react-relay"

import { QueryRenderer } from "src/components"
import { RenderedTable } from "src/components/organisms/PortfolioTable/Tables/LoanActiveTable"

import { VISIBLE_ORDER } from "./constants"
import { getBorrowerConnectionConfig, BorrowerRenderComponent } from "./utils"

const queries = {
  order: graphql`
    fragment LoanActiveOrdersListFragment on OrderEdge {
      node {
        id
        status
        confirmedAt
        offer {
          data
          receivedAt
          approvedAt
        }
        application {
          data
          shortTitle
          longTitle
        }
        chain {
          id
          gatheredAmount
        }
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              borrower {
                ticker
              }
            }
          }
        }
      }
    }
  `,
  query: graphql`
    query LoanActiveOrdersListQuery(
      $count: Int!
      $cursor: Cursor
      $profileId: ID!
      $filter: BorrowerOrdersFilter
    ) {
      ...LoanActiveOrdersList_root
      @arguments(
        count: $count
        cursor: $cursor
        profileId: $profileId
        filter: $filter
      )
    }
  `,
}

const PaginatedOrderTable = createPaginationContainer(
  BorrowerRenderComponent,
  {
    root: graphql`
      fragment LoanActiveOrdersList_root on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        profileId: { type: "ID!" }
        filter: { type: "BorrowerOrdersFilter" }
      ) {
        node(id: $profileId) {
          ... on LegalEntityProfile {
            borrower {
              orders(first: $count, after: $cursor, filter: $filter)
              @connection(key: "ActiveLoan_orders") {
                edges {
                  ...LoanActiveOrdersListFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
  },
  getBorrowerConnectionConfig(queries.query, "root"),
)

const Container = (render, profile) => (mainProps) => (
  <QueryRenderer
    {...mainProps}
    query={queries.query}
    render={(props) => (
      <PaginatedOrderTable root={props} render={render} profileType="root" />
    )}
    renderNull={() => <RenderedTable isFetching={true} load={{}} />}
    variables={{
      profileId: profile.id,
      count: VISIBLE_ORDER,
      filter: { status: ["CONFIRMED", "TRANSFER"] },
    }}
  />
)

export default Container
