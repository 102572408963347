export function getTotalData(array) {
  const totalData = {
    date: "Итого",
    total_gross: 0,
    loan: 0,
    interest: 0,
    personal_tax: 0,
    total: 0,
  }

  for (let i = 0; i < array.length; i += 1) {
    totalData.total_gross += array[i].total_gross
    totalData.loan += array[i].loan
    totalData.interest += array[i].interest
    totalData.personal_tax += array[i].personal_tax
    totalData.total += array[i].total
  }

  return totalData
}
