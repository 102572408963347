import React from "react"
import styled from "styled-components"

import { width, height, display } from "styled-system"

export const IconContainer = styled.div<any>`
  ${width}
  ${height}
  ${display}
  padding: ${({ padding }) => padding};
  border-radius: 50%;
  position: relative;
  background-color: ${({ theme, inverse }) => (inverse ? theme.colors.white : theme.colors.green)};
  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${({ rotate }) => `rotate(${rotate}deg)`};
    stroke: ${({ theme, inverse }) => (inverse ? theme.colors.green : theme.colors.white)};
    position: absolute;
  }
`

const CheckedSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12">
    <g id="Group_1" data-name="Group 1" transform="translate(-831 -60)">
      <path
        id="Shape"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2px"
        d="M16,0,10.667,4.848,5,10,0,5.455"
        transform="translate(832 61)"
      />
    </g>
  </svg>
)

const ApprovedIcon = (props) => (
  <IconContainer {...props}>
    <CheckedSvg />
  </IconContainer>
)

ApprovedIcon.defaultProps = {
  width: "26px",
  height: "26px",
  rotate: "45",
  inverse: true,
  padding: 0,
  display: "block",
}

export default ApprovedIcon
