/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileCash_profile = {
    readonly " $fragmentRefs": FragmentRefs<"Deposit_profile" | "Withdraw_profile">;
    readonly " $refType": "ProfileCash_profile";
};
export type ProfileCash_profile$data = ProfileCash_profile;
export type ProfileCash_profile$key = {
    readonly " $data"?: ProfileCash_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileCash_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileCash_profile",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Deposit_profile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Withdraw_profile"
    }
  ],
  "type": "UserProfile"
};
(node as any).hash = '17497a97a323710be4409bd010ade66e';
export default node;
