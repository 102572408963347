import React from "react"

import { StyledText, ContentContainer } from "./styles"

import getDescriptionFrom from "./utils"

const render = (props) => (
  <ContentContainer>
    <StyledText>{getDescriptionFrom(props)}</StyledText>
  </ContentContainer>
)

render.defaultProps = {
  cropped: false,
}

export default render
