import React from "react"

import { Translate, MiniLoader } from "src/components"
import { getOrderTicker, toCurrencyFormat } from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardBoldValue,
  CardAmount,
  CardStatus,
  CardStatusValue,
  CardStatusBar,
  CardButton,
  CardButtonsWrapper,
  CardCover,
} from "./styles"

import {
  getStatusbarValue,
  getOrderAmount,
  getOrderDate,
  getOrderName,
  getOrderDuration,
  getOrderRate,
} from "../Tables/utils"
import { statuses } from "../Tables/InvestmentActiveTable"
import { Statusbar } from "../Elements"

function InvestmentActiveCard(props) {
  const { toProposal, toInvest, cancelInvestment } = props
  const status = statuses.get(props.status) || {
    text: "Неизвестно",
    color: "grey",
  }

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          {getOrderDuration(props) && (
            <>
              {getOrderDuration(props)} <Translate i18n={"models:loan.month"} />
            </>
          )}
          {!getOrderDuration(props) && "_"}
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(props.amount)}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Сбор до</CardTitle>
        <CardValue>{getOrderDate(props)}</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={status.color}>{status.text}</CardStatusValue>
      </CardStatus>

      <CardStatusBar>
        <CardAmount>{getOrderAmount(props)}</CardAmount>
        <Statusbar width="100%" value={getStatusbarValue(props)} />
      </CardStatusBar>

      <CardButtonsWrapper>
        {props.status !== "TRANSFER" && (
          <>
            <CardButton compact onClick={() => toProposal(props)}>
              Подробнее
            </CardButton>
            <CardButton compact onClick={() => cancelInvestment(props)}>
              ОТМЕНИТЬ ИНВЕСТИЦИЮ
            </CardButton>
            <CardButton primary onClick={() => toInvest(props)}>
              ИНВЕСТИРОВАТЬ ЕЩЕ
            </CardButton>
          </>
        )}

        {props.status === "TRANSFER" && (
          <CardButton onClick={() => toProposal(props)}>Подробнее</CardButton>
        )}
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0%</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          0 <Translate i18n={"models:loan.month"} />
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Сбор до</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color="#6FA84B">Идет сбор средств</CardStatusValue>
      </CardStatus>

      <CardStatusBar>
        <CardAmount>0М из 0М</CardAmount>
        <Statusbar width="100%" value={0} />
      </CardStatusBar>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

InvestmentActiveCard.placeholder = Placeholder

export default InvestmentActiveCard
