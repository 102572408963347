/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountMenu_viewer = {
    readonly email: string;
    readonly trust: {
        readonly isAvailable: boolean;
    };
    readonly " $refType": "AccountMenu_viewer";
};
export type AccountMenu_viewer$data = AccountMenu_viewer;
export type AccountMenu_viewer$key = {
    readonly " $data"?: AccountMenu_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountMenu_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountMenu_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrust",
      "kind": "LinkedField",
      "name": "trust",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAvailable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
(node as any).hash = 'e8636c4865d5eb54a3464d9b168b5bde';
export default node;
