const changeHandler = (props) => (inputType = "text") => (event) => {
  const { name: injectedName, value: injectedValue, onChange } = props

  let changedName
  let changedValue

  if (inputType === "scans") {
    const { name, value } = event

    changedName = name
    changedValue = value
  } else if (inputType === "text") {
    const { name, value } = event.target

    changedName = name
    changedValue = value
  } else if (inputType === "checkbox") {
    const { name, checked } = event.target

    changedName = name
    changedValue = checked
  }

  const nextValue = { ...injectedValue }

  nextValue[changedName] = changedValue

  onChange({
    name: injectedName,
    value: nextValue,
  })
}

export default changeHandler
