import React from "react"

import { Loader } from "src/components"

import { Container } from "./styles"

const BlockLoader = () => (
  <Container>
    <Loader margin="auto" />
  </Container>
)

export default BlockLoader
