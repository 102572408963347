import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 20px 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`

export default Container
