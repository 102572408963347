import React from "react"

import {
  Box, Label, FormRow, Translate, DateInput,
} from "src/components"

export class LicenseDate extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { value } = event.target
    this.props.onChange({
      name: this.props.name,
      value,
    })
  }

  render() {
    return (
      <FormRow>
        <Box width={280}>
          <Label>
            <Translate
              i18n="application.worksheet.rows.license_date.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box width={200}>
          <DateInput
            name="licenseDate"
            value={this.props.value}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default LicenseDate
