/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type AccountInfoNullQueryVariables = {};
export type AccountInfoNullQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly country: string;
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly __typename: string;
            readonly bankAccounts: ReadonlyArray<{
                readonly id: string;
                readonly status: BankAccountStatusEnum;
                readonly checkingAccount: string | null;
                readonly correspondentAccount: string | null;
                readonly bank: {
                    readonly bic: string;
                    readonly name: string;
                } | null;
                readonly paymentApprovalDocument: {
                    readonly url: string;
                } | null;
            }>;
            readonly name?: string;
            readonly inn?: string | null;
            readonly investorAccreditation?: {
                readonly status: ProfileAccreditationStatus | null;
                readonly declineReason: string | null;
            };
            readonly borrowerAccreditation?: {
                readonly status: ProfileAccreditationStatus | null;
                readonly declineReason: string | null;
            };
        }>;
    } | null;
};
export type AccountInfoNullQuery = {
    readonly response: AccountInfoNullQueryResponse;
    readonly variables: AccountInfoNullQueryVariables;
};



/*
query AccountInfoNullQuery {
  viewer {
    id
    country
    profiles {
      id
      __typename
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
        paymentApprovalDocument {
          url
        }
      }
      ... on IndividualProfile {
        name
        inn
        investorAccreditation: accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on EntrepreneurProfile {
        name
        inn
        investorAccreditation: accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on LegalEntityProfile {
        name
        inn
        borrowerAccreditation: accreditation(role: BORROWER) {
          status
          declineReason
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "declineReason",
    "storageKey": null
  }
],
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": "investorAccreditation",
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "INVESTOR"
      }
    ],
    "concreteType": "Accreditation",
    "kind": "LinkedField",
    "name": "accreditation",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": "accreditation(role:\"INVESTOR\")"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "country",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profiles",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccount",
            "kind": "LinkedField",
            "name": "bankAccounts",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkingAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "correspondentAccount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Bank",
                "kind": "LinkedField",
                "name": "bank",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bic",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "paymentApprovalDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "EntrepreneurProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "borrowerAccreditation",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "role",
                    "value": "BORROWER"
                  }
                ],
                "concreteType": "Accreditation",
                "kind": "LinkedField",
                "name": "accreditation",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "accreditation(role:\"BORROWER\")"
              }
            ],
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountInfoNullQuery",
    "selections": (v6/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountInfoNullQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountInfoNullQuery",
    "operationKind": "query",
    "text": "query AccountInfoNullQuery {\n  viewer {\n    id\n    country\n    profiles {\n      id\n      __typename\n      bankAccounts {\n        id\n        status\n        checkingAccount\n        correspondentAccount\n        bank {\n          bic\n          name\n        }\n        paymentApprovalDocument {\n          url\n        }\n      }\n      ... on IndividualProfile {\n        name\n        inn\n        investorAccreditation: accreditation(role: INVESTOR) {\n          status\n          declineReason\n        }\n      }\n      ... on EntrepreneurProfile {\n        name\n        inn\n        investorAccreditation: accreditation(role: INVESTOR) {\n          status\n          declineReason\n        }\n      }\n      ... on LegalEntityProfile {\n        name\n        inn\n        borrowerAccreditation: accreditation(role: BORROWER) {\n          status\n          declineReason\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd276b87b146a00f0ff385e5b7aee7499';
export default node;
