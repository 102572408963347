import React from "react"

import { AttachmentField, TextField } from "../index"

import { Container, Title, Field } from "./styles"

function Group(props) {
  const { title, fields } = props.item

  return (
    <Container>
      <Title>{title}</Title>

      {fields.map((field, idx) => (
        <Field key={idx}>
          {field.type === "text" && <TextField data={field} />}
          {field.type === "attachment" && <AttachmentField data={field} />}
        </Field>
      ))}
    </Container>
  )
}

export default Group
