import React from "react"
import Button from "./styles"

const render = ({ children, ...props }) => (
  <Button {...props}>{children}</Button>
)

render.defaultProps = {
  variant: "primary",
  height: "40px",
}

export default render
