import React from "react"
import memoize from "memoize-one"

import { Translate } from "src/components"

import { getInvestorInn } from "../modalUtils"

const CESSION = "cession"
const INITIAL = "initial"

const paymentRequisites = memoize((order, viewer) => {
  const investorInn = getInvestorInn(viewer.profiles)
  const { inn: borrowerInn } = order.profile
  const { cession, orderNumber } = order

  const { duration, interestRate } = order.offer.data

  const orderType = cession && cession.isActive ? CESSION : INITIAL

  return [
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.payment_target.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.payment_target.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.nominal_account.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.nominal_account.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.target_inn.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.target_inn.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_name.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_name.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_bic.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_bic.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.correspondent_account.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.correspondent_account.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_inn.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_inn.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_kpp.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_kpp.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_address.label" />
      ),
      value: (
        <Translate i18n="components:bank_modal.invest.requisites.items.bank_address.value" />
      ),
    },
    {
      label: (
        <Translate i18n="components:bank_modal.invest.requisites.items.payment_reason.label" />
      ),
      value: (
        <Translate
          i18n={`components:bank_modal.invest.requisites.items.payment_reason.value.${orderType}`}
          orderNumber={orderNumber}
          borrowerInn={borrowerInn}
          duration={duration}
          interestRate={interestRate}
          investorInn={investorInn}
        />
      ),
    },
  ]
})

export default paymentRequisites
