/* eslint-disable max-len */
import React from "react"

const Other = () => (
  <g fill="#111" fillRule="evenodd">
    <path
      fillRule="nonzero"
      d="M3 0v6.75H0v3C0 11.025.975 12 2.25 12h7.5c1.275 0 2.25-.975 2.25-2.25V0H3zm7.5 9.75c0 .45-.3.75-.75.75H4.5v-9h6v8.25z"
    />
    <path d="M6 3.75h3v1.5H6zM6 6.75h3v1.5H6z" />
  </g>
)

const Estate = () => (
  <path
    fill="#0B1016"
    fillRule="nonzero"
    d="M13.779 5.452L7.362.133a.579.579 0 0 0-.738 0L.207 5.452A.593.593 0 0 0 .521 6.49a.578.578 0 0 0 .425-.125l.797-.66v6.703c0 .157.062.307.17.418a.58.58 0 0 0 .413.173h3.5v-2.955H8.16V13h3.5a.58.58 0 0 0 .412-.173c.11-.11.171-.261.171-.418V5.706l.797.66a.581.581 0 0 0 .957-.515.6.6 0 0 0-.218-.4zm-5.62 2.23H5.827V5.318H8.16v2.364z"
  />
)

const Vehicle = () => (
  <path
    fill="#0B1016"
    fillRule="nonzero"
    d="M11.775 6.225l-.6-.6L10.5 2.1c-.075-.375-.375-.6-.75-.6H7.5V0h-3v1.5H2.25c-.375 0-.675.225-.75.6L.825 5.625l-.6.6A.68.68 0 0 0 0 6.75v3c0 .45.3.75.75.75v.75c0 .45.3.75.75.75H3c.45 0 .75-.3.75-.75v-.75h4.5v.75c0 .45.3.75.75.75h1.5c.45 0 .75-.3.75-.75v-.75c.45 0 .75-.3.75-.75v-3a.68.68 0 0 0-.225-.525zM2.25 9c-.45 0-.75-.3-.75-.75s.3-.75.75-.75.75.3.75.75-.3.75-.75.75zm.15-3.75L2.85 3h6.3l.45 2.25H2.4zM9.75 9C9.3 9 9 8.7 9 8.25s.3-.75.75-.75.75.3.75.75-.3.75-.75.75z"
  />
)

const Guarantor = () => (
  <g fill="#0B1016" fillRule="evenodd">
    <path d="M9.646 6.977a.595.595 0 0 0-.818.007.552.552 0 0 0-.007.788l1.645 1.586-.768.74-1.645-1.586a.585.585 0 0 0-.414-.171.603.603 0 0 0-.418.165.56.56 0 0 0-.17.402.545.545 0 0 0 .178.4l1.49 1.438c-.42.169-.875.241-1.33.212-.04.397-.202.773-.466 1.08.219.037.44.056.662.057.536 0 1.067-.1 1.562-.298a4.086 4.086 0 0 0 1.325-.853l1.23-1.2a.552.552 0 0 0-.004-.794L9.646 6.977zM1.064 6.666a2.1 2.1 0 0 1 .597-.394 3.308 3.308 0 0 1-.498-1.854 3.32 3.32 0 0 1 .62-1.82A3.507 3.507 0 0 1 3.324 1.39 3.651 3.651 0 0 1 5.3 1.175L5.55.932C5.798.693 6.07.479 6.36.29A4.871 4.871 0 0 0 3.456.164 4.722 4.722 0 0 0 1.03 1.705 4.441 4.441 0 0 0 .005 4.326a4.415 4.415 0 0 0 .768 2.702c.082-.13.18-.252.291-.362z" />
    <path d="M6.38 9.716L3.63 7.063a1.563 1.563 0 0 0-2.154 0 1.431 1.431 0 0 0 0 2.076l2.751 2.653a1.563 1.563 0 0 0 2.154 0 1.431 1.431 0 0 0 0-2.076zM12.62 1.33A4.797 4.797 0 0 0 9.292 0a4.797 4.797 0 0 0-3.329 1.33L3.26 3.935l.413.397c.342.329.804.513 1.287.513.483 0 .945-.184 1.287-.513l1.046-1.007 4.913 4.822.416-.402A4.457 4.457 0 0 0 14 4.538a4.457 4.457 0 0 0-1.379-3.209h-.001z" />
  </g>
)

const glyphs = {
  OTHER: Other,
  ESTATE: Estate,
  VEHICLE: Vehicle,
  GUARANTOR: Guarantor,
}

const Icon = ({ children }) => {
  const Glyph = glyphs[children]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
    >
      <Glyph />
    </svg>
  )
}

export default Icon
