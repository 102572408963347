import styled from "styled-components"

import { Icons } from "src/components"

export const Modal = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const Container = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryBlack};
  max-width: 420px;
  padding: 40px 30px 30px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 80px 20px;
  }
`

export const SuccessContainer = styled(Container)`
  max-width: 360px;
  padding: 90px 20px 50px;

  @media screen and (max-width: 480px) {
    max-width: 360px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
    padding: 90px 20px 50px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 22px;
  width: 32px;
  height: 32px;
  border: none;
  padding: 4px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const SuccessCloseButton = styled(CloseButton)`
  @media screen and (max-width: 480px) {
    display: inline-block;
  }
`

export const ReturnButton = styled(CloseButton)`
  width: auto;
  left: 14px;
  display: none;

  span {
    font-family: Geometria, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: black;
  }

  @media screen and (max-width: 480px) {
    left: 14px;
    display: flex;
  }
`

export const Title = styled.h3`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primaryBlacks[9]};
  margin: 0 0 34px;
`

export const Button = styled.button`
  width: 100%;
  background-color: inherit;
  border: 1px solid ${(props) => props.theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 16px 0 14px;
  color: ${(props) => props.theme.colors.mainRed};
  margin-top: 40px;
  text-transform: uppercase;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    padding: 24px 0 21px;
  }
`

export const Description = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: ${(props) => props.theme.colors.primaryBlack};
  text-align: center;
  margin: 14px 0 0;
`

export const SuccessIcon = styled(Icons.Success)`
  margin: 0 auto;
  width: 50px;
  height: 50px;
`

export const SuccessTitle = styled(Title)`
  text-align: center;
  margin: 16px 0 0;
`
