import React from "react"

import { OrderDataProvider, RelayEnvironmentConsumer } from "src/context"

const QueryProvider = (props) => {
  const { entity: Entity, data } = props
  return (
    <OrderDataProvider value={data}>
      <Entity data={data} injected={data.chain} />
    </OrderDataProvider>
  )
}

export default ({ entity }) => (injectedData) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => (
      <QueryProvider
        data={injectedData}
        entity={entity}
        environment={environment}
      />
    )}
  </RelayEnvironmentConsumer>
)
