import styled from "styled-components"

import { Illustration } from "./icons"

export const Modal = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const Container = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  width: 100%;
  overflow-y: auto;
  max-height: 90vh;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.textBlack};

  max-width: 600px;
  padding: 40px 25px;

  @media screen and (max-width: 800px) {
    max-width: 500px;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
  }
`

export const Title = styled.h5`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlacks[9]};
  text-align: center;
  margin: 60px 0 0;

  @media screen and (max-width: 480px) {
    margin-top: 90px;
  }
`

export const DetailTitle = styled(Title)`
  text-align: left;
  margin: 0 0 28px;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;

  @media screen and (max-width: 480px) {
    margin-top: 40px;
  }
`

export const Text = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  margin: 12px 0 0;
`

export const DetailText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-top: 20px;
  white-space: pre-line;
  text-align: left;
`

export const Bold = styled.span`
  font-weight: 700;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 480px) {
    margin-top: auto;
  }
`

export const DetailButtonContainer = styled(ButtonContainer)`
  justify-content: left;

  @media screen and (max-width: 480px) {
    margin-top: 30px;
  }
`

export const Button = styled.button`
  max-width: 160px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[8]};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: inherit;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding: 16px 0 14px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primaryBlack};
  }

  @media screen and (max-width: 480px) {
    padding: 24px 0 21px;
    max-width: calc(50% - 8px);
  }
`

export const PrimaryButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.mainRed};
  margin-right: 14px;

  a {
    color: ${({ theme }) => theme.colors.mainRed};
  }
`

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainRed};

  :hover {
    text-decoration: underline;
  }
`

export const DetailButton = styled(PrimaryButton)`
  margin: 0;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`

export const Image = styled(Illustration)`
  margin: 10px auto 0;

  @media screen and (max-width: 480px) {
    margin-top: 60px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;

  @media screen and (max-width: 480px) {
    right: 16px;
    top: 30px;
  }
`

export const ReturnButton = styled(CloseButton)`
  width: auto;
  left: 14px;
  display: none;

  span {
    font-family: Geometria, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: black;
  }

  @media screen and (max-width: 480px) {
    left: 14px;
    display: flex;
  }
`
