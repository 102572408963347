import { isInvestor, isBorrower } from "src/utils"

const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)))
const pipe = (...fns) => compose.apply(compose, fns.reverse())

const notEmpty = (arr) => arr && arr.length >= 1
const empty = (arr) => !arr || arr.length === 0

const notValidParams = (args) => args === undefined || args === null || typeof args === "string"

const when = (predicate, trueFunc) => (args) => {
  if (notValidParams(args)) {
    return args
  }

  return predicate(args) ? trueFunc(args) : args
}

const approved = when(
  ({ APPROVED }) => notEmpty(APPROVED),
  () => "APPROVED",
)

const mixed = when(
  (args) => {
    const { PENDING, DECLINED, APPROVED } = args

    return notEmpty(PENDING) && notEmpty(DECLINED) && empty(APPROVED)
  },
  () => "MIXED",
)

const pending = when(
  (args) => {
    const { PENDING, APPROVED } = args

    return notEmpty(PENDING) && empty(APPROVED)
  },
  () => "PENDING",
)

const declined = when(
  (args) => {
    const { PENDING, APPROVED, DECLINED } = args

    return notEmpty(DECLINED) && empty(PENDING) && empty(APPROVED)
  },
  () => "DECLINED",
)

const initial = when(
  (args) => {
    const {
      PENDING, INITIAL, DECLINED, APPROVED,
    } = args

    return (
      notEmpty(INITIAL) && empty(PENDING) && empty(APPROVED) && empty(DECLINED)
    )
  },
  () => "INITIAL",
)

const emptyList = when(
  (args) => {
    const {
      PENDING, INITIAL, APPROVED, DECLINED,
    } = args

    return (
      empty(INITIAL) && empty(PENDING) && empty(APPROVED) && empty(DECLINED)
    )
  },
  () => "EMPTY",
)

const validate = pipe(approved, mixed, pending, declined, initial, emptyList)

const checkEqual = (role, status) => (profile) => profile[role] && profile[role].status === status

const getRoleStatuses = (profiles, accreditation) => (acc, status) => {
  const { key, name } = status

  acc[name] = profiles.filter(checkEqual(accreditation, key))

  return acc
}

const ProfileStatusEnum = [
  {
    name: "APPROVED",
    key: "APPROVED",
  },
  {
    name: "PENDING",
    key: "PENDING",
  },
  {
    name: "DECLINED",
    key: "DECLINED",
  },
  {
    name: "INITIAL",
    key: "INITIAL",
  },
]

const spreadFrom = (accreditation) => (profiles) =>
  ProfileStatusEnum.reduce(getRoleStatuses(profiles, accreditation), {})

const profilesBy = (typeCompareFn) => (profiles) => profiles.filter(typeCompareFn)

const statusFrom = (typeCompareFn) => (accreditation) =>
  pipe(profilesBy(typeCompareFn), spreadFrom(accreditation), validate)

// TODO: unused - вернуть позже или удалить окончательно
// const isRussianUser = (viewer) => viewer.country === "RU"

export default (data) => {
  const { profiles = [] } = data.viewer

  const investor = {
    status: statusFrom(isInvestor)("investorAccreditation")(profiles),
    profiles: profilesBy(isInvestor)(profiles),
  }

  const borrower = {
    status: statusFrom(isBorrower)("borrowerAccreditation")(profiles),
    profiles: profilesBy(isBorrower)(profiles),
  }

  return {
    investor,
    borrower,
  }
}
