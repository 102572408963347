import React, { useState, useEffect } from "react"

import {
  Translate,
  LoaderBox,
  MiniLoader,
  LoadMore,
  ModalDeclinedReason,
} from "src/components"
import { LoanDraftOrdersListQueryContainer } from "src/query"
import { getOrderTicker } from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  TDBold,
  ActionButton,
  TDStatus,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
} from "./styles"
import { Table, List } from "../Elements"
import {
  parseOrders,
  getOrderSum,
  getOrderName,
  getOrderRate,
  getOrderDuration,
} from "./utils"
import { DeclineIcon } from "./icons"
import { LoanDraftCard } from "../Cards"

export const statuses = new Map([
  [
    "INITIAL",
    {
      text: "Черновик",
      color: "#00679C",
    },
  ],
  [
    "DECLINED",
    {
      text: <>В размещении отказано {DeclineIcon}</>,
      color: "#F70000",
    },
  ],
  [
    "APPROVED",
    {
      text: "Ожидается оплата комиссии",
      color: "#FF8A00",
    },
  ],
  [
    "PENDING",
    {
      text: "На модерации",
      color: "#FF8A00",
    },
  ],
])

export function RenderedTable(props) {
  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [isShowing, setShowing] = useState(null)

  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
  } = props

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>{getOrderName(obj)}</TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => (
          <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>
        ),
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Срок",
        render: (i, obj) => (
          <TData key={i}>
            {getOrderDuration(obj) && (
              <>
                {getOrderDuration(obj)} <Translate i18n={"models:loan.month"} />
              </>
            )}
            {!getOrderDuration(obj) && "_"}
          </TData>
        ),
        placeholder: (i) => (
          <TData key={i}>
            0 <Translate i18n={"models:loan.month"} />
          </TData>
        ),
      },
      {
        title: "Сумма, ₽",
        render: (i, obj) => <TDBold key={i}>{getOrderSum(obj)}</TDBold>,
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Статус",
        render: (i, obj, isHover) => {
          const status = statuses.get(obj.status) || {
            text: "Неизвестно",
            color: "grey",
          }

          return (
            <TDStatus
              isClickable={obj.status === "DECLINED"}
              color={status.color}
              key={i}
              onClick={() => obj.status === "DECLINED" && setShowing(obj.id)}
            >
              {isHover && obj.status === "APPROVED" && (
                <ActionButton>ОПЛАТИТЬ КОМИССИЮ</ActionButton>
              )}
              {(!isHover || obj.status !== "APPROVED") && status.text}
            </TDStatus>
          )
        },
        placeholder: (i) => (
          <TDStatus color={"#00679C"} key={i}>
            Черновик
          </TDStatus>
        ),
      },
    ]
  }

  const { borrower } = data || {}
  const orders = [...parseOrders(borrower)]
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.borrower?.orders?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  return (
    <div>
      {(orders.length > 0 || isFetching) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="Черновики"
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              showModal={setShowing}
              title="Черновики"
              items={orders}
              Render={LoanDraftCard}
              isFetching={isFetching}
              toProposal={toProposal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}

          <ModalDeclinedReason
            isShowing={isShowing}
            onClose={() => setShowing(null)}
          />
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  const InvestmentActiveTable = LoanDraftOrdersListQueryContainer(
    (data) => <RenderedTable {...rest} {...data} />,
    profile,
  )

  return <InvestmentActiveTable />
}
