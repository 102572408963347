import React from "react"

import { ErrorsContainer } from "src/containers"
import { LocaleConsumer, RelayEnvironmentConsumer } from "src/context"

const ComposedConsumer = (props) => (
  <LocaleConsumer>
    {({ locale }) => (
      <ErrorsContainer>
        {(errors) => (
          <RelayEnvironmentConsumer>
            {({ environment }) => props.children({
              locale,
              errors,
              environment,
            })
            }
          </RelayEnvironmentConsumer>
        )}
      </ErrorsContainer>
    )}
  </LocaleConsumer>
)

export default ComposedConsumer
