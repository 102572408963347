import React from "react"

import {
  Box, Label, FormRow, Translate, TextField,
} from "src/components"

export default ({ value, onChange }) => (
  <FormRow>
    <Box width={240}>
      <Label whiteSpace="pre-line">
        <Translate
          i18n="accreditation.individual.rows.inn.label"
          ns="components"
        />
      </Label>
    </Box>
    <Box width="100%">
      <TextField type="number" name="inn" value={value} onChange={onChange} />
    </Box>
  </FormRow>
)
