import React from "react"

/* eslint-disable max-len */
const ViewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.2 59.2">
    <path d="M51.062 21.561c-11.889-11.889-31.232-11.889-43.121 0L0 29.501l8.138 8.138c5.944 5.944 13.752 8.917 21.561 8.917s15.616-2.972 21.561-8.917l7.941-7.941-8.139-8.137zm-1.217 14.664c-11.109 11.108-29.184 11.108-40.293 0l-6.724-6.724 6.527-6.527c11.109-11.108 29.184-11.108 40.293 0l6.724 6.724-6.527 6.527z" />
    <path d="M28.572 21.57c-3.86 0-7 3.14-7 7a1 1 0 0 0 2 0c0-2.757 2.243-5 5-5a1 1 0 0 0 0-2z" />
    <path d="M29.572 16.57c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.831-13-13-13zm0 24c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.934 11-11 11z" />
  </svg>
)

const HideIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.049 59.049">
    <path d="M11.285 41.39a1 1 0 0 0 1.247-1.564 27.366 27.366 0 0 1-2.305-2.06l-7.398-7.398 7.629-7.629c7.334-7.333 18.003-9.836 27.839-6.534a1.004 1.004 0 0 0 1.267-.63 1.002 1.002 0 0 0-.63-1.267c-10.562-3.545-22.016-.857-29.89 7.016L0 30.368l8.812 8.812a29.52 29.52 0 0 0 2.473 2.21zM50.237 21.325a29.373 29.373 0 0 0-4.394-3.616 1 1 0 0 0-1.115 1.661 27.342 27.342 0 0 1 4.094 3.369l7.398 7.398-7.629 7.629c-7.385 7.385-18.513 9.882-28.352 6.356a1 1 0 1 0-.675 1.883 28.97 28.97 0 0 0 9.776 1.693c7.621 0 15.124-2.977 20.665-8.518l9.043-9.043-8.811-8.812z" />
    <path d="M30.539 41.774c-2.153 0-4.251-.598-6.07-1.73a1 1 0 1 0-1.056 1.698 13.463 13.463 0 0 0 7.126 2.032c7.444 0 13.5-6.056 13.5-13.5a13.44 13.44 0 0 0-2.275-7.502 1 1 0 0 0-1.662 1.112 11.447 11.447 0 0 1 1.937 6.39c0 6.342-5.159 11.5-11.5 11.5zM30.539 18.774c2.065 0 4.089.553 5.855 1.6a1 1 0 0 0 1.019-1.721 13.49 13.49 0 0 0-6.875-1.879c-7.444 0-13.5 6.056-13.5 13.5 0 2.084.462 4.083 1.374 5.941a1 1 0 0 0 1.796-.88 11.392 11.392 0 0 1-1.17-5.06c.001-6.342 5.16-11.501 11.501-11.501zM54.621 5.567a.999.999 0 0 0-1.414 0l-46.5 46.5a.999.999 0 1 0 1.414 1.414l46.5-46.5a.999.999 0 0 0 0-1.414z" />
  </svg>
)

export default (props) => (props.visible ? <ViewIcon /> : <HideIcon />)
