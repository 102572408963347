import React from "react"
import styled from "styled-components"
import {
  color,
  fontSize,
  textAlign,
  fontWeight,
  letterSpacing,
} from "styled-system"

const Heading = styled.header`
  margin: 0;
  font-weight: 400;
  ${color}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${letterSpacing}
`

const render = ({ is, ...props }) => {
  const Component = is ? Heading.withComponent(is) : Heading
  return <Component {...props} />
}

render.h1 = (props) => render({ is: "h1", fontSize: 8, ...props })
render.h2 = (props) => render({ is: "h2", fontSize: 7, ...props })
render.h3 = (props) => render({ is: "h3", fontSize: 6, ...props })
render.h4 = (props) => render({ is: "h4", fontSize: 5, ...props })
render.h5 = (props) => render({ is: "h5", fontSize: 4, ...props })
render.h6 = (props) => render({ is: "h6", fontSize: 3, ...props })
render.h7 = (props) => render({ is: "h7", fontSize: 2, ...props })
render.h8 = (props) => render({ is: "h8", fontSize: 1, ...props })

export default render
