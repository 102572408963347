import React from "react"
import Relay, { graphql } from "react-relay"

import {
  Box, Flex, Heading, Translate, OrderFactory,
} from "src/components"

import { parseOrdersData } from "src/utils"

const Header = () => (
  <Box py="30px">
    <Heading.h3 textAlign="center">
      <Translate i18n="pages:guest-orders.completed-orders" />
    </Heading.h3>
  </Box>
)

const render = (props) => {
  const { completed } = props
  const orders = [...parseOrdersData({ orders: completed.completedOrders })]

  return (
    <>
      <Header />
      <Flex alignItems="flex-start" flexDirection="column">
        {orders.length
          ? orders.map((data, index) => {
            const Item = OrderFactory.createCompletedGuestOrder(data)

            return <Item key={index} />
          })
          : null}
      </Flex>
    </>
  )
}

export default Relay.createFragmentContainer(render, {
  completed: graphql`
    fragment CompletedGuest_completed on Query {
      completedOrders: orders(
        filter: { status: [COMPLETE, SUCCEEDED, TRANSFER] }
      ) {
        edges {
          node {
            id
            chain {
              id
              gatheredAmount
              investorsCount
            }
            status
          }
        }
      }
    }
  `,
})
