import React from "react"

import { QueryRenderer } from "src/components"
import Relay from "react-relay"
import { graphql } from "relay-runtime"

const query = graphql`
  query AccountsListQuery {
    viewer {
      ...AccountsList_viewer
    }
  }
`

const AccountsList = ({ render, ...rest }) => render(rest)

const refetchContainer = (render) => Relay.createRefetchContainer(
  (props) => <AccountsList {...props} render={render} />,
  {
    viewer: graphql`
        fragment AccountsList_viewer on User {
          id
          email
          country
          foreignProfiles {
            id
            __typename
            phone
            firstName
            lastName
            accreditation(role: INVESTOR) {
              status
            }
          }
          profiles {
            __typename
            ... on LegalEntityProfile {
              id
              name
              phone
              accountBalance
              accreditation(role: BORROWER) {
                status
              }
            }
            ... on IndividualProfile {
              id
              name
              phone
              accountBalance
              accreditation(role: INVESTOR) {
                status
              }
            }
            ... on EntrepreneurProfile {
              id
              name
              phone
              accountBalance
              accreditation(role: INVESTOR) {
                status
              }
            }
          }
        }
      `,
  },
  query,
)

export default (render) => (props) => {
  const Component = React.useMemo(() => refetchContainer(render), [render])
  return (
    <QueryRenderer
      {...props}
      query={query}
      render={(renderProps) => <Component {...renderProps} />}
    />
  )
}
