import styled from "styled-components"
import { textAlign, width } from "styled-system"
import { NavLink } from "react-router-dom"

import { Icons } from "src/components"

export * from "./Elements/wrappers"

export const TableLoader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 340px;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.75);
`

export const OrderContainerItems = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`
export const TableName = styled.div`
  width: 267px;
  padding-right: 37px;
  box-sizing: border-box;
  flex-shrink: 0;
  @media screen and (max-width: 1279px) {
    width: 190px;
  }
`

export const HeadTh = styled.th<any>`
  font-weight: 300;
  padding-bottom: 13px;
  ${textAlign};
  ${width};
`

export const HeadThBtn = styled.th<any>`
  font-weight: 300;
  padding-bottom: 13px;
  width: ${(props) => (props.isCession && "150px") || "120px"};
`

export const HeadTr = styled.tr``

export const TableHead = styled.thead`
  font-family: "Geometria", sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
`

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
`

export const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`

export const TooltipIcon = styled(Icons.Info)`
  width: 12px;
  height: 12px;
  margin-left: 4px;
  margin-bottom: -2px;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.primaryBlacks[7]};

  :hover {
    fill: ${({ theme }) => theme.colors.primaryBlack};
  }
`

export const TooltipText = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 10px;
  line-height: 14px;
  color: white;
  margin: 0;
`

export const TooltipLink = styled(NavLink)`
  text-decoration: underline;
  font-family: Geometria, sans-serif;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: white;
  margin: 0;
  cursor: pointer;
`

export const NotFoundBlock = styled.div`
  width: 100%;
  margin: 60px 20px;

  font-family: Geometria, sans-serif;
  color: rgba(74, 74, 74, 0.75);
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 300;
`
