import React from "react"
import { fetchQuery, graphql } from "relay-runtime"

import { useEnvironment } from "src/hooks"
import { Box, Loader } from "src/components"
import {
  isForeignInvestor,
  isRussianEntrepreneur,
  isRussianInvestor,
} from "src/utils"

import Russian from "./Russian"
import International from "./International"
import RussianEntrepreneur from "./RussianEntrepreneur"

const nodeTypeQuery = graphql`
  query InvestorTypenameQuery($id: ID!) {
    node(id: $id) {
      __typename
    }
  }
`

const russianInvestorQuery = graphql`
  query InvestorRussianQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ...RussianIndividualFragment_profile
    }

    viewer {
      id
      createdAt
    }
  }
`

const russianEntrepreneurQuery = graphql`
  query InvestorRussianEntrepreneurQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ...RussianEntrepreneurFragment_profile
    }

    viewer {
      id
      createdAt
    }
  }
`

const foreignInvestorQuery = graphql`
  query InvestorForeignQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ...InternationalIndividualFragment_profile
    }

    viewer {
      id
      createdAt
    }
  }
`

const viewerInfoQuery = graphql`
  query InvestorViewerQuery {
    viewer {
      id
      createdAt
    }
  }
`

const isProfileSet = (profile) => !!profile || profile === null
const belongsToType = (compareFn, profile, profileType) => compareFn(profile || { __typename: profileType })

const Investor = (props) => {
  const { environment } = useEnvironment()
  const [busy, setBusy] = React.useState(false)
  const [profile, setProfile] = React.useState(undefined)
  const [user, setUser] = React.useState(undefined)

  React.useEffect(() => {
    if (!props.profileId) {
      setBusy(true)

      fetchQuery(environment, viewerInfoQuery, { id: props.profileId })
        .then(({ viewer }) => {
          setBusy(false)
          setProfile(null)
          setUser(viewer)
        })
        .catch(() => {
          setBusy(false)
        })

      return
    }

    setBusy(true)
    fetchQuery(environment, nodeTypeQuery, { id: props.profileId })
      .then(({ node }) => {
        if (isRussianInvestor(node)) {
          return fetchQuery(environment, russianInvestorQuery, {
            id: props.profileId,
          })
        }

        if (isForeignInvestor(node)) {
          return fetchQuery(environment, foreignInvestorQuery, {
            id: props.profileId,
          })
        }

        if (isRussianEntrepreneur(node)) {
          return fetchQuery(environment, russianEntrepreneurQuery, {
            id: props.profileId,
          })
        }

        throw new Error("Wrong profile id")
      })
      .then(({ node, viewer }) => {
        setBusy(false)
        setProfile(node)
        setUser(viewer)
      })
      .catch(() => {
        setBusy(false)
      })
  }, [])

  if (busy) {
    return (
      <Box m="80px auto">
        <Loader fontSize="10px" />
      </Box>
    )
  }

  if (!isProfileSet(profile)) {
    return null
  }

  if (belongsToType(isRussianInvestor, profile, props.type)) {
    return <Russian profile={profile} viewer={user} />
  }

  if (belongsToType(isForeignInvestor, profile, props.type)) {
    return <International profile={profile} viewer={user} />
  }

  if (belongsToType(isRussianEntrepreneur, profile, props.type)) {
    return <RussianEntrepreneur profile={profile} viewer={user} />
  }

  return null
}

export default React.memo(Investor)
