import React from "react"

import memoize from "memoize-one"

import { OrderDataConsumer } from "src/context"
import { Translate } from "src/components"

import {
  Bold, PreLined, Container, TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

const MinAmountNotCollected = memoize(({ minValue }) => (
  <TextBlockWrapper>
    <PreLined>
      <Translate i18n="components:tooltips.min_amount_not_collected.head" />
      <Bold>{minValue} ₽</Bold>
    </PreLined>
  </TextBlockWrapper>
))

const render = memoize((order) => {
  const status = mapAmountToStatusFrom(order)

  return (
    <Container>
      {" "}
      {<MinAmountNotCollected {...status} id={order.id} />}
    </Container>
  )
})

export default () => <OrderDataConsumer>{render}</OrderDataConsumer>
