/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type PenaltiesInfo_order = {
    readonly id: string;
    readonly status: OrderStatusEnum | null;
    readonly paymentScheduleList: ReadonlyArray<{
        readonly info: {
            readonly state: string | null;
            readonly penalty: number | null;
            readonly pastdue_days: number | null;
        } | null;
        readonly items: ReadonlyArray<{
            readonly date: string;
            readonly state: string | null;
        }>;
    }>;
    readonly " $refType": "PenaltiesInfo_order";
};
export type PenaltiesInfo_order$data = PenaltiesInfo_order;
export type PenaltiesInfo_order$key = {
    readonly " $data"?: PenaltiesInfo_order$data;
    readonly " $fragmentRefs": FragmentRefs<"PenaltiesInfo_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PenaltiesInfo_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentSchedule",
      "kind": "LinkedField",
      "name": "paymentScheduleList",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LoanInfo",
          "kind": "LinkedField",
          "name": "info",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "penalty",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pastdue_days",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentScheduleItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order"
};
})();
(node as any).hash = 'cb1902209f846fd7bc15c830ca42dcac';
export default node;
