import React from "react"

import { Box, Text, Translate } from "src/components"

import { StyledList } from "./styles"
import SaveButton from "./SaveButton"

export const Controls = (props) => (
  <>
    <Box mb="20px">
      <SaveButton {...props} />
    </Box>
  </>
)

export const PaymentInfo = () => (
  <Box mt="25px" mb="10px">
    <Text
      fontSize="0.875rem"
      fontWeight="700"
      lineHeight="1.5"
      color="primaryBlack"
    >
      <Translate i18n="components:account.operations.deposit.instructions.title" />
    </Text>
    <Box>
      <StyledList>
        <li>
          <Text fontSize="0.875rem" color="primaryBlack" lineHeight="1.5">
            <Translate i18n="components:account.operations.deposit.instructions.points.0" />
          </Text>
        </li>
      </StyledList>
    </Box>
  </Box>
)
