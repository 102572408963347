import React, { useState, useCallback } from "react"
import { Icons } from "src/components"
import { useDebounceEffect } from "src/hooks"
import {
  Container,
  ClearButton,
  InputContainer,
  Input,
} from "./styles"

type Props = {
  value?: string
  onChange: (search: string | null) => void
  disabled?: boolean
  maxWidth?: string | number
  placeholder?: string
}

const SearchInput: React.FC<Props> = (props) => {
  const [value, setValue] = useState(props.value)

  useDebounceEffect(() => {
    props.onChange(value?.length > 0 ? value : null)
  }, [value, props.onChange])

  const handleChange = useCallback((event) => {
    setValue(event.target.value)
  }, [])

  const handleClear = useCallback(() => {
    if (props.disabled) {
      return
    }

    setValue("")
    props.onChange(null)
  }, [props.onChange, props.disabled])

  const fill = props.disabled ? "#a4a4a4" : "#5f5f5f"

  return (
    <Container maxWidth={props.maxWidth}>
      <InputContainer>
        <Input
          value={value || ""}
          onChange={handleChange}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
        {props.value ? (
          <ClearButton onClick={handleClear}>
            <Icons.Close fill={fill} />
          </ClearButton>
        ) : (
          <ClearButton>
            <Icons.Search fill={fill} />
          </ClearButton>
        )}
      </InputContainer>
    </Container>
  )
}

export default SearchInput
