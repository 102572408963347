import React from "react"

import { Translate, MiniLoader } from "src/components"

import { MobileInformationCompany } from "../Elements"

import {
  MobileWrapper as Container,
  MobileItemContainer,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  CardCover,
  FlipContainer,
  Flipper,
  FlipperFront,
} from "./styles"

function Info() {
  return (
    <CardCover>
      <MiniLoader changed fontSize="8px" margin="auto" />
    </CardCover>
  )
}

function MobileItem() {
  return (
    <MobileItemContainer minHeight={300}>
      <FlipContainer>
        <Flipper>
          <FlipperFront minHeight={300}>
            <HeaderWrap>
              <MobileHeaderContainer>
                <MobileInformationCompany name="_" />
              </MobileHeaderContainer>
            </HeaderWrap>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>№ заявки</MobileInformationName>
                <MobileInformationValue>_</MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rating"} />
                </MobileInformationName>
                <MobileInformationValue>_</MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>Сумма</MobileInformationName>
                <MobileInformationValue>0М</MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rate"} />
                </MobileInformationName>
                <MobileInformationValue>0%</MobileInformationValue>
              </MobileInformationItem>
            </MobileInformationContainer>
          </FlipperFront>
        </Flipper>
      </FlipContainer>
    </MobileItemContainer>
  )
}

export default {
  Container,
  Info,
  Body: MobileItem,
}
