import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation AddForeignProfileBankAccountMutation(
    $input: AddForeignProfileBankAccountInput!
  ) {
    addForeignProfileBankAccount(input: $input) {
      profile {
        id
        foreignBankAccounts: allBankAccounts {
          ... on ForeignBankAccount {
            id
            status
            IBAN
            ownerName
            bank {
              name
              address
              SWIFT
            }
          }
        }
        bankAccounts {
          ... on BankAccount {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
        }
      }
    }
  }
`
export default enhance({ mutation })
