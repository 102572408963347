import is from "is_js"

/* jslint browser: true */
/* eslint no-bitwise: ["error", { "allow": ["|", ">>", "&"] }] */

export default new (class {
  // https://coolaj86.com/articles/hashing-with-the-web-crypto-api.html
  private crypto: any

  private algos: { sha1: string; sha256: string; sha512: string }

  constructor() {
    this.crypto = window.crypto || (window as any).msCrypto
    this.algos = {
      sha1: "SHA-1",
      sha256: "SHA-256",
      sha512: "SHA-512",
    }
  }

  sha1sum = (str) => this.hashsum("sha1", str)

  hashsum = (hash, str) => {
    if (!is.existy(this.crypto)) {
      return new Promise((res) => res(false))
    }

    let ab
    const algo = { name: this.algos[hash] }

    if (typeof str === "string") {
      ab = this.str2ab(str)
    } else {
      ab = str
    }

    return this.crypto.subtle
      .digest(algo, ab)
      .then((digest) => this.ab2hex(digest))
      .catch((e) => {
        throw e
      })
  }

  ab2hex = (ab) => {
    const dv = new DataView(ab)
    let i
    let len
    let hex = ""
    let c

    for (i = 0, len = dv.byteLength; i < len; i += 1) {
      c = dv.getUint8(i).toString(16)
      if (c.length < 2) {
        c = `0${c}`
      }
      hex += c
    }

    return hex
  }

  str2ab = (stringToEncode, insertBOM?: any) => {
    const processedStringToEncode = stringToEncode.replace(/\r\n/g, "\n")
    const utftext = []

    if (insertBOM) {
      utftext[0] = 0xef
      utftext[1] = 0xbb
      utftext[2] = 0xbf
    }

    for (let n = 0; n < processedStringToEncode.length; n += 1) {
      const c = processedStringToEncode.charCodeAt(n)

      if (c < 128) {
        utftext[utftext.length] = c
      } else if (c > 127 && c < 2048) {
        utftext[utftext.length] = (c >> 6) | 192
        utftext[utftext.length] = (c & 63) | 128
      } else {
        utftext[utftext.length] = (c >> 12) | 224
        utftext[utftext.length] = ((c >> 6) & 63) | 128
        utftext[utftext.length] = (c & 63) | 128
      }
    }

    return new Uint8Array(utftext).buffer
  }

  str2abAscii = (str) => {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    const strLen = str.length

    for (let i = 0; i < strLen; i += 1) {
      bufView[i] = str.charCodeAt(i)
    }

    return buf
  }

  checksha1sum = (input, expected) => this.sha1sum(input).then((actual) => {
    if (actual !== expected) {
      throw new Error("sha1sums did not match :-(")
    } else {
      return true
    }
  })
})()
