import React from "react"

import { Icon } from "src/components"

import { Input, Container } from "./style"
import LandingCheckBox from "./Landing"

const render = (props) => (
  <Container>
    <Input {...props} type="checkbox" />
    <Icon fill={props.fill}>check</Icon>
  </Container>
)

render.landing = LandingCheckBox

export default render
