import idx from "idx"
import React from "react"
import memoize from "memoize-one"

import { OrderDataConsumer } from "src/context"
import { isActiveCession } from "src/utils"

import {
  Box,
  Blur,
  Button,
  Tooltip,
  Translate,
  RatingGlyph,
  ProfitabilityCalc,
} from "src/components"

import { StyledLink, ProfabilityIcon, ProfabilityWrapper } from "./styles"

import { TooltipContainer } from "../LoanType/styles"

const isInvestmentAvailable = memoize((order) => {
  const { chain, application } = order
  const { gatheredAmount } = chain
  const { maxValue } = application.data
  const availableAmount = Number(maxValue) - Number(gatheredAmount)

  return !!availableAmount && availableAmount > 0
})

const shouldRenderCalc = memoize((order, orderType) => {
  const { status, cession } = order
  const isValidStatus = status === "CONFIRMED"
  const isValidCession = !isActiveCession(order) || !!cession.originalChainId
  const isValidType = ["Order", "Proposal", "Investment"].includes(orderType)

  return (
    isValidStatus
    && isValidCession
    && isValidType
    && isInvestmentAvailable(order)
  )
})

const interestRateFromOrder = (order, isGuestMarket) => {
  if (isGuestMarket) return "NaN"
  let { interestRate } = order.offer.data

  if (isActiveCession(order)) {
    interestRate = `≈ ${interestRate.toFixed(1)}`
  }

  return `${interestRate} %`
}

const TooltipBody = () => (
  <>
    <Translate i18n="models:loan.cession_profitability_tooltip.0" />
    &nbsp;
    <StyledLink href="https://wow.karma.red/main/#rec107198934" target="_blank">
      <Translate i18n="models:loan.cession_profitability_tooltip.1" />
    </StyledLink>
  </>
)

class Content extends React.PureComponent<any, any> {
  state = { calcOpened: false }

  toggleCalc = () => {
    this.setState((state) => ({ calcOpened: !state.calcOpened }))
  }

  render() {
    const { order, orderType } = this.props

    if (!idx(order, (_) => _.offer.data.interestRate)) return null

    const isGuestMarket = orderType === "GuestOrder"
    const Wrapper = isGuestMarket ? Blur : React.Fragment
    const interestRate = interestRateFromOrder(order, isGuestMarket)

    return (
      <>
        <ProfabilityWrapper>
          <ProfabilityIcon />
          <Wrapper>{interestRate}</Wrapper>
          {isActiveCession(order) && (
            <TooltipContainer>
              <Tooltip
                borderColor="grey"
                borderWidth="2px"
                glyph={() => <RatingGlyph transform="none" />}
                content={TooltipBody}
              />
            </TooltipContainer>
          )}
        </ProfabilityWrapper>
        {shouldRenderCalc(order, orderType) && (
          <Box mt="8px">
            <Button
              p="0 10px"
              height="24px"
              variant="blueWide"
              onClick={this.toggleCalc}
            >
              <Translate i18n="models:loan.profitability_calc" />
            </Button>
          </Box>
        )}
        {this.state.calcOpened && (
          <ProfitabilityCalc order={order} onClose={this.toggleCalc} />
        )}
      </>
    )
  }
}

export default (props) => (
  <OrderDataConsumer>
    {(order) => <Content {...props} order={order} />}
  </OrderDataConsumer>
)
