import styled from "styled-components"
import { maxWidth, color } from "styled-system"

import { TopLevelInput } from "src/components"

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
`

export const Flex = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

export const MainTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #4a4a4a;
  font-family: Geometria, sans-serif;

  @media screen and (min-width: 481px) {
    display: none;
  }
`

export const ActiveSubTitle = styled.h5`
  width: 100%;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #6fa84b;
  font-family: Geometria, sans-serif;

  @media screen and (max-width: 480px) {
    text-align: center;
    margin-bottom: 50px;
  }
`

export const DisabledSubTitle = styled(ActiveSubTitle)`
  color: ${({ theme }) => theme.colors.mainRed};
`

export const InputWrapper = styled(Flex)`
  :not(:first-of-type) {
    margin-top: 40px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  margin-right: 24px;

  @media screen and (max-width: 480px) {
    margin: 0;
    max-width: 100%;
  }
`

export const Input = styled(TopLevelInput)`
  input {
    background-color: white;

    :disabled {
      opacity: 1;
      cursor: initial;
    }
  }

  @media screen and (max-width: 480px) {
    input {
      font-size: 18px;
      line-height: 23px;
      max-height: 75px;
      padding: 28px 40px 24px 30px;
      margin-top: 14px;

      :focus {
        padding: 27px 39px 23px 29px;
      }
    }

    input + div {
      font-size: 18px;
      line-height: 23px;
      padding: 20px 18px;
      height: 64px;
      top: 37px;
    }
  }
`

export const InputDescription = styled.p<any>`
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: Geometria, sans-serif;
  margin: 22px 0 0;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin: 16px 0 0;
  }

  ${maxWidth};
`

export const MainContainer = styled(Wrapper)`
  margin-top: 45px;

  @media screen and (max-width: 480px) {
    margin-top: 28px;
  }
`

export const MainDescription = styled(InputDescription)`
  margin: 0 0 20px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const RatingContainer = styled(Flex)`
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const ActionContainer = styled(Wrapper)`
  margin-top: 60px;
`

export const ButtonContainer = styled(Flex)`
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const Button = styled.button`
  position: relative;
  max-width: 220px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Geometria, sans-serif;
  font-size: 12px;
  line-height: 15px;
  padding: 17px 0 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  :not(:last-of-type) {
    margin-right: 32px;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    font-size: 24px;
    line-height: 29px;
    padding: 25px 0 21px;

    :not(:last-of-type) {
      margin: 0 0 25px;
    }
  }
`

export const PrimaryButton = styled(Button)`
  background: ${({ theme }) => theme.colors.mainRed};
  box-shadow: 0 5px 10px rgba(255, 116, 143, 0.55);
  color: white;
  border: none;
`

export const RatingBlockWrapper = styled.div<any>`
  position: relative;
  width: 100%;
  max-width: 76px;
  padding: 14px;
  border-radius: 4px;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  border: 1px solid
    ${({ active, backgroundColor }) => (active ? "#5f5f5f" : backgroundColor)};

  :not(:last-of-type) {
    margin-right: 10px;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    max-width: 100%;
    align-items: center;
    min-height: 70px;

    :not(:last-of-type) {
      margin: 0 0 15px;
    }
  }

  ${color};
`

export const RatingBlockTitle = styled.p`
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #5f5f5f;
  font-family: Geometria, sans-serif;
  margin: 0 0 20px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    max-width: 40px;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }
`

export const RatingBlockInput = styled(TopLevelInput)`
  input {
    font-size: 12px;
    line-height: 15px;
    padding: 7px 8px 5px;
    max-height: 32px;
    text-align: center;
    background-color: white;

    :focus {
      padding: 6px 7px 4px;
    }

    :disabled {
      opacity: 1;
      cursor: initial;
      border: none;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 75px;

    input {
      font-size: 14px;
      line-height: 18px;
      max-height: 40px;
      min-height: 40px;
    }
  }
`

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: 480px) {
    margin-bottom: 40px;

    label {
      align-items: flex-start;
    }
  }
`
