export const InitialState = {
  id: "",
  lastName: "",
  firstName: "",
  middleName: "",
  phone: "",
  isEntrepreneur: false,
}

interface IIndividualProfileProps {
  id: string;
  lastName: string;
  firstName: string;
  middleName: string;
  phone: string;
  isEntrepreneur: boolean;
}

export const profileDataFromState = (state: IIndividualProfileProps) => {
  const {
    id: profileId, lastName, firstName, middleName, phone,
  } = state

  const nextProfileData = {
    lastName: lastName.trim(),
    firstName: firstName.trim(),
    middleName: middleName?.trim() || null,
    phone,
  }

  if (profileId) {
    return {
      profileId,
      ...nextProfileData,
    }
  }

  return nextProfileData
}

export const getIdFromResponse = (res) => {
  const mutationInfo = res.addIndividualProfile || res.addEntrepreneurProfile
  return mutationInfo.profile.id
}
