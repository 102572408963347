import React from "react"
import styled from "styled-components"

import { CardWrapper, Text as BaseText } from "src/components"

export const Container = styled(CardWrapper)`
  padding: 28px;
  font-family: Geometria;
  font-size: 1rem;
  width: 100%;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`

export const Title = styled.h5`
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 26px;

  @media screen and (max-width: 800px) {
    margin-bottom: 16px;
  }
`

export const Text = styled(BaseText)`
  font-size: 14px;
  white-space: pre-line;
`

export const Bold = styled.span`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Link = styled.a`
  text-decoration: none;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 145%;
  display: inline-block;
  color: ${({ theme }) => theme.colors.mainRed};

  :hover {
    text-decoration: underline;
  }
`

export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 26px;

  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }
`

export const PeriodContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 26px;

  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }

  @media screen and (min-width: 901px) {
    flex-direction: row;

    & > * + * {
      margin-left: 16px;
    }
  }
`

/* eslint-disable max-len */
export const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 10V15.8333H4.16667V10H2.5V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333ZM10.8333 10.5583L12.9917 8.40833L14.1667 9.58333L10 13.75L5.83333 9.58333L7.00833 8.40833L9.16667 10.5583V2.5H10.8333V10.5583Z"
      fill="#3A5BBC"
    />
  </svg>
)

export const DownloadButton = styled.a<any>`
  flex-shrink: 0;
  font-family: Geometria, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  color: #3a5bbc;
  text-decoration: none;
  cursor: ${({ busy }) => (busy ? "wait" : "pointer")};
  display: flex;
  align-items: flex-end;
  padding: 0;
  ${({ disabled }) => disabled && "opacity: 0.5;"}

  span {
    margin-right: 8px;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`
