import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Row = styled(Container)`
  display: flex;
  align-items: center;

  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`

export const Label = styled.p`
  font-family: Geometria, sans-serif;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primaryBlack};
  width: 300px;
  text-transform: uppercase;
`

export const Value = styled(Label)`
  width: auto;
`
