import styled from "styled-components"
import { fontWeight } from "styled-system"

import { Flex } from "../ReferralForm/styles"

export const Container = styled(Flex)`
  width: 100%;
  margin-top: 60px;
  justify-content: space-between;
  font-family: Geometria, sans-serif;
  position: relative;

  @media screen and (max-width: 550px) {
    margin-top: 35px;
    flex-direction: column-reverse;
  }
`

export const TextBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const TextBlockTitle = styled.h3`
  font-family: Geometria, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;

  @media screen and (max-width: 800px) and (min-width: 551px) {
    font-size: 20px;
    line-height: 1.5;
  }

  @media screen and (max-width: 550px) {
    margin-top: 26px;
  }
`

export const Text = styled.p`
  font-size: 20px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  margin: 18px 0 0;

  @media screen and (max-width: 800px) and (min-width: 551px) {
    font-size: 14px;
  }
`

export const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 90px;
  padding: 40px;
  height: fit-content;
  min-width: 280px;

  @media screen and (max-width: 550px) {
    max-width: 100%;
    margin: 0;
  }
`

export const CardTitle = styled.h4`
  font-family: Geometria, sans-serif;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
  font-weight: 600;
`

export const CardTextBlock = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
`

export const CardLeftText = styled.p<any>`
  font-size: 16px;
  line-height: 120%;
  margin: 20px 0 0;
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${fontWeight};
`

export const CardSubText = styled.span`
  position: relative;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  font-size: 12px;
`

export const CardRightText = styled(CardLeftText)<any>`
  text-align: right;
  margin-left: 8px;
`

export const CardDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin: 20px 0 0;
`

export const CardRedDivider = styled(CardDivider)`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.mainRed};
`

export const CardDescription = styled.p`
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 50px 0 0;
`
