/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type Convert_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly name: string;
    readonly accountBalance: number;
    readonly accountBalanceDetailed: ReadonlyArray<{
        readonly currency: CurrencyEnum;
        readonly available: number;
    }>;
    readonly phone?: string | null;
    readonly inn?: string | null;
    readonly " $refType": "Convert_profile";
};
export type Convert_profile$data = Convert_profile;
export type Convert_profile$key = {
    readonly " $data"?: Convert_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"Convert_profile">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inn",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Convert_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileAccountBalance",
      "kind": "LinkedField",
      "name": "accountBalanceDetailed",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "LegalEntityProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "EntrepreneurProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = 'a27520c3c70681a01a2134d952ba09a0';
export default node;
