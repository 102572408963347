import React, { useCallback } from "react"

import {
  RatingBlockWrapper,
  RatingBlockTitle,
  RatingBlockInput,
} from "./styles"
import { defaultMinRates } from "./constants"

function RatingBlock(props) {
  const {
    value, active, onChange, name, title, bg, disabled, onClick,
  } = props

  const handleClick = useCallback(
    (event) => {
      if (event.target.tagName !== "INPUT" || !active) {
        onClick({ name })
      }
    },
    [onClick, name, active],
  )

  const handleBlur = useCallback(() => {
    if (!value && active) {
      onChange({ target: { name, value: defaultMinRates[name]?.value || 0 } })
    }
  }, [value, onChange, active, name])

  return (
    <RatingBlockWrapper
      backgroundColor={bg}
      active={active}
      onClick={disabled ? null : handleClick}
    >
      <RatingBlockTitle>{title}</RatingBlockTitle>
      <RatingBlockInput
        value={value && `${value}%`}
        onChange={onChange}
        onBlur={handleBlur}
        name={name}
        disabled={disabled}
      />
    </RatingBlockWrapper>
  )
}

export default RatingBlock
