import React from "react"

/* eslint-disable camelcase */
import card from "./card"
import empty from "./empty"
import h_6_4 from "./h_6_4"
import stepper from "./stepper"
import v_1_auto_1 from "./v_1_auto_1"
import containerWIthControls from "./containerWithControls"

const templates = {
  card,
  empty,
  h_6_4,
  stepper,
  v_1_auto_1,
  containerWIthControls,
}

const render = ({ layout, ...props }) => {
  const Template = templates[layout] || React.Fragment
  return <Template {...props} />
}

export default render
