import React from "react"

import {
  Flex, Text, Tooltip, Translate, RatingGlyph,
} from "src/components"

const TooltipContent = ({ originalChainId }) => (
  <Translate
    i18n="models:loan.loan_amount_cession.tooltip"
    number={originalChainId}
  />
)

const CessionMessage = ({ originalChainId }) => (
  <Flex width="235px">
    <Text fontSize="14px" lineHeight="1.43" color="greyDarker">
      <Translate i18n="models:loan.loan_amount_cession.label" />
    </Text>
    <Tooltip
      borderColor="grey"
      borderWidth="1px"
      glyph={() => <RatingGlyph transform="none" />}
      content={() => <TooltipContent originalChainId={originalChainId} />}
    />
  </Flex>
)

CessionMessage.defaultProps = {
  originalChainId: "",
}

export default CessionMessage
