import React from "react"
import { fetchQuery } from "relay-runtime"

import { OrderDataProvider, RelayEnvironmentConsumer } from "src/context"

import {
  LoanInfoQueryContainer,
  OrderInfoQueryContainer,
  GuestOrderInfoQueryContainer,
  InvestmentInfoQueryContainer,
  TrustedOrderInfoQueryContainer,
  CompletedOrderInfoQueryContainer,
  MarketOrderInfoInfoQueryContainer,
  CompletedGuestOrderInfoQueryContainer,
} from "src/query"

import { compose, parseOrder, setDataFromCession } from "src/utils"

import { SampleLoder } from "./Elements"

const schema = new Map([
  ["Loan", LoanInfoQueryContainer.query],
  ["Proposal", OrderInfoQueryContainer.query],
  ["Guest", GuestOrderInfoQueryContainer.query],
  ["Trusted", TrustedOrderInfoQueryContainer.query],
  ["Market", MarketOrderInfoInfoQueryContainer.query],
  ["Investments", InvestmentInfoQueryContainer.query],
  ["MarketCompleted", CompletedOrderInfoQueryContainer.query],
  ["GuestCompleted", CompletedGuestOrderInfoQueryContainer.query],
])

class QueryProvider extends React.Component<any, any> {
  state = {
    isLoading: true,
    entityData: null,
    fetchError: false,
  }

  componentDidMount() {
    this.setState(() => ({ isLoading: true }), this.fetchData)
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  handleResponse = (chain) => (res) => {
    const formatOrder = compose(setDataFromCession, parseOrder)
    this.setState(() => ({
      isLoading: false,
      entityData: { chain, ...formatOrder(res.node) },
    }))
  }

  fetchData = () => {
    const {
      query,
      data: { id, chain },
      environment,
    } = this.props

    const variables = { id }

    fetchQuery(environment, query, variables)
      .then(this.handleResponse(chain))
      .catch(() => this.setState(() => ({ fetchError: true })))
  }

  render() {
    const Entity = this.props.entity

    if (this.state.fetchError) {
      return null
    }

    return this.state.isLoading ? (
      <SampleLoder
        status={this.props.data.status}
        fetchError={this.state.fetchError}
      />
    ) : (
      <OrderDataProvider value={this.state.entityData}>
        <Entity data={this.state.entityData} injected={this.props.data} />
      </OrderDataProvider>
    )
  }
}

export default (props) => (data) => {
  const { target, entity } = props
  const query = schema.get(target)

  return (
    <RelayEnvironmentConsumer>
      {({ environment }) => (
        <QueryProvider
          data={data}
          query={query}
          entity={entity}
          environment={environment}
        />
      )}
    </RelayEnvironmentConsumer>
  )
}
