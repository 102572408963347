/* eslint-disable @typescript-eslint/no-unused-vars */
import { initialStateFromProps } from "src/utils"

import InitialBorrowerData from "./constants"

const parseNumber = (val) => (val?.replace?.(/\D/gim, "").trim() || "")

const parsePassportScans = (scans) => {
  if (!Array.isArray(scans)) return []
  return scans.map((scan) => (typeof scan === "string" ? scan : scan.id))
}

export const getSaveVariablesFromState = (state) => {
  const {
    busy,
    isLoading,
    confirmed,
    isSaveLoading,
    rulesApprove,
    passport,
    actualAddress,
    addressMatch,
    name,
    inn,
    ogrn,
    kpp,
    iian,
    ...rest
  } = state

  const passportData = { ...passport }
  passportData.scans = parsePassportScans(passportData.scans)

  return {
    actualAddress: addressMatch === true ? null : actualAddress,
    passport: passportData,
    name: name.trim(),
    inn: parseNumber(inn),
    ogrn: parseNumber(ogrn),
    kpp: parseNumber(kpp),
    iian: parseNumber(iian),
    ...rest,
  }
}

export const borrowerStateFromProps = (props) => {
  const {
    id, inn, iian, ogrn, name, kpp, publicEmail, address, passport, borrower,
  } = props.profile

  if (borrower === undefined || borrower === null) {
    return {
      ...InitialBorrowerData,
      inn,
      ogrn,
      kpp,
      iian,
      name,
      publicEmail,
      address,
      passport,
      profileId: id,
    }
  }

  const {
    bankAccounts,
    actualAddress,
    business,
    executive,
  } = props.profile

  const passportData = passport
    ? initialStateFromProps(passport, ["scans"])
    : InitialBorrowerData.passport

  return {
    inn,
    profileId: id,
    iian,
    ogrn,
    name,
    kpp,
    ...initialStateFromProps(borrower, ["publicEmail"]),
    executive: {
      ...initialStateFromProps(executive, ["name"]),
    },
    address: {
      ...initialStateFromProps(address, [
        "raw",
        "region",
        "area",
        "locality",
        "street",
        "house",
        "housing",
        "structure",
        "flat",
        "okato",
        "settlement",
        "postalCode",
        "cityDistrict",
      ]),
    },
    actualAddress: {
      ...initialStateFromProps(actualAddress, [
        "raw",
        "region",
        "area",
        "locality",
        "street",
        "house",
        "housing",
        "structure",
        "flat",
        "okato",
        "settlement",
        "postalCode",
        "cityDistrict",
      ]),
    },
    business: {
      ...initialStateFromProps(business, [
        "main",
        "aux",
        "startedAt",
        "legalForm",
      ]),
    },
    passport: passportData,
    bankAccounts,
    addressMatch: actualAddress == null,
  }
}
