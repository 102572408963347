import styled from "styled-components"

export const Container = styled.div`
  width: 320px;
  display: flex;
  border-radius: 3px;
  flex-direction: row;
  position: relative;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const AmountValue = styled.div<any>`
  z-index: 2;
  position: absolute;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.green};
  width: ${({ width }) => `${width}%`};
  left: 0;
  top: 0;
  height: 6px;
`

const borderColor = ({ theme, gathered }) => `${gathered ? theme.colors.whites[4] : theme.colors.greyDarks[4]}`

export const Divider = styled.div`
  z-index: 3;
  width: 32px;
  box-sizing: border-box;
  height: 6px;
  background: transparent;
  border-right: 1px solid ${borderColor};

  &:first-of-type {
    border-left: none;
    border-radius: 3px 0 0 3px;
  }

  &:last-of-type {
    border-right: none;
    border-radius: 0 3px 3px 0;
  }
`
