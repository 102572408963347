import React from "react"
import styled from "styled-components"

const IconWrapper = styled.div`
  max-width: 16px;
  max-height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-left: 16px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/icons/delete.svg);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`
const IconWrapperDisabled = styled(IconWrapper)`
  opacity: 0.5;

  &:hover {
    box-shadow: none;
  }
`

export const DeleteIcon = (props) => <IconWrapper {...props} />
export const DeleteIconDisabled = (props) => <IconWrapperDisabled {...props} />
