import React from "react"

import { Box, Document, Translate } from "src/components"

import { Subtitle } from "../styles"
import { formatDocuments } from "../../utils"

const docsMapFn = (doc, idx) => (
  <Box key={idx} mb="15px">
    <Document doc={doc} />
  </Box>
)

const ItemDocuments = ({ docs }) => {
  if (!docs.length) return null

  const formattedDocuments = formatDocuments(docs)

  return (
    <Box mb="25px">
      <Subtitle>
        <Translate i18n="components:account.order_item.provision.documents" />
      </Subtitle>
      {formattedDocuments.map(docsMapFn)}
    </Box>
  )
}

export default ItemDocuments
