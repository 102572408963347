const DefaultRoomItem = {
  address: {
    region: "",
    area: "",
    locality: "",
    street: "",
    house: "",
    housing: "",
    structure: "",
    flat: "",
  },
  tenure: "",
  purpose: "",
  area: "",
}

export default DefaultRoomItem
