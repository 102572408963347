import React from "react"

import { OrderDataConsumer } from "src/context"

import { InitialOrderBody, CessionOrderBody } from "./elements"

import { Container } from "./styles"

const Pennon = (props) => {
  const { order } = props
  const { status, cession } = order
  const isActiveCession = !!cession && cession.isActive

  return status === "DEAD" ? null : (
    <Container>
      {isActiveCession ? (
        <CessionOrderBody order={order} />
      ) : (
        <InitialOrderBody order={order} />
      )}
    </Container>
  )
}

export default (props) => (
  <OrderDataConsumer>
    {(orderData) => <Pennon {...props} order={orderData} />}
  </OrderDataConsumer>
)
