/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProvisionKindEnum = "ESTATE" | "GUARANTOR" | "OTHER" | "VEHICLE" | "%future added value";
export type Provision_order = {
    readonly provision: {
        readonly items: ReadonlyArray<{
            readonly id: string;
            readonly kind: ProvisionKindEnum;
            readonly name: string;
            readonly value: number;
            readonly description: string;
            readonly images: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly width: number | null;
                readonly height: number | null;
                readonly filename: string;
            }>;
            readonly videos: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly title: string | null;
                readonly description: string | null;
                readonly video: {
                    readonly url: string;
                };
            }>;
            readonly documents: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly filename: string;
            }>;
        }>;
        readonly value: number;
        readonly coverage: number;
    } | null;
    readonly " $refType": "Provision_order";
};
export type Provision_order$data = Provision_order;
export type Provision_order$key = {
    readonly " $data"?: Provision_order$data;
    readonly " $fragmentRefs": FragmentRefs<"Provision_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Provision_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderProvision",
      "kind": "LinkedField",
      "name": "provision",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "images",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ExternalMedia",
              "kind": "LinkedField",
              "name": "videos",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExternalMediaVideo",
                  "kind": "LinkedField",
                  "name": "video",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "documents",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order"
};
})();
(node as any).hash = '42c23c6891ccd08749e0078c3e1c4f28';
export default node;
