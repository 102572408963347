import React from "react"
import Relay, { graphql } from "react-relay"
import memoize from "memoize-one"

import { pipe, notifyGTM } from "src/utils"

import { DefaultModal } from "src/components"

import { ViewerDataConsumer } from "src/context"

import ModalType from "./Content"

const sendGTMEvent = memoize((order, viewer) => {
  if (!order || !order.chain) return () => {}

  const { id } = order.chain
  return notifyGTM({
    eventLabel: "pay-next",
    eventAction: `market-${id.split(".")[2]}`,
    extras: {
      "profile-id": viewer.id,
    },
  })
})

const InstructionsModal = (props) => {
  const {
    type, order, viewer, onClose, onNextStep,
  } = props

  const Body = ModalType[type]

  return (
    <DefaultModal
      padding="30px 40px"
      onClose={onClose}
      heightRestriction={false}
    >
      <Body
        order={order}
        onClose={onClose}
        onNextStep={pipe(sendGTMEvent(order, viewer), onNextStep)}
      />
    </DefaultModal>
  )
}

const Container = (props) => (
  <ViewerDataConsumer>
    {(viewer) => <InstructionsModal viewer={viewer} {...props} />}
  </ViewerDataConsumer>
)

export default Relay.createFragmentContainer(Container, {
  order: graphql`
    fragment InstructionsModal_order on Order {
      skipOffer
      application {
        data
      }
      chain {
        id
      }
      borrowerOffer: document(type: BORROWER_OFFER) {
        url
      }
      otherDocuments {
        description
        attachment {
          url
          filename
        }
      }
      guarantors {
        name
        description
        attachment {
          url
          filename
        }
      }
    }
  `,
})
