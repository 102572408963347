/* eslint-disable max-len */

import React from "react"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InfoIcon = ({ alert, ...rest }) => (
  <svg {...rest} viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.85 8.45H7.15V9.75H5.85V8.45ZM5.85 3.25H7.15V7.15H5.85V3.25ZM6.4935 0C2.9055 0 0 2.912 0 6.5C0 10.088 2.9055 13 6.4935 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.4935 0ZM6.5 11.7C3.627 11.7 1.3 9.373 1.3 6.5C1.3 3.627 3.627 1.3 6.5 1.3C9.373 1.3 11.7 3.627 11.7 6.5C11.7 9.373 9.373 11.7 6.5 11.7Z" />
  </svg>
)

export const CloseIcon = (props) => (
  <svg {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="black"
    />
  </svg>
)

InfoIcon.defaultProps = {
  width: "12px",
  height: "12px",
  fill: "#4a4a4a",
}

CloseIcon.defaultProps = {
  width: "12px",
  height: "12px",
  fill: "#4a4a4a",
}
