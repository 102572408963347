import React from "react"

import {
  Modal,
  CountDown,
  CloseIcon,
  CommitSuccessModal,
  ResendRequestButton,
} from "src/components"

import {
  Header,
  TokenInput,
  ContinueButton,
  ControlsContainer,
} from "./elements"

import { ModalWrapper, IconContainer, ModalContainer } from "./styles"

const setBodyOverflowStyle = (value) => {
  const body = document.querySelector("body")
  body.style.overflow = value
}

class ModerationRequestModal extends React.Component<any, any> {
  state = {
    token: "",
    value: undefined,
    isLoading: undefined,
  }

  componentDidMount() {
    setBodyOverflowStyle("hidden")

    this.props.startTimer()
  }

  /* eslint-disable class-methods-use-this */
  componentWillUnmount() {
    setBodyOverflowStyle("visible")
  }
  /* eslint-enable class-methods-use-this */

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))
  }

  onSubmit = () => {
    this.props.approveCallback(this.state.token)
  }

  requestPhoneConfirmation = () => {
    this.props.requestPhoneConfirmation()
    this.props.restartTimer()
  }

  render() {
    return this.props.confirmed ? (
      <CommitSuccessModal {...this.props} />
    ) : (
      <Modal>
        <ModalWrapper>
          <ModalContainer>
            <IconContainer onClick={this.props.close}>
              <CloseIcon />
            </IconContainer>
            <Header />
            <TokenInput onChange={this.onChange} value={this.state.value} />
            <ControlsContainer>
              <ResendRequestButton
                onClick={this.requestPhoneConfirmation}
                secondsLeft={this.props.secondsLeft}
                nowrap={true}
              />
              <ContinueButton
                isLoading={this.state.isLoading}
                onClick={this.onSubmit}
                disabled={!this.state.token}
              />
            </ControlsContainer>
          </ModalContainer>
        </ModalWrapper>
      </Modal>
    )
  }
}

export default (props) => (
  <CountDown>
    {({ start, restart, secondsLeft }) => (
      <ModerationRequestModal
        {...props}
        startTimer={start}
        restartTimer={restart}
        secondsLeft={secondsLeft}
      />
    )}
  </CountDown>
)
