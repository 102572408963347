import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetEntrepreneurProfileMutation(
    $profile: SetEntrepreneurProfileInput!
    $accreditation: UpdateEntrepreneurProfileAsInvestorInput!
  ) {
    setProfile: setEntrepreneurProfile(input: $profile) {
      profile {
        id
      }
    }

    requestAccreditation: updateEntrepreneurProfileAsInvestor(
      input: $accreditation
    ) {
      profile {
        ...AccreditationInfoFragment_profile
        id
        name
        phone
        birthDate
        birthPlace
        isPublicOfficial
        isRussiaTaxResident
        inn
        iian
        psrnie
        passport {
          id
          series
          number
          issuedAt
          issuedBy {
            code
            name
          }
          scans {
            id
            url
            filename
          }
        }
        actualAddress {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
        }
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
    }
  }
`

export default enhance({ mutation })
