import React from "react"
import styled from "styled-components"

import i18n from "src/i18n"
import { Tooltip, Translate } from "src/components"

const GlyphContent = styled.span`
  margin-left: -1px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 13px;
  font-weight: 600;
`

const TooltipBody = () => (
  <Translate i18n="components:signup-form.email-hint" ns="components" />
)

export const EmailTooltip = () => (
  <Tooltip
    borderWidth="1px"
    borderColor="grey"
    content={TooltipBody}
    glyph={() => <GlyphContent>?</GlyphContent>}
  />
)

export const errorForField = (error) => error && <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white} !important;
  font-size: 12px;
  text-decoration: underline;

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

const LabelWrapper = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`

const translateWithLink = (path, country) => {
  let target = null

  if (country && country === "RU") {
    target = ".russia"
  }

  if (country && country !== "RU") {
    target = ".international"
  }

  const url = i18n.t(`signup-form.accept-labels.${path}.link${target}`, {
    ns: "components",
  })

  return (
    <React.Fragment>
      <LabelWrapper>
        <Translate
          i18n={`components:signup-form.accept-labels.${path}.labels.0`}
        />
      </LabelWrapper>
      <StyledLink href={url} target="_blank">
        <Translate
          i18n={`components:signup-form.accept-labels.${path}.labels.1`}
        />
      </StyledLink>
    </React.Fragment>
  )
}

const translate = (key) => (
  <LabelWrapper>
    <Translate i18n={`components:signup-form.accept-labels.${key}.label`} />
  </LabelWrapper>
)

export const ConditionsAcceptedLabel = ({ country }) => translateWithLink("condition", country)
export const PersonalDataAcceptedLabel = ({ country }) => translateWithLink("personalData", country)
export const ShouldSubscribeToMailingListLabel = () => translate("shouldSubscribeToMailingList")
