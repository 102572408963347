import React from "react"
import Relay, { graphql } from "react-relay"

import { Translate } from "src/components"

import Modal from "./Modal"
import { StyledButton } from "./styles"

const isAccreditationApproved = (profile) => !!profile
  && !!profile.borrower && !!profile.investor
  && (profile.borrower.isApproved || profile.investor.isApproved)

const Deposit = (props) => {
  const { profile } = props
  const [shouldShowModal, setShouldShowModal] = React.useState(false)
  const showModal = React.useCallback(() => setShouldShowModal(true), [])
  const hideModal = React.useCallback(() => setShouldShowModal(false), [])

  const { RenderedButton } = props

  return (
    <>
      {RenderedButton && (
        <RenderedButton
          compact
          onClick={showModal}
          disabled={!isAccreditationApproved(profile)}
        >
          <Translate i18n="components:account.operations.deposit.buttons.deposit" />
        </RenderedButton>
      )}

      {!RenderedButton && (
        <StyledButton
          variant="outlinedRed"
          onClick={showModal}
          disabled={!isAccreditationApproved(profile)}
        >
          <Translate i18n="components:account.operations.deposit.buttons.deposit" />
        </StyledButton>
      )}

      {shouldShowModal && <Modal onClose={hideModal} profileId={profile.id} />}
    </>
  )
}

export default Relay.createFragmentContainer(Deposit, {
  profile: graphql`
    fragment Deposit_profile on UserProfile {
      id
      ... on IndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
        borrower {
          isApproved
        }
        investor {
          isApproved
        }
      }
      ... on ForeignIndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
        borrower {
          isApproved
        }
        investor {
          isApproved
        }
      }
      ... on EntrepreneurProfile {
        accreditation(role: INVESTOR) {
          status
        }
        borrower {
          isApproved
        }
        investor {
          isApproved
        }
      }
      ... on LegalEntityProfile {
        accreditation(role: BORROWER) {
          status
        }
        borrower {
          isApproved
        }
        investor {
          isApproved
        }
      }
    }
  `,
  foreignProfile: graphql`
    fragment Deposit_foreignProfile on ForeignIndividualProfile {
      id
      accreditation(role: INVESTOR) {
        status
      }
      borrower {
        isApproved
      }
      investor {
        isApproved
      }
    }
  `,
})
