import React from "react"

import { Translate } from "src/components"

import {
  Step,
  Label,
  LabelWrapper,
  IconContainer,
  StepperContainer,
} from "./styles"

import { Gone, Active, Default } from "./icons"

const renderStep = (label, index, length, activeIndex) => {
  const active = index === activeIndex
  const gone = activeIndex > index
  const isLast = index === length - 1
  const isFirst = index === 0

  return (
    <Step key={index} isLast={isLast}>
      <LabelWrapper isFirst={isFirst} isLast={isLast}>
        <Label isLast={isLast} active={active}>
          <Translate i18n={label} ns="components" />
        </Label>
      </LabelWrapper>
      <IconContainer gone={gone} active={active} isLast={isLast}>
        {gone && <Gone />}
        {active && <Active />}
        {!active && !gone && <Default />}
      </IconContainer>
    </Step>
  )
}

export default (props) => (
  <StepperContainer>
    {props.labels.map((label, index, labels) => renderStep(label, index, labels.length, props.activeStep))}
  </StepperContainer>
)
