import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation DeleteProfileMutation($input: DeleteProfileInput!) {
    deleteProfile(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
