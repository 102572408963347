import styled from "styled-components"

import { Icons } from ".."

export const Container = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primaryBlacks[9]};
  margin: 50px 0 0;
  font-weight: 600;
  padding: 0 20px;
  text-align: center;
`

export const Button = styled.a`
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  max-width: 160px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainRed};
  border-radius: 4px;
  margin: 50px 0 0;

  @media screen and (max-width: 480px) {
    max-width: 240px;
    height: 50px;
    margin-top: 20px;
  }
`

export const Illustration = styled(Icons.ClerkDoubt)`
  width: 120px;
  height: 120px;
`
