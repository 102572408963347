import React from "react"

import {
  Text, Tooltip, Translate, RatingGlyph,
} from "src/components"

import { Container, StyledLink, TooltipContainer } from "./styles"

const TooltipBody = () => (
  <>
    <Translate i18n="components:tooltips.rating" />
    <StyledLink to="/invest/scoring" target="_blank">
      <Translate i18n="components:tooltips.rating-link" />
    </StyledLink>
  </>
)

export default () => (
  <Container>
    <Text fontSize="13px" color="greyDark">
      <Translate i18n="loan.rating" ns="models" />
    </Text>
    <TooltipContainer>
      <Tooltip
        borderColor="grey"
        borderWidth="2px"
        glyph={() => <RatingGlyph transform="none" />}
        content={TooltipBody}
      />
    </TooltipContainer>
  </Container>
)
