import styled, { css } from "styled-components"

import { Icons } from "src/components"

import { Flex } from "../styles"

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  @media screen and (max-width: 800px) {
    margin-top: 32px;
  }
`

export const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 0 32px;
`

export const StepText = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 0 0 20px;
`

export const StepDescription = styled(StepText)`
  font-size: 14px;
  line-height: 18px;
  margin: 18px 0 0;
`

export const StepTextContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
`

export const StepContainer = styled(Flex)<any>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.primaryBlacks[1]};

  :last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryBlacks[1]};
  }

  ${({ completed }) => !completed
    && css`
      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: start;
      }
    `}

  ${({ completed }) => completed
    && css`
      ${StepText} {
        color: #6fa84b;
      }

      svg {
        fill: #6fa84b;
      }
    `}
`

export const Button = styled.button`
  position: relative;
  background: ${({ theme }) => theme.colors.mainRed};
  box-shadow: 0 5px 10px rgba(255, 116, 143, 0.55);
  border-radius: 4px;
  width: 150px;
  padding: 15px 0 13px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: white;

  :disabled {
    background-color: ${({ theme }) => theme.colors.mainReds[3]};
    box-shadow: none;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    margin-top: 24px;
  }
`

export const SuccessIcon = styled(Icons.Success)`
  width: 18px;
  height: 18px;
`

export const ProfileIcon = styled(Icons.Chel)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
`

export const CoinIcon = styled(Icons.Coin)`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
`

export const ContactIcon = styled(Icons.Contact)`
  width: 27px;
  height: 27px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
`

export const WalletIcon = styled(Icons.Wallet)`
  width: 26px;
  height: 24px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
`
