import React from "react"
import { fetchQuery } from "relay-runtime"

import { PreScheduleQuery } from "src/query"
import { DefaultModal } from "src/components"
import { MIN_INVESTMENT_AMOUNT } from "src/constants"
import { getPaymentSummaryByStatus } from "src/utils"
import { ViewerDataConsumer, RelayEnvironmentConsumer } from "src/context"

import { ModalContainer } from "./styles"
import Calculator from "./components/Calculator"
import Profitability from "./components/Profitability"
import PaymentSchedule from "./components/PaymentSchedule"

import { isDigit, formatNumber, fitsAvailableAmount } from "./utils"

class ProfitabilityCalc extends React.PureComponent<any, any> {
  state = {
    amount: "",
    busy: true,
    summary: null,
    schedule: null,
    monthlyProfit: null,
    overallProfit: null,
    scheduleOpened: false,
    availableAmount: undefined,
  }

  static getDerivedStateFromProps(props, state) {
    const { chain, application } = props.order
    const { gatheredAmount } = chain
    const { maxValue } = application.data
    const availableAmount = Number(maxValue) - Number(gatheredAmount)

    return state.availableAmount === availableAmount
      ? null
      : { availableAmount, amount: formatNumber(availableAmount) }
  }

  componentDidMount() {
    this.getPaymentSchedule()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  onChange = (e) => {
    let value = e.target.value.replace(/\D/g, "")

    if (!isDigit(value)) {
      e.preventDefault()
      return
    }

    if (e.target.tagName === "BUTTON") {
      this.setState({ amount: formatNumber(value) }, () => this.fetchSchedule(value))

      return
    }

    const { availableAmount } = this.state

    if (!fitsAvailableAmount(availableAmount)(value)) {
      value = availableAmount
    }

    this.setState({ amount: formatNumber(value) }, this.getPaymentSchedule)
  }

  getPaymentSchedule = () => {
    clearTimeout((this as any).debouncer)

    const amount = this.state.amount.replace(/\D/g, "")

    if (!Number(amount)) {
      this.setState({
        busy: false,
        summary: null,
        schedule: null,
        monthlyProfit: null,
        overallProfit: null,
      })

      return
    }

    (this as any).debouncer = setTimeout(() => this.fetchSchedule(amount), 1000)
  }

  fetchSchedule = async (amount) => {
    const currentAmount = this.state.amount.replace(/\D/g, "")

    if (amount !== currentAmount || Number(amount) < MIN_INVESTMENT_AMOUNT) return

    this.setState({ busy: true })

    const { environment, order } = this.props
    const { duration } = order.offer.data

    const variables = {
      orderId: order.id,
      amount: Number(amount),
    }

    try {
      const { preSchedule: schedule } = await fetchQuery(
        environment,
        PreScheduleQuery,
        variables,
      ) as any
      const summary = getPaymentSummaryByStatus(schedule, "new")

      const interest = summary.interest[this.props.currency]

      this.setState({
        summary,
        schedule,
        busy: false,
        monthlyProfit: (interest / duration).toFixed(),
        overallProfit: interest.toFixed(),
      })
    } catch {
      this.setState({
        busy: false,
        summary: null,
        schedule: null,
        monthlyProfit: null,
        overallProfit: null,
      })
    }
  }

  toggleSchedule = () => {
    const { schedule, scheduleOpened } = this.state

    if (!schedule && !scheduleOpened) return

    this.setState((state) => ({ scheduleOpened: !state.scheduleOpened }))
  }

  render() {
    const {
      busy,
      amount,
      summary,
      schedule,
      monthlyProfit,
      overallProfit,
      scheduleOpened,
      availableAmount,
    } = this.state

    const { order } = this.props
    const isActiveCession = order.cession && order.cession.isActive

    return (
      <DefaultModal
        padding="0"
        heightRestriction={false}
        closeButtonVariant="empty"
        onClose={this.props.onClose}
      >
        <ModalContainer>
          <Calculator
            busy={busy}
            amount={amount}
            onChange={this.onChange}
            orderId={order.id}
            availableAmount={availableAmount}
          />
          <Profitability
            busy={busy}
            monthlyProfit={monthlyProfit}
            overallProfit={overallProfit}
          />
        </ModalContainer>
        <PaymentSchedule
          summary={summary}
          schedule={schedule}
          opened={scheduleOpened}
          isCession={isActiveCession}
          onClick={this.toggleSchedule}
        />
      </DefaultModal>
    )
  }
}

export default (props) => (
  <ViewerDataConsumer>
    {({ currency }) => (
      <RelayEnvironmentConsumer>
        {({ environment }) => (
          <ProfitabilityCalc
            {...props}
            currency={currency}
            environment={environment}
          />
        )}
      </RelayEnvironmentConsumer>
    )}
  </ViewerDataConsumer>
)
