import React from "react"

import { Container, Title, Value } from "./styles"

function Block(props) {
  const { title, value } = props

  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  )
}

export default Block
