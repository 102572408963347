import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding: 16px 0;
`

export const ListContainer = styled.div`
  flex: 1 0 auto;
  margin: 0 16px;
  box-sizing: border-box;
`

export const Clickable = styled.button`
  background: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  margin: auto 0;
  padding: 0;
`

export const AttachmentLink = styled.a`
  width: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  text-decoration: none;
`
