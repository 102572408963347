import React from "react"
import PropTypes from "prop-types"
import { width } from "styled-system"
import styled from "styled-components"

const backgroundImage = ({ url, type }) => {
  if (!url && type === "IndividualProfile") {
    return "url(/images/user.svg);"
  }
  if (!url) {
    return "url(/images/users.svg);"
  }

  return `url(${url})`
}

const borderRadius = ({ rounded, circle }) => {
  if (circle && !rounded) {
    return "5px"
  }
  if (rounded && !circle) {
    return "50%"
  }

  return "0"
}

const Avatar = styled.div`
  background-image: ${backgroundImage};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${borderRadius};
  height: 0;
  padding-top: 100%;
  ${width}
`

const render = (props) => {
  const {
    url, type, width: widthFromProps, rounded,
  } = props

  return (
    <Avatar type={type} url={url} width={widthFromProps} rounded={rounded} />
  )
}

render.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  circle: PropTypes.bool,
  width: PropTypes.number,
  rounded: PropTypes.bool,
}

render.defaultProps = {
  width: 80,
  rounded: true,
  circle: false,
}

export default render
