import React from "react"
import { graphql } from "react-relay"

import { Box, Loader, QueryRenderer } from "src/components"

const query = graphql`
  query GuestOrdersListQuery {
    orders {
      ...GuestOrdersCount_orders
      edges {
        node {
          id
          chain {
            id
            gatheredAmount
            investorsCount
          }
          status
        }
      }
    }
    ...CompletedGuest_completed
  }
`

const MockLoader = () => (
  <Box py="60px">
    <Loader fontSize="14px" />
  </Box>
)

/* eslint-disable  arrow-body-style */
const Container = (render) => (props) => {
  return (
    <QueryRenderer
      {...props}
      query={query}
      render={render}
      renderNull={MockLoader}
    />
  )
}

Container.query = query

export default Container
