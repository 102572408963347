import React from "react"

import {
  Box, Flex, Button, Translate, FloatingLabelInput,
} from "src/components"

import { ExchangeRateConsumer } from "src/context"

import {
  WalletInfo,
  ModalHeader,
  ExchangeArrows,
  CurrencyTooltip,
  ConvertedAmount,
} from "./elements"

import { SubHeader } from "./styles"

const AmountModal = (props) => {
  const {
    amount,
    onClose,
    onChange,
    onComplete,
    balanceTargets,
    setBaseCurrency,
    getExchangePair,
  } = props

  const onBaseCurrencyChange = React.useCallback(() => {
    setBaseCurrency(balanceTargets.to.currency)
    onChange({ target: { value: "" } })
  }, [balanceTargets])

  const BaseCurrencyTooltip = React.useMemo(
    () => () => (
      <CurrencyTooltip>{balanceTargets.from.currency}</CurrencyTooltip>
    ),
    [balanceTargets],
  )

  const currentPair = React.useMemo(
    () => getExchangePair(balanceTargets.from.currency, balanceTargets.to.currency),
    [balanceTargets],
  )

  const convertedAmount = React.useMemo(() => {
    const { from, to } = balanceTargets
    const amountAsNumber = Number(amount.replace(",", ".").replace(/\s/g, ""))

    if (!amountAsNumber) return 0

    return props.convert(from.currency, to.currency, amountAsNumber)
  }, [amount])

  return (
    <>
      <ModalHeader i18n="components:account.operations.convert.amount.title" />
      <Flex alignItems="flex-end" mt="30px">
        <WalletInfo wallet={balanceTargets.from} type="from" />
        <ExchangeArrows onClick={onBaseCurrencyChange} />
        <WalletInfo wallet={balanceTargets.to} type="to" />
      </Flex>
      <Box mt="35px" mb="15px">
        <SubHeader>
          <Translate i18n="components:account.operations.convert.amount.amount_header" />
        </SubHeader>
      </Box>
      <FloatingLabelInput
        name="amount"
        value={amount}
        onChange={onChange}
        tooltip={BaseCurrencyTooltip}
        label="components:account.operations.convert.amount.amount_placeholder"
      />
      <ConvertedAmount
        currentPair={currentPair}
        convertedAmount={convertedAmount}
      />
      <Flex justifyContent="flex-end" mt="40px">
        <Button height="48px" variant="default" onClick={onClose}>
          <Translate i18n="components:account.operations.convert.amount.controls.cancel" />
        </Button>
        <Box ml="20px">
          <Button
            height="48px"
            variant="blueWide"
            onClick={onComplete}
            disabled={!convertedAmount}
          >
            <Translate i18n="components:account.operations.convert.amount.controls.convert" />
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default (props) => (
  <ExchangeRateConsumer>
    {({ rates, convert, getExchangePair }) => (
      <AmountModal
        {...props}
        convert={convert}
        exchangeRates={rates}
        getExchangePair={getExchangePair}
      />
    )}
  </ExchangeRateConsumer>
)
