import styled from "styled-components"

export const Wrapper = styled.div`
  font-family: Geometria;
  position: relative;
  min-width: 107px;
  max-width: 220px;
  width: 100%;
`

export const Title = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  font-weight: 600;

  @media screen and (max-width: 790px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Description = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.65);

  @media screen and (max-width: 790px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Value = styled.div`
  margin-top: 6px;
  font-size: 22px;
  line-height: 28px;
  color: #5f5f5f;
  font-weight: 600;

  @media screen and (max-width: 790px) {
    font-size: 30px;
    line-height: 38px;
    margin-top: 16px;
  }
`
