import React from "react"

import {
  Wrapper, TextContainer, Title, Text, Illustration,
} from "./styles"

function InfoWelcomePage() {
  return (
    <Wrapper>
      <Illustration />
      <TextContainer>
        <Title>Зарабатывай на Карма</Title>
        <Text>
          На платформе Карма вы можете выдать займы отобранным заемщикам прямо
          через интернет.
        </Text>
        <Text>
          Составляйте инвестиционный портфель и получайте регулярный доход.
        </Text>
        <Text>
          Полученный доход можно реинвестировать или вывести на ваш банковский
          счет.
        </Text>
      </TextContainer>
    </Wrapper>
  )
}

export default InfoWelcomePage
