import React from "react"

import {
  Container, Title, List, SubTitle,
} from "./styles"

const ProposalRisks = () => (
  <Container>
    <Title>ВНИМАНИЕ! Карма предупреждает, что:</Title>
    <List>
      <li>
        Инвестирование - это операция, связанная с высоким риском частичной или
        полной потери инвестиций
      </li>
      <li>
        Продажа прав требований, приобретенных инвестором в результате
        инвестирования, может быть затруднена или невозможна из-за
        непривлекательности таких прав для потенциальных покупателей
      </li>
      <li>Для снижения рисков распределяйте инвестиции по разным заемщикам</li>
    </List>

    <Title>
      Заёмщик — юридическое лицо проверяется по следующим факторам <br />
      (экспресс-оценка публичных сведений)
    </Title>
    <List>
      <li>
        Нахождение заемщика в перечене террористов и экстремистов
        (Росфинмониторинг)
      </li>
      <li>Наличие сведений о заемщике в реестре банкротств</li>
      <li>
        Статус заемщика: ликвидировано или находится в состоянии
        ликвидации/реорганизации
      </li>
      <li>Руководитель заемщика в реестре дисквалифицированных лиц</li>
      <li>Заемщик не представляет налоговую отчетность более года</li>
      <li>
        Наличие заемщика в реестре недобросовестных поставщиков (по 44-ФЗ и/или
        223-ФЗ)
      </li>
      <li>
        Наличие судебных разбирательств, где заемщик является ответчиком, с
        анализом существенности сумм требований
      </li>
      <li>
        Наличие судебных разбирательств, где заемщик является истцом, с анализом
        существенности сумм требований
      </li>
      <li>
        Наличие заемщика в реестре должников ФССП по исполнительным листам, с
        анализом существенности сумм взыскания
      </li>
      <li>Задолженность по уплате налогов на общую сумму более 1 000 рублей</li>
      <li>Со дня регистрации заемщика прошло менее 6 месяцев</li>
    </List>
    <Title>
      Проверка связанных физических лиц (руководитель, участники, бенефициары){" "}
    </Title>
    <List>
      <li>
        Нахождение физического лица в перечене террористов и экстремистов
        (Росфинмониторинг)
      </li>
      <li>Паспорт включен в список недействительных российских паспортов</li>
      <li>Наличие сведений в реестре дисквалифицированных лиц</li>
      <li>Наличие сведений о физическом лице в реестре банкротств</li>
      <li>Задолженность по уплате налогов</li>
      <li>Нахождение в реестре должников ФССП по исполнительным листам</li>
      <li>
        Физическое лицо является руководителем (исполнительным органом) более 5
        организаций
      </li>
      <li>
        Наличие судебных разбирательств, где физическое лицо является ответчиком
      </li>
    </List>
    <Title>
      Анализируются сведения и документы, предоставленные заемщиком
      Онлайн-сервису «Карма»{" "}
    </Title>
    <List>
      <li>
        Учредительные документы, в том числе подтверждающие полномочия
        исполнительных органов заемщика
      </li>
      <li>
        Финансовая (бухгалтерская) отчетность, включая: балансы, отчеты о
        финансовых результатах, налоговые декларации, оборотно-сальдовые
        ведомости, карточки счетов, сведения о текущих обязательствах (в т.ч.
        кредитных) и пр.
      </li>
      <li>
        Сведения о ведении бизнеса: данные о собственных или арендуемых основных
        средствах, лицензии, фото/видео материалы (презентации)
      </li>
    </List>
    <SubTitle>Категория обеспечения</SubTitle>
    <Title>Недвижимость</Title>
    <List>
      <li>Проверки по ЕГРН</li>
      <li>
        Проверка правообладания (документы-основания приобретения прав
        собственности)
      </li>
      <li>
        Проверки по прочим имеющимся факторам риска, например (по жилым
        помещениям): кто собственники, сколько их, возраст, как возникло право
        собственности, зарегистрированные лица, срок давности, история
        оспаривания, текущие споры и т.п.
      </li>
      <li>Оценка стоимости, ликвидности</li>
      <li>Автомобили</li>
      <li>
        Проверка обременения по Реестру уведомлений о залоге движимого имущества
      </li>
      <li>
        Проверки по прочим имеющимся факторам риска (год выпуска, пробег,
        наличие аварий, страховки и т.п.)
      </li>
      <li>Оценка стоимости, ликвидности</li>
    </List>
    <Title>Риски по проекту</Title>
    <List>
      <li>
        Оценка сумма заявки, срока и графика погашения, процентной ставки - по
        отношению к валюте баланса, годовой выручке, текущему финансовому
        результату, операционному циклу, сезонности, наличию альтернативных
        источников погашения
      </li>
      <li>
        Оценка рисков по целевому использованию: оборотное финансирование,
        покупка основных средств (недвижимости, оборудования), проектное
        финансирование, обеспечение тендерной заявки, обеспечение контракта, на
        выполнение контракта, выкуп доли УК, погашение кредита/займа и т.п.
      </li>
      <li>
        Оценка бизнес-плана (ТЭО, cash-flow), источника погашения,
        диверсификации по дебиторам/заказчикам
      </li>
    </List>
    <Title>Кредитная история</Title>
    <List>
      <li>Анализ отчетов БКИ</li>
      <li>Анализ истории, предоставленной заемщиком</li>
    </List>
    <Title>Мониторинг</Title>
    <List>
      <li>
        Динамика финансовых показателей по предоставляемой и публикуемой
        отчетности
      </li>
      <li>Данные от заемщика о текущих обязательствах</li>
    </List>
    <p>
      * Если заемщик-юридическое лицо входит в группу компаний, то
      экспресс-оценка и некоторые виды других обозначенных выше проверок
      осуществляется и в отношении связанных организаций.
    </p>

    <p>
      На основании проверки присваиваются рейтинг надежности заемщика и
      категория обеспечения (при наличии залога) по следующей шкале, от
      наилучшей к наихудшей оценке: AAA, AA, A, BBB, BB, B, CCC, CC, C, D.
    </p>
  </Container>
)

export default ProposalRisks
