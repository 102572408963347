import React from "react"

import { Box, Text, Translate } from "src/components"

import InfoLinkWrapper from "./styles"
import Attachments from "../Attachments"
import infoModalContent from "./infoModalContent"
import { InfoLink, ErrorElement, ErrorContainer } from "../Shared"

const Nullable = () => null

const getError = (error) => {
  const InjectedError = error

  if (InjectedError) {
    return (
      <ErrorContainer>
        <ErrorElement>{InjectedError}</ErrorElement>
      </ErrorContainer>
    )
  }

  return <Nullable />
}

const Selfie = (props) => {
  const { error, ...rest } = props

  return (
    <>
      <InfoLinkWrapper>
        <InfoLink
          content={infoModalContent}
          label="components:international_profile_form.extras.selfie"
        />
      </InfoLinkWrapper>
      <Attachments {...rest} />
      <Box mt="15px">
        <Text color="greyDark" lineHeight="1.5">
          <Translate i18n="components:international_profile_form.labels.KYC_document.format" />
        </Text>
      </Box>
      {getError(error)}
    </>
  )
}

export default Selfie
