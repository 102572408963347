import React from "react"
import { Flex, Text } from "src/components"

const render = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  height, fontSize, lineHeight, children, ...rest
}: any) => (
  <Flex display="flex" alignItems="center" lineHeight={lineHeight} {...rest}>
    <Text color="greyDarker" fontSize={fontSize} {...rest}>
      {children}
    </Text>
  </Flex>
)

render.defaultProps = {
  fontSize: 13,
  lineHeight: 1.5,
}

export default render
