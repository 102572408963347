import React from "react"
import styled from "styled-components"

import { AttachmentsListInput } from "src/components"
import { RelayEnvironmentConsumer } from "src/context"

const containerWidth = ({ width }) => `width: ${width}; min-width: ${width};`

const containerPadding = ({ padding }) => `padding: ${padding};`

const AttachmentsListInputContainer = styled.div<any>`
  ${containerWidth}
  ${containerPadding}
`
export type AttachmentType = {
  id: string;
  url: string;
  size: number;
  width: number | null;
  height: number | null;
  format: string | null;
  filename: string;
  isDeleteButtonDisabled?: boolean | null;
}

type AttachmentsProps = {
  environment: any;
  multiple: boolean;
  name: string;
  onChange: (event) => void;
  padding: string;
  value: AttachmentType[];
  width: string;
  disabled?: boolean | null;
}

class PassportScans extends React.Component<AttachmentsProps> {
  state = {
    attachments: this.props.value,
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = ({ name, value }) => {
    this.setState(() => ({ attachments: value.attachments }), this.forceUpdate)
  }

  forceUpdate = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state.attachments,
    })
  }

  render() {
    return (
      <AttachmentsListInputContainer
        width={this.props.width}
        padding={this.props.padding}
      >
        <AttachmentsListInput
          viewType="redesignedList"
          multiple={this.props.multiple}
          environment={this.props.environment}
          accept={"image/jpeg, image/png, application/pdf"}
          onChange={this.onChange}
          value={{ attachments: this.state.attachments }}
          name="files"
          disabled={this.props.disabled}
        />
      </AttachmentsListInputContainer>
    )
  }
}

(PassportScans as any).defaultProps = {
  width: "100%",
  padding: "0",
  multiple: true,
}

export default (props) => (
 <RelayEnvironmentConsumer>
    {({ environment }) => (
      <PassportScans {...props} environment={environment} />
    )}
  </RelayEnvironmentConsumer>
)
