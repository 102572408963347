/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ViewerTrustedOrdersListQueryVariables = {};
export type ViewerTrustedOrdersListQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly trust: {
            readonly isAvailable: boolean;
            readonly orders: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                    };
                }>;
            };
        };
    } | null;
};
export type ViewerTrustedOrdersListQuery = {
    readonly response: ViewerTrustedOrdersListQueryResponse;
    readonly variables: ViewerTrustedOrdersListQueryVariables;
};



/*
query ViewerTrustedOrdersListQuery {
  viewer {
    id
    trust {
      isAvailable
      orders {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTrust",
        "kind": "LinkedField",
        "name": "trust",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAvailable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderConnection",
            "kind": "LinkedField",
            "name": "orders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Order",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewerTrustedOrdersListQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ViewerTrustedOrdersListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ViewerTrustedOrdersListQuery",
    "operationKind": "query",
    "text": "query ViewerTrustedOrdersListQuery {\n  viewer {\n    id\n    trust {\n      isAvailable\n      orders {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ed922f3e11d4c3b1802fff578697696d';
export default node;
