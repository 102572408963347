export const TAB = {
  INFORMATION: "information",
  HISTORY: "history",
  BANK: "bank",
  TAXES: "taxes",
}

export const STATUS = {
  APPROVED: "APPROVED",
  INITIAL: "INITIAL",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
}

export const allStatuses = {
  INITIAL: {
    name: "Черновик",
    color: "#3A5BBC",
  },
  PENDING: {
    name: "На модерации",
    color: "#FF9900",
  },
  DECLINED: {
    name: "Отклонен",
    color: "#F70000",
  },
  APPROVED: {
    name: "Аккредитован",
    color: "#6FA84B",
  },
}

export const allProfileTypes = {
  IndividualProfile: "Физ. лицо",
  EntrepreneurProfile: "ИП",
  LegalEntityProfile: "ООО",
  ForeignIndividualProfile: "Физ. лицо",
}
