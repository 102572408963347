import styled, { css } from "styled-components"

export const CheckboxWrapper = styled.div`
  display: inline-block;
`

export const Checkbox = styled.input`
  display: none;
`

export const Label = styled.label<any>`
  position: relative;
  cursor: pointer;
  padding: 0;
  line-height: 18px;
  font-size: 14px;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: text-bottom;
    width: 17px;
    height: 17px;
    background: white;
    border: 1px solid #f70000;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &:after {
    ${(props) => props.isChecked
      && css`
        content: "";
        position: absolute;
        left: 3px;
        top: 5px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black,
          4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
        transform: rotate(45deg);
      `}
  }
`
