import styled from "styled-components"

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.mainRed};
  border-radius: 4px;
  padding: 0;
  border: none;
  width: 100%;
  height: 45px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  font-family: Geometria, sans-serif;
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    height: 65px;
  }
`

export const InputButton = styled.button`
  border: none;
  position: absolute;
  right: 10px;
  top: 16px;
  background-color: white;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`
