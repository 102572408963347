import React, { useState } from "react"

import { Modal } from "src/components"
import { InfoContainer, InfoCompanyName, InfoPlay } from "./styles"
import { play } from "./icons"

export default (props) => {
  const [handleModal, setHandleModal] = useState(false)
  const handleClose = () => setHandleModal(false)

  return (
    <>
      {handleModal && (
        <Modal.Video onClose={handleClose}>
          <iframe
            width="100%"
            height="100%"
            src={props.video.video.url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Modal.Video>
      )}

      <InfoContainer>
        <InfoCompanyName>{props.name}</InfoCompanyName>
        <InfoPlay>
          {props.video && (
            <div onClick={() => setHandleModal(true)}>{play}</div>
          )}
        </InfoPlay>
      </InfoContainer>
    </>
  )
}
