import styled from "styled-components"

export const Container = styled.div`
  width: 1180px;
  margin-left: 50px;
  padding: 50px 0;
  overflow: hidden;
`

export const Link = styled.a`
  font-size: 16px;
  color: #3a66ab;

  &:visited {
    color: #3a66ab;
  }
`

export const UnorderedList = styled.ul`
  margin: 6px 0 26px;
  padding-left: 24px;
  list-style: none;
  line-height: 24px;
  font-size: 16px;

  li {
    color: ${({ theme }) => theme.colors.greyDarkest};
    position: relative;
  }

  li::before {
    content: "•";
    position: absolute;
    left: -24px;
    font-size: 16px;
    padding-right: 0;
    color: ${({ theme }) => theme.colors.ginger};
  }
`

export const Bold = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`
