import styled from "styled-components"

export default styled.div`
  position: relative;
  width: 8px;
  height: 8px;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: ${({ theme }) => theme.colors.red};
  }

  &:after {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    background-color: ${({ theme }) => theme.colors.red};
  }
`
