const KYCDocumentsOptions = [
  {
    label: "components:international_profile_form.labels.passport",
    value: "PASSPORT",
  },
  {
    label: "components:international_profile_form.labels.id_card",
    value: "ID_CARD",
  },
  {
    label: "components:international_profile_form.labels.drivers",
    value: "DRIVERS",
  },
]

export default KYCDocumentsOptions
