import React from "react"

import Locales from "src/locales"
import { Flag } from "src/components"
import { LocaleConsumer } from "src/context"

import { LocaleList, LocaleButton } from "./style"

const setCurrentLocale = (setLocale) => (event) => {
  setLocale(event.currentTarget.value)
}

const renderLocale = ({ locale, setLocale }) => ({ value, flag, label }) => (
  <li key={value}>
    <LocaleButton
      value={value}
      active={value === locale}
      onClick={setCurrentLocale(setLocale)}
    >
      <Flag flag={flag} />
      <span>{label}</span>
    </LocaleButton>
  </li>
)

const render = ({ locale, setLocale }) => (
  <LocaleList>{Locales.map(renderLocale({ locale, setLocale }))}</LocaleList>
)

export default (props) => (
  <LocaleConsumer>
    {({ locale, setLocale }) => render({ ...props, locale, setLocale })}
  </LocaleConsumer>
)
