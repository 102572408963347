import { pipe, isRussianInvestor, isRussianEntrepreneur } from "src/utils"

const validateProfileType = ({ data, extras, errors }) => {
  const { profiles = [] } = extras.viewer || {}
  const hasIndividualProfile = profiles.some(isRussianInvestor)
  const hasEntrepreneurProfile = profiles.some(isRussianEntrepreneur)

  if (data.isEntrepreneur && hasEntrepreneurProfile) {
    const error = {
      dataPath: "/isEntrepreneur",
      keyword: "entrepreneurPresence",
    }
    return { data, extras, errors: [...errors, error] }
  }

  if (!data.isEntrepreneur && hasIndividualProfile) {
    const error = { dataPath: "/isEntrepreneur", keyword: "individualPresence" }
    return { data, extras, errors: [...errors, error] }
  }

  return { data, extras, errors }
}

const formatErrors = (mutationName) => ({ errors }) => {
  if (!errors.length) return null

  return {
    errors: [
      {
        path: [mutationName],
        extensions: {
          validations: [...errors],
        },
      },
    ],
  }
}

const promisifyValidationResult = (error) => (error ? Promise.reject(error) : Promise.resolve())

const createValidator = (mutationName) => (data, extras) => {
  const executeValidation = pipe(validateProfileType)
  const resolveValidation = pipe(
    executeValidation,
    formatErrors(mutationName),
    promisifyValidationResult,
  )

  return resolveValidation({
    data,
    extras,
    errors: [],
  })
}

export default createValidator
