import styled from "styled-components"

export const Container = styled.div<any>`
  width: 100%;
  max-width: calc(100% - 300px);
  position: relative;

  @media screen and (max-width: 800px) {
    max-width: calc(100% - 240px);
  }

  @media screen and (max-width: 800px) {
    display: ${(props) => (!props.tab && "none") || "block"};
    max-width: 100%;
  }
`

export const ReturnButton = styled.button`
  font-family: Geometria;
  font-size: 1rem;
  color: #4a4a4a;
  border-radius: 4px;
  background-color: inherit;
  margin: 0;
  margin-top: 26px;
  padding: 8px 8px 8px 0;
  font-weight: 600;
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
  }
`
