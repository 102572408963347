import styled from "styled-components"

export const Box = styled.div`
  position: relative;
  margin-top: 10px;
`

export const Link = styled.a`
  color: #3a5bbc;
  font-size: 16px;
  text-decoration: none;
  margin: 10px 0 0;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`

export const Empty = styled.p`
  color: #f70000;
  font-size: 16px;
  margin: 10px 0 0;

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`
