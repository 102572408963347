import React from "react"

import { Translate } from "src/components"

import { SubHeader, VideoWrapper } from "./styles"

const render = ({ medium, renderHeader }) => {
  if (medium.length === 0) return null

  const Header = renderHeader

  return (
    <React.Fragment>
      <Header>
        <Translate i18n="pages:user.proposal.titles.video_preview" />
      </Header>
      {medium.map((media, ii) => (
        <VideoWrapper key={ii}>
          <iframe src={media.video.url} />
        </VideoWrapper>
      ))}
    </React.Fragment>
  )
}

render.defaultProps = {
  renderHeader: SubHeader,
}

export default render
