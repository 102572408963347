import React from "react"
import { Route } from "react-router"
import { toast } from "react-toastify"
import Relay, { graphql } from "react-relay"

import { ErrorsContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import { CreateLegalEntityProfile, UpdateLegalEntityProfile } from "src/mutations"

import {
  Box,
  Text,
  Label,
  FormRow,
  Template,
  TextField,
  Translate,
  ImgUploader,
  RowsContainer,
  ErrorForField,
  PhoneConfirmInput,
  AccreditationSuggestModal,
} from "src/components"

import { initialStateFromProps } from "src/utils"
import { FormHeader, FormControls } from "./Elements"

class Form extends React.Component<any, any> {
  state = {
    busy: false,
    isLoading: false,
    showModal: false,
    ...initialStateFromProps(this.props.profile, [
      "id",
      "inn",
      "phone",
      "name",
      "_avatar",
      "signedPhone",
      "signedAvatar",
    ]),
  }

  applySignedPhone = (signedPhone) => {
    this.setState(() => ({
      signedPhone,
    }))
  }

  onLogoUpdate = (signedAvatar) => {
    this.setState(() => ({ signedAvatar }))
  }

  onLogoDelete = () => {
    this.setState(() => ({
      signedAvatar: "",
      _avatar: {
        url: "",
      },
    }))
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))
    this.props.clearError(`createLegalEntityProfile.${name}`)
  }

  onModalClose = () => {
    this.setState(() => ({ showModal: false }))
  }

  onSubmit = (event) => {
    if (event) event.preventDefault()

    if (this.state.busy) return

    this.setState(() => ({ busy: true, isLoading: true }), this.commit)
  }

  onError = (transaction) => {
    this.setState(() => ({ busy: false, isLoading: false }))
    this.props.setErrors(transaction)

    toast.error("К сожалению возникла ошибка", {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  onCompleted = (payload) => {
    toast.success("Изменения успешно сохранены", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const { createLegalEntityProfile } = payload

    if (!createLegalEntityProfile) {
      if (this.props.onCompleted) {
        this.props.onCompleted(payload)
      }

      this.props.history.push(
        `/profiles/${this.props.profile.id}/accreditation/borrower`,
      )

      return
    }

    let showModal = false
    let profileId = ""

    if (createLegalEntityProfile) {
      const {
        profile: { id },
      } = createLegalEntityProfile
      if (id) {
        showModal = true
        profileId = id
      }
    }

    this.setState(
      () => ({
        busy: false,
        isLoading: false,
        showModal,
      }),
      () => {
        if (showModal) {
          this.setState(() => ({ id: profileId }))
        }
      },
    )

    if (this.props.onCompleted) {
      this.props.onCompleted(payload)
    }
  }

  commit = () => {
    const variables = {
      input: {
        name: this.state.name,
        inn: this.state.inn,
        phone: this.state.phone,
        signedPhone: undefined,
        signedAvatar: undefined,
      },
    }

    if (this.state.signedPhone.length) {
      variables.input.signedPhone = this.state.signedPhone
    }

    if (this.state.signedAvatar.length) {
      variables.input.signedAvatar = this.state.signedAvatar
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    if (this.state.id) {
      this.update(variables, callbacks)
    } else {
      if (!this.state.signedPhone.length) {
        this.setState(() => ({ busy: false, isLoading: false }))
        toast.error("Подтвердите телефон", {
          position: toast.POSITION.TOP_RIGHT,
        })
        return
      }
      this.create(variables, callbacks)
    }
  }

  create = (variables, callbacks) => {
    CreateLegalEntityProfile.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  update = (variables, callbacks) => {
    UpdateLegalEntityProfile.commit(
      this.props.environment,
      {
        ...variables,
        input: {
          ...variables.input,
          id: this.state.id,
        },
      },
      null,
      callbacks,
    )
  }

  /* eslint-disable  no-underscore-dangle */
  renderFormContent = () => (
    <React.Fragment>
      <RowsContainer>
        <FormRow>
          <Label>
            <Translate
              i18n="juristic_profile_form.rows.name.label"
              ns="components"
            />
            <Box pt="6px" pr="32px">
              <Text fontSize={10}>
                <Translate
                  i18n="juristic_profile_form.rows.name.sub_label"
                  ns="components"
                />
              </Text>
            </Box>
          </Label>
          <Box width={480}>
            <TextField
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              error={ErrorForField(
                this.props.getError("createLegalEntityProfile.name"),
              )}
            />
          </Box>
        </FormRow>
        <FormRow>
          <Label>
            <Translate
              i18n="juristic_profile_form.rows.inn.label"
              ns="components"
            />
          </Label>
          <Box width={480}>
            <TextField
              type="text"
              name="inn"
              value={this.state.inn}
              onChange={this.onChange}
              error={ErrorForField(
                this.props.getError("createLegalEntityProfile.inn"),
              )}
            />
          </Box>
        </FormRow>
        <FormRow>
          <Label>
            <Translate
              i18n="individual_account.update.phone.label"
              ns="components"
            />
            <Box pt="6px" pr="32px">
              <Text fontSize={10}>
                <Translate
                  i18n="individual_account.update.phone.sub_label"
                  ns="components"
                />
              </Text>
            </Box>
          </Label>
          <Box width={380}>
            <PhoneConfirmInput
              applySignedPhone={this.applySignedPhone}
              phone={this.state.phone}
              onChange={this.onChange}
            />
          </Box>
        </FormRow>
      </RowsContainer>
      <ImgUploader
        url={this.state._avatar.url}
        onAvatarDelete={this.onLogoDelete}
        onAvatarUpdate={this.onLogoUpdate}
        environment={this.props.relay.environment}
      />
    </React.Fragment>
  )

  renderFooter = () => (
    <FormControls
      isLoading={this.state.isLoading}
      onCancel={this.props.onCancel}
      onSubmit={this.onSubmit}
    />
  )

  renderHeader = () => (
    <>
      <FormHeader inEdit={this.state.id.length} />
      <Text color="greyDarker">
        <Translate i18n="components:juristic_profile_form.hint" />
      </Text>
    </>
  )

  render() {
    return (
      <React.Fragment>
        <Template
          layout="card"
          header={this.renderHeader}
          content={this.renderFormContent}
          footer={this.renderFooter}
        />
        {this.state.showModal && (
          <AccreditationSuggestModal
            onClose={this.onModalClose}
            link={`/profiles/${this.state.id}/accreditation/borrower`}
          />
        )}
      </React.Fragment>
    )
  }
}

const render = (props) => (
  <Route>
    {({ history }) => (
      <ErrorsContainer>
        {(errors) => (
          <RelayEnvironmentConsumer>
            {({ environment }) => (
              <Form
                {...props}
                {...errors}
                environment={environment}
                history={history}
              />
            )}
          </RelayEnvironmentConsumer>
        )}
      </ErrorsContainer>
    )}
  </Route>
)

export default Relay.createFragmentContainer(render, {
  profile: graphql`
    fragment ManageLegalEntity_profile on LegalEntityProfile {
      id
      inn
      name
      phone
      _avatar {
        url
      }
    }
  `,
})
