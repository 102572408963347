import React from "react"
import { fetchQuery, graphql } from "relay-runtime"

import { useEnvironment } from "src/hooks"

import {
  Box,
  Text,
  Modal,
  Translate,
  DefaultModal,
  KarmaPaymentRequisites,
} from "src/components"

import { Controls, PaymentInfo } from "./elements"

import {
  ModalBox, Scrollable, TextError,
} from "./styles"

const query = graphql`
  query ModalVirtualAccountDepositRequisitesQuery($profileId: ID!) {
    localizedStrings {
      virtualDepositPurpose(profileId: $profileId) {
        name
        value
      }
    }
  }
`

const DepositModal = (props) => {
  // TODO: разобраться почему не используется busy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [busy, setBusy] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [virtualDepositPurpose, setVirtualDepositPurpose] = React.useState(null)
  const { environment } = useEnvironment()

  React.useEffect(() => {
    let didCancle = false
    const variables = {
      profileId: props.profileId,
    }

    if (props.profileId) {
      fetchQuery(environment, query, variables)
        .then((data: any) => {
          setError(null)

          if (!didCancle) {
            setVirtualDepositPurpose(data.localizedStrings.virtualDepositPurpose)
            setBusy(false)
          }
        })
        .catch((e) => {
          setError(e)

          if (!didCancle) {
            setBusy(false)
          }
        })
    }

    return () => {
      didCancle = true
    }
  }, [])

  if (!props.profileId) {
    // eslint-disable-next-line no-console
    console.warn("Не указан profileId")
    return <></>
  }

  return (
    <Modal>
      <DefaultModal
        padding="0"
        onClose={props.onClose}
        heightRestriction={false}
        closeButtonVariant="empty"
      >
        <ModalBox>
          <Text
            fontSize="1.375rem"
            fontWeight="600"
            lineHeight="1.25"
            color="#5F5F5F"
          >
            <Translate i18n="components:account.operations.deposit.title" />
          </Text>
          {/* <Divider/> */}
          <Scrollable>
            {!error && (
              <>
                <PaymentInfo />
                <Box m="25px 0">
                  <Controls profileId={props.profileId} />
                </Box>
                <KarmaPaymentRequisites reason={virtualDepositPurpose} />
              </>
            )}

            {!!error && (
              <TextError>Непредвиденная ошибка! Попробуйте позже</TextError>
            )}
          </Scrollable>
        </ModalBox>
      </DefaultModal>
    </Modal>
  )
}

export default DepositModal
