import React from "react"

import { Text } from "src/components"

export const HeadCell = (props) => (
  <th>
    <Text
      color="#7b838b"
      fontSize={14}
      fontWeight="normal"
      textAlign="left"
      {...props}
    >
      {props.children}
    </Text>
  </th>
)

export const RowTitleCell = (props) => (
  <td>
    <Text
      color="#7b838b"
      fontSize={15}
      fontWeight="normal"
      textAlign="left"
      {...props}
    >
      {props.children}
    </Text>
  </td>
)

export const Cell = (props) => (
  <td>
    <Text
      color="#0b1016"
      fontSize={15}
      fontWeight="normal"
      textAlign="left"
      {...props}
    >
      {props.children}
    </Text>
  </td>
)
