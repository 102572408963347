/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ModalPaymentScheduleQueryVariables = {
    orderId: string;
    amount: number;
};
export type ModalPaymentScheduleQueryResponse = {
    readonly preSchedule: ReadonlyArray<{
        readonly date: string;
        readonly payDate: string | null;
        readonly loan: number;
        readonly state: string | null;
        readonly total: number;
        readonly total_gross: number;
        readonly interest: number;
        readonly interest_fee: number;
        readonly personal_tax: number;
    } | null>;
};
export type ModalPaymentScheduleQuery = {
    readonly response: ModalPaymentScheduleQueryResponse;
    readonly variables: ModalPaymentScheduleQueryVariables;
};



/*
query ModalPaymentScheduleQuery(
  $orderId: ID!
  $amount: Float!
) {
  preSchedule(orderId: $orderId, amount: $amount) {
    date
    payDate
    loan
    state
    total
    total_gross
    interest
    interest_fee
    personal_tax
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "amount",
    "type": "Float!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "concreteType": "PaymentScheduleItem",
    "kind": "LinkedField",
    "name": "preSchedule",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loan",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total_gross",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interest_fee",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personal_tax",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModalPaymentScheduleQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModalPaymentScheduleQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ModalPaymentScheduleQuery",
    "operationKind": "query",
    "text": "query ModalPaymentScheduleQuery(\n  $orderId: ID!\n  $amount: Float!\n) {\n  preSchedule(orderId: $orderId, amount: $amount) {\n    date\n    payDate\n    loan\n    state\n    total\n    total_gross\n    interest\n    interest_fee\n    personal_tax\n  }\n}\n"
  }
};
})();
(node as any).hash = '52dde76b8ef7888e132127d364f5f02e';
export default node;
