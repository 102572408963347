import { Node, Text } from "slate"

import { getClickableLink } from "src/utils"

const serializeText = (text: Text) => {
  let element = text.text

  if (!element) {
    return ""
  }

  if (text.bold) {
    element = `<strong>${element}</strong>`
  }

  if (text.italic) {
    element = `<em>${element}</em>`
  }

  if (text.underline) {
    element = `<u>${element}</u>`
  }

  return `<span>${element}</span>`
}

const serialize = (node: Node) => {
  if (Text.isText(node)) {
    return serializeText(node)
  }

  const children = node.children.map((n) => serialize(n)).join("")

  switch (node.type) {
    case "paragraph":
      return `<p>${children}</p>`
    case "link":
      return `<a href=${getClickableLink(node.url)} rel="noreferrer noopener" target="_blank">${children}</a>`
    default:
      return children
  }
}

export const getRenderedValue = (value?: string | Node[]) => {
  if (!value || typeof value === "string") {
    return [value]
  }

  return value.map(serialize)
}
