import React, { useState } from "react"

import { Box, Text } from "src/components"
import { preventEventBubbling } from "src/utils"

import { SliderModal } from "../../Gallery"

import { ListItemWrapper, ListItemsContainer } from "./styles"

import { DeleteIcon, DeleteIconDisabled } from "./DeleteIcon"

import { AttachmentType } from "../../FormBuilder/NewInputs/Attachments/index"

type ItemProps = AttachmentType & {
  onItemPreview: (event) => void;
  onRemove: (event) => void;
  key: number;
}

type ListProps = {
  attachments: AttachmentType[];
  onRemove: (index) => (event) => void;
}

const Item = (props: ItemProps) => {
  const {
    onRemove, filename, onItemPreview,
    isDeleteButtonDisabled,
  } = props

  return (
    <ListItemWrapper>
      <Box onClick={onItemPreview}>
        <Text fontSize="16px">{filename}</Text>
      </Box>
      {isDeleteButtonDisabled ? <DeleteIconDisabled /> : <DeleteIcon onClick={onRemove} />}
    </ListItemWrapper>
  )
}

const List = (props: ListProps) => {
  const [showModal, setShowModal] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)

  const onItemPreview = (index) => (event) => {
    preventEventBubbling(event)
    setShowModal(true)
    setModalIndex(index)
  }

  const onModalClose = (event) => {
    preventEventBubbling(event)
    setShowModal(false)
  }

  const { onRemove, attachments } = props

  return (
    Array.isArray(attachments)
    && attachments.length > 0 && (
      <ListItemsContainer>
        {attachments.map((attachment, index) => (
          <Item
            {...attachment}
            key={index}
            onRemove={onRemove(index)}
            onItemPreview={onItemPreview(index)}
          />
        ))}
        {showModal && (
          <SliderModal
            images={attachments}
            initialSlide={modalIndex}
            onClose={onModalClose}
          />
        )}
      </ListItemsContainer>
    )
  )
}

export default List
