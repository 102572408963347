import React from "react"
import styled from "styled-components"

export const Container = styled.div`
  width: 36px;
  height: 36px;
`

export default () => (
  <Container>
    <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="m36 18c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18"
          fill="#f2f6fa"
        />
        <path d="m9.499 28.003h14v-17h-14z" fill="#9ea6ae" mask="url(#a)" />
        <path
          d="m11.358 8.128v1.25h13.5v16.5h1.25v-17.75z"
          fill="#9ea6ae"
          mask="url(#a)"
        />
        <path
          d="m11.358 11.003v6l2.5-2.27 2.5 2.27v-6z"
          fill="#f2f6fa"
          mask="url(#a)"
        />
      </g>
    </svg>
  </Container>
)
