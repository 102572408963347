import styled from "styled-components"

export const StatusbarContainer = styled.div<any>`
  width: ${(props) => props.width || "100%"};
  height: 6px;
  position: relative;
  margin-top: ${(props) => (props.margin && "8px") || "0"};
  margin-bottom: ${(props) => (props.margin && "15px") || "0"};
  ${(props) => `${props.value}%` || 0};
  flex-shrink: 0;
`
export const StatusBarFilled = styled.div<any>`
  height: 100%;
  background-color: #6fa84b;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: ${(props) => `${props.value}%` || 0};
`
export const StatusBarEmpty = styled.div`
  background-color: #e6eaee;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`
