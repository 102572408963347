import is from "is_js"
import memoize from "memoize-one"
import i18next from "i18next"
import { graphql, fetchQuery } from "relay-runtime"

const query = graphql`
  query instructionsModalUtilsQuery($id: ID!) {
    node(id: $id) {
      __typename
      ... on Order {
        fee
        document(type: BORROWER_OFFER) {
          url
          type
        }
        otherDocuments {
          attachment {
            url
            filename
          }
          description
        }
        skipOffer
        guarantors {
          attachment {
            url
            filename
          }
          name
          description
        }
      }
    }
  }
`

const instructionAdapter = (data) => {
  const {
    node: {
      document, fee, otherDocuments, skipOffer, guarantors,
    },
  } = data

  let url = null

  if (is.existy(document)) {
    const { url: documentUrl } = document
    url = documentUrl
  }

  return {
    url,
    fee,
    otherDocuments,
    skipOffer,
    guarantors,
  }
}

export const getInstructionsLink = (id, environment) => fetchQuery(environment, query, { id }).then(instructionAdapter)

const formatDocuments = memoize((documents) => {
  if (!Array.isArray(documents)) return []

  return documents.map((doc) => {
    const {
      name,
      description,
      attachment: { url, filename },
    } = doc

    const [, extension] = filename.split(".")

    return {
      url,
      title: name || description || filename,
      glyph: extension.split("?")[0],
    }
  })
})

const formatGeneratedDocument = memoize((doc, name) => {
  const result = { ...doc }
  const tokens = result.url.split(".")
  const [extension] = tokens[tokens.length - 1].split("?")

  result.title = name
  result.glyph = extension

  return result
})

export const normalizeDocuments = (order) => {
  const documents = []
  const {
    skipOffer, guarantors, borrowerOffer, otherDocuments,
  } = order

  if (!skipOffer && borrowerOffer && borrowerOffer.url) {
    const path = "instructions_modal.invest.borrower_offer"
    const name = i18next.getResource(i18next.language, "components", path)
    documents.push(formatGeneratedDocument(borrowerOffer, name))
  }

  documents.push(...formatDocuments(otherDocuments))
  documents.push(...formatDocuments(guarantors))

  return documents
}
