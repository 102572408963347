import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Text = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  width: 100%;
  max-width: 180px;
`

export const Button = styled.button`
  position: relative;
  width: 180px;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: white;
  background-color: ${({ theme }) => theme.colors.mainRed};
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  padding: 15px 0 13px;
  margin-top: 14px;
`
