import React from "react"
import ReactDOM from "react-dom"
import { BasicOverlay, BasicContainer, VideoContainer } from "./style"

const mountPoint = document.querySelector("#modal-root")

class Modal extends React.Component<any, any> {
  static Basic

  static Video

  componentDidMount() {
    (this as any).storedBodyOverflow = document.body.style.overflow
  }

  componentWillUnmount() {
    document.body.style.overflow = (this as any).storedBodyOverflow
  }

  render() {
    return ReactDOM.createPortal(this.props.children, mountPoint)
  }
}

class Basic extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    (this as any).overlayRef = React.createRef()
  }

  onOverlayClick = (event) => {
    if (
      event.target === (this as any).overlayRef.current
      && typeof this.props.onClose === "function"
    ) this.props.onClose(event)
  }

  render() {
    const { children } = this.props

    return (
      <Modal>
        <BasicOverlay ref={(this as any).overlayRef} onClick={this.onOverlayClick}>
          <BasicContainer>{children}</BasicContainer>
        </BasicOverlay>
      </Modal>
    )
  }
}

class Video extends React.PureComponent<any> {
  constructor(props) {
    super(props);
    (this as any).overlayRef = React.createRef()
  }

  onOverlayClick = (event) => {
    if (
      event.target === (this as any).overlayRef.current
      && typeof this.props.onClose === "function"
    ) this.props.onClose(event)
  }

  render() {
    const { children } = this.props

    return (
      <Modal>
        <BasicOverlay ref={(this as any).overlayRef} onClick={this.onOverlayClick}>
          <VideoContainer>{children}</VideoContainer>
        </BasicOverlay>
      </Modal>
    )
  }
}

Modal.Basic = Basic
Modal.Video = Video

export default Modal
