/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type AddProfileBankAccountInput = {
    profileId: string;
    account: BankAccountInput;
};
export type BankAccountInput = {
    bank?: BankInput | null;
    checkingAccount?: string | null;
    correspondentAccount?: string | null;
};
export type BankInput = {
    bic?: string | null;
    name?: string | null;
};
export type AddProfileBankAccountMutationVariables = {
    input: AddProfileBankAccountInput;
};
export type AddProfileBankAccountMutationResponse = {
    readonly addProfileBankAccount: {
        readonly profile: {
            readonly id?: string;
            readonly bankAccounts?: ReadonlyArray<{
                readonly id: string;
                readonly status: BankAccountStatusEnum;
                readonly checkingAccount: string | null;
                readonly correspondentAccount: string | null;
                readonly bank: {
                    readonly bic: string;
                    readonly name: string;
                } | null;
            }>;
            readonly foreignBankAccounts?: ReadonlyArray<{
                readonly id?: string;
                readonly status?: BankAccountStatusEnum;
                readonly checkingAccount?: string | null;
                readonly correspondentAccount?: string | null;
                readonly bank?: {
                    readonly bic: string;
                    readonly name: string;
                } | null;
                readonly IBAN?: string;
                readonly ownerName?: string;
                readonly foreignBank?: {
                    readonly name: string;
                    readonly address: string;
                    readonly SWIFT: string;
                };
            }>;
        };
    };
};
export type AddProfileBankAccountMutation = {
    readonly response: AddProfileBankAccountMutationResponse;
    readonly variables: AddProfileBankAccountMutationVariables;
};



/*
mutation AddProfileBankAccountMutation(
  $input: AddProfileBankAccountInput!
) {
  addProfileBankAccount(input: $input) {
    profile {
      __typename
      ... on IndividualProfile {
        id
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
      ... on EntrepreneurProfile {
        id
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
      ... on LegalEntityProfile {
        id
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
      ... on ForeignIndividualProfile {
        id
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
        foreignBankAccounts: allBankAccounts {
          __typename
          ... on BankAccount {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
          ... on ForeignBankAccount {
            id
            status
            IBAN
            ownerName
            foreignBank: bank {
              name
              address
              SWIFT
            }
          }
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddProfileBankAccountInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "checkingAccount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "correspondentAccount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Bank",
    "kind": "LinkedField",
    "name": "bank",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "bic",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bankAccounts",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "BankAccount"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IBAN",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerName",
      "storageKey": null
    },
    {
      "alias": "foreignBank",
      "args": null,
      "concreteType": "ForeignBank",
      "kind": "LinkedField",
      "name": "bank",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "SWIFT",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForeignBankAccount"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProfileBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddProfileBankAccountPayload",
        "kind": "LinkedField",
        "name": "addProfileBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "LegalEntityProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": "foreignBankAccounts",
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "allBankAccounts",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProfileBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddProfileBankAccountPayload",
        "kind": "LinkedField",
        "name": "addProfileBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v11/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v11/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v11/*: any*/),
                "type": "LegalEntityProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": "foreignBankAccounts",
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "allBankAccounts",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AddProfileBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation AddProfileBankAccountMutation(\n  $input: AddProfileBankAccountInput!\n) {\n  addProfileBankAccount(input: $input) {\n    profile {\n      __typename\n      ... on IndividualProfile {\n        id\n        bankAccounts {\n          id\n          status\n          checkingAccount\n          correspondentAccount\n          bank {\n            bic\n            name\n          }\n        }\n      }\n      ... on EntrepreneurProfile {\n        id\n        bankAccounts {\n          id\n          status\n          checkingAccount\n          correspondentAccount\n          bank {\n            bic\n            name\n          }\n        }\n      }\n      ... on LegalEntityProfile {\n        id\n        bankAccounts {\n          id\n          status\n          checkingAccount\n          correspondentAccount\n          bank {\n            bic\n            name\n          }\n        }\n      }\n      ... on ForeignIndividualProfile {\n        id\n        bankAccounts {\n          id\n          status\n          checkingAccount\n          correspondentAccount\n          bank {\n            bic\n            name\n          }\n        }\n        foreignBankAccounts: allBankAccounts {\n          __typename\n          ... on BankAccount {\n            id\n            status\n            checkingAccount\n            correspondentAccount\n            bank {\n              bic\n              name\n            }\n          }\n          ... on ForeignBankAccount {\n            id\n            status\n            IBAN\n            ownerName\n            foreignBank: bank {\n              name\n              address\n              SWIFT\n            }\n          }\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e94b37726010f350be76f4ad52d4cf1c';
export default node;
