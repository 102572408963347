/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type Withdraw_foreignProfile = {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly accountBalanceDetailed: ReadonlyArray<{
        readonly available: number;
        readonly blocked: number;
        readonly invested: number;
        readonly currency: CurrencyEnum;
    }>;
    readonly accreditation: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly foreignBankAccounts: ReadonlyArray<{
        readonly id: string;
        readonly __typename: "ForeignBankAccount";
        readonly status: BankAccountStatusEnum;
        readonly IBAN: string;
        readonly ownerName: string;
        readonly bank: {
            readonly name: string;
            readonly address: string;
            readonly SWIFT: string;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly bankAccounts: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly __typename: "ForeignIndividualProfile";
    readonly " $refType": "Withdraw_foreignProfile";
};
export type Withdraw_foreignProfile$data = Withdraw_foreignProfile;
export type Withdraw_foreignProfile$key = {
    readonly " $data"?: Withdraw_foreignProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"Withdraw_foreignProfile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Withdraw_foreignProfile",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileAccountBalance",
      "kind": "LinkedField",
      "name": "accountBalanceDetailed",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "role",
          "value": "INVESTOR"
        }
      ],
      "concreteType": "Accreditation",
      "kind": "LinkedField",
      "name": "accreditation",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": "accreditation(role:\"INVESTOR\")"
    },
    {
      "alias": "foreignBankAccounts",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "allBankAccounts",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "IBAN",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ownerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ForeignBank",
              "kind": "LinkedField",
              "name": "bank",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SWIFT",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ForeignBankAccount"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkingAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correspondentAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Bank",
          "kind": "LinkedField",
          "name": "bank",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bic",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForeignIndividualProfile"
};
})();
(node as any).hash = '15f9105e21a9ef5a00b996475f7f7d88';
export default node;
