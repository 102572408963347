import { width } from "styled-system"
import styled, { css } from "styled-components"

export const Container = styled.div<any>`
  position: relative;
  width: 100%;
  height: 64px;
  margin-bottom: 20px;
  ${width};
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.greyDarkests[5]};
  font-size: 14px;
  font-weight: 400;
  font-family: Geometria, sans-serif;
  position: absolute;
  width: 100%;
  top: 24px;
  left: 16px;
  z-index: 2;
  pointer-events: none;
  transition: 0.2s;
`

export const Select = styled.select`
  position: relative;
  font-weight: 400;
  font-family: Geometria, sans-serif;
  color: black;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  font-size: 16px;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 22px 11px 0;
  cursor: pointer;
  box-sizing: border-box;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.colors.blue};
    box-shadow: 0 0 12px 0 rgba(217, 222, 226, 0.56);
  }

  :focus:not(:disabled) + ${Label} {
    font-size: 12px;
    top: 14px;
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ value }) => !!value
    && css`
      & + ${Label} {
        font-size: 12px;
        top: 14px;
        color: ${({ theme }) => theme.colors.blue};
      }
    `};
`

Container.defaultProps = {
  width: "100%",
}
