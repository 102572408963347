import styled from "styled-components"

import { Button } from "src/components"

export const ApprovalContainer = styled.div`
  margin-top: 0;
  margin-left: auto;
`

export const StyledButton = styled(Button)`
  height: 32px !important;
  font-size: 14px;
`
