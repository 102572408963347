import React from "react"

import { MutationContainer } from "src/containers"
import { SetOrderKarmaTrustedStatus } from "src/mutations"

import {
  Text,
  Loader,
  Tooltip,
  FlexBox,
  CheckBox,
  Translate,
  RatingGlyph,
} from "src/components"

import { LoaderContainer, TooltipContainer } from "./styles"

const validStatuses = ["DECLINED"]

class Trustee extends React.Component<any, any> {
  state = {
    busy: false,
    trusted: null,
    isLoading: false,
  }

  static getDerivedStateFromProps(props, state) {
    return state.trusted === null ? { trusted: props.trusted } : null
  }

  onChange = () => {
    this.setState(() => ({ busy: true }))

    const { commit, orderId } = this.props

    const variables = {
      input: {
        orderId,
        isTrusted: !this.state.trusted,
      },
    }

    commit({
      variables,
      callbacks: {
        onError: this.onError,
        onCompleted: this.onCompleted,
      },
    })
  }

  onCompleted = () => {
    this.setState((state) => ({
      busy: false,
      trusted: !state.trusted,
    }))
  }

  onError = () => {
    this.setState(() => ({ busy: false }))
  }

  render() {
    const { status } = this.props

    if (!validStatuses.includes(status)) return null

    return (
      <FlexBox alignItems="center" mt={20}>
        {this.state.busy ? (
          <LoaderContainer>
            <Loader fontSize="3px" />
          </LoaderContainer>
        ) : (
          <CheckBox
            id={this.props.orderId}
            name="isTrusted"
            checked={this.state.trusted}
            onChange={this.onChange}
            disabled={this.state.busy}
          />
        )}
        <Text ml="8px" mr="5px" fontSize={13}>
          <label htmlFor={this.props.orderId}>
            <Translate i18n="order_list.trust.label" ns="components" />
          </label>
        </Text>
        <TooltipContainer>
          <Tooltip
            borderColor="grey"
            borderWidth="2px"
            glyph={() => <RatingGlyph transform="none" />}
            text="components:order_list.trust.tooltip"
          />
        </TooltipContainer>
      </FlexBox>
    )
  }
}

const render = (props) => (
  <MutationContainer mutation={SetOrderKarmaTrustedStatus}>
    {({ commit }) => <Trustee {...props} commit={commit} />}
  </MutationContainer>
)

export default render
