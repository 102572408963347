import React, { useState, useContext, useMemo } from "react"
import { useHistory } from "react-router-dom"

import { MiniLoader } from "src/components"
import { LoanPaymentGraphQueryContainer } from "src/query"
import { CurrentProfileContext } from "src/context"
import { toCurrencyFormat } from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  TDBold,
  TDStatus,
  TDDate,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
} from "./styles"
import { Table, List } from "../Elements"
import { parsePaymentsData, getOrderName, getPaymentDate } from "./utils"
import { PaymentCard } from "../Cards"

export const statuses = new Map([
  [
    "new",
    {
      text: "Ожидает оплаты",
      color: "rgba(74, 74, 74, 0.45)",
    },
  ],
  [
    "paid",
    {
      text: "Оплачен",
      color: "#6FA84B",
    },
  ],
  [
    "pastdue",
    {
      text: "Просрочка платежа",
      color: "#F70000",
    },
  ],
])

function getColumns(history) {
  return [
    {
      title: "Заявка",
      render: (i, obj) => (
        <TDName key={i}>
          <TDNameLink to={`/market/${obj.orderId}?source=orders`}>{getOrderName(obj)}</TDNameLink>
        </TDName>
      ),
      placeholder: (i) => <TDName key={i}>_</TDName>,
    },
    {
      title: "Дата платежа",
      render: (i, obj) => (
        <TDDate key={i}>{getPaymentDate(obj.item.date)}</TDDate>
      ),
      placeholder: (i) => <TDDate key={i}>_</TDDate>,
    },
    {
      title: "Дата оплаты",
      render: (i, obj) => (
        <TDDate key={i}>
          {getPaymentDate(obj.item.date, obj.item.payDate)}
        </TDDate>
      ),
      placeholder: (i) => <TDDate key={i}>_</TDDate>,
    },
    {
      title: "Сумма платежа",
      render: (i, obj) => (
        <TData key={i}>{toCurrencyFormat(obj.item.total_gross)}</TData>
      ),
      placeholder: (i) => <TData key={i}>0</TData>,
    },
    {
      title: "Основной долг",
      render: (i, obj) => (
        <TData key={i}>{toCurrencyFormat(obj.item.loan)}</TData>
      ),
      placeholder: (i) => <TData key={i}>0</TData>,
    },
    {
      title: "Проценты",
      render: (i, obj) => (
        <TData key={i}>{toCurrencyFormat(obj.item.interest)}</TData>
      ),
      placeholder: (i) => <TData key={i}>0</TData>,
    },
    {
      title: "НДФЛ",
      render: (i, obj) => (
        <TData key={i}>{toCurrencyFormat(obj.item.personal_tax)}</TData>
      ),
      placeholder: (i) => <TData key={i}>0</TData>,
    },
    {
      title: "К выплате",
      render: (i, obj) => (
        <TDBold key={i}>{toCurrencyFormat(obj.item.total)}</TDBold>
      ),
      placeholder: (i) => <TDBold key={i}>0</TDBold>,
    },
    {
      title: "Остаток долга",
      render: (i, obj) => (
        <TData key={i}>{toCurrencyFormat(obj.item.debt)}</TData>
      ),
      placeholder: (i) => <TData key={i}>0</TData>,
    },
    {
      title: "Статус",
      render: (i, obj) => {
        const status = statuses.get(obj.item.state) || {
          text: "Неизвестно",
          color: "grey",
        }

        return (
          <TDStatus width="auto" color={status.color} key={i}>
            {status.text}
          </TDStatus>
        )
      },
      placeholder: (i) => (
        <TDStatus width="auto" color={"#6FA84B"} key={i}>
          Оплачен
        </TDStatus>
      ),
    },
  ]
}

export function RenderedTable(props) {
  const { node, isFetching } = props
  const { edges = [] } = node?.borrower?.orders || {}

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile || {}

  const [payments, pastduePayments] = useMemo(
    () => parsePaymentsData(profileId, edges, "loan"),
    [profileId, edges],
  )
  const [isPaymentsExpanded, setPaymentsExpanded] = useState(true)
  const [isPastduePaymentsExpanded, setPastduePaymentsExpanded] = useState(true)

  const history = useHistory()

  const columns = useMemo(() => getColumns(history), [history])

  return (
    <div>
      <DesktopWrapper>
        <TableWrapper>
          {isFetching && (
            <TableLoader>
              <MiniLoader margin="auto" />
            </TableLoader>
          )}

          {pastduePayments.length > 0 && (
            <Table
              title="ПРОСРОЧЕННЫЕ ПЛАТЕЖИ"
              columns={columns}
              items={pastduePayments}
              isFetching={isFetching}
              isExpanded={isPastduePaymentsExpanded}
              setExpanded={setPastduePaymentsExpanded}
            />
          )}

          {payments.length > 0 && (
            <Table
              title="ГРАФИК ПЛАТЕЖЕЙ"
              columns={columns}
              items={payments}
              isFetching={isFetching}
              isExpanded={isPaymentsExpanded}
              setExpanded={setPaymentsExpanded}
            />
          )}
        </TableWrapper>
      </DesktopWrapper>

      <MobileWrapper>
        {pastduePayments.length > 0 && (
          <List
            title="ПРОСРОЧЕННЫЕ ПЛАТЕЖИ"
            items={pastduePayments}
            Render={PaymentCard}
            isFetching={isFetching}
            isExpanded={isPastduePaymentsExpanded}
            setExpanded={setPastduePaymentsExpanded}
          />
        )}

        {payments.length > 0 && (
          <List
            title="ГРАФИК ПЛАТЕЖЕЙ"
            items={payments}
            Render={PaymentCard}
            isFetching={isFetching}
            isExpanded={isPaymentsExpanded}
            setExpanded={setPaymentsExpanded}
          />
        )}
      </MobileWrapper>
    </div>
  )
}

export default function (props) {
  const { profile } = props

  const InvestmentActiveTable = LoanPaymentGraphQueryContainer(
    (data) => <RenderedTable {...data} />,
    profile,
  )

  return <InvestmentActiveTable />
}
