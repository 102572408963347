import React from "react"

import { Text, Heading, Translate } from "src/components"

import { Legend, LinkDocument, TitleContainer } from "./styles"

export const PhoneConfirmTitle = () => (
  <TitleContainer>
    <Heading.h4>
      <Translate
        i18n="individual_account.update.phone_confirm.title"
        ns="components"
      />
    </Heading.h4>
  </TitleContainer>
)

export const PhoneConfirmLegend = ({ phone }) => (
  <Legend>
    <Translate
      i18n="individual_account.update.phone_confirm.legend"
      ns="components"
      phone={phone}
    />
  </Legend>
)

export const PersonalLinkPhoneConfirmLabel = ({ personalLink }) => (
  <Text fontSize={14} color="black" lineHeight="1.5" pb="10px">
    <Translate
      i18n="individual_account.update.phone_confirm.personal_label"
      ns="components"
    />
    <LinkDocument href={personalLink} target="_blank">
      <Translate
        i18n="individual_account.update.phone_confirm.personal_label_highlighted"
        ns="components"
      />
    </LinkDocument>
  </Text>
)

export const TermsOfDataParseAcceptance = () => (
  <Text fontSize={14} color="black" lineHeight="1.5">
    <Translate
      i18n="individual_account.update.phone_confirm.dataParseAcceptance.0"
      ns="components"
    />
    &nbsp;
    <a href="sumsub.com">sumsub.com</a>
    &nbsp;
    <Translate
      i18n="individual_account.update.phone_confirm.dataParseAcceptance.1"
      ns="components"
    />
    &nbsp;
    <a href="my.karma.red">my.karma.red</a>
  </Text>
)

export const TermsOfAcceptanceLabel = ({ termsLink }) => (
  <Text fontSize={14} color="black" lineHeight="1.5">
    <Translate
      i18n="individual_account.update.phone_confirm.terms_label"
      ns="components"
    />
    <LinkDocument href={termsLink} target="_blank">
      <Translate
        i18n="individual_account.update.phone_confirm.terms_label_highlighted"
        ns="components"
      />
    </LinkDocument>
  </Text>
)

export const TermsHint = () => (
  <Text mt={20} color="greyDarkest" fontSize="14px" lineHeight="1.5">
    <Translate
      i18n="individual_account.update.phone_confirm.terms_hint"
      ns="components"
    />
  </Text>
)
