import React from "react"

import { ViewerData } from "src/context"

import messageAdapters from "./messageAdapters"

import { InfoText, InfoContainer } from "../styles"

const MessageSchema = new Map([
  ["INITIAL", messageAdapters.initial],
  ["PENDING", messageAdapters.pending],
  ["DECLINED", null],
  ["APPROVED", messageAdapters.approved],
  ["CONFIRMED", messageAdapters.confirmed],
  ["TRANSFER", null],
  ["COMPLETE", null],
  ["SUCCEEDED", null],
  ["DEAD", messageAdapters.dead],
  ["DEFAULT", null],
])

const InfoMessage = (props) => {
  const { data, viewerToOrderRelation } = props

  const { status } = data
  const pathFrom = MessageSchema.get(status)

  return pathFrom !== undefined && pathFrom !== null ? (
    <InfoContainer>
      <InfoText>{pathFrom(data, viewerToOrderRelation)}</InfoText>
    </InfoContainer>
  ) : null
}

export default (props) => (
  <ViewerData.renderPropContainer>
    {({ getViewerToOrderRelation }) => (
      <InfoMessage
        {...props}
        viewerToOrderRelation={getViewerToOrderRelation(props.data)}
      />
    )}
  </ViewerData.renderPropContainer>
)
