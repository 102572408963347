import React from "react"

import { PhoneConfirmModal } from "src/components"

import Form from "./Form"
import Controls from "../../../../Controls"
import FormContainer from "./styles"

import { SubmitModal } from "../Modals"

const Content = (props) => {
  const {
    schema,
    onSave,
    onChange,
    onCancel,
    formState,
    showCancel,
    onContinue,
    onSaveCancel,
    onSubmitSave,
    investorType,
    isSaveLoading,
    showSubmitModal,
    closeSubmitModal,
    isContinueLoading,
    isContinueDisabled,
    requestAccreditation,
    showPhoneConfirmModal,
    isConfirmationLoading,
    closePhoneConfirmModal,
    commitRequestFromPhoneConfirmModal,
  } = props

  return (
    <React.Fragment>
      <FormContainer>
        <Form schema={schema} value={formState} onChange={onChange} />
      </FormContainer>
      <Controls
        onSave={onSave}
        onCancel={onCancel}
        onContinue={onContinue}
        showCancel={showCancel}
        isSaveLoading={isSaveLoading}
        isContinueLoading={isContinueLoading}
        isContinueDisabled={isContinueDisabled}
      />
      {showSubmitModal && (
        <SubmitModal
          isSaveLoading={isSaveLoading}
          onClose={closeSubmitModal}
          onCancel={onSaveCancel}
          onSave={onSubmitSave}
        />
      )}
      {showPhoneConfirmModal && (
        <PhoneConfirmModal
          type={investorType}
          close={closePhoneConfirmModal}
          phone={formState.phone}
          commit={requestAccreditation}
          isLoading={isConfirmationLoading}
          commitRequest={commitRequestFromPhoneConfirmModal}
        />
      )}
    </React.Fragment>
  )
}

export default Content
