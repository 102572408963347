import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { Box, Text, Flex } from "src/components"

export const Header = styled.header`
  position: relative;
  margin-bottom: 38px;
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.fullBlacks[2]};
`

export const Logo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-image: url("/images/logo.png");
  background-position: center center;
  background-size: cover;
`

export const LogoText = styled(Text)`
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 24px;
`

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  color: #393939;

  &:active,
  &:visited {
    color: #393939;
  }
`

export const HelpContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-left: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.colors.primaryBlacks[8]};
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  padding-left: 1px;
  font-weight: 900;

  text-decoration: none;

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  }
`

export const Assistant = styled(Box)`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-image: url("/images/new_assistant.jpg");
  background-position: center center;
  background-size: cover;

  position: absolute;
  left: calc(50% - 37.5px);
  bottom: -37.5px;
`

export const LinksContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 15px;
  padding: 0;

  @media screen and (max-width: 480px) {
    margin-bottom: 5px;
  }
`
