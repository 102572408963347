import React from "react"

export const play = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0.5833C6.37 0.5833 0.58334 6.36996 0.58334 13.5C0.58334
    20.63 6.37 26.4166 13.5 26.4166C20.63 26.4166 26.4166 20.63 26.4166
    13.5C26.4166 6.36996 20.63 0.5833 13.5 0.5833ZM10.9167 19.3125V7.68746L18.6666
    13.5L10.9167 19.3125Z"
      fill="white"
    />
  </svg>
)

export const exit = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7
8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#4A4A4A"
    />
  </svg>
)
