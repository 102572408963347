import React from "react"
import styled from "styled-components"

import {
  top, left, right, width, height, bottom,
} from "styled-system"

/* eslint-disable max-len */
export const IconContainer = styled.div`
  ${width}
  ${height}
  ${top}
  ${right}
  ${bottom}
  ${left}
  position: absolute;
`

const SnowSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M5.105 1.67l-.522.899 4.048 2.376.512 1.935-3.15-1.848-.34.587-.588.337 1.813 3.177-1.923-.53L2.624 4.52l-.901.515 1.815 3.178-2.733-.753-.27 1.005 2.73.753-3.158 1.808.517.906 4.06-2.323 1.923.53-3.158 1.808.338.591-.002.68 3.642.024-1.414 1.406-4.68-.03-.002 1.041 3.642.024-2.006 1.996.732.742 2.007-1.996-.009 3.657 1.038.005.012-4.697 1.413-1.406-.01 3.656.678.004.586.343 1.829-3.153.51 1.936-2.349 4.054.898.527 1.83-3.155.724 2.749 1.005-.263-.727-2.75 3.15 1.849.522-.899-4.049-2.375-.51-1.937 3.15 1.85.34-.588.587-.336-1.813-3.178 1.923.53 2.33 4.084.901-.515-1.812-3.178 2.73.753.272-1.006-2.732-.753 3.159-1.808-.517-.905-4.06 2.324-1.923-.531 3.159-1.808-.338-.59.001-.682-3.642-.023 1.413-1.405 4.68.03.004-1.042-.001-.001-3.641-.024 2.005-1.995-.733-.741-2.006 1.994.01-3.655-1.04-.006-.01 4.697-1.412 1.406.008-3.655-.678-.005-.586-.343-1.829 3.153-.51-1.935L11.984.628 11.087.1 9.259 3.255 8.532.506 7.53.77l.726 2.748-3.15-1.848zm3.191 7.855l-1.659-2.91 2.882 1.692.444 1.676-1.667-.458zm2.228-1.482l1.673-2.887-.007 3.346-1.224 1.218-.442-1.677zm-5.39 4.14l2.892-1.653 1.664.459-1.223 1.217-3.333-.022zm8.231-1.262l-1.667-.459 1.223-1.218 3.334.022-2.89 1.655zM9.2 12.947l1.223-1.217.444 1.676-1.675 2.888.008-3.347zm2.67.197l-.443-1.678 1.666.46 1.66 2.909-2.884-1.691z"
    />
  </svg>
)

const Icon = (props) => (
  <IconContainer {...props}>
    <SnowSvg />
  </IconContainer>
)

Icon.defaultProps = {
  width: "21px",
  height: "21px",
}

export default Icon
