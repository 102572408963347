import React, { useState, useCallback } from "react"
import memoize from "memoize-one"
import { graphql } from "relay-runtime"
import { Spring } from "react-spring/renderprops"

import { useSubscription } from "src/hooks"
import { formatDateShort } from "src/utils/date"
import {
  Modal,
  Translate,
  ModalPaymentSchedule,
  CustomTooltip,
} from "src/components"
import { getOrderInterestRate, getOrderRating, roundNumber } from "src/utils"

import { TagsList, InformationCompany, Statusbar } from "../Elements"

import {
  HeaderContainerActive,
  HeaderWrap,
  InformationContainer,
  InformationItem,
  InformationName,
  InformationValue,
  TooltipWrap,
  BodyContainer,
  BodyGoal,
  BodyGoalItem,
  BodyGoalItemDescr,
  BodyGoalItemValue,
  FooterActionContainer,
  FooterActionItem,
  ActionLink,
  ActionButton,
  GoalInfo,
  GoalInfoItem,
  GoalInfoIcon,
  GoalInfoValue,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionExit,
  DescriptionTitle,
  DescriptionBody,
  DescriptionSchedule,
  DescriptionScheduleItem,
  DescriptionScheduleName,
  DescriptionScheduleValue,
  TableRow,
  TableTdName,
  TableTdNameWrap,
  TableTdRating,
  TableYoutube,
  GraphButton,
  CalculatorIcon,
  ClockIcon,
  RepaymentType,
  OrderTypeTd,
  OrderDate,
} from "./styles"

import {
  info, user, clock, exit, Youtube,
} from "./icons"
import { removeDots } from "./utils"
import { getCessionRedemptionDate } from "./helpers/mobileOrder"

import {
  OrderTableRowForGatheredAmountSubscription,
} from "./__generated__/OrderTableRowForGatheredAmountSubscription.graphql"

const monthArray = [
  "янв",
  "фев",
  "мар",
  "апр",
  "май",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
]

const Head = memoize(({ data, ...props }) => {
  const name = data.cession && data.cession.borrowerName
    ? data.cession.borrowerName
    : data.profile.name
  const isBg = data.backgroundImage && data.backgroundImage.url
  const video = data.application.externalMedium
  return (
    <HeaderWrap
      style={{
        background: isBg
          ? `url("${data.backgroundImage.url}")`
          : "url(images/OrderHead.png)",
        backgroundRepeat: "none",
        backgroundSize: "cover",
      }}
    >
      <HeaderContainerActive>
        <TagsList
          isCession={data.cession !== null && data.cession !== undefined}
          tags={data.tags || []}
          id={data.id}
        />
        <InformationCompany
          isCession={data.cession !== null && data.cession !== undefined}
          avatar={data.cession && data.cession.avatar.url}
          shortTitle={data.application.shortTitle}
          name={name}
          video={video[0]}
          openDescription={props.handleOpen}
        />
      </HeaderContainerActive>
    </HeaderWrap>
  )
})

const Info = ({ data }) => {
  const [toltipActive, setToltipActive] = useState(false)
  const creditRatingRight = data.profile.creditRating && data.profile.creditRating.rating
  const creditRatingLeft = data.creditRating && data.creditRating.rating
  const handleToltip = (value) => {
    setToltipActive(value)
  }
  return (
    <InformationContainer>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.rate"} />
        </InformationName>
        <InformationValue>{data.offer.data.interestRate}%</InformationValue>
      </InformationItem>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.rating"} />{" "}
          <span
            onMouseEnter={() => handleToltip(true)}
            onMouseLeave={() => handleToltip(false)}
          >
            {info}
          </span>
          {toltipActive && (
            <TooltipWrap
              onMouseEnter={() => handleToltip(true)}
              onMouseLeave={() => handleToltip(false)}
            >
              <Translate i18n={"components:tooltips.rating"} />{" "}
              <a href="/invest/scoring">
                <Translate i18n={"components:tooltips.rating-link"} />
              </a>
              .
            </TooltipWrap>
          )}
        </InformationName>
        {creditRatingLeft || creditRatingRight ? (
          <InformationValue>
            {creditRatingLeft || ""}
            {creditRatingLeft && creditRatingRight && "/"}
            {creditRatingRight || ""}
          </InformationValue>
        ) : (
          "-"
        )}
      </InformationItem>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.duration"} />
        </InformationName>
        <InformationValue>
          {data.offer.data.duration} <Translate i18n={"models:loan.month"} />
        </InformationValue>
      </InformationItem>
    </InformationContainer>
  )
}

const Body = memoize(({ data }) => {
  const amount = data.chain.gatheredAmount
  const amountView = String(amount).replace(
    /(\d{1,3})(?=((\d{3})*([^\d]|$)))/g,
    " $1 ",
  )
  const min = data.application.data.minValue
  const max = data.application.data.maxValue
  const ten = max > 1000000 ? 1000000 : 1000
  const valueStatusBar = (amount / min) * 100 > 100 ? 100 : (amount / min) * 100
  const dateParse = new Date(data.confirmedAt)
  const newDate = new Date(dateParse.setMonth(dateParse.getMonth() + 2))
  const dayEnd = newDate.getDate()
  const month = monthArray[newDate.getMonth()]

  return (
    <BodyContainer>
      <BodyGoal>
        <BodyGoalItem>
          <BodyGoalItemDescr>
            <Translate i18n={"models:loan.gathered"} />
          </BodyGoalItemDescr>
          <BodyGoalItemValue>{amountView} ₽</BodyGoalItemValue>
        </BodyGoalItem>
        <BodyGoalItem>
          <BodyGoalItemDescr style={{ textAlign: "right" }}>
            <Translate i18n={"models:loan.target"} />
          </BodyGoalItemDescr>
          <BodyGoalItemValue>
            <Translate
              i18n={"models:loan.sum"}
              min={min / ten}
              max={max / ten}
            />{" "}
            {max > 1000000 ? (
              <Translate i18n="models:loan.millions" />
            ) : (
              <Translate i18n="models:loan.thousands" />
            )}
            {" ₽"}
          </BodyGoalItemValue>
        </BodyGoalItem>
      </BodyGoal>
      <Statusbar margin value={valueStatusBar} />
      <GoalInfo>
        <GoalInfoItem>
          <GoalInfoIcon>{user}</GoalInfoIcon>
          <GoalInfoValue>
            {data.chain.investorsCount}{" "}
            <Translate i18n={"models:loan.people"} />
          </GoalInfoValue>
        </GoalInfoItem>
        <GoalInfoItem>
          <GoalInfoIcon>{clock}</GoalInfoIcon>
          <GoalInfoValue>
            <Translate
              i18n={"components:legal_entity_offer_form.switch_values.label"}
            />{" "}
            {dayEnd}{" "}
            <Translate
              i18n={`components:legal_entity_offer_form.switch_values.months.${month}`}
            />
          </GoalInfoValue>
        </GoalInfoItem>
      </GoalInfo>
    </BodyContainer>
  )
})

const FooterAction = memoize(({ data }) => (
  <FooterActionContainer>
    <FooterActionItem>
      <ActionLink
        href={data.externalUrl || `/market/${data.id}?source=market`}
        target="_blank"
      >
        <Translate i18n={"models:loan.buttons.detailed"} />
      </ActionLink>
    </FooterActionItem>
    <FooterActionItem>
      <ActionButton>
        <Translate i18n={"models:loan.buttons.Invest"} />
      </ActionButton>
    </FooterActionItem>
  </FooterActionContainer>
))

const Description = ({ data, ...props }) => {
  const offer = data.offer.data
  return (
    <DescriptionContainer>
      <DescriptionHeader>
        <DescriptionTitle>
          <Translate i18n={"models:loan.description"} />
        </DescriptionTitle>
        <DescriptionExit onClick={() => props.handleClose()}>
          {exit}
        </DescriptionExit>
      </DescriptionHeader>
      <DescriptionBody>{data.application.description || "-"}</DescriptionBody>
      <DescriptionTitle>
        <Translate i18n={"models:loan.schedule"} />
      </DescriptionTitle>
      <DescriptionSchedule>
        <DescriptionScheduleItem>
          <DescriptionScheduleName>
            <Translate i18n={"models:loan.interest_payments"} />
          </DescriptionScheduleName>
          <DescriptionScheduleValue>
            <Translate i18n={"models:loan.monthly"} />
          </DescriptionScheduleValue>
        </DescriptionScheduleItem>
        <DescriptionScheduleItem>
          <DescriptionScheduleName>
            <Translate i18n={"models:loan.debt_recovery"} />
          </DescriptionScheduleName>
          <DescriptionScheduleValue>
            {offer.repaymentSchedule.type === "end" && (
              <Translate i18n={"models:loan.at_end_loan"} />
            )}
            {offer.repaymentSchedule.type === "equal_share" && (
              <Translate
                i18n={"models:loan.monthly_equal_shares"}
                start={offer.repaymentSchedule.shareDate}
                end={offer.duration}
              />
            )}
          </DescriptionScheduleValue>
        </DescriptionScheduleItem>
      </DescriptionSchedule>
    </DescriptionContainer>
  )
}

const tableSubscription = graphql`
  subscription OrderTableRowForGatheredAmountSubscription(
    $input: GatheredAmountSubscriptionInput!
  ) {
    gatheredAmount(input: $input) {
      order {
        chain {
          gatheredAmount
        }
      }
    }
  }
`

const Table = (props) => {
  const [scheduleActive, setScheduleActive] = useState(false)

  const { data, history, isCession } = props
  const { application } = data
  const { repaymentSchedule, term } = data.offer.data
  const { setHandleOrder, handleOrder } = props
  const dateParse = new Date(data.confirmedAt)
  const newDate = new Date(
    dateParse.setDate(dateParse.getDate() + Number(term)),
  )
  const dayEnd = newDate.getDate()
  const month = monthArray[newDate.getMonth()]

  const redemptionDate = props.isCession && formatDateShort(getCessionRedemptionDate(data))
  const amount = data.chain?.gatheredAmount || 0

  const min = props.data.application.data.minValue
  const max = props.data.application.data.maxValue

  const goal = amount > min ? max : min
  const goalView = `${roundNumber(min)}-${roundNumber(max)}`
  const valueStatusBar = (amount / goal) * 100 > 100 ? 100 : (amount / goal) * 100

  const [handleModal, setHandleModal] = useState(false)

  const url = application?.externalMedium?.[0]?.video?.url
  const price = (props.isCession && Math.round((max / data.cession.amount) * 100)) || 0

  const monthView = month

  const toProposal = useCallback(() => {
    history.push(`/market/${data.id}?source=market`)
  }, [data.id])

  const isConfirmedOrder = props.data.status === "CONFIRMED"

  const handleOpenModal = useCallback(() => setHandleModal(true), [])
  const handleCloseModal = useCallback(() => setHandleModal(false), [])
  const handleMouseLeave = useCallback(() => setHandleOrder(true), [])
  const handleMouseEnter = useCallback(() => setHandleOrder(false), [])
  const handleOpenSchedule = useCallback(() => setScheduleActive(true), [])
  const handleCloseSchedule = useCallback(() => setScheduleActive(false), [])

  useSubscription<OrderTableRowForGatheredAmountSubscription>(
    () => ({
      subscription: tableSubscription,
      variables: { input: { orderId: data.id } },
      // eslint-disable-next-line no-console
      onError: (error) => console.error(error),
    }),
    [data.id],
  )

  return (
    <>
      {handleModal && (
        <Modal.Video onClose={handleCloseModal}>
          <iframe
            width="100%"
            height="100%"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Modal.Video>
      )}
      <TableRow onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
        <TableTdName>
          <TableTdNameWrap onClick={toProposal}>
            <CustomTooltip
              placement="bottomLeft"
              overlay={
                <span>
                  {application.longTitle === ""
                    ? application.shortTitle
                    : application.longTitle}
                </span>
              }
            >
              <span> {application.shortTitle}</span>
            </CustomTooltip>
          </TableTdNameWrap>
        </TableTdName>

        {url ? (
          <td>
            <TableYoutube onClick={handleOpenModal}>{Youtube}</TableYoutube>
          </td>
        ) : (
          <td />
        )}

        <TableTdRating>{getOrderRating(data)}</TableTdRating>

        <Spring config={{ clamp: true }} to={{ amount, valueStatusBar }}>
          {({ amount, valueStatusBar }) => {
            const amountView = roundNumber(amount)

            return (
              <>
                <td>
                  <Statusbar
                    value={valueStatusBar}
                    orange={amount < min}
                    width="52px"
                  />
                </td>

                <td>
                  {!isCession && `${amountView} из ${goalView || 0}`}
                  {isCession && `${amountView} из ${roundNumber(max)}`}
                </td>
              </>
            )
          }}
        </Spring>

        {isCession && (
          <td>
            <b>{price}%</b>
          </td>
        )}

        <td>
          <b>{getOrderInterestRate(data)}%</b>
        </td>

        {!isCession && (
          <td>
            <b>{data.offer.data.duration} мес</b>
          </td>
        )}

        <OrderTypeTd>{data.tags?.[0]?.name || "-"}</OrderTypeTd>

        <td>
          {repaymentSchedule?.type === "end" && (
            <RepaymentType>В конце</RepaymentType>
          )}

          {repaymentSchedule?.type === "equal_share" && (
            <RepaymentType>Частями</RepaymentType>
          )}

          {repaymentSchedule && (
            <CustomTooltip
              placement="top"
              overlay={<span>График погашения</span>}
            >
              <GraphButton onClick={handleOpenSchedule}>
                <CalculatorIcon />
              </GraphButton>
            </CustomTooltip>
          )}
        </td>

        {(handleOrder || !isConfirmedOrder) && (
          <>
            {props.isCession && redemptionDate && (
              <td>
                <ClockIcon />{" "}
                <OrderDate>{removeDots(redemptionDate)}</OrderDate>
              </td>
            )}

            {!props.isCession && (
              <td>
                <ClockIcon />{" "}
                <OrderDate>
                  {dayEnd} {monthView}
                </OrderDate>
              </td>
            )}
          </>
        )}

        {!handleOrder && isConfirmedOrder && (
          <td>
            <ActionButton
              href={`/market/${data.id}?source=market`}
              target="_blank"
            >
              {!props.isCession ? (
                <Translate i18n={"models:loan.buttons.Invest"} />
              ) : (
                "Купить"
              )}
            </ActionButton>
          </td>
        )}
      </TableRow>

      {scheduleActive && (
        <ModalPaymentSchedule
          isShowing={scheduleActive}
          onClose={handleCloseSchedule}
          {...props}
        />
      )}
    </>
  )
}

export default {
  Head,
  Body,
  Info,
  FooterAction,
  Description,
  Table,
}
