import React from "react"

import { Translate } from "src/components"

export const first = {
  header: [
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.0" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.1" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.2" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.3" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.4" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.5" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.6" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.7" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.8" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.9" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.first.headers.10" />
      ),
    },
  ],
  body: [
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.first.rows.0" />
        ),
      },
      {
        label: "22%",
      },
      {
        label: "13%",
      },
      {
        label: "3,0%",
      },
      {
        label: "16,1%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 161 000 ₽",
      },
      {
        label: "1 566 554 ₽",
      },
      {
        label: "2 113 047 ₽",
      },
      {
        label: "9 434 683 ₽",
      },
      {
        label: "89 013 249 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.first.rows.1" />
        ),
      },
      {
        label: "16%",
      },
      {
        label: "13%",
      },
      {
        label: "0,5%",
      },
      {
        label: "13,4%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 134 200 ₽",
      },
      {
        label: "1 459 046 ₽",
      },
      {
        label: "1 876 931 ₽",
      },
      {
        label: "6 612 180 ₽",
      },
      {
        label: "43 720 920 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.first.rows.2" />
        ),
      },
      {
        label: "8%",
      },
      {
        label: "-",
      },
      {
        label: "0,0%",
      },
      {
        label: "8,0%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 080 000 ₽",
      },
      {
        label: "1 259 712 ₽",
      },
      {
        label: "1 469 328 ₽",
      },
      {
        label: "3 172 169 ₽",
      },
      {
        label: "10 062 657 ₽",
      },
    ],
  ],
}

export const second = {
  header: [
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.0" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.1" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.2" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.3" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.4" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.5" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.6" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.7" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.8" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.9" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.second.headers.10" />
      ),
    },
  ],
  body: [
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.second.rows.0" />
        ),
      },
      {
        label: "22%",
      },
      {
        label: "6%",
      },
      {
        label: "3,0%",
      },
      {
        label: "17,7%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 176 800 ₽",
      },
      {
        label: "1 629 701 ₽",
      },
      {
        label: "2 256 905 ₽",
      },
      {
        label: "11 495 818 ₽",
      },
      {
        label: "132 153 841 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.second.rows.1" />
        ),
      },
      {
        label: "16%",
      },
      {
        label: "6%",
      },
      {
        label: "0,5%",
      },
      {
        label: "14,5%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 145 400 ₽",
      },
      {
        label: "1 502 697 ₽",
      },
      {
        label: "1 971 451 ₽",
      },
      {
        label: "7 662 274 ₽",
      },
      {
        label: "58 710 444 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.second.rows.2" />
        ),
      },
      {
        label: "8%",
      },
      {
        label: "-",
      },
      {
        label: "0,0%",
      },
      {
        label: "8,0%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 080 000 ₽",
      },
      {
        label: "1 259 712 ₽",
      },
      {
        label: "1 469 328 ₽",
      },
      {
        label: "3 172 169 ₽",
      },
      {
        label: "10 062 657 ₽",
      },
    ],
  ],
}

export const third = {
  header: [
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.0" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.1" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.2" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.3" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.4" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.5" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.6" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.7" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.8" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.9" />
      ),
    },
    {
      label: (
        <Translate i18n="components:investor_info.why.tables.third.headers.10" />
      ),
    },
  ],
  body: [
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.third.rows.0" />
        ),
      },
      {
        label: "22%",
      },
      {
        label: "6%",
      },
      {
        label: "7,0%",
      },
      {
        label: "13,7%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 136 800 ₽",
      },
      {
        label: "1 469 103 ₽",
      },
      {
        label: "1 898 543 ₽",
      },
      {
        label: "6 843 227 ₽",
      },
      {
        label: "46 829 762 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.third.rows.1" />
        ),
      },
      {
        label: "16%",
      },
      {
        label: "6%",
      },
      {
        label: "3,0%",
      },
      {
        label: "12,0%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 120 400 ₽",
      },
      {
        label: "1 406 434 ₽",
      },
      {
        label: "1 765 491 ₽",
      },
      {
        label: "5 502 962 ₽",
      },
      {
        label: "30 282 589 ₽",
      },
    ],
    [
      {
        label: (
          <Translate i18n="components:investor_info.why.tables.third.rows.2" />
        ),
      },
      {
        label: "8%",
      },
      {
        label: "-",
      },
      {
        label: "0,0%",
      },
      {
        label: "8,0%",
      },
      {
        label: "1 000 000 ₽",
      },
      {
        label: "1 080 000 ₽",
      },
      {
        label: "1 259 712 ₽",
      },
      {
        label: "1 469 328 ₽",
      },
      {
        label: "3 172 169 ₽",
      },
      {
        label: "10 062 657 ₽",
      },
    ],
  ],
}
