import React from "react"

export const IconBurger = () => (
  <svg
    width="39"
    height="31"
    viewBox="0 0 39 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2"
      y1="2.14282"
      x2="37"
      y2="2.14282"
      stroke="#4A4A4A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="2"
      y1="15.5713"
      x2="37"
      y2="15.5713"
      stroke="#4A4A4A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="2"
      y1="29"
      x2="37"
      y2="29"
      stroke="#4A4A4A"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
