import styled from "styled-components"

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  align-items: stretch;
  margin: 36px 0 68px;
`

export const Step = styled.div<any>`
  flex: ${({ isLast }) => (isLast ? 0 : 1)};
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const LabelWrapper = styled.div<any>`
  position: relative;
  padding-bottom: 17px;
  display: flex;
  font-size: 14px;
  color: ${({ theme, active }) => (active ? theme.colors.ginger : theme.colors.black)};
  margin-left: ${({ isLast }) => (isLast ? "auto" : 0)};
`

export const Label = styled.p<any>`
  top: 0;
  font-size: 16px;
  white-space: pre-line;
  position: absolute;
  color: ${({ theme, active }) => (active ? theme.colors.ginger : theme.colors.black)};
  left: ${({ isLast }) => (isLast ? "auto" : 0)};
  right: ${({ isLast }) => (isLast ? 0 : "auto")};
  text-align: ${({ isLast }) => (isLast ? "right" : "left")};
  margin: 0;
`

export const IconContainer = styled.div<any>`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: ${({ isLast }) => !isLast && "100%"};
    height: 2px;
    z-index: 1;
    transform: translateY(-50%);
    background-color: ${({ theme, gone }) => (gone ? theme.colors.green : theme.colors.grey)};
  }
`
