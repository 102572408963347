import React, { useState } from "react"
import memoize from "memoize-one"
import { Modal, Translate } from "src/components"

import {
  getOrderTicker,
  getOrderInterestRate,
  getOrderRating,
  roundNumber,
  getDebtURL,
} from "src/utils"
import {
  TagsList,
  InformationCompany,
  Statusbar,
} from "../Elements"
import {
  HeaderContainerActive,
  HeaderWrap,
  InformationContainer,
  InformationItem,
  InformationName,
  InformationValue,
  TooltipWrap,
  BodyContainer,
  BodyGoal,
  BodyGoalItem,
  BodyGoalItemDescr,
  BodyGoalItemValue,
  FooterActionContainer,
  FooterActionItemBtn,
  ActionLinkBtn,
  GoalInfoItem,
  GoalInfoIcon,
  GoalInfoValue,
  DescriptionContainer,
  DescriptionHeader,
  DescriptionExit,
  DescriptionTitle,
  DescriptionBody,
  DescriptionSchedule,
  DescriptionScheduleItem,
  DescriptionScheduleName,
  DescriptionScheduleValue,
  TableRow,
  TableTdName,
  TableTdNameWrap,
  TableTdRating,
  TableTdSum,
  TableTdRate,
  TableTdStatus,
  CalendarIcon,
  DebtLink,
} from "./styles"

import { info, user, exit } from "./icons"

const Head = memoize(({ data, ...props }) => {
  const name = data.cession && data.cession.borrowerName
    ? data.cession.borrowerName
    : data.profile.name
  const isBg = data.backgroundImage && data.backgroundImage.url
  const video = data.application.externalMedium
  return (
    <HeaderWrap
      style={{
        background: isBg
          ? `url("${data.backgroundImage.url}")`
          : "url(images/OrderHead.png)",
        backgroundRepeat: "none",
        backgroundSize: "cover",
      }}
    >
      <HeaderContainerActive>
        <TagsList
          isCession={data.cession !== null && data.cession !== undefined}
          tags={data.tags || []}
          id={data.id}
        />
        <InformationCompany
          isCession={data.cession !== null && data.cession !== undefined}
          avatar={data.cession && data.cession.avatar.url}
          shortTitle={data.application.shortTitle}
          name={name}
          video={video[0]}
          openDescription={props.handleOpen}
        />
      </HeaderContainerActive>
    </HeaderWrap>
  )
})

const Info = ({ data }) => {
  const [toltipActive, setToltipActive] = useState(false)
  const creditRatingRight = data.profile.creditRating && data.profile.creditRating.rating
  const creditRatingLeft = data.creditRating && data.creditRating.rating
  const handleToltip = (value) => {
    setToltipActive(value)
  }
  return (
    <InformationContainer>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.rate"} />
        </InformationName>
        <InformationValue>{data.offer.data.interestRate}%</InformationValue>
      </InformationItem>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.rating"} />{" "}
          <span
            onMouseEnter={() => handleToltip(true)}
            onMouseLeave={() => handleToltip(false)}
          >
            {info}
          </span>
          {toltipActive && (
            <TooltipWrap
              onMouseEnter={() => handleToltip(true)}
              onMouseLeave={() => handleToltip(false)}
            >
              <Translate i18n={"components:tooltips.rating"} />{" "}
              <a href="/invest/scoring">
                <Translate i18n={"components:tooltips.rating-link"} />
              </a>
              .
            </TooltipWrap>
          )}
        </InformationName>
        {creditRatingLeft || creditRatingRight ? (
          <InformationValue>
            {creditRatingLeft || ""}
            {creditRatingLeft && creditRatingRight && "/"}
            {creditRatingRight || ""}
          </InformationValue>
        ) : (
          "-"
        )}
      </InformationItem>
      <InformationItem>
        <InformationName>
          <Translate i18n={"models:loan.duration"} />
        </InformationName>
        <InformationValue>
          {data.offer.data.duration} <Translate i18n={"models:loan.month"} />
        </InformationValue>
      </InformationItem>
    </InformationContainer>
  )
}

const Body = memoize(({ data }) => {
  const amount = data.chain.gatheredAmount
  const amountView = String(amount).replace(
    /(\d{1,3})(?=((\d{3})*([^\d]|$)))/g,
    " $1 ",
  )
  const min = data.application.data.minValue
  const valueStatusBar = (amount / min) * 100 > 100 ? 100 : (amount / min) * 100

  return (
    <BodyContainer>
      <BodyGoal>
        <BodyGoalItem style={{ alignItems: "flex-end", display: "flex" }}>
          <GoalInfoItem>
            <GoalInfoIcon>{user}</GoalInfoIcon>
            <GoalInfoValue>
              {data.chain.investorsCount}{" "}
              <Translate i18n={"models:loan.people"} />
            </GoalInfoValue>
          </GoalInfoItem>
        </BodyGoalItem>
        <BodyGoalItem>
          <BodyGoalItemDescr style={{ textAlign: "right" }}>
            <Translate i18n={"models:loan.gathered"} />
          </BodyGoalItemDescr>
          <BodyGoalItemValue>{amountView} ₽</BodyGoalItemValue>
        </BodyGoalItem>
      </BodyGoal>
      <Statusbar margin={true} value={valueStatusBar} />
    </BodyContainer>
  )
})

const FooterAction = memoize(({ data }) => (
  <FooterActionContainer>
    <FooterActionItemBtn>
      <ActionLinkBtn
        href={data.externalUrl || `/market/${data.id}?source=market`}
        target="_blank"
      >
        <Translate i18n={"models:loan.buttons.detailed"} />
      </ActionLinkBtn>
    </FooterActionItemBtn>
  </FooterActionContainer>
))

const Description = ({ data, ...props }) => {
  const offer = data.offer.data
  return (
    <DescriptionContainer>
      <DescriptionHeader>
        <DescriptionTitle>
          <Translate i18n={"models:loan.description"} />
        </DescriptionTitle>
        <DescriptionExit onClick={() => props.handleClose()}>
          {exit}
        </DescriptionExit>
      </DescriptionHeader>
      <DescriptionBody>{data.application.description || "-"}</DescriptionBody>
      <DescriptionTitle>
        <Translate i18n={"models:loan.schedule"} />
      </DescriptionTitle>
      <DescriptionSchedule>
        <DescriptionScheduleItem>
          <DescriptionScheduleName>
            <Translate i18n={"models:loan.interest_payments"} />
          </DescriptionScheduleName>
          <DescriptionScheduleValue>
            <Translate i18n={"models:loan.monthly"} />
          </DescriptionScheduleValue>
        </DescriptionScheduleItem>
        <DescriptionScheduleItem>
          <DescriptionScheduleName>
            <Translate i18n={"models:loan.debt_recovery"} />
          </DescriptionScheduleName>
          <DescriptionScheduleValue>
            {offer.repaymentSchedule.type === "end" && (
              <Translate i18n={"models:loan.at_end_loan"} />
            )}
            {offer.repaymentSchedule.type === "equal_share" && (
              <Translate
                i18n={"models:loan.monthly_equal_shares"}
                start={offer.repaymentSchedule.shareDate}
                end={offer.duration}
              />
            )}
          </DescriptionScheduleValue>
        </DescriptionScheduleItem>
      </DescriptionSchedule>
    </DescriptionContainer>
  )
}

const Table = (props) => {
  const { data, history } = props
  const ticker = getOrderTicker(data)
  const { application } = data
  const monthArray = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ]
  const dateParseCompleted = new Date(data.completedAt)
  const dayEndCompleted = dateParseCompleted.getDate()
  const monthCompleted = monthArray[dateParseCompleted.getMonth()]
  const yearCompleted = dateParseCompleted.getFullYear()

  const dateParseSucceeded = React.useMemo(() => {
    const lastPay = data.paymentSchedule?.borrower?.[0]?.items.slice(-1).pop()
    const date = lastPay?.date
    return date && new Date(date)
  }, [data])
  const dayEndSucceeded = dateParseSucceeded && dateParseSucceeded.getDate()
  const monthSucceeded = dateParseSucceeded && monthArray[dateParseSucceeded.getMonth()]
  const yearSucceeded = dateParseSucceeded && dateParseSucceeded.getFullYear()

  const amount = props.data.chain?.gatheredAmount || 0
  const [handleModal, setHandleModal] = useState(false)
  const url = application?.externalMedium?.[0]?.video?.url

  const loanInfo = data.paymentSchedule?.borrower?.[0]?.info

  let statusView
  if (loanInfo?.state === "pastdue") {
    statusView = { isSuccess: false, descr: "Просрочка платежа" }
  } else if (loanInfo?.state === "ok") {
    statusView = { isSuccess: true, descr: "Выплаты по графику" }
  }

  function toProposal() {
    history.push(`/market/${data.id}?source=market`)
  }

  const isPastDue = loanInfo.state === "pastdue"

  return (
    <>
      {handleModal && (
        <Modal.Video onClose={() => setHandleModal(false)}>
          <iframe
            width="100%"
            height="100%"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Modal.Video>
      )}

      <TableRow>
        <TableTdName title={application.data.companyName}>
          <TableTdNameWrap onClick={toProposal}>
            {application.data.companyName}
          </TableTdNameWrap>
        </TableTdName>

        <td>{ticker}</td>
        <TableTdRating>{getOrderRating(data)}</TableTdRating>
        <TableTdSum>{roundNumber(amount)}</TableTdSum>
        <TableTdRate>{getOrderInterestRate(data)}%</TableTdRate>

        <td>
          {dayEndCompleted} {monthCompleted} {yearCompleted}
        </td>

        <td>
          {dayEndSucceeded} {monthSucceeded} {yearSucceeded}
        </td>

        {statusView && isPastDue && (
          <TableTdStatus isSuccess={statusView.isSuccess}>
            <DebtLink href={getDebtURL(data.chain.id)} target="_blank" rel="noreferrer noopener">
              <CalendarIcon /> {statusView.descr}
            </DebtLink>
          </TableTdStatus>
        )}
        {statusView && !isPastDue && (
          <TableTdStatus isSuccess={statusView.isSuccess}>
            <CalendarIcon /> {statusView.descr}
          </TableTdStatus>
        )}
        {!statusView && <td />}
      </TableRow>
    </>
  )
}

export default {
  Head,
  Body,
  Info,
  Table,
  FooterAction,
  Description,
}
