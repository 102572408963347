import { number } from "src/utils"
import memoize from "memoize-one"

const getTooltipLeftOffset = (minValuePercents) => {
  const containerWidth = 320
  const percents = Number(minValuePercents)
  const countedOffset = (containerWidth / 100) * percents - 28

  return countedOffset <= 320 ? countedOffset : 292
}

const mapAmountToStatusFrom = memoize((order) => {
  const {
    chain: { gatheredAmount },
    application: {
      data: { minValue, maxValue },
    },
  } = order

  return {
    minValue: number(minValue),
    maxValue: number(maxValue),
    maxAmountDelta: number(Number(maxValue) - gatheredAmount),
    minAmountDelta: number(Number(minValue) - gatheredAmount),
    maxAmountCollected: gatheredAmount >= Number(maxValue),
    minAmountCollected: gatheredAmount >= Number(minValue),
  }
})

export { getTooltipLeftOffset, mapAmountToStatusFrom }
