import React, { useContext, useMemo } from "react"

import { CurrentProfileContext } from "src/context"
import { findPaymentListByProfile } from "src/utils"

import InvestorPaymentsGraph from "./InvestorPaymentsGraph"
import OwnerPaymentsGraph from "./OwnerPaymentsGraph"

import { parseItems, parseInvestments, sortByDate } from "./utils"

function ProposalPaymentsGraph(props) {
  const { profile } = useContext(CurrentProfileContext)
  const { id: profileId } = profile || {}

  const { isOwner, data } = props
  const { paymentScheduleList, viewer } = data

  const paymentSchedule = findPaymentListByProfile(profileId, paymentScheduleList) || {}
  const { items = [], info } = paymentSchedule

  const investments = useMemo(() => parseInvestments(viewer.investments), [
    viewer.investments,
  ])
  const parsedItems = useMemo(() => parseItems(items), [items])
  const sortedItems = useMemo(() => sortByDate([...parsedItems, ...investments]), [
    investments,
    parsedItems,
  ])

  return (
    <>
      {isOwner && <OwnerPaymentsGraph items={parsedItems} paymentInfo={info} />}
      {!isOwner && <InvestorPaymentsGraph items={sortedItems} />}
    </>
  )
}

export default ProposalPaymentsGraph
