import React from "react"

import memoize from "memoize-one"

import { Box, Button, Translate } from "src/components"

import {
  Bold,
  PreLined,
  Container,
  StyledLink,
  HeaderWrapper,
  TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

import ComposedConsumer from "./composedConsumer"

const Action = memoize((props) => {
  const { id } = props

  return (
    <Box pt="18px">
      <StyledLink href={`${`/market/${id}?source=loans`}`} target="_blank">
        <Button variant="defaultThinWhite">
          <Translate i18n="common:about" />
        </Button>
      </StyledLink>
    </Box>
  )
})

const MinAmountCollected = memoize((props) => {
  const { id, maxAmountDelta } = props

  return (
    <React.Fragment>
      <HeaderWrapper>
        <Translate i18n="components:tooltips.min_amount_collected.head" />
      </HeaderWrapper>
      <TextBlockWrapper>
        <PreLined>
          <Translate i18n="components:tooltips.min_amount_collected.body" />
          <Bold>{maxAmountDelta} ₽</Bold>
        </PreLined>
        <Action id={id} />
      </TextBlockWrapper>
    </React.Fragment>
  )
})

const MinAmountNotCollected = memoize((props) => {
  const {
    id, minValue, viewerRole, minAmountDelta,
  } = props

  return (
    <TextBlockWrapper>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.head" />
        <Bold>{minValue} ₽</Bold>
      </PreLined>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.postfix" />
        <Bold>{minAmountDelta} ₽</Bold>
      </PreLined>
      {viewerRole === "MASTER" ? null : <Action id={id} />}
    </TextBlockWrapper>
  )
})

const render = memoize(({ order, relation }) => {
  const { minAmountCollected, ...rest } = mapAmountToStatusFrom(order)

  return (
    <Container>
      {minAmountCollected ? (
        <MinAmountCollected {...rest} viewerRole={relation} />
      ) : (
        <MinAmountNotCollected {...rest} viewerRole={relation} />
      )}
    </Container>
  )
})

export default () => <ComposedConsumer>{render}</ComposedConsumer>
