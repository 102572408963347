/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConfirmPhoneInput = {
    phone: string;
    token: string;
};
export type ConfirmPhoneMutationVariables = {
    input: ConfirmPhoneInput;
};
export type ConfirmPhoneMutationResponse = {
    readonly confirmPhone: {
        readonly signedPhone: string;
    };
};
export type ConfirmPhoneMutation = {
    readonly response: ConfirmPhoneMutationResponse;
    readonly variables: ConfirmPhoneMutationVariables;
};



/*
mutation ConfirmPhoneMutation(
  $input: ConfirmPhoneInput!
) {
  confirmPhone(input: $input) {
    signedPhone
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConfirmPhoneInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConfirmPhonePayload",
    "kind": "LinkedField",
    "name": "confirmPhone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedPhone",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmPhoneMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmPhoneMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ConfirmPhoneMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmPhoneMutation(\n  $input: ConfirmPhoneInput!\n) {\n  confirmPhone(input: $input) {\n    signedPhone\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a434c975be30c015e78af3d594276cd1';
export default node;
