import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 208px;

  @media screen and (max-width: 1100px) {
    min-width: 160px;
  }

  @media screen and (max-width: 420px) {
    min-width: 100%;
    flex: 1;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`

export const Header = styled.div`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }

  @media screen and (max-width: 420px) {
    text-align: center;
  }
`

export const Description = styled.div`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;

  div:not(:last-child) {
    margin-right: 20px;
  }

  @media screen and (max-width: 800px) {
    div:not(:last-child) {
      margin-right: 6px;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
    margin: 8px 0 0;
    font-weight: 300;

    div:not(:last-child) {
      margin-right: 24px;
    }
  }
`

export const Value = styled.div`
  font-family: Geometria;
  font-size: 26px;
  line-height: 33px;
  color: #4a4a4a;
  font-weight: bold;
  margin-top: 8px;

  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media screen and (max-width: 420px) {
    text-align-last: justify;
  }
`
