/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type LoanCompletedOrdersListFragment = {
    readonly node: {
        readonly id: string;
        readonly status: OrderStatusEnum | null;
        readonly confirmedAt: string | null;
        readonly paymentScheduleList: ReadonlyArray<{
            readonly info: {
                readonly state: string | null;
                readonly pastdue_days: number | null;
            } | null;
            readonly items: ReadonlyArray<{
                readonly state: string | null;
                readonly total: number;
                readonly loan: number;
                readonly payDate: string | null;
                readonly date: string;
                readonly interest: number;
                readonly total_gross: number;
                readonly total_details: ReadonlyArray<{
                    readonly currency: CurrencyEnum;
                    readonly amount: number;
                }>;
                readonly total_gross_details: ReadonlyArray<{
                    readonly currency: CurrencyEnum;
                    readonly amount: number;
                }>;
            }>;
        }>;
        readonly offer: {
            readonly data: string;
            readonly receivedAt: string | null;
            readonly approvedAt: string | null;
        } | null;
        readonly application: {
            readonly data: string;
            readonly shortTitle: string;
            readonly longTitle: string;
        } | null;
        readonly chain: {
            readonly id: string;
            readonly gatheredAmount: number;
        } | null;
        readonly profile: {
            readonly id: string;
            readonly name?: string;
            readonly borrower?: {
                readonly ticker: string | null;
            } | null;
        };
    };
    readonly " $refType": "LoanCompletedOrdersListFragment";
};
export type LoanCompletedOrdersListFragment$data = LoanCompletedOrdersListFragment;
export type LoanCompletedOrdersListFragment$key = {
    readonly " $data"?: LoanCompletedOrdersListFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LoanCompletedOrdersListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoanCompletedOrdersListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "confirmedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentSchedule",
          "kind": "LinkedField",
          "name": "paymentScheduleList",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LoanInfo",
              "kind": "LinkedField",
              "name": "info",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pastdue_days",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentScheduleItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "loan",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interest",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total_gross",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AmountWithCurrency",
                  "kind": "LinkedField",
                  "name": "total_details",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AmountWithCurrency",
                  "kind": "LinkedField",
                  "name": "total_gross_details",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderOffer",
          "kind": "LinkedField",
          "name": "offer",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approvedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderApplication",
          "kind": "LinkedField",
          "name": "application",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderChainInfo",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gatheredAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Borrower",
                  "kind": "LinkedField",
                  "name": "borrower",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ticker",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LegalEntityProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderEdge"
};
})();
(node as any).hash = '1cb5353e7fa513de5488e04e73944d89';
export default node;
