import React from "react"

import { Box, Text, MaskInput } from "src/components"
import { Label, Container } from "./styles"

const iianMask = "999 - 999 - 999   9999"

const Hint = (props) => (
  <Box mt={10}>
    <Text fontSize={0} {...props} />
  </Box>
)

const Error = (props) => <Hint color="red" {...props} />

const renderLabel = ({ label, disabled }) => label && <Label disabled={disabled}>{label}</Label>

const renderHint = ({ hint, error }) => !error && hint && <Hint color="greyDark">{hint}</Hint>

const renderError = ({ error }) => error && <Error>{error}</Error>

export default (props) => {
  const { label, hint, ...rest } = props

  return (
    <Container>
      {renderLabel({ label, ...rest })}
      <MaskInput mask={iianMask} {...rest} />
      {renderHint({ hint, ...rest })}
      {renderError({ ...rest })}
    </Container>
  )
}
