import React from "react"
import styled from "styled-components"

import {
  Box,
  Text,
  Button,
  Heading,
  FlexBox,
  Translate,
  DownloadLink,
  OtherDocuments,
} from "src/components"

import HeaderWrapper from "./styles"

const StyledDownLoadLink = styled(DownloadLink)`
  font-size: 16px;
  padding-bottom: 12px;
  color: ${({ theme }) => theme.colors.blue} !important;
  text-decoration: none;
`

export default (props) => {
  const { order, onClose, onNextStep } = props

  const { skipOffer, borrowerOffer, otherDocuments } = order

  return (
    <Box>
      <HeaderWrapper>
        <Heading.h5>
          <Translate i18n="instructions_modal.fee.header" ns="components" />
        </Heading.h5>
      </HeaderWrapper>
      <Box pt="14px" pb="18px">
        <Text>
          <Translate
            i18n="instructions_modal.fee.approve_description"
            ns="components"
          />
        </Text>
      </Box>
      <Box pb="24px">
        <Text>
          <Translate
            i18n="instructions_modal.fee.transfer_description"
            ns="components"
          />
        </Text>
      </Box>
      {!skipOffer && (
        <Box pb="18px">
          <StyledDownLoadLink
            href={borrowerOffer.url}
            target="_blank"
            textDecoration="underline"
            download
          >
            <Translate
              i18n="instructions_modal.fee.links.offer_doc"
              ns="components"
            />
          </StyledDownLoadLink>
        </Box>
      )}
      <OtherDocuments documents={otherDocuments} />
      <FlexBox display="flex" flexDirection="row" justifyContent="flex-end">
        <Button variant="secondary" onClick={onClose}>
          <Translate i18n="cancel" ns="common" />
        </Button>
        <Box pl="20px">
          <Button onClick={onNextStep}>
            <Translate
              i18n="accreditation.individual.buttons.continue"
              ns="components"
            />
          </Button>
        </Box>
      </FlexBox>
    </Box>
  )
}
