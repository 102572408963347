import React from "react"

import {
  Header,
  HeaderText,
  HeaderTitle,
  HeaderDescription,
  HeaderDescriptionItem,
  ImageContainer,
  Image,
} from "./styles"

function ReferralHeader() {
  return (
    <Header>
      <HeaderText>
        <HeaderTitle>Делитесь о нас с друзьями и получайте бонусы.</HeaderTitle>
        <HeaderDescription>
          <HeaderDescriptionItem>Приглашаете друзей</HeaderDescriptionItem>
          <HeaderDescriptionItem>
            Они инвестируют на Карме
          </HeaderDescriptionItem>
          <HeaderDescriptionItem>
            И вы и друзья получаете бонусы
          </HeaderDescriptionItem>
        </HeaderDescription>
      </HeaderText>

      <ImageContainer>
        <Image />
      </ImageContainer>
    </Header>
  )
}

export default ReferralHeader
