import React from "react"
import { useMutation } from "src/hooks"
import { getAttachmentLink } from "src/mutations"

const AttachmentLink = ({
  attachmentId, component, children, ...props
}) => {
  const Component = component || "a"

  const mutation = useMutation(getAttachmentLink)
  const [busy, setBusy] = React.useState(false)

  mutation.onCompleted((data) => {
    window.open(data.getAttachmentLink.url)
    setBusy(false)
  })

  mutation.onError((error) => {
    // eslint-disable-next-line no-console
    console.error(error)
    setBusy(false)
  })

  const handleClick = (event) => {
    event.preventDefault()

    if (busy) {
      return
    }

    mutation.commit({
      input: {
        id: attachmentId,
      },
    })

    setBusy(true)
  }

  return (
    <Component
      {...props}
      onClick={handleClick}
      disabled={!attachmentId}
      style={
        attachmentId && {
          cursor: busy ? "wait" : "pointer",
        }
      }
    >
      {children}
    </Component>
  )
}

export default AttachmentLink
