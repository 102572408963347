import styled from "styled-components"

export const Container = styled.div`
  display: inline-block;
  margin-bottom: 50px;
  margin-right: 70px;
  position: relative;
  font-family: Geometria;

  @media screen and (max-width: 790px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

export const Label = styled.label`
  font-size: 12px;
  line-height: 15px;
  color: #4a4a4a;
  font-weight: bold;
  font-family: Geometria;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Input = styled.input`
  display: block;
  height: 45px;
  width: 235px;
  color: #5f5f5f;
  border: 1px solid rgba(95, 95, 95, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 17px;
  padding: 14px;
  padding-right: 30px;
  font-family: Geometria;

  :focus {
    border: 1px solid rgba(95, 95, 95, 0.45);
  }

  @media screen and (max-width: 480px) {
    height: 70px;
    font-size: 20px;
    padding: 22px;
    padding-right: 42px;
    width: 100%;
  }
`

export const Currency = styled.span`
  font-family: Geometria;
  position: absolute;
  left: 215px;
  bottom: 72px;
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  color: #4a4a4a;

  @media screen and (max-width: 480px) {
    font-size: 19px;
    left: calc(100% - 28px);
    bottom: 103px;
    font-weight: bold;
  }
`

export const Description = styled.div`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.65);
  margin-top: 4px;
  
  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
`

export const ErrorText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.mainRed};
  font-family: Geometria;
  min-height: 16px;
  width: calc(100% + 40px);
  position: absolute;
  top: -40px;
  left: 0;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
    min-height: 16px;
    width: 100%;
    top: -50px;
  }
`
