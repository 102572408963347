import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query MarketOrdersListQuery {
    orders {
      edges {
        node {
          id
          status
          application {
            data
          }
          chain {
            id
          }
        }
      }
    }
  }
`

const Container = (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)

Container.query = query

export default Container
