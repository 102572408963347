import React, { useState } from "react"
import Relay from "react-relay"

import { DeleteProfileModal } from "src/components"

import { buildGroups } from "../../organisms/AccreditationInfo/constants"

import { Group } from "./Elements"
import {
  Container, ButtonContainer, Button, DeleteButton, DeclineReason,
} from "./styles"
import fragment from "./ProfileFormFragment"

function ProfileForm(props) {
  const { profile, history } = props
  const groups = buildGroups(profile)

  const [isOpened, setOpened] = useState(false)

  const { accreditation, profileTransactions, bankAccounts } = profile
  const { status, declineReason } = accreditation

  const buttonIsVisible = !profileTransactions.count && !bankAccounts.length

  function toEdit() {
    history.push(`/accounts/${profile.id}/accreditation`)
  }

  function openModal() {
    setOpened(true)
  }

  return (
    <Container>
      {status === "DECLINED" && !!declineReason && (
        <DeclineReason>{declineReason}</DeclineReason>
      )}

      {groups.map((item, idx) => (
        <Group key={idx} item={item} />
      ))}

      <ButtonContainer>
        <Button disabled={status === "PENDING"} onClick={toEdit}>
          редактировать
        </Button>

        {buttonIsVisible && (
          <DeleteButton onClick={openModal}>удалить профиль</DeleteButton>
        )}
      </ButtonContainer>

      {isOpened && (
        <DeleteProfileModal
          profileId={profile.id}
          isOpened={isOpened}
          onClose={() => setOpened(false)}
        />
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfileForm, { profile: fragment })
