import React, { memo } from "react"

import { processEvent, stateFromProps, ErrorsExecutor } from "./utils"

import { InputWrapper } from "../styles"

import ComposedConsumer from "./composedConsumer"

import Inputs from "../../Inputs"
import NewInputs from "../../NewInputs"

const StateLessInput = memo((props: any) => {
  const {
    errors, onChange, inputsType, directErrorPath, ...rest
  } = props

  const BuildInInput = inputsType === "old" ? Inputs : NewInputs

  return (
    <ErrorsExecutor
      errors={errors}
      onChange={onChange}
      directErrorPath={directErrorPath}
    >
      {(renderProps) => {
        const { data, nested, errors: executedErrors } = renderProps

        if (nested) {
          return (
            <BuildInInput
              {...rest}
              onChange={onChange}
              errors={executedErrors}
            />
          )
        }

        const { hasError, chainedOnChange, errorPresentation } = data

        return (
          <BuildInInput
            {...rest}
            hasError={hasError}
            onChange={chainedOnChange}
            error={errorPresentation}
            errors={executedErrors}
          />
        )
      }}
    </ErrorsExecutor>
  )
})

const compareStateSnapshot = (currentState, nextState) => JSON.stringify(currentState) !== JSON.stringify(nextState)

class StateFullInput extends React.Component<any, any> {
  state = {
    ...stateFromProps(this.props),
  }

  static getDerivedStateFromProps(props, state) {
    const nextState = stateFromProps(props)
    const currentState = state
    const stateValue = compareStateSnapshot(currentState, nextState)
      ? nextState
      : currentState

    return { ...stateValue }
  }

  shouldComponentUpdate(props, state) {
    return compareStateSnapshot(this.state, state)
  }

  onChange = (inputType) => (obtainedEvent) => {
    if (inputType === "bank_autosuggest") {
      return this.onChangeMultiply(inputType, obtainedEvent)
    }

    const { name, value } = processEvent(inputType)(obtainedEvent)[0]

    const nextState = { ...this.props.value }
    nextState[name] = value

    return this.props.onChange(nextState)
  }

  onChangeMultiply = (inputType, obtainedEvent) => {
    const nextState = { ...this.props.value }
    const resultArray = processEvent(inputType)(obtainedEvent)
    resultArray.forEach((bankField) => {
      nextState[bankField.name] = bankField.value
    })

    this.props.onChange(nextState)
  }

  render() {
    const {
      type,
      name,
      blank,
      length,
      inputsType,
      environment,
      nestedLevel,
      ...rest
    } = this.props

    return (
      <InputWrapper
        length={length}
        inputsType={inputsType}
        nesetedLevel={nestedLevel}
      >
        <StateLessInput
          {...rest}
          {...this.state.injections}
          {...this.state.dependencies}
          inputsType={inputsType}
          type={type}
          name={!blank && name}
          errors={this.state.errors}
          value={!blank && this.state.value}
          environment={environment || null}
          onChange={!blank && this.onChange(type)}
        />
      </InputWrapper>
    )
  }
}

export default (props) => (
  <ComposedConsumer>
    {(renderProps) => {
      const {
        type,
        value,
        onChange,
        purposedMutations,
        ...errors
      } = renderProps

      return (
        <StateFullInput
          {...props}
          value={value}
          errors={errors}
          inputsType={type}
          onChange={onChange}
          purposedmutations={purposedMutations}
        />
      )
    }}
  </ComposedConsumer>
)
