/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CancelInvestmentInput = {
    orderId: string;
    profileId: string;
    token: string;
};
export type CancelInvestmentMutationVariables = {
    input: CancelInvestmentInput;
};
export type CancelInvestmentMutationResponse = {
    readonly cancelInvestment: {
        readonly order: {
            readonly id: string;
        };
    };
};
export type CancelInvestmentMutation = {
    readonly response: CancelInvestmentMutationResponse;
    readonly variables: CancelInvestmentMutationVariables;
};



/*
mutation CancelInvestmentMutation(
  $input: CancelInvestmentInput!
) {
  cancelInvestment(input: $input) {
    order {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CancelInvestmentInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CancelInvestmentPayload",
    "kind": "LinkedField",
    "name": "cancelInvestment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelInvestmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelInvestmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CancelInvestmentMutation",
    "operationKind": "mutation",
    "text": "mutation CancelInvestmentMutation(\n  $input: CancelInvestmentInput!\n) {\n  cancelInvestment(input: $input) {\n    order {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a71a3d6c1b6514a5c890f6c2a47a6d49';
export default node;
