import React from "react"
import { Box, Text } from "src/components"

const DocumentBox = ({ children, disabled }) => (
  <Box
    pb="3px"
    mr="15px"
    alignSelf="flex-end"
    borderBottom="1px solid"
    borderColor={disabled ? "greyDarker" : "blue"}
  >
    {children}
  </Box>
)

export const Title = ({ children, disabled }) => (
  <DocumentBox disabled={disabled}>
    <Text fontSize={16} color={disabled ? "greyDarker" : "blue"}>
      {children}
    </Text>
  </DocumentBox>
)

export const Note = ({ children, disabled }) => (
  <Text fontSize={14} color={disabled ? "greyDark" : "blue"}>
    {children}
  </Text>
)
