/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type RequestWithdrawalInput = {
    profileId: string;
    amount: number;
    currency?: CurrencyEnum | null;
    token: string;
};
export type RequestWithdrawalMutationVariables = {
    input: RequestWithdrawalInput;
};
export type RequestWithdrawalMutationResponse = {
    readonly requestWithdrawal: {
        readonly profile: {
            readonly id: string;
            readonly __typename: "LegalEntityProfile";
            readonly accountBalanceDetailed: ReadonlyArray<{
                readonly available: number;
                readonly currency: CurrencyEnum;
                readonly invested: number;
                readonly blocked: number;
            }>;
        } | {
            readonly id: string;
            readonly __typename: "IndividualProfile";
            readonly accountBalanceDetailed: ReadonlyArray<{
                readonly available: number;
                readonly currency: CurrencyEnum;
                readonly invested: number;
                readonly blocked: number;
            }>;
        } | {
            readonly id: string;
            readonly __typename: "EntrepreneurProfile";
            readonly accountBalanceDetailed: ReadonlyArray<{
                readonly available: number;
                readonly currency: CurrencyEnum;
                readonly invested: number;
                readonly blocked: number;
            }>;
        } | {
            readonly id: string;
            readonly __typename: "ForeignIndividualProfile";
            readonly accountBalanceDetailed: ReadonlyArray<{
                readonly available: number;
                readonly currency: CurrencyEnum;
                readonly invested: number;
                readonly blocked: number;
            }>;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    };
};
export type RequestWithdrawalMutation = {
    readonly response: RequestWithdrawalMutationResponse;
    readonly variables: RequestWithdrawalMutationVariables;
};



/*
mutation RequestWithdrawalMutation(
  $input: RequestWithdrawalInput!
) {
  requestWithdrawal(input: $input) {
    profile {
      __typename
      ... on LegalEntityProfile {
        id
        __typename
        accountBalanceDetailed {
          available
          currency
          invested
          blocked
        }
      }
      ... on IndividualProfile {
        id
        __typename
        accountBalanceDetailed {
          available
          currency
          invested
          blocked
        }
      }
      ... on EntrepreneurProfile {
        id
        __typename
        accountBalanceDetailed {
          available
          currency
          invested
          blocked
        }
      }
      ... on ForeignIndividualProfile {
        id
        __typename
        accountBalanceDetailed {
          available
          currency
          invested
          blocked
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestWithdrawalInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blocked",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestWithdrawalPayload",
        "kind": "LinkedField",
        "name": "requestWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "LegalEntityProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestWithdrawalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestWithdrawalPayload",
        "kind": "LinkedField",
        "name": "requestWithdrawal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "LegalEntityProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RequestWithdrawalMutation",
    "operationKind": "mutation",
    "text": "mutation RequestWithdrawalMutation(\n  $input: RequestWithdrawalInput!\n) {\n  requestWithdrawal(input: $input) {\n    profile {\n      __typename\n      ... on LegalEntityProfile {\n        id\n        __typename\n        accountBalanceDetailed {\n          available\n          currency\n          invested\n          blocked\n        }\n      }\n      ... on IndividualProfile {\n        id\n        __typename\n        accountBalanceDetailed {\n          available\n          currency\n          invested\n          blocked\n        }\n      }\n      ... on EntrepreneurProfile {\n        id\n        __typename\n        accountBalanceDetailed {\n          available\n          currency\n          invested\n          blocked\n        }\n      }\n      ... on ForeignIndividualProfile {\n        id\n        __typename\n        accountBalanceDetailed {\n          available\n          currency\n          invested\n          blocked\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fca405054fec309c52312858eab08e6f';
export default node;
