import React from "react"

import { ErrorsContainer } from "src/containers"
import { PurposedMutationProvider } from "src/context"

import {
  AddIndividualProfile,
  RequestProfileAccreditation,
  RequestProfilePhoneConfirmation,
  SetIndividualProfile,
  UpdateIndividualProfileAsInvestor,
} from "src/mutations"

import { useMutation } from "src/hooks"

import { pipe, createChainedFunction, promisifyHookedMutation } from "src/utils"

import { profileDataFromState, investorDataFromState } from "./utils"

const processIndividualProfileMutations = [
  "setProfile",
  "addIndividualProfile",
  "requestAccreditation",
  "updateIndividualProfileAsInvestor",
]

const ProcessIndividualProfile = React.memo((props: any) => {
  const { errors } = props
  const { setErrors } = errors

  const use = pipe(useMutation, promisifyHookedMutation)

  const addProfile = use(AddIndividualProfile)
  const updateProfile = use(SetIndividualProfile)
  const updateInvestorData = use(UpdateIndividualProfileAsInvestor)

  const requestPhoneConfirmation = use(RequestProfilePhoneConfirmation)
  const requestProfileAccreditation = use(RequestProfileAccreditation)

  const throwError = (err) => {
    throw new Error(err)
  }

  const handleErrors = createChainedFunction(setErrors, throwError)

  const createIndividualProfile = (shouldSkipValidation) => (notify) => (
    formState,
  ) => {
    const input = {
      ...profileDataFromState(formState),
    }

    return addProfile({ input })
      .then((data) => {
        const {
          addIndividualProfile: { profile },
        } = data

        let updatedDataInput = {}
        if (formState.bic || formState.checkingAccount) {
          updatedDataInput = {
            bic: formState.bic || "",
            checkingAccount: formState.checkingAccount || "",
            ...investorDataFromState(formState),
            profileId: profile.id,
            shouldSkipValidation,
          }
        } else {
          updatedDataInput = {
            ...investorDataFromState(formState),
            profileId: profile.id,
            shouldSkipValidation,
          }
        }

        return updateInvestorData({
          input: updatedDataInput,
        }).catch((e) => {
          setErrors(e)
          notify("error")

          return {
            data,
            errorFromUpdate: true,
          }
        })
      })
      .catch(handleErrors)
  }

  const updateIndividualProfile = (shouldSkipValidation) => (notify) => (
    formState,
  ) => {
    const input = {
      profile: profileDataFromState(formState),
      accreditation: {
        ...investorDataFromState(formState),
        shouldSkipValidation,
      },
    }

    let updatedDataInput = {}
    if (formState.bic || formState.checkingAccount) {
      updatedDataInput = {
        bic: formState.bic || "",
        checkingAccount: formState.checkingAccount || "",
        ...investorDataFromState(formState),
        shouldSkipValidation,
      }
    } else {
      updatedDataInput = {
        ...investorDataFromState(formState),
        shouldSkipValidation,
      }
    }

    return updateInvestorData({
      input: updatedDataInput,
    })
      .then((data) => updateProfile(input).catch((e) => {
        setErrors(e)
        notify("error")

        return {
          data,
          errorFromUpdate: true,
        }
      }))
      .catch(handleErrors)
  }

  const requestToken = (formState) => {
    const tags = ["UPDATE_SUPPORTING_DOCUMENTS"]
    if (formState.bic || formState.checkingAccount) {
      tags.push("CONFIRM_BANK_ACCOUNT_WITH_PHONE")
    }
    requestPhoneConfirmation({
      input: {
        profileId: formState.id,
        kind: "REQUEST_PROFILE_ACCREDITATION",
        tags,
      },
    })
      .then(
        ({ requestProfilePhoneConfirmation }) => requestProfilePhoneConfirmation.status,
      )
      .catch(handleErrors)
  }

  const requestAccreditation = (profileId) => ({ input: injectedInput }) => {
    const { token } = injectedInput

    const input = {
      role: "INVESTOR",
      token,
      profileId,
    }

    return requestProfileAccreditation({ input }).catch(handleErrors)
  }

  return (
    <PurposedMutationProvider value={processIndividualProfileMutations}>
      {props.children({
        errors,
        requestToken,
        requestAccreditation,
        createIndividualProfile,
        updateIndividualProfile,
      })}
    </PurposedMutationProvider>
  )
})

export default (props) => (
  <ErrorsContainer>
    {(errors) => <ProcessIndividualProfile errors={errors} {...props} />}
  </ErrorsContainer>
)
