import React from "react"

import {
  Box,
  Label,
  FormRow,
  FlexBox,
  TextField,
  Translate,
  RadioButton,
} from "src/components"
import { cloneDeep } from "src/utils"
import { FLAT_VALUE } from "src/constants"

class Price extends React.Component<any, any> {
  onChange = (event) => {
    const { name, value } = event.target
    const price = cloneDeep(this.props.value)

    price[name] = value
    this.props.onChange({
      name: this.props.name,
      value: price,
    })
  }

  render() {
    return (
      <FormRow>
        <Box>
          <Label>
            <Translate
              i18n="legal_entity_offer_form.rows.price.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box>
          {FLAT_VALUE.map(({ label, value }, index) => (
            <FlexBox
              key={index}
              display="flex"
              flexDirection="row"
              justifyContnet="flex-start"
              alignItems="center"
              py="6px"
            >
              <RadioButton
                label={label}
                value={value}
                onChange={this.onChange}
                name="priceType"
                checked={this.props.value.priceType === value}
              />
            </FlexBox>
          ))}
          <Box>
            <TextField
              value={this.props.value.value}
              name="value"
              onChange={this.onChange}
              hint={
                <Translate
                  i18n="legal_entity_offer_form.rows.price.row_hints.value"
                  ns="components"
                />
              }
            />
          </Box>
        </Box>
      </FormRow>
    )
  }
}

export default Price
