import React, {
  useContext, useEffect, useMemo, useState,
} from "react"

import { LoaderBox, MiniLoader, LoadMore } from "src/components"
import { CurrentProfileContext } from "src/context"
import { InvestmentPunishedOrdersListQueryContainer } from "src/query"
import {
  findPaymentListByProfile, getOrderTicker, toCurrencyFormat, getDebtURL,
} from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  PunishmentStatusContainer,
  TDStatus,
  Graph,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
  DebtLink,
} from "./styles"
import { ClockIcon, GraphIcon } from "./icons"
import { Table, List, PunishmentStatus } from "../Elements"
import {
  parseInvestments,
  getPaymentAmount,
  getOrderName,
  getOrderRate,
  getOrdernFinishDate,
  getNotPaidAmount,
} from "./utils"
import { InvestmentPunishedCard } from "../Cards"

function RenderedTable(props) {
  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
    filter,
    setFilter,
  } = props

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>{getOrderName(obj)}</TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>,
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Долг, ₽",
        hint: "Тело долга + невыплаченные по графику %",
        render: (i, obj) => {
          const returnedLoan = getPaymentAmount(obj, profileId, "loan")
          const notPaidIntereset = getNotPaidAmount(obj, profileId, "interest")
          return <TData key={i}>{toCurrencyFormat(obj.loan - returnedLoan + notPaidIntereset)}</TData>
        },
        placeholder: (i) => <TData key={i}>0</TData>,
      },
      {
        title: "Выплачено, ₽",
        hint: "Тело долга + проценты + пени + неустойки",
        render: (i, obj) => <TData key={i}>{toCurrencyFormat(getPaymentAmount(obj, profileId))}</TData>,
        placeholder: (i) => <TData key={i}>0</TData>,
      },
      {
        title: "Дата погашения",
        render: (i, obj) => (
          <TData key={i}>
            {ClockIcon} {getOrdernFinishDate(obj)}
          </TData>
        ),
        placeholder: (i) => <TData key={i}>{ClockIcon} -</TData>,
      },
      {
        title: "Дней просрочки",
        render: (i, obj) => {
          const paymentScheduleList = findPaymentListByProfile(profileId, obj.paymentScheduleList || []) || {}

          // eslint-disable-next-line camelcase
          return <TData key={i}>{paymentScheduleList?.info?.pastdue_days ?? "-"}</TData>
        },
        placeholder: (i) => <TData key={i}>-</TData>,
      },
      {
        title: "Статус",
        render: (i, obj) => (
          <TDStatus color="#FF0505" key={i}>
            <DebtLink href={getDebtURL(obj.chain?.id)} target="_blank" rel="noreferrer noopener">
              <PunishmentStatusContainer>
                <div>
                  <Graph>{GraphIcon}</Graph>
                </div>
                <div>
                  <PunishmentStatus punishment={obj.punishment} />
                </div>
              </PunishmentStatusContainer>
            </DebtLink>
          </TDStatus>
        ),
        placeholder: (i) => (
          <TDStatus color="#FF0505" key={i}>
            <PunishmentStatusContainer>
              <PunishmentStatusContainer>
                <div>
                  <Graph>{GraphIcon}</Graph>
                </div>
                <div>
                  <PunishmentStatus punishment={{}} />
                </div>
              </PunishmentStatusContainer>
            </PunishmentStatusContainer>
          </TDStatus>
        ),
      },
    ]
  }

  const { investor } = data || {}
  const orders = useMemo(() => [...parseInvestments(investor)], [investor])
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.investor?.investments?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  const hasFilter = Boolean(Object.values(filter).find(Boolean))

  return (
    <div>
      {(orders.length > 0 || isFetching || hasFilter) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="ВЗЫСКАНИЕ"
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
                setFilter={setFilter}
                filter={filter}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              title="ВЗЫСКАНИЕ"
              items={orders}
              Render={InvestmentPunishedCard}
              isFetching={isFetching}
              toProposal={toProposal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
              setFilter={setFilter}
              filter={filter}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  return (
    <InvestmentPunishedOrdersListQueryContainer
      profile={profile}
      render={(data) => <RenderedTable {...rest} {...data} />}
    />
  )
}
