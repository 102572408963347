/* eslint-disable max-len */

import React from "react"

function Coin(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.4853 3.51469C18.2188 1.24819 15.2053 0 12 0C8.79469 0 5.78119 1.24819 3.51469 3.51469C1.24823 5.78119 0 8.79469 0 12C0 15.2053 1.24823 18.2188 3.51469 20.4853C5.78119 22.7518 8.79469 24 12 24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78119 20.4853 3.51469ZM12 22.5938C6.15858 22.5938 1.40625 17.8414 1.40625 12C1.40625 6.15858 6.15858 1.40625 12 1.40625C17.8414 1.40625 22.5938 6.15858 22.5938 12C22.5938 17.8414 17.8414 22.5938 12 22.5938Z" />
      <path d="M12.7532 11.2969H11.2469C10.3887 11.2969 9.69063 10.5988 9.69063 9.74068C9.69063 8.88253 10.3888 8.18443 11.2469 8.18443H14.2594C14.6477 8.18443 14.9625 7.86961 14.9625 7.4813C14.9625 7.09299 14.6477 6.77817 14.2594 6.77817H12.7031V5.22192C12.7031 4.83361 12.3883 4.5188 12 4.5188C11.6117 4.5188 11.2969 4.83361 11.2969 5.22192V6.77817H11.2469C9.61338 6.77817 8.28442 8.10718 8.28442 9.74068C8.28442 11.3742 9.61342 12.7031 11.2469 12.7031H12.7532C13.6113 12.7031 14.3094 13.4013 14.3094 14.2594C14.3094 15.1175 13.6113 15.8156 12.7532 15.8156H9.74069C9.35238 15.8156 9.03756 16.1304 9.03756 16.5188C9.03756 16.9071 9.35238 17.2219 9.74069 17.2219H11.2969V18.7782C11.2969 19.1665 11.6117 19.4813 12 19.4813C12.3883 19.4813 12.7031 19.1665 12.7031 18.7782V17.2219H12.7532C14.3867 17.2219 15.7156 15.8929 15.7156 14.2594C15.7156 12.6259 14.3867 11.2969 12.7532 11.2969Z" />
    </svg>
  )
}

Coin.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Coin
