import React from "react"

import { Box, Flex } from "src/components"

import {
  Logo,
  Header,
  Divider,
  LogoText,
  Assistant,
  StyledLink,
  HelpContainer,
  LinksContainer,
} from "./styles"

export default () => (
  <Header>
    <LinksContainer>
      <StyledLink to="/">
        <Flex alignItems="center">
          <Box is={Logo} mr="8px" />
          <LogoText>KARMA</LogoText>
        </Flex>
      </StyledLink>
      <Flex alignItems="center">
        <HelpContainer href="https://t.me/karmainvest" target="_blank">
          !
        </HelpContainer>
      </Flex>
    </LinksContainer>
    <Divider />
    <Assistant />
  </Header>
)
