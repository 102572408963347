import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query InvestmentsOrderListQuery {
    viewer {
      investments {
        edges {
          node {
            id
            order {
              id
              status
            }
            amount
          }
        }
      }
    }
  }
`

const Container = (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)

Container.query = query

export default Container
