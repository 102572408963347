import styled from "styled-components"

export const Container = styled.div`
  margin-top: 25px;
  padding: 30px 25px 30px 45px;
`

export const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -45px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.grey};
`

export const ListItemContainer = styled.div`
  position: relative;
`

export const StyledLink = styled.a`
  text-decoration: none;
`

export const StyledList = styled.ul`
  padding: 0 20px;
`
