import memoize from "memoize-one"
import React from "react"

import Indicator from "./Indicator"

import { Divider, Container, AmountValue } from "./styles"

const gatheredFunc = memoize((gatheredPercents) => (ii) => ii + 1 < gatheredPercents / 10)

const renderDividers = memoize((gatheredPercents) => {
  const dividers = []

  const isGathered = gatheredFunc(gatheredPercents)

  for (let ii = 0; ii < 9; ii += 1) {
    dividers.push(<Divider key={ii} gathered={isGathered(ii)} />)
  }

  return dividers
})

export default (props) => {
  const { gatheredPercents, minValuePercents } = props

  return (
    <Container>
      {renderDividers(gatheredPercents)}
      <AmountValue width={gatheredPercents} />
      <Indicator
        gatheredPercents={gatheredPercents}
        minValuePercents={minValuePercents}
      />
    </Container>
  )
}
