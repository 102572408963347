import React from "react"

import Icon from "./Icon"
import Body from "./Body"

const render = (props) => {
  const {
    text,
    icon,
    glyph,
    content,
    transform,
    borderColor,
    borderWidth,
    ...rest
  } = props

  return text || content ? (
    <Icon
      glyph={glyph}
      icon={icon}
      transform={transform}
      borderColor={borderColor}
      borderWidth={borderWidth}
    >
      <Body text={text} content={content} {...rest} />
    </Icon>
  ) : null
}

render.defaultProps = {
  transform: "none",
  borderColor: "red",
  borderWidth: "1px",
}

render.body = Body

export default render
