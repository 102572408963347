import styled from "styled-components"

export const Form = styled.form`
  position: relative;
  max-width: 650px;
  width: 100%;
`

export const Container = styled.div`
  position: relative;
  max-width: 550px;
  width: 100%;
`

export const Title = styled.h5`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 60px 0 20px;
  text-align: left;

  :first-of-type {
    margin-top: 40px;
  }
`

export const InputTooltip = styled.div`
  display: flex;
  align-items: center;
  margin-top: -12px;
  margin-bottom: 30px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryBlacks[6]};
`

export const TitleTooltip = styled(InputTooltip)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 55px 0 20px;
`

export const ButtonContainer = styled(Container)`
  max-width: 100%;
  display: flex;
  margin-top: 40px;
`

export const Button = styled.button`
  position: relative;
  width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mainRed};
  padding: 15px 0 13px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const RedButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.mainRed};
  color: white;
  margin-left: 22px;
`
