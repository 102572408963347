import React from "react"
import { graphql } from "react-relay"
import Slider from "react-slick"

import { QueryRenderer } from "src/components"

import {
  BannersContainer, Banner, GlobalStyle,
} from "./styles"

const query = graphql`
  query OrderBannersQuery {
    banners(filter: { active: true }) {
      id
      link
      images {
        id
        url
        size
        width
      }
      description
      active
    }
  }
`

const settings = {
  dots: true,
  speed: 500,
  variableWidth: false,
  draggable: false,
  className: "BannersWrap",
  slidesToShow: 2,
  autoplay: true,
  autoplaySpeed: 4000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        draggable: true,
        variableWidth: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1130,
      settings: {
        draggable: true,
        variableWidth: true,
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        draggable: true,
        variableWidth: false,
        slidesToShow: 1,
      },
    },
  ],
}

function OrderBanners(props) {
  const { banners } = props

  return (
    <>
      {banners && banners.length > 0 && (
        <>
          <GlobalStyle />

          <BannersContainer>
            <Slider infinite={banners.length > 1} {...settings}>
              {banners.map((banner, idx) => (
                <Banner key={idx} href={banner.link} target="_blank">
                  <img
                    src={banner.images && banner.images[0].url}
                    alt={banner.description || ""}
                  />
                </Banner>
              ))}
            </Slider>
          </BannersContainer>
        </>
      )}
    </>
  )
}

export default function Render() {
  return <QueryRenderer query={query} render={OrderBanners} />
}
