import styled from "styled-components"
import { width } from "styled-system"

import { Box, Text } from "src/components"

export const Table = styled.table`
  width: 1000px;
  border-collapse: collapse;
  background-color: white;

  thead tr {
    background-color: ${({ theme }) => theme.colors.blues[1]};

    th {
      margin: auto;
      vertical-align: top;
    }

    th:first-child {
      padding-left: 20px;
    }
  }

  tbody {
    tr {
      background-color: white;

      td {
        vertical-align: top;
      }

      td:first-child {
        padding-left: 20px;
      }
    }

    tr:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.blues[1]};
    }

    tr:last-child {
      background-color: ${({ theme }) => theme.colors.white};
      border-top: 2px solid ${({ theme }) => theme.colors.blues[1]};

      td {
        font-weight: bold;
      }
    }

    tr:empty {
      height: 20px;
    }
  }
`

export const HeadingBox = styled(Box)`
  flex: 1 1 0;
  text-align: left;
`

export const TooltipContainer = styled.div``

export const StyledText = styled(Text)`
  font-size: 10px;
  color: #5a616e;
  line-height: 1.45;
  text-transform: uppercase;
`

export const EmptyHeading = styled.th<any>`
  ${width}
`

export const EmptyBody = styled.td`
  ${width}
`

export const GlyphContent = styled(Text)`
  margin-top: 2px;
  line-height: 1;
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey};
`
