import styled, { createGlobalStyle } from "styled-components"

export const ModalWrapper = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`

export const ModalContainer = styled.div`
  padding: 80px 0;
  margin: 0 auto;
`

export const Icon = styled.div`
  background-image: url(/images/research.svg);
  background-size: contain;
  width: 136px;
  height: 136px;
  margin: 0 auto;
`

export const DisableScroll = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`
