/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type ProvisionKindEnum = "ESTATE" | "GUARANTOR" | "OTHER" | "VEHICLE" | "%future added value";
export type CompletedGuestOrderInfoQueryVariables = {
    id: string;
};
export type CompletedGuestOrderInfoQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly createdAt?: string;
        readonly status?: OrderStatusEnum | null;
        readonly statusFrom?: string | null;
        readonly offer?: {
            readonly id: string;
            readonly data: string;
        } | null;
        readonly application?: {
            readonly id: string;
            readonly data: string;
        } | null;
        readonly profile?: {
            readonly id: string;
            readonly name?: string;
            readonly creditRating?: {
                readonly rating: string;
            } | null;
            readonly _avatar?: {
                readonly url: string;
            } | null;
        };
        readonly confirmedAt?: string | null;
        readonly fee?: number | null;
        readonly chain?: {
            readonly id: string;
            readonly gatheredAmount: number;
            readonly investorsCount: number;
        } | null;
        readonly creditRating?: {
            readonly rating: string;
        } | null;
        readonly cession?: {
            readonly due: string;
            readonly isActive: boolean;
            readonly interestRate: number;
            readonly borrowerName: string;
            readonly originalChainId: string;
            readonly avatar: {
                readonly url: string;
            } | null;
            readonly borrowerRating: {
                readonly rating: string;
                readonly ratedAt: string;
            };
        } | null;
        readonly karma?: {
            readonly conclusion: string | null;
            readonly isInvestor: boolean;
        };
        readonly provision?: {
            readonly items: ReadonlyArray<{
                readonly kind: ProvisionKindEnum;
            }>;
        } | null;
    };
};
export type CompletedGuestOrderInfoQuery = {
    readonly response: CompletedGuestOrderInfoQueryResponse;
    readonly variables: CompletedGuestOrderInfoQueryVariables;
};



/*
query CompletedGuestOrderInfoQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on Order {
      createdAt
      status
      statusFrom
      offer {
        id
        data
      }
      application {
        id
        data
      }
      profile {
        __typename
        id
        ... on LegalEntityProfile {
          name
          creditRating {
            rating
          }
          _avatar {
            url
          }
        }
      }
      confirmedAt
      fee
      chain {
        id
        gatheredAmount
        investorsCount
      }
      creditRating {
        rating
      }
      cession {
        due
        isActive
        interestRate
        borrowerName
        originalChainId
        avatar {
          url
          id
        }
        borrowerRating {
          rating
          ratedAt
        }
      }
      karma {
        conclusion
        isInvestor
      }
      provision {
        items {
          __typename
          kind
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusFrom",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "data",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderOffer",
  "kind": "LinkedField",
  "name": "offer",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderApplication",
  "kind": "LinkedField",
  "name": "application",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditRating",
  "kind": "LinkedField",
  "name": "creditRating",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v11/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "_avatar",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    }
  ],
  "type": "LegalEntityProfile"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmedAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderChainInfo",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatheredAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investorsCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "due",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interestRate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "borrowerName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalChainId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditRating",
  "kind": "LinkedField",
  "name": "borrowerRating",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ratedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderKarma",
  "kind": "LinkedField",
  "name": "karma",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "conclusion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInvestor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompletedGuestOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderCession",
                "kind": "LinkedField",
                "name": "cession",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderProvision",
                "kind": "LinkedField",
                "name": "provision",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v25/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompletedGuestOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderCession",
                "kind": "LinkedField",
                "name": "cession",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderProvision",
                "kind": "LinkedField",
                "name": "provision",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v25/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CompletedGuestOrderInfoQuery",
    "operationKind": "query",
    "text": "query CompletedGuestOrderInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on Order {\n      createdAt\n      status\n      statusFrom\n      offer {\n        id\n        data\n      }\n      application {\n        id\n        data\n      }\n      profile {\n        __typename\n        id\n        ... on LegalEntityProfile {\n          name\n          creditRating {\n            rating\n          }\n          _avatar {\n            url\n          }\n        }\n      }\n      confirmedAt\n      fee\n      chain {\n        id\n        gatheredAmount\n        investorsCount\n      }\n      creditRating {\n        rating\n      }\n      cession {\n        due\n        isActive\n        interestRate\n        borrowerName\n        originalChainId\n        avatar {\n          url\n          id\n        }\n        borrowerRating {\n          rating\n          ratedAt\n        }\n      }\n      karma {\n        conclusion\n        isInvestor\n      }\n      provision {\n        items {\n          __typename\n          kind\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8364cc301388bf3b896d43265e859788';
export default node;
