/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type ProfileBankAccountsQueryVariables = {};
export type ProfileBankAccountsQueryResponse = {
    readonly viewer: {
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly __typename: string;
            readonly bankAccounts: ReadonlyArray<{
                readonly id: string;
                readonly status: BankAccountStatusEnum;
            }>;
        }>;
    } | null;
};
export type ProfileBankAccountsQuery = {
    readonly response: ProfileBankAccountsQueryResponse;
    readonly variables: ProfileBankAccountsQueryVariables;
};



/*
query ProfileBankAccountsQuery {
  viewer {
    profiles {
      id
      __typename
      bankAccounts {
        id
        status
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profiles",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileBankAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileBankAccountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProfileBankAccountsQuery",
    "operationKind": "query",
    "text": "query ProfileBankAccountsQuery {\n  viewer {\n    profiles {\n      id\n      __typename\n      bankAccounts {\n        id\n        status\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '79bc0373770319bfb89c1ffa3208a47e';
export default node;
