import styled from "styled-components"

export const BugrerContainer = styled.button`
  display: none;
  cursor: pointer;
  border: none;
  background: none;
  @media screen and (max-width: 900px) {
    display: flex;
    align-items: center;
    padding: 5px;
  }
`
