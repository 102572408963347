import styled from "styled-components"

import { Box, ArrowLink } from "src/components"

export const ActionContainer = styled(Box)`
  width: 150px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const StyledArrowLink = styled(ArrowLink)`
  color: ${({ theme }) => theme.colors.blue};
`
