import React from "react"

import {
  Box, Label, FormRow, Tooltip, Translate, TextField,
} from "src/components"

class Kpp extends React.Component<any, any> {
  state = {
    kpp: this.props.value,
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state.kpp,
    })
  }

  render() {
    return (
      <FormRow>
        <Box width={260}>
          <Label whiteSpace="pre-line">
            <Translate
              i18n="accreditation.individual.rows.kpp.label"
              ns="components"
            />
            <Tooltip text="tooltips.kpp" />
          </Label>
        </Box>
        <Box width="100%">
          <TextField
            type="text"
            name="kpp"
            value={this.state.kpp}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default Kpp
