import React from "react"

import { useTimer } from "src/hooks"

import { divideTime } from "./utils"
import {
  Container, Header, Description, Value,
} from "./styles"

function Timer(props) {
  const { closingTime } = props
  const time = useTimer(closingTime)

  return (
    <Container>
      <Header>До завершения сбора</Header>
      <Value>{divideTime(time)}</Value>
      <Description>
        <div>дн</div>
        <div>час</div>
        <div>мин</div>
        <div>сек</div>
      </Description>
    </Container>
  )
}

export default Timer
