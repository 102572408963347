import styled, { css } from "styled-components"
import { color, width, height } from "styled-system"

const fillCSS = css`
  & g {
    display: none;
  }

  & g#fill {
    display: inline;
  }
`

const outlineCSS = css`
  & g {
    display: none;
  }

  & g#outline {
    display: inline;
  }
`

const Container = styled.div<any>`
  ${color}
  ${width}
  ${height}
  & > svg {
    display: block;
    fill: currentColor;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  ${({ filled }) => filled && fillCSS}
  ${({ outlined }) => outlined && outlineCSS}
`

export default Container
