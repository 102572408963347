function queryParamsFromSearch(search) {
  const strings = search.slice(1).split("&")

  if (strings.length > 0) {
    return strings.reduce((acc, string) => {
      const [name, value] = string.split("=")
      return { ...acc, [name]: value === undefined ? true : value }
    }, {})
  }

  return {}
}

function matchPath(location, candidate) {
  const { pathname } = location
  const { exact, path } = candidate

  if (typeof path === "string") {
    return exact === true
      ? path === pathname
      : pathname.slice(0, path.length) === candidate
  }

  if (path instanceof RegExp) {
    return path.test(pathname)
  }

  return false
}

function findTitle(location, obj, prefix) {
  if (!obj || typeof obj !== "object") {
    return null
  }

  const { skipPrefix, title } = obj

  if (typeof title === "function") {
    const { search } = location
    const locationObj = {
      ...location,
      queryParams: queryParamsFromSearch(search),
    }

    const newTitle = title(locationObj)
    return skipPrefix ? newTitle : `${prefix} ${newTitle}`
  }

  return skipPrefix ? title : `${prefix} ${title}`
}

export function getNewTitle(location, data) {
  const { config = [], prefix = "", defaultTitle = "" } = data

  const matched = config.find((candidate) => matchPath(location, candidate))
  const title = findTitle(location, matched, prefix)

  return title || prefix + defaultTitle
}
