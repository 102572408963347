/* eslint-disable max-len */

import React, { useState } from "react"
import is from "is_js"
import { withRouter } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"

import { ErrorsContainer } from "src/containers"
import {
  Address,
  Box,
  CheckBoxField,
  FloatingLabelInput,
  Switch,
  Translate,
  SelectInput,
} from "src/components"
import {
  RegisterUser,
  AddEntrepreneurProfile,
  UpdateEntrepreneurProfileBorrowerData,
} from "src/mutations"
import { useEnvironment } from "src/hooks"

import PassportDataField from "../../../../FormBuilder/NewInputs/Passport"

import { getInitialValues, formatStrings } from "./utils"
import {
  Button,
  ButtonContainer,
  Container,
  Form,
  Title,
  RedButton,
} from "../styles"

function CreateTrustedEntrepreneur(props) {
  const {
    isEditable = false,
    user: propsUser,
    profile: propsProfile,
    history,
    createOrder,
  } = props

  const [values, setValues] = useState(
    getInitialValues(propsUser, propsProfile),
  )
  const [isChecked, setChecked] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(
    propsUser && { registerUser: { user: propsUser } },
  )
  const [profile, setProfile] = useState(propsProfile)

  const { environment } = useEnvironment()

  function handleChange(event) {
    const { name, value } = event.target

    setValues({
      ...values,
      [name]: typeof values[name] === "boolean" ? !values[name] : value,
    })
  }

  function handleSelectChange({ name, value }) {
    setValues({
      ...values,
      [name]: value,
    })
  }

  function createUser(inputs) {
    return new Promise((resolve, reject) => {
      const variables = {
        input: {
          email: inputs.email,
        },
      }

      const callbacks = {
        onError: reject,
        onCompleted: (res) => {
          setUser(res)
          resolve(res)
        },
      }

      RegisterUser.commit(environment, variables, null, callbacks)
    })
  }

  function createProfile(inputs, { registerUser }: any) {
    const userId = registerUser?.user?.id || ""

    return new Promise((resolve, reject) => {
      const variables = {
        input: {
          lastName: inputs.lastName,
          firstName: inputs.firstName,
          middleName: inputs.middleName,
          phone: inputs.phone,
          userId,
        },
      }

      const callbacks = {
        onError: reject,
        onCompleted: (res) => {
          setProfile(res)
          resolve(res)
        },
      }

      AddEntrepreneurProfile.commit(environment, variables, null, callbacks)
    })
  }

  function updateProfile(inputs, { addEntrepreneurProfile: res }: any) {
    const { id: profileId } = res.profile

    return new Promise((resolve, reject) => {
      const variables = {
        input: {
          profileId,
          lastName: inputs.lastName,
          firstName: inputs.firstName,
          middleName: inputs.middleName,
          inn: inputs.inn,
          ogrn: inputs.ogrn,
          iian: inputs.iian,
          publicEmail: inputs.publicEmail,

          address: inputs.mainAddress,

          passport: inputs.passport,

          actualAddress: inputs.addressMatch
            ? inputs.mainAddress
            : inputs.otherAddress,

          birthDate: inputs.birthDate,
          birthPlace: inputs.birthPlace,

          isRussiaTaxResident: inputs.isRussiaTaxResident,

          sex: inputs.sex,
        },
      }

      const callbacks = {
        onError: reject,
        onCompleted: resolve,
      }

      UpdateEntrepreneurProfileBorrowerData.commit(
        environment,
        variables,
        null,
        callbacks,
      )
    })
  }

  function getError(path) {
    const error = props.getError(path)

    if (is.not.existy(error)) return null
    return <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />
  }

  function handleError(error) {
    const { setErrors } = props

    const errorMessage = error.errors?.[0]?.message || "Ошибка"
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })

    setErrors(error)
    setLoading(false)
  }

  function handleComplete({ updateEntrepreneurProfileBorrowerData: res }) {
    const { id } = res.profile

    setLoading(false)
    history.push(`/trusted/${id}/info`)
  }

  function handleSubmit(event) {
    event.preventDefault()

    setLoading(true)
    const formatted = formatStrings(values)

    if (isEditable) {
      const { id: userId } = propsProfile

      updateProfile(formatted, {
        addEntrepreneurProfile: { profile: { id: userId } },
      })
        .then(handleComplete)
        .catch(handleError)

      return
    }

    if (profile) {
      updateProfile(formatted, profile).then(handleComplete).catch(handleError)

      return
    }

    if (user) {
      createProfile(formatted, user)
        .then((res) => updateProfile(formatted, res))
        .then(handleComplete)
        .catch(handleError)

      return
    }

    createUser(formatted)
      .then((res) => createProfile(formatted, res))
      .then((res) => updateProfile(formatted, res))
      .then(handleComplete)
      .catch(handleError)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Container>
        <Title>Анкетные данные</Title>
        <FloatingLabelInput
          value={values.lastName}
          onChange={handleChange}
          name="lastName"
          label="Фамилия"
          required
          error={getError("addEntrepreneurProfile.lastName")}
          hasError={getError("addEntrepreneurProfile.lastName")}
        />
        <FloatingLabelInput
          value={values.firstName}
          onChange={handleChange}
          name="firstName"
          label="Имя"
          required
          error={getError("addEntrepreneurProfile.firstName")}
          hasError={getError("addEntrepreneurProfile.firstName")}
        />
        <FloatingLabelInput
          value={values.middleName}
          onChange={handleChange}
          name="middleName"
          label="Отчество"
          error={getError("addEntrepreneurProfile.middleName")}
          hasError={getError("addEntrepreneurProfile.middleName")}
        />
        <SelectInput
          value={values.sex}
          onChange={handleSelectChange}
          name="sex"
          label="components:account.accreditation_info.sex.title"
          options={[
            {
              value: "MALE",
              label: "components:account.accreditation_info.sex.MALE",
            },
            {
              value: "FEMALE",
              label: "components:account.accreditation_info.sex.FEMALE",
            },
          ]}
        />
        <FloatingLabelInput
          value={values.phone}
          onChange={handleChange}
          name="phone"
          label="Телефон"
          mask="+79999999999"
          required
          error={getError("addEntrepreneurProfile.phone")}
          hasError={getError("addEntrepreneurProfile.phone")}
        />
        <FloatingLabelInput
          value={values.email}
          onChange={handleChange}
          name="email"
          label="Email"
          required
          disabled={propsUser}
          error={getError("registerUser.email")}
          hasError={getError("registerUser.email")}
        />
        <FloatingLabelInput
          value={values.publicEmail}
          onChange={handleChange}
          name="publicEmail"
          label="Публичный Email"
        />
        <FloatingLabelInput
          value={values.inn}
          onChange={handleChange}
          name="inn"
          label="ИНН"
          required
          mask="999999999999"
          error={getError("addEntrepreneurProfile.inn")}
          hasError={getError("addEntrepreneurProfile.inn")}
        />
        <FloatingLabelInput
          value={values.ogrn}
          onChange={handleChange}
          name="ogrn"
          label="ОГРНИП"
          mask="9999999999999"
        />
        <FloatingLabelInput
          value={values.iian}
          onChange={handleChange}
          name="iian"
          label="СНИЛС"
          mask="99999999999"
        />
        <FloatingLabelInput
          value={values.birthDate}
          onChange={handleChange}
          type="text"
          name="birthDate"
          mask="99-99-9999"
          label="components:individual_profile_form.placeholders.birthDate"
        />
        <FloatingLabelInput
          value={values.birthPlace}
          onChange={handleChange}
          type="text"
          name="birthPlace"
          label="components:individual_profile_form.placeholders.birthPlace"
        />

        <Title>Данные о бизнесе</Title>
        <Address.autoSuggest
          value={values.mainAddress}
          onChange={handleSelectChange}
          name="mainAddress"
          label="Юридический адрес организации"
        />

        <Title>
          Фактический адрес органицации совпадает юридическим адрессом
          организации, указанным в ЕГРЮЛ?
        </Title>
        <Box mb={20}>
          <Switch
            variant="tablet"
            name="addressMatch"
            value={values.addressMatch}
            onChange={handleChange}
          />
        </Box>
        <Address.autoSuggest
          disabled={values.addressMatch}
          name="otherAddress"
          value={values.otherAddress}
          onChange={handleSelectChange}
          label="Иной адрес"
        />

        <Title>Сканы паспорта</Title>
        <PassportDataField
          name="passport"
          value={values.passport}
          onChange={handleSelectChange}
          environment={environment}
          withoutHeader
          withScans
        />
        <Title>Является ли налоговым резидентом Российской Федерации?</Title>
        <Switch
          name="isRussiaTaxResident"
          value={values.isRussiaTaxResident}
          onChange={handleChange}
          variant="tablet"
        />
      </Container>

      <Box mt={60}>
        <CheckBoxField
          value={isChecked}
          onChange={() => setChecked(!isChecked)}
          color="#4a4a4a"
          fontSize="18px"
          fontWeight="bold"
          alignItems="flex-start"
          label={
            "Настоящим я подтверждаю, что вся введенная выше информация соответствует действительности и проверена мной лично."
          }
        />
      </Box>

      <ButtonContainer>
        <Button disabled={!isChecked || isLoading} type="submit">
          {isEditable && "Сохранить"}
          {!isEditable && "Создать"}
        </Button>

        {propsProfile?.user?.email && createOrder && (
          <RedButton onClick={createOrder} type="button">
            Создать заявку
          </RedButton>
        )}
      </ButtonContainer>
    </Form>
  )
}

function Render(props) {
  return (
    <>
      <ToastContainer autoClose={4000} hideProgressBar={true} />
      <ErrorsContainer>
        {({ getError, setErrors, clearError }) => (
          <CreateTrustedEntrepreneur
            {...props}
            getError={getError}
            setErrors={setErrors}
            clearError={clearError}
          />
        )}
      </ErrorsContainer>
    </>
  )
}

export default withRouter(Render)
