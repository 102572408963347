import React from "react"

import {
  Box, Text, Flex, Button, IconClose, Translate,
} from "src/components"

import { ExpertAvatar, ConclusionContent } from "../styles"

import {
  CloseButton,
  ItemContainer,
  ModalContainer,
  ExperienceContainer,
} from "./styles"

const urlify = (text) => {
  if (!text) return text

  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(
    urlRegex,
    (url) => `<a target="_blank" href="${url}">${url}</a>`,
  )
}

const Experience = ({ experience }) => {
  if (!experience) return null

  const markup = { __html: urlify(experience) }

  return (
    <ExperienceContainer mt="5px">
      <Text
        lineHeight="1.5"
        color="greyDarker"
        whiteSpace="pre-line"
        dangerouslySetInnerHTML={markup}
      />
    </ExperienceContainer>
  )
}

const ExpertDescription = (props) => {
  const {
    name, about, avatar, isKarma, onClick,
  } = props

  const defaultImage = isKarma ? "/images/karma.svg" : "/images/user.svg"
  const avatarUrl = avatar ? avatar.url : defaultImage

  return (
    <Flex mb="30px">
      <ExpertAvatar src={avatarUrl} />
      <Box ml="25px" width="350px">
        <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
          {name}
        </Text>
        <Experience experience={about} />
        <Button variant="empty" onClick={onClick}>
          <Text color="blue" lineHeight="1.5">
            <Translate i18n="components:account.order_item.conclusion.more" />
          </Text>
        </Button>
      </Box>
    </Flex>
  )
}

const ExpertDescriptionModal = (props) => {
  const {
    name, about, avatar, isKarma, onClose, experience,
  } = props

  const defaultImage = isKarma ? "/images/karma.svg" : "/images/user.svg"
  const avatarUrl = avatar ? avatar.url : defaultImage

  return (
    <ModalContainer>
      <ExpertAvatar src={avatarUrl} />
      <Box ml="25px" width="350px">
        <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
          {name}
        </Text>
        <Experience experience={about} />
        <Experience experience={experience} />
      </Box>
      <CloseButton variant="empty" onClick={onClose}>
        <IconClose />
      </CloseButton>
    </ModalContainer>
  )
}

const ConclusionBody = ({ children }) => (
  <>
    <Box mb="15px">
      <Text fontSize="18px" fontWeight="500" lineHeight="1.33">
        <Translate i18n="components:account.order_item.conclusion.title" />
      </Text>
    </Box>
    <ConclusionContent>{children}</ConclusionContent>
  </>
)

const ConclusionItem = ({ conclusion: conclusionItem }) => {
  const {
    opinion,
    isKarma,
    expert: {
      name, about, avatar, experience,
    },
  } = conclusionItem

  const [shouldShowInfo, setShouldShowInfo] = React.useState(false)
  const showInfo = React.useCallback(() => setShouldShowInfo(true), [])
  const hideInfo = React.useCallback(() => setShouldShowInfo(false), [])

  return (
    <ItemContainer>
      <ExpertDescription
        name={name}
        isKarma={isKarma}
        onClick={showInfo}
        avatar={avatar}
        about={about}
      />
      {shouldShowInfo && (
        <ExpertDescriptionModal
          name={name}
          isKarma={isKarma}
          onClose={hideInfo}
          avatar={avatar}
          experience={experience}
          about={about}
        />
      )}
      <ConclusionBody>{opinion}</ConclusionBody>
    </ItemContainer>
  )
}

export default ConclusionItem
