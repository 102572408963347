import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateOrderApplicationMutation(
    $input: UpdateOrderApplicationInput!
  ) {
    updateOrderApplication(input: $input) {
      order {
        id
        profile {
          id
          name
          creditRating {
            rating
          }
        }
        chain {
          id
          gatheredAmount
          investorsCount
        }
        offer {
          attachments {
            id
            url
            filename
          }
          data
        }
        application {
          attachments {
            id
            url
            filename
          }
          data
        }
        creditRating {
          rating
        }
      }
    }
  }
`

export default enhance({ mutation })
