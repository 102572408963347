import React from "react"

import {
  OwnerProposalSpecification,
  InvestorProposalSpecification,
} from "./Specifications"

function ProposalSpecification(props) {
  const { data, isOwner, history } = props

  return (
    <>
      {isOwner && <OwnerProposalSpecification data={data} />}
      {!isOwner && (
        <InvestorProposalSpecification history={history} data={data} />
      )}
    </>
  )
}

export default ProposalSpecification
