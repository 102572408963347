import React from "react"

import { VirtualAccountActions } from "src/components"

import Error from "./Error"
import Amount from "./Amount"
import Confirmation from "./Confirmation"
import Success from "./Success"

export default {
  error: {
    component: Error,
    next: "amount",
  },
  amount: {
    component: Amount,
    next: "confirmation",
  },
  success: {
    component: Success,
    next: null,
  },
  confirmation: {
    component: Confirmation,
    next: "success",
  },
  deposit: {
    component: (props) => <VirtualAccountActions.Deposit.Modal {...props} />,
    next: null,
  },
  guest: {
    next: null,
  },
}
