import i18n from "src/i18n"
import React from "react"

import { Box, Modal, RedirectButton } from "src/components"

const render = (props) => (
  <Modal.Basic onClose={props.onClose}>
    <Box mb={20}>
      {i18n.t(
        "components:user-page-header.modals.create-legal-entity-profile.description",
      )}
    </Box>
    <Box mb={20}>
      <RedirectButton to="/profiles/create/juristic" onClick={props.onClose}>
        {i18n.t(
          "components:user-page-header.modals.create-legal-entity-profile.button",
        )}
      </RedirectButton>
    </Box>
  </Modal.Basic>
)

export default render
