import memoize from "memoize-one"

import { pipe, freezeMap } from "./utils"

import {
  make,
  Loan,
  Order,
  MockOrder,
  MobileOrder,
  Trusted,
  Proposal,
  Investment,
  GuestOrder,
  CompletedOrder,
  MobileCompletedOrder,
  CompletedGuestOrder,
  MockCompletedOrder,
  MobileMockOrder,
  MobileMockCompletedOrder,
  RepaymentOrder,
  MockRepaymentOrder,
  MobileRepaymentOrder,
  MobileMockRepaymentOrder,
} from "./entities"

import provideQuery from "./queryProvider"
import provideData from "./dataProvider"

const orderFactory = () => {
  const schema = new Map<any, any>([
    ["Loan", { ...Loan }],
    ["Order", { ...Order }],
    ["MobileOrder", { ...MobileOrder }],
    ["MockOrder", { ...MockOrder }],
    ["MockCompletedOrder", { ...MockCompletedOrder }],
    ["MobileMockOrder", { ...MobileMockOrder }],
    ["MobileMockCompletedOrder", { ...MobileMockCompletedOrder }],
    ["Trusted", { ...Trusted }],
    ["Proposal", { ...Proposal }],
    ["Investment", { ...Investment }],
    ["GuestOrder", { ...GuestOrder }],
    ["CompletedOrder", { ...CompletedOrder }],
    ["MobileCompletedOrder", { ...MobileCompletedOrder }],
    ["CompletedGuestOrder", { ...CompletedGuestOrder }],
    ["RepaymentOrder", { ...RepaymentOrder }],
    ["MockRepaymentOrder", { ...MockRepaymentOrder }],
    ["MobileRepaymentOrder", { ...MobileRepaymentOrder }],
    ["MobileMockRepaymentOrder", { ...MobileMockRepaymentOrder }],
  ])

  freezeMap(schema)

  // @consumerType:
  // "Market"
  // | "Loans
  // | "Trusted"
  // | "Proposal"
  // | "Investments"
  // | "CompletedMarket"
  // => React.Component
  const to = memoize((target, withFetch = true) => (entity) => {
    if (withFetch) {
      return provideQuery({ target, entity })
    }
    return provideData({ entity })
  })

  // @orderType:
  // "Investment"
  // | "Loan"
  // | "Order"
  // | "Proposal"
  // | "Trusted"
  // | "CompletedOrder"
  // => {head, body, footer, container}
  const ensure = memoize((orderType, viewType, isCession = false) => () =>
    make(schema.get(orderType), orderType, viewType, isCession))
  // should provide fetch entity api || orderDataProvider
  const using = (data) => (queriedEntity) => queriedEntity(data)

  const createEntity = ({
    type,
    target,
    data,
    withFetch,
    viewType,
    isCession,
  }: any) => pipe(ensure(type, viewType, isCession), to(target, withFetch), using(data))

  const getEntity = (orderType: string, viewType: string, isCession: boolean) =>
    make(schema.get(orderType), orderType, viewType, isCession)

  return Object.freeze({ createEntity, getEntity })
}

const initFactory = () => {
  const { createEntity, getEntity } = orderFactory()

  return Object.freeze({
    createMock: (data, viewType, isCession?: any) => createEntity({
      type: "MockOrder",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    createCompletedMock: (data, viewType, isCession?: any) => createEntity({
      type: "MockCompletedOrder",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    createRepaymentMock: (data, viewType, isCession?: any) => createEntity({
      type: "MockRepaymentOrder",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    createLoan: (data) => createEntity({ type: "Loan", target: "Loan", data }),
    createOrder: (data, viewType, isCession) => createEntity({
      type: "Order",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    tableOrder: getEntity("Order", "Table", false),
    tableOrderCession: getEntity("Order", "Table", true),
    createMobileOrder: (data, viewType, isCession) => createEntity({
      type: "MobileOrder",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    mobileOrder: getEntity("MobileOrder", "MobileCard", false),
    mobileOrderCession: getEntity("MobileOrder", "MobileCard", true),
    createMobileCompletedOrder: (data, viewType) => createEntity({
      type: "MobileCompletedOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),
    createMobileRepaymentOrder: (data, viewType) => createEntity({
      type: "MobileRepaymentOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),

    createMobileMock: (data, viewType, isCession) => createEntity({
      type: "MobileMockOrder",
      target: "Market",
      withFetch: false,
      data,
      viewType,
      isCession,
    }),
    createMobileCompletedMock: (data, viewType) => createEntity({
      type: "MobileMockCompletedOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),
    createMobileRepaymentMock: (data, viewType) => createEntity({
      type: "MobileMockRepaymentOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),
    createTrusted: (data) => createEntity({ type: "Trusted", target: "Trusted", data }),
    createProposal: (data) => createEntity({ type: "Proposal", target: "Proposal", data }),
    createGuestOrder: (data) => createEntity({ type: "GuestOrder", target: "Guest", data }),
    createInvestment: (data) => createEntity({ type: "Investment", target: "Investments", data }),
    createCompletedOrder: (data, viewType) => createEntity({
      type: "CompletedOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),
    createRepaymentOrder: (data, viewType) => createEntity({
      type: "RepaymentOrder",
      target: "MarketCompleted",
      withFetch: false,
      data,
      viewType,
    }),
    createCompletedGuestOrder: (data) => createEntity({
      type: "CompletedGuestOrder",
      target: "GuestCompleted",
      data,
    }),
  })
}

export default initFactory()

/*
 * exampleUsage:
 * import {OrderFactory} from "/components"
 *
 * const render = props => {
 *   //  @data: any - will be applied to generated component as input props
 *   //  createLoan(data)
 *   //  createOrder(data)
 *   //  createTrusted(data)
 *   //  createProposal(data)
 *   //  createInvestment(data)
 *
 *   const OrderItem = OrderFactory.createOrder(data)
 *
 *   return (
 *     OrderItem
 *   )
 * }
 *
 * export default render
 * */
