/* eslint-disable max-len */

import React from "react"

function Diagram(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.618726 10.458C0.276666 10.458 0 10.7347 0 11.0767V22.6313C0 22.9734 0.276666 23.25 0.618726 23.25H6.26774C6.6098 23.25 6.88647 22.9734 6.88647 22.6313V11.0767C6.88647 10.7347 6.6098 10.458 6.26774 10.458H0.618726ZM5.64902 22.0126H1.23242V11.6904H5.64902V22.0126Z" />
      <path d="M8.83801 0.618726V22.6313C8.83801 22.9733 9.11468 23.25 9.45674 23.25H15.1058C15.4478 23.25 15.7245 22.9733 15.7245 22.6313V0.618726C15.7245 0.276666 15.4478 0 15.1058 0H9.45171C9.11468 0.00503029 8.83801 0.276666 8.83801 0.618726ZM10.0704 1.23745H14.487V22.0176H10.0704V1.23745Z" />
      <path d="M23.9388 23.2451C24.2808 23.2451 24.5575 22.9684 24.5575 22.6263V7.80208C24.5575 7.46002 24.2808 7.18335 23.9388 7.18335H18.2897C17.9477 7.18335 17.671 7.46002 17.671 7.80208V22.6314C17.671 22.9734 17.9477 23.2501 18.2897 23.2501H23.9388V23.2451ZM18.9085 8.4208H23.3251V22.0177H18.9085V8.4208Z" />
    </svg>
  )
}

Diagram.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Diagram
