import React from "react"

import { Translate, MiniLoader } from "src/components"

import {
  MobileInformationCompany,
  Statusbar,
} from "../Elements"

import {
  MobileWrapper as Container,
  MobileItemContainer,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  MobileGoalItem,
  MobileGoalValue,
  MobileGoalBar,
  CardCover,
  FlipContainer,
  Flipper,
  FlipperFront,
} from "./styles"

function Info() {
  return (
    <CardCover>
      <MiniLoader changed fontSize="8px" margin="auto" />
    </CardCover>
  )
}

function MobileItem(props) {
  const { isCession } = props

  return (
    <MobileItemContainer minHeight={300}>
      <FlipContainer isClicked={false}>
        <Flipper>
          <FlipperFront minHeight={300}>
            <HeaderWrap>
              <MobileHeaderContainer>
                <MobileInformationCompany name="_" />
              </MobileHeaderContainer>
            </HeaderWrap>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rating"} />
                </MobileInformationName>
                <MobileInformationValue>_</MobileInformationValue>
              </MobileInformationItem>

              {!isCession && (
                <>
                  <MobileInformationItem>
                    <MobileInformationName>
                      <Translate i18n={"models:loan.rate"} />
                    </MobileInformationName>
                    <MobileInformationValue>0%</MobileInformationValue>
                  </MobileInformationItem>
                  <MobileInformationItem>
                    <MobileInformationName>
                      <Translate i18n={"models:loan.duration"} />
                    </MobileInformationName>
                    <MobileInformationValue>
                      0 <Translate i18n={"models:loan.month"} />
                    </MobileInformationValue>
                  </MobileInformationItem>
                </>
              )}

              {isCession && (
                <>
                  <MobileInformationItem>
                    <MobileInformationName>Цена</MobileInformationName>
                    <MobileInformationValue>0%</MobileInformationValue>
                  </MobileInformationItem>
                  <MobileInformationItem>
                    <MobileInformationName>Доходность</MobileInformationName>
                    <MobileInformationValue>0%</MobileInformationValue>
                  </MobileInformationItem>
                </>
              )}
            </MobileInformationContainer>

            <MobileGoalItem>
              <MobileGoalValue>
                <span>Собрано</span>
                <span>
                  {!isCession && "0М из 0М"}
                  {isCession && "0M"}
                </span>
              </MobileGoalValue>
              <MobileGoalBar>
                <Statusbar value={0} />
              </MobileGoalBar>
            </MobileGoalItem>
          </FlipperFront>
        </Flipper>
      </FlipContainer>
    </MobileItemContainer>
  )
}

export default {
  Container,
  Info,
  Body: MobileItem,
}
