import React, { useState } from "react"
import Relay, { graphql } from "react-relay"

import {
  AttachmentFileList,
  SupportingDocumentsConfirmationModal,
} from "src/components"
import { userAgreementUrl } from "src/constants"

import {
  Container,
  Title,
  Description,
  Link,
  ButtonContainer,
  UploadButton,
  Download,
  SubmitButton,
  AttachmentTitle,
  StatusContainer,
  Status,
  Check,
} from "./styles"

const MAX_FILE_SIZE = 10485760 // 10 Mb

function getModerationStatus(supportingDocuments = []) {
  const documents = supportingDocuments.filter(
    (doc) => doc.status === "PENDING",
  )
  return documents.length > 0
}

function ProfileInvestorAccreditation(props) {
  const { openModal, profile } = props
  const { supportingDocuments = [] } = profile

  const [files, setFiles] = useState([])
  const [isOpened, setOpened] = useState(false)
  const [onModeration, setModeration] = useState(
    getModerationStatus(supportingDocuments),
  )

  const hasFiles = files.length > 0

  function handleChange(event) {
    const fileList = Array.from(event.target.files)
    const newFiles = fileList.filter((file: File) => file.size <= MAX_FILE_SIZE)

    setFiles([...files, ...newFiles])
  }

  function handleDelete(idx) {
    const newFiles = files.slice()
    newFiles.splice(idx, 1)
    setFiles(newFiles)
  }

  function handleClose(isSuccessful) {
    setOpened(false)
    setFiles([])

    if (isSuccessful) {
      setModeration(true)
    }
  }

  return (
    <Container>
      <Title isAlert={!onModeration}>
        Подтверждение статуса квалифицированного инвестора
      </Title>

      {!onModeration && (
        <>
          {!hasFiles && (
            <Description>
              <Link onClick={() => window.open(userAgreementUrl, "_blank")}>Правилами платформы</Link> {" "}
              установлено ограничение, по которому физ. лица, не являющиеся квалифицированными инвесторами, {" "}
              не могут инвестировать.
              Загрузите подтверждение наличия квалификации или аккредитуйтесь
              как ИП.
              <Link onClick={openModal}>Подробнее</Link>
            </Description>
          )}

          {hasFiles && (
            <>
              <AttachmentTitle>Загруженные документы</AttachmentTitle>
              <AttachmentFileList files={files} onDelete={handleDelete} />
            </>
          )}

          <ButtonContainer>
            <UploadButton compact={hasFiles}>
              загрузить
              <Download />
              <input type="file" multiple onChange={handleChange} />
            </UploadButton>

            {hasFiles && (
              <SubmitButton onClick={() => setOpened(true)}>
                отправить
              </SubmitButton>
            )}
          </ButtonContainer>
        </>
      )}

      {onModeration && (
        <>
          <StatusContainer>
            <Check />
            <Status>Документы отправлены!</Status>
          </StatusContainer>

          <Description>
            Проверка может занять 1-2 рабочих дня. При корректности
            предоставленных документов, вам будет автоматически открыта функция
            инвестирования свыше 600 тыс. в год.
          </Description>
        </>
      )}

      {hasFiles && (
        <SupportingDocumentsConfirmationModal
          isOpened={isOpened}
          onClose={handleClose}
          profileId={profile.id}
          files={files}
        />
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfileInvestorAccreditation, {
  profile: graphql`
    fragment ProfileInvestorAccreditation_profile on UserProfile {
      id
      __typename
      ... on IndividualProfile {
        investor {
          investedAmount
          qualified
        }
        supportingDocuments {
          id
          status
        }
      }
    }
  `,
})
