import React from "react"

import { VirtualAccountActions } from "src/components"
import ErrorModal from "./ErrorModal"
import AmountModal from "./AmountModal"
import Confirmation from "./Confirmation"
import SuccessModal from "./SuccessModal"

export default {
  error: {
    component: ErrorModal,
    next: "amount",
  },
  amount: {
    component: AmountModal,
    next: "confirmation",
  },
  success: {
    component: SuccessModal,
    next: null,
  },
  confirmation: {
    component: Confirmation,
    next: "success",
  },
  deposit: {
    component: (props) => <VirtualAccountActions.Deposit.Modal {...props} />,
    next: null,
  },
  guest: {
    next: null,
  },
}
