import React from "react"
import { graphql, fetchQuery } from "relay-runtime"

import { RelayEnvironmentConsumer } from "src/context"

import {
  CreateIndividualProfile,
  ApproveIndividualProfile,
  ApproveLegalEntityProfile,
  CreateLegalEntityProfile,
} from "src/components/organisms/SiteHeader/User/Elements/modals"

import getStatusFromQuery from "./utils"

const query = graphql`
  query ViewerAccessContainerQuery {
    viewer {
      country
      foreignProfiles {
        id
        __typename
        firstName
        lastName
        investorAccreditation: accreditation(role: INVESTOR) {
          status
        }
      }
      profiles {
        id
        name
        __typename
        investor {
          canBeInvestor
        }
        canBeBorrower
        investorAccreditation: accreditation(role: INVESTOR) {
          status
        }
        borrowerAccreditation: accreditation(role: BORROWER) {
          status
        }
      }
    }
  }
`

const withModal = ({ mode, role }) => role && mode === "withModal"

const InvestorModalsByStatus = new Map([
  ["APPROVED", null],
  ["PENDING", ApproveIndividualProfile],
  ["DECLINED", ApproveIndividualProfile],
  ["INITIAL", ApproveIndividualProfile],
  ["EMPTY", CreateIndividualProfile],
])

const borrowerModalsByStatus = new Map([
  ["APPROVED", null],
  ["PENDING", ApproveLegalEntityProfile],
  ["DECLINED", ApproveLegalEntityProfile],
  ["INITIAL", ApproveLegalEntityProfile],
  ["MIXED", ApproveLegalEntityProfile],
  ["EMPTY", CreateLegalEntityProfile],
])

const ModalsSchema = new Map([
  ["investor", InvestorModalsByStatus],
  ["borrower", borrowerModalsByStatus],
])

class Container extends React.PureComponent<any, any> {
  state = {
    modal: null,
    busy: false,
    isLoading: false,
    viewerData: null,
  }

  resetState = () => {
    this.setState(() => ({
      modal: null,
      viewerData: null,
      isLoading: false,
    }))
  }

  componentDidMount() {
    this.resetState()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  showModal = (modal, deniedCallback) => {
    this.setState(
      () => ({ modal }),
      () => {
        if (deniedCallback) {
          deniedCallback()
        }

        this.triggerCallback("onModalOpen")
      },
    )
  }

  processWith = (successCallback, deniedCallback) => () => {
    if (!withModal((this as any).props)) {
      return
    }

    const roleData = this.state.viewerData[this.props.role]
    const roleModals = ModalsSchema.get(this.props.role)
    const modal = roleModals.get(roleData.status)

    if (!modal && successCallback) {
      successCallback(this.state.viewerData)
    } else {
      this.showModal(modal, deniedCallback)
    }
  }

  checkAccess = async (successCallback, deniedCallback) => {
    this.setState(() => ({ isLoading: true }))

    const viewerData = getStatusFromQuery(
      await fetchQuery(this.props.environment, query, {}),
    )

    this.setState(
      () => ({ viewerData, isLoading: false }),
      this.processWith(successCallback, deniedCallback),
    )

    return viewerData
  }

  onModalClose = () => {
    this.setState(() => ({ modal: null }), this.triggerCallback("onModalClose"))
  }

  triggerCallback = (type) => () => {
    const callback = this.props[type]

    if (callback && typeof callback === "function") {
      callback()
    }
  }

  render() {
    return (
      <>
        {(this as any).props.children({
          checkAccess: this.checkAccess,
          isLoading: this.state.isLoading,
        })}
        {this.state.modal ? (
          <this.state.modal
            user={this.state.viewerData[this.props.role]}
            onClose={this.onModalClose}
          />
        ) : null}
      </>
    )
  }
}

(Container as any).defaultProps = {
  role: "investor",
};

(Container as any).defaultProps = {
  mode: "withModal",
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {(renderProps) => <Container {...props} {...renderProps} />}
  </RelayEnvironmentConsumer>
)

/*
 *
 * 1: Компонент предоставляет информацию о ролях пользователя
 * 2: Компонент предоставляет информацию о профилях пользователя
 * 3: Управление инициализацией идет снаружи, компонент предоставляет только публичное API
 * 4: Компонент должен показывать модальные окна в зависимости от парамтера type
 *
 * // APPROVED - profiles.length >=1 && profile.[role]accreditationStatus === APPROVED
 * // MIXED - profiles.length >=1 && profile.[role]accreditationStatus === PENDING && profile.[role]accreditationStatus === DECLINED
 * // PENDING - profiles.length === 1 && profile.[role]accreditationStatus === PENDING
 * // DECLINED - profiles.length === 1 && profile.[role]accreditationStatus === DECLINED
 * // INITIAL - profiles.length === 1 && profile.[role]accreditationStatue === null
 * // EMPTY - profiles.length === 0
 *
 * enum EStatus = APPROVED | PENDING | DECLINED | INITIAL | EMPTY
 *
 * interface IProfile  {
 *   borrowerAccreditation: {
 *     status: string | null
 *   }
 *   id: string
 *   investorAccreditation: {
 *     status: string | null
 *   }
 *   name: string
 *   __typename: string
 * }
 *
 * interface IRoleData {
 *   status: EStatus,
 *   profiles: [IProfile],
 * }
 *
 * interface IAccess = {
 *   investor: IRoleData,
 *   borrower IRoleData
 * }
 *
 * enum ERole = "investor" | "borrower"
 * enum EMode = "default" | "withModal"
 *
 * interface IProps {
 *   role: ?ERole,
 *   mode: EMode
 * }
 *
 * <ViewerAccessContainer props:IProps>
 *   {
 *     ({checkAccess: Promise<IAccess>})
 *   }
 * </ViewerAccessContainer>
 *
 * */
