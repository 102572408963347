/* eslint-disable import/prefer-default-export */

import moment from "moment"
import i18next from "i18next"

export const formatDate = (date, format = "DD MMMM YYYY") => {
  const { language } = i18next
  const momentDate = moment(date)

  return momentDate.isValid()
    ? momentDate.locale(language).format(format)
    : null
}

export const formatDateShort = (date, format = "DD MMM YYYY") => {
  const { language } = i18next
  const momentDate = moment(date)

  return momentDate.isValid()
    ? momentDate.locale(language).format(format)
    : null
}

export const formatDayMonth = (date, format = "DD MMM") => {
  const { language } = i18next
  const momentDate = moment(date)

  return momentDate.isValid()
    ? momentDate.locale(language).format(format)
    : null
}
