import React from "react"

import { number, getGatheredPercents } from "src/utils"
import { Box, Translate } from "src/components"

import { StyledText, StyledTitle } from "./styles"

export const CessionOrderBody = ({ order }) => {
  const { investorsCount } = order.chain

  return (
    <Box>
      <StyledTitle variant="white">
        <Translate i18n="components:order_list.completed_order.labels.investors_title" />
      </StyledTitle>
      <StyledText>
        <Translate
          i18n="components:order_list.completed_order.labels.investors_count"
          count={investorsCount}
        />
      </StyledText>
    </Box>
  )
}

export const InitialOrderBody = ({ order }) => {
  const { chain, application } = order

  const { gatheredAmount } = chain
  const { minValue } = application.data
  const percents = getGatheredPercents(minValue, gatheredAmount)

  return (
    <>
      <Box>
        <StyledTitle variant="white">
          <Translate i18n="components:order_list.completed_order.labels.gathered" />
        </StyledTitle>
        <StyledText>{number(gatheredAmount)} ₽</StyledText>
      </Box>
      <Box ml="auto">
        <StyledTitle variant="white">
          <Translate i18n="components:order_list.completed_order.labels.target" />
        </StyledTitle>
        <StyledText>{percents}%</StyledText>
      </Box>
    </>
  )
}
