/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileInfo_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly investor?: {
        readonly investedAmount: number;
        readonly qualified: boolean;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"ProfileCash_balance" | "ProfileCash_profile" | "ProfileMenu_profile" | "ProfileInvestorAccreditation_profile">;
    readonly " $refType": "ProfileInfo_profile";
};
export type ProfileInfo_profile$data = ProfileInfo_profile;
export type ProfileInfo_profile$key = {
    readonly " $data"?: ProfileInfo_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileInfo_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileInfo_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualProfileInvestor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "investedAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "qualified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileCash_balance"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileCash_profile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileMenu_profile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileInvestorAccreditation_profile"
    }
  ],
  "type": "UserProfile"
};
(node as any).hash = '7aaec821b92e53d8712412e2d25ed8b5';
export default node;
