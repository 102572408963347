/* TODO: unused - вернуть позже или удалить окончательно
import React from "react"
import styled from "styled-components"

import { Translate } from "src/components"

const Link = styled.a`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: underline;
`

  const IsPublicOfficialLink = "https://docs.google.com/document/d/1sHdCI43NHFdEx50SfcT5ITwhvhpYshX5Bm1GUqJGouc/edit"
  const IsPublicOfficialExtra = () => (
  <React.Fragment>
    <Translate i18n="components:individual_profile_form.extras.isPublicOfficial.content" />
    <Link href={IsPublicOfficialLink} target="_blank">
      <Translate i18n="components:individual_profile_form.extras.isPublicOfficial.link" />
    </Link>
  </React.Fragment>
) */

export default {
  groups: [
    {
      title: "individual_profile_form.titles.profile",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "text",
              name: "lastName",
              label: "components:individual_profile_form.placeholders.lastName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "firstName",
              label: "components:individual_profile_form.placeholders.firstName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "middleName",
              label: "components:individual_profile_form.placeholders.middleName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "select",
              name: "sex",
              label: "components:account.accreditation_info.sex.title",
              options: [
                {
                  value: "MALE",
                  label: "components:account.accreditation_info.sex.MALE",
                },
                {
                  value: "FEMALE",
                  label: "components:account.accreditation_info.sex.FEMALE",
                },
              ],
            },
            {
              type: "phone",
              name: "phone",
              staticPrefix: "+",
              label: "components:individual_profile_form.placeholders.phone",
            },
            {
              type: "text",
              name: "inn",
              mask: "999999999999",
              label: "components:individual_profile_form.placeholders.inn",
            },
            {
              type: "text",
              name: "iian",
              mask: "999 - 999 - 999 - 99",
              label: "components:individual_profile_form.placeholders.iian",
            },
            {
              type: "text",
              name: "birthDate",
              width: "214px",
              mask: "99 - 99 - 9999",
              label:
                "components:individual_profile_form.placeholders.birthDate",
            },
            {
              type: "text",
              name: "birthPlace",
              label:
                "components:individual_profile_form.placeholders.birthPlace",
            },
          ],
        },
      ],
    },
    {
      title: "individual_profile_form.titles.passport.header",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "passport",
              name: "passport",
              withScans: true,
            },
          ],
        },
      ],
    },
    {
      title: "Иные документы",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "supportingDocuments",
              name: "supportingDocuments",
              description: "components:individual_profile_form.extras.supportingDocuments.description",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "address",
              name: "address",
              label: "components:individual_profile_form.titles.registration",
            },
          ],
        },
      ],
    },
    {
      title: "individual_profile_form.titles.isTaxResident",
      rows: [
        {
          width: "100%",
          inputs: [
            {
              type: "switch",
              name: "isRussiaTaxResident",
              margin: 0,
            },
          ],
        },
        {
          width: "100%",
          inputs: [
            {
              type: "checkbox",
              name: "isPublicOfficial",
              initialValue: false,
              label:
                "components:international_profile_form.labels.USACitizenship",
            },
          ],
        },
      ],
    },
    {
      title: "components:account.bank_account.info.requisites",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "bank_autosuggest",
              name: "bankName",
              label: "components:account.bank_account.info.account_info.name",
            },
            {
              type: "bank_autosuggest",
              name: "bic",
              mask: "999999999",
              label: "components:account.bank_account.info.account_info.bic",
            },
            {
              type: "text",
              name: "checkingAccount",
              mask: "99999999999999999999",
              label: "components:account.bank_account.info.account_info.checking_account",
            },
            {
              type: "text",
              name: "correspondentAccount",
              mask: "99999999999999999999",
              label: "components:account.bank_account.info.account_info.correspondent_account",
            },
          ],
        },
      ],
    },
  ],
}
