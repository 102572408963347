import React from "react"
import styled from "styled-components"

const Title = styled.h4`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 800px) {
    font-size: 15px;
    line-height: 19px;
  }
`

const Header = () => <Title>Анкетные данные</Title>

export default Header
