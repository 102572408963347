import React from "react"

import {
  Box, Text, MaskInput,
} from "src/components"
import { Label, Container } from "./styles"

/* eslint-disable  no-useless-escape */
const dateMask = "99 - 99 - 9999"
/* eslint-enable  no-useless-escape */

const Hint = (props) => (
  <Box mt={10}>
    <Text fontSize={0} {...props} />
  </Box>
)

const Error = (props) => <Hint color="red" {...props} />

const renderLabel = ({ label, disabled }) => label && <Label disabled={disabled}>{label}</Label>

const renderHint = ({ hint, error }) => !error && hint && <Hint color="greyDark">{hint}</Hint>

const renderError = ({ error }) => error && <Error>{error}</Error>

export default (props) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hint, label, Component, ...rest
  } = props

  return (
    <Container>
      {renderLabel({ label, ...rest })}
      <MaskInput mask={dateMask} {...rest} />
      {renderHint({ hint, ...rest })}
      {renderError({ ...rest })}
    </Container>
  )
}
