import React from "react"

import { QueryParamsConsumer } from "src/context"
import {
  PageContainer,
  ProposalReturnButton,
  MiniLoader,
  ProposalMenus,
} from "src/components"

import { Container, LoaderWrapper, MockBlock } from "./styles"

import { Divider } from "../ProposalSpecification/Specifications/styles"
import {
  Container as HeaderContainer,
  Info,
  InfoBlockWrapper,
  ImageContainer,
  Image,
  BlockContainer,
} from "../ProposalHeader/styles"
import { InfoBlock } from "../ProposalHeader/Elements"
import { InvestorProposalSpecification } from "../ProposalSpecification/Specifications"

import {
  DESCRIPTION,
  DOCUMENTS,
  EXPERTS,
  RISKS,
  PROVISION,
  PAYMENTS,
} from "../ProposalBody/constants"

const menuItems = [
  {
    title: "описание",
    value: DESCRIPTION,
    isDisabled: false,
  },
  {
    title: "документы",
    value: DOCUMENTS,
    isDisabled: false,
  },
  {
    title: "график платежей",
    value: PAYMENTS,
    isDisabled: false,
  },
  {
    title: "заключение экспертов",
    value: EXPERTS,
    isDisabled: false,
  },
  {
    title: "оценка рисков",
    value: RISKS,
    isDisabled: false,
  },
  {
    title: "обеспечение",
    value: PROVISION,
    isDisabled: false,
  },
]

function ProposalMockLoader(props) {
  const { history, getQueryParam } = props

  const activeTab = getQueryParam("step") || "description"

  const mockData = {
    status: "",
    chain: {
      gatheredAmount: 0,
      investorsCount: 0,
    },
    viewer: {
      investedAmount: 0,
    },
    application: {
      data: {
        minValue: 0,
        maxValue: 0,
      },
    },
    offer: {
      data: {},
    },
  }

  function handleReturn() {
    const source = getQueryParam("source")

    if (source) {
      history.push(`/${source}`)
    } else {
      history.push("/market")
    }
  }

  return (
    <PageContainer>
      <ProposalReturnButton onClick={handleReturn}>Назад</ProposalReturnButton>
      <Container>
        <LoaderWrapper>
          <MiniLoader changed margin="auto" />
        </LoaderWrapper>

        <HeaderContainer>
          <Info>
            <MockBlock width="25%" height="10px" />
            <MockBlock width="50%" height="26px" />
            <MockBlock width="25%" height="10px" />
            <MockBlock width="100%" height="60px" />

            <InfoBlockWrapper>
              <BlockContainer>
                <InfoBlock title="Ставка" value="-" descr="годовых" />
                <InfoBlock
                  url="/"
                  title="Рейтинг"
                  value="-"
                  descr="подробнее"
                />
              </BlockContainer>
              <BlockContainer>
                <InfoBlock title="Срок" value="-" descr="месяцев" />
                <InfoBlock title="Погашение" value="-" descr="с 1-го месяца" />
              </BlockContainer>
            </InfoBlockWrapper>
          </Info>

          <ImageContainer bg={true}>
            <Image src="/images/MockImage.png" />
          </ImageContainer>
        </HeaderContainer>

        <Divider />

        <InvestorProposalSpecification data={mockData} />
        <ProposalMenus menuItems={menuItems} activeTab={activeTab} />
      </Container>
    </PageContainer>
  )
}

export default function Render(props) {
  return (
    <QueryParamsConsumer>
      {({ getQueryParam }) => (
        <ProposalMockLoader {...props} getQueryParam={getQueryParam} />
      )}
    </QueryParamsConsumer>
  )
}
