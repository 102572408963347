import styled from "styled-components"
import { space } from "styled-system"

import {
  Box, Text, Button,
} from "src/components"

export const ModalContainer = styled(Box)`
  display: flex;
  min-width: 965px;
  height: 210px;
  background-color: white;
`

export const CalcContainer = styled(Box)`
  width: 100%;
  padding: 25px;
`

export const ProfitabilityContainer = styled(Box)`
  display: flex;
  min-width: 480px;
  padding: 25px 40px 25px 35px;
  background-color: rgba(243, 246, 250, 0.7);
`

export const Title = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
`

export const AmountButton = styled(Button)`
  ${({ active }) => active && "color: white !important;"}
  ${({ active, theme }) => active && `border-color: ${theme.colors.greyDark} !important;`}
  ${({ active, theme }) => active && `background-color: ${theme.colors.greyDark} !important;`}
`

export const Divider = styled(Box)`
  height: 100%;
  width: 1px;
  margin-left: 35px;
  margin-right: 25px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const ScheduleContainer = styled(Box)`
  padding: 22px 25px;
  border-top: 2px solid ${({ theme }) => theme.colors.greyLight};
`

export const ScheduleButton = styled(Button)`
  margin-right: 10px;
  padding: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
  ${space};
`

export const TablesContainer = styled(Box)`
  margin-top: 48px;
  max-height: 390px;
  overflow-y: auto;
`

export const ArrowContainer = styled.span<any>`
  transform: ${({ opened }) => (opened ? "rotate(180deg)" : "none")};
`
