import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 650px;
`

export const Title = styled.h3`
  position: relative;
  width: 100%;
  margin: 0;
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 19px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Description = styled.p`
  position: relative;
  width: 100%;
  margin: 8px 0 24px;
  font-family: Geometria, sans-serif;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};
`

export const ButtonContainer = styled(Container)`
  margin-top: 40px;
  display: flex;
`

export const Button = styled.button`
  position: relative;
  width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding: 15px 0 13px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :not(:last-of-type) {
    margin-right: 32px;
  }
`

export const PrimaryButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.mainRed};
`
