import React from "react"

import { number } from "src/utils"
import { MIN_INVESTMENT_AMOUNT } from "src/constants"

import {
  Box, Text, Alert, Button, Translate,
} from "src/components"

import {
  Bullet, StyledLink, StyledList, ListItemContainer,
} from "./styles"

export const ListItemHeading = (props) => {
  const { children, number: listItemNumber } = props

  return (
    <ListItemContainer>
      <Bullet>{listItemNumber}</Bullet>
      <Text fontSize="18px" fontWeight="bold" lineHeight="1.56">
        {children}
      </Text>
    </ListItemContainer>
  )
}

export const KarmaRulesAlert = () => (
  <Box width="240px" mr="20px">
    <Alert textAlign="left" p="20px 20px 20px 25px" height="100%">
      <Box mb="5px">
        <Text fontSize="14px" fontWeight="600" lineHeight="1.7">
          <Translate i18n="components:bank_modal.invest.transaction.rules.title" />
        </Text>
      </Box>
      <Text fontSize="14px" lineHeight="1.5">
        <Translate i18n="components:bank_modal.invest.transaction.rules.content" />
      </Text>
    </Alert>
  </Box>
)

const options = {
  style: "currency",
  currency: "RUB",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const InvestmentAmountAlert = ({ maxInvestmentAmount }) => {
  const minAmount = number(MIN_INVESTMENT_AMOUNT, options)
  const maxAmount = number(maxInvestmentAmount, options)
  return (
    <Box flex="1">
      <Alert textAlign="left" p="20px 20px 20px 25px">
        <Box mb="5px">
          <Text fontSize="14px" fontWeight="600" lineHeight="1.7">
            <Translate
              i18n="components:bank_modal.invest.transaction.investment_amount.title"
              minAmount={minAmount}
              maxAmount={maxAmount}
            />
          </Text>
        </Box>
        <Text fontSize="14px" lineHeight="1.5">
          <Translate i18n="components:bank_modal.invest.transaction.investment_amount.content.body" />
        </Text>
        <StyledList>
          <li>
            <Text fontSize="14px" lineHeight="1.5">
              <Translate i18n="components:bank_modal.invest.transaction.investment_amount.content.more" />
            </Text>
          </li>
          <li>
            <Text fontSize="14px" lineHeight="1.5">
              <Translate i18n="components:bank_modal.invest.transaction.investment_amount.content.less" />
            </Text>
          </li>
        </StyledList>
      </Alert>
    </Box>
  )
}

export const RequisitesButton = ({ doc, onClick }) => (
  <Box mt="17px" mb="25px" onClick={onClick}>
    <StyledLink href={doc.url} target="_blank">
      <Button variant="red" height="32px">
        <Translate i18n="components:bank_modal.invest.buttons.download" />
      </Button>
    </StyledLink>
  </Box>
)
