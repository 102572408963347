import React from "react"
import Relay, { graphql } from "react-relay"

import { divideNumber } from "src/utils"

import {
  parseOrders,
  getInvestmentsAmount,
  getAverageInterest,
  getVisibleInvestments,
} from "./utils"

import { InvestmentCard } from "./Elements"

import {
  Container,
  Title,
  Value,
  Description,
  Button,
  InvestmentList,
  Tooltip,
  FlexContainer,
  MobileWrapper,
  Link,
} from "./styles"

function ProfilePortfolio(props) {
  const {
    investments: { investments },
  } = props
  const { count, edges } = investments

  const parsedInvestments = parseOrders(edges)
  const investmentsAmount = getInvestmentsAmount(parsedInvestments)
  const averageInterest = getAverageInterest(parsedInvestments)
  const visibleInvestments = getVisibleInvestments(parsedInvestments)

  return (
    <Container>
      <FlexContainer>
        <div>
          <Title>Портфель</Title>
          <Value>{divideNumber(Math.round(investmentsAmount))} ₽</Value>
        </div>

        <MobileWrapper>
          <Title>Средняя ставка</Title>
          <Value>{averageInterest}%</Value>
        </MobileWrapper>
      </FlexContainer>

      <Description>Средняя ставка по портфелю {averageInterest}%</Description>

      {visibleInvestments.length > 0 && (
        <InvestmentList>
          {visibleInvestments.map((item, i) => (
            <InvestmentCard key={i} data={item.order} />
          ))}
        </InvestmentList>
      )}

      {count > 3 && (
        <Link to="/orders">
          <Button>перейти в портфель</Button>
        </Link>
      )}

      {count < 1 && (
        <>
          <Tooltip>Вы еще не проинвестировали ни в одну заявку.</Tooltip>
          <Link to="/market">
            <Button>посмотреть заявки</Button>
          </Link>
        </>
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfilePortfolio, {
  investments: graphql`
    fragment ProfilePortfolio_investments on User
      @argumentDefinitions(filter: { type: "OrdersFilter" }) {
      investments(filter: $filter) {
        count
        edges {
          node {
            amount
            order {
              confirmedAt
              application {
                data
                shortTitle
              }
              offer {
                data
              }
              chain {
                id
              }
              paymentScheduleList {
                items {
                  loan
                  state
                }
              }
              profile {
                ... on LegalEntityProfile {
                  borrower {
                    ticker
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
})
