import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"

import { getNewTitle } from "./utils"

function HtmlTitle(props) {
  const { defaultTitle = "", prefix = "" } = props

  const [title, setTitle] = useState(prefix + defaultTitle)
  const location = useLocation()

  useEffect(() => {
    const newTitle = getNewTitle(location, props)
    setTitle(newTitle)
  }, [location])

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default HtmlTitle
