/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type PunishmentStatus = "BANKRUPTCY_FINISHED" | "BANKRUPTCY_PROCEDURE" | "ENFORCEMENT" | "NONE" | "PRETRIAL" | "TRIAL" | "%future added value";
export type InvestmentPunishedOrdersListFragment = {
    readonly node: {
        readonly id: string;
        readonly amount: number;
        readonly loan: number;
        readonly order: {
            readonly id: string;
            readonly status: OrderStatusEnum | null;
            readonly confirmedAt: string | null;
            readonly punishment: {
                readonly legalStatus: PunishmentStatus;
                readonly guarantorStatus: PunishmentStatus;
            };
            readonly paymentScheduleList: ReadonlyArray<{
                readonly profile: {
                    readonly id: string;
                } | null;
                readonly info: {
                    readonly state: string | null;
                    readonly pastdue_days: number | null;
                } | null;
                readonly items: ReadonlyArray<{
                    readonly date: string;
                    readonly state: string | null;
                    readonly loan: number;
                    readonly total: number;
                }>;
            }>;
            readonly offer: {
                readonly data: string;
                readonly receivedAt: string | null;
                readonly approvedAt: string | null;
            } | null;
            readonly application: {
                readonly data: string;
                readonly shortTitle: string;
                readonly longTitle: string;
            } | null;
            readonly chain: {
                readonly id: string;
                readonly gatheredAmount: number;
            } | null;
            readonly profile: {
                readonly id: string;
                readonly name?: string;
                readonly borrower?: {
                    readonly ticker: string | null;
                } | null;
            };
        };
    };
    readonly " $refType": "InvestmentPunishedOrdersListFragment";
};
export type InvestmentPunishedOrdersListFragment$data = InvestmentPunishedOrdersListFragment;
export type InvestmentPunishedOrdersListFragment$key = {
    readonly " $data"?: InvestmentPunishedOrdersListFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"InvestmentPunishedOrdersListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loan",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvestmentPunishedOrdersListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Investment",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Order",
          "kind": "LinkedField",
          "name": "order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderPunishment",
              "kind": "LinkedField",
              "name": "punishment",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "legalStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "guarantorStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentSchedule",
              "kind": "LinkedField",
              "name": "paymentScheduleList",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoanInfo",
                  "kind": "LinkedField",
                  "name": "info",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pastdue_days",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentScheduleItem",
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "date",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderOffer",
              "kind": "LinkedField",
              "name": "offer",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "receivedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderApplication",
              "kind": "LinkedField",
              "name": "application",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shortTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "longTitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderChainInfo",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gatheredAmount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Borrower",
                      "kind": "LinkedField",
                      "name": "borrower",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ticker",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LegalEntityProfile"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvestmentEdge"
};
})();
(node as any).hash = '52187ad4556bbef836b10323e9a0c9f5';
export default node;
