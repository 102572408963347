import React from "react"
import { fetchQuery, graphql } from "relay-runtime"

import { Translate, FormLoadingButton } from "src/components"

import { useEnvironment } from "src/hooks"

import { StyledLink } from "./styles"

const query = graphql`
  query SaveButtonQuery($profileId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        ... on IndividualProfile {
          virtualDepositPurpose {
            url
          }
        }
        ... on EntrepreneurProfile {
          virtualDepositPurpose {
            url
          }
        }
        ... on LegalEntityProfile {
          virtualDepositPurpose {
            url
          }
        }
      }
      ... on ForeignIndividualProfile {
        virtualDepositPurpose {
          url
        }
      }
    }
  }
`

const SaveButton = ({ profileId }) => {
  const [
    virtualDepositPurposeUrl,
    setVirtualDepositPurposeUrl,
  ] = React.useState(null)
  const [busy, setBusy] = React.useState(true)
  const { environment } = useEnvironment()

  React.useEffect(() => {
    let didCancle = false
    const variables = {
      profileId,
    }

    fetchQuery(environment, query, variables)
      .then((data: any) => {
        if (!didCancle) {
          setVirtualDepositPurposeUrl(data.node.virtualDepositPurpose.url)
          setBusy(false)
        }
      })
      .catch(() => {
        if (!didCancle) {
          setBusy(false)
        }
      })
    return () => {
      didCancle = true
    }
  }, [])

  return (
    <StyledLink target="_blank" href={virtualDepositPurposeUrl}>
      <FormLoadingButton
        width="205px"
        height="45px"
        fontSize="0.75rem"
        isLoading={busy}
        variant="outlinedRed"
      >
        <Translate i18n="components:account.operations.deposit.buttons.download" />
      </FormLoadingButton>
    </StyledLink>
  )
}

export default SaveButton
