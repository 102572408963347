import React from "react"
import memoize from "memoize-one"
import AutoSuggest from "react-autosuggest"

import { omit, createDaDataSuggestor } from "src/utils"

import { Input } from "src/components"

import theme from "./theme"

const renderSuggestion = (suggestion) => suggestion.data.name.payment
const mergeThemes = memoize((defaultTheme, customTheme) => ({
  ...defaultTheme,
  ...customTheme,
}))
const propsToOmit = [
  "renderInputComponent",
  "theme",
  "debounceTime",
  "mergeWithDefaultTheme",
  "onSuggestionSelected",
  "daDataSelector",
]

class BankDataSuggestion extends React.PureComponent<any, any> {
  state = {
    suggestions: [],
  }

  static defaultProps = {
    theme,
    debounceTime: 500,
    renderInputComponent: Input,
    mergeWithDefaultTheme: false,
  }

  componentDidMount() {
    (this as any).suggestBankData = createDaDataSuggestor("bank")
  }

  onSuggestionsFetchRequested = ({ value }) => {
    clearTimeout((this as any).timeout);
    (this as any).timeout = setTimeout(
      () => (this as any).suggestBankData(value, this.onSuggestionsLoad),
      this.props.debounceTime,
    )
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  onSuggestionsLoad = (suggestions) => {
    this.setState(() => ({ suggestions }))
  }

  getSuggestionValue = (suggestion) => this.props.daDataSelector(suggestion)

  onSuggestionSelected = (event, { suggestion }) => {
    event.stopPropagation()
    this.props.onSuggestionSelected(suggestion)
  }

  render() {
    const inputProps = omit(this.props, propsToOmit)
    const resultingTheme = this.props.mergeWithDefaultTheme
      ? mergeThemes(theme, this.props.theme)
      : this.props.theme

    return (
      <AutoSuggest
        theme={resultingTheme}
        inputProps={inputProps}
        renderInputComponent={this.props.renderInputComponent}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={false}
        suggestions={this.state.suggestions}
        getSuggestionValue={this.getSuggestionValue}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      />
    )
  }
}

export default BankDataSuggestion
