import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const LoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.45);
`

export const MockBlock = styled.div<any>`
  position: relative;
  background-color: #ebebeb;
  border-radius: 9px;
  margin-top: 14px;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "50px"};

  &:last-child {
    margin-top: 40px;
  }
`
