import React from "react"

import { Table, List } from "../../organisms/PortfolioTable/Elements"
import {
  divideNumber,
  getPaymentDate,
} from "../../organisms/PortfolioTable/Tables/utils"

import { PaymentCard } from "./Elements"

import {
  TData,
  TDDate,
  TDBold,
  TDStatus,
  DesktopContainer,
  MobileContainer,
} from "./styles"
import statuses, { investmentTypes, investmentAmountColors } from "./statuses"
import { isInvestment } from "./utils"

function getPaymentColumns() {
  return [
    {
      title: "Дата платежа",
      render: (i, obj) => <TDDate key={i}>{getPaymentDate(obj.date)}</TDDate>,
    },
    {
      title: "Дата оплаты",
      render: (i, obj) => (
        <TDDate key={i}>{getPaymentDate(obj.date, obj.payDate)}</TDDate>
      ),
    },
    {
      title: "Сумма платежа",
      render: (i, obj) => (
        <TData key={i}>{divideNumber(obj.total_gross.toFixed(2))}</TData>
      ),
    },
    {
      title: "Основной долг",
      render: (i, obj) => (
        <TData key={i}>{divideNumber(obj.loan.toFixed(2))}</TData>
      ),
    },
    {
      title: "Проценты",
      render: (i, obj) => (
        <TData key={i}>{divideNumber(obj.interest.toFixed(2))}</TData>
      ),
    },
    {
      title: "НДФЛ",
      render: (i, obj) => (
        <TData key={i}>{divideNumber(obj.personal_tax.toFixed(2))}</TData>
      ),
    },
    {
      title: "К выплате",
      render: (i, obj) => (
        <TDBold key={i}>{divideNumber(obj.total.toFixed(2))}</TDBold>
      ),
    },
    {
      title: "Остаток долга",
      render: (i, obj) => (
        <TData key={i}>{divideNumber(obj.debt.toFixed(2))}</TData>
      ),
    },
    {
      title: "Статус",
      render: (i, obj) => {
        const status = statuses.get(obj.state)
        return (
          <TDStatus color={status.color} key={i}>
            {status.text}
          </TDStatus>
        )
      },
    },
  ]
}

const getInvestmentColumns = () => [
  {
    title: "Дата платежа",
    render: (i, obj) => <TDDate key={i}>{getPaymentDate(obj.date)}</TDDate>,
  },
  {
    title: "",
    render: (i, obj) => (
      <TData colSpan={6} key={i} textAlign="center">
        {investmentTypes[obj.type] || "-"}
      </TData>
    ),
  },
  {
    title: "Остаток долга",
    render: (i, obj) => (
      <TData color={investmentAmountColors[obj.type]} key={i}>
        {obj.type === "SELL" ? "-" : "+"}
        {divideNumber(obj.amount.toFixed(2))}
      </TData>
    ),
  },
  {
    title: "Статус",
    render: (i) => (
      <TDStatus color="#6FA84B" key={i}>
        Исполнено
      </TDStatus>
    ),
  },
]

function InvestorPaymentsGraph(props) {
  const { items } = props

  return (
    <>
      <DesktopContainer>
        <Table
          columns={getPaymentColumns()}
          additionalColumns={getInvestmentColumns()}
          condition={isInvestment}
          items={items}
        />
      </DesktopContainer>

      <MobileContainer>
        <List items={items} Render={PaymentCard} />
      </MobileContainer>
    </>
  )
}

export default InvestorPaymentsGraph
