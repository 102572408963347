import React from "react"

import { Flex } from "src/components"

import { InvestContainer } from "./styles"

import { Paid, Invest } from "./Elements"

const options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const InvestmentsData = () => (
  <Flex mr="40px">
    <Invest options={options} />
    <Paid options={options} />
  </Flex>
)

const render = () => (
  <InvestContainer>
    <InvestmentsData />
  </InvestContainer>
)

export default render
