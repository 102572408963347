import React from "react"

import {
  Box, Label, Tooltip, FormRow, Translate, TextField,
} from "src/components"

export class Name extends React.Component<any, any> {
  state = {
    name: this.props.value,
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state.name,
    })
  }

  render() {
    return (
      <FormRow>
        <Box width={260}>
          <Label whiteSpace="pre-line">
            <Translate
              i18n="accreditation.borrower.rows.name.label"
              ns="components"
            />
            <Tooltip text="tooltips.name" />
          </Label>
        </Box>
        <Box width="100%">
          <TextField
            type="text"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default Name
