import React from "react"
import Relay, { graphql } from "react-relay"

import {
  DraftTable,
  ActiveTable,
  CompletedTable,
  SucceededTable,
} from "./Tables"

import { Container, Text, Button } from "./styles"

function TrustedProfileOrders(props) {
  const { profile, createOrder } = props
  const {
    id: profileId,
    user: { id: userId },
    orders,
  } = profile
  const { count = 0 } = orders

  function toProposal(data) {
    const { history } = props

    if (
      data.status === "INITIAL"
      || data.status === "PENDING"
      || data.status === "DECLINED"
    ) {
      history.push(`/orders/${data.id}`)
    } else {
      history.push(`/market/${data.id}?source=orders`)
    }
  }

  return (
    <Container>
      {!!count && (
        <>
          <DraftTable
            userId={userId}
            profileId={profileId}
            toProposal={toProposal}
          />
          <ActiveTable
            userId={userId}
            profileId={profileId}
            toProposal={toProposal}
          />
          <CompletedTable
            userId={userId}
            profileId={profileId}
            toProposal={toProposal}
          />
          <SucceededTable
            userId={userId}
            profileId={profileId}
            toProposal={toProposal}
          />
        </>
      )}

      {!count && (
        <>
          <Text>Заявок ещё нет. Создайте первую.</Text>
          <Button onClick={createOrder}>создать заявку</Button>
        </>
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(TrustedProfileOrders, {
  profile: graphql`
    fragment TrustedProfileOrders_profile on UserProfile {
      ... on UserProfile {
        id
        user {
          id
        }
        orders {
          count
        }
      }
    }
  `,
})
