import React from "react"

import { number, getProfileName, getProfileDetailedBalance } from "src/utils"

import { Text } from "src/components"

import { WithdrawProfileContainer } from "./styles"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const ProfileInfo = ({ profile, bordered }: any) => {
  const detailedBalances = getProfileDetailedBalance(profile) || []
  const balance = (!!detailedBalances
    && detailedBalances.find((item) => item.currency === "RUB")) || {
    available: 0,
  }

  return (
    <WithdrawProfileContainer bordered={bordered}>
      <Text color="greyShuttle" lineHeight="1.5">
        {getProfileName(profile)}
      </Text>
      <Text fontSize="11px" lineHeight="1.5" color="greyDark">
        {number(balance.available, options)} ₽
      </Text>
    </WithdrawProfileContainer>
  )
}
