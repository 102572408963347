import React from "react"

import { Box, Flex } from "src/components"

const render = ({ children, renderTop }) => (
  <Flex flex={1} flexDirection="column">
    <Flex flex="1 0 auto">{renderTop()}</Flex>

    <Box my={40}>{children}</Box>

    <Flex flex="1 0 auto" />
  </Flex>
)

export default render
