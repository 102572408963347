/* eslint-disable max-len */

import React from "react"

function Chel(props) {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3583 11.2801C14.6448 11.2801 16.4983 9.42627 16.4983 7.14004C16.4983 4.85356 14.6448 3 12.3583 3C10.0718 3 8.21826 4.85356 8.21826 7.14004C8.22126 9.42527 10.0731 11.2771 12.3583 11.2801ZM12.3583 4.60957C13.7602 4.60957 14.8968 5.74593 14.8968 7.14805C14.8968 8.54991 13.7602 9.68652 12.3583 9.68652C10.9562 9.68652 9.81982 8.54991 9.81982 7.14805C9.82033 5.74618 10.9564 4.61007 12.3583 4.60957Z" />
      <path d="M12.3582 12.8095C10.6643 12.797 9.03846 13.4769 7.85781 14.6913C6.65664 15.9165 6 17.5821 6 19.3919C6.00125 19.8336 6.3591 20.1914 6.80078 20.1927H17.9156C18.3573 20.1914 18.7152 19.8336 18.7164 19.3919C18.7164 17.5821 18.0598 15.9165 16.8586 14.6913C15.6779 13.4769 14.0524 12.797 12.3582 12.8095ZM7.65762 18.5911C7.79475 17.5441 8.26671 16.5697 9.00293 15.8124C9.88904 14.9158 11.0975 14.4111 12.3582 14.4111C13.6189 14.4111 14.8274 14.9158 15.7135 15.8124C16.4482 16.5704 16.9202 17.5446 17.0588 18.5911H7.65762Z" />
    </svg>
  )
}

Chel.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Chel
