import styled from "styled-components"

export const ItemWrapper = styled.li`
  text-decoration: none;
  list-style-type: none;
  margin: 0 0 70px;
  padding: 0;
  width: 100%;
  height: auto;
`

export const PrimaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #fbfbfb;
  width: 100%;
  height: 93px;
`

export const DateContainer = styled.div`
  font-family: Geometria;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: left;
  margin: auto 0;
`

export const SumContainer = styled.div`
  flex-shrink: 0;
  font-family: Geometria;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: right;
  margin: auto 0;
`

export const SecondaryContainer = styled.div`
  @media screen and (min-width: 651px) {
    padding: 0 32px;
  }
`

export const ItemData = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`

export const ItemDescription = styled.div`
  margin-right: 8px;
  font-family: Geometria;
  font-size: 18px;
  color: #4a4a4a;
  text-align: left;
`

export const ItemValue = styled.div`
  flex-shrink: 0;
  font-family: Geometria;
  font-size: 18px;
  color: #4a4a4a;
  text-align: right;
`
