import React from "react"
import { Route } from "react-router"

import { pipe } from "src/utils"
import { Box, Button, FlexBox } from "src/components"

import Icon from "./Icon"
import IconWrapper from "./styles"

const Content = ({ children, forward }: any) => (children ? (
    <Box ml={forward ? 0 : 16} mr={forward ? 16 : 0}>
      {children}
    </Box>
) : null)

const ReturnButton = (props) => {
  const {
    fill, path, forward, children, keepQuery,
  } = props

  const onClick = (history, targetPath) => () => {
    if (keepQuery) {
      const { search } = window.location
      const params = new URLSearchParams(search)
      history.push(`${targetPath}?${params.toString()}`)

      return
    }

    history.push(targetPath)
  }

  const onClickFromProps = props.onClick ? props.onClick : () => {}

  return (
    <Route>
      {({ history }) => (
        <Button
          {...props}
          variant="default"
          height={30}
          onClick={pipe(onClickFromProps, onClick(history, path))}
        >
          <FlexBox flexDirection={forward ? "row-reverse" : "row"}>
            <IconWrapper forward={forward}>
              <Icon fill={fill} />
            </IconWrapper>
            <Content>{children}</Content>
          </FlexBox>
        </Button>
      )}
    </Route>
  )
}

ReturnButton.glyph = Icon

export default ReturnButton
