import styled from "styled-components"

import { PlusIcon } from "src/components"

export const RemoveIcon = styled(PlusIcon)`
  transform: rotate(-45deg);
`

export const RemoveButtonWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`

export const AddButtonWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  padding-top: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`
