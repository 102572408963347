/* eslint-disable max-len */
import React from "react"

import { FloatingLabelInput } from "src/components"

import { TooltipContainer } from "./styles"

const HiddenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g fill="#9EA6AE" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M12.5 5C7.727 5 3.651 7.969 2 12.16c1.651 4.19 5.727 7.158 10.5 7.158S21.349 16.35 23 12.16C21.349 7.97 17.273 5 12.5 5zm0 11.932a4.774 4.774 0 0 1-4.773-4.773A4.774 4.774 0 0 1 12.5 7.386a4.774 4.774 0 0 1 4.773 4.773 4.774 4.774 0 0 1-4.773 4.773zm0-7.637a2.86 2.86 0 0 0-2.864 2.864 2.86 2.86 0 0 0 2.864 2.864 2.86 2.86 0 0 0 2.864-2.864A2.86 2.86 0 0 0 12.5 9.295z"
      />
      <path
        stroke="#FFF"
        strokeWidth="2"
        d="M1.586 20.678L20.678 1.586l2.828 2.828L4.414 23.506z"
      />
    </g>
  </svg>
)

const ShownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="15"
    viewBox="0 0 21 15"
  >
    <path
      fill="#9EA6AE"
      fillRule="nonzero"
      d="M10.5 0C5.727 0 1.651 2.969 0 7.16c1.651 4.19 5.727 7.158 10.5 7.158S19.349 11.35 21 7.16C19.349 2.97 15.273 0 10.5 0zm0 11.932a4.774 4.774 0 0 1-4.773-4.773A4.774 4.774 0 0 1 10.5 2.386a4.774 4.774 0 0 1 4.773 4.773 4.774 4.774 0 0 1-4.773 4.773zm0-7.637A2.86 2.86 0 0 0 7.636 7.16a2.86 2.86 0 0 0 2.864 2.864 2.86 2.86 0 0 0 2.864-2.864A2.86 2.86 0 0 0 10.5 4.295z"
    />
  </svg>
)

const VisibilityTooltip = ({ showPassword, onClick }) => (
  <TooltipContainer onClick={onClick}>
    {showPassword ? <ShownIcon /> : <HiddenIcon />}
  </TooltipContainer>
)

const Input = (props) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const togglePassword = React.useCallback(
    () => setShowPassword(!showPassword),
    [showPassword],
  )

  const Tooltip = React.useMemo(
    () => () => (
      <VisibilityTooltip onClick={togglePassword} showPassword={showPassword} />
    ),
    [showPassword],
  )

  return (
    <FloatingLabelInput
      name={props.name}
      type={showPassword ? "text" : "password"}
      value={props.value}
      error={props.error}
      onChange={props.onChange}
      label={props.label}
      tooltip={Tooltip}
    />
  )
}

export default React.memo(Input)
