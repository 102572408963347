import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  margin-top: 8px;
`

export const BlueText = styled.span`
  color: #3a5bbc;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
`

export const LinkButton = styled(BlueText)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
`
