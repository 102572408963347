/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import styled from "styled-components"

import { pipe } from "src/utils"
import { Translate, UploadInput, FormLoadingButton } from "src/components"

const StyledInput = styled.input`
  display: none;
`

const renderDefaultButton = ({ busy, onClick, ...rest }) => (
  <FormLoadingButton {...rest} isLoading={busy} onClick={onClick}>
    <Translate i18n="common:upload" />
  </FormLoadingButton>
)

const extractFilesFromEvent = (e) => e.target.files

const UploadButton = (props) => {
  const {
    busy, onDrop, accept, multiple, renderButton, ...rest
  } = props

  const inputRef = React.useRef(null)

  const onClick = React.useCallback(() => inputRef.current.click(), [])

  const onChange = React.useCallback(pipe(extractFilesFromEvent, onDrop), [
    onDrop,
  ])

  return (
    <>
      <StyledInput
        type="file"
        ref={inputRef}
        accept={accept}
        onChange={onChange}
        multiple={!!multiple}
      />
      {renderButton({ ...rest, busy, onClick })}
    </>
  )
}

UploadButton.defaultProps = {
  accept: "*",
  variant: "defaultBlue",
  renderButton: renderDefaultButton,
}

export default React.memo(({ onChange, multiple, ...props }: any) => (
  <UploadInput onChange={onChange} multiple={multiple}>
    {(uploaderProps) => <UploadButton {...props} {...uploaderProps} />}
  </UploadInput>
))
