import is from "is_js"
import React from "react"
import { Translate } from "src/components"
import { RelayEnvironmentConsumer } from "src/context"

import ErrorsContainer from "../ErrorsContainer"

class MutationContainer extends React.Component<any, any> {
  commit = ({ variables, uploadables, callbacks }) => {
    const processedCallbacks = { ...callbacks }

    const { onError } = callbacks

    processedCallbacks.onError = (transaction) => {
      this.props.setErrors(transaction)

      if (onError) {
        onError(transaction)
      }
    }

    this.props.mutation.commit(
      this.props.environment,
      variables,
      uploadables,
      processedCallbacks,
    )
  }

  getError = (path) => {
    const error = this.props.getError(path)
    if (is.not.existy(error)) return null
    return <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />
  }

  clearError = (path) => {
    this.props.clearError(path)
  }

  render() {
    return (this as any).props.children({
      commit: this.commit,
      getError: this.getError,
      clearError: this.clearError,
      setManualError: this.props.setManualError,
    })
  }
}

const render = ({ mutation, ...props }) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => (
      <ErrorsContainer>
        {({
          getError, setErrors, clearError, setManualError,
        }) => (
          <MutationContainer
            {...props}
            mutation={mutation}
            environment={environment}
            getError={getError}
            setErrors={setErrors}
            clearError={clearError}
            setManualError={setManualError}
          />
        )}
      </ErrorsContainer>
    )}
  </RelayEnvironmentConsumer>
)

export default render
