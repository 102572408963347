/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type Deposit_foreignProfile = {
    readonly id: string;
    readonly accreditation: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly borrower: {
        readonly isApproved: boolean;
    } | null;
    readonly investor: {
        readonly isApproved: boolean;
    } | null;
    readonly " $refType": "Deposit_foreignProfile";
};
export type Deposit_foreignProfile$data = Deposit_foreignProfile;
export type Deposit_foreignProfile$key = {
    readonly " $data"?: Deposit_foreignProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"Deposit_foreignProfile">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isApproved",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Deposit_foreignProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "role",
          "value": "INVESTOR"
        }
      ],
      "concreteType": "Accreditation",
      "kind": "LinkedField",
      "name": "accreditation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": "accreditation(role:\"INVESTOR\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Borrower",
      "kind": "LinkedField",
      "name": "borrower",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Investor",
      "kind": "LinkedField",
      "name": "investor",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ForeignIndividualProfile"
};
})();
(node as any).hash = '11ea990a1710a9ae33d9b620ccb50ed7';
export default node;
