import React, { useEffect } from "react"
import ReactDOM from "react-dom"

import {
  InputAmount,
  PaymentList,
  IncomeContainer,
  MiniLoader,
} from "src/components"

import {
  ModalContainer,
  WindowContainer,
  NameWrap,
  MobileExitBtn,
  CloseIcon,
  IncomeWrapper,
  FlexWrapper,
  Divider,
  LoadingContainer,
  OrderName,
  TitleDescription,
} from "./styles"

import { getIncome, getAverageIncome, getTitle } from "./utils"

function MobilePaymentSchedule(props) {
  const {
    onClose,
    isCession,
    data,
    schedule,
    changeAmount,
    amount,
    isFetching,
    errorMaxSum,
  } = props

  useEffect(() => {
    window.document.body.style.overflow = "hidden"

    return () => {
      window.document.body.style.overflow = null
    }
  }, [])

  return (
    <ModalContainer>
      <WindowContainer>
        <MobileExitBtn onClick={onClose}>
          <CloseIcon /> Назад
        </MobileExitBtn>

        <NameWrap>
          <TitleDescription>{getTitle(isCession)}</TitleDescription>
          <OrderName>{data.application.data.companyName}</OrderName>
        </NameWrap>

        {isFetching && (
          <LoadingContainer>
            <MiniLoader changed fontSize="5px" margin="auto" />
          </LoadingContainer>
        )}

        <FlexWrapper>
          <InputAmount
            isFetching={isFetching}
            onChange={changeAmount}
            value={amount}
            label="Введите сумму инвестиций"
            errorText={errorMaxSum}
          />
          <IncomeWrapper>
            <IncomeContainer
              title="Среднемесячный доход"
              value={getAverageIncome(schedule)}
            />
            <Divider />
            <IncomeContainer title="Общий доход" value={getIncome(schedule)} />
          </IncomeWrapper>
        </FlexWrapper>

        <PaymentList {...props} />
      </WindowContainer>
    </ModalContainer>
  )
}

export default function ModalMobilePaymentSchedule(props) {
  const { isShowing } = props
  const root = window.document.getElementById("app-root")
  return isShowing
    ? ReactDOM.createPortal(MobilePaymentSchedule(props), root)
    : null
}
