import styled, { css } from "styled-components"

const Label = styled.label<any>`
  align-items: ${({ alignItems }) => alignItems};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  user-select: none;

  & > * + * {
    margin-left: 8px;
  }

  & > span {
    transform: translateY(1px);
  }

  & a {
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ disabled }) => !!disabled
    && css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export default Label
