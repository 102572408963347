import React from "react"
import {
  Box, Text, Input, Tooltip,
} from "src/components"

import { Container, Label } from "./style"

const Hint = (props) => (
  <Box mt={10}>
    <Text fontSize={0} {...props} />
  </Box>
)

const Error = (props) => <Hint color="red" {...props} />

const renderLabel = ({ label, disabled, tooltip }: any) => label && (
    <Box pb="12px">
      <Label disabled={disabled}>{label}</Label>
      <Tooltip text={tooltip} />
    </Box>
)

const renderHint = ({ hint, error }: any) => !error && hint && <Hint color="greyDark">{hint}</Hint>

const renderError = ({ error }: any) => error && <Error>{error}</Error>

const render = ({
  label, hint, Component, ...props
}: any) => (
  <Container>
    {renderLabel({ label, ...props })}
    {Component ? <Component {...props} /> : <Input {...props} />}
    {renderHint({ hint, ...props })}
    {renderError({ ...props })}
  </Container>
)

export default render
