interface InputProps {
  type: string,
  name: string,
  width: string,
  mask?: string,
  label: string,
  maxWidth: string,
  errorPath: string,
}

const InputsSchema: InputProps[][] = [
  [
    {
      type: "text",
      name: "number",
      width: "100%",
      maxWidth: "49%",
      errorPath: "number",
      label: "components:international_profile_form.placeholders.number",
    },
  ],
  [
    {
      type: "text",
      name: "dateFrom",
      width: "100%",
      maxWidth: "49%",
      mask: "99 - 99 - 9999",
      errorPath: "dateFrom",
      label: "components:international_profile_form.placeholders.dateFrom",
    },
    {
      type: "text",
      name: "dateTo",
      width: "100%",
      maxWidth: "49%",
      mask: "99 - 99 - 9999",
      errorPath: "dateTo",
      label: "components:international_profile_form.placeholders.dateTo",
    },
  ],
]

export default InputsSchema
