import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"
import ReactTooltip from "react-tooltip"

import CardWrapper from "../../atoms/CardWrapper"

import { StarIcon } from "./icons"

export const Container = styled(CardWrapper)`
  font-family: "Geometria";
  width: 100%;
  padding: 20px;
  font-size: 0.75rem;
  line-height: 15px;
  margin: 0;
  color: #5f5f5f;
`

export const LegalForm = styled.p`
  color: #4a4a4a;
  margin: 0;
  font-weight: 100;
  @media (max-width: 800px) {
    font-size: 0.875rem;
    line-height: 18px;
  }
`

export const Name = styled.h6`
  font-size: 0.937rem;
  line-height: 19px;
  margin: 0;
  font-weight: 600;
  color: #5f5f5f;
  @media (max-width: 800px) {
    font-size: 1.375rem;
    line-height: 28px;
    font-weight: bold;
    margin-top: 16px;
  }
`

export const QualifiedStar = styled(StarIcon)`
  width: 15px;
  height: 15px;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 20px;
    height: 20px;
  }
`

export const QualifiedTooltip = styled(ReactTooltip)`
  background-color: ${({ theme }) => theme.colors.primaryBlack} !important;
  border-radius: 4px !important;
  padding: 10px 14px !important;

  &.place-left::after {
    border-left: 6px solid ${({ theme }) => theme.colors.primaryBlack} !important;
  }

  &.place-right::after {
    border-right: 8px solid ${({ theme }) => theme.colors.primaryBlack} !important;
  }

  &.place-top::after {
    border-top: 6px solid ${({ theme }) => theme.colors.primaryBlack} !important;
  }

  &.place-bottom::after {
    border-bottom: 6px solid ${({ theme }) => theme.colors.primaryBlack} !important;
  }
`

export const TooltipText = styled.p`
  font-size: 10px;
  line-height: 14px;
  color: white;
  font-family: Geometria, sans-serif;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 19px;
  }
`

export const Status = styled.p`
  color: ${(props) => props.color};
  margin: 8px 0 0;
  font-size: 12px;

  @media (max-width: 800px) {
    font-size: 14px;
  }
`

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const FlexContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
`

export const NameContainer = styled(Flex)`
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primaryBlacks[1]};
  margin: 20px 0;
`

export const Menu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`

export const MenuButton = styled.button<any>`
  font-family: Geometria;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  color: #5f5f5f;
  font-size: 0.75rem;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: 801px) {
    ${(props) => props.checked
      && css`
        font-weight: bold;

        &:before {
          content: "";
          position: absolute;
          height: 5px;
          width: 5px;
          background-color: #6fa84b;
          border-radius: 50%;
          top: 2.5px;
          left: -10px;
        }

        &:after {
          content: "";
          background-color: #fff;
          box-sizing: border-box;
          height: 14px;
          width: 14px;
          position: absolute;
          right: -26px;
          transform: rotate(45deg);
          box-shadow: 4px -4px 6px -2px rgba(0, 0, 0, 0.1);
        }
      `};
  }

  @media (max-width: 800px) {
    font-size: 1rem;
  }
`

export const MenuItem = styled.li`
  position: relative;
  line-height: 15px;
  margin-top: 10px;
  :first-of-type {
    margin-top: 15px;
  }
  @media (max-width: 480px) {
    margin-top: 15px;
  }
`

export const Button = styled.button`
  font-family: "Geometria";
  font-size: 0.625rem;
  line-height: 12px;
  width: 120px;
  border: 1px solid #f70000;
  color: #f70000;
  padding: 6px 0;
  border-radius: 4px;
  margin-top: 15px;
  font-weight: bold;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
    width: 100%;
    padding: 24px 0;
  }
`

export const Icon = styled.svg`
  margin-left: 6px;
`

export const ReferralLink = styled(NavLink)`
  text-decoration: none;
  font-family: Geometria, sans-serif;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 10px 0 0;
  width: 100%;
  text-align: left;
  color: #5f5f5f;
  font-size: 0.75rem;
`
