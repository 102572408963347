export const RowInputs = {
  text: "text",
  date: "date",
  phone: "phone",
  number: "number",
  textEditor: "textEditor",
}

export const CheckboxInputs = {
  switch: "switch",
  checkBox: "checkBox",
  switchSelect: "switchSelect",
}
