import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer, VirtualAccountActions } from "src/components"
import { divideNumber, isForeignInvestor } from "src/utils"

import BlockLoader from "./BlockLoader"
import { WalletIcon } from "./icons"
import {
  Container,
  Header,
  TitleContainer,
  Title,
  HeaderValue,
  Divider,
  Table,
  TRWithBorder,
  TD,
  TDBold,
  ActionContainer,
  OutlinedButton,
} from "./styles"

function BalanceBlock(props) {
  const { node } = props
  const { accountBalanceDetailed } = node

  const [profile, foreignProfile] = isForeignInvestor(node)
    ? [null, node]
    : [node, null]

  const { available = 0, blocked = 0, invested = 0 } = accountBalanceDetailed[0] || {}
  const total = available + blocked + invested

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>Баланс</Title>
          <WalletIcon />
        </TitleContainer>
        <HeaderValue>₽ {divideNumber(total.toFixed(2))}</HeaderValue>
      </Header>

      <Divider />

      <Table>
        <tbody>
          <tr>
            <TD>Инвестиции</TD>
            <TDBold>₽ {divideNumber(invested.toFixed(2))}</TDBold>
          </tr>

          <tr>
            <TD>Заблокировано</TD>
            <TDBold>₽ {divideNumber(blocked.toFixed(2))}</TDBold>
          </tr>

          <TRWithBorder>
            <TD>Доступно</TD>
            <TDBold>₽ {divideNumber(available.toFixed(2))}</TDBold>
          </TRWithBorder>

          <tr>
            <TD>Всего средств</TD>
            <TDBold>₽ {divideNumber(total.toFixed(2))}</TDBold>
          </tr>
        </tbody>
      </Table>

      <ActionContainer>
        <VirtualAccountActions.Withdraw
          profile={profile}
          foreignProfile={foreignProfile}
          RenderedButton={OutlinedButton}
        />

        <VirtualAccountActions.Deposit
          profile={profile}
          foreignProfile={foreignProfile}
          RenderedButton={OutlinedButton}
        />
      </ActionContainer>
    </Container>
  )
}

const query = graphql`
  query BalanceQuery($id: ID!) {
    node(id: $id) {
      ... on UserProfile {
        id
        __typename
        accountBalanceDetailed {
          currency
          available
          blocked
          invested
        }
        ...Deposit_profile
        ...Withdraw_profile
      }
      ... on ForeignIndividualProfile {
        id
        __typename
        ...Deposit_foreignProfile
        ...Withdraw_foreignProfile
      }
    }
  }
`

function Render(props) {
  const { profileId } = props

  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      query={query}
      render={BalanceBlock}
      renderNull={BlockLoader}
      variables={variables}
      {...props}
    />
  )
}

export default Render
