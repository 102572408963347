import React from "react"
import Gallery from "./Gallery"

import {
  Container,
  VideoFrame,
  Headline,
  Description,
  Row,
  Link,
} from "./styles"
import {
  GuarantorIcon,
  EstateIcon,
  OtherIcon,
  VehicleIcon,
  DocumentIcon,
} from "./icons"

const ProposalProvision = ({ provision }) => {
  if (!provision) return null
  const { items } = provision

  const iconMap = {
    VEHICLE: VehicleIcon(),
    OTHER: OtherIcon(),
    ESTATE: EstateIcon(),
    GUARANTOR: GuarantorIcon(),
  }

  return (
    <Container>
      {items.map((item) => (
        <div key={item.id}>
          {iconMap[item.kind]}
          <Headline>{item.name}</Headline>
          <Description>{item.description}</Description>
          {item.documents.map((document) => (
            <Row key={document.id}>
              <DocumentIcon />{" "}
              <Link href={document.url}>{document.filename}</Link>
            </Row>
          ))}
          <Gallery items={item.images} />
          {item.videos.map((video) => (
            <VideoFrame
              key={video.id}
              src={video.video.url}
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            />
          ))}
        </div>
      ))}
    </Container>
  )
}

export default ProposalProvision
