import styled from "styled-components"

import { Box } from "src/components"

export const TooltipContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  cursor: pointer;
  user-select: none;
`

export const Placeholder = ""
