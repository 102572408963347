import { Subject } from "rxjs"
import { VIDEO_META } from "src/constants"
import { debounceTime } from "rxjs/operators"

export default new (class {
  actor

  subscriptor

  relayEnvironment

  fetchMutation

  constructor() {
    this.actor = new Subject().pipe(debounceTime(1000))

    this.subscriptor = undefined
    this.relayEnvironment = undefined
    this.fetchMutation = undefined
  }

  parseMeta = (url) => {
    const values = {
      input: {
        url,
      },
    }

    const onCompleted = ({ scrapExternalMedia: { media } }) => this.subscriptor({
      ...media,
    })

    const onError = () => this.subscriptor({
      ...VIDEO_META,
      ogUrl: url,
    })

    this.fetchMutation.commit(this.relayEnvironment, values, null, {
      onCompleted,
      onError,
    })
  }

  initSubscription = (callback, environment, fetchMutation) => {
    this.subscriptor = callback
    this.relayEnvironment = environment
    this.fetchMutation = fetchMutation
    this.actor.subscribe(this.parseMeta)
  }

  notify = (url) => {
    this.actor.next(url)
  }
})()
