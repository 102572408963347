import styled from "styled-components"
import { width, height } from "styled-system"

export const PreviewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const ZoomAction = styled.div`
  visibility: hidden;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blacks[5]};
`

export const PreviewItem = styled.div`
  width: 185px;
  height: 127px;
  position: relative;
  display: flex;
  margin-bottom: 10px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  &:hover {
    & ${ZoomAction} {
      visibility: visible;
    }
  }
`

export const IconWrapper = styled.div`
  margin: auto;
  width: 32px;
`

export const ItemContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.darkCoffe};
  height: calc(100vh - 200px);
  width: calc(100vw - 324px);
  position: relative;
  overflow: auto;
  display: flex;
`

export const StyledImage = styled.img`
  margin: auto;
  cursor: pointer;
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
  ${width}
  ${height}
`

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SliderStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.darkCoffe};
  color: ${({ theme }) => theme.colors.whites[7]};
`

export const OpenLink = styled.a`
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.whites[7]};
  
  &:hover {
    text-decoration: underline;
  }
`

export const PreviewImage = styled.img`
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`

export const DescriptionContainer = styled.div`
  padding: 44px 0 4px;
  margin-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ginger};
`
