import React from "react"
import memoize from "memoize-one"
import onClickOutside from "react-onclickoutside"

import {
  Box, Text, Flex, Translate, FloatingLabelInput,
} from "src/components"
import { createChainedFunction } from "src/utils"

import {
  Option,
  Divider,
  InputWrapper,
  InputContainer,
  StyledArrowIcon,
  OptionsContainer,
  CollateralContainer,
  SelectedProfileContainer,
} from "./styles"

export const ModalHeader = (props) => (
  <>
    <Box mb="20px">
      <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
        <Translate {...props} />
      </Text>
    </Box>
    <Divider />
  </>
)

const CollateralType = ({ kind }) => (
  <CollateralContainer>
    <Text color="blue" fontSize="11px" lineHeight="1.5">
      <Translate i18n="modals:express-scoring.form.inputs.collateralKind" />
    </Text>
    <Text fontSize="16px" lineHeight="1.5">
      <Translate
        i18n={`components:account.order_item.provision.types.${kind}`}
      />
    </Text>
  </CollateralContainer>
)

const optionsMapFn = memoize((onSelect, selectedItem) => (item) => (
  <Option
    key={item}
    onClick={() => onSelect(item)}
    active={item === selectedItem}
  >
    <Text fontSize="16px" lineHeight="1.5">
      <Translate
        i18n={`components:account.order_item.provision.types.${item}`}
      />
    </Text>
  </Option>
))

const options = ["OTHER", "ESTATE", "VEHICLE", "GUARANTOR"]

const Select = (props) => {
  const { value, onChange } = props

  const [open, setOpen] = React.useState(false)
  const hideOptions = React.useCallback(() => setOpen(false), [])
  const showOptions = React.useCallback(() => setOpen(true), []);

  (Select as any).handleClickOutside = hideOptions

  const onSelect = createChainedFunction(onChange, hideOptions)
  const renderOptionFn = optionsMapFn(onSelect, value)

  return (
    <InputWrapper>
      <SelectedProfileContainer onClick={open ? hideOptions : showOptions}>
        <CollateralType kind={value} />
        <StyledArrowIcon optionsVisible={open} />
      </SelectedProfileContainer>
      {open && (
        <OptionsContainer shouldDisplay={open}>
          {options.map(renderOptionFn)}
        </OptionsContainer>
      )}
    </InputWrapper>
  )
}

const config = {
  handleClickOutside: () => (Select as any).handleClickOutside,
}

const CollateralTypeSelect = onClickOutside(Select, config)

const isKindValuable = (kind) => ["ESTATE", "VEHICLE", "OTHER"].includes(kind)

export const renderCollateralInput = (props) => {
  const { index, value: collateral, onChange } = props

  const onItemChange = (eventOrValue) => {
    const newValue = eventOrValue.target
      ? { value: eventOrValue.target.value, kind: collateral.kind }
      : {
        kind: eventOrValue,
        value: isKindValuable(eventOrValue) ? collateral.value : "",
      }

    onChange({ target: { name: index, value: newValue } })
  }

  return (
    <Flex>
      <CollateralTypeSelect value={collateral.kind} onChange={onItemChange} />
      <InputContainer>
        <FloatingLabelInput
          name={`${index}_value`}
          value={collateral.value}
          onChange={onItemChange}
          label="modals:express-scoring.form.inputs.collateralValue"
          disabled={!isKindValuable(collateral.kind)}
        />
      </InputContainer>
    </Flex>
  )
}
