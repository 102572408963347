import styled from "styled-components"

import { Box, Text } from "src/components"

export const ContentContainer = styled(Box)`
  padding: 8px 70px 0 0;
  width: 100%;
  word-break: break-word;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyDarker};
  fontsize: 16px;
  line-height: 1.5;
  word-break: break-word;
`
