import React from "react"
import memoize from "memoize-one"

import { pipe, notifyGTM } from "src/utils"
import { PageContainer, Exhibition } from "src/components"

import { isOwner } from "../utils"

import {
  MiscData,
  Controls,
  OrderInfo,
  EntityDate,
  InvestData,
  ProfileLogo,
} from "../Elements"

import { HeaderContainer, FooterContainer } from "./styles"

class GTMNotificator extends React.PureComponent<any, any> {
  // eslint-disable-next-line
  componentDidMount() {
    const { order, viewer } = this.props

    if (!order || !order.chain) return null

    const { id } = order.chain

    const { cession, application, id: orderId } = order

    const karmaPlatformNotifier = notifyGTM({
      eventAction: "project-view",
      eventLabel: id.split(".")[2],
      extras: {
        "profile-id": viewer.id,
      },
    })

    if (order.status !== "CONFIRMED") {
      karmaPlatformNotifier()
      return null
    }

    const ecommerceNotifier = notifyGTM({
      event: "productDetail",
      eventCategory: "",
      extras: {
        ecommerce: {
          detail: {
            products: [
              {
                id: orderId,
                name: application.shortTitle || application.data.companyName,
                category: cession && cession.isActive ? "Цессия" : "Первичная",
              },
            ],
          },
        },
      },
    })

    const gtmPipe = pipe(karmaPlatformNotifier, ecommerceNotifier)

    gtmPipe()
  }

  // eslint-disable-next-line
  render() {
    return null
  }
}

const Head = memoize(({ data, injected }) => {
  const isOwnLoan = isOwner(injected.viewer, data)
  return (
    <HeaderContainer>
      <GTMNotificator order={data} viewer={injected.viewer} />
      <ProfileLogo data={data} withLink={false} />
      <MiscData data={data} withLink={false} />
      <EntityDate data={data} />
      {data.viewer && !!data.viewer.investedAmount && !isOwnLoan && (
        <InvestData.proposal />
      )}
      <Controls.proposal data={data} status={data.status} isOwner={isOwnLoan} />
    </HeaderContainer>
  )
})

const Body = memoize(({ data }) => <Exhibition data={data} />)

const Footer = memoize(({ data, injected }) => (
  <FooterContainer>
    <OrderInfo data={data} viewer={injected.viewer} />
  </FooterContainer>
))

export default {
  Head,
  Body,
  Footer,
  Container: PageContainer,
}
