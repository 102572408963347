import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateSupportingDocumentsMutation(
    $input: updateSupportingDocumentsInput!
  ) {
    updateSupportingDocuments(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
