import styled from "styled-components"

import { Icons } from "src/components"

export const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: 510px;
  width: 100%;
  overflow-y: auto;
  max-height: 90vh;
  height: auto;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  padding: 40px 30px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 14px;
  padding: 0;
  display: flex;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const MobileCloseButton = styled.button`
  display: none;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primaryBlack};
  max-width: 90px;
  padding: 0;
  margin-bottom: 60px;
  line-height: 1.7;

  @media screen and (max-width: 480px) {
    display: flex;
  }
`

export const ArrowIcon = styled(Icons.Arrow)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.colors.primaryBlack};
`

export const CloseIcon = styled(Icons.Close)`
  width: 24px;
  height: 24px;
  fill: black;
`

export const Title = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryBlacks[9]};
`

export const Description = styled(Title)`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryBlacks[8]};
  margin-top: 18px;
  font-weight: 500;
  white-space: pre-line;
`
