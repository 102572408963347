import React from "react"

import { Text, Translate } from "src/components"

import { NavItem, NavContainer } from "./styles"

export default (props) => {
  const { schema, onClick, activeStep } = props

  return (
    <NavContainer>
      {schema
        && schema.map(({ step, isValid }, ii) => (isValid ? (
            <NavItem
              key={ii}
              active={activeStep === step}
              onClick={onClick(step)}
            >
              <Text fontSize="16px">
                <Translate i18n={`proposal_nav.${step}`} ns="common" />
              </Text>
            </NavItem>
        ) : null))}
    </NavContainer>
  )
}
