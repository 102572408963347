import React from "react"

import {
  Box, Text, Alert, Translate,
} from "src/components"

const Coverage = ({ coverage }) => {
  if (!coverage) return null

  return (
    <Box mb="60px">
      <Alert p="15px 25px 11px 25px" bg="greyLight" textAlign="left">
        <Text fontSize="18px" lineHeight="1.33" color="greyDarkest">
          <Translate
            coverage={coverage.toFixed()}
            i18n={`components:account.order_item.provision.${
              coverage >= 100 ? "full_coverage" : "coverage"
            }`}
          />
        </Text>
      </Alert>
    </Box>
  )
}

export default Coverage
