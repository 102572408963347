import React from "react"
import styled from "styled-components"

const GlyphContent = styled.span`
  transform: translateY(-2px);
  display: inline-block;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 13px;
  font-weight: 600;
`

const InfoGlyph = () => <GlyphContent>i</GlyphContent>

export default InfoGlyph
