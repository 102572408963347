import i18n from "src/i18n"
import React from "react"

import {
  WITHDRAWAL_FEE,
  MIN_INVESTMENT_AMOUNT,
  userAgreementUrl,
  personalDataAgreementUrl,
} from "src/constants"

import { RelayEnvironmentConsumer } from "../RelayEnvironment"

import { getLocale, setViewerLocale } from "./localeUtils"

export const LocaleContext: any = React.createContext(undefined)

const storage = {
  get: (environment, defaultLocale) => getLocale(environment, defaultLocale),
  set: (environment, locale) => setViewerLocale(environment, locale),
}

export const LocaleConsumer = LocaleContext.Consumer

const NameSpaces = [
  "common",
  "pages",
  "components",
  "errors",
  "modals",
  "models",
]

const interpolation = {
  defaultVariables: {
    WITHDRAWAL_FEE,
    MIN_INVESTMENT_AMOUNT,
    userAgreementUrl,
    personalDataAgreementUrl,
  },
}

class Provider extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      locale: null,
      translate: (...args) => (i18n as any).t(...args),
      setLocale: this.setLocale,
    }

    storage.get(this.props.environment, "ru").then((locale) => {
      i18n.init(
        {
          lng: locale,
          fallbackLng: "en",
          ns: [...NameSpaces],
          defaultNS: "common",
          interpolation,
        },
        this.setLocaleState,
      )
    })
  }

  setLocale = (locale) => {
    if (i18n.language === locale) return

    storage
      .set(this.props.environment, locale)
      .then(() => i18n.changeLanguage(locale, this.setLocaleState))
  }

  refetchViewerLocale = () => storage.get(this.props.environment, "ru").then(this.setLocale)

  setLocaleState = () => {
    const locale = i18n.language || i18n.options.fallbackLng[0]
    this.setState({ locale })
  }

  render() {
    if (this.state.locale === null) return null

    const providerInitialValue = {
      ...this.state,
      refetchViewerLocale: this.refetchViewerLocale,
    }

    return (
      <LocaleContext.Provider value={providerInitialValue}>
        {this.props.children}
      </LocaleContext.Provider>
    )
  }
}

export const LocaleProvider = (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => <Provider {...props} environment={environment} />}
  </RelayEnvironmentConsumer>
)
