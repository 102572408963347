import styled, { createGlobalStyle } from "styled-components"
import { fontSize } from "styled-system"

import {
  Box, Link, Button, CardWrapper,
} from "src/components"

export const TransactionContainer = styled(Box)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 45px;
`

export const DateBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65px;
`

export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #4a4a4a;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  position: relative;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`

export const ArrowBox = styled(DateBox)`
  width: 20px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const SubText = styled(Text)`
  color: rgba(74, 74, 74, 0.45);
`

export const StyledLin = styled(Link)`
  ${fontSize};
  cursor: pointer;
  line-height: 1.5;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.blue};
  }
`

export const StyledLink = styled(Link)`
  font-size: 1rem;
  line-height: 1.5;
  color: #3a5bbc;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  position: relative;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`

export const SenderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30%;

  @media screen and (max-width: 800px) {
    max-width: 28%;
  }

  @media screen and (max-width: 480px) {
    max-width: 40%;
  }
`

export const DestinationContainer = styled(SenderContainer)`
  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const ValueContainer = styled(SenderContainer)`
  max-width: 20%;
`

export const ValueText = styled(Text)`
  text-align: right;
  color: #5f5f5f;
  font-weight: 600;
  color: ${(props) => props.color || "#5F5F5F"};
`

export const ValueSubText = styled(ValueText)`
  color: ${(props) => props.color || "rgba(74, 74, 74, 0.45)"};
  font-weight: normal;
`

export const LoadButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 50px;
`

export const LoadButton = styled.button`
  margin: auto;
  color: #4a4a4a;
  border: none;
  padding: 0;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: bold;
`

export const LoadMoreButton = styled(Button)`
  width: calc(100% + 60px);
  height: auto;
  margin: 0 -30px -30px -30px;
  padding: 30px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.greyLight};
  border-radius: 0;
  cursor: pointer;
  text-decoration: none !important;
  text-align: center;
`

export const Container = styled(CardWrapper)`
  padding: 28px;
  font-family: Geometria;
  font-size: 1rem;
  width: 100%;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`

export const Title = styled.h4`
  font-size: 1.25rem;
  font-weight: bold;
  display: inline-block;
`

export const DownloadButton = styled.button`
  font-family: Geometria, sans-serif;
  font-weight: normal;
  font-size: 0.875rem;
  color: #3a5bbc;
  text-decoration: none;
  cursor: pointer;
  float: right;
  width: 85px;
  display: flex;
  align-items: flex-end;
  padding: 0;

  span {
    margin-right: 8px;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`

export const TooltipContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 12px 20px;
  width: 100%;
  max-width: 280px;
  color: black;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: left;
  border: none;
`

export const TooltipGlobalStyle = createGlobalStyle`
  .rc-tooltip {
    opacity: 1;
  }
  
  .rc-tooltip-inner {
    background-color: white;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
    padding: 0;
    min-height: auto;
    border-radius: 4px;
  }
  
  .rc-tooltip-arrow {
    border: none;
  }
`

export const AlertIconContainer = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
`

export const TitleContainer = styled.div`
  display: flex;
`

export const QueryLoaderContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`
