import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateExpressQuestionaryMutation(
    $input: CreateExpressQuestionaryInput!
  ) {
    createExpressQuestionary(input: $input) {
      user {
        id
        questionaries {
          id
          inn
          name
          phone
          site
          state {
            status
            reason
          }
          presentation {
            id
            url
            filename
          }
          isBelongToGroup
          groupInns
          orderData {
            amount
            term
            interest
            hasCollateral
            collaterals {
              kind
              value
            }
          }
        }
      }
    }
  }
`

export default enhance({ mutation })
