import { StatusMessageTextSchema } from "./constants"

export const showDeclineReason = (type, onClick) => type === "DECLINED"
  && onClick !== undefined
  && onClick !== null
  && typeof onClick === "function"

export const getStatusMessage = (element, status, outdated) => {
  const messageFunc = StatusMessageTextSchema.get(status)
  if (!messageFunc) {
    return null
  }
  return messageFunc(element, status, outdated)
}
