import React, { useState, useEffect } from "react"

import moment from "moment"
import i18next from "i18next"
import Relay from "react-relay"
import { Route } from "react-router"
import { toast } from "react-toastify"

import { createChainedFunction, notifyGTM } from "src/utils"
import { RussianComposedInvestorSchema } from "src/constants"

import { Content } from "../elements"
import { initialStateFromProps } from "./utils"
import fragment from "./RussianIndividualFragment"
import ProcessIndividualProfile from "./MutationContainer"

/* eslint-disable no-trailing-spaces */

/*
 *
 * В отдельном контейнере описать 3 действия:
 *   1) onSave -> сохранить в черновик +
 *   2) onCancel -> проверить dirty, предложить сохранить в черновик +
 *   3) onContinue -> сделать запрос на ???? +
 *
 * При onSave и OnContinue нужно деструктурировать state формы на IndividualProfileData и InvestorData +
 *
 * Модель для IndividualProfile:
 * {
 *   name: String!
 *   phone: String!
 * }
 *
 * Модель для SetIndividualProfile:
 * {
 *   profileId: ID!
 *   name: String!
 *   phone: String!
 * }
 *
 * Модель для InvestorData:
 * {
 *   profileId: ID!
 *   inn: String!
 *   iian: String!
 *   address: AddressInput!
 *   passport: PassportInput!
 *   birthDate: String!
 *   birthPlace: String!
 *   isPublicOfficial: Boolean!
 * }
 *
 * */

/* eslint-enable no-trailing-spaces */

const sendConfirmationGTMEvent = (viewer) => notifyGTM({
  event: "TokenConfirmation_Person",
  eventCategory: "",
  extras: {
    date: moment(viewer.createdAt).format("DDMMYYYY"),
  },
})()

const notifyToast = (type = "success") => {
  const toastFunction = toast[type]
  const toastMessage = i18next.t(`common:toasts.save_${type}`)
  toastFunction(toastMessage, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

const idFromSaveRequest = (res) => {
  const {
    setProfile,
    addIndividualProfile,
    updateIndividualProfileAsInvestor,
  } = res

  if (updateIndividualProfileAsInvestor) {
    return updateIndividualProfileAsInvestor.profile.id
  }

  if (setProfile) {
    return setProfile.profile.id
  }

  if (addIndividualProfile) {
    return addIndividualProfile.profile.id
  }

  return ""
}

const InvestorAccreditation = (props) => {
  const [isDirty, setDirty] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [isContinueLoading, setContinueLoading] = useState(false)
  const [isConfirmationLoading, setConfirmationLoading] = useState(false)
  const [showPhoneConfirmModal, setShowPhoneConfirmModal] = useState(false)

  const [formState, setFormState] = useState({
    ...initialStateFromProps(props),
  })
  const handleFormChange = (nextFormState) => setFormState({ ...nextFormState })

  const handleDirty = () => !isDirty && setDirty(true)

  const closeSubmitModal = () => setShowSubmitModal(false)
  const closePhoneConfirmModal = () => setShowPhoneConfirmModal(false)

  const onChange = createChainedFunction(handleFormChange, handleDirty)

  const process = formState.id.length > 0
    ? props.updateIndividualProfile
    : props.createIndividualProfile

  const onSave = () => {
    setSaveLoading(true)

    return process(true)()(formState)
      .then((res) => {
        const profileId = idFromSaveRequest(res)
        setFormState({
          ...formState,
          id: profileId,
        })

        notifyToast("success")
        setSaveLoading(false)
      })
      .catch((e) => {
        setSaveLoading(false)

        notifyToast("error")
        throw new Error(e)
      })
  }

  const onCancel = () => {
    if (isDirty) {
      setShowSubmitModal(true)
      return
    }

    props.history.push(`/accounts/${formState.id}`)
  }

  const onSaveCancel = () => props.history.push(`/accounts/${formState.id}`)

  const requestPhoneConfirmation = (state) => props.requestToken(state)

  const onContinue = () => {
    setContinueLoading(true)

    process(false)(notifyToast)(formState)
      .then((res) => {
        setContinueLoading(false)

        const { data, errorFromUpdate } = res

        if (errorFromUpdate) {
          const profileId = idFromSaveRequest(data)

          setFormState({
            ...formState,
            id: profileId,
          })
        } else {
          const profileId = idFromSaveRequest(res)

          setFormState({
            ...formState,
            id: profileId,
          })

          setShowPhoneConfirmModal(true)
        }
      })
      .catch(() => {
        notifyToast("error")
        setContinueLoading(false)
      })
  }

  useEffect(() => {
    if (!showPhoneConfirmModal) return

    requestPhoneConfirmation(formState)
  }, [showPhoneConfirmModal])

  const requestAccreditation = (data) => {
    setConfirmationLoading(true)

    props
      .requestAccreditation(formState.id)(data)
      .then(() => {
        sendConfirmationGTMEvent(props.viewer)
        setConfirmationLoading(false)
        props.history.push(`/accounts/${formState.id}`)
      })
      .catch(() => setConfirmationLoading(false))
  }

  const onSubmitSave = () => onSave()
    .then(() => props.history.push(`/accounts/${formState.id}`))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((e) => {})

  const commitRequestFromPhoneConfirmModal = () => props.requestToken(formState)

  return (
    <React.Fragment>
      <Content
        onSave={onSave}
        investorType="ru"
        schema={RussianComposedInvestorSchema}
        onChange={onChange}
        onCancel={onCancel}
        formState={formState}
        onContinue={onContinue}
        onSaveCancel={onSaveCancel}
        onSubmitSave={onSubmitSave}
        isSaveLoading={isSaveLoading}
        showSubmitModal={showSubmitModal}
        closeSubmitModal={closeSubmitModal}
        isContinueLoading={isContinueLoading}
        requestAccreditation={requestAccreditation}
        showPhoneConfirmModal={showPhoneConfirmModal}
        isConfirmationLoading={isConfirmationLoading}
        closePhoneConfirmModal={closePhoneConfirmModal}
        isContinueDisabled={!formState.isPublicOfficial}
        commitRequestFromPhoneConfirmModal={commitRequestFromPhoneConfirmModal}
      />
    </React.Fragment>
  )
}

const Russian = (props) => (
  <ProcessIndividualProfile>
    {(renderProps) => (
      <Route>
        {({ history }) => (
          <InvestorAccreditation
            {...props}
            {...renderProps}
            history={history}
          />
        )}
      </Route>
    )}
  </ProcessIndividualProfile>
)

export default Relay.createFragmentContainer(Russian, { profile: fragment })
