/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LocaleEnum = "EN" | "KO" | "RU" | "ZH" | "%future added value";
export type countriesQueryVariables = {
    locale: LocaleEnum;
};
export type countriesQueryResponse = {
    readonly countries: ReadonlyArray<{
        readonly code: string;
        readonly name: string;
    }>;
};
export type countriesQuery = {
    readonly response: countriesQueryResponse;
    readonly variables: countriesQueryVariables;
};



/*
query countriesQuery(
  $locale: LocaleEnum!
) {
  countries(locale: $locale) {
    code
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale",
    "type": "LocaleEnum!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "locale",
        "variableName": "locale"
      }
    ],
    "concreteType": "CountryEntry",
    "kind": "LinkedField",
    "name": "countries",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "countriesQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "countriesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "countriesQuery",
    "operationKind": "query",
    "text": "query countriesQuery(\n  $locale: LocaleEnum!\n) {\n  countries(locale: $locale) {\n    code\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ebfb5b0d07ef98694b053739c63d91cb';
export default node;
