import React from "react"

import { OrderDataConsumer } from "src/context"
import { number } from "src/utils"
import { Box, Title, Translate } from "src/components"

import {
  ArrowIcon,
  AmountTitle,
  AmountValue,
  AmountValueContainer,
  AmountTransactionRemaind,
} from "../styles"

const Invest = (props) => {
  const { order, options } = props

  const { status, viewer } = order

  const { investedAmount } = viewer

  return (
    <Box>
      <AmountTitle>
        <Title>
          <Translate i18n="components:order_list.investments.item.invested.amount.header" />
        </Title>
      </AmountTitle>
      <AmountValueContainer>
        <AmountValue variant="black">
          {number(investedAmount, options)} ₽ <ArrowIcon />
        </AmountValue>
      </AmountValueContainer>
      {status === "CONFIRMED" && (
        <AmountTransactionRemaind>
          <Translate i18n="components:order_list.investments.item.invested.amount.help" />
        </AmountTransactionRemaind>
      )}
    </Box>
  )
}

export default (props) => (
  <OrderDataConsumer>
    {(order) => <Invest order={order} {...props} />}
  </OrderDataConsumer>
)
