import styled, { css } from "styled-components"

export * from "./wrappers"

export const MenusWrapper = styled.div`
  position: relative;
  margin-top: 60px;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 800px) {
    justify-content: space-between;
  }
`

export const MenuItem = styled.div<any>`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textBlacks[8]};
  cursor: pointer;
  padding-bottom: 14px;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 30px;
  }

  ${(props) => props.disabled
    && css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.textBlacks[2]};
    `}

  ${(props) => props.isActive
    && css`
      font-weight: bold;
      border-bottom: 2px solid #f70000;
    `};

  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 6px;
    margin: 0;
  }
`

export const MobileMenuWrapper = styled.div`
  position: absolute;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
`

export const MobileMenuItem = styled.div<any>`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Geometria;
  font-size: 18px;
  line-height: 48px;
  align-items: center;
  color: ${({ theme }) => theme.colors.textBlacks[8]};
  padding: 0 14px;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
  font-weight: 300;

  ${(props) => props.disabled
    && css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.textBlacks[2]};
    `}

  ${(props) => props.isActive
    && css`
      font-weight: 600;
      background: #f5f5f5;
    `}

  ${(props) => props.button
    && css`
      font-weight: 600;
      background: #f5f5f5;
      border-radius: 10px;
    `}
`

export const MobileMenuDivider = styled.div`
  width: 100%;
  padding-top: 1px;
  background-color: ${({ theme }) => theme.colors.primaryBlacks[1]};
  margin-bottom: 8px;
`
