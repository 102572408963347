import React from "react"

import { PageContainer } from "src/components"

import { OrderInfo, CheckList, Table } from "./Elements"

import {
  Container,
  ReturnButton,
  TitleContainer,
  TextContainer,
  Title,
  Description,
  Illustration,
  DealsContainer,
  DealsTitle,
  DealsAmount,
  ReturnIcon,
} from "./styles"

function BorrowerRating() {
  const ratingIndex = 6 // Test

  return (
    <PageContainer>
      <Container>
        <ReturnButton>
          <ReturnIcon />
          Назад
        </ReturnButton>

        <OrderInfo />

        <Table ratingIndex={ratingIndex} />

        <DealsContainer>
          <DealsTitle>Кол-во сделок на Карме</DealsTitle>
          <DealsAmount>0</DealsAmount>
          <DealsAmount isActive>1-2</DealsAmount>
          <DealsAmount>2-5</DealsAmount>
          <DealsAmount>5-10</DealsAmount>
          <DealsAmount>10+</DealsAmount>
        </DealsContainer>

        <TitleContainer>
          <Illustration />

          <TextContainer>
            <Title>Чтобы подать заявку, пройдите финансовый анализ</Title>
            <Description>
              Также, вы можете предоставить информацию по обеспечению, что
              дополнительно увеличит ваш скоринг балл
            </Description>
          </TextContainer>
        </TitleContainer>

        <CheckList />
      </Container>
    </PageContainer>
  )
}

export default BorrowerRating
