import React from "react"
import i18next from "i18next"
import memoize from "memoize-one"

import {
  pipe,
  number,
  normalizePaymentItem,
  subtractScheduleItems,
  getPaymentsBalanceReport,
  getPaymentSummaryByStatus,
} from "src/utils"

import { CurrencySigns } from "src/constants"

import { EmptyBody, EmptyHeading } from "./styles"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const typeMap = new Map([
  [
    "investor",
    [
      "date",
      "total",
      "loan",
      "interest",
      "tax",
      "commission",
      "paid",
      "leftover_with_exchange",
      "status",
    ],
  ],
  ["debtor", ["date", "total", "loan", "interest", "leftover", "status"]],
  [
    "preschedule",
    [
      "month",
      "total",
      "loan",
      "interest",
      "tax",
      "commission",
      "paid",
      "leftover",
    ],
  ],
  [
    "preschedule_cession",
    [
      "date",
      "total",
      "loan",
      "interest",
      "tax",
      "commission",
      "paid",
      "leftover",
    ],
  ],
])

const header = [
  {
    name: "payoff_date",
    title:
      "components:account.order_item.payment_schedule.table.payoff_date.title",
  },
  {
    name: "payoff_date_cession",
    title:
      "components:account.order_item.payment_schedule.table.payoff_date.title",
  },
  {
    name: "month",
    title:
      "components:account.order_item.payment_schedule.table.payment_date.title",
  },
  {
    name: "date",
    title:
      "components:account.order_item.payment_schedule.table.payment_date.title",
  },
  {
    name: "total",
    title: "components:account.order_item.payment_schedule.table.total.title",
  },
  {
    name: "loan",
    title: "components:account.order_item.payment_schedule.table.loan.title",
  },
  {
    name: "interest",
    title:
      "components:account.order_item.payment_schedule.table.interest.title",
  },
  {
    name: "tax",
    title: "components:account.order_item.payment_schedule.table.tax.title",
  },
  {
    name: "commission",
    title:
      "components:account.order_item.payment_schedule.table.commission.title",
  },
  {
    name: "paid",
    title: "components:account.order_item.payment_schedule.table.paid.title",
  },
  {
    name: "leftover",
    title:
      "components:account.order_item.payment_schedule.table.leftover.title",
  },
  {
    name: "leftover_with_exchange",
    title:
      "components:account.order_item.payment_schedule.table.leftover.title",
  },
  {
    name: "status",
    title: "components:account.order_item.payment_schedule.table.status.title",
  },
  {
    name: "empty",
    component: () => <EmptyHeading width="130px" />,
  },
]

const buildAmount = (amount, currency) => {
  const currencySign = CurrencySigns[currency]
  return `${number(amount[currency], options)} ${currencySign}`
}

/* TODO: unused - вернуть или удалить окончательно
const getExchangeTooltip = (currency, payment) => {
  if (currency === "RUB") return {}

  return {
    tooltip: () => {
      const { exchangeRates } = payment
      const rate = exchangeRates.find((item) => item.currency === currency)

      if (!rate) return null

      return (
        <ExchangeTooltipBody
          rate={rate}
          state={payment.state}
          date={payment.date.split("-").reverse().join(".")}
        />
      )
    },
  }
} */

const buildRow = (paymentRaw, summary, idx, duration, currency) => {
  const payment = normalizePaymentItem(paymentRaw)
  const leftover = subtractScheduleItems(summary.loan, payment.paid)

  return [
    {
      name: "payoff_date",
      title: i18next.t(
        "components:account.order_item.payment_schedule.table.month.title",
        { count: duration },
      ),
    },
    {
      name: "payoff_date_cession",
      title: payment.date && payment.date.split("-").reverse().join("."),
    },
    {
      name: "month",
      title: i18next.t(
        "components:account.order_item.payment_schedule.table.month.title",
        { count: idx + 1 },
      ),
    },
    {
      name: "date",
      title: payment.date && payment.date.split("-").reverse().join("."),
    },
    {
      name: "total",
      title: buildAmount(payment.total_gross, currency),
    },
    {
      name: "loan",
      title: buildAmount(payment.loan, currency),
    },
    {
      name: "interest",
      title: buildAmount(payment.interest, currency),
    },
    {
      name: "commission",
      title: `- ${buildAmount(payment.interest_fee, currency)}`,
    },
    {
      name: "tax",
      title: `~ ${buildAmount(payment.personal_tax, currency)}`,
    },
    {
      name: "paid",
      title: `${buildAmount(payment.total, currency)}`,
    },
    {
      name: "leftover_with_exchange",
      title: `${buildAmount(leftover, currency)}`,
    },
    {
      name: "leftover",
      title: `${buildAmount(leftover, currency)}`,
    },
    {
      name: "status",
      title: payment.state,
    },
    {
      name: "empty",
      component: () => <EmptyBody width="130px" />,
    },
  ]
}

const buildSummaryRow = (summary, currency) => [
  {
    name: "payoff_date",
    title: i18next.t(
      "components:account.order_item.payment_schedule.table.summary.title",
    ),
  },
  {
    name: "payoff_date_cession",
    title: i18next.t(
      "components:account.order_item.payment_schedule.table.summary.title",
    ),
  },
  {
    name: "month",
    title: i18next.t(
      "components:account.order_item.payment_schedule.table.summary.title",
    ),
  },
  {
    name: "date",
    title: i18next.t(
      "components:account.order_item.payment_schedule.table.summary.title",
    ),
  },
  {
    name: "total",
    title: buildAmount(summary.total_gross, currency),
  },
  {
    name: "loan",
    title: buildAmount(summary.loan, currency),
  },
  {
    name: "interest",
    title: buildAmount(summary.interest, currency),
  },
  {
    name: "commission",
    title: `- ${buildAmount(summary.interest_fee, currency)}`,
  },
  {
    name: "tax",
    title: `~ ${buildAmount(summary.personal_tax, currency)}`,
  },
  {
    name: "paid",
    title: buildAmount(summary.total, currency),
  },
]

const filterByType = (type) => (items) => {
  const fields = typeMap.get(type)
  return items.filter((item) => fields.includes(item.name))
}

const noopMap = (value) => value
const removePersonalTax = (items) => items.filter((item) => item.name !== "tax")
const removeCommission = (items) => items.filter((item) => item.name !== "commission")

const getSchema = memoize((payments, type, duration, viewerCurrency) => {
  const summary = getPaymentSummaryByStatus(payments)
  const paymentsWithBalance = getPaymentsBalanceReport(payments)
  const rows = paymentsWithBalance.map((payment, idx) => buildRow(payment, summary, idx, duration, viewerCurrency))
  const personalTaxFormatter = +summary.personal_tax
    ? noopMap
    : removePersonalTax
  const interestFeeFormatter = +summary.interest_fee
    ? noopMap
    : removeCommission
  const rowMapFn = pipe(
    filterByType(type),
    personalTaxFormatter,
    interestFeeFormatter,
  )

  return {
    header: rowMapFn(header),
    body: [...rows, [], buildSummaryRow(summary, viewerCurrency)].map(rowMapFn),
  }
})

export default getSchema
