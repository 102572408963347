/* eslint-disable max-len */

import React from "react"

function Calendar(props) {
  return (
    <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z" />
      <path d="M3.625 7.1875H2.375V8.4375H3.625V7.1875Z" />
      <path d="M6.125 7.1875H4.875V8.4375H6.125V7.1875Z" />
      <path d="M8.625 7.1875H7.375V8.4375H8.625V7.1875Z" />
      <path d="M11.125 7.1875H9.875V8.4375H11.125V7.1875Z" />
      <path d="M13.625 7.1875H12.375V8.4375H13.625V7.1875Z" />
      <path d="M3.625 9.6875H2.375V10.9375H3.625V9.6875Z" />
      <path d="M6.125 9.6875H4.875V10.9375H6.125V9.6875Z" />
      <path d="M8.625 9.6875H7.375V10.9375H8.625V9.6875Z" />
      <path d="M11.125 9.6875H9.875V10.9375H11.125V9.6875Z" />
      <path d="M3.625 12.1875H2.375V13.4375H3.625V12.1875Z" />
      <path d="M6.125 12.1875H4.875V13.4375H6.125V12.1875Z" />
      <path d="M8.625 12.1875H7.375V13.4375H8.625V12.1875Z" />
      <path d="M11.125 12.1875H9.875V13.4375H11.125V12.1875Z" />
      <path d="M13.625 9.6875H12.375V10.9375H13.625V9.6875Z" />
    </svg>
  )
}

Calendar.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Calendar
