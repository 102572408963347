import React from "react"

import { FormBuilder, ScrollToTopOnMount } from "src/components"

const getStep = (schema) => class Step extends React.Component<any, any> {
  render() {
    const { environment, value, onChange } = this.props
    return (
        <>
          <ScrollToTopOnMount />
          <FormBuilder
            environment={environment}
            schema={schema}
            value={value}
            onChange={onChange}
          />
        </>
    )
  }
}

export default getStep
