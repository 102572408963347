/* eslint-disable max-len */

import React from "react"

function Clock(props) {
  return (
    <svg {...props} viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4811 3.97052C11.7957 2.3726 10.631 1.21048 9.01977 0.52329C8.21445 0.179831 7.37075 0.00439453 6.48932 0.00439453C5.60803 0.00439453 4.77055 0.178174 3.96613 0.52329C2.36821 1.20866 1.19836 2.37003 0.511758 3.97052C0.167704 4.77236 0 5.61241 0 6.49369C0 7.37514 0.167704 8.22215 0.511758 9.02416C1.19836 10.6246 2.36836 11.793 3.96613 12.4785C4.7707 12.8236 5.60801 12.9973 6.48932 12.9973C7.37062 12.9973 8.21445 12.8218 9.01964 12.4785C10.6309 11.7913 11.7956 10.6219 12.4811 9.02416C12.8262 8.21958 13 7.37514 13 6.49369C13 5.61241 12.8264 4.77507 12.4811 3.97052ZM11.4435 9.36535C10.9332 10.2404 10.2342 10.9346 9.35382 11.4479C8.47876 11.9581 7.51997 12.2154 6.48946 12.2154C5.71477 12.2154 4.97556 12.0662 4.27182 11.7606C3.5681 11.455 2.9605 11.0533 2.45222 10.5452C1.94395 10.0369 1.5394 9.4234 1.23689 8.71847C0.935278 8.01611 0.789113 7.26855 0.789113 6.49371C0.789113 5.46305 1.04234 4.50913 1.54972 3.63649C2.05891 2.76067 2.75808 2.06572 3.63225 1.55394C4.50626 1.04219 5.45883 0.786221 6.48932 0.786221C7.51997 0.786221 8.47859 1.04369 9.35367 1.55394C10.2341 2.06723 10.9316 2.76216 11.4433 3.63647C11.9551 4.51063 12.2109 5.46305 12.2109 6.49369C12.2109 7.52436 11.9568 8.48479 11.4435 9.36535Z" />
      <path d="M6.90161 6.43602V2.7542C6.90161 2.53388 6.70964 2.35617 6.4893 2.35617C6.28324 2.35617 6.09839 2.53388 6.09839 2.7542V6.49287C6.09839 6.5 6.11264 6.52851 6.11264 6.54975C6.09839 6.67771 6.13403 6.78433 6.21925 6.8697L8.34456 8.99486C8.48678 9.13707 8.74971 9.13707 8.89182 8.99486C9.05525 8.8314 9.0445 8.59423 8.89182 8.44047L6.90161 6.43602Z" />
    </svg>
  )
}

Clock.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Clock
