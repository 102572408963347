import React from "react"

import {
  InputContainer,
  InputBox,
  Input,
  Label,
  Button,
  ShareContainer,
  ShareButton,
  Telegram,
  Facebook,
  WhatsUp,
  LinkedIn,
  Messenger,
} from "./styles"

const InputBlock = React.forwardRef((props: any, ref) => {
  const {
    label, btn, disabledBtn, onClick, withShare, ...rest
  } = props

  return (
    <InputContainer>
      <InputBox>
        <Label>
          {label}
          <Input ref={ref} {...rest} />
        </Label>
        <Button onClick={onClick} disabled={disabledBtn}>
          {btn}
        </Button>
      </InputBox>

      {withShare && (
        <ShareContainer>
          <ShareButton>
            <Telegram />
          </ShareButton>
          <ShareButton>
            <Messenger />
          </ShareButton>
          <ShareButton>
            <Facebook />
          </ShareButton>
          <ShareButton>
            <LinkedIn />
          </ShareButton>
          <ShareButton>
            <WhatsUp />
          </ShareButton>
        </ShareContainer>
      )}
    </InputContainer>
  )
})

export default InputBlock
