import styled from "styled-components"

export const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Wrapper = styled(Flex)`
  width: 100%;
  margin-top: 60px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`
