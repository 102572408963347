import styled from "styled-components"

import OutlinedButton from "../../../../atoms/OutlinedButton"

import {
  TelegramIcon,
  FacebookIcon,
  LinkedInIcon,
  MessengerIcon,
  WhatsUpIcon,
} from "../../icons"

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  width: 100%;
  padding: 50px 45px;
  margin-top: 40px;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 550px) {
    margin-top: 60px;
    padding: 30px 14px;
  }
`

export const Text = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
  align-self: center;

  @media screen and (max-width: 1150px) {
    width: 473px;
    margin: 40px 0;
    align-self: auto;
  }

  @media screen and (max-width: 550px) {
    width: 100%;
    text-align: center;
  }
`

export const Flex = styled.div`
  position: relative;
  display: flex;
`

export const InputBox = styled(Flex)`
  width: 100%;
  @media screen and (max-width: 550px) {
    width: 100%;
    flex-direction: column;
  }
`

export const InputContainer = styled(InputBox)`
  flex-direction: column;
`

export const Input = styled.input`
  position: relative;
  background: #ffffff;
  border: 1px solid rgba(95, 95, 95, 0.35);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 45px;
  padding: 14px 18px;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 20px 0 0;

  &:focus {
    border: 1px solid rgba(95, 95, 95, 0.75);
  }

  &::placeholder {
    color: rgba(95, 95, 95, 0.45);
  }

  @media screen and (max-width: 550px) {
    height: 60px;
    font-size: 16px;
    line-height: 19px;
    margin-top: 30px;
  }
`

export const Label = styled.label`
  width: 100%;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 18px 0 0;

  @media screen and (max-width: 550px) {
    width: 100%;
    text-align: center;
  }
`

export const Button = styled(OutlinedButton)`
  width: 165px;
  height: 45px;
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  align-self: flex-end;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mainRed};

  @media screen and (max-width: 550px) {
    width: 100%;
    font-size: 16px;
    line-height: 44px;
    height: 60px;
    margin-top: 20px;
  }
`

export const ShareContainer = styled(Flex)`
  margin-top: 14px;
  
  @media screen and (max-width: 550px) {
    margin: 34px auto 0;
`

export const ShareButton = styled.button`
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  border: none;

  &:not(:last-child) {
    margin-right: 12px;
  }

  @media screen and (max-width: 550px) {
    width: 44px;
    height: 44px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const Telegram = styled(TelegramIcon)`
  width: 100%;
  height: 100%;
`

export const WhatsUp = styled(WhatsUpIcon)`
  width: 100%;
  height: 100%;
`

export const Facebook = styled(FacebookIcon)`
  width: 100%;
  height: 100%;
`

export const LinkedIn = styled(LinkedInIcon)`
  width: 100%;
  height: 100%;
`

export const Messenger = styled(MessengerIcon)`
  width: 100%;
  height: 100%;
`
