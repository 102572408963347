import i18n from "src/i18n"

export default [
  {
    exact: true,
    path: "/registration",
    title: () => i18n.t("common:titles.registration"),
  },
  {
    exact: true,
    path: "/registration/complete",
    title: () => i18n.t("common:titles.email_confirmation"),
  },
  {
    exact: true,
    path: "/login",
    title: () => i18n.t("common:titles.login"),
  },
  {
    exact: true,
    path: "/password",
    title: () => i18n.t("common:titles.password"),
  },
  {
    path: /^\/accounts\/create/i,
    title: () => i18n.t("common:titles.create_investor"),
  },
  {
    path: /^\/(accounts|profiles)\/[A-Za-z0-9]+\/accreditation/i,
    title: () => i18n.t("common:titles.accreditation"),
  },
  {
    path: /^\/accounts\/[A-Za-z0-9]+/i,
    title: (params) => {
      const tab = params.queryParams.type

      switch (tab) {
        case "history":
          return i18n.t("common:titles.transactions")
        case "bank":
          return i18n.t("common:titles.requisites")
        default:
          return i18n.t("common:titles.profiles")
      }
    },
  },
  {
    exact: true,
    path: "/market",
    title: () => i18n.t("common:titles.market"),
  },
  {
    path: /^\/market\/[A-Za-z0-9]+/i,
    title: () => i18n.t("common:titles.offer"),
  },
  {
    exact: true,
    path: "/orders/my/investments",
    title: () => i18n.t("common:titles.investments"),
  },
  {
    exact: true,
    path: "/orders/my/payments",
    title: () => i18n.t("common:titles.payments"),
  },
  {
    exact: true,
    path: "/orders/my/loans",
    title: () => i18n.t("common:titles.loans"),
  },
  {
    exact: true,
    path: "/orders/my/analytics",
    title: "Аналитика",
  },
  {
    exact: true,
    path: "/orders/my/autoinvest",
    title: "Автоинвестирование",
  },
  {
    path: /^\/(new-order|orders\/[A-Za-z0-9]+)/i,
    title: () => i18n.t("common:titles.order"),
  },
  {
    path: "/about",
    title: () => i18n.t("common:titles.about"),
  },
  {
    path: "/profiles/create/juristic",
    title: () => i18n.t("common:titles.create_borrower"),
  },
]
