export function getCurrentProfile(data) {
  const { profiles } = data

  if (!Array.isArray(profiles)) {
    return null
  }

  const currentProfileId = window.localStorage.getItem("activeProfile")

  const currentProfile = profiles.filter(
    (profile) => profile.id === currentProfileId,
  )
  if (currentProfile.length < 1) {
    return profiles[0]
  }

  return currentProfile[0]
}
