import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation AddProfileBankAccountMutation($input: AddProfileBankAccountInput!) {
    addProfileBankAccount(input: $input) {
      profile {
        ... on IndividualProfile {
          id
          bankAccounts {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
        }
        ... on EntrepreneurProfile {
          id
          bankAccounts {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
        }
        ... on LegalEntityProfile {
          id
          bankAccounts {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
        }
        ... on ForeignIndividualProfile {
          id
          bankAccounts {
            id
            status
            checkingAccount
            correspondentAccount
            bank {
              bic
              name
            }
          }
          foreignBankAccounts: allBankAccounts {
            ... on BankAccount {
              id
              status
              checkingAccount
              correspondentAccount
              bank {
                bic
                name
              }
            }
            ... on ForeignBankAccount {
              id
              status
              IBAN
              ownerName
              foreignBank: bank {
                name
                address
                SWIFT
              }
            }
          }
        }
      }
    }
  }
`
export default enhance({ mutation })
