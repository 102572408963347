import React from "react"
import { AttachmentLink } from "src/components"

import { Box, Link, Empty } from "./styles"

function AttachmentField(props) {
  const { value } = props.data

  return (
    <Box>
      {value && (
        <AttachmentLink
          component={Link}
          attachmentId={value.id}
        >
          {value.filename}
        </AttachmentLink>
      )}
      {!value && <Empty>Заполните поле</Empty>}
    </Box>
  )
}

export default AttachmentField
