const Address = {
  country: "",
  region: "",
  area: "",
  locality: "",
  street: "",
  house: "",
  housing: "",
  structure: "",
  flat: "",
}

const IssuedBy = {
  code: "",
  name: "",
}

const Passport = {
  number: "",
  series: "",
  issuedAt: "",
  scans: [],
  issuedBy: { ...IssuedBy },
  postponeScans: false,
}

const InitialState = {
  id: "",
  lastName: "",
  firstName: "",
  middleName: "",
  phone: "",
  inn: "",
  psrnie: "",
  iian: "",
  address: { ...Address },
  passport: { ...Passport },
  approvedOnce: false,
  birthDate: "",
  birthPlace: "",
  isPublicOfficial: false,
  isRussiaTaxResident: true,
  sex: "MALE",
}

export default InitialState
