import styled from "styled-components"
import { space } from "styled-system"

export const MenusWrapper = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`

export const MenusItem = styled.div<any>`
  font-family: Geometria, sans-serif;
  font-size: 15px;
  line-height: 19px;
  padding-bottom: 4px;
  color: #5f5f5f;
  font-weight: ${(props) => (props.isActive && "bold") || "600"};
  cursor: pointer;
  border-bottom: ${(props) => (props.isActive && "3px solid #F70000") || "none"};
  margin: 12px 12px 0 0;

  @media screen and (min-width: 650px) {
    ${space};
  }
`

export const Badge = styled.span<any>`
  display: inline;
  position: relative;

  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme, isActive }) => (isActive ? "#6FA84B" : theme.colors.mainRed)};
  }
`
