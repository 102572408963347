import React from "react"
import memoize from "memoize-one"
import PropTypes from "prop-types"

import {
  Text, FlexBox, CheckBox, Translate,
} from "src/components"

const ID = "personal-information-consent-check"

const label = memoize(() => (
  <Translate i18n="personal-information-consent-check.label" ns="components" />
))

const onChange = (onChangeFn) => (event) => {
  const { name, checked } = event.target
  if (onChangeFn) {
    onChangeFn({ name, checked, value: checked })
  }
}

const render = (props) => (
  <FlexBox alignItems="center" pt={40}>
    <FlexBox justifyContent="flex-end" pr={20}>
      <CheckBox
        id={ID}
        name={props.name}
        checked={props.checked}
        onChange={onChange(props.onChange)}
      />
    </FlexBox>
    <Text
      as="label"
      htmlFor={ID}
      fontSize="1.125rem"
      whiteSpace="pre-line"
      fontWeight="600"
    >
      {label()}
    </Text>
  </FlexBox>
)

render.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default render
