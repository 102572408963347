import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetOrderKarmaTrustedStatusMutation(
    $input: SetOrderKarmaTrustedStatusInput!
  ) {
    setOrderKarmaTrustedStatus(input: $input) {
      order {
        id
      }
    }
  }
`

export default enhance({ mutation })
