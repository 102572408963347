import React, { useState, useCallback, useMemo } from "react"
import { graphql } from "react-relay"
import { useHistory } from "react-router"

import { FloatingLabelInput, QueryRenderer } from "src/components"
import { MutationContainer } from "src/containers"
import { RequestChangeEmailConfirmation } from "src/mutations"
import { useEnvironment } from "src/hooks"

import { Button, Title } from "../../../../molecules/NewPasswordModal/styles"
import { ArrowContainer } from "../../../../../pages/common/join/investor/Welcome/styles"
import { ArrowIcon } from "../../../../../pages/common/join/investor/icons"

import {
  Container,
  SelectContainer,
  SelectLabel,
  StyledSelect,
} from "../styles"
import { EMAIL, initialValues, submitTypes } from "./constants"

const ChangeEmail = (props) => {
  const {
    className, viewer, commit, clearError, getError,
  } = props
  const { profiles = [] } = viewer

  const history = useHistory()
  const { environment } = useEnvironment()

  const accreditedProfile = useMemo(
    () => profiles.find(({ accreditation }) => accreditation.status === "APPROVED"),
    [profiles],
  )

  const [isPending, setPending] = useState(false)

  const [values, setValues] = useState(initialValues)
  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target
      setValues({
        ...values,
        [name]: value,
      })
      clearError(`requestChangeEmailConfirmation.${name}`)
    },
    [values, setValues, clearError],
  )

  const onError = useCallback(() => {
    setPending(false)
  }, [setPending])

  const onCompleted = useCallback(
    (res) => {
      setPending(false)

      if (res?.requestChangeEmailConfirmation?.status) {
        history.push("/email/confirmation")
      }
    },
    [history, setPending],
  )

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setPending(true)

      const profileId = accreditedProfile
        ? accreditedProfile.id
        : profiles?.[0]?.id
      const sendOnEmail = values.submitType === EMAIL || !accreditedProfile

      const callbacks = {
        onError,
        onCompleted,
      }
      const variables = {
        input: {
          profileId,
          sendOnEmail,
          newEmail: values.email,
        },
      }

      commit({ variables, callbacks, environment })
    },
    [
      accreditedProfile,
      values,
      commit,
      environment,
      setPending,
      onError,
      onCompleted,
      profiles,
    ],
  )

  return (
    <Container className={className}>
      <Title>Сменить E-mail</Title>

      <form onSubmit={handleSubmit}>
        <FloatingLabelInput
          value={values.email}
          onChange={handleChange}
          name="email"
          type="email"
          disabled={isPending}
          required
          withoutBorder
          errorColor="mainRed"
          label="Новый E-mail"
          error={getError("requestChangeEmailConfirmation.email")}
          hasError={getError("requestChangeEmailConfirmation.email")}
        />

        <SelectContainer>
          <SelectLabel htmlFor="submitType">Способ подтверждения</SelectLabel>
          <StyledSelect
            value={values.submitType}
            onChange={handleChange}
            options={submitTypes}
            name="submitType"
            disabled={isPending || !accreditedProfile}
            required
          />
          <ArrowContainer>
            <ArrowIcon />
          </ArrowContainer>
        </SelectContainer>

        <Button
          type="submit"
          disabled={isPending || !values.email || !values.submitType}
        >
          Изменить
        </Button>
      </form>
    </Container>
  )
}

const query = graphql`
  query ChangeEmailQuery {
    viewer {
      profiles {
        id
        __typename
        ... on LegalEntityProfile {
          accreditation(role: BORROWER) {
            status
          }
        }
        ... on IndividualProfile {
          accreditation(role: INVESTOR) {
            status
          }
        }
        ... on EntrepreneurProfile {
          accreditation(role: INVESTOR) {
            status
          }
        }
        ... on ForeignIndividualProfile {
          accreditation(role: INVESTOR) {
            status
          }
        }
      }
    }
  }
`

const MutationWrapper = (props) => (
  <MutationContainer mutation={RequestChangeEmailConfirmation}>
    {({ commit, getError, clearError }) => (
      <ChangeEmail
        {...props}
        commit={commit}
        getError={getError}
        clearError={clearError}
      />
    )}
  </MutationContainer>
)

const Render = () => <QueryRenderer query={query} render={MutationWrapper} />

export default Render
