import React from "react"

import { OrderDataConsumer, OrderTypeConsumer } from "src/context"

import Tooltip from "./Tooltip"
import indicatorSchema from "./indicatorSchema"

const ensure = (minValuePercents, gatheredPercents) => (
  argsFromComposedConsumer,
) => {
  const { order, orderType } = argsFromComposedConsumer
  const { status } = order
  const elementsByOrderType = indicatorSchema.get(orderType)
  if (!elementsByOrderType) return null

  const elements = elementsByOrderType.get(status)
  if (!elements) return null

  const { Content, Indicator } = elements

  return (
    <React.Fragment>
      {gatheredPercents < 100 && Indicator && (
        <Indicator gathered={minValuePercents} />
      )}
      {gatheredPercents < 100 && Content && (
        <Tooltip minValuePercents={minValuePercents} content={Content} />
      )}
    </React.Fragment>
  )
}

const ComposedConsumer = (props) => {
  const { children } = props

  return (
    <OrderTypeConsumer>
      {(orderType) => (
        <OrderDataConsumer>
          {(order) => children({ orderType, order })}
        </OrderDataConsumer>
      )}
    </OrderTypeConsumer>
  )
}

const Indicator = (props) => {
  const { minValuePercents, gatheredPercents } = props

  return (
    <ComposedConsumer>
      {ensure(minValuePercents, gatheredPercents)}
    </ComposedConsumer>
  )
}

export default Indicator
