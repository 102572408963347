import React from "react"

import styled from "styled-components"

import {
  Box,
  Text,
  Icon,
  Button,
  Translate,
  FileExtensionIcon,
} from "src/components"

import { IconContainer, ButtonWrapper, FileDownloadWrapper } from "./styles"

const DownloadLink = styled.a<any>`
  font-size: 14px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`

const DownLoadButton = (props) => (
  <ButtonWrapper>
    <DownloadLink
      href={props.link}
      download={props.fileName}
      variant="default"
      height={30}
    >
      <Button variant="default" height={30}>
        <Text fontSize={13}>
          <Translate i18n="download" ns="common" />
        </Text>
        <IconContainer>
          <Icon>arrow-down</Icon>
        </IconContainer>
      </Button>
    </DownloadLink>
  </ButtonWrapper>
)

class FileDownloadButton extends React.Component<any> {
  render() {
    return (
      <FileDownloadWrapper>
        <Box pr={20}>
          <FileExtensionIcon size={40}>
            {this.props.extension}
          </FileExtensionIcon>
        </Box>
        <Text fontSize="15px">{this.props.fileName}</Text>
        <DownLoadButton {...this.props} />
      </FileDownloadWrapper>
    )
  }
}

export default FileDownloadButton
