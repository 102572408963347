import { graphql } from "react-relay"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetViewerEmailMutation($input: SetViewerEmailInput!) {
    setViewerEmail(input: $input) {
      viewer {
        id
        email
      }
    }
  }
`

export default enhance({ mutation })
