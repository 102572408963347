import React from "react"
import { toast } from "react-toastify"

import i18n from "src/i18n"
import { useMutation } from "src/hooks"
import { ErrorsContainer } from "src/containers"
import { PurposedMutationProvider } from "src/context"
import { CreateExpressQuestionary } from "src/mutations"
import { Box, Translate, FormBuilder } from "src/components"

import schema from "./inputs"
import { StyledButton } from "./styles"

const initialState = {
  inn: "",
  name: "",
  site: "",
  amount: "",
  term: "",
  interest: "",
  phone: "",
  isBelongToGroup: false,
  groupInns: [""],
  hasCollateral: false,
  collaterals: [{ value: "", kind: "ESTATE" }],
  presentation: null,
}

const formatInn = (inn) => inn.replace(/\D/g, "")

const buildInput = (formState) => {
  const {
    inn,
    amount,
    term,
    interest,
    hasCollateral,
    collaterals,
    groupInns,
    presentation,
    ...rest
  } = formState

  return {
    ...rest,
    inn: formatInn(inn),
    groupInns: rest.isBelongToGroup ? groupInns.map(formatInn) : [],
    presentation: presentation ? presentation.id : null,
    orderData: {
      term,
      amount,
      interest,
      hasCollateral,
      collaterals: hasCollateral ? collaterals : [],
    },
  }
}

const ExpressScoringForm = (props) => {
  const [busy, setBusy] = React.useState(false)
  const [formState, onChange] = React.useState(initialState)

  const mutation = useMutation(CreateExpressQuestionary)

  mutation.onCompleted((data) => {
    setBusy(false)
    props.onCompleted(data)
  })

  mutation.onError((e) => {
    setBusy(false)
    props.setErrors(e)

    const message = i18n.t("common:toasts.save_error")
    toast.error(message)
  })

  const onSubmit = React.useCallback(() => {
    setBusy(true)
    const input = { input: buildInput(formState) }
    mutation.commit(input)
  }, [formState])

  return (
    <Box>
      <FormBuilder
        value={formState}
        onChange={onChange}
        inputsSchemaType="new"
        schema={schema}
      />
      <StyledButton isLoading={busy} variant="blueWide" onClick={onSubmit}>
        <Translate i18n="modals:express-scoring.form.save" />
      </StyledButton>
    </Box>
  )
}

export default (props) => (
  <ErrorsContainer>
    {(errors) => (
      <PurposedMutationProvider value={["createExpressQuestionary"]}>
        <ExpressScoringForm {...errors} {...props} />
      </PurposedMutationProvider>
    )}
  </ErrorsContainer>
)
