import React from "react"

import {
  Box,
  Button,
  Translate,
  BankDataSuggestion,
  FloatingLabelInput as FormInput,
} from "src/components"
import { compose } from "src/utils"

import inputs from "./inputs"
import { clearFormError } from "./utils"

import { InputsContainer } from "../styles"

const AccountForm = (props) => {
  const {
    busy,
    valid,
    account,
    onClick,
    onChange,
    getError,
    clearError,
    onSuggestionSelected,
  } = props

  const errorPrefix = account.id ? "updateBankAccount" : "addProfileBankAccount"
  const clear = clearFormError(clearError)

  return (
    <>
      <InputsContainer>
        {inputs.map((input) => {
          const {
            name, suggest, errorPath, daDataSelector,
            mask,
          } = input

          const path = `${errorPrefix}.${errorPath}`
          const onChangeDecorated = compose(clear(path), onChange)

          return suggest ? (
            <BankDataSuggestion
              key={name}
              name={name}
              mask={mask}
              value={account[name]}
              error={getError(path)}
              onChange={onChangeDecorated}
              daDataSelector={daDataSelector}
              label={`components:account.bank_account.edit.placeholders.${name}`}
              renderInputComponent={(inputProps) => (
                <FormInput {...inputProps} />
              )}
              onSuggestionSelected={onSuggestionSelected}
            />
          ) : (
            <FormInput
              key={name}
              name={name}
              mask={mask}
              value={account[name]}
              error={getError(path)}
              onChange={onChangeDecorated}
              label={`components:account.bank_account.edit.placeholders.${name}`}
            />
          )
        })}
      </InputsContainer>
      <Box>
        <Button
          variant="outlinedRed"
          height="35px"
          width="175px"
          disabled={!valid || busy}
          onClick={onClick}
          fontSize="0.625rem"
        >
          <Translate
            ns="components"
            i18n="account.bank_account.edit.buttons.save"
          />
        </Button>
      </Box>
    </>
  )
}

export default AccountForm
