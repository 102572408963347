import React from "react"

import {
  Box, Flex, Text, Translate,
} from "src/components"

import InvestedByKarmaIcon from "./elements"

const InvestedByKarma = () => (
  <Flex alignItems="center" mb="15px">
    <Box mr="7px">
      <InvestedByKarmaIcon />
    </Box>
    <Text
      color="red"
      fontSize="12px"
      fontWeight="500"
      textTransform="uppercase"
    >
      <Translate i18n="components:order_list.invested_by_karma" />
    </Text>
  </Flex>
)

export default InvestedByKarma
