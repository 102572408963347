import React from "react"
import i18next from "i18next"
import memoize from "memoize-one"

import { Translate } from "src/components"

import { Error, Input } from "./elements"

import { InputWrapper, InputContainer, InputTooltipContainer } from "./styles"

const translate = memoize((key) => (typeof key === "string" ? i18next.t(key) : ""))

// TODO: возможно описание типов не полное, доделать позднее.
interface IFloatingLabelInputProps {
  withoutBorder: boolean,
  type: string,
  name: string,
  value: string,
  mask?: string,
  label: string,
  error: string,
  errorColor: string,
  hasError: boolean,
  mb: string,
  tooltip: () => JSX.Element,
  onBlur: () => void,
  onChange: () => void,
  onFocus: () => void,
  onKeyDown: () => void
}

const FloatingLabelInput = React.forwardRef((props: any, ref) => {
  const {
    mb,
    value,
    error,
    label,
    onBlur,
    onFocus,
    hasError,
    errorColor,
    placeholder,
    withoutBorder,
    tooltip: Tooltip,
    ...inputProps
  } = props

  return (
    <InputContainer mb={mb} maxWidth={props.width}>
      <InputWrapper
        maxWidth={props.width}
        hasError={hasError}
        errorColor={errorColor}
        withoutBorder={withoutBorder}
      >
        <Input
          id={props.name + label}
          className="material__input"
          value={value !== null ? value : ""}
          placeholder={translate(placeholder)}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={ref}
          {...inputProps}
        />
        <label htmlFor={props.name + label} className="material__label">
          <Translate i18n={label} />
        </label>
        {Tooltip && (
          <InputTooltipContainer>
            <Tooltip />
          </InputTooltipContainer>
        )}
      </InputWrapper>
      <Error variant={errorColor} error={error} />
    </InputContainer>
  )
})

FloatingLabelInput.defaultProps = {
  errorColor: "red",
  onBlur: () => {},
  onFocus: () => {},
  mb: "20px",
}

export default FloatingLabelInput
