import React from "react"

import { compose } from "src/utils"

import Input from "./style"
import LandingInput from "./Landing"

/* eslint-disable no-param-reassign */
const onBlur = (event) => {
  event.target.readOnly = true
  return event
}

const onFocus = (event) => {
  event.target.readOnly = false
  return event
}

/* eslint-enable no-param-reassign */
const preventAutocompleteProps = {
  onBlur,
  onFocus,
  readOnly: true,
}

const render = React.forwardRef((props: any, ref) => {
  let localProps = { ...props }

  if (props.autoComplete === "off") {
    const {
      onBlur: preventedBlur,
      onFocus: preventedFocus,
    } = preventAutocompleteProps
    const composedOnBlur = props.onBlur
      ? compose(props.onBlur, preventedBlur)
      : preventedBlur

    const composedOnFocus = props.onFocus
      ? compose(props.onFocus, preventedFocus)
      : preventedFocus

    localProps = {
      ...props,
      ...preventAutocompleteProps,
      onBlur: composedOnBlur,
      onFocus: composedOnFocus,
    }
  }

  if (props.size) {
    localProps = {
      ...props,
      width: "auto",
    }
  }

  return <Input {...localProps} ref={ref} />
});

(render as any).landing = LandingInput

export default render
