import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetViewerPasswordMutation($input: SetViewerPasswordInput!) {
    setViewerPassword(input: $input) {
      viewer {
        id
        ...Form_viewer
      }
    }
  }
`

export default enhance({ mutation })
