/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type CreateOrderInfoQueryVariables = {
    id: string;
};
export type CreateOrderInfoQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly createdAt?: string;
        readonly status?: OrderStatusEnum | null;
        readonly profile?: {
            readonly id: string;
            readonly creditRating: {
                readonly rating: string;
            } | null;
            readonly _avatar: {
                readonly url: string;
            } | null;
            readonly name?: string;
            readonly inn?: string | null;
            readonly ogrn?: string | null;
            readonly executive?: {
                readonly name: string;
            } | null;
        };
        readonly offer?: {
            readonly id: string;
            readonly data: string;
            readonly declineReason: string | null;
            readonly receivedAt: string | null;
            readonly attachments: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly filename: string;
            }>;
        } | null;
        readonly application?: {
            readonly id: string;
            readonly data: string;
            readonly declineReason: string | null;
            readonly receivedAt: string | null;
            readonly attachments: ReadonlyArray<{
                readonly id: string;
                readonly url: string;
                readonly filename: string;
            }>;
            readonly externalMedium: ReadonlyArray<{
                readonly video: {
                    readonly url: string;
                };
            }>;
        } | null;
        readonly confirmedAt?: string | null;
        readonly fee?: number | null;
        readonly chain?: {
            readonly id: string;
            readonly gatheredAmount: number;
            readonly investorsCount: number;
        } | null;
        readonly creditRating?: {
            readonly rating: string;
        } | null;
        readonly otherDocuments?: ReadonlyArray<{
            readonly attachment: {
                readonly url: string;
                readonly filename: string;
            };
            readonly description: string;
        }>;
        readonly skipOffer?: boolean;
        readonly guarantors?: ReadonlyArray<{
            readonly attachment: {
                readonly url: string;
                readonly filename: string;
            };
            readonly name: string | null;
            readonly description: string | null;
        }>;
        readonly karma?: {
            readonly conclusion: string | null;
            readonly isInvestor: boolean;
        };
        readonly financialStatements?: ReadonlyArray<{
            readonly name: string;
            readonly year: number;
            readonly attachment: {
                readonly url: string;
            };
        }>;
    };
};
export type CreateOrderInfoQuery = {
    readonly response: CreateOrderInfoQueryResponse;
    readonly variables: CreateOrderInfoQueryVariables;
};



/*
query CreateOrderInfoQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on Order {
      createdAt
      status
      profile {
        __typename
        id
        ... on LegalEntityProfile {
          name
          inn
          ogrn
          executive {
            name
          }
        }
        creditRating {
          rating
        }
        _avatar {
          url
        }
      }
      offer {
        id
        data
        declineReason
        receivedAt
        attachments {
          id
          url
          filename
        }
      }
      application {
        id
        data
        declineReason
        receivedAt
        attachments {
          id
          url
          filename
        }
        externalMedium {
          video {
            url
          }
          id
        }
      }
      confirmedAt
      fee
      chain {
        id
        gatheredAmount
        investorsCount
      }
      creditRating {
        rating
      }
      otherDocuments {
        attachment {
          url
          filename
          id
        }
        description
      }
      skipOffer
      guarantors {
        attachment {
          url
          filename
          id
        }
        name
        description
      }
      karma {
        conclusion
        isInvestor
      }
      financialStatements {
        name
        year
        attachment {
          url
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditRating",
  "kind": "LinkedField",
  "name": "creditRating",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "_avatar",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ogrn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Person",
      "kind": "LinkedField",
      "name": "executive",
      "plural": false,
      "selections": [
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "LegalEntityProfile"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "declineReason",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receivedAt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderOffer",
  "kind": "LinkedField",
  "name": "offer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ExternalMediaVideo",
  "kind": "LinkedField",
  "name": "video",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmedAt",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderChainInfo",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatheredAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investorsCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "skipOffer",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderKarma",
  "kind": "LinkedField",
  "name": "karma",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "conclusion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInvestor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v15/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalMedia",
                    "kind": "LinkedField",
                    "name": "externalMedium",
                    "plural": true,
                    "selections": [
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderDocument",
                "kind": "LinkedField",
                "name": "otherDocuments",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGuarantor",
                "kind": "LinkedField",
                "name": "guarantors",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v10/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FinancialStatement",
                "kind": "LinkedField",
                "name": "financialStatements",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalMedia",
                    "kind": "LinkedField",
                    "name": "externalMedium",
                    "plural": true,
                    "selections": [
                      (v18/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderDocument",
                "kind": "LinkedField",
                "name": "otherDocuments",
                "plural": true,
                "selections": [
                  (v27/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderGuarantor",
                "kind": "LinkedField",
                "name": "guarantors",
                "plural": true,
                "selections": [
                  (v27/*: any*/),
                  (v10/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v25/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FinancialStatement",
                "kind": "LinkedField",
                "name": "financialStatements",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachment",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateOrderInfoQuery",
    "operationKind": "query",
    "text": "query CreateOrderInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on Order {\n      createdAt\n      status\n      profile {\n        __typename\n        id\n        ... on LegalEntityProfile {\n          name\n          inn\n          ogrn\n          executive {\n            name\n          }\n        }\n        creditRating {\n          rating\n        }\n        _avatar {\n          url\n        }\n      }\n      offer {\n        id\n        data\n        declineReason\n        receivedAt\n        attachments {\n          id\n          url\n          filename\n        }\n      }\n      application {\n        id\n        data\n        declineReason\n        receivedAt\n        attachments {\n          id\n          url\n          filename\n        }\n        externalMedium {\n          video {\n            url\n          }\n          id\n        }\n      }\n      confirmedAt\n      fee\n      chain {\n        id\n        gatheredAmount\n        investorsCount\n      }\n      creditRating {\n        rating\n      }\n      otherDocuments {\n        attachment {\n          url\n          filename\n          id\n        }\n        description\n      }\n      skipOffer\n      guarantors {\n        attachment {\n          url\n          filename\n          id\n        }\n        name\n        description\n      }\n      karma {\n        conclusion\n        isInvestor\n      }\n      financialStatements {\n        name\n        year\n        attachment {\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2491ed78e934c388518541e12fd8c773';
export default node;
