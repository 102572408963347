import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query CreateOrderInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Order {
        createdAt
        status
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              inn
              ogrn
              executive {
                name
              }
            }
            creditRating {
              rating
            }
            _avatar {
              url
            }
          }
        }
        offer {
          ... on OrderOffer {
            id
            data
            declineReason
            receivedAt
            attachments {
              ... on Attachment {
                id
                url
                filename
              }
            }
          }
        }
        application {
          ... on OrderApplication {
            id
            data
            declineReason
            receivedAt
            attachments {
              ... on Attachment {
                id
                url
                filename
              }
            }
          }
          externalMedium {
            video {
              url
            }
          }
        }
        confirmedAt
        fee
        chain {
          id
          gatheredAmount
          investorsCount
        }
        creditRating {
          rating
        }
        otherDocuments {
          attachment {
            url
            filename
          }
          description
        }
        skipOffer
        guarantors {
          attachment {
            url
            filename
          }
          name
          description
        }
        karma {
          conclusion
          isInvestor
        }
        financialStatements {
          name
          year
          attachment {
            url
          }
        }
      }
    }
  }
`

const container = (render) => (props) => {
  const { orderId, ...rest } = props

  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
