/* eslint-disable max-len */

import React from "react"

function Contact(props) {
  return (
    <svg {...props} viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.40587 13.0781C9.9116 13.0781 11.1322 11.8573 11.1322 10.3517C11.1322 8.846 9.9116 7.62537 8.40587 7.62537C6.90014 7.62537 5.6795 8.846 5.6795 10.3517C5.68148 11.8566 6.90096 13.0761 8.40587 13.0781ZM8.40587 8.68533C9.32905 8.68533 10.0776 9.43366 10.0776 10.357C10.0776 11.2802 9.32905 12.0287 8.40587 12.0287C7.48253 12.0287 6.73419 11.2802 6.73419 10.357C6.73452 9.43383 7.48269 8.68566 8.40587 8.68533Z" />
      <path d="M8.40586 14.0853C7.29036 14.0771 6.21969 14.5248 5.44219 15.3246C4.65117 16.1314 4.21875 17.2283 4.21875 18.4201C4.21957 18.7109 4.45523 18.9466 4.74609 18.9474H12.0656C12.3565 18.9466 12.5921 18.7109 12.593 18.4201C12.593 17.2283 12.1605 16.1314 11.3695 15.3246C10.592 14.5248 9.52152 14.0771 8.40586 14.0853ZM5.31035 17.8927C5.40066 17.2032 5.71146 16.5615 6.19629 16.0629C6.77983 15.4724 7.57562 15.14 8.40586 15.14C9.2361 15.14 10.0319 15.4724 10.6154 16.0629C11.0993 16.562 11.4101 17.2036 11.5014 17.8927H5.31035Z" />
      <path d="M23.5195 3.95508H3.48047C2.02451 3.95574 0.844409 5.13583 0.84375 6.5918V19.9863C0.844409 21.4423 2.02451 22.6224 3.48047 22.623H23.5195C24.9755 22.6224 26.1556 21.4423 26.1562 19.9863V6.5918C26.1556 5.13583 24.9755 3.95574 23.5195 3.95508ZM25.1016 19.9863C25.0991 20.8589 24.3921 21.5659 23.5195 21.5684H3.48047C2.60788 21.5659 1.90091 20.8589 1.89844 19.9863V6.5918C1.90091 5.71921 2.60788 5.01224 3.48047 5.00977H23.5195C24.3921 5.01224 25.0991 5.71921 25.1016 6.5918V19.9863Z" />
      <path d="M22.2223 12.7617H16.0787C15.7875 12.7617 15.5514 12.9977 15.5514 13.2891C15.5514 13.5804 15.7875 13.8164 16.0787 13.8164H22.2223C22.5135 13.8164 22.7496 13.5804 22.7496 13.2891C22.7496 12.9977 22.5135 12.7617 22.2223 12.7617Z" />
      <path d="M22.2223 16.1367H16.0787C15.7875 16.1367 15.5514 16.3727 15.5514 16.6641C15.5514 16.9554 15.7875 17.1914 16.0787 17.1914H22.2223C22.5135 17.1914 22.7496 16.9554 22.7496 16.6641C22.7496 16.3727 22.5135 16.1367 22.2223 16.1367Z" />
      <path d="M22.2223 9.38672H16.0787C15.7875 9.38672 15.5514 9.62271 15.5514 9.91406C15.5514 10.2054 15.7875 10.4414 16.0787 10.4414H22.2223C22.5135 10.4414 22.7496 10.2054 22.7496 9.91406C22.7496 9.62271 22.5135 9.38672 22.2223 9.38672Z" />
    </svg>
  )
}

Contact.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Contact
