import styled from "styled-components"

export const TitleWrap = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 21px;
  margin-top: 90px;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }

  @media screen and (max-width: 960px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 20px;
  }
`

export const Title = styled.h3`
  font-family: Geometria, sans-serif;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;

  @media screen and (max-width: 960px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const TitleSearch = styled.div`
  margin-left: auto;

  @media screen and (max-width: 800px) {
    display: none;
  }
`
