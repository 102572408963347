import React from "react"
import { Route } from "react-router"

const QueryParamsContext = React.createContext(undefined)

class QueryParamsApi extends React.Component<any, any> {
  static getSearchQuery = () => new URLSearchParams(window.location.search)

  constructor(props) {
    super(props)

    this.state = {
      getQueryParam: this.getQueryParam,
      setQueryParam: this.setQueryParam,
      deleteQueryParam: this.deleteQueryParam,
    }
  }

  getQueryParam = (name) => {
    const query = QueryParamsApi.getSearchQuery()

    return query.get(name)
  }

  setQueryParam = (name, value) => {
    const query = QueryParamsApi.getSearchQuery()
    query.set(name, value)

    const params = query.toString()

    this.props.history.push({
      search: `?${params}`,
    })

    return query
  }

  deleteQueryParam = (name) => {
    const query = QueryParamsApi.getSearchQuery()
    query.delete(name)

    const params = query.toString()

    this.props.history.push({
      search: `?${params}`,
    })

    return query
  }

  render() {
    const { children } = this.props

    return (
      <QueryParamsContext.Provider value={this.state}>
        {typeof children === "function"
          ? children(this.state)
          : children || null}
      </QueryParamsContext.Provider>
    )
  }
}

export const QueryParamsProvider = (props) => (
  <Route>
    {({ history }) => <QueryParamsApi {...props} history={history} />}
  </Route>
)

const provideQuery = (children) => (context) => (context ? (
  children(context)
) : (
    <QueryParamsProvider>{children}</QueryParamsProvider>
))

export const QueryParamsConsumer = (props) => {
  const { children } = props

  return (
    <QueryParamsContext.Consumer>
      {provideQuery(children)}
    </QueryParamsContext.Consumer>
  )
}
