import React from "react"

import {
  Box, Button, Translate, DocumentViewer,
} from "src/components"

import { StyledLink, SpacedSubHeader, DescriptionContainer } from "./styles"

const PresentationItem = React.memo(({ item }: any) => (
  <Box mb="30px">
    <DocumentViewer url={item.url} width="730px" height="410px" />
    <Box mt="10px">
      <StyledLink href={item.url} target="_blank">
        <Button variant="default" height="32px">
          <Translate i18n="pages:user.proposal.buttons.save_presentation" />
        </Button>
      </StyledLink>
    </Box>
  </Box>
))

export const Presentations = ({ items }) => {
  if (!items || !items.length) return null

  return (
    <Box mt="50px">
      <SpacedSubHeader>
        <Translate i18n="pages:user.proposal.titles.presentation" />
      </SpacedSubHeader>
      {items.map((item) => (
        <PresentationItem key={item.id} item={item} />
      ))}
    </Box>
  )
}

export const Description = ({ children }) => {
  if (!children) return null

  return (
    <>
      <SpacedSubHeader>
        <Translate i18n="pages:user.proposal.titles.description" />
      </SpacedSubHeader>
      <DescriptionContainer>{children}</DescriptionContainer>
    </>
  )
}
