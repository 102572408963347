import React from "react"

import { Button, Translate } from "src/components"

import { showDeclineReason } from "../utils"

const DeclineReasonWithModal = (props) => {
  const { status, onClick } = props

  return showDeclineReason(status, onClick) ? (
    <Button variant="empty" onClick={onClick} height="12px">
      <Translate i18n="account.link" ns="components" />
    </Button>
  ) : null
}

export default DeclineReasonWithModal
