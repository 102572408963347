import React, { useContext, useMemo } from "react"

import { CurrentProfileContext } from "src/context"
import { Translate, MiniLoader } from "src/components"
import { getOrderTicker, toCurrencyFormat, getDebtURL } from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardBoldValue,
  CardStatus,
  CardStatusValue,
  CardButton,
  CardButtonsWrapper,
  Graph,
  CardCover,
  DebtLink,
} from "./styles"
import { CalendarIcon } from "./icons"

import {
  getOrderName,
  getOrderDuration,
  getOrderRate,
  getPaymentAmount,
  getOrdernFinishDate,
} from "../Tables/utils"
import { statuses } from "../Tables/InvestmentCompletedTable"

function InvestmentCompletedCard(props) {
  const { toProposal, openSellModal } = props
  const status = statuses.get(
    props.paymentScheduleList[0] && props.paymentScheduleList[0].info.state,
  ) || {
    text: "Неизвестно",
    color: "grey",
  }

  const isSellEnabled = useMemo(() => !!props.karma?.purchase?.isEnable, [
    props.karma,
  ])

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  const isPastDue = props.paymentScheduleList[0] && props.paymentScheduleList[0].info.state === "pastdue"

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          {getOrderDuration(props) && (
            <>
              {getOrderDuration(props)} <Translate i18n={"models:loan.month"} />
            </>
          )}
          {!getOrderDuration(props) && "_"}
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(props.amount)}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardBoldValue>
          {toCurrencyFormat(getPaymentAmount(props, profileId))}
        </CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>{getOrdernFinishDate(props)}</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={status.color}>
        {isPastDue
          ? <DebtLink href={getDebtURL(props.chain?.id)} target="_blank" rel="noreferrer noopener">
            <Graph>{CalendarIcon}</Graph>
              {status.text}
          </DebtLink>
          : <>
            <Graph>{CalendarIcon}</Graph>
            {status.text}
          </>}
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton compact={isSellEnabled} onClick={() => toProposal(props)}>
          Подробнее
        </CardButton>

        {isSellEnabled && !!openSellModal && (
          <CardButton primary compact onClick={openSellModal(props)}>
            Продать
          </CardButton>
        )}
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0%</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          0 <Translate i18n={"models:loan.month"} />
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color="#6FA84B">
          <Graph>{CalendarIcon}</Graph>
          Выплаты по графику
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

InvestmentCompletedCard.placeholder = Placeholder

export default InvestmentCompletedCard
