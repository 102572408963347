import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled.label`
  position: relative;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};
  margin: 0;
  width: 100%;
`

export const Input = styled.input<{ withLabel?: boolean; withAdornment?: boolean }>`
  position: relative;
  width: 100%;
  max-height: 60px;
  height: 100%;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 16px;
  line-height: 20px;
  font-family: Geometria, sans-serif;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[3]};
  padding: 22px 18px 18px;
  margin: 0;

  :focus {
    border: 2px solid #0066ff;
    box-shadow: 0 1px 6px rgba(0, 19, 47, 0.25);
    padding: 21px 17px 17px;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ withLabel }) => withLabel
    && css`
      margin-top: 8px;
    `};

  ${({ withAdornment }) => withAdornment
    && css`
      padding-right: 48px;
    `};
`

export const Adornment = styled.div`
  position: absolute;
  right: 1px;
  top: 42px;
  height: 28px;
  width: 48px;
  padding: 4px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: Geometria, sans-serif;
  z-index: 2;
`
