import React, { useMemo, FC } from "react"
import { Node } from "slate"

import { getRenderedValue } from "./utils"

type RenderProps = {
  render?: string | Node[]
}

const Render: FC<RenderProps> = (props) => {
  const { render } = props

  const serializedRender = useMemo(() => getRenderedValue(render), [render])

  return (
    <>
      {serializedRender.map((item, idx) => (
        <div key={`${item}/${idx}`} dangerouslySetInnerHTML={{ __html: item }} />
      ))}
    </>
  )
}

export default Render
