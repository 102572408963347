/* eslint-disable max-len */

import React from "react"

export const TelegramIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
      fill="#039BE5"
    />
    <path
      d="M5.71981 12.2291L17.7719 7.58226C18.3313 7.38018 18.8198 7.71872 18.6386 8.56456L18.6396 8.56351L16.5875 18.2312C16.4354 18.9166 16.0281 19.0833 15.4583 18.7604L12.3333 16.4573L10.8261 17.9093C10.6594 18.076 10.5188 18.2166 10.1958 18.2166L10.4177 15.0364L16.2094 9.80414C16.4615 9.58226 16.1531 9.45726 15.8208 9.6781L8.66356 14.1843L5.57814 13.2218C4.90835 13.0093 4.89376 12.5521 5.71981 12.2291Z"
      fill="white"
    />
  </svg>
)

export const FacebookIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
      fill="#3B5998"
    />
    <path
      d="M15.6425 12.9894H13.412V21.1608H10.0327V12.9894H8.42543V10.1176H10.0327V8.25925C10.0327 6.93032 10.6639 4.84937 13.4421 4.84937L15.9453 4.85984V7.64737H14.1291C13.8311 7.64737 13.4122 7.79622 13.4122 8.43015V10.1203H15.9377L15.6425 12.9894Z"
      fill="white"
    />
  </svg>
)

export const WhatsUpIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5031 0H12.4969C5.60469 0 0 5.60625 0 12.5C0 15.2344 0.88125 17.7687 2.37969 19.8266L0.821875 24.4703L5.62656 22.9344C7.60313 24.2438 9.96094 25 12.5031 25C19.3953 25 25 19.3922 25 12.5C25 5.60781 19.3953 0 12.5031 0Z"
      fill="#4CAF50"
    />
    <path
      d="M19.7766 17.6515C19.475 18.5031 18.2781 19.2094 17.3234 19.4156C16.6703 19.5547 15.8172 19.6656 12.9453 18.475C9.27187 16.9531 6.90625 13.2203 6.72187 12.9781C6.54531 12.7359 5.2375 11.0015 5.2375 9.20779C5.2375 7.41404 6.14843 6.5406 6.51562 6.1656C6.81718 5.85779 7.31562 5.71716 7.79375 5.71716C7.94843 5.71716 8.0875 5.72498 8.2125 5.73123C8.57968 5.74685 8.76406 5.76873 9.00625 6.34841C9.30781 7.07498 10.0422 8.86873 10.1297 9.0531C10.2187 9.23748 10.3078 9.48748 10.1828 9.72966C10.0656 9.97966 9.9625 10.0906 9.77812 10.3031C9.59375 10.5156 9.41875 10.6781 9.23437 10.9062C9.06562 11.1047 8.875 11.3172 9.0875 11.6844C9.3 12.0437 10.0344 13.2422 11.1156 14.2047C12.5109 15.4469 13.6422 15.8437 14.0469 16.0125C14.3484 16.1375 14.7078 16.1078 14.9281 15.8734C15.2078 15.5719 15.5531 15.0719 15.9047 14.5797C16.1547 14.2265 16.4703 14.1828 16.8016 14.3078C17.1391 14.425 18.925 15.3078 19.2922 15.4906C19.6594 15.675 19.9016 15.7625 19.9906 15.9172C20.0781 16.0719 20.0781 16.7984 19.7766 17.6515Z"
      fill="#FAFAFA"
    />
  </svg>
)

export const LinkedInIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.5 24.999C19.4036 24.999 25 19.4026 25 12.499C25 5.59546 19.4036 -0.000976562 12.5 -0.000976562C5.59644 -0.000976562 0 5.59546 0 12.499C0 19.4026 5.59644 24.999 12.5 24.999Z"
        fill="#007AB9"
      />
      <path
        d="M19.9686 13.5056V18.6591H16.9808V13.851C16.9808 12.6437 16.5494 11.8193 15.4676 11.8193C14.642 11.8193 14.1516 12.3743 13.935 12.9118C13.8563 13.1039 13.8361 13.3706 13.8361 13.64V18.6589H10.848C10.848 18.6589 10.8881 10.5155 10.848 9.67259H13.8363V10.946C13.8303 10.9561 13.8218 10.9659 13.8164 10.9754H13.8363V10.946C14.2334 10.335 14.9415 9.46157 16.5291 9.46157C18.4949 9.46157 19.9686 10.7459 19.9686 13.5056ZM7.7222 5.34088C6.70011 5.34088 6.03141 6.01181 6.03141 6.8933C6.03141 7.75607 6.68072 8.44638 7.68298 8.44638H7.70237C8.74452 8.44638 9.39249 7.75607 9.39249 6.8933C9.37266 6.01181 8.74452 5.34088 7.7222 5.34088ZM6.209 18.6591H9.19596V9.67259H6.209V18.6591Z"
        fill="#F1F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const MessengerIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.5 0.00249188C5.74501 -0.130698 0.155423 5.226 0.00119019 11.9805C0.0188154 15.4188 1.54127 18.6769 4.16743 20.8963V24.4793C4.16743 24.7669 4.40061 25.0001 4.68823 25.0001C4.78583 25.0001 4.88148 24.9726 4.96423 24.9209L7.86707 23.1076C9.34603 23.6732 10.9165 23.9617 12.5 23.9585C19.2549 24.0917 24.8445 18.735 24.9987 11.9805C24.8445 5.22601 19.2549 -0.130698 12.5 0.00249188Z"
        fill="#2196F3"
      />
      <path
        d="M20.7284 8.54324C20.5734 8.33555 20.2893 8.2734 20.0618 8.39741L14.6457 11.3502L11.2762 8.46093C11.0696 8.2838 10.7614 8.29556 10.569 8.48802L4.31961 14.7374C4.11685 14.9414 4.11782 15.2712 4.32186 15.4739C4.48498 15.6361 4.7351 15.672 4.93727 15.5623L10.3534 12.6095L13.726 15.4998C13.9326 15.677 14.2408 15.6652 14.4332 15.4727L20.6826 9.22335C20.8647 9.03953 20.8842 8.74986 20.7284 8.54324Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="25" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

TelegramIcon.defaultProps = {
  width: "25px",
  height: "25px",
}

FacebookIcon.defaultProps = {
  width: "25px",
  height: "25px",
}

WhatsUpIcon.defaultProps = {
  width: "25px",
  height: "25px",
}

LinkedInIcon.defaultProps = {
  width: "25px",
  height: "25px",
}

MessengerIcon.defaultProps = {
  width: "25px",
  height: "25px",
}
