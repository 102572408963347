import styled from "styled-components"

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  #app-root {
    overflow: hidden;
  }
`
export const WindowContainer = styled.div`
  position: relative;
  font-family: Geometria;
  margin: auto;
  max-width: 600px;
  width: 100%;
  height: auto;
  min-height: 480px;
  background: #ffffff;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  padding: 30px;
`

export const Header = styled.div`
  display: flex;
`

export const Image = styled.img`
  margin: 0 auto;
  height: auto;
  width: 160px;
`

export const ImageContainer = styled.div`
  box-sizing: content-box;
  padding: 16px 0;
  margin-right: 60px;
`

export const TextWrapper = styled.div`
  display: inline-block;
  padding-top: 16px;
`

export const Title = styled.p`
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  color: #f70000;
  margin: 0;
`

export const Reason = styled.p`
  width: 264px;
  height: 41px;
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  margin-top: 14px;
  color: #4a4a4a;
  font-weight: bold;
`

export const CloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: inherit;
`

export const Helper = styled.p`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  margin: 0;
  margin-top: 50px;
`

export const EditBtn = styled.button`
  background-color: inherit;
  border: 1px solid #f70000;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  padding: 12px;
  color: #f70000;
`
export const Description = styled.p`
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  margin: 40px 0 12px 0;
`

export const Tooltip = styled.p`
  margin: 6px 0 0 25px;
  font-weight: lighter;
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
`

export const CheckboxWrapper = styled.div`
  margin-top: 60px;
`

export const Label = styled.p`
  font-weight: bold;
  display: inline-block;
  margin: 0;
  margin-left: 8px;
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  color: rgba(74, 74, 74, 0.75);
`

export const CheckboxLoader = styled.div`
  width: 17px;
  height: 18px;
  display: inline-block;
  vertical-align: text-bottom;
  padding: 7px 0;
  box-sizing: border-box;
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 200px;
  box-sizing: border-box;
`
