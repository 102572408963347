import Password from "./Password"
import Registration from "./Registration"
import IndividualProfile from "./IndividualProfile"
import LegalEntityProfile from "./LegalEntityProfile"
import TrustedAccount from "./Trusted"
import Email from "./Email"

const render: any = () => null

render.password = Password
render.registration = Registration
render.individualProfile = IndividualProfile
render.legalEntityProfile = LegalEntityProfile
render.trusted = TrustedAccount
render.email = Email

export default render
