import styled from "styled-components"

import { Box, Button } from "src/components"

import DefaultModal from "../DefaultModal"

export const StyledButton = styled(Button)`
  height: 32px !important;
  font-size: ${({ fontSize }) => fontSize};
  margin-right: auto;
`

export const TextContainer = styled(Box)`
  margin-top: 5px;
  font-size: 12px;
  white-space: pre-line;
  text-align: center;
`

export const ButtonContainer = styled(Box)`
  position: relative;
`

export const ModalContainer = styled(Box)`
  width: 100%;
  max-width: 560px;
  padding: 40px;

  @media (max-width: 479px) {
    padding: 40px 15px 0 15px;
  }
`

export const StyledDefaultModal = styled(DefaultModal)`
  @media (max-width: 479px) {
    width: 100% !important;
  }
`
