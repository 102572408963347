/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type User_viewer = {
    readonly id: string;
    readonly email: string;
    readonly country: string;
    readonly trust: {
        readonly isAvailable: boolean;
    };
    readonly profiles: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly approvedAsInvestor: boolean;
        readonly accountBalanceDetailed: ReadonlyArray<{
            readonly currency: CurrencyEnum;
            readonly available: number;
        }>;
        readonly investor: {
            readonly canBeInvestor: boolean;
        } | null;
        readonly name?: string;
        readonly accountBalance?: number;
        readonly bankAccounts?: ReadonlyArray<{
            readonly id: string;
            readonly status: BankAccountStatusEnum;
        }>;
        readonly accreditation?: {
            readonly status: ProfileAccreditationStatus | null;
        };
        readonly phone?: string | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"AccountMenu_viewer" | "ExpressQuestionary_viewer">;
    readonly " $refType": "User_viewer";
};
export type User_viewer$data = User_viewer;
export type User_viewer$key = {
    readonly " $data"?: User_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"User_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountBalance",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bankAccounts",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "User_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrust",
      "kind": "LinkedField",
      "name": "trust",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAvailable",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profiles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "approvedAsInvestor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProfileAccountBalance",
          "kind": "LinkedField",
          "name": "accountBalanceDetailed",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canBeInvestor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/)
          ],
          "type": "IndividualProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v7/*: any*/)
          ],
          "type": "ForeignIndividualProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v8/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/)
          ],
          "type": "EntrepreneurProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v8/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "role",
                  "value": "BORROWER"
                }
              ],
              "concreteType": "Accreditation",
              "kind": "LinkedField",
              "name": "accreditation",
              "plural": false,
              "selections": (v6/*: any*/),
              "storageKey": "accreditation(role:\"BORROWER\")"
            }
          ],
          "type": "LegalEntityProfile"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountMenu_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpressQuestionary_viewer"
    }
  ],
  "type": "User"
};
})();
(node as any).hash = 'afeee26d76d6dcf9d844e2cf72e762ae';
export default node;
