import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query InvestmentInfoQuery($id: ID!) {
    node(id: $id) {
      ...InstructionsModal_order
      ...PenaltiesInfo_order
      id
      __typename
      ... on Order {
        createdAt
        status
        statusFrom
        skipOffer
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              inn
            }
            _avatar {
              url
            }
          }
        }
        chain {
          id
          gatheredAmount
          investorsCount
        }
        offer {
          ... on OrderOffer {
            id
            data
          }
        }
        otherDocuments {
          attachment {
            url
            filename
          }
          description
        }
        guarantors {
          attachment {
            url
            filename
          }
          name
          description
        }
        karma {
          isInvestor
        }
        application {
          ... on OrderApplication {
            id
            data
          }
        }
        creditRating {
          rating
        }
        cession {
          due
          amount
          isActive
          interestRate
          borrowerName
          originalChainId
          avatar {
            url
          }
          borrowerRating {
            rating
            ratedAt
          }
        }
        borrowerOffer: document(type: BORROWER_OFFER) {
          url
          type
        }
        ...InstructionsModal_order
        confirmedAt
        viewer {
          hasIntent
          investedAmount
        }
        paymentScheduleList {
          info {
            state
          }
          profile {
            id
            __typename
          }
          foreignProfile {
            id
            __typename
          }
          items {
            state
            total
            total_gross
            total_details {
              currency
              amount
            }
            total_gross_details {
              currency
              amount
            }
          }
        }
        provision {
          items {
            kind
          }
        }
      }
    }
  }
`

const container = (render) => ({ orderId, ...rest }) => {
  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
