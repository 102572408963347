import React from "react"

import {
  Box,
  Text,
  FlexBox,
  Translate,
  TextField,
  FormLoadingButton,
} from "src/components"

export const Header = () => (
  <>
    <Box pb={86}>
      <Text fontSize={55} textAlign="center">
        <Translate i18n="order_approval_request.header" ns="modals" />
      </Text>
    </Box>
    <Box pt={60}>
      <Text fontSize={30} textAlign="center">
        <Translate i18n="order_approval_request.title" ns="modals" />
      </Text>
    </Box>
    <Box mt={10}>
      <Text fontSize={14} textAlign="center" whiteSpace="pre-line">
        <Translate i18n="order_approval_request.hint" ns="modals" />
      </Text>
    </Box>
  </>
)

export const TokenInput = (props) => (
  <FlexBox display="flex" justifyContent="center" pt={40}>
    <TextField
      name="token"
      onChange={props.onChange}
      value={props.token}
      label={
        <span>
          <Translate i18n="order_approval_request.token_input" ns="modals" />
        </span>
      }
    />
  </FlexBox>
)

export const ControlsContainer = (props) => (
  <FlexBox
    flexDirection="column"
    alignItems="center"
    mr="auto"
    ml="auto"
    pt={40}
  >
    {props.children}
  </FlexBox>
)

export const ContinueButton = (props) => (
  <Box mt={15}>
    <FormLoadingButton
      isLoading={props.isLoading}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <Translate
        i18n="accreditation.individual.buttons.continue"
        ns="components"
      />
    </FormLoadingButton>
  </Box>
)
