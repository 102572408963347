import React from "react"

import { Translate } from "src/components"
import { BodyContainer } from "./styles"

const render = (props) => {
  const {
    text,
    left,
    alignLeft,
    content: Content = () => null,
    translationVariables,
    ...rest
  } = props

  const alignment = alignLeft ? "-254px" : left

  return (
    <BodyContainer {...rest} left={alignment} alignLeft={alignLeft}>
      {text && (
        <Translate i18n={text} ns="components" {...translationVariables} />
      )}
      <Content />
    </BodyContainer>
  )
}

render.defaultProps = {
  top: "32px",
  left: "-12px",
  width: "300px",
  display: "none",
  padding: "20px",
  alignLeft: false,
}

export default render
