import {
  TAXATION,
  BUSINESS_ATTITUDE,
  CORPORATE_AGREEMENT,
} from "../application"

import { LENDING_PURPOSE_VALUE } from "../selectValues"

import { VIDEO_META } from "../misc"

export default {
  groups: [
    {
      label: "legal_entity_offer_form.rows.value",
      rows: [
        {
          label: "legal_entity_offer_form.rows.min-value.label",
          tooltip: "tooltips.min_value",
          inputs: [
            {
              type: "value",
              name: "minValue",
              min: 0,
            },
          ],
        },
        {
          label: "legal_entity_offer_form.rows.max-value.label",
          tooltip: "tooltips.max_value",
          inputs: [
            {
              type: "value",
              name: "maxValue",
              min: 0,
            },
          ],
        },
        {
          label: "application.worksheet.rows.lending_purpose.label",
          inputs: [
            {
              type: "select",
              name: "lendingPurpose",
              options: LENDING_PURPOSE_VALUE,
            },
          ],
        },
      ],
    },
    {
      title: "application.validation.rows.description.label",
      rows: [
        {
          inputs: [
            {
              label: "application.worksheet.rows.company_name.label",
              type: "text",
              name: "companyName",
              tooltip: "tooltips.company_name",
            },
            {
              label: "application.worksheet.rows.long_title.label",
              type: "text",
              name: "longTitle",
              tooltip: "tooltips.long_title",
            },
          ],
        },
        {
          inputs: [
            {
              label: "application.validation.rows.description.label",
              type: "wysiwyg",
              name: "description",
              tooltip: "tooltips.company_description",
            },
          ],
        },
        {
          label: "application.worksheet.rows.description_presentation.label",
          subLabel:
            "application.worksheet.rows.description_presentation.sub_label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "descriptionPresentations",
            },
          ],
        },
        {
          label: "application.worksheet.rows.description_attachments.label",
          subLabel:
            "application.worksheet.rows.description_attachments.sub_label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "descriptionAttachments",
              viewType: "sortableList",
            },
          ],
        },
        {
          label: "application.worksheet.rows.description_video.label",
          subLabel: "application.worksheet.rows.description_video.sub_label",
          inputs: [
            {
              type: "attachmentVideo",
              initialValue: { ...VIDEO_META },
              name: "descriptionVideo",
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.structure",
      rows: [
        {
          label: "application.worksheet.rows.functional_structure.label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "functionalStructure",
            },
          ],
        },
        {
          label: "application.worksheet.rows.business_attitude.label",
          inputs: [
            {
              type: "radioGroup",
              name: "businessAttitude",
              options: () => BUSINESS_ATTITUDE,
            },
          ],
        },
        {
          label: "application.worksheet.rows.taxation.label",
          inputs: [
            {
              type: "radioGroup",
              name: "taxation",
              options: () => TAXATION,
            },
          ],
        },
        {
          label: "application.worksheet.rows.personal_value.label",
          subLabel: "application.worksheet.rows.personal_value.sub_label",
          inputs: [
            {
              type: "number",
              name: "personaValue",
            },
          ],
        },
        {
          label: "application.worksheet.rows.payment_found.label",
          subLabel:
            "application.worksheet.rows.payment_found.sub_labels.period",
          inputs: [
            {
              type: "number",
              name: "period",
              label:
                "application.worksheet.rows.payment_found.sub_labels.period",
            },
            {
              type: "number",
              name: "amount",
              label: "application.worksheet.rows.payment_found.sub_labels.is",
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.placement_data",
      rows: [
        {
          width: "740px",
          inputs: [
            {
              type: "roomCharacteristics",
              name: "roomCharacteristics",
              initialValue: [
                {
                  address: {
                    flat: "",
                    area: "",
                    house: "",
                    region: "",
                    street: "",
                    housing: "",
                    locality: "",
                    structure: "",
                  },
                  tenure: "",
                  purpose: "",
                  area: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.license",
      rows: [
        {
          width: "740px",
          inputs: [
            {
              type: "license",
              name: "license",
              initialValue: [
                {
                  license: false,
                  license_name: "",
                  license_date: "",
                  license_number: "",
                  valid_date: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.financial_statements",
      rows: [
        {
          label: "application.worksheet.rows.balance_report.label",
          tooltip: "tooltips.balance_report",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "balanceReport",
            },
          ],
        },
        {
          label: "application.worksheet.rows.declaration.label",
          tooltip: "tooltips.declaration",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "declaration",
            },
          ],
        },
        {
          label: "application.worksheet.rows.income_book.label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "incomeBook",
            },
          ],
        },
        {
          label: "application.worksheet.rows.manage_report.label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "manageReport",
            },
          ],
        },
        {
          label: "application.worksheet.rows.financal_cards.label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "financalCards",
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.loan_repayment",
      rows: [
        {
          label: "application.worksheet.rows.repayment_source.label",
          inputs: [
            {
              type: "checkBox",
              name: "income",
              initialValue: false,
              label:
                "application.worksheet.switch_values.repayment_source.income.label",
            },
            {
              type: "checkBox",
              name: "salesRevenue",
              initialValue: false,
              label:
                "application.worksheet.switch_values.repayment_source.sales_revenue.label",
            },
            {
              type: "text",
              name: "salesRevenueDescription",
              hint:
                "application.worksheet.switch_values.repayment_source.sales_revenue.description",
              deps: [
                {
                  propName: "disabled",
                  compared: "salesRevenue",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "otherIncome",
              initialValue: false,
              label:
                "application.worksheet.switch_values.repayment_source.other_income.label",
            },
            {
              type: "text",
              name: "otherIncomeDescription",
              hint:
                "application.worksheet.switch_values.repayment_source.other_income.description",
              deps: [
                {
                  propName: "disabled",
                  compared: "otherIncome",
                  conditionValue: false,
                },
              ],
            },
          ],
        },
        {
          label: "application.worksheet.rows.contract.label",
          inputs: [
            {
              type: "radioGroup",
              name: "corporateAgreement",
              options: () => CORPORATE_AGREEMENT,
              initialValue: "empty",
            },
          ],
        },
        {
          deps: [
            {
              propName: "hidden",
              compared: "corporateAgreement",
              conditionValue: "empty",
            },
          ],
          emptyLabel: true,
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "corporateAgreementAttachments",
            },
          ],
        },
        {
          label: "application.worksheet.rows.protocol.label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "protocol",
            },
          ],
        },
        {
          label: "application.worksheet.rows.organs_decision.label",
          tooltip: "tooltips.organs_decision",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "organsDecision",
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.additional_info",
      subtitle: "application.worksheet.titles.additional_info_sub",
      rows: [
        {
          label: "application.worksheet.rows.contract_base.label",
          subLabel: "application.worksheet.rows.contract_base.sub_label",
          inputs: [
            {
              type: "fileDownload",
              blank: "true",
              extension: "doc",
              fileName: "Контрактная база - форма",
              link: "/docs/contract-basis.xlsx",
            },
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "contractBase",
            },
          ],
        },
        {
          label: "application.worksheet.rows.statement.label",
          subLabel: "application.worksheet.rows.statement.sub_label",
          inputs: [
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "statement",
            },
          ],
        },
        {
          label: "application.worksheet.rows.current_inform.label",
          inputs: [
            {
              type: "fileDownload",
              blank: "true",
              extension: "doc",
              fileName: "Справка-форма",
              link: "/docs/trust-basis.xlsx",
            },
            {
              type: "attachmentList",
              initialValue: { attachments: [] },
              name: "trustBase",
            },
          ],
        },
      ],
    },
    {
      title: "application.worksheet.titles.guarantees",
      rows: [
        {
          label: "application.worksheet.rows.guarantees.label",
          inputs: [
            {
              type: "checkBox",
              name: "noDebt",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.no_debt.label",
            },
            {
              type: "checkBox",
              name: "noDebtExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.no_debt_exceptions.label",
            },
            {
              type: "text",
              name: "noDebtExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "noDebtExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "violation",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.violation.label",
            },
            {
              type: "checkBox",
              name: "violationExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.violation_exceptions.label",
            },
            {
              type: "text",
              name: "violationExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "violationExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "full_powers",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.full_powers.label",
            },
            {
              type: "checkBox",
              name: "status",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.status.label",
            },
            {
              type: "checkBox",
              name: "effects",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.effects.label",
            },
            {
              type: "checkBox",
              name: "actions",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.actions.label",
            },
            {
              type: "checkBox",
              name: "actionsExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.actions_exceptions.label",
            },
            {
              type: "text",
              name: "actionsExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "actionsExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "reorganisation",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.reorganisation.label",
            },
            {
              type: "checkBox",
              name: "reorganisationExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.reorganisation_exceptions.label",
            },
            {
              type: "text",
              name: "reorganisationExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "reorganisationExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "contractBreach",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.contract_breach.label",
            },
            {
              type: "checkBox",
              name: "executiveProceedings",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.executive_proceedings.label",
            },
            {
              type: "checkBox",
              name: "executiveProceedingsExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.executive_proceedings_exceptions.label",
            },
            {
              type: "text",
              name: "executiveProceedingsExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "executiveProceedingsExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "text",
              name: "executiveProceedingsExceptionsValue",
              hint:
                "application.worksheet.switch_values.guarantees.executive_proceedings_exceptions.hint",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "executiveProceedingsExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "checkBox",
              name: "noWarnings",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.no_warnings.label",
            },
            {
              type: "checkBox",
              name: "noWarningsExceptions",
              initialValue: false,
              nestedLevel: 1,
              label:
                "application.worksheet.switch_values.guarantees.no_warnings_exceptions.label",
            },
            {
              type: "text",
              name: "noWarningsExceptionsDescription",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "noWarningsExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "text",
              name: "noWarningsExceptionsValue",
              hint:
                "application.worksheet.switch_values.guarantees.no_warnings_exceptions.hint",
              nestedLevel: 2,
              deps: [
                {
                  propName: "disabled",
                  compared: "noWarningsExceptions",
                  conditionValue: false,
                },
              ],
            },
            {
              type: "devider",
            },
            {
              type: "checkBox",
              name: "unchanged",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.unchanged.label",
            },
            {
              type: "checkBox",
              name: "financeSequencing",
              initialValue: false,
              label:
                "application.worksheet.switch_values.guarantees.finance_sequencing.label",
            },
          ],
        },
      ],
    },
  ],
}
