import Exit from "./exit"
import Info from "./info"
import Menu from "./menu"
import Time from "./time"
import Check from "./check"
import Close from "./close"
import Account from "./account"
import Document from "./document"
import Settings from "./settings"
import Dashboard from "./dashboard"
import ArrowDown from "./arrow-down"
import ExpandLess from "./expand-less"
import ExpandMore from "./expand-more"
import CheckCircle from "./check-circle"

export default {
  exit: Exit,
  info: Info,
  menu: Menu,
  time: Time,
  check: Check,
  close: Close,
  account: Account,
  document: Document,
  settings: Settings,
  dashboard: Dashboard,
  "arrow-down": ArrowDown,
  "expand less": ExpandLess,
  "expand-less": ExpandLess,
  "expand more": ExpandMore,
  "expand-more": ExpandMore,
  "check-circle": CheckCircle,
  "check circle": CheckCircle,
}
