export function formatStrings(inputs) {
  return Object.entries(inputs).reduce((acc, [key, value]) => {
    acc[key] = typeof value === "string" && !key.includes("mail") ? value.replace(/_/g, "") : value
    return acc
  }, {})
}

export function formatScans(passport: any = {}) {
  const { scans = [] } = passport
  const formatted = scans.map((scan) => (typeof scan === "string" ? scan : scan.id))

  return { scans: formatted }
}

export function getInitialValues(user: any = {}, profile: any = {}) {
  const email = user?.email || ""
  const {
    business = {},
    address,
    actualAddress,
    executive = {},
    passport = {},
  } = profile
  const scans = passport?.scans

  const addressIsMatch = address && actualAddress && address.raw && address.raw === actualAddress.raw

  const initialAddress = {
    country: "",
    region: "",
    area: "",
    locality: "",
    street: "",
    house: "",
    housing: "",
    structure: "",
    flat: "",
  }

  return {
    name: profile.name || "",
    phone: profile.phone || "",
    email: email || "",
    inn: profile.inn || "",
    kpp: profile.kpp || "",
    ogrn: profile.ogrn || "",
    publicEmail: profile?.borrower?.publicEmail || "",
    legalForm: business?.legalForm || "",
    startedAt: business?.startedAt || "",
    mainAddress: address || initialAddress,
    otherAddress: actualAddress || initialAddress,
    main: business?.main || "",
    aux: business?.aux || "",
    ceoName: executive?.name || "",
    iian: profile.iian || "",
    addressMatch: !!addressIsMatch,
    passport: { scans: scans || [] },
  }
}
