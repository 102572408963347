import React, { useEffect, createRef, useState } from "react"
import ReactDOM from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { FloatingLabelInput } from "src/components"
import { MutationContainer } from "src/containers"
import {
  UploadFile,
  UpdateSupportingDocuments,
  RequestProfilePhoneConfirmation,
} from "src/mutations"
import { useEnvironment } from "src/hooks"
import { fileManager } from "src/utils"

import { useCountDown } from "src/pages/common/join/investor/PhoneConfirmation/utils"

import {
  Modal,
  Container,
  ButtonContainer,
  Button,
  PrimaryButton,
  CloseButton,
  CloseIcon,
  InputContainer,
  Timer,
  ImageContainer,
  Image,
  Description,
  SuccessfulContainer,
  SuccessfulText,
  SuccessIcon,
} from "./styles"
import { useTokenInput } from "./utils"

function SupportingDocumentsConfirmationModal(props) {
  const {
    onClose, profileId, clearError, getError,
  } = props

  const [isLoading, setLoading] = useState(false)
  const [isSuccessful, setSuccessful] = useState(false)

  const { environment } = useEnvironment()
  const [secondsLeft, startCounter] = useCountDown(120 * 1000)
  const token = useTokenInput(clearError)

  const overlayRef = createRef<HTMLDivElement>()
  const containerRef = createRef<HTMLDivElement>()

  function handleClose() {
    if (!isLoading) {
      enableBodyScroll(containerRef.current)
      onClose(isSuccessful)
    }
  }

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      handleClose()
    }
  }

  function requestPhoneConfirmation() {
    if (secondsLeft) {
      return
    }

    (startCounter as any)()

    fileManager
      .uploadList(environment, UploadFile, props.files)
      .then((items: any) => {
        const variables = {
          input: {
            kind: "UPDATE_SUPPORTING_DOCUMENTS",
            attachments: items.map((item) => item.id),
            profileId,
          },
        }
        RequestProfilePhoneConfirmation.commit(environment, variables, null, {})
      })
  }

  function onError() {
    setLoading(false)
  }

  function onCompleted(data) {
    const { updateSupportingDocuments } = data

    if (updateSupportingDocuments && updateSupportingDocuments.status) {
      setSuccessful(true)
    }

    setLoading(false)
  }

  function uploadSupportingDocuments(uploadedFiles) {
    const { commit } = props

    const supportingDocuments = uploadedFiles.map((item) => ({
      attachment: item.id,
    }))

    if (supportingDocuments.length < 1 || !profileId) {
      return
    }

    const variables = {
      input: {
        token: token.value,
        profileId,
        supportingDocuments,
      },
    }

    const callbacks = {
      onError,
      onCompleted,
    }

    commit({ environment, variables, callbacks })
  }

  function handleSubmit() {
    if (!token.value || isLoading) {
      return
    }

    const { files } = props

    setLoading(true)

    fileManager
      .uploadList(environment, UploadFile, files)
      .then((uploadedFiles) => uploadSupportingDocuments(uploadedFiles))
  }

  useEffect(() => {
    if (profileId) {
      requestPhoneConfirmation()
    }
  }, [])

  useEffect(() => {
    disableBodyScroll(containerRef.current)

    return () => enableBodyScroll(containerRef.current)
  }, [])

  return (
    <Modal ref={overlayRef} onClick={onOverlayClick}>
      <Container compact={isSuccessful} ref={containerRef}>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>

        {!isSuccessful && (
          <>
            <ImageContainer>
              <Image />
            </ImageContainer>
            <Description>
              Чтобы подтвердить достоверность и корректность предоставленных
              данных введите код из смс
            </Description>

            <InputContainer>
              <FloatingLabelInput
                withoutBorder
                required
                autoFocus
                type="text"
                name="token"
                errorColor="mainRed"
                onChange={token.onChange}
                value={token.value}
                disabled={isLoading}
                label="components:join.investor.phone_confirmation.placeholder"
                error={getError("updateSupportingDocuments.token")}
                hasError={getError("updateSupportingDocuments.token")}
              />
            </InputContainer>

            {secondsLeft > 0 && (
              <Timer>Выслать пароль повторно через {secondsLeft} секунд</Timer>
            )}

            <ButtonContainer>
              <Button
                onClick={requestPhoneConfirmation}
                disabled={isLoading || secondsLeft}
              >
                ВЫСЛАТЬ ПОВТОРНО
              </Button>

              <PrimaryButton
                onClick={handleSubmit}
                disabled={!token.value || isLoading}
              >
                ПРОДОЛЖИТЬ
              </PrimaryButton>
            </ButtonContainer>
          </>
        )}

        {isSuccessful && (
          <SuccessfulContainer>
            <SuccessIcon />
            <SuccessfulText>Готово!</SuccessfulText>
          </SuccessfulContainer>
        )}
      </Container>
    </Modal>
  )
}

function Render(props) {
  return (
    <MutationContainer mutation={UpdateSupportingDocuments}>
      {({ commit, getError, clearError }) => (
        <SupportingDocumentsConfirmationModal
          {...props}
          commit={commit}
          getError={getError}
          clearError={clearError}
        />
      )}
    </MutationContainer>
  )
}

export default function (props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")

  return isOpened ? ReactDOM.createPortal(Render(props), root) : null
}
