import styled from "styled-components"

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const InfoCompanyName = styled.div`
  font-size: 22px;
  line-height: 1.4;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
    max-width: 80%;
  }
`

export const InfoPlay = styled.div`
  margin: 0;
  box-sizing: border-box;
  min-width: 30px;
  cursor: pointer;
`
