import React from "react"

import {
  Box,
  FlexBox,
  TextField,
  RadioButton,
} from "src/components"

import { PAYMENT_DATE } from "src/constants"

export class PaymentDate extends React.PureComponent<any, any> {
  onChange = (event) => {
    const dateValue = { ...this.props.value }
    const { name, value } = event.target

    dateValue[name] = value

    this.props.onChange({
      name: this.props.name,
      value: dateValue,
    })
  }

  render() {
    return (
      <Box>
        {PAYMENT_DATE.map(({ label, value }, index) => (
          <FlexBox
            key={index}
            display="flex"
            flexDirection="row"
            justifyContnet="flex-start"
            alignItems="center"
            py="6px"
          >
            <RadioButton
              label={label}
              value={value}
              onChange={this.onChange}
              name="type"
              checked={this.props.value.type === value}
            />
            {index === 0 ? (
              <Box width={80} pl={20}>
                <TextField
                  name="date"
                  value={this.props.value.date}
                  onChange={this.onChange}
                  disabled={this.props.value.type !== "certain_day"}
                />
              </Box>
            ) : null}
          </FlexBox>
        ))}
      </Box>
    )
  }
}

export default PaymentDate
