import React from "react"
import { Route } from "react-router-dom"

import {
  Box, Text, Modal, Button, FlexBox, Translate,
} from "src/components"

import {
  Icon, ModalWrapper, ModalContainer, DisableScroll,
} from "./styles"

const CommitSuccessModal = (props) => (
  <Modal>
    <ModalWrapper>
      <ModalContainer>
        <Box pb={128}>
          <Text fontSize={55} textAlign="center">
            <Translate i18n="application_success.header" ns="modals" />
          </Text>
        </Box>
        <Icon />
        <Box pt={60}>
          <Text fontSize={30} textAlign="center">
            <Translate i18n="application_success.title" ns="modals" />
          </Text>
        </Box>
        <Box pt={30}>
          <Text fontSize={20} textAlign="center">
            <Translate i18n="application_success.sub_title" ns="modals" />
          </Text>
        </Box>
        <FlexBox display="flex" justifyContent="center" pt={40}>
          <Button
            margin="0 auto"
            onClick={props.onContinue(props.continueLink)}
          >
            <Translate i18n="application_success.button" ns="modals" />
          </Button>
        </FlexBox>
      </ModalContainer>
    </ModalWrapper>

    <DisableScroll />
  </Modal>
)

const handleContinue = (history) => (url) => () => {
  history.push(url)
}

export default (props) => (
  <Route>
    {({ history }) => (
      <CommitSuccessModal onContinue={handleContinue(history)} {...props} />
    )}
  </Route>
)
