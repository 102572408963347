import React from "react"

import { DefaultModal, AccreditationStatus } from "src/components"

import ModalBody from "./ModalBody"

class StatusContainer extends React.Component<any, any> {
  state = {
    showModal: false,
  }

  onClick = () => {
    this.setState(() => ({ showModal: true }))
  }

  onModalClose = () => {
    this.setState(() => ({ showModal: false }))
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showModal && (
          <DefaultModal onClose={this.onModalClose}>
            <ModalBody
              action={this.props.action}
              accreditation={this.props.moderation}
            />
          </DefaultModal>
        )}
        <AccreditationStatus.contextProvider value={this.props}>
          <AccreditationStatus onClick={this.onClick} />
        </AccreditationStatus.contextProvider>
      </React.Fragment>
    )
  }
}

(StatusContainer as any).defaultProps = {
  showDetails: true,
  element: "role_item",
  statusFontSize: "14px",
  showStatusMessage: true,
  declineMessage: "account.accreditation_status.DECLINED",
}

export default StatusContainer
