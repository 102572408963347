import styled from "styled-components"
import { width, color, fontWeight } from "styled-system"

export const TableWrapper = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
`

export const TH = styled.th<any>`
  border: 2px solid black;
  text-align: center;
  width: 160px;
  font-weight: 400;
  font-size: 16px;
  ${width};
  ${color};
  ${fontWeight};

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`

export const TD = styled.td<any>`
  text-align: center;
  border: 2px solid black;
  font-weight: 400;
  font-size: 16px;
  ${width};
  ${color};
  ${fontWeight};

  @media screen and (max-width: 800px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`
