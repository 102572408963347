import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation ConfirmPhoneMutation($input: ConfirmPhoneInput!) {
    confirmPhone(input: $input) {
      signedPhone
    }
  }
`

export default enhance({ mutation })
