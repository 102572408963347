import styled from "styled-components"
import { width } from "styled-system"

import { Input } from "src/components"

export const IconContainer = styled.div`
  top: 50%;
  width: 60px;
  right: 10px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  position: absolute;
  transform: translateY(-50%);
  justify-content: flex-end;
`
export const CloseIconWrapper = styled.div`
  transform: translateY(-5%);
  width: 20px;
  height: 20px;

  svg {
    width: 8px;
    height: 8px;
  }
`

export const OptionsContainer = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  z-index: 20;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 5px 5px;
  width: 100%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.greyDark};
  max-height: 300px;
  overflow-y: scroll;
`

export const Option = styled.div`
  padding: 0 10px;
  width: 100%;
  min-height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey};
  }
`

export const SelectWrapper = styled.div<any>`
  display: inline-block;
  position: relative;
  ${width}
`

export const DecoratedInput = styled(Input)`
  border-radius: ${({ isOpen }) => (isOpen ? "5px 5px 0 0" : "5px")};
  color: transparent;
  user-select: none;
`

export const InputWrapper = styled.div`
  position: relative;
`

export const SelectedValue = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`
