import React from "react"
import { graphql, createPaginationContainer } from "react-relay"

import { QueryRenderer } from "src/components"
import { getProposalLink } from "src/utils"

import { RenderedTable as Table } from "../../PortfolioTable/Tables/LoanSucceededTable"
import { VISIBLE_ORDER } from "../../../../query/order/portfolio/constants"

const query = graphql`
  query SucceededTableQuery(
    $count: Int!
    $cursor: Cursor
    $userId: ID!
    $filter: OrdersFilter
  ) {
    ...SucceededTable_root
    @arguments(count: $count, cursor: $cursor, userId: $userId, filter: $filter)
  }
`

const PaginatedTable = createPaginationContainer(
  (props: any) => {
    const { toProposal, root, relay } = props
    const { node } = root

    const { hasNextPage } = node.orders.pageInfo
    const { hasMore, isLoading, loadMore } = relay

    function handleLoad() {
      if (hasMore() && !isLoading()) {
        loadMore(VISIBLE_ORDER)
      }
    }

    return (
      <Table
        toProposal={toProposal}
        data={{ borrower: node }}
        getProposalLink={getProposalLink}
        load={{ handleLoad, hasNextPage }}
      />
    )
  },
  {
    root: graphql`
      fragment SucceededTable_root on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "Cursor" }
        userId: { type: "ID!" }
        filter: { type: "OrdersFilter" }
      ) {
        node(id: $userId) {
          ... on User {
            orders(first: $count, after: $cursor, filter: $filter)
            @connection(key: "SucceededTable_orders") {
              edges {
                node {
                  id
                  status
                  confirmedAt
                  succeededAt
                  paymentSchedule {
                    status
                  }
                  offer {
                    data
                    receivedAt
                    approvedAt
                  }
                  application {
                    data
                    shortTitle
                    longTitle
                  }
                  chain {
                    id
                    gatheredAmount
                  }
                  profile {
                    ... on UserProfile {
                      id
                      ... on LegalEntityProfile {
                        name
                        borrower {
                          ticker
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps: (props) => props.root && props.root.node.orders,
    getFragmentVariables: (prevVars) => ({
      ...prevVars,
      count: VISIBLE_ORDER,
    }),
    getVariables: (props, { cursor }, { userId, filter }) => ({
      count: VISIBLE_ORDER,
      cursor,
      userId,
      filter,
    }),
    query,
  },
)

function PaginatedContainer(props) {
  const { userId, profileId, toProposal } = props

  return (
    <QueryRenderer
      {...props}
      query={query}
      render={(queryProps) => (
        <PaginatedTable root={queryProps} toProposal={toProposal} />
      )}
      renderNull={() => <Table isFetching={true} load={{}} />}
      variables={{
        count: VISIBLE_ORDER,
        userId,
        filter: { status: ["SUCCEEDED"], borrowers: [profileId] },
      }}
    />
  )
}

export default PaginatedContainer
