import React from "react"
import styled from "styled-components"
import {
  width,
  flexWrap,
  alignItems,
  flexDirection,
  justifyContent,
} from "styled-system"
import Box from "../Box"

const Flex = styled(Box)`
  display: flex;
  ${width}
  ${alignItems}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
`

const render = ({ is, ...props }: any) => {
  const Component = is ? Flex.withComponent(is) : Flex
  return <Component {...props} />
}

export default render
