import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestProfilePhoneConfirmationMutation(
    $input: RequestProfilePhoneConfirmationInput!
  ) {
    requestProfilePhoneConfirmation(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
