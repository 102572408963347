import React from "react"
import memoize from "memoize-one"

import { getProperty } from "src/utils"

import {
  Box, Flex, Text, Tooltip, Translate, RatingGlyph,
} from "src/components"

import Icon from "./Icon"

import {
  Container,
  StyledLink,
  TooltipContainer,
} from "../RatingTooltip/styles"

const provisions = ["ESTATE", "VEHICLE", "GUARANTOR", "OTHER"]

const offerProvisionMap = {
  flat: "ESTATE",
  transport: "VEHICLE",
  individual_beneficiary: "GUARANTOR",
}

const formatOfferProvision = memoize((provision) => {
  if (!provision) return []

  return provision.map((item) => ({ kind: offerProvisionMap[item.type] }))
})

const collectKinds = memoize((items) => {
  const kinds = items.map((item) => item.kind)

  return new Set(kinds)
})

const Provision = ({ order }) => {
  const { provision, offer } = order
  const orderProvision = getProperty(provision, "items", [])
  const offerProvision = getProperty(offer, "data.provision", [])
  const items = [...orderProvision, ...formatOfferProvision(offerProvision)]
  const kinds = collectKinds(items)

  return (
    <Flex mt="13px">
      {provisions.map(
        (item) => kinds.has(item) && (
            <Box key={item} mr="10px">
              <Icon>{item}</Icon>
            </Box>
        ),
      )}
    </Flex>
  )
}

const TooltipBody = ({ link }) => (
  <>
    <Translate i18n="components:tooltips.provision.body" />
    &nbsp;
    <StyledLink to={link} target="_blank">
      <Translate i18n="components:tooltips.provision.link" />
    </StyledLink>
  </>
)

const sourceMap = new Map([
  ["Loan", "loans"],
  ["Order", "market"],
  ["Investment", "investments"],
])

const isGuestOrder = (orderType) => ["GuestOrder", "CompletedGuestOrder"].includes(orderType)

const ProvisionLabel = (props) => {
  const { orderId, provision, orderType } = props

  const hasOrderProvision = !!getProperty(provision, "items.length")
  const link = `/market/${orderId}?source=${sourceMap.get(
    orderType,
  )}&step=provision`

  return (
    <Container>
      <Text fontSize="13px" color="greyDark">
        <Translate i18n="models:loan.provisionType" />
      </Text>
      {hasOrderProvision && !isGuestOrder(orderType) && (
        <TooltipContainer>
          <Tooltip
            borderColor="grey"
            borderWidth="2px"
            glyph={() => <RatingGlyph transform="none" />}
            content={() => <TooltipBody link={link} />}
          />
        </TooltipContainer>
      )}
    </Container>
  )
}

Provision.label = ProvisionLabel

export default Provision
