import styled from "styled-components"
import { width } from "styled-system"
import { NavLink } from "react-router-dom"

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`

export const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.fullBlacks[1]};
`

export const THead = styled.th<any>`
  padding-bottom: 14px;
  font-family: "Geometria", sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  ${width};
`

export const TData = styled.td`
  font-family: "Geometria", sans-serif;
  padding: 28px 0;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Link = styled(NavLink)`
  font-family: "Geometria", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`
