import React from "react"
import ReactTooltip from "rc-tooltip"

import { CustomTooltipGlobalStyle } from "./styles"

function CustomTooltip(props) {
  const { children, overlay, placement } = props

  return (
    <>
      <ReactTooltip placement={placement || "top"} overlay={overlay}>
        {children}
      </ReactTooltip>
      <CustomTooltipGlobalStyle />
    </>
  )
}

export default CustomTooltip
