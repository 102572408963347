import React from "react"

import { getProfilePayments, getPaymentSummaryByStatus } from "src/utils"
import { Box, Flex, PaymentTable } from "src/components"

import InfoMessage from "./InfoMessage"
import OverdueInfo from "./OverdueInfo"

const PaymentSchedule = ({ order, viewer, isOrderOwner }) => {
  const type = isOrderOwner ? "debtor" : "investor"

  const payments = React.useMemo(() => {
    const profiles = [...viewer.profiles, ...viewer.foreignProfiles]
    return getProfilePayments(profiles, order.paymentScheduleList, type)
  }, [order, viewer, type])

  const pastdueSummary = React.useMemo(
    () => getPaymentSummaryByStatus(payments.items, "pastdue"),
    [payments],
  )

  if (!type || !payments.items) return null

  return (
    <Flex>
      <Box width="100%">
        {type === "debtor" && (
          <PaymentTable type={type} currency="RUB" items={payments.items} />
        )}
        {type === "investor" && (
          <PaymentTable type={type} items={payments.items} />
        )}
      </Box>
      <Box ml="20px">
        {!!+pastdueSummary.total && type === "debtor" && (
          <OverdueInfo {...pastdueSummary} />
        )}
        {!+pastdueSummary.total && type === "debtor" && <InfoMessage />}
      </Box>
    </Flex>
  )
}

export default React.memo(PaymentSchedule)
