import React, { useContext } from "react"

import { ProfileType } from "src/constants"
import { CurrentProfileContext } from "src/context"

import { Container, Title } from "./styles"

import IndividualPaginationContainer from "./IndividualPaginationContainer"
import LegalEntityPaginationContainer from "./LegalEntityPaginationContainer"
import EntrepreneurPaginationContainer from "./EntrepreneurPaginationContainer"
import ForeignIndividualPaginationContainer from "./ForeignIndividualPaginationContainer"

const ContainersMap = new Map([
  [ProfileType.juristic, LegalEntityPaginationContainer],
  [ProfileType.individual, IndividualPaginationContainer],
  [ProfileType.entrepreneur, EntrepreneurPaginationContainer],
  [ProfileType.foreignIndividual, ForeignIndividualPaginationContainer],
])

function TransactionHistory() {
  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { __typename: profileType, id } = profile

  const PaginationContainer = ContainersMap.get(profileType)

  if (!PaginationContainer) return null

  return (
    <Container>
      <Title>История операций</Title>
      <PaginationContainer profileId={id} />
    </Container>
  )
}

export default TransactionHistory
