/* eslint-disable no-lonely-if */
import React from "react"

import {
  getOrderTicker,
  number,
  getProfileName,
  getOrderFullTitle,
} from "src/utils"
import { TransactionStatuses, TransactionTypes } from "src/constants"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const useTransactionAmountProps = (transaction, profile) => React.useMemo(() => {
  const {
    state, type, amount, toAccount = {},
  } = transaction
  const props: any = {
    amount: number(amount, options),
    sign: "+",
  }

  if (TransactionStatuses.groups.error.includes(state)) {
    props.tooltip = "error"
    props.tooltipColor = "#f70000"
  }

  if (TransactionStatuses.groups.pending.includes(state)) {
    props.tooltip = "pending"
    props.tooltipColor = "rgb(255, 143, 0)"
    props.color = "rgba(74, 74, 74, 0.75)"
  }

  if (TransactionTypes.groups.in.includes(type)) {
    props.color = "#6FA84B"
  }

  if (TransactionTypes.groups.out.includes(type)) {
    props.sign = "-"
  }

  if (TransactionTypes.groups.variable[type]) {
    const incoming = TransactionTypes.groups.variable[type].in

    // eslint-disable-next-line
      props.sign = incoming.includes(profile.__typename) ? "+" : "-"
  }

  if (type === "PURCHASE_LOAN" && profile.id !== toAccount.profile?.id) {
    props.sign = "-"
  }

  return props
}, [transaction])

export const useTransactionDestinations = (transaction, profile) => React.useMemo(() => {
  const {
    type, toAccount = {}, fromAccount = {}, loan = {},
  } = transaction

  const result: any = {
    from: {
      title: {
        label: `components:account.transactions_history.operations.${type}`,
      },
      subtitle: {},
    },
    to: {
      title: {},
      subtitle: {},
    },
  }

  if (type === "PURCHASE_LOAN" && profile.id === toAccount.profile?.id) {
    result.from.title.label = "Продажа долга"
  }

  if (fromAccount?.order) {
    result.from.subtitle.label = `(${getOrderTicker(
      fromAccount.order,
    )}) ${getOrderFullTitle(fromAccount.order.application)}`
    result.from.subtitle.url = `/market/${fromAccount.order.id}?source=accounts`
  } else {
    result.from.subtitle.label = fromAccount.description || "-"
  }

  if (type === "PURCHASE_LOAN" && loan?.order) {
    result.to.subtitle.label = `(${getOrderTicker(
      loan.order,
    )}) ${getOrderFullTitle(loan.order.application)}`
    result.to.subtitle.url = `/market/${loan.order.id}?source=accounts`
    result.to.title.label = toAccount.description || "-"
  } else if (toAccount?.order) {
    if (TransactionTypes.groups.out.includes(type)) {
      result.to.title.label = `(${getOrderTicker(
        toAccount.order,
      )}) ${getOrderFullTitle(toAccount.order.application)}`
      result.to.title.url = `/market/${toAccount.order.id}?source=accounts`
      result.to.subtitle.label = toAccount.order.profile.name
    } else {
      result.to.subtitle.label = `(${getOrderTicker(
        toAccount.order,
      )}) ${getOrderFullTitle(toAccount.order.application)}`
      result.to.subtitle.url = `/market/${toAccount.order.id}?source=accounts`
      result.to.title.label = toAccount.description || "-"
    }
  } else {
    if (TransactionTypes.groups.in.includes(type)) {
      result.to.title.label = getProfileName(profile)
      result.to.subtitle.label = toAccount.description || "-"
    } else {
      const profileName = getProfileName(toAccount.profile || {})
      const accountNameOrKarma = toAccount.profile && profileName.replace(" ", "")
        ? profileName
        : "components:account.transactions_history.karma"

      result.to.subtitle.label = toAccount.description || "-"
      result.to.title.label = type === TransactionTypes.WITHDRAWAL
        ? "components:account.transactions_history.bank_account"
        : accountNameOrKarma
    }
  }

  return result
}, [transaction])
