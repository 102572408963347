/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Conclusion_order = {
    readonly id: string;
    readonly expertOpinions: ReadonlyArray<{
        readonly id: string;
        readonly opinion: string;
        readonly expert: {
            readonly id: string;
            readonly name: string;
            readonly about: string;
            readonly experience: string;
            readonly avatar: {
                readonly id: string;
                readonly url: string;
            };
        };
    }>;
    readonly karma: {
        readonly conclusion: string | null;
    };
    readonly " $refType": "Conclusion_order";
};
export type Conclusion_order$data = Conclusion_order;
export type Conclusion_order$key = {
    readonly " $data"?: Conclusion_order$data;
    readonly " $fragmentRefs": FragmentRefs<"Conclusion_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Conclusion_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderExpertOpinion",
      "kind": "LinkedField",
      "name": "expertOpinions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "opinion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ExternalExpert",
          "kind": "LinkedField",
          "name": "expert",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "about",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "experience",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderKarma",
      "kind": "LinkedField",
      "name": "karma",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "conclusion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order"
};
})();
(node as any).hash = 'e5f954baba38253fa10f503856004c92';
export default node;
