import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Link = styled.a`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  max-width: 210px;
  margin: 0;
  color: ${({ theme }) => theme.colors.oceanBlue};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

export const Empty = styled.p`
  color: ${({ theme }) => theme.colors.mainRed};
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  max-width: 210px;
  margin: 0;
`
