import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 14px 0;
  width: 455px;
  @media screen and (max-width: 480px) {
    margin: 30px 0 0 0;
    width: 440px;
  }
`

export const ImageContainer = styled.a`
  position: relative;
  box-sizing: content-box;
  margin-bottom: 16px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 16px;
  }
  &:nth-of-type(3) {
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:nth-of-type(3) {
      margin-right: 0px;
    }
    margin-bottom: 8px;
  }
`

export const Image = styled.img`
  object-fit: cover;
  width: 140px;
  height: 100px;
`
