import React from "react"

/* TODO: unused - убрать или оставить позднее
import Relay, { graphql } from "react-relay"
import { QueryRenderer } from "src/components"
import { BannerProfileWrap, ActionWrap } from "./styles"
import { Arrow } from "./icons"

const id = localStorage.getItem("activeProfile")
const query = graphql`
  query BannerProfileQuery($profileId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        name
        isApproved
        approvedAsBorrower
        approvedAsInvestor
      }
    }
  }
`

const render = ({ data, ...props }) => {
  const pageWidth = window.document.documentElement.clientWidth
  const bannerText = pageWidth > 800
    ? "Заполни профиль и начни инвестировать!"
    : "Остался один шаг! Заполни профиль, капризный инвестор!"

  const { isApproved, approvedAsBorrower, approvedAsInvestor } = data
  return (
    <>
      {isApproved || approvedAsBorrower || approvedAsInvestor ? null : (
        <BannerProfileWrap href={`/accounts/${id || ""}`}>
          <div>{bannerText}</div>
          <ActionWrap>
            <div>Уже иду</div>
            <Arrow />
          </ActionWrap>
        </BannerProfileWrap>
      )}
    </>
  )
} */

/* const Container = props => (
  <QueryRenderer
    {...props}
    query={query}
    render={render}
    variables={{
      profileId: id,
    }}

  />
) */

// Временное решение
const Container = () => <></>

export default Container
