import React from "react"
import { Route } from "react-router"

import { toast, ToastContainer } from "react-toastify"

import {
  RequestProfileAccreditation,
  UpdateLegalEntityProfileBorrowerData,
} from "src/mutations"

import { ErrorsContainer, ProfileConfirmContainer } from "src/containers"

import { RelayEnvironmentConsumer } from "src/context"

import {
  Box,
  Text,
  FlexBox,
  Address,
  Template,
  Translate,
  RowsContainer,
  FormLoadingButton,
  PersonalInformationConsentCheck,
  FloatingLabelInput,
  TabletInput,
} from "src/components"
import { SuccessSaveMessage } from "src/constants"

import FormHeader from "./elements"
import {
  BusinessField,
  ExecutiveField,
  PassportDataField,
} from "./Rows"
import { getSaveVariablesFromState, borrowerStateFromProps } from "./utils"
import { Divider } from "./styles"

const FormWrapper = (props) => <>{props.children}</>

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      busy: false,
      isLoading: false,
      confirmed: false,
      isSaveLoading: false,
      rulesApprove: false,
      ...borrowerStateFromProps(this.props),
    }
  }

  onChange = (event) => {
    const { name, value, type } = event.target

    if (type === "checkbox") {
      return this.setState((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }))
    }

    return this.setState({ [name]: value })
  }

  onSelectChange = ({ name, value }) => {
    this.setState(() => ({ [name]: value }))
  }

  onDraftRequest = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (this.state.busy) {
      return
    }

    this.setState(() => ({ busy: true }))
    this.saveDraft()()
  }

  onError = (transaction) => {
    this.setState(() => ({
      busy: false,
      isLoading: false,
      isSaveLoading: false,
    }))

    toast.error("К сожалению возникла ошибка", {
      position: toast.POSITION.TOP_RIGHT,
    })

    this.props.setErrors(transaction)
  }

  saveDraft = (onCompleted?: any) => () => {
    const variables = {
      input: getSaveVariablesFromState(this.state),
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: onCompleted || this.onSaveDraftCompleted,
    }

    UpdateLegalEntityProfileBorrowerData.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSaveDraftCompleted = (payload) => {
    this.setState(() => ({ busy: false }))

    toast.success(SuccessSaveMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  requestProfileAccreditation = () => {
    const variables = {
      input: {
        token: "",
        role: "BORROWER",
        profileId: this.state.profileId,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: () => {
        this.setState(() => ({ confirmed: true }))
        this.props.history.push(`/accounts/${this.state.profileId}`)
      },
    }

    RequestProfileAccreditation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  request = () => {
    const variables = {
      input: {
        id: this.state.profileId,
        role: "BORROWER",
      },
    }

    const callbacks = {
      onError: this.onError,
    }

    RequestProfileAccreditation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  renderFormContent = () => (
    <React.Fragment>
      {/* TODO: Romove all commented legacy code */}
      <Box maxWidth={["100%", "550px"]}>
        <FloatingLabelInput
          name="inn"
          value={this.state.inn}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.individual.rows.inn.label"}
          mask="999999999999"
        />
        {/* <InnField
      name="inn"
      value={this.state.inn}
      onChange={this.onSelectChange}
    /> */}

        <FloatingLabelInput
          name="ogrn"
          value={this.state.ogrn}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.borrower.rows.ogrn.label"}
          mask="9999999999999"
        />
        {/* <OgrnField
      name="ogrn"
      value={this.state.ogrn}
      onChange={this.onSelectChange}
    /> */}

        <FloatingLabelInput
          name="kpp"
          value={this.state.kpp}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.individual.rows.kpp.label"}
          mask="9999999999"
        />
        {/* <KppField
      name="kpp"
      value={this.state.kpp}
      onChange={this.onSelectChange}
    /> */}

        <FloatingLabelInput
          name="name"
          value={this.state.name}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.borrower.rows.name.label"}
        />
        {/* <NameField
      name="name"
      value={this.state.name}
      onChange={this.onSelectChange}
    /> */}

        <FloatingLabelInput
          name="publicEmail"
          type="email"
          value={this.state.publicEmail}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.borrower.rows.publicEmail.label"}
        />

        <BusinessField
          name="business"
          value={this.state.business}
          onChange={this.onSelectChange}
        />

        {/* ====================================== */}

        <ExecutiveField
          name="executive"
          value={this.state.executive}
          onChange={this.onSelectChange}
        />

        <FloatingLabelInput
          name="iian"
          value={this.state.iian}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={"components:accreditation.individual.rows.iian.label"}
          mask="999 - 999 - 999 - 99"
        />
        {/* <FormRow>
      <Box width={260}>
        <Label whiteSpace="pre-line">
          <Translate
            i18n="accreditation.individual.rows.iian.label"
            ns="components"
          />
        </Label>
      </Box>
      <Box width="100%">
        <TextField
          name="iian"
          value={this.state.iian}
          onChange={this.onChange}
        />
      </Box>
    </FormRow> */}

        <PassportDataField
          name="passport"
          value={this.state.passport}
          onChange={this.onSelectChange}
          environment={this.props.environment}
        />

        <Divider />

        <Box mt="30px">
          <Address.autoSuggest
            name="address"
            allowUnreliable
            value={this.state.address}
            onChange={this.onSelectChange}
            error={""}
            label={
              "components:accreditation.borrower.rows.destination_place.label"
            }
          />
        </Box>

        <Box>
          <Text
            mb="3px"
            fontSize="1.125rem"
            fontWeight="600"
            color="primaryBlack"
          >
            <Translate
              i18n="accreditation.borrower.rows.destination_match.label"
              ns="components"
            />
          </Text>
          <TabletInput
            name="addressMatch"
            value={this.state.addressMatch}
            onChange={this.onChange}
          />
        </Box>
        {/* <RightSideSwitch
      name="addressMatch"
      value={this.state.addressMatch}
      checked={this.state.addressMatch}
      label="components:accreditation.borrower.rows.destination_match.label"
      rightLabel="components:application.worksheet.rows.license.yes"
      leftLabel="components:application.worksheet.rows.license.no"
      onChange={this.onSelectChange}
    /> */}

        {!this.state.addressMatch && (
          <Box>
            <Address.autoSuggest
              name="actualAddress"
              allowUnreliable
              value={this.state.actualAddress}
              onChange={this.onSelectChange}
              error={""}
              label={
                "components:accreditation.borrower.rows.other_address.label"
              }
            />
          </Box>
        )}
      </Box>
      <PersonalInformationConsentCheck
        name="rulesApprove"
        checked={this.state.rulesApprove}
        onChange={this.onSelectChange}
      />
    </React.Fragment>
  )

  renderFormControls = () => (
    <FlexBox
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <FormLoadingButton
        type="submit"
        default
        disabled={this.state.busy}
        onClick={this.onDraftRequest}
        isLoading={this.state.isSaveLoading}
        variant="outlinedBlack"
        width="176px"
      >
        <Translate
          i18n="accreditation.individual.buttons.save"
          ns="components"
        />
      </FormLoadingButton>

      <ProfileConfirmContainer
        environment={this.props.environment}
        profileId={this.state.profileId}
        confirmed={this.state.confirmed}
        continueLink={`/accounts/${this.state.profileId}`}
        mutationKind="REQUEST_PROFILE_ACCREDITATION"
      >
        {() => (
          <FormLoadingButton
            type="button"
            onClick={this.saveDraft(this.requestProfileAccreditation)}
            disabled={this.state.busy || !this.state.rulesApprove}
            isLoading={this.state.isLoading}
            variant="outlinedRed"
            width="176px"
          >
            <Translate
              i18n="accreditation.individual.buttons.continue"
              ns="components"
            />
          </FormLoadingButton>
        )}
      </ProfileConfirmContainer>
    </FlexBox>
  )

  render() {
    return (
      <RowsContainer maxWidth="100%">
        <Template
          header={FormHeader}
          content={this.renderFormContent}
          footer={this.renderFormControls}
          wrapper={FormWrapper}
          layout="card"
        />
      </RowsContainer>
    )
  }
}

export default (props) => (
  <React.Fragment>
    <ToastContainer autoClose={4000} hideProgressBar={true} />
    <ErrorsContainer>
      {(errors) => (
        <RelayEnvironmentConsumer>
          {({ environment }) => (
            <Route>
              {({ history }) => (
                <Form
                  {...props}
                  {...errors}
                  environment={environment}
                  history={history}
                />
              )}
            </Route>
          )}
        </RelayEnvironmentConsumer>
      )}
    </ErrorsContainer>
  </React.Fragment>
)
