import React from "react"

import { PaymentScheduleItem } from "src/components"

import { TotalScheduleItem } from "../PaymentScheduleItem"

import {
  HeadTh,
  HeadTr,
  TableHead,
  Table,
} from "../../organisms/OrdersList/Lists/Market/styles"

import { TableWrap } from "./styles"

import { getTotalData } from "./utils"

function Render(props) {
  const { schedule, isCession } = props

  let totalDebt = 0
  let balanceOwed = 0
  for (let i = 0; i < schedule.length; i += 1) {
    totalDebt += schedule[i].loan
  }

  return (
    <TableWrap>
      <Table cellPadding="5">
        <TableHead>
          <HeadTr>
            <HeadTh>Дата платежа</HeadTh>
            <HeadTh>Сумма</HeadTh>
            <HeadTh>Основной долг</HeadTh>
            <HeadTh>Проценты</HeadTh>
            <HeadTh>НДФЛ</HeadTh>
            <HeadTh>К выплате</HeadTh>
            <HeadTh>Остаток долга</HeadTh>
          </HeadTr>
        </TableHead>
        <tbody>
          {schedule.map((item, index) => {
            balanceOwed += item.loan
            const debt = totalDebt - balanceOwed

            return (
              <PaymentScheduleItem
                debt={debt}
                key={index}
                index={index}
                data={item}
                isCession={isCession}
              />
            )
          })}
          {schedule.length > 0 && (
            <TotalScheduleItem
              isCession={isCession}
              data={getTotalData(schedule)}
            />
          )}
        </tbody>
      </Table>
    </TableWrap>
  )
}

export default Render
