import React from "react"

import { TableBody, TableContainer } from "./styles"

import { Cell, HeadCell, RowTitleCell } from "./elements"

const Table = ({ scheme }) => (
  <TableContainer>
    <thead>
      <tr>
        {scheme.header.map((item, headerIndex) => (
          <HeadCell key={headerIndex}>{item.label}</HeadCell>
        ))}
      </tr>
    </thead>
    <TableBody>
      {scheme.body.map((row, idx) => (
        <tr key={idx}>
          {row.map((item, rowIndex) => (rowIndex ? (
              <Cell key={rowIndex}>{item.label}</Cell>
          ) : (
              <RowTitleCell key={rowIndex}>{item.label}</RowTitleCell>
          )))}
        </tr>
      ))}
    </TableBody>
  </TableContainer>
)

export default Table
