import React from "react"
import { graphql } from "react-relay"
import { ToastContainer } from "react-toastify"

import { dynamic, catchError } from "src/utils"
import i18n from "src/i18n"
import { titlesConfig } from "src/constants"

import {
  Footer,
  UtmWatcher,
  PageWrapper,
  QueryRenderer,
  ErrorBoundary,
  Translate,
  HtmlTitle,
} from "src/components"

import {
  LocaleProvider,
  RelayEnvironmentProvider,
  ViewerDataFragmentProvider,
} from "src/context"

import { MainPageQueryResponse } from "./__generated__/MainPageQuery.graphql"

const User = dynamic(() => import("./user").catch(catchError))
const Guest = dynamic(() => import("./guest").catch(catchError))

const query = graphql`
  query MainPageQuery {
    viewer {
      id
      locale
      country
      trust {
        isAvailable
      }
      ...User_viewer
      ...ViewerData_viewer
    }
  }
`

function Router(props: MainPageQueryResponse) {
  const { viewer } = props || {}
  const isLoggedIn = viewer && viewer.id

  return (
    <>
      {!!isLoggedIn && <User {...props} />}
      {!isLoggedIn && <Guest {...props} />}
    </>
  )
}

function Render(props: MainPageQueryResponse) {
  return (
    <ErrorBoundary>
      <ViewerDataFragmentProvider {...props}>
        <UtmWatcher />
        <Translate
          render={() => {
            const titlePrefix = i18n.t("common:titles.prefix")
            const defaultTitle = titlePrefix + i18n.t("common:titles.main")

            return (
              <HtmlTitle
                config={titlesConfig}
                defaultTitle={defaultTitle}
                prefix={titlePrefix}
              />
            )
          }}
        />
        <ToastContainer autoClose={4000} hideProgressBar />
        <PageWrapper>
          <Router {...props} />
        </PageWrapper>
        <Footer />
      </ViewerDataFragmentProvider>
    </ErrorBoundary>
  )
}

const Main = (props: any) => (
  <RelayEnvironmentProvider>
    <LocaleProvider>
      <QueryRenderer {...props} query={query} render={Render} />
    </LocaleProvider>
  </RelayEnvironmentProvider>
)

export default Main
