import Dead from "./Dead"
import Pending from "./Pending"
import Complete from "./Complete"
import Declined from "./Declined"
import Approved from "./Approved"
import Transfer from "./Transfer"
import Confirmed from "./Confirmed"

const render = () => null

render.dead = Dead
render.pending = Pending
render.declined = Declined
render.approved = Approved
render.transfer = Transfer
render.complete = Complete
render.confirmed = Confirmed

export default render
