import React from "react"
import { Icon } from "./styles"

/* eslint-disable max-len */
export const ErrorIcon = () => (
  <Icon
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85 8.45H7.15V9.75H5.85V8.45ZM5.85 3.25H7.15V7.15H5.85V3.25ZM6.4935 0C2.9055 0 0 2.912 0 6.5C0 10.088 2.9055 13 6.4935 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.4935 0ZM6.5 11.7C3.627 11.7 1.3 9.373 1.3 6.5C1.3 3.627 3.627 1.3 6.5 1.3C9.373 1.3 11.7 3.627 11.7 6.5C11.7 9.373 9.373 11.7 6.5 11.7Z"
      fill="#F70000"
    />
  </Icon>
)

export const StarIcon = (props) => (
  <svg {...props} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.25 1L5.49375 5.14375L1 5.525L4.4125 8.48125L3.3875 12.875L7.25 10.5438L11.1125 12.875L10.0875 8.48125L13.5 5.525L9.00625 5.14375L7.25 1Z" />
  </svg>
)

StarIcon.defaultProps = {
  width: "15px",
  height: "15px",
  fill: "#6FA84B",
}
