/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type TrustedOrderInfoQueryVariables = {
    id: string;
};
export type TrustedOrderInfoQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly createdAt?: string;
        readonly status?: OrderStatusEnum | null;
        readonly profile?: {
            readonly _avatar: {
                readonly url: string;
            } | null;
        };
        readonly offer?: {
            readonly data: string;
            readonly declineReason: string | null;
        } | null;
        readonly application?: {
            readonly data: string;
            readonly declineReason: string | null;
        } | null;
        readonly confirmedAt?: string | null;
        readonly karma?: {
            readonly isTrusted: boolean;
        };
    };
};
export type TrustedOrderInfoQuery = {
    readonly response: TrustedOrderInfoQueryResponse;
    readonly variables: TrustedOrderInfoQueryVariables;
};



/*
query TrustedOrderInfoQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on Order {
      createdAt
      status
      profile {
        __typename
        _avatar {
          url
        }
        id
      }
      offer {
        data
        declineReason
        id
      }
      application {
        data
        declineReason
        id
      }
      confirmedAt
      karma {
        isTrusted
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "_avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "declineReason",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "confirmedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderKarma",
  "kind": "LinkedField",
  "name": "karma",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTrusted",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrustedOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrustedOrderInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TrustedOrderInfoQuery",
    "operationKind": "query",
    "text": "query TrustedOrderInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on Order {\n      createdAt\n      status\n      profile {\n        __typename\n        _avatar {\n          url\n        }\n        id\n      }\n      offer {\n        data\n        declineReason\n        id\n      }\n      application {\n        data\n        declineReason\n        id\n      }\n      confirmedAt\n      karma {\n        isTrusted\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0f20018daa2c1bf19aaca8447617cdc1';
export default node;
