import memoize from "memoize-one"

import Content from "./Tooltip/Content"

import MinValue from "./styles"

/* eslint-disable no-shadow */
const IndicatorData = (Indicator = null, Content = null) => ({
  Content,
  Indicator,
})
/* eslint-enable no-shadow */

const ensureData = memoize(
  (indicator, content) => IndicatorData(indicator ? MinValue : null, content),
)

const ensureContent = memoize((type, status) => Content[type][status])

export default new Map([
  [
    "Order",
    new Map([
      ["CONFIRMED", ensureData(true, ensureContent("Order", "confirmed"))],
      ["TRANSFER", ensureData(null, null)],
      ["COMPLETE", ensureData(null, null)],
      ["DEAD", ensureData(true, ensureContent("Order", "dead"))],
    ]),
  ],
  [
    "Loan",
    new Map([
      ["DEAD", ensureData(true, ensureContent("Loan", "dead"))],
      ["PENDING", ensureData(true, ensureContent("Loan", "pending"))],
      ["DECLINED", ensureData(true, ensureContent("Loan", "declined"))],
      ["TRANSFER", ensureData(true, ensureContent("Loan", "transfer"))],
      ["CONFIRMED", ensureData(true, ensureContent("Loan", "confirmed"))],
      ["TRANSFER", ensureData(null, null)],
      ["COMPLETE", ensureData(true, ensureContent("Loan", "complete"))],
    ]),
  ],
  [
    "Proposal",
    new Map([
      ["DEAD", ensureData(true, ensureContent("Proposal", "dead"))],
      ["PENDING", ensureData(true, ensureContent("Proposal", "pending"))],
      ["DECLINED", ensureData(true, ensureContent("Proposal", "declined"))],
      ["APPROVED", ensureData(true, ensureContent("Proposal", "approved"))],
      ["TRANSFER", ensureData(true, ensureContent("Proposal", "transfer"))],
      ["COMPLETE", ensureData(true, ensureContent("Proposal", "complete"))],
      ["CONFIRMED", ensureData(true, ensureContent("Proposal", "confirmed"))],
      ["COMPLETE", ensureData(true, ensureContent("Proposal", "complete"))],
    ]),
  ],
  [
    "Investment",
    new Map([
      ["CONFIRMED", ensureData(true, ensureContent("Investment", "confirmed"))],
      ["TRANSFER", ensureData(null, null)],
      ["COMPLETE", ensureData(null, null)],
      ["DEAD", ensureData(true, ensureContent("Investment", "dead"))],
    ]),
  ],
])
