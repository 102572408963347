import { useState, useEffect } from "react"

import { useHotKey } from "src/hooks"
import { addressObjectToString } from "src/utils"

import useSuggestions from "./useSuggestions"

const addressToStringConfig = {
  divider: "",
  useTranslations: false,
}

const shouldApplyValue = (activeIndex, options) =>
  options.length > 0 && activeIndex >= 0 && activeIndex < options.length

const useAutoSuggest = (injectedValue) => {
  const [isDirty, setDirty] = useState(false)
  const [isFocused, setFocus] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeSuggestion, setActiveSuggestion] = useState({})
  const [displayOptions, setDisplayOptions] = useState(false)

  const addressAsString = injectedValue.raw
    || addressObjectToString(injectedValue, addressToStringConfig)
  const [inputValue, setInputValue] = useState(addressAsString)
  const [activeIndex, setActiveIndex] = useState(-1)

  const { isLoading, suggestions, notifyActor } = useSuggestions()

  useEffect(() => {
    if (!setDisplayOptions) setActiveIndex(-1)
  }, [setDisplayOptions])

  useEffect(() => {
    if (isFocused && isDirty) {
      setDisplayOptions(true)
    } else {
      setDisplayOptions(false)
    }
  }, [isFocused, isDirty])

  useEffect(() => {
    if (shouldApplyValue(activeIndex, suggestions)) {
      setInputValue(suggestions[activeIndex].value)
      setActiveSuggestion(suggestions[activeIndex])
    }
  }, [activeIndex])

  useEffect(() => {
    if (!isDirty) setDirty(true)

    notifyActor(inputValue)
  }, [inputValue])

  useEffect(() => {
    if (isFocused) {
      setActiveIndex(-1)
    }
  }, [isFocused])

  const incrementActiveIndex = () => {
    if (isFocused && activeIndex + 1 < suggestions.length) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const decrementActiveIndex = () => {
    if (isFocused && activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const onFocus = () => setFocus(true)

  const onBlur = () => {
    setFocus(false)
  }

  const onSelect = (i) => () => {
    setActiveIndex(i)
  }

  const onKeyboardSelect = () => {
    setDirty(false)
  }

  const onInputValueChange = (e) => {
    const { value } = e.target

    setInputValue(value)
  }

  useHotKey(13, onKeyboardSelect)
  useHotKey(38, decrementActiveIndex)
  useHotKey(40, incrementActiveIndex)

  return {
    onBlur,
    onFocus,
    onSelect,
    isLoading,
    inputValue,
    suggestions,
    activeIndex,
    displayOptions,
    onInputValueChange,
  }
}

export default useAutoSuggest
