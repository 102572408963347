import React from "react"
import memoize from "memoize-one"
import PropTypes from "prop-types"

import {
  TextField,
  Translate,
  FormRowItem,
  BankDataSuggestion,
} from "src/components"

import suggestionTheme from "./suggestionTheme"

const bicLabel = memoize(() => (
  <Translate ns="components" i18n="bank-input.bic.label" />
))

const nameLabel = memoize(() => (
  <Translate ns="components" i18n="bank-input.name.label" />
))

const fields = [
  {
    name: "bic",
    label: bicLabel,
    first: true,
    props: {
      size: 20,
      theme: suggestionTheme,
      mergeWithDefaultTheme: true,
      daDataSelector: (suggestion) => suggestion.data.bic,
      renderInputComponent: (props) => <TextField {...props} />,
    },
  },
  {
    name: "name",
    label: nameLabel,
    props: {
      size: 20,
      theme: suggestionTheme,
      mergeWithDefaultTheme: true,
      daDataSelector: (suggestion) => suggestion.data.name.payment,
      renderInputComponent: (props) => <TextField {...props} />,
    },
  },
]

class BankInput extends React.PureComponent<any> {
  onChange = (event) => {
    const { name, value } = event.target
    this.props.onChange({
      target: {
        name: this.props.name,
        value: (BankInput as any).toGraphQLInput({ ...this.props.value, [name]: value }),
      },
    })
  }

  renderField = (field) => (
    <FormRowItem key={field.name} label={field.label()} first={field.first}>
      <BankDataSuggestion
        name={field.name}
        value={this.props.value[field.name]}
        onChange={this.onChange}
        onSuggestionSelected={this.props.onSuggestionSelected}
        {...field.props}
      />
    </FormRowItem>
  )

  render() {
    return fields.map(this.renderField)
  }
}

(BankInput as any).propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    bic: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

(BankInput as any).fromGraphQLType = (attrs: any = {}) => ({
  bic: attrs.bic || "",
  name: attrs.name || "",
});

(BankInput as any).toGraphQLInput = (attrs) => ({
  bic: attrs.bic,
  name: attrs.name,
})

export default BankInput
