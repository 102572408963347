/* eslint-disable max-len */

import React from "react"

function Success(props) {
  return (
    <svg {...props} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM13.8704 24.5391L20.1872 30.9921L36.1298 14.7059L38.2354 16.8569L20.1872 35.2941L11.7648 26.6901L13.8704 24.5391Z"
      />
    </svg>
  )
}

Success.defaultProps = {
  width: "50px",
  height: "50px",
  fill: "#6FA84B",
}

export default Success
