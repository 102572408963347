import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ProfilesListQuery {
    viewer {
      id
      profiles {
        id
        __typename
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
          paymentApprovalDocument {
            url
          }
        }
        ... on IndividualProfile {
          name
          inn
          investorAccreditation: accreditation(role: INVESTOR) {
            status
            declineReason
          }
        }
        ... on EntrepreneurProfile {
          name
          inn
          investorAccreditation: accreditation(role: INVESTOR) {
            status
            declineReason
          }
          borrowerAccreditation: accreditation(role: BORROWER) {
            status
            declineReason
          }
        }
        ... on LegalEntityProfile {
          name
          inn
          borrowerAccreditation: accreditation(role: BORROWER) {
            status
            declineReason
          }
        }
      }
    }
  }
`

export default (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)
