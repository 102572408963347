import React from "react"

import { Flex } from "src/components"

import Icon from "./Icon"
import { Note, Title } from "./elements"
import { StyledLink, DocumentWrapper } from "./styles"

const Document = ({ doc, withIcon }) => {
  const {
    url, note, title, glyph, disabled,
  } = doc

  return (
    <DocumentWrapper>
      <StyledLink href={url} target="_blank">
        <Flex alignItems="center">
          {withIcon && <Icon disabled={disabled}>{glyph}</Icon>}
          <Title disabled={disabled}>{title}</Title>
          {note && <Note disabled={disabled}>{note}</Note>}
        </Flex>
      </StyledLink>
    </DocumentWrapper>
  )
}

Document.defaultProps = {
  withIcon: true,
}

export default Document
