import styled, { css } from "styled-components"

export const TitleContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`

export const Title = styled.div`
  font-family: Geometria;
  font-size: 20px;
  line-height: 56px;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
  text-align: left;
  color: rgba(74, 74, 74, 0.75);
`

export const Description = styled.div`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
  color: #000000;
  text-align: center;
`

export const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
`
