import styled from "styled-components"

import { Text, Title } from "src/components"

export const StyledTitle = styled(Title)`
  text-transform: capitalize;
`

export const Container = styled.div`
  padding: 0 37px 0 60px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  right: -33px;
  top: -11px;
  width: 340px;
  height: 70px;
  background-image: url(/images/backgrounds/pennon.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`

export const StyledText = styled(Text)`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.white};
`
