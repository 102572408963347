import React from "react"

import { RightSideSwitch } from "src/components"

export class LicenseApprove extends React.PureComponent<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = ({ name, value }) => {
    this.props.onChange({
      name: this.props.name,
      value,
    })
  }

  render() {
    return (
      <RightSideSwitch
        name="licenseApprove"
        id={`${this.props.id}licenseApprove`}
        value={this.props.value}
        checked={this.props.checked}
        label="components:application.worksheet.rows.license.label"
        rightLabel="components:application.worksheet.rows.license.yes"
        leftLabel="components:application.worksheet.rows.license.no"
        onChange={this.onChange}
      />
    )
  }
}

export default LicenseApprove
