import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-top: -28px;
  height: auto;
  background-color: #f9f9f9;
  padding: 56px 32px 28px;
  box-sizing: border-box;

  @media screen and (max-width: 1280px) {
    width: calc(100% + 40px);
    padding: 56px 20px 28px;
    margin-left: -20px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    padding: 56px 0 8px;
    margin: -28px 0 0;
  }
`

export const Table = styled.table`
  position: relative;
  width: 100%;
  table-layout: fixed;
`

export const TH = styled.td<any>`
  position: relative;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: center;
  padding-bottom: 16px;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.25;
    `};

  ${({ isSelected }) => isSelected
    && css`
      &:after {
        z-index: 9;
        content: "";
        background-color: #fff;
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        position: absolute;
        top: -45px;
        left: 50%;
        border-radius: 1px;
        transform: translateX(-50%) rotate(135deg);
        box-shadow: 4px -4px 6px -4px
          ${({ theme }) => theme.colors.fullBlacks[2]};
      }
    `};

  @media screen and (max-width: 480px) {
    ${({ isHidden }) => isHidden
      && css`
        display: none;
      `};
  }
`

export const FirstTH = styled(TH)`
  font-size: 10px;
  font-weight: normal;
  text-align: right;
  width: 85px;
`

export const TD = styled(TH)`
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: normal;
`

export const FirstTD = styled(TD)`
  font-size: 10px;
  text-align: right;
  width: 85px;
`

export const FooterTD = styled(TD)`
  font-weight: 600;
  padding-top: 20px;
`

export const FirstFooterTD = styled(FooterTD)`
  font-size: 10px;
  text-align: right;
  width: 85px;
`
