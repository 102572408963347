import React from "react"

import {
  Box, Translate,
} from "src/components"

import {
  PassportRowWrapper,
  Tooltip,
  PassportScansContainer,
} from "../Passport/styles"
import Attachments, { AttachmentType } from "../Attachments"

type SupportingDocumentsValue = {
  id: string;
  status: string;
  attachment: AttachmentType;
}

type SupportingDocumentsProps = {
  environment: any;
  error: string | null;
  errors: [];
  hasError: boolean;
  name: string;
  onChange: (event) => void;
  purposedmutations: string[];
  value: SupportingDocumentsValue[];
  description?: string;
}

const SupportingDocuments = (props: SupportingDocumentsProps) => {
  const changedValue = (attachments) => attachments?.map((item) => {
    const isDeleteButtonDisabled = item.status === "APPROVED"
    if (item.attachment) return { ...item.attachment, isDeleteButtonDisabled }
    return item
  })

  return <React.Fragment>
      <PassportScansContainer>
        {props.description && (
          <Box width="100%" pb="12px">
            <Tooltip>
              <Translate i18n={props.description} />
            </Tooltip>
          </Box>
        )}
        <PassportRowWrapper width="100%">
          <Attachments
            width="100%"
            padding="0 0 20px"
            {...props}
            value={changedValue(props.value)}
          />
          <Box width="100%">
            <Tooltip>
              <Translate i18n="components:individual_profile_form.extras.supportingDocuments.documents" />
            </Tooltip>
          </Box>
        </PassportRowWrapper>
      </PassportScansContainer>
    </React.Fragment>
}

export default SupportingDocuments
