import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation InstantPurchaseOrderMutation(
    $input: InstantPurchaseOrderInput!
    $orderId: ID!
  ) {
    instantPurchaseOrder(input: $input) {
      order {
        id
        chain {
          gatheredAmount
        }
        viewer {
          investedAmount
        }
      }
      profile {
        ... on IndividualProfile {
          id
          accountBalanceDetailed {
            available
            currency
          }
          investment(orderId: $orderId) {
            id
            amount
          }
        }
        ... on EntrepreneurProfile {
          id
          accountBalanceDetailed {
            available
            currency
          }
          investment(orderId: $orderId) {
            id
            amount
          }
        }
        ... on ForeignIndividualProfile {
          id
          investment(orderId: $orderId) {
            id
            amount
          }
        }
      }
    }
  }
`

export default enhance({ mutation })
