/* eslint-disable camelcase */
import React from "react"

import { MutationContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import { RequestWithdrawal, RequestProfilePhoneConfirmation } from "src/mutations"

import {
  Box,
  Flex,
  Button,
  CountDown,
  Translate,
  FormLoadingButton,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"

import { ModalHeader } from "./elements"
import { StyledText } from "./styles"
import { Withdraw_profile } from "./__generated__/Withdraw_profile.graphql"

type ConfirmationType = ConfirmationMainType & {
  clearError: (value: string) => void;
  clearTimer: () => void;
  commit: (value: any) => void;
  environment: any;
  getError: (value: string) => void;
  restartTimer: (value?: boolean) => void;
  secondsLeft: number;
  startTimer: () => void;
}

type ConfirmationMainType = {
  availableBalance: number;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onComplete: () => void;
  profile: Withdraw_profile;
  withdrawAmount: string;
}

class Confirmation extends React.PureComponent<ConfirmationType> {
  isMounted = false

  state = {
    code: "",
    busy: false,
    codeSent: false,
  }

  componentDidMount() {
    this.isMounted = true
    this.onCodeRequest()
    this.props.startTimer()
  }

  componentWillUnmount() {
    this.isMounted = false
  }

  onCodeRequest = () => {
    const variables = {
      input: {
        kind: "REQUEST_WITHDRAWAL",
        profileId: this.props.profile.id,
      },
    }

    const callbacks = {
      onError: this.onCodeRequestError,
      onCompleted: this.onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
    if (this.isMounted) {
      this.setState({ busy: true })
    }
  }

  onCodeRequestSuccess = () => {
    if (this.isMounted) {
      this.setState({ codeSent: true, busy: false })
    }
  }

  onCodeRequestError = () => {
    this.props.clearTimer()
    if (this.isMounted) {
      this.setState({ busy: false })
    }
  }

  onCodeResend = () => {
    this.onCodeRequest()
    this.props.restartTimer()
  }

  onCodeConfirm = () => {
    this.setState({ busy: true })

    const variables = {
      input: {
        currency: "RUB",
        token: this.state.code,
        profileId: this.props.profile.id,
        amount: Number(
          this.props.withdrawAmount.replace(",", ".").replace(/\s/g, ""),
        ),
      },
    }

    const callbacks = {
      onError: this.onCodeConfirmError,
      onCompleted: this.onCodeConfirmSuccess,
    }

    this.props.commit({ callbacks, variables })
  }

  onCodeConfirmSuccess = () => {
    this.props.onComplete()
  }

  onCodeConfirmError = () => {
    this.setState({ busy: false })
  }

  onChange = (e) => {
    this.setState({ code: e.target.value })
    this.props.clearError("requestWithdrawal.token")
  }

  render() {
    const { code, busy, codeSent } = this.state

    const {
      getError, secondsLeft,
    } = this.props

    const error = getError("requestWithdrawal.token")

    return (
      <Box width="375px" p="35px">
        <ModalHeader
          i18n="components:account.operations.withdraw.confirmation.title"
          // amount={withdrawAmount}
        />
        <Box m="25px 0 15px 0">
          <StyledText>
            <Translate
              i18n="components:account.operations.withdraw.confirmation.to_number"
              // number={profile.phone}
            />
          </StyledText>
        </Box>
        <FormInput
          autoFocus
          name="phone_confirmation"
          value={code}
          error={error}
          onChange={this.onChange}
          label="components:account.bank_account.confirm.label"
        />
        <ResendRequestButton nowrap secondsLeft={secondsLeft} />
        {/* <Box mt="25px" mb="40px">
          <StyledText>
            <Translate i18n="components:account.operations.withdraw.confirmation.time" />
          </StyledText>
        </Box> */}
        <Flex justifyContent="flex-end" mt="40px">
          <Box mr="12px">
            <Button
              width="165px"
              variant="outlinedBlack"
              height="45px"
              fontSize="0.75rem"
              disabled={!!secondsLeft || !codeSent}
              onClick={this.onCodeResend}
            >
              <Translate
                ns="components"
                i18n="account.bank_account.confirm.buttons.resend"
              />
            </Button>
          </Box>
          <FormLoadingButton
            width="123px"
            height="45px"
            fontSize="0.75rem"
            variant="outlinedRed"
            disabled={!code}
            isLoading={busy}
            onClick={this.onCodeConfirm}
          >
            <Translate
              ns="components"
              i18n="account.bank_account.confirm.buttons.continue"
            />
          </FormLoadingButton>
        </Flex>
      </Box>
    )
  }
}

export default (props: ConfirmationMainType) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => (
      <CountDown>
        {({
          start, clear, restart, secondsLeft,
        }) => (
          <MutationContainer mutation={RequestWithdrawal}>
            {({ commit, getError, clearError }) => (
              <Confirmation
                {...props}
                commit={commit}
                getError={getError}
                clearError={clearError}
                startTimer={start}
                clearTimer={clear}
                restartTimer={restart}
                secondsLeft={secondsLeft}
                environment={environment}
              />
            )}
          </MutationContainer>
        )}
      </CountDown>
    )}
  </RelayEnvironmentConsumer>
)
