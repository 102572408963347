import styled from "styled-components"

// TODO: delete all commented legacy code
export const ContentContainer = styled.div`
  display: block;
  /* flex-wrap: wrap; */
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  width: 100%;
  margin-top: 25px;
`

export const FooterContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Form = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`
