import React, { useState } from "react"
import Relay, { graphql } from "react-relay"

import {
  ProfileCash,
  ProfileMenu,
  ProfileInvestorAccreditation,
} from "src/components"
import { ProfileType } from "src/constants"

import { DescriptionModal } from "./Elements"

import { Container } from "./styles"

function ProfileInfo(props) {
  const {
    profile, foreignProfile, changeTab, tab, history,
  } = props
  const {
    location: { pathname, search },
  } = history

  const { investor, id: userId, __typename: profileType } = profile
  const { qualified } = investor || {}

  const isVisible = search === ""
    && (pathname === `/accounts/${userId}` || pathname === `/accounts/${userId}/`)

  const [isOpened, setOpened] = useState(false)

  function openModal() {
    setOpened(true)
  }

  function closeModal() {
    setOpened(false)
  }

  return (
    <Container isVisible={isVisible}>
      <ProfileMenu
        tab={tab}
        changeTab={changeTab}
        profile={profile}
        history={history}
      />
      <ProfileCash
        balance={profile}
        profile={profile}
        changeTab={changeTab}
        foreignProfile={foreignProfile}
        openModal={openModal}
      />

      {profileType === ProfileType.individual && !qualified && (
        <ProfileInvestorAccreditation profile={profile} openModal={openModal} />
      )}

      {/* <ProfilePortfolio investments={viewer} /> */}

      <DescriptionModal isOpened={isOpened} onClose={closeModal} />
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfileInfo, {
  profile: graphql`
    fragment ProfileInfo_profile on UserProfile {
      id
      __typename
      ... on IndividualProfile {
        investor {
          investedAmount
          qualified
        }
      }
      ...ProfileCash_balance
      ...ProfileCash_profile
      ...ProfileMenu_profile
      ...ProfileInvestorAccreditation_profile
    }
  `,
})
