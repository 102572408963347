import React from "react"
import Relay, { graphql } from "react-relay"

import { divideNumber } from "src/utils"
import { ProfileType, MAX_CIRCULATION_AMOUNT } from "src/constants"
import { VirtualAccountActions } from "src/components"

import {
  Container,
  FlexContainer,
  ValueInner,
  Title,
  Value,
  Text,
  ButtonContainer,
  CirculationContainer,
  Icon,
} from "./styles"

function ProfileCash(props) {
  const {
    balance, profile, foreignProfile, changeTab, openModal,
  } = props

  const {
    accountBalanceDetailed,
    __typename: profileType,
    investor,
  } = balance

  const { available = 0, blocked = 0, invested = 0 } = accountBalanceDetailed[0] || {}
  const total = available + blocked + invested

  const { investedAmount = 0 } = investor || {}

  return (
    <Container>
      <Title>Счет</Title>
      <Value>{divideNumber(total.toFixed(2))} ₽</Value>

      <ValueInner>
        <FlexContainer>
          <Text>Инвестиции, ₽</Text>
          <Text>{divideNumber(invested.toFixed(2))}</Text>
        </FlexContainer>
        <FlexContainer>
          <Text>Заблокировано, ₽</Text>
          <Text>{divideNumber(blocked.toFixed(2))}</Text>
        </FlexContainer>
        <FlexContainer>
          <Text>Доступно, ₽</Text>
          <Text>{divideNumber(available.toFixed(2))}</Text>
        </FlexContainer>

        {profileType === ProfileType.individual && (
          <CirculationContainer>
            <Text>
              Оборот, ₽{" "}
              <Icon
                alert={investedAmount >= MAX_CIRCULATION_AMOUNT}
                onClick={openModal}
              />
            </Text>
            <Text>{divideNumber(investedAmount.toFixed(2))}</Text>
          </CirculationContainer>
        )}

        {/* {circulation >= MAX_CIRCULATION_AMOUNT && ( */}
        {/*  <Alert> */}
        {/*    <Link */}
        {/*      onClick={() => changeTab("information")} */}
        {/*      to={`/accounts/${userId}?type=information`} */}
        {/*    > */}
        {/*      Подтвердите статус */}
        {/*    </Link> */}
        {/*    <AlertText> */}
        {/*      квалифицированного инвестора, чтобы продолжить инвестировать */}
        {/*    </AlertText> */}
        {/*  </Alert> */}
        {/* )} */}
      </ValueInner>

      <ButtonContainer>
        <VirtualAccountActions.Withdraw
          profile={profile}
          foreignProfile={foreignProfile}
          changeTab={changeTab}
        />
        <VirtualAccountActions.Deposit
          profile={profile}
          foreignProfile={foreignProfile}
        />
      </ButtonContainer>
    </Container>
  )
}

function Render(props) {
  const Fragment = Relay.createFragmentContainer(ProfileCash, {
    balance: graphql`
      fragment ProfileCash_balance on UserProfile {
        id
        __typename
        accountBalanceDetailed {
          currency
          available
          blocked
          invested
        }
        ... on IndividualProfile {
          investor {
            investedAmount
          }
        }
      }
    `,
    profile: graphql`
      fragment ProfileCash_profile on UserProfile {
        ...Deposit_profile
        ...Withdraw_profile
      }
    `,
    foreignProfile: graphql`
      fragment ProfileCash_foreignProfile on ForeignIndividualProfile {
        ...Deposit_foreignProfile
        ...Withdraw_foreignProfile
      }
    `,
  })
  return <Fragment {...props} />
}

export default React.memo(Render)
