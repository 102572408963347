export const WITHDRAWAL_FEE = 50
export const MIN_INVESTMENT_AMOUNT = 1000
export const MAX_CIRCULATION_AMOUNT = 600000

export const registrationSources = {
  WEB: "web",
  LANDING: "landing",
}

// eslint-disable-next-line
export const personalDataAgreementUrl =
  "https://docs.google.com/document/d/1I81In2cZ0ZsNua9_F1jUZqUWXsMLpvptXfSAhhZdM_U/edit"
export const userAgreementUrl = "https://docs.google.com/document/d/1Y5jFuojdMTJSh4Rwx_7mHrQsprqCjiqBZ-m13cUp9-w/edit"
// eslint-disable-next-line operator-linebreak
export const taxResidencyApplicationTemplateUrl =
  "https://docs.google.com/document/d/1JfAgnbXVZ1pEBeqT4dAqyfZQBSq7kkpsg0Mk1Vrn42c/edit"
