import React from "react"

import { Translate } from "src/components"

import {
  Container, Text, LinkButton, BlueText,
} from "./styles"

const ResendRequestButton = (props) => {
  const { onClick, secondsLeft } = props

  if (!secondsLeft) {
    return onClick ? (
      <Container>
        <LinkButton onClick={onClick}>
          <Translate
            i18n="individual_account.update.phone_confirm.resend_code"
            ns="components"
          />
        </LinkButton>
      </Container>
    ) : null
  }

  return (
    <Container>
      <Text>
        Не пришел код?{" "}
        <BlueText>Отправить повторно через {secondsLeft} сек</BlueText>
      </Text>
    </Container>
  )
}

export default ResendRequestButton
