import React from "react"

import { Translate } from "src/components"

import { Container, StyledButton, ButtonContainer } from "./styles"

/*
 *
 * действия
 * 1) Сохранить черновик -> без дополнительонго действия
 * 2) Продолжить  -> Дополнительное действие
 * 3) Отмена -> Предложить сохранение
 *
 * */

const Controls = (props) => {
  const {
    onSave,
    onCancel,
    onContinue,
    showCancel,
    saveLabel,
    cancelLabel,
    continueLabel,
    isSaveLoading,
    isContinueLoading,
    isContinueDisabled,
  } = props

  return (
    <Container>
      {onSave && (
        <ButtonContainer>
          <StyledButton
            variant="outlinedBlack"
            onClick={onSave}
            isLoading={isSaveLoading}
          >
            <Translate i18n={saveLabel} />
          </StyledButton>
        </ButtonContainer>
      )}
      {showCancel && (
        <ButtonContainer>
          <StyledButton variant="outlinedBlack" onClick={onCancel}>
            <Translate i18n={cancelLabel} />
          </StyledButton>
        </ButtonContainer>
      )}
      <ButtonContainer>
        <StyledButton
          isLoading={isContinueLoading}
          disabled={isContinueDisabled}
          variant="outlinedRed"
          onClick={onContinue}
        >
          <Translate i18n={continueLabel} />
        </StyledButton>
      </ButtonContainer>
    </Container>
  )
}

Controls.defaultProps = {
  onCancel: () => {},
  onContinue: () => {},
  showCancel: true,
  isContinueLoading: false,
  isContinueDisabled: false,
  saveLabel: "common:buttons.save",
  cancelLabel: "common:buttons.cancel",
  continueLabel: "common:buttons.continue",
  saveButtonVariant: "default",
}

export default Controls
