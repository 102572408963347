import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Flag = styled.div<any>`
  background-image: url(/flags/${({ flag }) => flag}.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  overflow: hidden;
  padding-top: 21px;
  width: 28px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);
`

const render = (props) => <Flag {...props} />

render.propTypes = {
  flag: PropTypes.string.isRequired,
}

export default render
