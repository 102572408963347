import styled from "styled-components"

import { Text } from "src/components"

export const TemplateContainer = styled.div`
  padding-top: 130px;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const Header = styled(Text)`
  font-size: 32px;
`

export const Image = styled.img`
  margin: 0 auto;
  height: 100%;
`

export const ImageContainer = styled.div`
  padding: 32px 0 46px;
  box-sizing: content-box;
  max-height: 180px;
`

export const Description = styled(Text)`
  font-size: 15px;
  white-space: pre-line;
  text-align: center;
  line-height: 24px;
`

export const Appendix = styled(Text)`
  padding-top: 14px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const ActionContainer = styled.div`
  padding-top: 32px;
  margin: 0 auto;
`
