import React from "react"

import { Translate } from "src/components"

import {
  Header, SubHeader, UnorderedList, InclinedText,
} from "./styles"

import { ContentContainer } from "../styles"

const render = () => (
  <ContentContainer>
    <Header>
      <Translate i18n="components:account.order_item.assessment.borrower.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.2" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.3" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.4" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.5" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.6" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.7" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.8" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.9" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.borrower.contents.10" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.related_individuals.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.2" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.3" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.4" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.5" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.6" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.related_individuals.contents.7" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.documents.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.documents.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.documents.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.documents.contents.2" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.collateral.header" />
    </Header>
    <SubHeader>
      <Translate i18n="components:account.order_item.assessment.collateral.estate.header" />
    </SubHeader>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.estate.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.estate.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.estate.contents.2" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.estate.contents.3" />
      </li>
    </UnorderedList>
    <SubHeader>
      <Translate i18n="components:account.order_item.assessment.collateral.vehicles.header" />
    </SubHeader>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.vehicles.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.vehicles.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.collateral.vehicles.contents.2" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.risks.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.risks.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.risks.contents.1" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.risks.contents.2" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.credit_history.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.credit_history.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.credit_history.contents.1" />
      </li>
    </UnorderedList>
    <Header>
      <Translate i18n="components:account.order_item.assessment.monitoring.header" />
    </Header>
    <UnorderedList>
      <li>
        <Translate i18n="components:account.order_item.assessment.monitoring.contents.0" />
      </li>
      <li>
        <Translate i18n="components:account.order_item.assessment.monitoring.contents.1" />
      </li>
    </UnorderedList>
    <InclinedText>
      <Translate i18n="components:account.order_item.assessment.rest.companies_group" />
    </InclinedText>
    <Header>
      <Translate i18n="components:account.order_item.assessment.rest.rating" />
    </Header>
  </ContentContainer>
)

export default render
