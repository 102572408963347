import React from "react"
import { FloatingLabelInput } from "src/components"
import { useMutation } from "src/hooks"
import i18n from "src/i18n"
import { GetOrderPortfolioReport } from "src/mutations"

import {
  startOfQuarter, subQuarters, endOfQuarter, format,
} from "date-fns"

import {
  PortfolioContainer, Label, DownloadButton, PeriodContainer, Container, Headline,
} from "./styles"

const parseDate = (str) => Date.parse(str.split(" - ").reverse().join("-"))

const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8333 10V15.8333H4.16667V10H2.5V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333ZM10.8333 10.5583L12.9917 8.40833L14.1667 9.58333L10 13.75L5.83333 9.58333L7.00833 8.40833L9.16667 10.5583V2.5H10.8333V10.5583Z"
      fill="#3A5BBC"
    />
  </svg>
)

export const Error = ({ error, variant }) => (
  <Container>
    <Label color={variant}>{error}</Label>
  </Container>
)

const Portfolio = ({ data }) => {
  const mutation = useMutation(GetOrderPortfolioReport)

  const [state, setState] = React.useState({
    dateFrom: format(startOfQuarter(subQuarters(new Date(), 1)), "DD - MM - YYYY"),
    dateTo: format(endOfQuarter(subQuarters(new Date(), 1)), "DD - MM - YYYY"),
    error: undefined,
  })

  mutation.onCompleted((data) => {
    window.open(data.getOrderPortfolioReport.url, "_blank")
  })

  mutation.onError((error) => {
    setState((prevState) => ({
      ...prevState,
      error: error.errors[0].message.toLowerCase().replace(" ", "_"),
    }))
  })

  const handleChange = (event) => {
    const { value, name } = event.target

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }))
  }

  const handleClick = () => {
    mutation.commit({
      input: {
        orderId: data.id,
        dateFrom: format(parseDate(state.dateFrom), "YYYY-MM-DD"),
        dateTo: format(parseDate(state.dateTo), "YYYY-MM-DD"),
        format: "XLS",
      },
    })
  }

  const isDateFromError = !state.dateFrom || Number.isNaN(parseDate(state.dateFrom))
  const isDateToError = !state.dateTo || Number.isNaN(parseDate(state.dateTo))
  const isDatePeriodValid = parseDate(state.dateFrom) <= parseDate(state.dateTo)
  const isDownloadButtonDisabled = isDateFromError || isDateToError || !isDatePeriodValid || mutation.busy

  const error = isDatePeriodValid
    ? state.error && `components:account.order_item.period_error.${state.error}`
    : "components:account.order_item.period_error.invalid"

  return (
    <>
      <Headline>
        Портфель
      </Headline>
      <PortfolioContainer>
      <PeriodContainer>
        <FloatingLabelInput
          type="text"
          name="dateFrom"
          value={state.dateFrom}
          width="214px"
          mask="99 - 99 - 9999"
          label="components:account.account_item.tabs.taxes.period_start"
          onChange={handleChange}
          hasError={isDateFromError}
        />
        <FloatingLabelInput
          type="text"
          name="dateTo"
          value={state.dateTo}
          width="214px"
          mask="99 - 99 - 9999"
          label="components:account.account_item.tabs.taxes.period_end"
          onChange={handleChange}
          hasError={isDateToError}
        />
      </PeriodContainer>
      <DownloadButton onClick={handleClick} disabled={isDownloadButtonDisabled} busy={mutation.busy}>
        <span>Cкачать</span>
        <DownloadIcon />
      </DownloadButton>
      <Error
        variant="red"
        error={error && i18n.t([error, "components:account.account_item.tabs.taxes.period_error.unknown"])}
      />
    </PortfolioContainer>
   </>
  )
}

export default Portfolio
