/* eslint-disable max-len */

import React from "react"

export const play = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6896 8.53446C29.6896 8.53446 29.3793 6.3103 28.5 5.32756C27.3621 4.03446 26.0689 4.03446 25.5 3.98272C21.3104 3.6724 15 3.62067 15 3.62067C15 3.62067 8.68963 3.6724 4.5 3.98272C3.93105 4.03446 2.63795 4.08619 1.5 5.32756C0.568945 6.31035 0.310371 8.53446 0.310371 8.53446C0.310371 8.53446 0 11.1724 0 13.8104V16.2414C0 18.8794 0.310371 21.4656 0.310371 21.4656C0.310371 21.4656 0.620742 23.6897 1.5 24.6725C2.63795 25.9656 4.13795 25.9138 4.81037 26.069C7.18963 26.3276 15 26.3793 15 26.3793C15 26.3793 21.3104 26.3793 25.5 26.0173C26.0689 25.9655 27.3621 25.9138 28.5 24.6724C29.3793 23.6897 29.6896 21.4655 29.6896 21.4655C29.6896 21.4655 30 18.8276 30 16.2414V13.8103C30 11.1724 29.6896 8.53446 29.6896 8.53446Z"
      fill="#D8362A"
    />
    <path d="M11.3794 9.7758V21L21.207 15.5172L11.3794 9.7758Z" fill="white" />
  </svg>
)
