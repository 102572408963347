import React from "react"
import moment from "moment"
import memoize from "memoize-one"

import { notifyGTM } from "src/utils"
import { MutationContainer } from "src/containers"
import { registrationSources } from "src/constants"
import { RelayEnvironmentConsumer } from "src/context"

import {
  InvestToOrder,
  RequestProfilePhoneConfirmation,
  InstantPurchaseOrder,
} from "src/mutations"

import {
  Box,
  Button,
  CountDown,
  Translate,
  FormLoadingButton,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"

import { StyledText, ControlsContainer } from "./styles"
import { ModalHeader } from "./elements"

const sendGTMEvent = memoize((order, amount, investmentId, viewer) => {
  const { cession, application, id: orderId } = order

  const amountAsNumber = Number(amount.replace(/\D/g, ""))

  const notify = notifyGTM({
    event: "purchase",
    eventCategory: "",
    extras: {
      date: moment(viewer.createdAt).format("DDMMYYYY"),
      ecommerce: {
        purchase: {
          actionField: {
            id: investmentId,
            revenue: amountAsNumber,
          },
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
              quantity: 1,
              price: amountAsNumber,
            },
          ],
        },
      },
    },
  })

  notify()
})

class Confirmation extends React.PureComponent<any, any> {
  state = {
    code: "",
    busy: false,
    codeSent: false,
  }

  componentDidMount() {
    this.onCodeRequest()
    this.props.startTimer()
  }

  onCodeRequest = () => {
    const isCession = !!this.props.order.cession
    const kind = isCession ? "INSTANT_PURCHASE_ORDER" : "INVEST_TO_ORDER"

    const variables = {
      input: {
        profileId: this.props.profile.id,
        orderId: this.props.order.id,
        kind,
      },
    }

    const callbacks = {
      onError: this.onCodeRequestError,
      onCompleted: this.onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )

    this.setState({ busy: true })
  }

  onCodeRequestSuccess = () => {
    this.setState({ codeSent: true, busy: false })
  }

  onCodeRequestError = () => {
    this.props.clearTimer()
    this.setState({ busy: false })
  }

  onCodeResend = () => {
    this.onCodeRequest()
    this.props.restartTimer()
  }

  onCodeConfirm = () => {
    this.setState({ busy: true })

    const variables = {
      input: {
        token: this.state.code,
        orderId: this.props.order.id,
        profileId: this.props.profile.id,
        amount: Number(this.props.amount.replace(/\D/g, "")),
        source: registrationSources.WEB,
      },
      orderId: this.props.order.id,
    }

    const callbacks = {
      onError: this.onCodeConfirmError,
      onCompleted: this.onCodeConfirmSuccess,
    }

    this.props.commit({ callbacks, variables })
  }

  onCodeConfirmSuccess = (data) => {
    const invest = data.investToOrder || data.instantPurchaseOrder
    const {
      id: profileId,
      investment: { id: investmentId },
    } = invest.profile || invest.foreignProfile

    const investmentIdentifier = `${investmentId}.${profileId}.${Date.now()}`

    sendGTMEvent(
      this.props.order,
      this.props.amount,
      investmentIdentifier,
      this.props.viewer,
    )

    this.props.onComplete()
  }

  onCodeConfirmError = () => {
    this.setState({ busy: false })

    if (!this.props.getError("investToOrder.token")) {
      this.props.onError()
    }

    if (!this.props.getError("instantPurchaseOrder.token")) {
      this.props.onError()
    }
  }

  onChange = (e) => {
    this.setState({ code: e.target.value })
    this.props.clearError("investToOrder.token")
    this.props.clearError("instantPurchaseOrder.token")
  }

  render() {
    const { code, busy, codeSent } = this.state

    const {
      amount, profile, getError, secondsLeft,
    } = this.props

    const error = getError("investToOrder.token") || getError("instantPurchaseOrder.token")

    return (
      <>
        <ModalHeader
          i18n="components:investment_modals.confirmation.title"
          amount={amount}
        />
        <Box m="20px 0">
          <StyledText>
            <Translate
              i18n="components:account.operations.withdraw.confirmation.to_number"
              number={profile.phone}
            />
          </StyledText>
        </Box>
        <FormInput
          autoFocus
          name="phone_confirmation"
          value={code}
          error={error}
          onChange={this.onChange}
          label="components:account.bank_account.confirm.label"
        />
        <ResendRequestButton nowrap secondsLeft={secondsLeft} />
        <ControlsContainer>
          <Box mr="20px">
            <Button
              variant="default"
              height="48px"
              disabled={!!secondsLeft || !codeSent}
              onClick={this.onCodeResend}
            >
              <Translate
                ns="components"
                i18n="account.bank_account.confirm.buttons.resend"
              />
            </Button>
          </Box>
          <FormLoadingButton
            variant="blueWide"
            height="48px"
            disabled={!code}
            isLoading={busy}
            onClick={this.onCodeConfirm}
          >
            <Translate
              ns="components"
              i18n="account.bank_account.confirm.buttons.continue"
            />
          </FormLoadingButton>
        </ControlsContainer>
      </>
    )
  }
}

export default (props) => {
  const { cession } = props.order
  const mutation = cession ? InstantPurchaseOrder : InvestToOrder

  return (
    <RelayEnvironmentConsumer>
      {({ environment }) => (
        <CountDown>
          {({
            start, clear, restart, secondsLeft,
          }) => (
            <MutationContainer mutation={mutation}>
              {({ commit, getError, clearError }) => (
                <Confirmation
                  {...props}
                  commit={commit}
                  getError={getError}
                  clearError={clearError}
                  startTimer={start}
                  clearTimer={clear}
                  restartTimer={restart}
                  secondsLeft={secondsLeft}
                  environment={environment}
                />
              )}
            </MutationContainer>
          )}
        </CountDown>
      )}
    </RelayEnvironmentConsumer>
  )
}
