import React from "react"
import memoize from "memoize-one"

import { Exhibition } from "src/components"

import {
  Action,
  Controls,
  MiscData,
  EntityDate,
  Description,
  ProfileLogo,
} from "../Elements"

import { HeaderContainer, FooterContainer } from "./styles"

const Head = memoize(({ data }) => (
  <HeaderContainer>
    <ProfileLogo data={data} source="loans" />
    <MiscData trusted data={data} source="loans" />
    <EntityDate data={data} />
    <Controls.loan data={data} />
  </HeaderContainer>
))

const Body = memoize(({ data }) => <Exhibition data={data} />)

const Footer = memoize(({ data }) => (
  <FooterContainer>
    <Description data={data} cropped />
    <Action data={data} source="loans" />
  </FooterContainer>
))

export default {
  Head,
  Body,
  Footer,
}
