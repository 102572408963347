import { parseOrder } from "src/utils"

export function parseOrders(edges) {
  return edges.map((edge) => {
    const { amount, order } = edge.node
    const parsedOrder = parseOrder(order)

    return { amount, order: parsedOrder }
  })
}

export function getInvestmentsAmount(investments) {
  return investments.reduce((total, investment) => total + investment.amount, 0)
}

export function getAverageInterest(investments) {
  if (investments.length < 1) return 0

  const interest = investments.reduce((total, investment) => {
    const { interestRate } = investment.order.offer.data

    return total + Number(interestRate)
  }, 0)

  return (interest / investments.length).toFixed(0)
}

function sortInvestmentsByDate(first, second) {
  const firstDate = new Date(first.order.confirmedAt)
  const secondDate = new Date(second.order.confirmedAt)

  const firstTime = firstDate.setDate(
    firstDate.getDate() + Number(first.order.offer.data.term),
  )
  const secondTime = secondDate.setDate(
    secondDate.getDate() + Number(second.order.offer.data.term),
  )

  return firstTime - secondTime
}

export function getVisibleInvestments(investments) {
  if (investments.length < 1) return []

  const sortedInvestments = investments.sort(sortInvestmentsByDate)
  return sortedInvestments.splice(0, 3)
}
