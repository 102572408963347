import styled from "styled-components"

export const Container = styled.div`
  position: relative;

  @media screen and (max-width: 420px) {
    margin-top: 12px;
  }
`

export const Title = styled.div`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }
`

export const Value = styled.div<any>`
  font-family: Geometria;
  font-size: 26px;
  line-height: 33px;
  color: #4a4a4a;
  text-align: center;
  font-weight: bold;
  margin-top: 8px;

  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
    line-height: 28px;
  }
`

export const Description = styled(Title)`
  margin-top: 8px;
`
