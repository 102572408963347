import Active from "./Active"
import Cession from "./Cession"
import Completed from "./Completed"
import Repayment from "./Repayment"

export default {
  active: Active,
  cession: Cession,
  completed: Completed,
  repayment: Repayment,
}
