import React from "react"

import { Box, Heading, Translate } from "src/components"

const Header = () => (
  <Box>
    <Heading.h4>
      <Translate i18n="accreditation.individual.header" ns="components" />
    </Heading.h4>
  </Box>
)

export default Header
