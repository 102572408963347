import styled from "styled-components"

import { Text } from "src/components"

export const Container = styled.div`
  padding: 0 30px;
  width: 45%;
`

export const RatingContainer = styled.span``

export const StatusContainer = styled.div`
  padding-top: 4px;
  box-sizing: content-box;
  height: 20px;
`

export const NameContainer = styled.div`
  display: inline-block;
  padding: 0 0 12px;
`

export const HeaderWrapper = styled.div``

export const Name = styled.a`
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    text-decoration: ${({ href }) => (href ? "underline" : "none")};
  }
`

export const DescriptionContainer = styled.div`
  max-width: 440px;
`

export const Description = styled.span`
  line-height: 1.43;
  color: ${({ theme }) => theme.colors.greyDarker};
  font-size: 14px;
`

export const InfoText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyDark};
  font-size: 14px;
  line-height: 1.43;
`

export const InfoContainer = styled.div`
  padding-top: 4px;
`

export const TooltipContainer = styled.div`
  cursor: pointer;
`

export const LoaderContainer = styled.div`
  transform: translateY(8px);
  width: 20px;
  height: 20px;
`
