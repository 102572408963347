import * as React from "react"
import { findDOMNode } from "react-dom"
import { DragSource, DropTarget } from "react-dnd"

import Item from "./Item"

const ItemTypes = {
  ITEM: "item",
}

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    }
  },
}

/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
const cardTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    if (dragIndex === hoverIndex) {
      return
    }

    const hoverBoundingRect = (findDOMNode(component) as any).getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    props.onMove(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },
}

/* eslint-enable consistent-return */
/* eslint-enable no-param-reassign */

class Card extends React.PureComponent<any, any> {
  render() {
    const { connectDragSource, connectDropTarget, ...rest } = this.props

    return (
      connectDragSource
      && connectDropTarget
      && connectDragSource(
        connectDropTarget(
          <div>
            <Item {...rest} />
          </div>,
        ),
      )
    )
  }
}

const dragSource = DragSource(
  ItemTypes.ITEM,
  cardSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Card)

export default DropTarget(ItemTypes.ITEM, cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(dragSource)
