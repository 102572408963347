import cryptoWrapper from "./cryptoWrapper"

export const attachmentsParser = new (class {
  applyedAttachments: any[]

  constructor() {
    this.applyedAttachments = []
  }

  get list() {
    const data = [...this.applyedAttachments]
    this.applyedAttachments = []

    return data
  }

  apply = (attachments) => {
    if (!attachments.length) {
      return []
    }

    return attachments.reduce((acc, item) => {
      if (!item || !item.id) return acc

      this.applyedAttachments.push(item.id)
      return [...acc, item.id]
    }, [])
  }
})()

export const parseAttachmentsData = (state) => {
  if (typeof state !== "object") {
    return state
  }

  const processedState = { ...state }

  Object.keys(processedState).forEach((prop) => {
    if (
      processedState[prop] !== undefined
      && processedState[prop] !== null
      && Object.prototype.hasOwnProperty.call(processedState[prop], "attachments")
    ) {
      processedState[prop].attachments = attachmentsParser.apply(
        processedState[prop].attachments,
      )
    } else {
      parseAttachmentsData(processedState[prop])
    }
  })

  return processedState
}

export const fileManager = new (class {
  cachedFiles

  environment

  variables

  constructor() {
    this.cachedFiles = new Map()
    this.environment = undefined
    this.variables = {
      input: {
        field: "file",
      },
    }
  }

  getBase64 = (file) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })
  }

  uploadList = (environment, mutation, files) => new Promise((resolve, reject) => {
    if (!mutation || !files.length || !environment) {
      resolve([])
    }

    if (!this.environment) {
      this.environment = environment
    }

    const promiseList = files.map((file) => this.uploadSingle(mutation, file))

    Promise.all(promiseList)
      .then((ids) => resolve(ids))
      .catch((e) => reject(e))
  })

  uploadSingle = (mutation, file) => new Promise((resolve, reject) => {
    this.getBase64(file)
      .then(cryptoWrapper.sha1sum)
      .then((hash) => {
        if (hash && this.cachedFiles.has(hash)) {
          resolve(this.cachedFiles.get(hash))

          return
        }

        const uploadables = { file }

        const completedCallback = ({ uploadFile }) => {
          this.cachedFiles.set(hash, uploadFile)
          resolve(uploadFile)
        }

        const callbacks = {
          onError: reject,
          onCompleted: completedCallback,
        }

        mutation.commit(
          this.environment,
          this.variables,
          uploadables,
          callbacks,
        )
      })
  })
})()
