import is from "is_js"
import React from "react"

import { ResetPassword } from "src/mutations"
import { MutationContainer } from "src/containers"
import {
  Box,
  Text,
  Button,
  Translate,
  FloatingLabelInput as Input,
} from "src/components"

import EmailTooltip from "./elements"

const isStateValid = (state) => is.email(state.email)

class Form extends React.Component<any, any> {
  state = {
    busy: false,
    email: "",
  }

  commit = () => {
    const variables = {
      input: {
        email: this.state.email,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    this.props.commit({ variables, callbacks })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError = (transaction) => {
    this.setState(() => ({ busy: false }))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCompleted = (payload) => {
    if (this.props.onCompleted) {
      this.props.onCompleted(this.state.email)
    }
  }

  onSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    if (this.is.busy || !this.is.valid) return
    this.setState(() => ({ busy: true }), this.commit)
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))
    this.props.clearError(`resetPassword.${name}`)
  }

  get is() {
    return {
      busy: this.state.busy,
      valid: isStateValid(this.state),
    }
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Box mb="15px">
          <Text fontSize="24px">
            <Translate i18n="components:password-reset-form.title" />
          </Text>
        </Box>
        <Input
          autoFocus
          name="email"
          type="email"
          errorColor="white"
          tooltip={EmailTooltip}
          value={this.state.email}
          onChange={this.onChange}
          error={this.props.getError("resetPassword.email")}
          label="components:password-reset-form.email.label"
        />
        <Box mt="20px">
          <Button disabled={!this.is.valid || this.is.busy} fullWidth>
            <Translate i18n="reset" ns="common" />
          </Button>
        </Box>
      </form>
    )
  }
}

const render = (props) => (
  <MutationContainer mutation={ResetPassword}>
    {({ commit, getError, clearError }) => (
      <Form
        {...props}
        commit={commit}
        getError={getError}
        clearError={clearError}
      />
    )}
  </MutationContainer>
)

export default render
