import { graphql } from "react-relay"

const query = graphql`
  query autoSuggestQuery($query: String!) {
    address(query: $query) {
      value
      postalCode
      okato
      oktmo
      region
      regionType
      area
      areaType
      city
      cityType
      cityDistrict
      cityDistrictType
      settlement
      settlementType
      street
      streetType
      house
      houseType
      block
      blockType
      flat
      flatType
      postalBox
    }
  }
`

export default query
