import React from "react"

import { number } from "src/utils"
import { RelayEnvironmentConsumer } from "src/context"

import {
  Box, Text, Heading, Translate,
} from "src/components"

import { HeaderWrapper } from "../styles"
import { getRequisitesLink } from "../modalUtils"
import RequisitesControls from "./RequisitesControls"

class Fee extends React.Component<any> {
  state = { busy: true, url: undefined }

  componentDidMount() {
    const {
      order: { orderId },
    } = this.props
    getRequisitesLink("BORROWER_FEE", orderId, this.props.environment).then(
      ({ url, fee }) => {
        this.setState(() => ({ busy: false, url, fee }))
      },
    )
  }

  render() {
    const { busy, url } = this.state
    const {
      order: { fee },
    } = this.props

    return (
      <Box>
        <HeaderWrapper>
          <Heading.h5>
            <Translate i18n="order_list.approval.fee_payment" ns="components" />
          </Heading.h5>
        </HeaderWrapper>
        <Box pt="14px" pb="18px">
          <Text>
            <Translate i18n="order_list.approval.last_step" ns="components" />
          </Text>
        </Box>
        <Box pb="24px">
          <Text>
            <Translate
              i18n="order_list.approval.last_step_description"
              ns="components"
              fee={number(fee)}
            />
          </Text>
        </Box>
        <Box>
          <Text>
            <Translate
              i18n="order_list.approval.last_step_footer"
              ns="components"
            />
          </Text>
        </Box>
        <RequisitesControls url={url} isLoading={busy} />
      </Box>
    )
  }
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => <Fee {...props} environment={environment} />}
  </RelayEnvironmentConsumer>
)
