/* eslint-disable max-len */

import React from "react"

import Item from "./Item"

import {
  Container,
  Block,
  BlockTitle,
  List,
  Divider,
  BlockDescription,
} from "./styles"

const financial = [
  {
    name: "Экспресс-анализ по ИНН",
    withDocuments: false,
    description:
      "Мы получаем всевозможную публичную информацию по вашей компании, исходя из анализа которой формируется скоринговый балл.",
  },
  {
    name: "Выписка по 51 счету и отчётность на последнюю дату",
    withDocuments: true,
    description:
      "Выгрузите из программы 1С-Бухгалтерия:\n- Карточку 51 счета в Excel(xlsx) за 24 месяца;\n- Предварительный баланс и ОФР на последнюю отчетную дату;",
  },
  {
    name: "Оборотно-сальдовые ведомости",
    withDocuments: true,
    description:
      "Предоставление ОСВ обязательно для заявок от 3 млн. рублей.\n\nВыгрузите из программы 1С-Бухгалтерия:\n- Поквартальные ОСВ по счетам 58,66,67 за 12 месяцев в разрезе контрагентов (3 файла);\n- Поквартальные ОСВ по счетам 60,62 за 12 месяцев разрезе контрагентов и субсчетов;\n- Проименуйте файлы корретно, чтобы они отражали суть содержания, например : \"ОСВ сч. 60 по контрагентам\" или \"ОСВ сч. 60 по субсчетам\";",
  },
]

const provision = [
  {
    name: "Дополнительное поручительство",
    withDocuments: false,
    description:
      "Вы можете добавить в качестве дополнительного поручителя физическое или юридическое лицо (поручительство основного бенефициара обязательно). Перейдите по ссылке для заполнения информации о поручителе.",
  },
  {
    name: "Залог недвижимости/авто/оборудования",
    withDocuments: false,
    description:
      "Залог имущества — простой способ заметно увеличить рейтинг вашей заявки, увеличить максимальную сумму и снизить ставку. Пройдите по ссылке, чтобы внести информацию по залогу.",
  },
  {
    name: "Контроль выручки по контракту",
    withDocuments: true,
    description:
      "При переводе выручки по контракту на номинальный или залоговый счет, инвесторы на Карме получают приоритетное право на погашение долга поступившыими денежными средствами, что существенно снижает их риски. Использование данной опции приведет к повышению рейтинга заявки и крайне рекомендуется для займов на исполнение контракта.\n\nУкажите тип контракта: коммерческий/государственный\nУкажите ссылку на контракт/конкурс (для государственного)",
  },
]

function CheckList() {
  return (
    <Container>
      <Block>
        <BlockTitle>фин анализ</BlockTitle>
        <BlockDescription>обязательно</BlockDescription>
        <List>
          {financial.map((item, idx) => (
            <Item key={idx} {...item} />
          ))}
        </List>
      </Block>

      <Divider />

      <Block>
        <BlockTitle>обеспечение</BlockTitle>
        <BlockDescription>опционально</BlockDescription>
        <List>
          {provision.map((item, idx) => (
            <Item key={idx} {...item} />
          ))}
        </List>
      </Block>
    </Container>
  )
}

export default CheckList
