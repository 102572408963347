import React, { useState, useEffect } from "react"

import { useEnvironment } from "src/hooks"

import { ViewerData } from "../ViewerData"

import {
  fetchCurrency,
  currencyFromProps,
  setViewerCurrency,
} from "./currencyUtils"

import Currencies from "./constants"

export const CurrencyContext = React.createContext({})
export const CurrencyConsumer = CurrencyContext.Consumer

const CurrencyProvider = (props) => {
  const { environment } = useEnvironment()

  const [currency, setCurrency] = useState(currencyFromProps(props))

  useEffect(() => {
    fetchCurrency(environment).then(setCurrency)
  }, [])

  const applyCurrency = (injectedCurrency) =>
    setViewerCurrency(injectedCurrency, environment).then(() => setCurrency(injectedCurrency))

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        applyCurrency,
        currencies: [...Currencies],
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  )
}

export default (props) => (
  <ViewerData.consumer>
    {(viewer) => <CurrencyProvider viewer={viewer} {...props} />}
  </ViewerData.consumer>
)
