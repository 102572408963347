import styled, { css } from "styled-components"
import { width } from "styled-system"
import { Link } from "react-router-dom"

export * from "./wrappers"

export const TData = styled.td`
  font-size: 16px;
  color: #4a4a4a;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`

export const TableWrapper = styled.div`
  position: relative;
`

export const TableLoader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 340px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
`

export const TDBold = styled.td`
  font-size: 16px;
  color: #4a4a4a;
  font-weight: bold;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`

export const TDName = styled.td`
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  max-width: 220px;
  width: 220px;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: rgba(74, 74, 74, 0.65);
  }

  @media screen and (max-width: 1279px) {
    font-size: 16px;
    width: 200px;
  }
`

export const TDNameLink = styled(Link)`
  text-decoration: none;
  color: #4a4a4a;
`

export const ActionWrapper = styled.span`
  width: 100%;
  display: inline-flex;
  flex-direction: row;
`

export const TDStatus = styled.td<any>`
  font-size: 16px;
  color: #4a4a4a;
  min-width: 230px;
  width: 230px;
  color: ${(props) => props.color || "#4a4a4a"};
  ${width};
  ${(props) =>
    props.isClickable
    && css`
      cursor: pointer;
    `}

  div svg path {
    fill: ${(props) => props.color || "#4a4a4a"};
  }

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`
export const DebtLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainRed};

  &:hover {
    opacity: 0.65;
  }
`

export const CompletedStatus = styled(TDStatus)`
  width: auto;
  min-width: 1px;
`

export const PunishmentStatusContainer = styled.div<{
  justifyContent?: string
}>`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
`

export const TDDate = styled.td`
  color: rgba(57, 57, 57, 0.75);
  font-size: 16px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`

const ActionButtonVariants = {
  primary: css`
    background: #f70000;
    color: #ffffff;
    box-shadow: 0px 5px 10px rgba(255, 116, 143, 0.55);
    border: 1px solid transparent;
  `,
  secondary: css`
    background-color: transparent;
    color: #434343;
    border: 1px solid #434343;
  `,
}

export const ActionButton = styled.a<any>`
  ${({ variant }) => ActionButtonVariants[variant || "primary"]};

  border-radius: 4px;
  padding: 15px 0;
  box-sizing: border-box;
  display: inline-block;
  flex-grow: 1;

  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  text-align: center;
  :not(:first-of-type) {
    margin-left: 16px;
  }
`

export const ActionLink = styled(Link)`
  ${ActionButtonVariants.primary};

  border-radius: 4px;
  padding: 15px 0;
  box-sizing: border-box;
  display: inline-block;
  flex-grow: 1;

  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  text-align: center;
  :not(:first-of-type) {
    margin-left: 16px;
  }
`

export const Graph = styled.div`
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
  border-radius: 2px;
  margin: 0 8px 0 0;
  width: 24px;
  height: 24px;
  padding: 4px;
`

export const SellButton = styled.button`
  max-width: 120px;
  width: 100%;
  height: 45px;
  padding: 2px 4px 0;
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainRed};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Geometria, sans-serif;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
