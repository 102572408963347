import styled from "styled-components"

const RadioWrapper = styled.div`
  .container {
    display: block;
    position: relative;
    padding: 2px 0 0 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 50%;
  }

  .container:hover input ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.greys[4]};
  }

  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.red};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.redLighter};
  }
`

export default RadioWrapper
