import styled from "styled-components"

export const CheckBox = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`

export const Label = styled.label`
  display: block;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  font-family: "Open Sans";
  user-select: none;
`

export const Icon = styled.div<any>`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  box-sizing: border-box;
  margin-right: 10px;
  transition: all 0.2s;
  opacity: ${({ checked }) => (checked ? "1" : "0.6")};

  &:after {
    content: "";
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`
