import React, { useCallback } from "react"
import { useHistory } from "react-router"

import { MiniLoader } from "src/components"
import { toRubleFormat, toCurrencyFormat, getDebtURL } from "src/utils"

import {
  PaymentCard,
  PaymentCardHeader,
  PaymentCardHeaderLeft,
  PaymentCardHeaderRight,
  PaymentCardData,
  PaymentCardDataLeft,
  PaymentCardDataRight,
  PaymentCardName,
  PaymentCardBody,
  CardCover,
  DebtLink,
} from "./styles"

import { getPaymentDate, getOrderName } from "../Tables/utils"
import { statuses } from "../Tables/InvestmentPaymentGraph"

function Card(props) {
  const status = statuses.get(props.item.state) || {
    text: "Неизвестно",
    color: "grey",
  }

  const history = useHistory()
  const toOrder = useCallback(
    () => history.push(`/market/${props.orderId}?source=orders`),
    [history, props.orderId],
  )

  const isPastDue = props.item.state === "pastdue"

  return (
    <PaymentCard>
      <PaymentCardHeader>
        <PaymentCardHeaderLeft>
          {getPaymentDate(props.item.date)}
        </PaymentCardHeaderLeft>
        <PaymentCardHeaderRight>{toRubleFormat(props.item.total_gross)}</PaymentCardHeaderRight>
      </PaymentCardHeader>

      <PaymentCardBody>
        <PaymentCardData>
          <PaymentCardDataLeft>Заявка</PaymentCardDataLeft>
          <PaymentCardName onClick={toOrder}>
            {getOrderName(props)}
          </PaymentCardName>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Дата оплаты</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {getPaymentDate(props.item.date, props.item.payDate)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Сумма платежа</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.total_gross)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Основной долг</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.loan)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Проценты</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.interest)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>НДФЛ</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.personal_tax)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>К выплате</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.total)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Остаток долга</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {toCurrencyFormat(props.item.debt)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Статус</PaymentCardDataLeft>
          <PaymentCardDataRight color={status.color}>
          { isPastDue
            ? <DebtLink href={getDebtURL(props.chain?.id)} target="_blank" rel="noreferrer noopener">
              {status.text}
            </DebtLink>
            : <>{status.text}</>}
          </PaymentCardDataRight>
        </PaymentCardData>
      </PaymentCardBody>
    </PaymentCard>
  )
}

function Placeholder() {
  return (
    <PaymentCard>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <PaymentCardHeader>
        <PaymentCardHeaderLeft>_</PaymentCardHeaderLeft>
        <PaymentCardHeaderRight>0 ₽</PaymentCardHeaderRight>
      </PaymentCardHeader>

      <PaymentCardBody>
        <PaymentCardData>
          <PaymentCardDataLeft>Заявка</PaymentCardDataLeft>
          <PaymentCardName>_</PaymentCardName>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Дата оплаты</PaymentCardDataLeft>
          <PaymentCardDataRight>_</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Сумма платежа</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Основной долг</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Проценты</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>НДФЛ</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>К выплате</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Остаток долга</PaymentCardDataLeft>
          <PaymentCardDataRight>0</PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Статус</PaymentCardDataLeft>
          <PaymentCardDataRight color="rgba(74, 74, 74, 0.45)">
            Ожидает оплаты
          </PaymentCardDataRight>
        </PaymentCardData>
      </PaymentCardBody>
    </PaymentCard>
  )
}

Card.placeholder = Placeholder

export default Card
