import React from "react"

export default () => (
  <React.Fragment>
    <path
      id="circle"
      d="M11.99,2C6.47,2,2,6.48,2,12c0,5.52,4.47,10,9.99,10C17.52,22,22,17.52,22,12C22,6.48,
      17.52,2,11.99,2z M12,20c-4.42,0-8-3.58-8-8c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z"
    />
    <polygon points="12.5,7 11,7 11,13 16.25,16.15 17,14.92 12.5,12.25" />
  </React.Fragment>
)
