import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestWithdrawalMutation($input: RequestWithdrawalInput!) {
    requestWithdrawal(input: $input) {
      profile {
        ... on LegalEntityProfile {
          id
          __typename
          accountBalanceDetailed {
            available
            currency
            invested
            blocked
          }
        }
        ... on IndividualProfile {
          id
          __typename
          accountBalanceDetailed {
            available
            currency
            invested
            blocked
          }
        }
        ... on EntrepreneurProfile {
          id
          __typename
          accountBalanceDetailed {
            available
            currency
            invested
            blocked
          }
        }
        ... on ForeignIndividualProfile {
          id
          __typename
          accountBalanceDetailed {
            available
            currency
            invested
            blocked
          }
        }
      }
    }
  }
`
export default enhance({ mutation })
