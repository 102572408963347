import styled from "styled-components"

import { CardWrapper } from "src/components"

export const Container = styled.div`
  width: 100%;
  max-width: calc(100% - 300px);
  position: relative;

  @media screen and (max-width: 800px) {
    max-width: calc(100% - 240px);
  }

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`

export const Box = styled(CardWrapper)`
  padding: 28px;
  font-size: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.textBlack};
  flex-direction: column;
  font-family: Geometria;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`

export const ReturnButton = styled.button`
  font-family: Geometria;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textBlack};
  border-radius: 4px;
  background-color: inherit;
  margin: 0;
  margin-top: 26px;
  padding: 8px 8px 8px 0;
  font-weight: 600;
  display: none;

  @media screen and (max-width: 800px) {
    display: block;
  }
`
