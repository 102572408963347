import React from "react"

import {
  Box, Loader, TextField, IconClose,
} from "src/components"

import { RowWrapper, IconWrapper, InputWrapper } from "./styles"

export const ResetIcon = ({ onClick, isLoading }) => (isLoading ? (
    <Box ml="20px">
      <Loader fontSize="4px" />
    </Box>
) : (
    <IconWrapper {...{ onClick }}>
      <IconClose />
    </IconWrapper>
))

export const Input = (props) => (
  <InputWrapper>
    <TextField {...props} />
  </InputWrapper>
)

export const VideoInput = ({
  name,
  onChange,
  ogUrl,
  onRemoveClick,
  isLoading,
}) => (
  <RowWrapper>
    <Input name={name} onChange={onChange} value={ogUrl} />
    <ResetIcon onClick={onRemoveClick} isLoading={isLoading} />
  </RowWrapper>
)

export const Preview = ({ video }) => (video && video.url && video.url.length ? (
    <Box pt={20}>
      <iframe src={video.url} frameBorder="0" width="300px" height="179px" />
    </Box>
) : null)
