import React from "react"
import moment from "moment"

import i18n from "src/i18n"
import { number } from "src/utils"
import { CurrencySigns } from "src/constants"

import {
  Box, Flex, Text, Icon, Translate,
} from "src/components"

import {
  Divider,
  SubHeader,
  WalletBalanceContainer,
  ExchangeArrowsContainer,
} from "./styles"

export const ModalHeader = (props) => (
  <>
    <Box mb="20px">
      <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
        <Translate {...props} />
      </Text>
    </Box>
    <Divider />
  </>
)

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const CurrencyTooltip = React.memo(({ children }: any) => (
  <Text fontSize="20px">{CurrencySigns[children]}</Text>
))

export const WalletInfo = ({ wallet, type }) => (
  <Box flex="1">
    <Box mb="15px">
      <SubHeader>
        <Translate
          i18n={`components:account.operations.convert.amount.${type}`}
        />
      </SubHeader>
    </Box>
    <WalletBalanceContainer>
      <Flex alignItems="center">
        <Text color="greyShuttle" lineHeight="1.5">
          {number(wallet.available)}
        </Text>
        <Box ml="10px">
          <Icon.currency>{wallet.currency}</Icon.currency>
        </Box>
      </Flex>
    </WalletBalanceContainer>
  </Box>
)

/* eslint-disable max-len */
const ExchangeIcon = () => (
  <svg
    height="22"
    viewBox="0 0 15 22"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m9.792 1-.908.909 2.76 2.76h-10.521v1.286h10.52l-2.76 2.76.908.908 4.311-4.312zm-8.791 16.312 4.311 4.31.91-.908-2.761-2.76h10.52v-1.285h-10.521l2.76-2.76-.908-.909z"
      fill="#5a616e"
      fillRule="evenodd"
      stroke="#5a616e"
      strokeWidth=".5"
    />
  </svg>
)
/* eslint-enable max-len */

export const ExchangeArrows = ({ onClick }) => (
  <ExchangeArrowsContainer onClick={onClick}>
    <ExchangeIcon />
  </ExchangeArrowsContainer>
)

export const ConvertedAmount = ({ currentPair, convertedAmount }) => (
  <Flex my="15px" alignItems="center" justifyContent="space-between">
    <Text color="greyDark" lineHeight="1.5">
      = {number(convertedAmount, options)} {CurrencySigns[currentPair.buy]}
    </Text>
    <Text color="greyDark" fontSize="11px">
      <Translate
        i18n="components:account.operations.convert.amount.current_rate"
        date={moment().locale(i18n.language).format("DD.MM.YYYY")}
        buy={currentPair.buy}
        sell={currentPair.sell}
        rate={currentPair.price}
      />
    </Text>
  </Flex>
)
