import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation getAttachmentLinkMutation($input: GetAttachmentLinkInput!) {
    getAttachmentLink(input: $input) {
      url
    }
  }
`

export default enhance({ mutation })
