import React from "react"
import i18n from "src/i18n"
import { graphql } from "react-relay"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useEnvironment, useMutation } from "src/hooks"
import { UpdateTaxPaymentDocuments } from "src/mutations"
import { FormBuilder, QueryRenderer, FormLoadingButton } from "src/components"
import { ErrorsContainer } from "src/containers"

const query = graphql`
  query taxPaymentForProfileQuery($profileId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        taxPaymentDocuments {
          id
          attachment {
            id
            url
            size
            width
            height
            format
            filename
          }
          status
        }
      }
    }
  }
`

const TaxPaymentSchema = {
  groups: [
    {
      title: "Платёжное поручение",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              name: "taxPaymentDocuments",
              type: "supportingDocuments",
            },
          ],
        },
      ],
    },
  ],
}

export const StyledButton = styled(FormLoadingButton)`
  height: 40px;
  font-size: 0.625rem;
  padding: 0 12px;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 20px 0 0;
    height: 60px;
    font-size: 0.875rem;
  }
`

const Render = (props) => {
  const { environment } = useEnvironment()
  const mutation = useMutation(UpdateTaxPaymentDocuments)
  const [value, setValue] = React.useState(props.node)

  mutation.onCompleted(() => {
    toast.success(i18n.t("common:toasts.save_success"))
  })

  mutation.onError((error) => {
    toast.error(i18n.t("common:toasts.save_error"))
    props.setErrors(error)
  })

  const handleSave = () => {
    const input = {
      profileId: props.profileId,
      supportingDocuments: value.taxPaymentDocuments.map((doc) => {
        const id = doc?.attachment?.id || doc.id
        const attachment = typeof doc === "string" ? doc : id
        return { attachment }
      }),
    }

    mutation.commit({ input })
  }

  return (
    <>
      <FormBuilder
        schema={TaxPaymentSchema}
        environment={environment}
        inputsSchemaType="new"
        value={value}
        onChange={setValue}
      />
      <StyledButton
        isLoading={mutation.busy}
        disabled={value.taxPaymentDocuments?.length === 0}
        variant="outlinedRed"
        onClick={handleSave}
      >
        Сохранить
      </StyledButton>
    </>
  )
}

interface Props {
  profileId: string
}

const TaxPaymentDocuments: React.FC<Props> = ({ profileId }) => (
  <QueryRenderer
    query={query}
    variables={{ profileId }}
    profileId={profileId}
    render={(props) => (
      <ErrorsContainer>
        {(errors) => <Render {...errors} {...props} />}
      </ErrorsContainer>
    )}
  />
)

export default TaxPaymentDocuments
