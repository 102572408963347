import styled from "styled-components"

export const DeleteIcon = styled.div`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-left: 16px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/icons/delete.svg);
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`

export const TitleContainer = styled.div`
  max-width: calc(100% - 32px);

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
