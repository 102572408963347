import { translation } from "src/utils"

const getTranslation = (element, state) => translation({
  path: `components:account.${element}.status.state.${state}`,
})

export const StatusMessageTextSchema = new Map([
  ["INITIAL", (element, status) => getTranslation(element, status)],
  ["PENDING", (element, status) => getTranslation(element, status)],
  ["DECLINED", (element, status) => getTranslation(element, status)],
  ["APPROVED", (element, status) => getTranslation(element, status)],
  [
    "CONFIRMED",
    (element, status, outdated) => {
      let localStatus = status
      if (outdated) {
        localStatus += "_OUTDATED"
        return getTranslation(element, localStatus)
      }
      return getTranslation(element, status)
    },
  ],
  ["TRANSFER", (element, status) => getTranslation(element, status)],
  ["COMPLETE", (element, status) => getTranslation(element, status)],
  ["SUCCEEDED", (element, status) => getTranslation(element, status)],
  ["DEAD", (element, status) => getTranslation(element, status)],
  ["DEFAULT", (element, status) => getTranslation(element, status)],
])

export const StatusMessageColorsSchema = new Map([
  ["DEAD", "red"],
  ["DEFAULT", "red"],
  ["DECLINED", "red"],
  ["INITIAL", "black"],
  ["COMPLETE", "green"],
  ["PENDING", "ginger"],
  ["APPROVED", "green"],
  ["CONFIRMED", "green"],
  ["TRANSFER", "ginger"],
  ["SUCCEEDED", "green"],
])
