import React from "react"
import styled from "styled-components"

import Item from "./Item"

const ListContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ItemContainer = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  margin: 0;
`

const renderAttachment = (onRemove) => (attachment, index) => {
  const {
    url, name, preview, filename,
  } = attachment

  return (
    <ItemContainer key={index}>
      <Item
        onRemoveRequest={onRemove(index)}
        url={url}
        name={filename || name}
        preview={preview}
      />
    </ItemContainer>
  )
}

const Render = (props) => (
  <ListContainer>
    {props.attachments.map(renderAttachment(props.onRemove))}
  </ListContainer>
)

export default Render
