import React from "react"
import createEnvironment from "src/environment"

const RelayEnvironmentContext = React.createContext<any>(undefined)

const RelayEnvironmentConsumer = RelayEnvironmentContext.Consumer

class RelayEnvironmentProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      environment: createEnvironment(),
      refreshEnvironment: this.refreshEnvironment,
    }
  }

  refreshEnvironment = () => {
    const environment = createEnvironment()
    this.setState(() => ({ environment }))
  }

  render() {
    return (
      <RelayEnvironmentContext.Provider value={this.state}>
        {this.props.children}
      </RelayEnvironmentContext.Provider>
    )
  }
}

export {
  RelayEnvironmentContext,
  RelayEnvironmentConsumer,
  RelayEnvironmentProvider,
}
