import React, { useState, useEffect, useRef } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import {
  MenusWrapper,
  MenuItem,
  DesktopWrapper,
  MobileWrapper,
  MobileMenuItem,
  MobileMenuWrapper,
  MobileMenuDivider,
} from "./styles"

function MobileMenu(props) {
  const { onClose, anchorEl } = props

  useEffect(() => {
    if (anchorEl) {
      disableBodyScroll()
    }

    return () => enableBodyScroll()
  }, [anchorEl])

  const menuRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (!menuRef.current) return
    const rect = menuRef.current.getBoundingClientRect()
    menuRef.current.style.top = `${Math.min(rect.top, window.innerHeight - rect.height - 4)}px`
  })

  if (!anchorEl) return null

  const rect = anchorEl.getBoundingClientRect()

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1300,
        right: "0px",
        bottom: "0px",
        top: "0px",
        left: "0px",
      }}
      onClick={onClose}
    >
      <MobileMenuWrapper
        ref={menuRef}
        style={{ top: rect.top, left: rect.left, width: rect.width }}
      >
        {props.children}
      </MobileMenuWrapper>
    </div>
  )
}

function ProposalMenus(props) {
  const { activeTab, setActiveTab, menuItems = [] } = props

  const [anchorEl, setAnchorEl] = useState()

  return (
    <>
      <DesktopWrapper>
        <MenusWrapper>
          {menuItems.map((item, i) => {
            if (item.isHidden) return <React.Fragment key={i} />

            return (
              <MenuItem
                key={i}
                disabled={item.isDisabled}
                isActive={activeTab === item.value}
                onClick={() => !item.isDisabled && setActiveTab(item.value)}
              >
                {item.title}
              </MenuItem>
            )
          })}
        </MenusWrapper>
      </DesktopWrapper>

      <MobileWrapper>
        <MobileMenuItem button onClick={(event) => setAnchorEl(event.target)}>
          {menuItems.find((item) => item.value === activeTab)?.title || ""}
          <img
            style={{ transform: "rotate(180deg)" }}
            src="/images/icons/arrow.svg"
          />
        </MobileMenuItem>
        <MobileMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MobileMenuItem onClick={(event) => setAnchorEl(event.target)}>
            Закрыть
            <img src="/images/icons/arrow.svg" />
          </MobileMenuItem>
          <MobileMenuDivider />
          {menuItems.map((item, i) => {
            if (item.isHidden) return <React.Fragment key={i} />

            return (
              <MobileMenuItem
                disabled={item.isDisabled}
                key={i}
                isActive={activeTab === item.value}
                onClick={() => !item.isDisabled && setActiveTab(item.value)}
              >
                {item.title}
              </MobileMenuItem>
            )
          })}
        </MobileMenu>
      </MobileWrapper>
    </>
  )
}

export default ProposalMenus
