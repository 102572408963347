import React from "react"

import { Address, SelectInput } from "src/components"

import BankAutosuggestInput from "./BankAutosuggestInput"

import {
  Phone,
  Number,
  Nullable,
  TextInput,
  SwitchInput,
  CheckBoxInput,
  SwitchSelectInput,
} from "./base"

import ListInput from "./List"
import Passport from "./Passport"
import SupportingDocuments from "./SupportingDocuments"
import Attachments from "./Attachments"
import SelfieInput from "./SelfieInput"
import KYCDocuments from "./KYCDocuments"
import MigrationCard from "./MigrationCard"
import ResidenceDocument from "./ResidenceDocument"
import AttachmentsButton from "./AttachmentsButton"
import TerritoryResidenceDocument from "./TerritoryResidenceDocument"

export interface IField {
  name: string;
  value: string;
}

export interface IInputProps {
  environment: any;
  error: string | null;
  errors: string[];
  hasError: boolean;
  label: string;
  mask: string;
  name: string;
  onChange: (value: IField[]) => void;
  purposedmutations: string[];
  value: string;
}

export interface IWrapperInputProps {
  decorator: any | null;
  environment: any;
  error: string | null;
  errors: string[];
  hasError: boolean;
  label: string;
  mask: string;
  name: string;
  onChange: (value: IField[]) => void;
  purposedmutations: string[];
  value: string;
  type: string;
}

const Schema = new Map([
  ["phone", Phone],
  ["number", Number],
  ["text", TextInput],
  ["list", ListInput],
  ["switch", SwitchInput],
  ["switchSelect", SwitchSelectInput],
  ["checkbox", CheckBoxInput],
  ["selfie", SelfieInput],
  ["address", Address.autoSuggest],
  ["bank_autosuggest", BankAutosuggestInput],
  ["passport", Passport],
  ["supportingDocuments", SupportingDocuments],
  ["attachments", Attachments],
  ["attachments_button", AttachmentsButton],
  ["kyc_documents", KYCDocuments],
  ["migration_card", MigrationCard],
  ["residence_document", ResidenceDocument],
  ["territory_residence_document", TerritoryResidenceDocument],
  ["select", SelectInput],
])

const useInputDecoratorAndProps = (props: IWrapperInputProps) => React.useMemo(() => {
  const { decorator, ...rest } = props
  // TODO: проверить decorator, не приходит в пропсах для формы аккредитации ФЛ и ИП
  const Decorator = decorator || React.Fragment
  const decoratorProps = decorator ? rest : {}

  return [Decorator, decoratorProps]
}, [props.value])

export default (props: IWrapperInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, decorator, ...rest } = props

  const Input = Schema.get(type)
  const [Decorator, decoratorProps] = useInputDecoratorAndProps(props)

  if (!Input) {
    return <Nullable />
  }

  return (
    <Decorator {...decoratorProps}>
      <Input {...rest} />
    </Decorator>
  )
}
