import React from "react"

import { Box, Flex, Loader } from "src/components"

const ListConsumer = (props) => {
  const {
    data, adapter, emptyView, itemPresentation,
  } = props

  const adaptedData = adapter ? adapter(data.viewer) : []

  return (
    <Flex alignItems="flex-start" flexDirection="column">
      {adaptedData && adaptedData.length > 0
        ? adaptedData.map((processedData, index) => {
          const Item = itemPresentation
            ? itemPresentation(processedData)
            : () => null

          return <Item key={index} />
        })
        : emptyView()}
    </Flex>
  )
}

const MockLoader = () => (
  <Box py="60px">
    <Loader fontSize="14px" />
  </Box>
)

const ListSource = (props) => {
  const { queryContainer, ...rest } = props

  const QueriedList = queryContainer(ListConsumer)

  return <QueriedList {...rest} renderNull={MockLoader} />
}

export default ListSource
