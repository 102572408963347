import Alert from "./Alert"
import Confirmation from "./Confirmation"
import Error from "./Error"
import Success from "./Success"

export default {
  alert: {
    component: Alert,
    next: "confirmation",
  },
  confirmation: {
    component: Confirmation,
    next: "success",
  },
  error: {
    component: Error,
    next: null,
  },
  success: {
    component: Success,
    next: null,
  },
}
