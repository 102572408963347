import React, {
  useContext, useEffect, useState, useMemo,
} from "react"

import {
  Translate, LoaderBox, MiniLoader, LoadMore,
} from "src/components"
import { CurrentProfileContext } from "src/context"
import { InvestmentSucceededOrdersListQueryContainer } from "src/query"
import { getOrderTicker, toCurrencyFormat } from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  TDBold,
  TDDate,
  TDStatus,
  Graph,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
} from "./styles"
import { ClockIcon, GraphIcon } from "./icons"
import { Table, List } from "../Elements"
import {
  parseInvestments,
  getPaymentAmount,
  getOrderName,
  getOrderDuration,
  getOrderRate,
  getOrderSuccedDate,
} from "./utils"
import { InvestmentSucceededCard } from "../Cards"

export const statuses = new Map([
  [
    "BEFOREHAND",
    {
      text: "Погашен досрочно",
      color: "#6FA84B",
    },
  ],
  [
    "PAID",
    {
      text: "Погашен",
      color: "#6FA84B",
    },
  ],
  [
    "DELAYED",
    {
      text: "Погашен с задержкой",
      color: "#F70000",
    },
  ],
])

export function RenderedTable(props) {
  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
    filter,
    setFilter,
  } = props

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>
              {getOrderName(obj)}
            </TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => (
          <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>
        ),
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Срок",
        render: (i, obj) => (
          <TData key={i}>
            {getOrderDuration(obj) && (
              <>
                {getOrderDuration(obj)} <Translate i18n={"models:loan.month"} />
              </>
            )}
            {!getOrderDuration(obj) && "_"}
          </TData>
        ),
        placeholder: (i) => <TData key={i}>0 мес</TData>,
      },
      {
        title: "Инвестиции, ₽",
        render: (i, obj) => (
          <TDBold key={i}>{toCurrencyFormat(obj.amount)}</TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Выплачено, ₽",
        render: (i, obj) => (
          <TDBold key={i}>
            {toCurrencyFormat(getPaymentAmount(obj, profileId))}
          </TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Дата погашения займа",
        render: (i, obj) => (
          <TDDate key={i}>
            {ClockIcon} {getOrderSuccedDate(obj)}
          </TDDate>
        ),
        placeholder: (i) => <TDDate key={i}>{ClockIcon} -</TDDate>,
      },
      {
        title: "Статус",
        render: (i, obj) => {
          const status = statuses.get(obj.paymentSchedule.status) || {
            text: "Неизвестно",
            color: "grey",
          }

          return (
            <TDStatus color={status.color} key={i}>
              <Graph>{GraphIcon}</Graph>
              {status.text}
            </TDStatus>
          )
        },
        placeholder: (i) => (
          <TDStatus color="#6FA84B" key={i}>
            <Graph>{GraphIcon}</Graph>
            Погашен
          </TDStatus>
        ),
      },
    ]
  }

  const { investor } = data || {}
  const orders = useMemo(() => [...parseInvestments(investor)], [investor])
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.investor?.investments?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  const hasFilter = Boolean(Object.values(filter).find(Boolean))

  return (
    <div>
      {(orders.length > 0 || isFetching || hasFilter) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="ЗАВЕРШЕННЫЕ"
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
                filter={filter}
                setFilter={setFilter}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              title="ЗАВЕРШЕННЫЕ"
              items={orders}
              Render={InvestmentSucceededCard}
              isFetching={isFetching}
              toProposal={toProposal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
              filter={filter}
              setFilter={setFilter}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  return (
    <InvestmentSucceededOrdersListQueryContainer
      profile={profile}
      render={(data) => <RenderedTable {...rest} {...data} />}
    />
  )
}
