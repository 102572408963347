const getAvaliableProfilesFromProps = (profiles) => {
  const availableProfiles = []
  const unavailableProfiles = []

  profiles.forEach((profile) => {
    const {
      borrowerAccreditation,
      investorAccreditation,
      __typename: type,
    } = profile

    if (!["LegalEntityProfile", "EntrepreneurProfile"].includes(type)) {
      return
    }

    if (
      (borrowerAccreditation && borrowerAccreditation.status === "APPROVED") ||
      (investorAccreditation && investorAccreditation.status === "APPROVED")
    ) {
      availableProfiles.push(profile)
    } else {
      unavailableProfiles.push(profile)
    }
  })

  return { availableProfiles, unavailableProfiles }
}

export default getAvaliableProfilesFromProps
