import styled from "styled-components"

export const Flex = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-end;
`

export const LeftText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  width: 210px;
  margin: 0 24px 0 0;
  text-transform: uppercase;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    margin-right: 16px;
    width: 180px;
  }
`

export const RightText = styled(LeftText)`
  margin: 0;
  width: calc(100% - 234px);
  text-transform: none;

  @media screen and (max-width: 800px) {
    width: calc(100% - 196px);
  }
`

export const Empty = styled(RightText)`
  color: #f70000;
`
