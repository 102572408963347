import React from "react"

import { Container, Title, ApexChart } from "./styles"

function ColumnChart() {
  const redColor = "#F70000"
  const greyColor = "rgba(74, 74, 74, 0.75)"

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      foreColor: greyColor,
      fontFamily: "Geometria, Helvetica, Arial, sans-serif",
    },
    colors: redColor,
    xaxis: {
      categories: ["2016", "2017", "2018", "2019"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          fontSize: "12px",
          colors: greyColor,
          fontFamily: "Geometria, Helvetica, Arial, sans-serif",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [greyColor],
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top",
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
  }

  const series = [
    {
      name: "Выручка",
      data: [],
    },
  ]

  return (
    <Container>
      <Title>Выручка</Title>
      <ApexChart
        options={options}
        series={series}
        type="bar"
        height="170"
        width="100%"
      />
    </Container>
  )
}

export default ColumnChart
