import styled from "styled-components"

import { Select } from "src/components"

export const Container = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: 100px auto 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: ${(props) => props.theme.colors.primaryBlack};
  max-width: 420px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 40px 30px 30px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }
`

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const StyledSelect = styled(Select.landing)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  font-weight: 500;
  color: black;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  height: 64px;
  margin: 0;
  padding: 30px 16px 10px;
`

export const SelectLabel = styled.label`
  position: absolute;
  top: 13px;
  left: 16px;
  color: rgb(53, 58, 67);
  font-size: 75%;
  user-select: none;
`
