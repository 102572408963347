import {
  useState, useEffect, useCallback, useRef,
} from "react"

function useCountdown(time = 60, interval = 1000) {
  const [secondsLeft, setSecondsLeft] = useState(0)
  const timer = useRef<any>()

  const start = useCallback(() => setSecondsLeft(time), [])
  const stop = useCallback(() => setSecondsLeft(0), [])

  useEffect(() => {
    if (secondsLeft < 0) {
      clearInterval(timer.current)
      setSecondsLeft(0)
    }
  }, [secondsLeft])

  useEffect(() => {
    function tick() {
      setSecondsLeft(secondsLeft - 1)
    }

    timer.current = setInterval(tick, interval)

    return () => {
      clearInterval(timer.current)
    }
  }, [secondsLeft])

  return { secondsLeft, start, stop }
}

export default useCountdown
