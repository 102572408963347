import React from "react"
import memoize from "memoize-one"

import { Translate } from "src/components"
import { OrderDataConsumer } from "src/context"

import {
  Bold, PreLined, Container, TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

const render = memoize((order) => {
  const { minValue, minAmountDelta } = mapAmountToStatusFrom(order)

  return (
    <Container>
      <TextBlockWrapper>
        <PreLined>
          <Translate i18n="components:tooltips.min_amount_not_collected.head" />
          <Bold>{minValue} ₽</Bold>
        </PreLined>
        <PreLined>
          <Translate i18n="components:tooltips.min_amount_not_collected.postfix" />
          <Bold>{minAmountDelta} ₽</Bold>
        </PreLined>
      </TextBlockWrapper>
    </Container>
  )
})

export default () => <OrderDataConsumer>{render}</OrderDataConsumer>
