import React from "react"

import { TableWrapper, TH, TD } from "./styles"

const COLORS = {
  orange: "#F9E7CE",
  blue: "#CCD6F7",
  green: "#D8EDD4",
  red: "#F1CCCB",
}

function Table() {
  return (
    <TableWrapper cellPadding={8}>
      <thead>
        <tr>
          <TH bg={COLORS.orange} fontWeight={700} rowSpan={2}>
            Категория обеспечения
          </TH>
          <TH bg={COLORS.orange} rowSpan={2} width={200}>
            Степень покрытия залогом
          </TH>
          <TH bg={COLORS.blue} fontWeight={700} colSpan={5}>
            Рейтинг надежности заемщика
          </TH>
        </tr>
        <tr>
          <TH bg={COLORS.blue} fontWeight={700}>
            AAA
          </TH>
          <TH bg={COLORS.blue} fontWeight={700}>
            AA
          </TH>
          <TH bg={COLORS.blue} fontWeight={700}>
            A
          </TH>
          <TH bg={COLORS.blue} fontWeight={700}>
            BBB
          </TH>
          <TH bg={COLORS.blue} fontWeight={700}>
            BB
          </TH>
        </tr>
      </thead>

      <tbody>
        <tr>
          <TD bg={COLORS.orange} fontWeight={700} rowSpan={2}>
            AAA
          </TD>
          <TD bg={COLORS.orange}>от 100%</TD>
          <TD>AAA</TD>
          <TD bg={COLORS.green}>AAA</TD>
          <TD bg={COLORS.green}>AA</TD>
          <TD bg={COLORS.green}>A</TD>
          <TD bg={COLORS.green}>BBB</TD>
        </tr>
        <tr>
          <TD bg={COLORS.orange}>от 70% до 99%</TD>
          <TD>AAA</TD>
          <TD bg={COLORS.green}>AAA</TD>
          <TD bg={COLORS.green}>AA</TD>
          <TD bg={COLORS.green}>A</TD>
          <TD bg={COLORS.green}>BBB</TD>
        </tr>

        <tr>
          <TD bg={COLORS.orange} fontWeight={700} rowSpan={2}>
            AA
          </TD>
          <TD bg={COLORS.orange}>от 100%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD bg={COLORS.green}>AA</TD>
          <TD bg={COLORS.green}>A</TD>
          <TD bg={COLORS.green}>BBB</TD>
        </tr>
        <tr>
          <TD bg={COLORS.orange}>от 70% до 99%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD bg={COLORS.green}>AA</TD>
          <TD bg={COLORS.green}>A</TD>
          <TD bg={COLORS.green}>BBB</TD>
        </tr>

        <tr>
          <TD bg={COLORS.orange} fontWeight={700} rowSpan={2}>
            A
          </TD>
          <TD bg={COLORS.orange}>от 100%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD>A</TD>
          <TD bg={COLORS.green}>A</TD>
          <TD bg={COLORS.green}>BBB</TD>
        </tr>
        <tr>
          <TD bg={COLORS.orange}>от 70% до 99%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD>A</TD>
          <TD>BBB</TD>
          <TD bg={COLORS.red}>x</TD>
        </tr>

        <tr>
          <TD bg={COLORS.orange} fontWeight={700} rowSpan={2}>
            BBB
          </TD>
          <TD bg={COLORS.orange}>от 100%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD>A</TD>
          <TD>BBB</TD>
          <TD bg={COLORS.red}>x</TD>
        </tr>
        <tr>
          <TD bg={COLORS.orange}>от 70% до 99%</TD>
          <TD>AAA</TD>
          <TD>AA</TD>
          <TD>A</TD>
          <TD>BBB</TD>
          <TD bg={COLORS.red}>x</TD>
        </tr>
      </tbody>
    </TableWrapper>
  )
}

export default Table
