import React from "react"
import styled from "styled-components"

export const BodyContainer = styled.span<any>`
  display: ${({ display }) => display};
  border-radius: 5px;
  padding: ${({ padding }) => padding};
  top: ${({ top }) => top};
  max-width: 330px;
  color: ${({ theme }) => theme.colors.white};
  left: ${({ left }) => left};
  font-size: 12px;
  width: ${({ width }) => width};
  position: absolute;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.greyShuttle};

  &:before {
    content: "";
    height: 10px;
    width: 10px;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.colors.greyShuttle};
    position: absolute;
    top: -5px;
    left: ${({ alignLeft }) => (alignLeft ? "initial" : "24px")};
    right: ${({ alignLeft }) => (alignLeft ? "24px" : "initial")};
  }

  &:after {
    content: "";
    width: 100%;
    height: 35px;
    top: -35px;
    left: 0;
    display: block;
    position: absolute;
  }

  &:hover {
    display: block;
  }
`

const transformStyles = ({ transform }) => `
  transform: ${transform};
`

const border = ({ theme, borderColor, borderWidth }) => `border: ${borderWidth} solid ${theme.colors[borderColor]};`

export const HoverContainer = styled.div`
  &:hover + ${BodyContainer} {
    display: block;
  }
`

export const IconContainer = styled.span<any>`
  display: block;
  ${transformStyles};
  border-radius: 50%;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  ${border};
  background-color: transparent;
  text-align: center;

  &:hover + ${BodyContainer} {
    display: block;
  }
`

/* eslint-disable max-len */
export const Glyph = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.474 8.965">
    <path
      id="_"
      data-name="?"
      fill="#f2625a"
      d="M2.458,8.965A1.067,1.067,0,0,1,1.687,8.7a.992.992,0,0,1-.274-.745A1,1,0,0,1,1.68,7.2a1.089,1.089,0,0,1,.778-.256,1.056,1.056,0,0,1,.763.262,1,1,0,0,1,.27.748,1,1,0,0,1-.273.739A1.041,1.041,0,0,1,2.458,8.965Zm.718-3.039H1.6V5.486a1.951,1.951,0,0,1,.244-.992,3.207,3.207,0,0,1,.89-.9,3.736,3.736,0,0,0,.823-.725,1.057,1.057,0,0,0,.2-.624.679.679,0,0,0-.285-.588,1.368,1.368,0,0,0-.8-.2,4.585,4.585,0,0,0-2.03.581L0,.743A5.68,5.68,0,0,1,2.808,0,3,3,0,0,1,4.753.588a1.93,1.93,0,0,1,.721,1.568,2.1,2.1,0,0,1-.3,1.128A4.253,4.253,0,0,1,4.049,4.352a3.487,3.487,0,0,0-.721.641,1,1,0,0,0-.152.576v.355Z"
    />
  </svg>
)

export const GlyphContainer = styled.span`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  svg {
    width: 41%;
  }
`

export const TooltipContainer = styled.span`
  position: relative;
  display: inline-flex;
`
