import React, { useState } from "react"

import { SetOrderKarmaTrustedStatus } from "src/mutations"
import { MutationContainer } from "src/containers"
import { Loader } from "src/components"
import { monthDeclension, getOrderTicker, getOrderRating } from "src/utils"

import { InfoBlock, Checkbox } from "./Elements"

import {
  TRANSFER,
  CONFIRMED,
  COMPLETE,
  SUCCEEDED,
  DECLINED,
  APPROVED,
  PENDING,
  INITIAL,
} from "./constants"

import {
  getInterest,
  getPaymentDate,
  getRepaymentType,
  getRepaymentDescription,
} from "./utils"
import {
  Container,
  Info,
  ImageContainer,
  Image,
  Ticker,
  Title,
  Status,
  Description,
  Cession,
  InfoBlockWrapper,
  DeclinedReason,
  CheckboxWrapper,
  CheckboxLoader,
  Label,
  Tooltip,
  ButtonWrapper,
  ButtonDescription,
  EditButton,
} from "./styles"

import { Divider } from "../ProposalSpecification/Specifications/styles"

export const statuses = new Map([
  [
    TRANSFER,
    {
      text: "Ожидается поступление средств на счет заемщика",
      color: "#FF9900",
    },
  ],
  [
    CONFIRMED,
    {
      text: "Идёт сбор средств",
      color: "#6FA84B",
    },
  ],
  [
    COMPLETE,
    {
      text: "Заявка успешно закрыта",
      color: "#6FA84B",
    },
  ],
  [
    SUCCEEDED,
    {
      text: "Договор займа закрыт",
      color: "#6FA84B",
    },
  ],
  [
    DECLINED,
    {
      text: "В размещении отказано",
      color: "#F70000",
    },
  ],
  [
    APPROVED,
    {
      text: "Ожидается оплата комиссии",
      color: "#FF9900",
    },
  ],
  [
    PENDING,
    {
      text: "На модерации",
      color: "#FF9900",
    },
  ],
  [
    INITIAL,
    {
      text: "Черновик",
      color: "#00679C",
    },
  ],
])

function ProposalHeader(props) {
  const { data, isOwner } = props
  const {
    cession, application, offer, karma, backgroundImage,
  } = data

  const image = backgroundImage ? backgroundImage.url : "/images/MockImage.png"

  const [isLoading, setLoading] = useState(false)
  const [isChecked, setChecked] = useState(karma.isTrusted)

  const status: any = statuses.get(data.status) || {}

  function handleChange() {
    setLoading(true)

    const { commit } = props
    const { id } = data

    const variables = {
      input: {
        orderId: id,
        isTrusted: !isChecked,
      },
    }

    commit({
      variables,
      callbacks: {
        onError: () => setLoading(false),
        onCompleted: () => {
          setChecked(!isChecked)
          setLoading(false)
        },
      },
    })
  }

  function toEdit() {
    const { history } = props

    history.push(`/orders/${data.id}`)
  }

  return (
    <>
      <Container>
        <Info>
          {cession && <Cession>Цессия</Cession>}
          <Ticker>Номер заявки - {getOrderTicker(data, data.cession)}</Ticker>
          <Title>
            {application.shortTitle || application.data.companyName || "-"}
          </Title>
          <Status color={status.color}>{status.text}</Status>

          {(data.status !== DECLINED || !isOwner) && (
            <>
              <Description>{application && application.longTitle}</Description>
              <InfoBlockWrapper>
                <InfoBlock
                  title={cession ? "Доходность" : "Ставка"}
                  value={`${getInterest(data)}%`}
                  descr="годовых"
                />

                {cession && (
                  <InfoBlock
                    title="Дата погашения"
                    value={getPaymentDate(data)}
                    descr={""}
                  />
                )}

                {!cession && (
                  <InfoBlock
                    title="Срок"
                    value={offer.data.duration}
                    descr={monthDeclension(offer.data.duration)}
                  />
                )}

                <InfoBlock
                  title="Тип погашения"
                  value={getRepaymentType(data)}
                  descr={getRepaymentDescription(data)}
                />

                <InfoBlock
                  title="Рейтинг"
                  value={getOrderRating(data)}
                  descr="подробнее"
                  url={`/invest/scoring?source=${data.id}`}
                />
              </InfoBlockWrapper>
            </>
          )}

          {data.status === DECLINED && isOwner && (
            <>
              <DeclinedReason>
                {application.declineReason || "Причина недоступна"}
              </DeclinedReason>
              <CheckboxWrapper>
                {isLoading && (
                  <CheckboxLoader>
                    <Loader fontSize="3px" />
                  </CheckboxLoader>
                )}

                {!isLoading && (
                  <Checkbox checked={isChecked} onChange={handleChange} />
                )}
                <Label>Разрешить помочь мне с оформлением заявки</Label>
              </CheckboxWrapper>
              <Tooltip>
                Наш сотрудник поможет составить заявку и сделать ее более
                привлекательной для инвесторов.
              </Tooltip>

              <ButtonWrapper>
                <ButtonDescription>
                  После того как вы внесете исправления, ваша заявка снова
                  должна будет пройти процедуру проверки.
                </ButtonDescription>
                <EditButton onClick={toEdit}>Редактировать анкету</EditButton>
              </ButtonWrapper>
            </>
          )}
        </Info>

        <ImageContainer img={backgroundImage}>
          <Image src={image} />
        </ImageContainer>
      </Container>

      <Divider />
    </>
  )
}

export default function Render(props) {
  return (
    <MutationContainer mutation={SetOrderKarmaTrustedStatus}>
      {({ commit }) => <ProposalHeader commit={commit} {...props} />}
    </MutationContainer>
  )
}
