import React, { useCallback, useState } from "react"
import memoize from "memoize-one"

import { notifyGTM, number, getOrderTicker } from "src/utils"
import { ErrorsContainer } from "src/containers"
import {
  Box,
  Document,
  Translate,
  FloatingLabelInput,
  CheckBoxField,
} from "src/components"

import {
  OrderType,
  AmountType,
  AmountMainType,
  ProfileType,
} from "../types"

import {
  InputWrapper,
  Header,
  Title,
  FlexContainer,
  InputContainer,
  InputLabel,
  DepositButton,
  DescriptionContainer,
  Description,
  AgreementContainer,
  AgreementText,
  RedButton,
  ReturnButton,
  CheckboxLabel,
} from "./styles"

import { useErrors, useOnChange } from "./hooks"
import { useOnSubmit } from "./hookUseOnSubmit"

import ProfileSelect from "./ProfilePicker"
import { normalizeDocuments } from "../../../molecules/InstructionsModal/instructionsModalUtils"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const sendGTMEvent = memoize((order: OrderType) => {
  const { cession, application, id: orderId } = order

  const notify = notifyGTM({
    event: "checkoutFunnel1",
    eventCategory: "",
    extras: {
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
              quantity: 1,
            },
          ],
        },
      },
    },
  })

  notify()
})

const Amount = (props: AmountType) => {
  const {
    order, amount, profile, availableAmount, goBack,
  } = props

  const { application } = order

  const ticker = getOrderTicker(order)
  const documents = normalizeDocuments(order)

  const onChange = useOnChange(props)
  const onSubmit = useOnSubmit(props)
  const getErrorByPath = useErrors(props)

  const [isAgreed, setAgreed] = useState(false)
  const handleChangeAgreed = useCallback(
    ({ target }) => setAgreed(target.checked),
    [],
  )

  const focusedOnInput = React.useRef(false)
  const onInputFocus = React.useCallback(() => {
    if (!focusedOnInput.current) {
      sendGTMEvent(order)
      focusedOnInput.current = true
    }
  }, [])

  const onChangeProfile = (userProfile: ProfileType) => {
    props.setProfile(userProfile)
    props.clearError("investToOrder.amount")
  }

  return (
    <>
      <ReturnButton onClick={goBack}>Назад</ReturnButton>

      <Header>
        <Title>
          Проинвестировать в заявку{" "}
          <span>
            “{application.data.companyName}” {ticker}
          </span>
        </Title>
      </Header>

      <InputWrapper>
        <InputLabel>
          <Translate i18n="components:investment_modals.amount.profile" />
        </InputLabel>
        <FlexContainer>
          <InputContainer>
            <ProfileSelect
              value={profile}
              onChange={onChangeProfile}
              options={props.profiles}
            />
          </InputContainer>

          <DepositButton variant="empty" onClick={props.showDeposit}>
            <Translate i18n="components:investment_modals.amount.deposit" />
          </DepositButton>
        </FlexContainer>
      </InputWrapper>

      <InputWrapper>
        <InputLabel>
          <Translate i18n="components:investment_modals.amount.amount_header" />
        </InputLabel>
        <FlexContainer>
          <InputContainer>
            <FloatingLabelInput
              autoFocus
              mb="0px"
              name="withdrawalAmount"
              value={amount}
              onChange={onChange}
              onFocus={onInputFocus}
              hasError={getErrorByPath("investToOrder.amount")}
              error={getErrorByPath("investToOrder.amount")}
              label="components:account.operations.withdraw.amount.amount_placeholder"
              autoComplete="new-value"
              autoCorrect="off"
            />
          </InputContainer>
          <DescriptionContainer>
            <Description>
              <Translate i18n="components:investment_modals.amount.amount_min" />
            </Description>
            <Description>
              <Translate
                i18n="components:investment_modals.amount.amount_left"
                maxAmount={number(availableAmount, options)}
              />
            </Description>
          </DescriptionContainer>
        </FlexContainer>
      </InputWrapper>

      <AgreementContainer>
        <AgreementText>
          Нажатием кнопки "инвестировать" и последующим вводом кода из смс вы акцептуете следующие оферты:
        </AgreementText>
        {/* <AgreementDescription>
          Фактом перевода денежных средств в адрес онлайн-сервиса «КАРМА» вы
          заключаете:
        </AgreementDescription> */}
      </AgreementContainer>

      {documents.map((doc, idx) => (
        <Box mb="20px" key={idx} is={Document} doc={doc} />
      ))}

      <Box>
        <CheckBoxField
          checked={isAgreed}
          onChange={handleChangeAgreed}
          name="agreement"
          fill="#F70000"
          color="#4a4a4a"
          fontSize="14px"
          alignItems="flex-start"
          label={
            <CheckboxLabel>
              Заверяю ООО «КАРМА ТЕХНОЛОДЖИ» в том, что если на меня
              распространяется ограничение, указанное в{" "}
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="http://www.consultant.ru/document/cons_doc_LAW_330652/0d78b2af30b8b13592ff61dc6cf25f3a9b4449f7/ "
              >
                части 1 ст. 7 Федерального закона от 02.08.2019 N 259-ФЗ
              </a>
              , то при инвестировании мной по данному договору займа сумма моих
              инвестиций на всех инвестиционных платформах за текущий
              календарный год не превысит 600 000 (Шестьсот тысяч) рублей.
            </CheckboxLabel>
          }
        />
      </Box>

      <RedButton disabled={!amount || !isAgreed} onClick={onSubmit}>
        инвестировать
      </RedButton>
    </>
  )
}

export default (props: AmountMainType) => (
 <ErrorsContainer>
    {(errors) => <Amount {...props} {...errors} />}
  </ErrorsContainer>
)
