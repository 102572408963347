import React, { useCallback } from "react"
import useCollapse from "react-collapsed"

import { SearchInput } from "src/components"
import {
  Title, Description, List, TitleContainer,
} from "./styles"
import {
  ArrowIcon,
  CollapseButton,
  CollapsedContent,
  NotFoundBlock,
} from "../Table/styles"
import { MOCK_ORDERS } from "../../../OrdersList/Lists/Market/constants"

const collapseStyles = {
  transitionDuration: ".3s",
  transitionTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
}

export default function (props) {
  const {
    title,
    descr,
    items,
    Render,
    isFetching,
    isExpanded = true,
    setExpanded,
    setFilter,
    filter,
    ...rest
  } = props

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    expandStyles: collapseStyles,
    collapseStyles,
  })

  const handleSearch = useCallback((search) => {
    setFilter((filter) => ({ ...filter, search }))
  }, [])

  const hasFilter = Boolean(filter && Object.values(filter).find(Boolean))
  const hasItems = items?.length > 0

  const showSearch = !!setFilter
  const isSearchDisabled = !isExpanded

  const showItems = !isFetching && hasItems
  const showNotFound = !isFetching && hasFilter && !hasItems
  const showMock = isFetching

  return (
    <div>
      {!!title && (
        <TitleContainer>
          <Title>{title}</Title>

          {!!setExpanded && (
            <CollapseButton
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
              disabled={isFetching}
              isExpanded={isExpanded}
            >
              <ArrowIcon />
            </CollapseButton>
          )}
        </TitleContainer>
      )}

      <CollapsedContent {...getCollapseProps()}>
        {showSearch && (
          <SearchInput
            value={filter?.search}
            placeholder="Название / ИНН"
            maxWidth="100%"
            onChange={handleSearch}
            disabled={isSearchDisabled}
          />
        )}
        {descr && <Description>{descr}</Description>}
        <List>
          {showItems
            && items.map((item, i) => <Render key={i} {...item} {...rest} />)}

          {showMock
            && Array(MOCK_ORDERS)
              .fill(0)
              .map((_, i) => <Render.placeholder key={i} />)}
        </List>

        {showNotFound && <NotFoundBlock>Не найдено</NotFoundBlock>}
      </CollapsedContent>
    </div>
  )
}
