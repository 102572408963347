import React, { useEffect, useRef, useState } from "react"

import { Translate, Box } from "src/components"
import {
  getOrderTicker,
  getOrderInterestRate,
  getOrderRating,
  roundNumber,
} from "src/utils"
import { formatDateShort } from "src/utils/date"

import { MobileInformationCompany } from "../Elements"

import {
  MobileWrapper as Container,
  MobileItemContainer,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  MobileDescriptionTitle,
  MobileDescription,
  MobileButton,
  CardButtonsWrapper,
  ButtonLink,
  DiagramIcon,
  FlipContainer,
  Flipper,
  FlipperBack,
  FlipperFront,
} from "./styles"
import { removeDots } from "./utils"

function getOrderStatus(order) {
  const status = order?.paymentSchedule?.status

  switch (status) {
    case "BEFOREHAND":
      return { isSuccess: true, description: "Погашен досрочно" }

    case "PAID":
      return { isSuccess: true, description: "Погашен в срок" }

    case "DELAYED":
      return { isSuccess: false, description: "Погашен с задержкой" }

    default:
      return { isSuccess: false, description: "Неизвестно" }
  }
}

function MobileItem(props) {
  const { data } = props

  const [isClicked, setClicked] = useState(false)

  const [height, setHeight] = useState(300)
  const containerRef = useRef(null)
  const containerRef2 = useRef(null)

  useEffect(() => {
    const maxHeight = containerRef.current.clientHeight > containerRef2.current.clientHeight
      ? containerRef.current.clientHeight
      : containerRef2.current.clientHeight
    setHeight(maxHeight)
  })

  const { companyName } = data?.application?.data
  const [media] = data?.application?.externalMedium
  const amount = props.data.chain?.gatheredAmount || 0

  const amountView = roundNumber(amount)
  const orderStatus = getOrderStatus(data)
  const orderCompletedDate = formatDateShort(data.completedAt)
  const orderSucceededDate = formatDateShort(data.succeededAt)

  function toggleAdditionalInfo() {
    setClicked(!isClicked)
  }

  return (
    <MobileItemContainer minHeight={height} onClick={toggleAdditionalInfo}>
      <FlipContainer isClicked={isClicked}>
        <Flipper>
          <FlipperFront minHeight={height} ref={containerRef}>
            <HeaderWrap>
              <MobileHeaderContainer>
                <MobileInformationCompany name={companyName} video={media} />
              </MobileHeaderContainer>
            </HeaderWrap>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>№ заявки</MobileInformationName>
                <MobileInformationValue>
                  {getOrderTicker(data)}
                </MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rating"} />
                </MobileInformationName>
                <MobileInformationValue>
                  {getOrderRating(data)}
                </MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>Сумма</MobileInformationName>
                <MobileInformationValue>{amountView}</MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rate"} />
                </MobileInformationName>
                <MobileInformationValue>
                  {getOrderInterestRate(data)}%
                </MobileInformationValue>
              </MobileInformationItem>
            </MobileInformationContainer>
          </FlipperFront>

          <FlipperBack minHeight={height} ref={containerRef2}>
            <Box mb={32}>
              <MobileDescriptionTitle>Описание</MobileDescriptionTitle>
              <MobileDescription>
                {data.application.longTitle
                  || data.application.shortTitle
                  || "Описание отсутствует"}
              </MobileDescription>
            </Box>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>Дата получения</MobileInformationName>
                <MobileInformationValue>
                  {removeDots(orderCompletedDate)}
                </MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>Дата погашения</MobileInformationName>
                <MobileInformationValue>
                  {removeDots(orderSucceededDate)}
                </MobileInformationValue>
              </MobileInformationItem>

              <MobileInformationItem>
                <MobileInformationName>Статус</MobileInformationName>
                <MobileInformationValue>
                  <DiagramIcon /> {orderStatus.description}
                </MobileInformationValue>
              </MobileInformationItem>
            </MobileInformationContainer>

            <CardButtonsWrapper>
              <MobileButton width="100%">
                <ButtonLink to={`/market/${data.id}?source=market`}>
                  подробнее
                </ButtonLink>
              </MobileButton>
            </CardButtonsWrapper>
          </FlipperBack>
        </Flipper>
      </FlipContainer>
    </MobileItemContainer>
  )
}

export default {
  Container,
  Body: MobileItem,
}
