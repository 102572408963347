import React from "react"
import styled from "styled-components"
import i18next from "i18next"

import { Text } from "src/components"

const BannerContainer = styled.div`
  z-index: 30;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  position: fixed;
  left: 0;
  line-height: 56px;
  background-color: #ddf1f1;
  border-bottom: 1px solid #d9dee2;
`

const DescriptionContainer = styled.div`
  margin-left: 38px;
  background-image: url(/banners/borrower/ginger.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 280px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`

const TitleContainer = styled.div``

const LinkContainer = styled.div`
  padding: 0 26px 0 42px;
  position: relative;
  background-image: url(/banners/borrower/linkBackground.png);
  background-position: center center;
  background-repeat: round;
  background-size: contain;

  &:before,
  &:after {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:before {
    content: "";
    background-image: url(/banners/borrower/link1.png);
    position: absolute;
    width: 31px;
    height: 100%;
    left: -9px;
    top: 0;
  }

  &:after {
    content: "";
    background-image: url(/banners/borrower/link2.png);
    width: 30px;
    height: 100%;
    position: absolute;
    left: -3px;
    top: 0;
  }
`

const StyledLink = styled.a`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.ginger};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

/* eslint-disable max-len */
const render = () => {
  const { language } = i18next

  return language === "ru" ? (
    <>
      <BannerContainer>
        <DescriptionContainer>
          <Text fontSize="18px">Акция +100 в Карму!</Text>
        </DescriptionContainer>
        <TitleContainer>
          <Text fontSize="16px">
            До конца года комиссия за размещение заявки на заем — 100₽! Заряди
            свой бизнес деньгами!
          </Text>
        </TitleContainer>
        <LinkContainer>
          <StyledLink
            href="https://medium.com/karmatrust/%D0%BF%D1%80%D0%B8%D0%B2%D0%B5%D1%82-%D0%B4%D1%80%D1%83%D0%B7%D1%8C%D1%8F-%D1%81%D0%B5%D0%B3%D0%BE%D0%B4%D0%BD%D1%8F-%D0%BC%D1%8B-%D0%BF%D1%80%D0%B8%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%B8%D0%BB%D0%B8-%D0%B4%D0%BB%D1%8F-%D0%B2%D0%B0%D1%81-%D0%BD%D0%B5%D1%87%D1%82%D0%BE-%D0%BE%D1%81%D0%BE%D0%B1%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5-b3da4f39adfa"
            target="_blank"
          >
            ПОДРОБНЕЕ
          </StyledLink>
        </LinkContainer>
      </BannerContainer>
    </>
  ) : null
}

export default render
