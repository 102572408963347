import React from "react"
import Relay, { graphql } from "react-relay"

import { divideNumber } from "src/utils"

import {
  Container,
  TextBlock,
  TextBlockTitle,
  Text,
  Card,
  CardTitle,
  CardDivider,
  CardRedDivider,
  CardDescription,
  CardTextBlock,
  CardLeftText,
  CardRightText,
  CardSubText,
} from "./styles"

const REFERRAL_DOCUMENT_URL = "https://drive.google.com/file/d/1tnyRIj3lyklNOo2pEWw3o8GqhVDBNQdc/view"

function ReferralDescription(props) {
  const { referrals = {} } = props?.referrals?.refer
  const {
    investedCount = 0,
    registeredCount = 0,
    paid = 0,
    income = 0,
  } = referrals

  return (
    <Container>
      <TextBlock>
        <TextBlockTitle>Как расcчитывается бонус</TextBlockTitle>
        <Text>1. Отправьте друзьям свою ссылку.</Text>
        <Text>
          2. Все, кто зарегистрируются по вашей ссылке получат +1 500 рублей на
          счет после того, как они инвестируют.
        </Text>
        <Text>
          3. Вы будете получать 1% от суммы инвестиции друга в период действия
          акции, но не менее 1 500 рублей и не более 300 000 рублей за каждого
          друга.
        </Text>
        <Text>4. Бонус рассчитывается и выплачивается ежемесячно.</Text>
        <a
          href={REFERRAL_DOCUMENT_URL}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Text>
            <i>* Полный текст акции</i>
          </Text>
        </a>
      </TextBlock>

      <Card>
        <CardTitle>Статистика</CardTitle>
        <CardRedDivider />
        <CardTextBlock>
          <CardLeftText>
            Зарегистрировались кодом
            <br />
            <CardSubText>Прошли идентификацию</CardSubText>
          </CardLeftText>
          <CardRightText fontWeight={600}>{registeredCount}</CardRightText>
        </CardTextBlock>
        <CardTextBlock>
          <CardLeftText>Проинвестировали</CardLeftText>
          <CardRightText fontWeight={600}>{investedCount}</CardRightText>
        </CardTextBlock>
        <CardDivider />
        <CardTextBlock>
          <CardLeftText fontWeight={600}>Ваш доход **</CardLeftText>
          <CardRightText fontWeight={600}>
            {divideNumber(income)} ₽
          </CardRightText>
        </CardTextBlock>
        <CardTextBlock>
          <CardLeftText fontWeight={600}>Ранее выплачено</CardLeftText>
          <CardRightText fontWeight={600}>{divideNumber(paid)} ₽</CardRightText>
        </CardTextBlock>
        <CardTextBlock>
          <CardLeftText fontWeight={600}>Бонус к выплате</CardLeftText>
          <CardRightText fontWeight={600}>
            {divideNumber(income - paid)} ₽
          </CardRightText>
        </CardTextBlock>
        <CardDescription>
          ** Сумма Бонуса перечисляется за вычетом НДФЛ (если уплата этого
          налога предусмотрена законодательством применительно к конкретному
          Пользователю).
        </CardDescription>
      </Card>
    </Container>
  )
}

export default Relay.createFragmentContainer(ReferralDescription, {
  referrals: graphql`
    fragment ReferralDescription_referrals on UserProfile {
      refer {
        referrals {
          paid
          income
          registeredCount
          investedCount
        }
      }
    }
  `,
})
