import React from "react"
import { Route } from "react-router-dom"

import { Button } from "src/components"

const handleRedirect = (params) => (event) => {
  const { to, onClick, history } = params

  history.push(to)

  if (onClick) {
    onClick(event)
  }
}

const render = (props) => {
  const {
    to, history, onClick, ...rest
  } = props

  return <Button {...rest} onClick={handleRedirect({ history, to, onClick })} />
}

export default (props) => (
  <Route>{({ history }) => render({ ...props, history })}</Route>
)
