/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type JuristicProfileData_profile = {
    readonly id: string;
    readonly name: string;
    readonly __typename: string;
    readonly user: {
        readonly id: string;
        readonly email: string;
    };
    readonly accreditation: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly phone?: string | null;
    readonly inn?: string | null;
    readonly kpp?: string | null;
    readonly ogrn?: string | null;
    readonly iian?: string | null;
    readonly borrower?: {
        readonly publicEmail: string | null;
    } | null;
    readonly address?: {
        readonly raw: string | null;
    } | null;
    readonly actualAddress?: {
        readonly raw: string | null;
    } | null;
    readonly business?: {
        readonly main: string | null;
        readonly aux: string | null;
        readonly legalForm: string | null;
        readonly startedAt: string | null;
    } | null;
    readonly passport?: {
        readonly scans: ReadonlyArray<{
            readonly url: string;
            readonly filename: string;
        }>;
    } | null;
    readonly executive?: {
        readonly name: string;
    } | null;
    readonly " $refType": "JuristicProfileData_profile";
};
export type JuristicProfileData_profile$data = JuristicProfileData_profile;
export type JuristicProfileData_profile$key = {
    readonly " $data"?: JuristicProfileData_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"JuristicProfileData_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ogrn",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iian",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Borrower",
  "kind": "LinkedField",
  "name": "borrower",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicEmail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "raw",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "actualAddress",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Passport",
  "kind": "LinkedField",
  "name": "passport",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "scans",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JuristicProfileData_profile",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "role",
          "value": "BORROWER"
        }
      ],
      "concreteType": "Accreditation",
      "kind": "LinkedField",
      "name": "accreditation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": "accreditation(role:\"BORROWER\")"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kpp",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Business",
          "kind": "LinkedField",
          "name": "business",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "main",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aux",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalForm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "executive",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "LegalEntityProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/)
      ],
      "type": "EntrepreneurProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '42761c5f25e88153c00c94973de35f35';
export default node;
