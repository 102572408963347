import styled from "styled-components"

import { CloseIcon } from "./icons"

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const WindowContainer = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  max-width: 740px;
  width: 100%;
  overflow-y: auto;
  max-height: 90vh;
  height: auto;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  padding: 40px 30px;
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: 14px;
  line-height: 1.5;

  @media screen and (max-width: 800px) {
    max-width: 500px;
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
  }
`

export const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  margin: 0 0 28px;
  color: ${(props) => props.theme.colors.textBlacks[8]};

  @media screen and (max-width: 480px) {
    margin-top: 45px;
  }
`

export const Text = styled.div`
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
`

export const Description = styled(Text)`
  font-style: italic;
  margin-bottom: 16px;
  font-size: 12px;
`

export const Button = styled.button`
  font-family: Geometria, sans-serif;
  position: absolute;
  padding: 0;
  top: 24px;
  right: 24px;
  width: 20px;
  height: 20px;

  @media screen and (max-width: 480px) {
    top: 32px;
  }
`

export const Icon = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  fill: ${(props) => props.theme.colors.fullBlack};
  vertical-align: middle;
`

export const ReturnButton = styled(Button)`
  left: 24px;
  width: auto;
  padding: 0 8px;
  color: ${(props) => props.theme.colors.textBlacks[8]};
  font-size: 16px;
  display: none;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    display: block;
  }
`

export const Divider = styled.div`
  position: relative;
  width: 35%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.primaryBlack};
  margin: 20px 0;
`

export const SupText = styled(Text)`
  font-size: 10px;
`
