import React from "react"

import { formatNumber } from "../utils"
import { ProfitabilityCell } from "../elements"
import { Divider, ProfitabilityContainer } from "../styles"

const Profitability = (props) => {
  const { busy, monthlyProfit, overallProfit } = props

  return (
    <ProfitabilityContainer>
      <ProfitabilityCell
        busy={busy}
        title="modals:profitability_calc.monthly_profit"
        amount={formatNumber(monthlyProfit)}
      />
      <Divider />
      <ProfitabilityCell
        busy={busy}
        title="modals:profitability_calc.overall_profit"
        amount={formatNumber(overallProfit)}
      />
    </ProfitabilityContainer>
  )
}

export default Profitability
