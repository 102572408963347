import React from "react"
import Relay, { graphql } from "react-relay"

import { getProfileDetailedBalance } from "src/utils"
import { ExchangeRateProvider } from "src/context"

import { Box, Translate, DefaultModal } from "src/components"

import { StyledButton } from "./styles"
import AmountModal from "./AmountModal"
import Confirmation from "./Confirmation"
import SuccessModal from "./SuccessModal"

import { useAmount, useExchangeTargets, useOnCompleteCallbacks } from "./hooks"

const modals = {
  amount: {
    component: AmountModal,
    next: "confirmation",
  },
  confirmation: {
    component: Confirmation,
    next: "success",
  },
  success: {
    component: SuccessModal,
    next: null,
  },
}

const Convert = ({ profile, baseCurrency }) => {
  const detailedBalance = getProfileDetailedBalance(profile)
  const [balanceTargets, setBaseCurrency] = useExchangeTargets(
    detailedBalance,
    baseCurrency,
  )
  const [amount, setAmount] = useAmount((balanceTargets as any).from.available)

  const [activeModal, setActiveModal] = React.useState(null)
  const openModal = React.useCallback(() => setActiveModal("amount"), [])
  const hideModal = React.useCallback(() => {
    (setAmount as any)({ target: { value: "" } })
    setActiveModal(null)
  }, [])

  const Modal = React.useMemo(() => modals[activeModal], [activeModal])
  const callbacks = useOnCompleteCallbacks(modals, setActiveModal, hideModal)

  return (
    <ExchangeRateProvider>
      <StyledButton variant="default" onClick={openModal}>
        <Translate i18n="components:account.operations.convert.button" />
      </StyledButton>
      {!!activeModal && (
        <DefaultModal
          padding="0"
          onClose={hideModal}
          heightRestriction={false}
          closeButtonVariant="empty"
        >
          <Box width="560px" p="40px">
            <Modal.component
              amount={amount}
              profile={profile}
              onClose={hideModal}
              onChange={setAmount}
              balanceTargets={balanceTargets}
              setBaseCurrency={setBaseCurrency}
              onComplete={callbacks[activeModal]}
            />
          </Box>
        </DefaultModal>
      )}
    </ExchangeRateProvider>
  )
}

Convert.defaultProps = {
  baseCurrency: "RUB",
}

const render = (props) => {
  const profile = props.profile || props.foreignProfile
  const detailedBalance = getProfileDetailedBalance(profile)

  if (!Array.isArray(detailedBalance) || detailedBalance.length < 2) return null

  return <Convert baseCurrency={props.baseCurrency} profile={profile} />
}

export default Relay.createFragmentContainer(render, {
  profile: graphql`
    fragment Convert_profile on UserProfile {
      id
      __typename
      name
      accountBalance
      accountBalanceDetailed {
        currency
        available
      }
      ... on LegalEntityProfile {
        phone
        inn
      }
      ... on IndividualProfile {
        phone
        inn
      }
      ... on EntrepreneurProfile {
        phone
        inn
      }
    }
  `,
  foreignProfile: graphql`
    fragment Convert_foreignProfile on ForeignIndividualProfile {
      id
      __typename
      phone
      firstName
      lastName
      accountBalanceDetailed {
        currency
        available
      }
    }
  `,
})
