import React, { useCallback } from "react"

import { createChainedFunction } from "src/utils"
import { InputPassword } from "src/components"

function Inputs(props) {
  const {
    viewer, getError, validate, clearError, attributes,
  } = props

  const onChange = useCallback(
    createChainedFunction(attributes.onChange, clearError, validate),
    [],
  )

  return (
    <>
      <InputPassword
        withoutBorder
        errorColor="mainRed"
        name="current_password"
        hasError={getError("setViewerPassword.current_password")}
        error={getError("setViewerPassword.current_password")}
        disabled={!viewer.password.present}
        label="components:account.password_update_form.rows.password.old"
        onChange={onChange}
        value={attributes.value.current_password}
      />
      <InputPassword
        withoutBorder
        errorColor="mainRed"
        name="password"
        hasError={
          getError("setViewerPassword.retry_password")
          || getError("setViewerPassword.password")
        }
        error={getError("setViewerPassword.password")}
        label="components:account.password_update_form.rows.password.new"
        onChange={onChange}
        value={attributes.value.password}
      />
      <InputPassword
        withoutBorder
        errorColor="mainRed"
        name="retry_password"
        hasError={
          getError("setViewerPassword.retry_password")
          || getError("setViewerPassword.password")
        }
        error={getError("setViewerPassword.retry_password")}
        label="components:account.password_update_form.rows.password.retry"
        onChange={onChange}
        value={attributes.value.retry_password}
      />
    </>
  )
}

export default Inputs
