import styled from "styled-components"

const Container = styled.div`
  display: flex;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.greyLight};
  padding: 0 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export default Container
