/* eslint-disable camelcase */
/*
 * mutations:
 *
 * AddIndividualProfile ({
 *   lastName: String!
 *   firstName: String!
 *   middleName: String
 *   phone: String!
 *   })
 *
 * SetIndividualProfile ({
 *   profileId: ID!
 *   lastName: String!
 *   firstName: String!
 *   middleName: String
 *   phone: String
 *   })
 *
 * UpdateIndividualProfileAsInvestor ({
 *     profileId: ID!
 *     inn: String!
 *     iian: String!
 *     address: AddressInput!
 *     passport: PassportInput!
 *     birthDate: String!
 *     birthPlace: String!
 *     isPublicOfficial: Boolean!
 *   })
 * */
import {
  pipe,
  omit,
  removeNonNumeric,
  removeWhiteSpaces,
  fillStringToLength,
} from "src/utils"

import InitialState from "./constants"
import {
  IIndividualProps,
  IIndividualStateWithBank,
  IIndividualProfileSmallDataset,
} from "./types"

import { RussianIndividualFragment_profile } from "./__generated__/RussianIndividualFragment_profile.graphql"

const numberFields = ["inn", "iian", "phone"]
const parseNumber = (val) => val?.replace(/\D/gim, "").trim() || ""

/*
 * -> YYYY-MM-DD
 * <- DD-MM-YYYY
 * */
const dateToFormState = (date) => {
  if (typeof date === "string") {
    return date.split("-").reverse().join(" - ")
  }

  return date
}

/*
 * -> DD-MM-YYYY
 * <- YYYY-MM-DD
 * */
const formStateToDate = (date) => {
  if (!date) return ""

  if (typeof date === "string") {
    return date.split(" - ").reverse().join("-").replace(/_/gim, "")
      .trim()
  }

  return date
}

const formStateToScans = (scans) => scans.map((scan) => {
  if (typeof scan === "string") {
    return scan
  }

  const { id } = scan

  return id
})

const formStateToDocuments = (scans) => scans.map((scan) => {
  if (typeof scan === "string") {
    return scan
  }

  if (scan.attachment) {
    return scan.attachment.id
  }

  return scan.id
})

const passportToState = (individualProfile: RussianIndividualFragment_profile) => {
  /* eslint-disable prefer-const */
  let {
    passport,
    address,
    birthDate,
    ...restIndividualProfileData
  } = individualProfile

  if (passport === null) {
    passport = { ...InitialState.passport }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { raw, ...addressWithoutRaw } = address || { raw: null }
  if (addressWithoutRaw === null) {
    addressWithoutRaw = { ...InitialState.address }
  }

  const {
    issuedAt,
    issuedBy: { code, name },
    number,
    series,
    scans: injectedScans,
    postponeScans,
  } = passport

  const stringedSeries = fillStringToLength(String(series), 4)
  const stringedNumber = fillStringToLength(String(number), 6)

  const numberAndSeries = `${stringedSeries} / ${stringedNumber}`

  const nextPassportData = {
    code: code.replace(/_/gim, "").trim(),
    name,
    scans: [...injectedScans],
    series: numberAndSeries,
    issuedAt: dateToFormState(issuedAt),
    postponeScans,
  }

  return {
    address: addressWithoutRaw,
    birthDate: dateToFormState(birthDate),
    ...restIndividualProfileData,
    passport: nextPassportData,
  }
}

const stateToPassport = (formState: IIndividualProfileSmallDataset) => {
  const { passport, ...restFormState } = formState

  const {
    code,
    name,
    scans: statePassportScans,
    series: stringedSeries,
    issuedAt,
    postponeScans,
  } = passport

  const [splitedSeries = "", splitedNumber = ""] = stringedSeries.split(" / ")

  const issuedBy = {
    code: code.replace(/_/gim, "").trim(),
    name,
  }

  const nextPassportData = {
    issuedBy,
    number: parseNumber(splitedNumber),
    series: parseNumber(splitedSeries),
    scans: formStateToScans(statePassportScans),
    issuedAt: formStateToDate(issuedAt),
    postponeScans: !!postponeScans,
  }

  return {
    ...restFormState,
    passport: {
      ...nextPassportData,
    },
  }
}

const stateToDocuments = (formState: IIndividualProfileSmallDataset) => {
  const { supportingDocuments, ...restFormState } = formState

  return {
    ...restFormState,
    supportingDocuments: formStateToDocuments(supportingDocuments),
  }
}

export const initialStateFromProps = (props: IIndividualProps) => {
  const { profile } = props

  if (!profile) {
    return InitialState
  }

  const {
    approvedOnce, isRussiaTaxResident, isPublicOfficial, address: injectedAddress, sex,
    bankAccounts,
  } = profile

  return {
    approvedOnce,
    id: profile.id,
    phone: profile.phone,
    lastName: profile.lastName,
    firstName: profile.firstName,
    middleName: profile.middleName || "",
    ...passportToState(profile),
    isPublicOfficial: !isPublicOfficial,
    isRussiaTaxResident: !!isRussiaTaxResident,
    address: injectedAddress || { ...InitialState.address },
    sex: sex || "MALE",
    checkingAccount: bankAccounts[0]?.checkingAccount,
    correspondentAccount: bankAccounts[0]?.correspondentAccount,
    bic: bankAccounts[0]?.bank.bic,
    bankName: bankAccounts[0]?.bank.name,
  }
}

export const profileDataFromState = (state: IIndividualStateWithBank) => {
  const {
    id, lastName, firstName, middleName, phone,
  } = state

  const profileData = {
    lastName: lastName.trim(),
    firstName: firstName.trim(),
    middleName: middleName?.trim() || null,
    phone: parseNumber(phone),
  }

  const profileDataCorrect = omit(profileData, [
    "bankName",
    "bic",
    "correspondentAccount",
    "checkingAccount",
  ])

  if (id) {
    return {
      profileId: id,
      ...profileDataCorrect,
    }
  }

  return profileDataCorrect
}

const processDateField = (individualProfile: IIndividualProfileSmallDataset) => {
  const { birthDate, ...restFormData } = individualProfile

  let nextBirthDate = birthDate

  if (typeof birthDate === "string") {
    nextBirthDate = nextBirthDate.split(" - ").reverse().join("-")
  }

  return {
    birthDate: nextBirthDate,
    ...restFormData,
  }
}

const maskedFields = ["code", "iian", "phone", "issuedAt"]

/* removeNonNumeric */
const spacedFields = ["birthDate"]

const nullableFields = ["inn", "iian", "birthDate", "birthPlace"]

const removeNullable = (string) => {
  if (string === null) {
    return ""
  }

  return string
}

const clear = (fields, acc) => (obj) => {
  if (typeof obj === "undefined" || obj == null || typeof obj !== "object") {
    return obj
  }
  /* eslint-disable no-param-reassign */
  /* eslint-disable array-callback-return */
  Object.keys(obj).map((key) => {
    if (typeof obj[key] === "string" || obj[key] === null) {
      if (fields.includes(key)) {
        obj[key] = acc(obj[key])
      } else {
        obj[key] = clear(fields, acc)(obj[key])
      }
    }
  })

  return obj
}

export const investorDataFromState = (state: IIndividualStateWithBank) => {
  const investor = omit(state, [
    "id",
    "lastName",
    "firstName",
    "middleName",
    "phone",
    "approvedOnce",
    "isApproved",
    "bankName",
    "checkingAccount",
    "correspondentAccount",
    "bic",
    "bankAccounts",
    "__typename",
  ])

  const processInvestorData = pipe(
    stateToPassport,
    stateToDocuments,
    processDateField,
    clear(nullableFields, removeNullable),
    clear(maskedFields, removeNonNumeric),
    clear(spacedFields, removeWhiteSpaces),
    clear(numberFields, parseNumber),
  )

  return processInvestorData({
    ...investor,
    profileId: state.id,
    isPublicOfficial: !state.isPublicOfficial,
  })
}
