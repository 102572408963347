import React from "react"

export function RenderTableComponent(props: any) {
  const hasNextPage = Boolean(props.root?.orders?.pageInfo?.hasNextPage)

  const handleLoad = () =>
    props.relay.hasMore()
    && !props.relay.isLoading()
    && props.relay.loadMore(props.pageSize)

  const Table = props.render({
    data: props.root ?? {},
    load: { handle: handleLoad, isNext: hasNextPage },
    environment: props.relay.environment,
    isFetching: props.root === null,
    filter: props.filter,
    setFilter: props.setFilter,
  })
  return <div>{Table}</div>
}
