/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialTransactionStateEnum = "DRAFT" | "ERROR" | "OK" | "PENDING_BANK" | "PROCESSING" | "REJECTED_BANK" | "REJECTED_OPERATOR" | "WAIT_BC" | "WAIT_MODERATE" | "%future added value";
export type FinancialTransactionTypeEnum = "BORROW" | "CURRENCY_EXCHANGE" | "DEPOSIT" | "FEE" | "FEE_REFUND" | "INCOME_TAX" | "INTEREST_FEE" | "INTEREST_REPAYMENT" | "INVESTMENT" | "INVESTMENT_REFUND" | "INVESTMENT_RETURN" | "KYC" | "LOAN_REPAYMENT" | "METAL_LOAN_REPAYMENT" | "META_BORROW" | "META_FEE" | "META_FEE_RETURN" | "META_INTEREST_REPAYMENT" | "META_INVESTMENT" | "META_INVESTMENT_RETURN" | "META_KYC" | "META_PENALTY" | "META_PENALTY_FEE" | "META_PURCHASE_LOAN" | "META_WITHDRAWAL" | "PENALTY" | "PENALTY_FEE" | "PURCHASE_LOAN" | "REFUND" | "TAX_REFUND" | "TRANSFER" | "UNKNOWN" | "WITHDRAWAL" | "WITHDRAWAL_FEE" | "%future added value";
export type TransactionItem_transaction = {
    readonly id: string;
    readonly date: string | null;
    readonly type: FinancialTransactionTypeEnum | null;
    readonly error: string | null;
    readonly state: FinancialTransactionStateEnum | null;
    readonly amount: number;
    readonly isAuto: boolean;
    readonly description: string | null;
    readonly toAccount: {
        readonly id: string;
        readonly description: string | null;
        readonly profile?: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly foreignProfile?: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
        } | null;
        readonly order?: {
            readonly id: string;
            readonly application: {
                readonly shortTitle: string;
                readonly longTitle: string;
            } | null;
            readonly chain: {
                readonly id: string;
            } | null;
            readonly profile: {
                readonly id: string;
                readonly name: string;
                readonly borrower?: {
                    readonly ticker: string | null;
                } | null;
            };
        };
    } | null;
    readonly fromAccount: {
        readonly id: string;
        readonly description: string | null;
        readonly profile?: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly foreignProfile?: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
        } | null;
        readonly order?: {
            readonly id: string;
            readonly application: {
                readonly shortTitle: string;
                readonly longTitle: string;
            } | null;
            readonly chain: {
                readonly id: string;
            } | null;
            readonly profile: {
                readonly id: string;
                readonly name: string;
                readonly borrower?: {
                    readonly ticker: string | null;
                } | null;
            };
        };
    } | null;
    readonly loan?: {
        readonly amount: number | null;
        readonly order: {
            readonly id: string;
            readonly application: {
                readonly shortTitle: string;
                readonly longTitle: string;
            } | null;
            readonly chain: {
                readonly id: string;
            } | null;
            readonly profile: {
                readonly id: string;
                readonly name: string;
                readonly borrower?: {
                    readonly ticker: string | null;
                } | null;
            };
        };
    } | null;
    readonly " $refType": "TransactionItem_transaction";
};
export type TransactionItem_transaction$data = TransactionItem_transaction;
export type TransactionItem_transaction$key = {
    readonly " $data"?: TransactionItem_transaction$data;
    readonly " $fragmentRefs": FragmentRefs<"TransactionItem_transaction">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Order",
  "kind": "LinkedField",
  "name": "order",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderApplication",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shortTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "longTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderChainInfo",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Borrower",
              "kind": "LinkedField",
              "name": "borrower",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ticker",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LegalEntityProfile"
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ForeignIndividualProfile",
        "kind": "LinkedField",
        "name": "foreignProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "FinancialTransactionProfileAccount"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v4/*: any*/)
    ],
    "type": "FinancialTransactionSharedWalletAccount"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionItem_transaction",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAuto",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "toAccount",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "fromAccount",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvestmentTransactionLoan",
          "kind": "LinkedField",
          "name": "loan",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "InvestmentTransaction"
    }
  ],
  "type": "FinancialTransactionInterface"
};
})();
(node as any).hash = 'c954d3eb9e86ec7cba44bde5a3427942';
export default node;
