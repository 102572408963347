import React, { useState, useCallback, FC } from "react"
import styled, { css } from "styled-components"
import { Node } from "slate"

import {
  Box, Tooltip, Translate, Wysiwyg,
} from "src/components"

const formatValue = (value?: string): Node[] => {
  if (!value || typeof value === "string") {
    return [
      {
        type: "paragraph",
        children: [{ text: value || "" }],
      },
    ]
  }

  return value
}

export const Label = styled.label<{ disabled?: boolean }>`
  ${({ disabled }) => !!disabled
    && css`
      opacity: 0.4;
    `}

  & > a {
    color: currentColor;
    text-decoration: none;
  }
`

const renderLabel = ({ label, disabled, tooltip }: any) => label && (
    <Box pb="12px">
      <Label disabled={disabled}>
        <Translate i18n={`${label}`} ns="components" />
      </Label>
      <Tooltip text={tooltip} />
    </Box>
)

const WysiwygInput: FC<any> = (props) => {
  const { value, onChange, name } = props

  const [state, setState] = useState<Node[]>(formatValue(value))

  const handleChange = useCallback(
    (newValue: Node[]) => {
      setState(newValue)
      onChange({ value: newValue, name })
    },
    [onChange, name, setState],
  )

  return (
    <>
      {renderLabel(props)}
      <Wysiwyg.Editor value={state} onChange={handleChange} />
    </>
  )
}

export default WysiwygInput
