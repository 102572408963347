import { pick } from "src/utils"

const getBankAccountInput = (flatAccount) => {
  if (!flatAccount) return null

  const {
    bic, name, checkingAccount, correspondentAccount,
  } = flatAccount

  return {
    checkingAccount,
    correspondentAccount,
    bank: {
      bic,
      name,
    },
  }
}

export const getUpdateVariables = (account) => ({
  input: {
    accountId: account.id,
    account: getBankAccountInput(account),
  },
})

export const getCreateVariables = (account, profileId) => ({
  input: {
    profileId,
    account: getBankAccountInput(account),
  },
})

export const getValidity = (form) => {
  const fieldsToCheck = [
    "name",
    "bic",
    "correspondentAccount",
    "checkingAccount",
  ]
  return Object.keys(pick(form, fieldsToCheck)).every((key) => !!form[key])
}

const flattenReduceFunc = (data) => (accum, key) => (typeof data[key] === "object"
  ? { ...accum, ...data[key] }
  : { ...accum, [key]: data[key] })

export const flatten = (data) => Object.keys(data).reduce(flattenReduceFunc(data), {})

export const clearFormError = (clearError) => (path) => (...rest) => {
  clearError(path)

  return rest
}
