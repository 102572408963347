import {
  Store, Network, Environment, RecordSource,
} from "relay-runtime"

import fetch from "./fetch"
import createSubscribe from "./subscribe"

export default () => new Environment({
  store: new Store(new RecordSource()),
  network: Network.create(fetch, createSubscribe()),
})
