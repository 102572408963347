import React from "react"

import { OrderDataConsumer, OrderTypeConsumer } from "src/context"

import {
  Box, Flex, Link, Invest, Translate, PaymentTable,
} from "src/components"

import {
  Title,
  ScheduleButton,
  TablesContainer,
  ScheduleContainer,
} from "../styles"

import { Arrow } from "../elements"

const formatWithCession = (str, isCession) => (isCession ? `${str}_cession` : str)

const Action = React.memo(() => (
  <OrderTypeConsumer>
    {(orderType) => (
      <OrderDataConsumer>
        {(order) => (orderType === "Proposal" ? (
            <Invest order={order} withOutdatedMessage buttonVariant="default" />
        ) : (
            <Link to={`/market/${order.id}?source=market`}>
              <ScheduleButton p="6px 20px" variant="default">
                <Translate i18n="modals:profitability_calc.actions.order_info" />
              </ScheduleButton>
            </Link>
        ))
        }
      </OrderDataConsumer>
    )}
  </OrderTypeConsumer>
))

const PaymentSchedule = (props) => {
  const {
    opened, onClick, schedule, isCession,
  } = props

  const prescheduleType = formatWithCession("preschedule", isCession)

  return (
    <ScheduleContainer>
      <Flex alignItems="center" justifyContent="space-between">
        <Box onClick={onClick}>
          <ScheduleButton variant="empty">
            <Translate i18n="modals:profitability_calc.payment_schedule.button" />
          </ScheduleButton>
          <Arrow opened={opened} />
        </Box>
        <Action />
      </Flex>
      {opened && schedule && (
        <TablesContainer>
          <Title>
            <Translate i18n="modals:profitability_calc.payment_schedule.schedule" />
          </Title>
          <Box mt="10px" mb="30px">
            <PaymentTable type={prescheduleType} items={schedule} />
          </Box>
        </TablesContainer>
      )}
    </ScheduleContainer>
  )
}

export default React.memo(PaymentSchedule)
