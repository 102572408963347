import styled from "styled-components"
import { maxWidth } from "styled-system"

export const RowWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  max-width: 550px;

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
`

export const InputBox = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 861px) {
    ${maxWidth};
  }
`
