import { maxWidth, space } from "styled-system"
import styled, { css } from "styled-components"

import { Box } from "src/components"

export const InputContainer = styled.div<any>`
  width: 100%;
  ${maxWidth};
  ${space};
`

export const InputTooltipContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 21px;
`

const errorStyles = ({ theme, hasError, errorColor }) => {
  if (hasError) {
    return css`
      .material__input {
        border-color: ${theme.colors[errorColor]} !important;
      }

      .material__label {
        color: ${theme.colors[errorColor]} !important;
      }
    `
  }

  return ""
}

const disabledStyles = css`
  .material__input:disabled + .material__label{
    //color: ${({ theme }) => theme.colors.grey};
    color: rgba(95, 95, 95, 0.55);
  }
  
  .material__input:disabled {
    cursor: not-allowed;
    //color: ${({ theme }) => theme.colors.grey};
    color: rgba(95, 95, 95, 0.75);
    //border-color: ${({ theme }) => theme.colors.greyLight};
    user-select: none;
    background-color: #ffffff;
  }
`

export const InputWrapper = styled(Box)`
  position: relative;
  ${errorStyles}

  .material__input {
    ${maxWidth};
    border: 1px solid ${({ theme }) => theme.colors.grey};
    width: 100%;
    height: 64px;
    border-radius: 4px;
    padding: 30px 16px 10px;
    font-size: 16px;
  }

  .material__input:focus:not(:disabled) {
    ${({ withoutBorder }) => !withoutBorder
      && css`
        border: 2px solid ${({ theme }) => theme.colors.blue};
        box-shadow: 0 0 12px 0 rgba(217, 222, 226, 0.56);
      `}
  }

  .material__label {
    font-size: 0.875rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    transition: all 200ms;
    opacity: 0.5;
    color: ${({ theme }) => theme.colors.greyDarkest};
  }

  .material__input:focus:not(:disabled) + .material__label,
  .material__input:not([value=""]) + .material__label {
    font-size: 75%;
    top: 20px;
    transform: translate3d(0, -50%, 0);
    opacity: 1;

    ${({ withoutBorder }) => !withoutBorder
      && css`
        color: ${({ theme }) => theme.colors.blue};
      `}
  }

  ${disabledStyles}
`
