import { graphql } from "react-relay"

const query = graphql`
  query PreScheduleQuery($orderId: ID!, $amount: Float!) {
    preSchedule(orderId: $orderId, amount: $amount) {
      date
      loan
      state
      total
      total_gross
      interest
      interest_fee
      personal_tax
      loan_details {
        currency
        amount
      }
      total_details {
        currency
        amount
      }
      total_gross_details {
        currency
        amount
      }
      interest_details {
        currency
        amount
      }
      interest_fee_details {
        currency
        amount
      }
      personal_tax_details {
        currency
        amount
      }
      exchangeRates {
        currency
        amount
      }
    }
  }
`

export default query
