export const EMAIL = "email"
export const SMS = "sms"

export const submitTypes = [
  { label: "Через E-mail", value: EMAIL },
  { label: "Через SMS", value: SMS },
]

export const initialValues = {
  email: "",
  submitType: EMAIL,
}
