import { compose } from "src/utils"

const API_KEY = "26047d3af9d5dba19cbeecce85af0d833f33d574"
const BASE_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest"
const DEFAULT_TYPE = "bank"
const DEFAULT_SUGGESTIONS_NUMBER = 3

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Token ${API_KEY}`,
}

const responseFormatter = (response) => JSON.parse(response.target.response).suggestions
const prepareResponse = (cb) => compose(cb, responseFormatter)

const requestSuggestions = (request, type) => (
  query,
  cb,
  count = DEFAULT_SUGGESTIONS_NUMBER,
) => {
  if (!cb && typeof cb !== "function") return null
  if (request.readyState === "LOADING") request.abort()

  request.open("POST", `${BASE_URL}/${type}`)
  Object.keys(headers).forEach((key) => request.setRequestHeader(key, headers[key]))

  const body = { query, count }
  request.onload = prepareResponse(cb)
  return request.send(JSON.stringify(body))
}

const createDaDataSuggestor = (type = DEFAULT_TYPE) => {
  const request = new XMLHttpRequest()
  return requestSuggestions(request, type)
}

export default createDaDataSuggestor
