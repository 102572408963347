import is from "is_js"
import React from "react"
import memoize from "memoize-one"
import idx from "idx"

import {
  getOfferRemainingDays,
  hasCollectedMaxAmount,
  getOfferExpirationDate,
} from "src/utils"
import { Translate } from "src/components"

const translation = memoize((props) => {
  const { path, ...rest } = props

  return <Translate i18n={path} {...rest} />
})

export default {
  initial: () => translation({ path: "components:account.order_item.info.state.INITIAL" }),
  pending: () => translation({ path: "components:account.order_item.info.state.PENDING" }),
  confirmed: (data) => {
    const { confirmedAt } = data
    const term = idx(data, (_) => _.offer.data.term)
      ? data.offer.data.term
      : null

    const parsedDate = getOfferExpirationDate(term, confirmedAt)
    const remainingDays = getOfferRemainingDays(parsedDate) + 1

    if (!is.number(remainingDays) || hasCollectedMaxAmount(data)) return null

    if (remainingDays === 1) return translation({ path: "common:order.entity-date.expired" })

    return translation({
      path: "common:order.entity-date.remainWithCount",
      count: remainingDays,
    })
  },
  approved: ({ fee }) => translation({
    path: "components:account.order_item.info.state.APPROVED",
    fee,
  }),
  dead: (data, viewerToOrderRelation) => {
    const { MASTER, INVESTOR } = viewerToOrderRelation

    if (MASTER) {
      return translation({
        path: "components:account.order_item.info.state.DEAD.toMaster",
      })
    }

    if (INVESTOR) {
      return translation({
        path: "components:account.order_item.info.state.DEAD.toInvestor",
      })
    }

    return null
  },
}
