/* eslint-disable import/prefer-default-export */

const stateToProps = (
  selectedValue,
  injectedValue,
  inputValue = null,
  allowUnreliable = false,
) => {
  if (!selectedValue) {
    return allowUnreliable
      ? {
        raw: inputValue,
        unreliable: true,
      }
      : injectedValue
  }

  const {
    area,
    block,
    city,
    settlement,
    flat,
    house,
    region,
    street,
    okato,
    oktmo,
    postalCode,
    cityDistrict,
  } = selectedValue

  const country = ""
  const locality = city !== null ? city : settlement
  let housing = ""
  let structure = ""

  if (block > 0) {
    housing = block
  } else {
    structure = block
  }

  return {
    area,
    flat,
    house,
    street,
    region,
    housing,
    country,
    locality,
    structure,
    okato,
    oktmo,
    settlement,
    postalCode,
    cityDistrict,
    raw: inputValue || null,
    unreliable: false,
  }
}

export { stateToProps }
