import styled from "styled-components"

export default styled.div<any>`
  padding: ${({ padding }) => padding};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "740px"};
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  align-items: flex-start;
`
