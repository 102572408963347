import React, { useState, useEffect, useCallback } from "react"
import onClickOutside from "react-onclickoutside"
import moment from "moment"

import i18n from "src/i18n"
import { Icon } from "src/components"

import ComposedConsumer from "./ComposedConsumer"

import {
  Tooltip,
  DateText,
  Container,
  RateMessage,
  CurrencyList,
  CurrencyButton,
} from "./styles"

const Currency = (props) => {
  const { value, open, setCurrency } = props

  return (
    <li key={value}>
      <CurrencyButton value={value} onClick={setCurrency} open={open}>
        <Icon.currency>{value}</Icon.currency>
      </CurrencyButton>
    </li>
  )
}

const initMessage = (pair) => {
  let dateMessage = ""
  let rateMessage = ""

  if (!pair) {
    return {
      dateMessage,
      rateMessage,
    }
  }

  const { buy, sell, price } = pair

  const date = moment().format("DD.MM.YYYY")

  dateMessage = `${i18n.t("common:on")} ${date}`
  rateMessage = `${i18n.t("common:rate")} ${buy} ${i18n.t(
    "common:to",
  )} ${sell} = ${price}`

  return {
    dateMessage,
    rateMessage,
  }
}

const Switcher = (props) => {
  const { country, currency: injectedCurrencyProps, exchangeRateData } = props

  const { rates } = exchangeRateData

  const { currency, currencies, applyCurrency } = injectedCurrencyProps

  const [showList, setShowList] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)

  const [rubEur, eurRub] = rates;

  (Switcher as any).handleClickOutside = () => setShowList(false)

  const viewList = useCallback(() => {
    if (showList) {
      setShowList(false)
    } else {
      setShowList(true)
    }
  }, [showList])

  useEffect(() => {
    setShowList(false)
  }, [currency])

  const setActiveCurrency = useCallback(
    (event) => {
      applyCurrency(event.currentTarget.value)
    },
    [currency],
  )

  const availableCurrencies = currencies.filter(
    (listCurrency) => listCurrency !== currency,
  )
  const pair = currency === "RUB" ? rubEur : eurRub
  const { dateMessage, rateMessage } = initMessage(pair)

  const onMouseOver = () => setShowTooltip(true)
  const onMouseOut = () => setShowTooltip(false)

  if (country === "RU") {
    return null
  }

  return (
    <Container>
      <CurrencyButton
        open={showList}
        onClick={viewList}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        tooltipIsVisible={showTooltip}
      >
        <Icon.currency>{currency}</Icon.currency>
        <Icon.arrowDown strokeColor="grey" optionsVisible={showList} />
        {!showList && showTooltip && (
          <Tooltip>
            <DateText>{dateMessage}</DateText>
            <RateMessage>{rateMessage}</RateMessage>
          </Tooltip>
        )}
      </CurrencyButton>
      {showList && (
        <CurrencyList>
          {availableCurrencies.map((listCurrency, index) => (
            <Currency
              key={index}
              open={showList}
              value={listCurrency}
              setCurrency={setActiveCurrency}
            />
          ))}
        </CurrencyList>
      )}
    </Container>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => (Switcher as any).handleClickOutside,
}

const CurrencySwitcher = onClickOutside(Switcher, clickOutsideConfig)

export default () => (
  <ComposedConsumer>
    {(renderProps) => <CurrencySwitcher {...renderProps} />}
  </ComposedConsumer>
)
