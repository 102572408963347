/* eslint-disable no-param-reassign */
import React from "react"

import { ErrorsConsumer } from "src/context"
import { cloneDeep, uniqueBy } from "src/utils"
import {
  Box,
  Flex,
  Text,
  Translate,
  ErrorForField,
  FloatingLabelInput,
} from "src/components"

import {
  AddIcon,
  DeleteIcon,
  InputContainer,
  AddMoreContainer,
  DefaultInputContainer,
} from "./styles"

const renderDefaultInput = (props) => (
  <DefaultInputContainer>
    <FloatingLabelInput
      name={props.index}
      value={props.value}
      onChange={props.onChange}
      label={props.label}
      mask={props.mask}
      maskChar={props.maskChar}
      hasError={!!props.error}
      error={props.error}
    />
  </DefaultInputContainer>
)

const useErrors = (errors, items, name) => React.useMemo(() => {
  const filtered = errors.filter((error) => error.path.split(".").includes(name))
  const unique = uniqueBy(filtered, "path")
  const errorsMap = new Array(items.length).fill(null)

  unique.forEach((item) => {
    const idx = item.path.split(name)[1].split(".")[1]
    errorsMap[idx] = item
  })

  return errorsMap
}, [errors, name])

const removeIndex = (error, idx) => error && { ...error, path: error.path.replace(`.${idx}`, "") }

const ListInput = (props) => {
  const {
    min,
    max,
    name,
    onChange,
    initialItem,
    renderInput,
    value: items,
    ...rest
  } = props

  const errors = useErrors(props.errorsData.errors, items, name)

  const onAdd = () => {
    if (items.length >= max) return

    onChange({
      name,
      value: [...items, cloneDeep(initialItem)],
    })
  }

  const onItemChange = (e) => {
    const { name: itemName, value } = e.target
    const newItems = items.map((item, idx) => (Number(idx) === Number(itemName) ? value : item))

    onChange({ name, value: newItems })

    if (errors[itemName]) {
      props.errorsData.clearError(errors[itemName].path)
    }
  }

  const onItemDelete = (e) => {
    if (items.length <= min) return

    const itemName = e.target.attributes.name.value
    const newItems = items.filter((item, idx) => idx.toString() !== itemName)

    onChange({ name, value: newItems })
  }

  return (
    <Box>
      {items.map((item, idx) => (
        <Flex key={idx} alignItems="center" mb="20px">
          <InputContainer>
            {renderInput({
              index: idx,
              value: item,
              onChange: onItemChange,
              error: ErrorForField(removeIndex(errors[idx], idx)),
              ...rest,
            })}
          </InputContainer>
          <DeleteIcon name={idx} onClick={onItemDelete} />
        </Flex>
      ))}
      {items.length < max && (
        <AddMoreContainer onClick={onAdd} alignItems="center">
          <AddIcon />
          <Text color="blue">
            <Translate i18n="common:add_more" />
          </Text>
        </AddMoreContainer>
      )}
    </Box>
  )
}

ListInput.defaultProps = {
  min: 0,
  max: Infinity,
  initialItem: "",
  renderInput: renderDefaultInput,
}

export default (props) => (
  <ErrorsConsumer>
    {(errors) => <ListInput {...props} errorsData={errors} />}
  </ErrorsConsumer>
)
