import React from "react"

import i18n from "src/i18n"
import {
  Box, Modal, Heading, RedirectButton,
} from "src/components"

export default (props) => {
  const { link, onClose } = props

  return (
    <Modal.Basic onClose={onClose}>
      <Heading.h3>
        {i18n.t("components:user-page-header.modals.create-profile.header")}
      </Heading.h3>
      <Box py={20}>
        {i18n.t(
          "components:user-page-header.modals.create-profile.description",
        )}
      </Box>
      <RedirectButton to={link} onClick={onClose}>
        {i18n.t("components:user-page-header.modals.create-profile.button")}
      </RedirectButton>
    </Modal.Basic>
  )
}
