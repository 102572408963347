import React from "react"

import { StatusbarContainer, StatusBarFilled, StatusBarEmpty } from "./styles"

export default (props) => (
  <StatusbarContainer width={props.width}>
    <StatusBarFilled value={props.value} />
    <StatusBarEmpty />
  </StatusbarContainer>
)
