import styled from "styled-components"
import { color } from "styled-system"

export const PaymentCard = styled.li`
  position: relative;
  font-family: Geometria;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 50px;
  background-color: #ffffff;
  padding-bottom: 32px;
  border-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.19);
`

export const PaymentCardHeader = styled.div`
  width: 100%;
  padding: 0 18px;
  height: 93px;
  display: flex;
  justify-content: space-between;
  background: #fbfbfb;
`

export const PaymentCardHeaderLeft = styled.div`
  font-family: Geometria;
  margin: auto 0;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: left;
`

export const PaymentCardHeaderRight = styled.div`
  font-family: Geometria;
  margin: auto 0;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: right;
  
  ${color};
`

export const PaymentCardBody = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
`

export const PaymentCardData = styled.div`
  width: 100%;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
`

export const PaymentCardDataLeft = styled.div`
  font-family: Geometria;
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: left;
`

export const PaymentCardDataRight = styled.div`
  font-family: Geometria;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  color: ${(props) => props.color || "#4a4a4a"};
`
