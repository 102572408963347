/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type InvestmentCompletedOrdersListFragment = {
    readonly node: {
        readonly id: string;
        readonly amount: number;
        readonly order: {
            readonly id: string;
            readonly karma: {
                readonly purchase: {
                    readonly isEnable: boolean;
                    readonly discount: number;
                } | null;
            };
            readonly status: OrderStatusEnum | null;
            readonly confirmedAt: string | null;
            readonly paymentScheduleList: ReadonlyArray<{
                readonly profile: {
                    readonly id: string;
                } | null;
                readonly info: {
                    readonly state: string | null;
                    readonly pastdue_days: number | null;
                } | null;
                readonly items: ReadonlyArray<{
                    readonly date: string;
                    readonly state: string | null;
                    readonly total: number;
                    readonly total_gross: number;
                    readonly total_details: ReadonlyArray<{
                        readonly currency: CurrencyEnum;
                        readonly amount: number;
                    }>;
                    readonly total_gross_details: ReadonlyArray<{
                        readonly currency: CurrencyEnum;
                        readonly amount: number;
                    }>;
                }>;
            }>;
            readonly offer: {
                readonly data: string;
                readonly receivedAt: string | null;
                readonly approvedAt: string | null;
            } | null;
            readonly application: {
                readonly data: string;
                readonly shortTitle: string;
                readonly longTitle: string;
            } | null;
            readonly chain: {
                readonly id: string;
                readonly gatheredAmount: number;
            } | null;
            readonly profile: {
                readonly id: string;
                readonly name?: string;
                readonly borrower?: {
                    readonly ticker: string | null;
                } | null;
            };
        };
    };
    readonly " $refType": "InvestmentCompletedOrdersListFragment";
};
export type InvestmentCompletedOrdersListFragment$data = InvestmentCompletedOrdersListFragment;
export type InvestmentCompletedOrdersListFragment$key = {
    readonly " $data"?: InvestmentCompletedOrdersListFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"InvestmentCompletedOrdersListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvestmentCompletedOrdersListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Investment",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Order",
          "kind": "LinkedField",
          "name": "order",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderKarma",
              "kind": "LinkedField",
              "name": "karma",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "KarmaPurchase",
                  "kind": "LinkedField",
                  "name": "purchase",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isEnable",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "discount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "confirmedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentSchedule",
              "kind": "LinkedField",
              "name": "paymentScheduleList",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "profile",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoanInfo",
                  "kind": "LinkedField",
                  "name": "info",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "pastdue_days",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentScheduleItem",
                  "kind": "LinkedField",
                  "name": "items",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "date",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total_gross",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AmountWithCurrency",
                      "kind": "LinkedField",
                      "name": "total_details",
                      "plural": true,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AmountWithCurrency",
                      "kind": "LinkedField",
                      "name": "total_gross_details",
                      "plural": true,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderOffer",
              "kind": "LinkedField",
              "name": "offer",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "receivedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approvedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderApplication",
              "kind": "LinkedField",
              "name": "application",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shortTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "longTitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderChainInfo",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gatheredAmount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Borrower",
                      "kind": "LinkedField",
                      "name": "borrower",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "ticker",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LegalEntityProfile"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvestmentEdge"
};
})();
(node as any).hash = 'bcc2f483470cde1fb94af8e292b68ee0';
export default node;
