import styled from "styled-components"
import { HeaderWrap } from "../../styles"

export const IconWrap = styled.a`
  display: flex;
  align-items: center;
  padding: 5px;
  &:first-child {
    padding-left: 0;
  }
`

export const IconLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Geometria;
  align-self: end;
  margin-top: 10px;
  ${HeaderWrap} > & {
    margin-right: 16px;
    ${IconWrap} {
      padding: 5px;
    }
  }
  @media screen and (max-width: 1279px) {
    ${HeaderWrap} > & {
      display: none;
    }
  }
`
export const IconLinkListTitle = styled.div`
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
  ${HeaderWrap} > ${IconLinkWrapper} > & {
    font-size: 10px;
    margin-bottom: 2px;
    padding-left: 5px;
  }
  color: ${(props) => props.color || "#4A4A4A"};
`
