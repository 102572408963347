import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { CardWrapper, OutlinedButton } from "src/components"

export * from "./wrappers"

export const Container = styled(CardWrapper)`
  padding: 12px 8px;
  width: 100%;
  margin-top: 20px;
  font-family: Geometria;
  color: #4a4a4a;

  @media screen and (max-width: 480px) {
    padding: 20px;
  }
`

export const FlexContainer = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 480px) {
    justify-content: space-between;
  }
`

export const Title = styled.h6`
  font-weight: 300;
  font-size: 0.75rem;
  color: rgba(74, 74, 74, 0.75);
  margin: 0;
  margin-top: 12px;
  padding: 0 12px;

  @media screen and (max-width: 800px) {
    margin: 0;
    padding: 0 4px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.875rem;
    padding: 0;
  }
`

export const Value = styled.p`
  font-weight: 800;
  font-size: 1.75rem;
  color: #5f5f5f;
  margin: 0;
  margin-top: 6px;
  padding: 0 12px;

  @media screen and (max-width: 800px) {
    padding: 0 4px;
  }

  @media screen and (max-width: 800px) and (min-width: 481px) {
    font-size: 1.375rem;
  }

  @media screen and(max-width: 480px) {
    padding: 0;
  }
`

export const Description = styled.p`
  font-weight: 300;
  font-size: 0.75rem;
  color: rgba(74, 74, 74, 0.75);
  margin: 0;
  padding: 0 12px;

  @media screen and (max-width: 800px) {
    padding: 0 4px;
  }

  @media screen and (max-width: 480px) {
    display: none;
    padding: 0;
  }
`

export const Tooltip = styled.p`
  font-size: 0.75rem;
  position: relative;
  margin: 40px 0 28px 0;
  padding: 0 12px;

  @media screen and (max-width: 800px) {
    padding: 0 4px;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.875rem;
    padding: 0;
  }
`

export const Button = styled(OutlinedButton)`
  width: 100%;
  padding: 14px 0 11px;
  margin-top: 12px;

  @media screen and (max-width: 480px) {
    padding: 22px 0 20px;
    font-size: 0.875rem;
    margin-top: 32px;
  }
`

export const Link = styled(NavLink)`
  text-decoration: none;
`

export const InvestmentList = styled.ul`
  width: 100%;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 28px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`
