import React from "react"
import styled from "styled-components"

import { OrderTypeConsumer } from "src/context"

import { useAdapter } from "../../utils"

const Container = styled.div<any>`
  position: ${({ inlined }) => (inlined ? "static" : "absolute")};
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const EntityDate = (props) => {
  const { data, inlined } = props
  const adaptedDate = useAdapter(data)

  return adaptedDate !== undefined && adaptedDate !== null ? (
    <Container inlined={inlined}>{adaptedDate}</Container>
  ) : null
}

EntityDate.defaultProps = {
  inlined: false,
}

export default (props) => (
  <OrderTypeConsumer>
    {(orderType) => <EntityDate {...props} orderType={orderType} />}
  </OrderTypeConsumer>
)
