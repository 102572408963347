import Dead from "./Dead"
import Pending from "./Pending"
import Declined from "./Declined"
import Complete from "./Complete"
import Transfer from "./Transfer"
import Confirmed from "./Confirmed"

const render = () => null

render.dead = Dead
render.pending = Pending
render.complete = Complete
render.declined = Declined
render.transfer = Transfer
render.confirmed = Confirmed
render.complete = Complete

export default render
