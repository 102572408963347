import styled from "styled-components"

export const Container = styled.li`
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-top: 12px;
  }
`

export const Card = styled.div`
  background: #f8f8f8;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: auto;
  padding: 12px;
  font-family: Geometria;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleFlex = styled(Flex)`
  margin-bottom: 14px;
`

export const LeftText = styled.p`
  font-weight: 300;
  font-size: 0.875rem;
  color: rgba(74, 74, 74, 0.75);
  text-align: left;
  margin: 0;

  @media screen and (max-width: 800px) {
    font-size: 0.75rem;
  }
`

export const RightText = styled(LeftText)`
  text-align: right;
  font-weight: 600;
`

export const Name = styled(LeftText)`
  font-size: 1rem;
  line-height: 20px;
  font-weight: bold;
  color: #5f5f5f;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 75%;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`

export const Ticker = styled(LeftText)`
  line-height: 20px;
  text-align: right;
  font-size: 0.75rem;
  font-weight: normal;
`
