/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ProvisionKindEnum = "ESTATE" | "GUARANTOR" | "OTHER" | "VEHICLE" | "%future added value";
export type CreateExpressQuestionaryInput = {
    inn: string;
    name: string;
    phone: string;
    site?: string | null;
    presentation?: string | null;
    isBelongToGroup: boolean;
    groupInns: Array<string>;
    orderData: OrderData;
};
export type OrderData = {
    amount: string;
    term: string;
    interest: string;
    hasCollateral: boolean;
    collaterals: Array<ExpressQuestionaryCollateralInput>;
};
export type ExpressQuestionaryCollateralInput = {
    kind: ProvisionKindEnum;
    value: string;
};
export type CreateExpressQuestionaryMutationVariables = {
    input: CreateExpressQuestionaryInput;
};
export type CreateExpressQuestionaryMutationResponse = {
    readonly createExpressQuestionary: {
        readonly user: {
            readonly id: string;
            readonly questionaries: ReadonlyArray<{
                readonly id: string;
                readonly inn: string;
                readonly name: string;
                readonly phone: string;
                readonly site: string | null;
                readonly state: {
                    readonly status: ProfileAccreditationStatus;
                    readonly reason: string | null;
                };
                readonly presentation: {
                    readonly id: string;
                    readonly url: string;
                    readonly filename: string;
                } | null;
                readonly isBelongToGroup: boolean;
                readonly groupInns: ReadonlyArray<string>;
                readonly orderData: {
                    readonly amount: string;
                    readonly term: string;
                    readonly interest: string;
                    readonly hasCollateral: boolean;
                    readonly collaterals: ReadonlyArray<{
                        readonly kind: ProvisionKindEnum;
                        readonly value: string;
                    }>;
                };
            }>;
        };
    };
};
export type CreateExpressQuestionaryMutation = {
    readonly response: CreateExpressQuestionaryMutationResponse;
    readonly variables: CreateExpressQuestionaryMutationVariables;
};



/*
mutation CreateExpressQuestionaryMutation(
  $input: CreateExpressQuestionaryInput!
) {
  createExpressQuestionary(input: $input) {
    user {
      id
      questionaries {
        id
        inn
        name
        phone
        site
        state {
          status
          reason
        }
        presentation {
          id
          url
          filename
        }
        isBelongToGroup
        groupInns
        orderData {
          amount
          term
          interest
          hasCollateral
          collaterals {
            kind
            value
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateExpressQuestionaryInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateExpressQuestionaryPayload",
    "kind": "LinkedField",
    "name": "createExpressQuestionary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ExpressQuestionary",
            "kind": "LinkedField",
            "name": "questionaries",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "site",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpressQuestionaryState",
                "kind": "LinkedField",
                "name": "state",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "presentation",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isBelongToGroup",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groupInns",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExpressQuestionaryOrderData",
                "kind": "LinkedField",
                "name": "orderData",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "term",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "interest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCollateral",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExpressQuestionaryCollateral",
                    "kind": "LinkedField",
                    "name": "collaterals",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "kind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateExpressQuestionaryMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateExpressQuestionaryMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "CreateExpressQuestionaryMutation",
    "operationKind": "mutation",
    "text": "mutation CreateExpressQuestionaryMutation(\n  $input: CreateExpressQuestionaryInput!\n) {\n  createExpressQuestionary(input: $input) {\n    user {\n      id\n      questionaries {\n        id\n        inn\n        name\n        phone\n        site\n        state {\n          status\n          reason\n        }\n        presentation {\n          id\n          url\n          filename\n        }\n        isBelongToGroup\n        groupInns\n        orderData {\n          amount\n          term\n          interest\n          hasCollateral\n          collaterals {\n            kind\n            value\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '67c82bbd1ba1372132c87989c77b6505';
export default node;
