/* eslint-disable camelcase */

import React, { createRef, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import memoize from "memoize-one"
import { graphql } from "react-relay"

import { QueryRenderer, Icons, Translate } from "src/components"
import { SetViewerPassword } from "src/mutations"
import { MutationContainer } from "src/containers"
import { parseDate, pick } from "src/utils"

import { useAttributes } from "../../organisms/Forms/Password/ChangePasswordModal/hooks"

import {
  Modal,
  Container,
  CloseButton,
  ReturnButton,
  Title,
  Button,
  Description,
  SuccessContainer,
  SuccessCloseButton,
  SuccessIcon,
  SuccessTitle,
} from "./styles"
import Inputs from "./Inputs"

export const matchError = {
  keyword: "match",
  path: "setViewerPassword.retry_password",
  type: "match",
}

export const requiredError = (type) => ({
  keyword: "required",
  path: `setViewerPassword.${type}`,
  type: "required",
})

const clearErrorFromEvent = memoize((clearError) => (event) => clearError(`setViewerPassword.${event.target.name}`))

const getManualError = (attributes) => {
  const { password, retry_password } = attributes.value

  if (!password) return requiredError`password`
  if (password !== retry_password) return matchError

  return null
}

const initialAttributes = {
  password: "",
  retry_password: "",
  current_password: "",
}

function NewPasswordModal(props) {
  const {
    onClose, viewer, getError, clearError,
  } = props

  const attributes = useAttributes(initialAttributes)
  const [busy, setBusy] = useState(false)
  const [isSuccess, setSuccess] = useState(false)

  const buttonIsDisabled = !attributes.value.password
    || !attributes.value.current_password
    || !attributes.value.retry_password
    || busy

  const modalRef = createRef<HTMLDivElement>()
  const overlayRef = createRef<HTMLDivElement>()

  function handleClose() {
    if (!busy) {
      enableBodyScroll(modalRef.current)
      onClose()
    }
  }

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      handleClose()
    }
  }

  useEffect(() => {
    disableBodyScroll(modalRef.current)

    return () => enableBodyScroll(modalRef.current)
  }, [])

  function onCompleted() {
    setBusy(false)
    setSuccess(true)
  }

  function onError() {
    setBusy(false)
  }

  function handleSubmit(event) {
    event.preventDefault()

    if (busy) {
      return
    }

    const { commit, setManualError } = props

    const error = getManualError(attributes)
    if (error) {
      setManualError(error)
      return
    }

    setBusy(true)

    const input = pick(attributes.value, ["password", "current_password"])
    const variables = { input }

    const callbacks = {
      onError,
      onCompleted,
    }

    commit({ callbacks, variables })
  }

  return (
    <Modal ref={overlayRef} onClick={onOverlayClick}>
      {!isSuccess && (
        <Container ref={modalRef}>
          <ReturnButton onClick={handleClose}>
            <Icons.LeftArrow />
            <span>Назад</span>
          </ReturnButton>

          <CloseButton onClick={handleClose}>
            <Icons.Close />
          </CloseButton>

          <Title>Сменить пароль</Title>

          <form onSubmit={handleSubmit}>
            <Inputs
              viewer={viewer}
              attributes={attributes}
              getError={getError}
              clearError={clearErrorFromEvent(clearError)}
            />

            <Button disabled={buttonIsDisabled}>Сохранить новый пароль</Button>
          </form>

          <Description>
            <Translate
              i18n="components:account.password_update_form.last_update"
              date={parseDate(viewer.password.updated_at)}
            />
          </Description>
        </Container>
      )}

      {isSuccess && (
        <SuccessContainer>
          <SuccessCloseButton onClick={handleClose}>
            <Icons.Close />
          </SuccessCloseButton>

          <SuccessIcon />
          <SuccessTitle>Пароль изменён</SuccessTitle>
        </SuccessContainer>
      )}
    </Modal>
  )
}

function ModalWithMutation(props) {
  return (
    <MutationContainer mutation={SetViewerPassword}>
      {({
        commit, getError, clearError, setManualError,
      }) => (
        <NewPasswordModal
          {...props}
          commit={commit}
          getError={getError}
          clearError={clearError}
          setManualError={setManualError}
        />
      )}
    </MutationContainer>
  )
}

function Render(props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")
  return isOpened ? ReactDOM.createPortal(ModalWithMutation(props), root) : null
}

const query = graphql`
  query NewPasswordModalQuery {
    viewer {
      id
      password {
        present
        updated_at
      }
    }
  }
`

export default function (props) {
  if (!props.isOpened) {
    return null
  }

  return <QueryRenderer {...props} query={query} render={Render} />
}
