import styled from "styled-components"

import { Box, Text } from "src/components"

export const PreLined = styled(Box)`
  white-space: pre-line;
`

export const HeaderWrapper = styled(PreLined)`
  padding: 0 22px;
  padding-bottom: 5px;
`

export const Bold = styled(Text)`
  display: inline-block;
  font-weight: 600;
  font-size: 12px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
`

export const Container = styled(Box)`
  line-height: 16px;
`

export const TextBlockWrapper = styled(Box)`
  padding: 0 22px;
`

export const StyledBox = styled(Box)`
  padding-top: 18px;
  text-align: left !important;
`
