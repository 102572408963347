import { pipe, getProperty } from "src/utils"

const validateDocuments = ({ data, errors }) => {
  const docs = getProperty(data, "documents[0].attachments")
  if (Array.isArray(docs) && docs.length > 2) {
    const error = { dataPath: "/documents", keyword: "maxLength" }
    return { data, errors: [...errors, error] }
  }

  return { data, errors }
}

const validateTranslatedDocuments = ({ data, errors }) => {
  if (!(data.translatedDocuments && data.translatedDocuments.length)) {
    const error = { dataPath: "/translatedDocuments", keyword: "presence" }
    return { data, errors: [...errors, error] }
  }

  return { data, errors }
}

const validateResidenceDocument = ({ data, errors }) => {
  if (
    data.isRussiaTaxResident
    && !(data.residenceDocument && data.residenceDocument.length)
  ) {
    const error = { dataPath: "/residenceDocument", keyword: "presence" }
    return { data, errors: [...errors, error] }
  }

  return { data, errors }
}

const validateTaxResidencyApplication = ({ data, errors }) => {
  if (
    data.isRussiaTaxResident
    && !(data.taxResidencyApplication && data.taxResidencyApplication.length)
  ) {
    const error = { dataPath: "/taxResidencyApplication", keyword: "presence" }
    return { data, errors: [...errors, error] }
  }

  return { data, errors }
}

const validateTerritoryResidenceDocument = ({ data, errors }) => {
  const doc = data.territoryResidenceDocument

  const newErrors = [
    !doc?.type && {
      dataPath: "/territoryResidenceDocument/type",
      keyword: "presence",
    },
    !doc?.number && {
      dataPath: "/territoryResidenceDocument/number",
      keyword: "presence",
    },
    !doc?.dateFrom && {
      dataPath: "/territoryResidenceDocument/dateFrom",
      keyword: "presence",
    },
    !(doc?.attachments && doc.attachments?.length) && {
      dataPath: "/territoryResidenceDocument/attachments",
      keyword: "presence",
    },
  ].filter(Boolean)

  return { data, errors: [...errors, ...newErrors] }
}

const validateMigrationCard = ({ data, errors }) => {
  const doc = data.migrationCard

  const newErrors = [
    !doc?.number && { dataPath: "/migrationCard/number", keyword: "presence" },
    !doc?.dateFrom && {
      dataPath: "/migrationCard/dateFrom",
      keyword: "presence",
    },
    !(doc?.attachments && doc.attachments?.length) && {
      dataPath: "/migrationCard/attachments",
      keyword: "presence",
    },
  ].filter(Boolean)

  return { data, errors: [...errors, ...newErrors] }
}

const validateSelfie = ({ data, errors }) => {
  if (!data.selfie || !data.selfie.length) {
    const error = { dataPath: "/selfie", keyword: "attachment" }
    return { data, errors: [...errors, error] }
  }

  return { data, errors }
}

const validateMinLength = (descriptors) => ({ data, errors }) => {
  if (!Array.isArray(descriptors)) return { data, errors }

  const minLengthErrors = descriptors.reduce((memo, descriptor) => {
    const value = String(getProperty(data, descriptor.accessor))
    if (!value.length) {
      const error = { dataPath: descriptor.dataPath, keyword: "minLength" }
      return [...memo, error]
    }

    return memo
  }, [])

  return { data, errors: [...errors, ...minLengthErrors] }
}

const formatErrors = (mutationName) => ({ errors }) => {
  if (!errors.length) return null

  return {
    errors: [
      {
        path: [mutationName],
        extensions: {
          validations: [...errors],
        },
      },
    ],
  }
}

const promisifyValidationResult = (error) => (error ? Promise.reject(error) : Promise.resolve())

const minLengthDescriptors = [
  {
    dataPath: "/firstName",
    accessor: "firstName",
  },
  {
    dataPath: "/lastName",
    accessor: "lastName",
  },
  {
    dataPath: "/phone",
    accessor: "phone",
  },
  {
    dataPath: "/tin",
    accessor: "tin",
  },
]

const createValidator = (mutationName, shouldSkipValidation = false) => (
  data,
) => {
  if (shouldSkipValidation) return Promise.resolve()

  const executeValidation = pipe(
    validateSelfie,
    validateDocuments,
    validateMigrationCard,
    validateResidenceDocument,
    validateTranslatedDocuments,
    validateTaxResidencyApplication,
    validateTerritoryResidenceDocument,
    validateMinLength(minLengthDescriptors),
  )

  const resolveValidation = pipe(
    executeValidation,
    formatErrors(mutationName),
    promisifyValidationResult,
  )

  return resolveValidation({
    data,
    errors: [],
  })
}

export default createValidator
