import styled from "styled-components"

export const CryingDude = styled.div`
  width: 300px;
  height: 140px;
  background-image: url(/images/cryingDude.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`

export const MailLink = styled.a`
  color: ${({ theme }) => theme.colors.greyDark};
  font-size: 16px;
`

export const ReasonContainer = styled.div`
  padding: 16px 0 32px;
  position: relative;

  &:before {
    content: "";
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 35%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: rotate(180deg);
    z-index: 100;
    position: absolute;
    width: 100%;
    height: 70px;
    bottom: 30px;
  }
`

export const List = styled.ul`
  word-break: break-all;
  max-height: 130px;
  padding: 0;
  padding-bottom: 40px;
  overflow-y: auto;
  list-style: none;
  position: relative;

  li {
    white-space: pre-line;
    line-height: 200%;
    font-weight: 600;
    word-break: break-word;
  }

  li:last-child {
    margin-bottom: 30px;
  }
`
