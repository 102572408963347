import styled, { createGlobalStyle } from "styled-components"

export const Container = styled.div`
  position: relative;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const StatusbarContainer = styled.div<any>`
  position: relative;
  margin-top: 10px;
  width: 350px;
  height: 10px;
  position: relative;
  ${(props) => `${props.value}%` || 0};
  flex-shrink: 0;

  @media screen and (max-width: 1100px) {
    width: auto;
    min-width: 200px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 15px;
    margin-top: 14px;
  }
`
export const StatusBarFilled = styled.div<any>`
  height: 100%;
  background-color: #6fa84b;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
`
export const StatusBarEmpty = styled.div`
  background-color: #e6eaee;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

export const StatusBarMinIndicator = styled.div<any>`
  height: 14px;
  z-index: 4;
  width: 3px;
  position: absolute;
  top: 50%;
  border-radius: 4px;
  transform: translateY(-50%);
  left: ${({ gathered }) => `${gathered}%`};
  background-color: ${({ theme }) => theme.colors.fullBlacks[7]};

  @media screen and (max-width: 480px) {
    height: 20px;
  }
`

export const TitileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const TitileLeft = styled.div`
  text-align: left;
`

export const TitileRight = styled.div`
  text-align: right;
`

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Geometria;
  font-size: 26px;
  line-height: 33px;
  color: #4a4a4a;
  font-weight: bold;
  margin-top: 8px;

  @media screen and (max-width: 1100px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26px;
    line-height: 33px;
    margin-top: 2px;
  }
`

export const ValueLeft = styled.div`
  text-align: left;
`

export const ValueRight = styled.div`
  text-align: right;
`

export const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBlack};
  border-radius: 4px;
  padding: 8px;
  width: 350px;

  @media screen and (max-width: 800px) {
    padding-right: 28px;
    width: 195px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`

export const TooltipText = styled.p`
  margin: 0;
  font-family: Geometria, sans-serif;
  font-size: 10px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 16px;
  }
`

export const TooltipCloseBtn = styled.button`
  position: absolute;
  border: none;
  background-color: inherit;
  right: 8px;
  padding: 0;
  display: none;
  width: 12px;
  height: 12px;

  svg {
    path {
      fill: white;
    }
  }

  @media screen and (max-width: 800px) {
    display: block;
  }
`

export const GlobalStyle = createGlobalStyle`
  .rc-tooltip-inner {
    background-color: ${({ theme }: any) => theme.colors.primaryBlack};
    box-shadow: none;
    padding: 0;
    min-height: auto;
    border-radius: 4px;
  }
  
  .rc-tooltip-arrow {
  ${({ placement, percent }: any) => (placement === "bottomLeft"
    ? `left: ${percent}% !important`
    : `right: ${percent}% !important`)}
  }
`
