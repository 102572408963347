import React from "react"

import { Box, Text, Heading as HeadingEl } from "src/components"

export const InfoText = (props) => (
  <Text
    color="greyDarkest"
    fontSize={16}
    textAlign="left"
    lineHeight={1.5}
    {...props}
  >
    {props.children}
  </Text>
)

export const TextBox = (props) => (
  <Box mb={20} {...props}>
    {props.children}
  </Box>
)

export const Hero = (props) => (
  <Box mb={20}>
    <HeadingEl.h2 {...props}>{props.children}</HeadingEl.h2>
  </Box>
)

export const Heading = (props) => (
  <Box mb={20}>
    <HeadingEl.h2 fontSize={28} {...props}>
      {props.children}
    </HeadingEl.h2>
  </Box>
)

export const SubHeading = (props) => (
  <HeadingEl.h4 fontSize={16} fontWeight="700" {...props}>
    {props.children}
  </HeadingEl.h4>
)
