import is from "is_js"

import {
  findPaymentListByProfile, parseInvestmentsData, parseOrder, setDataFromCession,
} from "src/utils"
import { formatDateShort } from "src/utils/date"

const monthArray = [
  "янв",
  "фев",
  "мар",
  "апр",
  "май",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
]

export function parseInvestments(data) {
  if (!is.existy(data)) {
    return []
  }

  const array = parseInvestmentsData(data)

  return array.map(({ order, ...rest }) => setDataFromCession(parseOrder({ ...order, ...rest })))
}

export function parseOrders(data) {
  if (!is.existy(data)) {
    return []
  }

  const { edges } = data.orders

  return edges.map((edge) => setDataFromCession(parseOrder(edge.node)))
}

export function getPaidItems(data) {
  const schedule = data?.paymentScheduleList?.[0]?.items
  if (!schedule) {
    return []
  }

  return schedule.filter((item) => item.state === "paid")
}

export function getPaidInterest(data) {
  const items = getPaidItems(data)
  const totalInterest = items.reduce((total, item) => total + item.interest, 0)
  return totalInterest
}

export function getPaidLoan(data) {
  const items = getPaidItems(data)
  const totalLoan = items.reduce((total, item) => total + item.loan, 0)
  return totalLoan
}

export function getOrderDebt(data) {
  const paidLoan = getPaidLoan(data)
  const payments = data?.paymentScheduleList?.[0]?.items

  if (!payments?.length) {
    return 0
  }

  const totalLoan = payments.reduce((total, item) => total + item.loan, 0)
  return (totalLoan - paidLoan)
}

export function getDateNextPayment(data) {
  const nextItem = data?.paymentScheduleList?.[0]?.items?.find(
    (item) => item.state === "new",
  )
  if (!nextItem) {
    return null
  }

  const date = new Date(nextItem.date)
  const day = date.getDate()
  const month = monthArray[date.getMonth()]

  return `${day} ${month}`
}

export function divideNumber(num) {
  return String(num)
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
    .replace(".", ",")
}

export function getOrderNumber(data) {
  if (!data.chain) {
    return "_"
  }

  const number = data.chain.id
  const numberView = number.split(".")[2]
  const { ticker } = data.profile.borrower

  return `${ticker || ""}${numberView || ""}`
}

export function getStatusbarValue(data) {
  if (!data.chain) {
    return 0
  }

  const { minValue: min, maxValue: max } = data.application.data
  const amount = data.chain.gatheredAmount
  const goal = amount > min ? max : min

  return (amount / goal) * 100 > 100 ? 100 : (amount / goal) * 100
}

export function getOrderName(data) {
  if (!data.application) {
    return "_"
  }

  return data.application.data.companyName || "_"
}

export function getOrderRate(data) {
  if (!data.offer) {
    return "_"
  }

  return `${data.offer.data.interestRate} %`
}

export function getOrderDuration(data) {
  if (!data.offer) {
    return null
  }

  return data.offer.data.duration
}

export function getOrderSum(data) {
  if (!data.application) {
    return "0"
  }

  const { minValue: min, maxValue: max } = data.application.data
  const minView = Math.floor((min / 1000000) * 100) / 100
  const maxView = Math.floor((max / 1000000) * 100) / 100

  return `${minView}М - ${maxView}М`
}

export function getOrderAmount(data) {
  if (!data.chain) {
    return "_"
  }

  const amount = data.chain.gatheredAmount
  const { minValue: min, maxValue: max } = data.application.data
  const goal = amount > min ? max : min
  const goalView = Math.floor((goal / 1000000) * 100) / 100
  const amountView = Math.floor((amount / 1000000) * 100) / 100

  return `${amountView}М из ${goalView}М`
}

export function getOrdernFinishDate(data) {
  // partial duplicate
  // src/components/organisms/OrderFactory/entities/helpers/mobileOrder.js
  const { paymentScheduleList } = data

  if (paymentScheduleList.length < 1 || !paymentScheduleList[0]) return 0

  const lastIndex = paymentScheduleList[0]?.items?.length - 1
  const payment = paymentScheduleList[0]?.items?.[lastIndex]

  if (!payment) return 0

  return formatDateShort(payment.date)
}

export function getOrderDate(data, type = "mini") {
  if (!data?.offer?.data) {
    return "-"
  }

  const { term } = data.offer.data
  const { confirmedAt } = data

  const dateParse = new Date(confirmedAt)
  const newDate = new Date(
    dateParse.setDate(dateParse.getDate() + Number(term)),
  )
  const dayEnd = newDate.getDate()
  const yearEnd = newDate.getFullYear()
  const month = monthArray[newDate.getMonth()]

  if (type === "full") {
    return `${dayEnd} ${month} ${yearEnd}`
  }

  return `${dayEnd} ${month}`
}

export function getOrderSuccedDate(data) {
  const date = new Date(data.succeededAt)
  const dayEnd = date.getDate()
  const yearEnd = date.getFullYear()
  const month = monthArray[date.getMonth()]

  return `${dayEnd} ${month} ${yearEnd}`
}

export function getPaymentAmount(data, profileId, type = "total") {
  const { paymentScheduleList = [] } = data

  if (paymentScheduleList.length < 1) return 0

  const paymentSchedule = findPaymentListByProfile(profileId, paymentScheduleList) || {}

  const paymentAmount = paymentSchedule?.items?.reduce((total, item) => {
    if (item.state === "paid") {
      return total + item[type]
    }

    return total
  }, 0)

  if (!paymentAmount) return 0

  return paymentAmount
}

export function getNotPaidAmount(data, profileId, type = "total") {
  const { paymentScheduleList = [] } = data

  if (paymentScheduleList.length < 1) return 0

  const paymentSchedule = findPaymentListByProfile(profileId, paymentScheduleList) || {}

  const paymentAmount = paymentSchedule?.items?.reduce((total, item) => {
    if (item.state !== "paid") {
      return total + item[type]
    }

    return total
  }, 0)

  if (!paymentAmount) return 0

  return paymentAmount
}

export function getPaymentDate(date, payDate = null) {
  const parsedDate = payDate || date
  const [year, month, day] = parsedDate.split("-")
  return `${day}.${month}.${year}`
}

function sortPayments(a, b) {
  const date1 = new Date(a.item.date).getTime()
  const date2 = new Date(b.item.date).getTime()

  if (date1 > date2) {
    return 1
  }

  if (date1 < date2) {
    return -1
  }

  return 0
}

function filterPayments(data) {
  const { state } = data.item

  return state !== "paid"
}

function parseOrderItems(profileId, data) {
  const { order, ...restData } = data
  const {
    paymentScheduleList, application, id, ...restOrder
  } = order

  if (!paymentScheduleList) {
    return []
  }

  const paymentSchedule = findPaymentListByProfile(
    profileId,
    paymentScheduleList,
  )
  if (!paymentSchedule) {
    return []
  }

  const { items, ...restPayments } = paymentSchedule

  let parsedApplication = null

  if (is.existy(application)) {
    const { data: applicationData, ...applicationRest } = application
    const parsedApplicationData = JSON.parse(applicationData)

    parsedApplication = { data: parsedApplicationData, ...applicationRest }
  }

  let totalDebt = items.reduce((total, item) => total + item.loan, 0)

  return items.map((item) => {
    const debt = totalDebt - item.loan
    totalDebt -= item.loan

    return {
      orderId: id,
      item: {
        ...item,
        debt,
      },
      application: parsedApplication,
      ...restPayments,
      ...restOrder,
      ...restData,
    }
  })
}

const separatePaymentsByStatus = (items) => {
  const pastduePayments = []
  const payments = []

  items.forEach((payment) => {
    if (payment.item.state === "pastdue") {
      pastduePayments.push(payment)
    } else {
      payments.push(payment)
    }
  })

  payments.sort(sortPayments)
  pastduePayments.sort(sortPayments)

  return [payments, pastduePayments]
}

export function parsePaymentsData(profileId, data, type?: string) {
  const orders = data.map((order) => {
    const node = type === "loan" ? { order: order.node } : order.node
    return parseOrderItems(profileId, node)
  }) || [[]]

  const newOrders = Array.isArray(orders) ? orders.flat() : orders
  const filteredOrders = newOrders.filter(filterPayments)

  return separatePaymentsByStatus(filteredOrders)
}
