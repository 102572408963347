import React from "react"

import { ErrorsConsumer, PurposedMutationConsumer } from "src/context"

import { InputsDataConsumer } from "../../context"

const ComposedConsumer = (props) => (
  <PurposedMutationConsumer>
    {(purposedMutations) => (
      <ErrorsConsumer>
        {(errors) => (
          <InputsDataConsumer>
            {({ type, value, onChange }: any) => props.children({
              ...errors,
              type,
              value,
              onChange,
              purposedMutations,
            })
            }
          </InputsDataConsumer>
        )}
      </ErrorsConsumer>
    )}
  </PurposedMutationConsumer>
)

export default ComposedConsumer
