import React from "react"
import memoize from "memoize-one"

import { Exhibition } from "src/components"

import {
  Action,
  MiscData,
  Controls,
  InvestData,
  EntityDate,
  Description,
  ProfileLogo,
} from "../Elements"

import { HeaderContainer, FooterContainer } from "./styles"

const Head = memoize(({ data, injected }) => (
  <HeaderContainer>
    <ProfileLogo data={data} source="investments" />
    <MiscData data={data} source="investments" />
    <EntityDate data={data} />
    {!!(injected && injected.amount) && <InvestData.investment />}
    <Controls.investment data={data} status={data.status} />
  </HeaderContainer>
))

const Body = memoize(({ data }) => <Exhibition data={data} />)

const Footer = memoize(({ data }) => (
  <FooterContainer>
    <Description data={data} cropped={true} />
    <Action data={data} source="investments" />
  </FooterContainer>
))

export default {
  Head,
  Body,
  Footer,
}
