import React from "react"
import onClickOutside from "react-onclickoutside"

import {
  Icon, Text, Translate, IconClose,
} from "src/components"

import { getSelectedValue } from "src/utils"

import {
  Option,
  InputWrapper,
  SelectWrapper,
  IconContainer,
  SelectedValue,
  DecoratedInput,
  OptionsContainer,
  CloseIconWrapper,
} from "./styles"

import Redesigned from "./Redesigned"
import LandingSelect from "./Landing"

const SelectInput = (props) => <DecoratedInput {...props} />

class InputComponent extends React.PureComponent<any, any> {
  state = {
    isOpen: false,
    value: getSelectedValue(this.props.options, this.props.value),
    autoComplete: false,
  }

  onKeyDown = (event) => {
    const keyCode = event.which
    if (!this.state.autoComplete) {
      if (event) {
        event.preventDefault()
      }
    }

    if (keyCode === 8) {
      (this as any).resetValue()
    }
    // TODO: add keyboard navigation ~ Dmitry Danev
  }

  resetValue = (event) => {
    if (event) {
      event.preventDefault()
    }

    const value = {
      option: "",
      label: "",
    }

    this.setState(
      () => ({ value }),
      () => {
        this.props.onChange({
          name: this.props.name,
          value: value.option,
        })
      },
    )
  }

  onFocus = () => {
    this.setState(() => ({ isOpen: true }))
  }

  onChange = (event) => {
    if (!this.props.autoComplete) {
      if (event) {
        event.preventDefault()
      }

      return
    }

    const { value } = event.target
    this.setState(() => ({ value }), this.filter)
  }

  onItemClick = (value) => (event) => {
    if (event) {
      event.preventDefault()
    }
    this.setState(
      () => ({ value, isOpen: false }),
      () => {
        // :TODO refactor this mess ~ Dmitry Danev
        this.props.onChange({
          name: this.props.name,
          value: this.state.value.value,
        })
      },
    )
    // TODO: add multiply selection ~ Dmitry Danev
  }

  handleClickOutside = () => {
    this.setState(() => ({ isOpen: false }))
  }

  toggleSelectState = () => {
    this.setState(() => ({ isOpen: !this.state.isOpen }))
  }

  onOptionClick = () => {
    this.setState(() => ({ isOpen: true }))
  }

  filter = () => {
    if (!this.props.autoComplete) {
      return
    }

    const options = [...this.props.options]
    const newOptions = options.filter(({ label }) => this.isMatch(label))

    this.setState(() => ({ options: newOptions }))
  }

  isMatch = (label) => label.substring(0, this.state.value.length) === this.state.value

  render() {
    return (
      <SelectWrapper width={this.props.width}>
        <IconContainer>
          {this.state.value.label.length ? (
            <CloseIconWrapper onClick={this.resetValue}>
              <IconClose />
            </CloseIconWrapper>
          ) : null}
          <Icon onClick={this.toggleSelectState}>
            {this.state.isOpen ? "expand-less" : "expand-more"}
          </Icon>
        </IconContainer>
        <InputWrapper>
          <SelectInput
            isOpen={this.state.isOpen}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            autoCorrect="off"
            spellCheck="false"
            hint={this.props.hint}
          />
          <SelectedValue onClick={this.onOptionClick}>
            {typeof this.state.value.label === "string" ? (
              <Translate i18n={this.state.value.label} ns="components" />
            ) : (
              this.state.value.label
            )}
          </SelectedValue>
        </InputWrapper>
        {this.state.isOpen && (
          <OptionsContainer>
            {this.props.options.map((option) => (
              <Option key={option.value} onClick={this.onItemClick(option)}>
                <Text>
                  {typeof option.label === "string" ? (
                    <Translate i18n={option.label} ns="components" />
                  ) : (
                    option.label
                  )}
                </Text>
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SelectWrapper>
    )
  }
}

(InputComponent as any).defaultProps = {
  value: {
    option: "",
    label: "",
  },
  multiply: false,
  autoComplete: false,
  width: "90%",
}

const Select = onClickOutside(InputComponent)

Select.redesigned = Redesigned
Select.landing = LandingSelect

export default Select
