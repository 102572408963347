import React from "react"
import Relay, { graphql } from "react-relay"

export const ViewerData: any = React.createContext({})

const ViewerDataProvider = ViewerData.Provider

const isMaster = (masterProfileId, viewerProfilesIds) =>
  viewerProfilesIds.length > 0 && viewerProfilesIds.includes(masterProfileId)

const isInvestor = (viewer) => {
  if (viewer) {
    return !!viewer.investedAmount && viewer.investedAmount > 0
  }

  return false
}

const adaptViewerProfiles = (injectedViewer) => {
  if (injectedViewer) {
    const { profiles } = injectedViewer
    return profiles.map(({ id }) => id)
  }

  return []
}

const getViewerToOrderRelation = (injectedViewer) => (order) => {
  const {
    viewer,
    profile: { id: masterProfileId },
  } = order

  const adaptedViewerProfiles = adaptViewerProfiles(injectedViewer)
  const Master = isMaster(masterProfileId, adaptedViewerProfiles)
  const Investor = isInvestor(viewer)
  const Guest = !Master && !Investor

  return {
    GUEST: Guest,
    MASTER: Master,
    INVESTOR: Investor,
  }
}

const render = (props) => (
  <ViewerDataProvider value={props.viewer}>{props.children}</ViewerDataProvider>
)

export const ViewerDataConsumer = ViewerData.Consumer
export const ViewerDataFragmentProvider = Relay.createFragmentContainer(
  render,
  {
    viewer: graphql`
      fragment ViewerData_viewer on User {
        id
        email
        locale
        country
        currency
        createdAt
        profiles {
          __typename
          investor {
            canBeInvestor
          }
          approvedAsInvestor
          ... on LegalEntityProfile {
            id
            name
            phone
            accountBalance
            accountBalanceDetailed {
              available
              currency
            }
            accreditation(role: BORROWER) {
              status
            }
          }
          ... on IndividualProfile {
            id
            name
            phone
            accountBalance
            accountBalanceDetailed {
              available
              currency
            }
            bankAccounts {
              id
              status
            }
            accreditation(role: INVESTOR) {
              status
            }
            investor {
              investedAmount
              qualified
            }
            supportingDocuments {
              id
              status
            }
          }
          ... on ForeignIndividualProfile {
            id
            __typename
            name
            foreignPhone: phone
            accountBalanceDetailed {
              available
              currency
            }
            bankAccounts {
              id
              status
            }
            accreditation(role: INVESTOR) {
              status
            }
          }
          ... on EntrepreneurProfile {
            id
            name
            phone
            accountBalance
            accountBalanceDetailed {
              available
              currency
            }
            bankAccounts {
              id
              status
            }
            accreditation(role: INVESTOR) {
              status
            }
          }
        }
        foreignProfiles {
          id
          __typename
          firstName
          lastName
          phone
          investor {
            canBeInvestor
          }
          approvedAsInvestor
          documents {
            type
            attachments {
              id
            }
          }
          selfie {
            id
          }
          accountBalanceDetailed {
            available
            currency
          }
          accreditation(role: INVESTOR) {
            status
          }
        }
      }
    `,
  },
)

const ViewerToOrderRelationContainer = (props) => (
  <ViewerDataConsumer>
    {(viewer) => props.children({
      getViewerToOrderRelation: getViewerToOrderRelation(viewer),
      viewer,
    })
    }
  </ViewerDataConsumer>
)

ViewerData.consumer = ViewerDataConsumer
ViewerData.renderPropContainer = ViewerToOrderRelationContainer
