export const toCurrencyFormat = (
  num?: number,
  minimumFractionDigits = 2,
): string => {
  if (typeof num !== "number") return ""

  return num.toLocaleString("ru-RU", {
    minimumFractionDigits,
  })
}

export const toRubleFormat = (
  num?: number,
  minimumFractionDigits = 2,
): string => {
  if (typeof num !== "number") return ""

  return num.toLocaleString("ru-RU", {
    style: "currency",
    currency: "RUB",
    minimumFractionDigits,
  })
}
