import React from "react"
import { createPaginationContainer, graphql } from "react-relay"

import { QueryRenderer, Loader } from "src/components"

import List from "./List"
import { QueryLoaderContainer } from "./styles"

const query = graphql`
  query EntrepreneurPaginationContainerQuery(
    $first: Int!
    $after: Cursor
    $profileId: ID!
  ) {
    ...EntrepreneurPaginationContainer_root
      @arguments(first: $first, after: $after, profileId: $profileId)
  }
`

const PaginatedList = createPaginationContainer(
  (props: any) => {
    const { root, relay } = props
    const { node } = root

    return <List data={node} relay={relay} />
  },
  {
    root: graphql`
      fragment EntrepreneurPaginationContainer_root on Query
        @argumentDefinitions(
          first: { type: "Int", defaultValue: 10 }
          after: { type: "Cursor" }
          profileId: { type: "ID!" }
        ) {
        node(id: $profileId) {
          id
          __typename
          ... on EntrepreneurProfile {
            transactions(first: $first, after: $after)
              @connection(key: "TransactionHistory_transactions") {
              edges {
                node {
                  id
                  ...TransactionItem_transaction
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    getConnectionFromProps: ({ root }) => root && root.node.transactions,
    getFragmentVariables: (prevVars) => ({
      ...prevVars,
      first: 10,
    }),
    getVariables: (props, { cursor }, { profileId }) => ({
      first: 10,
      after: cursor,
      profileId,
    }),
    query,
  },
)

function EntrepreneurPaginationContainer(props) {
  const { profileId } = props

  return (
    <QueryRenderer
      {...props}
      query={query}
      render={(queryProps) => <PaginatedList root={queryProps} />}
      renderNull={() => (
        <QueryLoaderContainer>
          <Loader />
        </QueryLoaderContainer>
      )}
      variables={{
        first: 10,
        profileId,
      }}
    />
  )
}

export default EntrepreneurPaginationContainer
