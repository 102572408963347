import React from "react"
import { IconClose } from "src/components"

import {
  ModalBody,
  Scrollable,
  Background,
  CloseButton,
  ShevroneIcon,
  BackButton,
} from "./styles"

class ProposalModal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    (this as any).overlayRef = React.createRef()
  }

  componentDidMount() {
    const body = document.querySelector("body")
    body.style.overflow = "hidden"
    document.addEventListener("keyup", this.escListener)
  }

  componentWillUnmount() {
    const body = document.querySelector("body")
    body.style.overflow = "visible"
    document.removeEventListener("keyup", this.escListener)
  }

  onOverlayClick = (event) => {
    event.stopPropagation()
    if (
      event.target === (this as any).overlayRef.current
      && typeof this.props.onClose === "function"
    ) {
      this.props.onClose(event)
    }
  }

  escListener = (e) => {
    const event = e || window.event

    let isEscape = false
    if (Object.prototype.hasOwnProperty.call(event, "key")) {
      isEscape = event.key === "Escape" || event.key === "Esc"
    } else {
      isEscape = event.keyCode === 27 || event.which === 27
    }

    if (isEscape) {
      this.props.onClose()
    }
  }

  render() {
    const {
      height,
      width,
      type,
      padding,
      maxHeight,
      heightRestriction,
      backgroundColor,
      onClose,
      closeButtonVariant,
      textAlign,
    } = this.props
    return (
      <Background ref={(this as any).overlayRef} onClick={this.onOverlayClick}>
        <ModalBody
          height={height}
          width={width}
          type={type}
          padding={padding}
          maxHeight={maxHeight}
          heightRestriction={heightRestriction}
          bg={backgroundColor}
        >
          <CloseButton onClick={onClose} variant={closeButtonVariant}>
            <IconClose />
          </CloseButton>

          <BackButton onClick={onClose}>
            <ShevroneIcon />
            Назад
          </BackButton>

          <Scrollable textAlign={textAlign}>{this.props.children}</Scrollable>
        </ModalBody>
      </Background>
    )
  }
}

(ProposalModal as any).defaultProps = {
  type: "default",
  maxHeight: "65%",
  width: "auto",
  heightRestriction: true,
  padding: "60px 70px 40px 80px",
  closeButtonVariant: "withShadow",
}

export default ProposalModal
