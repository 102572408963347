import styled from "styled-components"
import {
  top, right, bottom, left,
} from "styled-system"

import { Text, Box, Flex } from "src/components"

export const Container = styled(Box)`
  width: 280px;
  padding: 25px 25px 20px 25px;
  border-radius: 4px;
  background: linear-gradient(45deg, #e3251b, #e2251c);
`

export const StyledText = styled(Text)`
  color: white;
  opacity: ${({ shaded }) => (shaded ? 0.8 : 1)};
`

export const ColoredFlex = styled(Flex)`
  background-color: #e53f37;
`

export const ColoredBox = styled(Box)`
  background-color: #e53f37;
`

export const IconContainer = styled.div`
  position: relative;
  width: 46px;
  height: 40px;
`

export const AbsoluteContainer = styled.div<any>`
  position: absolute;
  height: 17px;
  width: 17px;
  ${top}
  ${right}
  ${bottom}
  ${left}
`
