import React from "react"

import { isInvestor } from "src/utils"
import i18n from "src/i18n"
import { Box, Modal, RedirectButton } from "src/components"

const render = (props) => {
  const investorProfiles = props.user.profiles.filter(isInvestor)
  const link = investorProfiles.length > 1
    ? "/accounts"
    : `/accounts/${investorProfiles[0].id}/accreditation`

  return (
    <Modal.Basic onClose={props.onClose}>
      <Box mb={20}>
        {i18n.t(
          "components:user-page-header.modals.approve-individual-profile.description",
        )}
      </Box>
      <RedirectButton to={link} onClick={props.onClose}>
        {i18n.t(
          "components:user-page-header.modals.approve-individual-profile.button",
        )}
      </RedirectButton>
    </Modal.Basic>
  )
}

export default render
