import React from "react"
import i18next from "i18next"

import ItemTitle from "./ItemTitle"
import ItemVideos from "./ItemVideos"
import ItemImages from "./ItemImages"
import ItemDocuments from "./ItemDocuments"
import ItemDescription from "./ItemDescription"

const ProvisionItem = ({ item, overallValue, overallCoverage }) => {
  const {
    name, kind, value, videos, images, documents, description,
  } = item

  const nameByKind = name || i18next.t(`components:account.order_item.provision.types.${kind}`)

  return (
    <>
      <ItemTitle kind={kind} name={nameByKind} />
      <ItemDescription
        kind={kind}
        value={value}
        name={nameByKind}
        description={description}
        overallValue={overallValue}
        overallCoverage={overallCoverage}
      />
      <ItemDocuments docs={documents} />
      <ItemImages images={images} />
      <ItemVideos items={videos} />
    </>
  )
}

export default ProvisionItem
