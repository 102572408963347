import React from "react"

import { OrdersListContainer } from "src/containers"
import { ViewerTrustedOrdersListQueryContainer } from "src/query"
import { OrderFactory } from "src/components"
import { parseOrdersData } from "src/utils"

import Empty from "./Empty"

const parseTrusteeData = ({ trust }) => parseOrdersData(trust)

export default () => (
  <OrdersListContainer.list
    queryContainer={ViewerTrustedOrdersListQueryContainer}
    emptyView={() => <Empty />}
    adapter={parseTrusteeData}
    itemPresentation={OrderFactory.createTrusted}
  />
)
