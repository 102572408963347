import React from "react"

/* eslint-disable max-len */
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.293 468.293">
    <path
      d="M337.335 0H95.219C84.874 0 76.488 8.386 76.488 18.732v430.829c0 10.345 8.386 18.732 18.732 18.732h305.95c10.345 0 18.732-8.386 18.732-18.732V82.567L337.335 0z"
      fill="#e1e6e9"
    />
    <path fill="#27a2db" d="M48.39 258.067h371.512v128.3H48.39z" />
    <g fill="#ebf0f3">
      <path d="M182.722 293.744c7.567 6.85 11.342 16.377 11.342 28.583 0 12.201-3.665 21.861-11.004 28.971-7.339 7.115-18.571 10.67-33.687 10.67h-26.056v-78.501h26.952c14.074 0 24.895 3.427 32.453 10.277zm-2.02 28.916c0-17.968-10.291-26.952-30.881-26.952h-13.252v53.793h14.714c9.505 0 16.789-2.262 21.843-6.795 5.053-4.527 7.576-11.208 7.576-20.046zM276.828 351.129c-7.933 7.75-17.739 11.625-29.419 11.625s-21.486-3.875-29.419-11.625c-7.942-7.745-11.908-17.406-11.908-28.971 0-11.57 3.966-21.226 11.908-28.976 7.933-7.75 17.739-11.62 29.419-11.62s21.486 3.87 29.419 11.62c7.942 7.75 11.908 17.406 11.908 28.976 0 11.565-3.966 21.225-11.908 28.971zm-9.706-49.132c-5.356-5.538-11.927-8.307-19.713-8.307-7.787 0-14.358 2.769-19.713 8.307-5.346 5.543-8.024 12.26-8.024 20.161s2.678 14.618 8.024 20.156c5.356 5.543 11.927 8.312 19.713 8.312 7.787 0 14.358-2.769 19.713-8.312 5.346-5.538 8.024-12.256 8.024-20.156s-2.677-14.619-8.024-20.161zM341.296 349.95c4.56 0 8.49-.763 11.79-2.298 3.29-1.535 6.736-3.989 10.336-7.357l8.527 8.76c-8.308 9.208-18.397 13.814-30.26 13.814-11.872 0-21.715-3.82-29.538-11.456-7.823-7.636-11.735-17.296-11.735-28.976s3.985-21.409 11.963-29.2c7.969-7.782 18.041-11.675 30.205-11.675s22.327 4.492 30.488 13.476l-8.417 9.208c-3.747-3.592-7.284-6.1-10.62-7.526-3.327-1.421-7.238-2.134-11.735-2.134-7.933 0-14.595 2.568-19.987 7.695-5.392 5.127-8.088 11.68-8.088 19.654s2.678 14.636 8.033 19.987c5.357 5.355 11.699 8.028 19.038 8.028z" />
    </g>
    <path fill="#2d93ba" d="M48.39 386.364l28.098 26.127v-26.127z" />
    <path fill="#ebf0f3" d="M337.336 82.567h82.566L337.335 0z" />
    <path fill="#d5d6db" d="M353.221 82.567l66.681 38.688V82.567z" />
  </svg>
)
