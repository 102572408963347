import React from "react"
import Relay, { graphql } from "react-relay"

import { Heading, Translate } from "src/components"

const render = ({ orders }) => {
  const { count } = orders

  return (
    <Heading.h3 textAlign="center">
      <Translate i18n="pages:guest-orders.orders-count" />: {count}
    </Heading.h3>
  )
}

export default Relay.createFragmentContainer(render, {
  orders: graphql`
    fragment GuestOrdersCount_orders on OrderConnection {
      count
    }
  `,
})
