import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const ButtonContainer = styled(Container)`
  display: flex;
  margin-top: 50px;
`

export const Button = styled.button`
  position: relative;
  min-width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[3]};
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding: 15px 16px 13px;
  background-color: white;
  margin-right: 16px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const RedButton = styled(Button)`
  color: white;
  background-color: ${({ theme }) => theme.colors.mainRed};
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
`

export const DeleteButton = styled(Button)`
  border: none;
  margin-right: 0;
  margin-left: auto;
`
