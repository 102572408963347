import styled from "styled-components"

const hpd = (theme) => theme.paddings[2]
const hpt = (theme) => theme.paddings[1]
const hpm = (theme) => theme.paddings[0]

export const Header = styled.header`
  align-items: center;
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 ${({ theme }) => hpd(theme)}px;

  @media (max-width: 1023px) {
    padding: 0 ${({ theme }) => hpt(theme)}px;
  }

  @media (max-width: 767px) {
    padding: 0 ${({ theme }) => hpm(theme)}px;
  }
`

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;

  & > * + * {
    margin-left: 10px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const LocaleList = styled.ul`
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  & > li + li {
    margin-left: 20px;
  }
`

const localeButtonBorderColor = (theme) => theme.colors.whites[4]

export const LocaleButton = styled.button<any>`
  align-items: center;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  height: 32px;
  padding: 0 5px;
  text-align: center;

  border-color: ${({ theme, active }) => (active === true ? localeButtonBorderColor(theme) : "transparent")};

  &:hover,
  &:active {
    border-color: ${({ theme }) => localeButtonBorderColor(theme)};
  }

  & > * + * {
    margin-left: 10px;
  }
`
