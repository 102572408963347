import React from "react"

const OrderData: any = React.createContext({})

export const OrderDataProvider = OrderData.Provider
export const OrderDataConsumer = OrderData.Consumer

const OrderType: any = React.createContext({})

export const OrderTypeProvider = OrderType.Provider
export const OrderTypeConsumer = OrderType.Consumer
