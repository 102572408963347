import styled from "styled-components"

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.2);
  width: 100%;
  max-width: 150px;
  height: 110px;
  font-family: Geometria;
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 16px;

  @media screen and (max-width: 480px) {
    flex-direction: row;
    max-width: 100%;
    height: 80px;
    margin: 0;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`

export const Title = styled.h5`
  font-size: 12px;
  line-height: 15px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    max-width: 45%;
    margin: auto 0;
  }
`

export const Value = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    max-width: 45%;
    margin: auto 0;
  }
`
