import React from "react"

import { Translate } from "src/components"
import RadioWrapper from "./styles"

const RadioButton = (props) => {
  const {
    name, value, checked, onChange, label: labelFromProps,
  } = props

  return (
    <RadioWrapper>
      <label className="container">
        <Translate i18n={labelFromProps} ns="components" />
        <input
          type="radio"
          value={value}
          name={name}
          onChange={onChange}
          checked={checked}
        />
        <span className="checkmark" />
      </label>
    </RadioWrapper>
  )
}

export default RadioButton
