import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { Box } from "src/components"

const AchieveContext = React.createContext({
  bottomAchieved: false,
  unfreeze: () => {},
  freeze: () => {},
})

const AchieveContextProvider = AchieveContext.Provider
const AchieveContextConsumer = AchieveContext.Consumer

class BottomAchieveContainer extends React.PureComponent {
  static contextProvider

  static contextConsumer

  state = {
    freeze: true,
    bottomAchieved: false,
  }

  onBottomAchieve = (isVisible) => {
    if (!this.state.freeze && !isVisible) return

    this.setState(() => ({ bottomAchieved: true }))
  }

  freeze = () => {
    this.setState(() => ({ freeze: true }))
  }

  unfreeze = () => {
    this.setState(() => ({ freeze: false }))
  }

  componentWillUnmount = () => {
    this.setState = () => {}
  }

  contextApi = {
    freeze: this.freeze,
    unfreeze: this.unfreeze,
  }

  render() {
    return (
      <React.Fragment>
        <AchieveContextProvider
          value={{
            ...this.contextApi,
            bottomAchieved: this.state.bottomAchieved,
          }}
        >
          {this.props.children}
        </AchieveContextProvider>
        <VisibilitySensor onChange={this.onBottomAchieve}>
          <Box width="1px" height="1px" />
        </VisibilitySensor>
      </React.Fragment>
    )
  }
}

BottomAchieveContainer.contextProvider = AchieveContextProvider
BottomAchieveContainer.contextConsumer = AchieveContextConsumer

export default BottomAchieveContainer
