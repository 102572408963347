import React from "react"
import Slider from "react-slick"

import { RelayEnvironmentConsumer } from "src/context"
import { getAttachmentLink } from "src/mutations"
import { preventEventBubbling } from "src/utils"
import { Box, DefaultModal } from "src/components"

import { GalleryContainer } from "../styles"

import Slide from "./Slide"
import Arrow from "./Arrows"
import SubControls from "./SubControls"

const fetchImage = (environment) => async ({ id }) => {
  const url = await new Promise(
    (resolve, reject) => {
      getAttachmentLink.commit(
        environment,
        { input: { id } },
        null,
        {
          onCompleted: (data) => resolve(data.getAttachmentLink.url),
          onError: reject,
        },
      )
    },
  )

  const resp = await fetch(url as any)

  if (!resp.ok) throw new Error(`fetch ${id} status ${resp.status}`)

  const blob = await resp.blob()

  return URL.createObjectURL(blob)
}

const shouldRenderControls = ({ images }: any) => Array.isArray(images) && images.length > 1

class SliderModal extends React.Component<any, any> {
  state = {
    activeSlide: this.props.initialSlide,
    images: this.props.images,
  }

  settings = {
    speed: 0,
    dots: false,
    swipe: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
  }

  slider

  componentDidMount() {
    document.addEventListener("keyup", this.keyControlsListener)

    Promise.allSettled(
      this.props.images.map(fetchImage(this.props.environment)),
    )
      .then((data) => this.setState({
        images: this.state.images.map((img, i) => {
          const result = data[i]

          if (result.status === "fulfilled") return { ...img, url: result.value }

          // eslint-disable-next-line no-console
          console.error(result.reason)
          return img
        }),
      }))
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyControlsListener)
  }

  setActiveIndex = (activeSlide) => {
    this.setState(() => ({ activeSlide }))
  }

  keyControlsListener = (e) => {
    const { keyCode } = e

    if (Number(keyCode) === 39) {
      this.onNext()
    } else if (Number(keyCode) === 37) {
      this.onPrev()
    }
  }

  onNext = (event?: any) => {
    preventEventBubbling(event)
    this.slider.slickNext()
  }

  onPrev = (event?: any) => {
    preventEventBubbling(event)
    this.slider.slickPrev()
  }

  onSlideClick = (event) => {
    preventEventBubbling(event)
    this.slider.slickNext()
  }

  /* eslint-disable no-return-assign */
  render() {
    return (
      <DefaultModal
        onClose={this.props.onClose}
        type="centered"
        maxHeight="none"
        padding="60px 30px 30px"
      >
        <GalleryContainer>
          {shouldRenderControls(this.props) && <Arrow onClick={this.onPrev} />}
          <Box height="calc(100vh - 150px)" width="calc(100vw - 260px)" px={32}>
            <Slider
              ref={(slider) => (this.slider = slider)}
              initialSlide={this.props.initialSlide}
              afterChange={this.setActiveIndex}
              {...this.settings}
            >
              {this.state.images.map(({ url }, ii) => (
                <Slide onClick={this.onSlideClick} key={ii} src={url} />
              ))}
            </Slider>
            <SubControls
              activeSlide={this.state.activeSlide}
              length={this.props.images.length}
              attachmentId={this.props.images[this.state.activeSlide].id}
            />
          </Box>
          {shouldRenderControls(this.props) && (
            <Arrow rotate={180} onClick={this.onNext} />
          )}
        </GalleryContainer>
      </DefaultModal>
    )
  }
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => <SliderModal {...props} environment={environment} />}
  </RelayEnvironmentConsumer>
)
