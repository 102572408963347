import styled from "styled-components"

export const Container = styled.div`
  position: relative;

  @media screen and (max-width: 800px) {
    width: 50%;
    margin: 0 auto;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
`

export const Button = styled.button`
  background: #ff0000;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 13px 0;
  width: 190px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    padding: 15px 0;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 22px 0 18px;
  }
`

export const Description = styled.div`
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  margin-top: 8px;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
    margin-top: 24px;
    font-weight: 300;
  }
`
