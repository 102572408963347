import styled, { css } from "styled-components"

import { Close, Illustration, Success } from "./icons"

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Modal = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const Container = styled(Flex)<any>`
  flex-direction: column;
  margin: auto;
  max-width: 550px;
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 14px ${({ theme }) => theme.colors.fullBlacks[3]};
  border-radius: 4px;
  padding: 36px;

  ${({ compact }) => compact
    && css`
      max-width: 360px;
    `};
`

export const ButtonContainer = styled(Flex)`
  margin-top: 40px;
`

export const Button = styled.button<any>`
  width: 165px;
  padding: 16px 0 14px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: inherit;
  font-family: Geometria, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const PrimaryButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.mainRed};
  margin-left: 12px;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 12px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
`

export const CloseIcon = styled(Close)`
  fill: ${({ theme }) => theme.colors.fullBlack};
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
`

export const Timer = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 0;
`

export const ImageContainer = styled.div`
  position: relative;
  margin-top: 44px;
  display: flex;
`

export const Image = styled(Illustration)`
  margin: 0 auto;
`

export const Description = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 18px 0 0;
`

export const SuccessfulContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin: 60px 0 30px;
`

export const SuccessfulText = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: center;
  margin: 28px 0 0;
`

export const SuccessIcon = styled(Success)`
  margin: 0 auto;
`
