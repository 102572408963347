import styled, { css } from "styled-components"
import { fontWeight } from "styled-system"

export * from "./wrappers"

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Container = styled(Flex)`
  position: relative;
  width: 100%;
  height: 160px;
  box-shadow: 0 1px 20px ${({ theme }) => theme.colors.fullBlacks[2]};
  border-radius: 6px;
  justify-content: space-between;
  padding-left: 40px;
  background-color: white;
  z-index: 2;
  margin-top: 46px;

  @media screen and (max-width: 800px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 690px) {
    flex-direction: column;
    padding: 0 0 30px;
    align-items: flex-start;
    height: auto;
  }

  @media screen and (max-width: 480px) {
    margin-top: 60px;
  }
`

export const MainContainer = styled(Flex)`
  flex-direction: column;

  @media screen and (max-width: 690px) {
    padding-left: 24px;
  }
`

export const Name = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  margin: 0;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.primaryBlacks[9]};

  @media screen and (max-width: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
`

export const Points = styled(Name)`
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Red = styled.span`
  color: ${({ theme }) => theme.colors.mainRed};
`

export const Button = styled.button`
  width: 160px;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.mainRed};
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: white;
  padding: 14px 0 12px;
  margin-left: auto;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media screen and (max-width: 800px) {
    width: 125px;
    margin: auto;
  }

  @media screen and (max-width: 800px) {
    padding: 19px 0 17px;
  }
`

export const InfoContainer = styled(Flex)`
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;

  @media screen and (max-width: 800px) {
    padding: 0 18px;
  }

  @media screen and (max-width: 690px) {
    padding: 0;
  }
`

export const InfoBlockContainer = styled(Flex)`
  align-items: center;

  @media screen and (max-width: 690px) {
    width: 100%;
    margin: 40px 0 0;
    justify-content: space-between;
    padding: 0 24px;
  }
`

export const InfoBlockDescription = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 10px;
  line-height: 11px;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 12px 0 0;
  max-width: 510px;
`

export const ButtonDescription = styled(InfoBlockDescription)`
  max-width: 160px;
  margin: 10px 0 0 auto;
`

export const InfoBlock = styled(MainContainer)`
  margin: 0 32px;

  @media screen and (max-width: 800px) {
    margin: 0 18px;
  }

  @media screen and (max-width: 690px) {
    margin: 0;
    padding: 0;
  }
`

export const InfoTitle = styled(Points)<any>`
  margin: 0;
  text-align: center;
  line-height: 20px;
  ${fontWeight};
`

export const InfoDescription = styled(InfoTitle)`
  font-size: 10px;
  line-height: 1;

  @media screen and (max-width: 800px) {
    font-size: 10px;
  }
`

export const InfoValue = styled(InfoDescription)`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.mainRed};

  @media screen and (max-width: 800px) {
    font-size: 22px !important;
    line-height: 26px !important;
  }
`

export const Divider = styled.div`
  position: relative;
  height: 45px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.fullBlacks[2]};

  margin: 0 32px;

  @media screen and (max-width: 800px) {
    margin: 0 18px;
  }

  @media screen and (max-width: 690px) {
    margin: 0;
  }
`

export const Text = styled(Name)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
  }

  @media screen and (max-width: 480px) {
    font-size: 15px;
    line-height: 19px;
  }
`

export const HeaderContainer = styled(Flex)<any>`
  justify-content: space-between;
  padding-top: 30px;

  @media screen and (max-width: 690px) {
    width: 100%;

    ${({ isShipped }) => isShipped
      && css`
        flex-direction: column-reverse;
        padding: 0;
      `};
  }
`
