import React, { useMemo, useCallback, useState } from "react"
import Relay, { graphql } from "react-relay"
import { useParams, useHistory } from "react-router"

import { RequestProfileAccreditation } from "src/mutations"
import { useEnvironment } from "src/hooks"

import { Group } from "./Elements"
import buildForm from "./buildForm"
import {
  Container,
  ButtonContainer,
  Button,
  RedButton,
  DeleteButton,
} from "./styles"

function JuristicProfileData(props) {
  const { profile, createOrder } = props

  const { environment } = useEnvironment()
  const [isPending, setPending] = useState(false)

  const forms = useMemo(() => buildForm(profile), [profile])
  const accreditationStatus = useMemo(() => profile?.accreditation.status, [
    profile,
  ])

  const { id } = useParams()
  const history = useHistory()

  const toEdit = useCallback(() => history.push(`/trusted/${id}/edit`), [
    history,
    id,
  ])

  const onError = useCallback(() => {
    setPending(false)
  }, [setPending])

  const onCompleted = useCallback(() => {
    setPending(false)
  }, [setPending])

  const requestAccreditation = useCallback(() => {
    setPending(true)

    const variables = {
      input: {
        profileId: profile?.id || "",
        token: "",
        role: "BORROWER",
      },
    }
    const callbacks = {
      onError,
      onCompleted,
    }

    RequestProfileAccreditation.commit(
      environment,
      variables,
      undefined,
      callbacks,
    )
  }, [profile, setPending, environment, onError, onCompleted])

  return (
    <Container>
      {forms.map((form, idx) => (
        <Group item={form} key={idx} />
      ))}

      <ButtonContainer>
        <Button disabled={isPending} onClick={toEdit}>
          редактировать
        </Button>

        {accreditationStatus !== "APPROVED" && (
          <Button
            onClick={requestAccreditation}
            disabled={isPending || accreditationStatus === "PENDING"}
          >
            отправить на аккредитацию
          </Button>
        )}

        <RedButton disabled={isPending} onClick={createOrder}>
          создать заявку
        </RedButton>
        <DeleteButton disabled>удалить профиль</DeleteButton>
      </ButtonContainer>
    </Container>
  )
}

export default Relay.createFragmentContainer(JuristicProfileData, {
  profile: graphql`
    fragment JuristicProfileData_profile on UserProfile {
      id
      name
      __typename
      user {
        id
        email
      }
      accreditation(role: BORROWER) {
        status
      }
      ... on LegalEntityProfile {
        phone
        inn
        kpp
        ogrn
        iian
        borrower {
          publicEmail
        }
        address {
          raw
        }
        actualAddress {
          raw
        }
        business {
          main
          aux
          legalForm
          startedAt
        }
        passport {
          scans {
            url
            filename
          }
        }
        executive {
          name
        }
      }
      ... on EntrepreneurProfile {
        phone
        inn
        ogrn
        iian
        borrower {
          publicEmail
        }
        address {
          raw
        }
        actualAddress {
          raw
        }
        passport {
          scans {
            url
            filename
          }
        }
      }
    }
  `,
})
