import React from "react"

import { OrderFactory } from "src/components"

import { MobileWrapper, List } from "./styles"
import { MOBILE_MOCK_ORDERS, MOBILE } from "./constants"

const MobileItem = React.memo((props: { data: any }) => <OrderFactory.mobileOrder data={props.data} />)

const Mobile = ({ isFetching, orders }) => {
  const show = !isFetching && orders.length > 0
  return (
    <MobileWrapper>
      <List>
        {show && orders.map((order, i) => <MobileItem key={i} data={order} />)}

        {isFetching
          && Array(MOBILE_MOCK_ORDERS)
            .fill(0)
            .map((_, i) => {
              const Item = OrderFactory.createMobileMock({}, MOBILE, false)
              return <Item key={i} />
            })}
      </List>
    </MobileWrapper>
  )
}

export default Mobile
