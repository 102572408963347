import React from "react"
import { graphql } from "relay-runtime"
import { Spring } from "react-spring/renderprops"
import { useSubscription } from "src/hooks"

import {
  COMPLETE,
  APPROVED,
  TRANSFER,
  SUCCEEDED,
} from "../../ProposalHeader/constants"

import {
  InfoBlock, Timer, Button, StatusBar,
} from "../Elements"

import {
  getClosingTime,
  divideNumber,
  getStatusBarValue,
  getProposalGoal,
  getFinishPaymentDate,
  getNextPayment,
  getStatusBarMinValue,
  getLoanDate,
} from "./utils"
import {
  Container,
  TextBlock,
  OutlinedButton,
  Divider,
  ButtonWrapper,
  DesktopContainer,
  MobileContainer,
} from "./styles"

import { OwnerProposalSpecificationForGatheredAmountSubscription as SubscriptionOperation }
  from "./__generated__/OwnerProposalSpecificationForGatheredAmountSubscription.graphql"

const subscription = graphql`
  subscription OwnerProposalSpecificationForGatheredAmountSubscription(
    $input: GatheredAmountSubscriptionInput!
  ) {
    gatheredAmount(input: $input) {
      order {
        status
        chain {
          gatheredAmount
          investorsCount
        }
      }
    }
  }
`

function OwnerProposalSpecification(props) {
  const [data, setData] = React.useState(props.data)
  const {
    chain, status, application, paymentScheduleList,
  } = data

  useSubscription<SubscriptionOperation>(
    () => ({
      skip: !data.id || status === COMPLETE || status === SUCCEEDED,
      subscription,
      variables: { input: { orderId: data.id } },
      onNext: (data) => {
        setData((prev) => ({
          ...prev,
          ...data.gatheredAmount.order,
          chain: {
            ...prev.chain,
            ...data.gatheredAmount.order.chain,
          },
        }))
      },
      // eslint-disable-next-line no-console
      onError: (error) => console.error(error),
    }),
    [data.id],
  )

  return (
    <>
      {status !== COMPLETE && status !== SUCCEEDED && (
        <>
          <DesktopContainer>
            <Container>
              <Timer closingTime={getClosingTime(data)} />
              <StatusBar
                goal={getProposalGoal(data)}
                gatheredAmount={chain.gatheredAmount}
                statusBarValue={getStatusBarValue(data)}
                statusBarMinValue={getStatusBarMinValue(data)}
                minValue={application.data.minValue}
                maxValue={application.data.maxValue}
              />
              <Spring to={chain}>
                {({ investorsCount }) => (
                  <InfoBlock
                    title="Инвесторов"
                    value={divideNumber(investorsCount.toFixed(0))}
                  />
                )}
              </Spring>

              {status === APPROVED && (
                <Button onClick={null}>Оплатить комиссию</Button>
              )}

              {status === TRANSFER && (
                <Button onClick={null}>Подтвердить</Button>
              )}
            </Container>
            <Divider />
          </DesktopContainer>

          <MobileContainer>
            <Container>
              <StatusBar
                goal={getProposalGoal(data)}
                gatheredAmount={chain.gatheredAmount}
                statusBarValue={getStatusBarValue(data)}
                statusBarMinValue={getStatusBarMinValue(data)}
                minValue={application.data.minValue}
                maxValue={application.data.maxValue}
              />
            </Container>

            <Divider />

            <Container>
              <Timer closingTime={getClosingTime(data)} />
              <Spring to={chain}>
                {({ investorsCount }) => (
                  <InfoBlock
                    title="Инвесторов"
                    value={divideNumber(investorsCount.toFixed(0))}
                  />
                )}
              </Spring>
            </Container>

            <Divider />

            <Container>
              {status === APPROVED && (
                <Button onClick={null}>Оплатить комиссию</Button>
              )}
              {status === TRANSFER && (
                <Button onClick={null}>Подтвердить</Button>
              )}
            </Container>
          </MobileContainer>
        </>
      )}

      {(status === COMPLETE || status === SUCCEEDED) && (
        <>
          <DesktopContainer>
            <Container>
              <InfoBlock
                title="Дата договора займа"
                value={getLoanDate(data)}
              />
              <InfoBlock
                title="Инвесторов"
                value={divideNumber(chain.investorsCount)}
              />
              {!!paymentScheduleList?.[0]?.items?.length && (
                <InfoBlock
                  title="Погашение долга"
                  value={getFinishPaymentDate(data)}
                />
              )}

              {status === COMPLETE && (
                <>
                  <TextBlock>
                    Для погашения очередного платежа по займу пополните баланс
                    на сумму не менее{" "}
                    <b>{divideNumber(getNextPayment(data).toFixed(2))}₽</b>
                  </TextBlock>
                  <OutlinedButton onClick={null}>Пополнить счет</OutlinedButton>
                </>
              )}
            </Container>
            <Divider />
          </DesktopContainer>

          <MobileContainer>
            <Container>
              <InfoBlock
                title="Дата договора займа"
                value={getLoanDate(data)}
              />
              <InfoBlock
                title="Инвесторов"
                value={divideNumber(chain.investorsCount)}
              />
              {!!paymentScheduleList?.[0]?.items?.length && (
                <InfoBlock
                  title="Погашение долга"
                  value={getFinishPaymentDate(data)}
                />
              )}
            </Container>

            <Divider />

            {status === COMPLETE && (
              <ButtonWrapper>
                <TextBlock>
                  Для погашения очередного платежа по займу пополните баланс на
                  сумму не менее{" "}
                  <b>{divideNumber(getNextPayment(data).toFixed(2))}₽</b>
                </TextBlock>
                <Button onClick={null}>Пополнить счет</Button>
              </ButtonWrapper>
            )}
          </MobileContainer>
        </>
      )}
    </>
  )
}

export default OwnerProposalSpecification
