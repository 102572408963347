import InputMask from "react-input-mask"

import React from "react"
import styled from "styled-components"

import { Box, Text } from "src/components"

const Container = styled(Box)`
  margin-top: 10px;
`

const Label = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  font-family: Geometria, sans-serif;
`

type InputProps = {
  mask: string;
}

export const Input = React.forwardRef((props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
  const { mask } = props

  if (!mask) {
    return <input {...props} ref={ref} />
  }

  return (
    <InputMask {...props}>
      {(inputProps) => <input {...inputProps} ref={ref} />}
    </InputMask>
  )
})

export const Error = ({ error, variant }) => {
  if (!error) {
    return null
  }

  return (
    <Container>
      <Label color={variant}>{error}</Label>
    </Container>
  )
}
