import styled, { css } from "styled-components"

export const Container = styled.div``
export const Label = styled.label<any>`
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.4;
    `}

  & > a {
    color: currentColor;
    text-decoration: none;
  }
`
