import React from "react"
import Relay, { graphql } from "react-relay"

import { isBorrower } from "src/utils"

import {
  Table, Row, THead, TData, Link,
} from "./styles"

function TrustedAccountList(props) {
  const { accounts } = props
  const { trustedUsers } = accounts

  return (
    <Table>
      <thead>
        <Row>
          <THead>Email</THead>
          <THead>Название</THead>
          <THead width={80}>Заявок</THead>
        </Row>
      </thead>

      <tbody>
        {trustedUsers.map((user) => (
          <React.Fragment key={user.id}>
            {user.profiles.filter(isBorrower).map((profile) => (
              <Row key={profile.id}>
                <TData>
                  <Link to={`/trusted/${profile.id}/info`}>{user.email}</Link>
                </TData>
                <TData>{profile.name || "-"}</TData>
                <TData>{profile.orders.count}</TData>
              </Row>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  )
}

export default Relay.createFragmentContainer(TrustedAccountList, {
  accounts: graphql`
    fragment TrustedAccountList_accounts on User {
      trustedUsers {
        id
        email
        profiles {
          id
          name
          __typename
          orders {
            count
          }
        }
      }
    }
  `,
})
