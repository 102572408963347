import React from "react"
import is from "is_js"

import { Blur, Translate } from "src/components"

import {
  HintLabel, Value, Container, StyledLabel,
} from "./styles"

const Label = ({ content }) => (typeof content === "string" ? (
    <StyledLabel>
      <Translate i18n={content} ns="models" />
    </StyledLabel>
) : (
  content()
))

const Hint = ({ hint }) => (typeof hint === "string" ? (
    <HintLabel>
      <Translate i18n={hint} ns="models" />
    </HintLabel>
) : (
  hint()
))

const renderLabel = (label) => (label ? <Label content={label} /> : null)

const renderValue = (value) => (is.existy(value) ? <Value>{value}</Value> : null)

const renderHint = (hint) => (hint ? <Hint hint={hint} /> : null)

const validValue = (value) => value !== undefined && value !== null

const defaultRender = (props) => {
  const {
    hint, guest, label, value, render, isLast,
  } = props

  const Wrapper = guest ? Blur : React.Fragment

  return render || validValue(value) ? (
    <Container isLast={isLast}>
      {renderLabel(label)}
      {render && render()}
      <Wrapper>{renderValue(value)}</Wrapper>
      {renderHint(hint)}
    </Container>
  ) : null
}

defaultRender.defaultProps = {
  alignItems: "flex-start",
  pr: 48,
}

export default defaultRender
