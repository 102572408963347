import React from "react"

import {
  ItemWrapper,
  PrimaryContainer,
  SecondaryContainer,
  DateContainer,
  SumContainer,
  ItemData,
  ItemDescription,
  ItemValue,
} from "./styles"

import { getDate, displayData, getMonth } from "../PaymentScheduleItem/utils"

export default function MobilePaymentScheduleItem(props) {
  const {
    isCession, data, debt, index,
  } = props

  return (
    <ItemWrapper>
      <PrimaryContainer>
        {isCession && <DateContainer>{getDate(data.date)}</DateContainer>}
        {!isCession && <DateContainer>{getMonth(index)}</DateContainer>}
        <SumContainer>{displayData(data.total_gross)}</SumContainer>
      </PrimaryContainer>

      <SecondaryContainer>
        <ItemData>
          <ItemDescription>Основной долг</ItemDescription>
          <ItemValue>{displayData(data.loan)}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>Проценты</ItemDescription>
          <ItemValue>{displayData(data.interest)}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>НДФЛ</ItemDescription>
          <ItemValue>{`~ ${displayData(data.personal_tax)}`}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>К выплате</ItemDescription>
          <ItemValue>{displayData(data.total)}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>Остаток долга</ItemDescription>
          <ItemValue>{displayData(debt)}</ItemValue>
        </ItemData>
      </SecondaryContainer>
    </ItemWrapper>
  )
}

export function TotalScheduleItem(props) {
  const { data } = props

  return (
    <ItemWrapper style={{ fontWeight: "bold" }}>
      <PrimaryContainer>
        <DateContainer>{getDate(data.date)}</DateContainer>
        <SumContainer>{displayData(data.total_gross)}</SumContainer>
      </PrimaryContainer>

      <SecondaryContainer>
        <ItemData>
          <ItemDescription>Основной долг</ItemDescription>
          <ItemValue>{displayData(data.loan)}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>Проценты</ItemDescription>
          <ItemValue>{displayData(data.interest)}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>НДФЛ</ItemDescription>
          <ItemValue>{`~ ${displayData(data.personal_tax)}`}</ItemValue>
        </ItemData>

        <ItemData>
          <ItemDescription>К выплате</ItemDescription>
          <ItemValue>{displayData(data.total)}</ItemValue>
        </ItemData>
      </SecondaryContainer>
    </ItemWrapper>
  )
}
