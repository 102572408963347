import React from "react"

import { Container, ImageContainer, Image } from "./styles"

function Gallery(props) {
  const { items } = props
  return (
    <Container>
      {items.map((item, i) => (
        <ImageContainer key={i} href={item.url} target="_blank">
          <Image src={item.url} />
        </ImageContainer>
      ))}
    </Container>
  )
}

export default Gallery
