import { graphql } from "relay-runtime"

const fragment = {
  profile: graphql`
    fragment AccreditationInfoFragment_profile on UserProfile {
      id
      __typename
      investor {
        canBeInvestor
      }
      ... on IndividualProfile {
        name
        phone
        isRussiaTaxResident
        sex
        name
        inn
        ifns
        iian
        birthDate
        birthPlace
        employment
        isPublicOfficial
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
        registrationAddress {
          ... on Address {
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
        passport {
          ... on Passport {
            series
            number
            issuedAt
            scans {
              id
              url
              filename
            }
            issuedBy {
              ... on PassportIssuer {
                code
                name
              }
            }
          }
        }
        supportingDocuments {
          ... on SupportingDocument {
            id
            attachment {
              id
              url
              size
              width
              height
              format
              filename
            }
            status
          }
        }
        accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on EntrepreneurProfile {
        name
        phone
        isRussiaTaxResident
        sex
        name
        inn
        psrnie
        ifns
        iian
        birthDate
        birthPlace
        isPublicOfficial
        registrationAddress {
          ... on Address {
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
          }
        }
        passport {
          ... on Passport {
            series
            number
            issuedAt
            scans {
              id
              url
              filename
            }
            issuedBy {
              ... on PassportIssuer {
                code
                name
              }
            }
          }
        }
        accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on ForeignIndividualProfile {
        name
        firstName
        lastName
        foreignPhone: phone
        tin
        profileTransactions: transactions {
          count
        }
        selfie {
          id
          url
          filename
          signature {
            url
          }
        }
        accreditation(role: INVESTOR) {
          status
          declineReason
        }
        isRussiaTaxResident
        taxResidencyApplication {
          id
          filename
          url
          size
          signature {
            url
          }
        }
        migrationCard {
          number
          dateFrom
          dateTo
          attachments {
            id
            filename
            url
            size
            signature {
              url
            }
          }
        }
        innDocument {
          id
          url
          filename
        }
        residenceDocument {
          id
          filename
          url
          size
          signature {
            url
          }
        }
        territoryResidenceDocument {
          type
          series
          number
          dateFrom
          dateTo
          attachments {
            id
            filename
            url
            size
            signature {
              url
            }
          }
        }
        documents {
          type
          attachments {
            id
            url
            size
            filename
            access
            format
            signature {
              url
            }
          }
        }
        translatedDocuments {
          id
          url
          filename
        }
        registrationAddress {
          ... on Address {
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
      }
      ... on LegalEntityProfile {
        name
        inn
        phone
        accreditation(role: BORROWER) {
          status
          declineReason
        }
        iian
        inn
        kpp
        ogrn
        name
        executive {
          name
        }
        business {
          ... on Business {
            main
            aux
            legalForm
            startedAt
          }
        }
        address {
          ... on Address {
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
        actualAddress {
          ... on Address {
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
        passport {
          ... on Passport {
            scans {
              id
              url
              filename
            }
          }
        }
        borrower {
          publicEmail
        }
      }
    }
  `,
  foreignProfile: graphql`
    fragment AccreditationInfoFragment_foreignProfile on ForeignIndividualProfile {
      id
      __typename
      firstName
      lastName
      phone
      documents {
        type
        attachments {
          id
          url
          filename
        }
      }
      translatedDocuments {
        id
        url
        filename
      }
      selfie {
        id
        url
        filename
      }
      accreditation(role: INVESTOR) {
        status
        declineReason
      }
      tin
      isRussiaTaxResident
      hasUSACitizenship
      taxResidencyApplication {
        id
        filename
        url
        size
        signature {
          url
        }
      }
      migrationCard {
        number
        dateFrom
        dateTo
        attachments {
          id
          filename
          url
          size
          signature {
            url
          }
        }
      }
      innDocument {
        id
        url
        filename
      }
      residenceDocument {
        id
        url
        filename
      }
      territoryResidenceDocument {
        type
        series
        number
        dateFrom
        dateTo
        attachments {
          id
          filename
          url
          size
          signature {
            url
          }
        }
      }
      registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          okato
          oktmo
          settlement
          postalCode
          cityDistrict
        }
      }
    }
  `,
}

export default fragment
