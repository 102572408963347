import styled, { css } from "styled-components"

export const CardCover = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
`

export const Card = styled.li`
  position: relative;
  font-family: Geometria;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  background-color: #ffffff;
`

export const CardName = styled.p`
  padding: 0 30px;
  margin: 0;
  font-family: Geometria;
  font-size: 22px;
  color: #4a4a4a;
  line-height: 90px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const CardData = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
`

export const CardTitle = styled.div`
  text-align: left;
  font-weight: 300;
  font-family: Geometria;
  font-size: 20px;
  line-height: 25px;
  color: rgba(74, 74, 74, 0.75);
  margin: 4px 0;
`

export const CardValue = styled.div`
  margin: 4px 0;
  font-family: Geometria;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: #4a4a4a;
  font-weight: 600;
`

export const CardBoldValue = styled.div`
  margin: 4px 0;
  font-family: Geometria;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  text-align: right;
  color: #4a4a4a;
`

export const CardStatus = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 40px;
`

export const CardStatusValue = styled.div<any>`
  margin: 4px 0;
  font-family: Geometria;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  text-align: right;
  width: 100%;
  max-width: 70%;
  color: ${(props) => props.color || "#4a4a4a"};
  div svg path {
    fill: ${(props) => props.color || "#4a4a4a"};
  }
  ${(props) => props.isClickable
    && css`
      cursor: pointer;
    `}
`

export const CardAmount = styled.div`
  width: 100%;
  text-align: right;
  font-family: Geometria;
  font-size: 20px;
  line-height: 25px;
  box-sizing: border-box;
  color: #4a4a4a;
  margin-bottom: 14px;
`

export const CardStatusBar = styled.div`
  margin-top: 40px;
  padding: 0 30px;
`

export const CardButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 60px;
  margin-bottom: 30px;

  @media screen and (max-width: 370px) {
    flex-direction: column;
  }
`

export const CardButton = styled.button<any>`
  height: 65px;
  padding: 0;
  width: ${(props) => (props.compact && "48%") || "100%"};
  text-align: center;
  border: ${(props) => (props.primary && "2px solid #F70000") || "2px solid #4a4a4a"};
  color: ${(props) => (props.primary && "#F70000") || "#4a4a4a"};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  text-transform: uppercase;
  margin: 10px 0px;

  @media screen and (max-width: 420px) {
    font-size: 14px;
  }

  @media screen and (max-width: 370px) {
    width: 100%;
  }
`

export const Graph = styled.div`
  display: inline-block;
  vertical-align: middle;
  border: 1px solid rgba(74, 74, 74, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 8px 0 0;
  width: 34px;
  height: 34px;
  padding: 6px;
`

export const PaymentCard = styled.li`
  position: relative;
  font-family: Geometria;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 50px;
  background-color: #ffffff;
  padding-bottom: 32px;
  border-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.19);
`

export const PaymentCardHeader = styled.div`
  width: 100%;
  padding: 0 18px;
  height: 93px;
  display: flex;
  justify-content: space-between;
  background: #fbfbfb;
`

export const PaymentCardHeaderLeft = styled.div`
  font-family: Geometria;
  margin: auto 0;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: left;
`

export const PaymentCardHeaderRight = styled.div`
  font-family: Geometria;
  margin: auto 0;
  font-size: 22px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: right;
`

export const PaymentCardBody = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
`

export const PaymentCardData = styled.div`
  width: 100%;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
`

export const PaymentCardName = styled.div`
  max-width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: Geometria;
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  font-weight: bold;
  text-align: right;
`

export const PaymentCardDataLeft = styled.div`
  font-family: Geometria;
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  text-align: left;
`

export const PaymentCardDataRight = styled.div`
  font-family: Geometria;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
  color: ${(props) => props.color || "#4a4a4a"};
`
export const DebtLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.mainRed};

  &:hover {
    opacity: 0.65;
  }
`
