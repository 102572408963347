/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ViewerAccessContainerQueryVariables = {};
export type ViewerAccessContainerQueryResponse = {
    readonly viewer: {
        readonly country: string;
        readonly foreignProfiles: ReadonlyArray<{
            readonly id: string;
            readonly __typename: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly investorAccreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
        }>;
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly __typename: string;
            readonly investor: {
                readonly canBeInvestor: boolean;
            } | null;
            readonly canBeBorrower: boolean;
            readonly investorAccreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
            readonly borrowerAccreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
        }>;
    } | null;
};
export type ViewerAccessContainerQuery = {
    readonly response: ViewerAccessContainerQueryResponse;
    readonly variables: ViewerAccessContainerQueryVariables;
};



/*
query ViewerAccessContainerQuery {
  viewer {
    country
    foreignProfiles {
      id
      __typename
      firstName
      lastName
      investorAccreditation: accreditation(role: INVESTOR) {
        status
      }
    }
    profiles {
      id
      name
      __typename
      investor {
        __typename
        canBeInvestor
      }
      canBeBorrower
      investorAccreditation: accreditation(role: INVESTOR) {
        status
      }
      borrowerAccreditation: accreditation(role: BORROWER) {
        status
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "alias": "investorAccreditation",
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ForeignIndividualProfile",
  "kind": "LinkedField",
  "name": "foreignProfiles",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeInvestor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeBorrower",
  "storageKey": null
},
v9 = {
  "alias": "borrowerAccreditation",
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "BORROWER"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "accreditation(role:\"BORROWER\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewerAccessContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ViewerAccessContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ViewerAccessContainerQuery",
    "operationKind": "query",
    "text": "query ViewerAccessContainerQuery {\n  viewer {\n    country\n    foreignProfiles {\n      id\n      __typename\n      firstName\n      lastName\n      investorAccreditation: accreditation(role: INVESTOR) {\n        status\n      }\n    }\n    profiles {\n      id\n      name\n      __typename\n      investor {\n        __typename\n        canBeInvestor\n      }\n      canBeBorrower\n      investorAccreditation: accreditation(role: INVESTOR) {\n        status\n      }\n      borrowerAccreditation: accreditation(role: BORROWER) {\n        status\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a551f24f36a15dfbda79ec27d7106e4';
export default node;
