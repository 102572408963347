import React from "react"
import styled from "styled-components"

import {
  Box, Link, Text, Alert, Translate,
} from "src/components"

const StyledLink = styled(Link)`
  text-decoration: underline;
`

const Info = () => (
  <Box mb="20px">
    <Alert>
      <Text fontSize="16px">
        <Translate i18n="pages:guest-orders.info.beginning" />
        &nbsp;
        <StyledLink to="/login">
          <Translate i18n="pages:guest-orders.info.login" />
        </StyledLink>
        &nbsp;
        <Translate i18n="pages:guest-orders.info.middle" />
        &nbsp;
        <StyledLink to="/join">
          <Translate i18n="pages:guest-orders.info.register" />
        </StyledLink>
        <Translate i18n="pages:guest-orders.info.ending" />
      </Text>
    </Alert>
  </Box>
)

export default Info
