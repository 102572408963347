import React from "react"

import { Box, Flex } from "src/components"

import { NavButton } from "./elements"

import {
  Container,
  GenericItem,
  StyledImage,
  ItemContainer,
  ItemsCarousel,
  DefaultItemsContainer,
} from "./styles"

const defaultRenderItem = (props) => {
  const { idx, item, onClick } = props

  return (
    <ItemContainer onClick={() => onClick && onClick(item, idx)}>
      <StyledImage src={item} />
    </ItemContainer>
  )
}

class Carousel extends React.PureComponent<any, any> {
  state = {
    offset: 0,
    lastIndex: 0,
    activeItem: 0,
    scrollable: false,
    lastAvailableItem: 0,
  }

  itemsRefs = []

  containerRef: any = React.createRef()

  static defaultProps = {
    itemsSpacing: "20px",
    alwaysShowControls: false,
    renderItem: defaultRenderItem,
    itemsContainer: DefaultItemsContainer,
  }

  componentDidMount() {
    this.setLastAvailableItem()
  }

  setLastAvailableItem = () => {
    const containerWidth = this.containerRef.current.offsetWidth
    const itemsWidth = this.itemsRefs.reduce(
      (accum, ref) => accum + ref.offsetWidth,
      0,
    )
    const scrollable = itemsWidth > containerWidth

    let lastAvailableItem

    if (scrollable) {
      this.itemsRefs.reduceRight((accum, item, idx) => {
        const width = accum + item.offsetWidth
        if (width <= containerWidth) lastAvailableItem = idx

        return width
      }, 0)
    } else {
      lastAvailableItem = this.itemsRefs.length
    }

    this.setState({ scrollable, lastAvailableItem })
  }

  addRef = (ref) => this.itemsRefs.push(ref)

  onNextClick = () => {
    const { activeItem, lastAvailableItem } = this.state

    const next = activeItem < lastAvailableItem ? activeItem + 1 : 0
    const nextRef = this.itemsRefs[next]

    this.setState({
      activeItem: next,
      offset: nextRef.offsetLeft,
    })
  }

  onPreviousClick = () => {
    const { activeItem, lastAvailableItem } = this.state

    const next = activeItem - 1 >= 0 ? activeItem - 1 : lastAvailableItem
    const nextRef = this.itemsRefs[next]

    this.setState({
      activeItem: next,
      offset: nextRef.offsetLeft,
    })
  }

  render() {
    const {
      items,
      renderItem,
      onItemClick,
      itemsSpacing,
      alwaysShowControls,
      itemsContainer: ItemsContainer,
    } = this.props

    const { offset, scrollable } = this.state

    return (
      <Container ref={this.containerRef}>
        <ItemsContainer>
          <ItemsCarousel offset={offset}>
            {items.map((item, idx) => (
              <GenericItem key={idx} mr={itemsSpacing} ref={this.addRef}>
                {renderItem({ item, idx, onClick: onItemClick })}
              </GenericItem>
            ))}
          </ItemsCarousel>
        </ItemsContainer>
        {(scrollable || alwaysShowControls) && (
          <Flex mt="10px" justifyContent="flex-end">
            <Box mr="10px">
              <NavButton onClick={this.onPreviousClick} />
            </Box>
            <NavButton forward onClick={this.onNextClick} />
          </Flex>
        )}
      </Container>
    )
  }
}

export default Carousel
