import React from "react"
import styled from "styled-components"

const getTransformStyles = ({ transform }) => {
  if (transform) {
    return `transform: ${transform}`
  }
  return "transform: translateY(-2px)"
}

const GlyphContent = styled.span`
  display: inline-block;
  ${getTransformStyles};
  color: ${({ theme }) => theme.colors.grey};
  font-size: 13px;
  font-weight: 600;
`

const RatingGlyph = (props) => <GlyphContent {...props}>i</GlyphContent>

export default RatingGlyph
