import React, { useEffect, useState } from "react"
import memoize from "memoize-one"
import { Route } from "react-router"

import { ProposalInfoQueryContainer } from "src/query"
import {
  getNodeIdFromUri,
  pipe,
  notifyGTM,
  getProperty,
  getAuthToken,
  isRussianInvestor,
  isForeignInvestor,
  getInvestorProfiles,
  parseOrder,
  hasCollectedMaxAmount,
  isOfferExpired,
} from "src/utils"

import { GuestOrderAction, VirtualAccountActions } from "src/components"

import { ViewerDataConsumer } from "src/context"
import { ViewerAccessContainer } from "src/containers"
import { calcMaxInvestmentAmount } from "./Content/hookUseOnSubmit"

import Contents from "./Content"
import EnsureMobile from "./EnsureMobile"
import { useOnCompleteCallbacks, useInvestmentAmount } from "./hooks"
import {
  OrderType, ViewerType, InvestmentRenderType,
  InvestmentPageMainType, ConsumerProps, InvestmentPageType,
} from "./types"

import { Container } from "./styles"

const hasInvestorProfile = (viewer: ViewerType) => {
  if (!viewer) return false

  const foreignProfiles = getProperty(viewer, "foreignProfiles", [])
  const hasForeignInvestor = foreignProfiles.some(isForeignInvestor)
  const hasRussianInvestor = viewer.profiles.some(isRussianInvestor)

  return hasForeignInvestor || hasRussianInvestor
}

const sendGTMEvent = memoize((order: OrderType, viewer: ViewerType) => {
  if (!order || !order.chain) return () => {}

  const { id } = order.chain

  const karmaPlatformNotifier = notifyGTM({
    eventAction: `market-${id.split(".")[2]}`,
    eventLabel: "pay-second-click",
    extras: {
      "profile-id": viewer.id,
    },
  })

  const { cession, application, id: orderId } = order

  const ecommerceNotifier = notifyGTM({
    event: "addToCart",
    eventCategory: "",
    extras: {
      ecommerce: {
        add: {
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
            },
          ],
        },
      },
      FLProfile: hasInvestorProfile(viewer),
    },
  })

  return pipe(karmaPlatformNotifier, ecommerceNotifier)
})

const getAvailableInvestmentAmount = (order: OrderType) => {
  const { gatheredAmount } = order.chain
  const maxValue = Number(order.application.data.maxValue)

  return maxValue - gatheredAmount
}

function InvestmentPage(props: InvestmentPageType) {
  const { order, viewer } = props
  const profiles = getInvestorProfiles(viewer).filter(
    (item) => item.approvedAsInvestor === true,
  )
  const [profile, setProfile] = useState(profiles[0] || null)
  const orderNumber = order.chain.id.split(".")[2]
  const availableAmount = getAvailableInvestmentAmount(order)

  const maxInvestmentAmount = calcMaxInvestmentAmount(profile, availableAmount)
  const [investmentAmount, setInvestmentAmount] = useInvestmentAmount(
    maxInvestmentAmount,
  )
  const sendGTM = sendGTMEvent(order, viewer)

  const collected = hasCollectedMaxAmount(order)
  const disabled = React.useMemo(
    () => isOfferExpired(order) || order.status === "COMPLETE" || collected,
    [order],
  )

  function goBack() {
    const { history, location } = props
    const orderId = getNodeIdFromUri(location.pathname)
    history.push(`/market/${orderId}`)
  }

  const [depositWindow, setDepositWindow] = useState(false)
  const [activeWindow, setActiveWindow] = useState(null)
  const [error, setError] = useState(null)

  const openContent = React.useCallback(() => {
    sendGTM()
    const content = getAuthToken() ? "amount" : "guest"
    if (content !== "guest") {
      props.checkAccess(() => {
        setActiveWindow(content)
      })

      return
    }

    setActiveWindow(content)
  }, [])

  useEffect(() => {
    if (disabled) {
      goBack()
    }

    openContent()
  }, [])

  const hideContent = React.useCallback(() => {
    (setInvestmentAmount as any)({ target: { value: "" } })
    const next = activeWindow === "deposit" ? "amount" : null
    setActiveWindow(next)
  }, [activeWindow])

  const showDeposit = React.useCallback(() => {
    hideContent()
    setDepositWindow(true)
  }, [])

  const closeDeposit = React.useCallback(() => {
    setDepositWindow(false)
    openContent()
  }, [])

  const showError = React.useCallback((err) => {
    setError(err)
    setActiveWindow("error")
  }, [])

  const setProfileAndAmount = React.useCallback(
    pipe(setProfile, () => (setInvestmentAmount as any)({ target: { value: "" } })),
    [],
  )

  const ActiveContent = React.useMemo(() => Contents[activeWindow], [
    activeWindow,
  ])
  const callbacks = useOnCompleteCallbacks(
    Contents,
    setActiveWindow,
    hideContent,
  )

  return (
    <>
      {!!activeWindow && (
        <>
          <Container>
            {ActiveContent.component ? (
              <ActiveContent.component
                order={order}
                viewer={viewer}
                profile={profile}
                profiles={profiles}
                showDeposit={showDeposit}
                setProfile={setProfileAndAmount}
                onClose={hideContent}
                orderNumber={orderNumber}
                amount={investmentAmount}
                availableAmount={availableAmount}
                onChange={setInvestmentAmount}
                onComplete={callbacks[activeWindow]}
                onError={showError}
                goBack={goBack}
                error={error}
              />
            ) : (
              <GuestOrderAction.modal onClose={hideContent} />
            )}
          </Container>
        </>
      )}
      {depositWindow && (
        <>
          <EnsureMobile />
          <VirtualAccountActions.Deposit.Modal
            profileId={profile?.id}
            order={order}
            onClose={closeDeposit}
          />
        </>
      )}
    </>
  )
}

const ComposedConsumer = (props: ConsumerProps) => {
  const { children } = props

  return (
    <Route>
      {({ history }) => (
        <ViewerDataConsumer>
          {(viewer) => (
            <ViewerAccessContainer role="investor">
              {({ checkAccess }) => children({
                viewer,
                history,
                checkAccess,
                ...props,
              })
              }
            </ViewerAccessContainer>
          )}
        </ViewerDataConsumer>
      )}
    </Route>
  )
}

function Render(props: InvestmentRenderType) {
  const { node, ...rest } = props
  const order = parseOrder(node)

  return (
    <ComposedConsumer>
      {(renderProps) => (
        <InvestmentPage order={order} {...rest} {...renderProps} />
      )}
    </ComposedConsumer>
  )
}

export default function (props: InvestmentPageMainType) {
  const { location } = props
  const orderId = getNodeIdFromUri(location.pathname)

  const QueryContainer = ProposalInfoQueryContainer((orderProps) => (
    <Render {...props} {...orderProps} />
  ))

  return <QueryContainer orderId={orderId} />
}
