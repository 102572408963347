import React from "react"

/* eslint-disable max-len */
/* eslint-disable no-tabs */
export default () => (
  <React.Fragment>
    <g id="base">
      <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8	S16.41,20,12,20z" />
      <polygon points="16.59,7.58 10,14.17 7.41,11.59 6,13 10,17 18,9" />
    </g>
    <g id="outline" display="none">
      <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8	S16.41,20,12,20z" />
    </g>
    <g id="fill" display="none">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </g>
  </React.Fragment>
)
