/* eslint-disable no-underscore-dangle */
import React from "react"

import { OrderTypeConsumer } from "src/context"
import { Box, Avatar } from "src/components"

import { getLinkPath } from "../../utils"

const ProfileLogo = (props) => {
  const {
    data, source, withLink, orderType,
  } = props

  const {
    id, status, profile, externalUrl,
  } = data

  const linkPath = getLinkPath(status, source, id, orderType, externalUrl)

  return (
    <Box width="112px">
      <a href={withLink ? linkPath : null} target="_blank">
        <Avatar
          width={112}
          circle={true}
          url={profile && profile._avatar && profile._avatar.url}
        />
      </a>
    </Box>
  )
}

ProfileLogo.defaultProps = {
  withLink: true,
}

export default (props) => (
  <OrderTypeConsumer>
    {(orderType) => <ProfileLogo orderType={orderType} {...props} />}
  </OrderTypeConsumer>
)
