import React from "react"

import {
  AccreditationStatusProvider,
  AccreditationStatusConsumer,
} from "./context"

import { Wrapper, StatusMessageContainer } from "./styles"

import { getStatusMessage } from "./utils"

import { DeclineReasonWithModal, DeclineReasonWithTooltip } from "./Elements"

const AccreditationStatus = (props) => {
  const {
    moderation,
    element,
    onClick,
    outdated,
    showDetails,
    showTooltip,
    showStatusMessage,
    fontSize: fontSizeFromProps,
  } = props

  const { status } = moderation

  return (
    <Wrapper status={status} fontSize={fontSizeFromProps}>
      {showStatusMessage && (
        <StatusMessageContainer>
          {getStatusMessage(element, status, outdated)}
        </StatusMessageContainer>
      )}
      {showDetails && (
        <DeclineReasonWithModal status={status} onClick={onClick} />
      )}
      {showTooltip && <DeclineReasonWithTooltip moderation={moderation} />}
    </Wrapper>
  )
}

AccreditationStatus.defaultProps = {
  fontSize: "12px",
  showTooltip: false,
}

const render = (props) => (
  <AccreditationStatusConsumer>
    {(contextProps) => <AccreditationStatus {...contextProps} {...props} />}
  </AccreditationStatusConsumer>
)

render.contextProvider = AccreditationStatusProvider

export default render
