import React from "react"

import { TabletInput } from "src/components"
import { getTranslationOrComponent } from "src/utils"

import RockerInput from "../Rocker"

import { SwitchLabel, SwitchWrapper } from "./styles"

const inputsSchema = new Map<string, any>([
  ["rocker", RockerInput],
  ["tablet", TabletInput],
])

const Switch = (props) => {
  const {
    margin, variant, leftLabel, rightLabel, ...rest
  } = props

  const Component = inputsSchema.get(variant)

  return (
    <SwitchWrapper margin={margin}>
      <SwitchLabel>{getTranslationOrComponent(leftLabel)}</SwitchLabel>
      <div className="onoffswitch">{Component && <Component {...rest} />}</div>
      <SwitchLabel>{getTranslationOrComponent(rightLabel)}</SwitchLabel>
    </SwitchWrapper>
  )
}

Switch.defaultProps = {
  variant: "rocker",
  margin: "0 12px",
}

export default Switch
