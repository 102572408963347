import idx from "idx"
import moment from "moment"
import memoize from "memoize-one"

export const isValidDaysNumber = (days) => {
  const daysAsNumber = Number(days)
  return !!daysAsNumber && daysAsNumber > 0
}

export const getOfferExpirationDate = (term, confirmedAt) => {
  // possible term formats: "10 - 08 - 2021"(date) and "42"(days) as string
  if (!term || (typeof term !== "string")) return null

  const isDateFormat = term.includes("-")
  if (!isDateFormat && (!confirmedAt || !isValidDaysNumber(term))) {
    return null
  }

  const date = isDateFormat
    ? moment(term.replace(/\s/g, ""), "DD MM YYYY")
    : moment(confirmedAt).add(Number(term), "days")

  return date.isValid() ? date.toDate() : null
}

export const isOfferExpired = (order) => {
  if (!idx(order, (_) => _.offer.data.term)) {
    return false
  }

  const { term } = order.offer.data
  const { confirmedAt } = order
  const expireDate = getOfferExpirationDate(term, confirmedAt)
  if (!expireDate) return null

  const now = Date.now()
  const mscExpireDate: any = moment
    .parseZone(expireDate)
    .utcOffset(3)
    .hours(23)
    .minutes(59)
    .seconds(59)

  return mscExpireDate < now
}

export const hasCollectedMaxAmount = memoize((order) => {
  if (!order.chain || !order.application) return true

  const { gatheredAmount } = order.chain
  const maxAmount = Number(order.application.data.maxValue)
  const sum = (order.viewer.investedAmount > 0) ? 0 : 10000
  return gatheredAmount >= maxAmount - sum
})
