import React from "react"
import memoize from "memoize-one"

import { notifyGTM, number } from "src/utils"
import { ErrorsContainer } from "src/containers"

import {
  Box,
  Flex,
  Text,
  Button,
  Document,
  Translate,
  FloatingLabelInput,
} from "src/components"

import { ModalHeader, CurrencyTooltip } from "./elements"

import {
  Divider,
  InfoText,
  SubHeader,
  ScrollContainer,
  AmountControlsContainer,
} from "./styles"

import { useErrors, useOnChange, useOnSubmit } from "./hooks"

import ProfileSelect from "./ProfilePicker"
import { normalizeDocuments } from "../../InstructionsModal/instructionsModalUtils"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const sendGTMEvent = memoize((order) => {
  const { cession, application, id: orderId } = order

  const notify = notifyGTM({
    event: "checkoutFunnel1",
    eventCategory: "",
    extras: {
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
              quantity: 1,
            },
          ],
        },
      },
    },
  })

  notify()
})

const AmountModal = (props) => {
  const {
    order, amount, profile, orderNumber, availableAmount,
  } = props

  const documents = normalizeDocuments(order)

  const onChange = useOnChange(props)
  const onSubmit = useOnSubmit(props)
  const getErrorByPath = useErrors(props)

  const focusedOnInput = React.useRef(false)
  const onInputFocus = React.useCallback(() => {
    if (!focusedOnInput.current) {
      sendGTMEvent(order)
      focusedOnInput.current = true
    }
  }, [])

  return (
    <>
      <ModalHeader
        i18n="components:investment_modals.amount.title"
        number={orderNumber}
      />
      <ScrollContainer>
        <Box mt="30px" is={SubHeader}>
          <Translate i18n="components:investment_modals.amount.profile" />
        </Box>
        <Box
          mt="15px"
          mb="40px"
          is={ProfileSelect}
          value={profile}
          onChange={props.setProfile}
          options={props.profiles}
        />
        <Flex alignItems="center">
          <Button variant="empty" onClick={props.showDeposit}>
            <Text color="blue" textDecoration="underline">
              <Translate i18n="components:investment_modals.amount.deposit" />
            </Text>
          </Button>
        </Flex>
        <Box my="15px">
          <SubHeader>
            <Translate i18n="components:investment_modals.amount.amount_header" />
          </SubHeader>
          <InfoText>
            <Translate i18n="components:investment_modals.amount.amount_min" />
          </InfoText>
          <InfoText>
            <Translate
              i18n="components:investment_modals.amount.amount_left"
              maxAmount={number(availableAmount, options)}
            />
          </InfoText>
        </Box>
        <Box mb="25px">
          <FloatingLabelInput
            autoFocus
            name="withdrawalAmount"
            value={amount}
            onChange={onChange}
            onFocus={onInputFocus}
            tooltip={CurrencyTooltip}
            error={getErrorByPath("investToOrder.amount")}
            label="components:account.operations.withdraw.amount.amount_placeholder"
            autoComplete="off"
          />
        </Box>

        <Divider />

        <Box mt="25px" mb="20px" is={SubHeader}>
          <Translate i18n="components:investment_modals.amount.documents_header" />
        </Box>
        {documents.map((doc, idx) => (
          <Box mb="20px" key={idx} is={Document} doc={doc} />
        ))}
      </ScrollContainer>
      <AmountControlsContainer>
        <Box
          is={Button}
          height="48px"
          variant="blueWide"
          disabled={!amount}
          onClick={onSubmit}
        >
          <Translate i18n="components:investment_modals.amount.continue" />
        </Box>
      </AmountControlsContainer>
    </>
  )
}

export default (props) => (
  <ErrorsContainer>
    {(errors) => <AmountModal {...props} {...errors} />}
  </ErrorsContainer>
)
