/* eslint-disable max-len */

import React, {
  useState, useCallback, useMemo, useContext,
} from "react"
import { graphql } from "react-relay"
import { useHistory } from "react-router"

import {
  SetInvestorAutoInvestmentsParams,
  RemoveInvestorAutoInvestmentsParams,
} from "src/mutations"
import {
  CheckBoxField,
  QueryRenderer,
  ConfirmMutationModal,
} from "src/components"
import { CurrentProfileContext } from "src/context"
import { compose } from "src/utils"

import {
  Wrapper,
  MainTitle,
  ActiveSubTitle,
  DisabledSubTitle,
  InputWrapper,
  InputContainer,
  Input,
  InputDescription,
  MainContainer,
  MainDescription,
  RatingContainer,
  ActionContainer,
  ButtonContainer,
  PrimaryButton,
  CheckboxContainer,
  Button,
} from "./styles"
import RatingBlock from "./RatingBlock"
import { SaveInfo, DeleteInfo } from "./modals"
import {
  formatValue,
  validMinRates,
  minRatesToVariables,
  number,
  minRatesToState,
  separate,
} from "./utils"
import {
  defaultOrderLimit,
  defaultBorrowerLimit,
  defaultLimit,
  defaultMinRates,
  ratings,
} from "./constants"

function PortfolioAutoInvest(props) {
  const { investor, id: profileId } = props.node

  const {
    autoInvestments = {},
    investments: { edges: investments },
  } = investor || {}

  const {
    orderLimit: orderLimitProp = "",
    borrowerLimit: borrowerLimitProp = "",
    minRates: minRatesProp,
    limit: limitProp = "",
    isActive: activeProp = false,
    cessionEnable = true,
  } = autoInvestments || {}

  const currentAutoInvested = useMemo(
    () => investments.reduce(
      (acc, { node }) => (["SUCCEEDED", "DEFAULT"].includes(node.order.status)
        ? acc
        : acc + node.autoAmount),
      0,
    ),
    [investments],
  )

  const [isPending, setPending] = useState(false)
  const [isEditing, setEditing] = useState(
    !activeProp || !autoInvestments?.minRates?.length,
  )
  const [confirmation, setConfirmation] = useState({})
  const [isAgreed, setAgreed] = useState(false)
  const [orderLimit, setOrderLimit] = useState(
    separate(orderLimitProp || defaultOrderLimit),
  )
  const [borrowerLimit, setBorrowerLimit] = useState(
    separate(borrowerLimitProp || defaultBorrowerLimit),
  )
  const [minRates, setMinRates] = useState(
    activeProp
      ? minRatesToState(defaultMinRates, minRatesProp)
      : defaultMinRates,
  )
  const [limit, setLimit] = useState(separate(limitProp || defaultLimit))
  const [isActive, setIsActive] = useState(activeProp)
  const [isCessionEnabled, setCessionEnabled] = useState(cessionEnable)

  const [showSaveInfo, setShowSaveInfo] = useState(false)
  const [showDeleteInfo, setShowDeleteInfo] = useState(false)

  const buttonIsDisabled = useMemo(
    () => !isAgreed
      || !orderLimit
      || !borrowerLimit
      || !validMinRates(minRates)
      || !limit
      || isPending,
    [isAgreed, orderLimit, borrowerLimit, minRates, limit, isPending],
  )

  const handleCessionEnable = useCallback(
    ({ target }) => setCessionEnabled(target.checked),
    [setCessionEnabled],
  )
  const handleChangeAgreed = useCallback(
    ({ target }) => setAgreed(target.checked),
    [setAgreed],
  )
  const handleChangeOrderLimit = useCallback(
    compose(setOrderLimit, formatValue),
    [setOrderLimit],
  )
  const handleChangeBorrowerLimit = useCallback(
    compose(setBorrowerLimit, formatValue),
    [setBorrowerLimit],
  )
  const handleChangeMinRatesValue = useCallback(
    (event) => {
      const { value, name } = event.target

      let parsedValue = number(value)
      if (Number(parsedValue) > 100) parsedValue = "100"

      setMinRates((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: parsedValue,
        },
      }))
    },
    [setMinRates],
  )

  const handleToggleMinRatesActive = useCallback(
    ({ name }) => {
      setMinRates((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          active: !prevState[name]?.active,
        },
      }))
    },
    [setMinRates],
  )

  const handleChangeLimit = useCallback(compose(setLimit, formatValue), [
    setLimit,
  ])

  const handleSaveComplete = useCallback(() => {
    setPending(false)
    setEditing(false)
    setIsActive(true)

    setShowSaveInfo(true)
    setConfirmation({})
  }, [setPending, setEditing, setIsActive, setShowSaveInfo, setConfirmation])

  const onError = useCallback(() => {
    setPending(false)
    setConfirmation({})
  }, [setPending, setConfirmation]) // TODO: Сделать обработчик ошибок

  const handleDeleteComplete = useCallback(() => {
    setPending(false)
    setEditing(true)

    setIsActive(false)
    setOrderLimit(separate(defaultOrderLimit))
    setBorrowerLimit(separate(defaultBorrowerLimit))
    setLimit(separate(defaultLimit))
    setMinRates(defaultMinRates)

    setConfirmation({})
    setShowDeleteInfo(true)
  }, [
    setPending,
    setEditing,
    setIsActive,
    setOrderLimit,
    setBorrowerLimit,
    setLimit,
    setMinRates,
    setConfirmation,
    setShowDeleteInfo,
  ])

  const handleCloseConfirmation = useCallback(() => {
    setPending(false)
    setConfirmation({})
  }, [setPending, setConfirmation])

  const handleSubmit = useCallback(() => {
    if (!isAgreed) return

    setPending(true)

    const variables = {
      input: {
        profileId,
        isActive: true,
        orderLimit: Number(number(orderLimit)),
        borrowerLimit: Number(number(borrowerLimit)),
        minRates: minRatesToVariables(minRates),
        limit: Number(number(limit)),
        cessionEnable: isCessionEnabled,
      },
    }

    setConfirmation({
      isOpened: true,
      mutation: SetInvestorAutoInvestmentsParams,
      mutationName: "setInvestorAutoInvestmentsParams",
      title: "Подтвердить изменение настроек",
      variables,
      onClose: handleCloseConfirmation,
      onComplete: handleSaveComplete,
      onError,
    })
  }, [
    isAgreed,
    setPending,
    profileId,
    orderLimit,
    borrowerLimit,
    minRates,
    limit,
    isCessionEnabled,
    setConfirmation,
    handleCloseConfirmation,
    handleSaveComplete,
    onError,
  ])

  const handleDelete = useCallback(() => {
    setPending(true)

    const variables = {
      input: {
        profileId,
      },
    }

    setConfirmation({
      isOpened: true,
      mutation: RemoveInvestorAutoInvestmentsParams,
      mutationName: "removeInvestorAutoInvestmentsParams",
      title: "Подтвердить удаление настроек",
      variables,
      onClose: handleCloseConfirmation,
      onComplete: handleDeleteComplete,
      onError,
    })
  }, [
    profileId,
    setPending,
    setConfirmation,
    handleCloseConfirmation,
    handleDeleteComplete,
    onError,
  ])

  const handleEdit = useCallback(() => setEditing(true), [setEditing])

  const handleCloseSaveInfo = useCallback(() => setShowSaveInfo(false), [
    setShowSaveInfo,
  ])
  const handleCloseDeleteInfo = useCallback(() => setShowDeleteInfo(false), [
    setShowDeleteInfo,
  ])

  return (
    <Wrapper>
      <MainTitle>Автоинвестирование</MainTitle>

      {isActive && <ActiveSubTitle>Активировано</ActiveSubTitle>}
      {!isActive && <DisabledSubTitle>Отключено</DisabledSubTitle>}

      <InputWrapper>
        <InputContainer>
          <Input
            value={limit}
            onChange={handleChangeLimit}
            label="Общий лимит автоинвестирования"
            placeholder={isEditing ? "Введите сумму" : ""}
            adornment="₽"
            disabled={isPending || !isEditing}
          />
        </InputContainer>
        <InputDescription maxWidth={500}>
          На данный момент использовано {separate(currentAutoInvested)} ₽
        </InputDescription>
      </InputWrapper>

      <InputWrapper>
        <InputContainer>
          <Input
            value={orderLimit}
            onChange={handleChangeOrderLimit}
            label="Сумма инвестирования в одну заявку"
            placeholder={isEditing ? "Введите сумму" : ""}
            adornment="₽"
            disabled={isPending || !isEditing}
          />
        </InputContainer>
        <InputDescription maxWidth={680}>
          Как только на рынке появится новая заявка, соответствующая вашим
          критериям, денежные средства в размере указанной суммы будут
          инвестированы в заявку. Если остаток ДС меньше указанной суммы, но
          больше 25 000 рублей, то будет инвестирована доступная сумма.
        </InputDescription>
      </InputWrapper>

      <InputWrapper>
        <InputContainer>
          <Input
            value={borrowerLimit}
            onChange={handleChangeBorrowerLimit}
            label="Лимит долга на одного заемщика"
            placeholder={isEditing ? "Введите сумму" : ""}
            adornment="₽"
            disabled={isPending || !isEditing}
          />
        </InputContainer>
        <InputDescription maxWidth={500}>
          Один и тот же заемщик может выставлять на платформе несколько заявок.
          Укажите максимальную сумму инвестирования в одного заемщика
          (рекомендуется 5-10% от портфеля).
        </InputDescription>
      </InputWrapper>

      <MainContainer>
        <MainDescription maxWidth={540}>
          Укажите инвестиционные рейтинги заявок, в которые вы готовы
          инвестировать и минимальную процентную ставку по договору займа для каждого рейтинга.
        </MainDescription>

        <RatingContainer>
          {ratings.map((rating) => {
            const name = rating.title.toLowerCase()
            return (
              <RatingBlock
                {...minRates[name]}
                onChange={handleChangeMinRatesValue}
                onClick={handleToggleMinRatesActive}
                name={name}
                key={name}
                disabled={isPending || !isEditing}
                {...rating}
              />
            )
          })}
        </RatingContainer>
      </MainContainer>

      <ActionContainer>
        <CheckboxContainer>
          <CheckBoxField
            checked={isCessionEnabled}
            onChange={handleCessionEnable}
            name="cessionEnabled"
            fill="#F70000"
            color="#4a4a4a"
            fontSize="14px"
            label="Инвестировать в цессии"
            disabled={isPending || !isEditing}
          />
        </CheckboxContainer>

        {isEditing && (
          <CheckboxContainer>
            <CheckBoxField
              checked={isAgreed}
              onChange={handleChangeAgreed}
              name="agreement"
              fill="#F70000"
              color="#4a4a4a"
              fontSize="14px"
              label={
                "Нажимая кнопку \"Сохранить\", я полностью соглашаюсь с условиями и подписываю Поручение инвестора на автоинвестирование"
              }
              disabled={isPending}
            />
          </CheckboxContainer>
        )}

        <ButtonContainer>
          {isEditing && (
            <PrimaryButton onClick={handleSubmit} disabled={buttonIsDisabled}>
              сохранить настройки
            </PrimaryButton>
          )}

          {!isEditing && (
            <>
              <Button onClick={handleEdit}>редактировать</Button>
              <Button onClick={handleDelete} disabled={isPending}>
                удалить
              </Button>
            </>
          )}
        </ButtonContainer>
      </ActionContainer>

      <ConfirmMutationModal {...confirmation} profile={props.node} />
      <SaveInfo isOpened={showSaveInfo} onClose={handleCloseSaveInfo} />
      <DeleteInfo isOpened={showDeleteInfo} onClose={handleCloseDeleteInfo} />
    </Wrapper>
  )
}

const query = graphql`
  query PortfolioAutoInvestQuery($profileId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        id
        __typename
      }
      ... on IndividualProfile {
        phone
        investor {
          autoInvestments {
            isActive
            orderLimit
            borrowerLimit
            minRates {
              rating
              minRate
            }
            limit
            cessionEnable
          }
          investments {
            edges {
              node {
                order {
                  status
                }
                autoAmount
              }
            }
          }
        }
      }
      ... on EntrepreneurProfile {
        phone
        investor {
          autoInvestments {
            isActive
            orderLimit
            borrowerLimit
            minRates {
              rating
              minRate
            }
            limit
            cessionEnable
          }
          investments {
            edges {
              node {
                order {
                  status
                }
                autoAmount
              }
            }
          }
        }
      }
      ... on ForeignIndividualProfile {
        foreignPhone: phone
        investor {
          autoInvestments {
            isActive
            orderLimit
            borrowerLimit
            minRates {
              rating
              minRate
            }
            limit
            cessionEnable
          }
          investments {
            edges {
              node {
                order {
                  status
                }
                autoAmount
              }
            }
          }
        }
      }

      ... on LegalEntityProfile {
        phone
        investor {
          autoInvestments {
            isActive
            orderLimit
            borrowerLimit
            minRates {
              rating
              minRate
            }
            limit
            cessionEnable
          }
          investments {
            edges {
              node {
                order {
                  status
                }
                autoAmount
              }
            }
          }
        }
      }
    }
  }
`

function Render() {
  const { profile } = useContext(CurrentProfileContext)
  const history = useHistory()

  if (!profile) {
    history.push("/orders/my")
  }

  const variables = {
    profileId: profile.id,
  }

  return (
    <QueryRenderer
      query={query}
      render={(queryProps) => <PortfolioAutoInvest {...queryProps} />}
      variables={variables}
    />
  )
}

export default Render
