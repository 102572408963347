import React from "react"

import { setUtmContent } from "src/utils"

const UtmWatcher = () => {
  React.useEffect(() => setUtmContent(), [])

  return null
}

export default UtmWatcher
