import styled from "styled-components"

import { Heading, PlusIcon } from "src/components"

const newHeadingStyles = ({ inputsType }) => {
  if (inputsType && inputsType === "new") {
    return "font-size: 18px !important; font-weight: bold; line-height: 1.56; max-width: 680px;"
  }

  return "line-height: 1.3;"
}

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledHeading = styled(Heading.h5)`
  ${newHeadingStyles};
  font-weight: bold !important;
`

/* TODO: unused - вернуть или удалить окончательно
const oldSchemaPadding = (subtitle) => `padding: 65px 0 ${subtitle ? 0 : "40px"};`
const newSchemaPadding = () => "padding: 20px 0 30px;"

const padding = ({ subtitle, inputsType }) => (inputsType === "new" ? newSchemaPadding() : oldSchemaPadding(subtitle))
*/

export const HeadingWrapper = styled.div<any>`
  padding: 0 0 25px 0;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primaryBlack};

  &:not(:first-child) {
    padding-top: 60px;
  }
`

export const RemoveIcon = styled(PlusIcon)`
  transform: rotate(-45deg);
`

export const RemoveButtonWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  padding-top: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`

export const AddButtonWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  padding-top: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const SubtitleContainer = styled.div`
  padding: 14px 0 40px;
  width: 580px;
`
