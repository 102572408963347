import React from "react"
import moment from "moment"
import i18next from "i18next"
import Relay, { graphql } from "react-relay"

import { Translate } from "src/components"

import {
  DateBox,
  StyledLink,
  TransactionContainer,
  SenderContainer,
  DestinationContainer,
  Text,
  SubText,
  ArrowBox,
  ValueContainer,
  ValueText,
  ValueSubText,
  AlertIconContainer,
  TitleContainer,
} from "./styles"
import { useTransactionAmountProps, useTransactionDestinations } from "./hooks"
import { ArrowIcon, AlertIcon } from "./icons"
import Tooltip from "./Tooltip"

const TransactionItem = (props) => {
  const { language } = i18next
  const { transaction } = props
  const { loan = {} } = transaction || {}
  const profile = props.profile || props.foreignProfile

  const momentDate = moment(transaction.date)
  const amountProps = useTransactionAmountProps(transaction, profile)
  const destinations = useTransactionDestinations(transaction, profile)

  return (
    <TransactionContainer>
      <DateBox>
        <Text>{momentDate.locale(language).format("D MMM")}</Text>
        <SubText>{momentDate.locale(language).format("YYYY")}</SubText>
      </DateBox>

      <SenderContainer>
        {transaction.type === "PURCHASE_LOAN" && (
          <TitleContainer>
            <Text style={{ width: "auto" }}>
              {transaction.isAuto && "Ⓐ "}
              <Translate i18n={destinations.from.title.label} />
            </Text>
            <Tooltip
              isSeller={profile.id === transaction?.toAccount?.profile?.id}
              amount={loan.amount || transaction.amount || "-"}
            >
              <AlertIconContainer>
                <AlertIcon />
              </AlertIconContainer>
            </Tooltip>
          </TitleContainer>
        )}

        {transaction.type !== "PURCHASE_LOAN" && (
          <Text>
            {transaction.isAuto && "Ⓐ "}
            <Translate i18n={destinations.from.title.label} />
          </Text>
        )}

        {destinations.from.subtitle.url ? (
          <StyledLink to={destinations.from.subtitle.url}>
            {destinations.from.subtitle.label}
          </StyledLink>
        ) : (
          <SubText>{destinations.from.subtitle.label}</SubText>
        )}
      </SenderContainer>

      <ArrowBox>
        <ArrowIcon />
      </ArrowBox>

      <DestinationContainer>
        {destinations.to.title.url ? (
          <StyledLink to={destinations.to.title.url}>
            {destinations.to.title.label}
          </StyledLink>
        ) : (
          <Text>
            <Translate i18n={destinations.to.title.label} />
          </Text>
        )}
        {destinations.to.subtitle.url ? (
          <StyledLink to={destinations.to.subtitle.url}>
            {destinations.to.subtitle.label}
          </StyledLink>
        ) : (
          <SubText>
            <Translate i18n={destinations.to.subtitle.label} />
          </SubText>
        )}
      </DestinationContainer>

      <ValueContainer>
        <ValueText color={amountProps.color}>
          {amountProps.sign} {amountProps.amount} ₽
        </ValueText>
        {amountProps.tooltip && (
          <ValueSubText color={amountProps.tooltipColor}>
            <Translate
              i18n={`components:account.transactions_history.amount_tooltips.${amountProps.tooltip}`}
            />
          </ValueSubText>
        )}
      </ValueContainer>
    </TransactionContainer>
  )
}

export default Relay.createFragmentContainer(TransactionItem, {
  transaction: graphql`
    fragment TransactionItem_transaction on FinancialTransactionInterface {
      id
      date
      type
      error
      state
      amount
      isAuto
      description
      toAccount {
        id
        description
        ... on FinancialTransactionProfileAccount {
          profile {
            id
            name
          }
          foreignProfile {
            id
            firstName
            lastName
          }
        }
        ... on FinancialTransactionSharedWalletAccount {
          order {
            id
            application {
              shortTitle
              longTitle
            }
            chain {
              id
            }
            profile {
              id
              name
              ... on LegalEntityProfile {
                borrower {
                  ticker
                }
              }
            }
          }
        }
      }
      fromAccount {
        id
        description
        ... on FinancialTransactionProfileAccount {
          profile {
            id
            name
          }
          foreignProfile {
            id
            firstName
            lastName
          }
        }
        ... on FinancialTransactionSharedWalletAccount {
          order {
            id
            application {
              shortTitle
              longTitle
            }
            chain {
              id
            }
            profile {
              id
              name
              ... on LegalEntityProfile {
                borrower {
                  ticker
                }
              }
            }
          }
        }
      }
      ... on InvestmentTransaction {
        loan {
          amount
          order {
            id
            application {
              shortTitle
              longTitle
            }
            chain {
              id
            }
            profile {
              id
              name
              ... on LegalEntityProfile {
                borrower {
                  ticker
                }
              }
            }
          }
        }
      }
    }
  `,
})
