/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type InstantPurchaseOrderInput = {
    orderId: string;
    profileId: string;
    amount: number;
    token: string;
    source?: string | null;
};
export type InstantPurchaseOrderMutationVariables = {
    input: InstantPurchaseOrderInput;
    orderId: string;
};
export type InstantPurchaseOrderMutationResponse = {
    readonly instantPurchaseOrder: {
        readonly order: {
            readonly id: string;
            readonly chain: {
                readonly gatheredAmount: number;
            } | null;
            readonly viewer: {
                readonly investedAmount: number;
            };
        };
        readonly profile: {
            readonly id?: string;
            readonly accountBalanceDetailed?: ReadonlyArray<{
                readonly available: number;
                readonly currency: CurrencyEnum;
            }>;
            readonly investment?: {
                readonly id: string;
                readonly amount: number;
            } | null;
        };
    };
};
export type InstantPurchaseOrderMutation = {
    readonly response: InstantPurchaseOrderMutationResponse;
    readonly variables: InstantPurchaseOrderMutationVariables;
};



/*
mutation InstantPurchaseOrderMutation(
  $input: InstantPurchaseOrderInput!
  $orderId: ID!
) {
  instantPurchaseOrder(input: $input) {
    order {
      id
      chain {
        gatheredAmount
        id
      }
      viewer {
        investedAmount
      }
    }
    profile {
      __typename
      ... on IndividualProfile {
        id
        accountBalanceDetailed {
          available
          currency
        }
        investment(orderId: $orderId) {
          id
          amount
        }
      }
      ... on EntrepreneurProfile {
        id
        accountBalanceDetailed {
          available
          currency
        }
        investment(orderId: $orderId) {
          id
          amount
        }
      }
      ... on ForeignIndividualProfile {
        id
        investment(orderId: $orderId) {
          id
          amount
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "InstantPurchaseOrderInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gatheredAmount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderViewer",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investedAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "orderId",
      "variableName": "orderId"
    }
  ],
  "concreteType": "Investment",
  "kind": "LinkedField",
  "name": "investment",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InstantPurchaseOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InstantPurchaseOrderPayload",
        "kind": "LinkedField",
        "name": "instantPurchaseOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InstantPurchaseOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InstantPurchaseOrderPayload",
        "kind": "LinkedField",
        "name": "instantPurchaseOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "EntrepreneurProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/)
                ],
                "type": "ForeignIndividualProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InstantPurchaseOrderMutation",
    "operationKind": "mutation",
    "text": "mutation InstantPurchaseOrderMutation(\n  $input: InstantPurchaseOrderInput!\n  $orderId: ID!\n) {\n  instantPurchaseOrder(input: $input) {\n    order {\n      id\n      chain {\n        gatheredAmount\n        id\n      }\n      viewer {\n        investedAmount\n      }\n    }\n    profile {\n      __typename\n      ... on IndividualProfile {\n        id\n        accountBalanceDetailed {\n          available\n          currency\n        }\n        investment(orderId: $orderId) {\n          id\n          amount\n        }\n      }\n      ... on EntrepreneurProfile {\n        id\n        accountBalanceDetailed {\n          available\n          currency\n        }\n        investment(orderId: $orderId) {\n          id\n          amount\n        }\n      }\n      ... on ForeignIndividualProfile {\n        id\n        investment(orderId: $orderId) {\n          id\n          amount\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '76ea9f1aa23232298109200f7ed46076';
export default node;
