/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type KindPhoneConfirmation = "APPROVE_PERSONAL_DATA_AGREEMENT" | "CANCEL_INVESTMENT" | "CONFIRM_BANK_ACCOUNT_WITH_PHONE" | "DELETE_PROFILE" | "EXCHANGE_CURRENCY" | "INSTANT_PURCHASE_ORDER" | "INVEST_TO_ORDER" | "REMOVE_INVESTOR_AUTO_INVESTMENTS_PARAMS" | "REQUEST_ORDER_APPROVAL" | "REQUEST_PROFILE_ACCREDITATION" | "REQUEST_WITHDRAWAL" | "SELL_ORDER" | "SET_INVESTOR_AUTO_INVESTMENTS_PARAMS" | "UPDATE_SUPPORTING_DOCUMENTS" | "%future added value";
export type RequestProfilePhoneConfirmationInput = {
    profileId: string;
    kind?: KindPhoneConfirmation | null;
    orderId?: string | null;
    tags?: Array<KindPhoneConfirmation> | null;
    attachments?: Array<string> | null;
    data?: string | null;
};
export type RequestProfilePhoneConfirmationMutationVariables = {
    input: RequestProfilePhoneConfirmationInput;
};
export type RequestProfilePhoneConfirmationMutationResponse = {
    readonly requestProfilePhoneConfirmation: {
        readonly status: boolean;
    };
};
export type RequestProfilePhoneConfirmationMutation = {
    readonly response: RequestProfilePhoneConfirmationMutationResponse;
    readonly variables: RequestProfilePhoneConfirmationMutationVariables;
};



/*
mutation RequestProfilePhoneConfirmationMutation(
  $input: RequestProfilePhoneConfirmationInput!
) {
  requestProfilePhoneConfirmation(input: $input) {
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestProfilePhoneConfirmationInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestProfilePhoneConfirmationPayload",
    "kind": "LinkedField",
    "name": "requestProfilePhoneConfirmation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestProfilePhoneConfirmationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestProfilePhoneConfirmationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RequestProfilePhoneConfirmationMutation",
    "operationKind": "mutation",
    "text": "mutation RequestProfilePhoneConfirmationMutation(\n  $input: RequestProfilePhoneConfirmationInput!\n) {\n  requestProfilePhoneConfirmation(input: $input) {\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e52ee2f4376667e595f20971f9024a6c';
export default node;
