import React from "react"

import { PopupWindow } from "./styles"
import { ExitIcon } from "./icons"

const ProposalPopup = ({ isVisible, onClose }) => (
  <>
    {isVisible && (
      <>
        <PopupWindow>
          <span onClick={onClose}>
            <ExitIcon />
          </span>
          <p>
            Для того чтобы начать инвестировать, вам необходимо пройти
            аккредитацию.{" "}
          </p>
          <button>ПРОЙТИ АККРЕДИТАЦИЮ</button>
        </PopupWindow>
      </>
    )}
  </>
)

export default ProposalPopup
