import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query MarketOrderInfoQuery($id: ID!) {
    node(id: $id) {
      ...DebtPaymentData_order
      ...InstructionsModal_order
      id
      __typename
      ... on Order {
        viewer {
          hasIntent
          investedAmount
        }
        tags {
          name
        }
        createdAt
        status
        statusFrom
        externalUrl
        fee
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              inn
              ogrn
              executive {
                name
              }
            }
            creditRating {
              rating
            }
            _avatar {
              url
            }
          }
        }
        offer {
          ... on OrderOffer {
            id
            data
            declineReason
            receivedAt
          }
        }
        application {
          ... on OrderApplication {
            id
            data
            declineReason
            receivedAt
          }
        }
        confirmedAt
        creditRating {
          rating
        }
        otherDocuments {
          attachment {
            url
            filename
          }
          description
        }
        cession {
          due
          amount
          isActive
          interestRate
          borrowerName
          originalChainId
          avatar {
            url
          }
          borrowerRating {
            rating
            ratedAt
          }
        }
        skipOffer
        karma {
          isInvestor
          isTrusted
        }
        chain {
          id
          gatheredAmount
          investorsCount
        }
        guarantors {
          attachment {
            url
            filename
          }
          name
          description
        }
        borrowerOffer: document(type: BORROWER_OFFER) {
          url
          type
        }
        provision {
          items {
            kind
          }
        }
      }
    }
  }
`

const container = (render) => (props) => {
  const { orderId, ...rest } = props

  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
