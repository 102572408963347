/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type PortfolioQueryVariables = {
    profileId: string;
};
export type PortfolioQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly __typename?: string;
        readonly accountBalanceDetailed?: ReadonlyArray<{
            readonly available: number;
            readonly blocked: number;
            readonly invested: number;
        }>;
        readonly investor?: {
            readonly isApproved: boolean;
            readonly accreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
            readonly autoInvestments: {
                readonly isActive: boolean;
            } | null;
            readonly investments: {
                readonly count: number;
            };
        } | null;
    };
};
export type PortfolioQuery = {
    readonly response: PortfolioQueryResponse;
    readonly variables: PortfolioQueryVariables;
};



/*
query PortfolioQuery(
  $profileId: ID!
) {
  node(id: $profileId) {
    __typename
    ... on UserProfile {
      id
      __typename
      ... on IndividualProfile {
        accountBalanceDetailed {
          available
          blocked
          invested
        }
        investor {
          isApproved
          accreditation {
            status
          }
          autoInvestments {
            isActive
          }
          investments {
            count
          }
        }
      }
      ... on EntrepreneurProfile {
        accountBalanceDetailed {
          available
          blocked
          invested
        }
        investor {
          isApproved
          accreditation {
            status
          }
          autoInvestments {
            isActive
          }
          investments {
            count
          }
        }
      }
      ... on ForeignIndividualProfile {
        accountBalanceDetailed {
          available
          blocked
          invested
        }
        investor {
          isApproved
          accreditation {
            status
          }
          autoInvestments {
            isActive
          }
          investments {
            count
          }
        }
      }
      ... on LegalEntityProfile {
        investor {
          isApproved
          accreditation {
            status
          }
          investments {
            count
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invested",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestmentConnection",
  "kind": "LinkedField",
  "name": "investments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AutoInvestmentsParams",
    "kind": "LinkedField",
    "name": "autoInvestments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Investor",
    "kind": "LinkedField",
    "name": "investor",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "profileId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualProfileInvestor",
            "kind": "LinkedField",
            "name": "investor",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "type": "IndividualProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v6/*: any*/),
        "type": "EntrepreneurProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v6/*: any*/),
        "type": "ForeignIndividualProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Investor",
            "kind": "LinkedField",
            "name": "investor",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "type": "LegalEntityProfile"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortfolioQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PortfolioQuery",
    "operationKind": "query",
    "text": "query PortfolioQuery(\n  $profileId: ID!\n) {\n  node(id: $profileId) {\n    __typename\n    ... on UserProfile {\n      id\n      __typename\n      ... on IndividualProfile {\n        accountBalanceDetailed {\n          available\n          blocked\n          invested\n        }\n        investor {\n          isApproved\n          accreditation {\n            status\n          }\n          autoInvestments {\n            isActive\n          }\n          investments {\n            count\n          }\n        }\n      }\n      ... on EntrepreneurProfile {\n        accountBalanceDetailed {\n          available\n          blocked\n          invested\n        }\n        investor {\n          isApproved\n          accreditation {\n            status\n          }\n          autoInvestments {\n            isActive\n          }\n          investments {\n            count\n          }\n        }\n      }\n      ... on ForeignIndividualProfile {\n        accountBalanceDetailed {\n          available\n          blocked\n          invested\n        }\n        investor {\n          isApproved\n          accreditation {\n            status\n          }\n          autoInvestments {\n            isActive\n          }\n          investments {\n            count\n          }\n        }\n      }\n      ... on LegalEntityProfile {\n        investor {\n          isApproved\n          accreditation {\n            status\n          }\n          investments {\n            count\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '46b7a7d7d0572c52edd13704d4d030ec';
export default node;
