import React from "react"

/* eslint-disable max-len */

export const ArrowIcon = () => (
  <svg
    width="19"
    height="8"
    viewBox="0 0 19 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 4L15 0V3H0V5H15V8L19 4Z" fill="#4A4A4A" fillOpacity="0.45" />
  </svg>
)

export const DownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 10V15.8333H4.16667V10H2.5V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V10H15.8333ZM10.8333 10.5583L12.9917 8.40833L14.1667 9.58333L10 13.75L5.83333 9.58333L7.00833 8.40833L9.16667 10.5583V2.5H10.8333V10.5583Z"
      fill="#3A5BBC"
    />
  </svg>
)

export const AlertIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85 8.45H7.15V9.75H5.85V8.45ZM5.85 3.25H7.15V7.15H5.85V3.25ZM6.4935 0C2.9055 0 0 2.912 0 6.5C0 10.088 2.9055 13 6.4935 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.4935 0ZM6.5 11.7C3.627 11.7 1.3 9.373 1.3 6.5C1.3 3.627 3.627 1.3 6.5 1.3C9.373 1.3 11.7 3.627 11.7 6.5C11.7 9.373 9.373 11.7 6.5 11.7Z"
      fill="#F70000"
    />
  </svg>
)
