import { getCessionRedemptionDate } from "../OrderFactory/entities/helpers/mobileOrder"

export function getTicker(data) {
  const { chain, profile } = data
  const number = chain.id
  const numberView = number.split(".")[2]
  const { ticker } = profile.borrower

  return `${ticker || ""}${numberView || ""}`
}

export function getRating(data) {
  const { profile, cession } = data
  const rating = cession
    ? cession.originalOrder.profile.creditRating
    : profile.creditRating

  if (!rating || !rating.rating) {
    return "-"
  }

  return rating.rating
}

export function getInterest(data) {
  const { offer, cession } = data
  const interest = cession ? cession.interestRate : offer.data.interestRate

  if (!interest) {
    return "0"
  }

  return interest
}

export function getPaymentDate(data) {
  const date = getCessionRedemptionDate(data)
  if (!date) return "-"

  const [year, month, day] = date.split("-")
  return `${day}.${month}.${year.slice(-2)}`
}

export function getRepaymentType(data) {
  const { offer, cession } = data
  const repaymentSchedule = cession
    ? cession.originalOrder?.offer?.data?.repaymentSchedule
    : offer?.data?.repaymentSchedule

  if (repaymentSchedule && repaymentSchedule.type) {
    if (repaymentSchedule.type === "end") {
      return "В конце"
    }

    if (repaymentSchedule.type === "equal_share") {
      return "Частями"
    }
  }

  return "-"
}

export function getRepaymentDescription(data) {
  const { offer, cession } = data
  const repaymentSchedule = cession
    ? cession.originalOrder?.offer?.data?.repaymentSchedule
    : offer?.data?.repaymentSchedule

  if (
    repaymentSchedule
    && repaymentSchedule.type
    && repaymentSchedule.type === "equal_share"
  ) {
    return `с ${repaymentSchedule.shareDate}-го месяца`
  }

  return ""
}
