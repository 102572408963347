import { graphql } from "react-relay"

const russianEntrepreneurFragment = graphql`
  fragment RussianEntrepreneurFragment_profile on Node {
    id
    __typename
    ... on EntrepreneurProfile {
      lastName
      firstName
      middleName
      phone
      isApproved
      approvedOnce
      isRussiaTaxResident
      sex
      inn
      iian
      psrnie
      birthDate
      birthPlace
      isPublicOfficial
      address: registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
        }
      }
      passport {
        ... on Passport {
          series
          number
          issuedAt
          postponeScans
          scans {
            id
            url
            filename
          }
          issuedBy {
            ... on PassportIssuer {
              code
              name
            }
          }
        }
      }
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  }
`

export default russianEntrepreneurFragment
