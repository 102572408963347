const buildPath = (base) => (path, queryParams?: any, externalUrl?: any) => {
  if (externalUrl) {
    return externalUrl
  }

  let result = `/${base}`

  if (path) {
    result += `/${path}`
  }

  if (Array.isArray(queryParams)) {
    const params = new URLSearchParams()
    queryParams.forEach((item) => {
      const [key, value] = item
      params.set(key, value)
    })

    result += `?${params.toString()}`
  }

  return result
}

export default new Map([
  ["PENDING", null],
  [
    "DEAD",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
  [
    "INITIAL",
    {
      path: buildPath("orders"),
      label: "components:form_builder.actions.edit",
    },
  ],
  [
    "DECLINED",
    {
      path: buildPath("orders"),
      label: "components:form_builder.actions.edit",
    },
  ],
  [
    "APPROVED",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
  [
    "CONFIRMED",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
  [
    "TRANSFER",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
  [
    "COMPLETE",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
  [
    "REJECTED",
    {
      path: buildPath("market"),
      label: "components:form_builder.actions.view",
    },
  ],
])
