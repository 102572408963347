/* eslint-disable max-len */

import React from "react"

export const DownloadIcon = (props) => (
  <svg {...props} viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.03337 6.60556L5.2917 6.60556L5.2917 0.8L4.13059 0.8L4.13059 6.60556L2.38892 6.60555L4.71115 8.92778L7.03337 6.60556ZM9.35559 11.25L9.35559 10.0889L0.0667001 10.0889L0.0667 11.25L9.35559 11.25Z"
      fill="#3A5BBC"
    />
  </svg>
)

DownloadIcon.defaultProps = {
  width: "12px",
  height: "12px",
  fill: "#4a4a4a",
}
