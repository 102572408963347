import styled from "styled-components"

import { Text } from "src/components"

export const DescriptionContainer = styled.p`
  margin: 0;
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.greyDarker};
`

export const SubHeader = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
`

export const SpacedSubHeader = styled(SubHeader)`
  margin-bottom: 20px;
`

export const DescriptionBlock = styled.div`
  margin-bottom: 50px;
`

export const StyledLink = styled.a`
  text-decoration: none;
`
