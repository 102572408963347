const InitialBorrowerData = {
  inn: "",
  ogrn: "",
  kpp: "",
  iian: "",
  name: "",
  publicEmail: "",
  address: {
    region: "",
    area: "",
    locality: "",
    street: "",
    house: "",
    housing: "",
    structure: "",
    flat: "",
  },
  actualAddress: {
    region: "",
    area: "",
    locality: "",
    street: "",
    house: "",
    housing: "",
    structure: "",
    flat: "",
  },
  business: {
    main: "",
    aux: "",
    startedAt: "",
    legalForm: "",
  },
  bankAccounts: [
    {
      bank: {
        bic: "",
        name: "",
      },
      checkingAccount: "",
      correspondentAccount: "",
    },
  ],
  addressMatch: false,
  passport: {
    series: "",
    number: "",
    issuedAt: "",
    issuedBy: {
      code: "",
      name: "",
    },
    scans: [],
  },
}

export default InitialBorrowerData
