import React, { useState } from "react"
import Tooltip from "rc-tooltip"

import { divideNumber } from "src/utils"

import {
  GlobalStyle,
  TooltipContainer,
  TooltipText,
  TooltipCloseBtn,
} from "./styles"

import { closeIcon } from "./icons"

function Content(props) {
  const { minValue, restAmount, onClose } = props

  return (
    <TooltipContainer>
      <TooltipCloseBtn onClick={onClose}>{closeIcon}</TooltipCloseBtn>
      <TooltipText>
        Минимальная сумма займа, необходимая проекту: {divideNumber(minValue)} ₽
      </TooltipText>
      <TooltipText>Осталось собрать: {divideNumber(restAmount)} ₽</TooltipText>
    </TooltipContainer>
  )
}

function Render(props) {
  const { children, minValuePercent, ...rest } = props
  const [isVisible, setVisible] = useState(false)

  function onClose() {
    setVisible(false)
  }

  const placement = minValuePercent < 50 ? "bottomLeft" : "bottomRight"
  const arrowPlacementPercent = minValuePercent < 50 ? minValuePercent + 1.5 : 98 - minValuePercent

  return (
    <>
      <Tooltip
        visible={isVisible}
        onVisibleChange={(visible) => setVisible(visible)}
        placement={placement}
        overlay={() => <Content onClose={onClose} {...rest} />}
      >
        {children}
      </Tooltip>
      <GlobalStyle percent={arrowPlacementPercent} placement={placement} />
    </>
  )
}

export default Render
