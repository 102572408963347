import React from "react"

import List from "./List"
import { Label } from "./elements"
import { Container } from "./styles"

// equals to 5MB
const MAX_FILE_SIZE = 5242880

const dropHandler = (injectedOnDrop) => (isLoading) => (event) => !isLoading && injectedOnDrop(event)

const RedesignedList = (props) => {
  const {
    width,
    accept,
    onDrop,
    disabled,
    onRemove,
    multiple,
    isLoading,
    attachments,
  } = props

  return (
    <Container
      accept={accept}
      onDrop={dropHandler(onDrop)(isLoading)}
      multiple={multiple}
      cursor={isLoading ? "not-allowed" : "pointer"}
      disabled={isLoading || disabled}
      width={width}
      maxSize={MAX_FILE_SIZE}
    >
      <List onRemove={onRemove} attachments={attachments} />
      <Label isLoading={isLoading} />
    </Container>
  )
}

RedesignedList.defaultProps = {
  width: "100%",
}

export default RedesignedList
