import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query AccreditationInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on LegalEntityProfile {
        name
        isApproved
        inn
        iian
        inn
        kpp
        ogrn
        name
        business {
          ... on Business {
            main
            aux
            legalForm
            startedAt
          }
        }
        executive {
          ... on Person {
            name
          }
        }
        address {
          ... on Address {
            raw
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
        actualAddress {
          ... on Address {
            raw
            country
            region
            area
            locality
            street
            house
            housing
            structure
            flat
            okato
            oktmo
            settlement
            postalCode
            cityDistrict
          }
        }
        passport {
          ... on Passport {
            scans {
              id
              url
              filename
            }
          }
        }
        borrower {
          publicEmail
        }
      }
    }
  }
`

export default (render) => ({ profileId, ...rest }) => {
  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}
