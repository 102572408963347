import React from "react"
import memoize from "memoize-one"
import PropTypes from "prop-types"

import { TextField, Translate, FormRowItem } from "src/components"

import Input from "../Input"

const checkingAccountLabel = memoize(() => (
  <Translate i18n="bank-account-input.checking-account.label" ns="components" />
))

const correspondentAccountLabel = memoize(() => (
  <Translate
    i18n="bank-account-input.correspondent-account.label"
    ns="components"
  />
))

const fields = [
  {
    name: "bank",
    suggest: true,
    render: (props) => <Input {...props} />,
  },
  {
    row: true,
    name: "correspondentAccount",
    label: correspondentAccountLabel,
    render: (props) => <TextField {...props} />,
  },
  {
    row: true,
    name: "checkingAccount",
    label: checkingAccountLabel,
    render: (props) => <TextField {...props} />,
  },
]

class BankAccountInput extends React.PureComponent<any> {
  onChange = (event) => {
    const { name, value } = event.target

    this.props.onChange({
      name: this.props.name,
      value: (BankAccountInput as any).toGraphQLInput({
        ...this.props.value,
        [name]: value,
      }),
    })
  }

  onSuggestionSelected = (suggestion) => {
    this.props.onChange({
      name: this.props.name,
      value: (BankAccountInput as any).toGraphQLInput({
        ...this.props.value,
        bank: {
          bic: suggestion.data.bic,
          name: suggestion.data.name.payment,
        },
        correspondentAccount: suggestion.data.correspondent_account,
      }),
    })
  }

  renderField = (field) => {
    const props = {
      key: field.name,
      name: field.name,
      value: this.props.value[field.name],
      onChange: this.onChange,
      ...field.props,
    }

    if (field.suggest) props.onSuggestionSelected = this.onSuggestionSelected
    const content = field.render(props)

    if (field.row === true) {
      return (
        <FormRowItem
          key={content.key}
          label={field.label()}
          first={field.first}
        >
          {content}
        </FormRowItem>
      )
    }

    return content
  }

  render() {
    return fields.map(this.renderField)
  }
}

(BankAccountInput as any).propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    bank: (Input as any).propTypes.value,
    checkingAccount: PropTypes.string.isRequired,
    correspondentAccount: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};

(BankAccountInput as any).fromGraphQLType = (attrs) => {
  const attributes = attrs || {}

  return {
    bank: (Input as any).fromGraphQLType(attributes.bank),
    checkingAccount: attributes.checkingAccount || "",
    correspondentAccount: attributes.correspondentAccount || "",
  }
}

(BankAccountInput as any).toGraphQLInput = (attrs) => ({
  bank: (Input as any).toGraphQLInput(attrs.bank),
  checkingAccount: attrs.checkingAccount,
  correspondentAccount: attrs.correspondentAccount,
})

export default BankAccountInput
