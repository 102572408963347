import React from "react"

import { Translate } from "src/components"

import List from "./List"
import { SubHeader, VideoWrapper } from "./styles"

const render = ({ descriptionVideo = {} }: any) => {
  const { video } = descriptionVideo

  if (!video || !video.url) return null

  return (
    <React.Fragment>
      <SubHeader>
        <Translate i18n="pages:user.proposal.titles.video_preview" />
      </SubHeader>
      <VideoWrapper>
        <iframe src={video.url} />
      </VideoWrapper>
    </React.Fragment>
  )
}

render.List = List

export default render
