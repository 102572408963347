/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileData_profile = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileFormFragment_profile" | "AccreditationInfoFragment_profile" | "BankAccounts_profile">;
    readonly " $refType": "ProfileData_profile";
};
export type ProfileData_profile$data = ProfileData_profile;
export type ProfileData_profile$key = {
    readonly " $data"?: ProfileData_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileData_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileData_profile",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileFormFragment_profile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccreditationInfoFragment_profile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BankAccounts_profile"
    }
  ],
  "type": "UserProfile"
};
(node as any).hash = 'e05b42e6fab3b2401b26884998b905f9';
export default node;
