import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 5px;
  padding: 16px;
`

export const Toolbar = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 24px;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`

export const Button = styled.button<{ isActive?: boolean }>`
  background-color: inherit;
  padding: 0;
  cursor: pointer;

  svg {
    vertical-align: text-bottom;
    fill: ${({ isActive }) => (isActive ? "black" : "darkgrey")};
  }
`
