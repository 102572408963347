/* eslint-disable max-len */

import React from "react"

function Download(props) {
  return (
    <svg {...props} viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0332 9.60556L10.2916 9.60556L10.2916 3.8L9.13047 3.8L9.13047 9.60556L7.3888 9.60555L9.71102 11.9278L12.0332 9.60556ZM14.3555 14.25L14.3555 13.0889L5.06658 13.0889L5.06658 14.25L14.3555 14.25Z" />
    </svg>
  )
}

Download.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Download
