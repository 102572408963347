import React from "react"
import styled from "styled-components"

import { Box } from "src/components"

/* eslint-disable max-len */
export const ProfabilityIcon = () => (
  <Box display="inline-block" width="16px" mr="8px">
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M-157-1795h1280V730H-157z" />
        <path
          d="M-102-258.5a4.5 4.5 0 0 0-4.5 4.5v455a4.5 4.5 0 0 0 4.5 4.5h1170a4.5 4.5 0 0 0 4.5-4.5v-455a4.5 4.5 0 0 0-4.5-4.5H-102z"
          stroke="#D9DEE2"
          fill="#FCFDFB"
        />
        <g fill="#0B1016" fillRule="nonzero">
          <path d="M8.583.5H6.917c-.23 0-.417.317-.417.707v12.72c0 .391.187.708.417.708h1.666c.23 0 .417-.317.417-.707V1.207C9 .817 8.813.5 8.583.5zM4.083 8.274H2.417c-.23 0-.417.317-.417.707v4.947c0 .39.187.707.417.707h1.666c.23 0 .417-.317.417-.707V8.98c0-.39-.187-.707-.417-.707zM13.083 4.74h-1.666c-.23 0-.417.317-.417.707v8.48c0 .391.187.708.417.708h1.666c.23 0 .417-.317.417-.707v-8.48c0-.391-.187-.708-.417-.708z" />
        </g>
      </g>
    </svg>
  </Box>
)

export const ProfabilityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 11px;
  font-size: 16px;
`

export const Container = styled.div`
  display: flex;
  min-width: 95px;
`

export const Label = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: white;
  cursor: pointer;

  &:active,
  &:visited {
    color: white;
  }
`
