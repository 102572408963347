import React from "react"

import { Icons } from "src/components"
import {
  Table, Row, IconCell, StatusCell,
} from "./styles"

const PUNISHMENT_SUBJECTS = [
  {
    field: "legalStatus",
    Icon: Icons.BuisnessAndTrade,
  },
  {
    field: "guarantorStatus",
    Icon: Icons.User,
  },
]

const PUNISHMENT_STATUSES = new Map([
  [
    "NONE",
    {
      text: "Ожидание",
      color: "#FF0505",
    },
  ],
  [
    "PRETRIAL",
    {
      text: "Досудебное взыскание",
      color: "#FF0505",
    },
  ],
  [
    "TRIAL",
    {
      text: "Суд",
      color: "#FF0505",
    },
  ],
  [
    "ENFORCEMENT",
    {
      text: "Исполнительное производство",
      color: "#FF0505",
    },
  ],
  [
    "BANKRUPTCY_PROCEDURE",
    {
      text: "Процедура банкроства",
      color: "#FF0505",
    },
  ],
  [
    "BANKRUPTCY_FINISHED",
    {
      text: "Банкротство завершено",
      color: "#6FA84B",
    },
  ],
])

const PunishmentStatus = ({ punishment }) => (
  <Table>
    {PUNISHMENT_SUBJECTS.map(({ field, Icon }) => {
      const status = PUNISHMENT_STATUSES.get(punishment?.[field]) || {
        text: "Неизвестно",
        color: "grey",
      }

      return (
        <Row key={field}>
          <IconCell fill={status.color}>
            <Icon />
          </IconCell>
          <StatusCell color={status.color}>{status.text}</StatusCell>
        </Row>
      )
    })}
  </Table>
)
export default PunishmentStatus
