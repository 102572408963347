import React from "react"
import Relay, { graphql } from "react-relay"
import memoize from "memoize-one"
import styled from "styled-components"

import { number, getProperty, getPaymentSummaryByStatus } from "src/utils"

import {
  Box,
  Text,
  Alert,
  Button,
  Translate,
  VirtualAccountActions,
} from "src/components"

const calculateNextPaymentAmount = memoize((schedules, profile) => {
  if (!Array.isArray(schedules)) return 0

  const borrowerSchedule = schedules.find(
    (item) => item.profile.id === profile.id,
  )

  if (!borrowerSchedule) return 0

  const nextPayment = borrowerSchedule.items.find(
    (item) => item.state === "new",
  )
  const pastduePayments = getPaymentSummaryByStatus(
    borrowerSchedule.items,
    "pastdue",
  )

  return (
    getProperty(nextPayment, "total", 0)
    + getProperty(pastduePayments, "total", 0)
  )
})

const isValidStatus = (status) => ["COMPLETE", "TRANSFER"].indexOf(status) !== -1

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const BoldText = styled.span`
  font-size: 14px;
  line-height: 1.45;
  font-weight: 500;
`

const DepositButton = React.memo(({ onClick }: any) => (
  <Box mt="20px" onClick={onClick}>
    <Button variant="blueWide" height="32px" width="100%">
      <Translate i18n="modals:debt_payment_requisites.deposit" />
    </Button>
  </Box>
))

class LoanPaymentData extends React.PureComponent<any, any> {
  state = {
    showModal: false,
  }

  static defaultProps = {
    buttonVariant: "blueWide",
    fontSize: "14px",
  }

  onClick = () => {
    this.setState(
      () => ({ showModal: true }),
      this.triggerCallback("onModalOpen"),
    )
  }

  onClose = () => {
    this.setState(
      () => ({ showModal: false }),
      this.triggerCallback("onModalClose"),
    )
  }

  triggerCallback = (type) => () => {
    const callback = this.props[type]

    if (typeof callback === "function") {
      callback()
    }
  }

  render() {
    if (!isValidStatus(this.props.order.status)) return null

    const { profile, paymentScheduleList } = this.props.order
    const nextPaymentAmount = calculateNextPaymentAmount(
      paymentScheduleList,
      profile,
    )
    const isEnoughMoney = profile.accountBalance >= nextPaymentAmount
    const infoMessageKey = isEnoughMoney ? "next_payment" : "deposit_reminder"

    return (
      <>
        <Box width="280px" mt="20px">
          <Alert textAlign="left" p="20px 25px" bg="greyLighter">
            <Text fontSize="14px" lineHeight="1.45">
              <Translate
                i18n={`modals:debt_payment_requisites.${infoMessageKey}`}
              />
              {!isEnoughMoney && (
                <>
                  &nbsp;
                  <BoldText>
                    {number(
                      nextPaymentAmount - profile.accountBalance,
                      options,
                    )}
                    ₽
                  </BoldText>
                </>
              )}
            </Text>
            {!isEnoughMoney && (
              <DepositButton onClick={this.onClick} order={this.props.order} />
            )}
          </Alert>
        </Box>
        {this.state.showModal && (
          <VirtualAccountActions.Deposit.Modal
            onClose={this.onClose}
            profileId={this.props.order.profile.id}
          />
        )}
      </>
    )
  }
}

export default Relay.createFragmentContainer(LoanPaymentData, {
  order: graphql`
    fragment DebtPaymentData_order on Order {
      status
      profile {
        id
        accountBalance
      }
      paymentScheduleList {
        profile {
          id
        }
        foreignProfile {
          id
        }
        items {
          date
          state
          total
          total_details {
            currency
            amount
          }
        }
      }
    }
  `,
})
