import React from "react"
import styled from "styled-components"

import { Translate } from "src/components"

const LabelsContainer = styled.span`
  display: inline-block;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
  height: 100%;
  color: white;
  font-size: 14px;
  z-index: 2;
`

const Container = styled.div`
  * {
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 84px;
    height: 32px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.greyDark};
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 4px;
    transition: 0.3s;
  }

  .slider:after {
    position: absolute;
    content: "";
    left: 30px;
    width: 14px;
    height: 100%;
    top: 0;
    background-color: ${({ theme }) => theme.colors.greyDark};
    z-index: 2;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    border-radius: 4px;
    left: -2px;
    top: -1px;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
    z-index: 5;
  }

  input:checked + .slider,
  input:checked + .slider:after {
    background-color: ${({ theme }) => theme.colors.green};
  }

  input:checked + .slider:after {
    left: 37px;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(53px);
  }
`

const TabletInput = (props) => {
  const { name, onChange } = props

  return (
    <Container>
      <label className="switch">
        <LabelsContainer>
          <span>
            <Translate i18n="common:labels.misc.yes" />
          </span>
          <span>
            <Translate i18n="common:labels.misc.no" />
          </span>
        </LabelsContainer>
        <input
          name={name}
          type="checkbox"
          checked={props.value}
          onChange={onChange}
        />
        <span className="slider" />
      </label>
    </Container>
  )
}

const SwitchSelectContainer = styled(Container)`
  .slider {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .slider:after {
    background-color: ${({ theme }) => theme.colors.green};
  }
`

const SwitchSelectInput = (props) => {
  const {
    name, onChange, leftSwitchLabel, rightSwitchLabel,
  } = props

  return (
    <SwitchSelectContainer>
      <label className="switch">
        <LabelsContainer>
          <span>
            <Translate i18n={leftSwitchLabel} />
          </span>
          <span>
            <Translate i18n={rightSwitchLabel} />
          </span>
        </LabelsContainer>
        <input
          name={name}
          type="checkbox"
          checked={props.value}
          onChange={onChange}
        />
        <span className="slider" />
      </label>
    </SwitchSelectContainer>
  )
}

TabletInput.SwitchSelect = SwitchSelectInput

export default TabletInput
