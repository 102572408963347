import styled from "styled-components"
import { width, height, color } from "styled-system"

import { Button } from "src/components"

import { Shevrone } from "./icons"

export const ContentContainer = styled.div`
  flex: 1;
`

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.blacks[8]};
  position: fixed;
  top: 0;
  z-index: 9000;
  left: 0;
`

export const Scrollable = styled.div<any>`
  max-height: ${({ maxHeight }) => maxHeight};
  height: 100%;
  text-align: ${({ textAlign }) => textAlign || "left"};
`

const styleFromType = ({ type }) => {
  if (type === "centered") {
    return `
      top: 50%;
      transform: translate(-50%, -50%);
    `
  }
  return `
      top: 5%;
      transform: translateX(-50%);
    `
}

export const ModalBody = styled.div<any>`
  ${styleFromType};
  position: absolute;
  left: 50%;
  z-index: 9001;
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .25);
  overflow: auto;
  border-radius: 5px;

  max-height: ${({ maxHeight, heightRestriction }) => (heightRestriction ? maxHeight : "none")};
  padding: ${({ padding }) => padding};
  background-color: ${({ theme }) => theme.colors.white};
  
  ${width};
  ${height};
  ${color};

  @media screen and (max-width: 480px) {
    left: 0;
    right: 0;
    top: 0;
    transform: none;
    height: 100vh;
    max-height: none;
  }
`

export const CloseButton = styled(Button)`
  display: flex;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  z-index: 1;
  width: 25px;
  height: 25px;
  padding: 4px;
  box-sizing: content-box;
  border-radius: 4px;

  svg {
    margin: auto;
    width: 14px;
    height: 14px;
  }
`

export const List = styled.ul`
  li {
    padding: 10px 0;
  }
`

export const ShevroneIcon = styled(Shevrone)``

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  font-family: "Geometria";
  font-size: 1rem;
  display: none;
  align-items: center;
  position: absolute;
  left: 13px;
  top: 24px;
  @media (max-width: 480px) {
    display: flex;
  }
`
