import styled from "styled-components"

import { Box, Select } from "src/components"
import { NavLink } from "react-router-dom"

export const SelectContainer = styled.div`
  position: relative;
`

export const ArrowContainer = styled.div`
  position: absolute;
  display: flex;
  top: 20px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-color: inherit;
  pointer-events: none;
`

export const StyledSelect = styled(Select.landing)`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 0.875rem;
  font-weight: 500;
  color: black;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  height: 64px;
  margin: 0;
  padding: 0 16px;
`

export const CheckBoxesContainer = styled(Box)`
  max-width: 1100px;

  @media (max-width: 959px) {
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 639px) {
    > * {
      width: 340px;
    }
  }

  @media (max-width: 480px) {
    > * {
      width: 100%;
    }
  }
`

export const ErrorLink = styled(NavLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.black};

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.black};

  &:active,
  &:visited {
    color: ${({ theme }) => theme.colors.black};
  }
`

export const StyledBox = styled(Box)`
  text-align: center;

  > * {
    cursor: pointer;
  }
`
