import memoize from "memoize-one"
import { graphql, fetchQuery } from "relay-runtime"

import { parseYearString } from "src/utils"
import { ProfileType } from "src/constants"

const query = graphql`
  query modalUtilsQuery($id: ID!, $type: OrderDocumentTypeEnum!) {
    node(id: $id) {
      __typename
      ... on Order {
        fee
        document(type: $type) {
          url
          type
        }
      }
    }
  }
`

export const getRequisitesLink = (type, id, environment) => fetchQuery(environment, query, { id, type }).then(
  ({
    node: {
      document: { url },
      fee,
    },
  }) => ({ url, fee }),
)

export const initialStateFromOrder = memoize((order) => {
  const {
    chain, confirmedAt, id: orderId, ...rest
  } = order

  const date = confirmedAt ? parseYearString(confirmedAt) : ""
  const { id } = chain
  const orderNumber = id.split(".")[2]

  return {
    date,
    chain,
    orderNumber,
    orderId,
    ...rest,
  }
})

export const getInvestorInn = memoize((profiles) => {
  /* eslint-disable no-underscore-dangle */
  const profile = profiles.find(
    (currentProfile) => currentProfile.__typename === ProfileType.individual,
  )
  /* eslint-enable no-underscore-dangle */

  return profile.inn || null
})
