import {
  Loans, Trust, Guest, Market, Investments,
} from "./Lists"

import NullStateScreen from "./NullStateScreen"

const render = () => null

render.trust = Trust
render.guest = Guest
render.loans = Loans
render.market = Market
render.investments = Investments
render.empty = NullStateScreen

export default render
