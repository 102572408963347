export const BUSINESS_ATTITUDE = [
  {
    label: "application.worksheet.switch_values.business_attitude.no",
    value: "no",
  },
  {
    label: "application.worksheet.switch_values.business_attitude.micro",
    value: "micro",
  },
  {
    label: "application.worksheet.switch_values.business_attitude.small",
    value: "small",
  },
  {
    label: "application.worksheet.switch_values.business_attitude.mid",
    value: "mid",
  },
]

export const TAXATION = [
  {
    label: "application.worksheet.switch_values.taxation.general",
    value: "general",
  },
  {
    label: "application.worksheet.switch_values.taxation.simple",
    value: "simple",
  },
  {
    label: "application.worksheet.switch_values.taxation.envd",
    value: "envd",
  },
]

export const PLACEMENT_PURPOSE = [
  {
    label: "application.worksheet.switch_values.placement_purpose.office",
    value: "office",
  },
  {
    label: "application.worksheet.switch_values.placement_purpose.manufacture",
    value: "manufacture",
  },
  {
    label: "application.worksheet.switch_values.placement_purpose.store",
    value: "store",
  },
  {
    label: "application.worksheet.switch_values.placement_purpose.stock",
    value: "stock",
  },
  {
    label: "application.worksheet.switch_values.placement_purpose.rest",
    value: "rest",
  },
]

export const CORPORATE_AGREEMENT = [
  {
    label:
      "application.worksheet.switch_values.corporate_agreement.empty.label",
    value: "empty",
  },
  {
    label: "application.worksheet.switch_values.corporate_agreement.got.label",
    value: "got",
  },
]

export const TENURE = [
  {
    label: "application.worksheet.switch_values.tenure.own",
    value: "own",
  },
  {
    label: "application.worksheet.switch_values.tenure.rent",
    value: "rent",
  },
]

export const GUARANTEES = [
  {
    label: "application.worksheet.switch_values.guarantees.no_debt.label",
    value: "no_debt",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.no_debt_exceptions.label",
    value: "no_debt_exceptions",
  },
  {
    label: "application.worksheet.switch_values.guarantees.violation.label",
    value: "violation",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.violation_exceptions.label",
    value: "violation_exceptions",
  },
  {
    label: "application.worksheet.switch_values.guarantees.full_powers.label",
    value: "full_powers",
  },
  {
    label: "application.worksheet.switch_values.guarantees.status.label",
    value: "status",
  },
  {
    label: "application.worksheet.switch_values.guarantees.effects.label",
    value: "effects",
  },
  {
    label: "application.worksheet.switch_values.guarantees.actions.label",
    value: "actions",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.actions_exceptions.label",
    value: "actions_exceptions",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.reorganisation.label",
    value: "reorganisation",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.reorganisation_exceptions.label",
    value: "reorganisation_exceptions",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.contract_breach.label",
    value: "contract_breach",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.executive_proceedings.label",
    value: "executive_proceedings",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.executive_proceedings_exceptions.label",
    value: "executive_proceedings_exceptions",
  },
  {
    label: "application.worksheet.switch_values.guarantees.no_warnings.label",
    value: "no_warnings",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.no_warnings_exceptions.label",
    value: "no_warnings_exceptions",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.documents_change.label",
    value: "documents_change",
  },
  {
    label:
      "application.worksheet.switch_values.guarantees.borrowing_status.label",
    value: "borrowing_status",
  },
]

export const REPAYMENT_SOURCE = [
  {
    label: "application.worksheet.switch_values.repayment_source.income.label",
    value: "income",
  },
  {
    label:
      "application.worksheet.switch_values.repayment_source.sales_revenue.label",
    value: "sales_revenue",
  },
  {
    label:
      "application.worksheet.switch_values.repayment_source.other_income.label",
    value: "other_income",
  },
]

export const SCHEDULE_ITEM = {
  date: "",
  value: "",
}
