export const MOCK_PROVISION_DATA = {
  flat: {
    address: {
      region: "",
      area: "",
      locality: "",
      street: "",
      house: "",
      housing: "",
      structure: "",
      flat: "",
    },
    egrnData: {
      area: "",
      flatCount: "",
      floor: "",
    },
    price: {
      type: "",
      value: "",
    },
    pledgor: {
      type: "",
      name: "",
    },
    encumbrances: false,
    additionalInfo: "",
  },
  individual_beneficiary: {
    name: "",
    attitude: "",
  },
}

export const EMPTY_PROVISION = {
  type: {
    label: "",
    value: "",
  },
  data: {},
}
