/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ProfileInfoQueryVariables = {
    id: string;
};
export type ProfileInfoQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly name?: string;
        readonly phone?: string | null;
        readonly _avatar?: {
            readonly url: string;
        } | null;
        readonly isApproved?: boolean;
        readonly investorAccreditation?: {
            readonly status: ProfileAccreditationStatus | null;
            readonly declineReason: string | null;
        };
        readonly borrowerAccreditation?: {
            readonly status: ProfileAccreditationStatus | null;
            readonly declineReason: string | null;
        };
    };
};
export type ProfileInfoQuery = {
    readonly response: ProfileInfoQueryResponse;
    readonly variables: ProfileInfoQueryVariables;
};



/*
query ProfileInfoQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on IndividualProfile {
      name
      phone
      _avatar {
        url
      }
      isApproved
      investorAccreditation: accreditation(role: INVESTOR) {
        status
        declineReason
      }
    }
    ... on EntrepreneurProfile {
      name
      phone
      _avatar {
        url
      }
      isApproved
      investorAccreditation: accreditation(role: INVESTOR) {
        status
        declineReason
      }
    }
    ... on LegalEntityProfile {
      name
      phone
      _avatar {
        url
      }
      isApproved
      borrowerAccreditation: accreditation(role: BORROWER) {
        status
        declineReason
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "_avatar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApproved",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "declineReason",
    "storageKey": null
  }
],
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": "investorAccreditation",
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "INVESTOR"
      }
    ],
    "concreteType": "Accreditation",
    "kind": "LinkedField",
    "name": "accreditation",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": "accreditation(role:\"INVESTOR\")"
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": (v6/*: any*/),
        "type": "IndividualProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v6/*: any*/),
        "type": "EntrepreneurProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "borrowerAccreditation",
            "args": [
              {
                "kind": "Literal",
                "name": "role",
                "value": "BORROWER"
              }
            ],
            "concreteType": "Accreditation",
            "kind": "LinkedField",
            "name": "accreditation",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "accreditation(role:\"BORROWER\")"
          }
        ],
        "type": "LegalEntityProfile"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileInfoQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileInfoQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProfileInfoQuery",
    "operationKind": "query",
    "text": "query ProfileInfoQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on IndividualProfile {\n      name\n      phone\n      _avatar {\n        url\n      }\n      isApproved\n      investorAccreditation: accreditation(role: INVESTOR) {\n        status\n        declineReason\n      }\n    }\n    ... on EntrepreneurProfile {\n      name\n      phone\n      _avatar {\n        url\n      }\n      isApproved\n      investorAccreditation: accreditation(role: INVESTOR) {\n        status\n        declineReason\n      }\n    }\n    ... on LegalEntityProfile {\n      name\n      phone\n      _avatar {\n        url\n      }\n      isApproved\n      borrowerAccreditation: accreditation(role: BORROWER) {\n        status\n        declineReason\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a3ae24933fb906eb3d19aea078835b9f';
export default node;
