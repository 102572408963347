import React from "react"
import glyphs from "./glyphs"
import Container from "./style"

const emptyGlyph = () => null

const render = ({ children, size, ...props }) => {
  const Glyph = glyphs[children] || emptyGlyph

  return (
    <Container {...props} width={size} height={size}>
      <Glyph />
    </Container>
  )
}

render.defaultProps = {
  size: 20,
}

export default render
