import styled from "styled-components"

export const DocumentListWrapper = styled.ul`
  margin-top: -12px;
  list-style-type: none;
  padding: 0 0 32px 0;
  margin: 0;
`

export const DocumentListItemWrapper = styled.li`
  padding: 6px 0;
`

export const StyledLink = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
`
