import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const Wrapper = styled.div`
  position: relative;
`

export const DesktopContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 800px)")

  return !mobile && <Wrapper {...props}/>
}

export const MobileContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 800px)")

  return mobile && <Wrapper {...props}/>
}
