import React from "react"

import { FlexBox, Translate, FormLoadingButton } from "src/components"

import { ButtonWithMargin, ControlsContainer } from "./styles"

const FirstStepControls = (props) => {
  const { onNextStep, isRequestLoading } = props

  return (
    <React.Fragment>
      <FormLoadingButton onClick={onNextStep()} isLoading={isRequestLoading}>
        <Translate
          i18n="accreditation.individual.buttons.continue"
          ns="components"
        />
      </FormLoadingButton>
    </React.Fragment>
  )
}

const BaseStepControls = (props) => {
  const {
    onSave,
    onNextStep,
    activeStep,
    isSaveLoading,
    onPreviousStep,
    isRequestLoading,
    disabledContinue,
    requestOrderApproval,
  } = props

  return (
    <FlexBox width="100%" justifyContent="space-between">
      <FlexBox display="flex" flexDirection="row" justifyContent="flex-start">
        <ButtonWithMargin onClick={onPreviousStep} variant="default">
          <Translate
            i18n="accreditation.individual.buttons.return"
            ns="components"
          />
        </ButtonWithMargin>
        <FormLoadingButton
          onClick={onSave}
          isLoading={isSaveLoading}
          variant="default"
        >
          <Translate
            i18n="accreditation.individual.buttons.save"
            ns="components"
          />
        </FormLoadingButton>
      </FlexBox>
      <FormLoadingButton
        secondary
        disabled={disabledContinue}
        isLoading={isRequestLoading}
        onClick={
          activeStep === 2 ? onNextStep(requestOrderApproval) : onNextStep()
        }
      >
        <Translate
          i18n="accreditation.individual.buttons.continue"
          ns="components"
        />
      </FormLoadingButton>
    </FlexBox>
  )
}

export default (props) => (
  <ControlsContainer>
    {props.activeStep === 0 ? (
      <FirstStepControls {...props} />
    ) : (
      <BaseStepControls {...props} />
    )}
  </ControlsContainer>
)
