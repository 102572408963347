import React from "react"

import {
  Box, Text, Translate, FloatingLabelInput,
} from "src/components"

import { ErrorsExecutor } from "../../Blocks/Input/utils"
import Attachments from "../Attachments"
import { ErrorElement, ErrorContainer } from "../Shared"

import InputsSchema from "./schema"
import { RowWrapper, InputBox } from "./styles"

const Nullable = () => null

const getError = (error) => {
  const InjectedError = error

  if (InjectedError) {
    return (
      <ErrorContainer>
        <ErrorElement>{InjectedError}</ErrorElement>
      </ErrorContainer>
    )
  }

  return <Nullable />
}

const MigrationCard = (props) => {
  const {
    errors, value, onChange, name,
  } = props

  const handleChange = ({ name: propName, value }) => {
    onChange({
      name,
      value: {
        ...props.value,
        [propName.split(".").pop()]: value,
      },
    })
  }

  return (
    <React.Fragment>
      {InputsSchema.map((row, rowI) => (
        <RowWrapper key={rowI}>
          {row.map((input, inpI) => {
            const {
              type,
              mask,
              width,
              maxWidth,
              label,
              errorPath,
              name: inputName,
            } = input

            return (
              <ErrorsExecutor
                key={`${rowI}|${inpI}`}
                errors={errors}
                onChange={(event) => handleChange(event.target)}
                pathPrefix="migrationCard"
                fieldName={errorPath}
              >
                {(renderProps) => {
                  const { data } = renderProps

                  const { hasError, chainedOnChange, errorPresentation } = data

                  return (
                    <InputBox maxWidth={maxWidth || "100%"}>
                      <FloatingLabelInput
                        width={width}
                        type={type}
                        mask={mask}
                        name={`migrationCard.${inputName}`}
                        label={label}
                        error={errorPresentation}
                        value={value && value[inputName]}
                        hasError={hasError}
                        onChange={chainedOnChange}
                      />
                    </InputBox>
                  )
                }}
              </ErrorsExecutor>
            )
          })}
        </RowWrapper>
      ))}

      <Box mb="12px">
        <Text lineHeight="1.75">
          <Translate i18n="components:international_profile_form.labels.migrationCard" />
        </Text>
      </Box>

      <ErrorsExecutor
        errors={errors}
        onChange={handleChange}
        pathPrefix="migrationCard"
        fieldName="attachments"
      >
        {({ data: { chainedOnChange, errorPresentation } }) => (
          <>
            <Attachments
              name="migrationCard.attachments"
              value={value?.attachments}
              onChange={chainedOnChange}
            />
            <Box mt="15px">
              <Text color="greyDark" lineHeight="1.5">
                <Translate i18n="components:international_profile_form.labels.KYC_document.format" />
              </Text>
            </Box>
            {getError(errorPresentation)}
          </>
        )}
      </ErrorsExecutor>
    </React.Fragment>
  )
}

export default MigrationCard
