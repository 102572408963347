import React from "react"
import styled from "styled-components"

import { Tooltip, Translate } from "src/components"

const GlyphContent = styled.span`
  margin-left: -1px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 13px;
  font-weight: 600;
`

const TooltipBody = () => (
  <Translate i18n="components:password-reset-form.hint" />
)

const EmailTooltip = () => (
  <Tooltip
    borderWidth="1px"
    borderColor="grey"
    content={TooltipBody}
    glyph={() => <GlyphContent>?</GlyphContent>}
  />
)

export default EmailTooltip
