import styled from "styled-components"

import { Heading } from "src/components"

export const ErrorContainer = styled.div`
  margin-top: 10px;
`

export const ErrorElement = styled.p`
  font-size: 14px;
  line-height: 1.15;
  color: ${({ theme }) => theme.colors.red};
`

export const StyledHeading = styled(Heading.h6)`
  line-height: 1.56;
`

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.75;
`

export const List = styled.ul`
  padding-left: 15px;
  line-height: 1.75;
`

export const IMGcontainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    margin: 20px 0;
  }
`

export const ScrollableWrapper = styled.div`
  max-height: 48vh;
  overflow-y: auto;
`

export { default as InfoLink } from "./InfoLink"
