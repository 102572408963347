import React from "react"
import styled from "styled-components"

import { Box, Text } from "src/components"

const Container = styled(Box)`
  margin: 10px 0;
`

const Label = styled(Text)`
  font-size: 10px;
  line-height: 12px;
  font-family: Geometria, sans-serif;
  min-height: 1em;
`

export const Error = ({ error, variant }) => (
  <Container>
    <Label color={variant}>{error}</Label>
  </Container>
)
