import styled from "styled-components"

export const ProfilesContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const Divider = styled.div`
  width: 100%;
  max-width: 745px;
  height: 1px;
  margin: 40px 0 0;
  background-color: ${({ theme }) => theme.colors.grey};
`

export const Container = styled.div`
  flex-grow: 1;
`

export const ProfilesWrapper = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
`

export const AccreditedProfile = styled.label`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  margin-bottom: 20px;
  max-width: 480px;

  div {
    position: relative;
    width: 100%;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 8px 10px;
    box-sizing: border-box;
  }

  input {
    display: none;
  }

  input:checked + div {
    border-bottom: 2px solid ${({ theme }) => theme.colors.red};
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: 5px 5px 0 0;

    &:before {
      position: absolute;
      height: 7px;
      background-repeat: no-repeat;
      right: 12px;
      top: 18px;
      content: "";
      background-image: url(/images/checked.svg);
      width: 14px;
      background-size: contain;
    }
  }
`

export const UnAccreditedProfile = styled.div`
  display: flex;
  max-width: 480px;
  flex-direction: row;
  margin-bottom: 20px;
  position: relative;
  border-radius: 5px;
  border: 1px dashed ${({ theme }) => theme.colors.grey};
  padding: 8px 10px;
  box-sizing: border-box;

  button {
    margin-left: auto;
  }
`
