/*
* input KYCDocumentInput {
    attachments: [ID!]!
    type: KYCDocumentTypeEnum
  }

  input ForeignIndividualProfileAttributesInput {
    firstName: String!
    lastName: String!
    documents: [KYCDocumentInput!]!
    selfie: ID!
    phone: String!
  }

  input CreateForeignIndividualProfileInput {
    attributes: ForeignIndividualProfileAttributesInput!
  }

  type CreateForeignIndividualProfilePayload {
    profile: ForeignIndividualProfile!
  }

  extend type Mutation {
    createForeignIndividualProfile(
      input: CreateForeignIndividualProfileInput!
    ): CreateForeignIndividualProfilePayload!
  }
* */
import React from "react"
import styled from "styled-components"

import { Box, Text, Translate } from "src/components"
import { taxResidencyApplicationTemplateUrl } from "../system"

const Link = styled.a`
  font-size: inherit;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue};

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.blue};
  }
`

const taxResidentInfoLink = "https://www.nalog.ru/rn77/ip/interest/reg_ip/resident_rf/"

const TaxResidenceDecorator = (props) => (
  <>
    <Text fontSize="18px" fornWeight="500" lineHeight="1.56">
      <Translate i18n="components:international_profile_form.titles.isTaxResident" />
    </Text>
    <Box mb="15px" mt="5px">
      <Text color="greyDark" lineHeight="1.5">
        <Translate i18n="components:international_profile_form.extras.isTaxResident.content" />
        <Link href={taxResidentInfoLink} target="_blank">
          <Translate i18n="components:international_profile_form.extras.isTaxResident.link" />
        </Link>
      </Text>
    </Box>
    {props.children}
    {!props.value && (
      <Text color="greyDark" lineHeight="1.5">
        <Translate i18n="components:international_profile_form.extras.isTaxResident.extraTax" />
      </Text>
    )}
  </>
)

const TaxResidencyApplicationLabel = () => (
  <>
    <Text fontSize="18px" fornWeight="500" lineHeight="1.56">
      <Translate i18n="components:international_profile_form.labels.taxResidencyApplication.label" />
    </Text>
    <Text color="greyDark" lineHeight="1.5">
      <Link href={taxResidencyApplicationTemplateUrl} target="_blank">
        <Translate i18n="components:international_profile_form.labels.taxResidencyApplication.link" />
      </Link>
    </Text>
  </>
)

const TinDecorator = (props) => (
  <>
    <Text lineHeight="1.75">
      <Translate i18n="components:international_profile_form.labels.tin.label" />
    </Text>
    <Box my="12px">{props.children}</Box>
    <Text color="greyDark" lineHeight="1.5">
      <Translate i18n="components:international_profile_form.labels.tin.info" />
    </Text>
  </>
)

const PhoneDecorator = (props) => (
  <>
    {props.children}
    <Text color="greyDark" lineHeight="1.5">
      <Translate i18n="components:international_profile_form.extras.phone" />
    </Text>
  </>
)

export default {
  groups: [
    {
      title: "international_profile_form.titles.profile",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "text",
              name: "firstName",
              label:
                "components:international_profile_form.placeholders.firstName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "lastName",
              label:
                "components:international_profile_form.placeholders.lastName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "text",
              name: "middleName",
              label:
                "components:international_profile_form.placeholders.middleName",
              deps: [
                {
                  propName: "disabled",
                  compared: "approvedOnce",
                  conditionValue: true,
                },
              ],
            },
            {
              type: "phone",
              name: "phone",
              staticPrefix: "+",
              label: "components:international_profile_form.placeholders.phone",
              decorator: PhoneDecorator,
            },
          ],
        },
      ],
    },
    {
      title: "components:international_profile_form.titles.KYC_document",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "kyc_documents",
              name: "documents",
            },
          ],
        },
      ],
    },
    {
      title:
        "components:international_profile_form.titles.translated_documents",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "attachments",
              name: "translatedDocuments",
            },
          ],
        },
      ],
    },
    {
      title: "components:international_profile_form.titles.selfie",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "selfie",
              name: "selfie",
              multiple: false,
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "text",
              name: "tin",
              label: "components:international_profile_form.placeholders.tin",
              decorator: TinDecorator,
            },
          ],
        },
      ],
    },
    {
      title: "components:international_profile_form.titles.innDocument",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "attachments",
              name: "innDocument",
            },
          ],
        },
      ],
    },
    {
      title: "components:international_profile_form.titles.migrationCard",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "migration_card",
              name: "migrationCard",
            },
          ],
        },
      ],
    },
    {
      title:
        "components:international_profile_form.titles.territoryResidenceDocument",
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "territory_residence_document",
              name: "territoryResidenceDocument",
            },
          ],
        },
      ],
    },
    {
      title: "components:individual_profile_form.titles.registration",
      rows: [
        {
          width: "550px",
          inputs: [
            {
              type: "address",
              name: "registrationAddress",
              label: "components:individual_profile_form.titles.registration",
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          width: "680px",
          inputs: [
            {
              type: "switch",
              name: "isRussiaTaxResident",
              margin: 0,
              decorator: TaxResidenceDecorator,
            },
            {
              type: "residence_document",
              name: "residenceDocument",
              label:
                "components:international_profile_form.labels.residenceDocument",
              multiple: false,
              inject: [
                {
                  propName: "isRussiaTaxResident",
                  stateValue: "isRussiaTaxResident",
                },
              ],
            },
            {
              type: "residence_document",
              name: "taxResidencyApplication",
              label: TaxResidencyApplicationLabel,
              inject: [
                {
                  propName: "isRussiaTaxResident",
                  stateValue: "isRussiaTaxResident",
                },
              ],
            },
          ],
        },
        {
          width: "680px",
          inputs: [
            {
              type: "checkbox",
              name: "hasUSACitizenship",
              initialValue: false,
              label:
                "components:international_profile_form.labels.USACitizenship",
            },
          ],
        },
      ],
    },
  ],
}
