import styled from "styled-components"

export const DropZoneContainer = styled.div`
  display: inline-flex;
  margin-left: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`

export const ContentContainer = styled.div<any>`
  cursor: pointer;
  display: flex;
  margin-left: auto;
  width: 100%;
  height: 100%;
  background-color: ${({ dragOver, theme }) => (dragOver ? theme.colors.blueBright : theme.colors.white)};
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blueBright};
  }
`

export const UploaderLabel = styled.span`
  user-select: none;
  display: inline-block;
  padding-top: 17px;
  text-align: center;
  white-space: pre-line;
  font-size: 14px;
  color: #0b1016;
  text-decoration: underline;
`

export const DropZoneActiveStyles = {
  boxSizing: "border-box",
  borderRadius: "5px",
  width: "180px",
  height: "180px",
  border: "1px dashed #D9DEE2",
}

export const CameraIcon = styled.div`
  width: 55px;
  height: 40px;
  background-image: url(/images/camera.png);
  background-size: contain;
`

export const ImgPreview = styled.div<any>`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${({ url }) => url});
  background-size: contain;
`

export const ImgContainer = styled.div`
  display: inline-flex;
  margin-left: auto;
  width: 180px;
  height: 180px;
  box-sizing: border-box;
  border: 1px solid #d9dee2;
  overflow: hidden;
`

export const LoaderContainer = styled(ImgContainer)`
  justify-content: center;
  align-items: center;
`

export const RemoveIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:after {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

export const RemoveButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.blacks[2]};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.blacks[4]};
  }
`
