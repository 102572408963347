/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"
import { Route } from "react-router"

import { Text, Translate } from "src/components"

import { Container, TabContainer } from "./styles"

const Tab = React.memo(({ label, active, onClick }: any) => (
  <TabContainer active={active} onClick={onClick}>
    <Text fontSize="15px" whiteSpace="pre-line">
      <Translate i18n={label} />
    </Text>
  </TabContainer>
))

const getDefaultTabType = ({ schema, defaultTabIndex }) => schema[defaultTabIndex] && schema[defaultTabIndex].type

const useActiveTab = (attrs) => {
  const setActiveTab = (type) => React.useCallback(() => {
    attrs.onChange({ activeTab: type })

    if (attrs.writeToUrl && attrs.history) {
      attrs.history.push({
        search: `?type=${type}`,
      })
    }
  }, [type])

  if (!attrs.writeToUrl) {
    return [attrs.activeTab, setActiveTab]
  }

  const { search } = attrs.history.location
  const urlSearchParams = new URLSearchParams(search)
  const type = urlSearchParams.get("type") || getDefaultTabType(attrs)

  return [type, setActiveTab]
}

const shouldTriggerTabChange = (props, activeTab) => props.writeToUrl
  && props.activeTab
  && activeTab
  && activeTab !== props.activeTab

const SwitchBar = (props) => {
  const [activeTab, setActiveTab] = useActiveTab(props)

  React.useEffect(() => props.onChange({ activeTab }), [])

  const { schema, renderTab: TabItem, renderContainer: TabsContainer } = props

  if (shouldTriggerTabChange(props, activeTab)) {
    props.onChange({ activeTab })
  }

  return (
    <TabsContainer>
      {schema.map(({ label, type, ...rest }, index) => (
        <TabItem
          {...rest}
          key={index}
          label={label}
          active={activeTab === type}
          onClick={setActiveTab(type)}
        />
      ))}
    </TabsContainer>
  )
}

SwitchBar.defaultProps = {
  schema: [],
  defaultTabIndex: 0,
  onChange: () => {},
  writeToUrl: true,
  renderTab: Tab,
  renderContainer: Container,
}

export default (props) => (
  <Route>{({ history }) => <SwitchBar {...props} history={history} />}</Route>
)
