import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation AddIndividiualProfileMutation($input: AddIndividualProfileInput!) {
    addIndividualProfile(input: $input) {
      profile {
        id
        name
        phone
        investor {
          canBeInvestor
        }
        ... on IndividualProfile {
          lastName
          firstName
          middleName
          accreditation(role: INVESTOR) {
            status
          }
        }
      }
    }
  }
`

const updater = (store) => {
  const root = store.getRoot()
  const viewer = root.getLinkedRecord("viewer")
  const profiles = viewer.getLinkedRecords("profiles") || []
  const payload = store.getRootField("addIndividualProfile")
  const profile = payload.getLinkedRecord("profile")

  viewer.setLinkedRecords([...profiles, profile], "profiles")
}

export default enhance({ mutation, updater })
