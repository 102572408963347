import { useState, useCallback } from "react"

const valueFromTarget = (target) => {
  if (!target) return null
  if (target.type === "checkbox") return target.checked
  return target.value
}

const useInput = (initial) => {
  const isNumber = typeof initial === "number"
  const [value, setValue] = useState(initial)
  const onChange = useCallback((e) => setValue(valueFromTarget(e.target)), [])

  return {
    value,
    setValue,
    hasValue:
      value !== undefined
      && value !== null
      && (!isNumber ? value.trim && value.trim() !== "" : true),
    clear: useCallback(() => setValue(""), []),
    onChange,
    bindToInput: {
      onChange,
      value,
    },
    bind: {
      onChange: setValue,
      value,
    },
  }
}

/*
* const Component = () => {
    const newTodo = useInput("");
    return (
      <input type="text" value={newTodo.value} onChange={newTodo.onChange} />
    );
}
* */

export default useInput
