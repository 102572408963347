import React from "react"

import {
  Box, Text, Flex, Link, Button, Translate,
} from "src/components"

import { StyledText } from "./styles"

const render = ({ onComplete, profile }) => (
  <Box width="420px" m="auto">
    <Box mb="10px">
      <Text
        fontSize="24px"
        fontWeight="500"
        lineHeight="1.25"
        textAlign="center"
      >
        <Translate i18n="components:account.operations.convert.success.title" />
      </Text>
    </Box>
    <Box mb="40px">
      <StyledText textAlign="center">
        <Translate i18n="components:account.operations.convert.success.time" />
      </StyledText>
    </Box>
    <Flex justifyContent="center">
      <Link to={`/accounts/${profile.id}?type=history`}>
        <Button height="48px" variant="blueWide" onClick={onComplete}>
          <Translate i18n="components:account.operations.convert.success.transactions" />
        </Button>
      </Link>
    </Flex>
  </Box>
)

export default render
