import { Link } from "src/components"

import styled from "styled-components"

export const StyledLink = styled(Link)`
  text-decoration: underline;
`

export const TooltipContainer = styled.div`
  transform: translateY(-3px);
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`
