import React from "react"
import {
  ModalContainer, Box, Icons, Button,
} from "src/components"
import { getOrderFullTitle, divideNumber } from "src/utils"

import {
  Container,
  CloseButton,
  ReturnButton,
  Title,
  ControlsContainer,
  StyledText,
} from "./styles"

const Alert = ({ onClose, goNext, order }) => (
  <ModalContainer isOpened onClose={onClose}>
    <Container>
      <ReturnButton onClick={onClose}>
        <Icons.LeftArrow />
        <span>Назад</span>
      </ReturnButton>

      <CloseButton onClick={onClose}>
        <Icons.Close />
      </CloseButton>

      <Title>Отмена инвестиции</Title>

      <Box m="20px 0">
        <StyledText>
          Вы уверены что хотите отменить инвестицию в заявку №{" "}
          {order.chain.id.split(".").pop()}, "
          {getOrderFullTitle(order.application)}" в размере{" "}
          {divideNumber(order.amount.toFixed(2))} рублей
        </StyledText>
      </Box>

      <ControlsContainer>
        <Box mr="20px">
          <Button variant="default" height="48px" onClick={goNext}>
            Уверен
          </Button>
        </Box>
        <Box mr="20px">
          <Button variant="primary" height="48px" onClick={onClose}>
            Нет
          </Button>
        </Box>
      </ControlsContainer>
    </Container>
  </ModalContainer>
)

export default Alert
