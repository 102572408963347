import React from "react"

import { ViewerDataConsumer } from "src/context"
import { DebtPaymentData } from "src/components"

import Approval from "../Approval"
import { ControlsContainer } from "./styles"
import TransferSubmit from "../TransferSubmit"

const LoanControlsSchema = new Map([
  [
    "APPROVED",
    ({ data }) => (
      <ControlsContainer>
        <Approval order={data} type="fee" />
      </ControlsContainer>
    ),
  ],
  [
    "TRANSFER",
    () => (
      <ControlsContainer>
        <TransferSubmit />
      </ControlsContainer>
    ),
  ],
  [
    "COMPLETE",
    ({ data }) => (
      <ControlsContainer>
        <DebtPaymentData
          order={data}
          message={null}
          buttonVariant="blueWide"
          withMessage={false}
        />
      </ControlsContainer>
    ),
  ],
])

// TODO: разобраться почему не используется viewer
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LoanControls = ({ data, viewer }) => {
  const { status } = data
  const Controls = LoanControlsSchema.get(status)

  return Controls ? <Controls data={data} /> : null
}

export default (props) => (
  <ViewerDataConsumer>
    {(viewer) => <LoanControls viewer={viewer} {...props} />}
  </ViewerDataConsumer>
)
