import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ViewerOrdersListQuery {
    viewer {
      ... on User {
        id
        orders {
          count
          edges {
            node {
              id
              status
              chain {
                id
                gatheredAmount
                investorsCount
              }
            }
          }
        }
      }
    }
  }
`

const Container = (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)

Container.query = query

export default Container
