/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type InvestmentDocumentQueryVariables = {
    profileId: string;
    orderId: string;
};
export type InvestmentDocumentQueryResponse = {
    readonly node: {
        readonly id?: string;
        readonly investment?: {
            readonly id: string;
            readonly number: string;
            readonly confirmations: ReadonlyArray<{
                readonly document: {
                    readonly url: string;
                };
            }> | null;
        } | null;
    };
};
export type InvestmentDocumentQuery = {
    readonly response: InvestmentDocumentQueryResponse;
    readonly variables: InvestmentDocumentQueryVariables;
};



/*
query InvestmentDocumentQuery(
  $profileId: ID!
  $orderId: ID!
) {
  node(id: $profileId) {
    __typename
    ... on UserProfile {
      id
      ... on IndividualProfile {
        investment(orderId: $orderId) {
          id
          number
          confirmations {
            document {
              url
              id
            }
          }
        }
      }
      ... on EntrepreneurProfile {
        investment(orderId: $orderId) {
          id
          number
          confirmations {
            document {
              url
              id
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "orderId",
    "variableName": "orderId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "Investment",
    "kind": "LinkedField",
    "name": "investment",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvestmentConfirmation",
        "kind": "LinkedField",
        "name": "confirmations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneratedDocument",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": (v3/*: any*/),
    "concreteType": "Investment",
    "kind": "LinkedField",
    "name": "investment",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "InvestmentConfirmation",
        "kind": "LinkedField",
        "name": "confirmations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneratedDocument",
            "kind": "LinkedField",
            "name": "document",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "EntrepreneurProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvestmentDocumentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v7/*: any*/),
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v7/*: any*/),
            "type": "EntrepreneurProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvestmentDocumentQuery",
    "operationKind": "query",
    "text": "query InvestmentDocumentQuery(\n  $profileId: ID!\n  $orderId: ID!\n) {\n  node(id: $profileId) {\n    __typename\n    ... on UserProfile {\n      id\n      ... on IndividualProfile {\n        investment(orderId: $orderId) {\n          id\n          number\n          confirmations {\n            document {\n              url\n              id\n            }\n          }\n        }\n      }\n      ... on EntrepreneurProfile {\n        investment(orderId: $orderId) {\n          id\n          number\n          confirmations {\n            document {\n              url\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b7b38c6bad5283dc798e4135487be98c';
export default node;
