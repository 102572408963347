import React from "react"

import {
  Box, Label, Switch, FormRow, Translate,
} from "src/components"

class Encumbrances extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { checked } = event.target
    this.props.onChange({
      name: this.props.name,
      value: checked,
    })
  }

  render() {
    return (
      <FormRow>
        <Box>
          <Label>
            <Translate
              i18n="legal_entity_offer_form.rows.encumbrances.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box ml={-10}>
          <Switch
            id="encumbrances"
            rightLabel={
              <Translate
                i18n="legal_entity_offer_form.rows.encumbrances.value"
                ns="components"
              />
            }
            checked={this.props.value}
            value={this.props.value}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default Encumbrances
