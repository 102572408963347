import styled from "styled-components"

export const FileDownloadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const ButtonWrapper = styled.div`
  margin-left: auto;
`
export const IconContainer = styled.div`
  transform: translate(13px, 4px);
`
