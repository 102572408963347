import React from "react"

import { createChainedFunction } from "src/utils"

import Input from "./Input"

const Attributes = (props) => {
  const {
    viewer, getError, validate, clearError, attributes,
  } = props

  const onChange = React.useCallback(
    createChainedFunction(attributes.onChange, clearError, validate),
    [],
  )

  return (
    <>
      <Input
        name="current_password"
        error={getError("setViewerPassword.current_password")}
        disabled={!viewer.password.present}
        label="components:account.password_update_form.rows.password.old"
        onChange={onChange}
        value={attributes.value.current_password}
      />
      <Input
        name="password"
        error={getError("setViewerPassword.password")}
        label="components:account.password_update_form.rows.password.new"
        onChange={onChange}
        value={attributes.value.password}
      />
      <Input
        name="retry_password"
        error={getError("setViewerPassword.retry_password")}
        label="components:account.password_update_form.rows.password.retry"
        onChange={onChange}
        value={attributes.value.retry_password}
      />
    </>
  )
}

export default React.memo(Attributes)
