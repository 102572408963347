import React from "react"

import {
  Box, Text, Loader, Translate, FloatingLabelInput,
} from "src/components"

import {
  Option,
  InputWrapper,
  OptionsContainer,
  GotNoSuggestionsContainer,
} from "./styles"

import { stateToProps } from "./utils"

import useAutoSuggest from "./useAutoSuggest"

/*
 *
 * 1) Запрогать floatingLabelAutoComplete инпут (локально, только для этой задачи)
 *     1] показать autoCompleteContainer при onChange
 *     2] Реализовать обработку событий клавиатуры +
 *     3] Запрогать компонент с Mock данными +
 * 2) Запрогать адаптер stateToProps
 * 3) Запрогать адаптер propsToState
 *
 * */

const SuggestionsLoader = () => (
  <Box py="18px">
    <Loader fontSize="4px" />
  </Box>
)

const Suggestions = (props) => {
  const { onSelect, suggestions, activeIndex } = props

  if (suggestions.length === 0) {
    return (
      <GotNoSuggestionsContainer>
        <Text fontSize="16px">
          <Translate i18n="common:labels.got_no_matches" />
        </Text>
      </GotNoSuggestionsContainer>
    )
  }

  return suggestions.map(({ value: suggestionValue }, i) => (
    <Option
      key={suggestionValue}
      active={i === activeIndex}
      onClick={onSelect(i)}
    >
      {suggestionValue}
    </Option>
  ))
}

const AutoSuggest = (props) => {
  const {
    name,
    error,
    allowUnreliable,
    value: injectedInputValue,
    onChange: injectedOnChange,
    ...restInputProps
  } = props

  const {
    onBlur,
    onFocus,
    onSelect,
    isLoading,
    inputValue,
    suggestions,
    activeIndex,
    displayOptions,
    onInputValueChange,
  } = useAutoSuggest(injectedInputValue)

  React.useEffect(() => {
    injectedOnChange({
      name,
      value: stateToProps(
        suggestions[activeIndex],
        injectedInputValue,
        inputValue,
        allowUnreliable,
      ),
    })
  }, [activeIndex, inputValue])

  return (
    <InputWrapper>
      <FloatingLabelInput
        {...restInputProps}
        onFocus={onFocus}
        value={inputValue}
        onBlur={onBlur}
        onChange={onInputValueChange}
        error={error}
        hasError={!!error}
      />
      <OptionsContainer shouldDisplay={displayOptions}>
        {isLoading ? (
          <SuggestionsLoader />
        ) : (
          <Suggestions
            suggestions={suggestions}
            onSelect={onSelect}
            activeIndex={activeIndex}
          />
        )}
      </OptionsContainer>
    </InputWrapper>
  )
}

export default AutoSuggest
