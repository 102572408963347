import styled from "styled-components"

import { Text } from "src/components"

export const Header = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 0 10px;
`

export const SubHeader = styled(Header)`
  margin: 0;
  color: ${({ theme }) => theme.colors.greyDarkest};
`

export const UnorderedList = styled.ul`
  margin: 6px 0 26px;
  padding-left: 24px;
  list-style: none;
  line-height: 24px;
  font-size: 16px;

  li {
    color: ${({ theme }) => theme.colors.greyDarkest};
    position: relative;
  }

  li::before {
    content: "•";
    position: absolute;
    left: -24px;
    font-size: 16px;
    padding-right: 0;
    color: ${({ theme }) => theme.colors.ginger};
  }
`

export const InclinedText = styled(Text)`
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 20px;
  font-style: italic;
  color: ${({ theme }) => theme.colors.greyDarkest};
`
