import React from "react"
import ReactTooltip from "rc-tooltip"

import { TooltipContainer, TooltipGlobalStyle } from "./styles"

function Tooltip(props) {
  const { children, amount, isSeller } = props

  return (
    <>
      <ReactTooltip
        placement="top"
        overlay={
          <TooltipContainer>
            {`Вы ${
              isSeller ? "продали" : "приобрели"
            } право  требования основного долга на сумму ${amount} руб.`}
          </TooltipContainer>
        }
      >
        {children}
      </ReactTooltip>
      <TooltipGlobalStyle />
    </>
  )
}

export default Tooltip
