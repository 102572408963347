import React, { useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { isJuristicBorrower } from "src/utils"

import { MenusWrapper, MenusItem, Badge } from "./styles"
import { PATH } from "./constants"

function PortfolioMenus(props) {
  const { autoInvestIsActive, profile } = props

  const isBorrowerProfile = useMemo(() => isJuristicBorrower(profile), [profile])

  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <MenusWrapper>
      {isBorrowerProfile && (
        <>
          <MenusItem
            isActive={pathname === PATH.LOANS}
            onClick={() => history.push(PATH.LOANS)}
          >
            Мои займы
          </MenusItem>

          {!!profile?.investor?.isApproved && (
            <>
              <MenusItem
                isActive={pathname === PATH.INVESTMENTS}
                onClick={() => history.push(PATH.INVESTMENTS)}
              >
                Мои инвестиции
              </MenusItem>
              <MenusItem
                isActive={pathname === PATH.ANALYTICS}
                onClick={() => history.push(PATH.ANALYTICS)}
                ml="32px"
              >
                Аналитика
              </MenusItem>
              <MenusItem
                isActive={pathname === PATH.AUTOINVEST}
                onClick={() => history.push(PATH.AUTOINVEST)}
                ml="32px"
              >
                Автоинвестирование
              <Badge isActive={!!autoInvestIsActive} />
              </MenusItem>
            </>
          )}

          <MenusItem
            isActive={pathname === PATH.PAYMENTS}
            onClick={() => history.push(PATH.PAYMENTS)}
          >
            График платежей
          </MenusItem>
        </>
      )}

      {!isBorrowerProfile && (
        <>
          <MenusItem
            isActive={pathname === PATH.INVESTMENTS}
            onClick={() => history.push(PATH.INVESTMENTS)}
          >
            Мои инвестиции
          </MenusItem>

          <MenusItem
            isActive={pathname === PATH.PAYMENTS}
            onClick={() => history.push(PATH.PAYMENTS)}
            ml="auto"
          >
            График платежей
          </MenusItem>

          <MenusItem
            isActive={pathname === PATH.ANALYTICS}
            onClick={() => history.push(PATH.ANALYTICS)}
            ml="32px"
          >
            Аналитика
          </MenusItem>

          <MenusItem
            isActive={pathname === PATH.AUTOINVEST}
            onClick={() => history.push(PATH.AUTOINVEST)}
            ml="32px"
          >
            Автоинвестирование
            <Badge isActive={!!autoInvestIsActive} />
          </MenusItem>
        </>
      )}
    </MenusWrapper>
  )
}

export default PortfolioMenus
