import React from "react"

import { Text, Loader, Translate } from "src/components"

import {
  AsLink, UploadIcon, LabelContainer, LoaderContainer,
} from "./styles"

export const LabelText = () => (
  <React.Fragment>
    <Text textAlign="center" fontSize="14px">
      <Translate i18n="labels.upload.prefix" ns="common" />
    </Text>
    <AsLink>
      <Translate i18n="labels.upload.postfix" ns="common" />
    </AsLink>
  </React.Fragment>
)

export const Label = ({ isLoading }) => (
  <LabelContainer>
    {isLoading ? <AttachmentsLoader /> : <UploadIcon />}
    <LabelText />
  </LabelContainer>
)

export const AttachmentsLoader = () => (
  <LoaderContainer>
    <Loader fontSize="4px" margin="auto" />
  </LoaderContainer>
)
