import React from "react"
import { ErrorsProvider } from "src/context"

const processValidationError = ({ path, extensions: { validations } }) => validations.map((validation) => ({
  type: "validation",
  path: [path, validation.dataPath].join("/").replace(/\/+/g, "."),
  keyword: validation.keyword,
}))

class ErrorsContainer extends React.Component {
  state = {
    errors: [],
  }

  getError = (path) => this.state.errors.find((error) => error.path === path)

  clearError = (path) => {
    const errors = this.state.errors.filter((error) => error.path !== path)
    if (errors.length !== this.state.errors.length) this.setState(() => ({ errors }))
  }

  setErrors = (transaction) => {
    const errors = transaction.errors.reduce((memo, error) => {
      if (error.extensions && error.extensions.validations) {
        return memo.concat(processValidationError(error))
      }

      return memo
    }, [])

    this.setState(() => ({ errors }))
  }

  setManualError = (error) => {
    const errors = [...this.state.errors]
    errors.push(error)
    this.setState(() => ({ errors }))
  }

  render() {
    return (
      <ErrorsProvider
        value={{
          errors: this.state.errors,
          getError: this.getError,
          setErrors: this.setErrors,
          clearError: this.clearError,
          setManualError: this.setManualError,
        }}
      >
        {(this as any).props.children({
          errors: this.state.errors,
          getError: this.getError,
          setErrors: this.setErrors,
          clearError: this.clearError,
          setManualError: this.setManualError,
        })}
      </ErrorsProvider>
    )
  }
}

export default ErrorsContainer
