/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type LoanPaymentGraphFragment = {
    readonly node: {
        readonly id: string;
        readonly status: OrderStatusEnum | null;
        readonly application: {
            readonly data: string;
        } | null;
        readonly chain: {
            readonly id: string;
            readonly gatheredAmount: number;
        } | null;
        readonly paymentScheduleList: ReadonlyArray<{
            readonly profile: {
                readonly id: string;
            } | null;
            readonly info: {
                readonly state: string | null;
            } | null;
            readonly items: ReadonlyArray<{
                readonly date: string;
                readonly payDate: string | null;
                readonly loan: number;
                readonly state: string | null;
                readonly total: number;
                readonly total_gross: number;
                readonly interest: number;
                readonly interest_fee: number;
                readonly personal_tax: number;
            }>;
        }>;
    };
    readonly " $refType": "LoanPaymentGraphFragment";
};
export type LoanPaymentGraphFragment$data = LoanPaymentGraphFragment;
export type LoanPaymentGraphFragment$key = {
    readonly " $data"?: LoanPaymentGraphFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LoanPaymentGraphFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoanPaymentGraphFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderApplication",
          "kind": "LinkedField",
          "name": "application",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "data",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderChainInfo",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gatheredAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentSchedule",
          "kind": "LinkedField",
          "name": "paymentScheduleList",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LoanInfo",
              "kind": "LinkedField",
              "name": "info",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentScheduleItem",
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "payDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "loan",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total_gross",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interest",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "interest_fee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personal_tax",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderEdge"
};
})();
(node as any).hash = 'aa2301759836eaec564c05e81ffb5414';
export default node;
