import React from "react"

import { normalizePhone } from "src/utils"
import { ErrorsContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"
import { RequestPhoneConfirmation } from "src/mutations"
import {
  Box,
  Flex,
  Modal,
  TextField,
  Translate,
  PhoneInput,
  ErrorForField,
  FormLoadingButton,
} from "src/components"
import { formatError, uniqueError } from "./errors"

import PhoneConfirmModal from "./PhoneConfirmModal"

class PhoneInputRow extends React.Component<any, any> {
  state = {
    phone: this.props.phone,
    confirmed: false,
    isLoading: false,
    shouldConfirmed: false,
    normalizedPhone: this.props.phone,
    busy: false,
  }

  onChange = (event) => {
    const { name, value } = event.target

    this.setState(() => ({
      [name]: value,
      confirmed: false,
      shouldConfirmed: false,
    }))

    this.props.applySignedPhone("")
    this.props.clearError(`requestPhoneConfirmation.${name}`)
  }

  onSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (this.state.busy) return

    const normalizedPhone = normalizePhone(this.state.phone)

    if (this.props.phone === normalizedPhone) {
      this.props.setManualError(uniqueError)
      return
    }

    if (normalizedPhone.length < 11) {
      this.props.setManualError(formatError)
      return
    }

    this.setState(() => ({
      busy: true,
      isLoading: true,
      normalizedPhone,
    }))

    this.commit()
  }

  onError = (transaction) => {
    this.setState(() => ({ busy: false, isLoading: false }))
    this.props.setErrors(transaction)
  }

  onCompleted = (payload) => {
    this.setState(() => ({
      busy: false,
      isLoading: false,
      shouldConfirmed: true,
    }))

    if (this.props.onCompleted) {
      this.props.onCompleted(payload)
    }
  }

  commit = () => {
    const variables = {
      input: {
        phone: normalizePhone(this.state.phone),
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    RequestPhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  applySignedPhone = (signedPhone) => {
    this.setState(() => ({ confirmed: true }))
    this.props.applySignedPhone(signedPhone)
  }

  closeModal = () => {
    this.setState(() => ({
      shouldConfirmed: false,
      confirmed: false,
    }))
  }

  render() {
    const {
      phone, isLoading, confirmed, shouldConfirmed,
    } = this.state

    const variant = confirmed ? "green" : "primary"
    const buttonLabel = confirmed ? "confirmed" : "submit"

    return (
      <Flex height={40} width={440} display="flex">
        <Box width={220} display={"inline-block"}>
          <TextField
            type="text"
            name="phone"
            Component={PhoneInput}
            value={phone}
            onChange={this.onChange}
            error={ErrorForField(
              this.props.getError("requestPhoneConfirmation.phone"),
            )}
          />
        </Box>
        <Box display={"inline-flex"} pl={20} alignSelf={"center"}>
          <FormLoadingButton
            variant={variant}
            height={"30px"}
            onClick={this.onSubmit}
            disabled={!phone || confirmed}
            isLoading={isLoading}
            fontSize="10px"
          >
            <Translate
              i18n={`components:individual_account.update.buttons.${buttonLabel}`}
            />
          </FormLoadingButton>
        </Box>
        {shouldConfirmed && !confirmed && (
          <Modal>
            <PhoneConfirmModal
              close={this.closeModal}
              phone={phone}
              commitRequest={this.commit}
              applySignedPhone={this.applySignedPhone}
            />
          </Modal>
        )}
      </Flex>
    )
  }
}

const render = (props) => (
  <ErrorsContainer>
    {(errors) => (
      <RelayEnvironmentConsumer>
        {({ environment }) => (
          <PhoneInputRow {...props} {...errors} environment={environment} />
        )}
      </RelayEnvironmentConsumer>
    )}
  </ErrorsContainer>
)

export default render
