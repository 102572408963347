/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type AccreditationProfile_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly accreditation?: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly " $refType": "AccreditationProfile_profile";
};
export type AccreditationProfile_profile$data = AccreditationProfile_profile;
export type AccreditationProfile_profile$key = {
    readonly " $data"?: AccreditationProfile_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"AccreditationProfile_profile">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "INVESTOR"
      }
    ],
    "concreteType": "Accreditation",
    "kind": "LinkedField",
    "name": "accreditation",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": "accreditation(role:\"INVESTOR\")"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccreditationProfile_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "ForeignIndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "EntrepreneurProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        }
      ],
      "type": "LegalEntityProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '7dfd3a95b2240b180c91708b8a6c233d';
export default node;
