import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query AccountHeaderQuery {
    viewer {
      email
      profiles {
        id
        __typename
        ... on IndividualProfile {
          name
          phone
        }
        ... on EntrepreneurProfile {
          name
          phone
        }
      }
    }
  }
`

export default (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)
