import React from "react"
import { fetchQuery, graphql } from "react-relay"

import { useEnvironment } from "src/hooks"

const ExchangeRateContext = React.createContext<any>({})

const query = graphql`
  query ExchangeRateQuery {
    exchangeRates {
      buy
      sell
      price
    }
  }
`

const findPair = (from, to, rates) => rates.find(
  ({ buy, sell }) => sell === from.toUpperCase() && buy === to.toUpperCase(),
)

const convert = (from, to, amount, rates) => {
  const exchangeDescriptor = findPair(from, to, rates)

  if (!exchangeDescriptor) return null

  return amount / exchangeDescriptor.price
}

export const ExchangeRateProvider = (props) => {
  const { environment } = useEnvironment()

  const [rates, setRates] = React.useState([])

  React.useEffect(() => {
    fetchQuery(environment, query, {}).then(({ exchangeRates }) => {
      setRates(exchangeRates)
    })
  }, [])

  const convertCurrency = React.useCallback(
    (from, to, amount) => convert(from, to, amount, rates),
    [rates],
  )

  const getExchangePair = React.useCallback(
    (from, to) => findPair(from, to, rates),
    [rates],
  )

  const value = {
    rates,
    getExchangePair,
    convert: convertCurrency,
  }

  return (
    <ExchangeRateContext.Provider value={value}>
      {props.children}
    </ExchangeRateContext.Provider>
  )
}

export const ExchangeRateConsumer = ExchangeRateContext.Consumer
