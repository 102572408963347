/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InvestorForeignQueryVariables = {
    id: string;
};
export type InvestorForeignQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly " $fragmentRefs": FragmentRefs<"InternationalIndividualFragment_profile">;
    };
    readonly viewer: {
        readonly id: string;
        readonly createdAt: string;
    } | null;
};
export type InvestorForeignQuery = {
    readonly response: InvestorForeignQueryResponse;
    readonly variables: InvestorForeignQueryVariables;
};



/*
query InvestorForeignQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ...InternationalIndividualFragment_profile
  }
  viewer {
    id
    createdAt
  }
}

fragment InternationalIndividualFragment_profile on Node {
  ... on ForeignIndividualProfile {
    __typename
    id
    firstName
    lastName
    middleName
    tin
    isRussiaTaxResident
    hasUSACitizenship
    internationalPhone: phone
    documents {
      type
      attachments {
        id
        url
        filename
      }
    }
    translatedDocuments {
      id
      url
      filename
    }
    selfie {
      id
      url
      filename
    }
    taxResidencyApplication {
      id
      filename
      url
      size
      signature {
        url
      }
    }
    migrationCard {
      number
      dateFrom
      dateTo
      attachments {
        id
        url
        filename
      }
    }
    innDocument {
      id
      url
      filename
    }
    residenceDocument {
      id
      url
      filename
    }
    territoryResidenceDocument {
      attachments {
        id
        url
        filename
      }
      type
      series
      number
      dateFrom
      dateTo
    }
    registrationAddress {
      country
      region
      area
      locality
      street
      house
      housing
      structure
      flat
      raw
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFrom",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateTo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestorForeignQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InternationalIndividualFragment_profile"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvestorForeignQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "middleName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRussiaTaxResident",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUSACitizenship",
                "storageKey": null
              },
              {
                "alias": "internationalPhone",
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KYCDocument",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "translatedDocuments",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "selfie",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "taxResidencyApplication",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "size",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentSignature",
                    "kind": "LinkedField",
                    "name": "signature",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MigrationCard",
                "kind": "LinkedField",
                "name": "migrationCard",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "innDocument",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "residenceDocument",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TerritoryResidenceDocument",
                "kind": "LinkedField",
                "name": "territoryResidenceDocument",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "series",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "registrationAddress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "region",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "area",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locality",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "house",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "housing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "structure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "raw",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ForeignIndividualProfile"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvestorForeignQuery",
    "operationKind": "query",
    "text": "query InvestorForeignQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ...InternationalIndividualFragment_profile\n  }\n  viewer {\n    id\n    createdAt\n  }\n}\n\nfragment InternationalIndividualFragment_profile on Node {\n  ... on ForeignIndividualProfile {\n    __typename\n    id\n    firstName\n    lastName\n    middleName\n    tin\n    isRussiaTaxResident\n    hasUSACitizenship\n    internationalPhone: phone\n    documents {\n      type\n      attachments {\n        id\n        url\n        filename\n      }\n    }\n    translatedDocuments {\n      id\n      url\n      filename\n    }\n    selfie {\n      id\n      url\n      filename\n    }\n    taxResidencyApplication {\n      id\n      filename\n      url\n      size\n      signature {\n        url\n      }\n    }\n    migrationCard {\n      number\n      dateFrom\n      dateTo\n      attachments {\n        id\n        url\n        filename\n      }\n    }\n    innDocument {\n      id\n      url\n      filename\n    }\n    residenceDocument {\n      id\n      url\n      filename\n    }\n    territoryResidenceDocument {\n      attachments {\n        id\n        url\n        filename\n      }\n      type\n      series\n      number\n      dateFrom\n      dateTo\n    }\n    registrationAddress {\n      country\n      region\n      area\n      locality\n      street\n      house\n      housing\n      structure\n      flat\n      raw\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e9e8737911ca090fd899742f4d2daabf';
export default node;
