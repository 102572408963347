export default [
  {
    name: "name",
    errorPath: "bank.name",
    suggest: true,
    daDataSelector: (suggestion) => suggestion.data.name.payment,
  },
  {
    name: "bic",
    errorPath: "bank.bic",
    mask: "999999999",
    suggest: true,
    daDataSelector: (suggestion) => suggestion.data.bic,
  },
  {
    name: "correspondentAccount",
    mask: "99999999999999999999",
    errorPath: "correspondentAccount",
  },
  {
    name: "checkingAccount",
    mask: "99999999999999999999",
    errorPath: "checkingAccount",
  },
]
