import React from "react"

import { useInput } from "src/hooks"
import { createChainedFunction } from "src/utils"

export const useTokenInput = (clearError) => {
  const token = useInput("")

  const clearFieldError = (path) => () => clearError(path)

  token.onChange = React.useCallback(
    createChainedFunction(
      clearFieldError("updateSupportingDocuments.token"),
      token.onChange,
    ),
    [],
  )

  return token
}
