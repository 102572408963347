import styled from "styled-components"
import { maxWidth } from "styled-system"

export const ExecutiveContainer = styled.div`
  margin: 30px 0 0;
  padding: 40px 0 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`
export const Header = styled.h6`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textBlack};
  margin: 0;
`

export const PassportScansContainer = styled.div`
  width: 100%;
  position: relative;
`

export const Tooltip = styled.p`
  position: relative;
  margin: 0;
  font-family: Geometria;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.textBlacks[7]};
`

export const PassportRowWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  ${maxWidth}
`
