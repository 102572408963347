import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer } from "src/components"
import { divideNumber } from "src/utils"

import { PATH } from "../../../molecules/PortfolioMenus/constants"

import BlockLoader from "./BlockLoader"
import {
  getTotalInvestmentAmount,
  getPastdueInvestments,
  getNormalInvestments,
} from "./utils"
import { CoinIcon } from "./icons"
import {
  Container,
  Header,
  TitleContainer,
  Title,
  HeaderValue,
  Divider,
  Table,
  TRWithBorder,
  TD,
  TDBold,
  ActionContainer,
  OutlinedButton,
  Annotation,
} from "./styles"

function InvestmentsBlock(props) {
  const { history } = props

  const {
    node: { id: profileId, investor },
  } = props
  const { count, edges } = investor?.investments || {}

  const totalAmount = getTotalInvestmentAmount(profileId, edges)

  const normalInvestments = getNormalInvestments(profileId, edges)
  const normalInvestmentsAmount = getTotalInvestmentAmount(
    profileId,
    normalInvestments,
  )

  const pastdueInvestments = getPastdueInvestments(profileId, edges, 1, 30)
  const pastdueInvestmentAmount = getTotalInvestmentAmount(
    profileId,
    pastdueInvestments,
  )

  const pastdueInvestmentsOver30Days = getPastdueInvestments(
    profileId,
    edges,
    31,
    60,
  )
  const pastdueInvestmentOver30DaysAmount = getTotalInvestmentAmount(
    profileId,
    pastdueInvestmentsOver30Days,
  )

  const pastdueInvestmentsOver60Days = getPastdueInvestments(
    profileId,
    edges,
    61,
    90,
  )
  const pastdueInvestmentOver60DaysAmount = getTotalInvestmentAmount(
    profileId,
    pastdueInvestmentsOver60Days,
  )

  const pastdueInvestmentsOver90Days = getPastdueInvestments(
    profileId,
    edges,
    91,
  )
  const pastdueInvestmentOver90DaysAmount = getTotalInvestmentAmount(
    profileId,
    pastdueInvestmentsOver90Days,
  )

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>Мои инвестиции</Title>
          <CoinIcon />
        </TitleContainer>
        <HeaderValue>₽ {divideNumber(totalAmount.toFixed(2))}</HeaderValue>
      </Header>

      <Divider />

      <Table>
        <tbody>
          <tr>
            <TD>Сделок</TD>
            <TD textAlign="right">{normalInvestments.length} шт</TD>
            <TDBold>
              ₽ {divideNumber(normalInvestmentsAmount.toFixed(2))}
            </TDBold>
          </tr>

          <tr>
            <TD>1-30 д/п*</TD>
            <TD textAlign="right">{pastdueInvestments.length} шт</TD>
            <TDBold>
              ₽ {divideNumber(pastdueInvestmentAmount.toFixed(2))}
            </TDBold>
          </tr>

          <tr>
            <TD>31-60 д/п*</TD>
            <TD textAlign="right">{pastdueInvestmentsOver30Days.length} шт</TD>
            <TDBold>
              ₽ {divideNumber(pastdueInvestmentOver30DaysAmount.toFixed(2))}
            </TDBold>
          </tr>

          <tr>
            <TD>60-90 д/п*</TD>
            <TD textAlign="right">{pastdueInvestmentsOver60Days.length} шт</TD>
            <TDBold>
              ₽ {divideNumber(pastdueInvestmentOver60DaysAmount.toFixed(2))}
            </TDBold>
          </tr>

          <TRWithBorder>
            <TD>более 90 д/п*</TD>
            <TD textAlign="right">{pastdueInvestmentsOver90Days.length} шт</TD>
            <TDBold>
              ₽ {divideNumber(pastdueInvestmentOver90DaysAmount.toFixed(2))}
            </TDBold>
          </TRWithBorder>

          <tr>
            <TD>Итого</TD>
            <TD textAlign="right">{count} шт</TD>
            <TDBold>₽ {divideNumber(totalAmount.toFixed(2))}</TDBold>
          </tr>
        </tbody>
      </Table>

      <Annotation>*д/п - дней просрочки</Annotation>

      <ActionContainer>
        <OutlinedButton onClick={() => history.push(PATH.INVESTMENTS)}>
          Мой портфель
        </OutlinedButton>
      </ActionContainer>
    </Container>
  )
}

const query = graphql`
  query InvestmentsQuery($id: ID!) {
    node(id: $id) {
      ... on UserProfile {
        id
        __typename
        ... on IndividualProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  history {
                    type
                    amount
                  }
                  order {
                    id
                    status
                    paymentScheduleList {
                      items {
                        loan
                        state
                      }
                      info {
                        pastdue_days
                      }
                      profile {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on EntrepreneurProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  history {
                    type
                    amount
                  }
                  order {
                    id
                    status
                    paymentScheduleList {
                      items {
                        loan
                        state
                      }
                      info {
                        pastdue_days
                      }
                      profile {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on ForeignIndividualProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  history {
                    type
                    amount
                  }
                  order {
                    id
                    status
                    paymentScheduleList {
                      items {
                        loan
                        state
                      }
                      info {
                        pastdue_days
                      }
                      profile {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on LegalEntityProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  history {
                    type
                    amount
                  }
                  order {
                    id
                    status
                    paymentScheduleList {
                      items {
                        loan
                        state
                      }
                      info {
                        pastdue_days
                      }
                      profile {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function Render(props) {
  const { profileId } = props

  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      query={query}
      render={InvestmentsBlock}
      renderNull={BlockLoader}
      variables={variables}
      {...props}
    />
  )
}

export default Render
