/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TrustedProfileOrders_profile = {
    readonly id: string;
    readonly user: {
        readonly id: string;
    };
    readonly orders: {
        readonly count: number;
    };
    readonly " $refType": "TrustedProfileOrders_profile";
};
export type TrustedProfileOrders_profile$data = TrustedProfileOrders_profile;
export type TrustedProfileOrders_profile$key = {
    readonly " $data"?: TrustedProfileOrders_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"TrustedProfileOrders_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrustedProfileOrders_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = 'c0dc04ac44f966b123f38e247a5b66f6';
export default node;
