import styled from "styled-components"

import { Button } from "src/components"

export const ButtonWithMargin = styled(Button)`
  margin-right: 20px;
`

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 32px 40px 0;
  margin: 32px -40px 0;
`
