import styled from "styled-components"

export const Main = styled.header`
  align-items: center;
  border-bottom: 1px solid #efefef;
  display: flex;
  height: 50px;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 20px;
`

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 20px;
`

export const LocalesContainer = styled.ul`
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const LocaleContainer = styled.li`
  & + & {
    margin-left: 28px;
  }
`

export const LocaleButton = styled.button<any>`
  background-color: transparent;
  background-image: url(/flags/${(props) => props.flag}.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  height: 21px;
  width: 28px;

  &:disabled {
    opacity: 0.5;
  }
`
