import React from "react"
import * as Sentry from "@sentry/browser"

import { PageWrapper } from "src/components"

import {
  Container, Title, Button, Illustration,
} from "./styles"

type ErrorBoundaryState = {
  hasError: boolean
  eventId: string | null
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageWrapper>
          <Container>
            <Illustration />
            <Title>Упс, кажется что-то пошло не так</Title>
            <Button href="/login">Перейти на рынок</Button>
          </Container>
        </PageWrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
