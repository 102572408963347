import styled from "styled-components"

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 24px;
`

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export const DocumentWrapper = styled.div`
  width: fit-content;
`
