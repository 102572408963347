import React from "react"

import { PageContainer } from "src/components"

import ControlsContainer from "./styles"

const renderControls = (LeftControl, RightControl) => (
  <ControlsContainer>
    {LeftControl && <LeftControl />}
    {RightControl && <RightControl />}
  </ControlsContainer>
)

const renderContent = (Content) => Content && <Content />

export default ({ leftControl, rightControl, content }) => (
  <React.Fragment>
    {renderControls(leftControl, rightControl)}
    <PageContainer>{renderContent(content)}</PageContainer>
  </React.Fragment>
)
