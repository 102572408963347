import styled from "styled-components"
import Chart from "react-apexcharts"

export const Container = styled.div`
  position: relative;
  font-family: Geometria;
  margin-bottom: 10px;
  width: 100%;
  max-width: 185px;

  @media screen and (min-width: 801px) {
    &:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    max-width: 175px;
  }

  @media screen and (max-width: 480px) {
    max-width: 185px;
  }
`

export const Title = styled.h6`
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);
  font-weight: 600;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const ApexChart = styled(Chart)`
  margin-left: -21px;
`
