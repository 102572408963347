import React from "react"

import styled from "styled-components"

import {
  Button,
  Heading,
  Translate,
  DefaultModal,
  FormLoadingButton,
} from "src/components"

const ButtonsContainer = styled.div`
  padding-top: 44px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 84px;
`

const SubmitModal = (props) => {
  const {
    onSave, onClose, onCancel, isSaveLoading,
  } = props

  return (
    <DefaultModal onClose={onClose} padding="45px 44px 42px">
      <Heading.h4 textAlign="center">
        <Translate i18n="components:individual_account.update.submit_modal.save_from_sketch" />
      </Heading.h4>
      <ButtonsContainer>
        <FormLoadingButton
          isLoading={isSaveLoading}
          width="240px"
          height="32px"
          variant="red"
          onClick={onSave}
          display="inline-block"
        >
          <Translate i18n="components:individual_account.update.submit_modal.save_as_sketch" />
        </FormLoadingButton>
        <Button
          width="240px"
          height="32px"
          variant="empty"
          onClick={onCancel}
          display="inline-block"
        >
          <Translate i18n="components:individual_account.update.submit_modal.do_not_save" />
        </Button>
      </ButtonsContainer>
    </DefaultModal>
  )
}

export default SubmitModal
