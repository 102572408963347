import React, {
  useContext, useState, useRef, useEffect, useCallback,
} from "react"
import { useHistory } from "react-router"
import { graphql } from "relay-runtime"
import { Spring } from "react-spring/renderprops"

import { useSubscription } from "src/hooks"
import { Translate, ModalPaymentSchedule, Box } from "src/components"
import { CurrentProfileContext } from "src/context"
import {
  getOrderInterestRate,
  getOrderRating,
  roundNumber,
  hasCollectedMaxAmount,
  isOfferExpired,
} from "src/utils"
import { formatDateShort, formatDayMonth } from "src/utils/date"

import { MobileInformationCompany, Statusbar } from "../Elements"

import { getCessionRedemptionDate } from "./helpers/mobileOrder"
import {
  MobileWrapper as Container,
  MobileItemContainer,
  MobileHeaderContainer,
  HeaderWrap,
  MobileInformationContainer,
  MobileInformationItem,
  MobileInformationName,
  MobileInformationValue,
  MobileGoalItem,
  MobileGoalValue,
  MobileGoalBar,
  MobileDescriptionTitle,
  MobileDescription,
  MobileButton,
  PrimaryMobileButton,
  CardButtonsWrapper,
  CalculatorIcon,
  ButtonLink,
  FlipContainer,
  Flipper,
  FlipperBack,
  FlipperFront,
} from "./styles"
import { removeDots } from "./utils"

import {
  MobileOrderItemForGatheredAmountSubscription,
} from "./__generated__/MobileOrderItemForGatheredAmountSubscription.graphql"

function getOrderDate(data) {
  const { term = 0 } = data?.offer?.data

  const dateParse = new Date(data.confirmedAt)
  const newDate = new Date(
    dateParse.setDate(dateParse.getDate() + Number(term)),
  )

  return formatDayMonth(newDate)
}

const subscription = graphql`
  subscription MobileOrderItemForGatheredAmountSubscription(
    $input: GatheredAmountSubscriptionInput!
  ) {
    gatheredAmount(input: $input) {
      order {
        chain {
          gatheredAmount
        }
      }
    }
  }
`

function MobileItem(props) {
  const { data, isCession } = props

  const { profile = {} } = useContext(CurrentProfileContext)
  const [isClicked, setClicked] = useState(false)
  const [scheduleActive, setScheduleActive] = React.useState(false)
  const history = useHistory()

  const [height, setHeight] = useState(300)
  const containerRef = useRef(null)
  const containerRef2 = useRef(null)

  useEffect(() => {
    const maxHeight = Math.max(
      containerRef.current.clientHeight,
      containerRef2.current.clientHeight,
      300,
    )

    if (height !== maxHeight) setHeight(maxHeight)
  })

  const { companyName } = data?.application?.data
  const [media] = data?.application?.externalMedium

  const { duration, repaymentSchedule } = data.offer.data
  const { minValue: min, maxValue: max } = data.application.data

  const price = (isCession && Math.round((max / data.cession.amount) * 100)) || 0
  const amount = data.chain?.gatheredAmount || 0
  const goal = amount > min ? max : min

  const goalView = `${roundNumber(min)}-${roundNumber(max)}`
  const valueStatusBar = (amount / goal) * 100 > 100 ? 100 : (amount / goal) * 100

  const cessionRedemptionDate = formatDateShort(getCessionRedemptionDate(data))

  const volumeView = `${roundNumber(
    data?.chain?.gatheredAmount || 0,
  )} из ${roundNumber(max)}`

  const collected = hasCollectedMaxAmount(data)
  const isDisabled = React.useMemo(
    () => !profile
      || isOfferExpired(data)
      || data.status === "COMPLETE"
      || collected,
    [data, profile],
  )

  const toggleAdditionalInfo = useCallback(() => {
    setClicked((val) => !val)
  }, [])

  const toInvest = useCallback((event) => {
    event.stopPropagation()

    if (!isDisabled) {
      history.push(`/invest/${data.id}`)
    }
  }, [isDisabled, data.id])

  const openCalculator = useCallback((event) => {
    event.stopPropagation()
    setScheduleActive(true)
  }, [])

  const closeCalculator = useCallback(() => setScheduleActive(false), [])

  useSubscription<MobileOrderItemForGatheredAmountSubscription>(
    () => ({
      subscription,
      variables: { input: { orderId: data.id } },
      // eslint-disable-next-line no-console
      onError: (error) => console.error(error),
    }),
    [data.id],
  )

  return (
    <MobileItemContainer minHeight={height} onClick={toggleAdditionalInfo}>
      <FlipContainer isClicked={isClicked}>
        <Flipper>
          <FlipperFront minHeight={height} ref={containerRef}>
            <HeaderWrap>
              <MobileHeaderContainer>
                <MobileInformationCompany name={companyName} video={media} />
              </MobileHeaderContainer>
            </HeaderWrap>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>
                  <Translate i18n={"models:loan.rating"} />
                </MobileInformationName>
                <MobileInformationValue>
                  {getOrderRating(data)}
                </MobileInformationValue>
              </MobileInformationItem>

              {!isCession && (
                <>
                  <MobileInformationItem>
                    <MobileInformationName>
                      <Translate i18n={"models:loan.rate"} />
                    </MobileInformationName>
                    <MobileInformationValue>
                      {getOrderInterestRate(data)}%
                    </MobileInformationValue>
                  </MobileInformationItem>
                  <MobileInformationItem>
                    <MobileInformationName>
                      <Translate i18n={"models:loan.duration"} />
                    </MobileInformationName>
                    <MobileInformationValue>
                      {duration} <Translate i18n={"models:loan.month"} />
                    </MobileInformationValue>
                  </MobileInformationItem>
                </>
              )}

              {isCession && (
                <>
                  <MobileInformationItem>
                    <MobileInformationName>Цена</MobileInformationName>
                    <MobileInformationValue>{price}%</MobileInformationValue>
                  </MobileInformationItem>
                  <MobileInformationItem>
                    <MobileInformationName>Доходность</MobileInformationName>
                    <MobileInformationValue>
                      {getOrderInterestRate(data)}%
                    </MobileInformationValue>
                  </MobileInformationItem>
                </>
              )}
            </MobileInformationContainer>

            <Spring config={{ clamp: true }} to={{ amount, valueStatusBar }}>
              {({ amount, valueStatusBar }) => {
                const amountView = roundNumber(amount)

                return (
                  <MobileGoalItem>
                    <MobileGoalValue>
                      <span>Собрано</span>
                      <span>
                        {!isCession && `${amountView} из ${goalView}`}
                        {isCession && `${volumeView}`}
                      </span>
                    </MobileGoalValue>
                    <MobileGoalBar>
                      <Statusbar value={valueStatusBar} orange={amount < min} />
                    </MobileGoalBar>
                  </MobileGoalItem>
                )
              }}
            </Spring>
          </FlipperFront>

          <FlipperBack minHeight={height} ref={containerRef2}>
            <Box mb={32}>
              <MobileDescriptionTitle>Описание</MobileDescriptionTitle>
              <MobileDescription>
                {data.application.longTitle
                  || data.application.shortTitle
                  || "Описание отсутствует"}
              </MobileDescription>
            </Box>

            <MobileInformationContainer>
              <MobileInformationItem>
                <MobileInformationName>Погашение ОД</MobileInformationName>
                <MobileInformationValue>
                  {repaymentSchedule?.type === "end" && "В конце"}
                  {repaymentSchedule?.type === "equal_share" && "Частями"}
                </MobileInformationValue>
              </MobileInformationItem>

              {!isCession && (
                <MobileInformationItem>
                  <MobileInformationName>Сбор до</MobileInformationName>
                  <MobileInformationValue>
                    {removeDots(getOrderDate(data))}
                  </MobileInformationValue>
                </MobileInformationItem>
              )}

              {isCession && (
                <MobileInformationItem>
                  <MobileInformationName>Погашение займа</MobileInformationName>
                  <MobileInformationValue>
                    {removeDots(cessionRedemptionDate)}
                  </MobileInformationValue>
                </MobileInformationItem>
              )}
            </MobileInformationContainer>

            <CardButtonsWrapper>
              <PrimaryMobileButton
                disabled={isDisabled}
                onClick={toInvest}
                width="39%"
              >
                инвестировать
              </PrimaryMobileButton>
              <MobileButton width="39%">
                <ButtonLink to={`/market/${data.id}?source=market`}>
                  подробнее
                </ButtonLink>
              </MobileButton>
              <MobileButton onClick={openCalculator}>
                <CalculatorIcon />
              </MobileButton>
            </CardButtonsWrapper>
          </FlipperBack>

          {scheduleActive && (
            <ModalPaymentSchedule
              isShowing={scheduleActive}
              onClose={closeCalculator}
              {...props}
            />
          )}
        </Flipper>
      </FlipContainer>
    </MobileItemContainer>
  )
}

export default {
  Container,
  Body: MobileItem,
}
