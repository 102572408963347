import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const InnerDesktopWrapper = styled.div`
  position: relative;
`

const InnerMobileWrapper = styled.div`
  position: relative;
  margin-top: 60px;
  display: flex;
`

export const DesktopWrapper = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return !mobile && <InnerDesktopWrapper {...props}/>
}

export const MobileWrapper = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return mobile && <InnerMobileWrapper {...props}/>
}
