import React from "react"

import { MIN_INVESTMENT_AMOUNT } from "src/constants"

import {
  Box,
  Text,
  Flex,
  Translate,
  FloatingLabelInput as Input,
} from "src/components"

import { AmountButton } from "../elements"
import { CalcContainer, Title } from "../styles"

import { formatNumber, isEqualAmount, fitsAvailableAmount } from "../utils"

const defaultAmounts = [MIN_INVESTMENT_AMOUNT, 50000, 150000]

const CurrencyTooltip = () => <Text fontSize="20px">₽</Text>

const Calculator = (props) => {
  const {
    busy, amount, onChange, availableAmount,
  } = props

  const fits = fitsAvailableAmount(availableAmount)

  return (
    <CalcContainer>
      <Box mb="5px">
        <Title>
          <Translate i18n="modals:profitability_calc.title" />
        </Title>
      </Box>
      <Box width="470px" mb="10px">
        <Input
          name="phone_confirmation"
          value={amount}
          disabled={!!busy}
          onChange={onChange}
          tooltip={CurrencyTooltip}
          label="modals:profitability_calc.amount_label"
        />
      </Box>
      <Flex mb="25px" width="525px">
        {defaultAmounts.map(
          (item) => fits(item) && (
              <AmountButton
                mr="10px"
                key={item}
                value={item}
                disabled={busy}
                onClick={onChange}
                active={isEqualAmount(item, amount)}
              >
                {formatNumber(item)}
              </AmountButton>
          ),
        )}
        {!defaultAmounts.includes(availableAmount) && (
          <AmountButton
            disabled={busy}
            onClick={onChange}
            value={availableAmount}
            active={isEqualAmount(availableAmount, amount)}
          >
            {formatNumber(availableAmount)}
          </AmountButton>
        )}
      </Flex>
    </CalcContainer>
  )
}

export default Calculator
