import React from "react"
import { useParams } from "react-router-dom"
import { graphql } from "react-relay"

import { PageContainer, QueryRenderer } from "src/components"

import { ReferralHeader, ReferralForm, ReferralDescription } from "./Elements"

function ReferralPage(props) {
  return (
    <PageContainer>
      <ReferralHeader />
      <ReferralForm referralLink={props.node} />
      <ReferralDescription referrals={props.node} />
    </PageContainer>
  )
}

const query = graphql`
  query ReferralPageQuery($id: ID!) {
    node(id: $id) {
      ... on UserProfile {
        ...ReferralForm_referralLink
        ...ReferralDescription_referrals
      }
    }
  }
`

export default () => {
  const { id } = useParams()

  const variables = {
    id,
  }

  return (
    <QueryRenderer query={query} render={ReferralPage} variables={variables} />
  )
}
