import { width } from "styled-system"
import styled, { css } from "styled-components"

const state = (color) => css`
  border-color: ${color} !important;
  border-left-width: 4px;
  padding-left: 7px;
`

export const Input = styled.input<any>`
  color: ${({ theme }) => theme.colors.greyShuttle};
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
  border-radius: 5px;
  height: 50px;
  margin: 0;
  padding: 0 10px;
  ${width};

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyDark};
    font-weight: 700;
  }

  &:disabled {
    opacity: 0.4;
  }

  ${({ error }) => error && state("red")};
  ${({ success }) => success && state("green")};
`

Input.defaultProps = {
  width: "100%",
}

export const Error = styled.div`
  margin-top: 3px;
  font-size: 14px;
  line-height: 1.55;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.red};
`
