export const matchError = {
  keyword: "match",
  path: "setViewerPassword.retry_password",
  type: "match",
}

export const requiredError = (type) => ({
  keyword: "required",
  path: `setViewerPassword.${type}`,
  type: "required",
})
