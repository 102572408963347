import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ProfileBankAccountsQuery {
    viewer {
      profiles {
        id
        __typename
        bankAccounts {
          id
          status
        }
      }
    }
  }
`

/* eslint-disable arrow-body-style */
export default (render) => (props) => {
  return <QueryRenderer {...props} query={query} render={render} />
}
