const theme = {
  container: {
    position: "relative",
  },
  suggestionsList: {
    position: "absolute",
    top: "65px",
    right: 0,
    left: 0,
    margin: 0,
    padding: 0,
    border: "1px solid #aaa",
    backgroundColor: "white",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    zIndex: "10",
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
}

export default theme
