import React, { useState } from "react"

import { UpdateBankAccount, AddProfileBankAccount } from "src/mutations"
import { MutationContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"
import { compose } from "src/utils"
import { FloatingLabelInput, BankDataSuggestion } from "src/components"

import { ButtonContainer, PrimaryButton, Button } from "../styles"
import { Form } from "./styles"
import {
  getBankAccountValues,
  getCreateBankAccountVariables,
  getUpdateBankAccountVariables,
} from "./utils"
import {
  clearFormError,
  getValidity,
} from "../../BankAccounts/RussianBankAccounts/utils"

function EditBankAccount(props) {
  const {
    bankAccount, getError, clearError, setEditing,
  } = props

  const [values, setValues] = useState(getBankAccountValues(bankAccount))
  const [isValid, setValid] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const errorPrefix = bankAccount.id
    ? "updateBankAccount"
    : "addProfileBankAccount"

  function handleSuggestionSelected(suggestion) {
    const {
      bic,
      name,
      correspondent_account: correspondentAccount,
    } = suggestion.data

    const newValues = {
      ...values,
      bic,
      name: name.payment,
      correspondentAccount: correspondentAccount || "",
    }
    const valid = getValidity(newValues)

    setValid(valid)
    setValues(newValues)
  }

  function handleChange(event) {
    const { name, value } = event.target

    const newValues = {
      ...values,
      [name]: value,
    }
    const valid = getValidity(newValues)

    setValid(valid)
    setValues(newValues)
  }
  const clear = clearFormError(clearError)
  const handleChangeDecorated = (path) => compose(clear(path), handleChange)

  function onCompleted() {
    setLoading(false)
    setEditing(false)
  }

  function onError() {
    setLoading(false)
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (!isValid) return

    const { commit, profileId } = props
    setLoading(true)

    const callbacks = {
      onError,
      onCompleted,
    }
    const variables = bankAccount.id
      ? getUpdateBankAccountVariables(values, bankAccount.id)
      : getCreateBankAccountVariables(values, profileId)

    commit({ callbacks, variables })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <BankDataSuggestion
        name="name"
        label="Наименование банка"
        hasError={getError(`${errorPrefix}.bank.name`)}
        error={getError(`${errorPrefix}.bank.name`)}
        value={values.name}
        onChange={handleChangeDecorated(`${errorPrefix}.bank.name`)}
        daDataSelector={(suggestion) => suggestion.data.name.payment}
        onSuggestionSelected={handleSuggestionSelected}
        renderInputComponent={(inputProps) => (
          <FloatingLabelInput {...inputProps} />
        )}
      />
      <BankDataSuggestion
        name="bic"
        label="БИК"
        mask={"999999999"}
        hasError={getError(`${errorPrefix}.bank.bic`)}
        error={getError(`${errorPrefix}.bank.bic`)}
        value={values.bic}
        onChange={handleChangeDecorated(`${errorPrefix}.bank.bic`)}
        daDataSelector={(suggestion) => suggestion.data.bic}
        onSuggestionSelected={handleSuggestionSelected}
        renderInputComponent={(inputProps) => (
          <FloatingLabelInput {...inputProps} />
        )}
      />
      <FloatingLabelInput
        value={values.correspondentAccount}
        onChange={handleChangeDecorated(`${errorPrefix}.correspondentAccount`)}
        name="correspondentAccount"
        label="Корреспондентский счет"
        mask={"99999999999999999999"}
        hasError={getError(`${errorPrefix}.correspondentAccount`)}
        error={getError(`${errorPrefix}.correspondentAccount`)}
      />
      <FloatingLabelInput
        value={values.checkingAccount}
        onChange={handleChangeDecorated(`${errorPrefix}.checkingAccount`)}
        name="checkingAccount"
        label="Расчетный счет"
        mask={"99999999999999999999"}
        hasError={getError(`${errorPrefix}.checkingAccount`)}
        error={getError(`${errorPrefix}.checkingAccount`)}
      />

      <ButtonContainer>
        <PrimaryButton disabled={!isValid || isLoading} type="submit">
          Сохранить
        </PrimaryButton>

        {bankAccount && bankAccount.id && (
          <Button type="button" onClick={() => setEditing(false)}>
            Отменить
          </Button>
        )}
      </ButtonContainer>
    </Form>
  )
}

export default (props) => {
  const { bankAccount } = props
  const mutation = bankAccount && bankAccount.id ? UpdateBankAccount : AddProfileBankAccount

  return (
    <MutationContainer mutation={mutation}>
      {({ commit, getError, clearError }) => (
        <RelayEnvironmentConsumer>
          {({ environment }) => (
            <EditBankAccount
              {...props}
              commit={commit}
              getError={getError}
              clearError={clearError}
              environment={environment}
            />
          )}
        </RelayEnvironmentConsumer>
      )}
    </MutationContainer>
  )
}
