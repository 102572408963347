import React from "react"

import { TextField } from "src/components"
import { InputContainer, IconContainer } from "./styles"

import EyeIcon from "./icon"

class PasswordInput extends React.Component<any, any> {
  state = {
    type: "password",
    visible: false,
  }

  changeInputType = () => {
    if (this.props.disabled) {
      return
    }

    const type = this.state.type === "password" ? "text" : "password"
    this.setState(({ visible }) => ({ type, visible: !visible }))
  }

  render() {
    return (
      <InputContainer>
        <TextField type={this.state.type} {...this.props} />
        <IconContainer
          onClick={this.changeInputType}
          disabled={this.props.disabled}
        >
          <EyeIcon visible={this.state.visible} />
        </IconContainer>
      </InputContainer>
    )
  }
}

export default PasswordInput
