import React from "react"

import {
  Box, Flex, OrderFactory,
} from "src/components"

import { parseOrdersData } from "src/utils"

import Info from "./Info"
import OrdersCount from "./GuestOrdersCount"
import CompletedGuest from "./CompletedGuest"

const Orders = (props) => {
  const orders = [...parseOrdersData(props.data)]

  return (
    <>
      <Box mb={30}>
        <OrdersCount orders={props.orders || null} />
      </Box>
      <Info />
      <Flex alignItems="flex-start" flexDirection="column">
        {orders.length
          ? orders.map((data, index) => {
            const Item = OrderFactory.createGuestOrder(data)

            return <Item key={index} />
          })
          : null}
      </Flex>
      <CompletedGuest completed={props.data} />
    </>
  )
}

export default Orders
