import React from "react"

import { graphql, createPaginationContainer } from "react-relay"

import { QueryRenderer } from "src/components"
import { VISIBLE_ORDER } from "../portfolio/constants"
import { RenderTableComponent } from "./utils"

// TODO: unused - вернуть позже или удалить окончательно
/* const MockLoader = (props) => (
  <Box py="60px">
    <Loader fontSize="14px" />
  </Box>
) */

const manageOrdersQuery = graphql`
  query MarketOrdersHugeListQuery(
    $pageSize: Int!
    $after: Cursor
    $filter: OrdersFilterActive
  ) {
    ...MarketOrdersHugeList_root
  }
`
const PaginatedOrderTable = createPaginationContainer(
  RenderTableComponent,
  {
    root: graphql`
      fragment MarketOrdersHugeList_root on Query {
        orders(first: $pageSize, after: $after, filter: $filter)
          @connection(key: "ManageOrdersRelay_orders") {
          edges {
            node {
              id
              __typename
              ... on Order {
                viewer {
                  hasIntent
                  investedAmount
                }
                tags {
                  name
                }
                backgroundImage {
                  url
                }
                createdAt
                status
                externalUrl
                fee
                profile {
                  ... on UserProfile {
                    id
                    ... on EntrepreneurProfile {
                      creditRating {
                        rating
                      }
                    }
                    ... on LegalEntityProfile {
                      name
                      inn
                      ogrn
                      executive {
                        name
                      }
                      borrower {
                        ticker
                      }
                    }
                    creditRating {
                      rating
                    }
                    _avatar {
                      url
                    }
                  }
                }
                offer {
                  ... on OrderOffer {
                    id
                    data
                    declineReason
                    receivedAt
                  }
                }
                application {
                  shortTitle
                  longTitle
                  description
                  externalMedium {
                    video {
                      url
                    }
                  }
                  ... on OrderApplication {
                    id
                    data
                    declineReason
                    receivedAt
                  }
                }
                confirmedAt
                creditRating {
                  rating
                }
                otherDocuments {
                  attachment {
                    url
                    filename
                  }
                  description
                }
                cession {
                  due
                  amount
                  isActive
                  interestRate
                  borrowerName
                  originalChainId
                  avatar {
                    url
                  }
                  borrowerRating {
                    rating
                    ratedAt
                  }
                }
                skipOffer
                karma {
                  isInvestor
                  isTrusted
                }
                chain {
                  id
                  gatheredAmount
                  investorsCount
                }
                guarantors {
                  attachment {
                    url
                    filename
                  }
                  name
                  description
                }
                status
                provision {
                  items {
                    kind
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: manageOrdersQuery,
    getConnectionFromProps: (props) => props.root.orders,
    getFragmentVariables: (previousVars) => ({
      ...previousVars,
      pageSize: VISIBLE_ORDER,
    }),
    getVariables: (props, paginationInfo, { filter }) => ({
      filter,
      pageSize: VISIBLE_ORDER,
      after: paginationInfo.cursor,
    }),
  },
)

type Props = {
  render: (props: any) => JSX.Element
}

const MarketOrdersHugeList = ({ render }: Props) => {
  const [filter, setFilter] = React.useState({})

  const paginatedRender = (props) => (
    <PaginatedOrderTable
      root={props.data || null}
      render={render}
      filter={filter}
      setFilter={setFilter}
      pageSize={VISIBLE_ORDER}
    />
  )

  return (
    <QueryRenderer
      query={manageOrdersQuery}
      render={paginatedRender}
      renderNull={paginatedRender}
      variables={{
        filter: {
          ...filter,
          status: ["TRANSFER", "CONFIRMED"],
          cession: false,
        },
        pageSize: VISIBLE_ORDER,
      }}
    />
  )
}

export default MarketOrdersHugeList
