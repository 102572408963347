/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type InvestmentsOrderListQueryVariables = {};
export type InvestmentsOrderListQueryResponse = {
    readonly viewer: {
        readonly investments: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly order: {
                        readonly id: string;
                        readonly status: OrderStatusEnum | null;
                    };
                    readonly amount: number;
                };
            }>;
        };
    } | null;
};
export type InvestmentsOrderListQuery = {
    readonly response: InvestmentsOrderListQueryResponse;
    readonly variables: InvestmentsOrderListQueryVariables;
};



/*
query InvestmentsOrderListQuery {
  viewer {
    investments {
      edges {
        node {
          id
          order {
            id
            status
          }
          amount
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestmentConnection",
  "kind": "LinkedField",
  "name": "investments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvestmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Investment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "order",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentsOrderListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvestmentsOrderListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvestmentsOrderListQuery",
    "operationKind": "query",
    "text": "query InvestmentsOrderListQuery {\n  viewer {\n    investments {\n      edges {\n        node {\n          id\n          order {\n            id\n            status\n          }\n          amount\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bb3b620a5d4dc438664014c3aff687c2';
export default node;
