/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type ConfirmBankAccountWithPhoneInput = {
    accountId: string;
    token: string;
};
export type ConfirmForeignBankAccountWithPhoneMutationVariables = {
    input: ConfirmBankAccountWithPhoneInput;
};
export type ConfirmForeignBankAccountWithPhoneMutationResponse = {
    readonly confirmBankAccountWithPhone: {
        readonly foreignAccount: {
            readonly id: string;
            readonly status: BankAccountStatusEnum;
            readonly ownerName: string;
            readonly IBAN: string;
            readonly bank: {
                readonly name: string;
                readonly SWIFT: string;
                readonly address: string;
            };
        } | null;
    };
};
export type ConfirmForeignBankAccountWithPhoneMutation = {
    readonly response: ConfirmForeignBankAccountWithPhoneMutationResponse;
    readonly variables: ConfirmForeignBankAccountWithPhoneMutationVariables;
};



/*
mutation ConfirmForeignBankAccountWithPhoneMutation(
  $input: ConfirmBankAccountWithPhoneInput!
) {
  confirmBankAccountWithPhone(input: $input) {
    foreignAccount {
      id
      status
      ownerName
      IBAN
      bank {
        name
        SWIFT
        address
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ConfirmBankAccountWithPhoneInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConfirmBankAccountWithPhonePayload",
    "kind": "LinkedField",
    "name": "confirmBankAccountWithPhone",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ForeignBankAccount",
        "kind": "LinkedField",
        "name": "foreignAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IBAN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignBank",
            "kind": "LinkedField",
            "name": "bank",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SWIFT",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmForeignBankAccountWithPhoneMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmForeignBankAccountWithPhoneMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ConfirmForeignBankAccountWithPhoneMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmForeignBankAccountWithPhoneMutation(\n  $input: ConfirmBankAccountWithPhoneInput!\n) {\n  confirmBankAccountWithPhone(input: $input) {\n    foreignAccount {\n      id\n      status\n      ownerName\n      IBAN\n      bank {\n        name\n        SWIFT\n        address\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b5bbce23b754bc7f251305b3a0fadd73';
export default node;
