import React from "react"

import { Paid, Invest } from "./Elements"

import { Investments, InvestContainer } from "./styles"

const options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const InvestmentsData = () => (
  <Investments>
    <Invest options={options} />
    <Paid options={options} />
  </Investments>
)

export default () => (
  <InvestContainer>
    <InvestmentsData />
  </InvestContainer>
)
