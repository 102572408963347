import React from "react"
import styled from "styled-components"

import Icons from "../Icons"

const Icon = styled(Icons.Arrow)`
  width: 24px;
  height: 26px;
  padding-bottom: 2px;
  fill: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (min-width: 481px) {
    display: none;
  }
`

const Button = styled.button`
  font-family: Geometria, sans-serif;
  position: relative;
  font-size: 12px;
  margin: 0;
  background-color: inherit;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};
  border: none;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.19);
  display: inline-block;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryBlack};
    margin-top: 20px;
    display: flex;
    box-shadow: none;
    padding: 0;
  }
`

export default function (props) {
  const { onClick, children } = props

  return (
    <Button onClick={onClick}>
      <Icon /> {children}
    </Button>
  )
}
