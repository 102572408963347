import React from "react"

import { PLEDGOR_TYPE } from "src/constants"
import {
  Box,
  FlexBox,
  FormRow,
  Label,
  RadioButton,
  TextField,
  Translate,
} from "src/components"
import { cloneDeep } from "src/utils"

class Pledgor extends React.Component<any, any> {
  onChange = (event) => {
    const { name, value } = event.target
    const pledgorValue = cloneDeep(this.props.value)
    pledgorValue[name] = value

    this.props.onChange({
      name: this.props.name,
      value: pledgorValue,
    })
  }

  render() {
    return (
      <FormRow>
        <Box>
          <Label>
            <Translate
              i18n="legal_entity_offer_form.rows.pledgor.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box>
          {PLEDGOR_TYPE.map(({ label, value }, index) => (
            <FlexBox
              key={index}
              display="flex"
              flexDirection="row"
              justifyContnet="flex-start"
              alignItems="center"
              py="6px"
            >
              <RadioButton
                label={label}
                value={value}
                onChange={this.onChange}
                name="pledgorType"
                checked={this.props.value.pledgorType === value}
              />
            </FlexBox>
          ))}
          <Box width="480px">
            <TextField
              name="name"
              value={this.props.value.name}
              onChange={this.onChange}
              hint={
                <Translate
                  i18n="legal_entity_offer_form.rows.pledgor.row_hints.name"
                  ns="components"
                />
              }
            />
          </Box>
        </Box>
      </FormRow>
    )
  }
}

export default Pledgor
