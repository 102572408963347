import React from "react"

import { isRussianInvestor, isRussianEntrepreneur } from "src/utils"
import { Box, Text, Document } from "src/components"

import normalizeDocuments from "../utils"
import { confirmedDateAdapter } from "../../../utils"
import InvestmentDocument from "./InvestmentDocument"

const isValidStatus = (status) => ["CONFIRMED", "COMPLETE", "SUCCEEDED"].includes(status)

const ConfirmationDate = ({ children }) => (
  <Box mb="35px">
    <Text fontSize="14px" lineHeight="1.5" color="greyShuttle">
      {children}
    </Text>
  </Box>
)

const DocumentsList = ({ order, viewer }) => {
  const { status } = order
  const documents = React.useMemo(() => normalizeDocuments(order), [order])
  const getConfirmationDate = confirmedDateAdapter({
    i18nRef: "common:order.entity-date.confirmed",
  })
  const individualProfile = viewer.profiles.find(isRussianInvestor)
  const entrepreneurProfile = viewer.profiles.find(isRussianEntrepreneur)

  return (
    <>
      {isValidStatus(status) && (
        <ConfirmationDate>{getConfirmationDate(order)}</ConfirmationDate>
      )}
      {documents.map((doc, idx) => (
        <Box mb="20px" key={idx}>
          <Document doc={doc} />
        </Box>
      ))}
      {individualProfile && (
        <InvestmentDocument profile={individualProfile} order={order} />
      )}
      {entrepreneurProfile && (
        <InvestmentDocument profile={entrepreneurProfile} order={order} />
      )}
    </>
  )
}

export default DocumentsList
