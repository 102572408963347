import styled from "styled-components"

import { Icons, Flex } from "src/components"

export const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: 375px;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  padding: 40px 30px;
`

export const Title = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 22px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primaryBlacks[9]};
`

export const Description = styled(Title)`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primaryBlack};
  margin-top: 25px;
  font-weight: 700;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  right: 16px;
  padding: 0;
  display: flex;
`

export const CloseIcon = styled(Icons.Close)`
  width: 24px;
  height: 24px;
  fill: black;
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 16px;
`

export const Timer = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 0;
`

export const ButtonContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
`

export const Button = styled.button`
  width: 165px;
  padding: 16px 0 14px;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[3]};
  box-sizing: border-box;
  border-radius: 4px;
  background-color: inherit;
  font-family: Geometria, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const PrimaryButton = styled(Button)`
  border-color: ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.mainRed};
  margin-left: 12px;
  width: 125px;
`

export const SuccessContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 50px 0 20px;
`

export const SuccessTitle = styled(Title)`
  margin-top: 28px;
  text-align: center;
`

export const SuccessIcon = styled(Icons.Success)`
  margin: 0 auto;
`
