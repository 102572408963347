import React, { useState } from "react"

import {
  Icon, Button, Translate, DefaultModal,
} from "src/components"

import {
  Label,
  ButtonWrapper,
  IconContainer,
  LabelContainer,
  ButtonContainer,
} from "./styles"

const InfoLink = (props) => {
  const { label, content } = props

  const [showModal, setShowModal] = useState(false)
  const onModalOpen = () => setShowModal(true)
  const onModalClose = () => setShowModal(false)

  return (
    <React.Fragment>
      {label && (
        <LabelContainer>
          <Label onClick={onModalOpen}>
            <Translate i18n={label} />
          </Label>
          <IconContainer>
            <Icon.info />
          </IconContainer>
        </LabelContainer>
      )}
      {showModal && (
        <DefaultModal padding="48px 40px 40px" onClose={onModalClose}>
          {content && content()}
          <ButtonContainer>
            <ButtonWrapper>
              <Button height="48px" variant="blueWide" onClick={onModalClose}>
                <Translate i18n="common:buttons.ok" />
              </Button>
            </ButtonWrapper>
          </ButtonContainer>
        </DefaultModal>
      )}
    </React.Fragment>
  )
}

export default InfoLink
