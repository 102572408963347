import React from "react"

import {
  Box,
  Label,
  Translate,
} from "src/components"

import { initialStateFromProps } from "src/utils"

import Attachments from "../../../../../FormBuilder/NewInputs/Attachments"

import {
  PassportScansContainer,
  Tooltip,
  PassportRowWrapper,
} from "./styles"

const ScansHeader = () => (
  <Box>
    <Label whiteSpace="pre-line" mb="3px" textTransform="uppercase">
      <Translate i18n="components:individual_profile_form.titles.passport.scans" />
    </Label>
  </Box>
)

/* TODO: unused - вернуть или удалить окончательно
const PostponeCheckbox = ({ checked, onChange }) => (
  <Box pb="20px">
    <CheckBoxField
      name="postponeScans"
      fill="#F70000"
      color="#4a4a4a"
      checked={checked}
      onChange={onChange}
      fontSize="14px"
      label={
        <Translate i18n="components:individual_profile_form.labels.passport.postponeScans" />
      }
    />
  </Box>
) */

class PassportData extends React.Component<any, any> {
  state = {
    ...initialStateFromProps(this.props.value, ["scans"]),
  }

  onSelectChange = ({ name, value }) => {
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state,
    })
  }

  render() {
    return (
      <>
        {!this.props.withoutHeader && <ScansHeader />}

        <PassportScansContainer>
          <Box width="100%" pb="12px">
            <Tooltip>
              <Translate i18n="components:individual_profile_form.extras.postponeScans" />
            </Tooltip>
          </Box>

          <PassportRowWrapper width="100%">
            <Attachments
              name="scans"
              value={this.state.scans}
              onChange={this.onSelectChange}
              width="100%"
              padding="0 0 20px"
            />
            <Box width="100%">
              <Tooltip>
                <Translate i18n="components:individual_profile_form.extras.passport" />
              </Tooltip>
            </Box>
          </PassportRowWrapper>
        </PassportScansContainer>
      </>

    // TODO: Romove all commented legacy code

    // <PassportScans
    //   environment={this.props.environment}
    //   value={this.state.scans}
    //   onChange={this.onSelectChange}
    //   name="scans"
    // />
    )
  }
}

export default PassportData
