import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding-top: 30px;
`

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  word-break: break-word;
`

const border = ({ theme, active }) => (active ? `3px solid ${theme.colors.red}` : "none")

export const NavItem = styled.div<any>`
  margin-right: 40px;
  padding: 5px 2px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;
  border-bottom: ${border};

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

export const NavContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: flex-start;
`

export const ContentContainer = styled.div`
  max-width: 900px;
`

export const ExpertAvatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`

export const SocialIcon = styled.div<any>`
  width: 24px;
  height: 24px;
  background-image: url("${({ image }) => image}");
  cursor: pointer;
`

export const ConclusionContent = styled.p`
  margin: 0;
  text-align: left;
  font-size: 16px;
  white-space: pre-line;
`
