import React, { useState } from "react"

import { DescriptionModal, DocumentsModal } from "../Modals"

import {
  ItemContainer,
  ItemName,
  InfoIcon,
  ButtonContainer,
  ItemButton,
  SuccessIcon,
  ItemPoint,
} from "./styles"

function Item(props) {
  const { name, description, withDocuments } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCompleted, setCompleted] = useState(false)
  const [isHovered, setHovered] = useState(false)
  const [isClicked, setClicked] = useState(false)
  const [descriptionIsOpened, setDescriptionIsOpened] = useState(false)
  const [documentsIsOpened, setDocumentsIsOpened] = useState(false)

  function handleMouseEnter() {
    if (!isClicked) {
      setHovered(true)
    }
  }

  function handleMouseLeave() {
    if (!isClicked) {
      setHovered(false)
    }
  }

  function handleClick() {
    if (withDocuments) {
      setDocumentsIsOpened(true)
      return
    }

    setClicked(true)
    setHovered(false)
  }

  return (
    <>
      <ItemContainer
        isCompleted={isCompleted}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isClicked={isClicked}
      >
        <ItemName>
          <InfoIcon onClick={() => setDescriptionIsOpened(true)} />
          {name}
        </ItemName>

        {!isCompleted && (isHovered || isClicked) && (
          <ButtonContainer isClicked={isClicked}>
            <ItemButton onClick={handleClick}>
              {withDocuments && "отправить документы"}
              {!withDocuments && !isClicked && "отправить инструкцию"}
              {!withDocuments && isClicked && "отправить еще раз"}
            </ItemButton>
          </ButtonContainer>
        )}

        {isCompleted && (
          <ButtonContainer isCompleted={isCompleted}>
            <ItemPoint>
              650 баллов
              <SuccessIcon />
            </ItemPoint>
          </ButtonContainer>
        )}
      </ItemContainer>

      <DescriptionModal
        title={name}
        text={description}
        isOpened={descriptionIsOpened}
        onClose={() => setDescriptionIsOpened(false)}
      />

      <DocumentsModal
        title={name}
        text={description}
        isOpened={documentsIsOpened}
        onClose={() => setDocumentsIsOpened(false)}
      />
    </>
  )
}

export default Item
