import styled from "styled-components"

import { HeaderImage } from "../../images"

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  font-family: Geomentria, sans-serif;
  justify-content: space-between;

  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
  }
`

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 650px;

  @media screen and (max-width: 550px) {
    max-width: 100%;
  }
`

export const HeaderTitle = styled.h2`
  font-size: 45px;
  line-height: 57px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 800px) {
    font-size: 31px;
    line-height: 39px;
  }
`

export const HeaderDescription = styled.ol`
  margin: 24px 0 0;
  padding: 0;
  list-style-type: none;
  counter-reset: custom-counter;
`

export const HeaderDescriptionItem = styled.li`
  font-family: Geometria, sans-serif;
  font-size: 20px;
  line-height: 200%;
  color: ${({ theme }) => theme.colors.primaryBlack};
  counter-increment: custom-counter;
  position: relative;
  padding: 0 0 0 36px;

  &:before {
    content: counter(custom-counter);
    color: #ffffff;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: ${({ theme }) => theme.colors.mainRed};
    border-radius: 50%;
    text-align: center;
    left: 0;
    top: 6px;
  }

  @media screen and (max-width: 800px) and (min-width: 551px) {
    font-size: 14px;

    &:before {
      font-size: 14px;
      width: 19px;
      height: 19px;
      top: 4px;
    }
  }
`

export const ImageContainer = styled.div`
  max-width: 256px;
  width: 100%;
  height: auto;
  position: relative;
  padding: 0 40px;
  box-sizing: content-box;

  @media screen and (max-width: 800px) {
    max-width: 200px;
  }

  @media screen and (max-width: 550px) {
    max-width: 106px;
    padding: 0 0 25px 0;
    margin: 0 auto;
  }
`

export const Image = styled(HeaderImage)`
  width: 100%;
  height: auto;
`
