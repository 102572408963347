import memoize from "memoize-one"

export const ProposalSteps = {
  orderDescription: "orderDescription",
  financialStatements: "financialStatements",
  documents: "documents",
  schedule: "schedule",
  conclusion: "conclusion",
  assessment: "assessment",
  provision: "provision",
}

export const navSchema = memoize((order) => {
  const {
    karma,
    provision,
    expertOpinions,
    financialStatements,
    paymentScheduleList,
  } = order

  return [
    {
      step: ProposalSteps.orderDescription,
      isValid: true,
    },
    {
      step: ProposalSteps.financialStatements,
      isValid: !!(financialStatements && financialStatements.length),
    },
    {
      step: ProposalSteps.documents,
      isValid: true,
    },
    {
      step: ProposalSteps.schedule,
      isValid: !!(paymentScheduleList || []).some(
        (payment) => !!payment.items.length,
      ),
    },
    {
      step: ProposalSteps.assessment,
      isValid: true,
    },
    {
      step: ProposalSteps.conclusion,
      isValid: !!(
        (expertOpinions && expertOpinions.length)
        || karma.conclusion
      ),
    },
    {
      step: ProposalSteps.provision,
      isValid: !!provision,
    },
  ]
})
