/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type ProfileInvestmentsFilter = {
    status?: Array<OrderStatusEnum> | null;
    cession?: boolean | null;
    punishment?: boolean | null;
    search?: string | null;
};
export type InvestmentPaymentGraphIndividualQueryVariables = {
    profileId: string;
    filter?: ProfileInvestmentsFilter | null;
};
export type InvestmentPaymentGraphIndividualQueryResponse = {
    readonly node: {
        readonly investor?: {
            readonly investments: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly amount: number;
                        readonly order: {
                            readonly id: string;
                            readonly status: OrderStatusEnum | null;
                            readonly application: {
                                readonly data: string;
                            } | null;
                            readonly chain: {
                                readonly id: string;
                                readonly gatheredAmount: number;
                            } | null;
                            readonly paymentScheduleList: ReadonlyArray<{
                                readonly profile: {
                                    readonly id: string;
                                } | null;
                                readonly info: {
                                    readonly state: string | null;
                                } | null;
                                readonly items: ReadonlyArray<{
                                    readonly date: string;
                                    readonly payDate: string | null;
                                    readonly loan: number;
                                    readonly state: string | null;
                                    readonly total: number;
                                    readonly total_gross: number;
                                    readonly interest: number;
                                    readonly interest_fee: number;
                                    readonly personal_tax: number;
                                }>;
                            }>;
                        };
                    };
                }>;
            };
        } | null;
    };
};
export type InvestmentPaymentGraphIndividualQuery = {
    readonly response: InvestmentPaymentGraphIndividualQueryResponse;
    readonly variables: InvestmentPaymentGraphIndividualQueryVariables;
};



/*
query InvestmentPaymentGraphIndividualQuery(
  $profileId: ID!
  $filter: ProfileInvestmentsFilter
) {
  node(id: $profileId) {
    __typename
    ... on IndividualProfile {
      investor {
        investments(filter: $filter) {
          edges {
            node {
              id
              amount
              order {
                id
                status
                application {
                  data
                  id
                }
                chain {
                  id
                  gatheredAmount
                }
                paymentScheduleList {
                  profile {
                    __typename
                    id
                  }
                  info {
                    state
                  }
                  items {
                    date
                    payDate
                    loan
                    state
                    total
                    total_gross
                    interest
                    interest_fee
                    personal_tax
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ProfileInvestmentsFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderChainInfo",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatheredAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "LoanInfo",
  "kind": "LinkedField",
  "name": "info",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentScheduleItem",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loan",
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total_gross",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interest_fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "personal_tax",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentPaymentGraphIndividualQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualProfileInvestor",
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "InvestmentConnection",
                    "kind": "LinkedField",
                    "name": "investments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvestmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Investment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Order",
                                "kind": "LinkedField",
                                "name": "order",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderApplication",
                                    "kind": "LinkedField",
                                    "name": "application",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PaymentSchedule",
                                    "kind": "LinkedField",
                                    "name": "paymentScheduleList",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "profile",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v9/*: any*/),
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "IndividualProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvestmentPaymentGraphIndividualQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualProfileInvestor",
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "InvestmentConnection",
                    "kind": "LinkedField",
                    "name": "investments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvestmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Investment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Order",
                                "kind": "LinkedField",
                                "name": "order",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderApplication",
                                    "kind": "LinkedField",
                                    "name": "application",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PaymentSchedule",
                                    "kind": "LinkedField",
                                    "name": "paymentScheduleList",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "profile",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v9/*: any*/),
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "IndividualProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvestmentPaymentGraphIndividualQuery",
    "operationKind": "query",
    "text": "query InvestmentPaymentGraphIndividualQuery(\n  $profileId: ID!\n  $filter: ProfileInvestmentsFilter\n) {\n  node(id: $profileId) {\n    __typename\n    ... on IndividualProfile {\n      investor {\n        investments(filter: $filter) {\n          edges {\n            node {\n              id\n              amount\n              order {\n                id\n                status\n                application {\n                  data\n                  id\n                }\n                chain {\n                  id\n                  gatheredAmount\n                }\n                paymentScheduleList {\n                  profile {\n                    __typename\n                    id\n                  }\n                  info {\n                    state\n                  }\n                  items {\n                    date\n                    payDate\n                    loan\n                    state\n                    total\n                    total_gross\n                    interest\n                    interest_fee\n                    personal_tax\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '44fce7681ad3a837a9b8cc8ef38c771f';
export default node;
