export const formatError = {
  keyword: "format",
  path: "requestPhoneConfirmation.phone",
  type: "validation",
}

export const uniqueError = {
  keyword: "unique",
  path: "requestPhoneConfirmation.phone",
  type: "validation",
}
