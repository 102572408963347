import React from "react"

import { Route } from "react-router"

import {
  Button, Template, Translate,
} from "src/components"

const handleRoute = (history) => (event) => {
  if (event) {
    event.preventDefault()
  }
  history.push("/accounts")
}

const Action = () => (
  <Route>
    {({ history }) => (
      <Button variant="blueWide" onClick={handleRoute(history)}>
        <Translate i18n="components:order_list.loans.mixed.buttons.action" />
      </Button>
    )}
  </Route>
)

export default () => (
  <Template
    layout="empty"
    header="components:order_list.loans.mixed.header"
    image="/images/orderLists/empty/loans.svg"
    description="components:order_list.loans.mixed.description"
    action={<Action />}
  />
)
