/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type UpdateForeignBankAccountInput = {
    accountId: string;
    attributes: ForeignBankAccountInput;
};
export type ForeignBankAccountInput = {
    ownerName: string;
    IBAN: string;
    bank: ForeignBankInput;
};
export type ForeignBankInput = {
    name: string;
    address: string;
    SWIFT: string;
};
export type UpdateForeignBankAccountMutationVariables = {
    input: UpdateForeignBankAccountInput;
};
export type UpdateForeignBankAccountMutationResponse = {
    readonly updateForeignBankAccount: {
        readonly account: {
            readonly id: string;
            readonly status: BankAccountStatusEnum;
            readonly ownerName: string;
            readonly IBAN: string;
            readonly bank: {
                readonly name: string;
                readonly address: string;
                readonly SWIFT: string;
            };
        };
    };
};
export type UpdateForeignBankAccountMutation = {
    readonly response: UpdateForeignBankAccountMutationResponse;
    readonly variables: UpdateForeignBankAccountMutationVariables;
};



/*
mutation UpdateForeignBankAccountMutation(
  $input: UpdateForeignBankAccountInput!
) {
  updateForeignBankAccount(input: $input) {
    account {
      id
      status
      ownerName
      IBAN
      bank {
        name
        address
        SWIFT
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateForeignBankAccountInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateForeignBankAccountPayload",
    "kind": "LinkedField",
    "name": "updateForeignBankAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ForeignBankAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IBAN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignBank",
            "kind": "LinkedField",
            "name": "bank",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SWIFT",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateForeignBankAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateForeignBankAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateForeignBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateForeignBankAccountMutation(\n  $input: UpdateForeignBankAccountInput!\n) {\n  updateForeignBankAccount(input: $input) {\n    account {\n      id\n      status\n      ownerName\n      IBAN\n      bank {\n        name\n        address\n        SWIFT\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c528b5e23c0f0250ffd0a020ef019283';
export default node;
