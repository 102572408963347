/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SellOrderInput = {
    profileId: string;
    orderId: string;
    token: string;
};
export type SellOrderMutationVariables = {
    input: SellOrderInput;
};
export type SellOrderMutationResponse = {
    readonly sellOrder: {
        readonly order: {
            readonly id: string;
            readonly profile: {
                readonly id: string;
            };
        };
    };
};
export type SellOrderMutation = {
    readonly response: SellOrderMutationResponse;
    readonly variables: SellOrderMutationVariables;
};



/*
mutation SellOrderMutation(
  $input: SellOrderInput!
) {
  sellOrder(input: $input) {
    order {
      id
      profile {
        __typename
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SellOrderInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SellOrderPayload",
        "kind": "LinkedField",
        "name": "sellOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SellOrderPayload",
        "kind": "LinkedField",
        "name": "sellOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SellOrderMutation",
    "operationKind": "mutation",
    "text": "mutation SellOrderMutation(\n  $input: SellOrderInput!\n) {\n  sellOrder(input: $input) {\n    order {\n      id\n      profile {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3de777c72ff8eb6feb5e6d6cdca6e765';
export default node;
