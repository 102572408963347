import React from "react"

const Rub = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <defs>
      <path id="a" d="M0 0h7.42v10H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="20" fill="#7B838B" rx="3" />
      <g transform="translate(6 5)">
        <path
          fill="#FFF"
          d="M2.5 4.94h1.2c1.3 0 2.41-.34 2.41-1.92 0-1.25-.83-1.9-2.14-1.9H2.5v3.82zM2.5
        6v1.41h2.15v.8H2.5V10H1.22V8.23H0v-.8h1.22V6H0v-.8h1.22V0H4.2c1.92 0 3.23 1.17 3.23
        3.02C7.42 4.96 6.03 6 4.07 6H2.5z"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
)

const Eur = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <defs>
      <path id="a" d="M0 0h6.5v10H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="20" fill="#7B838B" rx="3" />
      <g transform="translate(6 5)">
        <path
          fill="#FFF"
          d="M6.5 1.24c-.39-.1-.84-.14-1.34-.14-1.63 0-2.7.91-3.03
         2.48h3.22v.78H2.04V5.4h3.31v.77H2.1C2.35 7.91 3.45 8.9 5.16 8.9a5.6 5.6 0 0 0
          1.35-.14v1.09c-.4.1-.85.15-1.35.15C2.7 10 1.14 8.6.86 6.18H0v-.77h.82v-.8l.01-.25H0v-.78h.9C1.24
          1.31 2.78 0 5.16 0c.5 0 .95.06 1.35.16v1.08z"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
)

const glyphs = new Map([
  ["rub", Rub],
  ["eur", Eur],
])

export default React.memo((props) => {
  if (typeof props.children !== "string") return null

  const currency = props.children.toLowerCase()
  const Glyph = glyphs.get(currency)

  return Glyph ? <Glyph /> : null
})
