import React from "react"
import idx from "idx"

import { OrderDataConsumer, OrderTypeConsumer } from "src/context"

import { getProperty, getCreditLabel, getCreditRating } from "src/utils"

import {
  Duration,
  LoanType,
  Provision,
  LoanAmount,
  RatingHint,
  Profability,
  RatingTooltip,
  LoanTypeTitle,
  ExhibitionItem,
} from "./Elements"

import Container from "./styles"

const buildColumnsFrom = (order, orderType) => [
  {
    label: () => <LoanTypeTitle cession={order.cession} />,
    render: () => <LoanType cession={order.cession} />,
  },
  {
    label: "№",
    value: idx(order, (_) => _.chain.id)
      ? `${order.chain.id.split(".")[2]}`
      : null,
  },
  {
    label: "loan.profitability",
    render: () => <Profability orderType={orderType} />,
  },
  {
    render: () => <LoanAmount {...order} orderType={orderType} />,
  },
  {
    label: () => <RatingTooltip />,
    value:
      idx(order, (_) => _.creditRating.rating)
      || idx(order, (_) => _.profile.creditRating.rating)
        ? `${getCreditRating({ node: order })}`
        : null,
    hint: () => <RatingHint hints={getCreditLabel({ node: order })} />,
  },
  {
    label: () => (
      <Provision.label
        orderId={order.id}
        orderType={orderType}
        provision={order.provision}
      />
    ),
    render: () => <Provision order={order} />,
  },
  {
    label: "loan.invested",
    value: getProperty(order, "chain.investorsCount", null),
  },
  {
    label: `loan.${
      order.cession && order.cession.isActive ? "durationCession" : "duration"
    }`,
    render: () => <Duration order={order} orderType={orderType} />,
  },
]

const validStatuses = [
  "APPROVED",
  "PENDING",
  "CONFIRMED",
  "TRANSFER",
  "SUCCEEDED",
  "COMPLETE",
  "DECLINED",
  "DEFAULT",
  "DEAD",
]

const shouldRenderExhibition = (data) => idx(data, (_) => _.status) && validStatuses.includes(data.status)

const Exhibition = ({ order, orderType }) => {
  if (!shouldRenderExhibition(order)) return null

  const columns = buildColumnsFrom(order, orderType)

  return (
    <Container>
      {columns.map((item, ii) => (
        <ExhibitionItem
          {...item}
          key={ii}
          isFirst={ii === 0}
          isLast={ii === columns.length - 1}
        />
      ))}
    </Container>
  )
}

export default (props) => (
  <OrderDataConsumer>
    {(order) => (
      <OrderTypeConsumer>
        {(type) => <Exhibition {...props} order={order} orderType={type} />}
      </OrderTypeConsumer>
    )}
  </OrderDataConsumer>
)
