import React, { useEffect, useState } from "react"

import moment from "moment"
import i18next from "i18next"
import Relay from "react-relay"
import { Route } from "react-router"
import { toast } from "react-toastify"

import { createChainedFunction, notifyGTM, pipe } from "src/utils"
import { InternationalComposedInvestorSchema } from "src/constants"

import { Content } from "../elements"
import fragment from "./InternationalIndividualFragment"
import ProcessInternationalProfile from "./MutationContainer"
import {
  initialStateFromProps,
  performFormStateChangeEffects,
  getProfileIdFromCreateMutation,
} from "./utils"

const sendConfirmationGTMEvent = (viewer) => notifyGTM({
  event: "TokenConfirmation_Person",
  eventCategory: "",
  extras: {
    date: moment(viewer.createdAt).format("DDMMYYYY"),
  },
})()

const notifyToast = (type = "success") => {
  const toastFunction = toast[type]
  const toastMessage = i18next.t(`common:toasts.save_${type}`)

  toastFunction(toastMessage, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

const InvestorAccreditation = (props) => {
  const FormSchema = InternationalComposedInvestorSchema

  const [isDirty, setDirty] = useState(false)
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [isContinueLoading, setContinueLoading] = useState(false)
  const [isConfirmationLoading, setConfirmationLoading] = useState(false)
  const [showPhoneConfirmModal, setShowPhoneConfirmModal] = useState(false)

  const [formState, setFormState] = useState({
    ...initialStateFromProps(props),
  })

  const handleFormChange = pipe(performFormStateChangeEffects, setFormState)

  const handleDirty = () => !isDirty && setDirty(true)

  const closeSubmitModal = () => setShowSubmitModal(false)
  const closePhoneConfirmModal = () => setShowPhoneConfirmModal(false)

  const onChange = createChainedFunction(handleFormChange, handleDirty)

  const process = formState.id.length > 0
    ? props.updateIndividualProfile
    : props.createIndividualProfile

  const onSave = () => {
    setSaveLoading(true)

    return process({ shouldSkipValidation: true })(formState)
      .then((data) => {
        const profileId = getProfileIdFromCreateMutation(data)
        if (profileId) {
          setFormState({ ...formState, id: profileId })
        }

        setSaveLoading(false)
        notifyToast("success")
      })
      .catch(() => {
        setSaveLoading(false)
        notifyToast("error")
      })
  }

  const onCancel = () => {
    if (isDirty) {
      setShowSubmitModal(true)
      return
    }

    props.history.push(`/accounts/${formState.id}`)
  }

  const onSaveCancel = () => props.history.push(`/accounts/${formState.id}`)

  const requestPhoneConfirmation = () => props.requestToken(formState)

  const onContinue = () => {
    setContinueLoading(true)

    process({ shouldSkipValidation: false })(formState)
      .then((data) => {
        const profileId = getProfileIdFromCreateMutation(data)
        if (profileId) {
          setFormState({ ...formState, id: profileId })
        }

        setContinueLoading(false)
        setShowPhoneConfirmModal(true)
        notifyToast("success")
      })
      .catch(() => {
        notifyToast("error")
        setContinueLoading(false)
      })
  }

  const requestAccreditation = (data) => {
    setConfirmationLoading(true)

    props
      .requestAccreditation(formState.id)(data)
      .then(() => {
        sendConfirmationGTMEvent(props.viewer)
        setConfirmationLoading(false)
        props.history.push(`/accounts/${formState.id}`)
      })
      .catch(() => setConfirmationLoading(false))
  }

  const onSubmitSave = () => onSave().then(() => props.history.push(`/accounts/${formState.id}`))

  const commitRequestFromPhoneConfirmModal = () => props.requestToken(formState)

  useEffect(() => {
    if (!showPhoneConfirmModal) return

    requestPhoneConfirmation()
  }, [showPhoneConfirmModal])

  return (
    <React.Fragment>
      <Content
        onSave={onSave}
        investorType="int"
        schema={FormSchema}
        onChange={onChange}
        onCancel={onCancel}
        formState={formState}
        onContinue={onContinue}
        onSaveCancel={onSaveCancel}
        onSubmitSave={onSubmitSave}
        showCancel={!!formState.id}
        isSaveLoading={isSaveLoading}
        showSubmitModal={showSubmitModal}
        closeSubmitModal={closeSubmitModal}
        isContinueLoading={isContinueLoading}
        isContinueDisabled={!formState.hasUSACitizenship}
        requestAccreditation={requestAccreditation}
        showPhoneConfirmModal={showPhoneConfirmModal}
        isConfirmationLoading={isConfirmationLoading}
        closePhoneConfirmModal={closePhoneConfirmModal}
        commitRequestFromPhoneConfirmModal={commitRequestFromPhoneConfirmModal}
      />
    </React.Fragment>
  )
}

const render = (props) => (
  <ProcessInternationalProfile>
    {(renderProps) => (
      <Route>
        {({ history }) => (
          <InvestorAccreditation
            {...props}
            {...renderProps}
            history={history}
          />
        )}
      </Route>
    )}
  </ProcessInternationalProfile>
)

export default Relay.createFragmentContainer(render, { profile: fragment })
