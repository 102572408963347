import React from "react"

import { ModalContainer } from "src/components"

import {
  Container,
  CloseButton,
  MobileCloseButton,
  CloseIcon,
  ArrowIcon,
  Title,
  Description,
} from "../styles"

function DescriptionModal(props) {
  const {
    isOpened, onClose, title, text,
  } = props

  return (
    <ModalContainer isOpened={isOpened} onClose={onClose}>
      <Container>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        <MobileCloseButton onClick={onClose}>
          <ArrowIcon />
          Назад
        </MobileCloseButton>

        <Title>{title}</Title>
        <Description>{text}</Description>
      </Container>
    </ModalContainer>
  )
}

export default DescriptionModal
