import styled from "styled-components"

const Paper = styled.div<any>`
  font-family: "Geometria";
  position: absolute;
  top: 50%;
  left: 50%;
  height: ${(props) => props.height || "200px"};
  width: ${(props) => props.width || "400px"};
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  z-index: 11;
  border-radius: 4px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.25);
  padding: 44px 35px 35px 35px;
`

export default Paper
