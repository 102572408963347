import React from "react"

export default (props) => (
  <React.Fragment>
    <path
      fill={props.fill || "inherit"}
      d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z"
    />
    <polygon
      fill={props.fill || "inherit"}
      id="check"
      points="17.99,9 16.58,7.58 9.99,14.17 7.41,11.6 5.99,13.01 9.99,17"
    />
  </React.Fragment>
)
