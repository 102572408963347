import React from "react"

import { Box, Loader } from "src/components"

import ViewerAccessContainer from "../../ViewerAccessContainer"

class Container extends React.PureComponent<any, any> {
  state = {
    busy: false,
    profiles: null,
    isLoading: false,
    presentation: null,
  }

  componentDidMount = () => {
    this.process()
  }

  processViewerAccess = (status) => () => {
    if (status === "APPROVED") {
      this.setState(() => ({ presentation: this.props.listPresentation }))
      return
    }

    const statusPresentation = this.props.statusScreensSchema[status]
    this.setState(() => ({ presentation: statusPresentation }))
  }

  fetchViewerAccess = async () => {
    const { checkAccess } = this.props
    const viewerAccess = await checkAccess()

    const { profiles, status } = viewerAccess[this.props.role]

    this.setState(
      () => ({
        busy: false,
        isLoading: false,
        profiles,
      }),
      this.processViewerAccess(status),
    )
  }

  process = () => {
    if (this.state.busy || this.state.isLoading) return

    this.setState(
      () => ({
        busy: true,
        isLoading: true,
      }),
      this.fetchViewerAccess,
    )
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  render() {
    return this.state.presentation === undefined
      || this.state.presentation === null ? (
      <Box py="60px">
        <Loader fontSize="14px" />
      </Box>
      ) : (
      <this.state.presentation profiles={this.state.profiles} />
      )
  }
}

/*
 *
 * // props
 * role: @string - investor|borrower
 * statusScreensSchema: @Map<StatusEnum, React.Component> - map of available statusBased components
 *   @example {
 *     const {status} = this.state.data // PENDING
 *     const Screen = this.props.statusScreensSchema.get(status) // React.Component || null
 *   }
 * listPresentation: React.Component - Presentation component which will be used if viewer got valid role access
 *
 * */

const render = (props) => (
  <ViewerAccessContainer role={props.role} mode="default">
    {(renderProps) => <Container {...renderProps} {...props} />}
  </ViewerAccessContainer>
)

render.defaultProps = {
  role: "borrower",
}

export default render
