import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

export const InnerTabletContainer = styled.div``

export const MobileFooter = styled.div`
  background: #141414;
  min-height: 160px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 45px;

  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  margin-top: auto;
  height: 160px;
`

export const MobileProfile = styled.div`
  display: block;
`

export const TabletContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return !mobile && <InnerTabletContainer {...props}/>
}

export const Footer = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return mobile && <MobileFooter {...props}/>
}

export const Profile = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return mobile && <MobileProfile {...props}/>
}
