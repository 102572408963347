/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type Withdraw_profile = {
    readonly __typename: string;
    readonly id: string;
    readonly name: string;
    readonly accountBalanceDetailed: ReadonlyArray<{
        readonly available: number;
        readonly blocked: number;
        readonly invested: number;
        readonly currency: CurrencyEnum;
    }>;
    readonly bankAccounts: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly inn?: string | null;
    readonly accreditation?: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly passport?: {
        readonly scans: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
    readonly " $refType": "Withdraw_profile";
};
export type Withdraw_profile$data = Withdraw_profile;
export type Withdraw_profile$key = {
    readonly " $data"?: Withdraw_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"Withdraw_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v5 = [
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Passport",
    "kind": "LinkedField",
    "name": "passport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Attachment",
        "kind": "LinkedField",
        "name": "scans",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v6/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Withdraw_profile",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileAccountBalance",
      "kind": "LinkedField",
      "name": "accountBalanceDetailed",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkingAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correspondentAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Bank",
          "kind": "LinkedField",
          "name": "bank",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bic",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        }
      ],
      "type": "LegalEntityProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v6/*: any*/)
      ],
      "type": "ForeignIndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v7/*: any*/),
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v7/*: any*/),
      "type": "EntrepreneurProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = 'b6b6bf1417fb54b30472464c0e9ccd0c';
export default node;
