import React from "react"

import {
  Box, Link, Text, Button, Translate,
} from "src/components"

import { StyledLink } from "./styles"

export const Title = () => (
  <Box mb="35px">
    <Text
      fontSize="24px"
      fontWeight="600"
      lineHeight="1.5"
      textAlign="center"
      color="black"
    >
      <Translate i18n="pages:guest-orders.guest-order-info.title" />
    </Text>
  </Box>
)

export const RegisterButton = () => (
  <Link to="/join">
    <Button variant="red" height="40px">
      <Translate i18n="pages:guest-orders.guest-order-info.buttons.register" />
    </Button>
  </Link>
)

export const LoginButton = () => (
  <Box mt="17px">
    <StyledLink to="/login">
      <Translate i18n="pages:guest-orders.guest-order-info.buttons.login" />
    </StyledLink>
  </Box>
)
