import React from "react"

import {
  Box,
  Label,
  FormRow,
  TextField,
  Translate,
} from "src/components"

export default ({ onChange, value }) => (
  <FormRow>
    <Box width="240px">
      <Label>
        <Translate
          i18n="accreditation.individual.rows.name.label"
          ns="components"
        />
      </Label>
    </Box>
    <Box width="100%">
      <TextField type="text" name="name" value={value} onChange={onChange} />
    </Box>
  </FormRow>
)
