import React from "react"
import i18next from "i18next"

import { Translate } from "src/components"

import { Container, Select, Label } from "./styles"

function SelectInput(props) {
  const {
    width, className, options, label, onChange, ...rest
  } = props

  function handleChange(event) {
    onChange(event.target)
  }

  return (
    <Container className={className} width={width}>
      <Select onChange={handleChange} {...rest}>
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {i18next.t(item.label) || ""}
          </option>
        ))}
      </Select>

      {label && (
        <Label>
          <Translate i18n={label} />
        </Label>
      )}
    </Container>
  )
}

export default SelectInput
