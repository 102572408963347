import React from "react"
import memoize from "memoize-one"

import { Exhibition, GuestOrderAction } from "src/components"

import {
  MiscData, EntityDate, Description, ProfileLogo,
} from "../Elements"

import { HeaderContainer, FooterContainer } from "./styles"

const Head = memoize(({ data }) => (
  <HeaderContainer>
    <ProfileLogo data={data} source="market" withLink={false} />
    <MiscData data={data} source="market" withLink={false} />
    <EntityDate data={data} />
  </HeaderContainer>
))

const Body = memoize(({ data }) => <Exhibition data={data} />)

const Footer = memoize(({ data }) => (
  <FooterContainer>
    <Description data={data} cropped={true} />
    <GuestOrderAction />
  </FooterContainer>
))

export default {
  Head,
  Body,
  Footer,
}
