import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateLegalEntityProfileMutation(
    $input: CreateLegalEntityProfileInput!
  ) {
    createLegalEntityProfile(input: $input) {
      user {
        ...User_viewer
        profiles {
          __typename
          id
          ... on LegalEntityProfile {
            id
            inn
            name
            phone
          }
          ...Header_profile
          ...ProfileBody_profile
        }
      }
      profile {
        id
      }
    }
  }
`

export default enhance({ mutation })
