/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ChangeEmailQueryVariables = {};
export type ChangeEmailQueryResponse = {
    readonly viewer: {
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly __typename: string;
            readonly accreditation?: {
                readonly status: ProfileAccreditationStatus | null;
            };
        }>;
    } | null;
};
export type ChangeEmailQuery = {
    readonly response: ChangeEmailQueryResponse;
    readonly variables: ChangeEmailQueryVariables;
};



/*
query ChangeEmailQuery {
  viewer {
    profiles {
      id
      __typename
      ... on LegalEntityProfile {
        accreditation(role: BORROWER) {
          status
        }
      }
      ... on IndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on EntrepreneurProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on ForeignIndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "role",
        "value": "INVESTOR"
      }
    ],
    "concreteType": "Accreditation",
    "kind": "LinkedField",
    "name": "accreditation",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": "accreditation(role:\"INVESTOR\")"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profiles",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        }
      ],
      "type": "LegalEntityProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "EntrepreneurProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "ForeignIndividualProfile"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChangeEmailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ChangeEmailQuery",
    "operationKind": "query",
    "text": "query ChangeEmailQuery {\n  viewer {\n    profiles {\n      id\n      __typename\n      ... on LegalEntityProfile {\n        accreditation(role: BORROWER) {\n          status\n        }\n      }\n      ... on IndividualProfile {\n        accreditation(role: INVESTOR) {\n          status\n        }\n      }\n      ... on EntrepreneurProfile {\n        accreditation(role: INVESTOR) {\n          status\n        }\n      }\n      ... on ForeignIndividualProfile {\n        accreditation(role: INVESTOR) {\n          status\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0823a8b4e803bbc9af1507b560082e4a';
export default node;
