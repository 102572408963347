import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`

export const Name = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  max-width: 210px;
  margin: 0 24px 0 0;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Value = styled(Name)`
  margin: 0;
  max-width: calc(100% - 234px);
  text-transform: none;
`

export const Empty = styled(Value)`
  color: ${({ theme }) => theme.colors.mainRed};
`
