import React from "react"

import memoize from "memoize-one"

import { ViewerData, OrderDataConsumer } from "src/context"

import { Controls } from "src/components/organisms/OrderFactory/Elements"

import { Translate } from "src/components"

import {
  Bold,
  PreLined,
  Container,
  StyledBox,
  HeaderWrapper,
  TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

const Action = memoize((props) => {
  const { order } = props
  const { status } = order

  return (
    <ViewerData.renderPropContainer>
      {({ getViewerToOrderRelation }) => {
        const { MASTER: isMaster } = getViewerToOrderRelation(order)

        return (
          <StyledBox>
            <Controls.proposal
              buttonVariant="defaultThinWhite"
              withOutdatedMessage={false}
              data={order}
              status={status}
              isOwner={isMaster}
            />
          </StyledBox>
        )
      }}
    </ViewerData.renderPropContainer>
  )
})

const MinAmountCollected = memoize((props) => {
  const { order, maxAmountDelta } = props

  return (
    <React.Fragment>
      <HeaderWrapper>
        <Translate i18n="components:tooltips.min_amount_collected.head" />
      </HeaderWrapper>
      <TextBlockWrapper>
        <PreLined>
          <Translate i18n="components:tooltips.min_amount_collected.body" />
          <Bold>{maxAmountDelta} ₽</Bold>
        </PreLined>
        <Action order={order} />
      </TextBlockWrapper>
    </React.Fragment>
  )
})

const MinAmountNotCollected = memoize((props) => {
  const { order, minValue, minAmountDelta } = props

  return (
    <TextBlockWrapper>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.head" />
        <Bold>{minValue} ₽</Bold>
      </PreLined>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.postfix" />
        <Bold>{minAmountDelta} ₽</Bold>
      </PreLined>
      <Action order={order} />
    </TextBlockWrapper>
  )
})

const render = memoize((order) => {
  const { minAmountCollected, ...rest } = mapAmountToStatusFrom(order)

  return (
    <Container>
      {" "}
      {minAmountCollected ? (
        <MinAmountCollected {...rest} order={order} />
      ) : (
        <MinAmountNotCollected {...rest} order={order} />
      )}
    </Container>
  )
})

export default () => <OrderDataConsumer>{render}</OrderDataConsumer>
