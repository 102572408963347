/*
*
* firstName: String!
  lastName: String!
  documents: [KYCDocumentInput!]!
  selfie: ID!
  phone: String!
*
* */

import { KYCDocument } from "src/constants"

const Address = {
  country: "",
  region: "",
  area: "",
  locality: "",
  street: "",
  house: "",
  housing: "",
  structure: "",
  flat: "",
}

// eslint-disable-next-line
export const InitialState = {
  id: "",
  tin: "",
  phone: "",
  selfie: "",
  lastName: "",
  firstName: "",
  middleName: "",
  innDocument: [],
  residenceDocument: "",
  translatedDocuments: [],
  taxResidencyApplication: [],
  territoryResidenceDocument: {
    number: "",
    dateFrom: "",
    type: "PERMANENT_RESIDENCY",
  },
  migrationCard: {
    number: "",
    dateFrom: "",
  },
  registrationAddress: { ...Address },
  hasUSACitizenship: false,
  isRussiaTaxResident: false,
  documents: [{ ...KYCDocument }],
}
