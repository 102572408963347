import is from "is_js"
import React from "react"

import { setAuthToken } from "src/utils"
import { ErrorsContainer } from "src/containers"
import { LocaleConsumer, RelayEnvironmentConsumer } from "src/context"
import { LoginWithEmailAndPassword } from "src/mutations"
import {
  Box,
  Flex,
  Translate,
  CheckBoxField,
  FloatingLabelInput as Input,
} from "src/components"

import { errorForField, PasswordTooltip } from "./elements"

import { Button, InputButton } from "./styles"

import { VisibilityIcon, VisibilityOffIcon } from "./icons"

const stateValid = (state) => is.email(state.email) && is.not.empty(state.password)

class LoginForm extends React.Component<any, any> {
  state = {
    busy: false,
    email: "",
    password: "",
    remember: false,
    inputType: "password",
  }

  onSubmit = (event) => {
    if (event) event.preventDefault()

    if (this.state.busy === true) return

    this.setState(() => ({ busy: true }))
    this.commit()
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))
    this.props.clearError(`loginWithEmailAndPassword.${name}`)
  }

  onRememberChange = (event) => {
    const remember = event.target.checked
    this.setState(() => ({ remember }))
  }

  commit = () => {
    const variables = {
      input: {
        email: this.state.email,
        password: this.state.password,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    LoginWithEmailAndPassword.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  onError = (transaction) => {
    this.setState(() => ({ busy: false }))
    this.props.setErrors(transaction)
  }

  onCompleted = ({ loginWithEmailAndPassword }) => {
    this.setState(() => ({ busy: false }))
    setAuthToken(loginWithEmailAndPassword.token).then(
      this.props.refetchViewerLocale,
    )

    if (this.props.onCompleted) {
      this.props.onCompleted()
    }
  }

  get is() {
    return {
      busy: this.state.busy,
      valid: stateValid(this.state),
    }
  }

  toggleInputType = () => {
    const newType = this.state.inputType === "password" ? "text" : "password"
    this.setState(() => ({ inputType: newType }))
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Input
          withoutBorder
          autoFocus
          type="email"
          name="email"
          errorColor="mainRed"
          value={this.state.email}
          onChange={this.onChange}
          label="components:login_form.email"
          error={errorForField(
            this.props.getError("loginWithEmailAndPassword.email"),
          )}
          hasError={errorForField(
            this.props.getError("loginWithEmailAndPassword.email"),
          )}
        />
        <Box position="relative" mt="20px">
          <Input
            withoutBorder
            type={this.state.inputType}
            name="password"
            errorColor="mainRed"
            onChange={this.onChange}
            value={this.state.password}
            label="components:login_form.password"
            error={errorForField(
              this.props.getError("loginWithEmailAndPassword.password"),
            )}
            hasError={errorForField(
              this.props.getError("loginWithEmailAndPassword.password"),
            )}
          />
          <InputButton type="button" onClick={this.toggleInputType}>
            {this.state.inputType !== "password" && <VisibilityIcon />}
            {this.state.inputType === "password" && <VisibilityOffIcon />}
          </InputButton>
        </Box>
        <Flex mt="25px" alignItems="center" justifyContent="space-between">
          <CheckBoxField
            fill="mainRed"
            fontSize="12px"
            color="greyDarker"
            checked={this.state.remember}
            onChange={this.onRememberChange}
            label={
              <Translate i18n="login_form.remember-label" ns="components" />
            }
          />
          <PasswordTooltip />
        </Flex>
        <Box mt="30px">
          <Button type="submit" disabled={!this.is.valid || this.is.busy}>
            <Translate i18n="common:enter" />
          </Button>
        </Box>
      </form>
    )
  }
}

const render = (props) => (
  <LocaleConsumer>
    {({ refetchViewerLocale }) => (
      <ErrorsContainer>
        {(errors) => (
          <RelayEnvironmentConsumer>
            {({ environment }) => (
              <LoginForm
                {...props}
                {...errors}
                environment={environment}
                refetchViewerLocale={refetchViewerLocale}
              />
            )}
          </RelayEnvironmentConsumer>
        )}
      </ErrorsContainer>
    )}
  </LocaleConsumer>
)

export default render
