const colors = {
  green: "rgba(111, 168, 75, 0.1)",
  yellow: "rgba(255, 153, 0, 0.1)",
  pink: "rgba(247, 0, 0, 0.1)",
  red: "rgba(247, 0, 0, 0.15)",
}

export const borrowerRating = [
  {
    rating: "components:scoring_info.borrower.table.rows.0.0",
    text: "components:scoring_info.borrower.table.rows.0.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.1.0",
    text: "components:scoring_info.borrower.table.rows.1.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.2.0",
    text: "components:scoring_info.borrower.table.rows.2.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.3.0",
    text: "components:scoring_info.borrower.table.rows.3.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.4.0",
    text: "components:scoring_info.borrower.table.rows.4.1",
    color: colors.yellow,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.5.0",
    text: "components:scoring_info.borrower.table.rows.5.1",
    color: colors.yellow,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.6.0",
    text: "components:scoring_info.borrower.table.rows.6.1",
    color: colors.pink,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.7.0",
    text: "components:scoring_info.borrower.table.rows.7.1",
    color: colors.pink,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.8.0",
    text: "components:scoring_info.borrower.table.rows.8.1",
    color: colors.pink,
  },
  {
    rating: "components:scoring_info.borrower.table.rows.9.0",
    text: "components:scoring_info.borrower.table.rows.9.1",
    color: colors.red,
  },
]

export const collateralRating = [
  {
    rating: "components:scoring_info.collateral.list.0.0",
    text: "components:scoring_info.collateral.list.0.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.collateral.list.1.0",
    text: "components:scoring_info.collateral.list.1.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.collateral.list.2.0",
    text: "components:scoring_info.collateral.list.2.1",
    color: colors.green,
  },
  {
    rating: "components:scoring_info.collateral.list.3.0",
    text: "components:scoring_info.collateral.list.3.1",
    color: colors.green,
  },
]
