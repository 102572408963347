import React from "react"
import { QueryRenderer } from "react-relay"
import { RelayEnvironmentConsumer } from "src/context"

const createRender = ({ render, renderNull, ...rest }) => ({
  error,
  props,
  retry,
}) => {
  if (error) throw error

  if (props) {
    return render({
      ...rest, ...props, data: props, retry,
    })
  }

  return renderNull ? renderNull({ ...rest }) : null
}

export default (props) => {
  const {
    query, render, variables, renderNull, ...rest
  } = props

  const processedRender = createRender({ render, renderNull, ...rest })

  return (
    <RelayEnvironmentConsumer>
      {({ environment }) => (
        <QueryRenderer
          query={query}
          render={processedRender}
          variables={variables}
          environment={environment}
        />
      )}
    </RelayEnvironmentConsumer>
  )
}
