import styled from "styled-components"
import { space } from "styled-system"
import { NavLink } from "react-router-dom"

export const MainTitle = styled.h2`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding-bottom: 32px;
`

export const TextBlock = styled.p<any>`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.primaryBlack};
  white-space: pre-line;
  margin: 0;
  ${space};
`

export const ReturnButton = styled(NavLink)`
  font-family: Geometria, sans-serif;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 0 60px;
  padding: 8px 8px 8px 0;
  font-weight: 600;
  display: none;
  text-decoration: none;

  @media screen and (max-width: 800px) {
    display: inline-block;
  }
`

export const ContentContainer = styled.div`
  width: 1180px;
  margin: 55px auto 70px 0;
`

export const Link = styled.a`
  font-size: 16px;
  color: #3a66ab;

  &:visited {
    color: #3a66ab;
  }
`

export const SwitchBarWrapper = styled.div`
  width: calc(100% + 100px);
  position: relative;
  margin-left: -50px;
`
