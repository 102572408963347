/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type AddForeignProfileBankAccountInput = {
    profileId: string;
    attributes: ForeignBankAccountInput;
};
export type ForeignBankAccountInput = {
    ownerName: string;
    IBAN: string;
    bank: ForeignBankInput;
};
export type ForeignBankInput = {
    name: string;
    address: string;
    SWIFT: string;
};
export type AddForeignProfileBankAccountMutationVariables = {
    input: AddForeignProfileBankAccountInput;
};
export type AddForeignProfileBankAccountMutationResponse = {
    readonly addForeignProfileBankAccount: {
        readonly profile: {
            readonly id: string;
            readonly foreignBankAccounts: ReadonlyArray<{
                readonly id?: string;
                readonly status?: BankAccountStatusEnum;
                readonly IBAN?: string;
                readonly ownerName?: string;
                readonly bank?: {
                    readonly name: string;
                    readonly address: string;
                    readonly SWIFT: string;
                };
            }>;
            readonly bankAccounts: ReadonlyArray<{
                readonly id: string;
                readonly status: BankAccountStatusEnum;
                readonly checkingAccount: string | null;
                readonly correspondentAccount: string | null;
                readonly bank: {
                    readonly bic: string;
                    readonly name: string;
                } | null;
            }>;
        };
    };
};
export type AddForeignProfileBankAccountMutation = {
    readonly response: AddForeignProfileBankAccountMutationResponse;
    readonly variables: AddForeignProfileBankAccountMutationVariables;
};



/*
mutation AddForeignProfileBankAccountMutation(
  $input: AddForeignProfileBankAccountInput!
) {
  addForeignProfileBankAccount(input: $input) {
    profile {
      id
      foreignBankAccounts: allBankAccounts {
        __typename
        ... on ForeignBankAccount {
          id
          status
          IBAN
          ownerName
          bank {
            name
            address
            SWIFT
          }
        }
        ... on BankAccount {
          id
        }
      }
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddForeignProfileBankAccountInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "IBAN",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ForeignBank",
      "kind": "LinkedField",
      "name": "bank",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "SWIFT",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForeignBankAccount"
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bankAccounts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkingAccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correspondentAccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Bank",
      "kind": "LinkedField",
      "name": "bank",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bic",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddForeignProfileBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddForeignProfileBankAccountPayload",
        "kind": "LinkedField",
        "name": "addForeignProfileBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "foreignBankAccounts",
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "allBankAccounts",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddForeignProfileBankAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddForeignProfileBankAccountPayload",
        "kind": "LinkedField",
        "name": "addForeignProfileBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "foreignBankAccounts",
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "allBankAccounts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "BankAccount"
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AddForeignProfileBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation AddForeignProfileBankAccountMutation(\n  $input: AddForeignProfileBankAccountInput!\n) {\n  addForeignProfileBankAccount(input: $input) {\n    profile {\n      id\n      foreignBankAccounts: allBankAccounts {\n        __typename\n        ... on ForeignBankAccount {\n          id\n          status\n          IBAN\n          ownerName\n          bank {\n            name\n            address\n            SWIFT\n          }\n        }\n        ... on BankAccount {\n          id\n        }\n      }\n      bankAccounts {\n        id\n        status\n        checkingAccount\n        correspondentAccount\n        bank {\n          bic\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '44d1842db1e1ce6282a215c0daad3848';
export default node;
