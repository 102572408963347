/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type SupportingDocumentStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type RussianIndividualFragment_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly lastName?: string;
    readonly firstName?: string;
    readonly middleName?: string | null;
    readonly phone?: string | null;
    readonly isApproved?: boolean;
    readonly approvedOnce?: boolean;
    readonly isRussiaTaxResident?: boolean;
    readonly sex?: SexEnum | null;
    readonly inn?: string | null;
    readonly iian?: string | null;
    readonly birthDate?: string | null;
    readonly birthPlace?: string | null;
    readonly isPublicOfficial?: boolean | null;
    readonly address?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly raw: string | null;
    } | null;
    readonly passport?: {
        readonly series: string | null;
        readonly number: string | null;
        readonly issuedAt: string | null;
        readonly postponeScans: boolean | null;
        readonly scans: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }>;
        readonly issuedBy: {
            readonly code: string | null;
            readonly name: string | null;
        } | null;
    } | null;
    readonly supportingDocuments?: ReadonlyArray<{
        readonly id: string;
        readonly attachment: {
            readonly id: string;
            readonly url: string;
            readonly size: number;
            readonly width: number | null;
            readonly height: number | null;
            readonly format: string | null;
            readonly filename: string;
        };
        readonly status: SupportingDocumentStatus | null;
    }>;
    readonly bankAccounts?: ReadonlyArray<{
        readonly id: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly " $refType": "RussianIndividualFragment_profile";
};
export type RussianIndividualFragment_profile$data = RussianIndividualFragment_profile;
export type RussianIndividualFragment_profile$key = {
    readonly " $data"?: RussianIndividualFragment_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"RussianIndividualFragment_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RussianIndividualFragment_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "middleName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isApproved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "approvedOnce",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRussiaTaxResident",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sex",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "iian",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "birthPlace",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPublicOfficial",
          "storageKey": null
        },
        {
          "alias": "address",
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "registrationAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "region",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "area",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "house",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "housing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "structure",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "raw",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Passport",
          "kind": "LinkedField",
          "name": "passport",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "series",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "issuedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postponeScans",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "scans",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PassportIssuer",
              "kind": "LinkedField",
              "name": "issuedBy",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SupportingDocument",
          "kind": "LinkedField",
          "name": "supportingDocuments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "size",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "format",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccount",
          "kind": "LinkedField",
          "name": "bankAccounts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkingAccount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "correspondentAccount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Bank",
              "kind": "LinkedField",
              "name": "bank",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bic",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    }
  ],
  "type": "Node"
};
})();
(node as any).hash = '252e2ce0ebaebfeba3e39318e3387ca6';
export default node;
