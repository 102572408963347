import React from "react"
import memoize from "memoize-one"

import { Translate } from "src/components"
import { notifyGTM } from "src/utils"
import { ViewerDataConsumer } from "src/context"

import { StyledButton, ApprovalContainer } from "./styles"

import { PaymentData } from "../index"

const sendGTMEvent = memoize((order, viewer) => {
  if (!order || !order.chain) return () => {}

  const { id } = order.chain
  return notifyGTM({
    eventAction: `market-${id.split(".")[2]}`,
    eventLabel: "pay-click",
    extras: {
      "profile-id": viewer.id,
    },
  })
})

class Controls extends React.Component<any, any> {
  state = {
    modal: false,
  }

  onClick = () => {
    const { order, viewer } = this.props

    this.setState(() => ({ modal: true }), sendGTMEvent(order, viewer))
  }

  onClose = () => {
    this.setState(() => ({ modal: false }))
  }

  render() {
    return (
      <ApprovalContainer>
        <StyledButton variant="blueWide" onClick={this.onClick}>
          {this.props.label && (
            <Translate i18n={this.props.label} ns="components" />
          )}
        </StyledButton>
        {this.state.modal && (
          <PaymentData
            type={this.props.type}
            order={this.props.order}
            onClose={this.onClose}
          />
        )}
      </ApprovalContainer>
    )
  }
}

(Controls as any).defaultProps = {
  type: "invest",
  label: "order_list.approval.buttons.pay",
}

export default (props) => (
  <ViewerDataConsumer>
    {(viewer) => <Controls {...props} viewer={viewer} />}
  </ViewerDataConsumer>
)
