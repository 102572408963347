import memoize from "memoize-one"

/* eslint-disable no-unused-vars */
import React from "react"
/* eslint-disable no-unused-vars */

import { cloneDeep } from "src/utils"
import { Translate } from "src/components"

const getAttachmentsByIds = (ids, attachments) => {
  const parsedAttachments = []

  ids.forEach((attachmentId) => {
    if (attachments.map(({ id }) => id).includes(attachmentId)) {
      const attachment = attachments.find(
        ({ id: objectID }) => objectID === attachmentId,
      )
      parsedAttachments.push(attachment)
    }
  })

  return parsedAttachments
}

export const getFormBuilderState = memoize((schema, value, attachments?: any) => {
  const { groups } = schema

  return groups.reduce((state, group) => {
    const processedStats = { ...state }

    group.rows
      .reduce((inputs, row) => inputs.concat(row.inputs), [])
      .forEach((input) => {
        const { name, blank, initialValue } = input

        if (blank) {
          return
        }

        const defaultValue = initialValue === null || initialValue === undefined
          ? ""
          : cloneDeep(initialValue)

        if (name) {
          processedStats[name] = value && value[name] ? value[name] : defaultValue

          if (
            Object.prototype.hasOwnProperty.call(
              processedStats[name],
              "attachments",
            )
          ) {
            processedStats[name].attachments = [
              ...getAttachmentsByIds(
                processedStats[name].attachments,
                attachments,
              ),
            ]
          }
        }
      })

    return processedStats
  }, {})
})

export const getDepsState = memoize((deps, state) => {
  const getComparedValue = (compared, values) => values[compared]

  return deps
    ? deps.reduce((depsState, { propName, compared, conditionValue }) => {
      const processedDepsState = { ...depsState }

      processedDepsState[propName] = getComparedValue(compared, state) === conditionValue
      return processedDepsState
    }, {})
    : {}
})

const injectionReduceFn = (state) => (
  injectState,
  { propName, stateValue },
) => ({ ...injectState, [propName]: state[stateValue] })

export const getInjectState = memoize((inject, state) => (inject ? inject.reduce(injectionReduceFn(state), {}) : {}))

export const numberInputOnChangeDecorator = ({
  injectedOnchange,
  max,
  isFloat = false,
}) => (event) => {
  const regExp = /^[+-]?(?:\d*)?(\.?\d?)$/
  const { value } = event.target

  /* eslint-disable no-param-reassign */
  if (max && value > max) {
    event.target.value = 120
  }

  if (event.target.value[0] === "0") {
    const replacedValue = value.replace(/^0+/, "")
    event.target.value = Number(replacedValue)
  }

  if (isFloat && (!regExp.exec(value) || Number.isNaN(value))) {
    event.target.value = 0
  }

  /* eslint-enable no-param-reassign */
  injectedOnchange(event)
}

export const numberInputValueGuard = (value, isFloat = false) => {
  let nestedValue = value

  if (!isFloat && typeof nestedValue === "string") {
    nestedValue = Number(nestedValue)
  }

  if (!isFloat && !Number.isInteger(nestedValue)) {
    nestedValue = 0
  }

  if (isFloat && Number.isNaN(nestedValue)) {
    nestedValue = 0
  }

  return nestedValue
}

export const getTranslationOrComponent = (value) => {
  if (typeof value === "string") {
    return <Translate i18n={value} />
  }

  if (typeof value === "object" && value.i18n && value.ns) {
    return <Translate i18n={value.i18n} ns={value.ns} />
  }

  return value
}
