import { findPaymentListByProfile } from "src/utils"

function isDefaultedInvestments(investment) {
  return investment.node.order.status === "DEFAULT"
}

function isPastdueInvestments(profileId, investment, from = 1, to = Infinity) {
  const { paymentScheduleList } = investment.node.order

  const paymentSchedule = findPaymentListByProfile(profileId, paymentScheduleList) || {}
  const { info = {} } = paymentSchedule

  const { pastdue_days: pastdueDays } = info
  if (!pastdueDays) {
    return false
  }

  return pastdueDays >= from && pastdueDays <= to
}

export function getPastdueInvestments(profileId, investments, from, to?: number) {
  if (!investments) {
    return []
  }

  return investments.filter((investment) => isPastdueInvestments(profileId, investment, from, to))
}

export function getNormalInvestments(profileId, investments) {
  if (!investments) {
    return []
  }

  return investments.filter(
    (investment) => !isDefaultedInvestments(investment)
      && !isPastdueInvestments(profileId, investment),
  )
}

function getSoldAmount(history) {
  return history?.reduce(
    (acc, { type, amount }) => (type === "SELL" ? acc + amount : acc),
    0
  ) || 0
}
function getInvestmentAmount(profileId, investment) {
  const {
    amount = 0,
    order: { paymentScheduleList = [] },
    history,
  } = investment.node || {}

  const { items = [] } = findPaymentListByProfile(profileId, paymentScheduleList) || {}
  const paidItems = items.filter(({ state }) => state === "paid")
  const investmentAmount = paidItems.reduce((acc, { loan }) => acc + loan, 0)
  const soldAmount = getSoldAmount(history)

  return amount - investmentAmount - soldAmount || 0
}

export function getTotalInvestmentAmount(profileId, investments) {
  if (!investments) {
    return 0
  }

  return investments.reduce(
    (acc, investment) => acc + getInvestmentAmount(profileId, investment),
    0,
  )
}

export function getLevel(count) {
  if (count > 20) return 3
  if (count >= 15) return 2
  if (count >= 10) return 1
  return 0
}

export function getRiskLevel(balance, investments) {
  const investmentAmounts = investments.map(
    ({ node }) => node.loan - getSoldAmount(node.history),
  ) || [1]
  const maxInvestment = Math.max(...investmentAmounts)
  const percent = (maxInvestment / balance) * 100

  return getLevel(percent)
}
