/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ScrapExternalMediaInput = {
    url: string;
};
export type ScrapExternalMediaMutationVariables = {
    input: ScrapExternalMediaInput;
};
export type ScrapExternalMediaMutationResponse = {
    readonly scrapExternalMedia: {
        readonly media: {
            readonly id: string;
            readonly title: string | null;
            readonly url: string;
            readonly description: string | null;
            readonly video: {
                readonly url: string;
                readonly width: number | null;
                readonly height: number | null;
            };
        };
    };
};
export type ScrapExternalMediaMutation = {
    readonly response: ScrapExternalMediaMutationResponse;
    readonly variables: ScrapExternalMediaMutationVariables;
};



/*
mutation ScrapExternalMediaMutation(
  $input: ScrapExternalMediaInput!
) {
  scrapExternalMedia(input: $input) {
    media {
      id
      title
      url
      description
      video {
        url
        width
        height
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ScrapExternalMediaInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScrapExternalMediaPayload",
    "kind": "LinkedField",
    "name": "scrapExternalMedia",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExternalMedia",
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalMediaVideo",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapExternalMediaMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapExternalMediaMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ScrapExternalMediaMutation",
    "operationKind": "mutation",
    "text": "mutation ScrapExternalMediaMutation(\n  $input: ScrapExternalMediaInput!\n) {\n  scrapExternalMedia(input: $input) {\n    media {\n      id\n      title\n      url\n      description\n      video {\n        url\n        width\n        height\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2168ca131bf64cd7c7f0f7bf51f87c64';
export default node;
