/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfitQueryVariables = {
    id: string;
};
export type ProfitQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly investor?: {
            readonly analytics: {
                readonly tax: string;
                readonly interestPercent: string;
                readonly interest: string;
                readonly penalty: string;
            };
            readonly rating: {
                readonly rank: number | null;
                readonly total: number;
            };
        } | null;
    };
    readonly orders: {
        readonly count: number;
    };
};
export type ProfitQuery = {
    readonly response: ProfitQueryResponse;
    readonly variables: ProfitQueryVariables;
};



/*
query ProfitQuery(
  $id: ID!
) {
  node(id: $id) {
    id
    __typename
    ... on IndividualProfile {
      investor {
        analytics {
          tax
          interestPercent
          interest
          penalty
        }
        rating {
          rank
          total
        }
      }
    }
    ... on EntrepreneurProfile {
      investor {
        analytics {
          tax
          interestPercent
          interest
          penalty
        }
        rating {
          rank
          total
        }
      }
    }
    ... on ForeignIndividualProfile {
      investor {
        analytics {
          tax
          interestPercent
          interest
          penalty
        }
        rating {
          rank
          total
        }
      }
    }
    ... on LegalEntityProfile {
      investor {
        analytics {
          tax
          interestPercent
          interest
          penalty
        }
        rating {
          rank
          total
        }
      }
    }
  }
  orders {
    count
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProfileAnalytics",
    "kind": "LinkedField",
    "name": "analytics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tax",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interestPercent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "interest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "penalty",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "InvestorRating",
    "kind": "LinkedField",
    "name": "rating",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rank",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Investor",
    "kind": "LinkedField",
    "name": "investor",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualProfileInvestor",
            "kind": "LinkedField",
            "name": "investor",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "type": "IndividualProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v2/*: any*/),
        "type": "EntrepreneurProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v2/*: any*/),
        "type": "ForeignIndividualProfile"
      },
      {
        "kind": "InlineFragment",
        "selections": (v2/*: any*/),
        "type": "LegalEntityProfile"
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "OrderConnection",
    "kind": "LinkedField",
    "name": "orders",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfitQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfitQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProfitQuery",
    "operationKind": "query",
    "text": "query ProfitQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    id\n    __typename\n    ... on IndividualProfile {\n      investor {\n        analytics {\n          tax\n          interestPercent\n          interest\n          penalty\n        }\n        rating {\n          rank\n          total\n        }\n      }\n    }\n    ... on EntrepreneurProfile {\n      investor {\n        analytics {\n          tax\n          interestPercent\n          interest\n          penalty\n        }\n        rating {\n          rank\n          total\n        }\n      }\n    }\n    ... on ForeignIndividualProfile {\n      investor {\n        analytics {\n          tax\n          interestPercent\n          interest\n          penalty\n        }\n        rating {\n          rank\n          total\n        }\n      }\n    }\n    ... on LegalEntityProfile {\n      investor {\n        analytics {\n          tax\n          interestPercent\n          interest\n          penalty\n        }\n        rating {\n          rank\n          total\n        }\n      }\n    }\n  }\n  orders {\n    count\n  }\n}\n"
  }
};
})();
(node as any).hash = '2c74ec8148afc1ae644ad0217fccdc13';
export default node;
