import React from "react"
import propTypes from "prop-types"
import { SocialListWrapper, IconContainer } from "./styles"

const Icon = (props) => {
  const IconContent = props.item
  return (
    <IconContainer href={props.to} target="_blank">
      <IconContent color={props.color} />
    </IconContainer>
  )
}

Icon.propTypes = {
  to: propTypes.string,
  color: propTypes.string,
  item: propTypes.func,
}

const SocialList = (props) => {
  const data = props.data || []
  return (
    <SocialListWrapper>
      {data.map((item, i) => (
        <Icon
          key={`${props.title}-${i}`}
          to={item.link}
          item={item.icon}
          color={props.color}
        />
      ))}
    </SocialListWrapper>
  )
}
SocialList.propTypes = {
  color: propTypes.string,
  data: propTypes.arrayOf(
    propTypes.shape({
      icon: propTypes.func,
      link: propTypes.string,
    }),
  ),
}
export default SocialList
