import React, { useState } from "react"

import {
  Container,
  MainContainer,
  Name,
  Points,
  Red,
  Button,
  InfoContainer,
  InfoBlockContainer,
  InfoBlockDescription,
  InfoDescription,
  InfoTitle,
  InfoValue,
  Divider,
  ButtonContainer,
  Text,
  HeaderContainer,
  MobileButtonContainer,
  ButtonDescription,
} from "./styles"

function OrderInfo() {
  const [isShipped, setShipped] = useState(false)

  function handleClick() {
    setShipped(true)
  }

  return (
    <Container>
      <HeaderContainer isShipped={isShipped}>
        <MainContainer>
          <Name>ООО "Ворота"</Name>
          <Points>
            Баллов - <Red>650</Red>
          </Points>
        </MainContainer>

        <MobileButtonContainer isShipped={isShipped}>
          {isShipped && (
            <Text>
              Вам на почту отправлена инструкция для следующего шага. Проверьте
              её скорее!
            </Text>
          )}
          {!isShipped && <Button onClick={handleClick}>ПОДАТЬ ЗАЯВКУ</Button>}
        </MobileButtonContainer>
      </HeaderContainer>

      <InfoContainer>
        <InfoBlockContainer>
          <div>
            <InfoTitle fontWeight={700}>Рейтинг</InfoTitle>
            <InfoValue>ВВВ</InfoValue>
            <InfoDescription>среднее качество</InfoDescription>
          </div>

          <Divider />

          <div>
            <InfoTitle>Ставка</InfoTitle>
            <InfoValue>21%</InfoValue>
            <InfoDescription>годовых</InfoDescription>
          </div>

          <Divider />

          <div>
            <InfoTitle>Макс. сумма </InfoTitle>
            <InfoValue>6000</InfoValue>
            <InfoDescription>тыс. руб</InfoDescription>
          </div>

          <Divider />

          <div>
            <InfoTitle>Срок</InfoTitle>
            <InfoValue>9</InfoValue>
            <InfoDescription>до (мес.)</InfoDescription>
          </div>
        </InfoBlockContainer>

        <InfoBlockDescription>
          *Максимальная ставка, которая одобрена согласно вашему скоринг-баллу.
          После нажатия на кнопку СОЗДАТЬ ЗАЯВКУ вы сможете выбрать необходимую
          сумму, с учетом предложенного максимума
        </InfoBlockDescription>
      </InfoContainer>

      <ButtonContainer isShipped={isShipped}>
        {isShipped && (
          <Text>
            Вам на почту отправлена инструкция для следующего шага. Проверьте её
            скорее!
          </Text>
        )}
        {!isShipped && (
          <>
            <Button disabled onClick={handleClick}>
              СОЗДАТЬ ЗАЯВКУ
            </Button>
            <ButtonDescription>
              Прикрепите необходимые документы ниже
            </ButtonDescription>
          </>
        )}
      </ButtonContainer>
    </Container>
  )
}

export default OrderInfo
