import React from "react"
import { graphql } from "relay-runtime"

import { Box, DefaultModal, QueryRenderer } from "src/components"

import Form from "./Form"
import Header from "./Header"

const render = (props) => {
  const { viewer } = props

  return (
    <DefaultModal
      padding="0"
      onClose={props.onClose}
      heightRestriction={false}
      closeButtonVariant="empty"
    >
      <Box width="445px" p="40px">
        <Header viewer={viewer} />
        <Form viewer={viewer} />
      </Box>
    </DefaultModal>
  )
}

export default (props) => {
  if (!props.open) return null

  return (
    <QueryRenderer
      {...props}
      query={graphql`
        query ChangePasswordModalQuery {
          viewer {
            id
            ...Form_viewer
            ...Header_viewer
          }
        }
      `}
      render={render}
    />
  )
}
