import React from "react"
import styled from "styled-components"

const Background = styled.div`
  background-image: url(/images/backgrounds/main.jpg);
  background-position: left bottom;
  background-size: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  ${({ theme }) => theme.devices.phone} {
    filter: blur(5px);
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    background-image: url(/images/backgrounds/main@2x.jpg);
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3) {
    background-image: url(/images/backgrounds/main@3x.jpg);
  }
`

export default () => <Background />
