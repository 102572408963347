import React, { useState } from "react"

import { AttachmentFileList } from "src/components"

import {
  Container,
  Header,
  Title,
  Description,
  Link,
  ImageContainer,
  Image,
  ButtonContainer,
  Button,
  SubmitButton,
  Icon,
  AttachmentTitle,
} from "./styles"

const MAX_FILE_SIZE = 10485760 // 10 Mb

function AccreditationDocuments() {
  const [files, setFiles] = useState([])

  function handleChange(event) {
    const fileList = event.target.files
    const newFiles = []

    for (let i = 0; i < fileList.length; i += 1) {
      if (fileList[i].size <= MAX_FILE_SIZE) {
        newFiles.push(fileList[i])
      }
    }

    const newState = files.concat(newFiles)
    setFiles(newState)
  }

  function handleDelete(idx) {
    const newFiles = files.slice()
    newFiles.splice(idx, 1)
    setFiles(newFiles)
  }

  return (
    <Container>
      <Header>
        <ImageContainer>
          <Image src="/images/Clerk.png" />
        </ImageContainer>

        <Title>
          Подтвердите статус квалифицированного инвестора, чтобы продолжить
          инвестировать.
        </Title>
      </Header>

      <Description>
        По закону такому-то никто не может инвестировать, если не имеет то-то и
        то-то. Тут нужно описать одной строкой хотя бы.
        <Link>Подробнее</Link>
      </Description>

      {files.length > 0 && (
        <>
          <AttachmentTitle>Загруженные документы</AttachmentTitle>
          <AttachmentFileList files={files} onDelete={handleDelete} />
        </>
      )}

      <ButtonContainer>
        <Button>
          загрузить
          <Icon />
          <input type="file" multiple onChange={handleChange} />
        </Button>

        {files.length > 0 && <SubmitButton>отправить</SubmitButton>}
      </ButtonContainer>
    </Container>
  )
}

export default AccreditationDocuments
