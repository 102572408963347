import idx from "idx"
import is from "is_js"
import React from "react"

import { Box, Title, Translate } from "src/components"

import { ProfileType, OrderStatusActionSchema } from "src/constants"

import {
  notifyGTM,
  formatDate,
  getProperty,
  isOrderOwner,
  getProfilePayments,
  getOfferRemainingDays,
  getOfferExpirationDate,
  getPaymentSummaryByStatus,
} from "src/utils"

import { ViewerData } from "src/context"

export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)))

export const pipe = (...fns) => compose.apply(compose, fns.reverse())

const mapSet = () => {
  throw new Error("Can't add property ' + key + ', map is not extensible")
}

const mapDelete = () => {
  throw new Error("Can't delete property ' + key + ', map is frozen")
}

const mapClear = () => {
  throw new Error("Can't clear map, map is frozen")
}

/* eslint-disable no-param-reassign */
export const freezeMap = (myMap) => {
  myMap.set = mapSet
  myMap.delete = mapDelete
  myMap.clear = mapClear

  Object.freeze(myMap)
}

export const isOwner = (viewer, order) => {
  const {
    profile: { id: orderOwnerProfileId },
  } = order
  const { profiles } = viewer

  return !!profiles.find((profile) => profile.id === orderOwnerProfileId)
}

export const getLinkPath = (status, source, id, orderType, externalUrl) => {
  const action = OrderStatusActionSchema.get(status)

  if (orderType === "Trusted") {
    return action.path(`${id}/trusted`)
  }

  return action && action.path(id, [["source", source]], externalUrl)
}

export const getPaidAmount = (viewer, order) => {
  const { paymentScheduleList: list } = order
  if (!list || !list.length || isOrderOwner(viewer, order)) return null

  const profiles = [...viewer.profiles, ...viewer.foreignProfiles]
  const payment = getProfilePayments(profiles, list, "investor")
  const paid = getPaymentSummaryByStatus(payment.items, "paid")

  return is.existy(paid) ? paid.total : null
}

export const hasPastduePayment = (paymentScheduleList) => {
  const { state } = getProperty(paymentScheduleList, "[0].info", {})
  return state === "pastdue"
}

export const isPaidOff = (paymentScheduleList) => {
  const payments = getProperty(paymentScheduleList, "[0].items", [])
  return !!payments.length && payments.every((item) => item.state === "paid")
}

export const statusChangeDateAdapter = ({ i18nRef }) => (data) => {
  const { statusFrom } = data

  return (
    <React.Fragment key={statusFrom}>
      <Translate i18n={i18nRef} date={formatDate(statusFrom)} />
    </React.Fragment>
  )
}

export const expiredDateAdapter = ({ i18nRef }) => (data) => {
  const { confirmedAt } = data

  const term = idx(data, (_) => _.offer.data.term) ? data.offer.data.term : null
  const parsedDate = getOfferExpirationDate(term, confirmedAt)

  return (
    <React.Fragment key={confirmedAt}>
      <Translate i18n={i18nRef} />
      &nbsp;
      <Translate render={() => formatDate(parsedDate)} />
    </React.Fragment>
  )
}

export const createdDateAdapter = ({ i18nRef }) => (data) => {
  const { createdAt } = data

  return (
    <ViewerData.renderPropContainer>
      {({ getViewerToOrderRelation }) => {
        const { MASTER: isMaster } = getViewerToOrderRelation(data)

        return isMaster ? (
          <Translate
            key={createdAt}
            i18n={i18nRef}
            date={formatDate(createdAt)}
          />
        ) : null
      }}
    </ViewerData.renderPropContainer>
  )
}

export const remainingDaysAdapter = ({ i18nRef }) => (data) => {
  const { confirmedAt } = data

  const term = idx(data, (_) => _.offer.data.term) ? data.offer.data.term : null
  const parsedDate = getOfferExpirationDate(term, confirmedAt)
  const remainingDays = getOfferRemainingDays(parsedDate)

  return (
    <React.Fragment key={remainingDays}>
      <Translate i18n={i18nRef} count={remainingDays} />
    </React.Fragment>
  )
}

export const confirmedDateAdapter = ({ i18nRef }) => (data) => {
  const { confirmedAt } = data

  return (
    <React.Fragment key={confirmedAt}>
      <Translate i18n={i18nRef} date={formatDate(confirmedAt)} />
    </React.Fragment>
  )
}

const AdapterSchema = new Map<string, any>([
  [
    "INITIAL",
    createdDateAdapter({
      i18nRef: "common:order.entity-date.created-from-sketch",
    }),
  ],
  [
    "PENDING",
    createdDateAdapter({
      i18nRef: "common:order.entity-date.created-from-sketch",
    }),
  ],
  [
    "DECLINED",
    createdDateAdapter({ i18nRef: "common:order.entity-date.created" }),
  ],
  [
    "APPROVED",
    expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
  ],
  [
    "CONFIRMED",
    [
      expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
      createdDateAdapter({ i18nRef: "common:order.entity-date.created" }),
    ],
  ],
  [
    "TRANSFER",
    [
      expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
      createdDateAdapter({ i18nRef: "common:order.entity-date.created" }),
    ],
  ],
  [
    "COMPLETE",
    statusChangeDateAdapter({ i18nRef: "common:order.entity-date.completed" }),
  ],
  [
    "SUCCEEDED",
    expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
  ],
  [
    "DEAD",
    [
      expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
      createdDateAdapter({ i18nRef: "common:order.entity-date.created" }),
    ],
  ],
  [
    "SUCCEEDED",
    expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
  ],
  [
    "DEAD",
    [
      expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
      createdDateAdapter({ i18nRef: "common:order.entity-date.created" }),
    ],
  ],
  [
    "DEFAULT",
    expiredDateAdapter({ i18nRef: "common:order.entity-date.expired" }),
  ],
])

const initAdapter = (status) => AdapterSchema.get(status)

export const useAdapter = (data) => {
  const { status } = data
  const adapter = initAdapter(status)

  if (adapter === undefined) {
    return ""
  }

  if (Array.isArray(adapter)) {
    return adapter.map((adapterFunction, index) => (
      <Box key={index}>
        <Title variant="black">
          {<React.Fragment key={index}>{adapterFunction(data)}</React.Fragment>}
        </Title>
      </Box>
    ))
  }

  return <Title variant="black">{adapter(data)}</Title>
}

/* eslint-disable no-underscore-dangle */
const isForeignInvestor = (profile) => profile.__typename === ProfileType.foreignIndividual
const isRussianInvestor = (profile) => profile.__typename === ProfileType.individual
/* eslint-enable no-underscore-dangle */

const hasInvestorProfile = (viewer) => {
  if (!viewer) return false

  const foreignProfiles = getProperty(viewer, "foreignProfiles", [])
  const hasForeignInvestor = foreignProfiles.some(isForeignInvestor)

  const hasRussianInvestor = viewer.profiles.some(isRussianInvestor)

  return hasForeignInvestor || hasRussianInvestor
}

export const orderPageGTMNotifier = (order, orderType, position, viewer) => {
  if (order.status !== "CONFIRMED" || orderType !== "Order") {
    return () => {}
  }

  const { id, cession, application } = order

  return notifyGTM({
    event: "productClick",
    eventCategory: "",
    extras: {
      ecommerce: {
        click: {
          products: [
            {
              id,
              position,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
            },
          ],
        },
      },
      FLProfile: hasInvestorProfile(viewer),
    },
  })
}
