import React from "react"

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.584 14.584">
    <g
      id="Group_1"
      data-name="Group 1"
      transform="translate(-1174.208 -454.208)"
    >
      <g id="Group" transform="translate(1174 454)">
        <path
          id="Rectangle_13"
          data-name="Rectangle 13"
          className="cls-1"
          transform="rotate(45 6.482 16.359)"
          d="M0 0h1.875v18.75H0z"
        />
        <path
          id="Rectangle_13-2"
          data-name="Rectangle 13"
          className="cls-1"
          transform="rotate(-45 1.956 .516)"
          d="M0 0h1.875v18.75H0z"
        />
      </g>
    </g>
  </svg>
)
