import React, { useState } from "react"
import { useHistory, useParams } from "react-router"
import Relay, { graphql } from "react-relay"

import { getBankAccountsFromForeignProfile } from "src/utils"

import EditBankAccount from "./EditBankAccount"
import InfoBankAccount from "./InfoBankAccount"

import { Container, Title, Description } from "./styles"

function TrustedBankAccount(props) {
  const { profile } = props

  const history = useHistory()
  const { id: accountId } = useParams()

  if (!profile || !profile.id) {
    history.push(`/trusted/${accountId}/info`)
  }

  const [bankAccount = {}] = getBankAccountsFromForeignProfile(profile)

  const [isEditing, setEditing] = useState(!bankAccount.id)

  return (
    <Container>
      <Title>
        {isEditing && "Укажите реквизиты"}
        {!isEditing && "Банковские реквизиты"}
      </Title>
      <Description>
        Введеные реквизиты банковского счета будут использоваться для вывода
        средств с виртуального счета.
      </Description>

      {isEditing && (
        <EditBankAccount
          profileId={profile.id}
          bankAccount={bankAccount}
          setEditing={setEditing}
        />
      )}

      {!isEditing && (
        <InfoBankAccount bankAccount={bankAccount} setEditing={setEditing} />
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(TrustedBankAccount, {
  profile: graphql`
    fragment TrustedBankAccount_profile on UserProfile {
      id
      __typename
      user {
        id
      }
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  `,
})
