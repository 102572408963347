import { graphql } from "react-relay"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestChangeEmailConfirmationMutation(
    $input: RequestChangeEmailConfirmationInput!
  ) {
    requestChangeEmailConfirmation(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
