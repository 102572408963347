/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type DebtPaymentData_order = {
    readonly status: OrderStatusEnum | null;
    readonly profile: {
        readonly id: string;
        readonly accountBalance: number;
    };
    readonly paymentScheduleList: ReadonlyArray<{
        readonly profile: {
            readonly id: string;
        } | null;
        readonly foreignProfile: {
            readonly id: string;
        } | null;
        readonly items: ReadonlyArray<{
            readonly date: string;
            readonly state: string | null;
            readonly total: number;
            readonly total_details: ReadonlyArray<{
                readonly currency: CurrencyEnum;
                readonly amount: number;
            }>;
        }>;
    }>;
    readonly " $refType": "DebtPaymentData_order";
};
export type DebtPaymentData_order$data = DebtPaymentData_order;
export type DebtPaymentData_order$key = {
    readonly " $data"?: DebtPaymentData_order$data;
    readonly " $fragmentRefs": FragmentRefs<"DebtPaymentData_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DebtPaymentData_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountBalance",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentSchedule",
      "kind": "LinkedField",
      "name": "paymentScheduleList",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ForeignIndividualProfile",
          "kind": "LinkedField",
          "name": "foreignProfile",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentScheduleItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AmountWithCurrency",
              "kind": "LinkedField",
              "name": "total_details",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order"
};
})();
(node as any).hash = '893fffe11a99ae548c6f0889fc62e4bb';
export default node;
