/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SaveButtonQueryVariables = {
    profileId: string;
};
export type SaveButtonQueryResponse = {
    readonly node: {
        readonly virtualDepositPurpose?: {
            readonly url: string;
        } | null;
    };
};
export type SaveButtonQuery = {
    readonly response: SaveButtonQueryResponse;
    readonly variables: SaveButtonQueryVariables;
};



/*
query SaveButtonQuery(
  $profileId: ID!
) {
  node(id: $profileId) {
    __typename
    ... on UserProfile {
      ... on IndividualProfile {
        virtualDepositPurpose {
          url
          id
        }
      }
      ... on EntrepreneurProfile {
        virtualDepositPurpose {
          url
          id
        }
      }
      ... on LegalEntityProfile {
        virtualDepositPurpose {
          url
          id
        }
      }
    }
    ... on ForeignIndividualProfile {
      virtualDepositPurpose {
        url
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GeneratedDocument",
    "kind": "LinkedField",
    "name": "virtualDepositPurpose",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GeneratedDocument",
    "kind": "LinkedField",
    "name": "virtualDepositPurpose",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "EntrepreneurProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "LegalEntityProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "ForeignIndividualProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "IndividualProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "EntrepreneurProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "LegalEntityProfile"
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "ForeignIndividualProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SaveButtonQuery",
    "operationKind": "query",
    "text": "query SaveButtonQuery(\n  $profileId: ID!\n) {\n  node(id: $profileId) {\n    __typename\n    ... on UserProfile {\n      ... on IndividualProfile {\n        virtualDepositPurpose {\n          url\n          id\n        }\n      }\n      ... on EntrepreneurProfile {\n        virtualDepositPurpose {\n          url\n          id\n        }\n      }\n      ... on LegalEntityProfile {\n        virtualDepositPurpose {\n          url\n          id\n        }\n      }\n    }\n    ... on ForeignIndividualProfile {\n      virtualDepositPurpose {\n        url\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '1b193617a9bca92323bb005e68ad2b85';
export default node;
