export const ModalShowErrors = [
  (error) => {
    const match = error.message?.match(/Invalid order status: ([A-Z]+?) status/)
    return (
      match && {
        key: "invalid_order_status",
      }
    )
  },
  (error) => {
    const match = error.message?.match(/Max order amount exceeded/)
    return (
      match && {
        key: "max_order_amount_exceeded",
      }
    )
  },
  (error) => {
    const match = error.message?.match(/Qualified amount exceeded/)
    return (
      match && {
        key: "qualified_amount_exceeded",
      }
    )
  },
]
