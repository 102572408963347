import React from "react"

import { Translate } from "src/components"
import { OrderStatusActionSchema } from "src/constants"

import { StyledArrowLink, ActionContainer } from "./styles"

import ComposedConsumer from "./ComposedConsumer"
import { orderPageGTMNotifier } from "../../utils"

const link = ({ status }: any) => OrderStatusActionSchema.get(status)

const Action = (props) => {
  const {
    data, viewer, source, trusted, orderType, orderPosition,
  } = props

  const sendGTMEvent = React.useMemo(
    () => orderPageGTMNotifier(data, orderType, orderPosition, viewer),
    [data, orderType, orderPosition, viewer],
  )

  const { id, status, externalUrl } = data

  const linkData = link({ status, id })

  if (!linkData) return null

  const href = trusted
    ? linkData.path(`${id}/trusted`)
    : linkData.path(id, [["source", source]], externalUrl)

  return (
    <ActionContainer onClick={sendGTMEvent}>
      <StyledArrowLink variant="blue" href={href}>
        <Translate i18n={linkData.label} />
      </StyledArrowLink>
    </ActionContainer>
  )
}

export default (props) => (
  <ComposedConsumer>
    {(consumerProps) => <Action {...props} {...consumerProps} />}
  </ComposedConsumer>
)
