import styled from "styled-components"

export const Link = styled.a<any>`
  display: inline-block;
  font-size: 14px;
  color: ${({ theme, variant }) => theme.colors[variant]};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-start;

  &:hover {
    ${Link} {
      text-decoration: underline;
    }
  }
`

export const ArrowContainer = styled.div<any>`
  width: 14px;
  height: 14px;
  display: inline-block;
  padding-left: 8px;

  svg {
    g {
      stroke: ${({ theme, variant }) => theme.colors[variant]};
    }
  }
`
