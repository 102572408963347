import { graphql } from "react-relay"

const fragment = graphql`
  fragment RussianIndividualFragment_profile on Node {
    id
    __typename
    ... on IndividualProfile {
      lastName
      firstName
      middleName
      phone
      isApproved
      approvedOnce
      isRussiaTaxResident
      sex
      inn
      iian
      birthDate
      birthPlace
      isPublicOfficial
      address: registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
        }
      }
      passport {
        ... on Passport {
          series
          number
          issuedAt
          postponeScans
          scans {
            id
            url
            filename
          }
          issuedBy {
            ... on PassportIssuer {
              code
              name
            }
          }
        }
      }
      supportingDocuments {
        ... on SupportingDocument {
          id
          attachment {
            id
            url
            size
            width
            height
            format
            filename
          }
          status
        }
      }
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  }
`

export default fragment
