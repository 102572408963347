import React from "react"

import {
  Box, Label, FormRow, Translate, TextField,
} from "src/components"

export class Ogrn extends React.Component<any, any> {
  state = {
    ogrn: this.props.value,
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state.ogrn,
    })
  }

  render() {
    return (
      <FormRow>
        <Box width={260}>
          <Label whiteSpace="pre-line">
            <Translate
              i18n="accreditation.borrower.rows.ogrn.label"
              ns="components"
            />
          </Label>
        </Box>
        <Box width="100%">
          <TextField
            type="text"
            name="ogrn"
            value={this.state.ogrn}
            onChange={this.onChange}
          />
        </Box>
      </FormRow>
    )
  }
}

export default Ogrn
