import React from "react"

import { Route } from "react-router"

import {
  Button, Template, Translate,
} from "src/components"

const handleRoute = (history, id) => () => {
  history.push(`/accounts/${id}/accreditation`)
}

const Action = ({ profiles }) => {
  const { id } = profiles[0]

  return (
    <Route>
      {({ history }) => (
        <Button onClick={handleRoute(history, id)} variant="blueWide">
          <Translate i18n="components:order_list.investments.initial.buttons.action" />
        </Button>
      )}
    </Route>
  )
}

export default (props) => (
  <Template
    layout="empty"
    header="components:order_list.investments.initial.header"
    image="/images/orderLists/pending/investments.svg"
    description="components:order_list.investments.initial.description"
    appendix="components:order_list.investments.initial.appendix"
    action={<Action {...props} />}
  />
)
