import "whatwg-fetch"
import { Observable } from "rxjs"

import { getAuthToken } from "src/utils"

const getBearerToken = () => `Bearer ${getAuthToken()}`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function fetchQuery(operation, variables, cacheConfig?: any, uploadables?: any) {
  return fetch(process.env.GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getBearerToken(),
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => response.json())
}

function fetchMutation(operation, variables, cacheConfig, uploadables) {
  const formData = new FormData()

  formData.append("query", operation.text)
  formData.append("variables", JSON.stringify(variables))

  Object.entries(uploadables || {}).forEach(([name, value]: any) => {
    formData.append(name, value)
  })

  return fetch(process.env.GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: getBearerToken(),
    },
    body: formData,
  }).then((response) => response.json())
}

const fetchQuery$ = (operation, variables, cacheConfig, uploadables) => new Observable((observer) => {
  fetchQuery(operation, variables, cacheConfig, uploadables).then((json) => {
    if (json.errors) observer.error(json)
    observer.next(json)
    observer.complete()
  })
})

const fetchMutation$ = (operation, variables, cacheConfig, uploadables) => new Observable((observer) => {
  fetchMutation(operation, variables, cacheConfig, uploadables).then(
    (json) => {
      if (json.errors) observer.error(json)
      observer.next(json)
      observer.complete()
    },
  )
})

function fetchQueryOrMutation(operation, variables, cacheConfig, uploadables) {
  switch (operation.operationKind) {
    case "query": {
      return fetchQuery$(operation, variables, cacheConfig, uploadables)
    }

    case "mutation": {
      return fetchMutation$(operation, variables, cacheConfig, uploadables)
    }

    default: {
      throw new Error(`Unknown operation "${operation.operationKind}"`)
    }
  }
}

export default fetchQueryOrMutation
