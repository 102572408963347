import React from "react"

import { Container, Paragraph, Headline } from "./styles"

const ProposalExpertOpinion = ({ data }) => {
  const { expertOpinions } = data
  return (
    <Container>
      {expertOpinions.map((item) => (
        <div key={item.id}>
          <Headline>Заключение эксперта Карма</Headline>
          <Paragraph>{item.opinion}</Paragraph>
        </div>
      ))}
    </Container>
  )
}

export default ProposalExpertOpinion
