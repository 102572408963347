import React from "react"
import memoize from "memoize-one"
import onClickOutside from "react-onclickoutside"

import { createChainedFunction } from "src/utils"

import {
  Option,
  InputWrapper,
  StyledArrowIcon,
  OptionsContainer,
  SelectedProfileContainer,
} from "./styles"

import { ProfileInfo } from "./elements"

const optionsMapFn = memoize((onSelect, selectedItem) => (item) => (
  <Option
    key={item.id}
    onClick={() => onSelect(item)}
    active={item.id === selectedItem.id}
  >
    <ProfileInfo profile={item} />
  </Option>
))

const ProfileSelect = (props) => {
  const { value, onChange, options } = props

  const [open, setOpen] = React.useState(false)
  const hideOptions = React.useCallback(() => setOpen(false), [])
  const showOptions = React.useCallback(
    () => Array.isArray(options) && options.length > 1 && setOpen(true),
    [],
  );

  (ProfileSelect as any).handleClickOutside = hideOptions

  const onSelect = React.useCallback(
    createChainedFunction(onChange, hideOptions),
    [],
  )
  const renderOptionFn = optionsMapFn(onSelect, value)

  return (
    <InputWrapper>
      <SelectedProfileContainer onClick={open ? hideOptions : showOptions}>
        <ProfileInfo profile={value} bordered />
        <StyledArrowIcon optionsVisible={open} />
      </SelectedProfileContainer>
      {open && (
        <OptionsContainer shouldDisplay={open}>
          {options.map(renderOptionFn)}
        </OptionsContainer>
      )}
    </InputWrapper>
  )
}

const config = {
  handleClickOutside: () => (ProfileSelect as any).handleClickOutside,
}

export default onClickOutside(ProfileSelect, config)
