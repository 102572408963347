/* eslint-disable max-len */

import React from "react"

export const DownloadIcon = (props) => (
  <svg {...props} viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.03337 6.60556L5.2917 6.60556L5.2917 0.8L4.13059 0.8L4.13059 6.60556L2.38892 6.60555L4.71115 8.92778L7.03337 6.60556ZM9.35559 11.25L9.35559 10.0889L0.0667001 10.0889L0.0667 11.25L9.35559 11.25Z" />
  </svg>
)

export const CheckIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM4.16104 7.36175L6.0561 9.29765L10.8388 4.41179L11.4705 5.05709L6.0561 10.5883L3.52936 8.00705L4.16104 7.36175Z"
    />
  </svg>
)

DownloadIcon.defaultProps = {
  width: "12px",
  height: "12px",
  fill: "#4a4a4a",
}

CheckIcon.defaultProps = {
  width: "15px",
  height: "15px",
  fill: "#4a4a4a",
}
