/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type removeInvestorAutoInvestmentsParamsInput = {
    profileId: string;
    token: string;
};
export type RemoveInvestorAutoInvestmentsParamsMutationVariables = {
    input: removeInvestorAutoInvestmentsParamsInput;
};
export type RemoveInvestorAutoInvestmentsParamsMutationResponse = {
    readonly removeInvestorAutoInvestmentsParams: {
        readonly status: boolean;
    };
};
export type RemoveInvestorAutoInvestmentsParamsMutation = {
    readonly response: RemoveInvestorAutoInvestmentsParamsMutationResponse;
    readonly variables: RemoveInvestorAutoInvestmentsParamsMutationVariables;
};



/*
mutation RemoveInvestorAutoInvestmentsParamsMutation(
  $input: removeInvestorAutoInvestmentsParamsInput!
) {
  removeInvestorAutoInvestmentsParams(input: $input) {
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "removeInvestorAutoInvestmentsParamsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "removeInvestorAutoInvestmentsParamsPayload",
    "kind": "LinkedField",
    "name": "removeInvestorAutoInvestmentsParams",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveInvestorAutoInvestmentsParamsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveInvestorAutoInvestmentsParamsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RemoveInvestorAutoInvestmentsParamsMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveInvestorAutoInvestmentsParamsMutation(\n  $input: removeInvestorAutoInvestmentsParamsInput!\n) {\n  removeInvestorAutoInvestmentsParams(input: $input) {\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a487685a9341968a976be2a2a5dd3f6';
export default node;
