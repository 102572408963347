/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type NewPasswordModalQueryVariables = {};
export type NewPasswordModalQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly password: {
            readonly present: boolean;
            readonly updated_at: string | null;
        };
    } | null;
};
export type NewPasswordModalQuery = {
    readonly response: NewPasswordModalQueryResponse;
    readonly variables: NewPasswordModalQueryVariables;
};



/*
query NewPasswordModalQuery {
  viewer {
    id
    password {
      present
      updated_at
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPassword",
        "kind": "LinkedField",
        "name": "password",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "present",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPasswordModalQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewPasswordModalQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "NewPasswordModalQuery",
    "operationKind": "query",
    "text": "query NewPasswordModalQuery {\n  viewer {\n    id\n    password {\n      present\n      updated_at\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a8289ad644defc5fc7b24fbf6ccc76c';
export default node;
