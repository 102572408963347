import styled from "styled-components"

export const ListContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`

export const ListItem = styled.div<any>`
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  padding: 5px 6px;
  flex: 1;
  margin-bottom: ${({ isLast }) => (isLast ? "0" : "10px")};
  background-color: ${({ isDragging, theme }) => (isDragging ? theme.colors.grey : theme.colors.white)};
  cursor: move;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 300%;
`

export const ListItemIndex = styled.div`
  padding: 5px 0;
  width: 30px;
  height: 60px;
  margin-bottom: 10px;
  line-height: 50px;
  text-align: left;
`

export const IndexContainer = styled.div`
  padding-top: 20px;
  height: 50px;
  display: flex;
  flex-direction: column;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`

export const ListImage = styled.div<any>`
  width: 40px;
  height: 40px;
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center left;
  margin-right: 12px;
`

export const InputContainer = styled.div`
  flex-direction: row;
  display: flex;
`
