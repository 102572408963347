import React from "react"
import styled from "styled-components"

import { Button } from "src/components"

const IconWrapper = styled(Button)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.ginger};
`

export const ArrowIcon = styled.div<any>`
  background-image: url(/images/arrow.svg);
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : "none")};
  margin: auto;
  width: 10px;
  background-size: contain;
  height: 18px;
`

export default ({ rotate, ...rest }: any) => (
  <IconWrapper variant="withShadow" {...rest}>
    <ArrowIcon rotate={rotate} />
  </IconWrapper>
)
