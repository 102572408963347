import styled from "styled-components"

export const Closer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
`

export const PopupWindow = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 364px;
  height: 164px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.19);
  font-size: 14px;
  line-height: 18px;
  padding: 40px 35px 0 35px;
  .ProposalPopup-exitIcon {
    position: absolute;
    top: 26px;
    right: 18px;
    cursor: pointer;
  }
  p {
    margin: 0 0 20px 0;
    color: rgba(74, 74, 74, 0.75);
    font-weight: 600;
  }
  button {
    color: #f70000;
    padding: 0;
    width: 165px;
    height: 34px;
    font-family: "Geometria";
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    border: 1px solid #f70000;
    border-radius: 4px;
    @media (max-width: 480px) {
      width: 375px;
      height: 40px;
    }
  }
  @media (max-width: 480px) {
    width: 438px;
    height: 215px;
    padding: 56px 20px 0 38px;
    font-size: 18px;
  }
`
