import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateLegalEntityProfileBorrowerDataMutation(
    $input: UpdateLegalEntityProfileBorrowerDataInput!
  ) {
    updateLegalEntityProfileBorrowerData(input: $input) {
      profile {
        id
        user {
          id
        }
        ...Header_profile
        ...ProfileBody_profile
      }
    }
  }
`

export default enhance({ mutation })
