import React from "react"
import ReactDOM from "react-dom"

import {
  InputAmount,
  NewPaymentTable,
  IncomeContainer,
  MiniLoader,
} from "src/components"

import { exit } from "./icons"
import { Graph } from "../OrderFactory/entities/icons"

import {
  ModalContainer,
  WindowContainer,
  NameWrap,
  TitleWrap,
  ExitBtn,
  IncomeWrapper,
  FlexWrapper,
  Divider,
  LoadingContainer,
  StatusWrap,
} from "./styles"

import { getIncome, getAverageIncome, getTitle } from "./utils"

function PaymentSchedule(props) {
  const {
    onClose,
    isCession,
    data,
    schedule,
    changeAmount,
    amount,
    isFetching,
    errorMaxSum,
  } = props

  const overlayRef = React.createRef<HTMLDivElement>()
  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      onClose()
    }
  }

  React.useEffect(() => {
    window.document.body.style.overflow = "hidden"

    return () => {
      window.document.body.style.overflow = null
    }
  }, [])

  return (
    <ModalContainer ref={overlayRef} onClick={onOverlayClick}>
      <WindowContainer>
        <ExitBtn onClick={onClose}>{exit}</ExitBtn>
        <NameWrap>{data.application.data.companyName}</NameWrap>
        <TitleWrap>{getTitle(isCession)}</TitleWrap>
        {isCession && <StatusWrap>{Graph} Платежи идут по графику</StatusWrap>}
        {isFetching && (
          <LoadingContainer>
            <MiniLoader fontSize="5px" margin="auto" />
          </LoadingContainer>
        )}
        <FlexWrapper>
          <InputAmount
            isFetching={isFetching}
            onChange={changeAmount}
            value={amount}
            label="Введите сумму инвестиций"
            errorText={errorMaxSum}
          />
          <IncomeWrapper>
            <IncomeContainer
              title="Среднемесячный доход"
              value={getAverageIncome(schedule)}
            />
            <Divider />
            <IncomeContainer title="Общий доход" value={getIncome(schedule)} />
          </IncomeWrapper>
        </FlexWrapper>
        <NewPaymentTable {...props} />
      </WindowContainer>
    </ModalContainer>
  )
}

export default function ModalPaymentSchedule(props) {
  const { isShowing } = props
  const root = window.document.getElementById("app-root")
  return isShowing ? ReactDOM.createPortal(PaymentSchedule(props), root) : null
}
