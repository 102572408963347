/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountDescriptionQueryVariables = {};
export type AccountDescriptionQueryResponse = {
    readonly viewer: {
        readonly profiles: ReadonlyArray<{
            readonly __typename: string;
        }>;
    } | null;
};
export type AccountDescriptionQuery = {
    readonly response: AccountDescriptionQueryResponse;
    readonly variables: AccountDescriptionQueryVariables;
};



/*
query AccountDescriptionQuery {
  viewer {
    profiles {
      __typename
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDescriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountDescriptionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountDescriptionQuery",
    "operationKind": "query",
    "text": "query AccountDescriptionQuery {\n  viewer {\n    profiles {\n      __typename\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4c7ac9c2dbf692aeffb552042c8d03f9';
export default node;
