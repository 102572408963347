import Loan from "./Loan"
import Order from "./Order"
import Proposal from "./Proposal"
import Investment from "./Investment"
import GuestOrder from "./GuestOrder"

const render = () => null

render.Loan = Loan
render.Order = Order
render.Proposal = Proposal
render.Investment = Investment
render.GuestOrder = GuestOrder

export default render
