import React, { SetStateAction, Dispatch } from "react"
import { SearchInput } from "src/components"
import { Title, TitleSearch, TitleWrap } from "./styles"
import { MobileWrapper } from "../wrappers"

type Filter = {
  search: string,
}

export type HeaderProps = {
  setFilter: Dispatch<SetStateAction<Filter>>,
  filter: Filter,
  title: JSX.Element | string,
}

export function Header(props: HeaderProps): JSX.Element {
  const handleSearch = React.useCallback(
    (search) => {
      props.setFilter((filter) => ({
        ...filter,
        search,
      }))
    },
    [props.setFilter],
  )

  return (
    <>
      <TitleWrap>
        <Title>{props.title}</Title>

        <TitleSearch>
          <SearchInput
            value={props.filter.search}
            onChange={handleSearch}
            placeholder="Название / ИНН"
          />
        </TitleSearch>
      </TitleWrap>

      <MobileWrapper>
        <SearchInput
          maxWidth="100%"
          value={props.filter.search}
          onChange={handleSearch}
          placeholder = "Название / ИНН"
        />
      </MobileWrapper>
    </>
  )
}
