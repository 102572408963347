import React from "react"
import memoize from "memoize-one"

import { WITHDRAWAL_FEE } from "src/constants"
import { ErrorsContainer } from "src/containers"
import { getProperty, isRussianEntrepreneur, isRussianInvestor } from "src/utils"

import {
  Box,
  Flex,
  Text,
  Translate,
  FloatingLabelInput,
} from "src/components"

import {
  ModalHeader,
  ProfileInfo,
  CurrencyTooltip,
  PassportWarning,
  ProfileRequisites,
} from "./elements"

import { useErrors, useOnChange, useOnSubmit } from "./hooks"

import {
  SubHeader, AmountModalContainer, NButton, AmountButtonContainer,
} from "./styles"

const isPassportFilled = memoize((profile) => {
  if (!isRussianInvestor(profile) && !isRussianEntrepreneur(profile)) return true

  const scans = getProperty(profile, "passport.scans", [])
  return !!scans.length
})

const AmountModal = (props) => {
  const {
    profile, onClose, withdrawAmount, availableBalance,
  } = props

  const withdrawAmountNumber = Number(
    withdrawAmount.replace(",", ".").replace(/\s/g, ""),
  )
  const isEnoughMoney = availableBalance && availableBalance > WITHDRAWAL_FEE
  const isAmountValid = (withdrawAmountNumber + WITHDRAWAL_FEE <= availableBalance)

  const onChange = useOnChange(props)
  const onSubmit = useOnSubmit(props)
  const getErrorByPath = useErrors(props)

  return (
    <Box maxWidth="510px" width="100%" p="35px" height="100%" overflow="auto">
      <Box mb="30px">
        <ModalHeader i18n="components:account.operations.withdraw.amount.title" />
      </Box>
      <AmountModalContainer>
        <SubHeader>
          <Translate i18n="components:account.operations.withdraw.amount.from" />
        </SubHeader>
        <Box mt="8px" mb="30px">
          <ProfileInfo profile={profile} />
        </Box>
        <Flex justifyContent="space-between" mb="8px">
          <SubHeader>
            <Translate i18n="components:account.operations.withdraw.amount.amount_header" />
          </SubHeader>
          <SubHeader>
            <Translate i18n="components:account.operations.withdraw.amount.fee" />
          </SubHeader>
        </Flex>
        <FloatingLabelInput
          name="withdrawalAmount"
          value={withdrawAmount}
          onChange={onChange}
          tooltip={CurrencyTooltip}
          error={getErrorByPath("requestWithdrawal.amount")}
          label="components:account.operations.withdraw.amount.amount_placeholder"
        />
        <Box mb="20px">
          {!isEnoughMoney && (
            <Text fontSize="14px" color="red" lineHeight="1.7">
              <Translate i18n="components:account.operations.withdraw.amount.not_enough_money" />
            </Text>
          )}
          {!isAmountValid && (
            <Text fontSize="14px" color="red" lineHeight="1.7">
              <Translate i18n="components:account.operations.withdraw.amount.not_valid_amount" />
            </Text>
          )}
          {!isPassportFilled(profile) && (
            <Box mt="10px">
              <PassportWarning profileId={profile.id} />
            </Box>
          )}
        </Box>
        <ProfileRequisites profile={profile} />
      </AmountModalContainer>
      <AmountButtonContainer>
        <NButton onClick={onClose}>
          <Translate i18n="components:account.operations.withdraw.amount.controls.cancel" />
        </NButton>
        <Box ml="20px">
          <NButton
            color="#F70000"
            borderColor="#F70000"
            onClick={onSubmit}
            disabled={!isPassportFilled(profile) || !isEnoughMoney || !isAmountValid}
          >
            <Translate i18n="components:account.operations.withdraw.amount.controls.withdraw" />
          </NButton>
        </Box>
      </AmountButtonContainer>
    </Box>
  )
}

export default (props) => (
  <ErrorsContainer>
    {(errors) => <AmountModal {...props} {...errors} />}
  </ErrorsContainer>
)
