import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation ConfirmBankAccountWithPhoneMutation(
    $input: ConfirmBankAccountWithPhoneInput!
  ) {
    confirmBankAccountWithPhone(input: $input) {
      account {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  }
`
export default enhance({ mutation })
