import React from "react"

import {
  Box,
  Button,
  FlexBox,
  Translate,
  DownloadLink,
  FormLoadingButton,
} from "src/components"

const RequisitesControls = ({ isLoading, url }) => (
  <FlexBox display="flex" flexDirection="row" pt={42} pb={34}>
    <Box pr={20}>
      <DownloadLink href={url} target="_blank" download>
        <FormLoadingButton
          disabled={isLoading}
          defaultThin
          height={30}
          width={281}
        >
          <Translate
            i18n="order_list.approval.buttons.requisites"
            ns="components"
          />
        </FormLoadingButton>
      </DownloadLink>
    </Box>
    <Box>
      <Button variant="defaultThin" disabled height={30}>
        <Translate i18n="order_list.approval.buttons.mail" ns="components" />
      </Button>
    </Box>
  </FlexBox>
)

export default RequisitesControls
