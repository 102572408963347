import styled from "styled-components"

export const TData = styled.td`
  font-size: 16px;

  @media screen and (max-width: 900px) {
    font-size: 14px;
  }
`

export const TableRow = styled.tr`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  height: 76px;
  vertical-align: middle;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  color: #4a4a4a;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`

export const TDStatus = styled.td`
  color: #6fa84b;
`
