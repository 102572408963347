import React from "react"
import styled from "styled-components"

import Text from "../Text"

const Title = styled(Text)`
  font-size: 10px;
  text-transform: uppercase;
  color: ${({ theme, variant }) => theme.colors[variant]};
`

const Container = styled.div<any>`
  padding: ${({ padding }) => padding};
`

const render = ({
  children, variant, padding,
}: any) => (
  <Container padding={padding}>
    <Title variant={variant}>{children}</Title>
  </Container>
)

render.defaultProps = {
  variant: "greyDark",
  padding: "0 0 4px 0",
}

export default render
