/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type UpdateEntrepreneurProfileBorrowerDataInput = {
    profileId: string;
    lastName?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    inn: string;
    ogrn?: string | null;
    iian?: string | null;
    publicEmail?: string | null;
    address?: AddressInput | null;
    actualAddress?: AddressInput | null;
    passport?: PassportInput | null;
    license?: EntrepreneurLicenseInput | null;
    birthDate?: string | null;
    birthPlace?: string | null;
    isPublicOfficial?: boolean | null;
    isRussiaTaxResident?: boolean | null;
    sex?: SexEnum | null;
    bic?: string | null;
    checkingAccount?: string | null;
};
export type AddressInput = {
    country?: string | null;
    region?: string | null;
    area?: string | null;
    locality?: string | null;
    street?: string | null;
    house?: string | null;
    housing?: string | null;
    structure?: string | null;
    flat?: string | null;
    raw?: string | null;
    countryIsoCode?: string | null;
    okato?: string | null;
    oktmo?: string | null;
    settlement?: string | null;
    postalCode?: string | null;
    cityDistrict?: string | null;
    unreliable?: boolean | null;
};
export type PassportInput = {
    series?: string | null;
    number?: string | null;
    issuedAt?: string | null;
    issuedBy?: PassportIssuerInput | null;
    scans: Array<string>;
    postponeScans?: boolean | null;
};
export type PassportIssuerInput = {
    code?: string | null;
    name?: string | null;
};
export type EntrepreneurLicenseInput = {
    type?: string | null;
    numb?: string | null;
    date?: string | null;
    place?: string | null;
    duration?: string | null;
    typeList?: string | null;
};
export type UpdateEntrepreneurProfileBorrowerDataMutationVariables = {
    input: UpdateEntrepreneurProfileBorrowerDataInput;
};
export type UpdateEntrepreneurProfileBorrowerDataMutationResponse = {
    readonly updateEntrepreneurProfileBorrowerData: {
        readonly profile: {
            readonly id: string;
            readonly user: {
                readonly id: string;
            };
        };
    };
};
export type UpdateEntrepreneurProfileBorrowerDataMutation = {
    readonly response: UpdateEntrepreneurProfileBorrowerDataMutationResponse;
    readonly variables: UpdateEntrepreneurProfileBorrowerDataMutationVariables;
};



/*
mutation UpdateEntrepreneurProfileBorrowerDataMutation(
  $input: UpdateEntrepreneurProfileBorrowerDataInput!
) {
  updateEntrepreneurProfileBorrowerData(input: $input) {
    profile {
      id
      user {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateEntrepreneurProfileBorrowerDataInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateEntrepreneurProfileBorrowerDataPayload",
    "kind": "LinkedField",
    "name": "updateEntrepreneurProfileBorrowerData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EntrepreneurProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEntrepreneurProfileBorrowerDataMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateEntrepreneurProfileBorrowerDataMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateEntrepreneurProfileBorrowerDataMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEntrepreneurProfileBorrowerDataMutation(\n  $input: UpdateEntrepreneurProfileBorrowerDataInput!\n) {\n  updateEntrepreneurProfileBorrowerData(input: $input) {\n    profile {\n      id\n      user {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '728986db899cc766c9440ab444c6f604';
export default node;
