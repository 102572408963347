import React, { useState } from "react"

import { ProfileType } from "src/constants"

import { unionDocs } from "src/utils"
import { Document, PresentationIcon } from "../ProposalDocuments/icons"
import Wysiwyg from "../Wysiwyg"

import { Gallery, PopUp } from "./Elements"

import {
  MainContainer,
  MainTitle,
  ContentContainer,
  DescriptionContainer,
  TextContainer,
  Text,
  TextTitle,
  TextContent,
  CardsContainer,
  Card,
  CardTitle,
  CardText,
  CardDescription,
  CardAvatar,
  VideoFrame,
  AttachmentsContainer,
  Attachment,
  VideoContainer,
  Link,
  CardRow,
} from "./styles"

import { parseAttachments } from "./utils"

// eslint-disable-next-line object-curly-newline
function ProposalDescription({ data, isInvestor, isOwner }) {
  const {
    application, profile, cession, document,
  } = data
  const [isVisible, setVisible] = useState(false)

  const { images, otherAttachments } = parseAttachments(application.attachments)
  const ceo = cession
    ? cession?.originalOrder?.profile?.borrower
    : profile?.borrower

  const sellerIsLegalEntity = profile.__typename === ProfileType.juristic

  const profileCard = cession ? cession.originalOrder?.profile : profile
  const workerCount = cession
    ? cession.originalOrder?.application.data.personaValue
    : application.data.personaValue

  const otherDocuments = [...data.otherDocuments]
  let financialStatements = [...data.financialStatements]
  let guarantors = [...data.guarantors]

  // if we get a cession, combine order documents with cession documents
  if (cession && cession.originalOrder) {
    financialStatements = unionDocs(
      data.financialStatements,
      data.cession.originalOrder.financialStatements,
    )
    guarantors = unionDocs(
      data.guarantors,
      data.cession.originalOrder.guarantors,
    )
  }

  const handleClick = (e) => {
    if (!isInvestor && !isOwner) {
      e.preventDefault()
      setVisible(true)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        {application.externalMedium.length > 0 && (
          <VideoContainer>
            <VideoFrame
              src={application.externalMedium[0].video.url}
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            />
          </VideoContainer>
        )}

        {/* <ChartsContainer> */}
        {/*  <ColumnChart /> */}
        {/*  <ColumnChart /> */}
        {/*  <ColumnChart /> */}
        {/*  <ColumnChart /> */}
        {/* </ChartsContainer> */}
      </ContentContainer>

      <MainTitle>{cession ? "О заемщике" : "О компании"}</MainTitle>

      <DescriptionContainer>
        <TextContainer>
          <Text>
            <TextTitle>Описание бизнеса</TextTitle>
            <TextContent>
              <Wysiwyg.Render render={application.data.description} />
            </TextContent>
            <Gallery items={images} />

            <AttachmentsContainer>
              {otherAttachments.map((item, i) => (
                <Attachment key={i} href={item.url}>
                  <PresentationIcon /> {item.filename}
                </Attachment>
              ))}
            </AttachmentsContainer>
          </Text>

          {application.infoBlocks
            && application.infoBlocks.map((block, i) => (
              <Text key={i}>
                <TextTitle>{block.title}</TextTitle>
                <TextContent>{block.content}</TextContent>
              </Text>
            ))}
        </TextContainer>

        <CardsContainer>
          {!cession && ceo && ceo.executive && (
            <Card style={{ display: "flex" }}>
              {ceo.executive.photo && <CardAvatar src={ceo.executive.photo} />}
              <div>
                <CardTitle>{ceo.executive.name || ""}</CardTitle>
                <CardDescription>Ген. директор</CardDescription>
              </div>
            </Card>
          )}

          {!cession && application.founders && application.founders.length > 0 && (
            <Card>
              <CardTitle>Учредители</CardTitle>
              {application.founders.map((item, i) => (
                <CardText key={i}>
                  {item.person.name} - {item.share}% УК
                </CardText>
              ))}
            </Card>
          )}

          {profileCard && (
            <Card>
              <CardTitle>
                {cession ? "Карточка заемщика" : "Карточка компании"}
              </CardTitle>
              <CardText>Наименование заемщика - {profileCard.name}</CardText>
              <CardText>ИНН заемщика - {profileCard.inn}</CardText>
              {profileCard?.borrower?.publicEmail && (
                <CardText>
                  Email для чеков самозанятых -{" "}
                  {profileCard.borrower.publicEmail}
                </CardText>
              )}
              {cession && ceo?.executive && (
                <CardText>Ген. директор - {ceo.executive.name || ""}</CardText>
              )}
              {!!workerCount && (
                <CardText>Количество сотрудников - {workerCount}</CardText>
              )}

              {cession
                && cession.originalOrder?.application.founders
                && cession.originalOrder?.application.founders.length > 0 && (
                  <>
                    <CardText pt={20} fontWeight="bold">
                      Учредители:
                    </CardText>
                    {cession.originalOrder?.application.founders.map(
                      (item, i) => (
                        <CardText key={i}>
                          {item.person.name} - {item.share}% УК
                        </CardText>
                      )
                    )}
                  </>
              )}
            </Card>
          )}

          {cession && (
            <Card>
              <CardTitle>Карточка цедента</CardTitle>
              {sellerIsLegalEntity && (
                <CardText>Наименование - {profile.name}</CardText>
              )}
              {!sellerIsLegalEntity && (
                <CardText>ФИО - {profile.name}</CardText>
              )}
              <CardText>ИНН - {profile.inn}</CardText>
              {sellerIsLegalEntity && (
                <>
                  {profile.executive && (
                    <CardText>
                      Ген. директор -{" "}
                      {profile.executive.name || ""}
                    </CardText>
                  )}
                  {application.founders && application.founders.length > 0 && (
                    <>
                      <CardText pt={20} fontWeight="bold">
                        Учредители:
                      </CardText>
                      {application.founders.map((item, i) => (
                        <CardText key={i}>
                          {item.person.name} - {item.share}% УК
                        </CardText>
                      ))}
                    </>
                  )}
                </>
              )}
            </Card>
          )}

          {(otherDocuments.length > 0
            || financialStatements.length > 0
            || document
            || guarantors.length > 0) && (
            <Card>
              <CardTitle>Документы по проекту</CardTitle>

              {document && ( // Предварительная оферта
                <CardRow as="li">
                  <Document />
                  <Link
                    href={document.url}
                    target="_blank"
                    onClick={handleClick}
                  >
                    Предварительная оферта
                  </Link>
                </CardRow>
              )}

              {guarantors.map((item, i) => (
                <CardRow as="li" key={i}>
                  <Document />
                  <Link
                    href={item.attachment.url}
                    target="_blank"
                    onClick={handleClick}
                  >
                    {item.name || item.description}
                  </Link>
                </CardRow>
              ))}

              {otherDocuments.map((item, i) => (
                <CardRow as="li" key={i}>
                  <Document />
                  <Link
                    href={item.attachment.url}
                    target="_blank"
                    onClick={handleClick}
                  >
                    {item.name || item.description}
                  </Link>
                </CardRow>
              ))}

              {financialStatements.map((item, i) => (
                <CardRow as="li" key={i}>
                  <Document />
                  <Link
                    href={item.attachment.url}
                    target="_blank"
                    onClick={handleClick}
                  >
                    {item.name}
                  </Link>
                </CardRow>
              ))}

              <PopUp isVisible={isVisible} onClose={() => setVisible(false)} />
            </Card>
          )}
        </CardsContainer>
      </DescriptionContainer>
    </MainContainer>
  )
}

export default ProposalDescription
