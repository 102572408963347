import React from "react"

import { Route } from "react-router"

import {
  Text, Button, Template, Translate,
} from "src/components"

const handleRoute = (history, id) => (event) => {
  if (event) {
    event.preventDefault()
  }
  history.push(`/accounts/${id}`)
}

const Action = ({ id }) => (
  <Route>
    {({ history }) => (
      <Button variant="blueWide" onClick={handleRoute(history, id)}>
        <Text fontSize="16px">
          <Translate i18n="components:order_list.investments.rejected.buttons.action" />
        </Text>
      </Button>
    )}
  </Route>
)

export default ({ profiles }) => {
  const { id } = profiles[0] //  REFACTOR THIS MESS

  return (
    <Template
      layout="empty"
      header="components:order_list.investments.rejected.header"
      image="/images/orderLists/pending/investments.svg"
      description="components:order_list.investments.rejected.description"
      action={<Action id={id} />}
    />
  )
}
