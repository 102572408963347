import React from "react"

/* eslint-disable max-len */
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <g
      id="Group_2"
      data-name="Group 2"
      transform="translate(1.5 1.5)"
      strokeMiterlimit="10"
      fill="none"
    >
      <g id="Combined_Shape" data-name="Combined Shape">
        <path
          className="cls-3"
          d="M6.75 15v-1.547a5.96 5.96 0 0 1-2.929-1.213l-1.094 1.093-1.06-1.06L2.76 11.18a5.98 5.98 0 0 1-1.213-2.93H0v-1.5h1.547A5.967 5.967 0 0 1 2.76 3.82L1.667 2.727l1.06-1.06L3.82 2.76a5.967 5.967 0 0 1 2.93-1.213V0h1.5v1.547a5.983 5.983 0 0 1 2.93 1.213l1.094-1.094 1.06 1.06-1.094 1.095a5.96 5.96 0 0 1 1.213 2.929H15v1.5h-1.547a5.97 5.97 0 0 1-1.214 2.929l1.094 1.094-1.06 1.06-1.094-1.093a5.971 5.971 0 0 1-2.929 1.213V15z"
        />
        <path
          d="M8.25 15v-1.547a5.971 5.971 0 0 0 2.93-1.213l1.093 1.093 1.06-1.06-1.093-1.094a5.97 5.97 0 0 0 1.213-2.929H15v-1.5h-1.547a5.96 5.96 0 0 0-1.213-2.93l1.093-1.093-1.06-1.06L11.18 2.76a5.983 5.983 0 0 0-2.93-1.213V0h-1.5v1.547A5.967 5.967 0 0 0 3.82 2.76L2.727 1.667l-1.06 1.06L2.76 3.82a5.967 5.967 0 0 0-1.213 2.93H0v1.5h1.547a5.98 5.98 0 0 0 1.213 2.93l-1.093 1.093 1.06 1.06 1.094-1.093a5.96 5.96 0 0 0 2.929 1.213V15h1.5m1.5 1.5h-4.5V14.656a7.476 7.476 0 0 1-1.22-.505l-.242.243-1.061 1.06-1.06-1.06-1.06-1.06-1.062-1.06 1.061-1.061.244-.244A7.496 7.496 0 0 1 .345 9.75H-1.5v-4.5H.345c.132-.42.3-.828.505-1.219l-.244-.243-1.06-1.061 1.06-1.06 1.06-1.06L2.727-.456 3.787.606l.244.244C4.421.646 4.83.477 5.25.345V-1.5h4.5V.345A7.5 7.5 0 0 1 10.97.85l.244-.244 1.06-1.06 1.061 1.06 1.06 1.06 1.06 1.061-1.06 1.06-.243.244c.204.39.373.799.505 1.219H16.5v4.5h-1.845c-.132.42-.3.828-.505 1.219l.244.244 1.06 1.06-1.06 1.061-1.06 1.06-1.06 1.06-1.061-1.06-.244-.244c-.39.204-.799.373-1.219.505V16.5z"
          fill="#353a43"
        />
      </g>
      <g
        id="Oval_4"
        data-name="Oval 4"
        transform="translate(5.25 5.25)"
        stroke="#353a43"
        strokeWidth="1.5"
      >
        <circle className="cls-3" cx="2.25" cy="2.25" r="2.25" />
        <circle cx="2.25" cy="2.25" r="3" />
      </g>
    </g>
  </svg>
)
