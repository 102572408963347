import Deposit from "./Deposit"
import Withdraw from "./Withdraw"
import Modal from "./Deposit/Modal"
import Convert from "./Convert"

const render: any = () => null

render.Deposit = Deposit
render.Deposit.Modal = Modal
render.Withdraw = Withdraw
render.Convert = Convert

export default render
