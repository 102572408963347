import React from "react"
import { Route } from "react-router"

import {
  Box, Text, Button, Translate,
} from "src/components"

import ReturnIcon from "./Icon"

const clickHandler = ({ history }) => () => history.goBack()

const HistoryBack = (props) => {
  const { history, ...rest } = props

  return (
    <Button
      height="32px"
      variant="default"
      onClick={clickHandler(history)}
      {...rest}
    >
      <ReturnIcon />
      <Box pl="12px">
        <Text fontSize="14px">
          <Translate i18n="components:accreditation.individual.buttons.return" />
        </Text>
      </Box>
    </Button>
  )
}

export default (props) => (
  <Route>{(history) => <HistoryBack {...props} history={history} />}</Route>
)
