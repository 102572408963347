import React from "react"

import { number } from "src/utils"

const options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

const formatNumber = (str) => {
  if (!str) return ""

  const formatted = str
    .split(",")
    .map((item) => item.replace(/\D/g, ""))
    .join(".")

  const hasDivider = formatted.split(".").length > 1
  const fraction = formatted.split(".")[1]

  if (hasDivider && (!fraction || !Number(fraction))) {
    return `${number(formatted, options)},${fraction.slice(0, 2)}`
  }

  return number(formatted, options)
}

export const useAmount = (balance) => {
  const [value, setValue] = React.useState("")
  const onChange = React.useCallback(
    (e) => {
      const formatted = formatNumber(e.target.value)
      const formattedAsNumber = Number(
        formatted.replace(",", ".").replace(/\s/g, ""),
      )

      if (formattedAsNumber > balance) {
        setValue(formatNumber(String(balance).replace(".", ",")))
        return
      }

      setValue(formatted)
    },
    [balance],
  )

  return [value, onChange]
}

export const useExchangeTargets = (accountBalanceDetailed, baseCurrency) => {
  const [from, setFrom] = React.useState(baseCurrency)

  const targets = React.useMemo(
    () => ({
      from: accountBalanceDetailed.find((item) => item.currency === from),
      to: accountBalanceDetailed.find((item) => item.currency !== from),
    }),
    [from],
  )

  return [targets, setFrom]
}

export const useOnCompleteCallbacks = (items, setActiveModal, hideModal) => React.useMemo(
  () => Object.keys(items).reduce((memo, key) => {
    const cb = () => (items[key].next ? setActiveModal(items[key].next) : hideModal())

    return { ...memo, [key]: cb }
  }, {}),
  [items],
)
