import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { Translate } from "src/components"

export const errorForField = (error) => error && <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />

const StyledLink = styled(Link)`
  font-family: Geometria, sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.greyDarker};

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.greyDarker};
  }
`

export const PasswordTooltip = () => (
  <StyledLink to="/password/reset">
    <Translate i18n="login_form.password-reset-link" ns="components" />
  </StyledLink>
)
