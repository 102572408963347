import React from "react"

import { toast, ToastContainer } from "react-toastify"

import { RelayEnvironmentConsumer } from "src/context"

import { UpdateOrderOffer, UpdateOrderApplication } from "src/mutations"

import { Translate, FormLoadingButton } from "src/components"

import {
  attachmentsParser,
  orderStateFromProps,
  parseAttachmentsData,
} from "src/utils"

import { OrderOfferSchema, OrderApplicationSchema } from "src/constants"

import getStep from "./utils"

import { Container, ContentContainer, ControlsContainer } from "./styles"

const tabs = {
  application: {
    component: getStep(OrderApplicationSchema),
    selector: (order) => order.application,
    action: (callbacks, environment) => (value, orderId) => {
      const application = parseAttachmentsData(value)

      const variables = {
        input: {
          isDraft: true,
          orderId,
          application: JSON.stringify(application),
          attachments: attachmentsParser.list,
        },
      }

      UpdateOrderApplication.commit(environment, variables, null, callbacks)
    },
  },
  offer: {
    component: getStep(OrderOfferSchema),
    selector: (order) => order.offer,
    action: (callbacks, environment) => (value, orderId) => {
      const { offerAttachments, ...rest } = value

      const variables = {
        input: {
          isDraft: true,
          orderId,
          data: JSON.stringify({ ...rest }),
          attachments: offerAttachments,
        },
      }

      UpdateOrderOffer.commit(environment, variables, null, callbacks)
    },
  },
}

const message = (type) => (
  <Translate i18n={`trusted.toast.${type}`} ns="components" />
)

class TrustedOrder extends React.Component<any, any> {
  state = {
    order: { ...orderStateFromProps(this.props.data) },
    busy: undefined,
  }

  save = () => {
    this.setState({ busy: true })

    const activeTab = tabs[this.props.tab]
    const value = activeTab.selector(this.state.order)
    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    activeTab.action(callbacks, this.props.environment)(
      value,
      this.state.order.orderId,
    )
  }

  onCompleted = (data) => {
    let { order } = this.state

    if (data.updateOrderApplication) {
      const { order: node } = data.updateOrderApplication
      order = { ...orderStateFromProps({ node }) }
    }

    this.setState({
      busy: false,
      order,
    })

    toast.success(message("success"), {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  // TODO: разобраться почему не используется ошибка
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError = (error) => {
    this.setState({ busy: false })
    toast.error(message("error"), {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  onChange = (data) => {
    const { tab: step } = this.props

    this.setState((state) => ({
      order: {
        ...state.order,
        [step]: data,
      },
    }))
  }

  render() {
    const activeTab = tabs[this.props.tab]
    const value = activeTab.selector(this.state.order)
    const Step = activeTab.component

    return (
      <>
        <ToastContainer autoClose={4000} hideProgressBar />
        <Container>
          <ContentContainer>
            <Step
              value={value}
              onChange={this.onChange}
              environment={this.props.environment}
            />
          </ContentContainer>
          <ControlsContainer>
            <FormLoadingButton onClick={this.save} isLoading={this.state.busy}>
              <Translate i18n="trusted.buttons.save" ns="components" />
            </FormLoadingButton>
          </ControlsContainer>
        </Container>
      </>
    )
  }
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => <TrustedOrder environment={environment} {...props} />}
  </RelayEnvironmentConsumer>
)
