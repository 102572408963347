/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type GuestOrdersListQueryVariables = {};
export type GuestOrdersListQueryResponse = {
    readonly orders: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly chain: {
                    readonly id: string;
                    readonly gatheredAmount: number;
                    readonly investorsCount: number;
                } | null;
                readonly status: OrderStatusEnum | null;
            };
        }>;
        readonly " $fragmentRefs": FragmentRefs<"GuestOrdersCount_orders">;
    };
    readonly " $fragmentRefs": FragmentRefs<"CompletedGuest_completed">;
};
export type GuestOrdersListQuery = {
    readonly response: GuestOrdersListQueryResponse;
    readonly variables: GuestOrdersListQueryVariables;
};



/*
query GuestOrdersListQuery {
  orders {
    ...GuestOrdersCount_orders
    edges {
      node {
        id
        chain {
          id
          gatheredAmount
          investorsCount
        }
        status
      }
    }
  }
  ...CompletedGuest_completed
}

fragment CompletedGuest_completed on Query {
  completedOrders: orders(filter: {status: [COMPLETE, SUCCEEDED, TRANSFER]}) {
    edges {
      node {
        id
        chain {
          id
          gatheredAmount
          investorsCount
        }
        status
      }
    }
  }
}

fragment GuestOrdersCount_orders on OrderConnection {
  count
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderChainInfo",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gatheredAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "investorsCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GuestOrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GuestOrdersCount_orders"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompletedGuest_completed"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GuestOrdersListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "completedOrders",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "status": [
                "COMPLETE",
                "SUCCEEDED",
                "TRANSFER"
              ]
            }
          }
        ],
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "orders(filter:{\"status\":[\"COMPLETE\",\"SUCCEEDED\",\"TRANSFER\"]})"
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "GuestOrdersListQuery",
    "operationKind": "query",
    "text": "query GuestOrdersListQuery {\n  orders {\n    ...GuestOrdersCount_orders\n    edges {\n      node {\n        id\n        chain {\n          id\n          gatheredAmount\n          investorsCount\n        }\n        status\n      }\n    }\n  }\n  ...CompletedGuest_completed\n}\n\nfragment CompletedGuest_completed on Query {\n  completedOrders: orders(filter: {status: [COMPLETE, SUCCEEDED, TRANSFER]}) {\n    edges {\n      node {\n        id\n        chain {\n          id\n          gatheredAmount\n          investorsCount\n        }\n        status\n      }\n    }\n  }\n}\n\nfragment GuestOrdersCount_orders on OrderConnection {\n  count\n}\n"
  }
};
})();
(node as any).hash = '0a2b5e3537ef276e2401f86ed276590b';
export default node;
