import React from "react"
import i18next from "i18next"
import Relay, { graphql } from "react-relay"

import ConclusionItem from "./elements"
import { ContentContainer } from "../styles"

const formatKarmaConclusion = (karma) => {
  if (!karma || !karma.conclusion) return []

  const result = {
    id: Date.now(),
    isKarma: true,
    opinion: karma.conclusion,
    expert: {
      id: Date.now(),
      name: i18next.t("components:account.order_item.conclusion.karma_expert"),
    },
  }

  return [result]
}

const render = ({ order }) => {
  const { expertOpinions, karma } = order
  const items = [...formatKarmaConclusion(karma), ...expertOpinions]

  return (
    <ContentContainer>
      {items.map((item) => (
        <ConclusionItem key={item.id} conclusion={item} />
      ))}
    </ContentContainer>
  )
}

export default Relay.createFragmentContainer(render, {
  order: graphql`
    fragment Conclusion_order on Order {
      id
      expertOpinions {
        id
        opinion
        expert {
          id
          name
          about
          experience
          avatar {
            id
            url
          }
        }
      }
      karma {
        conclusion
      }
    }
  `,
})
