import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer } from "src/components"
import { divideNumber } from "src/utils"

import BlockLoader from "./BlockLoader"
import {
  Container,
  Header,
  TitleContainer,
  Title,
  HeaderValue,
  Table,
  TRWithBorder,
  TD,
  TDBold,
  ActionContainer,
  RedButton,
  Description,
  ButtonLink,
  RatingDivider,
  RatingContainer,
  RatingLabel,
  RatingTotal,
  RatingRank,
} from "./styles"

function ProfitBlock(props) {
  const {
    node: { investor = {} },
    orders,
  } = props
  const { analytics = {}, rating = {} } = investor

  const {
    interestPercent = 0, interest = 0, penalty = 0, tax = 0,
  } = analytics

  const interestValue = Number(interest) || 0
  const penaltyValue = Number(penalty) || 0
  const taxValue = Number(tax) || 0
  const totalValue = interestValue + penaltyValue - taxValue

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>Доходность</Title>
          <Title>%</Title>
        </TitleContainer>
        <HeaderValue>
          % {divideNumber(Number(interestPercent).toFixed(2))}
        </HeaderValue>
      </Header>

      <RatingContainer>
        <RatingLabel>Ваш рейтинг инвестора</RatingLabel>
        <RatingTotal>
          <RatingRank>{rating?.rank || "-"}</RatingRank> /{" "}
          {rating?.total || "-"}
        </RatingTotal>
      </RatingContainer>

      <RatingDivider />

      <Table>
        <tbody>
          <tr>
            <TD>Процентный доход</TD>
            <TDBold>₽ {divideNumber(interestValue.toFixed(2))}</TDBold>
          </tr>
          <TRWithBorder>
            <TD>Штрафы и пени</TD>
            <TDBold>₽ {divideNumber(penaltyValue.toFixed(2))}</TDBold>
          </TRWithBorder>

          <tr>
            <TD>Доход до налогов</TD>
            <TDBold>
              ₽ {divideNumber((interestValue + penaltyValue).toFixed(2))}
            </TDBold>
          </tr>
          <TRWithBorder>
            <TD>Удержан НДФЛ</TD>
            <TDBold>
              {Number(tax) > 0 && "-"}₽ {divideNumber(taxValue.toFixed(2))}
            </TDBold>
          </TRWithBorder>

          <tr>
            <TD>Чистый доход</TD>
            <TDBold>₽ {divideNumber(totalValue.toFixed(2))}</TDBold>
          </tr>
        </tbody>
      </Table>

      <ActionContainer>
        <Description>Активных заявок: {orders.count}</Description>
        <RedButton>
          <ButtonLink to="/market">Инвестировать</ButtonLink>
        </RedButton>
      </ActionContainer>
    </Container>
  )
}

const query = graphql`
  query ProfitQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on IndividualProfile {
        investor {
          analytics {
            tax
            interestPercent
            interest
            penalty
          }
          rating {
            rank
            total
          }
        }
      }

      ... on EntrepreneurProfile {
        investor {
          analytics {
            tax
            interestPercent
            interest
            penalty
          }
          rating {
            rank
            total
          }
        }
      }

      ... on ForeignIndividualProfile {
        investor {
          analytics {
            tax
            interestPercent
            interest
            penalty
          }
          rating {
            rank
            total
          }
        }
      }

      ... on LegalEntityProfile {
        investor {
          analytics {
            tax
            interestPercent
            interest
            penalty
          }
          rating {
            rank
            total
          }
        }
      }
    }
    orders {
      count
    }
  }
`

function Render(props) {
  const { profileId } = props

  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      query={query}
      render={ProfitBlock}
      renderNull={BlockLoader}
      variables={variables}
      {...props}
    />
  )
}

export default Render
