import React, { useMemo } from "react"

import {
  Box, Text, Flex, Translate,
} from "src/components"

import { ModalShowErrors } from "./constants"

import {
  StyledText, RedButton, ReturnButton, StyledLink,
} from "./styles"

const Render = ({ onComplete, goBack, error: errorProp }) => {
  const error = useMemo(() => {
    const serverError = errorProp?.errors?.find((err) => err.message !== "")
    const returnedError = serverError ? ModalShowErrors.find((show) => show(serverError)) : null
    const errorCode = returnedError ? returnedError(serverError) : { key: "content" }

    return (
      {
        content: `components:investment_modals.error.${errorCode.key}`,
        noRetry: true,
      }
    )
  }, [errorProp])

  return (
    <>
      <ReturnButton onClick={goBack}>Назад</ReturnButton>

      <Box maxWidth="420px" m="auto">
        <Box mb="10px">
          <Text
            color="#4a4a4a"
            fontSize="24px"
            fontWeight="500"
            lineHeight="1.25"
            textAlign="center"
          >
            <Translate i18n="components:investment_modals.error.title" />
          </Text>
        </Box>
        <Box mb="40px">
          <StyledText textAlign="center">
            <Translate i18n={error.content} />
          </StyledText>
        </Box>
        <Flex alignItems="center" flexDirection="column">
          {error.noRetry !== true && (
            <RedButton onClick={onComplete}>
              <Translate i18n="components:investment_modals.error.button" />
            </RedButton>
          )}
          <StyledLink to="/market">
            <RedButton>
              <Translate i18n="components:investment_modals.success.market" />
            </RedButton>
          </StyledLink>
        </Flex>
      </Box>
    </>
  )
}

export default Render
