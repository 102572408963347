import styled, { css } from "styled-components"

import { Flex, Icons } from "src/components"

export const Container = styled(Flex)`
  width: 100%;
  margin-top: 60px;

  @media screen and (max-width: 800px) {
    margin-top: 46px;
    justify-content: space-between;
  }

  @media screen and (max-width: 650px) {
    justify-content: initial;
    align-items: center;
    flex-direction: column;
  }
`

export const Block = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-width: 340px;

  @media screen and (max-width: 650px) {
    max-width: 100%;

    :not(:first-child) {
      margin-top: 60px;
    }
  }
`

export const BlockTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media screen and (max-width: 650px) {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
`

export const BlockDescription = styled(BlockTitle)`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  text-transform: lowercase;
`

export const List = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`

export const Divider = styled.div`
  position: relative;
  width: 1px;
  margin: 63px 30px 0;
  background-color: ${({ theme }) => theme.colors.fullBlacks[2]};

  @media screen and (max-width: 650px) {
    display: none;
  }
`

export const ItemContainer = styled(Flex)`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 60px;
  box-shadow: 0 1px 12px ${({ theme }) => theme.colors.fullBlacks[2]};
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid white;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  :not(:first-child) {
    margin-top: 20px;
  }

  ${({ isCompleted, isClicked }) => !isCompleted
    && !isClicked
    && css`
      :hover {
        border-color: ${({ theme }) => theme.colors.mainRed};
        box-shadow: none;
      }
    `};

  ${({ isClicked }) => isClicked
    && css`
      border-color: ${({ theme }) => theme.colors.primaryBlacks[5]};
      box-shadow: none;
    `};

  ${({ isCompleted }) => isCompleted
    && css`
      box-shadow: none;
      border-color: rgba(111, 168, 75, 0.65);
    `};
`

export const ItemName = styled(Flex)`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primaryBlacks[9]};
  align-items: center;
`

export const InfoIcon = styled(Icons.Info)`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  fill: ${({ theme }) => theme.colors.primaryBlack};
  margin-right: 10px;
  cursor: pointer;
`

export const ItemPoint = styled(ItemName)`
  font-weight: bold;
`

export const SuccessIcon = styled(Icons.Success)`
  width: 19px;
  height: 19px;
  margin-left: 10px;

  @media screen and (max-width: 480px) {
    width: 15px;
    height: 15px;
  }
`

export const ItemButton = styled.button`
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #f70000;
  border: 1px solid #f70000;
  padding: 7px 8px 6px;
  max-height: 26px;
`

export const ButtonContainer = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background: linear-gradient(
    to left,
    white 75%,
    rgba(255, 255, 255, 0.5) 90%,
    transparent
  );
  border-radius: 6px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;

  ${({ isCompleted }) => isCompleted
    && css`
      width: 50%;
    `};

  ${({ isClicked }) => isClicked
    && css`
      width: 60%;

      ${ItemButton} {
        color: ${({ theme }) => theme.colors.primaryBlack};
        border-color: ${({ theme }) => theme.colors.primaryBlacks[5]};
      }
    `};
`
