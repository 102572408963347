import styled from "styled-components"

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 32px 40px 0;
  margin: 32px -40px 0;
`

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 32px 38px;
  border-radius: 5px;
`

export const ContentContainer = styled.div`
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`
