/* eslint-disable react-hooks/rules-of-hooks */
import React from "react"

import { Box, Text, Flex } from "src/components"

import { DeleteIcon, TitleContainer } from "./styles"

const renderDefaultItem = (props) => {
  const {
    idx, item, onClick, onDelete, extractTitle,
  } = props

  const onItemClick = () => typeof onClick === "function" && onClick(item)
  const onItemDelete = () => typeof onDelete === "function" && onDelete(item)

  const title = extractTitle(item)

  return (
    <Flex my="10px" alignItems="center" key={`${title}.${idx}`}>
      <TitleContainer onClick={onItemClick}>
        <Text fontSize="16px">{title}</Text>
      </TitleContainer>
      <DeleteIcon onClick={onItemDelete} />
    </Flex>
  )
}

const render = (props) => {
  const {
    items,
    renderItem,
    onItemClick,
    onItemDelete,
    extractTitle,
    container: Container,
  } = props

  return (
    <Container>
      {items.map((item, idx) => renderItem({
        idx,
        item,
        extractTitle,
        onClick: onItemClick,
        onDelete: onItemDelete,
      }))}
    </Container>
  )
}

render.defaultProps = {
  items: [],
  container: Box,
  extractTitle: (value) => value,
  renderItem: renderDefaultItem,
}

export default React.memo(render)
