import styled, { css } from "styled-components"

import { Icons } from "src/components"

export const Arrow = styled(Icons.LeftArrow)`
  transform: rotate(270deg);
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  margin-left: 12px;
  border-radius: 50%;
  transition: transform 300ms;
  transition-timing-function: "cubic-bezier(0.250, 0.460, 0.450, 0.940);
`

export const Button = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 110px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: left;

  :hover {
    ${Arrow} {
      background-color: ${({ theme }) => theme.colors.fullBlacks[1]};
    }
  }
`

export const Content = styled.div`
  white-space: pre-line;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 110px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fullBlacks[1]};
  transition: padding 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.1, 0.95);

  ${({ isOpen }: any) => isOpen
    && css`
      padding-bottom: 32px;
      ${Arrow} {
        transform: rotate(90deg);
      }
    `};
`
