import React from "react"
import styled from "styled-components"

import {
  Box, Icon, Flex, Text, Tooltip, Translate,
} from "src/components"

import { renderCollateralInput } from "./elements"

// ok, looks like all previous switch input type scheme entries are built with knowledge about wrong positioning
// so I'll just apply this fix. Hello to Dmitry. Don't blame me ¯\_(ツ)_/¯
const SwitchFix = styled.div`
  transform: translate(-15px, 5px);
`

const InfoIconContainer = styled.div`
  padding-left: 9px;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }
`

const FileButtonBox = styled(Box)`
  max-width: 195px;
`

const InfoIcon = (
  <InfoIconContainer>
    <Icon.info />
  </InfoIconContainer>
)

const SwitchDecorator = (props) => (
  <Flex justifyContent="space-between" alignItems="center">
    <Flex mr="15px">
      <Translate i18n={props.label} />
      {props.tooltip && (
        <Tooltip alignLeft text={props.tooltip} icon={() => InfoIcon} />
      )}
    </Flex>
    <SwitchFix>{props.children}</SwitchFix>
  </Flex>
)

const FileDecorator = (props) => (
  <Flex my="20px" alignItems="center" justifyContent="space-between">
    <Box mr="15px">
      <Translate i18n={props.label} />
    </Box>
    <FileButtonBox>{props.children}</FileButtonBox>
  </Flex>
)

const ListDecorator = (props) => {
  if (props.hidden) return null

  return (
    <Box mt="-30px" mb="10px">
      {props.listTitle && (
        <Text fontSize="14px" color="greyDark">
          <Translate i18n={props.listTitle} />
        </Text>
      )}
      <Box my="10px">{props.children}</Box>
    </Box>
  )
}

const CurrencyTooltip = React.memo(() => (
  <Text fontSize="20px" color="greyShuttle">
    ₽
  </Text>
))

const PercentageTooltip = React.memo(() => (
  <Text fontSize="20px" color="greyShuttle">
    %
  </Text>
))

const TermTooltip = React.memo(() => (
  <Text color="greyShuttle">
    <Translate i18n="modals:express-scoring.form.tooltips.term" />
  </Text>
))

export default {
  groups: [
    {
      title: "modals:express-scoring.form.inputs.company",
      rows: [
        {
          width: "470px",
          inputs: [
            {
              type: "text",
              name: "inn",
              mask: "999999999999",
              maskChar: null,
              label: "modals:express-scoring.form.inputs.inn",
            },
            {
              type: "text",
              name: "name",
              label: "modals:express-scoring.form.inputs.name",
            },
            {
              type: "text",
              name: "site",
              label: "modals:express-scoring.form.inputs.site",
            },
            {
              type: "attachments_button",
              name: "presentation",
              label: "modals:express-scoring.form.inputs.presentation",
              decorator: FileDecorator,
            },
            {
              type: "switch",
              name: "isBelongToGroup",
              label: "modals:express-scoring.form.inputs.isBelongToGroup",
              decorator: SwitchDecorator,
            },
          ],
        },
        {
          width: "470px",
          inputs: [
            {
              type: "list",
              label: "modals:express-scoring.form.inputs.inn",
              min: 1,
              max: 10,
              name: "groupInns",
              mask: "999999999999",
              maskChar: null,
              listTitle: "modals:express-scoring.form.inputs.groupInns",
              decorator: ListDecorator,
            },
          ],
          deps: [
            {
              propName: "hidden",
              compared: "isBelongToGroup",
              conditionValue: false,
            },
          ],
        },
      ],
    },
    {
      title: "modals:express-scoring.form.inputs.loan",
      rows: [
        {
          width: "470px",
          inputs: [
            {
              type: "text",
              name: "amount",
              tooltip: CurrencyTooltip,
              label: "modals:express-scoring.form.inputs.amount",
              directErrorPath: "orderData.amount",
            },
            {
              type: "text",
              name: "term",
              tooltip: TermTooltip,
              label: "modals:express-scoring.form.inputs.term",
              directErrorPath: "orderData.term",
            },
            {
              type: "text",
              name: "interest",
              tooltip: PercentageTooltip,
              label: "modals:express-scoring.form.inputs.interest",
              directErrorPath: "orderData.interest",
            },
            {
              type: "phone",
              name: "phone",
              mask: "+7 (999) 999-99-99",
              label: "modals:express-scoring.form.inputs.phone",
            },
            {
              type: "switch",
              name: "hasCollateral",
              // eslint-disable-next-line
              tooltip: "modals:express-scoring.form.tooltips.hasCollateral",
              label: "modals:express-scoring.form.inputs.hasCollateral",
              decorator: SwitchDecorator,
              directErrorPath: "orderData.hasCollateral",
            },
          ],
        },
        {
          width: "470px",
          inputs: [
            {
              type: "list",
              name: "collaterals",
              min: 1,
              max: 10,
              decorator: ListDecorator,
              renderInput: renderCollateralInput,
              initialItem: { value: 0, kind: "ESTATE" },
            },
          ],
          deps: [
            {
              propName: "hidden",
              compared: "hasCollateral",
              conditionValue: false,
            },
          ],
        },
      ],
    },
  ],
}
