import React from "react"

import {
  Container,
  Illustration,
  Title,
  Description,
  ButtonLink,
  TelegramIcon,
} from "./styles"

function NullStateScreen() {
  return (
    <Container>
      <Illustration />
      <Title>Ура! Инвесторы быстро разобрали все заявки 😃</Title>
      <Description>
        Подпишитесь на канал инвесторов в Телеграм, чтобы как можно раньше
        узнавать о новых заявках
      </Description>
      <ButtonLink href="https://t.me/karmainvest" target="_blank">
        <TelegramIcon />
        Подписаться
      </ButtonLink>
    </Container>
  )
}

export default NullStateScreen
