import React from "react"

import { ModalContainer, Icons } from "src/components"

import {
  Container, CloseButton, Title, ReturnButton, InfoContainer, StyledText, Button,
} from "./styles"

const SellOrderModal = (props) => {
  const {
    onClose, isOpened, order, onSubmit,
  } = props
  const discount = order?.karma?.purchase?.discount || 0

  return (
    <ModalContainer isOpened={isOpened} onClose={onClose}>
      <Container>
        <ReturnButton onClick={onClose}>
          <Icons.LeftArrow />
          <span>Назад</span>
        </ReturnButton>

        <CloseButton onClick={onClose}>
          <Icons.Close />
        </CloseButton>

        <Title>Продажа долга</Title>

        <InfoContainer>
          <StyledText>Скидка: {discount}%</StyledText>
        </InfoContainer>

        <Button onClick={onSubmit}>Продать</Button>
      </Container>
    </ModalContainer>
  )
}

export default SellOrderModal
