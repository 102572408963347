import React from "react"
import { StatusbarContainer, StatusBarFilled } from "./styles"

function Statusbar(props) {
  const {
    value, orange, margin, width,
  } = props

  return (
    <StatusbarContainer margin={margin} width={width}>
      <StatusBarFilled value={value} orange={orange} />
    </StatusbarContainer>
  )
}

export default Statusbar
