import { graphql } from "react-relay"

const fragment = graphql`
  fragment InternationalIndividualFragment_profile on Node {
    ... on ForeignIndividualProfile {
      __typename
      id
      firstName
      lastName
      middleName
      tin
      isRussiaTaxResident
      hasUSACitizenship
      internationalPhone: phone
      documents {
        type
        attachments {
          id
          url
          filename
        }
      }
      translatedDocuments {
        id
        url
        filename
      }
      selfie {
        id
        url
        filename
      }
      taxResidencyApplication {
        id
        filename
        url
        size
        signature {
          url
        }
      }
      migrationCard {
        number
        dateFrom
        dateTo
        attachments {
          id
          url
          filename
        }
      }
      innDocument {
        id
        url
        filename
      }
      residenceDocument {
        id
        url
        filename
      }
      territoryResidenceDocument {
        attachments {
          id
          url
          filename
        }
        type
        series
        number
        dateFrom
        dateTo
      }
      registrationAddress {
        ... on Address {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
          raw
        }
      }
    }
  }
`

export default fragment
