import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ViewerTrustedOrdersListQuery {
    viewer {
      ... on User {
        id
        trust {
          isAvailable
          orders {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export default (render) => (props) => (
  <QueryRenderer {...props} query={query} render={render} />
)
