import React from "react"
import memoize from "memoize-one"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import styled, { css } from "styled-components"

import { Text, Loader, Translate } from "src/components"

const Label = memoize(() => (
  <Text whiteSpace="nowrap">
    <Translate
      i18n="accreditation.individual.rows.passport_scan.hints.upload_label"
      ns="components"
    />
  </Text>
))

const loadingStyles = ({ theme }) => css`
  background-color: ${theme.colors.greyLight};
  cursor: not-allowed;

  &:hover {
    background-color: ${theme.colors.greyLight};
  }
`

const Container = styled(Dropzone)<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 16px;
  border: 1px dashed ${({ theme }) => theme.colors.greyLight};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }

  ${({ isloading }) => isloading && loadingStyles}
`

const render = (props) => {
  const {
    accept, onDrop, multiple, isLoading,
  } = props

  return (
    <Container
      accept={accept}
      onDrop={(e) => !isLoading && onDrop(e)}
      multiple={multiple}
      isloading={isLoading}
      disabled={isLoading}
    >
      {isLoading ? <Loader fontSize="4px" /> : <Label />}
    </Container>
  )
}

render.propTypes = {
  accept: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default render
