import React from "react"

import { parseOrdersData } from "src/utils"
import { OrderFactory } from "src/components"
import { OrdersListContainer } from "src/containers"
import { ViewerOrdersListQueryContainer } from "src/query"

import StatusScreens from "../../StatusScreens"

const Loans = () => (
  <OrdersListContainer.list
    queryContainer={ViewerOrdersListQueryContainer}
    emptyView={() => <StatusScreens.loans.EmptyList />}
    adapter={parseOrdersData}
    itemPresentation={OrderFactory.createLoan}
  />
)

export default Loans
