import React from "react"

import { divideNumber } from "../../organisms/ProposalSpecification/Specifications/utils"

import { Block } from "./Elements"

import {
  Container,
  Header,
  Divider,
  BlockWrapper,
  ButtonWrapper,
  Button,
  ResultWrapper,
  ResultTitle,
  ResultValue,
  Body,
  DescriptionWrapper,
  DescriptionTitle,
  DescriptionValue,
  DescriptionTooltip,
  DescriptionLink,
  TabletDescriptionTooltip,
} from "./styles"

function ProposalPastduePayment(props) {
  const { item, info } = props

  return (
    <Container>
      <Header>Вы нарушили график выплаты займа</Header>
      <Divider />

      <DescriptionWrapper>
        <DescriptionTitle>
          Вам необходимо незамедлительно произвести оплату долга в размере
        </DescriptionTitle>
        <DescriptionValue>{`${divideNumber(
          item.loan.toFixed(2),
        )} ₽`}</DescriptionValue>
        <DescriptionTooltip>
          Если вы уже произвели оплату свяжитесь{" "}
          <DescriptionLink href="/">со службой поддержки</DescriptionLink>
        </DescriptionTooltip>
      </DescriptionWrapper>

      <Body>
        <BlockWrapper>
          <Block
            title="Просроченный основной долг"
            value={`${divideNumber(item.loan.toFixed(2))} ₽`}
          />
          <Block
            title="Просроченные проценты"
            value={`${divideNumber(item.interest.toFixed(2))} ₽`}
          />
          <Block
            title="Неустойка"
            value={`${divideNumber(info.penalty.toFixed(2))} ₽`}
          />
        </BlockWrapper>

        <ButtonWrapper>
          <ResultWrapper>
            <ResultTitle>Итого:</ResultTitle>
            <ResultValue>{`${divideNumber(
              item.total.toFixed(2),
            )} ₽`}</ResultValue>
          </ResultWrapper>
          <Button onClick={null}>ПОПОЛНИТЬ СЧЕТ</Button>
        </ButtonWrapper>
      </Body>

      <TabletDescriptionTooltip>
        Если вы уже произвели оплату свяжитесь{" "}
        <DescriptionLink href="/">со службой поддержки</DescriptionLink>
      </TabletDescriptionTooltip>
    </Container>
  )
}

export default ProposalPastduePayment
