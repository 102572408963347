import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation ExchangeCurrencyMutation($input: ExchangeCurrencyInput!) {
    exchangeCurrency(input: $input) {
      status
    }
  }
`
export default enhance({ mutation })
