import React from "react"

import { FloatingLabelInput, BankDataSuggestion } from "src/components"
import { preventEventBubbling } from "src/utils"
import { IInputProps } from "../index"

const BankAutosuggestInput: React.FC<IInputProps> = (props) => {
  const {
    name, onChange, hasError, error, mask, label,
  } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    preventEventBubbling(event)

    const { name: inputName, value } = event.target

    onChange([{
      name: inputName,
      value,
    },
    ])
  }

  function handleSuggestionSelected(suggestion: any) {
    const {
      bic,
      name: nameData,
      correspondent_account: correspondentAccount,
    } = suggestion.data

    onChange([{
      name: "bankName",
      value: nameData.payment,
    },
    {
      name: "bic",
      value: bic,
    },
    {
      name: "correspondentAccount",
      value: correspondentAccount || "",
    },
    ])
  }

  const daDataSelector = name === "bankName" ? (suggestion) =>
    suggestion.data.name.payment : (suggestion) => suggestion.data.bic

  return (
    <BankDataSuggestion
      name={name}
      label={label}
      hasError= {hasError}
      error={error}
      value={props.value || ""}
      onChange={handleChange}
      mask={mask}
      daDataSelector={daDataSelector}
      onSuggestionSelected={handleSuggestionSelected}
      renderInputComponent={(inputProps) => (
        <FloatingLabelInput {...inputProps} ref={inputProps.ref} />
      )}
    />
  )
}

export default BankAutosuggestInput
