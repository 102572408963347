import styled, { css } from "styled-components"

export const Container = styled.div<any>`
  background-color: ${({ open, theme }) => (open ? theme.colors.greyLight : "transparent")};
  border-left: ${({ theme }) => `1px solid ${theme.colors.brownDarkest}`};
  position: relative;
  width: 90px;
  z-index: 100;
`

export const CurrencyList = styled.ul<any>`
  background-color: ${({ theme }) => theme.colors.greyDarkest};
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  z-index: 1;

  ${({ align }) => align === "left" && "left: 0"};
  ${({ align }) => align === "right" && "right: 0"};

  & > li {
    border-top: 1px solid ${({ theme }) => theme.colors.greyDarkest};
  }
`

export const Tooltip = styled.div`
  left: 0;
  height: 88px;
  bottom: -100px;
  display: flex;
  padding: 24px 22px;
  position: absolute;
  align-items: flex-start;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.greyDark};

  &:before {
    position: absolute;
    top: -18px;
    width: 0;
    height: 0;
    content: "";
    border-style: solid;
    border-width: 0 9px 18px 9px;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.greyDark} transparent;
  }
`

export const DateText = styled.span`
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grey};
`

export const RateMessage = styled.span`
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`

/* eslint-disable no-confusing-arrow */
const activeTooltipStyles = css`
  ${({ tooltipIsVisible }: any) => tooltipIsVisible
    ? ` &:before {
          left: 0;
          height: 100px;
          width: 200px;
          position: absolute;
          content: "";
          z-index: 1;
          bottom: -100px;
          background-color: transparent;
        }`
    : ""}
`

export const CurrencyButton = styled.button<any>`
  position: relative;
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${({ open, theme }) => open ? theme.colors.black : theme.colors.white};
  cursor: pointer;
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  height: 60px;
  width: 90px;

  &:hover,
  &:active {
    background-color: #474d58;
  }

  & > * + * {
    margin-left: 5px;
  }

  ${activeTooltipStyles}
`
