import i18n from "src/i18n"
import React from "react"

import { Box, Modal, RedirectButton } from "src/components"

const renderProfile = (props) => (profile) => (
  <Box mt={20} key={profile.id}>
    <Box mb={10}>{profile.name}</Box>
    <RedirectButton
      to={`/profiles/${profile.id}/accreditation/borrower`}
      onClick={props.onClose}
    >
      {i18n.t(
        "components:user-page-header.modals.approve-legal-entity-profile.button",
      )}
    </RedirectButton>
  </Box>
)

const render = (props) => {
  /* eslint-disable no-underscore-dangle */
  const profiles = props.user.profiles.filter(
    (processedProfile) => processedProfile.__typename === "LegalEntityProfile",
  )

  return (
    <Modal.Basic onClose={props.onClose}>
      <Box>
        {i18n.t(
          "components:user-page-header.modals.approve-legal-entity-profile.description",
        )}
      </Box>
      {profiles.map(renderProfile(props))}
    </Modal.Basic>
  )
}

export default render
