import React from "react"
import PropTypes from "prop-types"

import { Icon } from "src/components"

import {
  Container, Clickable, AttachmentLink, ListContainer,
} from "./styles"

const render = ({
  url, name, preview, onRemoveRequest,
}) => (
  <Container>
    <Icon>document</Icon>
    <ListContainer>
      <AttachmentLink href={url || preview} target="_blank">
        {name}
      </AttachmentLink>
    </ListContainer>
    <Clickable onClick={onRemoveRequest}>
      <Icon>close</Icon>
    </Clickable>
  </Container>
)

render.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  preview: PropTypes.string,
}

export default render
