import Loan from "./Loan"
import Proposal from "./Proposal"
import Investment from "./Investment"

const render = () => null

render.loan = Loan
render.proposal = Proposal
render.investment = Investment

export default render
