import React from "react"
import { fetchQuery, graphql } from "relay-runtime"

import { useEnvironment } from "src/hooks"

import { Box, Translate } from "src/components"

import { SubHeader, StyledText } from "./styles"
import { KarmaPaymentRequisitesQueryResponse } from "./__generated__/KarmaPaymentRequisitesQuery.graphql"

const query = graphql`
  query KarmaPaymentRequisitesQuery {
    localizedStrings {
      karmaPaymentRequisites {
        key
        name
        value
      }
    }
  }
`

const RowItem = React.memo((props: any) => {
  const { name, label, value } = props

  return (
    <>
      <Translate i18n={name || label} />
      :&nbsp;
      {typeof value === "string" ? <Translate i18n={value} /> : value}
      <br />
    </>
  )
})

const formatPaymentReason = (reason) => {
  if (React.isValidElement(reason)) {
    return {
      name:
        "components:account.bank_account.confirm.karma_requisites.payment_target.label",
      value: reason,
    }
  }

  return reason
}

const KarmaPaymentRequisites = (props) => {
  const { reason } = props

  const { environment } = useEnvironment()
  const [schema, setSchema] = React.useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [busy, setBusy] = React.useState(true)
  const formattedReason = React.useMemo(() => formatPaymentReason(reason), [
    reason,
  ])

  React.useEffect(() => {
    let didCancle = false
    fetchQuery(environment, query, {})
      .then((data: KarmaPaymentRequisitesQueryResponse) => {
        if (!didCancle) {
          setBusy(false)
          setSchema(data.localizedStrings.karmaPaymentRequisites)
        }
      })
      .catch(() => {
        if (!didCancle) {
          setBusy(false)
        }
      })
    return () => {
      didCancle = true
    }
  }, [])

  if (!schema && !reason) return null

  return (
    <>
      <Box>
        <SubHeader>
          <Translate
            ns="components"
            i18n="account.bank_account.confirm.karma_requisites.title"
          />
        </SubHeader>
      </Box>
      <Box mb="13px">
        <StyledText>
          {!!schema && schema.map(
            (item, idx) => idx < 6 && <RowItem key={idx} {...item} />,
          )}
          {!!reason && reason && Array.isArray(reason) ? (
            reason.map((item, idx) => <RowItem key={idx} {...item} />)
          ) : (
            <RowItem {...formattedReason} />
          )}
        </StyledText>
      </Box>
    </>
  )
}

export default KarmaPaymentRequisites
