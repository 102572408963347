import Slider from "react-slick"

import styled from "styled-components"
import { width, height } from "styled-system"

export const Container = styled.div<any>`
  ${width}
  ${height}
  display: flex;
  align-items: center;
  margin: 0 auto;
`

export const Slide = styled.img<any>`
  object-fit: contain;
  ${width}
  ${height}
`

export const StyledSlider = styled(Slider)`
  width: 100%;
  height: 100%;

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    opacity: 0.75;
    :hover {
      opacity: 0.9;
    }
  }

  .slick-next {
    right: -40px;

    @media (max-width: 480px) {
      right: 5px;
    }
  }

  .slick-prev {
    left: -40px;
    z-index: 1;

    @media (max-width: 480px) {
      left: 5px;
    }
  }

  .slick-dots {
    bottom: -60px;

    @media (max-width: 480px) {
      bottom: 0px;
      background-color: ${({ theme }) => theme.colors.whites[1]};
      padding: 10px;
    }

    li {
      @media (max-width: 480px) {
        width: 9%;
      }
    }

    ul {
      padding: 0;
    }

    li button:before {
      color: ${({ theme }) => theme.colors.white};
      font-size: 2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
