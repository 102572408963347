import React from "react"

import {
  Box, Flex, Translate, DefaultModal,
} from "src/components"

import { StyledArrowLink, ActionContainer } from "./styles"

import { Title, LoginButton, RegisterButton } from "./elements"

const Modal = ({ onClose }) => (
  <DefaultModal onClose={onClose}>
    <Box width="520px">
      <Title />
      <Flex alignItems="center" flexDirection="column">
        <RegisterButton />
        <LoginButton />
      </Flex>
    </Box>
  </DefaultModal>
)

Modal.defaultProps = {
  onClose: () => {},
}

class GuestOrderAction extends React.PureComponent {
  static modal

  state = { modal: false }

  onModalOpen = () => {
    this.setState({ modal: true })
  }

  onModalClose = () => {
    this.setState({ modal: false })
  }

  render() {
    return (
      <>
        <ActionContainer onClick={this.onModalOpen}>
          <StyledArrowLink variant="blue" href={null}>
            <Translate i18n="components:form_builder.actions.view" />
          </StyledArrowLink>
        </ActionContainer>
        {this.state.modal && <Modal onClose={this.onModalClose} />}
      </>
    )
  }
}

GuestOrderAction.modal = Modal

export default GuestOrderAction
