/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderBannersQueryVariables = {};
export type OrderBannersQueryResponse = {
    readonly banners: ReadonlyArray<{
        readonly id: string;
        readonly link: string;
        readonly images: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly size: number;
            readonly width: number | null;
        }>;
        readonly description: string | null;
        readonly active: boolean;
    }>;
};
export type OrderBannersQuery = {
    readonly response: OrderBannersQueryResponse;
    readonly variables: OrderBannersQueryVariables;
};



/*
query OrderBannersQuery {
  banners(filter: {active: true}) {
    id
    link
    images {
      id
      url
      size
      width
    }
    description
    active
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "filter",
        "value": {
          "active": true
        }
      }
    ],
    "concreteType": "Banner",
    "kind": "LinkedField",
    "name": "banners",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Attachment",
        "kind": "LinkedField",
        "name": "images",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      }
    ],
    "storageKey": "banners(filter:{\"active\":true})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderBannersQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrderBannersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "OrderBannersQuery",
    "operationKind": "query",
    "text": "query OrderBannersQuery {\n  banners(filter: {active: true}) {\n    id\n    link\n    images {\n      id\n      url\n      size\n      width\n    }\n    description\n    active\n  }\n}\n"
  }
};
})();
(node as any).hash = '2b66e5aaadfc23b815bedd92720eb9f9';
export default node;
