import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer, CustomTooltip } from "src/components"

import { PATH } from "../../../molecules/PortfolioMenus/constants"

import BlockLoader from "./BlockLoader"
import { CoinIcon } from "./icons"
import {
  Container,
  Header,
  TitleContainer,
  Title,
  HeaderValue,
  Divider,
  Table,
  TD,
  TDBold,
  ActionContainer,
  OutlinedButton,
  InfoIcon,
  TooltipContainer,
} from "./styles"
import TooltipTexts from "./TooltipTexts.json"
import { getLevel, getRiskLevel } from "./utils"

const diversificationLevels = ["Низкая", "Средняя", "Высокая", "Очень высокая"]
const riskLevels = ["Низкий", "Средний", "Высокий", "Очень высокий"]

function RiskBlock(props) {
  const { history, node } = props
  const { accountBalanceDetailed, investor } = node

  const {
    investments: { count, edges },
  } = investor

  const { invested = 0 } = accountBalanceDetailed[0] || {}

  const diversification = getLevel(count)
  const diversificationRisk = diversificationLevels.length - 1 - diversification
  const risk = getRiskLevel(invested, edges)

  const totalLevel = Math.max(diversificationRisk, risk)

  return (
    <Container>
      <Header>
        <TitleContainer>
          <Title>Уровень риска</Title>
          <CoinIcon />
        </TitleContainer>
        <HeaderValue>{count > 0 ? riskLevels[totalLevel] : "-"}</HeaderValue>
      </Header>

      <Divider />

      <Table>
        <tbody>
          <tr>
            <TD>
              Диверсификация
              <CustomTooltip
                placement="top"
                overlay={
                  <TooltipContainer>
                    {TooltipTexts.diversification}
                  </TooltipContainer>
                }
              >
                <span>
                  <InfoIcon />
                </span>
              </CustomTooltip>
            </TD>
            <TDBold>{diversificationLevels[diversification]}</TDBold>
          </tr>

          <tr>
            <TD>
              Max риск на заемщика
              <CustomTooltip
                placement="top"
                overlay={
                  <TooltipContainer>{TooltipTexts.risk}</TooltipContainer>
                }
              >
                <span>
                  <InfoIcon />
                </span>
              </CustomTooltip>
            </TD>
            <TDBold>{riskLevels[risk]}</TDBold>
          </tr>
        </tbody>
      </Table>

      <ActionContainer>
        <OutlinedButton onClick={() => history.push(PATH.INVESTMENTS)}>
          Мой портфель
        </OutlinedButton>
      </ActionContainer>
    </Container>
  )
}

const query = graphql`
  query RiskQuery($id: ID!) {
    node(id: $id) {
      ... on UserProfile {
        id
        __typename
        accountBalanceDetailed {
          invested
        }
        ... on IndividualProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  loan
                  history {
                    type
                    amount
                  }
                }
              }
            }
          }
        }
        ... on EntrepreneurProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  loan
                  history {
                    type
                    amount
                  }
                }
              }
            }
          }
        }
        ... on ForeignIndividualProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  loan
                  history {
                    type
                    amount
                  }
                }
              }
            }
          }
        }
        ... on LegalEntityProfile {
          investor {
            investments(filter: { status: [COMPLETE, DEFAULT] }) {
              count
              edges {
                node {
                  amount
                  loan
                  history {
                    type
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function Render(props) {
  const { profileId } = props

  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      query={query}
      render={RiskBlock}
      renderNull={BlockLoader}
      variables={variables}
      {...props}
    />
  )
}

export default Render
