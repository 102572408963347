import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { CardWrapper, OutlinedButton } from "src/components"

import { InfoIcon } from "./icons"

export const Container = styled(CardWrapper)`
  padding: 20px;
  width: 100%;
  margin-top: 20px;
  font-family: Geometria;
  color: #4a4a4a;
`

export const Title = styled.h6`
  font-weight: 300;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 0;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`

export const Value = styled.p`
  font-weight: 900;
  font-size: 1.75rem;
  color: #5f5f5f;
  margin: 0;
  line-height: 1.5;
`

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

export const Text = styled.p`
  margin: 0;
  font-size: 0.75rem;
  line-height: 150%;

  @media screen and (max-width: 800px) {
    font-size: 1.125rem;
  }
`

export const ValueInner = styled.div`
  position: relative;
  margin-top: 1.125rem;
`

export const ButtonContainer = styled(FlexContainer)`
  margin-top: 32px;
  position: relative;
  width: 100%;
`

export const Button = styled(OutlinedButton)`
  width: 48%;
  padding: 16.5px 0 13.5px;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
    padding: 22px 0 20px;
  }
`

export const PrimaryButton = styled(Button)`
  border: 1px solid #f70000;
  color: #f70000;
`

export const CirculationContainer = styled(FlexContainer)`
  margin-top: 20px;
`

export const Icon = styled(InfoIcon)`
  width: 13px;
  height: 14px;
  padding-bottom: 1px;
  vertical-align: sub;
  cursor: pointer;
  margin-left: 4px;
  fill ${({ theme, alert }) => (alert && theme.colors.mainRed) || theme.colors.textBlacks[7]};
  
  @media screen and (max-width: 800px) {
    width: 18px;
    height: 19px;
  }
`

export const Alert = styled.div`
  position: relative;
  margin-top: 4px;
  width: 100%;
`

export const Link = styled(NavLink)`
  text-decoration: underline;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.mainRed};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 1.125rem;
  }
`

export const AlertText = styled(Text)`
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.mainRed};
  margin: 0;
`
