import React from "react"
import styled from "styled-components"

import {
  Box, Text, Alert, Translate, ArrowLink,
} from "src/components"
import { userAgreementUrl } from "src/constants"

const Container = styled.div`
  width: 280px;
`

const StyledArrowLink = styled(ArrowLink)`
  color: ${({ theme }) => theme.colors.blue};
`

const InfoMessage = React.memo(() => (
  <Container>
    <Alert p="25px" bg="greyLighter" textAlign="left">
      <Text fontSize="14px" lineHeight="1.43" color="greyDarkest">
        <Translate i18n="components:account.order_item.payment_schedule.info.debtor" />
      </Text>
      <Box mt="15px">
        <StyledArrowLink variant="blue" href={userAgreementUrl} target="_blank">
          <Translate i18n="components:account.order_item.payment_schedule.info.button" />
        </StyledArrowLink>
      </Box>
    </Alert>
  </Container>
))

export default InfoMessage
