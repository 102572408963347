import styled from "styled-components"

export const BannerContainer = styled.div`
  z-index: 30;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  overflow: hidden;
  position: fixed;
  left: 0;
  line-height: 56px;
  background-color: #b8dce8;
  border-bottom: 1px solid #d9dee2;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const StyledLink = styled.a`
  margin-right: 31px;
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.red};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const LeadingRibbon = styled.img`
  z-index: 1;
  height: 100%;
  margin-left: 5px;
`

export const BallContainer = styled.div`
  width: 115px;
  position: relative;
  background-image: url(/banners/investor/ribbon2.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;

  img {
    width: 48px;
  }
`
