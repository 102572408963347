import React, { useEffect, useContext } from "react"
import Relay, { graphql } from "react-relay"

import {
  ProfileForm,
  TransactionsHistory,
  BankAccounts,
  ProfileTaxReports,
} from "src/components"
import { CurrentProfileContext } from "src/context"

import { TAB } from "../../molecules/ProfileMenu/constants"

import { Container, ReturnButton } from "./styles"

function ProfileData(props) {
  const {
    tab,
    profile,
    foreignProfile,
    match,
    history,
    handleReturn,
  } = props

  const currentProfile = useContext(CurrentProfileContext) as any
  const { id: userId } = currentProfile.profile

  useEffect(() => {
    if (userId !== match.params.id) {
      history.push(`/accounts/${userId}/${history.location.search}`)
    }
  }, [userId])

  return (
    <Container tab={tab}>
      <ReturnButton onClick={handleReturn}>{"< Назад"}</ReturnButton>

      {(tab === TAB.INFORMATION || tab === "") && (
        <ProfileForm profile={profile} history={history} />
      )}

      {tab === TAB.HISTORY && <TransactionsHistory />}

      {tab === TAB.BANK && (
        <BankAccounts profile={profile} foreignProfile={foreignProfile} />
      )}

      {tab === TAB.TAXES && (
        <ProfileTaxReports
          currentProfile={currentProfile.profile}
          profile={profile}
        />
      )}
    </Container>
  )
}

export default Relay.createFragmentContainer(ProfileData, {
  profile: graphql`
    fragment ProfileData_profile on UserProfile {
      ...ProfileFormFragment_profile
      ...AccreditationInfoFragment_profile
      ...BankAccounts_profile
    }
  `,
})
