import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateBankAccountMutation($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      account {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  }
`
export default enhance({ mutation })
