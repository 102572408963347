import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestProfileAccreditationMutation(
    $input: RequestProfileAccreditationInput!
  ) {
    requestProfileAccreditation(input: $input) {
      profile {
        id
        ...AccreditationInfoFragment_profile
      }
    }
  }
`

export default enhance({ mutation })
