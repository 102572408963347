/* eslint-disable max-len */

import React from "react"

function Key(props) {
  return (
    <svg {...props} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.85093 0C5.61455 0 3.7951 1.81945 3.7951 4.05584C3.7951 4.55302 3.88343 5.03616 4.05786 5.49497L0.179794 9.37331C0.124364 9.42877 0.0932159 9.50393 0.0932159 9.5823V11.7044C0.0932159 11.8677 0.225544 12 0.388786 12H2.1376C2.2159 12 2.291 11.9689 2.34643 11.9136L2.93576 11.3252C2.99131 11.2697 3.02251 11.1945 3.02251 11.116V10.3923H3.74525C3.90849 10.3923 4.04082 10.26 4.04082 10.0968V9.80707H4.33053C4.49375 9.80707 4.6261 9.67474 4.6261 9.5115V8.8755H5.2621C5.34047 8.8755 5.41564 8.84435 5.47109 8.78892L6.41143 7.84859C6.87031 8.02317 7.35352 8.11163 7.85089 8.11163C10.0873 8.11163 11.9067 6.29217 11.9067 4.05579C11.9067 1.81945 10.0873 0 7.85093 0ZM7.85093 7.52048C7.36714 7.52048 6.89923 7.42277 6.46018 7.23002C6.34665 7.18019 6.21879 7.20762 6.13508 7.2892C6.13416 7.29007 6.13327 7.29077 6.13238 7.29169L5.13971 8.28436H4.33058C4.16733 8.28436 4.03501 8.41669 4.03501 8.57993V9.21593H3.74529C3.58208 9.21593 3.44972 9.34826 3.44972 9.5115V9.80119H2.72696C2.56374 9.80119 2.43139 9.93352 2.43139 10.0968V10.9934L2.0153 11.4088H0.684403V10.9912L4.29708 7.3785C4.40098 7.2746 4.40098 7.10618 4.29708 7.00231C4.19318 6.89843 4.02481 6.89838 3.92084 7.00231L0.684403 10.2388V9.70477L4.61478 5.77411C4.61567 5.77322 4.61635 5.7724 4.61717 5.77153C4.69878 5.68788 4.72629 5.56003 4.67647 5.44641C4.4839 5.00738 4.38631 4.53949 4.38631 4.05586C4.38631 2.14542 5.94057 0.591164 7.851 0.591164C9.76144 0.591164 11.3157 2.1454 11.3157 4.05586C11.3156 5.96625 9.76133 7.52048 7.85093 7.52048Z" />
      <path d="M9.08527 1.71429C8.47479 1.71429 7.97815 2.21094 7.97815 2.82141C7.97815 3.43189 8.47479 3.92853 9.08527 3.92853C9.69574 3.92853 10.1924 3.43189 10.1924 2.82141C10.1924 2.21094 9.69577 1.71429 9.08527 1.71429ZM9.08527 3.33739C8.80076 3.33739 8.56929 3.10592 8.56929 2.82141C8.56929 2.5369 8.80076 2.30544 9.08527 2.30544C9.36977 2.30544 9.60124 2.5369 9.60124 2.82141C9.60124 3.10592 9.36977 3.33739 9.08527 3.33739Z" />
    </svg>
  )
}

Key.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Key
