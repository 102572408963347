import React, { useContext } from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"
import { CurrentProfileContext } from "src/context"

const ViewerInfoQuery = graphql`
  query AccountInfoNullQuery {
    viewer {
      id
      country
      profiles {
        id
        __typename
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
          paymentApprovalDocument {
            url
          }
        }
        ... on IndividualProfile {
          name
          inn
          investorAccreditation: accreditation(role: INVESTOR) {
            status
            declineReason
          }
        }
        ... on EntrepreneurProfile {
          name
          inn
          investorAccreditation: accreditation(role: INVESTOR) {
            status
            declineReason
          }
        }
        ... on LegalEntityProfile {
          name
          inn
          borrowerAccreditation: accreditation(role: BORROWER) {
            status
            declineReason
          }
        }
      }
    }
  }
`

const ProfileQuery = graphql`
  query AccountInfoQuery($id: ID!, $userFilter: OrdersFilter) {
    node(id: $id) {
      ... on UserProfile {
        id
        __typename
        ...AccreditationProfile_profile
        ...ProfileInfo_profile
        ...ProfileData_profile
      }
    }
    viewer {
      country
      profiles {
        __typename
      }
      ...ProfilePortfolio_investments @arguments(filter: $userFilter)
    }
  }
`

export default (render, placeholder) => ({ history }) => {
  const { profile } = useContext(CurrentProfileContext)

  if (!profile) {
    history.replace("/join/investor/step-3")
  }

  let query = ViewerInfoQuery
  let variables = null

  if (profile) {
    const { id } = profile

    query = ProfileQuery

    variables = {
      id,
      userFilter: { investors: [id] },
    }
  }

  return <QueryRenderer
    query={query}
    render={render}
    variables={variables}
    renderNull={placeholder}
  />
}
