import React from "react"

import { QueryParamsConsumer } from "src/context"
import { ProfilesListQueryContainer } from "src/query"

import {
  Box, Text, Heading, Translate,
} from "src/components"

import { Divider, Container, ProfilesContainer } from "./styles"

import getAvailableProfilesFromProps from "./utils"

import { AccreditedProfiles, UnAccreditedProfiles } from "./elements"

const Header = () => (
  <React.Fragment>
    <Box pt={35}>
      <Heading.h6>
        <Translate i18n="application.borrower.header" ns="components" />
      </Heading.h6>
    </Box>
    <Box pt={14} pb={48}>
      <Text>
        <Translate i18n="application.borrower.legend" ns="components" />
      </Text>
    </Box>
  </React.Fragment>
)

class Borrower extends React.Component<any, any> {
  state = {
    ...getAvailableProfilesFromProps(this.props.viewer.profiles),
  }

  componentDidMount() {
    const { availableProfiles: profiles } = this.state

    if (profiles.length && !this.props.activeProfileId) {
      let idx = 0
      const profileId = this.props.getQueryParam("profileId")

      if (profileId) {
        idx = profiles.findIndex((profile) => profile.id === profileId)
        this.props.deleteQueryParam("profileId")
      }

      this.props.onProfileChange(profiles[idx].id)
    }
  }

  render() {
    return (
      <Container>
        <Header />
        <ProfilesContainer>
          <AccreditedProfiles
            profiles={this.state.availableProfiles}
            checkedProfile={this.props.activeProfileId}
            onChange={this.props.onProfileChange}
            name="activeProfile"
          />
        </ProfilesContainer>
        <Divider />
        <ProfilesContainer>
          <UnAccreditedProfiles profiles={this.state.unavailableProfiles} />
        </ProfilesContainer>
      </Container>
    )
  }
}

export default ProfilesListQueryContainer((props) => (
  <QueryParamsConsumer>
    {({ getQueryParam, deleteQueryParam }) => (
      <Borrower
        {...props}
        getQueryParam={getQueryParam}
        deleteQueryParam={deleteQueryParam}
      />
    )}
  </QueryParamsConsumer>
))
