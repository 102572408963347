import React from "react"

import { Template } from "src/components"

export default () => (
  <Template
    layout="empty"
    header="components:order_list.loans.empty.header"
    image="/images/empty/loans.svg"
    description="components:order_list.loans.empty.description"
  />
)
