import React, { useEffect } from "react"

import { notifyGTM, parseHugeOrdersData } from "src/utils"

import { OrderPositionProvider } from "src/context"
import { MarketOrdersListHugeQueryContainer } from "src/query"

import {
  LoaderBox,
  MiniLoader,
  OrderFactory,
  Translate,
  LoadMore,
  CustomTooltip,
} from "src/components"

import Mobile from "./Mobile"

import {
  OrderContainerItems,
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  TableLoader,
  DesktopWrapper,
  TooltipIcon,
  TooltipText,
  TooltipLink,
  NotFoundBlock,
} from "./styles"

import { MOCK_ORDERS, TABLE } from "./constants"
import { Header } from "./Elements/Header"

const splitArray = (arr, chunkLength = 20) => {
  if (!Array.isArray(arr)) return []

  const result = []

  for (let index = 0; index < arr.length; index += chunkLength) {
    const chunk = arr.slice(index, index + chunkLength)
    result.push(chunk)
  }

  return result
}

const sendGTMEvent = (orders) => {
  if (!Array.isArray(orders) || !orders.length) return

  const allImpressions = orders.map((order, index) => {
    const { id, cession, application } = order

    return {
      id,
      position: index + 1,
      name: application.shortTitle || application.data.companyName,
      category: cession && cession.isActive ? "Цессия" : "Первичная",
    }
  })

  // нужно отправлять порциями не более 20 штук
  const impressionsChunks = splitArray(allImpressions)

  impressionsChunks.forEach((impressions) =>
    notifyGTM({
      eventAction: "push",
      eventCategory: "",
      extras: {
        ecommerce: {
          impressions,
        },
      },
    }))
}

const TableItem = React.memo((props: { data: any }) => (
  <OrderFactory.tableOrder data={props.data} />
))

const Orders = (props) => {
  const [isLoading, setLoading] = React.useState(false)
  const orders = parseHugeOrdersData(props.data)
  const { isFetching } = props

  const eventSent = React.useRef(false)
  useEffect(() => {
    if (eventSent.current) return

    sendGTMEvent(orders)
    eventSent.current = true
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [props.data.orders && props.data.orders.edges])

  const handleLoad = () => {
    setLoading(true)
    props.load.handle()
  }

  const hasFilter = Boolean(Object.values(props.filter).find(Boolean))
  const hasOrders = orders.length > 0

  const showTable = isFetching || hasOrders || hasFilter
  const showNotFound = !isFetching && hasFilter && !hasOrders

  return (
    <>
      {showTable && (
        <>
          <Header
            title={<Translate i18n="pages:user.market.requests" />}
            filter={props.filter}
            setFilter={props.setFilter}
          />

          <OrderContainerItems>
            <DesktopWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table cellPadding="5">
                <TableHead>
                  <HeadTr>
                    <HeadTh width="18%">Название</HeadTh>
                    <HeadTh width="40px" />
                    <HeadTh>
                      Рейтинг{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div style={{ width: "180px" }}>
                            <TooltipText>
                              Подробную информацию о расчете рейтинга можно
                              узнать{" "}
                              <TooltipLink to="/invest/scoring">
                                здесь
                              </TooltipLink>
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh width="72px" textAlign="center" />
                    <HeadTh>Цель, ₽</HeadTh>
                    <HeadTh>Ставка</HeadTh>
                    <HeadTh>Срок</HeadTh>
                    <HeadTh>
                      Тип{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div>
                            <TooltipText>
                              ИГК - исполнение государственного контракта
                            </TooltipText>
                            <TooltipText>
                              ИКК - исполнение коммерческого контракта
                            </TooltipText>
                            <TooltipText>
                              ПОС - пополнение оборотных средств
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh>Погашение ОД</HeadTh>
                    <HeadTh width="120px">Сбор до</HeadTh>
                  </HeadTr>
                </TableHead>

                <tbody>
                  {!isFetching
                    && hasOrders
                    && orders.map((data, index) => (
                      <OrderPositionProvider key={index} value={index + 1}>
                        <TableItem data={data} />
                      </OrderPositionProvider>
                    ))}

                  {isFetching
                    && [...Array(MOCK_ORDERS)].map((item, i) => {
                      const Item = OrderFactory.createMock({}, TABLE, false)
                      return (
                        <OrderPositionProvider key={i} value={i + 1}>
                          <Item />
                        </OrderPositionProvider>
                      )
                    })}
                </tbody>
              </Table>
            </DesktopWrapper>
            <Mobile isFetching={isFetching} orders={orders} />

            {showNotFound && <NotFoundBlock>Не найдено</NotFoundBlock>}
          </OrderContainerItems>

          <LoaderBox>
            {props.load.isNext && !isLoading && (
              <LoadMore onClick={handleLoad}>+ загрузить еще</LoadMore>
            )}
            {props.load.isNext && isLoading && <MiniLoader />}
          </LoaderBox>
        </>
      )}
    </>
  )
}

export default () => <MarketOrdersListHugeQueryContainer render={Orders} />
