import React, { FC } from "react"
import { useSlate, RenderLeafProps, RenderElementProps } from "slate-react"

import { Icons } from "src/components"
import { getClickableLink } from "src/utils"

import {
  isMarkActive, toggleMark, insertLink, isLinkActive,
} from "./utils"
import { Button } from "./styles"

export const Element: FC<RenderElementProps> = ({
  attributes,
  children,
  element,
}) => {
  if (element.type === "link") {
    return (
      <a {...attributes} href={getClickableLink(element.url)} rel="noreferrer noopener" target="_blank">
        {children}
      </a>
    )
  }

  return <p {...attributes}>{children}</p>
}

export const Leaf: FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  let element = children

  if (leaf.bold) {
    element = <strong>{element}</strong>
  }

  if (leaf.italic) {
    element = <em>{element}</em>
  }

  if (leaf.underline) {
    element = <u>{element}</u>
  }

  return <span {...attributes}>{element}</span>
}

export const FormatButton = ({ format, icon }) => {
  const editor = useSlate()
  const Icon = Icons[icon] || (() => <></>)

  return (
    <Button
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

export const LinkButton = () => {
  const editor = useSlate()

  const handleClick = (event) => {
    event.preventDefault()

    // eslint-disable-next-line no-alert
    const url = window.prompt("Введите URL адрес:")

    if (url) {
      insertLink(editor, url)
    }
  }

  return (
    <Button isActive={isLinkActive(editor)} onClick={handleClick}>
      <Icons.FormatLink />
    </Button>
  )
}
