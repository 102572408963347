import React, { createRef, useEffect } from "react"
import ReactDOM from "react-dom"
import { withRouter } from "react-router"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { isRussianInvestor, isRussianEntrepreneur, isForeignInvestor } from "src/utils"

import { ClerkIcon } from "./icons"
import {
  Modal,
  Container,
  Title,
  Content,
  Item,
  CompactButton,
  ButtonContainer,
} from "./styles"

export function getProfilesType(profiles = []) {
  const hasIndividual = profiles.some(isRussianInvestor)
  const hasEntrepreneur = profiles.some(isRussianEntrepreneur)
  const hasForeign = profiles.some(isForeignInvestor)

  return {
    hasIndividual,
    hasEntrepreneur,
    hasForeign,
  }
}

function CreateNewProfile(props) {
  const { onClose, history, viewer } = props
  const { hasIndividual, hasEntrepreneur } = getProfilesType(viewer.profiles)

  // const [investorSelected, setInvestorSelected] = useState(false)

  const overlayRef = createRef<HTMLDivElement>()
  const windowRef = createRef<HTMLDivElement>()

  function handleClose() {
    enableBodyScroll(windowRef.current)
    onClose()
  }

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      handleClose()
    }
  }

  useEffect(() => {
    disableBodyScroll(windowRef.current)

    return () => enableBodyScroll(windowRef.current)
  }, [])

  const hasProfiles = viewer.profiles.length > 0

  function createEntrepreneur() {
    const path = hasProfiles
      ? "/accounts/create/entrepreneur"
      : "/profiles/create/individual"
    history.push(path)
    handleClose()
  }

  function createIndividual() {
    const path = hasProfiles
      ? "/accounts/create/individual"
      : "/profiles/create/individual"
    history.push(path)
    handleClose()
  }

  // function createBorrower() {
  //   history.push("/profiles/create/juristic")
  //   handleClose()
  // }

  return (
    <Modal ref={overlayRef} onClick={onOverlayClick}>
      <Container ref={windowRef}>
        {/* // TODO: удалить или вернуть? */}
        {/* {!investorSelected && ( */}
        {/*  <> */}
        {/*    <Title>Тип профиля</Title> */}
        {/*    <Content> */}
        {/*      <Item> */}
        {/*        <FarmerIcon /> */}
        {/*        <Button onClick={createBorrower}>профиль заемщика</Button> */}
        {/*      </Item> */}

        {/*      <Item> */}
        {/*        <ClerkIcon /> */}
        {/*        <Button */}
        {/*          disabled={hasEntrepreneur && hasIndividual} */}
        {/*          onClick={() => setInvestorSelected(true)} */}
        {/*        > */}
        {/*          профиль инвестора */}
        {/*        </Button> */}
        {/*      </Item> */}
        {/*    </Content> */}
        {/*  </> */}
        {/* )} */}

        <Title>Профиль инвестора</Title>
        <Content>
          <Item>
            <ClerkIcon />
            <ButtonContainer>
              <CompactButton
                disabled={hasEntrepreneur}
                onClick={createEntrepreneur}
              >
                ИП
              </CompactButton>
              <CompactButton
                disabled={hasIndividual}
                onClick={createIndividual}
              >
                ФИЗ. ЛИЦО
              </CompactButton>
            </ButtonContainer>
          </Item>
        </Content>
      </Container>
    </Modal>
  )
}

export default function Render(props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")
  const modalWithRouter = withRouter(CreateNewProfile)

  return isOpened ? ReactDOM.createPortal((modalWithRouter as any)(props), root) : null
}
