import React from "react"
import Relay, { graphql } from "react-relay"

import { Box, Text, Translate } from "src/components"

const render = ({ viewer }) => (
  <>
    <Box mb="10px">
      <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
        <Translate i18n="components:account.password_update_form.title" />
      </Text>
    </Box>
    <Box mb="25px">
      <Text fontSize="15px" lineHeight="1.6" color="greyDarkest">
        <Translate
          i18n="components:account.password_update_form.owner"
          owner={viewer.email}
        />
      </Text>
    </Box>
  </>
)

export default Relay.createFragmentContainer(render, {
  viewer: graphql`
    fragment Header_viewer on User {
      email
    }
  `,
})
