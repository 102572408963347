import styled from "styled-components"

import { Flex } from "src/components"

export const Container = styled.div`
  margin-left: auto;
  position: relative;

  &:after {
    width: 100%;
    height: 40px;
    content: "";
    position: absolute;
    left: 0;
    bottom: -40px;
    background-color: transparent;
  }
`

export const StatusBarWrapper = styled.div`
  padding-top: 18px;
`

export const StyledFlex = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
`
