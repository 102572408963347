import React from "react"

import { divideNumber, getOrderTicker, getOrderDebt } from "src/utils"

import {
  Container,
  Card,
  Flex,
  TitleFlex,
  LeftText,
  RightText,
  Ticker,
  Name,
} from "./styles"

function PaymentCard(props) {
  const { data } = props
  const { application, offer } = data
  const debt = getOrderDebt(data)

  return (
    <Container>
      <Card>
        <TitleFlex>
          <Name>{application.shortTitle}</Name>
          <Ticker>{getOrderTicker(data)}</Ticker>
        </TitleFlex>

        <Flex>
          <LeftText>Ставка</LeftText>
          <RightText>{offer.data.interestRate}%</RightText>
        </Flex>

        <Flex>
          <LeftText>Срок</LeftText>
          <RightText>{offer.data.duration} мес</RightText>
        </Flex>

        <Flex>
          <LeftText>Остаток долга</LeftText>
          <RightText>{divideNumber(debt)}</RightText>
        </Flex>
      </Card>
    </Container>
  )
}

export default PaymentCard
