import React, { useState, useCallback } from "react"
import useCollapse from "react-collapsed"

import { SearchInput, CustomTooltip } from "src/components"

import {
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  Title,
  Description,
  TableRow,
  TitleContainer,
  CollapseButton,
  ArrowIcon,
  CollapsedContent,
  TitleSearch,
  NotFoundBlock,
  TooltipIcon,
  TooltipText,
} from "./styles"

import { MOCK_ORDERS } from "../../../OrdersList/Lists/Market/constants"

const collapseStyles = {
  transitionDuration: ".3s",
  transitionTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
}

export default function (props) {
  const {
    title,
    descr,
    columns,
    items,
    isFetching,
    condition,
    additionalColumns,
    isExpanded = true,
    setExpanded,
    setFilter,
    filter,
  } = props

  const [isHover, setIsHover] = useState(null)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
    expandStyles: collapseStyles,
    collapseStyles,
  })

  const hasFilter = Boolean(filter && Object.values(filter).find(Boolean))
  const hasItems = items?.length > 0

  const showSearch = !!setFilter
  const isSearchDisabled = !isExpanded

  const showItems = !isFetching && hasItems
  const showNotFound = !isFetching && hasFilter && !hasItems
  const showMock = isFetching

  const handleSearch = useCallback((search) => {
    setFilter((filter) => ({ ...filter, search }))
  }, [])

  return (
    <div>
      {!!title && (
        <TitleContainer>
          <Title>{title}</Title>

          {!!setExpanded && (
            <CollapseButton
              {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}
              disabled={isFetching}
              isExpanded={isExpanded}
            >
              <ArrowIcon />
            </CollapseButton>
          )}

          {showSearch && (
            <TitleSearch>
              <SearchInput
                value={filter?.search}
                placeholder="Название / ИНН"
                onChange={handleSearch}
                disabled={isSearchDisabled}
              />
            </TitleSearch>
          )}
        </TitleContainer>
      )}

      <CollapsedContent {...getCollapseProps()}>
        {descr && <Description>{descr}</Description>}

        <Table cellPadding="5">
          <TableHead>
            <HeadTr>
              {columns.map((column, i) => (
                <HeadTh key={i}>
                  {column.title}
                  {column.hint && (
                    <>
                      {" "}
                      <CustomTooltip placement="bottom" overlay={<TooltipText>{column.hint}</TooltipText>}>
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </>
                  )}
                </HeadTh>
              ))}
            </HeadTr>
          </TableHead>
          <tbody>
            {showItems
              && items.map((item, i) => (
                <TableRow key={i} onMouseLeave={() => setIsHover(null)} onMouseEnter={() => setIsHover(i)}>
                  {typeof condition === "function" && condition(item)
                    ? additionalColumns.map((column, j) => column.render(j, item, isHover === i))
                    : columns.map((column, j) => column.render(j, item, isHover === i))}
                </TableRow>
              ))}

            {showMock
              && Array(MOCK_ORDERS).fill(0).map((_, i) => (
                <TableRow key={i}>{columns.map((column, j) => column.placeholder(j))}</TableRow>
              ))}
          </tbody>
        </Table>

        {showNotFound && <NotFoundBlock>Не найдено</NotFoundBlock>}
      </CollapsedContent>
    </div>
  )
}
