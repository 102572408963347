/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type KYCDocumentTypeEnum = "DRIVERS" | "ID_CARD" | "OTHER" | "PASSPORT" | "RESIDENCE_PERMIT" | "%future added value";
export type TerritoryResidenceDocumentTypeEnum = "PERMANENT_RESIDENCY" | "VISA" | "%future added value";
export type InternationalIndividualFragment_profile = {
    readonly __typename: "ForeignIndividualProfile";
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly middleName: string | null;
    readonly tin: string | null;
    readonly isRussiaTaxResident: boolean;
    readonly hasUSACitizenship: boolean;
    readonly internationalPhone: string;
    readonly documents: ReadonlyArray<{
        readonly type: KYCDocumentTypeEnum;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }>;
    }>;
    readonly translatedDocuments: ReadonlyArray<{
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    }> | null;
    readonly selfie: {
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    } | null;
    readonly taxResidencyApplication: ReadonlyArray<{
        readonly id: string;
        readonly filename: string;
        readonly url: string;
        readonly size: number;
        readonly signature: {
            readonly url: string | null;
        } | null;
    }> | null;
    readonly migrationCard: {
        readonly number: string | null;
        readonly dateFrom: string | null;
        readonly dateTo: string | null;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }>;
    } | null;
    readonly innDocument: ReadonlyArray<{
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    }> | null;
    readonly residenceDocument: {
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    } | null;
    readonly territoryResidenceDocument: {
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }> | null;
        readonly type: TerritoryResidenceDocumentTypeEnum | null;
        readonly series: string | null;
        readonly number: string | null;
        readonly dateFrom: string | null;
        readonly dateTo: string | null;
    } | null;
    readonly registrationAddress: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly raw: string | null;
    } | null;
    readonly " $refType": "InternationalIndividualFragment_profile";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "InternationalIndividualFragment_profile";
};
export type InternationalIndividualFragment_profile$data = InternationalIndividualFragment_profile;
export type InternationalIndividualFragment_profile$key = {
    readonly " $data"?: InternationalIndividualFragment_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"InternationalIndividualFragment_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFrom",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateTo",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternationalIndividualFragment_profile",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "middleName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRussiaTaxResident",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasUSACitizenship",
          "storageKey": null
        },
        {
          "alias": "internationalPhone",
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "KYCDocument",
          "kind": "LinkedField",
          "name": "documents",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "translatedDocuments",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "selfie",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "taxResidencyApplication",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DocumentSignature",
              "kind": "LinkedField",
              "name": "signature",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MigrationCard",
          "kind": "LinkedField",
          "name": "migrationCard",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "innDocument",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "residenceDocument",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TerritoryResidenceDocument",
          "kind": "LinkedField",
          "name": "territoryResidenceDocument",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "series",
              "storageKey": null
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "registrationAddress",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "region",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "area",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locality",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "house",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "housing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "structure",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flat",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "raw",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ForeignIndividualProfile"
    }
  ],
  "type": "Node"
};
})();
(node as any).hash = '9eeadbbd45b2d80a7f7b0fd8e94acc6c';
export default node;
