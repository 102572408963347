import styled from "styled-components"
import { width, space } from "styled-system"

export const BasicOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
`

export const BasicContainer = styled.div<any>`
  background-color: rgb(255, 255, 255);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
  margin: auto;
  ${width}
  ${space}
`

export const VideoContainer = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
  margin: auto;
  width: 70vw;
  height: 70vh;
  ${space}
`

BasicContainer.defaultProps = {
  width: 480,
  px: 40,
  py: 40,
}
