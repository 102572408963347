import styled, { css } from "styled-components"

import { OutlinedButton, CardWrapper } from "src/components"

import { DownloadIcon, CheckIcon } from "./icons"

export const Container = styled(CardWrapper)`
  padding: 20px;
  width: 100%;
  margin-top: 20px;
  font-family: Geometria, sans-serif;
  color: ${({ theme }) => theme.colors.primaryBlack};
`

export const Titles = styled.h6`
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`

export const Title = styled.h6<any>`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${({ isAlert, theme }) => (isAlert && theme.colors.mainRed) || theme.colors.primaryBlack};
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`

export const Description = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 12px 0 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`

export const Bold = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlacks[7]};
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`

export const Link = styled.span`
  text-decoration: underline;
  font-size: 12px;
  line-height: 150%;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textBlacks[9]};
  margin: 0 0 0 4px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
`

export const UploadButton = styled.label<any>`
  width: 140px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-transform: uppercase;
  padding: 13px 0;
  display: block;
  text-align: center;
  cursor: pointer;

  ${({ isDisabled }) => isDisabled
    && css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  ${({ compact }) => compact
    && css`
      width: 48%;
      padding: 8px 0;
    `};

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 14px;
    padding: 19px 0;
    font-weight: 600;

    ${({ compact }) => compact
      && css`
        width: 48%;
      `};
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const SubmitButton = styled(OutlinedButton)`
  width: 48%;
  padding: 9px 0 7px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.mainRed};
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  font-size: 12px;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    height: 59px;
    font-size: 14px;
    font-weight: 600;
  }
`

export const Download = styled(DownloadIcon)`
  width: 10px;
  height: 11px;
  margin-left: 6px;
  fill: ${({ theme }) => theme.colors.darkBlue};
`

export const AttachmentTitle = styled.h6`
  font-family: Geometria, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin: 30px 0 0;
  color: ${({ theme }) => theme.colors.textBlacks[5]};
`

export const StatusContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const Status = styled(Description)`
  color: ${({ theme }) => theme.colors.darkGreen};
  margin: 0;
  font-weight: 500;
`

export const Check = styled(CheckIcon)`
  fill: ${({ theme }) => theme.colors.darkGreen};
  width: 15px;
  height: 15px;
  margin-right: 12px;
`
