import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetIndividualProfileMutation(
    $profile: SetIndividualProfileInput!
    $accreditation: UpdateIndividualProfileAsInvestorInput!
  ) {
    setProfile: setIndividualProfile(input: $profile) {
      profile {
        id
      }
    }

    requestAccreditation: updateIndividualProfileAsInvestor(
      input: $accreditation
    ) {
      profile {
        ...AccreditationInfoFragment_profile
        id
        name
        phone
        birthDate
        birthPlace
        isRussiaTaxResident
        inn
        iian
        sex
        passport {
          id
          series
          number
          issuedAt
          issuedBy {
            code
            name
          }
          scans {
            id
            url
            filename
          }
        }
        actualAddress {
          country
          region
          area
          locality
          street
          house
          housing
          structure
          flat
        }
        bankAccounts {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
    }
  }
`

export default enhance({ mutation })
