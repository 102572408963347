import React from "react"

import { Box, Translate } from "src/components"

import {
  StyledText,
  ColoredBox,
  ColoredFlex,
  IconContainer,
  AbsoluteContainer,
} from "./styles"

const RowText = ({ children }) => (
  <StyledText shaded fontSize="14px" lineHeight="1.43">
    {children}
  </StyledText>
)

export const Row = ({ label, value }) => (
  <ColoredFlex
    p="12px 25px"
    m="0 -25px 2px -25px"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box width="115px">
      <RowText>
        <Translate i18n={label} />
      </RowText>
    </Box>
    <RowText>{value}</RowText>
  </ColoredFlex>
)

export const DebtAmount = ({ children }) => (
  <ColoredBox
    p="10px"
    mb="15px"
    mt="15px"
    borderRadius="4px"
    border="1px dashed white"
    fontWeight="bold"
    display="inline-block"
  >
    {children}
  </ColoredBox>
)

/* eslint-disable max-len */
const KarmaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm-6.25-28.75v17.5h4.95v-3.797l1.3-1.78 2.85 5.577h5.9l-5.2-9.966 5.2-7.534H22.9l-4.15 6.525V11.25h-5z"
      opacity=".56"
    />
  </svg>
)

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g fill="none" fillRule="evenodd" transform="translate(.75 .5)">
      <circle cx="8" cy="8" r="8" fill="#FFF" />
      <path stroke="#E2251C" strokeWidth="2" d="M8 3.333v8" />
      <path
        stroke="#E2251C"
        strokeLinecap="square"
        strokeWidth="2"
        d="M11.333 9.333L8 12 4.667 9.333"
      />
    </g>
  </svg>
)

export const Icon = () => (
  <IconContainer>
    <KarmaIcon />
    <AbsoluteContainer right="0" bottom="0">
      <ArrowIcon />
    </AbsoluteContainer>
  </IconContainer>
)
