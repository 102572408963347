import styled from "styled-components"

import { Text } from "src/components"

export const InvestContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Investments = styled(AmountContainer)`
  padding-right: 28px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
`

export const AmountTitle = styled.div`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.greyDarker};
`

export const AmountValueContainer = styled.div`
  line-height: 1.17;
  padding: 2px 0 14px;
`

export const AmountValue = styled(Text)`
  font-size: 24px;
  color: ${({ theme, variant }) => theme.colors[variant]};
`

export const ArrowIcon = styled.span`
  background-position: center center;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(/images/icons/rotated_arrow.svg);
  margin-bottom: -4px;
  background-repeat: no-repeat;
`

export const AmountTransactionRemaind = styled.span`
  line-height: 20px;
  font-size: 14px;
  white-space: pre-line;
`

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  margin: 0 28px;
  border-right: 1px solid ${({ theme }) => theme.colors.grey};
`
