import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const Flex = styled.div`
  position: relative;
  display: flex;
`
export const DesktopContainer = styled(Flex)`
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  width: 42px;
  height: 60px;
  position: relative;
  flex-shrink: 0;
  z-index: 99;
  user-select: none;
  font-family: Geometria, sans-serif;
  color: ${({ theme }) => theme.colors.primaryBlack};
  justify-content: flex-end;
`

export const Container = (props) => {
  const mobile = useMediaPredicate("(max-width: 800px)")

  return !mobile && <DesktopContainer {...props}/>
}
