import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UploadAvatarMutation($input: UploadAvatarInput!) {
    uploadAvatar(input: $input) {
      url
      signedAvatar
    }
  }
`

export default enhance({ mutation })
