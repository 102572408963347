import React, { useContext } from "react"

import { CurrentProfileContext } from "src/context"
import { Translate, MiniLoader } from "src/components"
import { getOrderTicker, toCurrencyFormat } from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardBoldValue,
  CardStatus,
  CardStatusValue,
  CardButton,
  CardButtonsWrapper,
  Graph,
  CardCover,
} from "./styles"
import { CalendarIcon } from "./icons"

import {
  getOrderSuccedDate,
  getOrderName,
  getOrderDuration,
  getOrderRate,
  getPaymentAmount,
} from "../Tables/utils"
import { statuses } from "../Tables/InvestmentSucceededTable"

function InvestmentSucceededCard(props) {
  const { toProposal } = props
  const status = statuses.get(props.paymentSchedule.status) || {
    text: "Неизвестно",
    color: "grey",
  }

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          {getOrderDuration(props) && (
            <>
              {getOrderDuration(props)} <Translate i18n={"models:loan.month"} />
            </>
          )}
          {!getOrderDuration(props) && "_"}
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(props.amount)}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(getPaymentAmount(props, profileId))}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>{getOrderSuccedDate(props)}</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={status.color}>
          <Graph>{CalendarIcon}</Graph>
          {status.text}
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton onClick={() => toProposal(props)}>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0%</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          0 <Translate i18n={"models:loan.month"} />
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Инвестиции, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color="#6FA84B">
          <Graph>{CalendarIcon}</Graph>
          Погашен
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

InvestmentSucceededCard.placeholder = Placeholder

export default InvestmentSucceededCard
