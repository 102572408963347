import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation ScrapExternalMediaMutation($input: ScrapExternalMediaInput!) {
    scrapExternalMedia(input: $input) {
      media {
        id
        title
        url
        description
        video {
          ... on ExternalMediaVideo {
            url
            width
            height
          }
        }
      }
    }
  }
`

export default enhance({ mutation })
