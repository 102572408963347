import styled from "styled-components"

export const StyledLabel = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const Value = styled.div`
  font-size: 16px;
  margin-top: 11px;
`

export const HintLabel = styled.span`
  font-size: 12px;
  white-space: pre-line;
  padding-top: 12px;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${({ isLast }) => (isLast ? "0" : "0 32px 0 0")};
`
