import React from "react"
import moment from "moment"
import memoize from "memoize-one"

import { notifyGTM } from "src/utils"
import { MutationContainer } from "src/containers"
import { registrationSources } from "src/constants"
import { RelayEnvironmentConsumer } from "src/context"

import {
  InvestToOrder,
  RequestProfilePhoneConfirmation,
  InstantPurchaseOrder,
} from "src/mutations"

import {
  Loader,
  CountDown,
  Translate,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"

import {
  InputWrapper,
  Header,
  Title,
  SubTitle,
  FlexContainer,
  InputContainer,
  InputLabel,
  RedButton,
  ReturnButton,
  LoaderContainer,
  LoaderText,
} from "./styles"

const sendGTMEvent = memoize((order, amount, investmentId, viewer) => {
  const { cession, application, id: orderId } = order

  const amountAsNumber = Number(amount.replace(/\D/g, ""))

  const notify = notifyGTM({
    event: "purchase",
    eventCategory: "",
    extras: {
      date: moment(viewer.createdAt).format("DDMMYYYY"),
      ecommerce: {
        purchase: {
          actionField: {
            id: investmentId,
            revenue: amountAsNumber,
          },
          products: [
            {
              id: orderId,
              name: application.shortTitle || application.data.companyName,
              category: cession && cession.isActive ? "Цессия" : "Первичная",
              quantity: 1,
              price: amountAsNumber,
            },
          ],
        },
      },
    },
  })

  notify()
})

class Confirmation extends React.PureComponent<any, any> {
  state = {
    code: "",
    busy: false,
    codeSent: false,
    error: null,
    hasError: false,
    processing: false,
  }

  componentDidMount() {
    this.onCodeRequest()
    this.props.startTimer()
  }

  onCodeRequest = () => {
    const variables = {
      input: {
        profileId: this.props.profile.id,
        // eslint-disable-next-line no-extra-boolean-cast
        kind: !!this.props.order.cession ? "INSTANT_PURCHASE_ORDER" : "INVEST_TO_ORDER",
        orderId: this.props.order.id,
      },
    }

    const callbacks = {
      onError: this.onCodeRequestError,
      onCompleted: this.onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )

    this.setState({ busy: true })
  }

  onCodeRequestSuccess = () => {
    this.setState({ codeSent: true, busy: false })
  }

  onCodeRequestError = () => {
    this.props.clearTimer()
    this.setState({ busy: false, hasError: true })
  }

  onCodeResend = () => {
    this.onCodeRequest()
    this.props.restartTimer()
  }

  onCodeConfirm = () => {
    this.setState({ busy: true, processing: true })

    const variables = {
      input: {
        token: this.state.code,
        orderId: this.props.order.id,
        profileId: this.props.profile.id,
        amount: Number(this.props.amount.replace(/\D/g, "")),
        source: registrationSources.WEB,
      },
      orderId: this.props.order.id,
    }

    const callbacks = {
      onError: this.onCodeConfirmError,
      onCompleted: this.onCodeConfirmSuccess,
    }

    this.props.commit({ callbacks, variables })
  }

  onCodeConfirmSuccess = (data) => {
    try {
      const invest = data.investToOrder || data.instantPurchaseOrder
      const { id: profileId, investment } = invest.profile || invest.foreignProfile

      if (investment?.id) {
        const investmentIdentifier = `${investment.id}.${profileId}.${Date.now()}`

        sendGTMEvent(
          this.props.order,
          this.props.amount,
          investmentIdentifier,
          this.props.viewer,
        )
      }
    } finally {
      this.props.onComplete()
    }
  }

  onCodeConfirmError = (error) => {
    const { getError, onError } = this.props

    const isTokenError = getError("investToOrder.token") || getError("instantPurchaseOrder.token")

    if (isTokenError) {
      this.setState({ busy: false, processing: false, hasError: true })
    } else {
      onError(error)
    }
  }

  onChange = (e) => {
    this.setState({ code: e.target.value, hasError: false })
    this.props.clearError("investToOrder.token")
    this.props.clearError("instantPurchaseOrder.token")
  }

  render() {
    const { code, busy, processing } = this.state

    const {
      amount,
      profile,
      getError,
      secondsLeft,
      goBack,
      orderNumber,
    } = this.props

    const mutationError = getError("investToOrder.token") || getError("instantPurchaseOrder.token")
    const error = mutationError || (this.state.hasError ? "Непредвиденная ошибка. Попробуйте снова" : null)

    return (
      <>
        <ReturnButton onClick={goBack}>Назад</ReturnButton>

        <Header>
          <Title>
            <span>
              <Translate
                i18n="components:investment_modals.confirmation.title"
                amount={amount}
              />
            </span>
          </Title>
          <SubTitle>Инвестирование в заявку №{orderNumber}</SubTitle>
        </Header>

        {!processing && (
          <>
            <InputWrapper>
              <InputLabel>
                <Translate
                  i18n="components:account.operations.withdraw.confirmation.R_to_number"
                  number={profile.phone}
                />
              </InputLabel>
              <FlexContainer>
                <InputContainer>
                  <FormInput
                    autoFocus
                    mb="0px"
                    name="phone_confirmation"
                    hasError={error}
                    value={code}
                    error={error}
                    onChange={this.onChange}
                    label="components:account.bank_account.confirm.label"
                    autoComplete="off"
                  />
                </InputContainer>
              </FlexContainer>
            </InputWrapper>

            <ResendRequestButton
              onClick={this.onCodeResend}
              secondsLeft={secondsLeft}
            />
          </>
        )}

        {processing && (
          <LoaderContainer>
            <LoaderText>Операция выполняется...</LoaderText>
          </LoaderContainer>
        )}

        <RedButton
          disabled={!code || busy || processing}
          onClick={this.onCodeConfirm}
        >
          {busy || processing ? <Loader /> : "Готово"}
        </RedButton>
      </>
    )
  }
}

export default (props) => {
  const { cession } = props.order
  const mutation = cession ? InstantPurchaseOrder : InvestToOrder

  return (
    <RelayEnvironmentConsumer>
      {({ environment }) => (
        <CountDown>
          {({
            start, clear, restart, secondsLeft,
          }) => (
            <MutationContainer mutation={mutation}>
              {({ commit, getError, clearError }) => (
                <Confirmation
                  {...props}
                  commit={commit}
                  getError={getError}
                  clearError={clearError}
                  startTimer={start}
                  clearTimer={clear}
                  restartTimer={restart}
                  secondsLeft={secondsLeft}
                  environment={environment}
                />
              )}
            </MutationContainer>
          )}
        </CountDown>
      )}
    </RelayEnvironmentConsumer>
  )
}
