export const DocumentTypeOptions = [
  {
    label:
      "components:international_profile_form.labels.territoryResidenceDocumentType.PERMANENT_RESIDENCY",
    value: "PERMANENT_RESIDENCY",
  },
  {
    label:
      "components:international_profile_form.labels.territoryResidenceDocumentType.VISA",
    value: "VISA",
  },
]

interface InputProps {
  type: string,
  name: string,
  width: string,
  mask?: string,
  label: string,
  maxWidth: string,
  errorPath: string,
}

export const InputsSchema: InputProps[][] = [
  [
    {
      type: "text",
      name: "series",
      width: "100%",
      maxWidth: "49%",
      errorPath: "series",
      label: "components:international_profile_form.placeholders.series",
    },
    {
      type: "text",
      name: "number",
      width: "100%",
      maxWidth: "49%",
      errorPath: "number",
      label: "components:international_profile_form.placeholders.number",
    },
  ],
  [
    {
      type: "text",
      name: "dateFrom",
      width: "100%",
      maxWidth: "49%",
      mask: "99 - 99 - 9999",
      errorPath: "dateFrom",
      label: "components:international_profile_form.placeholders.dateFrom",
    },
    {
      type: "text",
      name: "dateTo",
      width: "100%",
      maxWidth: "49%",
      mask: "99 - 99 - 9999",
      errorPath: "dateTo",
      label: "components:international_profile_form.placeholders.dateTo",
    },
  ],
]
