import React from "react"
import { CancelInvestment } from "src/mutations"
import { ConfirmMutationModal } from "src/components"

const Confirmation = ({
  order, onClose, goNext, onError,
}) => (
  <ConfirmMutationModal
    isOpened
    title="Подтверждение отмены инвестиции"
    variables={{
      input: {
        profileId: order.profile.id,
        orderId: order.id,
      },
    }}
    order={order}
    profile={order.profile}
    onClose={onClose}
    onError={onError}
    onComplete={goNext}
    mutationName="cancelInvestment"
    mutation={CancelInvestment}
  />
)

export default Confirmation
