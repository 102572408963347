/* eslint-disable max-len */

import React from "react"

export const Prev = () => (
  <svg
    width="7.72"
    height="12.5"
    viewBox="0 0 14 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8938 20.1063L5.3063 11.5L13.8938
    2.89375L11.25 0.25L5.10067e-05 11.5L11.25 22.75L13.8938 20.1063Z"
      fill="white"
    />
  </svg>
)

export const Logout = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97012 16.4751H2.24251C1.82988 16.4751 1.49502 16.1402 1.49502 15.7276V2.27242C1.49502
    1.85979 1.82992 1.524932.24251 1.52493H8.97012C9.38348 1.52493 9.71761 1.19081 9.71761 0.777439C9.71761 0.364072
     9.38348 0.0298767 8.97012 0.0298767H2.24251C1.00614 0.0298767 0 1.03605 0 2.27242V15.7276C0 16.9639 1.00614
     17.9701 2.24251 17.9701H8.97012C9.38348 17.9701 9.71761 17.636 9.71761 17.2226C9.71761 16.8092 9.38348 16.4751
     8.97012 16.4751Z"
      fill="#ffffff"
    />
    <path
      d="M17.7773 8.46778L13.2324 3.98272C12.9394 3.69268 12.4655 3.69644 12.1754 3.99021C11.8854 4.28397
    11.8884 4.75714 12.1829 5.04718L15.4308 8.25248H6.72759C6.31423 8.25248 5.9801 8.58661 5.9801 8.99997C5.9801
    9.41334 6.31423 9.7475 6.72759 9.7475H15.4308L12.1829 12.9528C11.8884 13.2428 11.8862 13.716 12.1754
    14.0098C12.3219 14.1578 12.5148 14.2325 12.7077 14.2325C12.8975 14.2325 13.0874 14.1608 13.2324 14.0172L17.7773
    9.53217C17.9193 9.39165 18 9.20026 18 8.99994C18 8.79969 17.92 8.60907 17.7773 8.46778Z"
      fill="#ffffff"
    />
  </svg>
)

export const Logo = () => (
  <svg
    width="47"
    height="10"
    viewBox="0 0 47 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6223 7.83331V1.56665H16.2585V3.98567H16.9317L18.3154 1.56665H20.0731L18.2687 4.56413V4.58166L20.176 7.83331H18.3435L16.9317 5.29159H16.2585V7.83331H14.6223ZM20.204 7.83331L22.476 1.56665H24.1963L26.4683 7.83331H24.7854L24.374 6.51863H22.289L21.887 7.83331H20.204ZM22.6723 5.29159H23.9906L23.6167 4.07332L23.3455 2.99527H23.3268C23.2271 3.45103 23.1367 3.81038 23.0557 4.07332L22.6723 5.29159ZM27.1415 7.83331V1.56665H29.4695C30.0617 1.56665 30.4949 1.61632 30.7691 1.71565C31.1556 1.85588 31.4579 2.0823 31.676 2.3949C31.8942 2.70751 32.0033 3.08876 32.0033 3.53868C32.0033 3.88926 31.916 4.22377 31.7415 4.54222C31.567 4.86067 31.3207 5.09585 31.0029 5.24777V5.2653C31.0652 5.34126 31.1493 5.46688 31.2553 5.64217L32.5549 7.83331H30.7317L29.5443 5.72105H28.7776V7.83331H27.1415ZM28.7776 4.40637H29.4882C29.7562 4.40637 29.965 4.33917 30.1146 4.20478C30.2642 4.07039 30.339 3.8805 30.339 3.63509C30.339 3.29035 30.2081 3.06831 29.9464 2.96898C29.803 2.91055 29.5973 2.88134 29.3293 2.88134H28.7776V4.40637ZM33.0317 7.83331L33.5834 1.56665H35.3598L36.3321 4.21355L36.6687 5.24777H36.6874C36.8121 4.82707 36.9243 4.48233 37.024 4.21355L37.9964 1.56665H39.7728L40.3151 7.83331H38.6789L38.4732 5.01112C38.4607 4.87089 38.4545 4.71313 38.4545 4.53784C38.4545 4.36255 38.4576 4.22231 38.4639 4.11714L38.4732 3.95938H38.4545C38.3049 4.39761 38.1803 4.74819 38.0805 5.01112L37.3793 6.82539H35.9769L35.2756 5.01112L34.9017 3.95938H34.883C34.9079 4.34502 34.9079 4.6956 34.883 5.01112L34.6773 7.83331H33.0317ZM40.7358 7.83331L43.0078 1.56665H44.7281L47.0001 7.83331H45.3171L44.9058 6.51863H42.8208L42.4188 7.83331H40.7358ZM43.2041 5.29159H44.5224L44.1484 4.07332L43.8773 2.99527H43.8586C43.7589 3.45103 43.6685 3.81038 43.5875 4.07332L43.2041 5.29159Z"
      fill="white"
    />
    <path
      d="M4.78561 9.57121C7.42862 9.57121 9.57121 7.42862 9.57121 4.78561C9.57121 2.14259 7.42862 0 4.78561 0C2.14259 0 0 2.14259 0 4.78561C0 7.42862 2.14259 9.57121 4.78561 9.57121Z"
      fill="#FF2D55"
    />
    <path
      d="M2.59229 2.39282V7.17843H4.17154V6.1402L4.58629 5.65352L5.49555 7.17843H7.37789L5.71888 4.45307L7.37789 2.39282H5.5115L4.18749 4.17729V2.39282H2.59229Z"
      fill="white"
    />
  </svg>
)

export const Burger = () => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.5"
      y1="1.5"
      x2="20.5"
      y2="1.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="7.5"
      x2="20.5"
      y2="7.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1.5"
      y1="13.5"
      x2="20.5"
      y2="13.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Key = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4679 0C7.48605 0 5.06011 2.42594 5.06011 5.40778C5.06011 6.07069 5.17789 6.71487 5.41046 7.32662L0.239705 12.4978C0.165799 12.5717 0.124268 12.6719 0.124268 12.7764V15.6059C0.124268 15.8236 0.300705 16 0.518361 16H2.85011C2.95452 16 3.05464 15.9586 3.12855 15.8848L3.91433 15.1002C3.98839 15.0263 4.02999 14.9259 4.02999 14.8213V13.8564H4.99364C5.2113 13.8564 5.38774 13.68 5.38774 13.4623V13.0761H5.77402C5.99164 13.0761 6.16811 12.8997 6.16811 12.682V11.834H7.01611C7.12061 11.834 7.22083 11.7925 7.29477 11.7186L8.54855 10.4648C9.16039 10.6976 9.80467 10.8155 10.4678 10.8155C13.4497 10.8155 15.8756 8.38956 15.8756 5.40772C15.8756 2.42594 13.4497 0 10.4679 0ZM10.4679 10.0273C9.82283 10.0273 9.19896 9.89703 8.61355 9.64003C8.46218 9.57359 8.29171 9.61016 8.18008 9.71894C8.17886 9.72009 8.17767 9.72103 8.17649 9.72225L6.85292 11.0458H5.77408C5.55642 11.0458 5.37999 11.2222 5.37999 11.4399V12.2879H4.99371C4.77608 12.2879 4.59961 12.4643 4.59961 12.682V13.0682H3.63592C3.4183 13.0682 3.24183 13.2447 3.24183 13.4623V14.6579L2.68705 15.2118H0.912518V14.6549L5.72942 9.838C5.86796 9.69947 5.86796 9.47491 5.72942 9.33641C5.59089 9.19791 5.36639 9.19784 5.22777 9.33641L0.912518 13.6517V12.9397L6.15302 7.69881C6.15421 7.69763 6.15511 7.69653 6.15621 7.69537C6.26502 7.58384 6.30171 7.41338 6.23527 7.26188C5.97852 6.6765 5.84839 6.05266 5.84839 5.40781C5.84839 2.86056 7.92074 0.788219 10.468 0.788219C13.0152 0.788219 15.0875 2.86053 15.0875 5.40781C15.0875 7.955 13.0151 10.0273 10.4679 10.0273Z"
      fill="white"
      fillOpacity="0.95"
    />
    <path
      d="M12.1136 2.28577C11.2996 2.28577 10.6375 2.94795 10.6375 3.76192C10.6375 4.57589 11.2996 5.23808 12.1136 5.23808C12.9276 5.23808 13.5898 4.57589 13.5898 3.76192C13.5898 2.94795 12.9276 2.28577 12.1136 2.28577ZM12.1136 4.44989C11.7343 4.44989 11.4256 4.14127 11.4256 3.76192C11.4256 3.38258 11.7343 3.07395 12.1136 3.07395C12.493 3.07395 12.8016 3.38258 12.8016 3.76192C12.8016 4.14127 12.493 4.44989 12.1136 4.44989Z"
      fill="white"
      fillOpacity="0.95"
    />
  </svg>
)
