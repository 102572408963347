import is from "is_js"
import moment from "moment"
import memoize from "memoize-one"

import {
  cloneDeep, isInvestor, isBorrower, getProperty,
} from "src/utils"

import { OrderOfferSchema, OrderApplicationSchema } from "src/constants"

import { parseYearString } from "./index"
import { getFormBuilderState } from "./formBuilder"

const getOrderFullTitle = ({ shortTitle, longTitle }) =>
  (`${shortTitle}. ${longTitle}`).replace(".. ", ". ").replace(/\. *$/, "")

const parseOrdersData = ({ orders }) => {
  if (!is.existy(orders)) {
    return []
  }

  const { edges } = orders
  return edges.map((edge) => edge.node)
}

const swapIds = ({ node }) => {
  const { id: ivnestmentId, ...rest } = node
  const { id: orderId } = node.order

  return {
    id: orderId,
    ivnestmentId,
    ...rest,
    order: node.order,
  }
}

const swapStatuses = ({ order, ...rest }) => ({
  ...rest,
  order: { ...order },
  status: order.status,
})

const parseInvestmentsData = ({ investments }) => {
  if (!is.existy(investments)) {
    return []
  }

  const { edges } = investments

  return edges.map(swapIds).map(swapStatuses)
}

function getBootstrappedOrder(data) {
  const order = data

  if (!order.application) {
    order.application = {
      attachments: [],
      externalMedium: [],
      shortTitle: "_",
      longTitle: "",
      data: {
        minValue: 0,
        maxValue: 0,
      },
    }
  }

  if (!order.chain) {
    order.chain = {
      id: "",
      investorsCount: 0,
      gatheredAmount: 0,
    }
  }

  if (!order.offer) {
    order.offer = {
      data: {
        interestRate: 0,
        duration: "-",
      },
    }
  }

  return order
}

const parseOrder = (order) => {
  const {
    application, offer, cession, ...orderRest
  } = order

  let parsedOffer = null
  let parsedApplication = null
  let parsedCession = cession

  if (is.existy(offer)) {
    const { data: offerData, ...offerRest } = offer
    const parsedOfferData = JSON.parse(offerData)

    parsedOffer = { data: parsedOfferData, ...offerRest }
  }

  if (is.existy(application)) {
    const { data: applicationData, ...applicationRest } = application
    const parsedApplicationData = JSON.parse(applicationData)

    parsedApplication = { data: parsedApplicationData, ...applicationRest }
  }

  if (cession && cession.originalOrder) {
    const { originalOrder, ...restCession } = cession
    const parsedOriginalOrder = parseOrder(originalOrder)
    parsedCession = {
      originalOrder: parsedOriginalOrder,
      ...restCession,
    }
  }

  return {
    offer: parsedOffer,
    application: parsedApplication,
    cession: parsedCession,
    ...orderRest,
  }
}

const getOrderName = ({ application: { data } }) => {
  const MAX_NAME_LENGTH = 60
  const { companyName } = data
  let nameLength = 0

  if (is.existy(companyName)) {
    nameLength = companyName.length
  }

  if (nameLength > MAX_NAME_LENGTH) {
    return companyName.slice(0, MAX_NAME_LENGTH).concat("...")
  }
  return companyName
}

const getOfferRemainingDays = (offerExpireDate): number => {
  const now = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  const expireDate = moment(offerExpireDate).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })

  if (now > expireDate) {
    return 0
  }

  return expireDate.diff(now, "days")
}

const getCreditRating = ({ node }) => {
  let orderCreditRating = ""
  let profileCreditRating = ""
  let devider = ""

  if (is.existy(node)) {
    const {
      creditRating: orderRating,
      profile: { creditRating: profileRating },
    } = node

    if (is.existy(orderRating)) {
      orderCreditRating = orderRating.rating
    }

    if (is.existy(profileRating)) {
      profileCreditRating = profileRating.rating
    }

    if (is.existy(orderRating) && is.existy(profileRating)) {
      devider = "/"
    }
  }
  return `${orderCreditRating}${devider}${profileCreditRating}`
}

const getCreditLabel = ({ node }) => {
  let orderCreditRating = ""
  let profileCreditRating = ""

  if (is.existy(node)) {
    const {
      creditRating: orderRating,
      profile: { creditRating: profileRating },
    } = node

    if (is.existy(orderRating)) {
      profileCreditRating = "models:loan.reliability.provision"
    }

    if (is.existy(profileRating)) {
      orderCreditRating = "models:loan.reliability.debtor"
    }
  }
  return { profileCreditRating, orderCreditRating }
}

const orderStateFromProps = ({ node }: any) => {
  if (is.existy(node)) {
    const {
      application,
      id: orderId,
      offer,
      profile: { id: activeProfileId, name: profileName },
      chain,
      otherDocuments,
      guarantors,
      borrowerOffer,
      investorPayment,
      skipOffer,
      karma,
      financialStatements,
    } = node

    const generatedDocData = {
      borrowerOffer,
      investorPayment,
    }

    const chainData = { ...chain }
    let parsedApplication = {}
    let parsedOffer = {}
    let activeStep = 0
    let applicationAttachments = []
    let offerAttachments = []

    if (orderId.length && activeProfileId.length) {
      activeStep = 1
    }

    if (is.existy(application)) {
      const { data, attachments } = application

      parsedApplication = typeof data === "string" ? { ...JSON.parse(data) } : data

      applicationAttachments = [...attachments]
    }

    if (is.existy(offer)) {
      const { data, attachments } = offer
      parsedOffer = typeof data === "string" ? JSON.parse(data) : data

      offerAttachments = [...attachments]
    }

    return {
      orderId,
      activeStep,
      creditRating: getCreditRating({ node }),
      activeProfileId,
      profileName,
      offer: {
        offerAttachments,
        ...getFormBuilderState(OrderOfferSchema, parsedOffer, offerAttachments),
      },
      application: {
        applicationAttachments,
        ...getFormBuilderState(
          OrderApplicationSchema,
          parsedApplication,
          applicationAttachments,
        ),
      },
      chain: chainData,
      otherDocuments,
      guarantors,
      skipOffer,
      generatedDocData,
      financialStatements,
      karma,
    }
  }
  return {
    activeStep: 0,
    orderId: undefined,
    creditRating: "",
    profileName: "",
    activeProfileId: "",
    offer: getFormBuilderState(OrderOfferSchema, {}),
    application: getFormBuilderState(OrderApplicationSchema, {}),
    chain: undefined,
    otherDocuments: [],
    guarantors: [],
    skipOffer: false,
    generatedDocData: {},
    karma: {},
    financialStatements: [],
  }
}

const docOrderData = (order) => {
  const {
    chain,
    confirmedAt,
    application: { data: applicationData },
    profile: {
      name: profileName,
      ogrn,
      executive: { name: managerName },
    },
  } = order

  const { id } = chain
  const orderNumber = id.split(".")[2]
  const date = confirmedAt ? parseYearString(confirmedAt) : ""

  const { minValue, maxValue } = JSON.parse(applicationData)

  return {
    ogrn,
    date,
    minValue,
    maxValue,
    managerName,
    orderNumber,
    profileName,
  }
}

const getOrderModerationStatus = (order) => {
  const { offer, status, application } = order

  const declineReason = []

  if (is.existy(offer)) {
    const { declineReason: offerDeclinedReason } = offer

    if (offerDeclinedReason) {
      declineReason.push(offerDeclinedReason)
    }
  }

  if (is.existy(application)) {
    const { declineReason: applicationDeclineReason } = application

    if (applicationDeclineReason) {
      declineReason.push(applicationDeclineReason)
    }
  }

  return {
    status,
    declineReason,
  }
}

const normalizeAttachments = (data, attachments) => {
  const result = { ...data }

  Object.keys(result).forEach((key) => {
    const prop = result[key]
    if (prop && prop.attachments) {
      result[key] = prop.attachments.map((id) => attachments.find((att) => att.id === id))
    }
  })

  return result
}

const normalizeCurrenciesMap = (descriptors) => {
  if (!descriptors) return {}

  return descriptors.reduce(
    (memo, item) => ({ ...memo, [item.currency]: item.amount }),
    {},
  )
}

export const addScheduleItems = (normalizedTarget, normalizedValue) => {
  const result = { ...normalizedTarget }
  Object.keys(normalizedValue).forEach((key) => {
    if (!result[key]) {
      result[key] = normalizedValue[key]
      return
    }

    result[key] += normalizedValue[key]
  })

  return result
}

export const subtractScheduleItems = (normalizedTarget, normalizedValue) => {
  const result = { ...normalizedTarget }
  Object.keys(normalizedValue).forEach((key) => {
    if (!result[key]) {
      result[key] = 0
      return
    }

    result[key] -= normalizedValue[key]
  })

  return result
}

const initialScheduleItem = {
  valueOf() {
    return this.RUB || 0
  },
}

export const normalizePaymentItem = memoize((payment) => {
  const result = { ...payment }
  const propsList = [
    "loan",
    "total",
    "interest",
    "total_gross",
    "interest_fee",
    "personal_tax",
  ]

  propsList.forEach((item) => {
    const details = result[`${item}_details`]
    if (details !== undefined) {
      result[item] = normalizeCurrenciesMap(details)
    }
  })

  return result
})

const getPaymentsBalanceReport = memoize((payments) => {
  let paid = initialScheduleItem

  return [].concat(payments).map((payment) => {
    paid = addScheduleItems(paid, normalizeCurrenciesMap(payment.loan_details))

    return {
      ...payment,
      paid,
    }
  })
})

const getPaymentSummaryByStatus = memoize((payments, status = "all") => {
  const initialAccum = {
    loan: { ...initialScheduleItem },
    total: { ...initialScheduleItem },
    interest: { ...initialScheduleItem },
    total_gross: { ...initialScheduleItem },
    interest_fee: { ...initialScheduleItem },
    personal_tax: { ...initialScheduleItem },
  }

  return [].concat(payments).reduce((accum, next) => {
    if (status !== "all" && next.state !== status) return accum

    return {
      date: next.date,
      state: next.state,
      loan: addScheduleItems(
        accum.loan,
        normalizeCurrenciesMap(next.loan_details),
      ),
      total: addScheduleItems(
        accum.total,
        normalizeCurrenciesMap(next.total_details),
      ),
      interest: addScheduleItems(
        accum.interest,
        normalizeCurrenciesMap(next.interest_details),
      ),
      total_gross: addScheduleItems(
        accum.total_gross,
        normalizeCurrenciesMap(next.total_gross_details),
      ),
      interest_fee: addScheduleItems(
        accum.interest_fee,
        normalizeCurrenciesMap(next.interest_fee_details),
      ),
      personal_tax: addScheduleItems(
        accum.personal_tax,
        normalizeCurrenciesMap(next.personal_tax_details),
      ),
    }
  }, initialAccum)
  /* eslint-enable camelcase */
})

const isOrderOwner = memoize((viewer, order) => {
  const { profiles: viewerProfiles } = viewer
  const { profile: orderOwner } = order

  /* eslint-disable  no-underscore-dangle */
  return viewerProfiles.some((profile) => {
    if (profile && profile.__typename === "LegalEntityProfile") {
      return profile.id === orderOwner.id
    }

    return false
  })
  /* eslint-enable  no-underscore-dangle */
})

const getProfilePayments = memoize((profiles, payments, profileType) => {
  const profileMatchFn = profileType === "debtor" ? isBorrower : isInvestor
  const payment = (payments || []).find((item) => {
    const profile = item.profile || item.foreignProfile
    return profileMatchFn(profile) && !!item.items.length
  })

  return payment || payments[0] || null
})

const getGatheredPercents = memoize((minValue, gatheredAmount) => Math.ceil((gatheredAmount * 100) / minValue))

const setDataFromCession = memoize((order) => {
  const { cession } = order || {}

  if (!cession || !cession.isActive) return order

  const output = { ...order }
  const offer = { ...output.offer }
  const profile = { ...output.profile }

  output.initialOrder = cloneDeep(order)

  if (offer && offer.data) {
    offer.data.interestRate = cession.interestRate
    output.offer = offer
  }

  if (profile) {
    const { avatar, borrowerName, borrowerRating } = cession

    /* eslint-disable no-underscore-dangle */
    profile._avatar = avatar
    profile.name = borrowerName
    profile.creditRating = borrowerRating

    output.profile = profile
  }

  return output
})

const isActiveCession = (order) => getProperty(order, "cession.isActive", false)

function getOrderDebt(order) {
  const { paymentScheduleList: list } = order

  if (list.length < 1) return 0

  const { items } = list[0]
  const unpaidItems = items.filter((item) => item.state !== "paid")

  return unpaidItems.reduce((total, item) => total + item.loan, 0).toFixed(0)
}

function getOrderTicker(data, isCession = false) {
  const { profile, chain, cession } = data

  let chainId = ""
  let orderTicker = ""

  if (chain && chain.id) {
    // eslint-disable-next-line prefer-destructuring
    chainId = chain.id.split(".")[2]
  }

  if (
    isCession
    && cession
    && cession.originalOrder
    && cession.originalOrder.profile.borrower
  ) {
    orderTicker = cession.originalOrder.profile.borrower.ticker || ""
  }

  if (!isCession && profile.borrower) {
    orderTicker = profile.borrower.ticker || ""
  }

  return `${orderTicker + chainId}`
}

function getOrderInterestRate(order) {
  const { cession, offer } = order

  if (cession) {
    return cession?.interestRate || 0
  }

  return offer?.data?.interestRate || 0
}

function getOrderRating(order) {
  const { profile, cession } = order

  if (cession) {
    return cession.borrowerRating.rating || "-"
  }

  return order?.creditRating?.rating || profile?.creditRating?.rating || "-"
}

function findPaymentListByProfile(profileId, paymentScheduleList) {
  if (
    !profileId
    || !paymentScheduleList
    || !Array.isArray(paymentScheduleList)
  ) {
    return null
  }

  return paymentScheduleList.find(({ profile }) => profile?.id === profileId)
}

const parseHugeOrdersData = ({ orders }) => {
  if (!is.existy(orders)) {
    return []
  }
  const { edges } = orders

  return edges
    .map((edge) => edge.node)
    .map(parseOrder)
    .map(setDataFromCession)
}

export {
  getOrderFullTitle,
  findPaymentListByProfile,
  getOrderRating,
  getOrderInterestRate,
  parseOrder,
  getOrderName,
  docOrderData,
  isOrderOwner,
  getCreditLabel,
  getCreditRating,
  parseOrdersData,
  isActiveCession,
  setDataFromCession,
  getProfilePayments,
  orderStateFromProps,
  getGatheredPercents,
  parseHugeOrdersData,
  parseInvestmentsData,
  normalizeAttachments,
  getOfferRemainingDays,
  getOrderModerationStatus,
  getPaymentsBalanceReport,
  getPaymentSummaryByStatus,
  getOrderTicker,
  getOrderDebt,
  getBootstrappedOrder,
}
