import styled from "styled-components"

const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px 50px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.greys[3]};
`

export default ControlsContainer
