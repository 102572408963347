/* eslint-disable camelcase */
import React from "react"
import i18next from "i18next"
import Relay, { graphql } from "react-relay"
import memoize from "memoize-one"
import { toast } from "react-toastify"

import { pick } from "src/utils"
import { SetViewerPassword } from "src/mutations"
import { MutationContainer } from "src/containers"

import { Box, Translate, FormLoadingButton } from "src/components"

import LastUpdate from "./LastUpdate"
import Attributes from "./Attributes"
import { useAttributes } from "./hooks"

export const matchError = {
  keyword: "match",
  path: "setViewerPassword.retry_password",
  type: "match",
}

export const requiredError = (type) => ({
  keyword: "required",
  path: `setViewerPassword.${type}`,
  type: "required",
})

const clearErrorFromEvent = memoize((clearError) => (event) => clearError(`setViewerPassword.${event.target.name}`))

const initialAttributes = {
  password: "",
  retry_password: "",
  current_password: "",
}

const onCompleted = () => {
  const message = i18next.t(
    "components:account.password_update_form.toasts.success",
  )
  toast.success(message)
}

const onError = () => {
  const message = i18next.t(
    "components:account.password_update_form.toasts.error",
  )
  toast.error(message)
}

const submit = (attributes, commit, setBusy) => {
  setBusy(true)
  const input = pick(attributes.value, ["password", "current_password"])
  const variables = { input }

  const callbacks = {
    onError: () => {
      setBusy(false)
      onError()
    },
    onCompleted: () => {
      setBusy(false)
      onCompleted()
    },
  }

  commit({ callbacks, variables })
}

const getManualError = (attributes) => {
  const { password, retry_password } = attributes.value

  if (!password) return requiredError`password`
  if (password !== retry_password) return matchError

  return null
}

const Form = (props) => {
  const { viewer } = props
  const attributes = useAttributes(initialAttributes)

  const [busy, setBusy] = React.useState(false)

  const onSubmit = React.useCallback(() => {
    const error = getManualError(attributes)
    if (error) {
      props.setManualError(error)
      return
    }

    submit(attributes, props.commit, setBusy)
  }, [attributes.value])

  return (
    <Box>
      <Attributes
        viewer={viewer}
        attributes={attributes}
        getError={props.getError}
        clearError={clearErrorFromEvent(props.clearError)}
      />
      <Box mt="35px" mb="15px">
        <FormLoadingButton
          height="48px"
          width="100%"
          variant="blueWide"
          onClick={onSubmit}
          isLoading={busy}
        >
          <Translate i18n="components:account.password_update_form.button.save" />
        </FormLoadingButton>
      </Box>
      <LastUpdate date={viewer.password.updated_at} />
    </Box>
  )
}

const render = (props) => (
  <MutationContainer mutation={SetViewerPassword}>
    {({
      commit, getError, clearError, setManualError,
    }) => (
      <Form
        {...props}
        commit={commit}
        getError={getError}
        clearError={clearError}
        setManualError={setManualError}
      />
    )}
  </MutationContainer>
)

export default Relay.createFragmentContainer(render, {
  viewer: graphql`
    fragment Form_viewer on User {
      password {
        present
        updated_at
      }
    }
  `,
})
