import React, { useContext, useState, useCallback } from "react"

import { CurrentProfileContext } from "src/context"
import { MiniLoader } from "src/components"

import Empty from "./Empty"
import TransactionItem from "./TransactionItem"
import SaveAsFileButton from "./SaveAsFileButton"

import { LoadButtonContainer, LoadButton } from "./styles"

function TransactionList(props) {
  const {
    data: { transactions = {} },
    relay,
  } = props
  const { edges } = transactions

  const { hasNextPage } = transactions.pageInfo

  const [isFetching, setFetching] = useState(false)
  const { profile = {} } = useContext(CurrentProfileContext)

  const loadMore = useCallback(() => {
    if (!relay.isLoading()) {
      relay.loadMore(10, () => setFetching(false))
    }
  }, [])

  const handleClick = useCallback(() => {
    setFetching(true)
    loadMore()
  }, [])

  if (!edges.length) {
    return <Empty profile={profile} />
  }

  return (
    <>
      <SaveAsFileButton profile={profile} />

      {edges.map((transaction) => (
        <TransactionItem
          key={transaction.node.id}
          transaction={transaction.node}
          profile={profile}
        />
      ))}

      {relay.hasMore() && hasNextPage && (
        <LoadButtonContainer>
          {isFetching && <MiniLoader fontSize="5px" margin="auto" />}

          {!isFetching && (
            <LoadButton onClick={handleClick}>загрузить еще +</LoadButton>
          )}
        </LoadButtonContainer>
      )}
    </>
  )
}

export default TransactionList
