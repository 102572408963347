import React from "react"

import { Box, Text, Translate } from "src/components"

import { Subtitle, VideoContainer } from "../styles"

const itemsMapFn = (item, idx) => (
  <Box key={idx} mb="20px">
    <VideoContainer>
      <iframe src={item.video.url} />
    </VideoContainer>
    <Text fontSize="18px" lineHeight="1.55" color="greyDarkest">
      {item.title}
    </Text>
    {item.description && (
      <Text color="greyDark" lineHeight="1.5">
        {item.description}
      </Text>
    )}
  </Box>
)

const ItemVideos = ({ items }) => {
  if (!items.length) return null

  return (
    <Box mb="25px">
      <Subtitle>
        <Translate i18n="components:account.order_item.provision.videos" />
      </Subtitle>
      {items.map(itemsMapFn)}
    </Box>
  )
}

export default ItemVideos
