import React from "react"

import {
  Text, AddButton, Translate,
} from "src/components"

import DefaultLicenseItem from "./constants"
import { AddButtonWrapper } from "../../styles"
import { RemoveButton } from "../../elements"

import LicenseItemRow from "./LicenseItem"

const AddButtonLabel = () => (
  <Translate i18n="license-input.add-button.label" ns="components" />
)

export class License extends React.PureComponent<any, any> {
  onChange = (index) => ({ value }) => {
    const license = [...this.props.value]
    license[index] = value
    this.forceChange(license)
  }

  onRemove = (index) => (event) => {
    if (event) {
      event.preventDefault()
    }

    const license = [...this.props.value]
    license.splice(index, 1)
    this.forceChange(license)
  }

  onAdd = (event) => {
    if (event) {
      event.preventDefault()
    }

    const license = [...this.props.value]
    license.push({ ...DefaultLicenseItem })
    this.forceChange(license)
  }

  forceChange = (license) => {
    this.props.onChange({
      name: this.props.name,
      value: license,
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.props.value.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <RemoveButton onClick={this.onRemove(index)} />}
            <LicenseItemRow
              id={index}
              value={item}
              key={index}
              onChange={this.onChange(index)}
            />
          </React.Fragment>
        ))}
        <AddButtonWrapper>
          <AddButton onClick={this.onAdd}>
            <Text>
              <AddButtonLabel />
            </Text>
          </AddButton>
        </AddButtonWrapper>
      </React.Fragment>
    )
  }
}

export default License
