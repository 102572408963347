import { useEffect, useRef, useState } from "react"

function useTimer(start) {
  const [time, setTime] = useState(0)
  const interval = useRef<any>()

  useEffect(() => {
    function tick() {
      const now = Date.now()
      setTime(start - now)
    }

    if (start - Date.now() > 0) {
      interval.current = setInterval(tick, 1000)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    if (time < 0) {
      clearInterval(interval.current)
      setTime(0)
    }
  }, [time])

  return time
}

export default useTimer
