import { width } from "styled-system"
import styled, { css } from "styled-components"

const state = (color) => css`
  border-color: ${color} !important;
  border-left-width: 4px;
  padding-left: 7px;
`

const Container = styled.select<any>`
  color: ${({ theme }) => theme.colors.greyShuttle};
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDark};
  border-radius: 5px;
  height: 50px;
  margin: 0;
  padding: 0 10px;
  ${width};

  &:disabled {
    opacity: 0.4;
  }

  ${({ error }) => error && state("red")};
  ${({ success }) => success && state("green")};
`

Container.defaultProps = {
  width: "100%",
}

export default Container
