import React from "react"

import { UpdateBankAccount, AddProfileBankAccount } from "src/mutations"
import { getProperty } from "src/utils"
import { MutationContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import {
  flatten,
  getValidity,
  getCreateVariables,
  getUpdateVariables,
} from "./utils"

import Header from "./Header"
import AccountForm from "./AccountForm"
import AccountInfo from "./AccountInfo"
import Confirmation from "./Confirmation"

import { Container } from "../styles"

const validData = (state, props) => {
  const idFromProps = props.account && props.account.id

  if (
    getProperty(state, "account.status")
    !== getProperty(props, "account.status")
  ) return true

  return !state.account || (!state.account.id && idFromProps)
}

class AccountData extends React.Component<any, any> {
  state = {
    busy: false,
    editing: false,
    confirmationModal: false,
    valid: undefined,
    account: undefined,
  }

  static defaultProps = {
    account: {
      bank: {
        name: "",
        bic: "",
      },
      checkingAccount: "",
      correspondentAccount: "",
    },
  }

  static getDerivedStateFromProps(props, state) {
    if (!validData(state, props)) return null

    const flattenAccount = flatten(props.account)
    const valid = getValidity(flattenAccount)

    return {
      valid,
      account: flattenAccount,
    }
  }

  onChange = (event) => {
    const { name, value } = event.target

    if (!name) return

    this.setState((state) => {
      const account = { ...state.account, [name]: value }
      const valid = getValidity(account)

      return { account, valid }
    })
  }

  onSuggestionSelected = (suggestion) => {
    this.setState((state) => {
      const account = {
        ...state.account,
        bic: suggestion.data.bic,
        name: suggestion.data.name.payment,
        correspondentAccount: suggestion.data.correspondent_account || "",
      }

      const valid = getValidity(account)

      return { account, valid }
    })
  }

  onClick = () => {
    const { valid, account } = this.state
    if (!valid) return

    this.setState(() => ({ busy: true }))

    const { profileId, commit } = this.props

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    const variables = account.id
      ? getUpdateVariables(account)
      : getCreateVariables(account, profileId)

    commit({ callbacks, variables })
  }

  onCompleted = () => {
    this.setState(() => ({
      busy: false,
      confirmationModal: true,
    }))
  }

  onError = () => {
    this.setState(() => ({ busy: false }))
  }

  setEditing = () => {
    this.setState(() => ({ editing: true }))
  }

  onConfirmationSuccess = () => {
    this.setState(() => ({ confirmationModal: false, editing: false }))
  }

  showModal = () => {
    this.setState(() => ({ confirmationModal: true }))
  }

  hideModal = () => {
    this.setState(() => ({ confirmationModal: false, editing: false }))
  }

  render() {
    const {
      busy, valid, editing, account, confirmationModal,
    } = this.state

    const {
      getError, profileId, clearError, environment,
    } = this.props

    if (!account) return null

    return (
      <Container>
        <Header edit={!account.id} status={account.status} />
        {account.id && !editing ? (
          <AccountInfo
            account={account}
            onEdit={this.setEditing}
            onConfirm={this.showModal}
          />
        ) : (
          <AccountForm
            busy={busy}
            valid={valid}
            account={account}
            getError={getError}
            onClick={this.onClick}
            clearError={clearError}
            onChange={this.onChange}
            onSuggestionSelected={this.onSuggestionSelected}
          />
        )}
        {confirmationModal && (
          <Confirmation
            profileId={profileId}
            accountId={account.id}
            getError={getError}
            clearError={clearError}
            environment={environment}
            onClose={this.hideModal}
            onCompleted={this.onConfirmationSuccess}
          />
        )}
      </Container>
    )
  }
}

export default (props) => {
  const { account } = props
  const mutation = account && account.id ? UpdateBankAccount : AddProfileBankAccount

  return (
    <MutationContainer mutation={mutation}>
      {({ commit, getError, clearError }) => (
        <RelayEnvironmentConsumer>
          {({ environment }) => (
            <AccountData
              {...props}
              commit={commit}
              getError={getError}
              clearError={clearError}
              environment={environment}
            />
          )}
        </RelayEnvironmentConsumer>
      )}
    </MutationContainer>
  )
}
