/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreditRatingEnum = "A" | "AA" | "AAA" | "B" | "BB" | "BBB" | "C" | "CC" | "CCC" | "D" | "%future added value";
export type setInvestorAutoInvestmentsParamsInput = {
    isActive: boolean;
    profileId: string;
    limit: number;
    orderLimit: number;
    borrowerLimit: number;
    minRates: Array<AutoInvestmentsRatesInput>;
    token?: string | null;
    cessionEnable?: boolean | null;
};
export type AutoInvestmentsRatesInput = {
    rating: CreditRatingEnum;
    minRate: number;
};
export type SetInvestorAutoInvestmentsParamsMutationVariables = {
    input: setInvestorAutoInvestmentsParamsInput;
};
export type SetInvestorAutoInvestmentsParamsMutationResponse = {
    readonly setInvestorAutoInvestmentsParams: {
        readonly status: boolean;
    };
};
export type SetInvestorAutoInvestmentsParamsMutation = {
    readonly response: SetInvestorAutoInvestmentsParamsMutationResponse;
    readonly variables: SetInvestorAutoInvestmentsParamsMutationVariables;
};



/*
mutation SetInvestorAutoInvestmentsParamsMutation(
  $input: setInvestorAutoInvestmentsParamsInput!
) {
  setInvestorAutoInvestmentsParams(input: $input) {
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "setInvestorAutoInvestmentsParamsInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "setInvestorAutoInvestmentsParamsPayload",
    "kind": "LinkedField",
    "name": "setInvestorAutoInvestmentsParams",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetInvestorAutoInvestmentsParamsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetInvestorAutoInvestmentsParamsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetInvestorAutoInvestmentsParamsMutation",
    "operationKind": "mutation",
    "text": "mutation SetInvestorAutoInvestmentsParamsMutation(\n  $input: setInvestorAutoInvestmentsParamsInput!\n) {\n  setInvestorAutoInvestmentsParams(input: $input) {\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '018ed2942dc139274f1200c371ffa75e';
export default node;
