import React from "react"
import memoize from "memoize-one"
import styled from "styled-components"

import {
  number,
  numberInputValueGuard,
  numberInputOnChangeDecorator,
} from "src/utils"

import {
  Bank,
  Select,
  Switch,
  Address,
  DateInput,
  TextField,
  Translate,
  CheckBoxField,
  PasswordInput,
  AttachmentVideo,
  FileDownloadButton,
} from "src/components"

import AttachmentsListInput from "../../AttachmentsListInput"

/* eslint-disable import/no-named-as-default */
import License from "./License"
import Provision from "./Provision"
import RadioGroup from "./RadioGroup"
import TextEditor from "./TextEditor"
import PaymentDate from "./PaymentDate"
import TimeTableInput from "./TimeTableInput"
import RepaymentSchedule from "./RepaymentSchedule"
import RoomCharacteristics from "./RoomCharacteristics"
import SequenceOfRedemption from "./SequenceOfRedemption"
import Wysiwyg from "./Wysiwyg"

const PaddedDevider = styled.div`
  width: 10px;
  height: 100%;
`

const Devider = styled.div`
  width: 100%;
  margin: 80px 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey};
`

const translate = memoize((text) => (text ? <Translate i18n={`${text}`} ns="components" /> : <Nullable />))

const Nullable = () => null

const Value = memoize((props) => {
  const {
    hint, label, value, ...rest
  } = props

  return (
    <TextField
      type="text"
      hint={hint && translate(hint)}
      label={label && translate(label)}
      value={number(value)}
      {...rest}
    />
  )
})

const NumberInput = memoize((props) => {
  const {
    hint, label, isFloat, ...rest
  } = props

  const { max, value } = rest

  // TODO: should inject validatorFunction declared on FormBuilderSchema instead of maxValue
  const decoratedOnChange = numberInputOnChangeDecorator({
    injectedOnchange: rest.onChange,
    max,
    isFloat,
  })
  const injectedValue = numberInputValueGuard(value, isFloat)

  return (
    <TextField
      {...rest}
      type="number"
      hint={hint && translate(hint)}
      label={label && translate(label)}
      value={injectedValue}
      onChange={decoratedOnChange}
    />
  )
})

const Text = memoize((props) => {
  const { hint, label, ...rest } = props

  return (
    <TextField
      type="text"
      hint={hint && translate(hint)}
      label={label && translate(label)}
      {...rest}
    />
  )
})

const Date = memoize((props) => {
  const { hint, ...rest } = props

  return <DateInput hint={hint && translate(hint)} {...rest} />
})

const SwitchInput = memoize((props) => {
  const { rightLabel, leftLabel, ...rest } = props

  return (
    <Switch
      leftLabel={leftLabel && translate(leftLabel)}
      rightLabel={rightLabel && translate(rightLabel)}
      {...rest}
    />
  )
})

const CheckBox = memoize((props) => {
  const {
    label, padded, value, ...rest
  } = props

  return (
    <React.Fragment>
      {padded && <PaddedDevider />}
      <CheckBoxField
        label={label && translate(label)}
        checked={value}
        value={value}
        {...rest}
      />
    </React.Fragment>
  )
})

const Password = memoize((props) => <PasswordInput {...props} />)
const TimeTable = memoize((props) => <TimeTableInput {...props} />)
const LicenseInput = memoize((props) => <License {...props} />)
const SelectInput = memoize((props) => <Select {...props} />)
const FileDownload = memoize((props) => <FileDownloadButton {...props} />)
const AddressInput = memoize((props) => <Address {...props} />)
const ProvisionInput = memoize((props) => <Provision {...props} />)
const DeviderElement = memoize((props) => <Devider {...props} />)
const TextEditorInput = memoize((props) => <TextEditor {...props} />)
const RadioGroupInput = memoize((props) => <RadioGroup {...props} />)
const PaymentDateInput = memoize((props) => <PaymentDate {...props} />)
const BankAccountsInput = memoize((props) => <Bank.accounts {...props} />)
const AttachmentVideoInput = memoize((props) => <AttachmentVideo {...props} />)
const RepaymentScheduleInput = memoize((props) => (
  <RepaymentSchedule {...props} />
))
const RoomCharacteristicsInput = memoize((props) => (
  <RoomCharacteristics {...props} />
))
const SequenceOfRedemptionInput = memoize((props) => (
  <SequenceOfRedemption {...props} />
))
const WysiwygInput = memoize((props) => <Wysiwyg {...props} />)

const Schema = new Map<string, any>([
  ["date", Date],
  ["text", Text],
  ["value", Value],
  ["number", NumberInput],
  ["switch", SwitchInput],
  ["select", SelectInput],
  ["devider", DeviderElement],
  ["checkBox", CheckBox],
  ["password", Password],
  ["timeTable", TimeTable],
  ["license", LicenseInput],
  ["address", AddressInput],
  ["provision", ProvisionInput],
  ["radioGroup", RadioGroupInput],
  ["textEditor", TextEditorInput],
  ["fileDownload", FileDownload],
  ["paymentDate", PaymentDateInput],
  ["bankAccounts", BankAccountsInput],
  ["attachmentList", AttachmentsListInput],
  ["attachmentVideo", AttachmentVideoInput],
  ["repaymentSchedule", RepaymentScheduleInput],
  ["roomCharacteristics", RoomCharacteristicsInput],
  ["sequenceOfRedemption", SequenceOfRedemptionInput],
  ["wysiwyg", WysiwygInput],
])

export default (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, hasError, ...rest } = props

  const Input = Schema.get(type)

  if (!Input) {
    return <Nullable />
  }

  return <Input {...rest} />
}
