import aa from "../locales/aa.json"
import en from "../locales/en.json"
import ja from "../locales/ja.json"
import ko from "../locales/ko.json"
import ru from "../locales/ru.json"
import zh from "../locales/zh.json"

const locales = {
  aa,
  en,
  ja,
  ko,
  ru,
  zh,
}

export default {
  type: "backend",
  read: (language, namespace, callback) => {
    // eslint-disable-next-line no-param-reassign
    if (window._jipt) language = "aa"

    // if (typeof locales[language] !== "function") return callback(null, {})

    const locale = locales[language]
    return callback(null, locale[namespace])

    // locales[language]().then((data) => {
    //   callback(null, data[namespace])
    // })
  },
}
