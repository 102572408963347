import React from "react"

import {
  Container, Name, Value, Empty,
} from "./styles"

function TextField(props) {
  const { data } = props
  const { name, value } = data

  return (
    <Container>
      <Name>{name}</Name>

      {value && <Value>{value}</Value>}

      {!value && <Empty>Заполните данные</Empty>}
    </Container>
  )
}

export default TextField
