import Input from "./Input"
import Modal from "./Modal"
import Details from "./Details"
import Accounts from "./Accounts"
import AccountInput from "./AccountInput"

const render = () => null

render.input = Input
render.modal = Modal
render.details = Details
render.accounts = Accounts
render.accountInput = AccountInput

export default render
