import { divideNumber } from "src/utils"

export const number = (str) => str.replace(/\D/gim, "")

export const separate = (val) => divideNumber(number(`${val}`))

export const formatValue = (event) => separate(event.target.value)

export const validMinRates = (minRates) => Object.values(minRates).every((rate: any) => !rate.active || !!rate.value)

export const minRatesToVariables = (minRates) => Object.entries(minRates)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .filter(([_name, { active }]: any) => active)
  .map(([name, { value }]: any) => ({
    rating: name.toUpperCase(),
    minRate: Number(value),
  }))

export const minRatesToState = (defaultMinRates, minRates) => []
  .concat(
    Object.entries(defaultMinRates).map(([rating, { value }]: any) => ({
      value,
      rating,
      active: false,
    })),
    minRates
      .map(({ rating, minRate }) => ({
        rating: rating.toLowerCase(),
        active: true,
        value: minRate,
      }))
      .filter(({ rating }) => rating in defaultMinRates),
  )
  .reduce(
    (acc, { rating, value, active }) => ({
      ...acc,
      [rating.toLowerCase()]: {
        value: value || "",
        active,
      },
    }),
    {},
  )
