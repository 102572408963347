import React from "react"
import { toast, ToastContainer } from "react-toastify"
import { Route } from "react-router"

import { ErrorsContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import {
  CreateOrder,
  UpdateOrderOffer,
  RequestOrderApproval,
  UpdateOrderApplication,
} from "src/mutations"

import {
  Heading, Template, Translate, AccountDescription,
} from "src/components"

import {
  notifyGTM,
  attachmentsParser,
  orderStateFromProps,
  parseAttachmentsData,
} from "src/utils"

import { Offer, Borrower, Application } from "./Steps"
import Controls from "./Controls"

const renderTitle = () => (
  <Heading.h2>
    <Translate
      i18n="account.role_item.buttons.borrower.action"
      ns="components"
    />
  </Heading.h2>
)

class NewOrderStepper extends React.Component<any, any> {
  state = {
    busy: false,
    isSaveLoading: false,
    isRequestLoading: false,
    confirmed: undefined,
    ...orderStateFromProps(this.props),
  }

  sendGTMStepEvent = (step) => notifyGTM({
    eventAction: "request",
    eventLabel: step,
    extras: {
      "profile-id": this.props.viewer.id,
    },
  })

  onRequestCompleted = ({ nextStep, confirm }: any) => ({ isDraft }: any) => (
    payload,
  ) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      busy, isSaveLoading, isRequestLoading, ...rest
    } = this.state

    let order: any = { ...rest }

    if (payload) {
      const { order: node } = payload.updateOrderApplication
      order = { ...orderStateFromProps({ node }) }
    }

    this.setState(() => ({
      busy: false,
      isSaveLoading: false,
      isRequestLoading: false,
      ...order,
    }))

    if (isDraft) {
      toast.success("Изменения успешно сохранены", {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      this.setState(
        () => ({
          activeStep: nextStep === 3 ? 2 : nextStep,
        }),
        () => nextStep === 3 && confirm(),
      )
    }
  }

  steps = [
    {
      label: "orders-tabs.borrower",
      content: () => (
        <Borrower
          onProfileChange={this.onProfileChange}
          activeProfileId={this.state.activeProfileId}
        />
      ),
      extra: AccountDescription,
      action: () => this.createOrder(),
      onRequestCompleted: ({
        createOrder: {
          order: { id },
        },
      }: any) => {
        this.setState(() => ({ busy: false, isRequestLoading: false }))
        this.props.history.push(`/orders/${id}`)
      },
    },
    {
      label: "orders-tabs.borrower_application",
      content: () => (
        <Application
          name="application"
          value={this.state.application}
          environment={this.props.environment}
          onChange={this.onChange}
          sendGTMEvent={this.sendGTMStepEvent("step-2")}
        />
      ),
      action: ({ isDraft }: any) => this.updateOrderApplication({ isDraft }),
      onRequestCompleted: this.onRequestCompleted({ nextStep: 2 }),
    },
    {
      label: "orders-tabs.order_conditions",
      content: () => (
        <Offer
          name="offer"
          value={this.state.offer}
          onChange={this.onChange}
          sendGTMEvent={this.sendGTMStepEvent("step-3")}
        />
      ),
      action: ({ isDraft, confirm }) => this.updateOrderOffer({ isDraft, confirm }),
      onRequestCompleted: ({ isDraft, confirm }) => this.onRequestCompleted({ nextStep: 3, confirm })({ isDraft }),
    },
  ]

  onProfileChange = (id) => {
    if (id === this.state.activeProfileId) {
      this.setState(() => ({ activeProfileId: "" }))
    } else {
      this.setState(() => ({ activeProfileId: id }))
    }
  }

  onChange = ({ name, value }) => {
    this.setState(() => ({ [name]: value }))
  }

  onPreviousStep = () => {
    const currentStep = this.state.activeStep
    if (currentStep <= 0) {
      return
    }

    this.setState(() => ({ activeStep: currentStep - 1 }))
  }

  onError = (transaction) => {
    toast.error("К сожалению возникла ошибка", {
      position: toast.POSITION.TOP_RIGHT,
    })
    this.setState({
      busy: false,
      isRequestLoading: false,
      isSaveLoading: false,
    })

    if (this.props.setErrors) {
      this.props.setErrors(transaction)
    }
  }

  createOrder = () => {
    const variables = {
      input: {
        profileId: this.state.activeProfileId,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.steps[this.state.activeStep].onRequestCompleted,
    }

    CreateOrder.commit(this.props.environment, variables, null, callbacks)
  }

  updateOrderApplication = ({ isDraft }) => {
    const application = parseAttachmentsData({ ...this.state.application })

    const variables = {
      input: {
        isDraft,
        orderId: this.state.orderId,
        application: JSON.stringify(application),
        attachments: attachmentsParser.list,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.steps[this.state.activeStep].onRequestCompleted({
        isDraft,
      }),
    }

    UpdateOrderApplication.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  onOrderApprovalCompleted = () => {
    this.setState(() => ({ confirmed: true, isRequestLoading: false }))
    this.sendGTMStepEvent("success")()
    this.props.history.push("/market")
  }

  requestOrderApproval = () => {
    this.setState(() => ({ isRequestLoading: true }))

    const variables = {
      input: {
        orderId: this.state.orderId,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onOrderApprovalCompleted,
    }

    RequestOrderApproval.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  updateOrderOffer = ({ isDraft, confirm }) => {
    const { offerAttachments, ...rest } = this.state.offer

    const variables = {
      input: {
        isDraft,
        orderId: this.state.orderId,
        data: JSON.stringify({ ...rest }),
        attachments: offerAttachments,
      },
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: (this as any).steps[this.state.activeStep].onRequestCompleted({
        isDraft,
        confirm,
      })(),
    }

    UpdateOrderOffer.commit(this.props.environment, variables, null, callbacks)
  }

  onRequest = ({ isDraft }) => (confirm) => () => {
    if (this.state.isRequestLoading || this.state.busy) {
      return
    }

    this.setState(() => ({ busy: true, isRequestLoading: true }))
    this.steps[this.state.activeStep].action({ isDraft, confirm })
  }

  onSave = ({ isDraft }) => () => {
    if (this.state.isSaveLoading || this.state.busy) {
      return
    }

    this.setState(() => ({ busy: true, isSaveLoading: true }))
    this.steps[this.state.activeStep].action({ isDraft })
  }

  renderControls = () => {
    const { offer, activeStep } = this.state
    const disabledContinue = activeStep === 2 && !offer.approve

    return (
      <Controls
        confirmed={this.state.confirmed}
        activeStep={this.state.activeStep}
        disabledContinue={disabledContinue}
        onPreviousStep={this.onPreviousStep}
        environment={this.props.environment}
        profileId={this.state.activeProfileId}
        onSave={this.onSave({ isDraft: true })}
        isSaveLoading={this.state.isSaveLoading}
        isRequestLoading={this.state.isRequestLoading}
        onNextStep={this.onRequest({ isDraft: false })}
        requestOrderApproval={this.requestOrderApproval}
      />
    )
  }

  render() {
    return (
      <Template
        title={renderTitle}
        controls={this.renderControls}
        steps={this.steps}
        layout="stepper"
        activeStep={this.state.activeStep}
      />
    )
  }
}

const render = (props) => (
  <React.Fragment>
    <ToastContainer autoClose={4000} hideProgressBar={true} />
    <ErrorsContainer>
      {(errors) => (
        <RelayEnvironmentConsumer>
          {({ environment }) => (
            <Route>
              {({ history }) => (
                <NewOrderStepper
                  {...props}
                  {...errors}
                  history={history}
                  environment={environment}
                />
              )}
            </Route>
          )}
        </RelayEnvironmentConsumer>
      )}
    </ErrorsContainer>
  </React.Fragment>
)

export default render
