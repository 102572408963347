/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type BankAccounts_foreignProfile = {
    readonly id: string;
    readonly foreignBankAccounts: ReadonlyArray<{
        readonly id?: string;
        readonly status?: BankAccountStatusEnum;
        readonly IBAN?: string;
        readonly ownerName?: string;
        readonly bank?: {
            readonly name: string;
            readonly address: string;
            readonly SWIFT: string;
        };
    }>;
    readonly bankAccounts: ReadonlyArray<{
        readonly id: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly __typename: "ForeignIndividualProfile";
    readonly " $refType": "BankAccounts_foreignProfile";
};
export type BankAccounts_foreignProfile$data = BankAccounts_foreignProfile;
export type BankAccounts_foreignProfile$key = {
    readonly " $data"?: BankAccounts_foreignProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"BankAccounts_foreignProfile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BankAccounts_foreignProfile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": "foreignBankAccounts",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "allBankAccounts",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "IBAN",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ownerName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ForeignBank",
              "kind": "LinkedField",
              "name": "bank",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "SWIFT",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ForeignBankAccount"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccount",
      "kind": "LinkedField",
      "name": "bankAccounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkingAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correspondentAccount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Bank",
          "kind": "LinkedField",
          "name": "bank",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bic",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForeignIndividualProfile"
};
})();
(node as any).hash = '80c949f851c1ae429dffb78ca1637c81';
export default node;
