import React from "react"

import { Translate, MiniLoader } from "src/components"
import { getOrderTicker } from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardBoldValue,
  CardStatus,
  CardStatusValue,
  CardButton,
  CardButtonsWrapper,
  CardCover,
} from "./styles"

import {
  getOrderName,
  getOrderDuration,
  getOrderRate,
  getOrderSum,
} from "../Tables/utils"
import { statuses } from "../Tables/LoanDraftTable"

function LoanDraftCard(props) {
  const { showModal, toProposal } = props
  const status = statuses.get(props.status) || {
    text: "Неизвестно",
    color: "grey",
  }

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          {getOrderDuration(props) && (
            <>
              {getOrderDuration(props)} <Translate i18n={"models:loan.month"} />
            </>
          )}
          {!getOrderDuration(props) && "_"}
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Сумма, ₽</CardTitle>
        <CardBoldValue>{getOrderSum(props)}</CardBoldValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue
          isClickable={props.status === "DECLINED"}
          onClick={() => props.status === "DECLINED" && showModal(props.id)}
          color={status.color}
        >
          {status.text}
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        {props.status === "APPROVED" && (
          <>
            <CardButton onClick={() => toProposal(props)} compact>
              Подробнее
            </CardButton>
            <CardButton compact primary>
              ОПЛАТИТЬ КОМИССИЮ
            </CardButton>
          </>
        )}

        {props.status !== "APPROVED" && (
          <CardButton onClick={() => toProposal(props)}>Подробнее</CardButton>
        )}
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Срок</CardTitle>
        <CardValue>
          0 <Translate i18n={"models:loan.month"} />
        </CardValue>
      </CardData>

      <CardData>
        <CardTitle>Сумма, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color="#00679C">Черновик</CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

LoanDraftCard.placeholder = Placeholder

export default LoanDraftCard
