import Order from "./Order"
import Proposal from "./Proposal"
import Investment from "./Investment"
import CompletedOrder from "./CompletedOrder"

const render = () => null

render.order = Order
render.proposal = Proposal
render.investment = Investment
render.completedOrder = CompletedOrder

export default render
