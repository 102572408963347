import React, { useEffect, useState } from "react"

import { LoaderBox, MiniLoader, LoadMore } from "src/components"
import { LoanCompletedOrdersListQueryContainer } from "src/query"
import { getOrderTicker, toCurrencyFormat } from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  TDBold,
  TDDate,
  CompletedStatus,
  Graph,
  TableLoader,
  TableWrapper,
  DesktopWrapper,
  MobileWrapper,
} from "./styles"
import { ClockIcon, GraphIcon } from "./icons"
import { Table, List } from "../Elements"
import { LoanCompletedCard } from "../Cards"
import {
  parseOrders,
  getOrderName,
  getOrderRate,
  getPaidInterest,
  getPaidLoan,
  getOrderDebt,
  getDateNextPayment,
} from "./utils"

export const statuses = new Map([
  [
    "ok",
    {
      text: "Выплаты по графику",
      color: "#6FA84B",
    },
  ],
  [
    "pastdue",
    {
      text: "Просрочка платежа",
      color: "#F70000",
    },
  ],
])

export function RenderedTable(props) {
  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
  } = props

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>{getOrderName(obj)}</TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => (
          <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>
        ),
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Выплачено тело, ₽",
        render: (i, obj) => (
          <TDBold key={i}>{toCurrencyFormat(getPaidLoan(obj))}</TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Выплачено %",
        render: (i, obj) => (
          <TDBold key={i}>{toCurrencyFormat(getPaidInterest(obj))}</TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Остаток долга, ₽",
        render: (i, obj) => (
          <TDBold key={i}>{toCurrencyFormat(getOrderDebt(obj))}</TDBold>
        ),
        placeholder: (i) => <TDBold key={i}>0</TDBold>,
      },
      {
        title: "Дата след платежа",
        render: (i, obj) => (
          <TDDate key={i}>
            {getDateNextPayment(obj) && (
              <>
                {ClockIcon} {getDateNextPayment(obj)}
              </>
            )}
          </TDDate>
        ),
        placeholder: (i) => <TDDate key={i}>{ClockIcon} -</TDDate>,
      },
      {
        title: "Статус",
        render: (i, obj) => {
          const status = statuses.get(
            obj.paymentScheduleList[0]
                && obj.paymentScheduleList[0].info.state,
          ) || {
            text: "Неизвестно",
            color: "grey",
          }

          return (
            <CompletedStatus color={status.color} key={i}>
              <Graph>{GraphIcon}</Graph>
              {status.text}
            </CompletedStatus>
          )
        },
        placeholder: (i) => (
          <CompletedStatus color={"#6FA84B"} key={i}>
            <Graph>{GraphIcon}</Graph>
          </CompletedStatus>
        ),
      },
    ]
  }

  const { borrower } = data || {}
  const orders = [...parseOrders(borrower)]
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.borrower?.orders?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  return (
    <div>
      {(orders.length > 0 || isFetching) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="ДЕЙСТВУЮЩИЕ ЗАЙМЫ"
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              title="ДЕЙСТВУЮЩИЕ ЗАЙМЫ"
              items={orders}
              Render={LoanCompletedCard}
              isFetching={isFetching}
              toProposal={toProposal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  const InvestmentActiveTable = LoanCompletedOrdersListQueryContainer(
    (data) => <RenderedTable {...rest} {...data} />,
    profile,
  )

  return <InvestmentActiveTable />
}
