import React from "react"

import { ViewerData, OrderDataConsumer } from "src/context"
import { number } from "src/utils"
import { Box, Title, Translate } from "src/components"

import {
  Divider,
  AmountTitle,
  AmountValue,
  AmountValueContainer,
} from "../styles"

import { getPaidAmount } from "../../../utils"

const Paid = (props) => {
  const { order, viewer, options } = props

  const { status } = order

  const paidAmountValue = getPaidAmount(viewer, order)

  return (
    status === "COMPLETE" && (
      <>
        <Divider />
        <Box>
          <AmountTitle>
            <Title>
              <Translate i18n="components:order_list.investments.item.invested.amount.paid" />
            </Title>
          </AmountTitle>
          <AmountValueContainer>
            <AmountValue variant="greyDark">
              {number(paidAmountValue, options)} ₽
            </AmountValue>
          </AmountValueContainer>
        </Box>
      </>
    )
  )
}

export default (props) => (
  <OrderDataConsumer>
    {(order) => (
      <ViewerData.renderPropContainer>
        {({ viewer }) => <Paid order={order} viewer={viewer} {...props} />}
      </ViewerData.renderPropContainer>
    )}
  </OrderDataConsumer>
)
