/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"

const IconWrapper = styled.div`
  width: 14px;
  height: 10px;
  margin: 0 auto;
  transform: translateY(-25%);
`

const ReturnIcon = ({ fill }) => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
      <path
        fill={fill}
        d="M13 4H3.4l2.31-2.31A.948.948 0 0 0 6 1a1.012 1.012 0 0 0-1-1
        .943.943 0 0 0-.69.29L.331 4.269A.959.959 0 0 0 0 5a.907.907 0 0 0
         .323.723L4.31 9.71A.943.943 0 0 0 5 10a1.011 1.011 0 0 0 1-1 .948.948 0 0 0-.29-.69L3.4 6H13a1 1 0 0 0 0-2z"
        data-name="Group 1"
      />
    </svg>
  </IconWrapper>
)

ReturnIcon.defaultProps = {
  fill: "#5a616e",
}

export default ReturnIcon
