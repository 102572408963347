import React, { useEffect, useState } from "react"

import {
  Translate, LoaderBox, MiniLoader, LoadMore,
} from "src/components"
import { LoanActiveOrdersListQueryContainer } from "src/query"
import { getOrderTicker } from "src/utils"

import {
  TData,
  TDName,
  TDNameLink,
  ActionButton,
  TDStatus,
  TDDate,
  TableWrapper,
  TableLoader,
  DesktopWrapper,
  MobileWrapper,
} from "./styles"
import { ClockIcon } from "./icons"
import { Statusbar, Table, List } from "../Elements"
import { LoanActiveCard } from "../Cards"
import {
  parseOrders,
  getStatusbarValue,
  getOrderAmount,
  getOrderDate,
  getOrderName,
  getOrderRate,
  getOrderDuration,
} from "./utils"

export const statuses = new Map([
  [
    "TRANSFER",
    {
      text: "Ожидается поступление средств на счет заемщика",
      color: "#FF8A00",
    },
  ],
  [
    "CONFIRMED",
    {
      text: "Идёт сбор средств",
      color: "#6FA84B",
    },
  ],
])

export function RenderedTable(props) {
  const [isExpanded, setExpanded] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const {
    toProposal,
    getProposalLink,
    isFetching,
    data,
    load: { handleLoad, hasNextPage },
  } = props

  function getColumns() {
    return [
      {
        title: "Название",
        render: (i, obj) => (
          <TDName key={i}>
            <TDNameLink to={getProposalLink(obj)}>{getOrderName(obj)}</TDNameLink>
          </TDName>
        ),
        placeholder: (i) => <TDName key={i}>_</TDName>,
      },
      {
        title: "№ заявки",
        render: (i, obj) => (
          <TData key={i}>{getOrderTicker(obj, !!obj.cession)}</TData>
        ),
        placeholder: (i) => <TData key={i}>_</TData>,
      },
      {
        title: "",
        render: (i, obj) => (
          <TData key={i}>
            <Statusbar width="70px" value={getStatusbarValue(obj)} />
          </TData>
        ),
        placeholder: (i) => (
          <TData key={i}>
            <Statusbar width="70px" value={0} />
          </TData>
        ),
      },
      {
        title: "Привлечено, ₽",
        render: (i, obj) => <TData key={i}>{getOrderAmount(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0</TData>,
      },
      {
        title: "Ставка",
        render: (i, obj) => <TData key={i}>{getOrderRate(obj)}</TData>,
        placeholder: (i) => <TData key={i}>0%</TData>,
      },
      {
        title: "Срок",
        render: (i, obj) => (
          <TData key={i}>
            {getOrderDuration(obj) && (
              <>
                {getOrderDuration(obj)} <Translate i18n={"models:loan.month"} />
              </>
            )}
            {!getOrderDuration(obj) && "_"}
          </TData>
        ),
        placeholder: (i) => (
          <TData key={i}>
            0 <Translate i18n={"models:loan.month"} />
          </TData>
        ),
      },
      {
        title: "Сбор до",
        render: (i, obj) => (
          <TDDate key={i}>
            {ClockIcon} {getOrderDate(obj)}
          </TDDate>
        ),
        placeholder: (i) => <TDDate key={i}>{ClockIcon} 0</TDDate>,
      },
      {
        title: "Статус",
        render: (i, obj, isHover) => {
          const status = statuses.get(obj.status) || {
            text: "Неизвестно",
            color: "grey",
          }

          return (
            <TDStatus color={status.color} key={i}>
              {isHover && obj.status === "TRANSFER" && (
                <ActionButton>подтвердить</ActionButton>
              )}
              {(!isHover || obj.status !== "TRANSFER") && status.text}
            </TDStatus>
          )
        },
        placeholder: (i) => (
          <TDStatus color={"#6FA84B"} key={i}>
            Идёт сбор средств
          </TDStatus>
        ),
      },
    ]
  }

  const { borrower } = data || {}
  const orders = [...parseOrders(borrower)]
  const columns = getColumns()

  useEffect(() => {
    setLoading(false)
  }, [data?.borrower?.orders?.edges])

  function load() {
    setLoading(true)
    handleLoad()
  }

  return (
    <div>
      {(orders.length > 0 || isFetching) && (
        <>
          <DesktopWrapper>
            <TableWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table
                title="СОБИРАЮТ СРЕДСТВА"
                descr="Если заявки не соберут необходимую сумму, ваши средства будут возвращены."
                columns={columns}
                items={orders}
                isFetching={isFetching}
                isExpanded={isExpanded}
                setExpanded={setExpanded}
              />
            </TableWrapper>
          </DesktopWrapper>

          <MobileWrapper>
            <List
              title="СОБИРАЮТ СРЕДСТВА"
              descr="Если заявки не соберут необходимую сумму, ваши средства будут возвращены."
              items={orders}
              Render={LoanActiveCard}
              isFetching={isFetching}
              toProposal={toProposal}
              isExpanded={isExpanded}
              setExpanded={setExpanded}
            />
          </MobileWrapper>

          {isExpanded && (
            <LoaderBox>
              {hasNextPage && !isLoading && (
                <LoadMore onClick={load}>+ загрузить еще</LoadMore>
              )}
              {hasNextPage && isLoading && <MiniLoader />}
            </LoaderBox>
          )}
        </>
      )}
    </div>
  )
}

export default function (props) {
  const { profile, ...rest } = props

  const InvestmentActiveTable = LoanActiveOrdersListQueryContainer(
    (data) => <RenderedTable {...rest} {...data} />,
    profile,
  )
  return <InvestmentActiveTable />
}
