import styled, { keyframes } from "styled-components"

/* eslint-disable max-len */
const LoadingKeyframes = keyframes`
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ff4a38, 1.8em -1.8em 0 0em rgba(255,74,56, 0.2), 2.5em 0em 0 0em rgba(255,74,56, 0.2), 1.75em 1.75em 0 0em rgba(255,74,56, 0.2), 0em 2.5em 0 0em rgba(255,74,56, 0.2), -1.8em 1.8em 0 0em rgba(255,74,56, 0.2), -2.6em 0em 0 0em rgba(255,74,56, 0.5), -1.8em -1.8em 0 0em rgba(255,74,56, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.7), 1.8em -1.8em 0 0em #ff4a38, 2.5em 0em 0 0em rgba(255,74,56, 0.2), 1.75em 1.75em 0 0em rgba(255,74,56, 0.2), 0em 2.5em 0 0em rgba(255,74,56, 0.2), -1.8em 1.8em 0 0em rgba(255,74,56, 0.2), -2.6em 0em 0 0em rgba(255,74,56, 0.2), -1.8em -1.8em 0 0em rgba(255,74,56, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.5), 1.8em -1.8em 0 0em rgba(255,74,56, 0.7), 2.5em 0em 0 0em #ff4a38, 1.75em 1.75em 0 0em rgba(255,74,56, 0.2), 0em 2.5em 0 0em rgba(255,74,56, 0.2), -1.8em 1.8em 0 0em rgba(255,74,56, 0.2), -2.6em 0em 0 0em rgba(255,74,56, 0.2), -1.8em -1.8em 0 0em rgba(255,74,56, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.2), 1.8em -1.8em 0 0em rgba(255,74,56, 0.5), 2.5em 0em 0 0em rgba(255,74,56, 0.7), 1.75em 1.75em 0 0em #ff4a38, 0em 2.5em 0 0em rgba(255,74,56, 0.2), -1.8em 1.8em 0 0em rgba(255,74,56, 0.2), -2.6em 0em 0 0em rgba(255,74,56, 0.2), -1.8em -1.8em 0 0em rgba(255,74,56, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.2), 1.8em -1.8em 0 0em rgba(255,74,56, 0.2), 2.5em 0em 0 0em rgba(255,74,56, 0.5), 1.75em 1.75em 0 0em rgba(255,74,56, 0.7), 0em 2.5em 0 0em #ff4a38, -1.8em 1.8em 0 0em rgba(255,74,56, 0.2), -2.6em 0em 0 0em rgba(255,74,56, 0.2), -1.8em -1.8em 0 0em rgba(255,74,56, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.2), 1.8em -1.8em 0 0em rgba(255,74,56, 0.2), 2.5em 0em 0 0em rgba(255,74,56, 0.2), 1.75em 1.75em 0 0em rgba(255,74,56, 0.5), 0em 2.5em 0 0em rgba(255,74,56, 0.7), -1.8em 1.8em 0 0em #ff4a38, -2.6em 0em 0 0em rgba(255,74,56, 0.2), -1.8em -1.8em 0 0em rgba(255,74,56, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.2), 1.8em -1.8em 0 0em rgba(255,74,56, 0.2), 2.5em 0em 0 0em rgba(255,74,56, 0.2), 1.75em 1.75em 0 0em rgba(255,74,56, 0.2), 0em 2.5em 0 0em rgba(255,74,56, 0.5), -1.8em 1.8em 0 0em rgba(255,74,56, 0.7), -2.6em 0em 0 0em #ff4a38, -1.8em -1.8em 0 0em rgba(255,74,56, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,74,56, 0.2), 1.8em -1.8em 0 0em rgba(255,74,56, 0.2), 2.5em 0em 0 0em rgba(255,74,56, 0.2), 1.75em 1.75em 0 0em rgba(255,74,56, 0.2), 0em 2.5em 0 0em rgba(255,74,56, 0.2), -1.8em 1.8em 0 0em rgba(255,74,56, 0.5), -2.6em 0em 0 0em rgba(255,74,56, 0.7), -1.8em -1.8em 0 0em #ff4a38;
  }
`

const Loader = styled.div<any>`
  margin: ${({ margin }) => margin};
  font-size: ${({ fontSize }) => fontSize};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${LoadingKeyframes} 1.1s infinite ease;
  transform: translateZ(0);
  display: ${({ display }) => display};
`

Loader.defaultProps = {
  margin: "0px auto",
  display: "block",
}

export default Loader
