import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateLegalEntityProfileMutation(
    $input: UpdateLegalEntityProfileInput!
  ) {
    updateLegalEntityProfile(input: $input) {
      profile {
        id
        name
        inn
        ...Header_profile
        ...ProfileBody_profile
      }
    }
  }
`

export default enhance({ mutation })
