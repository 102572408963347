import styled from "styled-components"

import { Icons } from "src/components"

export const AttachmentTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin: 32px 0 0;
  color: ${({ theme }) => theme.colors.textBlacks[5]};
`

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;

  @media screen and (max-width: 480px) {
    margin-top: 50px;
  }
`

export const UploadButton = styled.label`
  width: 100%;
  max-width: 150px;
  font-family: Geometria, sans-serif;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-transform: uppercase;
  padding: 11px 0 9px;
  text-align: center;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    max-width: 48%;
    height: 50px;
    padding: 16px 0 14px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const SubmitButton = styled.button`
  width: 100%;
  max-width: 150px;
  height: 40px;
  font-family: Geometria, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: white;
  background-color: ${({ theme }) => theme.colors.mainRed};
  padding: 14px 0 12px;
  border-radius: 4px;
  text-transform: uppercase;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 480px) {
    max-width: 48%;
    height: 50px;
    padding: 19px 0 17px;
  }
`

export const DownloadIcon = styled(Icons.Download)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  fill: ${({ theme }) => theme.colors.darkBlue};
`
