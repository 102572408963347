/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LegalEntityPaginationContainer_root = {
    readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly transactions?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly " $fragmentRefs": FragmentRefs<"TransactionItem_transaction">;
                };
            }>;
            readonly pageInfo: {
                readonly hasNextPage: boolean;
                readonly endCursor: unknown | null;
            };
        };
    };
    readonly " $refType": "LegalEntityPaginationContainer_root";
};
export type LegalEntityPaginationContainer_root$data = LegalEntityPaginationContainer_root;
export type LegalEntityPaginationContainer_root$key = {
    readonly " $data"?: LegalEntityPaginationContainer_root$data;
    readonly " $fragmentRefs": FragmentRefs<"LegalEntityPaginationContainer_root">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after",
      "type": "Cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "profileId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "node",
          "transactions"
        ]
      }
    ]
  },
  "name": "LegalEntityPaginationContainer_root",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "profileId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "transactions",
              "args": null,
              "concreteType": "TransactionConnection",
              "kind": "LinkedField",
              "name": "__TransactionHistory_transactions_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TransactionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "TransactionItem_transaction"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LegalEntityProfile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query"
};
})();
(node as any).hash = '457c828e5a8583c0cd23fdf1dcdf6d28';
export default node;
