import React from "react"
import { Route } from "react-router"

import { notifyGTM, pipe } from "src/utils"
import { ViewerAccessContainer } from "src/containers"

import { NewLoanButton } from "../styles"

const handleRoute = (history, to) => () => {
  history.push(to)
}

const clickHandler = (checkAccess, history, to) => () => {
  checkAccess(handleRoute(history, to))
}

function NewLoanLink(props) {
  const { viewer } = props

  const sendGTMEvent = notifyGTM({
    eventAction: "request",
    eventLabel: "click",
    extras: {
      "profile-id": viewer.id,
    },
  })

  return (
    <ViewerAccessContainer mode="withModal" role="borrower">
      {({ checkAccess }) => (
        <Route>
          {({ history }) => (
            <NewLoanButton
              onClick={pipe(
                clickHandler(checkAccess, history, "/new-order"),
                sendGTMEvent,
              )}
            >
              Заявка на заём
            </NewLoanButton>
          )}
        </Route>
      )}
    </ViewerAccessContainer>
  )
}

export default NewLoanLink
