export const notifyGTM = ({
  eventAction,
  eventLabel,
  event = "karma-platform",
  eventCategory = "karma-platform",
  extras = {},
}: any) => () => {
  if ((global as any).dataLayer) {
    (global as any).dataLayer.push({
      eventAction,
      eventLabel,
      event,
      eventCategory,
      ...extras,
    })
  }
}

export const notifyYM = (goal) => () => {
  if ((global as any).yaCounter50918261) {
    (global as any).yaCounter50918261.reachGoal(goal)
  }
}

export const getCid = () => {
  let match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)")
  const raw = match ? decodeURIComponent(match[1]) : null

  if (raw) {
    match = raw.match(/(\d+\.\d+)$/)
  }

  return match ? match[1] : null
}

const utmLabelsKey = "karma_utm_labels"
const isUtmLabel = (key) => !!key && key.slice(0, 3) === "utm"

export const setUtmContent = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const labels = window.localStorage.getItem(utmLabelsKey) || "{}"
  const labelsObj = JSON.parse(labels)

  queryParams.forEach((value, key) => {
    if (isUtmLabel(key)) {
      labelsObj[key] = value
    }
  })

  window.localStorage.setItem(utmLabelsKey, JSON.stringify(labelsObj))
}

export const getUtmContent = () => {
  try {
    const labels = window.localStorage.getItem(utmLabelsKey) || "{}"
    const labelsObj = JSON.parse(labels)

    if (!Object.keys(labelsObj).length) {
      return null
    }

    const urlParams = new URLSearchParams()
    Object.keys(labelsObj).forEach((key) => urlParams.set(key, labelsObj[key]))

    return urlParams.toString()
  } catch {
    return null
  }
}
