import styled from "styled-components"

const MinValue = styled.div<any>`
  height: 12px;
  z-index: 4;
  width: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ gathered }) => `${gathered}%`};
  background-color: ${({ theme }) => theme.colors.greyDarkest};
`

export default MinValue
