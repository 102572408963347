import React from "react"
import idx from "idx"

import { Box } from "src/components"

import { Name, NameContainer } from "./styles"

const shouldRenderName = (application) => idx(application, (_) => _.data.companyName)

const renderName = (application) => (shouldRenderName(application) ? application.data.companyName : "Без названия")

export default (props) => {
  const { application, linkPath } = props

  return (
    <Box>
      <NameContainer>
        <Name href={linkPath} target="_blank">
          {renderName(application)}
        </Name>
      </NameContainer>
    </Box>
  )
}
