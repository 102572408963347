import i18n from "src/i18n"
import React from "react"

import { isRussianUser } from "src/utils"
import { ViewerDataConsumer } from "src/context"
import { Box, Modal, RedirectButton } from "src/components"

const render = (props) => {
  const pathSuffix = isRussianUser(props.viewer) ? "individual" : "foreign"

  return (
    <Modal.Basic onClose={props.onClose}>
      <Box mb={20}>
        {i18n.t(
          "components:user-page-header.modals.create-individual-profile.description",
        )}
      </Box>

      <RedirectButton
        onClick={props.onClose}
        to={`/accounts/create/${pathSuffix}`}
      >
        {i18n.t(
          "components:user-page-header.modals.create-individual-profile.button",
        )}
      </RedirectButton>
    </Modal.Basic>
  )
}

export default (props) => (
  <ViewerDataConsumer>
    {(viewer) => render({ ...props, viewer })}
  </ViewerDataConsumer>
)
