import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation SetViewerCurrencyMutation($input: SetViewerCurrencyInput!) {
    setViewerCurrency(input: $input) {
      viewer {
        id
      }
    }
  }
`

export default enhance({ mutation })
