import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestPhoneConfirmationMutation(
    $input: RequestPhoneConfirmationInput!
  ) {
    requestPhoneConfirmation(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
