import styled from "styled-components"

export const Table = styled.table`
  width: min-content;
`

export const Row = styled.tr``

export const IconCell = styled.td<{ fill: string}>`
  text-align: center;
  vertical-align: middle;

  svg path {
    fill: ${(props) => props.fill} !important;
  }
`

export const StatusCell = styled.td`
  text-align: left;
  color: ${(props) => props.color};
`
