import React from "react"

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#9EA6AE"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m0 1.4a6.6 6.6 0 1 1-.01 13.21A6.6 6.6 0 0 1 8 1.4"
      />
      <path stroke="#9EA6AE" strokeWidth="1.4" d="M8 4.12v4.1" />
      <path fill="#9EA6AE" d="M9 10.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
    </g>
  </svg>
)
