import React from "react"

import { useInput } from "src/hooks"
import { createChainedFunction } from "src/utils"
import {
  AddEntrepreneurProfile,
  AddIndividualProfile,
  CreateForeignIndividualProfile,
} from "src/mutations"

const getRussianInvestorInput = (profileData) => {
  const input = {
    firstName: profileData.firstName,
    lastName: profileData.lastName,
    middleName: profileData.middleName,
    phone: profileData.phone,
  }

  return { input }
}

const getForeignInvestorInput = (profileData) => {
  const input = {
    attributes: {
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      middleName: profileData.middleName,
      phone: profileData.phone,
    },
    shouldSkipValidation: true,
  }

  return { input }
}

// eslint-disable-next-line
export const getMutationAndVariables = (viewer, profileData) => {
  if (viewer.country !== "RU") {
    return [CreateForeignIndividualProfile, getForeignInvestorInput(profileData)]
  }

  return profileData.isEntrepreneur
    ? [AddEntrepreneurProfile, getRussianInvestorInput(profileData)]
    : [AddIndividualProfile, getRussianInvestorInput(profileData)]
}

export const useCountDown = (timeToCount = 60 * 1000, interval = 1000) => {
  const [secondsLeft, setSecondsLeft] = React.useState(0)
  const start = React.useCallback(() => setSecondsLeft(timeToCount / 1000), [])

  React.useEffect(() => {
    if (secondsLeft === 0) {
      return
    }

    window.setTimeout(() => {
      const nextSecondsLeft = secondsLeft - 1 > 0 ? secondsLeft - 1 : 0
      setSecondsLeft(nextSecondsLeft)
    }, interval)
  }, [secondsLeft])

  return [secondsLeft, start]
}

export const useTokenInput = (clearError) => {
  const token = useInput("")

  const clearFieldError = (path) => () => clearError(path)

  token.onChange = React.useCallback(
    createChainedFunction(
      clearFieldError("confirmPhone.token"),
      token.onChange,
    ),
    [],
  )

  return token
}
