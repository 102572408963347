import React from "react"
import styled from "styled-components"
import {
  display,
  alignSelf,
  alignItems,
  alignContent,
  flex,
  flexWrap,
  flexBasis,
  flexDirection,
  justifySelf,
  justifyContent,
  borderRadius,
} from "styled-system"
import Box from "../Box"

const Component = styled(Box)`
  ${display}
  ${alignSelf}
  ${alignItems}
  ${alignContent}
  ${flex}
  ${flexWrap}
  ${flexBasis}
  ${flexDirection}
  ${justifySelf}
  ${justifyContent}
  ${borderRadius}
  position: ${({ position }) => position || "relative"};
`

Component.defaultProps = {
  display: "flex",
}

const render = (props) => <Component {...props} />

export default render
