import styled from "styled-components"

export const LoadMoreButton = styled.button`
  padding: 0;
  text-transform: uppercase;
  vertical-align: middle;
  padding: 30px 0;
  font-family: Geometria;
  font-size: 13px;
  line-height: 16px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
  }
`
