import styled from "styled-components"

export const StatusbarContainer = styled.div<any>`
  width: ${(props) => `${props.width}` || "70px"};
  height: 10px;
  position: relative;
  margin: 0;
  ${(props) => `${props.value}%` || 0};
  flex-shrink: 0;
`
export const StatusBarFilled = styled.div<any>`
  height: 100%;
  background-color: #6fa84b;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: ${(props) => `${props.value}%` || 0};
`
export const StatusBarEmpty = styled.div`
  background-color: #e6eaee;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`
