import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { OutlinedButton } from "src/components"

import { IllustrationIcon } from "./icons"

export const Container = styled.div`
  font-family: Geometria, sans-serif;
  position: relative;
  width: 100%;
  background-color: rgba(247, 0, 0, 0.04);
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 22px 32px;
  display: flex;
  margin-bottom: 60px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }
`

export const Image = styled(IllustrationIcon)`
  display: block;
  min-width: 65px;
  min-height: 65px;
  max-width: 65px;
  max-height: 65px;
  margin-right: 24px;

  @media screen and (max-width: 800px) {
    margin-right: 16px;
  }
`

export const FlexContainer = styled.div`
  position: relative;
  display: flex;
`

export const MainContainer = styled(FlexContainer)`
  width: calc(100% - 90px);

  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: calc(100% - 85px);
  }
`

export const ButtonContainer = styled(FlexContainer)`
  width: 70%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export const Title = styled.h2`
  width: 30%;
  position: relative;
  font-style: normal;
  margin-right: 24px;
  font-weight: 600;
  align-self: center;
  font-size: 18px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.textBlacks[8]};

  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 22px;
    margin: 0 0 18px 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 8px;
  }
`

export const Description = styled.p`
  width: 60%;
  position: relative;
  align-self: center;
  font-size: 12px;
  line-height: 1.5;
  margin: 0 32px 0 0;
  color: ${({ theme }) => theme.colors.textBlack};

  @media screen and (max-width: 800px) {
    width: calc(100% - 180px);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
`

export const Link = styled(NavLink)`
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  display: inline;
  text-decoration: underline;
  color: blue;
`

export const Button = styled(OutlinedButton)`
  align-self: center;
  font-family: Geometria, sans-serif;
  width: 212px;
  padding: 16px 0 13px;
  background-color: ${({ theme }) => theme.colors.mainRed};
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-left: auto;

  @media screen and (max-width: 800px) {
    width: 180px;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const MobileButton = styled(Button)`
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
    width: 100%;
    margin: 18px 0 0;
    font-size: 14px;
    padding: 23px 0 21px;
  }
`
