import React from "react"

import { parseDate } from "src/utils"
import { Text, Translate } from "src/components"

const render = ({ date }) => {
  if (!date) return null

  return (
    <Text fontSize="14px" color="greyDark" textAlign="center">
      <Translate
        i18n="components:account.password_update_form.last_update"
        date={parseDate(date)}
      />
    </Text>
  )
}

export default React.memo(render)
