import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RemoveInvestorAutoInvestmentsParamsMutation(
    $input: removeInvestorAutoInvestmentsParamsInput!
  ) {
    removeInvestorAutoInvestmentsParams(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
