import React from "react"

import { Box, Translate } from "src/components"

import {
  Link, Bold, Container, UnorderedList,
} from "./styles"

import {
  Hero, TextBox, Heading, InfoText, SubHeading,
} from "./elements"

import * as tables from "./tables"
import Table from "./Table"

/* eslint-disable max-len */
const InvestorInfo = () => (
  <Container>
    <Hero>
      <Translate i18n="components:investor_info.title" />
    </Hero>
    <TextBox mb={64}>
      <InfoText>
        <Bold>
          <Translate i18n="components:investor_info.subtitle" />
        </Bold>
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.why.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.why.contents.0" />
      </InfoText>
      <InfoText>
        <Translate i18n="components:investor_info.why.contents.1" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.why.tables.first.title" />
      </InfoText>
    </TextBox>
    <Box mb={30}>
      <Table scheme={tables.first} />
    </Box>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.why.tables.second.title" />
      </InfoText>
    </TextBox>
    <Box mb={30}>
      <Table scheme={tables.second} />
    </Box>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.why.tables.third.title" />
      </InfoText>
    </TextBox>
    <Box mb={30}>
      <Table scheme={tables.third} />
    </Box>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.why.contents.2" />
      </InfoText>
      <Link
        href="https://docs.google.com/spreadsheets/d/1GnDhMkr4KgMB_6_Ja65TDzz3_S90SCWU1aXNf4PexEo/edit#gid=0"
        target="_blank"
      >
        https://docs.google.com/spreadsheets/d/1GnDhMkr4KgMB_6_Ja65TDzz3_S90SCWU1aXNf4PexEo/edit#gid=0
      </Link>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.risks.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.risks.contents.0" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.risks.contents.1" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.risks.contents.2" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.risks.contents.3" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.risks.contents.4" />
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.choose_borrower.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.choose_borrower.contents.0" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.choose_borrower.contents.1" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.choose_borrower.contents.2" />
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.returns.title" />
    </Heading>
    <TextBox>
      <SubHeading>
        <Translate i18n="components:investor_info.returns.contents.0" />
      </SubHeading>
      <Box ml={18}>
        <InfoText>
          <Translate i18n="components:investor_info.returns.contents.1" />
        </InfoText>
      </Box>
    </TextBox>
    <TextBox>
      <SubHeading>
        <Translate i18n="components:investor_info.returns.contents.2" />
      </SubHeading>
      <Box ml={18}>
        <InfoText>
          <Translate i18n="components:investor_info.returns.contents.3" />
        </InfoText>
      </Box>
    </TextBox>
    <TextBox>
      <SubHeading>
        <Translate i18n="components:investor_info.returns.contents.4" />
      </SubHeading>
      <Box ml={18}>
        <InfoText>
          <Translate i18n="components:investor_info.returns.contents.5" />
        </InfoText>
      </Box>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.collateral.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.collateral.contents.0" />
      </InfoText>
    </TextBox>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.collateral.contents.1" />
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.taxes.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.taxes.contents.0" />
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.invest.title" />
    </Heading>
    <TextBox>
      <UnorderedList>
        <li>
          <InfoText>
            <Bold>
              <Translate i18n="components:investor_info.invest.contents.0" />
            </Bold>
          </InfoText>
          <InfoText>
            <Translate i18n="components:investor_info.invest.contents.1" />
          </InfoText>
        </li>
        <li>
          <InfoText>
            <Bold>
              <Translate i18n="components:investor_info.invest.contents.2" />
            </Bold>
          </InfoText>
          <InfoText>
            <Translate i18n="components:investor_info.invest.contents.3" />
          </InfoText>
        </li>
      </UnorderedList>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.neshmagla.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.neshmagla.contents.0" />
      </InfoText>
    </TextBox>
    <Heading>
      <Translate i18n="components:investor_info.withdrawal.title" />
    </Heading>
    <TextBox>
      <InfoText>
        <Translate i18n="components:investor_info.withdrawal.contents.0" />
      </InfoText>
    </TextBox>
  </Container>
)

export default InvestorInfo
