import React from "react"

import { Box, UploadButton, RemovableList } from "src/components"

const AttachmentsButton = (props) => {
  const {
    name, value, onChange, multiple, ...rest
  } = props

  const extractTitle = React.useCallback((item) => item.filename, [])

  const items = React.useMemo(() => (multiple ? value : [value]), [value])

  const onValueChange = React.useCallback(
    (uploads) => {
      onChange({
        name,
        value: multiple ? [...value, ...uploads] : uploads,
      })
    },
    [name, value, onChange],
  )

  const onItemDelete = React.useCallback(
    (itemToDelete) => {
      const newValue = multiple
        ? value.filter((file) => file.id !== itemToDelete.id)
        : null

      onChange({ name, value: newValue })
    },
    [onChange, value],
  )

  return (
    <Box>
      {(multiple || !value) && (
        <UploadButton {...rest} multiple={multiple} onChange={onValueChange} />
      )}
      {((multiple && !!items.length) || !!value) && (
        <RemovableList
          items={items}
          onItemDelete={onItemDelete}
          extractTitle={extractTitle}
        />
      )}
    </Box>
  )
}

export default AttachmentsButton
