import styled from "styled-components"

export const Container = styled.div`
  font-family: "Geometria";
  font-size: 18px;
  line-height: 23px;
  padding: 40px 0 0 0;
  & p {
    font-size: 16px;
    line-height: 20px;
    color: rgba(74, 74, 74, 0.9);
  }
  & p:first-of-type {
    margin-top: 30px;
  }
`

export const SubTitle = styled.h5`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 13px;
  color: rgba(74, 74, 74, 0.9);
  font-weight: 600;
`

export const Title = styled.h6`
  font-size: 18px;
  margin: 0;
  color: rgba(74, 74, 74, 0.9);
`

export const List = styled.ul`
  margin: 5px 0 20px 0;
  list-style: none;
  padding: 0;
  color: rgba(74, 74, 74, 0.9);
  line-height: 1.6rem;
  & li {
    margin: 0;
    position: relative;
    margin: 0 0 0 20px;
    line-height: 1.6;
  }
  & li::before {
    content: "\\2022";
    position: absolute;
    left: -18px;
    top: 1px;
    color: #f70000;
    font-weight: bold;
    display: inline-block;
    width: 5px;
    margin-right: 10px;
  }
  @media (max-width: 800px) {
    margin-top: 10px;
  }
  @media (max-width: 480px) {
    margin-top: 18px;
  }
`
