/* eslint-disable max-len */

import React from "react"

export const ClockIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4811 3.9705C11.7957 2.37259 10.631 1.21047 9.01977
    0.523275C8.21445 0.179816 7.37075 0.00437927 6.48932 0.00437927C5.60803
    0.00437927 4.77055 0.178159 3.96613 0.523275C2.36821 1.20864 1.19836
    2.37002 0.511758 3.9705C0.167704 4.77234 0 5.61239 0 6.49367C0 7.37512
    0.167704 8.22214 0.511758 9.02415C1.19836 10.6246 2.36836 11.793 3.96613
    12.4785C4.7707 12.8236 5.60801 12.9972 6.48932 12.9972C7.37062 12.9972
    8.21445 12.8218 9.01964 12.4785C10.6309 11.7913 11.7956 10.6219 12.4811
    9.02415C12.8262 8.21957 13 7.37512 13 6.49367C13 5.61239 12.8264 4.77506
    12.4811 3.9705ZM11.4435 9.36533C10.9332 10.2404 10.2342 10.9346 9.35382
    11.4479C8.47876 11.9581 7.51997 12.2154 6.48946 12.2154C5.71477 12.2154
    4.97556 12.0662 4.27182 11.7605C3.5681 11.455 2.9605 11.0533 2.45222
    10.5452C1.94395 10.0369 1.5394 9.42339 1.23689 8.71845C0.935278 8.01609
    0.789113 7.26853 0.789113 6.49369C0.789113 5.46304 1.04234 4.50911 1.54972
    3.63648C2.05891 2.76065 2.75808 2.06571 3.63225 1.55393C4.50626 1.04217
    5.45883 0.786206 6.48932 0.786206C7.51997 0.786206 8.47859 1.04368 9.35367
    1.55393C10.2341 2.06722 10.9316 2.76214 11.4433 3.63645C11.9551 4.51062
    12.2109 5.46304 12.2109 6.49367C12.2109 7.52435 11.9568 8.48477 11.4435
    9.36533Z"
      fill="#4A4A4A"
    />
    <path
      d="M6.9016 6.43601V2.75418C6.9016 2.53386 6.70964 2.35616 6.48929
    2.35616C6.28323 2.35616 6.09838 2.53386 6.09838 2.75418V6.49285C6.09838
    6.49999 6.11263 6.5285 6.11263 6.54974C6.09838 6.6777 6.13402 6.78431
    6.21925 6.86968L8.34456 8.99484C8.48677 9.13705 8.7497 9.13705 8.89181
    8.99484C9.05524 8.83138 9.0445 8.59422 8.89181 8.44045L6.9016 6.43601Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const GraphIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6562 1.5625H16.7188V0H15.1562V1.5625H4.84375V0H3.28125V1.5625H2.34375C1.05141 1.5625 0 2.61391 0 3.90625V17.6562C0 18.9486 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9486 20 17.6562V3.90625C20 2.61391 18.9486 1.5625 17.6562 1.5625ZM18.4375 17.6562C18.4375 18.087 18.087 18.4375 17.6562 18.4375H2.34375C1.91297 18.4375 1.5625 18.087 1.5625 17.6562V7.34375H18.4375V17.6562ZM18.4375 5.78125H1.5625V3.90625C1.5625 3.47547 1.91297 3.125 2.34375 3.125H3.28125V4.6875H4.84375V3.125H15.1562V4.6875H16.7188V3.125H17.6562C18.087 3.125 18.4375 3.47547 18.4375 3.90625V5.78125Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 8.98438H2.96875V10.5469H4.53125V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 8.98438H6.09375V10.5469H7.65625V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 8.98438H9.21875V10.5469H10.7812V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 8.98438H12.3438V10.5469H13.9062V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 8.98438H15.4688V10.5469H17.0312V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 12.1094H2.96875V13.6719H4.53125V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 12.1094H6.09375V13.6719H7.65625V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 12.1094H9.21875V13.6719H10.7812V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 12.1094H12.3438V13.6719H13.9062V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 15.2344H2.96875V16.7969H4.53125V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 15.2344H6.09375V16.7969H7.65625V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 15.2344H9.21875V16.7969H10.7812V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 15.2344H12.3438V16.7969H13.9062V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 12.1094H15.4688V13.6719H17.0312V12.1094Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const DeclineIcon = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85 8.45H7.15V9.75H5.85V8.45ZM5.85 3.25H7.15V7.15H5.85V3.25ZM6.4935 0C2.9055 0 0 2.912 0 6.5C0 10.088 2.9055 13 6.4935 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.4935 0ZM6.5 11.7C3.627 11.7 1.3 9.373 1.3 6.5C1.3 3.627 3.627 1.3 6.5 1.3C9.373 1.3 11.7 3.627 11.7 6.5C11.7 9.373 9.373 11.7 6.5 11.7Z"
      fill="#F70000"
    />
  </svg>
)
