import React from "react"

import { DefaultModal } from "src/components"

import ModalType from "./Content"
import { initialStateFromOrder } from "./modalUtils"

const BankModal = (props) => {
  const ModalBody = ModalType[props.type]
  const order = initialStateFromOrder(props.order)

  return (
    <DefaultModal
      padding="30px 40px"
      onClose={props.onClose}
      heightRestriction={false}
    >
      <ModalBody order={order} />
    </DefaultModal>
  )
}

export default BankModal
