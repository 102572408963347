import React from "react"

import {
  Box,
  Text,
  Flex,
  Button,
  Heading,
  Document,
  Translate,
} from "src/components"

import HeaderWrapper from "../Fee/styles"
import { normalizeDocuments } from "../../instructionsModalUtils"

export default (props) => {
  const { order, onClose, onNextStep } = props

  const documents = normalizeDocuments(order)

  return (
    <React.Fragment>
      <Box width="700px">
        <HeaderWrapper>
          <Heading.h5>
            <Translate i18n="components:instructions_modal.invest.header" />
          </Heading.h5>
        </HeaderWrapper>
        <Box mt="15px" mb="35px">
          <Text color="red" fontWeight="500">
            <Translate i18n="components:instructions_modal.invest.approve_description" />
          </Text>
        </Box>
        {documents.map((doc, idx) => (
          <Box mb="20px" key={idx}>
            <Document doc={doc} />
          </Box>
        ))}
        <Box mt="25px" mb="30px">
          <Text color="greyDarkest" lineHeight="1.5">
            <Translate i18n="components:instructions_modal.invest.approve_condition" />
          </Text>
        </Box>
        <Flex justifyContent="flex-end">
          <Box mr="20px">
            <Button height="32px" variant="default" onClick={onClose}>
              <Translate i18n="common:cancel" />
            </Button>
          </Box>
          <Button height="32px" onClick={onNextStep}>
            <Translate i18n="components:accreditation.individual.buttons.continue" />
          </Button>
        </Flex>
      </Box>
    </React.Fragment>
  )
}
