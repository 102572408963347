/* eslint-disable max-len */
import React from "react"

export const Shevrone = (props) => (
  <svg
    className={props.className}
    onClick={props.onClick}
    style={props.style}
    viewBox="0 0 14 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.390381 19.7237L8.59622 11.5L0.390381 3.27625L2.91663 0.75L13.6666 11.5L2.91663 22.25L0.390381 19.7237Z"
      fill="#FFF"
    />
  </svg>
)
