import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ProfileTransactionsListQuery($id: ID!, $cursor: Cursor) {
    node(id: $id) {
      id
      __typename
      ... on EntrepreneurProfile {
        transactions(after: $cursor) {
          ...ProfileTransactionsList_list @relay(mask: false)
        }
      }
      ... on ForeignIndividualProfile {
        transactions(after: $cursor) {
          ...ProfileTransactionsList_list @relay(mask: false)
        }
      }
      ... on IndividualProfile {
        transactions(after: $cursor) {
          ...ProfileTransactionsList_list @relay(mask: false)
        }
      }
      ... on LegalEntityProfile {
        transactions(after: $cursor) {
          ...ProfileTransactionsList_list @relay(mask: false)
        }
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fragment = graphql`
  fragment ProfileTransactionsList_list on TransactionConnection
    @relay(mask: false) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        date
        type
        amount
        description
        toAccount {
          ... on FinancialTransactionProfileAccount {
            id
            profile {
              id
              name
            }
            foreignProfile {
              id
              firstName
              lastName
            }
          }
          ... on FinancialTransactionSharedWalletAccount {
            id
            order {
              id
              application {
                shortTitle
                longTitle
              }
              chain {
                id
              }
            }
          }
        }
        fromAccount {
          ... on FinancialTransactionProfileAccount {
            id
            profile {
              id
              name
            }
            foreignProfile {
              id
              firstName
              lastName
            }
          }
          ... on FinancialTransactionSharedWalletAccount {
            id
            order {
              id
              application {
                shortTitle
                longTitle
              }
              chain {
                id
              }
            }
          }
        }
      }
    }
  }
`

const Container = (render) => ({ profileId, cursor, ...rest }) => {
  const variables = {
    id: profileId,
    cursor,
  }
  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

Container.query = query

export default Container
