import styled from "styled-components"

export * from "./wrappers"

export const Container = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => props.direction || "row"};
  width: 100%;
  margin-top: 32px;

  @media screen and (max-width: 480px) {
    margin-top: 24px;
    flex-wrap: wrap;
  }
`

export const TextBlock = styled.div`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
  max-width: 400px;
  width: 100%;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
    max-width: 100%;
    margin-bottom: 14px;
  }
`

export const OutlinedButton = styled.button`
  padding: 0;
  margin: 0;
  font-family: Geometria;
  font-size: 10px;
  line-height: 12px;
  border: 1px solid #f70000;
  color: #f70000;
  background-color: inherit;
  border-radius: 4px;
  width: 160px;
  height: 45px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 61px;
    font-size: 20px;
    line-height: 25px;
  }
`

export const Divider = styled.div`
  position: relative;
  margin-top: 32px;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
`

export const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 32px;
`
