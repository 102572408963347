import React from "react"

import {
  Title, Value, Container, Description,
} from "./styles"

function InfoBlock(props) {
  const {
    title, value, bold, descr,
  } = props

  return (
    <Container>
      <Title>{title}</Title>
      <Value bold={bold}>{value}</Value>
      {!!descr && <Description>{descr}</Description>}
    </Container>
  )
}

export default InfoBlock
