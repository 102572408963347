import React from "react"
import styled from "styled-components"

import { Box, Flex } from "src/components"

export const DeleteIcon = styled.div<any>`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-left: 16px;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/images/icons/delete.svg);
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`

export const InputContainer = styled(Box)`
  flex: 1;
`

export const DefaultInputContainer = styled(Box)`
  > div {
    margin-bottom: 0;
  }
`

const AddSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#306ECF" />
      <g stroke="#FFF" strokeWidth="2">
        <path d="M12 6v12M6 12h12" />
      </g>
    </g>
  </svg>
)

const AddIconContainer = styled(Box)`
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  margin-right: 15px;
`

export const AddIcon = () => (
  <AddIconContainer>
    <AddSign />
  </AddIconContainer>
)

export const AddMoreContainer = styled(Flex)`
  cursor: pointer;
`
