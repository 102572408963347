const InputsSchema = [
  [
    {
      type: "text",
      name: "series",
      width: "100%",
      maxWidth: "32%",
      mask: "9999 / 999999",
      errorPaths: ["series", "number"],
      rootErrorKeywords: ["uniqueness"],
      label: "components:individual_profile_form.placeholders.series",
    },
    {
      type: "text",
      name: "issuedAt",
      width: "100%",
      maxWidth: "32%",
      mask: "99 - 99 - 9999",
      errorPath: "issuedAt",
      label: "components:individual_profile_form.placeholders.issuedAt",
    },
    {
      type: "text",
      name: "code",
      width: "100%",
      maxWidth: "32%",
      mask: "999 - 999",
      errorPath: "issuedBy.code",
      label: "components:individual_profile_form.placeholders.issuedBy.code",
    },
  ],
  [
    {
      type: "text",
      name: "name",
      width: "100%",
      label: "components:individual_profile_form.placeholders.issuedBy.name",
      errorPath: "issuedBy.name",
    },
  ],
]

export default InputsSchema
