import React from "react"

import { CurrencySigns } from "src/constants"
import { MutationContainer } from "src/containers"
import { RelayEnvironmentConsumer } from "src/context"

import { ExchangeCurrency, RequestProfilePhoneConfirmation } from "src/mutations"

import {
  Box,
  Flex,
  Button,
  CountDown,
  Translate,
  FormLoadingButton,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"

import { ModalHeader } from "./elements"
import { StyledText } from "./styles"

class Confirmation extends React.PureComponent<any, any> {
  state = {
    code: "",
    busy: false,
    codeSent: false,
  }

  componentDidMount() {
    this.onCodeRequest()
    this.props.startTimer()
  }

  onCodeRequest = () => {
    const variables = {
      input: {
        profileId: this.props.profile.id,
        kind: "EXCHANGE_CURRENCY",
      },
    }

    const callbacks = {
      onError: this.onCodeRequestError,
      onCompleted: this.onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )

    this.setState({ busy: true })
  }

  onCodeRequestSuccess = () => {
    this.setState({ codeSent: true, busy: false })
  }

  onCodeRequestError = () => {
    this.props.clearTimer()
    this.setState({ busy: false })
  }

  onCodeResend = () => {
    this.onCodeRequest()
    this.props.restartTimer()
  }

  onCodeConfirm = () => {
    this.setState({ busy: true })

    const variables = {
      input: {
        profileId: this.props.profile.id,
        amount: Number(this.props.amount.replace(",", ".").replace(/\s/g, "")),
        fromCurrency: this.props.balanceTargets.from.currency,
        toCurrency: this.props.balanceTargets.to.currency,
      },
    }

    const callbacks = {
      onError: this.onCodeConfirmError,
      onCompleted: this.onCodeConfirmSuccess,
    }

    this.props.commit({ callbacks, variables })
  }

  onCodeConfirmSuccess = () => {
    this.props.onComplete()
  }

  onCodeConfirmError = () => {
    this.setState({ busy: false })
  }

  onChange = (e) => {
    this.setState({ code: e.target.value })
    this.props.clearError("exchangeCurrency.token")
  }

  render() {
    const { code, busy, codeSent } = this.state

    const {
      amount,
      profile,
      getError,
      secondsLeft,
      balanceTargets,
    } = this.props

    const error = getError("exchangeCurrency.token")

    return (
      <>
        <ModalHeader
          i18n="components:account.operations.convert.confirmation.title"
          amount={amount}
          currency={CurrencySigns[balanceTargets.from.currency]}
        />
        <Box m="20px 0">
          <StyledText>
            <Translate
              i18n="components:account.operations.withdraw.confirmation.to_number"
              number={profile.phone}
            />
          </StyledText>
        </Box>
        <FormInput
          autoFocus
          name="phone_confirmation"
          value={code}
          error={error}
          onChange={this.onChange}
          label="components:account.bank_account.confirm.label"
        />
        <ResendRequestButton nowrap secondsLeft={secondsLeft} />
        <Flex justifyContent="flex-end" mt="40px">
          <Box mr="20px">
            <Button
              variant="default"
              height="48px"
              disabled={!!secondsLeft || !codeSent}
              onClick={this.onCodeResend}
            >
              <Translate
                ns="components"
                i18n="account.bank_account.confirm.buttons.resend"
              />
            </Button>
          </Box>
          <FormLoadingButton
            variant="blueWide"
            height="48px"
            disabled={!code}
            isLoading={busy}
            onClick={this.onCodeConfirm}
          >
            <Translate
              ns="components"
              i18n="account.bank_account.confirm.buttons.continue"
            />
          </FormLoadingButton>
        </Flex>
      </>
    )
  }
}

export default (props) => (
  <RelayEnvironmentConsumer>
    {({ environment }) => (
      <CountDown>
        {({
          start, clear, restart, secondsLeft,
        }) => (
          <MutationContainer mutation={ExchangeCurrency}>
            {({ commit, getError, clearError }) => (
              <Confirmation
                {...props}
                commit={commit}
                getError={getError}
                clearError={clearError}
                startTimer={start}
                clearTimer={clear}
                restartTimer={restart}
                secondsLeft={secondsLeft}
                environment={environment}
              />
            )}
          </MutationContainer>
        )}
      </CountDown>
    )}
  </RelayEnvironmentConsumer>
)
