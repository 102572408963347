import React from "react"
import { Route } from "react-router"
import { fetchQuery, graphql } from "react-relay"

import { isBorrower } from "src/utils"
import { useEnvironment } from "src/hooks"
import { ExpressScoring } from "src/components"

import PendingModal from "./PendingModal"

const query = graphql`
  query QuestionaryOrActionQuery {
    viewer {
      id
      profiles {
        __typename
      }
      questionaries {
        id
        state {
          status
        }
      }
    }
  }
`

const isQuestionaryPending = (questionary) => !!questionary && ["INITIAL", "PENDING"].includes(questionary.state.status)

const QuestionaryOrAction = (props) => {
  const { action, failureAction, link } = props

  const { environment } = useEnvironment()
  const [busy, setBusy] = React.useState(false)
  const [questionaryModal, setQuestionaryModal] = React.useState(false)
  const [pendingModal, setPendingModal] = React.useState(false)

  const onClose = React.useCallback(() => {
    setPendingModal(false)
    setQuestionaryModal(false)
  }, [])

  const onComplete = ({ viewer }) => {
    const questionary = viewer.questionaries[0]
    const hasBorrowerProfile = viewer.profiles.some(isBorrower)

    if (!questionary && !hasBorrowerProfile) {
      setQuestionaryModal(true)
      if (typeof failureAction === "function") failureAction()
      return
    }

    if (isQuestionaryPending(questionary)) {
      setPendingModal(true)
      if (typeof failureAction === "function") failureAction()
      return
    }

    if (typeof action === "function") action()
    if (typeof link === "string") props.history.push(link)
  }

  const onClick = React.useCallback(() => {
    if (props.viewer) {
      onComplete({ viewer: props.viewer })
      return
    }

    if (busy) return

    setBusy(true)
    fetchQuery(environment, query, undefined)
      .then(onComplete)
      .finally(() => setBusy(false))
  }, [busy])

  return (
    <>
      {questionaryModal && (
        <ExpressScoring
          onClose={onClose}
          target={props.target}
          onCompleted={props.onCompleted}
        />
      )}
      {pendingModal && <PendingModal onClose={onClose} />}
      {props.children({ onClick, busy })}
    </>
  )
}

export default (props) => (
  <Route>
    {({ history }) => <QuestionaryOrAction {...props} history={history} />}
  </Route>
)
