import React from "react"

import { Box } from "src/components"

import { Form, FooterContainer, ContentContainer } from "./styles"

const renderHeader = (header) => (header ? <Box>{header()}</Box> : null)

const renderSubHeader = (subHeader) => (subHeader ? (
    <Box pl={40} pt={12} fontSize={"16px"}>
      {subHeader()}
    </Box>
) : null)

const renderContent = (content) => (content ? <ContentContainer>{content()}</ContentContainer> : null)

const renderFooter = (footer) => (footer ? <FooterContainer>{footer()}</FooterContainer> : null)

const render = (props) => {
  const {
    header, subHeader, content, footer, extra, wrapper,
  } = props

  const Wrapper = wrapper

  return (
    <Wrapper>
      <Form>
        {renderHeader(header)}
        {renderSubHeader(subHeader)}
        {renderContent(content)}
        {renderFooter(footer)}
        {extra && extra()}
      </Form>
    </Wrapper>
  )
}

render.defaultProps = {
  wrapper: React.Fragment,
}

export default render
