/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type ExchangeRateQueryVariables = {};
export type ExchangeRateQueryResponse = {
    readonly exchangeRates: ReadonlyArray<{
        readonly buy: CurrencyEnum;
        readonly sell: CurrencyEnum;
        readonly price: number;
    }>;
};
export type ExchangeRateQuery = {
    readonly response: ExchangeRateQueryResponse;
    readonly variables: ExchangeRateQueryVariables;
};



/*
query ExchangeRateQuery {
  exchangeRates {
    buy
    sell
    price
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExchangeRate",
    "kind": "LinkedField",
    "name": "exchangeRates",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sell",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExchangeRateQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExchangeRateQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ExchangeRateQuery",
    "operationKind": "query",
    "text": "query ExchangeRateQuery {\n  exchangeRates {\n    buy\n    sell\n    price\n  }\n}\n"
  }
};
})();
(node as any).hash = '7b67cb45ea55bcd5a4ea302f12fbe87d';
export default node;
