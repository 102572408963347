import React from "react"

import { number, getProfileName, getProfileDetailedBalance } from "src/utils"

import { Box, Text, Translate } from "src/components"

import { Divider, WithdrawProfileContainer } from "./styles"

export const ModalHeader = (props) => (
  <>
    <Box mb="20px">
      <Text fontSize="24px" fontWeight="500" lineHeight="1.25">
        <Translate {...props} />
      </Text>
    </Box>
    <Divider />
  </>
)

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const ProfileInfo = ({ profile, bordered }: any) => {
  const detailedBalances = getProfileDetailedBalance(profile)
  const balance = detailedBalances.find((item) => item.currency === "RUB") || {
    available: 0,
  }

  return (
    <WithdrawProfileContainer bordered={bordered}>
      <Text color="greyShuttle" lineHeight="1.5">
        {getProfileName(profile)}
      </Text>
      <Text fontSize="11px" lineHeight="1.5" color="greyDark">
        {number(balance.available, options)} ₽
      </Text>
    </WithdrawProfileContainer>
  )
}

export const CurrencyTooltip = React.memo(() => <Text fontSize="20px">₽</Text>)
