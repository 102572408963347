import React, { useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router"

import { FloatingLabelInput } from "src/components"
import { MutationContainer } from "src/containers"
import { SetViewerEmail } from "src/mutations"
import { useEnvironment } from "src/hooks"
import { getURLSearchParams } from "src/utils"

import { Container } from "../styles"
import { Button, Title } from "../../../../molecules/NewPasswordModal/styles"

const ConfirmationEmail = (props) => {
  const {
    className, clearError, getError, commit,
  } = props

  const { environment } = useEnvironment()
  const history = useHistory()

  const [isPending, setPending] = useState(false)

  const [token, setToken] = useState(getURLSearchParams("token") || "")
  const handleChange = useCallback(
    (e) => {
      setToken(e.target.value)
      clearError("setViewerEmail.token")
    },
    [setToken],
  )

  const onError = useCallback(() => {
    setPending(false)
  }, [setPending])

  const onCompleted = useCallback(() => {
    setPending(false)
    history.push("/accounts")
  }, [setPending, history])

  const changeEmail = useCallback(
    (submitToken) => {
      setPending(true)

      const callbacks = {
        onError,
        onCompleted,
      }
      const variables = {
        input: { token: submitToken },
      }

      commit({ variables, callbacks, environment })
    },
    [onError, onCompleted, commit, environment, setPending],
  )

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()

      changeEmail(token)
    },
    [changeEmail, token],
  )

  useEffect(() => {
    const urlToken = getURLSearchParams("token")

    if (urlToken) {
      changeEmail(urlToken)
    }
  }, [])

  return (
    <Container className={className}>
      <Title>Подтверждение</Title>

      <form onSubmit={handleSubmit}>
        <FloatingLabelInput
          value={token}
          onChange={handleChange}
          name="token"
          disabled={isPending}
          required
          withoutBorder
          errorColor="mainRed"
          label="Код подтверждения"
          error={getError("setViewerEmail.token")}
          hasError={getError("setViewerEmail.token")}
        />

        <Button type="submit" disabled={isPending || !token}>
          Подтвердить
        </Button>
      </form>
    </Container>
  )
}

const Render = () => (
  <MutationContainer mutation={SetViewerEmail}>
    {({ commit, getError, clearError }) => (
      <ConfirmationEmail
        commit={commit}
        getError={getError}
        clearError={clearError}
      />
    )}
  </MutationContainer>
)

export default Render
