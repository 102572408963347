import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;

  &:not(:first-child) {
    margin-top: 32px;
  }
`

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 26px;

  @media screen and (max-width: 800px) {
    margin-top: 16px;
  }
`

export const Title = styled.h5`
  font-size: 19px;
  margin: 0;
  font-weight: bold;

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
`

export const Moderate = styled.p`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.newOrange};
  margin: 12px 0 60px;
  width: 100%;
  max-width: 480px;

  @media screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
`
