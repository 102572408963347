/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type Convert_foreignProfile = {
    readonly id: string;
    readonly phone: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly accountBalanceDetailed: ReadonlyArray<{
        readonly currency: CurrencyEnum;
        readonly available: number;
    }>;
    readonly __typename: "ForeignIndividualProfile";
    readonly " $refType": "Convert_foreignProfile";
};
export type Convert_foreignProfile$data = Convert_foreignProfile;
export type Convert_foreignProfile$key = {
    readonly " $data"?: Convert_foreignProfile$data;
    readonly " $fragmentRefs": FragmentRefs<"Convert_foreignProfile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Convert_foreignProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProfileAccountBalance",
      "kind": "LinkedField",
      "name": "accountBalanceDetailed",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "available",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ForeignIndividualProfile"
};
(node as any).hash = 'd27de059030216316bc6acd8d69b1403';
export default node;
