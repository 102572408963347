import React from "react"
import i18next from "i18next"
import { fetchQuery, graphql } from "relay-runtime"

import { getProperty } from "src/utils"
import { Box, Document } from "src/components"
import { useEnvironment } from "src/hooks"

const query = graphql`
  query InvestmentDocumentQuery($profileId: ID!, $orderId: ID!) {
    node(id: $profileId) {
      ... on UserProfile {
        id
        ... on IndividualProfile {
          investment(orderId: $orderId) {
            id
            number
            confirmations {
              document {
                url
              }
            }
          }
        }
        ... on EntrepreneurProfile {
          investment(orderId: $orderId) {
            id
            number
            confirmations {
              document {
                url
              }
            }
          }
        }
      }
    }
  }
`

const isValidStatus = (status) => ["COMPLETE", "SUCCEEDED"].includes(status)

const InvestmentDocument = ({ profile, order }) => {
  const { environment } = useEnvironment()
  const [busy, setBusy] = React.useState(false)
  const [documentUrl, setDocumentUrl] = React.useState(null)
  const [investmentNumber, setInvestmentNumber] = React.useState(null)

  const shouldFetchDocument = isValidStatus(order.status)

  React.useEffect(() => {
    if (documentUrl || busy || !profile || !shouldFetchDocument) return

    setBusy(true)

    const variables = {
      orderId: order.id,
      profileId: profile.id,
    }

    fetchQuery(environment, query, variables)
      .then((data) => {
        setBusy(false)
        setInvestmentNumber(getProperty(data, "node.investment.number", null))
        setDocumentUrl(
          getProperty(data, "node.investment.confirmation.url", {}),
        )
      })
      .catch(() => {
        setBusy(false)
        setDocumentUrl({})
      })
  }, [])

  if (typeof documentUrl !== "string") {
    return null
  }

  const doc = {
    glyph: "pdf",
    url: documentUrl,
    title: i18next.t("components:account.order_item.investment_document", {
      investmentNumber,
    }),
  }

  return (
    <Box mb="20px">
      <Document doc={doc} />
    </Box>
  )
}

export default InvestmentDocument
