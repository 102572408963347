import React from "react"

import {
  Box, Text, FlexBox, Translate, ReturnButton,
} from "src/components"

import Container from "./styles"

import { getBackPath, getNavButton, getSurroundingOrders } from "./utils"

const Controls = ({ orderId, orders, viewer }) => {
  const [previous, next] = getSurroundingOrders(orders.edges, orderId)

  const Next = getNavButton({ order: next, forward: true, viewer })
  const Previous = getNavButton({ order: previous, forward: false, viewer })

  return (
    <FlexBox>
      <Box mr="15px">
        <Previous />
      </Box>
      <Next />
    </FlexBox>
  )
}

const Navigation = (props) => {
  const {
    orders, viewer, source, orderId,
  } = props

  return (
    <Container>
      <ReturnButton path={getBackPath(source)}>
        <Text fontSize={12}>
          <Translate i18n="user.proposal.buttons.return" ns="pages" />
        </Text>
      </ReturnButton>
      {orders && <Controls orderId={orderId} orders={orders} viewer={viewer} />}
    </Container>
  )
}

Navigation.defaultProps = {
  source: "market",
}

export default Navigation
