import React from "react"
import { Spring } from "react-spring/renderprops"

import { divideNumber } from "src/utils"
import Tooltip from "./Tooltip"

import {
  StatusbarContainer,
  StatusBarFilled,
  StatusBarEmpty,
  Container,
  TitileWrapper,
  TitileLeft,
  TitileRight,
  ValueWrapper,
  ValueLeft,
  ValueRight,
  StatusBarMinIndicator,
} from "./styles"

const springConfig = { clamp: true, duration: 300 }

function StatusBar(props) {
  const {
    gatheredAmount,
    goal,
    statusBarValue,
    statusBarMinValue,
    minValue,
    maxValue,
  } = props

  return (
    <Container>
      <Tooltip
        minValue={minValue}
        minValuePercent={statusBarMinValue}
        restAmount={(maxValue - gatheredAmount).toFixed()}
      >
        <div>
          <TitileWrapper>
            <TitileLeft>Собрано</TitileLeft>
            <TitileRight>Цель</TitileRight>
          </TitileWrapper>

          <ValueWrapper>
            <ValueLeft>
              <Spring config={springConfig} to={{ gatheredAmount }}>
                {({ gatheredAmount }) => (
                  <>{divideNumber(gatheredAmount.toFixed())}</>
                )}
              </Spring>
            </ValueLeft>
            <ValueRight>{goal}</ValueRight>
          </ValueWrapper>

          <StatusbarContainer>
            <StatusBarMinIndicator gathered={statusBarMinValue} />
            <Spring config={springConfig} to={{ width: `${statusBarValue}%` }}>
              {({ width }) => <StatusBarFilled style={{ width }} />}
            </Spring>
            <StatusBarEmpty />
          </StatusbarContainer>
        </div>
      </Tooltip>
    </Container>
  )
}

export default StatusBar
