import React from "react"
import styled from "styled-components"

import { Heading } from "src/components"

const Container = styled.div`
  padding: 44px 0 4px;
  margin-bottom: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ginger};
`

export const SubHeader = ({ children }: any) => (
  <Container>
    <Heading.h2>{children}</Heading.h2>
  </Container>
)

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  & + & {
    margin-top: 50px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`
