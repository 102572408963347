import React from "react"
import styled from "styled-components"

import { Text } from "src/components"

const StyledText = styled(Text)`
  word-break: break-word;
`

export default ({ children, ...props }) => (
  <StyledText
    is="h2"
    fontSize="30px"
    textAlign="center"
    color="greyDarkest"
    fontWeight="800"
    {...props}
  >
    {children}
  </StyledText>
)
