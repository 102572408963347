import is from "is_js"
import i18n from "src/i18n"
import memoize from "memoize-one"

import React from "react"
import { Translate } from "src/components"

export const getSelectedValue = (options, value) => {
  const option = []
    .concat(options)
    .filter(Boolean)
    .find((availableOption) => availableOption.value === value)
  return (
    option || {
      label: "",
      value: "",
    }
  )
}

export const getField = (user, name, defaultValue = "") =>
  (is.existy(user) && is.existy(user[name]) ? user[name] : defaultValue)

export const initialStateFromProps = (props, fields): any => {
  const state = {}

  fields.forEach((field) => {
    state[field] = getField(props, field)
  })
  return state
}

export const number = (value, options?: any, language = i18n.language) => {
  if (!!window.Intl && !!window.Intl.NumberFormat) {
    return window.Intl.NumberFormat(language, options).format(value)
  }

  return value
}

export const normalizePhone = (phone) => phone.replace(/\D/g, "")

export const getNodeIdFromUri = (uri) => {
  const [, , id] = uri.split("/")

  return id
}

export const getQueryParam = (name) => {
  /* jslint browser: true */
  const { search } = window.location
  const params = new URLSearchParams(search)

  return params.get(name)
}

export const removeQueryParam = (name) => {
  const { search } = window.location
  const params = new URLSearchParams(search)

  params.delete(name)

  return params
}

export const translation = memoize(({ path, ...rest }) => (
  <Translate i18n={path} {...rest} />
))

export const preventEventBubbling = (event) => {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }
}

export const promisifyHookedMutation = (mutation) => (input, uploadables) => {
  mutation.commit(input, uploadables)

  return new Promise((res, rej) => {
    mutation.onCompleted(res)
    mutation.onError(rej)
  })
}

const addressToStringConfig = {
  divider: ",",
  useTranslations: true,
}

export const addressObjectToString = (
  addressObject,
  config = addressToStringConfig,
) => {
  if (!addressObject) return ""

  const prefixMapper = {
    area: false,
    locality: false,
    street: config.useTranslations
      ? i18n.t("common:address_prefixes.street")
      : "ул",
    house: config.useTranslations
      ? i18n.t("common:address_prefixes.house")
      : "д",
    housing: config.useTranslations
      ? i18n.t("common:address_prefixes.housing")
      : "корп",
    structure: config.useTranslations
      ? i18n.t("common:address_prefixes.structure")
      : "стр",
    flat: config.useTranslations
      ? i18n.t("common:address_prefixes.flat")
      : "кв",
  }

  let result = ""

  Object.keys(addressObject).forEach((key) => {
    if (
      addressObject[key] !== null
      && addressObject[key] !== undefined
      && addressObject[key].length
      && addressObject[key].length > 0
      && Object.prototype.hasOwnProperty.call(prefixMapper, key)
    ) {
      if (result && config.divider) {
        result = result.concat(config.divider)
      }

      if (prefixMapper[key]) {
        result = result.concat(` ${prefixMapper[key]}.`)
      }

      result = result.concat(` ${addressObject[key]}`)
    }
  })

  return result.trim()
}

export const getClickableLink = (link) => {
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link
  }

  return `http://${link}`
}

export function getProposalLink(order) {
  if (
    order.status === "INITIAL"
    || order.status === "PENDING"
    || order.status === "DECLINED"
  ) {
    return `/orders/${order.id}`
  }
  return `/market/${order.id}`
}
