/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type QuestionaryOrActionQueryVariables = {};
export type QuestionaryOrActionQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly profiles: ReadonlyArray<{
            readonly __typename: string;
        }>;
        readonly questionaries: ReadonlyArray<{
            readonly id: string;
            readonly state: {
                readonly status: ProfileAccreditationStatus;
            };
        }>;
    } | null;
};
export type QuestionaryOrActionQuery = {
    readonly response: QuestionaryOrActionQueryResponse;
    readonly variables: QuestionaryOrActionQueryVariables;
};



/*
query QuestionaryOrActionQuery {
  viewer {
    id
    profiles {
      __typename
      id
    }
    questionaries {
      id
      state {
        status
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpressQuestionary",
  "kind": "LinkedField",
  "name": "questionaries",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpressQuestionaryState",
      "kind": "LinkedField",
      "name": "state",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionaryOrActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QuestionaryOrActionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "QuestionaryOrActionQuery",
    "operationKind": "query",
    "text": "query QuestionaryOrActionQuery {\n  viewer {\n    id\n    profiles {\n      __typename\n      id\n    }\n    questionaries {\n      id\n      state {\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7bad611f6d09a42193441711961822e3';
export default node;
