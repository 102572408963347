import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/browser"

import App from "./App"

Sentry.init({
  dsn: "https://47a02f9d6d8e46b0b5f5fa8c8af5700c@o363591.ingest.sentry.io/4477053",
})

const mount = document.getElementById("app-root")
ReactDOM.render(<App />, mount)
/*
import * as firebase from "firebase/app"
import "firebase/analytics";
import "firebase/messaging"

var firebaseConfig = {
    apiKey: "AIzaSyCuDpsW_PvogvFjrdzYXze5sf7Rh0_WMOo",
    authDomain: "devnet-44c67.firebaseapp.com",
    databaseURL: "https://devnet-44c67.firebaseio.com",
    projectId: "devnet-44c67",
    storageBucket: "devnet-44c67.appspot.com",
    messagingSenderId: "425022489976",
    appId: "1:425022489976:web:aac24e552ba9ee4c52b48d",
    measurementId: "G-4SLX7916JY"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  console.log(firebase.messageing)
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey("BOOXJKcUi-JlHJp1youyjj_U5sliB6uaMqAwGZVGwaqkb4I1w1-i_7kFQuSAIPSEUJ5zcs56tYuyyszgd3RXmeY");
  messaging.getToken().then((currentToken) => {
    if (currentToken) {
      console.log("token", currentToken)
      //sendTokenToServer(currentToken);
      //updateUIForPushEnabled(currentToken);
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
      //updateUIForPushPermissionRequired();
      //setTokenSentToServer(false);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    //showToken('Error retrieving Instance ID token. ', err);
    //setTokenSentToServer(false);
  });

  firebase.analytics();
  */
