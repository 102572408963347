import styled from "styled-components"

import { Box, Flex, Button } from "src/components"

export const ItemContainer = styled(Box)`
  position: relative;
  margin-bottom: 65px;
`

export const ModalContainer = styled(Flex)`
  position: absolute;
  left: -32px;
  top: -32px;
  z-index: 2;
  max-height: available;
  overflow-y: auto;
  padding: 32px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.16);
`

export const CloseButton = styled(Button)`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const ExperienceContainer = styled(Box)`
  a {
    color: ${({ theme }) => theme.colors.blue};

    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`
