import Reset from "./Reset"
import Create from "./Create"
import ChangePasswordModal from "./ChangePasswordModal"

const render = () => null

render.reset = Reset
render.create = Create
render.change = ChangePasswordModal

export default render
