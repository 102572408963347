import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation MailVirtualDepositMutation($input: MailVirtualDepositInput!) {
    mailVirtualDeposit(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
