/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type BorrowerOrdersFilter = {
    status?: Array<OrderStatusEnum> | null;
};
export type LoanPaymentGraphQueryVariables = {
    profileId: string;
    filter?: BorrowerOrdersFilter | null;
};
export type LoanPaymentGraphQueryResponse = {
    readonly node: {
        readonly borrower?: {
            readonly orders: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly status: OrderStatusEnum | null;
                        readonly application: {
                            readonly data: string;
                        } | null;
                        readonly chain: {
                            readonly id: string;
                            readonly gatheredAmount: number;
                        } | null;
                        readonly paymentScheduleList: ReadonlyArray<{
                            readonly profile: {
                                readonly id: string;
                            } | null;
                            readonly info: {
                                readonly state: string | null;
                            } | null;
                            readonly items: ReadonlyArray<{
                                readonly date: string;
                                readonly payDate: string | null;
                                readonly loan: number;
                                readonly state: string | null;
                                readonly total: number;
                                readonly total_gross: number;
                                readonly interest: number;
                                readonly interest_fee: number;
                                readonly personal_tax: number;
                            }>;
                        }>;
                    };
                }>;
            };
        } | null;
    };
};
export type LoanPaymentGraphQuery = {
    readonly response: LoanPaymentGraphQueryResponse;
    readonly variables: LoanPaymentGraphQueryVariables;
};



/*
query LoanPaymentGraphQuery(
  $profileId: ID!
  $filter: BorrowerOrdersFilter
) {
  node(id: $profileId) {
    __typename
    ... on LegalEntityProfile {
      borrower {
        orders(filter: $filter) {
          edges {
            node {
              id
              status
              application {
                data
                id
              }
              chain {
                id
                gatheredAmount
              }
              paymentScheduleList {
                profile {
                  __typename
                  id
                }
                info {
                  state
                }
                items {
                  date
                  payDate
                  loan
                  state
                  total
                  total_gross
                  interest
                  interest_fee
                  personal_tax
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "BorrowerOrdersFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderChainInfo",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatheredAmount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "LoanInfo",
  "kind": "LinkedField",
  "name": "info",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentScheduleItem",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loan",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total_gross",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interest_fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "personal_tax",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoanPaymentGraphQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Borrower",
                "kind": "LinkedField",
                "name": "borrower",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "OrderConnection",
                    "kind": "LinkedField",
                    "name": "orders",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Order",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderApplication",
                                "kind": "LinkedField",
                                "name": "application",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PaymentSchedule",
                                "kind": "LinkedField",
                                "name": "paymentScheduleList",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "profile",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoanPaymentGraphQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Borrower",
                "kind": "LinkedField",
                "name": "borrower",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "OrderConnection",
                    "kind": "LinkedField",
                    "name": "orders",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Order",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrderApplication",
                                "kind": "LinkedField",
                                "name": "application",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PaymentSchedule",
                                "kind": "LinkedField",
                                "name": "paymentScheduleList",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "profile",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v8/*: any*/),
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "LegalEntityProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LoanPaymentGraphQuery",
    "operationKind": "query",
    "text": "query LoanPaymentGraphQuery(\n  $profileId: ID!\n  $filter: BorrowerOrdersFilter\n) {\n  node(id: $profileId) {\n    __typename\n    ... on LegalEntityProfile {\n      borrower {\n        orders(filter: $filter) {\n          edges {\n            node {\n              id\n              status\n              application {\n                data\n                id\n              }\n              chain {\n                id\n                gatheredAmount\n              }\n              paymentScheduleList {\n                profile {\n                  __typename\n                  id\n                }\n                info {\n                  state\n                }\n                items {\n                  date\n                  payDate\n                  loan\n                  state\n                  total\n                  total_gross\n                  interest\n                  interest_fee\n                  personal_tax\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '8eef13360b137a8a3c98f536ed646085';
export default node;
