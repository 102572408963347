import styled from "styled-components"

const LoaderBox = styled.div`
  justify-content: flex-start;
  display: flex;
  @media screen and (max-width: 800px) {
    justify-content: center;
  }
`

export default LoaderBox
