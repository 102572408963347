import React from "react"
import { useHistory } from "react-router"

import {
  StepContainer,
  StepTextContainer,
  StepText,
  StepDescription,
  Button,
  SuccessIcon,
} from "./styles"

function StepWelcomePage(props) {
  const {
    Icon,
    text,
    description,
    textButton,
    link,
    completed,
    disabled,
  } = props

  const history = useHistory()

  function handleClick() {
    history.push(link)
  }

  return (
    <StepContainer completed={completed && !disabled}>
      <div>
        <StepTextContainer>
          <Icon />
          <StepText>{text}</StepText>
        </StepTextContainer>

        {!!description && !completed && !disabled && (
          <StepDescription>{description}</StepDescription>
        )}
      </div>

      {completed && <SuccessIcon />}

      {!completed && (
        <Button onClick={handleClick} disabled={disabled}>
          {textButton}
        </Button>
      )}
    </StepContainer>
  )
}

export default StepWelcomePage
