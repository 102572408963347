import React, { useCallback, useMemo, FC } from "react"
import { createEditor, Node } from "slate"
import { Slate, Editable, withReact } from "slate-react"
import { withHistory } from "slate-history"

import {
  Leaf, Element, FormatButton, LinkButton,
} from "./elements"
import { Container, Toolbar } from "./styles"
import { withLinks } from "./utils"

type WysiwygProps = {
  value: Node[]
  onChange: (value: Node[]) => void
  className?: string
}

const Wysiwyg: FC<WysiwygProps> = (props) => {
  const { value, onChange, className } = props

  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    [],
  )
  const renderLeaf = useCallback((leafProps) => <Leaf {...leafProps} />, [])
  const renderElement = useCallback(
    (elementProps) => <Element {...elementProps} />,
    [],
  )

  return (
    <Container className={className}>
      <Slate editor={editor} value={value} onChange={onChange}>
        <Toolbar>
          <FormatButton format="bold" icon="FormatBold" />
          <FormatButton format="italic" icon="FormatItalic" />
          <FormatButton format="underline" icon="FormatUnderline" />
          <LinkButton />
        </Toolbar>
        <Editable
          renderLeaf={renderLeaf}
          renderElement={renderElement}
          spellCheck={true}
        />
      </Slate>
    </Container>
  )
}

export default Wysiwyg
