import React from "react"
import memoize from "memoize-one"

import { createChainedFunction } from "src/utils"

import { ViewerData, OrderDataConsumer, ElementHoverConsumer } from "src/context"

import { Box, Invest, Translate } from "src/components"

import {
  Bold,
  PreLined,
  Container,
  HeaderWrapper,
  TextBlockWrapper,
} from "../styles"

import { mapAmountToStatusFrom } from "../../utils"

const renderActionContent = (order) => ({ freeze, unfreeze, hide }) => {
  const onModalCloseChained = createChainedFunction(unfreeze, hide)

  return (
    <Invest
      onClick={freeze}
      onModalClose={onModalCloseChained}
      buttonVariant="defaultThinWhite"
      fontSize="12px"
      order={order}
    />
  )
}

const Action = memoize((props) => {
  const { order } = props

  return (
    <Box pt="18px">
      <ElementHoverConsumer>{renderActionContent(order)}</ElementHoverConsumer>
    </Box>
  )
})

const MinAmountCollected = memoize((props) => {
  const { order, isOwner, maxAmountDelta } = props

  const bodyKey = isOwner ? "body_owner" : "body"

  return (
    <React.Fragment>
      <HeaderWrapper>
        <Translate i18n="components:tooltips.min_amount_collected.head" />
      </HeaderWrapper>
      <TextBlockWrapper>
        <PreLined>
          <Translate
            i18n={`components:tooltips.min_amount_collected.${bodyKey}`}
          />
          <Bold>{maxAmountDelta} ₽</Bold>
        </PreLined>
        {!isOwner && <Action order={order} />}
      </TextBlockWrapper>
    </React.Fragment>
  )
})

const MinAmountNotCollected = memoize((props) => {
  const {
    order, isOwner, minValue, minAmountDelta,
  } = props

  return (
    <TextBlockWrapper>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.head" />
        <Bold>{minValue} ₽</Bold>
      </PreLined>
      <PreLined>
        <Translate i18n="components:tooltips.min_amount_not_collected.postfix" />
        <Bold>{minAmountDelta} ₽</Bold>
      </PreLined>
      {!isOwner && <Action order={order} />}
    </TextBlockWrapper>
  )
})

const Content = (props) => {
  const { order, ownerRelation } = props
  const { MASTER: isOwner } = ownerRelation

  const { minAmountCollected, ...rest } = mapAmountToStatusFrom(order)

  return (
    <Container>
      {" "}
      {minAmountCollected ? (
        <MinAmountCollected {...rest} order={order} isOwner={isOwner} />
      ) : (
        <MinAmountNotCollected {...rest} order={order} isOwner={isOwner} />
      )}
    </Container>
  )
}

export default () => (
  <OrderDataConsumer>
    {(order) => (
      <ViewerData.renderPropContainer>
        {({ getViewerToOrderRelation }) => (
          <Content
            order={order}
            ownerRelation={getViewerToOrderRelation(order)}
          />
        )}
      </ViewerData.renderPropContainer>
    )}
  </OrderDataConsumer>
)
