import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateEntrepreneurProfileBorrowerDataMutation(
    $input: UpdateEntrepreneurProfileBorrowerDataInput!
  ) {
    updateEntrepreneurProfileBorrowerData(input: $input) {
      profile {
        id
        user {
          id
        }
      }
    }
  }
`

export default enhance({ mutation })
