import { Observable, GraphQLResponse } from "relay-runtime"
import { io } from "socket.io-client"
import { getAuthToken } from "src/utils"

const subscriptions = new Map()

const socket = io(process.env.GRAPHQL_ENDPOINT, {
  path: "/graphql/socket-io",
  autoConnect: false,
  auth: {
    token: null,
  },
})

socket.on("connect", () => {
  subscriptions.forEach(({ query, variables }, id) => {
    socket.emit("subscribe", { id, query, variables })
  })
})

socket.on("graphql", ({ id, done, ...resp }) => {
  const subscription = subscriptions.get(id)

  if (!subscription) {
    if (!done) socket.emit("unsubscribe", { id })
    return
  }

  const { sink } = subscription

  if (sink.closed) {
    if (!done) socket.emit("unsubscribe", { id })
    subscriptions.delete(id)
    return
  }

  if (done) {
    sink.complete()
    subscriptions.delete(id)
    return
  }

  sink.next(resp)
})

const subscribe = (request, variables) => {
  const id = Math.random().toString(16).slice(2, 8)

  return Observable.create<GraphQLResponse>((sink) => {
    const query = request.text

    subscriptions.set(id, { sink, query, variables })
    socket.emit("subscribe", { id, query, variables })

    return () => {
      socket.emit("unsubscribe", { id })
      subscriptions.delete(id)
    }
  })
}

export default () => {
  const { auth } = socket as any
  auth.token = getAuthToken()
  socket.disconnect().connect()

  return subscribe
}
