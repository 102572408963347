/* eslint-disable max-len */

import React from "react"

function Exit(props) {
  return (
    <svg {...props} viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.48174 10.0681H1.37042C1.11826 10.0681 0.913623 9.86344 0.913623 9.6113V1.38869C0.913623 1.13653 1.11828 0.931894 1.37042 0.931894H5.48174C5.73435 0.931894 5.93854 0.727707 5.93854 0.475093C5.93854 0.22248 5.73435 0.0182495 5.48174 0.0182495H1.37042C0.614861 0.0182495 0 0.633132 0 1.38869V9.61128C0 10.3668 0.614861 10.9817 1.37042 10.9817H5.48174C5.73435 10.9817 5.93854 10.7775 5.93854 10.5249C5.93854 10.2723 5.73435 10.0681 5.48174 10.0681Z" />
      <path d="M10.8639 5.17475L8.08646 2.43388C7.90739 2.25663 7.61778 2.25893 7.44054 2.43846C7.26329 2.61798 7.26512 2.90714 7.44511 3.08438L9.42994 5.04318H4.1113C3.85868 5.04318 3.6545 5.24737 3.6545 5.49998C3.6545 5.75259 3.85868 5.9568 4.1113 5.9568H9.42994L7.44511 7.9156C7.26514 8.09284 7.26376 8.382 7.44054 8.56153C7.53006 8.65198 7.64792 8.69765 7.76579 8.69765C7.88182 8.69765 7.99784 8.6538 8.08646 8.56608L10.8639 5.82521C10.9507 5.73934 11 5.62238 11 5.49996C11 5.37758 10.9511 5.2611 10.8639 5.17475Z" />
    </svg>
  )
}

Exit.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "black",
}

export default Exit
