/* eslint-disable max-len */

import React from "react"

export const Close = (props) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
  </svg>
)

export const Illustration = (props) => (
  <svg
    {...props}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="#FF2C56"
    />
    <path
      d="M82.8592 81.244H17.0447C14.4399 81.244 13.6083 78.9966 15.0653 76.433L25.3746 58.5086C26.0619 57.2784 27.9863 56.3162 29.6427 56.3162H71.464C73.1203 56.3162 74.976 57.2784 75.6289 58.5086L85.086 76.433C86.4055 78.9966 85.464 81.244 82.8592 81.244Z"
      fill="white"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M28.5912 68.9347H58.0414C58.3148 68.9347 58.577 68.826 58.7704 68.6327C58.9637 68.4394 59.0723 68.1771 59.0723 67.9037C59.0723 67.6303 58.9637 67.3681 58.7704 67.1748C58.577 66.9814 58.3148 66.8728 58.0414 66.8728H28.5912C28.3178 66.8728 28.0556 66.9814 27.8623 67.1748C27.6689 67.3681 27.5603 67.6303 27.5603 67.9037C27.5603 68.1771 27.6689 68.4394 27.8623 68.6327C28.0556 68.826 28.3178 68.9347 28.5912 68.9347H58.0414C58.3148 68.9347 58.577 68.826 58.7704 68.6327C58.9637 68.4394 59.0723 68.1771 59.0723 67.9037C59.0723 67.6303 58.9637 67.3681 58.7704 67.1748C58.577 66.9814 58.3148 66.8728 58.0414 66.8728H28.5912C28.3178 66.8728 28.0556 66.9814 27.8623 67.1748C27.6689 67.3681 27.5603 67.6303 27.5603 67.9037C27.5603 68.1771 27.6689 68.4394 27.8623 68.6327C28.0556 68.826 28.3178 68.9347 28.5912 68.9347Z"
      fill="black"
    />
    <path
      d="M25.7665 74.5772H74.6188C74.8922 74.5772 75.1544 74.4686 75.3477 74.2753C75.5411 74.0819 75.6497 73.8197 75.6497 73.5463C75.6497 73.2729 75.5411 73.0107 75.3477 72.8173C75.1544 72.624 74.8922 72.5154 74.6188 72.5154H25.7665C25.4931 72.5154 25.2309 72.624 25.0375 72.8173C24.8442 73.0107 24.7356 73.2729 24.7356 73.5463C24.7356 73.8197 24.8442 74.0819 25.0375 74.2753C25.2309 74.4686 25.4931 74.5772 25.7665 74.5772H74.6188C74.8922 74.5772 75.1544 74.4686 75.3477 74.2753C75.5411 74.0819 75.6497 73.8197 75.6497 73.5463C75.6497 73.2729 75.5411 73.0107 75.3477 72.8173C75.1544 72.624 74.8922 72.5154 74.6188 72.5154H25.7665C25.4931 72.5154 25.2309 72.624 25.0375 72.8173C24.8442 73.0107 24.7356 73.2729 24.7356 73.5463C24.7356 73.8197 24.8442 74.0819 25.0375 74.2753C25.2309 74.4686 25.4931 74.5772 25.7665 74.5772Z"
      fill="black"
    />
    <path
      d="M69.9519 14.0273C69.6372 13.7153 69.2783 13.4513 68.8866 13.2438C66.3712 10.4259 62.3299 8.61149 59.0172 8.81768C52.3231 9.24379 48.8866 13.6287 45.2715 18.6665C43.299 21.3744 41.5052 24.3091 39.1753 26.7283C37.6976 28.2747 36.5155 29.7936 36.1719 31.7455C36.0147 31.9392 35.8678 32.1411 35.732 32.3503C35.5081 32.7896 35.3285 33.25 35.1959 33.7249C34.7265 34.2563 34.2011 34.7356 33.6289 35.1544C32.3545 36.4135 31.227 37.8131 30.2681 39.3263C30.128 39.545 30.0062 39.7749 29.9038 40.0136C27.3609 42.3091 28.6117 47.6905 32.4605 47.4431C32.6198 47.5752 32.7873 47.6969 32.9622 47.8074C34.598 48.8452 36.2818 49.7799 38.0069 50.639C38.4949 51.0445 38.921 51.395 39.2303 51.6149C41.7341 53.3216 44.0782 55.2516 46.2337 57.3812C47.9725 59.1613 51.732 59.0445 52.8798 56.5221C53.5052 55.1476 54.1787 53.7386 54.8798 52.3984C55.6145 52.0572 56.2368 51.5136 56.6736 50.8314C60.0756 45.6287 63.9519 40.5496 66.8042 35.0514C67.1547 34.3641 67.4915 33.6768 67.842 32.9001C68.7769 31.9963 69.5756 30.9614 70.2131 29.828C72.9004 24.8727 74.3574 18.3641 69.9519 14.0273Z"
      fill="#F7AF2F"
    />
    <path
      d="M49.3336 59.5945C45.0105 56.1581 40.0552 53.6495 35.7872 50.1443C35.6172 49.9965 35.4056 49.9052 35.1815 49.8831C34.9574 49.8609 34.7319 49.909 34.5363 50.0206C32.5905 48.4754 30.5573 47.0435 28.447 45.7319C28.2646 45.6329 28.0573 45.589 27.8504 45.6054C27.6435 45.6219 27.4458 45.6981 27.2814 45.8247C27.1169 45.9513 26.9927 46.1229 26.9239 46.3187C26.8551 46.5146 26.8446 46.7262 26.8937 46.9278C27.4435 49.3677 27.3954 51.7388 27.8353 54.0893C27.8321 54.2201 27.8604 54.3497 27.9178 54.4673C27.9934 54.811 28.0758 55.1546 28.1721 55.5051C28.1569 55.6936 28.19 55.8828 28.2683 56.055V56.0893C28.2553 56.2939 28.2981 56.4982 28.392 56.6804L28.7356 57.3677C28.831 57.5732 29.0003 57.7351 29.2099 57.8213C29.3061 57.9313 29.416 58.0412 29.5604 58.1787C29.6338 58.4725 29.8092 58.7306 30.0552 58.9072C30.5188 59.2589 31.0108 59.5715 31.526 59.8419C32.0557 60.2374 32.7201 60.4078 33.3748 60.3161C33.4437 60.2921 33.5104 60.2622 33.5741 60.2268C34.0346 60.5842 34.4951 60.9141 34.9487 61.2852C35.0631 61.3682 35.1946 61.4245 35.3336 61.4502L37.1411 63.2646C37.2472 63.3677 37.3739 63.4471 37.5129 63.4976C37.6519 63.5481 37.8 63.5685 37.9475 63.5575C38.095 63.5464 38.2384 63.5042 38.3684 63.4336C38.4983 63.3629 38.6118 63.2655 38.7013 63.1478C40.8427 62.7881 42.9628 62.3108 45.0518 61.7182C45.282 61.6506 45.4799 61.5016 45.6085 61.299L48.5501 61.3746C49.4367 61.4227 50.0758 60.1787 49.3336 59.5945Z"
      fill="white"
    />
    <path
      d="M34.3642 62.5429C33.4839 62.2291 32.6737 61.7458 31.9794 61.1202C31.191 60.5543 30.3538 60.0598 29.4776 59.6426C29.2994 59.5515 29.0995 59.5115 28.9 59.5269C28.7005 59.5423 28.5091 59.6126 28.347 59.7299C28.1849 59.8472 28.0583 60.007 27.9813 60.1917C27.9043 60.3765 27.8799 60.5788 27.9106 60.7766C27.7995 60.9267 27.7436 61.1106 27.7524 61.2972C27.7611 61.4838 27.834 61.6616 27.9587 61.8006C28.1168 62.0068 28.2886 62.1993 28.4536 62.3986C28.4732 62.5241 28.5151 62.6452 28.5773 62.756C28.6622 62.9075 28.7587 63.0523 28.866 63.1889C28.8431 63.3931 28.8765 63.5996 28.9625 63.7861C29.0485 63.9727 29.1839 64.1322 29.3539 64.2474C29.6187 64.4546 29.9548 64.5485 30.2886 64.5085C30.3399 64.501 30.3904 64.4895 30.4398 64.4742C30.7373 64.5858 31.0461 64.6641 31.3608 64.7078C31.5466 64.7793 31.7523 64.7793 31.9381 64.7078C32.1379 64.6828 32.3215 64.5849 32.4536 64.4329C32.9921 64.5281 33.5362 64.5878 34.0825 64.6116C34.3276 64.5956 34.5597 64.4953 34.7394 64.3278C34.9191 64.1603 35.0354 63.9358 35.0685 63.6924C35.1017 63.449 35.0497 63.2016 34.9213 62.9921C34.793 62.7826 34.5962 62.6239 34.3642 62.5429Z"
      fill="black"
    />
    <path
      d="M29.1957 64.9276C28.5085 64.6527 27.9792 59.9723 27.2438 54.8589C26.5566 50.2129 26.0686 46.0273 27.6012 43.6974L48.7559 14.2129C50.7506 11.37 53.789 9.43177 57.2078 8.82141C60.6267 8.21104 64.1481 8.97811 67.0033 10.9551L67.6287 11.3881C70.4716 13.3828 72.4098 16.4213 73.0202 19.8401C73.6305 23.2589 72.8635 26.7803 70.8865 29.6355L50.2679 59.2369C48.7902 61.7661 44.0823 62.4053 40.0892 63.285C35.0995 64.3709 29.883 65.2163 29.1957 64.9276Z"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M48.9347 29.2509C50.1836 29.2509 51.1959 28.2385 51.1959 26.9897C51.1959 25.7409 50.1836 24.7285 48.9347 24.7285C47.6859 24.7285 46.6736 25.7409 46.6736 26.9897C46.6736 28.2385 47.6859 29.2509 48.9347 29.2509Z"
      fill="black"
    />
    <path
      d="M58.8383 36.4193C60.0871 36.4193 61.0995 35.407 61.0995 34.1581C61.0995 32.9093 60.0871 31.897 58.8383 31.897C57.5895 31.897 56.5771 32.9093 56.5771 34.1581C56.5771 35.407 57.5895 36.4193 58.8383 36.4193Z"
      fill="black"
    />
    <path
      d="M48.3093 31.6907C47.0791 35.4433 51.4433 38.7216 54.6667 36.3917C55.464 35.8144 54.6667 34.4742 53.8832 35.0584C53.4346 35.4064 52.8852 35.5996 52.3175 35.6091C51.7498 35.6185 51.1944 35.4436 50.7344 35.1107C50.2745 34.7777 49.9349 34.3047 49.7666 33.7624C49.5982 33.2202 49.6102 32.638 49.8007 32.1031C50.11 31.1546 48.6186 30.7285 48.3093 31.6907Z"
      fill="black"
    />
    <path
      d="M27.7388 44.4468L49.9793 59.4846"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M27.8284 57.7388L37.3816 63.8487"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
)

export const Success = (props) => (
  <svg {...props} viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50ZM13.8704 24.5391L20.1872 30.9921L36.1298 14.7059L38.2354 16.8569L20.1872 35.2941L11.7648 26.6901L13.8704 24.5391Z"
    />
  </svg>
)

Close.defaultProps = {
  width: "24px",
  height: "24px",
  fill: "#4a4a4a",
}

Illustration.defaultProps = {
  width: "100px",
  height: "100px",
}

Success.defaultProps = {
  width: "50px",
  height: "50px",
  fill: "#6FA84B",
}
