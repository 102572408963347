import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query TrustedOrderInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Order {
        createdAt
        status
        profile {
          ... on UserProfile {
            _avatar {
              url
            }
          }
        }
        offer {
          ... on OrderOffer {
            data
            declineReason
          }
        }
        application {
          ... on OrderApplication {
            data
            declineReason
          }
        }
        confirmedAt
        karma {
          isTrusted
        }
      }
    }
  }
`

const container = (render) => ({ orderId, ...rest }) => {
  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
