import React from "react"

import { Box, Text, Translate } from "src/components"

import { SuccessContainer, SuccessSignContainer } from "./styles"

const SuccessSign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="24" cy="24" r="24" fill="#5BAB3A" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13 24.274L21.567 32 33.83 18"
      />
    </g>
  </svg>
)

const render = () => (
  <SuccessContainer>
    <SuccessSignContainer>
      <SuccessSign />
    </SuccessSignContainer>
    <Box mt="30px" mb="10px">
      <Text fontSize="24px" fontWeignt="500" lineHeight="1.5">
        <Translate i18n="modals:express-scoring.success.title" />
      </Text>
    </Box>
    <Text lineHeight="1.5">
      <Translate i18n="modals:express-scoring.success.content" />
    </Text>
  </SuccessContainer>
)

export default render
