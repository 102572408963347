import React from "react"

import {
  Text, Tooltip, Translate, RatingGlyph,
} from "src/components"

import {
  Badge, Container, StyledLink, TooltipContainer,
} from "./styles"

const TooltipBody = ({ active }) => {
  const type = active ? "cession" : "regular"
  return (
    <>
      <Translate i18n={`components:tooltips.loan_type.${type}.body`} />
      &nbsp;
      {active && (
        <StyledLink
          href="https://karmared.atlassian.net/wiki/spaces/KTR/pages/598441985"
          target="_blank"
        >
          <Translate i18n="components:tooltips.loan_type.cession.link" />
        </StyledLink>
      )}
    </>
  )
}

export const LoanTypeTitle = ({ cession }) => {
  const isActive = cession && cession.isActive

  return (
    <Container>
      <Text fontSize="13px" color="greyDark">
        <Translate i18n="models:loan.type" />
      </Text>
      <TooltipContainer>
        <Tooltip
          borderColor="grey"
          borderWidth="2px"
          glyph={() => <RatingGlyph transform="none" />}
          content={() => <TooltipBody active={isActive} />}
        />
      </TooltipContainer>
    </Container>
  )
}

export const LoanType = ({ cession }) => {
  const isActive = cession && cession.isActive
  const color = isActive ? "ginger" : "grey"
  const label = isActive ? "cession" : "initial"

  return (
    <Badge color={color}>
      <Text
        color="white"
        fontSize="10px"
        fontWeight="bold"
        textTransform="uppercase"
      >
        <Translate i18n={`models:loan.loan_types.${label}`} />
      </Text>
    </Badge>
  )
}
