import React from "react"
import onClickOutside from "react-onclickoutside"

import Locales from "src/locales"
import { Flag } from "src/components"
import { LocaleConsumer } from "src/context"

import { Container, LocaleList, LocaleButton } from "./styles"

class LocaleSwitcher extends React.Component<any, any> {
  static defaultProps = {
    align: "left",
    primary: true,
  }

  state = {
    showLocaleList: false,
  }

  setLocale = (event) => {
    const locale = event.currentTarget.value
    this.props.setLocale(locale)
    this.setState(() => ({ showLocaleList: false }))
  }

  toggleLocaleList = () => this.setState(({ showLocaleList }) => ({ showLocaleList: !showLocaleList }))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  renderLocale = ({ flag, label, value }: any, val?: any) => (
    <li key={value}>
      <LocaleButton
        variant={this.props.variant}
        value={value}
        onClick={this.setLocale}
        open={this.state.showLocaleList}
      >
        <Flag flag={flag} />
      </LocaleButton>
    </li>
  )

  renderLocaleList = (locales, value) => this.state.showLocaleList && (
      <LocaleList align={this.props.align}>
        {locales.map((locale) => this.renderLocale(locale, value))}
      </LocaleList>
  )

  handleClickOutside = () => {
    if (!this.state.showLocaleList) {
      return
    }
    this.setState(() => ({ showLocaleList: false }))
  }

  render() {
    const locale = Locales.find(
      (currentLocale) => currentLocale.value === this.props.locale,
    )
    const locales = Locales.filter(
      (currentLocale) => currentLocale.value !== this.props.locale,
    )

    return (
      <Container open={this.state.showLocaleList}>
        <LocaleButton
          variant={this.props.variant}
          onClick={this.toggleLocaleList}
          open={this.state.showLocaleList}
          primary={this.props.primary}
          selectedLanguage
        >
          <Flag flag={locale.flag} />
        </LocaleButton>
        {this.renderLocaleList(locales, locale.value)}
      </Container>
    )
  }
}

const EnhancedLocaleSwitcher = onClickOutside(LocaleSwitcher)

const render = (props) => (
  <LocaleConsumer>
    {(context) => (
      <EnhancedLocaleSwitcher excludeScrollbar={true} {...props} {...context} />
    )}
  </LocaleConsumer>
)

export default render
