/* eslint-disable max-len */
import React from "react"

import { StyledSVG, IconContainer } from "../styles"

const Other = () => (
  <g fill="#9EA6AE" fillRule="evenodd">
    <path
      fillRule="nonzero"
      d="M4 0v9H0v4c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3V0H4zm10 13c0 .6-.4 1-1 1H6V2h8v11z"
    />
    <path d="M8 5h4v2H8zM8 9h4v2H8z" />
  </g>
)

const Estate = () => (
  <path
    fill="#9EA6AE"
    fillRule="nonzero"
    d="M15.747 6.315L8.414.155a.656.656 0 0 0-.844 0L.237 6.315a.697.697 0 0 0-.086.956.654.654 0 0 0 .93.103l.911-.765v7.765c0 .182.07.356.195.484.125.129.295.2.472.2h4v-3.422h2.666v3.423h4a.658.658 0 0 0 .472-.2.694.694 0 0 0 .195-.485V6.61l.911.766a.662.662 0 0 0 .492.158.654.654 0 0 0 .454-.248.69.69 0 0 0 .147-.507.701.701 0 0 0-.249-.463zM9.325 8.898H6.66V6.16h2.666v2.738z"
  />
)

const Vehicle = () => (
  <path
    fill="#9EA6AE"
    fillRule="nonzero"
    d="M15.7 8.3l-.8-.8-.9-4.7c-.1-.5-.5-.8-1-.8h-3V0H6v2H3c-.5 0-.9.3-1 .8l-.9 4.7-.8.8c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1v1c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-1h6v1c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-1c.6 0 1-.4 1-1V9c0-.3-.1-.5-.3-.7zM3 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm.2-5l.6-3h8.4l.6 3H3.2zm9.8 5c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
  />
)

const Guarantor = () => (
  <g fill="#9EA6AE" fillRule="evenodd">
    <path d="M12.402 9.113a.758.758 0 0 0-1.051.009.727.727 0 0 0-.01 1.03l2.115 2.07-.987.967-2.115-2.07a.751.751 0 0 0-.533-.225.764.764 0 0 0-.536.215.732.732 0 0 0-.22.526.72.72 0 0 0 .229.522l1.917 1.878c-.54.221-1.126.316-1.71.277a2.59 2.59 0 0 1-.6 1.412c.281.047.566.072.851.074a5.332 5.332 0 0 0 2.009-.39 5.245 5.245 0 0 0 1.703-1.114l1.58-1.568a.727.727 0 0 0-.004-1.036l-2.638-2.577zM1.368 8.707c.224-.214.483-.388.767-.514a4.373 4.373 0 0 1 .157-4.8 4.52 4.52 0 0 1 1.982-1.578 4.626 4.626 0 0 1 2.539-.28l.324-.318A6.86 6.86 0 0 1 8.176.38 6.17 6.17 0 0 0 4.444.214a6.06 6.06 0 0 0-3.12 2.013A5.853 5.853 0 0 0 .006 5.651 5.834 5.834 0 0 0 .993 9.18c.107-.171.232-.33.375-.473z" />
    <path d="M8.204 12.69L4.667 9.225a1.987 1.987 0 0 0-2.77 0 1.89 1.89 0 0 0 0 2.712l3.538 3.465a1.987 1.987 0 0 0 2.77 0 1.89 1.89 0 0 0 0-2.712zM16.226 1.736A6.117 6.117 0 0 0 11.946 0a6.117 6.117 0 0 0-4.279 1.736L4.19 5.14l.531.52c.44.429 1.034.67 1.655.67.62 0 1.215-.241 1.655-.67l1.344-1.316 6.317 6.298.535-.524A5.866 5.866 0 0 0 18 5.927c0-1.572-.638-3.08-1.773-4.191h-.001z" />
  </g>
)

const glyphs = {
  OTHER: Other,
  ESTATE: Estate,
  VEHICLE: Vehicle,
  GUARANTOR: Guarantor,
}

const Icon = ({ children }) => {
  const Glyph = glyphs[children]

  return (
    <IconContainer>
      <StyledSVG
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <Glyph />
      </StyledSVG>
    </IconContainer>
  )
}

export default Icon
