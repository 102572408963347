import styled from "styled-components"

export const BankDataWrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  margin-top: 40px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey};
`
