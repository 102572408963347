import styled from "styled-components"

export const ContentContainer = styled.div`
  flex: 1;
`

export const List = styled.ul`
  li {
    padding: 10px 0;
  }
`

export const TableWrapper = styled.div`
  margin-top: 15px;

  tr {
    padding: 12px 0;
  }

  td {
    text-align: left;
    height: 40px;
    vertical-align: top;
    padding: 0 6px;
  }
`

export const Scrollable = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
`

export const HeaderWrapper = styled.div`
  padding-bottom: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.grey};
`
