import styled from "styled-components"

const Container = styled.div<any>`
  padding: 35px 10px 0 0;
  margin-left: ${({ marginLeft }) => marginLeft || "auto"};
  width: 240px;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export default Container
