import React from "react"

import { Label, Address } from "src/components"

import Price from "./Price"
import Pledgor from "./Pledgor"
import EgrnData from "./EgrnData"
import Encumbrances from "./Encumbrances"
import AdditionalInfo from "./AdditionalInfo"

class Flat extends React.PureComponent<any, any> {
  onChange = ({ name, value }) => {
    const flatValue = { ...this.props.value }
    flatValue[name] = value

    this.props.onChange(flatValue)
  }

  onDefaultChange = (event) => {
    const { name, value } = event.target
    const flatValue = { ...this.props.value }
    flatValue[name] = value

    this.props.onChange(flatValue)
  }

  render() {
    return (
      <React.Fragment>
        <Address
          value={this.props.value.address}
          name="address"
          onChange={this.onChange}
        >
          <Label>Адрес</Label>
        </Address>
        <EgrnData
          value={this.props.value.egrnData}
          name="egrnData"
          onChange={this.onChange}
        />
        <Price
          value={this.props.value.price}
          name="price"
          onChange={this.onChange}
        />
        <Encumbrances
          value={this.props.value.encumbrances}
          name="encumbrances"
          onChange={this.onChange}
        />
        <AdditionalInfo
          name="additionalInfo"
          value={this.props.value.additionalInfo}
          onChange={this.onDefaultChange}
        />
        <Pledgor
          value={this.props.value.pledgor}
          name="pledgor"
          onChange={this.onChange}
        />
      </React.Fragment>
    )
  }
}

export default Flat
