export const defaultOrderLimit = "10 000"
export const defaultBorrowerLimit = "10 000"
export const defaultLimit = "200 000"

export const defaultMinRates = {
  /*
  d: {
    value: "",
    active: false,
  },
  c: {
    value: "",
    active: false,
  },
  cc: {
    value: "",
    active: false,
  },
  ccc: {
    value: "",
    active: false,
  },
  b: {
    value: "",
    active: false,
  },
  */
  bb: {
    value: "23",
    active: false,
  },
  bbb: {
    value: "20",
    active: true,
  },
  a: {
    value: "17",
    active: true,
  },
  aa: {
    value: "15",
    active: false,
  },
  aaa: {
    value: "13",
    active: false,
  },
}

export const ratings = [
  /*
  {
    title: "D",
    bg: "#fff5f5",
  },
  {
    title: "C",
    bg: "#fff5f5",
  },
  {
    title: "CC",
    bg: "#fff5f5",
  },
  {
    title: "CCC",
    bg: "#fff5f5",
  },
  {
    title: "B",
    bg: "#fffbf5",
  },
  */
  {
    title: "BB",
    bg: "#fffbf5",
  },
  {
    title: "BBB",
    bg: "#f9fbf8",
  },
  {
    title: "A",
    bg: "#f9fbf8",
  },
  {
    title: "AA",
    bg: "#f9fbf8",
  },
  {
    title: "AAA",
    bg: "#f9fbf8",
  },
]
