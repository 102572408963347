import * as React from "react"
import { DragDropContext } from "react-dnd"
import HTML5Backend from "react-dnd-html5-backend"

import Item from "./SortableItem"

import {
  ListItemIndex,
  ListContainer,
  InputContainer,
  IndexContainer,
} from "./styles"

const render = (props) => {
  const { onMove, onRemove, attachments } = props

  return (
    <InputContainer>
      <IndexContainer>
        {attachments.map((attachment, index) => (
          <ListItemIndex>{index + 1}</ListItemIndex>
        ))}
      </IndexContainer>
      <ListContainer>
        {attachments.map(({ id, ...rest }, idx) => (
          <Item
            {...rest}
            key={id + idx}
            index={idx}
            onMove={onMove}
            onRemove={onRemove(idx)}
            attachmentsLength={attachments.length}
          />
        ))}
      </ListContainer>
    </InputContainer>
  )
}

export default DragDropContext(HTML5Backend)(render)
