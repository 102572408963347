import React from "react"

import {
  Block, Title, Description, Value,
} from "./styles"

export default function InfoBlock(props) {
  const {
    title, value, descr, url,
  } = props

  return (
    <Block>
      <Title>{title}</Title>
      <Value>{value}</Value>
      <Description href={url}>{descr}</Description>
    </Block>
  )
}
