import styled, { css } from "styled-components"

export const PopoverContainer = styled.div<any>`
  position: absolute;
  width: 180px;
  overflow: auto;
  padding: 10px 0;

  top: 0;

  border-radius: 4px;
  background: #ffffff;
  z-index: 10;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);

  flex-direction: column;
  ${({ show }) => {
    if (show) {
      return css`
        opacity: 1;
        visibility: visible;
      `
    }
    return css`
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    `
  }}
`

export const Option = styled.button<any>`
  font-size: 16px;
  padding: 10px 23px;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.catskillWhite};
  }
`
