import React, { useState } from "react"
import styled from "styled-components"
import { width, height } from "styled-system"

import { Loader } from "src/components"

const StyledIframe = styled.iframe<any>`
  ${width};
  ${height};
  ${({ loaded }) => !loaded && "display: none;"}
`

const LoaderContainer = styled.div`
  ${width};
  ${height};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.greyLighter};
`

const DocumentViewer = (props) => {
  const [loaded, setLoaded] = useState(false)
  const { url, ...containerProps } = props

  if (!url) return null

  const src = `https://docs.google.com/gview?url=${url}&embedded=true`

  return (
    <>
      <StyledIframe
        src={src}
        frameBorder="0"
        loaded={loaded}
        onLoad={setLoaded}
        {...containerProps}
      />
      {!loaded && (
        <LoaderContainer {...containerProps}>
          <Loader fontSize="6px" />
        </LoaderContainer>
      )}
    </>
  )
}

DocumentViewer.defaultProps = {
  width: "100%",
  height: "600px",
}

export default DocumentViewer
