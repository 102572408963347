import React from "react"
import styled from "styled-components"

import AccountInput from "../AccountInput"

const initialStateFromProps = ({ value }: any) => (value.length
  ? [].concat(value).filter(Boolean)
  : [].concat((AccountInput as any).fromGraphQLType()))

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 40px;
  background-color: ${({ theme }) => theme.colors.grey};
`

class BankAccounts extends React.Component<any> {
  state = {
    Accounts: initialStateFromProps(this.props),
  }

  onChange = (index) => ({ value }) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.Accounts[index] = value

    this.forceUpdate()
    this.forceChange()
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state.Accounts,
    })
  }

  render() {
    return (
      <>
        {this.state.Accounts.map((account, index) => (
          <React.Fragment key={index}>
            <AccountInput
              key={index}
              name={`${index}`}
              value={account}
              onChange={this.onChange(index)}
            />
          </React.Fragment>
        ))}
        <Divider />
      </>
    )
  }
}

export default BankAccounts
