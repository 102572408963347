import React from "react"

import {
  Box, Label, FormRow, Translate, TextField,
} from "src/components"

export class LicenseName extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { value } = event.target

    this.props.onChange({
      name: this.props.name,
      value,
    })
  }

  render() {
    return (
      <FormRow>
        <Box width={280}>
          <Label>
            <Translate
              i18n="application.worksheet.rows.license_name.label"
              ns="components"
            />
          </Label>
        </Box>
        <TextField
          name="licenseName"
          value={this.props.value}
          onChange={this.onChange}
        />
      </FormRow>
    )
  }
}

export default LicenseName
