import React from "react"
import { Route } from "react-router"

import { graphql, fetchQuery } from "relay-runtime"

import { RelayEnvironmentConsumer } from "src/context"

import {
  Box, Text, Translate, FormLoadingButton,
} from "src/components"

import Container from "./styles"

const query = graphql`
  query AccountDescriptionQuery {
    viewer {
      profiles {
        __typename
      }
    }
  }
`

class AccountDescription extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      mounted: false,
      isLoading: false,
      hasIndividual: false,
    }
  }

  componentDidMount() {
    this.setState(() => ({ mounted: true }))
    this.fetchAccountData()
  }

  accountDataToState = ({ viewer }) => {
    if (!this.state.mounted) {
      return
    }
    this.setState(() => ({ isLoading: false }))

    const IndividualProfile = viewer.profiles.find(
      ({ __typename }) => __typename === "IndividualProfile",
    )

    if (IndividualProfile !== undefined) {
      this.setState(() => ({ hasIndividual: true }))
    }
  }

  componentWillUnmount() {
    this.setState(() => ({ mounted: false }))
  }

  fetchAccountData = () => {
    this.setState(() => ({ isLoading: true }))
    fetchQuery(this.props.environment, query, {}).then(this.accountDataToState)
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onJuristicCreate, onIndividualCreate, ...rest } = this.props

    return (
      <Container {...rest}>
        <Text pb={18} fontSize={16}>
          <Translate i18n="application.borrower.extra.title" ns="components" />
        </Text>
        <Text pb={30} fontSize={14} whiteSpace="pre-line">
          <Translate
            i18n="application.borrower.extra.description"
            ns="components"
          />
        </Text>
        <Text pb={16} fontSize={16}>
          <Translate
            i18n="application.borrower.extra.add_profile"
            ns="components"
          />
        </Text>
        <Box>
          {/* <Box pb={14}> */}
          {/*  <Button variant="defaultThin" onClick={onJuristicCreate}> */}
          {/*    <Text fontSize={14}> */}
          {/*      <Translate */}
          {/*        i18n="application.borrower.extra.buttons.juristic" */}
          {/*        ns="components" */}
          {/*      /> */}
          {/*    </Text> */}
          {/*  </Button> */}
          {/* </Box> */}
          <FormLoadingButton
            variant="defaultThin"
            onClick={onIndividualCreate}
            isLoading={this.state.isLoading}
            fontSize="3px"
            disabled={this.state.hasIndividual}
          >
            <Text fontSize={14}>
              <Translate
                i18n="application.borrower.extra.buttons.individual"
                ns="components"
              />
            </Text>
          </FormLoadingButton>
        </Box>
      </Container>
    )
  }
}
const handleJuristicCreateClick = (history) => () => {
  history.push("/profiles/create/juristic")
}

const handleIndividualCreateClick = (history) => () => {
  history.push("/accounts/create/individual")
}

export default (props) => (
  <Route>
    {({ history }) => (
      <RelayEnvironmentConsumer>
        {(renderProps) => (
          <AccountDescription
            onJuristicCreate={handleJuristicCreateClick(history)}
            onIndividualCreate={handleIndividualCreateClick(history)}
            {...renderProps}
            {...props}
          />
        )}
      </RelayEnvironmentConsumer>
    )}
  </Route>
)
