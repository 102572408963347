import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 800px) {
    display: block;
    margin-top: 45px;
  }
`

export const Info = styled.div`
  position: relative;
  max-width: 50%;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 320px;

  @media screen and (max-width: 800px) {
    max-height: none;
  }
`

export const ImageContainer = styled.div<any>`
  position: relative;
  width: 45%;
  max-height: 320px;
  box-sizing: content-box;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 24px;
    max-height: none;
    ${({ bg }) => !bg
      && css`
        @media screen and (max-width: 800px) {
          display: none;
        }
      `}
  }
`

export const Ticker = styled.p`
  display: inline;
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: rgba(74, 74, 74, 0.65);
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
    color: rgba(74, 74, 74, 0.45);
  }
`

export const Title = styled.p`
  font-family: Geometria;
  font-size: 32px;
  line-height: 40px;
  color: #4a4a4a;
  margin: 0;
  font-weight: bold;
`

export const Status = styled.p<any>`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color};
  margin: 0;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
  }
`

export const Description = styled.p`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
  margin: 0;
  margin-top: 24px;

  @media screen and (max-width: 480px) {
    font-size: 17px;
    line-height: 21px;
    margin-top: 18px;
  }
`

export const Cession = styled.p`
  display: inline;
  font-family: Geometria;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  vertical-align: bottom;
  padding: 6px 4px 4px;
  color: #ffffff;
  background: #ff8a00;
  border-radius: 4px;
  margin: 0;
  margin-right: 14px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 25px;
  }
`

export const InfoBlockWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  
  @media screen and (max-width: 800px) {
    justify-content: space-between;
  }
  
  @media screen and (max-width: 580px) {
    justify-content: space-evenly;
  }
`

export const DeclinedReason = styled.p`
  font-family: Geometria;
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
  margin: 0;
  margin-top: 18px;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    margin-top: 24px;
  }
`

export const CheckboxWrapper = styled.div`
  margin-top: 60px;
  position: relative;
`

export const Label = styled.p`
  font-weight: bold;
  display: inline-block;
  margin: 0;
  margin-left: 8px;
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  color: rgba(74, 74, 74, 0.75);
`

export const CheckboxLoader = styled.div`
  width: 17px;
  height: 18px;
  display: inline-block;
  vertical-align: text-bottom;
  padding: 7px 0;
  box-sizing: border-box;

  @media screen and (max-width: 480px) {
    width: 25px;
    height: 26px;
    padding-top: 12px;
  }
`

export const Tooltip = styled.p`
  margin: 6px 0 0 25px;
  font-weight: lighter;
  font-family: Geometria;
  font-size: 12px;
  line-height: 15px;
  color: rgba(74, 74, 74, 0.75);

  @media screen and (max-width: 480px) {
    margin-left: 33px;
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;

  @media screen and (max-width: 480px) {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }
`

export const EditButton = styled.button`
  padding: 0;
  background: #ff0000;
  border-radius: 4px;
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  height: 44px;
  min-width: 240px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 60px;
  }
`
export const ButtonDescription = styled.p`
  font-family: Geometria;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
  margin: 40px 0 12px 0;
  margin: 0;
  margin-left: 12px;

  @media screen and (max-width: 480px) {
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
  }
`

export const BlockContainer = styled.div`
  display: flex;
  margin-top: 20px;

  :first-child {
    margin-right: 14px;
  }

  @media screen and (max-width: 480px) {
    justify-content: space-between;
    margin-right: 0;

    :first-child {
      margin-right: 0;
    }
  }
`
