/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountHeaderQueryVariables = {};
export type AccountHeaderQueryResponse = {
    readonly viewer: {
        readonly email: string;
        readonly profiles: ReadonlyArray<{
            readonly id: string;
            readonly __typename: string;
            readonly name?: string;
            readonly phone?: string | null;
        }>;
    } | null;
};
export type AccountHeaderQuery = {
    readonly response: AccountHeaderQueryResponse;
    readonly variables: AccountHeaderQueryVariables;
};



/*
query AccountHeaderQuery {
  viewer {
    email
    profiles {
      id
      __typename
      ... on IndividualProfile {
        name
        phone
      }
      ... on EntrepreneurProfile {
        name
        phone
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "profiles",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "EntrepreneurProfile"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountHeaderQuery",
    "operationKind": "query",
    "text": "query AccountHeaderQuery {\n  viewer {\n    email\n    profiles {\n      id\n      __typename\n      ... on IndividualProfile {\n        name\n        phone\n      }\n      ... on EntrepreneurProfile {\n        name\n        phone\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '37af8c335fdf783365d0ba794a03e3be';
export default node;
