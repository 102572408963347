import styled from "styled-components"

import { Icons } from "src/components"

import { Flex } from "../styles"

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  padding: 48px 32px;
  background-color: #F7F7F7;
  align-items: center;
  margin-right: 40px;

  @media screen and (max-width: 800px) {
    flex-direction: row;
    max-width: 100%;
    padding: 16px 32px;
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 32px 16px;
  }
`

export const Illustration = styled(Icons.AsianUnderMagnifier)`
  width: 120px;
  height: 120px;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    margin: 0 40px 0 0;
  }

  @media screen and (max-width: 480px) {
    width: 80px;
    height: 80px;
    margin: 0 20px 0 0;
  }
`

export const TextContainer = styled(Flex)`
  margin-top: 32px;
  flex-direction: column;
  width: 100%;
  
  @media screen and (max-width: 800px) {
    margin: 0;
  }
`

export const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: center;
  margin: 0 0 20px;

  @media screen and (max-width: 800px) {
    text-align: left;
  }
`

export const Text = styled.p`
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 16px 0 0;
`
