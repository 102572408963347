import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  width: 100%;
`

export const ImageContainer = styled.a`
  position: relative;
  box-sizing: content-box;

  &:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 18px;
  }

  @media screen and (max-width: 800px) {
    &:nth-child(odd) {
      margin-right: 20px;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }
`

export const Image = styled.div`
  background-position: center;
  background-size: cover;
  width: 170px;
  height: 110px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 210px;
    height: 160px;
  }
`
