import styled, { css } from "styled-components"
import {
  Box, Text, Flex, Link, Icon, Button,
} from "src/components"

export const SubHeader = styled(Text)`
  font-weight: 500;
  line-height: 1.4;
`

export const InfoText = styled(Text)`
  font-size: 14px;
  line-height: 1.45;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const WithdrawProfileContainer = styled(Box)`
  padding: 10px 25px;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ bordered }) => (bordered ? "4px" : "0")};
  border: 1px solid
    ${({ theme, bordered }) => theme.colors[bordered ? "greyLight" : "white"]};
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledButton = styled(Button)`
  height: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyShuttle};
  line-height: 1.5;
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.blue};

  &:visited,
  &:active {
    color: ${({ theme }) => theme.colors.blue};
  }
`

export const ScrollContainer = styled(Box)`
  max-height: 60vh;
  overflow-y: auto;
  margin: 0 -40px;
  padding: 0 40px 35px 40px;
`

export const OptionsContainer = styled.div<any>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  border-radius: 4px;
  z-index: 10;
  max-height: 320px;
  box-shadow: 0 0 24px 0 rgba(217, 222, 226, 0.76);
  background-color: ${({ theme }) => theme.colors.white};
  ${({ shouldDisplay }) => {
    if (shouldDisplay) {
      return "opacity: 1; visibility: visible;"
    }
    return "opacity: 0; visibility: hidden; transition: all .3s ease-in-out;"
  }}
`

export const InputWrapper = styled.div`
  position: relative;
`

const activeStyles = css`
  background-color: ${({ active, theme }: any) => {
    if (active) {
      return theme.colors.catskillWhite
    }
    return theme.colors.white
  }};
`

const hoverStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.catskillWhite};
`

export const Option = styled.div<any>`
  width: 100%;
  height: 56px;
  overflow: hidden;
  ${activeStyles}
  &:hover {
    ${hoverStyles}
  }
`

export const SelectedProfileContainer = styled.div`
  position: relative;
`

export const StyledArrowIcon = styled(Icon.arrowDown)`
  position: absolute;
  top: 23px;
  right: 27px;
  cursor: pointer;
`

export const ControlsContainer = styled(Flex)`
  justify-content: flex-end;
  margin-top: 40px;

  @media (max-width: 479px) {
    flex-direction: column;
    margin-top: 20px;

    button {
      width: 100%;
      margin-bottom: 10px;
    }

    > * {
      margin: 0;
    }
  }
`

export const AmountControlsContainer = styled(Flex)`
  justify-content: flex-end;
  margin-top: 10px;

  @media (max-width: 479px) {
    button {
      width: 100%;
    }

    > * {
      margin: 0;
    }
  }
`

export const SuccessContainer = styled(Box)`
  width: 100%;
  max-width: 420px;
  margin: auto;
`
