import React from "react"

import { Translate } from "src/components"

import { SliderModal, GalleryPreview, DescriptionSubHeader } from "./Elements"

class Gallery extends React.Component<any, any> {
  state = {
    showModal: false,
    initialSlide: 0,
  }

  onModalClose = () => {
    this.setState(() => ({
      showModal: false,
      initialSlide: 0,
    }))
  }

  onModalOpen = (i) => () => {
    this.setState(() => ({
      showModal: true,
      initialSlide: i,
    }))
  }

  render() {
    const { images, header, renderHeader: Header } = this.props

    if (!images || !images.length) return null

    return (
      <>
        {header && (
          <Header>
            <Translate i18n={header} />
          </Header>
        )}
        <GalleryPreview images={images} onClick={this.onModalOpen} />
        {this.state.showModal && (
          <SliderModal
            images={images}
            initialSlide={this.state.initialSlide}
            onClose={this.onModalClose}
          />
        )}
      </>
    )
  }
}

(Gallery as any).defaultProps = {
  images: [],
  renderHeader: DescriptionSubHeader,
}

export default Gallery

export { SliderModal } from "./Elements"
