import React from "react"
import i18n from "i18next"

import { normalizePhone } from "src/utils"
import { ErrorsConsumer, RelayEnvironmentConsumer } from "src/context"
import {
  Box,
  CountDown,
  Translate,
  DefaultModal,
  ErrorForField,
  CheckBoxField,
  FloatingLabelInput,
  FormLoadingButton,
  ResendRequestButton,
} from "src/components"
import { userAgreementUrl, personalDataAgreementUrl } from "src/constants"

import { Controls } from "./styles"

import {
  TermsHint,
  PhoneConfirmTitle,
  PhoneConfirmLegend,
  TermsOfAcceptanceLabel,
  TermsOfDataParseAcceptance,
  PersonalLinkPhoneConfirmLabel,
} from "./elements"

class PhoneConfirmModal extends React.Component<any, any> {
  constructor(props) {
    super(props);

    (this as any).termsLink = i18n.t(
      "components:individual_account.update.phone_confirm.terms_link",
    );
    (this as any).personalLink = i18n.t(
      "components:individual_account.update.phone_confirm.personal_link",
    )
  }

  state = {
    token: "",
    personalDataAcceptance: false,
    termsOfServiceAcceptance: false,
    termsOfDataParseAcceptance: false,
  }

  get acceptedRules() {
    return this.props.type === "ru"
      ? this.state.personalDataAcceptance && this.state.termsOfServiceAcceptance
      : this.state.termsOfDataParseAcceptance
  }

  onChange = (event) => {
    const { name, value } = event.target

    this.setState(() => ({ [name]: value }))
  }

  onCheckBoxChange = (event) => {
    const { name, checked } = event.target

    this.setState(() => ({ [name]: checked }))
  }

  commitRequest = () => {
    this.props.commitRequest()
    this.props.restartTimer()
  }

  onConfirmSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }

    if (!this.acceptedRules) return

    const variables = {
      input: {
        phone: normalizePhone(this.props.phone),
        token: this.state.token,
      },
    }

    this.props.commit(variables)
  }

  close = () => {
    this.props.close()
  }

  componentDidMount() {
    this.props.startTimer()
  }

  render() {
    const { phone } = this.props

    return (
      <DefaultModal
        onClose={this.close}
        padding="30px 40px 34px"
        width="480px"
        heightRestriction={true}
        maxHeight="90vh"
      >
        <PhoneConfirmTitle />
        <PhoneConfirmLegend phone={phone} />
        <Box pt={24} pb={16}>
          <FloatingLabelInput
            type="string"
            name="token"
            value={this.state.token}
            onChange={this.onChange}
            error={ErrorForField(
              this.props.getError("requestProfileAccreditation.token"),
            )}
            label="components:individual_account.update.phone_confirm.label"
            autoFocus
          />
        </Box>
        {this.props.type === "ru" ? (
          <React.Fragment>
            <CheckBoxField
              fill="grey"
              name="personalDataAcceptance"
              alignItems="flex-start"
              checked={this.state.personalDataAcceptance}
              onChange={this.onCheckBoxChange}
              label={
                <PersonalLinkPhoneConfirmLabel
                  personalLink={personalDataAgreementUrl}
                />
              }
            />
            <CheckBoxField
              fill="grey"
              name="termsOfServiceAcceptance"
              alignItems="flex-start"
              checked={this.state.termsOfServiceAcceptance}
              onChange={this.onCheckBoxChange}
              label={<TermsOfAcceptanceLabel termsLink={userAgreementUrl} />}
            />
          </React.Fragment>
        ) : (
          <CheckBoxField
            fill="grey"
            name="termsOfDataParseAcceptance"
            alignItems="flex-start"
            checked={this.state.termsOfDataParseAcceptance}
            onChange={this.onCheckBoxChange}
            label={<TermsOfDataParseAcceptance />}
          />
        )}
        <TermsHint />
        <Controls>
          <FormLoadingButton
            onClick={this.onConfirmSubmit}
            isLoading={this.props.isLoading}
            fontSize="10px"
            default
            disabled={!(this.acceptedRules && this.state.token)}
          >
            <Translate
              i18n="individual_account.update.buttons.submit"
              ns="components"
            />
          </FormLoadingButton>
          <Box pl={16}>
            <ResendRequestButton
              secondsLeft={this.props.secondsLeft}
              onClick={this.commitRequest}
            />
          </Box>
        </Controls>
      </DefaultModal>
    )
  }
}

(PhoneConfirmModal as any).defaultProps = {
  type: "ru",
}

const WithCountDown = (props) => (
  <CountDown>
    {({ start, restart, secondsLeft }) => (
      <PhoneConfirmModal
        {...props}
        startTimer={start}
        restartTimer={restart}
        secondsLeft={secondsLeft}
      />
    )}
  </CountDown>
)

const render = (props) => (
  <ErrorsConsumer>
    {(errors) => (
      <RelayEnvironmentConsumer>
        {({ environment }) => (
          <WithCountDown {...props} {...errors} environment={environment} />
        )}
      </RelayEnvironmentConsumer>
    )}
  </ErrorsConsumer>
)

export default render
