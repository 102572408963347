export function getBankAccountValues(bankAccount: any = {}) {
  const { bank = {}, checkingAccount, correspondentAccount } = bankAccount
  const { name, bic } = bank

  return {
    name: name || "",
    bic: bic || "",
    checkingAccount: checkingAccount || "",
    correspondentAccount: correspondentAccount || "",
  }
}

export function getCreateBankAccountVariables(values, profileId) {
  return {
    input: {
      profileId,
      account: {
        bank: {
          name: values.name,
          bic: values.bic,
        },
        checkingAccount: values.checkingAccount,
        correspondentAccount: values.correspondentAccount,
      },
    },
  }
}

export function getUpdateBankAccountVariables(values, accountId) {
  return {
    input: {
      accountId,
      account: {
        bank: {
          name: values.name,
          bic: values.bic,
        },
        checkingAccount: values.checkingAccount,
        correspondentAccount: values.correspondentAccount,
      },
    },
  }
}
