import React from "react"

import {
  Button,
  Invest,
  Translate,
  PenaltiesInfo,
  DebtPaymentData,
} from "src/components"

import Approval from "../Approval"
import { ControlsContainer, Link } from "./styles"

const ProposalControlsSchema = new Map([
  ["APPROVED", ({ data }) => <Approval order={data} type="fee" />],
  [
    "CONFIRMED",
    (props) => {
      const {
        data, isOwner, buttonVariant, withOutdatedMessage,
      } = props

      if (isOwner) return null

      return (
        <Invest
          order={data}
          withOutdatedMessage={withOutdatedMessage}
          buttonVariant={buttonVariant}
        />
      )
    },
  ],
  [
    "TRANSFER",
    ({ isOwner }) => {
      if (!isOwner) return null

      return (
        <Link href="mailto:help@karma.red">
          <Button variant="ginger">
            <Translate i18n="components:individual_account.update.buttons.submit" />
          </Button>
        </Link>
      )
    },
  ],
  [
    "COMPLETE",
    ({ data, isOwner }) => {
      const Control = isOwner ? DebtPaymentData : PenaltiesInfo
      return <Control order={data} />
    },
  ],
])

const ProposalControls = (props) => {
  const Controls = ProposalControlsSchema.get(props.status)

  if (!Controls) return null

  return (
    <ControlsContainer>
      <Controls {...props} />
    </ControlsContainer>
  )
}

ProposalControls.defaultProps = {
  withOutdatedMessage: true,
}

export default ProposalControls
