/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type ViewerOrdersListQueryVariables = {};
export type ViewerOrdersListQueryResponse = {
    readonly viewer: {
        readonly id: string;
        readonly orders: {
            readonly count: number;
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly status: OrderStatusEnum | null;
                    readonly chain: {
                        readonly id: string;
                        readonly gatheredAmount: number;
                        readonly investorsCount: number;
                    } | null;
                };
            }>;
        };
    } | null;
};
export type ViewerOrdersListQuery = {
    readonly response: ViewerOrdersListQueryResponse;
    readonly variables: ViewerOrdersListQueryVariables;
};



/*
query ViewerOrdersListQuery {
  viewer {
    id
    orders {
      count
      edges {
        node {
          id
          status
          chain {
            id
            gatheredAmount
            investorsCount
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderChainInfo",
                    "kind": "LinkedField",
                    "name": "chain",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gatheredAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "investorsCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewerOrdersListQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ViewerOrdersListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ViewerOrdersListQuery",
    "operationKind": "query",
    "text": "query ViewerOrdersListQuery {\n  viewer {\n    id\n    orders {\n      count\n      edges {\n        node {\n          id\n          status\n          chain {\n            id\n            gatheredAmount\n            investorsCount\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '986ce5b367c325a9de447e49c15b50ce';
export default node;
