import styled from "styled-components"
import { Input as BaseInput } from "src/components"

export const Container = styled.div<{ maxWidth?: string | number }>`
  display: flex;
  font-family: Geometria, sans-serif;
  max-width: ${(props) => props.maxWidth || "320px"};
  width: 100%;
`

export const InputContainer = styled.div`
  position: relative;
  flex-grow: 1;
  margin-right: 4px;
`

export const Input = styled(BaseInput)`
  padding-right: 40px;
`

export const ClearButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  height: 40px;
  cursor: pointer;
`
