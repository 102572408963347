import React from "react"

import { imageSizer } from "src/utils"

import { StyledImage, ItemContainer } from "../styles"

class Slide extends React.PureComponent<any, any> {
  state = {
    width: 0,
    height: 0,
  }

  componentDidMount() {
    imageSizer.getSize(this.props.src).then(({ width, height }) => {
      this.setState(() => ({ width, height }))
    })
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  render() {
    return (
      <ItemContainer>
        <StyledImage
          src={this.props.src}
          width={this.state.width}
          height={this.state.height}
          onClick={this.props.onClick}
        />
      </ItemContainer>
    )
  }
}

export default Slide
