import React from "react"

import {
  Container, Row, Label, Value,
} from "./styles"
import { ButtonContainer, Button } from "../styles"

function InfoBankAccount(props) {
  const { setEditing, bankAccount = {} } = props
  const { bank = {}, checkingAccount, correspondentAccount } = bankAccount
  const { name, bic } = bank

  return (
    <Container>
      <Row>
        <Label>Наименование банка</Label>
        <Value>{name || ""}</Value>
      </Row>
      <Row>
        <Label>БИК</Label>
        <Value>{bic || ""}</Value>
      </Row>
      <Row>
        <Label>Корреспондентский счет</Label>
        <Value>{correspondentAccount || ""}</Value>
      </Row>
      <Row>
        <Label>Расчетный счет</Label>
        <Value>{checkingAccount || ""}</Value>
      </Row>

      <ButtonContainer>
        <Button onClick={() => setEditing(true)}>Редактировать</Button>
      </ButtonContainer>
    </Container>
  )
}

export default InfoBankAccount
