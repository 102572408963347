import React from "react"
import { graphql } from "react-relay"

import { QueryRenderer } from "src/components"
import { RenderedTable } from "src/components/organisms/PortfolioTable/Tables/LoanPaymentGraph"

const queries = {
  order: graphql`
    fragment LoanPaymentGraphFragment on OrderEdge {
      node {
        id
        status
        application {
          data
        }
        chain {
          id
          gatheredAmount
        }
        paymentScheduleList {
          profile {
            id
          }
          info {
            state
          }
          items {
            date
            payDate
            loan
            state
            total
            total_gross
            interest
            interest_fee
            personal_tax
          }
        }
      }
    }
  `,
  query: graphql`
    query LoanPaymentGraphQuery($profileId: ID!, $filter: BorrowerOrdersFilter) {
      node(id: $profileId) {
        ... on LegalEntityProfile {
          borrower {
            orders(filter: $filter) {
              edges {
                ...LoanPaymentGraphFragment @relay(mask: false)
              }
            }
          }
        }
      }
    }
  `,
}

const Container = (Render, profile) => (mainProps) => (
  <QueryRenderer
    {...mainProps}
    query={queries.query}
    render={(props) => <Render {...props} />}
    renderNull={() => <RenderedTable isFetching={true} />}
    variables={{
      filter: { status: ["COMPLETE"] },
      profileId: profile.id,
    }}
  />
)

export default Container
