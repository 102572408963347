import React from "react"

import { Translate } from "src/components"

import { HintLabel } from "../ExhibitionItem/styles"

export default ({ hints }) => {
  const { profileCreditRating, orderCreditRating } = hints

  return (
    <HintLabel>
      <Translate i18n={profileCreditRating} />
      {profileCreditRating && orderCreditRating ? "/\n" : null}
      <Translate i18n={orderCreditRating} />
    </HintLabel>
  )
}
