/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type UpdateBankAccountInput = {
    accountId: string;
    account: BankAccountInput;
};
export type BankAccountInput = {
    bank?: BankInput | null;
    checkingAccount?: string | null;
    correspondentAccount?: string | null;
};
export type BankInput = {
    bic?: string | null;
    name?: string | null;
};
export type UpdateBankAccountMutationVariables = {
    input: UpdateBankAccountInput;
};
export type UpdateBankAccountMutationResponse = {
    readonly updateBankAccount: {
        readonly account: {
            readonly id: string;
            readonly status: BankAccountStatusEnum;
            readonly checkingAccount: string | null;
            readonly correspondentAccount: string | null;
            readonly bank: {
                readonly bic: string;
                readonly name: string;
            } | null;
        };
    };
};
export type UpdateBankAccountMutation = {
    readonly response: UpdateBankAccountMutationResponse;
    readonly variables: UpdateBankAccountMutationVariables;
};



/*
mutation UpdateBankAccountMutation(
  $input: UpdateBankAccountInput!
) {
  updateBankAccount(input: $input) {
    account {
      id
      status
      checkingAccount
      correspondentAccount
      bank {
        bic
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBankAccountInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateBankAccountPayload",
    "kind": "LinkedField",
    "name": "updateBankAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccount",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkingAccount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "correspondentAccount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Bank",
            "kind": "LinkedField",
            "name": "bank",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bic",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBankAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBankAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateBankAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBankAccountMutation(\n  $input: UpdateBankAccountInput!\n) {\n  updateBankAccount(input: $input) {\n    account {\n      id\n      status\n      checkingAccount\n      correspondentAccount\n      bank {\n        bic\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f0e3415a018526977fd41107a75cd571';
export default node;
