import React from "react"
import Relay from "react-relay"
import { Route, Switch } from "react-router-dom"

import { isInvestor, isJuristicBorrower, getProperty } from "src/utils"
import { Box, Forms } from "src/components"

import {
  Group,
  EditControls,
  DeclinedAlert,
  ModerationAlert,
  UpdateProfileButton,
} from "./elements"

import { buildGroups } from "./constants"
import fragment from "./AccreditationInfoFragment"

const useSchema = (profile) => React.useMemo(() => buildGroups(profile), [profile])

const AccreditationForm = React.memo(({ profile }: any) => {
  if (!profile) {
    return null
  }

  if (isInvestor(profile)) {
    return (
      <React.Fragment>
        <Forms.individualProfile.accreditation.investor
          profileId={profile.id}
        />
      </React.Fragment>
    )
  }

  return null
})

/* eslint-disable no-underscore-dangle */
const AccreditationInfo = (props) => {
  const profile = props.profile || props.foreignProfile
  const schema = useSchema(profile)
  const accreditationStatus = getProperty(profile, "accreditation.status", "")
  const declineReason = getProperty(profile, "accreditation.declineReason", "")
  const path = isJuristicBorrower(profile)
    ? `/profiles/${profile.id}/accreditation/borrower`
    : `/accounts/${profile.id}/accreditation`

  return (
    <>
      {accreditationStatus === "PENDING" && <ModerationAlert />}
      {accreditationStatus === "DECLINED" && (
        <DeclinedAlert reason={declineReason} />
      )}
      <UpdateProfileButton profile={profile} history={props.history} />
      <Box mb="45px">
        {schema.map((item, idx) => (
          <Group key={idx} item={item} />
        ))}
      </Box>
      {accreditationStatus !== "PENDING" && <EditControls path={path} />}
    </>
  )
}

const AccreditationInfoFragmentContainer = Relay.createFragmentContainer(
  AccreditationInfo,
  fragment,
)

const render = (props) => {
  const profile = props.profile || props.foreignProfile

  return (
    <>
      <Switch>
        <Route
          exact
          path="/accounts/:id"
          render={(routeProps) => (
            <AccreditationInfoFragmentContainer {...props} {...routeProps} />
          )}
        />
        <Route
          exact
          path="/accounts/:id/accreditation"
          render={() => <AccreditationForm profile={profile} />}
        />
      </Switch>
    </>
  )
}

export default React.memo(render)
