import React from "react"

import {
  TableRow,
  TableTdName,
  TableTdRating,
  TableTdNameWrap,
  TableTdSum,
  TableTdRate,
  TableTdStatus,
} from "./styles"
import { Chart } from "./icons"

function Table() {
  return (
    <TableRow>
      <TableTdName title={"_"}>
        <TableTdNameWrap>_</TableTdNameWrap>
      </TableTdName>
      <TableTdSum>0</TableTdSum>
      <TableTdRate>0%</TableTdRate>
      <td>_</td>
      <td>_</td>
      <td>_</td>
      <TableTdRating>_</TableTdRating>
      <TableTdStatus isSuccess={true}>{Chart} Выплаты по графику</TableTdStatus>
    </TableRow>
  )
}

export default {
  Table,
}
