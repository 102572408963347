import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const DesktopFooterDescription = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const DesktopFooterLinkContainer = styled.div`
  display: flex;
  margin-left: 50px;
`

export const FooterDescription = (props) => {
  const mobile = useMediaPredicate("(max-width: 680px)")

  return !mobile && <DesktopFooterDescription {...props}/>
}

export const FooterLinkContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 900px)")

  return !mobile && <DesktopFooterLinkContainer {...props}/>
}
