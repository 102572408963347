/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type GatheredAmountSubscriptionInput = {
    orderId: string;
};
export type OwnerProposalSpecificationForGatheredAmountSubscriptionVariables = {
    input: GatheredAmountSubscriptionInput;
};
export type OwnerProposalSpecificationForGatheredAmountSubscriptionResponse = {
    readonly gatheredAmount: {
        readonly order: {
            readonly status: OrderStatusEnum | null;
            readonly chain: {
                readonly gatheredAmount: number;
                readonly investorsCount: number;
            } | null;
        };
    };
};
export type OwnerProposalSpecificationForGatheredAmountSubscription = {
    readonly response: OwnerProposalSpecificationForGatheredAmountSubscriptionResponse;
    readonly variables: OwnerProposalSpecificationForGatheredAmountSubscriptionVariables;
};



/*
subscription OwnerProposalSpecificationForGatheredAmountSubscription(
  $input: GatheredAmountSubscriptionInput!
) {
  gatheredAmount(input: $input) {
    order {
      status
      chain {
        gatheredAmount
        investorsCount
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "GatheredAmountSubscriptionInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gatheredAmount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "investorsCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OwnerProposalSpecificationForGatheredAmountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GatheredAmountSubscriptionPayload",
        "kind": "LinkedField",
        "name": "gatheredAmount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OwnerProposalSpecificationForGatheredAmountSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GatheredAmountSubscriptionPayload",
        "kind": "LinkedField",
        "name": "gatheredAmount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderChainInfo",
                "kind": "LinkedField",
                "name": "chain",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "OwnerProposalSpecificationForGatheredAmountSubscription",
    "operationKind": "subscription",
    "text": "subscription OwnerProposalSpecificationForGatheredAmountSubscription(\n  $input: GatheredAmountSubscriptionInput!\n) {\n  gatheredAmount(input: $input) {\n    order {\n      status\n      chain {\n        gatheredAmount\n        investorsCount\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '63db605366b76a769a8d4ebafb95ec6f';
export default node;
