import React from "react"

import { Button, FlexBox } from "src/components"

import { RemoveIcon, RemoveButtonWrapper } from "./styles"

const RemoveButton = ({ onClick }) => (
  <RemoveButtonWrapper>
    <Button onClick={onClick} variant="defaultThin">
      <FlexBox display="flex" flexDirection="row" alignItems="center">
        <RemoveIcon />
      </FlexBox>
    </Button>
  </RemoveButtonWrapper>
)

export default RemoveButton
