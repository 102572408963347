import React from "react"

import { Description, Button, Container } from "./styles"

function Btn(props) {
  const {
    onClick, children, descr, disabled,
  } = props

  return (
    <Container>
      <Button disabled={disabled} onClick={onClick}>
        {children}
      </Button>
      {descr && <Description>{descr}</Description>}
    </Container>
  )
}

export default Btn
