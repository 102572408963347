import React from "react"

import { Translate } from "src/components"

import {
  pipe, createChainedFunction, number,
} from "src/utils"

import { calcMaxInvestmentAmount } from "./hookUseOnSubmit"

import { AmountType, ErrorType } from "../types"

export const getErrorTranslation = (error: ErrorType) => {
  if (error?.sum) return <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" sum={error.sum} />
  return error && <Translate i18n={`${error.path}.${error.keyword}`} ns="errors" />
}

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const useOnChange = (props: AmountType) => React.useCallback((e) => {
  const maxInvestmentAmount = calcMaxInvestmentAmount(props.profile, props.availableAmount)
  const amountAsNumber = Number(
    e.target.value.replace(",", "").replace(/\s/g, ""),
  )

  createChainedFunction(
    () => props.clearError("investToOrder.amount"),
    props.onChange,
  )(e)

  if (amountAsNumber > maxInvestmentAmount) {
    props.setManualError({
      keyword: "max",
      path: "investToOrder.amount",
      sum: number(maxInvestmentAmount, options, "ru"),
    })
  }
}, [props.onChange])

export const useErrors = (props: AmountType) => React.useCallback(
  (path) => pipe(props.getError, getErrorTranslation)(path),
  [],
)
