import React, { useState } from "react"

import { Icons, FloatingLabelInput } from "src/components"

import { Container, InputButton } from "./styles"

function InputPassword(props) {
  const { className, ...rest } = props

  const [isHidden, setHidden] = useState(true)

  function handleClick() {
    setHidden(!isHidden)
  }

  return (
    <Container className={className}>
      <FloatingLabelInput type={isHidden ? "password" : "text"} {...rest} />
      <InputButton type="button" onClick={handleClick}>
        {isHidden && <Icons.ClosedEye />}
        {!isHidden && <Icons.OpenedEye />}
      </InputButton>
    </Container>
  )
}

export default InputPassword
