import React from "react"

import {
  Box,
  Text,
  Label,
  Tooltip,
  Translate,
  FloatingLabelInput,
} from "src/components"

import { initialStateFromProps } from "src/utils"

export class Business extends React.Component<any, any> {
  state = {
    ...initialStateFromProps(this.props.value, [
      "main",
      "aux",
      "legalForm",
      "startedAt",
    ]),
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: { ...this.state },
    })
  }

  render() {
    // TODO: Romove all commented legacy code
    return (
      <React.Fragment>
        <FloatingLabelInput
          name="legalForm"
          value={this.state.legalForm}
          onChange={this.onChange}
          error={""} // string value to show as an error
          hasError={false} // trigger to apply a red style for input
          label={
            "components:accreditation.borrower.rows.organizational_form.label"
          }
        />
        {/* <FormRow>
          <Box width={260}>
            <Label whiteSpace="pre-line">
              <Translate
                i18n="accreditation.borrower.rows.organizational_form.label"
                ns="components"
              />
              <Tooltip text="tooltips.organizational_form"/>
            </Label>
          </Box>
          <Box width="100%">
            <TextField
              type="string"
              name="legalForm"
              value={this.state.legalForm}
              onChange={this.onChange}
            />
          </Box>
        </FormRow> */}

        <Box mb={20}>
          <FloatingLabelInput
            name="startedAt"
            value={this.state.startedAt}
            onChange={this.onChange}
            error={""} // string value to show as an error
            hasError={false} // trigger to apply a red style for input
            label={"components:accreditation.borrower.rows.start_date.label"}
          />
          <Box mt={-15}>
            <Text
              color="greyDark"
              fontSize="0.8125rem"
              display="flex"
              alignItems="center"
            >
              <Translate
                i18n="accreditation.borrower.rows.start_date.hint"
                ns="components"
              />
              <Tooltip text="tooltips.start_date" />
            </Text>
          </Box>
        </Box>
        {/* <FormRow>
          <Box width={260}>
            <Label whiteSpace="pre-line">
              <Translate
                i18n="accreditation.borrower.rows.start_date.label"
                ns="components"
              />
            </Label>
            <Label whiteSpace="pre-line">
              <Translate
                i18n="accreditation.borrower.rows.start_date.hint"
                ns="components"
              />
              <Tooltip text="tooltips.start_date" />
            </Label>
          </Box>
          <Box width="100%">
            <TextField
              type="string"
              name="startedAt"
              value={this.state.startedAt}
              onChange={this.onChange}
            />
          </Box>
        </FormRow> */}

        <Box display="block">
          <Label whiteSpace="pre-line" mb="2px">
            <Translate
              i18n="accreditation.borrower.rows.activity_kind.label"
              ns="components"
            />
            <Tooltip text="tooltips.activity_kind" />
          </Label>

          <FloatingLabelInput
            name="main"
            value={this.state.main}
            onChange={this.onChange}
            error={""} // string value to show as an error
            hasError={false} // trigger to apply a red style for input
            label={
              "components:accreditation.borrower.rows.activity_kind.row_hints.base"
            }
          />

          <FloatingLabelInput
            name="aux"
            value={this.state.aux}
            onChange={this.onChange}
            error={""} // string value to show as an error
            hasError={false} // trigger to apply a red style for input
            label={
              "components:accreditation.borrower.rows.activity_kind.row_hints.additional"
            }
          />
        </Box>

        {/* <FormRow>
          <Box width={260}>
            <Label whiteSpace="pre-line">
              <Translate
                i18n="accreditation.borrower.rows.activity_kind.label"
                ns="components"
              />
              <Tooltip text="tooltips.activity_kind" />
            </Label>
          </Box>
          <Box width="100%">
            <Box pb={40}>
              <TextField
                type="string"
                name="main"
                value={this.state.main}
                onChange={this.onChange}
                hint={
                  <Translate
                    i18n="accreditation.borrower.rows.activity_kind.row_hints.base"
                    ns="components"
                  />
                }
              />
            </Box>
            <Box pb={40}>
              <TextField
                type="string"
                name="aux"
                value={this.state.aux}
                onChange={this.onChange}
                hint={
                  <Translate
                    i18n="accreditation.borrower.rows.activity_kind.row_hints.additional"
                    ns="components"
                  />
                }
              />
            </Box>
          </Box>
        </FormRow> */}
      </React.Fragment>
    )
  }
}

export default Business
