import React from "react"
import memoize from "memoize-one"
import styled from "styled-components"

import { notifyGTM } from "src/utils"
import { ViewerDataConsumer } from "src/context"

import { Translate } from "src/components"
import Link from "../Link"
import Text from "../Text"
import Flex from "../Flex"

import Icon from "./Icon"

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`

const sendGTMEvent = memoize((viewer) => notifyGTM({
  eventAction: "invest-reminder",
  eventLabel: "click",
  extras: {
    "profile-id": viewer.id,
  },
}))

const InvestInfoLink = (props) => (
  <StyledLink
    to="/invest/info"
    target="_blank"
    onClick={sendGTMEvent(props.viewer)}
  >
    <Flex width={170} alignItems="center" justifyContent="space-between">
      <Icon />
      <Text fontSize={14} color="#3a66ab">
        <Translate i18n="pages:user.market.investInfo" />
      </Text>
    </Flex>
  </StyledLink>
)

export default (props) => (
  <ViewerDataConsumer>
    {(viewer) => <InvestInfoLink {...props} viewer={viewer} />}
  </ViewerDataConsumer>
)
