import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateTaxPaymentDocumentsMutation(
    $input: updateTaxPaymentDocumentsInput!
  ) {
    updateTaxPaymentDocuments(input: $input) {
      status
    }
  }
`

export default enhance({ mutation })
