import styled from "styled-components"
import { FormLoadingButton } from "src/components"

export const Container = styled.div`
  position: relative;
  padding-top: 42px;
  display: flex;
  width: 100%;

  @media screen and (max-width: 700px) {
    justify-content: space-between;
    flex-direction: column;
  }
`

export const ButtonContainer = styled.div`
  position: relative;

  :first-of-type {
    margin-right: auto;
  }

  :not(:first-of-type) {
    margin-left: 20px;
  }

  @media screen and (max-width: 700px) {
    margin: 0 !important;
  }
`

export const StyledButton = styled(FormLoadingButton)`
  height: 40px;
  font-size: 0.625rem;
  padding: 0 12px;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 20px 0 0;
    height: 60px;
    font-size: 0.875rem;
  }
`
