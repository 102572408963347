import { ConnectionConfig, GraphQLTaggedNode } from "react-relay"
import React from "react"
import { VISIBLE_ORDER } from "./constants"

export const getInvestmentConnectionConfig = (
  graphqlQuery: GraphQLTaggedNode,
  profileType,
): ConnectionConfig<any> => ({
  direction: "forward",
  query: graphqlQuery,
  getConnectionFromProps: (props) =>
    props[profileType] && props[profileType].node.investor.investments,
  getFragmentVariables: (previousVars) => ({
    ...previousVars,
    count: VISIBLE_ORDER,
  }),
  getVariables: (props, { cursor }, { profileId, filter }) => ({
    count: VISIBLE_ORDER,
    cursor,
    profileId,
    filter,
  }),
})

export const InvestorRenderComponent = (props) => {
  const { profileType, filter, setFilter } = props
  const node = props[profileType]?.node ?? null
  const data = node?.investor?.investments

  const hasNextPage = Boolean(data?.pageInfo?.hasNextPage)
  const { hasMore, isLoading, loadMore } = props.relay

  function handleLoad() {
    if (hasMore() && !isLoading()) {
      loadMore(VISIBLE_ORDER)
    }
  }

  const Table = props.render({
    data: node ?? {},
    load: { handleLoad, hasNextPage },
    isFetching: node === null,
    filter,
    setFilter,
  })

  return <>{Table}</>
}

export const getBorrowerConnectionConfig = (
  graphqlQuery: GraphQLTaggedNode,
  profileType,
): ConnectionConfig<any> => ({
  direction: "forward",
  query: graphqlQuery,
  getConnectionFromProps: (props) =>
    props[profileType] && props[profileType].node.borrower.orders,
  getFragmentVariables: (previousVars) => ({
    ...previousVars,
    count: VISIBLE_ORDER,
  }),
  getVariables: (props, { cursor }, { profileId, filter }) => ({
    count: VISIBLE_ORDER,
    cursor,
    profileId,
    filter,
  }),
})

export const BorrowerRenderComponent = (props) => {
  const { profileType } = props
  const { node } = props[profileType]
  const data = node.borrower.orders

  const { hasNextPage } = data.pageInfo
  const { hasMore, isLoading, loadMore } = props.relay

  function handleLoad() {
    if (hasMore() && !isLoading()) {
      loadMore(VISIBLE_ORDER)
    }
  }

  const Table = props.render({
    data: node,
    load: { handleLoad, hasNextPage },
  })

  return <>{Table}</>
}
