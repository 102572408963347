import styled from "styled-components"

export const TagsContainer = styled.div`
  display: flex;
  height: 53px;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
`
export const TagsItem = styled.div`
  display: block;
  font-size: 11px;
  line-height: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 7px;
  padding-left: 5px;
  padding-right: 3px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  margin-right: 8px;
  border-radius: 3px;
  &:last-child {
    margin-right: 0;
  }
`
