import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { color, style, space } from "styled-system"

const textDecoration = style({
  prop: "textDecoration",
  cssProperty: "textDecoration",
})

const StyledLink = styled(Link)`
  ${color}
  ${space}
  ${textDecoration}
`

const render = (props) => <StyledLink {...props} />

render.defaultProps = {
  color: "currentColor",
  textDecoration: "none",
}

export default render
