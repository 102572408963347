import memoize from "memoize-one"
import idx from "idx"

export const isOrderCompleted = (status) => ["COMPLETE", "SUCCEEDED", "TRANSFER"].includes(status)

export const getGatheredPercents = memoize((chain, application, status) => {
  const key = isOrderCompleted(status) ? "minValue" : "maxValue"
  const gatheredPercents = chain !== undefined
    && chain !== null
    && idx(application, (_) => _.data[key])
    ? (chain.gatheredAmount * 100) / +application.data[key]
    : null

  return !Number.isNaN(gatheredPercents) && gatheredPercents <= 100
    ? gatheredPercents
    : 100
})

export const getMinValuePercents = memoize((application) => {
  const minValuePercents = idx(application, (_) => _.data.maxValue)
    && idx(application, (_) => _.data.minValue)
    ? (application.data.minValue * 100) / +application.data.maxValue
    : null

  return !Number.isNaN(minValuePercents) && minValuePercents <= 100
    ? minValuePercents
    : 100
})

export const shouldRenderLoanAmount = (chain, application) => chain !== undefined
  && chain !== null
  && idx(application, (_) => _.data.maxValue)
