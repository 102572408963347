import React from "react"

import {
  AttachmentList,
  Attachment,
  Value,
  DocumentIcon,
  CloseButton,
  CloseIcon,
} from "./styles"

function AttachmentFileList(props) {
  const { files, onDelete } = props

  return (
    <>
      {files.length > 0 && (
        <AttachmentList>
          {files.map((file, idx) => (
            <Attachment key={idx}>
              <DocumentIcon />
              <Value>{file.name}</Value>
              <CloseButton onClick={() => onDelete(idx)}>
                <CloseIcon />
              </CloseButton>
            </Attachment>
          ))}
        </AttachmentList>
      )}
    </>
  )
}

export default AttachmentFileList
