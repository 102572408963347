import React from "react"

import {
  Box, Label, FlexBox, FormRow, TextField, Translate,
} from "src/components"
import { cloneDeep } from "src/utils"

class EgrnData extends React.Component<any, any> {
  onChange = (event) => {
    const { name, value } = event.target
    const egrnData = cloneDeep(this.props.value)
    egrnData[name] = value

    this.props.onChange({
      name: this.props.name,
      value: egrnData,
    })
  }

  render() {
    return (
      <FormRow>
        <Box>
          <Label>
            <Translate
              i18n="legal_entity_offer_form.rows.egrn_data.label"
              ns="components"
            />
          </Label>
        </Box>
        <FlexBox display="flex" flexDirection="row" justifyContent="flex-start">
          <Box width="80px" mr={20}>
            <TextField
              value={this.props.value.area}
              onChange={this.onChange}
              name="area"
              hint={
                <Translate
                  i18n="legal_entity_offer_form.rows.egrn_data.row_hints.area"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width="130px" mr={20}>
            <TextField
              value={this.props.value.flatCount}
              onChange={this.onChange}
              name="flatCount"
              hint={
                <Translate
                  i18n="legal_entity_offer_form.rows.egrn_data.row_hints.flat_count"
                  ns="components"
                />
              }
            />
          </Box>
          <Box width="80px">
            <TextField
              value={this.props.value.floor}
              onChange={this.onChange}
              name="floor"
              hint={
                <Translate
                  i18n="legal_entity_offer_form.rows.egrn_data.row_hints.floor"
                  ns="components"
                />
              }
            />
          </Box>
        </FlexBox>
      </FormRow>
    )
  }
}

export default EgrnData
