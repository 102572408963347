/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SupportingDocumentStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type taxPaymentForProfileQueryVariables = {
    profileId: string;
};
export type taxPaymentForProfileQueryResponse = {
    readonly node: {
        readonly taxPaymentDocuments?: ReadonlyArray<{
            readonly id: string;
            readonly attachment: {
                readonly id: string;
                readonly url: string;
                readonly size: number;
                readonly width: number | null;
                readonly height: number | null;
                readonly format: string | null;
                readonly filename: string;
            };
            readonly status: SupportingDocumentStatus | null;
        }>;
    };
};
export type taxPaymentForProfileQuery = {
    readonly response: taxPaymentForProfileQueryResponse;
    readonly variables: taxPaymentForProfileQueryVariables;
};



/*
query taxPaymentForProfileQuery(
  $profileId: ID!
) {
  node(id: $profileId) {
    __typename
    ... on UserProfile {
      taxPaymentDocuments {
        id
        attachment {
          id
          url
          size
          width
          height
          format
          filename
        }
        status
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profileId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SupportingDocument",
  "kind": "LinkedField",
  "name": "taxPaymentDocuments",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachment",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taxPaymentForProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taxPaymentForProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "taxPaymentForProfileQuery",
    "operationKind": "query",
    "text": "query taxPaymentForProfileQuery(\n  $profileId: ID!\n) {\n  node(id: $profileId) {\n    __typename\n    ... on UserProfile {\n      taxPaymentDocuments {\n        id\n        attachment {\n          id\n          url\n          size\n          width\n          height\n          format\n          filename\n        }\n        status\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0ce3d1a2d7178397d3e7e7c8dcc9487c';
export default node;
