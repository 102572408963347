import { css, createGlobalStyle } from "styled-components"

const fonts = [
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 500,
  },
  {
    name: "Bold",
    weight: 600,
  },
]

const fontFaces = fonts.map(
  (font) => css`
    @font-face {
      font-family: "HelveticaNeue";
      font-weight: ${font.weight};
      src: local(${`Helvetica Neue ${font.name}`}),
        url(/fonts/HelveticaNeue/${font.name}.woff) format("woff");
    }
  `,
)

const openSansFonts = [
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 600,
  },
  {
    name: "Bold",
    weight: 700,
  },
]

const openSansFontFaces = openSansFonts.map(
  (font) => css`
    @font-face {
      font-family: "Open Sans";
      font-weight: ${font.weight};
      src: local(${`Open Sans ${font.name}`}),
        url(/fonts/OpenSans/${font.name}.ttf) format("truetype");
    }
  `,
)

const geometriaFonts = [
  {
    name: "Light",
    weight: 300,
  },
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 600,
  },
  {
    name: "Bold",
    weight: 700,
  },
  {
    name: "Heavy",
    weight: 900,
  },
]

const geometriaFontFaces = geometriaFonts.map(
  (font) => css`
    @font-face {
      font-family: "Geometria";
      font-weight: ${font.weight};
      src: local(${`Geometria ${font.name}`}),
        url(/fonts/Geometria/${font.name}.ttf) format("truetype");
    }
  `,
)

const montserratFonts = [
  {
    name: "Light",
    weight: 300,
  },
  {
    name: "Regular",
    weight: 400,
  },
  {
    name: "Medium",
    weight: 600,
  },
  {
    name: "Bold",
    weight: 700,
  },
  {
    name: "Black",
    weight: 800,
  },
]

const montserratFontFaces = montserratFonts.map(
  (font) => css`
    @font-face {
      font-family: "Montserrat";
      font-weight: ${font.weight};
      src: local(${`Montserrat ${font.name}`}),
        url(/fonts/Montserrat/${font.name}.ttf) format("truetype");
    }
  `,
)

/* eslint-disable no-unused-expressions */
const Fonts = createGlobalStyle`
  ${fontFaces}
  ${openSansFontFaces}
  ${montserratFontFaces}
  ${geometriaFontFaces}
`

export default Fonts
