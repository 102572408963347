import React from "react"

import {
  Box, Label, FormRow, Translate, TextField,
} from "src/components"

export default (props) => (
  <FormRow>
    <Box>
      <Label>
        <Translate
          i18n="legal_entity_offer_form.rows.additional_information.label"
          ns="components"
        />
      </Label>
    </Box>
    <Box width="480px">
      <TextField
        name="additionalInfo"
        onChange={props.onChange}
        value={props.value}
      />
    </Box>
  </FormRow>
)
