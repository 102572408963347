import React from "react"

import { IconContainer } from "./styles"

const disabledColor = "#7B838B"

/* eslint-disable max-len */
const doc = ({ disabled }) => (
  <g fill="none" fillRule="evenodd">
    <path fill="#FFF" d="M-51-636h1280V464H-51z" />
    <path
      fill={disabled ? disabledColor : "#2372BA"}
      d="M24 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4z"
    />
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M19 7l-2.94 10h-2.442l-1.677-5.795L10.179 17H7.744L5 7h2.501L9.1 13.462 11.048 7h1.902l1.814 6.462L16.504 7H19"
    />
  </g>
)

const pdf = ({ disabled }) => (
  <g fill="none" fillRule="evenodd">
    <path fill="#FFF" d="M-51-546h1280V554H-51z" />
    <path
      fill={disabled ? disabledColor : "#E2251C"}
      d="M24 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4z"
    />
    <path
      fill="#FFF"
      fillRule="nonzero"
      stroke="#FFF"
      strokeWidth=".5"
      d="M11.28 8.592c.096-.384.192-.576.192-.864v-.192c.096-.48.096-.864 0-.96V6.48l-.096-.096s0 .096-.096.096c-.192.576-.192 1.248 0 2.112zM8.4 15.216c-.192.096-.384.192-.48.288-.672.576-1.152 1.248-1.248 1.536.576-.096 1.152-.672 1.728-1.824zm8.832-1.44c-.096-.096-.48-.384-1.824-.384h-.192v.096c.672.288 1.344.48 1.824.48H17.328v-.096s-.096 0-.096-.096zm.384.768c-.192.096-.48.192-.864.192-.768 0-1.92-.192-2.88-.672-1.632.192-2.88.384-3.84.768-.096 0-.096 0-.192.096-1.152 2.016-2.112 2.976-2.88 2.976-.192 0-.288 0-.384-.096l-.48-.288v-.096C6 17.232 6 17.136 6 16.944c.096-.48.672-1.344 1.824-2.016.192-.096.48-.288.864-.48.288-.48.576-1.056.96-1.728.48-.96.768-1.92 1.056-2.784-.384-1.152-.576-1.824-.192-3.168.096-.384.384-.768.768-.768h.192c.192 0 .384.096.576.192.672.672.384 2.208 0 3.456v.096c.384 1.056.96 1.92 1.536 2.496.288.192.48.384.864.576.48 0 .864-.096 1.248-.096 1.152 0 1.92.192 2.208.672.096.192.096.384.096.576-.096.096-.192.384-.384.576zm-6.24-3.744c-.192.672-.576 1.44-.96 2.304-.192.384-.384.672-.576 1.056h.192c1.248-.48 2.4-.768 3.168-.864-.192-.096-.288-.192-.384-.288-.48-.576-1.056-1.344-1.44-2.208z"
    />
  </g>
)

const excel = ({ disabled }) => (
  <g fill="none" fillRule="evenodd">
    <path fill="#FFF" d="M-51-591h1280V509H-51z" />
    <path
      fill={disabled ? disabledColor : "#08743B"}
      d="M24 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4z"
    />
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M16.5 17h-2.645l-1.805-3.284L10.155 17H7.5l3.295-5.335L8.133 7h2.684l1.246 2.537L13.39 7h2.73l-2.79 4.665L16.5 17"
    />
  </g>
)

const question = () => (
  <>
    <defs>
      <path id="a" d="M.443.5h2.5V3h-2.5z" />
      <path id="c" d="M0 .29h6.755v8.692H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#9EA6AE" />
      <g transform="translate(10 15.71)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FFF"
          d="M.443 1.75a1.25 1.25 0 1 1 2.501.001 1.25 1.25 0 0 1-2.5 0"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(8 5.71)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#FFF"
          d="M5.808 1.256C4.667.114 2.787-.036 1.533.91 1.416.984.387 1.674.03 3.17L0 3.292l1.703.406.028-.12c.18-.755.652-1.13.797-1.23a1.568 1.568 0 0 1 2.043.146c.287.287.44.67.434 1.075a1.475 1.475 0 0 1-.472 1.06l-.654.608a3.337 3.337 0 0 0-1.06 2.435v1.31h1.75v-1.31c0-.437.182-.857.502-1.154l.653-.608a3.208 3.208 0 0 0 1.03-2.31 3.204 3.204 0 0 0-.946-2.344"
          mask="url(#d)"
        />
      </g>
    </g>
  </>
)

const star = () => (
  <g fill="none" fillRule="evenodd">
    <path
      fill="#9EA6AE"
      d="M20.5 24h-17A3.5 3.5 0 0 1 0 20.5v-17A3.5 3.5 0 0 1 3.5 0h17A3.5 3.5 0 0 1 24 3.5v17a3.5 3.5 0 0 1-3.5 3.5"
    />
    <path
      fill="#FFF"
      d="M10.074 10.046L5 10.086l4.304 2.795-1.63 5.433 4.327-3.496 4.325 3.496-1.63-5.433L19 10.085l-5.073-.04L12.001 5z"
    />
  </g>
)

const medium = () => (
  <g fill="none" fillRule="evenodd">
    <path fill="#9EA6AE" d="M0 24h24V0H0z" />
    <path
      fill="#FFF"
      d="M5.728 8.026a.624.624 0 0 0-.204-.526L4.02 5.686v-.27h4.675l3.611 7.922 3.178-7.923h4.456v.271L18.65 6.92a.377.377 0 0 0-.142.361v9.068a.376.376 0 0 0 .142.361l1.258 1.234v.271h-6.323v-.27l1.301-1.265c.128-.128.128-.166.128-.36V8.99l-3.62 9.195h-.489L6.69 8.99v6.163c-.035.26.051.52.233.708l1.695 2.054v.271H3.815v-.27l1.693-2.055a.818.818 0 0 0 .22-.708V8.026z"
    />
  </g>
)

const telegram = () => (
  <g fill="none" fillRule="evenodd">
    <mask id="b" fill="#fff">
      <path d="M0 0h24v24H0z" />
    </mask>
    <path
      fill="#9EA6AE"
      d="M15.696 17.71s-.3.748-1.124.39l-3.05-2.34v.001l-1.956 1.783s-.153.116-.32.044l.374-3.314.013.01.007-.067s5.487-4.93 5.71-5.14c.226-.21.15-.256.15-.256.016-.255-.404 0-.404 0l-7.27 4.617-3.026-1.02s-.465-.165-.51-.524c-.045-.36.524-.555.524-.555L16.85 6.62s.99-.436.99.284L15.696 17.71zM12 0C5.373 0 0 5.372 0 12c0 6.627 5.373 12 12 12 6.628 0 12-5.373 12-12 0-6.628-5.372-12-12-12z"
    />
  </g>
)

const web = () => (
  <g fill="none" fillRule="evenodd">
    <path
      fill="#9EA6AE"
      d="M20.5 24h-17A3.5 3.5 0 0 1 0 20.5v-17A3.5 3.5 0 0 1 3.5 0h17A3.5 3.5 0 0 1 24 3.5v17a3.5 3.5 0 0 1-3.5 3.5"
    />
    <path
      fill="#FFF"
      d="M13.982 17.124c.502-1.118.841-2.649.917-4.374h2.543a5.504 5.504 0 0 1-3.46 4.374zM6.557 12.75H9.1c.076 1.725.415 3.256.917 4.375a5.509 5.509 0 0 1-3.46-4.375zm3.46-5.875c-.502 1.119-.84 2.651-.917 4.375H6.557a5.509 5.509 0 0 1 3.46-4.375zm.593 4.375c.135-2.761.965-4.392 1.39-4.716.424.324 1.254 1.955 1.39 4.716h-2.78zm0 1.5h2.78c-.136 2.761-.966 4.392-1.39 4.717-.425-.325-1.255-1.956-1.39-4.717zm6.832-1.5H14.9c-.076-1.724-.415-3.256-.917-4.375a5.507 5.507 0 0 1 3.46 4.375zM12 5a7 7 0 0 0-7 7 7 7 0 1 0 7-7z"
    />
  </g>
)

const info = () => (
  <g fill="none" fillRule="evenodd">
    <circle cx="12" cy="12" r="12" fill="#9EA6AE" />
    <g fill="#FFF">
      <path d="M10 8.96v1.75h1.25v5.25H9v1.75h6.25v-1.75H13v-7zM11.742 7.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5" />
    </g>
  </g>
)

const defaultGlyph = () => (
  <g fill="none" fillRule="evenodd">
    <rect width="24" height="24" fill="#3A66AB" rx="3.5" />
    <path fill="#FFF" d="M6 13h12v-2H6zM6 18h6v-2H6zM6 8h12V6H6z" />
  </g>
)

const document = () => (
  <g fill="none" fillRule="evenodd">
    <rect width="24" height="24" fill="#9EA6AE" rx="3.5" />
    <path fill="#FFF" d="M6 13h12v-2H6zM6 18h6v-2H6zM6 8h12V6H6z" />
  </g>
)

const glyphs = {
  document,
  doc,
  pdf,
  web,
  xls: excel,
  xlsx: excel,
  docx: doc,
  star,
  info,
  medium,
  default: defaultGlyph,
  question,
  telegram,
}

const Icon = (props) => {
  const Glyph = glyphs[props.children] || glyphs.default

  return (
    <IconContainer>
      {Glyph ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <Glyph disabled={props.disabled} />
        </svg>
      ) : null}
    </IconContainer>
  )
}

export default Icon
