import React from "react"
import i18n from "src/i18n"

import {
  startOfQuarter, subQuarters, endOfQuarter, format,
} from "date-fns"

import { Translate, FloatingLabelInput } from "src/components"
import { useMutation } from "src/hooks"
import { CreateInvestorIncomeReport } from "src/mutations"

import {
  Container, Title, DownloadIcon, DownloadButton, PeriodContainer, Text, Link, Bold,
} from "./styles"

import { Error } from "./elements"
import TaxPaymentDocuments from "./taxPayment"

const parseDate = (str) => Date.parse(str.split(" - ").reverse().join("-"))

const Render = (props) => {
  const mutation = useMutation(CreateInvestorIncomeReport)

  const [state, setState] = React.useState({
    dateFrom: format(startOfQuarter(subQuarters(new Date(), 1)), "DD - MM - YYYY"),
    dateTo: format(endOfQuarter(subQuarters(new Date(), 1)), "DD - MM - YYYY"),
    error: undefined,
  })

  mutation.onCompleted((data) => {
    window.open(data.createInvestorIncomeReport.url, "_blank")
  })

  mutation.onError((error) => {
    setState((prevState) => ({
      ...prevState,
      error: error.errors[0].message.toLowerCase().replace(" ", "_"),
    }))
  })

  const handleChange = (event) => {
    const { value, name } = event.target

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: null,
    }))
  }

  const handleClick = () => {
    mutation.commit({
      input: {
        profileId: props.currentProfile.id,
        dateFrom: format(parseDate(state.dateFrom), "YYYY-MM-DD"),
        dateTo: format(parseDate(state.dateTo), "YYYY-MM-DD"),
      },
    })
  }

  const dateFromError = !state.dateFrom || Number.isNaN(parseDate(state.dateFrom))
  const dateToError = !state.dateTo || Number.isNaN(parseDate(state.dateTo))
  const validPeriod = parseDate(state.dateFrom) <= parseDate(state.dateTo)
  const disabled = dateFromError || dateToError || !validPeriod || mutation.busy

  const error = validPeriod
    ? state.error && `components:account.account_item.tabs.taxes.period_error.${state.error}`
    : "components:account.account_item.tabs.taxes.period_error.invalid"

  return (
    <Container>
      <Title>
        <Translate i18n="components:account.account_item.tabs.taxes.section1" />
      </Title>
      <PeriodContainer>
        <FloatingLabelInput
          type="text"
          name="dateFrom"
          value={state.dateFrom}
          width="214px"
          mask="99 - 99 - 9999"
          label="components:account.account_item.tabs.taxes.period_start"
          onChange={handleChange}
          hasError={dateFromError}
        />
        <FloatingLabelInput
          type="text"
          name="dateTo"
          value={state.dateTo}
          width="214px"
          mask="99 - 99 - 9999"
          label="components:account.account_item.tabs.taxes.period_end"
          onChange={handleChange}
          hasError={dateToError}
        />
      </PeriodContainer>
      <DownloadButton onClick={handleClick} disabled={disabled} busy={mutation.busy}>
        <span>Cкачать</span>
        <DownloadIcon />
      </DownloadButton>
      <Error
        variant="red"
        error={error && i18n.t([error, "components:account.account_item.tabs.taxes.period_error.unknown"])}
      />
      <Title>Пояснение Кармы по уплате НДФЛ:</Title>
      <Text>
        Дорогие инвесторы,{"\n\n"}
        Согласно Правилам платформы Карма удерживает НДФЛ для последующей уплаты в ФНС.
        {"\n\n"}В связи с наличием ограничений в операциях по номинальному счету согласно 259-ФЗ Карма не имеет
        возможности заплатить НДФЛ за инвесторов как третье лицо, в связи с этим просим Вас самостоятельно исполнить эту
        обязанность.
        {"\n\n"}В ЛК на Карме в Истории операций вы увидите транзакцию по разблокировке НДФЛ за 2020 год.
        {"\n\n"}Для уплаты налога вам необходимо:{"\n\n"}
        <Bold>1. Cкачать “Отчет по доходам и удержанному НДФЛ” за период с 01.01.2020 по 31.12.2020.</Bold>
        {"\n\n"}
        Сложите Общую сумму дохода и рассчитайте НДФЛ 13%, если вы налоговый резидент РФ или 30%, если не являетесь
        налоговым резидентом РФ. Эту сумму необходимо заплатить в вашу налоговую.{"\n\n"}
        <Bold>2. Как найти налоговую и заплатить?</Bold>
        {"\n\n"}
        На сайте <Link href="https://nalog.ru">nalog.ru</Link>, в разделе Физические лица - Уплата налогов, сборов
        страховых взносов физических лиц - Уплата за себя - Уплата имущественных налогов и НДФЛ единым налоговым
        платежом {"\n\n"}
        <Link href="https://service.nalog.ru/payment/payment.html?payer=fl#singleTaxPayment">
          https://service.nalog.ru/payment/payment.html?payer=fl#singleTaxPayment
        </Link>
        {"\n\n"}
        Вводите ФИО, ИНН и сумму платежа (НДФЛ). Далее оплата по инструкции.
        {"\n\n"}
        Все 🎉
        {"\n\n"}
      </Text>
      <TaxPaymentDocuments profileId={props.currentProfile.id}/>
    </Container>
  )
}

export default Render
