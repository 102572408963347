import React from "react"

import Info from "./Info"
import Stepper from "./Stepper"

import { Wrapper } from "./styles"

function InvestorWelcomePage(props) {
  return (
    <Wrapper>
      <Info />
      <Stepper {...props} />
    </Wrapper>
  )
}

export default InvestorWelcomePage
