import styled, { createGlobalStyle } from "styled-components"

export const Container = styled.div<any>`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`

export const DisableScroll = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`
