import React from "react"
import styled from "styled-components"

import {
  Box, Text, Modal, Button, Translate, DefaultModal,
} from "src/components"

const ModalContainer = styled.div`
  width: 550px;
  padding: 55px 85px 45px 85px;
  text-align: center;
`

const render = ({ onClose }) => (
  <Modal>
    <DefaultModal
      padding="0"
      type="centered"
      onClose={onClose}
      heightRestriction={false}
      closeButtonVariant="empty"
    >
      <ModalContainer>
        <Box mb="24px">
          <Text fontSize="18px" fontWeight="500" lineHeight="1.5">
            <Translate i18n="modals:express-scoring.pending.content" />
          </Text>
        </Box>
        <Button width="180px" onClick={onClose} variant="blueWide">
          <Translate i18n="modals:express-scoring.pending.button" />
        </Button>
      </ModalContainer>
    </DefaultModal>
  </Modal>
)

export default render
