import React from "react"

import { OrderDataConsumer, ViewerDataConsumer } from "src/context"

export default (props) => (
  <OrderDataConsumer>
    {(order) => (
      <ViewerDataConsumer.renderPropContainer>
        {({ getViewerToOrderRelation, viewer }) => props.children({
          order,
          viewer,
          relation: getViewerToOrderRelation(order),
        })
        }
      </ViewerDataConsumer.renderPropContainer>
    )}
  </OrderDataConsumer>
)
