import React from "react"

import {
  Box,
  Button,
  FlexBox,
  Template,
  Translate,
  RowsContainer,
  FormLoadingButton,
} from "src/components"

import Header from "./elements"

import { Inn, Name } from "./rows"

class LegalEntityAccreditationForm extends React.Component {
  state = {
    isLoading: false,
    birthDate: "",
    name: "",
    inn: "",
    ifns: "",
  }

  onChange = (event) => {
    const { name, value } = event.target
    this.setState(() => ({ [name]: value }))
  }

  renderFormContent = () => (
    <RowsContainer>
      <Name value={this.state.name} onChange={this.onChange} />
      <Inn value={this.state.inn} onChange={this.onChange} />
    </RowsContainer>
  )

  renderFormControls = () => (
    <React.Fragment>
      <FlexBox display="flex" flexDirection="row">
        <Button variant="default">
          <Translate i18n="accreditation.buttons.return" ns="components" />
        </Button>
        <Box width={130} />
        <FormLoadingButton
          // onClick={this.onSubmit}
          isLoading={this.state.isLoading}
          secondary
        >
          <Translate i18n="accreditation.buttons.continue" ns="components" />
        </FormLoadingButton>
      </FlexBox>
      <Button variant="default">
        <Translate i18n="accreditation.buttons.save" ns="components" />
      </Button>
    </React.Fragment>
  )

  render() {
    return (
      <RowsContainer maxWidth="100%">
        <Template
          header={Header}
          content={this.renderFormContent}
          footer={this.renderFormControls}
          layout="card"
        />
      </RowsContainer>
    )
  }
}

export default LegalEntityAccreditationForm
