/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FinancialTransactionTypeEnum = "BORROW" | "CURRENCY_EXCHANGE" | "DEPOSIT" | "FEE" | "FEE_REFUND" | "INCOME_TAX" | "INTEREST_FEE" | "INTEREST_REPAYMENT" | "INVESTMENT" | "INVESTMENT_REFUND" | "INVESTMENT_RETURN" | "KYC" | "LOAN_REPAYMENT" | "METAL_LOAN_REPAYMENT" | "META_BORROW" | "META_FEE" | "META_FEE_RETURN" | "META_INTEREST_REPAYMENT" | "META_INVESTMENT" | "META_INVESTMENT_RETURN" | "META_KYC" | "META_PENALTY" | "META_PENALTY_FEE" | "META_PURCHASE_LOAN" | "META_WITHDRAWAL" | "PENALTY" | "PENALTY_FEE" | "PURCHASE_LOAN" | "REFUND" | "TAX_REFUND" | "TRANSFER" | "UNKNOWN" | "WITHDRAWAL" | "WITHDRAWAL_FEE" | "%future added value";
export type ProfileTransactionsList_list = {
    readonly pageInfo: {
        readonly endCursor: unknown | null;
        readonly hasNextPage: boolean;
    };
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly id: string;
            readonly date: string | null;
            readonly type: FinancialTransactionTypeEnum | null;
            readonly amount: number;
            readonly description: string | null;
            readonly toAccount: {
                readonly id?: string;
                readonly profile?: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly foreignProfile?: {
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                } | null;
                readonly order?: {
                    readonly id: string;
                    readonly application: {
                        readonly shortTitle: string;
                        readonly longTitle: string;
                    } | null;
                    readonly chain: {
                        readonly id: string;
                    } | null;
                };
            } | null;
            readonly fromAccount: {
                readonly id?: string;
                readonly profile?: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly foreignProfile?: {
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                } | null;
                readonly order?: {
                    readonly id: string;
                    readonly application: {
                        readonly shortTitle: string;
                        readonly longTitle: string;
                    } | null;
                    readonly chain: {
                        readonly id: string;
                    } | null;
                };
            } | null;
        };
    }>;
};
export type ProfileTransactionsList_list$data = ProfileTransactionsList_list;
export type ProfileTransactionsList_list$key = {
    readonly " $data"?: ProfileTransactionsList_list$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfileTransactionsList_list">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ForeignIndividualProfile",
        "kind": "LinkedField",
        "name": "foreignProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "FinancialTransactionProfileAccount"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderApplication",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longTitle",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderChainInfo",
            "kind": "LinkedField",
            "name": "chain",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "FinancialTransactionSharedWalletAccount"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "ProfileTransactionsList_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "toAccount",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "fromAccount",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TransactionConnection"
};
})();
(node as any).hash = '6279cf8abddc64fe4acd3836f7da3689';
export default node;
