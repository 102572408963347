import React from "react"
import ReactDOM from "react-dom"

import { Container, DisableScroll } from "./styles"

function ModalContainer(props) {
  const { onClose, children } = props
  const overlayRef = React.createRef()

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      onClose()
    }
  }

  return (
    <Container ref={overlayRef} onClick={onOverlayClick}>
      <DisableScroll />
      {children}
    </Container>
  )
}

export default function Render(props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")
  return isOpened ? ReactDOM.createPortal(ModalContainer(props), root) : null
}
