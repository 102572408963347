import React from "react"

import {
  PaymentCard as Container,
  PaymentCardHeader,
  PaymentCardHeaderLeft,
  PaymentCardHeaderRight,
  PaymentCardData,
  PaymentCardDataLeft,
  PaymentCardDataRight,
  PaymentCardBody,
} from "./styles"

import {
  divideNumber,
  getPaymentDate,
} from "../../../../organisms/PortfolioTable/Tables/utils"
import statuses, { investmentTypes, investmentAmountColors } from "../../statuses"
import { isInvestment } from "../../utils"

function PaymentCard(props) {
  const status = statuses.get(props.state)

  return (
    <Container>
      <PaymentCardHeader>
        <PaymentCardHeaderLeft>
          {getPaymentDate(props.date)}
        </PaymentCardHeaderLeft>
        <PaymentCardHeaderRight>{`${divideNumber(
          props.total_gross.toFixed(2),
        )} ₽`}</PaymentCardHeaderRight>
      </PaymentCardHeader>

      <PaymentCardBody>
        <PaymentCardData>
          <PaymentCardDataLeft>Дата оплаты</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {getPaymentDate(props.date, props.payDate)}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Сумма платежа</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.total_gross.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Основной долг</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.loan.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Проценты</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.interest.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>НДФЛ</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.personal_tax.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>К выплате</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.total.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Остаток долга</PaymentCardDataLeft>
          <PaymentCardDataRight>
            {divideNumber(props.debt.toFixed(2))}
          </PaymentCardDataRight>
        </PaymentCardData>

        <PaymentCardData>
          <PaymentCardDataLeft>Статус</PaymentCardDataLeft>
          <PaymentCardDataRight color={status.color}>
            {status.text}
          </PaymentCardDataRight>
        </PaymentCardData>
      </PaymentCardBody>
    </Container>
  )
}

const InvestmentCard = (props) => (
  <Container>
    <PaymentCardHeader>
      <PaymentCardHeaderLeft>
        {getPaymentDate(props.date)}
      </PaymentCardHeaderLeft>

      <PaymentCardHeaderRight color={investmentAmountColors[props.type]}>
        {props.type === "SELL" ? "-" : "+"}
        {divideNumber(props.amount.toFixed(2))} ₽
      </PaymentCardHeaderRight>
    </PaymentCardHeader>

    <PaymentCardBody>
      <PaymentCardData>
        <PaymentCardDataLeft>Тип</PaymentCardDataLeft>
        <PaymentCardDataRight>
          {investmentTypes[props.type]}
        </PaymentCardDataRight>
      </PaymentCardData>
    </PaymentCardBody>

    <PaymentCardBody>
      <PaymentCardData>
        <PaymentCardDataLeft>Статус</PaymentCardDataLeft>
        <PaymentCardDataRight color="#6FA84B">Исполнено</PaymentCardDataRight>
      </PaymentCardData>
    </PaymentCardBody>
  </Container>
)

const Card = (props) => {
  if (isInvestment(props)) {
    return <InvestmentCard {...props} />
  }

  return <PaymentCard {...props} />
}

export default Card
