import React from "react"

import { Box, Stepper } from "src/components"

import { Container, ContentContainer } from "./styles"

export default ({
  title, activeStep, steps, controls,
}) => {
  const labels = steps.map(({ label }) => label)
  const { content, extra } = steps[activeStep]

  return (
    <Container>
      {title && <Box pb={30}>{title()}</Box>}
      <Stepper activeStep={activeStep} labels={labels} />
      {content && (
        <ContentContainer>
          {content()}
          {extra && extra()}
        </ContentContainer>
      )}
      {controls && controls()}
    </Container>
  )
}
