import React from "react"

import { Translate } from "src/components"

import AttachmentField from "../AttachmentField"
import TextField from "../TextField"

import {
  Container, Title, TextContainer, Moderate,
} from "./styles"

function Group(props) {
  const { item } = props
  const { title, fields, status } = item

  const pending = "components:account.accreditation_info.alerts.pending"

  return (
    <Container>
      <Title>
        <Translate i18n={title} />
      </Title>

      {status === "PENDING" && (
        <Moderate>
          <Translate i18n={pending} />
        </Moderate>
      )}

      <TextContainer>
        {fields.map((field, idx) => (
          <div key={idx}>
            {field.type === "text" && <TextField data={field} />}
            {field.type === "attachment" && <AttachmentField data={field} />}
          </div>
        ))}
      </TextContainer>
    </Container>
  )
}

export default Group
