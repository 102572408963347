import React, { useEffect } from "react"
import Relay, { graphql } from "react-relay"
import { Redirect } from "react-router"

import { Forms } from "src/components"
import { AccreditationInfoQueryContainer } from "src/query"
import { ProfileType } from "src/constants"
import { isJuristicBorrower } from "src/utils"

import { Container, Box, ReturnButton } from "./styles"

const AccreditationBorrower = AccreditationInfoQueryContainer(({ node }) => {
  if (!node) return null

  const { __typename: type } = node

  return (
    <>
      {node && type === ProfileType.juristic && (
        <Forms.legalEntityProfile.accreditation.borrower profile={node} />
      )}
    </>
  )
})

function AccreditationInvestor(props) {
  const { profileId } = props

  return (
    <Forms.individualProfile.accreditation.investor profileId={profileId} />
  )
}

function AccreditationProfile(props) {
  const { profile, handleReturn } = props
  const { id: userId, accreditation } = profile

  useEffect(() => {
    if (accreditation.status === "PENDING") {
      handleReturn()
    }
  }, [])

  if (!userId) {
    return <Redirect to="/accounts" />
  }

  return (
    <Container>
      <ReturnButton onClick={handleReturn}>{"< Назад"}</ReturnButton>
      <Box>
        {isJuristicBorrower(profile) && (
          <AccreditationBorrower profileId={userId} />
        )}

        {!isJuristicBorrower(profile) && (
          <AccreditationInvestor profileId={userId} />
        )}
      </Box>
    </Container>
  )
}

export default Relay.createFragmentContainer(AccreditationProfile, {
  profile: graphql`
    fragment AccreditationProfile_profile on UserProfile {
      id
      __typename
      ... on IndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on ForeignIndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on EntrepreneurProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on LegalEntityProfile {
        accreditation(role: BORROWER) {
          status
        }
      }
    }
  `,
})
