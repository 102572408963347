import React from "react"

const valueFromEventTarget = (target) => {
  if (target.type === "checkbox") return target.checked
  return target.value
}

const useAttributes = (initialAttributes) => {
  const [attributes, setAttributes] = React.useState(initialAttributes)

  const attributesRef = React.useRef(attributes)

  React.useEffect(() => {
    attributesRef.current = attributes
  }, [attributes])

  const setAttributeFromEvent = React.useCallback((eventOrFn) => {
    if (typeof eventOrFn === "function") {
      setAttributes(eventOrFn)
      return
    }

    const { name } = eventOrFn.target
    const value = valueFromEventTarget(eventOrFn.target)

    setAttributes((attrs) => ({ ...attrs, [name]: value }))
  }, [])

  return {
    set: setAttributes,
    ref: attributesRef,
    value: attributes,
    onChange: setAttributeFromEvent,
  }
}

export default useAttributes
