import React from "react"
import Dropzone from "react-dropzone"

import { Loader, Translate } from "src/components"
import { UploadAvatarInput } from "src/mutations"

import {
  RemoveIcon,
  CameraIcon,
  ImgPreview,
  ImgContainer,
  UploaderLabel,
  LoaderContainer,
  ContentContainer,
  DropZoneContainer,
  RemoveButtonWrapper,
  DropZoneActiveStyles,
} from "./styles"

const RemoveButton = ({ onClick }) => (
  <RemoveButtonWrapper onClick={onClick}>
    <RemoveIcon />
  </RemoveButtonWrapper>
)

export default class ImgUploader extends React.Component<any, any> {
  state = {
    url: this.props.url,
    busy: false,
    files: null,
    dragOver: false,
    isLoading: false,
  }

  onDrop = (files) => {
    const avatar = files[0]
    if (this.state.busy) {
      return
    }
    this.setState(() => ({
      busy: true,
      isLoading: true,
    }))
    this.commit(avatar)
  }

  onDragOver = () => {
    this.setState(() => ({ dragOver: true }))
  }

  onDragLeave = () => {
    this.setState(() => ({ dragOver: false }))
  }

  onError = () => {
    this.setState(() => ({
      busy: false,
      isLoading: false,
    }))

    if (this.props.setErrors) {
      this.props.setErrors()
    }
  }

  onDelete = (event) => {
    if (event) {
      event.preventDefault()
    }

    this.setState(() => ({ url: "" }))
    this.props.onAvatarDelete()
  }

  onCompleted = (args) => {
    const {
      uploadAvatar: { signedAvatar, url },
    } = args

    this.setState(() => ({
      url,
      signedAvatar,
      isLoading: false,
      busy: false,
    }))
    this.props.onAvatarUpdate(signedAvatar)
  }

  commit = (avatar) => {
    const variables = {
      input: {
        field: "avatar",
      },
    }

    const uploadables = {
      avatar,
    }

    const callbacks = {
      onError: this.onError,
      onCompleted: this.onCompleted,
    }

    UploadAvatarInput.commit(
      this.props.environment,
      variables,
      uploadables,
      callbacks,
    )
  }

  dropZoneConfig = {
    accept: "image/jpeg,image/png",
    multiple: false,
    onDrop: this.onDrop,
    onDragOver: this.onDragOver,
    onDragLeave: this.onDragLeave,
    style: DropZoneActiveStyles,
  }

  render() {
    return !this.state.url ? (
      <DropZoneContainer>
        {this.state.isLoading ? (
          <LoaderContainer>
            <Loader fontSize="14px" />
          </LoaderContainer>
        ) : (
          <Dropzone {...this.dropZoneConfig as any}>
            <ContentContainer dragOver={this.state.dragOver}>
              <CameraIcon />
              <UploaderLabel>
                <Translate
                  i18n="individual_account.update.photo_upload"
                  ns="components"
                />
              </UploaderLabel>
            </ContentContainer>
          </Dropzone>
        )}
      </DropZoneContainer>
    ) : (
      <DropZoneContainer>
        <ImgContainer>
          <ImgPreview url={this.state.url} />
        </ImgContainer>
        <RemoveButton onClick={this.onDelete} />
      </DropZoneContainer>
    )
  }
}
