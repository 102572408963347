import styled from "styled-components"

export const InputContainer = styled.div`
  position: relative;
`

export const IconContainer = styled.div<any>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  position: absolute;
  top: 8px;
  right: 40px;
  width: 24px;
  height: 24px;

  svg path {
    fill: ${({ disabled, theme }) => (disabled ? theme.colors.grey : theme.colors.black)};
  }
`
