import React from "react"

import { Translate, Flex } from "src/components"

import { IconLinkWrapper, IconLinkListTitle, IconWrap } from "./styles"

const Icon = (props) => {
  const IconContent = props.item
  return (
    <IconWrap href={props.to} target="_blank">
      <IconContent color={props.color} size={props.size} />
    </IconWrap>
  )
}

const IconLinkList = (props) => {
  const data = props.data || []
  return (
    <IconLinkWrapper>
      <IconLinkListTitle color={props.color}>
        <Translate
          i18n={`components:user-page-header.icon-link.${props.title}.title`}
        />
      </IconLinkListTitle>
      <Flex justifyContent="space-between" alignItems="center" display="flex">
        {data.map((item, i) => (
          <Icon
            key={`${props.title}-${i}`}
            to={item.link}
            item={item.icon}
            color={props.color}
            size={props.size}
          />
        ))}
      </Flex>
    </IconLinkWrapper>
  )
}

export default IconLinkList
