import React from "react"

import { Box, Heading, Translate } from "src/components"

import { List, StyledHeading } from "../Shared"

export default () => (
  <React.Fragment>
    <Box pb="12px">
      <StyledHeading>
        <Translate i18n="components:international_profile_form.infoMessageContent.selfie.0" />
      </StyledHeading>
    </Box>
    <Box pt="16px">
      <Heading.h6>
        <Translate i18n="components:international_profile_form.infoMessageContent.selfie.1" />
      </Heading.h6>
    </Box>
    <List>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.selfie.2" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.selfie.3" />
      </li>
    </List>
  </React.Fragment>
)
