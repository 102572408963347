import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import { CurrentProfileContext } from "src/context"

import {
  BalanceBlock,
  InvestmentsBlock,
  ProfitBlock,
  RiskBlock,
} from "./Blocks"
import { Container, BlockContainer } from "./styles"

function PortfolioAnalytics() {
  const history = useHistory()

  const { profile } = useContext(CurrentProfileContext)
  if (!profile) {
    return <></>
  }

  return (
    <Container>
      <BlockContainer>
        <BalanceBlock profileId={profile.id} />
        <ProfitBlock profileId={profile.id} />
      </BlockContainer>

      <BlockContainer>
        <InvestmentsBlock profileId={profile.id} history={history} />
        <RiskBlock profileId={profile.id} history={history} />
      </BlockContainer>
    </Container>
  )
}

export default PortfolioAnalytics
