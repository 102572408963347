/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type KarmaPaymentRequisitesQueryVariables = {};
export type KarmaPaymentRequisitesQueryResponse = {
    readonly localizedStrings: {
        readonly karmaPaymentRequisites: ReadonlyArray<{
            readonly key: string;
            readonly name: string;
            readonly value: string;
        }>;
    };
};
export type KarmaPaymentRequisitesQuery = {
    readonly response: KarmaPaymentRequisitesQueryResponse;
    readonly variables: KarmaPaymentRequisitesQueryVariables;
};



/*
query KarmaPaymentRequisitesQuery {
  localizedStrings {
    karmaPaymentRequisites {
      key
      name
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LocalizedStrings",
    "kind": "LinkedField",
    "name": "localizedStrings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalizedString",
        "kind": "LinkedField",
        "name": "karmaPaymentRequisites",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "KarmaPaymentRequisitesQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "KarmaPaymentRequisitesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "KarmaPaymentRequisitesQuery",
    "operationKind": "query",
    "text": "query KarmaPaymentRequisitesQuery {\n  localizedStrings {\n    karmaPaymentRequisites {\n      key\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b66ef2c14845daed6c754b2e364de9f1';
export default node;
