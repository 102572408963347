import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const Wrapper = styled.div`
  display: inline-block;
`

export const MobileWrapper = (props) => {
  const mobile = useMediaPredicate("(max-width: 480px)")

  return mobile && <Wrapper {...props}/>
}
