import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ProposalInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Order {
        beganAt
        expertOpinions {
          id
          opinion
          expert {
            id
            name
            about
            experience
          }
        }
        viewer {
          hasIntent
          investedAmount
          investments {
            id
            history {
              type
              date
              amount
            }
            number
            confirmations {
              investedAt
              document {
                id
                url
                signature {
                  url
                }
              }
            }
          }
        }
        tags {
          name
        }
        backgroundImage {
          url
        }
        succeededAt
        createdAt
        status
        externalUrl
        fee
        profile {
          ... on UserProfile {
            id
            __typename
            ... on LegalEntityProfile {
              name
              inn
              ogrn
              executive {
                name
                photo
                externalLink
              }
              borrower {
                publicEmail
                ticker
              }
            }
            ... on EntrepreneurProfile {
              name
              inn
              ogrn
              borrower {
                publicEmail
                ticker
              }
            }
            creditRating {
              rating
            }
            _avatar {
              url
            }
          }
        }
        offer {
          ... on OrderOffer {
            id
            data
            declineReason
            receivedAt
          }
        }
        application {
          shortTitle
          longTitle
          description
          attachments {
            id
            url
            size
            width
            height
            filename
            access
            format
            signature {
              url
            }
          }
          graphs {
            title
            rows {
              x
              y
            }
          }
          infoBlocks {
            title
            content
          }
          founders {
            share
            person {
              name
              photo
              externalLink
            }
          }
          externalMedium {
            video {
              url
            }
          }
          ... on OrderApplication {
            id
            data
            declineReason
            receivedAt
          }
        }
        confirmedAt
        creditRating {
          rating
        }
        expertOpinions {
          id
          opinion
        }
        otherDocuments {
          attachment {
            id
            url
            filename
            signature {
              url
            }
          }
          description
        }
        financialStatements {
          attachment {
            id
            filename
            url
            signature {
              url
            }
          }
          name
          year
        }
        cession {
          due
          amount
          isActive
          interestRate
          borrowerName
          originalChainId
          avatar {
            url
          }
          borrowerRating {
            rating
            ratedAt
          }
          originalOrder {
            succeededAt
            paymentSchedule {
              borrower {
                items {
                  date
                }
              }
            }
            paymentScheduleList {
              profile {
                id
              }
              items {
                date
              }
            }
            beganAt
            guarantors {
              attachment {
                url
                filename
                id
                signature {
                  url
                }
              }
              name
              description
            }
            financialStatements {
              attachment {
                id
                filename
                url
                signature {
                  url
                }
              }
              name
              year
            }
            otherDocuments {
              attachment {
                id
                url
                filename
                signature {
                  url
                }
              }
              description
            }
            application {
              data
            }
            offer {
              data
            }
            creditRating {
              rating
            }
            profile {
              id
              creditRating {
                rating
              }
              ... on LegalEntityProfile {
                name
                inn
                ogrn
                borrower {
                  ticker
                }
                executive {
                  name
                  photo
                  externalLink
                }
              }
            }
          }
        }
        skipOffer
        karma {
          isInvestor
          isTrusted
        }
        chain {
          id
          gatheredAmount
          investorsCount
        }
        guarantors {
          attachment {
            url
            filename
            id
            signature {
              url
            }
          }
          name
          description
        }
        status
        provision {
          items {
            id
            kind
            name
            images {
              id
              url
              filename
              access
              signature {
                url
              }
            }
            videos {
              id
              url
              title
              description
              video {
                url
              }
            }
            documents {
              id
              url
              filename
              access
              signature {
                url
              }
            }
            description
          }
        }
        paymentScheduleList {
          profile {
            id
          }
          items {
            date
            payDate
            loan
            state
            total
            total_gross
            interest
            interest_fee
            personal_tax
          }
          info {
            state
            pastdue_days
            penalty
          }
        }
      }
    }
  }
`

const Container = (render, loader?: any) => ({ orderId, ...rest }) => {
  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      renderNull={loader || null}
      variables={variables}
    />
  )
}

Container.query = query

export default Container
