import React from "react"

import { CheckboxWrapper, Checkbox, Label } from "./styles"

export default function CheckboxContainer(props) {
  const { checked, onChange } = props

  return (
    <CheckboxWrapper>
      <Label isChecked={checked}>
        <Checkbox type="checkbox" checked={checked} onChange={onChange} />
      </Label>
    </CheckboxWrapper>
  )
}
