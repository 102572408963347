import React from "react"
import idx from "idx"

import { Translate } from "src/components"

const cropDescription = ({ CROP_SIZE, description }) => {
  if (description.length <= CROP_SIZE) {
    return description
  }

  const cropped = description.substring(0, CROP_SIZE)
  const POSTFIX = "..."

  return cropped.concat(POSTFIX)
}

const isEmptyDescriptionFrom = (data) => !idx(data, (_) => _.application.data.description)

const parseDescriptionWith = ({ CROP_SIZE, description, cropped }) =>
  (cropped ? cropDescription({ CROP_SIZE, description }) : description)

const getDescriptionFrom = ({ data, cropped }) => {
  if (isEmptyDescriptionFrom(data)) {
    return <Translate i18n="components:account.order_item.description.empty" />
  }

  const CROP_SIZE = 250
  const { description } = data.application.data

  return parseDescriptionWith({ CROP_SIZE, description, cropped })
}

export default getDescriptionFrom
