/*
 * form: {
 *   groups: [{
 *     title?: string,
 *     subtitle?: string,
 *     rows: [{
 *       deps? [{
 *         propName: string,
 *         compared: string | object,
 *         conditionValue: any
 *       }],
 *       label?: string,
 *       subLabel?: string,
 *       description?: string,
 *       input: {
 *         type: InputType,
 *         name: string,
 *         initialValue?: any,
 *         mask?: string,
 *         hint?: string,
 *         width?: InputWidth,
 *         deps?: [{
 *           propName: string,
 *           compared: string | object,
 *           conditionValue: any
 *         }],
 *         inject?: {
 *           [name]: value
 *         },
 *         options? : [{
 *           label: string,
 *           value: string
 *         }],
 *         decorator: Component,
 *         rightLabel?: text,
 *         leftLabel?: text
 *       }
 *     }]
 *   }]
 * }
 *
 * enum InputType = {
 *    text: "text",
 *    password: "password",
 *    number: "number",
 *    address: "address",
 *    switch: "switch",
 *    checkbox: "checkbox",
 *    date: "date",
 *    select: "select",
 *    fileDownload: "fileDownload",
 *    fileUpload: "fileUpload",
 *    attachments: "attachments"
 * }
 *
 * enum InputWidth = {
 *   one: 1,
 *   two: 2,
 *   three: 3,
 *   four: 4
 * }
 * */
import React from "react"

import { Row, Input, Group } from "./Blocks"

import { InputsDataProvider } from "./context"

const validArray = (arr) => Array.isArray(arr)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderInputs = (row: any, environment: any, onChange: any) => {
  if (!Array.isArray(row.inputs)) {
    return null
  }

  return row.inputs.map((input, idx) => (
    <Input
      {...input}
      environment={environment}
      length={row.inputs.length}
      key={idx + JSON.stringify(input)}
    />
  ))
}

const FormBuilder = (props) => {
  const {
    schema,
    environment,
    inputsSchemaType,
    value: injectedValue,
    onChange: injectedOnChange,
  } = props

  return (
    <InputsDataProvider
      value={{
        value: injectedValue,
        type: inputsSchemaType,
        onChange: injectedOnChange,
      }}
    >
      {validArray(schema.groups)
        && schema.groups.map((group, groupIdx) => (
          <Group {...group} key={groupIdx + JSON.stringify(group)}>
            {validArray(group.rows)
              && group.rows.map((row, rowIdx) => (
                <Row
                  key={rowIdx + JSON.stringify(row)}
                  value={injectedValue}
                  {...row}
                >
                  {renderInputs(row, environment, injectedOnChange)}
                </Row>
              ))}
          </Group>
        ))}
    </InputsDataProvider>
  )
}

FormBuilder.defaultProps = {
  inputsSchemaType: "old",
}

export default FormBuilder
