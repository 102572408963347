import React from "react"

import { MutationContainer } from "src/containers"
import { ConfirmBankAccount, RequestProfilePhoneConfirmation } from "src/mutations"
import {
  Box,
  Flex,
  Text,
  Button,
  CountDown,
  Translate,
  DefaultModal,
  FormLoadingButton,
  ResendRequestButton,
  FloatingLabelInput as FormInput,
} from "src/components"

import { ContentWrapper } from "../styles"
import { ConfirmationType, ConfirmationMainType } from "../types"

class Confirmation extends React.PureComponent<ConfirmationType> {
  state = {
    code: "",
    busy: false,
    codeSent: false,
  }

  componentDidMount() {
    this.onCodeRequest()
    this.props.startTimer()
  }

  onCodeRequest = () => {
    const variables = {
      input: {
        kind: "CONFIRM_BANK_ACCOUNT_WITH_PHONE",
        profileId: this.props.profileId,
      },
    }

    const callbacks = {
      onError: this.onCodeRequestError,
      onCompleted: this.onCodeRequestSuccess,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )

    this.setState({ busy: true })
  }

  onCodeRequestSuccess = () => {
    this.setState({ codeSent: true, busy: false })
  }

  onCodeRequestError = () => {
    this.props.clearTimer()
    this.setState({ busy: false })
  }

  onCodeResend = () => {
    this.onCodeRequest()
    this.props.restartTimer()
  }

  onCodeConfirm = () => {
    this.setState({ busy: true })

    const variables = {
      input: {
        token: this.state.code,
        accountId: this.props.accountId,
      },
    }

    const callbacks = {
      onError: this.onCodeConfirmError,
      onCompleted: this.onCodeConfirmSuccess,
    }

    this.props.commit({ callbacks, variables })
  }

  onCodeConfirmSuccess = () => {
    this.props.onCompleted()
  }

  onCodeConfirmError = () => {
    this.setState({ busy: false })
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ code: e.target.value })
    this.props.clearError("confirmBankAccountWithPhone.token")
  }

  render() {
    const { code, busy, codeSent } = this.state

    const { onClose, getError, secondsLeft } = this.props

    const error = getError("confirmBankAccountWithPhone.token")

    return (
      <>
        <DefaultModal
          padding="40px 35px"
          onClose={onClose}
          heightRestriction={false}
          closeButtonVariant="empty"
        >
          <Box width="300px">
            <Text fontSize="1.375rem" fontWeight="600" color="#5F5F5F">
              <Translate
                ns="components"
                i18n="account.bank_account.confirm.title"
              />
            </Text>
            <ContentWrapper>
              <Box mb="15px" mt="25px">
                <Text fontSize="0.875rem" fontWeight="600">
                  <Translate
                    ns="components"
                    i18n="account.bank_account.confirm.info"
                  />
                </Text>
              </Box>
              <FormInput
                autoFocus
                name="phone_confirmation"
                value={code}
                error={error}
                onChange={this.onChange}
                label="components:account.bank_account.confirm.label"
              />
              <ResendRequestButton nowrap secondsLeft={secondsLeft} />
              <Flex mt="35px" justifyContent="flex-end">
                <Box mr="12px">
                  <Button
                    variant="outlinedBlack"
                    height="45px"
                    width="165px"
                    fontSize="0.75rem"
                    disabled={!!secondsLeft || !codeSent}
                    onClick={this.onCodeResend}
                  >
                    <Translate
                      ns="components"
                      i18n="account.bank_account.confirm.buttons.resend"
                    />
                  </Button>
                </Box>
                <FormLoadingButton
                  variant="outlinedRed"
                  height="45px"
                  width="123px"
                  fontSize="0.75rem"
                  disabled={!code}
                  isLoading={busy}
                  onClick={this.onCodeConfirm}
                >
                  <Translate
                    ns="components"
                    i18n="account.bank_account.confirm.buttons.continue"
                  />
                </FormLoadingButton>
              </Flex>
            </ContentWrapper>
          </Box>
        </DefaultModal>
      </>
    )
  }
}

export default (props: ConfirmationMainType) => (
  <CountDown>
    {({
      start, clear, restart, secondsLeft,
    }) => (
      <MutationContainer mutation={ConfirmBankAccount}>
        {({ commit, getError, clearError }) => (
          <Confirmation
            {...props}
            commit={commit}
            getError={getError}
            clearError={clearError}
            startTimer={start}
            clearTimer={clear}
            restartTimer={restart}
            secondsLeft={secondsLeft}
          />
        )}
      </MutationContainer>
    )}
  </CountDown>
)
