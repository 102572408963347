import React from "react"
import styled from "styled-components"

import {
  color as styledColor,
  fontSize as styledFontSize,
  lineHeight as styledLineHeight,
  fontWeight as styledFontWeight,
} from "styled-system"

import i18n from "src/i18n"
import { Box, CheckBox } from "src/components"

import Label from "./styles"

const CheckBoxLabel = styled.span`
  ${styledColor};
  ${styledFontSize};
  ${styledFontWeight};
  ${styledLineHeight};
  font-family: Geometria, sans-serif;
  white-space: pre-line;
`

const renderLabel = ({ label, ...rest }) => {
  if (!label) return null

  return (
    <CheckBoxLabel {...rest}>
      {typeof label === "string" ? i18n.t(label) : label}
    </CheckBoxLabel>
  )
}

const render = (props) => {
  const {
    label,
    color,
    fontSize,
    lineHeight,
    fontWeight,
    alignItems,
    checkBoxMargin,
    disabled,
    ...rest
  } = props

  return (
    <Label alignItems={alignItems} disabled={disabled}>
      <Box m={checkBoxMargin}>
        <CheckBox disabled={disabled} {...rest} />
      </Box>
      {renderLabel({
        label,
        color,
        fontSize,
        lineHeight,
        fontWeight,
      })}
    </Label>
  )
}

render.defaultProps = {
  alignItems: "center",
}

export default render
