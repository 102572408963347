import React from "react"

import { BankAccountStatuses } from "src/constants"
import {
  Box, Flex, Text, Button, Translate,
} from "src/components"

import { WrappedText } from "../styles"

const isApproved = (status) => status === BankAccountStatuses.approved

const BankDataRow = ({ name, value }) => (
  <Flex alignItems="center" justifyContent="space" mb="15px">
    <Box minWidth={["63%", "63%", "37%"]}>
      <Text
        fontSize={["0.8125rem", "0.875rem", "1rem"]}
        color="primaryBlack"
        textTransform="uppercase"
        width="250px"
      >
        <Translate i18n={name} />
      </Text>
    </Box>
    <WrappedText
      fontSize={["0.8125rem", "0.875rem", "1rem"]}
      color="primaryBlack"
      textTransform="uppercase"
      width="40%"
      overflowWrap="break-word"
    >
      {value}
    </WrappedText>
  </Flex>
)

const render = ({ account, onEdit, onConfirm }) => (
  <Box mt="12px">
    <BankDataRow
      value={account.name}
      name="components:account.bank_account.info.account_info.name"
    />
    <BankDataRow
      value={account.bic}
      name="components:account.bank_account.info.account_info.bic"
    />
    <BankDataRow
      value={account.checkingAccount}
      name="components:account.bank_account.info.account_info.checking_account"
    />
    <BankDataRow
      value={account.correspondentAccount}
      name="components:account.bank_account.info.account_info.correspondent_account"
    />
    <Flex mt="40px">
      {!isApproved(account.status) && (
        <Box mr="20px">
          <Button
            height="35px"
            width="175px"
            variant="outlinedRed"
            onClick={onConfirm}
            fontSize="0.625rem"
          >
            <Translate i18n="components:account.bank_account.edit.buttons.confirm" />
          </Button>
        </Box>
      )}
      <Button
        height="35px"
        width="175px"
        variant="outlinedBlack"
        onClick={onEdit}
        fontSize="0.625rem"
      >
        <Translate i18n="components:account.bank_account.edit.buttons.edit" />
      </Button>
    </Flex>
  </Box>
)

export default React.memo(render)
