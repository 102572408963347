import React from "react"

import { Box, Text, Translate } from "src/components"

import { Subtitle } from "../styles"

const ItemDescription = (props) => {
  const {
    kind,
    name,
    value,
    description,
    overallValue,
    overallCoverage,
  } = props

  const itemCoverage = ((overallCoverage * value) / overallValue).toFixed()
  const shouldRenderCoverage = kind !== "GUARANTOR"
  const coverageType = Number(itemCoverage) >= 100 ? "full_item_coverage" : "item_coverage"

  return (
    <Box mb="25px">
      <Subtitle>
        <Translate i18n="components:account.order_item.provision.description" />
      </Subtitle>
      <Text color="greyDark" lineHeight="1.75">
        {description}
      </Text>
      {shouldRenderCoverage && (
        <Text color="green" lineHeight="1.5">
          <Translate
            name={name}
            coverage={itemCoverage}
            i18n={`components:account.order_item.provision.${coverageType}`}
          />
        </Text>
      )}
    </Box>
  )
}

export default ItemDescription
