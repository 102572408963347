import styled from "styled-components"

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.blacks[5]};
`

export const ModalBody = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDarkest};
  padding: 68px 80px 24px;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 7px;
  }
`

export const Controls = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const LinkDocument = styled.a`
  color: #c12a2a;
  &:visited {
    color: #c12a2a;
  }
`
