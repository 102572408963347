import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"

import { Icons } from "src/components"

export * from "./wrappers"

const Flex = styled.div`
  position: relative;
  display: flex;
`
export const Inner = styled(Flex)<any>`
  position: absolute;
  background: white;
  padding: 20px 12px;
  border-radius: 5px;
  flex-direction: column;

  ${({ active }) => active
    && css`
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      width: 200px;
      cursor: default;
    `};
`

export const Header = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`

export const ProfileContainer = styled(Flex)`
  flex-direction: column;
  margin-top: -10px;
  max-width: calc(100% - 30px);
`

export const ProfileTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
`

export const ProfileEmail = styled(ProfileTitle)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Divider = styled.div`
  width: 100%;
  padding-top: 1px;
  background-color: ${({ theme }) => theme.colors.primaryBlacks[1]};
  margin: 12px 0 20px;
`

export const Menu = styled(Flex)`
  flex-direction: column;
  margin-bottom: 8px;
`

export const MenuButton = styled.button`
  width: 100%;
  border: none;
  padding: 0;
  display: flex;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.primaryBlack};

  :not(:first-child) {
    margin-top: 16px;
  }
`

export const KeyIcon = styled(Icons.Key)`
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 12px;
  height: 12px;
  margin-right: 8px;
`

export const MailIcon = styled(Icons.Mail)`
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 12px;
  height: 12px;
  margin-right: 8px;
`

export const ExitIcon = styled(Icons.Exit)`
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 11px;
  height: 11px;
  margin-right: 9px;
`

export const ProfileIcon = styled(Icons.Profile)`
  fill: ${({ theme }) => theme.colors.primaryBlack};
  width: 20px;
  height: 20px;
`

export const AccountsContainer = styled(Menu)`
  margin: 0;
`

export const TrustedAccountLink = styled(NavLink)`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;

  :hover {
    text-decoration: underline;
  }
`
