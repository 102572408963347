import React from "react"

import { ExitIcon } from "../../../ProposalPopup/icons"

import {
  Window, Closer, Text, Button,
} from "./styles"

function PopUp(props) {
  const { isVisible, onClose } = props

  return (
    <>
      {isVisible && (
        <Window>
          <Closer onClick={onClose}>
            <ExitIcon />
          </Closer>
          <Text>
            Для того чтобы просматвивать документы, вам необходимо пройти
            аккредитацию.
          </Text>
          <Button>ПРОЙТИ АККРЕДИТАЦИЮ</Button>
        </Window>
      )}
    </>
  )
}

export default PopUp
