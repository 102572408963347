export function getCessionRedemptionDate(data: any = {}) {
  // eslint-disable-next-line
  const { cession } = data
  if (!cession) return undefined

  const { originalOrder } = cession

  if (!originalOrder) return undefined

  const {
    paymentSchedule: { borrower: orderBorrower },
  } = originalOrder

  if (
    !orderBorrower
    || !orderBorrower[0]
    || !orderBorrower[0].items
    || !orderBorrower[0].items.length
  ) return undefined
  const lastIndex = orderBorrower[0].items.length - 1
  return orderBorrower[0].items[lastIndex].date
}
