import styled from "styled-components"

import { Icons } from "src/components"

export * from "./wrappers"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  @media screen and (max-width: 480px) {
    min-height: 110vh;
    height: auto;
  }
`

export const MobMenuContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: 210px;
  height: auto;
  background: #262626;
  z-index: 999;
  color: #fff;
  font-family: Geometria;
  overflow: scroll;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.35);
  border-radius: 0px 0px 0px 9px;

  & .IconLinkWrapper {
    display: block;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0;
    box-shadow: none;
    left: 0;
    right: 0;
  }
`
export const MobMenuInner = styled.div`
  padding: 26px 26px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 35px;
  
  @media screen and (max-width: 480px) {
    padding: 40px 40px 0;
    height: auto;
    min-height: 680px;
    flex-grow: 1;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  
  @media screen and (max-width: 480px) {
    height: auto;
    min-height: 100%;
  }
`
export const ButtionPrev = styled.button`
  box-sizing: border-box;
  cursor: pointer;
  padding-left: 0;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
  }
`

export const ContentFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  height: 1px;
  margin-bottom: 24px;
`

export const Action = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  margin-top: 8px;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin: 0;
  }
`

export const Account = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const AccountTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.whites[7]};
`

export const AccountEmail = styled(AccountTitle)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: 6px;
  font-size: 14px;
`

export const Menu = styled.div`
  margin-top: 32px;
`

export const FooterLink = styled.button`
  padding: 0;
  border: 0;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  :not(:first-child) {
    margin-top: 12px;

    @media screen and (max-width: 480px) {
      margin-top: 28px;
    }
  }

  svg {
    vertical-align: text-bottom;
  }
  span {
    font-size: 14px;
    margin-left: 5px;
  }
`
export const ProfileName = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  font-weight: 300;
  color: #fff;
  font-family: Geometria, sans-serif;
`
export const ProfileWallet = styled.div`
  font-size: 30px;
  line-height: 38px;
  font-weight: bold;
  font-family: Geometria, sans-serif;
`

export const MailIcon = styled(Icons.Mail)`
  fill: white;
  width: 16px;
  height: 16px;
`
