import styled from "styled-components"

import { OutlinedButton } from "src/components"

import { DownloadIcon } from "../../icons"

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-width: 530px;
  background-color: rgba(255, 116, 143, 0.02);
  border: 1px solid rgba(247, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 9px;
  margin: 0 0 20px;
  padding: 24px;
  font-family: Geometria, sans-serif;

  @media screen and (max-width: 480px) {
    margin: 0;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
`

export const Title = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.mainRed};
  margin: 0;
  align-self: center;
`

export const Description = styled.p`
  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.textBlacks[7]};
  margin: 12px 0 0;
`

export const Link = styled.span`
  text-decoration: underline;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textBlacks[9]};
  margin: 0 0 0 4px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-weight: bold;
  }
`

export const Image = styled.img`
  margin: auto;
  height: 51px;
  width: 51px;
`

export const ImageContainer = styled.div`
  box-sizing: content-box;
  padding: 0;
  margin-right: 18px;

  @media screen and (max-width: 480px) {
    margin-right: 8px;
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  margin: 28px 0 0;
`

export const Button = styled.label`
  width: 150px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.colors.oceanBlue};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.oceanBlue};
  text-transform: uppercase;
  padding: 12px 0;
  display: block;
  text-align: center;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

export const SubmitButton = styled(OutlinedButton)`
  width: 150px;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.mainRed};
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  font-size: 12px;
  padding: 13px 0 11px;
  margin-left: auto;
`

export const Icon = styled(DownloadIcon)`
  width: 12px;
  height: 13px;
  padding-bottom: 1px;
  margin-left: 4px;
  fill: ${({ theme }) => theme.colors.oceanBlue};
  vertical-align: sub;
`

export const AttachmentTitle = styled.h6`
  font-family: Geometria, sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin: 20px 0 0;
  color: ${({ theme }) => theme.colors.textBlacks[5]};
`
