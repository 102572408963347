import React from "react"

import { number } from "src/utils"

export const formatNumber = (str: string) => {
  if (!str) return ""

  const formatted = String(str).replace(/\D/g, "")

  return number(formatted, {}, "ru")
}

export const useInvestmentAmount = (balance: number) => {
  const [value, setValue] = React.useState("")
  const onChange = React.useCallback(
    (e) => {
      const formatted = formatNumber(e.target.value)

      setValue(formatted)
    },
    [balance],
  )

  return [value, onChange]
}

export const useOnCompleteCallbacks = (items: any, setActiveModal: (value: string | null) =>
  void, hideModal: () => void) => React.useMemo(
  () => Object.keys(items).reduce((memo, key) => {
    const cb = () => (items[key].next ? setActiveModal(items[key].next) : hideModal())

    return { ...memo, [key]: cb }
  }, {}),
  [items],
)
