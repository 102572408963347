import { createGlobalStyle } from "styled-components"

export const CustomTooltipGlobalStyle = createGlobalStyle`
  .rc-tooltip {
    opacity: 1;
  }
  
  .rc-tooltip-inner {
    box-shadow: none;
    padding: 8px 12px;
    min-height: auto;
    border-radius: 4px;
  }
`
