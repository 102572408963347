import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  position: relative;
  height: auto;
`

export const InputButton = styled.button`
  border: none;
  position: absolute;
  right: 10px;
  top: 16px;
  background-color: white;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`
