import styled from "styled-components"
import {
  height,
  width,
  space,
  variant,
  textAlign,
  fontSize,
} from "styled-system"

const buttonStyle = variant({
  key: "buttons",
})

const Button = styled.button<any>`
  font-family: "Geometria";
  border-radius: 4px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;

  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0 25px"};
  width: ${({ fullWidth }) => (fullWidth === true ? "100%" : "auto")};

  border-style: solid;
  border-width: 1px;
  ${buttonStyle}
  ${height}
  ${width}
  ${space}
  ${textAlign}
  ${fontSize}

  &:disabled {
    opacity: .4;
    cursor: not-allowed;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`

export default Button
