import React from "react"
import { IconBurger } from "./icons"
import { BugrerContainer } from "./styles"

const BugrerMenu = (props) => (
  <BugrerContainer onClick={() => props.handleClick(true)}>
    <IconBurger />
  </BugrerContainer>
)
export default BugrerMenu
