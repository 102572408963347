import styled from "styled-components"

export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.coffee};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`

export const ModalContainer = styled.div`
  padding: 80px 0;
  margin: 0 auto;
`

export const Icon = styled.div`
  background-image: url(/images/research.svg);
  background-size: contain;
  width: 136px;
  height: 136px;
  margin: 0 auto;
`

export const LinkButton = styled.span`
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.greyDarker};

  &:hover {
    text-decoration: underline;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 7px;
  }
`
