import React, { useState } from "react"

import { AttachmentFileList, ModalContainer } from "src/components"

import {
  Container,
  CloseButton,
  MobileCloseButton,
  CloseIcon,
  ArrowIcon,
  Title,
  Description,
} from "../styles"

import {
  AttachmentTitle,
  ButtonContainer,
  UploadButton,
  SubmitButton,
  DownloadIcon,
} from "./styles"

const MAX_FILE_SIZE = 10485760 // 10 Mb

function DocumentsModal(props) {
  const {
    isOpened, onClose, title, text,
  } = props

  const [files, setFiles] = useState([])
  const hasFiles = files.length > 0

  function handleChange(event) {
    const fileList = event.target.files
    const newFiles = [...fileList].filter((file) => file.size <= MAX_FILE_SIZE)

    setFiles([...files, ...newFiles])
  }

  function handleDelete(idx) {
    const newFiles = files.slice()
    newFiles.splice(idx, 1)
    setFiles(newFiles)
  }

  function handleClose() {
    setFiles([])
    onClose()
  }

  return (
    <ModalContainer isOpened={isOpened} onClose={handleClose}>
      <Container>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>

        <MobileCloseButton onClick={handleClose}>
          <ArrowIcon />
          Назад
        </MobileCloseButton>

        <Title>{title}</Title>
        <Description>{text}</Description>

        {hasFiles && (
          <>
            <AttachmentTitle>Загруженные документы</AttachmentTitle>
            <AttachmentFileList files={files} onDelete={handleDelete} />
          </>
        )}

        <ButtonContainer>
          <UploadButton>
            загрузить
            <DownloadIcon />
            <input type="file" multiple onChange={handleChange} />
          </UploadButton>

          {hasFiles && <SubmitButton>отправить</SubmitButton>}
        </ButtonContainer>
      </Container>
    </ModalContainer>
  )
}

export default DocumentsModal
