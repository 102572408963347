import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateForeignBankAccountMutation(
    $input: UpdateForeignBankAccountInput!
  ) {
    updateForeignBankAccount(input: $input) {
      account {
        id
        status
        ownerName
        IBAN
        bank {
          name
          address
          SWIFT
        }
      }
    }
  }
`
export default enhance({ mutation })
