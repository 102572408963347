import React from "react"
import { NavLink } from "react-router-dom"
import styled, { css } from "styled-components"

const className = css``

const EnhancedNavLink = styled(NavLink)`
  align-items: center;
  color: #4a4a4a;
  display: inline-flex;
  height: 100%;
  padding: 0;
  position: relative;
  text-decoration: none;

  &.active {
    font-weight: bold;
  }

  @media screen and (max-width: 899px) {
    order: -1;
  }
`

export default (props) => (
  <EnhancedNavLink
    {...props}
    className={className}
    activeClassName="active"
  />
)
