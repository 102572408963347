import { LOAN_PRODUCT_TYPE } from "../selectValues"

export default {
  groups: [
    {
      title: "legal_entity_offer_form.header",
      rows: [
        {
          label: "legal_entity_offer_form.rows.term.label",
          inputs: [
            {
              type: "number",
              name: "duration",
              hint: "legal_entity_offer_form.rows.term.hint",
              initialValue: 12,
              min: 1,
              max: 120,
            },
          ],
        },
        {
          label: "legal_entity_offer_form.rows.product.label",
          inputs: [
            {
              type: "select",
              name: "productType",
              options: LOAN_PRODUCT_TYPE,
            },
          ],
        },
        {
          label: "legal_entity_offer_form.rows.repayment_schedule.label",
          inputs: [
            {
              type: "repaymentSchedule",
              name: "repaymentSchedule",
              inject: [
                {
                  propName: "duration",
                  stateValue: "duration",
                },
              ],
            },
          ],
        },
        {
          label: "legal_entity_offer_form.rows.interest_rate.label",
          subLabel: "legal_entity_offer_form.rows.interest_rate.sub_label",
          inputs: [
            {
              type: "number",
              name: "interestRate",
              step: "0.1",
              isFloat: true,
            },
          ],
        },
      ],
    },
    {
      title: "legal_entity_offer_form.titles.provision",
      rows: [
        {
          width: "740px",
          inputs: [
            {
              type: "provision",
              name: "provision",
              initialValue: [{ type: "", data: {} }],
            },
          ],
        },
      ],
    },
    {
      title: "legal_entity_offer_form.titles.bank_data",
      tooltip: "tooltips.bank_data",
      rows: [
        {
          width: "740px",
          inputs: [
            {
              type: "bankAccounts",
              name: "bankData",
            },
          ],
        },
      ],
    },
    {
      title: "legal_entity_offer_form.titles.renewal",
      subtitle: "legal_entity_offer_form.sub_titles.renewal",
      rows: [
        {
          label: "legal_entity_offer_form.rows.loan_term.label",
          inputs: [
            {
              type: "number",
              name: "term",
              min: 0,
            },
          ],
        },
        {
          label: "empty",
          inputs: [
            {
              type: "checkBox",
              name: "approve",
              label:
                "legal_entity_offer_form.rows.legal_entity_offer_form.label",
            },
          ],
        },
      ],
    },
  ],
}
