import React from "react"

import { FormBuilder, ScrollToTopOnMount } from "src/components"
import { OrderOfferSchema } from "src/constants"

import { Container } from "./styles"

class Offer extends React.Component<any, any> {
  componentDidMount() {
    this.props.sendGTMEvent()
  }

  onChange = (state) => {
    this.props.onChange({
      name: this.props.name,
      value: state,
    })
  }

  render() {
    return (
      <Container>
        <ScrollToTopOnMount />
        <FormBuilder
          schema={OrderOfferSchema}
          value={this.props.value}
          onChange={this.onChange}
        />
      </Container>
    )
  }
}

export default Offer
