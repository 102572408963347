import styled from "styled-components"

export const IconContainer = styled.div`
  padding-left: 9px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
`

export const ButtonWrapper = styled.div`
  padding-top: 25px;
  margin: auto;
`

export const Label = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blue};
  line-height: 1.5;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
