import styled, { css } from "styled-components"

export const Container = styled.div``

export const Label = styled.label<any>`
  ${({ disabled }) => disabled
    && css`
      opacity: 0.4;
    `}

  & > a {
    color: currentColor;
    text-decoration: none;
  }
`

export const Hint = styled.p`
  color: ${({ theme }) => theme.colors.greyDarker};
  font-size: 13px;
  margin: 10px 0 0;
`
