import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RegisterUserMutation($input: RegisterUserInput!) {
    registerUser(input: $input) {
      status
      user {
        id
      }
    }
  }
`

export default enhance({ mutation })
