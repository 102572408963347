/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type Deposit_profile = {
    readonly id: string;
    readonly accreditation?: {
        readonly status: ProfileAccreditationStatus | null;
    };
    readonly borrower?: {
        readonly isApproved: boolean;
    } | null;
    readonly investor?: {
        readonly isApproved: boolean;
    } | null;
    readonly " $refType": "Deposit_profile";
};
export type Deposit_profile$data = Deposit_profile;
export type Deposit_profile$key = {
    readonly " $data"?: Deposit_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"Deposit_profile">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isApproved",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Borrower",
  "kind": "LinkedField",
  "name": "borrower",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Investor",
  "kind": "LinkedField",
  "name": "investor",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Deposit_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualProfileInvestor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v5/*: any*/),
      "type": "ForeignIndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": (v5/*: any*/),
      "type": "EntrepreneurProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "type": "LegalEntityProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '94b61d232f17216d0fb87c2719a267ef';
export default node;
