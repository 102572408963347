import React from "react"
import onClickOutside from "react-onclickoutside"

import { PopoverContainer, Option } from "./styles"

const TrustedSelectAccountKind = (props) => {
  const { show, onClose, children } = props;

  (TrustedSelectAccountKind as any).handleClickOutside = () => onClose()

  return (
    <PopoverContainer show={show} >
      {children}
    </PopoverContainer>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => (TrustedSelectAccountKind as any).handleClickOutside,
}

const Render = onClickOutside(TrustedSelectAccountKind, clickOutsideConfig)
Render.option = Option

export default Render
