import { createGlobalStyle } from "styled-components"

/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-ignore
import Slick from "slick-carousel/slick/slick.css"
// @ts-ignore
import Toastify from "react-toastify/dist/ReactToastify.css"
// @ts-ignore
import SlickTheme from "slick-carousel/slick/slick-theme.css"
// @ts-ignore
import Swiper from "swiper/css/swiper.css"
// @ts-ignore
import Tooltip from "rc-tooltip/assets/bootstrap.css"

/* eslint-disable  no-unused-expressions */
const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: "HelveticaNeue", Helvetica, sans-serif;
    font-size: 16px;
    height: 100%;
  }

  body {
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    font-family: inherit;
    outline: none;
  }

  button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: none;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline-color: rgb(0, 103, 244);
  }

  h1 {
    font-size: 55px;
    font-weight: 400;
    margin: 0;

    &.dynamic {
      @media(max-width: 1023px) {
        font-size: 40px;
      }

      @media(max-width: 767px) {
        font-size: 28px;
      }
    }
  }


  h2 {
    font-size: 40px;
    font-weight: 400;
    margin: 0;
  }


  h3 {
    font-size: 35px;
    font-weight: 400;
    margin: 0;

    &.dynamic {
      @media(max-width: 1023px) {
        font-size: 30px;
      }

      @media(max-width: 767px) {
        font-size: 24px;
      }
    }
  }

  h4 {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
  }

  h5 {
    font-size: 24px;
    font-weight: 400;
    margin: 0;

    &.dynamic {
      @media(max-width: 1023px) {
        font-size: 20px;
      }

      @media(max-width: 767px) {
        font-size: 16px;
      }
    }
  }


  a[data-current] {
    color: currentColor;
  }

  a[data-no-underline] {
    text-decoration: none;
  }


  #app-root {
    height: 100%;
    position: relative;
  }

  #modal-root {
    position: relative;
  }

  ${Toastify}
  ${Slick}
  ${SlickTheme}
  ${Swiper}
  ${Tooltip}
`

export default GlobalStyle
