import React from "react"

export const IconAdd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491.86 491.86">
    <path
      d="M465.167 211.614H280.245V26.691c0-8.424-11.439-26.69-34.316-26.69s-34.316 18.267-34.316
      26.69v184.924H26.69C18.267 211.614 0 223.053 0 245.929s18.267 34.316 26.69 34.316h184.924v184.924c0
      8.422 11.438 26.69 34.316 26.69s34.316-18.268 34.316-26.69V280.245H465.17c8.422 0 26.69-11.438
      26.69-34.316s-18.27-34.315-26.693-34.315z"
    />
  </svg>
)

export const IconClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.584 14.584">
    <path transform="rotate(45 6.482 16.359)" d="M0 0h1.875v18.75H0z" />
    <path transform="rotate(-45 1.956 .516)" d="M0 0h1.875v18.75H0z" />
  </svg>
)

export const IconLogout = () => (
  <svg
    style={{ margin: 10, boxSizing: "border-box" }}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97012 16.4751H2.24251C1.82988 16.4751 1.49502 16.1402 1.49502 15.7276V2.27242C1.49502 1.85979
      1.82992 1.52493 2.24251 1.52493H8.97012C9.38348 1.52493 9.71761 1.19081 9.71761 0.777439C9.71761 0.364072
      9.38348 0.0298767 8.97012 0.0298767H2.24251C1.00614 0.0298767 0 1.03605 0 2.27242V15.7276C0 16.9639 1.00614
      17.9701 2.24251 17.9701H8.97012C9.38348 17.9701 9.71761 17.636 9.71761 17.2226C9.71761 16.8092 9.38348 16.4751
      8.97012 16.4751Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.7773 8.46778L13.2324 3.98272C12.9394 3.69268 12.4655 3.69644 12.1754 3.99021C11.8854 4.28397
      11.8884 4.75714 12.1829 5.04718L15.4308 8.25248H6.72759C6.31423 8.25248 5.9801 8.58661 5.9801 8.99997C5.9801
      9.41334 6.31423 9.7475 6.72759 9.7475H15.4308L12.1829 12.9528C11.8884 13.2428 11.8862 13.716 12.1754
      14.0098C12.3219 14.1578 12.5148 14.2325 12.7077 14.2325C12.8975 14.2325 13.0874 14.1608 13.2324 14.0172L17.7773
      9.53217C17.9193 9.39165 18 9.20026 18 8.99994C18 8.79969 17.92 8.60907 17.7773 8.46778Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const telegram = (props) => (
  <svg
    width={props.size || 22}
    height={props.size || 22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5319 3.53992C17.0273 3.7393 2.7039 9.7783 2.7039 9.7783C2.7039 9.7783 2.01021 10.0155 2.06521
      10.4534C2.12021 10.8914 2.68602 11.0921 2.68602 11.0921L6.3724 12.333C6.3724 12.333 7.48546 15.983 7.70477
      16.676C7.92408 17.3697 8.0994 17.3862 8.0994 17.3862C8.3029 17.4749 8.48921 17.3332 8.48921 17.3332L10.8714
      15.1614L14.5846 18.0084C15.5883 18.4463 15.9534 17.534 15.9534 17.534L18.5116 4.42749C18.7385 3.41274 18.0647
      3.33024 17.5319 3.53992Z"
      stroke={props.color || "#4A4A4A"}
      strokeMiterlimit="10"
    />
    <path
      d="M15.7156 6.71275C15.7341 6.40268 15.3017 6.62406 15.3017 6.62406L6.29681 12.2017C6.29681 12.2017
      7.4855 15.983 7.70481 16.6767C7.92344 17.3704 8.22319 17.4886 8.22319 17.4886L8.89556 13.2096C8.89556 13.2096
      15.2597 7.27856 15.5334 7.02281C15.807 6.76775 15.7156 6.71275 15.7156 6.71275Z"
      fill={props.color || "#4A4A4A"}
    />
  </svg>
)

export const facebook = (props) => (
  <svg
    width={props.size || 22}
    height={props.size || 22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.44 19.8V12.76H10.12V10.56H11.44V9.24C11.44 7.48 12.32 6.16 14.08 6.16C15.3573 6.16 16.28 6.6 16.28
      6.6V8.36H15.4C14.4562 8.36 14.08 8.58176 14.08 9.24V10.56H16.28L15.84 12.76H14.08V19.8"
      stroke={props.color || "#4A4A4A"}
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.96001 19.8H18.04C19.012 19.8 19.8 19.012 19.8 18.04V3.96001C19.8 2.98805 19.012 2.20001 18.04
      2.20001H3.96001C2.98805 2.20001 2.20001 2.98805 2.20001 3.96001V18.04C2.20001 19.012 2.98805 19.8 3.96001 19.8Z"
      stroke={props.color || "#4A4A4A"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const medium = (props) => (
  <svg
    width={props.size || 22}
    height={props.size || 22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.904 5.47361C20.882 5.44501 20.8604 5.41597 20.8353 5.39001C20.8129 5.36581
      20.7896 5.34205 20.7649 5.32049C20.7046 5.26857 20.64 5.22061 20.5669 5.18321L15.3019 2.51681L14.989
      2.35841C14.9886 2.35797 14.9877 2.35797 14.9873 2.35753C14.8896 2.30825 14.7849 2.27085 14.6775
      2.24445C14.5614 2.21585 14.4417 2.20001 14.3216 2.20001C13.7944 2.20001 13.299 2.47633 13.0284 2.92073L12.2505
      4.20069L9.93388 8.01329L8.6856 5.56821C8.58836 5.41069 8.4546 5.28749 8.29488 5.20653L3.01004 2.53089C3.00916
      2.53045 3.00784 2.53001 3.00652 2.52913L2.8402 2.44509C2.61976 2.33333 2.39272 2.27657 2.16568 2.27657C1.43264
      2.27657 0.880005 2.87013 0.880005 3.65685V15.5615C0.880005 16.2105 1.298 16.8934 1.8744 17.1851L6.66996
      19.613C6.91416 19.7371 7.16452 19.8 7.41312 19.8C8.21656 19.8 8.8 19.1669 8.8 18.2943V14.4536L18.48
      19.36L18.4796 19.3556L18.8188 19.5272C19.1145 19.6768 19.4106 19.7525 19.6992 19.7525C20.522 19.7525 21.1196
      19.1391 21.1196 18.2943V6.08697C21.12 5.85773 21.0373 5.64697 20.904 5.47361ZM7.92 18.2943C7.92 18.6864 7.711
      18.92 7.41312 18.92C7.30752 18.92 7.19048 18.8905 7.06728 18.8285L2.27172 16.4001C1.99012 16.2576 1.76 15.8801
      1.76 15.5615V3.65685C1.76 3.34313 1.9272 3.15657 2.16568 3.15657C2.2506 3.15657 2.34432 3.18033 2.44244
      3.23005L2.61052 3.31497C2.6114 3.31541 2.61184 3.31541 2.61272 3.31585L7.89712 5.99149C7.9068 5.99633 7.91296
      6.00513 7.92 6.01261V18.2943ZM13.4583 3.90765L13.7804 3.37789C13.8956 3.18869 14.1082 3.08001 14.3216
      3.08001C14.3726 3.08001 14.4232 3.08749 14.4729 3.10025C14.5112 3.10905 14.5508 3.12269 14.5917
      3.14337L20.1665 5.96597C20.167 5.96641 20.167 5.96685 20.1674 5.96685L20.1727 5.96949C20.1762 5.97125 20.1766
      5.97565 20.1797 5.97829C20.2079 5.99941 20.2193 6.03725 20.2004 6.06849L14.6489 15.2051L14.0822
      16.1379L10.4042 8.93465L13.4583 3.90765ZM8.8 13.4671V7.72817L12.7521 15.4687L9.19732 13.6686L8.8
      13.4671ZM20.24 18.2943C20.24 18.663 20.0213 18.8725 19.6997 18.8725C19.5558 18.8725 19.3912
      18.8307 19.2166 18.7423L18.4219 18.3397L14.8676 16.5396L20.24 7.69781V18.2943Z"
      fill={props.color || "#4A4A4A"}
    />
  </svg>
)

export const apple = (props) => (
  <svg
    width={props.size || 22}
    height={props.size || 22}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8984 0H4.10156C1.83996 0 0 1.83996 0 4.10156V15.8984C0 18.16 1.83996 20 4.10156
      20H15.8984C18.16 20 20 18.16 20 15.8984V4.10156C20 1.83996 18.16 0 15.8984 0ZM18.8281 15.8984C18.8281
      17.5139 17.5139 18.8281 15.8984 18.8281H4.10156C2.48613 18.8281 1.17188 17.5139 1.17188 15.8984V4.10156C1.17188
      2.48613 2.48613 1.17188 4.10156 1.17188H15.8984C17.5139 1.17188 18.8281 2.48613 18.8281 4.10156V15.8984Z"
      fill={props.color || "#4A4A4A"}
    />
    <path
      d="M11.9393 4.82542C11.8718 4.57347 11.7102 4.36284 11.4843 4.23245C11.018 3.96323 10.4196 4.12351
      10.1503 4.58991L10 4.85023L9.84969 4.58987C9.58043 4.12359 8.98203 3.96323 8.5157 4.23241H8.51566C8.04934
      4.50167 7.88902 5.10007 8.15824 5.56644L8.87234 6.80331L6.23742 11.3672H4.31641C3.77793 11.3672 3.33984
      11.8053 3.33984 12.3437C3.33984 12.8822 3.77793 13.3203 4.31641 13.3203H11.7328L10.6052 11.3672H8.49269L11.8417
      5.56647C11.9721 5.34058 12.0068 5.07737 11.9393 4.82542Z"
      fill={props.color || "#4A4A4A"}
    />
    <path
      d="M15.6836 11.3672H13.7626L11.5787 7.58459L10.4511 9.53772L13.8419 15.4107C13.9723 15.6366 14.1829
      15.7982 14.4349 15.8658C14.519 15.8883 14.6044 15.8995 14.6892 15.8995C14.8585 15.8995 15.0255 15.8551
      15.176 15.7682C15.6423 15.4989 15.8026 14.9005 15.5334 14.4342L14.8902 13.3203H15.6836C16.2221 13.3203
      16.6602 12.8822 16.6602 12.3437C16.6602 11.8053 16.2221 11.3672 15.6836 11.3672Z"
      fill={props.color || "#4A4A4A"}
    />
    <path
      d="M4.65873 14.1016L4.46666 14.4342C4.19744 14.9005 4.35779 15.499 4.82412 15.7682C4.97752 15.8568
      5.14517 15.8989 5.31076 15.8988C5.64849 15.8988 5.97744 15.7237 6.15814 15.4107L6.914 14.1016H4.65873Z"
      fill={props.color || "#4A4A4A"}
    />
  </svg>
)

export const android = (props) => (
  <svg
    width={props.size || 22}
    height={props.size || 22}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9151 8.09119L4.26697 0.289787C3.93532 0.100181 3.55797 0 3.17591 0C2.78888 0 2.40736 0.10267
      2.07259 0.296898C1.39701 0.688821 0.977356 1.41747 0.977356 2.19856V17.8014C0.977356 18.5824 1.39701
      19.3111 2.07241 19.703C2.40718 19.8974 2.78879 20.0001 3.17582 20.0001C3.5577 20.0001 3.93505 19.8998 4.26688
      19.7102L17.9149 11.9087C18.5981 11.5181 19.0225 10.7868 19.0225 9.99996C19.0225 9.21309 18.5983 8.48178
      17.9151 8.09119ZM12.0009 6.24651L10.127 8.85672L5.64461 2.61315L12.0009 6.24651ZM3.08987 18.6591C2.968
      18.6472 2.84844 18.6117 2.74168 18.5497C2.47589 18.3955 2.31073 18.1087 2.31073 17.8014V2.19847C2.31073
      1.89117 2.47589 1.60441 2.74177 1.45018C2.84817 1.3884 2.96675 1.3516 3.08862 1.33906L9.30624 9.99996L3.08987
      18.6591ZM5.64461 17.3869L10.127 11.1432L12.0009 13.7534L5.64461 17.3869ZM17.2533 10.751L13.1647 13.0881L10.9476
      9.99996L13.1647 6.91177L17.2533 9.24891C17.5221 9.4026 17.6892 9.69035 17.6892 9.99996C17.6892 10.3096
      17.5222 10.5974 17.2533 10.751Z"
      fill={props.color || "#4A4A4A"}
    />
  </svg>
)

export const Logo = (props) => (
  <svg
    width="90"
    height="19"
    viewBox="0 0 90 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 15V3H31.1331V7.63217H32.4222L35.0719 3H38.4378L34.9824
      8.73986V8.77343L38.6347 15H35.1256L32.4222 10.1329H31.1331V15H28ZM38.6884 15L43.039 3H46.3332L50.6838
      15H47.4612L46.6734 12.4825H42.6809L41.9111 15H38.6884ZM43.415 10.1329H45.9394L45.2232 7.8L44.704
      5.73567H44.6682C44.4772 6.6084 44.3042 7.2965 44.149 7.8L43.415 10.1329ZM51.9729 15V3H56.4308C57.5647
      3 58.3943 3.0951 58.9194 3.28531C59.6595 3.55385 60.2383 3.98741 60.6561 4.58601C61.0738 5.18462 61.2827
      5.91468 61.2827 6.77622C61.2827 7.44756 61.1156 8.08811 60.7814 8.6979C60.4472 9.3077 59.9757 9.75804 59.367
      10.049V10.0825C59.4864 10.228 59.6475 10.4685 59.8504 10.8042L62.339 15H58.8478L56.5741
      10.9552H55.106V15H51.9729ZM55.106 8.43776H56.4666C56.9799 8.43776 57.3797 8.30909 57.6662 8.05175C57.9526
      7.7944 58.0959 7.43077 58.0959 6.96084C58.0959 6.3007 57.8452 5.87553 57.3439 5.68531C57.0694 5.57343 56.6755
      5.51748 56.1623 5.51748H55.106V8.43776ZM63.2521 15L64.3084 3H67.7101L69.572 8.06853L70.2166
      10.049H70.2524C70.4911 9.24335 70.7059 8.58322 70.8969 8.06853L72.7589 3H76.1606L77.199 15H74.0658L73.672
      9.5958C73.6481 9.32727 73.6362 9.02518 73.6362 8.68951C73.6362 8.35385 73.6421 8.08532 73.6541
      7.88392L73.672 7.58182H73.6362C73.3497 8.42098 73.111 9.09231 72.92 9.5958L71.5772 13.0699H68.8917L67.5489
      9.5958L66.8328 7.58182H66.797C66.8447 8.32028 66.8447 8.99161 66.797 9.5958L66.4031 15H63.2521ZM78.0046
      15L82.3552 3H85.6494L90 15H86.7774L85.9896 12.4825H81.9971L81.2273 15H78.0046ZM82.7312 10.1329H85.2556L84.5394
      7.8L84.0202 5.73567H83.9844C83.7934 6.6084 83.6204 7.2965 83.4652 7.8L82.7312 10.1329Z"
      fill={props.color || "#393939"}
    />
    <path
      d="M9.16393 18.3279C14.225 18.3279 18.3279 14.225 18.3279 9.16393C18.3279 4.10283 14.225 0 9.16393
      0C4.10283 0 0 4.10283 0 9.16393C0 14.225 4.10283 18.3279 9.16393 18.3279Z"
      fill="#FF2D55"
    />
    <path
      d="M4.96378 4.58197V13.7459H7.98787V11.7578L8.78208 10.8259L10.5232 13.7459H14.1277L10.9509
      8.52712L14.1277 4.58197H10.5538L8.01842 7.99903V4.58197H4.96378Z"
      fill="white"
    />
  </svg>
)

export const email = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 612.074 612.074"
    width={props.size || 22}
    height={props.size || 22}
   >
  <g>
    <path d="M612.074,132.141v-2.38c0-8.849-4.016-19.26-11.229-26.473l-0.818-0.818c0,0-0.818,0-0.818-0.818
      c-1.636-1.636-3.198-2.38-4.833-4.016c-0.818,0-0.818-0.818-1.636-0.818c-1.636-0.818-4.016-1.636-5.652-2.38
      c-0.818,0-0.818-0.818-1.636-0.818c-2.38-0.818-4.833-1.636-7.213-1.636c-0.818,0-0.818,0-1.636,0c-2.38,
      0-5.651-0.818-8.849-0.818
      H43.427c-3.198,0-6.395,0-9.667,0.818c-0.818,0-1.636,0-2.38,0.818c-2.38,0.818-4.834,0.818-6.395,1.636
      c-0.818,0-0.818,0.818-1.636,0.818c-1.636,0.818-4.016,1.636-5.652,2.38l-0.818,0.818c-1.636,0.818-3.198,
      2.38-4.834,3.198
      c-0.818,0.818-1.636,1.636-2.38,2.38C4.016,110.428,0.818,117.715,0,125.746c0,0.818,0,0.818,0,1.636v357.384
      c0,0.818,0,0.818,0,1.636c1.636,11.229,7.213,20.896,15.244,26.473c7.213,4.833,16.062,8.031,26.473,
      8.031H569.39c0,0,0,0,0.818,0
      l0,0c2.38,0,5.651,0,8.031-0.818c0.818,0,0.818,0,1.636,0c2.38-0.818,4.834-0.818,6.395-1.636h0.818
      c17.698-6.395,24.911-21.714,24.911-36.14v-2.38v-0.818v-0.818V134.521c0-0.818,0-0.818,0-1.636
      C612.074,132.959,612.074,132.959,612.074,132.141z M560.69,120.913l-252.98,246.51l-57.854-56.218l0,
      0L51.459,120.838H560.69
      V120.913z M29.819,475.099V140.991l187.095,179.882L29.819,475.099z M299.679,
      491.905H56.292l182.336-149.393l58.597,57.036
      c2.38,2.38,4.834,3.198,7.213,4.016h0.818c0.818,0,0.818,0,1.636,0l0,0c0.818,0,1.636,0,1.636,0h0.818
      c2.38-0.818,5.651-1.636,7.213-4.016l55.4-53.838l183.079,146.196H299.679z M582.329,475.843L394.417,
      324.07L582.329,140.99
      V475.843z" fill={props.color || "#FFFFFF"}/>
  </g>
</svg>
)
