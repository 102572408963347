import React from "react"

import {
  Box,
  Label,
  FormRow,
  TextField,
  Translate,
} from "src/components"

import Input from "../Input"

class BankDetails extends React.Component<any> {
  state = (BankDetails as any).fromGraphQLType(this.props.value)

  onBankChange = (event) => {
    if (event) {
      event.preventDefault()
    }

    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  onChange = (event) => {
    if (event) {
      event.preventDefault()
    }

    const { name, value } = event.target
    this.setState(() => ({ [name]: value }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: this.state,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Input
          name="bank"
          value={this.state.bank}
          onChange={this.onBankChange}
        />
        <FormRow>
          <Box width={260}>
            <Label>
              <Translate
                i18n="accreditation.individual.rows.correspondent_account.label"
                ns="components"
              />
            </Label>
          </Box>
          <Box width="100%">
            <TextField
              type="text"
              name="correspondentAccount"
              value={this.state.correspondentAccount}
              onChange={this.onChange}
            />
          </Box>
        </FormRow>
        <FormRow>
          <Box width={260}>
            <Label>
              <Translate
                i18n="accreditation.individual.rows.checking_account.label"
                ns="components"
              />
            </Label>
          </Box>
          <Box>
            <TextField
              type="text"
              name="checkingAccount"
              value={this.state.checkingAccount}
              onChange={this.onChange}
            />
          </Box>
        </FormRow>
      </React.Fragment>
    )
  }
}

(BankDetails as any).fromGraphQLType = (attributes: any = { bank: {} }) => ({
  bank: (Input as any).fromGraphQLType(attributes.bank),
  checkingAccount: attributes.checkingAccount || "",
  correspondentAccount: attributes.correspondentAccount || "",
});

(BankDetails as any).toGraphQLInput = (attributes) => ({
  bank: {
    bic: attributes.bank.bic,
    name: attributes.bank.name,
  },
  checkingAccount: attributes.checkingAccount,
  correspondentAccount: attributes.correspondentAccount,
})

export default BankDetails
