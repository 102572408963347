import React from "react"

import Step from "./Step"

import {
  Wrapper,
  Title,
  ProfileIcon,
  CoinIcon,
  WalletIcon,
  ContactIcon,
} from "./styles"

function StepperWelcomePage(props) {
  const {
    investmentsCount, isAccredited, balance, profileId,
  } = props

  const steps = [
    {
      Icon: ProfileIcon,
      text: "Создать аккаунт на Карма",
      textButton: "Создать",
      link: "/profiles/create/individual",
      completed: true,
    },
    {
      Icon: ContactIcon,
      text: "Пройти аккредитацию",
      description:
        "Для создания виртуального счета нам потребуется немного информации",
      textButton: "Пройти",
      link: `/accounts/${profileId}/accreditation`,
      completed: isAccredited,
    },
    {
      Icon: WalletIcon,
      text: "Пополнить виртуальный счет",
      description: "Пополните виртуальный счёт переводом из вашего банка",
      textButton: "Пополнить",
      link: `/accounts/${profileId}`,
      completed: isAccredited && balance > 0,
      disabled: !isAccredited,
    },
    {
      Icon: CoinIcon,
      text: "Инвестировать в заявки",
      textButton: "Инвестировать",
      link: "/market",
      completed: isAccredited && balance > 0 && investmentsCount > 0,
      disabled: !(isAccredited && balance > 0),
    },
  ]

  return (
    <Wrapper>
      <Title>Что дальше?</Title>

      {steps.map((step) => (
        <Step key={step.text} {...step} />
      ))}
    </Wrapper>
  )
}

export default StepperWelcomePage
