import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation RequestOrderApprovalMutation($input: RequestOrderApprovalInput!) {
    requestOrderApproval(input: $input) {
      order {
        id
      }
    }
  }
`

export default enhance({ mutation })
