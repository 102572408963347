import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query ProfileInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on IndividualProfile {
        name
        phone
        _avatar {
          url
        }
        isApproved
        investorAccreditation: accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on EntrepreneurProfile {
        name
        phone
        _avatar {
          url
        }
        isApproved
        investorAccreditation: accreditation(role: INVESTOR) {
          status
          declineReason
        }
      }
      ... on LegalEntityProfile {
        name
        phone
        _avatar {
          url
        }
        isApproved
        borrowerAccreditation: accreditation(role: BORROWER) {
          status
          declineReason
        }
      }
    }
  }
`

export default (render) => ({ profileId, ...rest }) => {
  const variables = {
    id: profileId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}
