import React from "react"

import { Heading } from "src/components"

import { DescriptionContainer } from "../styles"

export default ({ children }) => (
  <DescriptionContainer>
    <Heading.h2>{children}</Heading.h2>
  </DescriptionContainer>
)
