const suggestionTheme = {
  suggestionsList: {
    position: "absolute",
    top: "40px",
    right: 0,
    left: 0,
    margin: 0,
    padding: 0,
    border: "1px solid #aaa",
    backgroundColor: "white",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    zIndex: "10",
    listStyleType: "none",
  },
}

export default suggestionTheme
