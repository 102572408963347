import styled from "styled-components"

import { Text } from "src/components"

export const Container = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  margin: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 14px ${(props) => props.theme.colors.fullBlacks[3]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryBlack};
  max-width: 420px;
  padding: 40px 30px 30px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 80px 20px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 22px;
  width: 32px;
  height: 32px;
  border: none;
  padding: 4px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const ReturnButton = styled(CloseButton)`
  width: auto;
  left: 14px;
  display: none;

  span {
    font-family: Geometria, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: black;
  }

  @media screen and (max-width: 480px) {
    left: 14px;
    display: flex;
  }
`

export const Title = styled.h3`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primaryBlacks[9]};
  margin: 0 0 34px;
`

export const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.greyShuttle};
  line-height: 1.5;
`

export const Button = styled.button`
  max-width: 150px;
  width: 100%;
  height: 45px;
  padding: 2px 4px 0;
  border: 0;
  background-color: ${({ theme }) => theme.colors.mainRed};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Geometria, sans-serif;
  
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`
