export function parseItems(data) {
  let totalDebt = data.reduce((total, item) => total + item.loan, 0)

  return data.map((item) => {
    const debt = totalDebt - item.loan
    totalDebt -= item.loan

    return {
      ...item,
      debt,
    }
  })
}

export function getPastduePayment(items) {
  const pastdueItems = items.filter((item) => item.state === "pastdue")
  const { length } = pastdueItems

  if (!length) return null

  return pastdueItems[length - 1]
}

export const parseInvestments = (investments = []) => {
  const parsed = investments.map((investment) => investment.history || [])
  return parsed?.flat(1) || []
}

export const sortByDate = (items = []) => items.sort((a, b) => {
  const first = a.date ? new Date(a.date).getTime() : 0
  const second = b.date ? new Date(b.date).getTime() : 0

  return first - second
})

export const isInvestment = (item) => item.type === "INVESTMENT" || item.type === "PURCHASE" || item.type === "SELL"
