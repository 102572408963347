import styled from "styled-components"

export const IconWrapper = styled.div`
  margin-left: auto;
  transform: translateY(-5%);
  width: 18px;
  height: 18px;
  cursor: pointer;
`

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const InputWrapper = styled.div`
  width: 94%;
`
