import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const RockerWidth = 56
const RockerPadding = 4
const GuideWidth = 18

const Container = styled.div`
  display: inline-block;
  position: relative;

  & > input {
    height: 100%;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

const GuideCSS = () => css`
  background-color: rgb(248, 244, 244);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 50%;
  content: "";
  height: 18px;
  width: 18px;

  position: absolute;
  top: 50%;
  left: ${RockerPadding}px;
  transform: translateY(-50%);
  transition: left 50ms;
`

const Plane = styled.div<any>`
  border-radius: 13px;
  color: ${({ theme }) => theme.colors.white};
  height: 26px;
  position: relative;
  width: ${RockerWidth}px;

  &::after {
    ${GuideCSS}
  }

  background-color: ${({ theme }) => theme.colors.greyDarker};

  input:disabled + & {
    opacity: 0.4;
  }

  input:checked + & {
    background-color: ${({ backgroundColor, theme }) => theme.colors[backgroundColor]};

    &::after {
      left: ${RockerWidth - GuideWidth - RockerPadding}px;
    }
  }
`

const SVG = styled.svg<any>`
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: 50%;
  ${({ position }) => position}: 10px;
  transform: translateY(-50%);
`

const Icon = ({ path, ...props }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
    <path d={path} />
  </SVG>
)

const render = (props) => {
  const { blank, monochrome, ...rest } = props

  return (
    <Container>
      <input type="checkbox" {...rest} />
      <Plane backgroundColor={monochrome ? "greyDarker" : "green"}>
        {!blank && (
          <React.Fragment>
            <Icon position="left" path="M1,5L3.5,7.5L9,2.5" />
            <Icon position="right" path="M1,1L9,9M1,9L9,1" />
          </React.Fragment>
        )}
      </Plane>
    </Container>
  )
}

render.propTypes = {
  blank: PropTypes.bool,
  monochrome: PropTypes.bool,
}

render.defaultProps = {
  blank: false,
  monochrome: false,
}

export default render
