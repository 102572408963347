import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  padding: 32px 38px;
  border-radius: 5px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`

export const FooterContainer = styled.div`
  height: 90px;
  display: flex;
  padding: 0 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`
