import React, { useState } from "react"
import ReactDOM from "react-dom"

import { MutationContainer } from "src/containers"
import { SetOrderKarmaTrustedStatus } from "src/mutations"
import { TrustedOrderInfoQueryContainer } from "src/query"
import { Loader, QueryRenderer } from "src/components"

import {
  ModalContainer,
  WindowContainer,
  Image,
  ImageContainer,
  Title,
  Reason,
  TextWrapper,
  Header,
  CloseBtn,
  Helper,
  EditBtn,
  Description,
  CheckboxWrapper,
  Label,
  Tooltip,
  CheckboxLoader,
  LoaderContainer,
} from "./styles"
import { closeIcon } from "./icons"
import { Checkbox } from "./Elements"

function ModalWindow(props) {
  const { children, onClose } = props

  const overlayRef = React.createRef<HTMLDivElement>()
  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      onClose()
    }
  }

  return (
    <ModalContainer ref={overlayRef} onClick={onOverlayClick}>
      <WindowContainer>
        <CloseBtn onClick={onClose}>{closeIcon}</CloseBtn>
        {children}
      </WindowContainer>
    </ModalContainer>
  )
}

function OrderInfo(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClose, node } = props
  const { declineReason } = node.application
  const { isTrusted } = node.karma

  const [isChecked, setChecked] = useState(isTrusted)
  const [isLoading, setLoading] = useState(false)

  /* TODO: used вернуть или удалить окончательно
  const overlayRef = React.createRef()

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      onClose()
    }
  } */

  function handleChange() {
    setLoading(true)

    const { commit } = props
    const { id } = node

    const variables = {
      input: {
        orderId: id,
        isTrusted: !isChecked,
      },
    }

    commit({
      variables,
      callbacks: {
        onError: () => setLoading(false),
        onCompleted: () => {
          setChecked(!isChecked)
          setLoading(false)
        },
      },
    })
  }

  return (
    <>
      <Header>
        <ImageContainer>
          <Image src="/images/cryingDude.svg" />
        </ImageContainer>
        <TextWrapper>
          <Title>В размещении отказано</Title>
          <Reason>{declineReason || "Причина недоступна"}</Reason>
        </TextWrapper>
      </Header>

      <CheckboxWrapper>
        {isLoading && (
          <CheckboxLoader>
            <Loader fontSize="3px" />
          </CheckboxLoader>
        )}
        {!isLoading && <Checkbox checked={isChecked} onChange={handleChange} />}
        <Label>Разрешить помочь мне с оформлением заявки</Label>
      </CheckboxWrapper>
      <Tooltip>
        Наш сотрудник поможет составить заявку и сделать ее более
        привлекательной для инвесторов.
      </Tooltip>

      <Description>
        После того как вы внесете исправления, ваша заявка снова должна будет
        пройти процедуру проверки.
      </Description>
      <EditBtn>Редактировать анкету</EditBtn>

      <Helper>Если у вас есть вопросы, пишите на help@karma.red</Helper>
    </>
  )
}

function ModalQueryContainer({ orderId, ...rest }) {
  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={TrustedOrderInfoQueryContainer.query}
      render={(propses) => (
        <ModalWindow {...propses}>
          <OrderInfo {...propses} />
        </ModalWindow>
      )}
      renderNull={(propses) => (
        <ModalWindow {...propses}>
          <LoaderContainer>
            <Loader fontSize="12px" />
          </LoaderContainer>
        </ModalWindow>
      )}
      variables={variables}
    />
  )
}

function Render(props) {
  const { isShowing, ...rest } = props

  return (
    <MutationContainer mutation={SetOrderKarmaTrustedStatus}>
      {({ commit }) => (
        <ModalQueryContainer orderId={isShowing} {...rest} commit={commit} />
      )}
    </MutationContainer>
  )
}

export default function ModalDeclinedReason(props) {
  const { isShowing } = props
  const root = window.document.getElementById("app-root")
  return isShowing ? ReactDOM.createPortal(Render(props), root) : null
}
