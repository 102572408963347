import styled from "styled-components"

export const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 70px;
  width: 100%;
  padding: 20px 50px 0;
  box-sizing: border-box;
  background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`

export const TabContainer = styled.div<any>`
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 5px 5px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-bottom: none;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.grey)};
`
