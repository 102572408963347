import Create from "./Create"
import Manage from "./ManageIndividual"
import Accreditation from "./Accreditation"

const render = () => null

render.create = Create
render.manage = Manage
render.accreditation = Accreditation

export default render
