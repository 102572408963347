import React from "react"
import Relay, { graphql } from "react-relay"

import { BankAccountStatuses } from "src/constants"
import { getBankAccountsFromForeignProfile, getProperty } from "src/utils"

import { Translate, DefaultModal } from "src/components"

import { StyledButton, TooltipContext } from "./styles"
import AmountModal from "./AmountModal"
import Confirmation from "./Confirmation"
import SuccessModal from "./SuccessModal"

import { useOnCompleteCallbacks, useWithdrawAmount } from "./hooks"

import { Tooltip } from "./elements"

const modals = {
  amount: {
    component: AmountModal,
    next: "confirmation",
  },
  confirmation: {
    component: Confirmation,
    next: "success",
  },
  success: {
    component: SuccessModal,
    next: null,
  },
}

const getAvailableBalanceAmount = (profile) => {
  const { accountBalance, accountBalanceDetailed } = profile

  if (Array.isArray(accountBalance)) {
    return getProperty(accountBalance, "[0].available", 0)
  }

  if (Array.isArray(accountBalanceDetailed)) {
    return getProperty(accountBalanceDetailed, "[0].available", 0)
  }

  return accountBalance || 0
}

const Withdraw = (props) => {
  const profile = props.profile || props.foreignProfile
  const bankAccounts = getBankAccountsFromForeignProfile(profile)
  const hasApprovedBankAccount = bankAccounts.some(
    (acc) => acc.status === BankAccountStatuses.approved,
  )
  const availableBalance = getAvailableBalanceAmount(profile)
  const isAccreditationApproved = profile.accreditation.status === "APPROVED"

  const [withdrawAmount, setWithdrawAmount] = useWithdrawAmount()

  const [activeModal, setActiveModal] = React.useState(null)
  const openModal = React.useCallback(() => setActiveModal("amount"), [])
  const hideModal = React.useCallback(() => {
    ;(setWithdrawAmount as any)({ target: { value: "" } })
    setActiveModal(null)
  }, [])

  const Modal = React.useMemo(() => modals[activeModal], [activeModal])
  const callbacks = useOnCompleteCallbacks(modals, setActiveModal, hideModal)

  const isDisabled = !hasApprovedBankAccount || !availableBalance || !isAccreditationApproved

  const { RenderedButton } = props

  return (
    <>
      <TooltipContext data-tip data-for="withdraw">
        {RenderedButton && (
          <RenderedButton onClick={openModal} disabled={isDisabled}>
            <Translate i18n="components:account.operations.withdraw.amount.controls.withdraw" />
          </RenderedButton>
        )}

        {!RenderedButton && (
          <StyledButton
            variant="outlinedBlack"
            onClick={openModal}
            disabled={isDisabled}
          >
            <Translate i18n="components:account.operations.withdraw.amount.controls.withdraw" />
          </StyledButton>
        )}
      </TooltipContext>

      <Tooltip
        isAccreditationApproved={isAccreditationApproved}
        isAvailableBalance={!!availableBalance}
        isDisabled={isDisabled}
        changeTab={props.changeTab}
        id="withdraw"
      />

      {!!activeModal && (
        <DefaultModal
          padding="0"
          onClose={hideModal}
          maxHeight={"90%"}
          closeButtonVariant="empty"
        >
          <Modal.component
            profile={profile}
            onClose={hideModal}
            onChange={setWithdrawAmount}
            withdrawAmount={withdrawAmount}
            onComplete={callbacks[activeModal]}
            availableBalance={availableBalance}
          />
        </DefaultModal>
      )}
    </>
  )
}

export default Relay.createFragmentContainer(Withdraw, {
  profile: graphql`
    fragment Withdraw_profile on UserProfile {
      __typename
      id
      name
      accountBalanceDetailed {
        available
        blocked
        invested
        currency
      }
      bankAccounts {
        id
        __typename
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
      ... on LegalEntityProfile {
        inn
        accreditation(role: BORROWER) {
          status
        }
      }
      ... on ForeignIndividualProfile {
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on IndividualProfile {
        inn
        passport {
          scans {
            id
          }
        }
        accreditation(role: INVESTOR) {
          status
        }
      }
      ... on EntrepreneurProfile {
        inn
        passport {
          scans {
            id
          }
        }
        accreditation(role: INVESTOR) {
          status
        }
      }
    }
  `,
  foreignProfile: graphql`
    fragment Withdraw_foreignProfile on ForeignIndividualProfile {
      __typename
      id
      firstName
      lastName
      accountBalanceDetailed {
        available
        blocked
        invested
        currency
      }
      accreditation(role: INVESTOR) {
        status
      }
      foreignBankAccounts: allBankAccounts {
        ... on ForeignBankAccount {
          id
          __typename
          status
          IBAN
          ownerName
          bank {
            name
            address
            SWIFT
          }
        }
      }
      bankAccounts {
        ... on BankAccount {
          id
          __typename
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
    }
  `,
})
