/* eslint-disable max-len */

import React from "react"

export const IllustrationIcon = (props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 65 65"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6462 64.6154C50.4892 64.6154 64.9539 50.1507 64.9539 32.3077C64.9539 14.4646 50.4892 0 32.6462 0C14.8031 0 0.338501 14.4646 0.338501 32.3077C0.338501 50.1507 14.8031 64.6154 32.6462 64.6154Z"
      fill="#FF2C56"
    />
    <path
      d="M53.8783 52.4961H11.3521C9.66896 52.4961 9.13161 51.0439 10.0731 49.3874L16.7345 37.8055C17.1786 37.0106 18.422 36.3889 19.4923 36.3889H46.5153C47.5855 36.3889 48.7846 37.0106 49.2065 37.8055L55.3172 49.3874C56.1698 51.0439 55.5614 52.4961 53.8783 52.4961Z"
      fill="white"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M18.8129 44.5424H37.8422C38.0189 44.5424 38.1883 44.4723 38.3132 44.3473C38.4382 44.2224 38.5083 44.053 38.5083 43.8763C38.5083 43.6996 38.4382 43.5302 38.3132 43.4053C38.1883 43.2803 38.0189 43.2102 37.8422 43.2102H18.8129C18.6362 43.2102 18.4668 43.2803 18.3418 43.4053C18.2169 43.5302 18.1467 43.6996 18.1467 43.8763C18.1467 44.053 18.2169 44.2224 18.3418 44.3473C18.4668 44.4723 18.6362 44.5424 18.8129 44.5424H37.8422C38.0189 44.5424 38.1883 44.4723 38.3132 44.3473C38.4382 44.2224 38.5083 44.053 38.5083 43.8763C38.5083 43.6996 38.4382 43.5302 38.3132 43.4053C38.1883 43.2803 38.0189 43.2102 37.8422 43.2102H18.8129C18.6362 43.2102 18.4668 43.2803 18.3418 43.4053C18.2169 43.5302 18.1467 43.6996 18.1467 43.8763C18.1467 44.053 18.2169 44.2224 18.3418 44.3473C18.4668 44.4723 18.6362 44.5424 18.8129 44.5424Z"
      fill="black"
    />
    <path
      d="M16.9877 48.1884H48.5537C48.7304 48.1884 48.8998 48.1182 49.0248 47.9933C49.1497 47.8684 49.2199 47.699 49.2199 47.5223C49.2199 47.3456 49.1497 47.1762 49.0248 47.0513C48.8998 46.9263 48.7304 46.8562 48.5537 46.8562H16.9877C16.811 46.8562 16.6416 46.9263 16.5166 47.0513C16.3917 47.1762 16.3215 47.3456 16.3215 47.5223C16.3215 47.699 16.3917 47.8684 16.5166 47.9933C16.6416 48.1182 16.811 48.1884 16.9877 48.1884H48.5537C48.7304 48.1884 48.8998 48.1182 49.0248 47.9933C49.1497 47.8684 49.2199 47.699 49.2199 47.5223C49.2199 47.3456 49.1497 47.1762 49.0248 47.0513C48.8998 46.9263 48.7304 46.8562 48.5537 46.8562H16.9877C16.811 46.8562 16.6416 46.9263 16.5166 47.0513C16.3917 47.1762 16.3215 47.3456 16.3215 47.5223C16.3215 47.699 16.3917 47.8684 16.5166 47.9933C16.6416 48.1182 16.811 48.1884 16.9877 48.1884Z"
      fill="black"
    />
    <path
      d="M45.5382 9.06393C45.3348 8.86231 45.1029 8.69173 44.8498 8.55767C43.2244 6.73689 40.6132 5.56449 38.4727 5.69772C34.1472 5.97305 31.9267 8.80636 29.5908 12.0616C28.3163 13.8113 27.1572 15.7075 25.6517 17.2708C24.6969 18.27 23.9331 19.2514 23.711 20.5126C23.6095 20.6378 23.5146 20.7682 23.4268 20.9034C23.2821 21.1872 23.1661 21.4848 23.0804 21.7916C22.7771 22.135 22.4376 22.4447 22.0679 22.7153C21.2444 23.5289 20.5159 24.4332 19.8963 25.411C19.8058 25.5523 19.7271 25.7008 19.6609 25.8551C18.0178 27.3383 18.826 30.8156 21.313 30.6557C21.4158 30.741 21.5241 30.8196 21.6371 30.8911C22.6941 31.5616 23.7821 32.1656 24.8968 32.7207C25.2121 32.9827 25.4874 33.2092 25.6873 33.3513C27.3051 34.4541 28.8197 35.7012 30.2126 37.0773C31.3361 38.2275 33.7653 38.152 34.5069 36.5221C34.911 35.634 35.3463 34.7236 35.7992 33.8576C36.274 33.6371 36.676 33.2859 36.9583 32.8451C39.1566 29.4833 41.6612 26.2014 43.5042 22.6487C43.7307 22.2046 43.9483 21.7605 44.1748 21.2587C44.7789 20.6747 45.295 20.006 45.7069 19.2736C47.4433 16.0717 48.3848 11.8662 45.5382 9.06393Z"
      fill="#F7AF2F"
    />
    <path
      d="M32.2156 38.5072C29.4222 36.2868 26.2203 34.6658 23.4625 32.401C23.3527 32.3055 23.2159 32.2465 23.0711 32.2322C22.9263 32.2179 22.7806 32.2489 22.6543 32.321C21.3969 31.3226 20.0832 30.3974 18.7196 29.5499C18.6017 29.4859 18.4678 29.4575 18.3341 29.4682C18.2004 29.4788 18.0727 29.528 17.9664 29.6098C17.8602 29.6916 17.7799 29.8025 17.7355 29.9291C17.691 30.0556 17.6842 30.1923 17.7159 30.3226C18.0712 31.8992 18.0401 33.4313 18.3244 34.9501C18.3223 35.0346 18.3406 35.1183 18.3776 35.1943C18.4265 35.4164 18.4798 35.6384 18.542 35.8649C18.5322 35.9867 18.5536 36.1089 18.6041 36.2202V36.2424C18.5958 36.3746 18.6234 36.5065 18.6841 36.6243L18.9061 37.0684C18.9677 37.2012 19.0772 37.3058 19.2125 37.3615C19.2747 37.4325 19.3458 37.5036 19.439 37.5924C19.4865 37.7822 19.5998 37.9491 19.7588 38.0631C20.0583 38.2904 20.3762 38.4924 20.7091 38.6671C21.0514 38.9227 21.4807 39.0328 21.9037 38.9735C21.9482 38.958 21.9913 38.9387 22.0325 38.9158C22.3301 39.1467 22.6276 39.3599 22.9207 39.5997C22.9946 39.6533 23.0796 39.6897 23.1694 39.7063L24.3374 40.8787C24.4059 40.9453 24.4877 40.9966 24.5776 41.0293C24.6674 41.0619 24.7631 41.0751 24.8584 41.0679C24.9537 41.0608 25.0464 41.0335 25.1304 40.9879C25.2143 40.9422 25.2876 40.8793 25.3455 40.8032C26.7292 40.5708 28.099 40.2624 29.4489 39.8795C29.5977 39.8358 29.7255 39.7395 29.8086 39.6086L31.7093 39.6574C32.2822 39.6885 32.6952 38.8847 32.2156 38.5072Z"
      fill="white"
    />
    <path
      d="M22.5431 40.4123C21.9743 40.2096 21.4508 39.8973 21.0021 39.4931C20.4928 39.1274 19.9518 38.8078 19.3856 38.5383C19.2705 38.4794 19.1413 38.4536 19.0124 38.4635C18.8835 38.4735 18.7598 38.5189 18.6551 38.5947C18.5503 38.6705 18.4685 38.7738 18.4188 38.8931C18.369 39.0125 18.3532 39.1432 18.3731 39.271C18.3013 39.368 18.2652 39.4868 18.2708 39.6074C18.2765 39.728 18.3236 39.8429 18.4042 39.9327C18.5063 40.0659 18.6174 40.1903 18.7239 40.3191C18.7366 40.4002 18.7637 40.4784 18.8039 40.55C18.8588 40.6479 18.9211 40.7415 18.9904 40.8298C18.9757 40.9617 18.9972 41.0951 19.0528 41.2156C19.1084 41.3362 19.1958 41.4392 19.3057 41.5137C19.4768 41.6476 19.694 41.7082 19.9097 41.6824C19.9428 41.6776 19.9754 41.6701 20.0074 41.6602C20.1996 41.7323 20.3991 41.783 20.6025 41.8112C20.7225 41.8574 20.8554 41.8574 20.9755 41.8112C21.1046 41.795 21.2232 41.7318 21.3086 41.6336C21.6565 41.6951 22.0081 41.7336 22.3611 41.749C22.5195 41.7387 22.6694 41.6739 22.7855 41.5656C22.9016 41.4574 22.9768 41.3124 22.9982 41.1551C23.0196 40.9978 22.986 40.8379 22.9031 40.7026C22.8202 40.5672 22.693 40.4647 22.5431 40.4123Z"
      fill="black"
    />
    <path
      d="M19.2034 41.9534C18.7593 41.7757 18.4174 38.7515 17.9422 35.4474C17.4981 32.4454 17.1828 29.7408 18.1731 28.2354L31.8423 9.18383C33.1312 7.34689 35.0945 6.09451 37.3035 5.70012C39.5126 5.30573 41.788 5.80137 43.6329 7.07883L44.037 7.35861C45.874 8.64749 47.1263 10.6108 47.5207 12.8199C47.9151 15.029 47.4195 17.3043 46.142 19.1492L32.8193 38.2763C31.8645 39.9105 28.8224 40.3236 26.2423 40.892C23.0182 41.5937 19.6475 42.1399 19.2034 41.9534Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M31.958 18.9005C32.7649 18.9005 33.4191 18.2464 33.4191 17.4395C33.4191 16.6326 32.7649 15.9784 31.958 15.9784C31.1511 15.9784 30.4969 16.6326 30.4969 17.4395C30.4969 18.2464 31.1511 18.9005 31.958 18.9005Z"
      fill="black"
    />
    <path
      d="M38.3572 23.5324C39.1641 23.5324 39.8182 22.8782 39.8182 22.0713C39.8182 21.2644 39.1641 20.6103 38.3572 20.6103C37.5503 20.6103 36.8961 21.2644 36.8961 22.0713C36.8961 22.8782 37.5503 23.5324 38.3572 23.5324Z"
      fill="black"
    />
    <path
      d="M31.5538 20.4771C30.7589 22.9018 33.5789 25.0201 35.6617 23.5147C36.1768 23.1416 35.6617 22.2757 35.1554 22.6531C34.8655 22.878 34.5106 23.0028 34.1437 23.0089C33.7769 23.015 33.418 22.902 33.1208 22.6869C32.8236 22.4718 32.6042 22.1661 32.4954 21.8157C32.3867 21.4653 32.3944 21.0891 32.5175 20.7435C32.7173 20.1307 31.7537 19.8554 31.5538 20.4771Z"
      fill="black"
    />
    <path
      d="M18.262 28.7194L32.6328 38.4361"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M18.3199 37.3082L24.4928 41.2562"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
    />
  </svg>
)

IllustrationIcon.defaultProps = {
  width: "65px",
  height: "65px",
}
