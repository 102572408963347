import React from "react"
import styled from "styled-components"

const Button = styled.button`
  position: relative;
  background-color: inherit;
  text-transform: uppercase;
  color: #4a4a4a;
  border: 1px solid rgba(74, 74, 74, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 0.75rem;
  font-family: Geometria, sans-serif;
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

function OutlinedButton(props) {
  const { children, ...rest } = props

  return <Button {...rest}>{children}</Button>
}

export default OutlinedButton
