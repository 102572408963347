import React from "react"

import { isJuristicBorrower } from "src/utils"
import {
  Box, Flex, Text, Link, Alert, Button, Translate,
} from "src/components"

import { AttachmentLink, Divider } from "./styles"

export const EmptyField = () => (
  <Text lineHeight="1.5" color="red">
    <Translate i18n="components:account.accreditation_info.empty" />
  </Text>
)

const AttachmentBody = ({ value }) => (value ? (
    <AttachmentLink target="_blank" href={value.url}>
      {value.filename}
    </AttachmentLink>
) : (
    <EmptyField />
))

const AttachmentBodyWithLabel = ({ label, value }) => (
  <Flex mb="10px" alignItems="center">
    <Box width="170px" mr="20px">
      <Text
        fontSize="11px"
        lineHeight="1.5"
        color="greyShuttle"
        textTransform="uppercase"
      >
        <Translate i18n={label} />
      </Text>
    </Box>
    {value ? (
      <AttachmentLink target="_blank" href={value.url}>
        {value.filename}
      </AttachmentLink>
    ) : (
      <EmptyField />
    )}
  </Flex>
)

export const AttachmentField = React.memo(({ label, value }: any) => (
  <Box>
    {label ? (
      <AttachmentBodyWithLabel label={label} value={value} />
    ) : (
      <AttachmentBody value={value} />
    )}
  </Box>
))

export const TextField = React.memo(({ label, value }: any) => (
  <Flex mb="10px" alignItems="center">
    <Box width="170px" mr="20px">
      <Text
        fontSize="11px"
        lineHeight="1.5"
        color="greyShuttle"
        textTransform="uppercase"
      >
        <Translate i18n={label} />
      </Text>
    </Box>
    {value ? (
      <Text lineHeight="1.5" color="greyShuttle">
        <Translate i18n={value} />
      </Text>
    ) : (
      <EmptyField />
    )}
  </Flex>
))

export const ModerationAlert = () => (
  <Box mb="40px">
    <Alert
      bg="#fffdfa"
      border="1px solid"
      borderColor="rgba(255, 143, 0, 0.25)"
    >
      <Text fontSize="14px">
        <Translate i18n="components:account.accreditation_info.alerts.pending" />
      </Text>
    </Alert>
  </Box>
)

export const DeclinedAlert = ({ reason }) => (
  <Box mb="40px">
    <Alert
      bg="greyLighter"
      border="1px solid"
      borderColor="rgba(226, 37, 28, 0.25)"
    >
      <Text fontSize="14px">
        <Translate i18n="components:account.accreditation_info.alerts.declined" />
      </Text>
      <Text fontSize="14px" whiteSpace="pre-line">
        {reason}
      </Text>
    </Alert>
  </Box>
)

export const Group = React.memo(({ item: { title, fields } }: any) => (
  <Box mb="35px">
    <Box mb="20px">
      <Text fontWeight="500" lineHeight="1.75">
        <Translate i18n={title} />
      </Text>
    </Box>
    {fields.map((field, idx) => (field.type === "text" ? (
        <TextField key={idx} {...field} />
    ) : (
        <AttachmentField key={idx} {...field} />
    )))}
  </Box>
))

export const EditControls = React.memo(({ path }: any) => (
  <>
    <Box m="45px -30px 30px -30px">
      <Divider />
    </Box>
    <Link to={path}>
      <Button height="48px" variant="default">
        <Translate i18n="components:account.accreditation_info.buttons.edit" />
      </Button>
    </Link>
  </>
))

export const UpdateProfileButton = React.memo((props: any) => {
  const onClick = React.useCallback(
    () => props.history.push(`/profiles/${props.profile.id}/update`),
    [],
  )

  if (!isJuristicBorrower(props.profile)) return null

  return (
    <Box mb="20px" is={Button} onClick={onClick} variant="default">
      <Translate i18n="components:account.accreditation_info.buttons.update" />
    </Box>
  )
})
