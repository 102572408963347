import React from "react"

import { QueryParamsConsumer } from "src/context"
import { Translate } from "src/components"

import ListBuilder from "./ListBuilder"
import Table from "./Table"
import { borrowerRating, collateralRating } from "./schemas"

import { MainTitle, TextBlock, ReturnButton } from "./styles"

function ScoringInfo(props) {
  function getReturnPath() {
    const { getQueryParam } = props
    const source = getQueryParam("source")

    if (source) return `/market/${source}`

    return "/market"
  }

  return (
    <>
      <ReturnButton to={getReturnPath()}>{"< Назад"}</ReturnButton>

      <MainTitle>
        <Translate i18n="components:scoring_info.title" />
      </MainTitle>
      <TextBlock>
        <Translate i18n="components:scoring_info.content" />
      </TextBlock>

      <TextBlock py={20}>
        <Translate i18n="components:scoring_info.blocks.0" />
      </TextBlock>
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.0" />
      </TextBlock>

      <TextBlock py={20}>
        <Translate i18n="components:scoring_info.blocks.1" />
      </TextBlock>
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.1" />
      </TextBlock>

      <TextBlock py={20}>
        <Translate i18n="components:scoring_info.blocks.2" />
      </TextBlock>
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.2" />
      </TextBlock>

      <TextBlock py={20}>
        <Translate i18n="components:scoring_info.blocks.3" />
      </TextBlock>
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.3" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.borrower.contents.4" />
      </TextBlock>

      <TextBlock py={20}>
        <Translate i18n="components:scoring_info.blocks.4" />
      </TextBlock>
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.5" />
      </TextBlock>
      <ListBuilder schema={borrowerRating} />
      <TextBlock>
        <Translate i18n="components:scoring_info.borrower.contents.6" />
      </TextBlock>

      <TextBlock pt={30}>
        <Translate i18n="components:scoring_info.collateral.contents.0" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.1" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.2" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.3" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.4" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.5" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.6" />
      </TextBlock>
      <ListBuilder schema={collateralRating} />
      <TextBlock>
        <Translate i18n="components:scoring_info.collateral.contents.7" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.8" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.9" />
      </TextBlock>

      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.blocks.5" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.10" />
      </TextBlock>
      <Table />
      <TextBlock>
        <Translate i18n="components:scoring_info.collateral.contents.11" />
      </TextBlock>
      <TextBlock pt={20}>
        <Translate i18n="components:scoring_info.collateral.contents.12" />
      </TextBlock>
    </>
  )
}

function Render(props) {
  return (
    <QueryParamsConsumer>
      {({ getQueryParam }) => (
        <ScoringInfo {...props} getQueryParam={getQueryParam} />
      )}
    </QueryParamsConsumer>
  )
}

export default Render
