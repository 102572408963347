import React from "react"

import { StatusBar } from "src/components"
import { ElementHoverProvider } from "src/context"

import { Container, StatusBarWrapper } from "./styles"

import TargetValues from "./TargetValues"
import CessionMessage from "./CessionMessage"

import {
  isOrderCompleted,
  getMinValuePercents,
  getGatheredPercents,
  shouldRenderLoanAmount,
} from "./utils"

class LoanAmount extends React.PureComponent<any> {
  state = {
    hover: false,
    freeze: false,
  }

  onMouseEnterHandler = () => {
    if (this.state.freeze) return

    this.setState(() => ({ hover: true }))
  }

  onMouseLeaveHandler = () => {
    if (this.state.freeze) return

    this.setState(() => ({ hover: false }))
  }

  freeze = () => {
    this.setState(() => ({ freeze: true }))
  }

  unFreeze = () => {
    this.setState(() => ({ freeze: false }))
  }

  hide = () => {
    this.setState(() => ({ hover: false }))
  }

  show = () => {
    this.setState(() => ({ hover: true }))
  }

  contextApi = {
    hide: this.hide,
    show: this.show,
    freeze: this.freeze,
    unfreeze: this.unFreeze,
  }

  render() {
    const {
      chain, status, cession, orderType, application,
    } = this.props

    const gatheredPercents = getGatheredPercents(chain, application, status)
    const minValuePercents = getMinValuePercents(application)
    const isActiveCession = !!cession && cession.isActive
    const isGuestMarket = orderType === "GuestOrder"

    return shouldRenderLoanAmount(chain, application) ? (
      <Container
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
      >
        {isOrderCompleted(status) && isActiveCession ? (
          <CessionMessage originalChainId={cession.originalChainId} />
        ) : (
          <TargetValues guest={isGuestMarket} />
        )}
        <StatusBarWrapper>
          <ElementHoverProvider
            value={{
              isHovered: this.state.hover,
              ...this.contextApi,
            }}
          >
            <StatusBar
              gatheredPercents={gatheredPercents}
              minValuePercents={minValuePercents}
            />
          </ElementHoverProvider>
        </StatusBarWrapper>
      </Container>
    ) : null
  }
}

export default LoanAmount
