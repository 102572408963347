import React from "react"
import Relay, { graphql } from "react-relay"

import InputBlock from "./inputBlock"
import { Container } from "./styles"

function ReferralForm(props) {
  const { link = "" } = props?.referralLink?.refer

  const referralUrl = `https://wow.karma.red/?utm_refer=${link}`

  /* TODO: unused - вернуть позже или удалить окончательно
  const [email, setEmail] = useState("")
   function handleChange(event) {
    const { value } = event.target
    setEmail(value)
  }

  function handleClick() {} */

  const inputRef = React.createRef<HTMLInputElement>()
  function handleCopy() {
    inputRef.current.select()
    window.document.execCommand("copy")
  }

  return (
    <Container>
      {/* <InputBlock */}
      {/*  label="Пригласить по почте" */}
      {/*  type="email" */}
      {/*  placeholder="Введите email" */}
      {/*  btn="Отправить" */}
      {/*  value={email} */}
      {/*  disabledBtn={!email} */}
      {/*  onChange={handleChange} */}
      {/*  onClick={handleClick} */}
      {/* /> */}

      {/* <Text>Или</Text> */}

      <InputBlock
        label="Поделиться ссылкой"
        readOnly
        value={referralUrl}
        btn="Скопировать"
        ref={inputRef}
        onClick={handleCopy}
      />
    </Container>
  )
}

export default Relay.createFragmentContainer(ReferralForm, {
  referralLink: graphql`
    fragment ReferralForm_referralLink on UserProfile {
      refer {
        link
      }
    }
  `,
})
