import React from "react"

import { Tooltip, RatingGlyph } from "src/components"

const DeclineReasonWithTooltip = (props) => {
  const { moderation } = props
  const { status, declineReason } = moderation

  return status === "DECLINED" ? (
    <Tooltip
      borderColor="grey"
      borderWidth="2px"
      text={declineReason}
      glyph={() => <RatingGlyph transform="translateY(2px)" />}
    />
  ) : null
}

export default DeclineReasonWithTooltip
