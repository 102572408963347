import styled, { css } from "styled-components"

export const OptionsContainer = styled.div<any>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  border-radius: 4px;
  z-index: 10;
  box-shadow: 0 0 24px 0 rgba(217, 222, 226, 0.76);
  background-color: ${({ theme }) => theme.colors.white};
  ${({ shouldDisplay }) => {
    if (shouldDisplay) {
      return "opacity: 1; visibility: visible;"
    }
    return "opacity: 0; visibility: hidden; transition: all .3s ease-in-out;"
  }}
`

export const InputWrapper = styled.div`
  position: relative;
`

const activeStyles = css`
  background-color: ${({ active, theme }: any) => {
    if (active) {
      return theme.colors.catskillWhite
    }
    return theme.colors.white
  }};
`

const hoverStyles = css`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.catskillWhite};
`

export const Option = styled.div<any>`
  font-size: 16px;
  line-height: 56px;
  padding: 0 23px;
  width: 100%;
  height: 56px;
  ${activeStyles}
  &:hover {
    ${hoverStyles}
  }
`

export const GotNoSuggestionsContainer = styled.div`
  padding: 18px 0 18px 23px;
`
