import styled, { css } from "styled-components"

import { Flex, Icons } from "src/components"

export const Container = styled(Flex)`
  position: relative;
  flex-direction: column;
`

export const ReturnButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  padding: 4px 4px 4px 0;
  font-family: Geometria, sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: left;
  width: 70px;
  margin-top: -32px;

  @media screen and (max-width: 1280px) {
    margin-top: 0;
  }

  @media screen and (max-width: 480px) {
    color: ${({ theme }) => theme.colors.primaryBlack};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
`

export const ReturnIcon = styled(Icons.Arrow)`
  fill: ${({ theme }) => theme.colors.primaryBlack};
`

export const TitleContainer = styled(Flex)`
  margin-top: 60px;
`

export const TextContainer = styled(Flex)`
  flex-direction: column;
`

export const Title = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.primaryBlacks[9]};
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 21px;
    line-height: 26px;
  }
`

export const Description = styled(Title)`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  max-width: 700px;
  color: ${({ theme }) => theme.colors.primaryBlacks[8]};

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const Illustration = styled(Icons.Asian)`
  margin-right: 24px;

  @media screen and (max-width: 480px) {
    width: 44px;
    height: 44px;
  }
`

export const DealsContainer = styled(Flex)`
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;

  @media screen and (max-width: 480px) {
    justify-content: flex-start;
  }
`

export const DealsTitle = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 40px 0 0;

  @media screen and (max-width: 480px) {
    margin-right: 30px;
  }
`

export const DealsAmount = styled.div<any>`
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  font-family: Geometria, sans-serif;
  color: ${({ theme }) => theme.colors.primaryBlacks[6]};
  padding-top: 2px;

  :not(:last-child) {
    margin-right: 40px;

    @media screen and (max-width: 480px) {
      margin-right: 20px;
    }
  }

  ${({ isActive }) => isActive
    && css`
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primaryBlacks[9]};
      background: #efeeee;
    `};
`
