import React from "react"
import styled from "styled-components"
import { useMediaPredicate } from "react-media-hook"

const DesktopWrapper = styled.div``

const DesktopAggregator = styled.div`
  height: 144px;
  width: 100%;
`

export const AccountLinkContainer = (props) => {
  const mobile = useMediaPredicate("(max-width: 900px)")

  return !mobile && <DesktopWrapper {...props}/>
}

export const ExitWrap = (props) => {
  const mobile = useMediaPredicate("(max-width: 900px)")

  return !mobile && <DesktopWrapper {...props}/>
}

export const Aggregator = (props) => {
  const mobile = useMediaPredicate("(max-width: 1280px)")

  return !mobile && <DesktopAggregator {...props}/>
}
