import React from "react"
import Input from "../Input"
import List from "./SortableList"

const render = (props) => {
  const {
    onMove, onRemove, attachments, ...rest
  } = props

  return (
    <React.Fragment>
      <Input {...rest} />
      <List onMove={onMove} attachments={attachments} onRemove={onRemove} />
    </React.Fragment>
  )
}

export default render
