import React from "react"

import { Box } from "src/components"

import {
  StyledLink,
  DocumentListWrapper,
  DocumentListItemWrapper,
} from "./styles"

const getFileName = (url) => {
  const path = url.split("/")
  return path[path.length - 1]
}

const Document = (props) => {
  const { attachment, description } = props

  const filename = description || getFileName(attachment.url)

  return (
    <DocumentListItemWrapper>
      <StyledLink href={attachment.url} target="_blank">
        {filename}
      </StyledLink>
    </DocumentListItemWrapper>
  )
}

export default (props) => {
  const { documents } = props

  return documents && documents.length ? (
    <Box>
      <DocumentListWrapper>
        {documents.map((document, ii) => (
          <Document {...document} key={document.description + ii} />
        ))}
      </DocumentListWrapper>
    </Box>
  ) : null
}
