import React, { useState, useEffect, useContext } from "react"

import { FormErrorsContext } from "src/context"
import {
  Box, Text, Translate, ErrorForField,
} from "src/components"
import { KYCDocument } from "src/constants"

import InfoLinkWrapper from "./styles"
import RadioGroup from "../RadioGroup"
import Attachments from "../Attachments"
import KYCDocumentsOptions from "./constants"

import infoModalContent from "./infoModalContent"

import { InfoLink, ErrorElement, ErrorContainer } from "../Shared"

const useKYCState = (props) => {
  const { value } = props

  const { type: injectedType, attachments: injectedAttachments } = value[0] || KYCDocument

  const [type, setType] = useState(injectedType)
  const [attachments, setAttachments] = useState(injectedAttachments)

  return {
    type,
    setType,
    attachments,
    setAttachments,
  }
}

const getError = (errors, contextErrorGetter) => {
  if (errors && errors.length > 0) {
    return (
      <ErrorContainer>
        <ErrorElement>
          {ErrorForField(contextErrorGetter(errors[0].path), true)}
        </ErrorElement>
      </ErrorContainer>
    )
  }

  return null
}

const KYCDocuments = (props) => {
  const { errors } = props

  const {
    getError: contextErrorGetter,
    clearError: contextErrorCleaner,
  } = useContext(FormErrorsContext)

  const {
    type, setType, attachments, setAttachments,
  } = useKYCState(props)

  useEffect(() => {
    const nextValue = [
      {
        type,
        attachments,
      },
    ]

    props.onChange({
      name: props.name,
      value: nextValue,
    })
  }, [type, attachments])

  const clearError = () => {
    if (!errors || errors.length === 0) return
    contextErrorCleaner(errors[0].path)
  }

  const onTypeChange = (e) => {
    const { value } = e
    setType(value)
  }

  const onAttachmentChange = (e) => {
    const { value } = e
    setAttachments(value)
    clearError()
  }

  const Error = () => getError(errors, contextErrorGetter)

  return (
    <React.Fragment>
      <InfoLinkWrapper>
        <InfoLink
          label="components:international_profile_form.extras.KYC_document"
          content={infoModalContent}
        />
        <Text color="greyDark" lineHeight="1.5">
          <Translate i18n="components:international_profile_form.labels.KYC_document.info" />
        </Text>
      </InfoLinkWrapper>
      <RadioGroup
        name="type"
        value={type}
        options={KYCDocumentsOptions}
        onChange={onTypeChange}
      />
      <React.Fragment>
        <Attachments value={attachments} onChange={onAttachmentChange} />
        <Box mt="15px">
          <Text color="greyDark" lineHeight="1.5">
            <Translate i18n="components:international_profile_form.labels.KYC_document.format" />
          </Text>
        </Box>
        <Error />
      </React.Fragment>
    </React.Fragment>
  )
}

export default KYCDocuments
