import React from "react"
import { Route } from "react-router"

import {
  Box, Text, Flex, Button, Translate,
} from "src/components"

import {
  ProfilesWrapper,
  AccreditedProfile,
  UnAccreditedProfile,
} from "./styles"

export const AccreditedProfiles = (props) => {
  const { profiles, onChange, checkedProfile } = props

  const onValueChange = (event) => {
    const { value } = event.target

    onChange(value)
  }

  return (
    <React.Fragment>
      <AccreditedLegend />
      <ProfilesWrapper>
        {profiles.length ? (
          profiles.map(({ name, __typename: type, id }, index) => (
            <AccreditedProfile key={index}>
              <input
                value={id}
                name={name}
                type="checkbox"
                onChange={onValueChange}
                checked={checkedProfile === id}
              />
              <div>
                <Text fontSize={13} color="greyDark">
                  <Translate
                    i18n={`application.profile_type.${type}`}
                    ns="components"
                  />
                </Text>
                <Text>{name}</Text>
              </div>
            </AccreditedProfile>
          ))
        ) : (
          <Text color="greyDark">
            <Translate
              i18n="application.empty_available_profiles"
              ns="components"
            />
          </Text>
        )}
      </ProfilesWrapper>
    </React.Fragment>
  )
}

export const AccreditedLegend = () => (
  <Box mr={42} width="220px">
    <Text fontSize={13} color="greyDark" textTransform="uppercase">
      <Translate
        i18n="application.borrower.accredited_legend.title"
        ns="components"
      />
    </Text>
    <Box pt={14}>
      <Text fontSize={12} color="greyDark" whiteSpace="pre-line">
        <Translate
          i18n="application.borrower.accredited_legend.body"
          ns="components"
        />
      </Text>
    </Box>
  </Box>
)

export const UnAccreditedLegend = () => (
  <Box mr={42} width="220px">
    <Text fontSize={13} color="greyDark" textTransform="uppercase">
      <Translate
        i18n="application.borrower.unaccredited_legend.title"
        ns="components"
      />
    </Text>
    <Box pt={14}>
      <Text fontSize={12} color="greyDark" whiteSpace="pre-line">
        <Translate
          i18n="application.borrower.unaccredited_legend.body"
          ns="components"
        />
      </Text>
    </Box>
    <Box pt={18}>
      <Text fontSize={12} color="greyDark" whiteSpace="pre-line">
        <Translate
          i18n="application.borrower.unaccredited_legend.gutter"
          ns="components"
        />
      </Text>
    </Box>
  </Box>
)

const onAccreditationClick = (history) => (id) => () => {
  history.push(`/profiles/${id}/accreditation/borrower`)
}

export const UnAccreditedProfiles = ({ profiles }) => (
  <Route>
    {({ history }) => (profiles.length ? (
        <React.Fragment>
          <UnAccreditedLegend />
          <ProfilesWrapper>
            {profiles.map((profile, index) => {
              const { id, name: profileName, __typename: type } = profile

              return (
                <UnAccreditedProfile key={index}>
                  <Flex
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Text fontSize={13} color="greyDark">
                      <Translate
                        i18n={`application.profile_type.${type}`}
                        ns="components"
                      />
                    </Text>
                    <Text color="greyDark" fontSize={15}>
                      {profileName}
                    </Text>
                  </Flex>
                  <Button
                    variant="defaultBlue"
                    onClick={onAccreditationClick(history)(id)}
                  >
                    <Translate
                      i18n="account.role_item.buttons.bring_in_data"
                      ns="components"
                    />
                  </Button>
                </UnAccreditedProfile>
              )
            })}
          </ProfilesWrapper>
        </React.Fragment>
    ) : null)
    }
  </Route>
)
