import styled from "styled-components"

export const SocialListWrapper = styled.div`
  display: flex;
`
export const IconContainer = styled.a`
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
`
