import React from "react"
import styled from "styled-components"

import {
  flex,
  color,
  order,
  space,
  width,
  height,
  display,
  borders,
  fontSize,
  alignSelf,
  lineHeight,
  borderColor,
  borderRadius,
  maxWidth,
  minWidth,
  position,
  justifyContent,
  overflow,
} from "styled-system"

const Box = styled.div`
  ${maxWidth}
  ${minWidth}
  ${display}
  ${flex}
  ${color}
  ${order}
  ${space}
  ${width}
  ${height}
  ${borders}
  ${fontSize}
  ${alignSelf}
  ${lineHeight}
  ${borderColor}
  ${borderRadius}
  ${position}
  ${justifyContent}
  ${overflow}
`

const render = ({ is, ...props }: any) => {
  const Component = is ? Box.withComponent(is) : Box
  return <Component {...props} />
}

export default render
