import styled from "styled-components"

export const Container = styled.div`
  font-family: "Geometria";
  color: rgba(74, 74, 74, 0.9);
  line-height: 20px;
  padding-top: 70px;
  @media (max-width: 480px) {
    padding-top: 50px;
  }
`

export const Paragraph = styled.p`
  white-space: pre-wrap;
  line-height: 1.6rem;
`

export const Headline = styled.h2`
  color: #4a4a4a;
  font-size: 26px;
  line-height: 33px;
  font-weight: 600;
`
