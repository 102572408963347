import { useEffect } from "react"

const useHotKey = (key, callback) => {
  const documentEventHandler = (e) => {
    if (e) {
      e.stopPropagation()
    }

    const { keyCode } = e

    if (key === keyCode) callback(e)
  }

  useEffect(() => {
    document.addEventListener("keydown", documentEventHandler)

    return () => {
      document.removeEventListener("keydown", documentEventHandler)
    }
  })
}

export default useHotKey
