import React from "react"

import {
  OrderTypeConsumer,
  ViewerDataConsumer,
  OrderPositionConsumer,
} from "src/context"

const ComposedConsumer = (props) => (
  <ViewerDataConsumer>
    {(viewer) => (
      <OrderTypeConsumer>
        {(orderType) => (
          <OrderPositionConsumer>
            {(orderPosition) => props.children({
              viewer,
              orderType,
              orderPosition,
            })
            }
          </OrderPositionConsumer>
        )}
      </OrderTypeConsumer>
    )}
  </ViewerDataConsumer>
)

export default ComposedConsumer
