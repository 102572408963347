import { useState, useEffect, useContext } from "react"

import { fetchQuery } from "relay-runtime"

import { LocaleContext, RelayEnvironmentContext } from "src/context"

import countriesQuery from "./countriesQuery"

const useOptions = () => {
  const { environment } = useContext(RelayEnvironmentContext)
  const { locale: globalLocale } = useContext(LocaleContext)

  const [isLoading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const handleLocales = ({ countries }) => {
    setLoading(false)
    setOptions(countries)
  }

  useEffect(() => {
    setOptions([])
    setLoading(true)

    fetchQuery(environment, countriesQuery, {
      locale: globalLocale.toUpperCase(),
    }).then(handleLocales)
  }, [globalLocale])

  return {
    isLoading,
    options: options.map((item, index) => ({ ...item, index })),
  }
}

export default useOptions
