const statusColors = {
  INITIAL: "alabaster",
  PENDING: "romance",
  TRANSFER: "romance",
  DECLINED: "roseWhite",
  APPROVED: "blueBright",
  CONFIRMED: "greenLightest",
  COMPLETE: "greenLightest",
  SUCCEEDED: "greenLightest",
  DEAD: "roseWhite",
}

export { statusColors }
