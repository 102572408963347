import React from "react"

import { Label, Address, Translate } from "src/components"
import { cloneDeep } from "src/utils"

import Purpose from "./Purpose"
import Tenure from "./Tenure"
import Area from "./Area"

class RoomItem extends React.PureComponent<any, any> {
  onSelectChange = ({ name, value }) => {
    const roomItemData = {
      ...cloneDeep(this.props.value),
      [name]: value,
    }

    this.props.onChange({
      value: roomItemData,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Purpose
          value={this.props.value.purpose}
          onChange={this.onSelectChange}
          name="purpose"
          index={this.props.index}
        />
        <Tenure
          value={this.props.value.tenure}
          onChange={this.onSelectChange}
          name="tenure"
          index={this.props.index}
        />
        <Address
          name="address"
          value={this.props.value.address}
          onChange={this.onSelectChange}
        >
          <Label whiteSpace="pre-line">
            <Translate
              i18n="application.worksheet.rows.address.label"
              ns="components"
            />
          </Label>
        </Address>
        <Area
          value={this.props.value.area}
          onChange={this.onSelectChange}
          name="area"
        />
      </React.Fragment>
    )
  }
}

export default RoomItem
