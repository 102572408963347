import React from "react"

import ValidDateField from "./ValidDate"
import LicenseDateField from "./LicenseDate"
import LicenseNameField from "./LicenseName"
import LicenseNumberField from "./LicenseNumber"
import LicenseApproveField from "./LicenseApprove"

export class LicenseItem extends React.PureComponent<any, any> {
  onSelectChange = ({ name, value }) => {
    const license = { ...this.props.value }
    license[name] = value
    this.forceChange(license)
  }

  forceChange = (license) => {
    this.props.onChange({
      value: license,
    })
  }

  render() {
    /* eslint-disable camelcase */
    const {
      license,
      valid_date,
      license_date,
      license_name,
      license_number,
    } = this.props.value

    return (
      <React.Fragment>
        <LicenseApproveField
          name="license"
          id={this.props.id}
          onChange={this.onSelectChange}
          value={license}
          checked={license}
        />
        <LicenseNameField
          name="license_name"
          onChange={this.onSelectChange}
          value={license_name}
        />
        <LicenseNumberField
          name="license_number"
          onChange={this.onSelectChange}
          value={license_number}
        />
        <LicenseDateField
          name="license_date"
          onChange={this.onSelectChange}
          value={license_date}
        />
        <ValidDateField
          name="valid_date"
          onChange={this.onSelectChange}
          value={valid_date}
        />
      </React.Fragment>
    )
  }
}

export default LicenseItem
