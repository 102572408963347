import React from "react"

/* eslint-disable max-len */
const InvestedByKarmaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    viewBox="0 0 18 22"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#E2251C"
        d="M16.124 21.366L9.019 17.49a.79.79 0 0 0-.752 0l-7.105 3.876A.786.786 0 0 1 0 20.676V.786C0 .352.352 0 .786 0H16.5c.434 0 .786.352.786.786v19.89a.786.786 0 0 1-1.162.69"
      />
      <path
        fill="#FFF"
        d="M5.642 6.126v7.54h2.48v-1.637l.652-.767 1.427 2.403h2.956l-2.604-4.294 2.604-3.245h-2.93l-2.08 2.811v-2.81z"
      />
    </g>
  </svg>
)

export default InvestedByKarmaIcon
