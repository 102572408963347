import React from "react"

import { Box, Text, Translate } from "src/components"

import { AccountItem, ItemsContainer, HeaderContainer } from "./styles"

const getAccountDataFromProps = ({ email, profiles }) => [
  {
    label: () => (
      <Translate i18n="account.header.labels.account" ns="components" />
    ),
    data: email,
  },
  {
    label: () => (
      <Translate i18n="account.header.labels.profiles" ns="components" />
    ),
    data: profiles.length,
  },
]

const HeaderItem = ({ viewer }: any) => (
  <>
    {viewer
      && getAccountDataFromProps(viewer).map(({ label, data }, index) => (
        <AccountItem key={index}>
          <Text
            fontSize={12}
            whiteSpace="nowrap"
            textTransform="uppercase"
            color="greyDark"
          >
            {label()}
          </Text>
          <Box pt={10}>
            <Text fontSize={14}>{data}</Text>
          </Box>
        </AccountItem>
      ))}
  </>
)

export default (props) => (
  <HeaderContainer alignItems="flex-start">
    <ItemsContainer>
      <HeaderItem viewer={props.viewer || null} />
    </ItemsContainer>
    {props.children}
  </HeaderContainer>
)
