import React from "react"

import {
  Box, Text, Modal, Translate, DefaultModal,
} from "src/components"

import Form from "./Form"
import Success from "./Success"
import { ModalHeader } from "./elements"
import { ModalContainer } from "./styles"

const DecoratedForm = ({ target, ...props }) => (
  <>
    <Box my="25px">
      <Text lineHeight="1.5">
        <Translate i18n={`modals:express-scoring.form.legend.${target}`} />
      </Text>
    </Box>
    <Form {...props} />
  </>
)

const ExpressScoring = (props) => {
  const [completed, setCompleted] = React.useState(false)
  const onComplete = React.useCallback(() => {
    setCompleted(true)
    if (typeof props.onCompleted === "function") props.onCompleted()
  }, [])

  return (
    <Modal>
      <DefaultModal
        padding="0"
        type="centered"
        onClose={props.onClose}
        heightRestriction={false}
        closeButtonVariant="empty"
      >
        <ModalContainer>
          {completed ? (
            <Success />
          ) : (
            <>
              <ModalHeader i18n="modals:express-scoring.title" />
              <DecoratedForm onCompleted={onComplete} target={props.target} />
            </>
          )}
        </ModalContainer>
      </DefaultModal>
    </Modal>
  )
}

ExpressScoring.defaultProps = {
  target: "order",
}

export default ExpressScoring
