import React from "react"

import {
  Box, Text, Flex, Button, Translate,
} from "src/components"

import { StyledText } from "./styles"

const render = ({ onComplete }) => (
  <Box width="420px" m="auto">
    <Box mb="10px">
      <Text
        fontSize="24px"
        fontWeight="500"
        lineHeight="1.25"
        textAlign="center"
      >
        <Translate i18n="components:investment_modals.error.title" />
      </Text>
    </Box>
    <Box mb="40px">
      <StyledText textAlign="center">
        <Translate i18n="components:investment_modals.error.content" />
      </StyledText>
    </Box>
    <Flex justifyContent="center">
      <Button height="48px" variant="blueWide" onClick={onComplete}>
        <Translate i18n="components:investment_modals.error.button" />
      </Button>
    </Flex>
  </Box>
)

export default render
