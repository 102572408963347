import React from "react"

import { MiniLoader } from "src/components"
import { getOrderTicker, toCurrencyFormat } from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardBoldValue,
  CardStatus,
  CardStatusValue,
  CardButton,
  CardButtonsWrapper,
  Graph,
  CardCover,
} from "./styles"
import { CalendarIcon } from "./icons"

import {
  getOrderName,
  getOrderRate,
  getPaidLoan,
  getPaidInterest,
  getOrderDebt,
  getDateNextPayment,
} from "../Tables/utils"
import { statuses } from "../Tables/LoanCompletedTable"

function LoanCompletedCard(props) {
  const { toProposal } = props
  const status = statuses.get(
    props.paymentScheduleList[0] && props.paymentScheduleList[0].info.state,
  ) || {
    text: "Неизвестно",
    color: "grey",
  }

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено тело, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(getPaidLoan(props))}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено %</CardTitle>
        <CardBoldValue>{toCurrencyFormat(getPaidInterest(props))}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Остаток долга, ₽</CardTitle>
        <CardBoldValue>{toCurrencyFormat(getOrderDebt(props))}</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Дата след платежа</CardTitle>
        <CardValue>{getDateNextPayment(props)}</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={status.color}>
          <Graph>{CalendarIcon}</Graph>
          {status.text}
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton onClick={() => toProposal(props)}>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0%</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено тело, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено %</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Остаток долга, ₽</CardTitle>
        <CardBoldValue>0</CardBoldValue>
      </CardData>

      <CardData>
        <CardTitle>Дата след платежа</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color="#6FA84B">
          <Graph>{CalendarIcon}</Graph>
          Выплаты по графику
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

LoanCompletedCard.placeholder = Placeholder

export default LoanCompletedCard
