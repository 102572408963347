import styled from "styled-components"
import { space, fontWeight } from "styled-system"

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 96px;
  font-family: Geometria;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    margin-top: 76px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 35px;
  }
`

export const MainTitle = styled.h3`
  font-size: 36px;
  line-height: 45px;
  font-weight: bold;
  //margin-top: 100px; //TODO: вернуть после добавления графиков выручки
  //
  //@media screen and (max-width: 800px) {
  //  margin-top: 70px;
  //}
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

export const VideoContainer = styled.div`
  position: relative;
  flex-grow: 1;
  max-width: 55%;
  width: 100%;
  margin-right: 8%;
  margin-bottom: 100px; //TODO: Убрать margin-bottom после добавления графиков выручки

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-bottom: 70px;
  }
`

export const ChartsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 2;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    margin-top: 32px;
  }
`

export const DescriptionContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 65%;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`

export const Text = styled.div`
  position: relative;
  margin-top: 28px;
  width: 100%;

  @media screen and (max-width: 800px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 24px;
  }
`

export const TextTitle = styled.h4`
  font-size: 26px;
  line-height: 33px;
  font-weight: 600;
`

export const TextContent = styled.div`
  font-size: 16px;
  line-height: 1.6em;
  margin-top: 8px;
  color: rgba(74, 74, 74, 0.9);
  white-space: pre-wrap;

  p {
    margin: 0;
  }

  div {
    min-height: 24px;
  }
`

export const AttachmentsContainer = styled.div`
  position: relative;
  margin-top: 40px;
  width: 100%;
`

export const Attachment = styled.a`
  line-height: 1.6em;
  display: block;
  font-family: Geometria;
  font-size: 14px;
  color: #518ef8;
  text-decoration: none;
`

export const CardsContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 33%;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    margin-top: 32px;
  }
`

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 24px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`

export const CardTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  margin: 0;

  @media screen and (max-width: 800px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`

export const CardText = styled.p<any>`
  font-size: 14px;
  margin: 0;
  margin-top: 8px;
  ${space};
  ${fontWeight};

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`

export const CardDescription = styled.p`
  font-size: 14px;
  margin: 0;
  margin-top: 4px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`

export const VideoFrame = styled.iframe`
  width: 100%;
  height: 360px;

  @media screen and (max-width: 800px) {
    height: 435px;
  }

  @media screen and (max-width: 480px) {
    height: 260px;
  }
`

export const CardAvatar = styled.img`
  object-fit: cover;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin-right: 20px;

  @media screen and (max-width: 800px) {
    width: 65px;
    height: 65px;
  }

  @media screen and (max-width: 480px) {
    width: 74px;
    height: 74px;
  }
`

export const Link = styled.a`
  white-space: nowrap;
  text-decoration: none;
  margin-left: 25px;
  color: ${({ theme }) => theme.colors.textBlack};
  :hover {
    border-bottom: 0.5px solid #4a4a4a;
  }
`

export const CardRow = styled.li`
  position: relative;
  list-style: none;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
  margin-top: 6px;

  :first-of-type {
    margin-top: 20px;
  }

  svg {
    position: absolute;
  }
`
