import React from "react"

import { Box, Text, Translate } from "src/components"

const PaidOff = () => (
  <Box>
    <Text fontSize={16} color="green">
      <Translate
        ns="components"
        i18n="account.order_item.paidoff_status.header"
      />
    </Text>
  </Box>
)

export default PaidOff
