/* eslint-disable no-param-reassign */
import React from "react"
import { Icon, CheckBox, Label } from "./style"

const render = ({ children, ...props }) => (
  <Label>
    <CheckBox type="checkbox" {...props} />
    <Icon {...props} />
    {children}
  </Label>
)

export default render
