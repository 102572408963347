import styled from "styled-components"

import { Box, Text } from "src/components"

export const IconContainer = styled(Box)`
  position: relative;
  width: 45px;
  height: 45px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledSVG = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

export const Subtitle = styled(Text)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
`

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`

export const StyledImage = styled.img`
  max-width: 100%;
`

export const BackgroundMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  visibility: hidden;
  background-color: ${({ theme }) => theme.colors.blacks[5]};
`

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  max-width: 280px;

  &:hover {
    & ${BackgroundMask} {
      visibility: visible;
    }
  }
`

export const LoopContainer = styled.div`
  margin: auto;
  width: 32px;
`
