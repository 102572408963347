import React from "react"

import {
  ZoomAction,
  PreviewItem,
  PreviewImage,
  PreviewContainer,
} from "../styles"

import LoopIcon from "./LoopIcon"

export default ({ images, onClick }) => (
  <PreviewContainer>
    {images.map(({ url }, ii) => (
      <PreviewItem key={ii}>
        <PreviewImage src={url} />
        <ZoomAction onClick={onClick(ii)}>
          <LoopIcon />
        </ZoomAction>
      </PreviewItem>
    ))}
  </PreviewContainer>
)
