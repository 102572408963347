import React from "react"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
`

const Background = styled.div`
  position: fixed;
  height: 56px;
  width: 100%;
  background-color: white;
  z-index: 10;
`

const BannerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 99;
  transition: opacity 0.5s linear;
`

class BannerSlider extends React.Component<any, any> {
  current = 0

  banners = []

  repeater

  static defaultProps = {
    interval: 10000,
  }

  /* eslint-disable class-methods-use-this */
  shouldComponentUpdate() {
    return false
  }
  /* eslint-enable class-methods-use-this */

  componentDidMount() {
    if (!this.hasValidBanners(this.banners)) return

    this.banners[0].style.opacity = "1"

    this.repeater = setInterval(() => {
      const { banners, current } = this
      const next = (current + 1) % banners.length

      banners[current].style.opacity = "0"
      banners[next].style.opacity = "1"

      this.current = next
    }, this.props.interval)
  }

  componentWillUnmount() {
    clearInterval(this.repeater)
  }

  hasValidBanners = (banners) => Array.isArray(banners) && banners.length

  render() {
    this.banners = []
    const { banners } = this.props

    if (!this.hasValidBanners(banners)) return null

    return (
      <Container>
        {banners.map((Banner, idx) => (
          <BannerContainer key={idx} ref={(ref) => this.banners.push(ref)}>
            <Banner />
          </BannerContainer>
        ))}
        <Background />
      </Container>
    )
  }
}

export default BannerSlider
