import React from "react"
import styled from "styled-components"

const CardWrapper = styled.div`
  position: relative;
  height: auto;
  background-color: #ffffff;
  width: auto;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`

export default function (props) {
  const { className, children } = props

  return <CardWrapper className={className}>{children}</CardWrapper>
}
