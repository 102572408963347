import { useCallback } from "react"

import { createChainedFunction } from "src/utils"

import useInput from "./useInput"

function useTokenInput(clearError, errorPath) {
  const token = useInput("")

  token.onChange = useCallback(
    createChainedFunction(() => clearError(errorPath), token.onChange),
    [],
  )

  return token
}

export default useTokenInput
