import React from "react"
import styled from "styled-components"

export const Active = () => (
  <ActiveContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 13">
      <path
        d="M1 1v7.333L5 12"
        fill="none"
        stroke="#ff8f00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </ActiveContainer>
)

export const Gone = () => (
  <GoneContainer>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
      <path
        d="M13 1L4.75 9 1 5.364"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  </GoneContainer>
)

export const Default = () => <DefaultContainer />

const Container = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: relative;
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }
`

const DefaultContainer = styled(Container)`
  border: 2px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.white};
`

const ActiveContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.ginger};
  padding: 4px 3px 8px 7px;
`

const GoneContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.green};
  background-color: ${({ theme }) => theme.colors.green};
`
