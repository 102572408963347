import React from "react"

import { FormBuilder } from "src/components"
import { InvestorProfileSchema } from "src/constants"

const Form = (props) => {
  const { value, onChange } = props

  return (
    <FormBuilder
      value={value}
      onChange={onChange}
      inputsSchemaType="new"
      schema={InvestorProfileSchema}
    />
  )
}

export default Form
