import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UpdateForeignIndividualProfileMutation(
    $input: UpdateForeignIndividualProfileInput!
  ) {
    updateForeignIndividualProfile(input: $input) {
      profile {
        id
        __typename
        ...AccreditationInfoFragment_foreignProfile
      }
    }
  }
`

export default enhance({ mutation })
