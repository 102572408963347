import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query CompletedGuestOrderInfoQuery($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on Order {
        createdAt
        status
        statusFrom
        offer {
          ... on OrderOffer {
            id
            data
          }
        }
        application {
          ... on OrderApplication {
            id
            data
          }
        }
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              creditRating {
                rating
              }
              _avatar {
                url
              }
            }
          }
        }
        confirmedAt
        fee
        chain {
          id
          gatheredAmount
          investorsCount
        }
        creditRating {
          rating
        }
        cession {
          due
          isActive
          interestRate
          borrowerName
          originalChainId
          avatar {
            url
          }
          borrowerRating {
            rating
            ratedAt
          }
        }
        karma {
          conclusion
          isInvestor
        }
        provision {
          items {
            kind
          }
        }
      }
    }
  }
`

const container = (render) => (props) => {
  const { orderId, ...rest } = props

  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
