/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type AddEntrepreneurProfileInput = {
    lastName: string;
    firstName: string;
    middleName?: string | null;
    phone: string;
    userId?: string | null;
};
export type AddEntrepreneurProfileMutationVariables = {
    input: AddEntrepreneurProfileInput;
};
export type AddEntrepreneurProfileMutationResponse = {
    readonly addEntrepreneurProfile: {
        readonly profile: {
            readonly id: string;
            readonly name: string;
            readonly phone: string | null;
            readonly lastName: string;
            readonly firstName: string;
            readonly middleName: string | null;
            readonly accreditation: {
                readonly status: ProfileAccreditationStatus | null;
            };
        };
    };
};
export type AddEntrepreneurProfileMutation = {
    readonly response: AddEntrepreneurProfileMutationResponse;
    readonly variables: AddEntrepreneurProfileMutationVariables;
};



/*
mutation AddEntrepreneurProfileMutation(
  $input: AddEntrepreneurProfileInput!
) {
  addEntrepreneurProfile(input: $input) {
    profile {
      id
      name
      phone
      lastName
      firstName
      middleName
      accreditation(role: INVESTOR) {
        status
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddEntrepreneurProfileInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddEntrepreneurProfilePayload",
    "kind": "LinkedField",
    "name": "addEntrepreneurProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EntrepreneurProfile",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "middleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "role",
                "value": "INVESTOR"
              }
            ],
            "concreteType": "Accreditation",
            "kind": "LinkedField",
            "name": "accreditation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": "accreditation(role:\"INVESTOR\")"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddEntrepreneurProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddEntrepreneurProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AddEntrepreneurProfileMutation",
    "operationKind": "mutation",
    "text": "mutation AddEntrepreneurProfileMutation(\n  $input: AddEntrepreneurProfileInput!\n) {\n  addEntrepreneurProfile(input: $input) {\n    profile {\n      id\n      name\n      phone\n      lastName\n      firstName\n      middleName\n      accreditation(role: INVESTOR) {\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '30e1887ea415d15ba723402bab43e0cc';
export default node;
