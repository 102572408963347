import styled from "styled-components"

import { Icons } from "src/components"

const Flex = styled.div`
  position: relative;
  display: flex;
`

export const Container = styled(Flex)`
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 90px auto 0;
`

export const Illustration = styled(Icons.ClerkDoubt)`
  margin: 0 auto;
`

export const Title = styled.h3`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 45px 0 0;
  text-align: center;

  @media screen and (max-width: 800px) {
    font-size: 28px;
    line-height: 35px;
  }
`

export const Description = styled.p`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
  text-align: center;

  @media screen and (max-width: 800px) {
    margin-top: 20px;
  }
`

export const ButtonLink = styled.a`
  min-width: 160px;
  height: 45px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.mainRed};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin: 40px auto 0;
  padding: 0 16px;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
    height: 55px;
    min-width: 200px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: 335px;
    height: 60px;
    margin-top: 60px;
  }
`

export const TelegramIcon = styled(Icons.Telegram)`
  width: 22px;
  height: 22px;
  margin-right: 16px;

  @media screen and (max-width: 800px) {
    width: 28px;
    height: 28px;
  }
`
