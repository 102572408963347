import styled from "styled-components"

export const Container = styled.div`
  font-family: "Geometria";
  padding-top: 25px;
  max-width: 650px;
  @media (max-width: 800px) {
    padding-top: 10px;
  }
`
export const VideoFrame = styled.iframe`
  width: 455px;
  height: 254px;
  @media screen and (max-width: 480px) {
    width: 440px;
    height: 216px;
  }
`
export const Headline = styled.h3`
  color: #4a4a4a;
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
  margin: 14px 0;
`
export const Description = styled.p`
  color: rgba(74, 74, 74, 0.9);
  font-size: 16px;
  line-height: 20px;
`
export const IconContainer = styled.svg`
  margin-top: 45px;
`
export const Row = styled.li`
  list-style: none;
`
export const Link = styled.a`
  color: #4a4a4a;
  font-size: 14px;
  text-decoration: none;
`
