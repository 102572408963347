import React from "react"
import Relay, { graphql } from "react-relay"

import { getBankAccountsFromForeignProfile } from "src/utils"

import RussianBankAccounts from "./RussianBankAccounts/AccountData"

const Modal = (props) => {
  const { id } = props.profile

  const bankAccounts = getBankAccountsFromForeignProfile(props.profile)

  return <RussianBankAccounts profileId={id} account={bankAccounts[0]} />
}

export default Relay.createFragmentContainer(Modal, {
  profile: graphql`
    fragment BankAccounts_profile on UserProfile {
      id
      __typename
      bankAccounts {
        id
        status
        checkingAccount
        correspondentAccount
        bank {
          bic
          name
        }
      }
    }
  `,
  foreignProfile: graphql`
    fragment BankAccounts_foreignProfile on ForeignIndividualProfile {
      id
      __typename
      foreignBankAccounts: allBankAccounts {
        ... on ForeignBankAccount {
          id
          status
          IBAN
          ownerName
          bank {
            name
            address
            SWIFT
          }
        }
      }
      bankAccounts {
        ... on BankAccount {
          id
          status
          checkingAccount
          correspondentAccount
          bank {
            bic
            name
          }
        }
      }
    }
  `,
})
