import idx from "idx"

import { isJuristicBorrower, isRussianEntrepreneur } from "src/utils"

function buildForm(profile) {
  const { email = "" } = profile.user
  const borrower = profile.borrower || {}
  const {
    business = {},
    address = {},
    actualAddress = {},
    executive = {},
    passport = {},
  } = profile

  const { scans = [] } = passport || {}

  const addressIsMatch = idx(address, (_) => _.raw)
    && address.raw === idx(actualAddress, (_) => _.raw)

  if (isJuristicBorrower(profile)) {
    return [
      {
        title: "Анкетные данные",
        fields: [
          {
            type: "text",
            name: "Наименование",
            value: profile.name,
          },
          {
            type: "text",
            name: "Телефон",
            value: profile.phone,
          },
          {
            type: "text",
            name: "Email",
            value: email,
          },
          {
            type: "text",
            name: "Публичный Email",
            value: borrower.publicEmail,
          },
          {
            type: "text",
            name: "ИНН",
            value: profile.inn,
          },
          {
            type: "text",
            name: "КПП",
            value: profile.kpp,
          },
          {
            type: "text",
            name: "ОГРН",
            value: profile.ogrn,
          },
        ],
      },
      {
        title: "Данные о бизнесе",
        fields: [
          {
            type: "text",
            name: "Орг. Правовая форма",
            value: business?.legalForm,
          },
          {
            type: "text",
            name: "Дата создания",
            value: business?.startedAt,
          },
          {
            type: "text",
            name: "Юр. адрес",
            value: idx(address, (_) => _.raw) || "Нет",
          },
          !addressIsMatch && {
            type: "text",
            name: "Фактический адрес",
            value: idx(actualAddress, (_) => _.raw) || "Нет",
          },
        ],
      },
      {
        title:
          "Фактический адрес организации совпадает с юридическим адресом организации, указанным в ЕГРЮЛ?",
        fields: [
          {
            type: "text",
            name: "Ответ",
            value: addressIsMatch ? "Да" : "Нет",
          },
        ],
      },
      {
        title: "Виды деятельности",
        fields: [
          {
            type: "text",
            name: "Основной",
            value: business?.main,
          },
          {
            type: "text",
            name: "Дополнительный",
            value: business?.aux,
          },
        ],
      },
      {
        title: "Данные руководителя",
        fields: [
          {
            type: "text",
            name: "ФИО",
            value: executive?.name || "",
          },
          {
            type: "text",
            name: "СНИЛС",
            value: profile.iian,
          },
        ],
      },
      {
        title: "Сканы паспорта",
        fields: scans.length
          ? scans.map((item) => ({
            type: "attachment",
            value: item,
          }))
          : [{ type: "attachment" }],
      },
    ]
  }

  if (isRussianEntrepreneur(profile)) {
    return [
      {
        title: "Анкетные данные",
        fields: [
          {
            type: "text",
            name: "Наименование",
            value: profile.name,
          },
          {
            type: "text",
            name: "Телефон",
            value: profile.phone,
          },
          {
            type: "text",
            name: "Email",
            value: email,
          },
          {
            type: "text",
            name: "Публичный Email",
            value: borrower.publicEmail,
          },
          {
            type: "text",
            name: "ИНН",
            value: profile.inn,
          },
          {
            type: "text",
            name: "ОГРНИП",
            value: profile.ogrn,
          },
        ],
      },
      {
        title: "Данные о бизнесе",
        fields: [
          {
            type: "text",
            name: "Юр. адрес",
            value: idx(address, (_) => _.raw) || "Нет",
          },
          !addressIsMatch && {
            type: "text",
            name: "Фактический адрес",
            value: idx(actualAddress, (_) => _.raw) || "Нет",
          },
        ],
      },
      {
        title:
          "Фактический адрес организации совпадает с юридическим адресом организации, указанным в ЕГРЮЛ?",
        fields: [
          {
            type: "text",
            name: "Ответ",
            value: addressIsMatch ? "Да" : "Нет",
          },
        ],
      },
      {
        title: "Данные руководителя",
        fields: [
          {
            type: "text",
            name: "СНИЛС",
            value: profile.iian,
          },
        ],
      },
      {
        title: "Сканы паспорта",
        fields: scans.length
          ? scans.map((item) => ({
            type: "attachment",
            value: item,
          }))
          : [{ type: "attachment" }],
      },
    ]
  }

  return []
}

export default buildForm
