import React from "react"
import Relay, { graphql } from "react-relay"

import { getProperty, number } from "src/utils"
import {
  Box, Text, Alert, Translate,
} from "src/components"

const isValidOrderStatus = (status) => status === "COMPLETE"
const isValidScheduleStatus = (status) => status === "pastdue"

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

const PenaltiesInfo = ({ order }) => {
  const { state, penalty, pastdue_days: pastdueDays } = getProperty(
    order,
    "paymentScheduleList[0].info",
    {},
  )

  const isValidState = isValidOrderStatus(order.status) && isValidScheduleStatus(state)

  if (!isValidState) return null

  return (
    <>
      <Box width="280px" mt="20px">
        <Alert textAlign="left" p="20px 25px" bg="greyLighter">
          <Text fontSize="14px" lineHeight="1.45">
            <Translate
              i18n="modals:debt_payment_requisites.penalties_time"
              count={pastdueDays}
            />
          </Text>
          <Text fontSize="14px" lineHeight="1.45">
            <Translate
              i18n="modals:debt_payment_requisites.penalties_amount"
              amount={number(penalty, options)}
            />
          </Text>
        </Alert>
      </Box>
    </>
  )
}

export default Relay.createFragmentContainer(PenaltiesInfo, {
  order: graphql`
    fragment PenaltiesInfo_order on Order {
      id
      status
      paymentScheduleList {
        info {
          state
          penalty
          pastdue_days
        }
        items {
          date
          state
        }
      }
    }
  `,
})
