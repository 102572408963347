import React from "react"

import { Route } from "react-router"

import {
  Box, Text, Button, FlexBox, Template, Translate,
} from "src/components"

const handleRoute = ({ history, id }) => (event) => {
  if (event) {
    event.preventDefault()
  }
  history.push(`/accounts/${id}`)
}

const ProfileWithAction = ({ name, id }) => (
  <Box px="12px">
    <Box py="4px">
      <Text>{name}</Text>
    </Box>
    <Route>
      {({ history }) => (
        <Button variant="blueWide" onClick={handleRoute({ history, id })}>
          <Translate i18n="components:order_list.loans.rejected.buttons.action" />
        </Button>
      )}
    </Route>
  </Box>
)

const ActionsList = ({ profiles }) => (
  <FlexBox>
    {profiles.map(({ name, id }) => (
      <ProfileWithAction id={id} name={name} key={id} />
    ))}
  </FlexBox>
)

export default (props) => (
  <Template
    layout="empty"
    header="components:order_list.investments.rejected.header"
    image="/images/orderLists/empty/loans.svg"
    description="components:order_list.loans.rejected.description"
    action={<ActionsList {...props} />}
  />
)
