import React from "react"
import update from "immutability-helper"

import { fileManager, preventEventBubbling } from "src/utils"
import { UploadFile } from "src/mutations"
import {
  SortableDnd,
  AttachmentControl,
  RedesignedAttachmentInput,
} from "../Attachment"

const listsTypes = {
  list: AttachmentControl,
  sortableList: SortableDnd,
  redesignedList: RedesignedAttachmentInput,
}

class AttachmentsListInput extends React.PureComponent<any, any> {
  state = {
    list: this.props.value.attachments,
    isLoading: false,
    busy: false,
  }

  applyAttachments = (list, ids) => {
    if (this.props.multiple) {
      return list.concat(ids)
    }

    return [ids[0]]
  }

  onDrop = (files) => {
    const list = this.state.list ? [...this.state.list] : []

    if (this.state.busy || this.state.isLoading) {
      return
    }

    this.setState(() => ({ busy: true, isLoading: true }))

    fileManager
      .uploadList(this.props.environment, UploadFile, files)
      .then((ids) => this.setState(
        () => ({
          isLoading: false,
          busy: false,
          list: this.applyAttachments(list, ids),
        }),
        this.forceChange,
      ))
  }

  onRemove = (index) => (event) => {
    preventEventBubbling(event)

    const list = [...this.state.list]
    list.splice(index, 1)

    this.setState(() => ({ list }), this.forceChange)
  }

  forceChange = () => {
    this.props.onChange({
      name: this.props.name,
      value: {
        attachments: this.state.list,
      },
    })
  }

  onMove = (dragIndex, hoverIndex) => {
    const { list } = this.state
    const dragItem = list[dragIndex]

    this.setState(
      update(this.state, {
        list: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        },
      }),
      this.forceChange,
    )
  }

  render() {
    const AttachmentsList = listsTypes[this.props.viewType]

    return (
      <AttachmentsList
        onMove={this.onMove}
        onDrop={this.onDrop}
        accept={this.props.accept}
        multiple={this.props.multiple}
        onRemove={this.onRemove}
        isLoading={this.state.isLoading}
        attachments={this.state.list}
      />
    )
  }
}

(AttachmentsListInput as any).defaultProps = {
  viewType: "list",
  multiple: "true",
}

export default AttachmentsListInput
