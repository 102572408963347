import styled from "styled-components"

const isLoading = ({ disabled, theme }) => (disabled
  ? `pointer-events: none;
     cursor: not-allowed;
     text-decoration: none;
     color: ${theme.colors.greyDark} !important;`
  : `color: ${theme.colors.black} !important;`)

export default styled.a<any>`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  text-decoration: ${({ textDecoration }) => textDecoration || "none"};
  ${isLoading}
`
