import { useEffect, DependencyList, useMemo } from "react"
import {
  requestSubscription,
  GraphQLSubscriptionConfig,
  OperationType,
} from "relay-runtime"
import useEnvironment from "./useEnvironment"

type Config<T extends OperationType> = GraphQLSubscriptionConfig<T> & {
  skip?: boolean
}

const useSubscription = <T extends OperationType = OperationType>(
  configFactory: () => Config<T>,
  deps?: DependencyList,
) => {
  const { environment } = useEnvironment()
  const config = useMemo(configFactory, deps)

  useEffect(() => {
    if (config.skip) return

    const { dispose } = requestSubscription(environment, config)

    // eslint-disable-next-line consistent-return
    return dispose
  }, [environment, config])
}

export default useSubscription
