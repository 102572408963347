import React from "react"
import { toast, ToastContainer } from "react-toastify"

import { ModerationRequestModal } from "src/components"
import { RequestProfilePhoneConfirmation } from "src/mutations"

class ProfileConfirmContainer extends React.Component<any, any> {
  state = {
    showModal: false,
    callback: undefined,
    activeProfileId: undefined,
  }

  onRequestPhoneConfirmationError = () => {
    toast.error("Укажите номер телефона в профиле", {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  onRequestPhoneConfirmationCompleted = () => {
    this.setState(() => ({ showModal: true }))
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  requestPhoneConfirmation = () => {
    const variables = {
      input: {
        profileId: this.props.profileId,
        kind: this.props.mutationKind,
      },
    }

    const callbacks = {
      onError: this.onRequestPhoneConfirmationError,
      onCompleted: this.onRequestPhoneConfirmationCompleted,
    }

    RequestProfilePhoneConfirmation.commit(
      this.props.environment,
      variables,
      null,
      callbacks,
    )
  }

  requestApproval = (callback) => () => {
    this.setState(() => ({ callback }), this.requestPhoneConfirmation)
  }

  render() {
    return (
      <>
        <ToastContainer autoClose={4000} hideProgressBar={true} />
        {this.state.showModal && (
          <ModerationRequestModal
            close={this.closeModal}
            profileId={this.state.activeProfileId}
            approveCallback={this.state.callback}
            requestPhoneConfirmation={this.requestPhoneConfirmation}
            {...this.props}
          />
        )}
        {(this as any).props.children({ confirm: this.requestApproval })}
      </>
    )
  }
}

export default ProfileConfirmContainer
