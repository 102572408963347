import React from "react"
import propTypes from "prop-types"

import { Link } from "src/components"

import {
  FooterContainer,
  FooterWrap,
  LogoContainer,
  HeaderLogoWrap,
  FooterDescription,
  FooterInfo,
  FooterLinkContainer,
  FooterLink,
  Version,
} from "./styles"
import {
  Logo, email, facebook, medium, telegram,
} from "./icons"
import { SocialList } from "./Elements"

const HeaderLogo = (props) => (
  <LogoContainer>
    <Link to="/market" style={{ display: "flex", alignItems: "center" }}>
      <Logo color={props.color} />
    </Link>
  </LogoContainer>
)

HeaderLogo.propTypes = {
  color: propTypes.string,
}

const linksSocial = [
  { icon: email, link: "mailto:hello@karma.red" },
  // { icon: facebook, link: "https://www.facebook.com/KarmaTrustRu" },
  { icon: medium, link: "https://medium.com/karmared" },
  { icon: telegram, link: "https://t.me/karmainvest" },
]

const navigationLinks = [
  { label: "FAQ", to: "/faq" },
]

function Footer() {
  return (
    <FooterContainer>
      <FooterWrap>
        <HeaderLogoWrap>
          <HeaderLogo color="#ffffff" />
        </HeaderLogoWrap>

        <FooterLinkContainer>
          <Link to={{ pathname: "https://wow.karma.red/about" }} target="_blank">О компании </Link>
        </FooterLinkContainer>

        <FooterLinkContainer>
          {navigationLinks.map(({ to, label }) => (
            <FooterLink key={to} to={to}>
              {label}
            </FooterLink>
          ))}
        </FooterLinkContainer>

        <FooterDescription>
          <FooterInfo>Остались вопросы? Напишите нам!</FooterInfo>
          <SocialList data={linksSocial} />
        </FooterDescription>
      </FooterWrap>
      <Version>Версия клиента: {process.env.APP_VERSION}</Version>
    </FooterContainer>
  )
}

export default Footer
