import React from "react"

import {
  Glyph,
  IconContainer,
  GlyphContainer,
  HoverContainer,
  TooltipContainer,
} from "./styles"

export default (props) => {
  const {
    glyph: NestedGlyph, icon: NestedIcon, children, ...rest
  } = props

  return (
    <TooltipContainer>
      {NestedIcon ? (
        <HoverContainer>
          <NestedIcon />
        </HoverContainer>
      ) : (
        <IconContainer {...rest}>
          <GlyphContainer>
            {NestedGlyph ? <NestedGlyph /> : <Glyph />}
          </GlyphContainer>
        </IconContainer>
      )}
      {children}
    </TooltipContainer>
  )
}
