/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type ProfileInvestmentsFilter = {
    status?: Array<OrderStatusEnum> | null;
    cession?: boolean | null;
    punishment?: boolean | null;
    search?: string | null;
};
export type InvestmentActiveOrdersListIndividualQueryVariables = {
    count: number;
    cursor?: unknown | null;
    profileId: string;
    filter?: ProfileInvestmentsFilter | null;
};
export type InvestmentActiveOrdersListIndividualQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"InvestmentActiveOrdersList_individual">;
};
export type InvestmentActiveOrdersListIndividualQuery = {
    readonly response: InvestmentActiveOrdersListIndividualQueryResponse;
    readonly variables: InvestmentActiveOrdersListIndividualQueryVariables;
};



/*
query InvestmentActiveOrdersListIndividualQuery(
  $count: Int!
  $cursor: Cursor
  $profileId: ID!
  $filter: ProfileInvestmentsFilter
) {
  ...InvestmentActiveOrdersList_individual_2pp4c
}

fragment InvestmentActiveOrdersList_individual_2pp4c on Query {
  node(id: $profileId) {
    __typename
    ... on IndividualProfile {
      investor {
        investments(first: $count, after: $cursor, filter: $filter) {
          edges {
            node {
              id
              amount
              profile {
                __typename
                id
              }
              order {
                id
                status
                confirmedAt
                offer {
                  data
                  receivedAt
                  approvedAt
                  id
                }
                application {
                  data
                  shortTitle
                  longTitle
                  id
                }
                chain {
                  id
                  gatheredAmount
                }
                profile {
                  __typename
                  id
                  ... on LegalEntityProfile {
                    name
                    borrower {
                      ticker
                    }
                  }
                }
              }
              __typename
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "Cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profileId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ProfileInvestmentsFilter"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvestmentActiveOrdersListIndividualQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "profileId",
            "variableName": "profileId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "InvestmentActiveOrdersList_individual"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvestmentActiveOrdersListIndividualQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "profileId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualProfileInvestor",
                "kind": "LinkedField",
                "name": "investor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "InvestmentConnection",
                    "kind": "LinkedField",
                    "name": "investments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvestmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Investment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "profile",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Order",
                                "kind": "LinkedField",
                                "name": "order",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "confirmedAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderOffer",
                                    "kind": "LinkedField",
                                    "name": "offer",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "receivedAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "approvedAt",
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderApplication",
                                    "kind": "LinkedField",
                                    "name": "application",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "shortTitle",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "longTitle",
                                        "storageKey": null
                                      },
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "OrderChainInfo",
                                    "kind": "LinkedField",
                                    "name": "chain",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "gatheredAmount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "profile",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Borrower",
                                            "kind": "LinkedField",
                                            "name": "borrower",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "ticker",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "LegalEntityProfile"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": [
                      "filter"
                    ],
                    "handle": "connection",
                    "key": "ActiveInvestments_investments",
                    "kind": "LinkedHandle",
                    "name": "investments"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "IndividualProfile"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InvestmentActiveOrdersListIndividualQuery",
    "operationKind": "query",
    "text": "query InvestmentActiveOrdersListIndividualQuery(\n  $count: Int!\n  $cursor: Cursor\n  $profileId: ID!\n  $filter: ProfileInvestmentsFilter\n) {\n  ...InvestmentActiveOrdersList_individual_2pp4c\n}\n\nfragment InvestmentActiveOrdersList_individual_2pp4c on Query {\n  node(id: $profileId) {\n    __typename\n    ... on IndividualProfile {\n      investor {\n        investments(first: $count, after: $cursor, filter: $filter) {\n          edges {\n            node {\n              id\n              amount\n              profile {\n                __typename\n                id\n              }\n              order {\n                id\n                status\n                confirmedAt\n                offer {\n                  data\n                  receivedAt\n                  approvedAt\n                  id\n                }\n                application {\n                  data\n                  shortTitle\n                  longTitle\n                  id\n                }\n                chain {\n                  id\n                  gatheredAmount\n                }\n                profile {\n                  __typename\n                  id\n                  ... on LegalEntityProfile {\n                    name\n                    borrower {\n                      ticker\n                    }\n                  }\n                }\n              }\n              __typename\n            }\n            cursor\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cf1070ab04a50a5da3611c1435791d72';
export default node;
