import React, {
  useContext, useState, createRef, useEffect,
} from "react"
import ReactDOM from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Link } from "react-router-dom"

import { CurrentProfileContext } from "src/context"
import { Icons } from "src/components"
import { userAgreementUrl } from "src/constants"

import DescriptionText from "../../organisms/ProfileInfo/Elements/DescriptionModal/Text"

import {
  Modal,
  Container,
  Image,
  Title,
  Text,
  Link as TextLink,
  ButtonContainer,
  Button,
  PrimaryButton,
  DetailTitle,
  DetailButtonContainer,
  DetailButton,
  CloseButton,
  ReturnButton,
} from "./styles"

const QualifiedInfoModal = (props) => {
  const { onClose } = props
  const { profile } = useContext(CurrentProfileContext)

  const [isDetailed, setDetailed] = useState(false)

  const modalRef = createRef<HTMLDivElement>()
  const overlayRef = createRef<HTMLDivElement>()

  function handleClose() {
    enableBodyScroll(modalRef.current)
    onClose()
  }

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      handleClose()
    }
  }

  useEffect(() => {
    disableBodyScroll(modalRef.current)

    return () => enableBodyScroll(modalRef.current)
  }, [])

  return (
    <Modal ref={overlayRef} onClick={onOverlayClick}>
      <Container ref={modalRef}>
        <ReturnButton onClick={handleClose}>
          <Icons.LeftArrow />
          <span>Назад</span>
        </ReturnButton>
        <CloseButton onClick={handleClose}>
          <Icons.Close />
        </CloseButton>

        {!isDetailed && (
          <>
            <Image />
            <Title>Подтвердите статус квалифицированного инвестора</Title>
            <Text>
              <TextLink href={userAgreementUrl} target="_blank">Правилами платформы</TextLink> установлено{" "}
              ограничение, по которому физ. лица, не являющиеся квалифицированными инвесторами, {" "}
              не могут инвестировать.
              Загрузите подтверждение наличия квалификации или аккредитуйтесь
              как ИП.
            </Text>

            <ButtonContainer>
              <PrimaryButton>
                <Link to={`/accounts/${profile.id}`}>подтвердить</Link>
              </PrimaryButton>
              <Button onClick={() => setDetailed(true)}>подробнее</Button>
            </ButtonContainer>
          </>
        )}

        {isDetailed && (
          <>
            <DetailTitle>Памятка</DetailTitle>
            <DescriptionText />
            <DetailButtonContainer>
              <DetailButton>
                <Link to={`/accounts/${profile.id}`}>подтвердить</Link>
              </DetailButton>
            </DetailButtonContainer>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default function Render(props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")
  return isOpened
    ? ReactDOM.createPortal(QualifiedInfoModal(props), root)
    : null
}
