import {
  useRef, useState, useEffect, useCallback,
} from "react"

import useEnvironment from "./useEnvironment"

const useMutation = (mutation) => {
  const callbacks = useRef({ onError: null, onCompleted: null })
  const pendingMutation = useRef<any>()

  const [busy, setBusy] = useState(false)
  const [response, setResponse] = useState({ errors: null, data: null })

  const { environment } = useEnvironment()

  const onError = useCallback((fn) => {
    callbacks.current.onError = fn
  }, [])

  const onCompleted = useCallback((fn) => {
    callbacks.current.onCompleted = fn
  }, [])

  const commit = useCallback(
    (variables, uploadables?: any) => {
      if (busy) return

      setBusy(true)

      pendingMutation.current = mutation.commit(
        environment,
        variables,
        uploadables,
        {
          onError: (transaction) => {
            setBusy(false)

            setResponse({
              errors: transaction.errors,
              data: null,
            })

            if (typeof callbacks.current.onError === "function") {
              callbacks.current.onError(transaction)
            }
          },
          onCompleted: (payload) => {
            setBusy(false)

            setResponse({
              data: payload,
              errors: null,
            })

            if (typeof callbacks.current.onCompleted === "function") {
              callbacks.current.onCompleted(payload)
            }
          },
        },
      )
    },
    [mutation],
  )

  useEffect(
    () => () => pendingMutation.current
      && pendingMutation.current.dispose
      && pendingMutation.current.dispose(),
    [],
  )

  return {
    busy,
    commit,
    response,
    onError,
    onCompleted,
  }
}

export default useMutation
