/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ManageLegalEntity_profile = {
    readonly id: string;
    readonly inn: string | null;
    readonly name: string;
    readonly phone: string | null;
    readonly _avatar: {
        readonly url: string;
    } | null;
    readonly " $refType": "ManageLegalEntity_profile";
};
export type ManageLegalEntity_profile$data = ManageLegalEntity_profile;
export type ManageLegalEntity_profile$key = {
    readonly " $data"?: ManageLegalEntity_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"ManageLegalEntity_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageLegalEntity_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "_avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LegalEntityProfile"
};
(node as any).hash = '68775491c77102c98dc2e84cec64b6de';
export default node;
