import styled from "styled-components"

import { Text, CardWrapper } from "src/components"

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.grey};
`

export const InputsContainer = styled.form`
  margin-bottom: 35px;
  max-width: 550px;
  input:last-child {
    margin-bottom: 0;
  }
`

export const ContentWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.primaryBlacks[5]};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
`

export const WrappedText = styled(Text)`
  overflow-wrap: ${({ overflowWrap }) => overflowWrap || "normal"};
`

export const StyledLink = styled.a`
  text-decoration: none;
`

export const Container = styled(CardWrapper)`
  padding: 28px;
  font-family: Geometria;
  font-size: 1rem;
  width: 100%;
  color: #4a4a4a;

  @media screen and (max-width: 800px) {
    box-shadow: none;
    padding: 0;
    margin-top: 40px;
  }
`
