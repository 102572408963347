import React from "react"

import {
  Box, Text, Flex, Translate, Tooltip,
} from "src/components"

import { HeadingBox, StyledText, GlyphContent } from "./styles"

const statuses = {
  paid: {
    label: "components:account.order_item.payment_schedule.statuses.paid",
    color: "green",
  },
  pastdue: {
    label: "components:account.order_item.payment_schedule.statuses.pastdue",
    color: "red",
  },
  new: {
    label: "components:account.order_item.payment_schedule.statuses.new",
    color: "greyDark",
  },
}

export const ExchangeTooltipBody = ({ rate, date, state }) => {
  const dateSuffix = state === "new" ? "future_date" : "past_date"

  return (
    <Box>
      <Text color="grey" fontSize="12px" lineHeight="1.35">
        <Translate
          i18n={`components:account.order_item.payment_schedule.table.currency_tooltip.${dateSuffix}`}
          date={date}
        />
      </Text>
      <Box mt="5px">
        <Text color="white">
          <Translate
            i18n="components:account.order_item.payment_schedule.table.currency_tooltip.rate"
            rate={rate.amount}
            currency={rate.currency}
          />
        </Text>
      </Box>
    </Box>
  )
}

const InfoGlyph = () => <GlyphContent>i</GlyphContent>

const ExchangeRateTooltip = ({ content }) => (
  <Box mt="-2px">
    <Tooltip
      borderColor="grey"
      borderWidth="2px"
      glyph={InfoGlyph}
      content={content}
    />
  </Box>
)

const HeadingCell = ({ item }) => (
  <th>
    <Box p="12px 0">
      <HeadingBox>
        <StyledText>
          <Translate i18n={item.title} />
        </StyledText>
        {item.subtitle && (
          <Text lineHeight="2" fontSize="10px" color="greyDark">
            <Translate i18n={item.subtitle} />
          </Text>
        )}
      </HeadingBox>
    </Box>
  </th>
)

const BodyCell = ({ item }) => {
  const { title, subtitle, tooltip: TooltipBody } = item

  const { label, color } = statuses[title] || {}

  return (
    <td>
      <Box p="16px 0">
        <Flex>
          <Text fontSize="16px" textAlign="left" color={color || "greyDarkest"}>
            {label ? <Translate i18n={label} /> : title}
          </Text>
          {TooltipBody && <ExchangeRateTooltip content={TooltipBody} />}
        </Flex>
        {subtitle && (
          <Text lineHeight="2" fontSize="11px" color="greyDark">
            <Translate i18n={subtitle} />
          </Text>
        )}
      </Box>
    </td>
  )
}

export const Row = ({ schema }) => (
  <tr>
    {schema.map((item, idx) => {
      const Component = item.component || BodyCell

      return <Component key={idx} item={item} />
    })}
  </tr>
)

export const Header = ({ schema }) => (
  <tr>
    {schema.map((item, idx) => {
      const Component = item.component || HeadingCell

      return <Component key={idx} item={item} />
    })}
  </tr>
)
