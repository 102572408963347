/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type CurrencyEnum = "EUR" | "RUB" | "%future added value";
export type KYCDocumentTypeEnum = "DRIVERS" | "ID_CARD" | "OTHER" | "PASSPORT" | "RESIDENCE_PERMIT" | "%future added value";
export type LocaleEnum = "EN" | "KO" | "RU" | "ZH" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type SupportingDocumentStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type ViewerData_viewer = {
    readonly id: string;
    readonly email: string;
    readonly locale: LocaleEnum;
    readonly country: string;
    readonly currency: CurrencyEnum;
    readonly createdAt: string;
    readonly profiles: ReadonlyArray<{
        readonly __typename: string;
        readonly investor: {
            readonly canBeInvestor: boolean;
            readonly investedAmount?: number;
            readonly qualified?: boolean;
        } | null;
        readonly approvedAsInvestor: boolean;
        readonly id?: string;
        readonly name?: string;
        readonly phone?: string | null;
        readonly accountBalance?: number;
        readonly accountBalanceDetailed?: ReadonlyArray<{
            readonly available: number;
            readonly currency: CurrencyEnum;
        }>;
        readonly accreditation?: {
            readonly status: ProfileAccreditationStatus | null;
        };
        readonly bankAccounts?: ReadonlyArray<{
            readonly id: string;
            readonly status: BankAccountStatusEnum;
        }>;
        readonly supportingDocuments?: ReadonlyArray<{
            readonly id: string;
            readonly status: SupportingDocumentStatus | null;
        }>;
        readonly foreignPhone?: string;
    }>;
    readonly foreignProfiles: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly phone: string;
        readonly investor: {
            readonly canBeInvestor: boolean;
        } | null;
        readonly approvedAsInvestor: boolean;
        readonly documents: ReadonlyArray<{
            readonly type: KYCDocumentTypeEnum;
            readonly attachments: ReadonlyArray<{
                readonly id: string;
            }>;
        }>;
        readonly selfie: {
            readonly id: string;
        } | null;
        readonly accountBalanceDetailed: ReadonlyArray<{
            readonly available: number;
            readonly currency: CurrencyEnum;
        }>;
        readonly accreditation: {
            readonly status: ProfileAccreditationStatus | null;
        };
    }>;
    readonly " $refType": "ViewerData_viewer";
};
export type ViewerData_viewer$data = ViewerData_viewer;
export type ViewerData_viewer$key = {
    readonly " $data"?: ViewerData_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"ViewerData_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canBeInvestor",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvedAsInvestor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountBalance",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfileAccountBalance",
  "kind": "LinkedField",
  "name": "accountBalanceDetailed",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "available",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v0/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BankAccount",
  "kind": "LinkedField",
  "name": "bankAccounts",
  "plural": true,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v14 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerData_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profiles",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "role",
                  "value": "BORROWER"
                }
              ],
              "concreteType": "Accreditation",
              "kind": "LinkedField",
              "name": "accreditation",
              "plural": false,
              "selections": (v10/*: any*/),
              "storageKey": "accreditation(role:\"BORROWER\")"
            }
          ],
          "type": "LegalEntityProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualProfileInvestor",
              "kind": "LinkedField",
              "name": "investor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "investedAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "qualified",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SupportingDocument",
              "kind": "LinkedField",
              "name": "supportingDocuments",
              "plural": true,
              "selections": (v11/*: any*/),
              "storageKey": null
            }
          ],
          "type": "IndividualProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            {
              "alias": "foreignPhone",
              "args": null,
              "kind": "ScalarField",
              "name": "phone",
              "storageKey": null
            },
            (v8/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/)
          ],
          "type": "ForeignIndividualProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/)
          ],
          "type": "EntrepreneurProfile"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ForeignIndividualProfile",
      "kind": "LinkedField",
      "name": "foreignProfiles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Investor",
          "kind": "LinkedField",
          "name": "investor",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "KYCDocument",
          "kind": "LinkedField",
          "name": "documents",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachments",
              "plural": true,
              "selections": (v14/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "selfie",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        (v8/*: any*/),
        (v13/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
(node as any).hash = '066f1cea09dcce20b24da5a980a5076e';
export default node;
