import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation UploadPassportScanMutation($input: UploadPassportScanInput!) {
    uploadPassportScan(input: $input) {
      url
      name
      signature
    }
  }
`

export default enhance({ mutation })
