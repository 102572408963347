import React from "react"

import { graphql } from "react-relay"
import { QueryRenderer } from "src/components"

const query = graphql`
  query OrderInfoQuery($id: ID!) {
    node(id: $id) {
      ...DebtPaymentData_order
      ...Provision_order
      ...Conclusion_order
      ...InstructionsModal_order
      ...FinancialStatements_order
      ...PenaltiesInfo_order
      id
      __typename
      ... on Order {
        createdAt
        status
        statusFrom
        profile {
          ... on UserProfile {
            id
            ... on LegalEntityProfile {
              name
              inn
              ogrn
              executive {
                name
              }
            }
            creditRating {
              rating
            }
            _avatar {
              url
            }
          }
        }
        offer {
          ... on OrderOffer {
            id
            data
            declineReason
            receivedAt
            attachments {
              ... on Attachment {
                id
                url
                filename
              }
            }
          }
        }
        application {
          ... on OrderApplication {
            id
            data
            declineReason
            receivedAt
            attachments {
              ... on Attachment {
                id
                url
                filename
              }
            }
          }
          externalMedium {
            video {
              url
            }
          }
        }
        confirmedAt
        fee
        chain {
          id
          gatheredAmount
          investorsCount
        }
        creditRating {
          rating
        }
        otherDocuments {
          attachment {
            url
            filename
          }
          description
        }
        skipOffer
        guarantors {
          attachment {
            url
            filename
          }
          name
          description
        }
        cession {
          due
          amount
          isActive
          interestRate
          borrowerName
          originalChainId
          avatar {
            url
          }
          borrowerRating {
            rating
            ratedAt
          }
        }
        borrowerOffer: document(type: BORROWER_OFFER) {
          url
          type
        }
        karma {
          conclusion
          isInvestor
        }
        paymentScheduleList {
          info {
            state
          }
          profile {
            id
            __typename
            bankAccounts {
              id
              status
            }
          }
          foreignProfile {
            id
            __typename
            bankAccounts {
              ... on BankAccount {
                id
                status
              }
            }
          }
          items {
            date
            loan
            state
            total
            interest
            total_gross
            interest_fee
            personal_tax
            loan_details {
              currency
              amount
            }
            total_details {
              currency
              amount
            }
            interest_details {
              currency
              amount
            }
            total_gross_details {
              currency
              amount
            }
            interest_fee_details {
              currency
              amount
            }
            personal_tax_details {
              currency
              amount
            }
            exchangeRates {
              currency
              amount
            }
          }
        }
        viewer {
          hasIntent
          investedAmount
        }
        provision {
          items {
            kind
          }
        }
        financialStatements {
          name
        }
        expertOpinions {
          id
        }
      }
    }
  }
`

const container = (render) => ({ orderId, ...rest }) => {
  const variables = {
    id: orderId,
  }

  return (
    <QueryRenderer
      {...rest}
      query={query}
      render={render}
      variables={variables}
    />
  )
}

container.query = query

export default container
