import { userAgreementUrl } from "./system"

export const RUSSIAN_PHONE_MASK = "+9 (999) 999-99-99"

export const SCROLL_KEYS = {
  37: 1,
  38: 1,
  39: 1,
  40: 1,
}
export const SuccessSaveMessage = "Заявка успешно сохранена, вы можете продолжить редактирование позже"

export const Tables = [
  {
    title: {
      label: "about.tables.documents.title",
      ns: "pages",
    },
    rows: [
      {
        link: userAgreementUrl,
        title: {
          label: "about.tables.documents.rows.terms",
          ns: "pages",
        },
        date: {
          year: 2018,
          month: {
            title: "legal_entity_offer_form.switch_values.months.oct",
            ns: "components",
          },
          day: 31,
        },
      },
      {
        link: "/docs/november-stock.pdf",
        title: "Акция (размер вознаграждение платформы - 100 рублей)",
        date: {
          month: {
            title: "legal_entity_offer_form.switch_values.months.oct",
            ns: "components",
          },
          day: 19,
        },
      },
      {
        link:
          "https://docs.google.com/document/d/18cd_pOe_cAJHPZC3MDeSqhZ_eh9Y0dX3Wxi2seCULmg/edit?usp=sharing",
        title: {
          label: "about.tables.documents.rows.terms",
          ns: "pages",
        },
        date: {
          month: {
            title: "legal_entity_offer_form.switch_values.months.oct",
            ns: "components",
          },
          day: 16,
        },
      },
      {
        link:
          "https://docs.google.com/document/d/1gfPrgKbNP4ItvHreGPqd7chcYvGThhwVFydOEnAk5Jo/edit?usp=sharing\n",
        title: {
          label: "about.tables.documents.rows.terms",
          ns: "pages",
        },
        date: {
          year: "",
          month: {
            title: "legal_entity_offer_form.switch_values.months.aug",
            ns: "components",
          },
          day: 14,
        },
      },
      {
        link:
          "https://docs.google.com/document/d/13zDAuzVNfP4eVx2bpotI65qZSZtU80k3h1a8xtEb620/edit?usp=sharing",
        title: {
          label: "about.tables.documents.rows.agreement",
          ns: "pages",
        },
        date: {
          year: "",
          month: {
            title: "legal_entity_offer_form.switch_values.months.aug",
            ns: "components",
          },
          day: 14,
        },
      },
      {
        link:
          "https://docs.google.com/document/d/1aXhcN4fQnI3qU0g6iO9u5ZXGeG9cmA6_CpYRw9Unj-k/edit?usp=sharing",
        title: {
          label: "about.tables.documents.rows.rates",
          ns: "pages",
        },
        date: {
          year: "",
          month: {
            title: "legal_entity_offer_form.switch_values.months.aug",
            ns: "components",
          },
          day: 14,
        },
      },
    ],
  },
]

export const VIDEO_META = {
  id: null,
  url: "",
  type: "",
  title: null,
  description: null,
  video: {
    url: null,
    width: null,
    height: null,
  },
}

export const RequisitesOrderType = {
  fee: "BORROWER_FEE",
  invest: "INVESTOR_PAYMENT",
}

export const OrdersSwitchBarSchema = [
  {
    label: "components:orders-tabs.mine",
    type: "loans",
  },
  {
    label: "components:orders-tabs.others",
    type: "investments",
    analytics: {
      eventAction: "my-invest",
      eventLabel: "click",
    },
  },
]

export const OrdersTrusteeSwitchBarSchema = [
  ...OrdersSwitchBarSchema,
  {
    label: "components:orders-tabs.trust",
    type: "trust",
  },
]

export const TrustedSwitchBarSchema = [
  {
    label: "components:trusted-tabs.application",
    type: "application",
  },
  {
    label: "components:trusted-tabs.offer",
    type: "offer",
  },
]

export const CurrencySigns = {
  rub: "₽",
  RUB: "₽",
  eur: "€",
  EUR: "€",
}

export const KYCDocument = {
  type: "PASSPORT",
  attachments: [],
}
