/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountsListQueryVariables = {};
export type AccountsListQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AccountsList_viewer">;
    } | null;
};
export type AccountsListQuery = {
    readonly response: AccountsListQueryResponse;
    readonly variables: AccountsListQueryVariables;
};



/*
query AccountsListQuery {
  viewer {
    ...AccountsList_viewer
    id
  }
}

fragment AccountsList_viewer on User {
  id
  email
  country
  foreignProfiles {
    id
    __typename
    phone
    firstName
    lastName
    accreditation(role: INVESTOR) {
      status
    }
  }
  profiles {
    __typename
    ... on LegalEntityProfile {
      id
      name
      phone
      accountBalance
      accreditation(role: BORROWER) {
        status
      }
    }
    ... on IndividualProfile {
      id
      name
      phone
      accountBalance
      accreditation(role: INVESTOR) {
        status
      }
    }
    ... on EntrepreneurProfile {
      id
      name
      phone
      accountBalance
      accreditation(role: INVESTOR) {
        status
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountBalance",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v2/*: any*/),
  (v6/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountsList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountsListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "foreignProfiles",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profiles",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "role",
                        "value": "BORROWER"
                      }
                    ],
                    "concreteType": "Accreditation",
                    "kind": "LinkedField",
                    "name": "accreditation",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": "accreditation(role:\"BORROWER\")"
                  }
                ],
                "type": "LegalEntityProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "IndividualProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "EntrepreneurProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AccountsListQuery",
    "operationKind": "query",
    "text": "query AccountsListQuery {\n  viewer {\n    ...AccountsList_viewer\n    id\n  }\n}\n\nfragment AccountsList_viewer on User {\n  id\n  email\n  country\n  foreignProfiles {\n    id\n    __typename\n    phone\n    firstName\n    lastName\n    accreditation(role: INVESTOR) {\n      status\n    }\n  }\n  profiles {\n    __typename\n    ... on LegalEntityProfile {\n      id\n      name\n      phone\n      accountBalance\n      accreditation(role: BORROWER) {\n        status\n      }\n    }\n    ... on IndividualProfile {\n      id\n      name\n      phone\n      accountBalance\n      accreditation(role: INVESTOR) {\n        status\n      }\n    }\n    ... on EntrepreneurProfile {\n      id\n      name\n      phone\n      accountBalance\n      accreditation(role: INVESTOR) {\n        status\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '668ca021d74ac5d316eb14ec1a93a259';
export default node;
