import React, { useState } from "react"
import i18next from "i18next"
import { Route } from "react-router"
import { toast } from "react-toastify"

import { Box } from "src/components"

import Form from "./Form"
import Controls from "../../../Controls"
import ProcessIndividualProfile from "./MutationContainer"

import FormContainer from "./styles"
import { InitialState, getIdFromResponse } from "./utils"

const notifyToast = (type = "success") => {
  const toastFunction = toast[type]
  const toastMessage = i18next.t(`common:toasts.save_${type}`)

  toastFunction(toastMessage)
}

const Content = (props) => {
  const {
    onSave,
    onChange,
    onCancel,
    formState,
    onContinue,
    isContinueLoading,
  } = props

  return (
    <Box>
      <FormContainer>
        <Form value={formState} onChange={onChange} />
      </FormContainer>
      <Controls
        onSave={onSave}
        saveLabel="common:buttons.borrower"
        saveButtonVariant="defaultBlue"
        onCancel={onCancel}
        onContinue={onContinue}
        isContinueLoading={isContinueLoading}
      />
    </Box>
  )
}

const CreateRussianInvestor = (props) => {
  const [isContinueLoading, setContinueLoading] = useState(false)

  const [formState, setFormState] = useState({ ...InitialState })
  const onChange = (nextFormState) => setFormState({ ...nextFormState })

  const onCancel = () => {
    props.history.push("/accounts")
  }

  const onSave = () => {
    props.history.push("/profiles/create/juristic")
  }

  const onContinue = () => {
    setContinueLoading(true)

    props
      .createRussianInvestorProfile(formState)
      .then((data) => {
        const profileId = getIdFromResponse(data)

        notifyToast("success")
        setContinueLoading(false)
        props.history.push(`/accounts/${profileId}/accreditation`)
      })
      .catch(() => {
        notifyToast("error")
        setContinueLoading(false)
      })
  }

  return (
    <React.Fragment>
      <Content
        onSave={onSave}
        onChange={onChange}
        onCancel={onCancel}
        formState={formState}
        onContinue={onContinue}
        isContinueLoading={isContinueLoading}
      />
    </React.Fragment>
  )
}

const render = (props) => (
  <ProcessIndividualProfile viewer={props.viewer}>
    {(renderProps) => (
      <Route>
        {({ history }) => (
          <CreateRussianInvestor
            {...props}
            {...renderProps}
            history={history}
          />
        )}
      </Route>
    )}
  </ProcessIndividualProfile>
)

export default render
