import styled from "styled-components"
import { width } from "styled-system"

import { Box, Text } from "src/components"

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background-color: ${({ theme }) => theme.colors.blacks[5]};
`

export const ModalBody = styled.div`
  ${width}
  top: 20%;
  left: 50%;
  border-radius: 5px;
  position: absolute;
  padding: 30px 40px 34px;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyDarkest};
`

export const IconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.grey};
    padding: 7px;
  }
`

export const Controls = styled.div`
  padding-top: 42px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const LinkDocument = styled.a`
  color: ${({ theme }) => theme.colors.blue} !important;
`

export const TitleContainer = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  margin-bottom: 20px;
  padding-bottom: 14px;
`

export const Legend = styled(Text)`
  line-height: 1.5;
  font-size: 16px;
  whitespace: pre-line;
  color: ${({ theme }) => theme.colors.greyDarkest};
`
