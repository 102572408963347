/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BankAccountStatusEnum = "APPROVED" | "DECLINED" | "INITIAL" | "%future added value";
export type KYCDocumentTypeEnum = "DRIVERS" | "ID_CARD" | "OTHER" | "PASSPORT" | "RESIDENCE_PERMIT" | "%future added value";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type SexEnum = "FEMALE" | "MALE" | "%future added value";
export type SupportingDocumentStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type TerritoryResidenceDocumentTypeEnum = "PERMANENT_RESIDENCY" | "VISA" | "%future added value";
export type AccreditationInfoFragment_profile = {
    readonly id: string;
    readonly __typename: string;
    readonly investor: {
        readonly canBeInvestor: boolean;
    } | null;
    readonly name?: string;
    readonly phone?: string | null;
    readonly isRussiaTaxResident?: boolean;
    readonly sex?: SexEnum | null;
    readonly inn?: string | null;
    readonly ifns?: string | null;
    readonly iian?: string | null;
    readonly birthDate?: string | null;
    readonly birthPlace?: string | null;
    readonly employment?: string | null;
    readonly isPublicOfficial?: boolean | null;
    readonly bankAccounts?: ReadonlyArray<{
        readonly id: string;
        readonly status: BankAccountStatusEnum;
        readonly checkingAccount: string | null;
        readonly correspondentAccount: string | null;
        readonly bank: {
            readonly bic: string;
            readonly name: string;
        } | null;
    }>;
    readonly registrationAddress?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly okato: string | null;
        readonly oktmo: string | null;
        readonly settlement: string | null;
        readonly postalCode: string | null;
        readonly cityDistrict: string | null;
    } | null;
    readonly passport?: {
        readonly series: string | null;
        readonly number: string | null;
        readonly issuedAt: string | null;
        readonly scans: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly filename: string;
        }>;
        readonly issuedBy: {
            readonly code: string | null;
            readonly name: string | null;
        } | null;
    } | null;
    readonly supportingDocuments?: ReadonlyArray<{
        readonly id: string;
        readonly attachment: {
            readonly id: string;
            readonly url: string;
            readonly size: number;
            readonly width: number | null;
            readonly height: number | null;
            readonly format: string | null;
            readonly filename: string;
        };
        readonly status: SupportingDocumentStatus | null;
    }>;
    readonly accreditation?: {
        readonly status: ProfileAccreditationStatus | null;
        readonly declineReason: string | null;
    };
    readonly psrnie?: string | null;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly foreignPhone?: string;
    readonly tin?: string | null;
    readonly profileTransactions?: {
        readonly count: number;
    };
    readonly selfie?: {
        readonly id: string;
        readonly url: string;
        readonly filename: string;
        readonly signature: {
            readonly url: string | null;
        } | null;
    } | null;
    readonly taxResidencyApplication?: ReadonlyArray<{
        readonly id: string;
        readonly filename: string;
        readonly url: string;
        readonly size: number;
        readonly signature: {
            readonly url: string | null;
        } | null;
    }> | null;
    readonly migrationCard?: {
        readonly number: string | null;
        readonly dateFrom: string | null;
        readonly dateTo: string | null;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly filename: string;
            readonly url: string;
            readonly size: number;
            readonly signature: {
                readonly url: string | null;
            } | null;
        }>;
    } | null;
    readonly innDocument?: ReadonlyArray<{
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    }> | null;
    readonly residenceDocument?: {
        readonly id: string;
        readonly filename: string;
        readonly url: string;
        readonly size: number;
        readonly signature: {
            readonly url: string | null;
        } | null;
    } | null;
    readonly territoryResidenceDocument?: {
        readonly type: TerritoryResidenceDocumentTypeEnum | null;
        readonly series: string | null;
        readonly number: string | null;
        readonly dateFrom: string | null;
        readonly dateTo: string | null;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly filename: string;
            readonly url: string;
            readonly size: number;
            readonly signature: {
                readonly url: string | null;
            } | null;
        }> | null;
    } | null;
    readonly documents?: ReadonlyArray<{
        readonly type: KYCDocumentTypeEnum;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly url: string;
            readonly size: number;
            readonly filename: string;
            readonly access: string;
            readonly format: string | null;
            readonly signature: {
                readonly url: string | null;
            } | null;
        }>;
    }>;
    readonly translatedDocuments?: ReadonlyArray<{
        readonly id: string;
        readonly url: string;
        readonly filename: string;
    }> | null;
    readonly kpp?: string | null;
    readonly ogrn?: string | null;
    readonly executive?: {
        readonly name: string;
    } | null;
    readonly business?: {
        readonly main: string | null;
        readonly aux: string | null;
        readonly legalForm: string | null;
        readonly startedAt: string | null;
    } | null;
    readonly address?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly okato: string | null;
        readonly oktmo: string | null;
        readonly settlement: string | null;
        readonly postalCode: string | null;
        readonly cityDistrict: string | null;
    } | null;
    readonly actualAddress?: {
        readonly country: string | null;
        readonly region: string | null;
        readonly area: string | null;
        readonly locality: string | null;
        readonly street: string | null;
        readonly house: string | null;
        readonly housing: string | null;
        readonly structure: string | null;
        readonly flat: string | null;
        readonly okato: string | null;
        readonly oktmo: string | null;
        readonly settlement: string | null;
        readonly postalCode: string | null;
        readonly cityDistrict: string | null;
    } | null;
    readonly borrower?: {
        readonly publicEmail: string | null;
    } | null;
    readonly " $refType": "AccreditationInfoFragment_profile";
};
export type AccreditationInfoFragment_profile$data = AccreditationInfoFragment_profile;
export type AccreditationInfoFragment_profile$key = {
    readonly " $data"?: AccreditationInfoFragment_profile$data;
    readonly " $fragmentRefs": FragmentRefs<"AccreditationInfoFragment_profile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRussiaTaxResident",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inn",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ifns",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "iian",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthPlace",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPublicOfficial",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "region",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "area",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locality",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "house",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "housing",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "structure",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flat",
  "storageKey": null
},
v21 = [
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "okato",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "oktmo",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "settlement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "postalCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "cityDistrict",
    "storageKey": null
  }
],
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "registrationAddress",
  "plural": false,
  "selections": (v21/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "series",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v27 = [
  (v0/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "scans",
  "plural": true,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "Passport",
  "kind": "LinkedField",
  "name": "passport",
  "plural": false,
  "selections": [
    (v23/*: any*/),
    (v24/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedAt",
      "storageKey": null
    },
    (v28/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PassportIssuer",
      "kind": "LinkedField",
      "name": "issuedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v32 = [
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "declineReason",
    "storageKey": null
  }
],
v33 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v32/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "DocumentSignature",
  "kind": "LinkedField",
  "name": "signature",
  "plural": false,
  "selections": [
    (v25/*: any*/)
  ],
  "storageKey": null
},
v35 = [
  (v0/*: any*/),
  (v26/*: any*/),
  (v25/*: any*/),
  (v30/*: any*/),
  (v34/*: any*/)
],
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFrom",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateTo",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": (v35/*: any*/),
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccreditationInfoFragment_profile",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "investor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeInvestor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employment",
          "storageKey": null
        },
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccount",
          "kind": "LinkedField",
          "name": "bankAccounts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "checkingAccount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "correspondentAccount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Bank",
              "kind": "LinkedField",
              "name": "bank",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bic",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v22/*: any*/),
        (v29/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SupportingDocument",
          "kind": "LinkedField",
          "name": "supportingDocuments",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v25/*: any*/),
                (v30/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "width",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "height",
                  "storageKey": null
                },
                (v31/*: any*/),
                (v26/*: any*/)
              ],
              "storageKey": null
            },
            (v11/*: any*/)
          ],
          "storageKey": null
        },
        (v33/*: any*/)
      ],
      "type": "IndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "psrnie",
          "storageKey": null
        },
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "registrationAddress",
          "plural": false,
          "selections": [
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/)
          ],
          "storageKey": null
        },
        (v29/*: any*/),
        (v33/*: any*/)
      ],
      "type": "EntrepreneurProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": "foreignPhone",
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tin",
          "storageKey": null
        },
        {
          "alias": "profileTransactions",
          "args": null,
          "concreteType": "TransactionConnection",
          "kind": "LinkedField",
          "name": "transactions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "selfie",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v25/*: any*/),
            (v26/*: any*/),
            (v34/*: any*/)
          ],
          "storageKey": null
        },
        (v33/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "taxResidencyApplication",
          "plural": true,
          "selections": (v35/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MigrationCard",
          "kind": "LinkedField",
          "name": "migrationCard",
          "plural": false,
          "selections": [
            (v24/*: any*/),
            (v36/*: any*/),
            (v37/*: any*/),
            (v38/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "innDocument",
          "plural": true,
          "selections": (v27/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "residenceDocument",
          "plural": false,
          "selections": (v35/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TerritoryResidenceDocument",
          "kind": "LinkedField",
          "name": "territoryResidenceDocument",
          "plural": false,
          "selections": [
            (v39/*: any*/),
            (v23/*: any*/),
            (v24/*: any*/),
            (v36/*: any*/),
            (v37/*: any*/),
            (v38/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "KYCDocument",
          "kind": "LinkedField",
          "name": "documents",
          "plural": true,
          "selections": [
            (v39/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Attachment",
              "kind": "LinkedField",
              "name": "attachments",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v25/*: any*/),
                (v30/*: any*/),
                (v26/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "access",
                  "storageKey": null
                },
                (v31/*: any*/),
                (v34/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "translatedDocuments",
          "plural": true,
          "selections": (v27/*: any*/),
          "storageKey": null
        },
        (v22/*: any*/)
      ],
      "type": "ForeignIndividualProfile"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v5/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "role",
              "value": "BORROWER"
            }
          ],
          "concreteType": "Accreditation",
          "kind": "LinkedField",
          "name": "accreditation",
          "plural": false,
          "selections": (v32/*: any*/),
          "storageKey": "accreditation(role:\"BORROWER\")"
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kpp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ogrn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "executive",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Business",
          "kind": "LinkedField",
          "name": "business",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "main",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aux",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalForm",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v21/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "actualAddress",
          "plural": false,
          "selections": (v21/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Passport",
          "kind": "LinkedField",
          "name": "passport",
          "plural": false,
          "selections": [
            (v28/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Borrower",
          "kind": "LinkedField",
          "name": "borrower",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publicEmail",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LegalEntityProfile"
    }
  ],
  "type": "UserProfile"
};
})();
(node as any).hash = '5bc5cfca5a70be266f4e29d19a7f1e66';
export default node;
