import React from "react"

import { ProposalPastduePayment } from "src/components"

import { Table, List } from "../../organisms/PortfolioTable/Elements"
import {
  divideNumber,
  getPaymentDate,
} from "../../organisms/PortfolioTable/Tables/utils"

import { PaymentCard } from "./Elements"

import {
  TData,
  TDDate,
  TDStatus,
  DesktopContainer,
  MobileContainer,
} from "./styles"
import statuses from "./statuses"

import { getPastduePayment } from "./utils"

function OwnerPaymentsGraph(props) {
  const { items, paymentInfo } = props
  const pastduePayment = getPastduePayment(items)

  function getColumns() {
    return [
      {
        title: "Дата платежа",
        render: (i, obj) => <TDDate key={i}>{getPaymentDate(obj.date)}</TDDate>,
      },
      {
        title: "Дата оплаты",
        render: (i, obj) => (
          <TDDate key={i}>{getPaymentDate(obj.date, obj.payDate)}</TDDate>
        ),
      },
      {
        title: "Сумма платежа",
        render: (i, obj) => (
          <TData key={i}>{divideNumber(obj.total_gross.toFixed(2))}</TData>
        ),
      },
      {
        title: "Основной долг",
        render: (i, obj) => (
          <TData key={i}>{divideNumber(obj.loan.toFixed(2))}</TData>
        ),
      },
      {
        title: "Проценты",
        render: (i, obj) => (
          <TData key={i}>{divideNumber(obj.interest.toFixed(2))}</TData>
        ),
      },
      {
        title: "Остаток долга",
        render: (i, obj) => (
          <TData key={i}>{divideNumber(obj.debt.toFixed(2))}</TData>
        ),
      },
      {
        title: "Статус",
        render: (i, obj) => {
          const status = statuses.get(obj.state)
          return (
            <TDStatus color={status.color} key={i}>
              {status.text}
            </TDStatus>
          )
        },
      },
    ]
  }

  return (
    <>
      {pastduePayment && (
        <ProposalPastduePayment item={pastduePayment} info={paymentInfo} />
      )}

      <DesktopContainer>
        <Table columns={getColumns()} items={items} />
      </DesktopContainer>

      <MobileContainer>
        <List items={items} Render={PaymentCard} />
      </MobileContainer>
    </>
  )
}

export default OwnerPaymentsGraph
