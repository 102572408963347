import React, { useCallback, useState, useMemo } from "react"
import Relay, { graphql } from "react-relay"

import { Route } from "react-router"

import { ExchangeRateProvider } from "src/context"
import { isRussianUser } from "src/utils"
import { NewPasswordModal } from "src/components"

import {
  Aggregator,
  LogoContainer,
  HeaderContainer,
  HeaderWrap,
  ActionWrap,
  LogoLink,
  ContinueRegistrationLink,
  ButtonsContainer,
  ProfileLink,
  ProfileIcon,
} from "./styles"

import {
  NavItems,
  AccountMenu,
  ProfileList,
  BurgerMenu,
  MobMenu,
  NewLoanLink,
} from "./Elements"

import {
  facebook, email, medium, telegram, Logo,
} from "./icons"

import { CreateNewProfile } from "./Elements/modals"

const HeaderLogo = (props: any) => (
  <LogoContainer isHidden={props.isHidden}>
    <LogoLink to="/market">
      <Logo color={props.color} />
    </LogoLink>
  </LogoContainer>
)

const linksSocial = [
  { icon: email, link: "mailto:hello@karma.red" },
  // { icon: facebook, link: "https://www.facebook.com/KarmaTrustRu" },
  { icon: medium, link: "https://medium.com/karmared" },
  { icon: telegram, link: "https://t.me/karmainvest" },
]

const Header = (props) => {
  const { history } = props
  const hasProfiles = useMemo(() => props.viewer.profiles.length > 0, [
    props.viewer.profiles,
  ])

  const activeProfile = window.localStorage.getItem("activeProfile")
  if (props.viewer && hasProfiles && !activeProfile) {
    const { id } = props.viewer.profiles[0]
    window.localStorage.setItem("activeProfile", id)
  }

  const [accountLink, setAccountLink] = useState(
    window.localStorage.getItem("activeProfile"),
  )
  const [menuActive, setMenuActive] = useState(false)
  const [isScrolling, setScrolling] = useState(false)
  const [modalIsOpened, setModalIsOpened] = useState(false)
  const [passwordModalIsOpened, setPasswordModalIsOpened] = useState(false)

  const toChangeEmail = useCallback(() => history.push("/email"), [history])

  React.useEffect(() => {
    function listener() {
      if (window.pageYOffset > 50) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.onscroll = () => {
      listener()
    }

    return () => {
      window.onscroll = null
    }
  }, [])

  // временная фигня, чтобы не показывать хедер на /join маршрутах
  if (window.location.pathname.includes("/join")) {
    return null
  }

  if (!props.viewer) {
    return null
  }

  function openModal(cb) {
    if (cb) {
      cb()
    }

    setModalIsOpened(true)
  }

  function logout() {
    history.push("/logout")
  }

  function openPasswordModal() {
    setPasswordModalIsOpened(true)
  }

  const createNewProfile = () => {
    if (!isRussianUser(props.viewer)) {
      history.push("/profiles/create/foreign")
    } else {
      setModalIsOpened(true)
    }
  }

  return (
    props.viewer && (
      <ExchangeRateProvider>
        <Aggregator />
        <HeaderContainer isScrolling={isScrolling}>
          <HeaderWrap>
            <BurgerMenu handleClick={setMenuActive} />

            <HeaderLogo isHidden={hasProfiles} />
            <NavItems {...props} />
            {/* <IconLinkList data={linksSocial} title="social" /> */}
            {/* <IconLinkList data={linksApps} title="application" /> */}

            <ButtonsContainer isHidden={hasProfiles}>
              <NewLoanLink {...props} />
              {!hasProfiles && (
                <ContinueRegistrationLink onClick={createNewProfile}>
                  Создать профиль
                </ContinueRegistrationLink>
              )}
            </ButtonsContainer>

            {hasProfiles && (
              <>
                <ProfileList
                  viewer={props.viewer}
                  setAccountLink={setAccountLink}
                  openModal={openModal}
                />

                <ProfileLink to={`/accounts/${accountLink}`}>
                  <ProfileIcon />
                </ProfileLink>
              </>
            )}

            <ActionWrap hasProfiles={hasProfiles}>
              {/* <LocaleSwitcher /> */}
              <AccountMenu
                logout={logout}
                openPasswordModal={openPasswordModal}
                viewer={props.viewer}
                history={history}
                toChangeEmail={toChangeEmail}
              />
            </ActionWrap>
          </HeaderWrap>
        </HeaderContainer>

        {menuActive && (
          <MobMenu
            icons={[{ title: "social", data: linksSocial, size: 40 }]}
            viewer={props.viewer}
            handle={setMenuActive}
            logout={logout}
            openPasswordModal={openPasswordModal}
            toChangeEmail={toChangeEmail}
          />
        )}

        <CreateNewProfile
          viewer={props.viewer}
          isOpened={modalIsOpened}
          onClose={() => setModalIsOpened(false)}
        />

        <NewPasswordModal
          isOpened={passwordModalIsOpened}
          onClose={() => setPasswordModalIsOpened(false)}
        />
      </ExchangeRateProvider>
    )
  )
}

function Render(props) {
  return (
    <Route>{({ history }) => <Header {...props} history={history} />}</Route>
  )
}

export default Relay.createFragmentContainer(Render, {
  viewer: graphql`
    fragment User_viewer on User {
      ...AccountMenu_viewer
      id
      email
      country
      trust {
        isAvailable
      }
      profiles {
        id
        __typename
        approvedAsInvestor
        accountBalanceDetailed {
          currency
        }
        investor {
          canBeInvestor
        }
        ... on IndividualProfile {
          name
          accountBalance
          accountBalanceDetailed {
            available
            currency
          }
          bankAccounts {
            id
            status
          }
          accreditation(role: INVESTOR) {
            status
          }
        }
        ... on ForeignIndividualProfile {
          name
          accountBalanceDetailed {
            available
            currency
          }
          accreditation(role: INVESTOR) {
            status
          }
        }
        ... on EntrepreneurProfile {
          name
          phone
          accountBalance
          accountBalanceDetailed {
            available
            currency
          }
          bankAccounts {
            id
            status
          }
          accreditation(role: INVESTOR) {
            status
          }
        }
        ... on LegalEntityProfile {
          name
          phone
          accountBalance
          accountBalanceDetailed {
            available
            currency
          }
          accreditation(role: BORROWER) {
            status
          }
        }
      }
      ...ExpressQuestionary_viewer
    }
  `,
})
