import styled from "styled-components"
import { color } from "styled-system"

export const Container = styled.label`
  color: ${({ theme }) => theme.colors.red};
  display: flex;
  ${color};

  & > * #check {
    opacity: 0;
  }

  & > :checked + * #check {
    opacity: 1;
  }
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
`
