import styled, { keyframes } from "styled-components"

/* eslint-disable max-len */
const LoadingKeyframes = keyframes`
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 -0.3em, 3em 0 0 -0.25em, 2em 2em 0 -0.2em, 0 3em 0 -0.15em, -2em 2em 0 -0.1em, -3em 0 0 0em, -2em -2em 0 0.1em;
  }
  12.5% {
    box-shadow: 0 -3em 0 0.1em, 2em -2em 0 0.2em, 3em 0 0 -0.3em, 2em 2em 0 -0.25em, 0 3em 0 -0.2em, -2em 2em 0 -0.15em, -3em 0 0 -0.1em, -2em -2em 0 0em;
  }
  25% {
    box-shadow: 0 -3em 0 0em, 2em -2em 0 0.1em, 3em 0 0 0.2em, 2em 2em 0 -0.3em, 0 3em 0 -0.25em, -2em 2em 0 -0.2em, -3em 0 0 -0.15em, -2em -2em 0 -0.1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -0.1em, 2em -2em 0 0em, 3em 0em 0 0.1em, 2em 2em 0 0.2em, 0 3em 0 -0.3em, -2em 2em 0 -0.25em, -3em 0em 0 -0.2em, -2em -2em 0 -0.15em;
  }
  50% {
    box-shadow: 0 -3em 0 -0.15em, 2em -2em 0 -0.1em, 3em 0 0 0em, 2em 2em 0 0.1em, 0 3em 0 0.2em, -2em 2em 0 -0.3em, -3em 0em 0 -0.25em, -2em -2em 0 -0.2em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -0.2em, 2em -2em 0 -0.15em, 3em 0 0 -0.1em, 2em 2em 0 0em, 0 3em 0 0.1em, -2em 2em 0 0.2em, -3em 0 0 -0.3em, -2em -2em 0 -0.25em;
  }
  75% {
    box-shadow: 0em -3em 0 -0.25em, 2em -2em 0 -0.2em, 3em 0em 0 -0.15em, 2em 2em 0 -0.1em, 0 3em 0 0em, -2em 2em 0 0.1em, -3em 0em 0 0.2em, -2em -2em 0 -0.3em;
  }
  87.5% {
    box-shadow: 0em -3em 0 -0.3em, 2em -2em 0 -0.25em, 3em 0 0 -0.2em, 2em 2em 0 -0.15em, 0 3em 0 -0.1em, -2em 2em 0 0em, -3em 0em 0 0.1em, -2em -2em 0 0.2em;
  }
`

const MiniLoader = styled.div<any>`
  color: black;
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: ${LoadingKeyframes} 1.1s infinite linear;
  transform: translateZ(0);
  display: ${({ display }) => display};

  ${({ changed, fontSize, margin }) => changed
    && `
    @media screen and (max-width: 800px) {
      font-size: ${fontSize};
      margin: ${margin};
    }
  `}

  ${({ changed }) => !changed
    && `
    @media screen and (max-width: 800px) {
      font-size: 8px;
      margin: 39px 10px;
    }
  `}
`

MiniLoader.defaultProps = {
  margin: "36px 10px",
  display: "block",
  fontSize: "4px",
}

export default MiniLoader
