import React from "react"
import styled from "styled-components"

const Glyph = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="6px">
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 0L7 6 1 0"
    />
  </svg>
)

const GlyphContainer = styled.div<any>`
  transform: ${({ optionsVisible }) => {
    if (optionsVisible) return "rotate(180deg)"
    return "rotate(0)"
  }};

  svg {
    stroke: ${({ strokeColor, theme }) => theme.colors[strokeColor]};
  }
`

const ArrowDown = (props) => (
  <GlyphContainer {...props}>
    <Glyph />
  </GlyphContainer>
)

ArrowDown.defaultProps = {
  strokeColor: "black",
}

export default ArrowDown
