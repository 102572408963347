/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrderDocumentTypeEnum = "BORROWER_FEE" | "BORROWER_OFFER" | "BORROWER_PAYMENT" | "GENERATED" | "INVESTOR_PAYMENT" | "%future added value";
export type modalUtilsQueryVariables = {
    id: string;
    type: OrderDocumentTypeEnum;
};
export type modalUtilsQueryResponse = {
    readonly node: {
        readonly __typename: "Order";
        readonly fee: number | null;
        readonly document: {
            readonly url: string;
            readonly type: OrderDocumentTypeEnum;
        } | null;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
};
export type modalUtilsQuery = {
    readonly response: modalUtilsQueryResponse;
    readonly variables: modalUtilsQueryVariables;
};



/*
query modalUtilsQuery(
  $id: ID!
  $type: OrderDocumentTypeEnum!
) {
  node(id: $id) {
    __typename
    ... on Order {
      fee
      document(type: $type) {
        url
        type
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type",
    "type": "OrderDocumentTypeEnum!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "modalUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GeneratedDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "modalUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "GeneratedDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Order"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "modalUtilsQuery",
    "operationKind": "query",
    "text": "query modalUtilsQuery(\n  $id: ID!\n  $type: OrderDocumentTypeEnum!\n) {\n  node(id: $id) {\n    __typename\n    ... on Order {\n      fee\n      document(type: $type) {\n        url\n        type\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '81344fd327260431e0e38812005bd684';
export default node;
