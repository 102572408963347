import React from "react"

import { Translate } from "src/components"

import {
  Flex, LeftText, RightText, Empty,
} from "./styles"

function TextField(props) {
  const { data } = props
  const { label, value } = data

  return (
    <Flex>
      <LeftText>
        <Translate i18n={label} />
      </LeftText>
      {value && (
        <RightText>
          <Translate i18n={value} />
        </RightText>
      )}
      {!value && <Empty>Заполните данные</Empty>}
    </Flex>
  )
}

export default TextField
