/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateOrderApplicationInput = {
    orderId: string;
    application: string;
    attachments: Array<string>;
    isDraft?: boolean | null;
    shortTitle?: string | null;
    longTitle?: string | null;
};
export type UpdateOrderApplicationMutationVariables = {
    input: UpdateOrderApplicationInput;
};
export type UpdateOrderApplicationMutationResponse = {
    readonly updateOrderApplication: {
        readonly order: {
            readonly id: string;
            readonly profile: {
                readonly id: string;
                readonly name: string;
                readonly creditRating: {
                    readonly rating: string;
                } | null;
            };
            readonly chain: {
                readonly id: string;
                readonly gatheredAmount: number;
                readonly investorsCount: number;
            } | null;
            readonly offer: {
                readonly attachments: ReadonlyArray<{
                    readonly id: string;
                    readonly url: string;
                    readonly filename: string;
                }>;
                readonly data: string;
            } | null;
            readonly application: {
                readonly attachments: ReadonlyArray<{
                    readonly id: string;
                    readonly url: string;
                    readonly filename: string;
                }>;
                readonly data: string;
            } | null;
            readonly creditRating: {
                readonly rating: string;
            } | null;
        };
    };
};
export type UpdateOrderApplicationMutation = {
    readonly response: UpdateOrderApplicationMutationResponse;
    readonly variables: UpdateOrderApplicationMutationVariables;
};



/*
mutation UpdateOrderApplicationMutation(
  $input: UpdateOrderApplicationInput!
) {
  updateOrderApplication(input: $input) {
    order {
      id
      profile {
        __typename
        id
        name
        creditRating {
          rating
        }
      }
      chain {
        id
        gatheredAmount
        investorsCount
      }
      offer {
        attachments {
          id
          url
          filename
        }
        data
        id
      }
      application {
        attachments {
          id
          url
          filename
        }
        data
        id
      }
      creditRating {
        rating
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrderApplicationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditRating",
  "kind": "LinkedField",
  "name": "creditRating",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderChainInfo",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gatheredAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "investorsCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrderApplicationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrderApplicationPayload",
        "kind": "LinkedField",
        "name": "updateOrderApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrderApplicationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrderApplicationPayload",
        "kind": "LinkedField",
        "name": "updateOrderApplication",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderOffer",
                "kind": "LinkedField",
                "name": "offer",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderApplication",
                "kind": "LinkedField",
                "name": "application",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateOrderApplicationMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrderApplicationMutation(\n  $input: UpdateOrderApplicationInput!\n) {\n  updateOrderApplication(input: $input) {\n    order {\n      id\n      profile {\n        __typename\n        id\n        name\n        creditRating {\n          rating\n        }\n      }\n      chain {\n        id\n        gatheredAmount\n        investorsCount\n      }\n      offer {\n        attachments {\n          id\n          url\n          filename\n        }\n        data\n        id\n      }\n      application {\n        attachments {\n          id\n          url\n          filename\n        }\n        data\n        id\n      }\n      creditRating {\n        rating\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '686278df891a6ef15d3337c56216d7ae';
export default node;
