import styled from "styled-components"
import { fontSize } from "styled-system"

import { StatusMessageColorsSchema } from "./constants"

const getColor = (args) => {
  const { theme, status } = args
  const colorName = StatusMessageColorsSchema.get(status)

  const color = colorName !== undefined && colorName !== null
    ? theme.colors[colorName]
    : theme.colors.greyDark

  return `color: ${color};`
}

export const Wrapper = styled.span<any>`
  ${fontSize}
  ${getColor}
`

export const StatusMessageContainer = styled.span`
  display: inline-block;
  padding-right: 4px;
`
