import { getProperty } from "src/utils"

import { InitialState } from "./constants"

/*
* export const KYCDocument = {
  type: "",
  attachments: [],
}

export const InitialState = {
  id: "",
  phone: "",
  selfie: "",
  lastName: "",
  firstName: "",
  documents: [KYCDocument],
}
* */

/*
 * -> YYYY-MM-DD
 * <- DD - MM - YYYY
 * */
const dateToFormState = (date) => {
  if (typeof date === "string") {
    return date.split("-").reverse().join(" - ")
  }

  return date
}

/*
 * -> DD - MM - YYYY
 * <- YYYY-MM-DD
 * */
const formStateToDate = (date) => {
  if (!date) return ""

  if (typeof date === "string") {
    return date.split(" - ").reverse().join("-").replace(/_/gim, "")
      .trim()
  }

  return date
}

export const formStateToAttachments = (attachments) => {
  if (!Array.isArray(attachments)) return null

  return attachments.map((doc) => {
    if (typeof doc === "string") {
      return doc
    }
    const { id: injectedId } = doc

    return injectedId
  })
}

const territoryResidenceDocumentToFormState = (doc) => {
  if (!doc) return { ...InitialState.territoryResidenceDocument }

  const { dateFrom, dateTo, ...rest } = doc

  return {
    ...InitialState.territoryResidenceDocument,
    ...rest,
    dateFrom: dateToFormState(dateFrom),
    dateTo: dateToFormState(dateTo),
  }
}

export const formStateToTerritoryResidenceDocument = (state) => {
  if (!state) return null

  const {
    dateFrom, dateTo, attachments: injectedAttachemtns, ...rest
  } = state

  const attachments = formStateToAttachments(injectedAttachemtns)

  if (attachments === null) return null

  return {
    ...rest,
    dateFrom: formStateToDate(dateFrom),
    dateTo: formStateToDate(dateTo),
    attachments,
  }
}

const migrationCardToFormState = (migrationCard) => {
  if (!migrationCard) return { ...InitialState.migrationCard }

  const { dateFrom, dateTo, ...rest } = migrationCard

  return {
    ...InitialState.migrationCard,
    ...rest,
    dateFrom: dateToFormState(dateFrom),
    dateTo: dateToFormState(dateTo),
  }
}

export const formStateToMigrationCard = (state) => {
  if (!state) return null

  const {
    dateFrom, dateTo, attachments: injectedAttachemtns, ...rest
  } = state

  const attachments = formStateToAttachments(injectedAttachemtns)

  if (attachments === null) return null

  return {
    ...rest,
    dateFrom: formStateToDate(dateFrom),
    dateTo: formStateToDate(dateTo),
    attachments,
  }
}

const selfieToState = (selfie) => {
  if (selfie) {
    return [selfie]
  }

  return []
}

export const initialStateFromProps = (props) => {
  const { profile } = props

  if (!profile) return { ...InitialState }

  const {
    migrationCard,
    territoryResidenceDocument,
    hasUSACitizenship,
    selfie: injectedSelfie,
    internationalPhone: phone,
    residenceDocument: injectedResidenceDocument,
    registrationAddress: injectdedRegistrationAddress,
    ...restInternational
  } = profile

  return {
    ...restInternational,
    phone,
    selfie: selfieToState(injectedSelfie),
    hasUSACitizenship: !hasUSACitizenship,
    migrationCard: migrationCardToFormState(migrationCard),
    residenceDocument: selfieToState(injectedResidenceDocument),
    registrationAddress: injectdedRegistrationAddress || {
      ...InitialState.registrationAddress,
    },
    territoryResidenceDocument: territoryResidenceDocumentToFormState(
      territoryResidenceDocument,
    ),
  }
}

export const performFormStateChangeEffects = (formState) => {
  const result = { ...formState }
  if (!result.isRussiaTaxResident) {
    result.residenceDocument = null
    result.taxResidencyApplication = null
  }

  return result
}

export const getProfileIdFromCreateMutation = (data) =>
  getProperty(data, "createForeignIndividualProfile.profile.id", null)
