import Empty from "./Empty"
import Initial from "./Initial"
import Pending from "./Pending"
import Declined from "./Declined"
import EmptyList from "./EmptyList"

const render = () => null

render.EMPTY = Empty
render.INITIAL = Initial
render.PENDING = Pending
render.DECLINED = Declined
render.EmptyList = EmptyList

export default render
