import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"

import { OutlinedButton } from "src/components"

export const ProfileListContainer = styled.div`
  color: #4a4a4a;
  font-family: Geometria;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  width: 220px;
  height: 78px;
  position: relative;
  flex-shrink: 0;
  z-index: 99;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  margin-left: 55px;
  margin-right: 55px;

  @media screen and (max-width: 1000px) {
    margin-right: 30px;
  }

  @media screen and (max-width: 900px) {
    margin-left: -15px;
    height: 68px;
    width: 200px;
  }

  @media screen and (max-width: 900px) {
    margin-left: auto;
    margin-right: 0;
  }
`
export const ProfileListWrap = styled.div<any>`
  position: absolute;
  background: #ffffff;
  padding: 14px 15px 0px;
  width: 100%;
  border-radius: 5px;
  box-shadow: ${(props) => (props.active && "0px 1px 10px rgba(0, 0, 0, 0.2)") || "none"};

  ${({ active }) => active
    && css`
      padding-bottom: 22px;
    `};
`
export const ProfileName = styled.div`
  line-height: 13px;
  font-size: 10px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    line-height: 18px;
    font-size: 14px;
  }
`

export const ProfileNameLink = styled(NavLink)`
  text-decoration: underline;
  color: #4a4a4a;

  @media screen and (max-width: 480px) {
    text-decoration: none;
  }
`

export const ProfileWallet = styled.div`
  padding-top: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    line-height: 23px;
    font-size: 18px;
  }
`
export const ProfileHandle = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`
export const ProfileListItem = styled.div`
  width: 100%;
`
export const ProfileListHandleWrap = styled.div`
  position: relative;
  padding-top: 20px;
  & > ${ProfileListItem} {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(74, 74, 74, 0.1);
    ${ProfileWallet} {
      font-weight: normal;
    }
    &:first-child {
      border-top: 1px solid rgba(74, 74, 74, 0.1);
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`
export const ProfileListInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AddButton = styled(OutlinedButton)`
  width: 100%;
  margin-top: 20px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[8]};
  padding: 11px 0 10px;
`
