import React from "react"

import { Link, LinkContainer, ArrowContainer } from "./styles"

const Arrow = ({ variant }) => (
  <ArrowContainer variant={variant}>
    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <g
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4.5 4l4 4-4 4M8.5 4l4 4-4 4" />
      </g>
    </svg>
  </ArrowContainer>
)

const ArrowLink = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children, variant, href, ...rest
}) => (
  <LinkContainer>
    <Link variant={variant} href={href} target="_blank">
      {children}
    </Link>
    <Arrow variant={variant} />
  </LinkContainer>
)

ArrowLink.defaultProps = {
  variant: "white",
}

export default ArrowLink
