import styled from "styled-components"
import { screenSizes } from "../Enums/screenSizes"

export default styled.div`
  margin-bottom: 90px;
  @media screen and (max-width: ${screenSizes.tablet}) {
    margin-top: 90px;
  }
  /* padding: 30px 50px; */
`
