/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SetViewerPasswordInput = {
    password: string;
    current_password?: string | null;
};
export type SetViewerPasswordMutationVariables = {
    input: SetViewerPasswordInput;
};
export type SetViewerPasswordMutationResponse = {
    readonly setViewerPassword: {
        readonly viewer: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"Form_viewer">;
        };
    };
};
export type SetViewerPasswordMutation = {
    readonly response: SetViewerPasswordMutationResponse;
    readonly variables: SetViewerPasswordMutationVariables;
};



/*
mutation SetViewerPasswordMutation(
  $input: SetViewerPasswordInput!
) {
  setViewerPassword(input: $input) {
    viewer {
      id
      ...Form_viewer
    }
  }
}

fragment Form_viewer on User {
  password {
    present
    updated_at
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetViewerPasswordInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetViewerPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetViewerPasswordPayload",
        "kind": "LinkedField",
        "name": "setViewerPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Form_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetViewerPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetViewerPasswordPayload",
        "kind": "LinkedField",
        "name": "setViewerPassword",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserPassword",
                "kind": "LinkedField",
                "name": "password",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "present",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updated_at",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetViewerPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation SetViewerPasswordMutation(\n  $input: SetViewerPasswordInput!\n) {\n  setViewerPassword(input: $input) {\n    viewer {\n      id\n      ...Form_viewer\n    }\n  }\n}\n\nfragment Form_viewer on User {\n  password {\n    present\n    updated_at\n  }\n}\n"
  }
};
})();
(node as any).hash = '9007c1907ec7ead849f94c502c7a0b83';
export default node;
