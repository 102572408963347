import { NavLink } from "react-router-dom"
import styled from "styled-components"

import { Box, Text, Flex } from "src/components"

export const Container = styled.div<any>`
  width: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 40px;

  @media screen and (max-width: 800px) {
    width: 225px;
    margin-right: 15px;
  }

  @media screen and (max-width: 800px) {
    display: ${(props) => (props.isVisible && "flex") || "none"};
    width: 100%;
    margin: 0;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const ShadedText = styled(Text)`
  font-size: 14px;
  line-height: 1.45;
  color: ${({ theme }) => theme.colors.greyDark};
`

export const WordWrapContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  word-break: break-all;
`

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &.active > div {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

export const ItemContainer = styled(Flex)`
  margin: 0 -30px;
  padding: 10px 30px;
  cursor: pointer;
  background-color: ${({ theme, active }) => theme.colors[active ? "greyLight" : "white"]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`

export const GearContainer = styled(Box)`
  margin: 2px 0 0 7px;
  cursor: pointer;
`

export const StyledEnterSVG = styled.svg`
  min-width: 24px;
  min-height: 24px;
`
