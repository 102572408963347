import React from "react"

import {
  StyledImage,
  LoopContainer,
  ItemContainer,
  BackgroundMask,
} from "./styles"

const LoopIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.011 32.51">
    <g transform="translate(-987 -1738)">
      <g transform="translate(988 1739)">
        <circle
          cx="12"
          cy="12"
          r="12"
          fill="none"
          stroke="#fff"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <path fill="#fff" transform="translate(11 7)" d="M0 0h2v10H0z" />
        <path fill="#fff" transform="rotate(90 3 14)" d="M0 0h2v10H0z" />
        <path
          fill="#fff"
          transform="rotate(-45 34.728 -12.471)"
          d="M0 0h2v15H0z"
        />
      </g>
    </g>
  </svg>
)

export const ItemHoverMask = () => (
  <BackgroundMask>
    <LoopContainer>
      <LoopIcon />
    </LoopContainer>
  </BackgroundMask>
)

export const renderImageItem = (props) => {
  const { idx, item, onClick } = props

  return (
    <ItemContainer onClick={() => onClick && onClick(item, idx)}>
      <StyledImage src={item.url} />
      <ItemHoverMask />
    </ItemContainer>
  )
}
