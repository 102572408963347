import React, { useContext } from "react"

import { CurrentProfileContext } from "src/context"
import { MiniLoader } from "src/components"
import {
  getOrderTicker, toCurrencyFormat, getDebtURL, findPaymentListByProfile,
} from "src/utils"

import {
  Card,
  CardName,
  CardData,
  CardTitle,
  CardValue,
  CardStatus,
  CardStatusValue,
  CardButton,
  CardButtonsWrapper,
  Graph,
  CardCover,
  DebtLink,
} from "./styles"
import { CalendarIcon } from "./icons"

import {
  getOrderName, getOrderRate, getPaymentAmount, getOrdernFinishDate, getNotPaidAmount,
} from "../Tables/utils"
import { PunishmentStatusContainer } from "../Tables/styles"
import { PunishmentStatus } from "../Elements"

function InvestmentPunishedCard(props) {
  const { toProposal } = props

  const { profile = {} } = useContext(CurrentProfileContext) as any
  const { id: profileId } = profile

  const paymentScheduleList = findPaymentListByProfile(profileId, props.paymentScheduleList || []) || {}

  // eslint-disable-next-line camelcase
  const pastDueDays = paymentScheduleList?.info?.pastdue_days ?? "-"

  const returnedLoan = getPaymentAmount(props, profileId, "loan")
  const notPaidIntereset = getNotPaidAmount(props, profileId, "interest")

  return (
    <Card>
      <CardName>{getOrderName(props)}</CardName>

      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>{getOrderTicker(props, !!props.cession)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>{getOrderRate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Долг, ₽</CardTitle>
        <CardValue>{toCurrencyFormat(props.loan - returnedLoan + notPaidIntereset)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardValue>{toCurrencyFormat(getPaymentAmount(props, profileId))}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>{getOrdernFinishDate(props)}</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Дней просрочки</CardTitle>
        <CardValue>{pastDueDays}</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={"#FF0505"}>
          <DebtLink href={getDebtURL(props.chain?.id)} target="_blank" rel="noreferrer noopener">
            <PunishmentStatusContainer justifyContent="flex-end">
              <div>
                <Graph>{CalendarIcon}</Graph>
              </div>
              <div>
                <PunishmentStatus punishment={props.punishment} />
              </div>
            </PunishmentStatusContainer>
          </DebtLink>
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton onClick={() => toProposal(props)}>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

function Placeholder() {
  return (
    <Card>
      <CardCover>
        <MiniLoader changed fontSize="8px" margin="auto" />
      </CardCover>

      <CardName>_</CardName>
      <CardData>
        <CardTitle>№ заявки</CardTitle>
        <CardValue>_</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Ставка</CardTitle>
        <CardValue>0%</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Долг, ₽</CardTitle>
        <CardValue>0</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Выплачено, ₽</CardTitle>
        <CardValue>0</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Погашение займа</CardTitle>
        <CardValue>-</CardValue>
      </CardData>

      <CardData>
        <CardTitle>Дней просрочки</CardTitle>
        <CardValue>-</CardValue>
      </CardData>

      <CardStatus>
        <CardTitle>Статус</CardTitle>
        <CardStatusValue color={"#FF0505"}>
          <PunishmentStatusContainer justifyContent="flex-end">
            <div>
              <Graph>{CalendarIcon}</Graph>
            </div>
            <div>
              <PunishmentStatus punishment={{}} />
            </div>
          </PunishmentStatusContainer>
        </CardStatusValue>
      </CardStatus>

      <CardButtonsWrapper>
        <CardButton>Подробнее</CardButton>
      </CardButtonsWrapper>
    </Card>
  )
}

InvestmentPunishedCard.placeholder = Placeholder

export default InvestmentPunishedCard
