import colors from "./colors"

export default {
  outlinedWhite: {
    backgroundColor: "transparent",
    borderColor: colors.white,
    color: colors.white,
    padding: 0,
    paddingTop: "4px",
  },

  outlinedBlack: {
    backgroundColor: "transparent",
    borderColor: colors.primaryBlacks[3],
    color: colors.primaryBlack,
    padding: 0,
    paddingTop: "4px",
  },

  outlinedRed: {
    backgroundColor: "transparent",
    borderColor: colors.primaryRed,
    color: colors.primaryRed,
    padding: 0,
    paddingTop: "4px",
  },

  default: {
    backgroundColor: colors.white,
    borderColor: colors.grey,
    color: colors.black,

    "&:hover:not(:disabled)": {
      backgroundColor: colors.white,
      borderColor: colors.greyDarkest,
      color: colors.black,
    },
  },

  withShadow: {
    border: "none",
    boxShadow: "0 1px 0px rgba(0,0,0,0.16), 0 1px 1px rgba(0,0,0,0.23)",
    backgroundColor: "none",
    padding: 0,

    "&:active": {
      backgroundColor: colors.greyLights[3],
      boxShadow: "0 2px 2px rgba(0,0,0,0.16), 0 2px 6px rgba(0,0,0,0.23)",
    },

    "&:hover:not(:disabled)": {
      boxShadow: "0 2px 2px rgba(0,0,0,0.16), 0 2px 6px rgba(0,0,0,0.23)",
    },
  },

  defaultThin: {
    backgroundColor: colors.white,
    borderColor: colors.greyDark,
    display: "inline-flex",
    color: colors.black,
    textAlign: "left",
    height: "auto",
    padding: "7px 9px",
    flexDirection: "row",
    justifyContent: "flex-start",

    "&:hover:not(:disabled)": {
      backgroundColor: colors.white,
      borderColor: colors.greyDarkest,
      color: colors.black,
    },

    "& p": {
      display: "inline-block",
    },
  },

  defaultThinWhite: {
    backgroundColor: "transparent",
    borderColor: colors.white,
    display: "inline-flex",
    color: colors.white,
    padding: "5px 12px",
    height: "28px",
    fontSize: "12px",

    "&:hover:not(:disabled)": {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.greyDarkest,
    },
  },

  defaultBlue: {
    height: "34px",
    backgroundColor: colors.white,
    borderColor: colors.blue,
    color: colors.blue,

    "&:hover:not(:disabled)": {
      backgroundColor: colors.blue,
      borderColor: colors.blue,
      color: colors.white,
    },
  },

  blueWide: {
    height: "48px",
    backgroundColor: colors.blue,
    borderColor: colors.blue,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.blue,
      color: colors.blue,
    },
  },

  ginger: {
    backgroundColor: colors.ginger,
    borderColor: colors.ginger,
    color: colors.white,
    textDecoration: "none",

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.ginger,
      color: colors.ginger,
    },
  },

  gingerThin: {
    backgroundColor: colors.ginger,
    borderColor: colors.ginger,
    color: colors.white,
    textDecoration: "none",
    height: "32px",

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.ginger,
      color: colors.ginger,
    },
  },

  primary: {
    backgroundColor: colors.red,
    borderColor: colors.red,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.red,
      color: colors.red,
    },
  },

  secondary: {
    backgroundColor: "transparent",
    borderColor: colors.red,
    color: colors.red,

    "&:hover:not(:disabled)": {
      backgroundColor: colors.red,
      borderColor: colors.red,
      color: colors.white,
    },
  },

  white: {
    backgroundColor: "transparent",
    borderColor: colors.white,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: colors.white,
      borderColor: colors.white,
      color: colors.black,
    },
  },

  green: {
    backgroundColor: colors.green,
    borderColor: colors.green,
    color: colors.white,

    "&:hover:not(:disabled)": {
      borderColor: colors.green,
      backgroundColor: "transparent",
      color: colors.green,
    },
  },

  red: {
    backgroundColor: colors.red,
    borderColor: colors.red,
    color: colors.white,

    "&:hover:not(:disabled)": {
      borderColor: colors.red,
      backgroundColor: "transparent",
      color: colors.red,
    },
  },

  redTransparent: {
    backgroundColor: "transparent",
    borderColor: colors.red,
    color: colors.red,

    "&:hover:not(:disabled)": {
      backgroundColor: colors.red,
      color: colors.white,
    },
  },

  transparent: {
    backgroundColor: "transparent",
    border: "none",
    color: "transparent",
    padding: "10px",

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      color: "transparent",
      border: "none",
    },
  },

  transparentWidthBg: {
    backgroundColor: "transparent",
    border: "none",
    color: "transparent",

    "&:hover:not(:disabled)": {
      backgroundColor: colors.grey,
      color: "transparent",
      border: "none",
    },
  },

  grey: {
    backgroundColor: colors.grey,
    color: colors.black,
    borderColor: colors.black,
  },

  whiteRounded: {
    backgroundColor: colors.white,
    boxShadow: "0 2px 4px 0 rgba(0, 0 ,0, .16)",
    fontSize: "14px",
    height: "32px",
    lineHeight: "16px",
    padding: "0 12px 0 16px",
    color: "#353a43",

    "&:hover:not(:disabled)": {
      color: "#345a96",
    },
  },

  socialFb: {
    backgroundColor: colors.fb,
    borderColor: colors.fb,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.fb,
      color: colors.fb,
    },

    "&:hover:not(:disabled) svg path": {
      fill: colors.fb,
    },
  },

  socialVk: {
    backgroundColor: colors.vk,
    borderColor: colors.vk,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.vk,
      color: colors.vk,
    },

    "&:hover:not(:disabled) svg path": {
      fill: colors.vk,
    },
  },

  socialSs: {
    backgroundColor: colors.ss,
    borderColor: colors.ss,
    color: colors.white,

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: colors.ss,
      color: colors.ss,
    },

    "&:hover:not(:disabled) svg path": {
      fill: colors.ss,
    },
  },

  empty: {
    backgroundColor: "none",
    borderColor: "none",
    border: "none",
    color: colors.black,
    padding: "0",
    fontSize: "13px",

    "&:hover:not(:disabled)": {
      backgroundColor: "transparent",
      borderColor: "none",
      textDecoration: "underline",
    },
  },

  landingRed: {
    height: "50px",
    padding: "0 75px",
    minWidth: "230px",
    maxWidth: "100%",
    border: "none",
    borderRadius: "5px",
    fontFamily: "inherit",
    fontWeight: "800",
    color: colors.white,
    textTransform: "uppercase",
    backgroundColor: colors.red,
    letterSpacing: "1.5px",

    "&::disabled": {
      opacity: "0.5",
    },
  },
}
