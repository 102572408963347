import React from "react"

import { Container, Link, Empty } from "./styles"

function AttachmentField(props) {
  const { value } = props.data

  return (
    <Container>
      {value && (
        <Link target="_blank" href={value.url}>
          {value.filename}
        </Link>
      )}

      {!value && <Empty>Заполните данные</Empty>}
    </Container>
  )
}

export default AttachmentField
