import React from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"

import { pipe } from "src/utils"
import { UploadFile } from "src/mutations"
import { useStatelessMutation } from "src/hooks"

const defaultErrorMessage = "Не удалось загрузить файл"

const promisify = (mutation) => (data) => new Promise((resolve, reject) => {
  mutation.commit({
    ...data,
    callbacks: {
      onCompleted: (payload) => resolve(payload),
      onError: (transaction) => reject(transaction),
    },
  })
})

const UploadInput = ({ children, multiple, ...props }) => {
  const [busy, setBusy] = React.useState(false)
  const [error, setError] = React.useState(null)
  const usePromisifiedMutation = pipe(useStatelessMutation, promisify)
  const upload = usePromisifiedMutation(UploadFile)

  React.useEffect(() => {
    if (props.onBusy) props.onBusy(busy)
  }, [busy, props.onBusy])

  const onError = () => {
    setBusy(false)
    setError(defaultErrorMessage)
    toast.error(defaultErrorMessage)
  }

  const onCompleted = (payload) => {
    setBusy(false)
    setError(null)

    const results = payload.map((item) => item.uploadFile)
    const data = multiple ? results : results[0]

    props.onChange(data)
  }

  const onDrop = React.useCallback(
    // eslint-disable-next-line
    (files) => {
      if (busy || !files || !files.length) return false

      setBusy(true)
      setError(null)

      const promises = [...files].map((file) => {
        const input = {
          variables: { input: { field: "file" } },
          uploadables: { file },
        }

        return upload(input)
      })

      Promise.all(promises).then(onCompleted).catch(onError)
    },
    [props.onChange],
  )

  return children({
    busy,
    error,
    onDrop,
    multiple,
  })
}

UploadInput.propTypes = {
  onBusy: PropTypes.func,
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(UploadInput)
