export const ProfileRoles = {
  investor: "investor",
  borrower: "borrower",
  guarantor: "guarantor",
}

export const AccreditationStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  null: "null",
}

export const ProfileType = {
  individual: "IndividualProfile",
  juristic: "LegalEntityProfile",
  foreignIndividual: "ForeignIndividualProfile",
  entrepreneur: "EntrepreneurProfile",
}

export const BankAccountStatuses = {
  initial: "INITIAL",
  approved: "APPROVED",
}

export const TransactionStatuses = {
  OK: "OK",
  ERROR: "ERROR",
  WAIT_BC: "WAIT_BC",
  PENDING_BANK: "PENDING_BANK",
  WAIT_MODERATE: "WAIT_MODERATE",
  REJECTED_BANK: "REJECTED_BANK",
  REJECTED_OPERATOR: "REJECTED_OPERATOR",
  PROCESSING: "PROCESSING",
  groups: {
    success: [],
    error: [],
    pending: [],
  },
}

TransactionStatuses.groups = {
  success: [TransactionStatuses.OK],
  error: [
    TransactionStatuses.ERROR,
    TransactionStatuses.REJECTED_BANK,
    TransactionStatuses.REJECTED_OPERATOR,
  ],
  pending: [
    TransactionStatuses.WAIT_BC,
    TransactionStatuses.PENDING_BANK,
    TransactionStatuses.WAIT_MODERATE,
    TransactionStatuses.PROCESSING,
  ],
}

export const TransactionTypes = {
  BORROW: "BORROW",
  DEPOSIT: "DEPOSIT",
  WITHDRAWAL: "WITHDRAWAL",
  FEE: "FEE",
  FEE_REFUND: "FEE_REFUND",
  KYC: "KYC",
  LOAN_REPAYMENT: "LOAN_REPAYMENT",
  INCOME_TAX: "INCOME_TAX",
  REFUND: "REFUND",
  PENALTY: "PENALTY",
  PENALTY_FEE: "PENALTY_FEE",
  INTEREST_FEE: "INTEREST_FEE",
  INTEREST_REPAYMENT: "INTEREST_REPAYMENT",
  INVESTMENT: "INVESTMENT",
  INVESTMENT_REFUND: "INVESTMENT_REFUND",
  UNKNOWN: "UNKNOWN",
  CURRENCY_EXCHANGE: "CURRENCY_EXCHANGE",
  WITHDRAWAL_FEE: "WITHDRAWAL_FEE",
  PURCHASE_LOAN: "PURCHASE_LOAN",
  groups: {
    in: [],
    out: [],
    variable: {},
  },
}

TransactionTypes.groups = {
  in: [
    TransactionTypes.BORROW,
    TransactionTypes.DEPOSIT,
    TransactionTypes.REFUND,
    TransactionTypes.FEE_REFUND,
    TransactionTypes.INTEREST_REPAYMENT,
    TransactionTypes.INVESTMENT_REFUND,
    TransactionTypes.KYC,
    TransactionTypes.LOAN_REPAYMENT,
  ],
  out: [
    TransactionTypes.WITHDRAWAL,
    TransactionTypes.FEE,
    TransactionTypes.INVESTMENT,
    TransactionTypes.INCOME_TAX,
    TransactionTypes.INTEREST_FEE,
    TransactionTypes.PENALTY_FEE,
    TransactionTypes.WITHDRAWAL_FEE,
    TransactionTypes.PURCHASE_LOAN,
  ],
  variable: {
    [TransactionTypes.PENALTY]: {
      in: [
        "IndividualProfile",
        "EntrepreneurProfile",
        "ForeignIndividualProfile",
      ],
      out: ["LegalEntityProfile"],
    },
  },
}
