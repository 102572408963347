import { fetchQuery } from "relay-runtime"
import { graphql } from "react-relay"

import { SetViewerLocale } from "src/mutations"

const localeQuery = graphql`
  query localeUtilsQuery {
    viewer {
      locale
    }
  }
`

const getNavigatorLocale = () => {
  const { language } = navigator

  if (!language) {
    return undefined
  }

  return language.substring(0, 2)
}

const fetchViewerLocale = (environment) => fetchQuery(environment, localeQuery, {}).catch(() => {})

const handleResponse = (res) => {
  const { viewer } = res

  return viewer
}

const requestLocaleUpdate = (environment, locale) => new Promise((res, rej) => {
  const input = {
    locale: locale.toUpperCase(),
  }

  SetViewerLocale.commit(environment, { input }, null, {
    onError: rej,
    onCompleted: res,
  })
})

const possibleLocales = ["ru", "en", "ko"]

const processResponse = (viewer) => {
  if (!viewer) {
    return getNavigatorLocale()
  }

  const { locale } = viewer

  if (!locale) {
    return getNavigatorLocale()
  }

  return locale.toLowerCase()
}

const setViewerLocale = (environment, locale) => fetchViewerLocale(environment)
  .then(handleResponse)
  .then((viewer) => {
    if (!viewer) return null

    return requestLocaleUpdate(environment, locale.toUpperCase())
  })

const getLocale = (environment, defaultLocale) => fetchViewerLocale(environment)
  .then(handleResponse)
  .then(processResponse)
  .then((locale) => {
    if (possibleLocales.includes(locale)) {
      return locale
    }

    return defaultLocale
  })

export { getLocale, setViewerLocale }
