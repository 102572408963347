import styled from "styled-components"

import { Text } from "src/components"

export const StyledText = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 1.5;
`

export const SubHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 1rem;
  line-height: 2;
  font-weight: 600;
`
