import React from "react"

import { PurposedMutationProvider } from "src/context"
import { ErrorsContainer } from "src/containers"
import {
  CreateForeignIndividualProfile,
  RequestProfilePhoneConfirmation,
  UpdateForeignIndividualProfile,
  RequestForeignProfileAccreditation,
} from "src/mutations"
import { useMutation } from "src/hooks"
import { pipe, createChainedFunction, promisifyHookedMutation } from "src/utils"

import {
  formStateToAttachments,
  formStateToMigrationCard,
  formStateToTerritoryResidenceDocument,
} from "./utils"

import createValidator from "./validations"

const processIndividualProfileMutations = [
  "createForeignIndividualProfile",
  "updateForeignIndividualProfile",
]

const initAttributes = ({
  formState,
  provideId,
  shouldSkipValidation = false,
}) => {
  let nextFormState = null
  let nextSelfieData = ""
  let nextResidenceDocumentData

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __typename,
    id: profileId,
    hasUSACitizenship,
    selfie: injectedSelfieArray,
    documents: injectedDocuments,
    migrationCard: injectedMigrationCard,
    innDocument: injectedInnDocumentArray,
    residenceDocument: injectedResidenceDocumentArray,
    translatedDocuments: injectedTranslatedDocumentArray,
    taxResidencyApplication: injectedTaxResidencyApplication,
    territoryResidenceDocument: injectedTerritoryResidenceDocument,
    ...restFormState
  } = formState

  if (Array.isArray(injectedResidenceDocumentArray)) {
    const [currentResidenceDocument] = formStateToAttachments(
      injectedResidenceDocumentArray,
    )

    nextResidenceDocumentData = currentResidenceDocument || null
  }

  if (Array.isArray(injectedSelfieArray)) {
    const [currentSelfie] = formStateToAttachments(injectedSelfieArray)

    nextSelfieData = currentSelfie || null
  }

  const nextDocumentsData = injectedDocuments.map(
    ({ type, attachments: injectedAttachments }) => ({
      type,
      attachments: formStateToAttachments(injectedAttachments),
    }),
  )

  const nextTaxResidencyApplication = formStateToAttachments(
    injectedTaxResidencyApplication,
  )
  const nextTranslatedDocuments = formStateToAttachments(
    injectedTranslatedDocumentArray,
  )
  const nextInnDocument = formStateToAttachments(injectedInnDocumentArray)

  nextFormState = {
    ...restFormState,
    middleName: restFormState?.middleName?.trim() || null,
    selfie: nextSelfieData,
    documents: nextDocumentsData,
    hasUSACitizenship: !hasUSACitizenship,
    migrationCard: formStateToMigrationCard(injectedMigrationCard),
    residenceDocument: restFormState.isRussiaTaxResident
      ? nextResidenceDocumentData || null
      : null,
    taxResidencyApplication: nextTaxResidencyApplication?.length
      ? nextTaxResidencyApplication
      : null,
    translatedDocuments: nextTranslatedDocuments?.length
      ? nextTranslatedDocuments
      : null,
    innDocument: nextInnDocument?.length ? nextInnDocument : null,
    territoryResidenceDocument: formStateToTerritoryResidenceDocument(
      injectedTerritoryResidenceDocument,
    ),
  }

  const attributes = { ...nextFormState }

  if (provideId) {
    return { input: { attributes, profileId, shouldSkipValidation } }
  }

  return { input: { attributes, shouldSkipValidation } }
}

const ensureCreateProfileData = (formState, shouldSkipValidation) => initAttributes({
  formState,
  provideId: false,
  shouldSkipValidation,
})

const ensureUpdateProfileData = (formState, shouldSkipValidation) => initAttributes({
  formState,
  provideId: true,
  shouldSkipValidation,
})

const ensureRequestTokenData = ({ id }) => ({ input: { profileId: id, kind: "REQUEST_PROFILE_ACCREDITATION" } })

const ProcessInternationalProfile = React.memo((props: any) => {
  const { errors } = props
  const { setErrors } = errors

  const use = pipe(useMutation, promisifyHookedMutation)

  const createProfile = use(CreateForeignIndividualProfile)
  const updateProfile = use(UpdateForeignIndividualProfile)
  const requestPhoneConfirmation = use(RequestProfilePhoneConfirmation)
  const requestProfileAccreditation = use(RequestForeignProfileAccreditation)

  const throwError = (err) => {
    throw new Error(err)
  }

  const handleErrors = createChainedFunction(setErrors, throwError)

  const createIndividualProfile = ({ shouldSkipValidation }) => (formState) => {
    const input = ensureCreateProfileData(formState, shouldSkipValidation)
    const validate = createValidator(
      "createForeignIndividualProfile",
      shouldSkipValidation,
    )

    return validate(formState)
      .then(() => createProfile(input))
      .catch(handleErrors)
  }

  const updateIndividualProfile = ({ shouldSkipValidation }) => (formState) => {
    const input = ensureUpdateProfileData(formState, shouldSkipValidation)
    const validate = createValidator(
      "updateForeignIndividualProfile",
      shouldSkipValidation,
    )

    return validate(formState)
      .then(() => updateProfile(input))
      .catch(handleErrors)
  }

  const requestToken = (formState) => requestPhoneConfirmation(ensureRequestTokenData(formState))
    .then(
      ({ requestProfilePhoneConfirmation }) => requestProfilePhoneConfirmation.status,
    )
    .catch(handleErrors)

  const requestAccreditation = (profileId) => ({ input: injectedInput }) => {
    const { token } = injectedInput

    const input = {
      role: "INVESTOR",
      token,
      profileId,
    }

    return requestProfileAccreditation({ input }).catch(handleErrors)
  }

  return (
    <PurposedMutationProvider value={processIndividualProfileMutations}>
      {props.children({
        errors,
        requestToken,
        requestAccreditation,
        createIndividualProfile,
        updateIndividualProfile,
      })}
    </PurposedMutationProvider>
  )
})

export default (props) => (
  <ErrorsContainer>
    {(errors) => <ProcessInternationalProfile errors={errors} {...props} />}
  </ErrorsContainer>
)
