import React from "react"

import { OrderFactory } from "src/components"
import { OrdersListContainer } from "src/containers"
import { InvestmentsOrderListQueryContainer } from "src/query"
import { parseInvestmentsData } from "src/utils"

import StatusScreens from "../../StatusScreens"

const Investments = () => (
  <OrdersListContainer.list
    queryContainer={InvestmentsOrderListQueryContainer}
    emptyView={() => <StatusScreens.investments.EmptyList />}
    adapter={parseInvestmentsData}
    itemPresentation={OrderFactory.createInvestment}
  />
)

export default Investments
