export function getProfileInfo(profile) {
  let balanceRUB = []

  if (
    profile.accountBalanceDetailed
    && profile.accountBalanceDetailed.length > 0
  ) {
    balanceRUB = profile.accountBalanceDetailed.map(
      (balance) => balance.currency === "RUB" && balance.available,
    )
  } else {
    balanceRUB = [0]
  }

  return {
    id: profile.id,
    type: profile.__typename,
    name:
      profile.__typename === "EntrepreneurProfile"
        ? `ИП ${profile.name}`
        : profile.name,
    balanceRUB: balanceRUB[0] || 0,
  }
}
