import React from "react"

export default () => (
  <path
    d="M4 1v9.6L1.69 8.29A.948.948 0 0 0 1 8a1.012 1.012 0 0 0-1 1 .943.943 0 0
     0 .29.69l3.979 3.979A.959.959 0 0 0 5 14a.907.907 0 0 0 .723-.323L9.71
      9.69A.943.943 0 0 0 10 9a1.011 1.011 0 0 0-1-1 .948.948 0 0 0-.69.29L6
       10.6V1a1 1 0 0 0-2 0z"
    fill="#e2251c"
    data-name="Group 1"
  />
)
