import React from "react"
import useCollapse from "react-collapsed"

import {
  Container, Button, Arrow, Content,
} from "./styles"

const collapseStyles = {
  transitionDuration: "300ms",
  transitionTimingFunction: "cubic-bezier(0.250, 0.460, 0.450, 0.940)",
}

function Collapse(props) {
  const { title, children, ...rest } = props

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    expandStyles: collapseStyles,
    collapseStyles,
  })

  return (
    <Container isOpen={isExpanded} {...rest}>
      <Button {...getToggleProps()}>
        {title}
        <Arrow />
      </Button>

      <Content {...getCollapseProps()}>{children}</Content>
    </Container>
  )
}

export default Collapse
