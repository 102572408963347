/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LocaleEnum = "EN" | "KO" | "RU" | "ZH" | "%future added value";
export type SetViewerLocaleInput = {
    locale: LocaleEnum;
};
export type SetViewerLocaleMutationVariables = {
    input: SetViewerLocaleInput;
};
export type SetViewerLocaleMutationResponse = {
    readonly setViewerLocale: {
        readonly viewer: {
            readonly id: string;
        };
    };
};
export type SetViewerLocaleMutation = {
    readonly response: SetViewerLocaleMutationResponse;
    readonly variables: SetViewerLocaleMutationVariables;
};



/*
mutation SetViewerLocaleMutation(
  $input: SetViewerLocaleInput!
) {
  setViewerLocale(input: $input) {
    viewer {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SetViewerLocaleInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetViewerLocalePayload",
    "kind": "LinkedField",
    "name": "setViewerLocale",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetViewerLocaleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetViewerLocaleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "SetViewerLocaleMutation",
    "operationKind": "mutation",
    "text": "mutation SetViewerLocaleMutation(\n  $input: SetViewerLocaleInput!\n) {\n  setViewerLocale(input: $input) {\n    viewer {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd87fd4095d96fd3d55a6ad76ae148ad4';
export default node;
