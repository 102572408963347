export const imageSizer = new (class {
  loaded

  constructor() {
    // :TODO - cache didn't work
    this.loaded = {}
  }

  getSize = (src) => new Promise((res) => {
    if (Object.prototype.hasOwnProperty.call(this.loaded, "src")) {
      res(this.loaded[src])
    }
    const image = new Image()

    image.onload = () => {
      const { width, height } = image
      this.loaded[`${src}`] = { width, height }

      res({ width, height })
    }

    image.src = src
  })
})()

const sortStatements = (a, b) => {
  if (a > b) {
    return -1
  }
  if (a < b) {
    return 1
  }
  return 0
}

const reduceStatements = (acc, statement) => {
  const {
    name,
    year,
    attachment: { url },
  } = statement

  const isYearAvailable = Object.prototype.hasOwnProperty.call(acc, "year")

  if (isYearAvailable) {
    acc[year].push({ name, url })
  } else {
    acc[year] = new Array(1).fill({ name, url })
  }

  return acc
}

export const serializeFinancialStatements = (statements) => (!statements || statements.length === 0
  ? null
  : statements.sort(sortStatements).reduce(reduceStatements, {}))

export const unionDocs = (arrA, arrB) => {
  const res = [...arrA, ...arrB]
  return res.reduce((acc, item) => {
    // method .every returns true for any condition put on an empty array
    if (
      acc.every((currentVal) => currentVal.attachment.id !== item.attachment.id)
    ) {
      acc.push(item)
    }
    return acc
  }, [])
}
