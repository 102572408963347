import React from "react"

import { Translate } from "src/components"

import { Statusbar } from "../Elements"

import {
  TableRow,
  TableTdName,
  TableTdRating,
  TableTDSchedule,
  GraphButton,
  TableTdNameWrap,
} from "./styles"
import { clock, Graph } from "./icons"

function Table(props) {
  const { isCession } = props

  return (
    <TableRow>
      <TableTdName title={"_"}>
        <TableTdNameWrap>_</TableTdNameWrap>
      </TableTdName>
      <td />
      <td>0%</td>

      {isCession && <td>0%</td>}
      {!isCession && (
        <td>
          <b>
            0 <Translate i18n={"models:loan.month"} />
          </b>
        </td>
      )}

      <TableTDSchedule>
        Частями
        <GraphButton>{Graph}</GraphButton>
      </TableTDSchedule>
      <td>{clock} -</td>
      <td>_</td>
      <TableTdRating>_</TableTdRating>

      {isCession && <td>0</td>}
      {!isCession && (
        <td>
          <Statusbar value={0} width="110px" res={true} />
        </td>
      )}
    </TableRow>
  )
}

export default {
  Table,
}
