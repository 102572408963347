const steps = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]

const Color = (name, parts) => {
  const plural = `${name}s`

  return {
    [name]: `rgb(${parts.join(",")})`,
    [plural]: steps.map((opacity) => `rgba(${parts.join(",")},${opacity})`),
  }
}

export default {
  ...Color("primaryBlack", [74, 74, 74]),
  ...Color("primaryRed", [247, 0, 0]),
  ...Color("redDark", [175, 44, 43]),
  ...Color("red", [226, 37, 28]),
  ...Color("redLight", [242, 98, 90]),
  ...Color("redLighter", [242, 115, 108]),
  ...Color("redLightest", [255, 251, 251]),
  ...Color("roseWhite", [255, 251, 251]),
  ...Color("mainRed", [247, 0, 0]),

  ...Color("ginger", [255, 143, 0]),
  ...Color("romance", [255, 253, 250]),

  ...Color("springWood", [252, 253, 251]),

  ...Color("orange", [255, 143, 0]),
  ...Color("newOrange", [255, 153, 0]),

  ...Color("green", [91, 171, 58]),
  ...Color("greenLightest", [252, 253, 251]),
  ...Color("darkGreen", [111, 168, 75]),

  ...Color("fullBlack", [0, 0, 0]),
  ...Color("black", [11, 16, 22]),
  ...Color("darkCoffe", [30, 30, 30]),
  ...Color("textBlack", [47, 47, 47]),

  ...Color("blueBright", [244, 248, 249]),

  ...Color("blue", [58, 102, 171]),
  ...Color("blueFlat", [48, 110, 207]),
  ...Color("oceanBlue", [58, 91, 188]),
  ...Color("darkBlue", [0, 103, 156]),
  ...Color("catskillWhite", [251, 252, 253]),

  ...Color("greyDarkest", [53, 58, 67]),
  ...Color("greyDarker", [123, 131, 139]),
  ...Color("greyDark", [158, 166, 174]),
  ...Color("greyLight", [53, 62, 70]),
  ...Color("greyLightest", [71, 77, 88]),

  ...Color("greyShuttle", [90, 97, 110]),

  ...Color("brownDarkest", [112, 112, 112]),

  ...Color("coffee", [244, 248, 249]),

  ...Color("grey", [217, 222, 226]),

  ...Color("greyLight", [229, 234, 238]),
  ...Color("greyLighter", [244, 248, 249]),
  ...Color("greyLightest", [251, 252, 253]),

  ...Color("white", [255, 255, 255]),
  ...Color("catskillWhite", [243, 246, 250]),
  ...Color("alabaster", [252, 252, 252]),

  ...Color("fb", [51, 72, 143]),
  ...Color("vk", [59, 118, 187]),
  ...Color("ss", [226, 37, 28]),
}
