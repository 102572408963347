import styled from "styled-components"

import { Box, Button } from "src/components"

export const StyledButton = styled(Button)`
  height: 32px !important;
  font-size: ${({ fontSize }) => fontSize};
  margin-right: auto;
`

export const ButtonContainer = styled(Box)`
  position: relative;
`

export const Container = styled(Box)`
  width: 100%;
  padding: 0;

  @media (max-width: 479px) {
    padding: 40px 15px 0 15px;
  }
`
