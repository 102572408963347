/* eslint-disable max-len */

import React from "react"

export const info = (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.125 5.625H4.875V6.375H4.125V5.625ZM4.125
  2.625H4.875V4.875H4.125V2.625ZM4.49625 0.75C2.42625
  0.75 0.75 2.43 0.75 4.5C0.75 6.57 2.42625 8.25 4.49625
  8.25C6.57 8.25 8.25 6.57 8.25 4.5C8.25 2.43 6.57 0.75
  4.49625 0.75ZM4.5 7.5C2.8425 7.5 1.5 6.1575 1.5 4.5C1.5
  2.8425 2.8425 1.5 4.5 1.5C6.1575 1.5 7.5 2.8425 7.5 4.5C7.5
  6.1575 6.1575 7.5 4.5 7.5Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const user = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99999 5.56522C7.53678 5.56522 8.78259 4.3194
  8.78259 2.78261C8.78259 1.24582 7.53678 0 5.99999 0C4.46319
   0 3.21738 1.24582 3.21738 2.78261C3.21738 4.3194 4.46319
   5.56522 5.99999 5.56522Z"
      fill="#393939"
    />
    <path
      d="M5.99999 6.95653C3.21455 6.95653 0.956512 9.2457
  0.956512 12H11.0435C11.0435 9.21457 8.78543 6.95653 5.99999 6.95653Z"
      fill="#393939"
    />
  </svg>
)

export const clock = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4811 3.9705C11.7957 2.37259 10.631 1.21047 9.01977
  0.523275C8.21445 0.179816 7.37075 0.00437927 6.48932 0.00437927C5.60803
  0.00437927 4.77055 0.178159 3.96613 0.523275C2.36821 1.20864 1.19836
  2.37002 0.511758 3.9705C0.167704 4.77234 0 5.61239 0 6.49367C0 7.37512
  0.167704 8.22214 0.511758 9.02415C1.19836 10.6246 2.36836 11.793 3.96613
  12.4785C4.7707 12.8236 5.60801 12.9972 6.48932 12.9972C7.37062 12.9972
  8.21445 12.8218 9.01964 12.4785C10.6309 11.7913 11.7956 10.6219 12.4811
  9.02415C12.8262 8.21957 13 7.37512 13 6.49367C13 5.61239 12.8264 4.77506
  12.4811 3.9705ZM11.4435 9.36533C10.9332 10.2404 10.2342 10.9346 9.35382
  11.4479C8.47876 11.9581 7.51997 12.2154 6.48946 12.2154C5.71477 12.2154
  4.97556 12.0662 4.27182 11.7605C3.5681 11.455 2.9605 11.0533 2.45222
  10.5452C1.94395 10.0369 1.5394 9.42339 1.23689 8.71845C0.935278 8.01609
  0.789113 7.26853 0.789113 6.49369C0.789113 5.46304 1.04234 4.50911 1.54972
  3.63648C2.05891 2.76065 2.75808 2.06571 3.63225 1.55393C4.50626 1.04217
  5.45883 0.786206 6.48932 0.786206C7.51997 0.786206 8.47859 1.04368 9.35367
  1.55393C10.2341 2.06722 10.9316 2.76214 11.4433 3.63645C11.9551 4.51062
  12.2109 5.46304 12.2109 6.49367C12.2109 7.52435 11.9568 8.48477 11.4435
  9.36533Z"
      fill="#4A4A4A"
    />
    <path
      d="M6.9016 6.43601V2.75418C6.9016 2.53386 6.70964 2.35616 6.48929
  2.35616C6.28323 2.35616 6.09838 2.53386 6.09838 2.75418V6.49285C6.09838
  6.49999 6.11263 6.5285 6.11263 6.54974C6.09838 6.6777 6.13402 6.78431
  6.21925 6.86968L8.34456 8.99484C8.48677 9.13705 8.7497 9.13705 8.89181
  8.99484C9.05524 8.83138 9.0445 8.59422 8.89181 8.44045L6.9016 6.43601Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const exit = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7
8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const Youtube = (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.762 6.54309C22.762 6.54309 22.5241 4.8379 21.85 4.08447C20.9776
3.09309 19.9862 3.09309 19.55 3.05342C16.338 2.81551 11.5 2.77585 11.5 2.77585C11.5
2.77585 6.66205 2.81551 3.45 3.05342C3.01381 3.09309 2.02243 3.13275 1.15 4.08447C0.436191
4.83794 0.237951 6.54309 0.237951 6.54309C0.237951 6.54309 0 8.56551 0 10.5879V12.4518C0 14.4742
0.237951 16.4569 0.237951 16.4569C0.237951 16.4569 0.475902 18.1621 1.15 18.9156C2.02243 19.9069
3.17243 19.8673 3.68795 19.9863C5.51205 20.1845 11.5 20.2241 11.5 20.2241C11.5 20.2241 16.338 20.2241
19.55 19.9466C19.9862 19.9069 20.9776 19.8672 21.85 18.9155C22.5241 18.1621 22.762 16.4569 22.762
16.4569C22.762 16.4569 23 14.4345 23 12.4517V10.5879C23 8.56551 22.762 6.54309 22.762 6.54309Z"
      fill="#D8362A"
    />
    <path
      d="M8.72414 7.49478V16.1L16.2586 11.8965L8.72414 7.49478Z"
      fill="white"
    />
  </svg>
)

export const Chart = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="initial"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.352724 6.3347C0.157722 6.3347 0 6.49242 0 6.68743V13.2745C0 13.4695 0.157722 13.6272
    0.352724 13.6272H3.57313C3.76813 13.6272 3.92585 13.4695 3.92585 13.2745V6.68743C3.92585 6.49242
  3.76813 6.3347 3.57313 6.3347H0.352724ZM3.2204 12.9218H0.702581V7.03728H3.2204V12.9218Z"
      fill="#4A4A4A"
    />
    <path
      d="M5.03851 0.725527V13.2745C5.03851 13.4695 5.19624 13.6272 5.39124 13.6272H8.61164C8.80664 13.6272
    8.96436 13.4695 8.96436 13.2745V0.725527C8.96436 0.530525 8.80664 0.372803 8.61164 0.372803H5.38837C5.19624
  0.37567 5.03851 0.530525 5.03851 0.725527ZM5.74109 1.07825H8.25891V12.9246H5.74109V1.07825Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.6473 13.6243C13.8423 13.6243 14 13.4666 14 13.2716V4.82057C14 4.62557 13.8423 4.46785 13.6473
    4.46785H10.4269C10.2319 4.46785 10.0742 4.62557 10.0742 4.82057V13.2745C10.0742 13.4695 10.2319 13.6272
  10.4269 13.6272H13.6473V13.6243ZM10.7796 5.1733H13.2974V12.9246H10.7796V5.1733Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const ChartBig = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="initial"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.352724 6.3347C0.157722 6.3347 0 6.49242 0 6.68743V13.2745C0 13.4695 0.157722 13.6272
    0.352724 13.6272H3.57313C3.76813 13.6272 3.92585 13.4695 3.92585 13.2745V6.68743C3.92585 6.49242
  3.76813 6.3347 3.57313 6.3347H0.352724ZM3.2204 12.9218H0.702581V7.03728H3.2204V12.9218Z"
      fill="#4A4A4A"
    />
    <path
      d="M5.03851 0.725527V13.2745C5.03851 13.4695 5.19624 13.6272 5.39124 13.6272H8.61164C8.80664 13.6272
    8.96436 13.4695 8.96436 13.2745V0.725527C8.96436 0.530525 8.80664 0.372803 8.61164 0.372803H5.38837C5.19624
  0.37567 5.03851 0.530525 5.03851 0.725527ZM5.74109 1.07825H8.25891V12.9246H5.74109V1.07825Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.6473 13.6243C13.8423 13.6243 14 13.4666 14 13.2716V4.82057C14 4.62557 13.8423 4.46785 13.6473
    4.46785H10.4269C10.2319 4.46785 10.0742 4.62557 10.0742 4.82057V13.2745C10.0742 13.4695 10.2319 13.6272
  10.4269 13.6272H13.6473V13.6243ZM10.7796 5.1733H13.2974V12.9246H10.7796V5.1733Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const Graph = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6562 1.5625H16.7188V0H15.1562V1.5625H4.84375V0H3.28125V1.5625H2.34375C1.05141 1.5625 0 2.61391 0 3.90625V17.6562C0 18.9486 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9486 20 17.6562V3.90625C20 2.61391 18.9486 1.5625 17.6562 1.5625ZM18.4375 17.6562C18.4375 18.087 18.087 18.4375 17.6562 18.4375H2.34375C1.91297 18.4375 1.5625 18.087 1.5625 17.6562V7.34375H18.4375V17.6562ZM18.4375 5.78125H1.5625V3.90625C1.5625 3.47547 1.91297 3.125 2.34375 3.125H3.28125V4.6875H4.84375V3.125H15.1562V4.6875H16.7188V3.125H17.6562C18.087 3.125 18.4375 3.47547 18.4375 3.90625V5.78125Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 8.98438H2.96875V10.5469H4.53125V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 8.98438H6.09375V10.5469H7.65625V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 8.98438H9.21875V10.5469H10.7812V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 8.98438H12.3438V10.5469H13.9062V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 8.98438H15.4688V10.5469H17.0312V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 12.1094H2.96875V13.6719H4.53125V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 12.1094H6.09375V13.6719H7.65625V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 12.1094H9.21875V13.6719H10.7812V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 12.1094H12.3438V13.6719H13.9062V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 15.2344H2.96875V16.7969H4.53125V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 15.2344H6.09375V16.7969H7.65625V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 15.2344H9.21875V16.7969H10.7812V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 15.2344H12.3438V16.7969H13.9062V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 12.1094H15.4688V13.6719H17.0312V12.1094Z"
      fill="#4A4A4A"
    />
  </svg>
)

export const MobileGraph = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6562 1.5625H16.7188V0H15.1562V1.5625H4.84375V0H3.28125V1.5625H2.34375C1.05141 1.5625 0 2.61391 0 3.90625V17.6562C0 18.9486 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9486 20 17.6562V3.90625C20 2.61391 18.9486 1.5625 17.6562 1.5625ZM18.4375 17.6562C18.4375 18.087 18.087 18.4375 17.6562 18.4375H2.34375C1.91297 18.4375 1.5625 18.087 1.5625 17.6562V7.34375H18.4375V17.6562ZM18.4375 5.78125H1.5625V3.90625C1.5625 3.47547 1.91297 3.125 2.34375 3.125H3.28125V4.6875H4.84375V3.125H15.1562V4.6875H16.7188V3.125H17.6562C18.087 3.125 18.4375 3.47547 18.4375 3.90625V5.78125Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 8.98438H2.96875V10.5469H4.53125V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 8.98438H6.09375V10.5469H7.65625V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 8.98438H9.21875V10.5469H10.7812V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 8.98438H12.3438V10.5469H13.9062V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 8.98438H15.4688V10.5469H17.0312V8.98438Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 12.1094H2.96875V13.6719H4.53125V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 12.1094H6.09375V13.6719H7.65625V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 12.1094H9.21875V13.6719H10.7812V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 12.1094H12.3438V13.6719H13.9062V12.1094Z"
      fill="#4A4A4A"
    />
    <path
      d="M4.53125 15.2344H2.96875V16.7969H4.53125V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M7.65625 15.2344H6.09375V16.7969H7.65625V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M10.7812 15.2344H9.21875V16.7969H10.7812V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M13.9062 15.2344H12.3438V16.7969H13.9062V15.2344Z"
      fill="#4A4A4A"
    />
    <path
      d="M17.0312 12.1094H15.4688V13.6719H17.0312V12.1094Z"
      fill="#4A4A4A"
    />
  </svg>
)
