/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type InstructionsModal_order = {
    readonly skipOffer: boolean;
    readonly application: {
        readonly data: string;
    } | null;
    readonly chain: {
        readonly id: string;
    } | null;
    readonly borrowerOffer: {
        readonly url: string;
    } | null;
    readonly otherDocuments: ReadonlyArray<{
        readonly description: string;
        readonly attachment: {
            readonly url: string;
            readonly filename: string;
        };
    }>;
    readonly guarantors: ReadonlyArray<{
        readonly name: string | null;
        readonly description: string | null;
        readonly attachment: {
            readonly url: string;
            readonly filename: string;
        };
    }>;
    readonly " $refType": "InstructionsModal_order";
};
export type InstructionsModal_order$data = InstructionsModal_order;
export type InstructionsModal_order$key = {
    readonly " $data"?: InstructionsModal_order$data;
    readonly " $fragmentRefs": FragmentRefs<"InstructionsModal_order">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachment",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InstructionsModal_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skipOffer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderApplication",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderChainInfo",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "borrowerOffer",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "BORROWER_OFFER"
        }
      ],
      "concreteType": "GeneratedDocument",
      "kind": "LinkedField",
      "name": "document",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "document(type:\"BORROWER_OFFER\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderDocument",
      "kind": "LinkedField",
      "name": "otherDocuments",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderGuarantor",
      "kind": "LinkedField",
      "name": "guarantors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Order"
};
})();
(node as any).hash = 'c4e30f347f30dacfcfd47ff081bc0bca';
export default node;
