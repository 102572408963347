import React from "react"

import {
  Box,
  FlexBox,
  RadioButton,
} from "src/components"

class RadioGroup extends React.PureComponent<any, any> {
  onChange = (event) => {
    const { value } = event.target

    this.props.onChange({
      name: this.props.name,
      value,
    })
  }

  render() {
    const options = typeof this.props.options === "function"
      ? this.props.options()
      : this.props.options

    return (
      <Box>
        {options.map(({ label, value }, index) => (
          <FlexBox
            key={index}
            display="flex"
            flexDirection="row"
            justifyContnet="flex-start"
            alignItems="center"
            py="6px"
          >
            <RadioButton
              label={label}
              value={value}
              onChange={this.onChange}
              name={this.props.name}
              checked={this.props.value === value}
            />
          </FlexBox>
        ))}
      </Box>
    )
  }
}

(RadioGroup as any).defaultProps = {
  options: [],
}

export default RadioGroup
