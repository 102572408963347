import React, { useEffect } from "react"

import { DefaultModal } from "src/components"

import { Slide, Container, StyledSlider } from "./styles"
import { Shevrone } from "./icons"

const NextArrow: React.FC<any> = ({ className, style, onClick }) => (
  <Shevrone
    className={className}
    style={{
      ...style,
      display: "block",
      color: "#F7F7F7",
    }}
    onClick={onClick}
  />
)

const PrevArrow: React.FC<any> = ({ className, style, onClick }) => (
  <Shevrone
    className={className}
    style={{
      ...style,
      display: "block",
      color: "#F7F7F7",
      transform: "rotate(180deg)",
    }}
    onClick={onClick}
  />
)

export const Carousel = ({ onClose, sliders, initialSlide }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let sliderRef

  useEffect(() => {
    sliderRef = (document.querySelector(".slick-slide") as any).focus()
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
    accessibility: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
  }

  return (
    <DefaultModal
      type="centered"
      padding="0"
      onClose={onClose}
      heightRestriction={false}
      closeButtonVariant="empty"
      backgroundColor="transparent"
    >
      <Container width={["100vw", "80vw"]} height={["100vh", "80vh"]}>
        <StyledSlider {...settings}>
          {sliders.map((item, i) => (
            <Slide
              key={i}
              src={item.url}
              role="presentation"
              width={["100vw", "80vw"]}
              height={["100vh", "80vh"]}
            />
          ))}
        </StyledSlider>
      </Container>
    </DefaultModal>
  )
}
