import styled from "styled-components"

export const SwitchWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;

  .onoffswitch {
    position: relative;
    width: auto;
    user-select: none;
    margin: ${({ margin }) => margin};
  }

  .onoffswitch-checkbox {
    display: none;
  }

  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 16px;
  }

  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 26px;
    padding: 0;
    line-height: 26px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }

  .onoffswitch-inner:before {
    content: "";
    padding-left: 10px;
    background-color: #7b838b;
    color: #000000;
  }

  .onoffswitch-inner:after {
    content: " ";
    padding-right: 10px;
    background-color: #7b838b;
    color: #000000;
    text-align: right;
  }

  .onoffswitch-switch {
    display: block;
    width: 18px;
    margin: 4px;
    background: #f8f4f4;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border-radius: 16px;
    transition: all 0.3s ease-in 0s;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }

  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
`

export const SwitchLabel = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`
