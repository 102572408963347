import styled from "styled-components"

export const Container = styled.div`
  font-family: Geometria;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background: #f70000;
  border-radius: 4px;
  margin-top: 40px;
  color: #ffffff;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: normal;
  }

  @media screen and (max-width: 480px) {
    padding: 25px;
  }
`

export const Header = styled.h4`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin: auto 0;
  max-width: 150px;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    line-height: 18px;
    max-width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
`

export const Divider = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 110px;
  width: 1px;

  @media screen and (max-width: 800px) {
    display: none;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 1px;
    display: block;
    margin-top: 16px;
  }
`

export const BlockWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
  height: 110px;

  @media screen and (max-width: 480px) {
    margin-top: 32px;
    height: 132px;
    width: 100%;
  }
`

export const Button = styled.button`
  background-color: inherit;
  color: #ffffff;
  width: 100%;
  text-align: center;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 16px 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 19px;
    padding: 25px 0;
  }
`

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ResultTitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 0;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
  }
`

export const ResultValue = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 23px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    display: block;
    margin-top: 50px !important;
  }

  @media screen and (max-width: 800px) {
    margin-top: 20px;
  }
`

export const DescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 228px;

  @media screen and (max-width: 800px) {
    max-width: 100%;
    display: block;
  }

  @media screen and (max-width: 480px) {
    margin-top: 32px;
  }
`

export const DescriptionTitle = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin: 0;

  @media screen and (max-width: 800px) {
    display: inline;
  }

  @media screen and (max-width: 480px) {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
  }
`

export const DescriptionValue = styled.p`
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  margin: 0;

  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 15px;
    display: inline;

    &:before {
      content: " ";
    }
  }

  @media screen and (max-width: 480px) {
    display: inline-block;
    font-size: 26px;
    line-height: 33px;
    margin-top: 32px;
  }
`

export const DescriptionTooltip = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin: 0;

  @media screen and (max-width: 800px) {
    display: none;
  }

  @media screen and (max-width: 480px) {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
  }
`

export const DescriptionLink = styled.a`
  font-weight: bold;
  margin: 0;
  color: #ffffff;
`

export const TabletDescriptionTooltip = styled.p`
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  display: none;
  margin-top: 16px;

  @media screen and (max-width: 800px) {
    display: inline-block;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`
