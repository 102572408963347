/* eslint-disable max-len */

import React from "react"

export const DocumentIcon = (props) => (
  <svg {...props} viewBox="0 0 11 13" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 0.25H1.75C1.0625 0.25 0.50625 0.8125 0.50625 1.5L0.5 11.5C0.5 12.1875 1.05625 12.75 1.74375 12.75H9.25C9.9375 12.75 10.5 12.1875 10.5 11.5V4L6.75 0.25ZM1.75 11.5V1.5H6.125V4.625H9.25V11.5H1.75Z"
      fill="black"
    />
  </svg>
)

export const CloseIcon = (props) => (
  <svg {...props} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="black"
    />
  </svg>
)

DocumentIcon.defaultProps = {
  width: "11px",
  height: "13px",
  fill: "#4a4a4a",
}

CloseIcon.defaultProps = {
  width: "12px",
  height: "12px",
  fill: "#4a4a4a",
}
