/* eslint-disable max-len */
import React from "react"

const BuisnessAndTradeIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6667 7.57501L19.0083 9.03335C19.6 9.15835 20 9.65835 20 10.25V18.75C20 19.4417 19.4417 20 18.75 20H11.25C11.4833 20 11.6667 19.8167 11.6667 19.5833V19.1667H18.75C18.975 19.1667 19.1667 18.9833 19.1667 18.75V10.25C19.1667 10.0583 19.0333 9.88335 18.8417 9.84168L11.6667 8.42501V7.57501Z" />
    <path d="M16.25 11.6667C16.4834 11.6667 16.6667 11.85 16.6667 12.0833C16.6667 12.3167 16.4834 12.5 16.25 12.5H14.5834C14.35 12.5 14.1667 12.3167 14.1667 12.0833C14.1667 11.85 14.35 11.6667 14.5834 11.6667H16.25Z" />
    <path d="M16.25 14.1667C16.4834 14.1667 16.6667 14.35 16.6667 14.5833C16.6667 14.8167 16.4834 15 16.25 15H14.5834C14.35 15 14.1667 14.8167 14.1667 14.5833C14.1667 14.35 14.35 14.1667 14.5834 14.1667H16.25Z" />
    <path d="M16.25 16.6667C16.4834 16.6667 16.6667 16.85 16.6667 17.0833C16.6667 17.3167 16.4834 17.5 16.25 17.5H14.5834C14.35 17.5 14.1667 17.3167 14.1667 17.0833C14.1667 16.85 14.35 16.6667 14.5834 16.6667H16.25Z" />
    <path d="M11.6666 19.5834C11.6666 19.8167 11.4833 20 11.25 20C11.0166 20 10.8333 19.8167 10.8333 19.5834V19.1667V7.91666C10.8333 7.79166 10.8916 7.675 10.9833 7.59166C11.0833 7.51666 11.2083 7.48333 11.3333 7.50833L11.6666 7.575V8.425V19.1667V19.5834Z" />
    <path d="M10.8334 19.1667V19.5834C10.8334 19.8167 11.0167 20 11.25 20H7.91669C8.15002 20 8.33335 19.8167 8.33335 19.5834V19.1667H10.8334Z" />
    <path d="M8.75002 4.16669C8.98335 4.16669 9.16669 4.35002 9.16669 4.58335C9.16669 4.81668 8.98335 5.00002 8.75002 5.00002H7.08335C6.85002 5.00002 6.66669 4.81668 6.66669 4.58335C6.66669 4.35002 6.85002 4.16669 7.08335 4.16669H8.75002Z" />
    <path d="M9.16669 7.08336C9.16669 7.31669 8.98335 7.50003 8.75002 7.50003H7.08335C6.85002 7.50003 6.66669 7.31669 6.66669 7.08336C6.66669 6.85002 6.85002 6.66669 7.08335 6.66669H8.75002C8.98335 6.66669 9.16669 6.85002 9.16669 7.08336Z" />
    <path d="M8.75002 9.16666C8.98335 9.16666 9.16669 9.34999 9.16669 9.58332C9.16669 9.81665 8.98335 9.99998 8.75002 9.99998H7.08335C6.85002 9.99998 6.66669 9.81665 6.66669 9.58332C6.66669 9.34999 6.85002 9.16666 7.08335 9.16666H8.75002Z" />
    <path d="M8.75002 11.6667C8.98335 11.6667 9.16669 11.85 9.16669 12.0833C9.16669 12.3167 8.98335 12.5 8.75002 12.5H7.08335C6.85002 12.5 6.66669 12.3167 6.66669 12.0833C6.66669 11.85 6.85002 11.6667 7.08335 11.6667H8.75002Z" />
    <path d="M5 12.0833C5 12.3167 4.81667 12.5 4.58333 12.5H2.91667C2.68333 12.5 2.5 12.3167 2.5 12.0833C2.5 11.85 2.68333 11.6667 2.91667 11.6667H4.58333C4.81667 11.6667 5 11.85 5 12.0833Z" />
    <path d="M4.58333 4.16669C4.81667 4.16669 5 4.35002 5 4.58335C5 4.81668 4.81667 5.00002 4.58333 5.00002H2.91667C2.68333 5.00002 2.5 4.81668 2.5 4.58335C2.5 4.35002 2.68333 4.16669 2.91667 4.16669H4.58333Z" />
    <path d="M4.58333 6.66669C4.81667 6.66669 5 6.85002 5 7.08336C5 7.31669 4.81667 7.50003 4.58333 7.50003H2.91667C2.68333 7.50003 2.5 7.31669 2.5 7.08336C2.5 6.85002 2.68333 6.66669 2.91667 6.66669H4.58333Z" />
    <path d="M4.58333 9.16666C4.81667 9.16666 5 9.34999 5 9.58332C5 9.81665 4.81667 9.99998 4.58333 9.99998H2.91667C2.68333 9.99998 2.5 9.81665 2.5 9.58332C2.5 9.34999 2.68333 9.16666 2.91667 9.16666H4.58333Z" />
    <path d="M7.49998 15.4167C7.49998 15.1834 7.30831 15 7.08331 15H4.58331C4.34998 15 4.16665 15.1834 4.16665 15.4167V19.1667H3.33331V15.4167C3.33331 14.725 3.89165 14.1667 4.58331 14.1667H7.08331C7.77498 14.1667 8.33331 14.725 8.33331 15.4167V19.1667H7.49998V15.4167Z" />
    <path d="M4.16665 19.1667H7.49998H8.33331V19.5834C8.33331 19.8167 8.14998 20 7.91665 20H3.74998C3.51665 20 3.33331 19.8167 3.33331 19.5834V19.1667H4.16665Z" />
    <path d="M1.45833 0.166676L10.6167 1.55834C11.225 1.65834 11.6667 2.17501 11.6667 2.79168V7.57501L11.3333 7.50834C11.2083 7.48334 11.0833 7.51668 10.9833 7.59168C10.8917 7.67501 10.8333 7.79168 10.8333 7.91668V2.79168C10.8333 2.58334 10.6833 2.40834 10.4833 2.37501L1.325 0.991676C1.3 0.983343 1.275 0.983343 1.25 0.983343C1.15 0.983343 1.05833 1.01668 0.983333 1.08334C0.883333 1.16668 0.833333 1.27501 0.833333 1.40001V18.75C0.833333 18.9833 1.025 19.1667 1.25 19.1667H3.33333V19.5833C3.33333 19.8167 3.51667 20 3.75 20H1.25C0.558333 20 0 19.4417 0 18.75V1.40001C0 1.03334 0.158333 0.683343 0.441667 0.450009C0.725 0.208343 1.09167 0.108342 1.45833 0.166676Z" />
  </svg>
)

BuisnessAndTradeIcon.defaultProps = {
  width: "20",
  height: "20",
}

export default BuisnessAndTradeIcon
