import memoize from "memoize-one"

export const formatDocuments = memoize((documents) => {
  if (!Array.isArray(documents)) return []

  return documents.map((doc) => {
    const { name, attachment, description } = doc

    const { url, filename } = attachment || doc
    const [, extension] = filename.split(".")

    return {
      url,
      title: name || description || filename,
      glyph: extension.split("?")[0],
    }
  })
})

export const formatGeneratedDocument = memoize((doc, name) => {
  const result = { ...doc }
  const tokens = result.url.split(".")
  const [extension] = tokens[tokens.length - 1].split("?")

  result.title = name
  result.glyph = extension

  return result
})

const normalizeDocuments = memoize((order) => {
  const documents = []
  const {
    otherDocuments, guarantors, skipOffer, borrowerOffer,
  } = order

  if (!skipOffer && borrowerOffer && borrowerOffer.url) {
    const name = "Договор займа на условиях предварительной заявки"
    documents.push(formatGeneratedDocument(borrowerOffer, name))
  }

  documents.push(...formatDocuments(otherDocuments))
  documents.push(...formatDocuments(guarantors))

  return documents
})

export default normalizeDocuments
