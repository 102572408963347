import React, { useState, useContext, createContext } from "react"

import { ViewerData } from "../ViewerData"

import { getCurrentProfile } from "./utils"

export const CurrentProfileContext: any = createContext({})

export function CurrentProfileProvider(props) {
  const { children } = props
  const viewerData = useContext(ViewerData)

  const data = getCurrentProfile(viewerData)
  const [currentProfile, setCurrentProfile] = useState(data)

  const contextData = {
    profile: currentProfile,
    update: setCurrentProfile,
  }

  return (
    <CurrentProfileContext.Provider value={contextData}>
      {children}
    </CurrentProfileContext.Provider>
  )
}
