import styled from "styled-components"
import ReactTooltip from "react-tooltip"

import {
  Box, Text, Link, Button, OutlinedButton,
} from "src/components"

export const SubHeader = styled(Text)`
  font-weight: 600;
  line-height: 1.4;
  color: #4a4a4a;
`

export const WithdrawProfileContainer = styled(Box)`
  padding: 10px 25px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.greyLight};
  cursor: pointer;
  user-select: none;
`

export const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.greyLight};
`

export const StyledButton = styled(Button)`
  height: 45px;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 1px;
`
export const StyledTooltip = styled(ReactTooltip)`
  font-size: 0.875rem !important;
  pointer-events: auto !important;
  border-radius: 4px;
  padding: 18px 24px;

  background-color: ${({ theme }) => theme.colors.primaryBlack} !important;

  :hover {
    visibility: visible !important;
    opacity: 1 !important;
  }

  p {
    margin: 0 0 13px 0;
  }
`

export const TooltipContext = styled.span`
  width: 48%;
`

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 18px;
  font-size: 0.875rem;
  font-weight: bold;
`

export const AmountModalContainer = styled(Box)`
  //max-height: 100%;
  //overflow-y: auto;
`

export const StyledLink = styled(Link)`
  text-decoration: underline;
`

export const BankDataTitleContainer = styled.div`
  min-width: 210px;
  max-width: 210px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 16px;
  text-transform: uppercase;

  @media screen and (max-width: 480px) {
    color: ${({ theme }) => theme.colors.primaryBlacks[6]};
    min-width: initial;
    max-width: 100%;
  }
`

export const BankDataItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  align-items: flex-start;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const NButton = styled(OutlinedButton)`
  height: 45px;
  padding: 0;
  padding-top: 4px;

  width: ${({ width }) => width || "150px"};
  border-color: ${({ borderColor }) => borderColor || "rgba(74, 74, 74, 0.45)"};
  color: ${({ color }) => color || "#4A4A4A"};
`

export const WithdrawTitle = styled(Text)`
  font-size: 1.375rem;
  line-height: 1.25;
  font-weight: 600;

  @media screen and (max-width: 480px) {
    margin-top: 32px;
  }
`

export const AmountButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
  
  @media screen and (max-width: 480px) {
    justify-content: space-between;
  }
`
