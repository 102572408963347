import React from "react"

import { Flex, Logo, Link } from "src/components"

import { Header, LogoContainer, ButtonContainer } from "./style"

import Locales from "./Locales"

const renderButton = (Button) => Button && <Button />

const render = ({ button }: any) => (
  <Header>
    <Flex flex="1">
      <Link to="/">
        <LogoContainer>
          <Logo />
          <span>My.karma.red</span>
        </LogoContainer>
      </Link>
    </Flex>
    <Locales />
    <ButtonContainer>{renderButton(button)}</ButtonContainer>
  </Header>
)

export default render
