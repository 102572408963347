/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type LoanActiveOrdersListFragment = {
    readonly node: {
        readonly id: string;
        readonly status: OrderStatusEnum | null;
        readonly confirmedAt: string | null;
        readonly offer: {
            readonly data: string;
            readonly receivedAt: string | null;
            readonly approvedAt: string | null;
        } | null;
        readonly application: {
            readonly data: string;
            readonly shortTitle: string;
            readonly longTitle: string;
        } | null;
        readonly chain: {
            readonly id: string;
            readonly gatheredAmount: number;
        } | null;
        readonly profile: {
            readonly id: string;
            readonly name?: string;
            readonly borrower?: {
                readonly ticker: string | null;
            } | null;
        };
    };
    readonly " $refType": "LoanActiveOrdersListFragment";
};
export type LoanActiveOrdersListFragment$data = LoanActiveOrdersListFragment;
export type LoanActiveOrdersListFragment$key = {
    readonly " $data"?: LoanActiveOrdersListFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"LoanActiveOrdersListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoanActiveOrdersListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "confirmedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderOffer",
          "kind": "LinkedField",
          "name": "offer",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "approvedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderApplication",
          "kind": "LinkedField",
          "name": "application",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderChainInfo",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gatheredAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Borrower",
                  "kind": "LinkedField",
                  "name": "borrower",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ticker",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "LegalEntityProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderEdge"
};
})();
(node as any).hash = '275c131bc09c525ed0d97fd62c739ab7';
export default node;
