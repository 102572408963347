import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import {
  ModalContainer,
  WindowContainer,
  Title,
  Button,
  Icon,
  ReturnButton,
} from "./styles"

import DescriptionText from "./Text"

function DescriptionModal(props) {
  const { onClose } = props

  const modalRef = React.createRef<HTMLDivElement>()
  const overlayRef = React.createRef<HTMLDivElement>()

  function handleClose() {
    enableBodyScroll(modalRef.current)
    onClose()
  }

  function onOverlayClick(event) {
    if (event.target === overlayRef.current) {
      handleClose()
    }
  }

  useEffect(() => {
    disableBodyScroll(modalRef.current)

    return () => enableBodyScroll(modalRef.current)
  }, [])

  return (
    <ModalContainer ref={overlayRef} onClick={onOverlayClick}>
      <WindowContainer ref={modalRef}>
        <ReturnButton onClick={handleClose}>{"< Назад"}</ReturnButton>
        <Button onClick={handleClose}>
          <Icon />
        </Button>
        <Title>Памятка</Title>
        <DescriptionText />
      </WindowContainer>
    </ModalContainer>
  )
}

export default function Render(props) {
  const { isOpened } = props
  const root = window.document.getElementById("app-root")
  return isOpened ? ReactDOM.createPortal(DescriptionModal(props), root) : null
}
