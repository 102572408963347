/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileAccreditationStatus = "APPROVED" | "DECLINED" | "INITIAL" | "PENDING" | "%future added value";
export type AccountsList_viewer = {
    readonly id: string;
    readonly email: string;
    readonly country: string;
    readonly foreignProfiles: ReadonlyArray<{
        readonly id: string;
        readonly __typename: string;
        readonly phone: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly accreditation: {
            readonly status: ProfileAccreditationStatus | null;
        };
    }>;
    readonly profiles: ReadonlyArray<{
        readonly __typename: "LegalEntityProfile";
        readonly id: string;
        readonly name: string;
        readonly phone: string | null;
        readonly accountBalance: number;
        readonly accreditation: {
            readonly status: ProfileAccreditationStatus | null;
        };
    } | {
        readonly __typename: "IndividualProfile";
        readonly id: string;
        readonly name: string;
        readonly phone: string | null;
        readonly accountBalance: number;
        readonly accreditation: {
            readonly status: ProfileAccreditationStatus | null;
        };
    } | {
        readonly __typename: "EntrepreneurProfile";
        readonly id: string;
        readonly name: string;
        readonly phone: string | null;
        readonly accountBalance: number;
        readonly accreditation: {
            readonly status: ProfileAccreditationStatus | null;
        };
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }>;
    readonly " $refType": "AccountsList_viewer";
};
export type AccountsList_viewer$data = AccountsList_viewer;
export type AccountsList_viewer$key = {
    readonly " $data"?: AccountsList_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountsList_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "role",
      "value": "INVESTOR"
    }
  ],
  "concreteType": "Accreditation",
  "kind": "LinkedField",
  "name": "accreditation",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": "accreditation(role:\"INVESTOR\")"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountBalance",
  "storageKey": null
},
v7 = [
  (v0/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/),
  (v6/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountsList_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ForeignIndividualProfile",
      "kind": "LinkedField",
      "name": "foreignProfiles",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profiles",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v2/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "role",
                  "value": "BORROWER"
                }
              ],
              "concreteType": "Accreditation",
              "kind": "LinkedField",
              "name": "accreditation",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": "accreditation(role:\"BORROWER\")"
            }
          ],
          "type": "LegalEntityProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": (v7/*: any*/),
          "type": "IndividualProfile"
        },
        {
          "kind": "InlineFragment",
          "selections": (v7/*: any*/),
          "type": "EntrepreneurProfile"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
(node as any).hash = '40450a4cedab41d820f11a16577714ce';
export default node;
