import moment from "moment"

import { findPaymentListByProfile, getOfferExpirationDate } from "src/utils"

const monthArray = [
  "янв",
  "фев",
  "мар",
  "апр",
  "май",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "ноя",
  "дек",
]

export function getClosingTime(data) {
  const { offer, confirmedAt, status } = data
  const { term } = offer.data

  if (status !== "CONFIRMED") {
    return 0
  }

  const expireDate = getOfferExpirationDate(term, confirmedAt)

  const now = Date.now()
  const mscExpireDate = moment
    .parseZone(expireDate)
    .utcOffset(3)
    .hours(23)
    .minutes(59)
    .seconds(59)

  if (mscExpireDate.valueOf() < now.valueOf()) {
    return 0
  }

  return mscExpireDate.valueOf()
}

export function divideNumber(num) {
  return String(num)
    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ")
    .replace(".", ",")
}

export function getStatusBarValue(data) {
  const { application, chain } = data
  const { maxValue: max } = application.data

  const amount = chain.gatheredAmount
  if (amount < 1) return 0

  const statusBarValue = (amount / max) * 100 > 100 ? 100 : (amount / max) * 100

  return statusBarValue
}

export function getStatusBarMinValue(data) {
  const { application } = data
  const { minValue: min, maxValue: max } = application.data

  const statusBarMinValue = (min / max) * 100 > 100 ? 100 : (min / max) * 100

  return statusBarMinValue
}

export function getProposalGoal(data) {
  const { application } = data
  const { minValue, maxValue } = application.data

  const min = Math.floor(Math.round(((minValue / 1000000) * 100))) / 100
  const max = Math.floor(Math.round(((maxValue / 1000000) * 100))) / 100

  if (min === max) {
    return `${max} млн ₽`
  }

  return `${min}-${max} млн ₽`
}

function parseDate(date) {
  if (!date) return "-"

  const parsedDate = new Date(date)
  const day = parsedDate.getDate()
  const month = monthArray[parsedDate.getMonth()]
  const year = parsedDate.getFullYear()

  return `${day} ${month} ${year}`
}

export function parseDateBySchedule(schedule) {
  if (!schedule || !schedule.length) return "-"

  const [first] = schedule
  if (!first || !first.items || !first.items.length) {
    return "-"
  }

  const { length } = first.items
  const payment = first.items[length - 1]

  return parseDate(payment.date)
}

export function getFinishPaymentDate(order) {
  const {
    cession, status, succeededAt, paymentScheduleList,
  } = order

  if (cession) {
    const { originalOrder } = cession
    if (!originalOrder) return "-"

    const {
      succeededAt: cessionSucceededAt,
      status: cessionStatus,
      paymentScheduleList: cessionPaymentScheduleList,
    } = originalOrder

    if (cessionStatus === "SUCCEEDED") {
      return parseDate(cessionSucceededAt)
    }

    return parseDateBySchedule(cessionPaymentScheduleList)
  }

  if (status === "SUCCEEDED") {
    return parseDate(succeededAt)
  }

  return parseDateBySchedule(paymentScheduleList)
}

export function getNextPayment(data) {
  const nextItem = data?.paymentScheduleList?.[0]?.items?.find(
    (item) => item.state === "new"
  )
  if (!nextItem) {
    return 0
  }

  return nextItem.total
}

export function getPaymentAmount(data, profileId) {
  const { paymentScheduleList = [] } = data

  if (paymentScheduleList.length < 1) return 0

  const paymentSchedule = findPaymentListByProfile(profileId, paymentScheduleList) || {}

  const paymentAmount = paymentSchedule?.items?.reduce((total, item) => {
    if (item.state === "paid") {
      return total + item.total
    }

    return total
  }, 0)

  if (!paymentAmount) return 0

  return paymentAmount
}

export function getLoanDate(data) {
  const { cession, beganAt } = data
  const date = cession ? cession?.originalOrder?.beganAt : beganAt

  if (!date) {
    return "-"
  }

  const loanDate = new Date(date)
  const day = loanDate.getDate()
  const month = monthArray[loanDate.getMonth()]
  const year = loanDate.getFullYear()

  return `${day} ${month} ${year}`
}
