import React, { useState } from "react"

import { Container, ImageContainer, Image } from "./styles"
import { Carousel } from "../Carousel"

function Gallery({ items }) {
  const [isOpen, setIsOpen] = useState(false)
  const [initialSlide, setInitialSlide] = useState(0)

  const handleClick = (slideIndex) => {
    setInitialSlide(slideIndex)
    setIsOpen(true)
  }

  return (
    <Container>
      {items.map((item, i) => (
        <ImageContainer key={i} onClick={handleClick.bind(this, i)}>
          <Image style={{ backgroundImage: `url(${item.url})` }} />
        </ImageContainer>
      ))}

      {isOpen && (
        <Carousel
          initialSlide={initialSlide}
          onClose={() => setIsOpen(false)}
          sliders={items}
        />
      )}
    </Container>
  )
}

export default Gallery
