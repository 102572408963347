import React from "react"
import styled, { css } from "styled-components"
import {
  Box, Tooltip, TextArea, TextField, Translate,
} from "src/components"

export const Label = styled.label<any>`
  ${({ disabled }) => disabled
    && css`
      opacity: 0.4;
    `}

  & > a {
    color: currentColor;
    text-decoration: none;
  }
`

const renderLabel = ({ label, disabled, tooltip }: any) => label && (
    <Box pb="12px">
      <Label disabled={disabled}>
        <Translate i18n={`${label}`} ns="components" />
      </Label>
      <Tooltip text={tooltip} />
    </Box>
)

class TextEditor extends React.PureComponent<any, any> {
  render() {
    return (
      <React.Fragment>
        {renderLabel(this.props)}
        <TextField
          type="text"
          name={this.props.name}
          Component={TextArea}
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    )
  }
}

export default TextEditor
