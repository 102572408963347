import React from "react"

import { Flex, Text } from "src/components"

import Icon from "./Icon"

const ItemTitle = ({ name, kind }) => (
  <Flex alignItems="center" mb="15px">
    <Icon>{kind}</Icon>
    <Text fontSize="24px" fontWeight="500" lineHeight="1.20">
      {name}
    </Text>
  </Flex>
)

export default ItemTitle
