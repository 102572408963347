import React from "react"

import {
  Box, Button, Loader, FlexBox,
} from "src/components"

const render = (props) => {
  const {
    mr, fontSize, disabled, children, isLoading, ...rest
  } = props

  return (
    <Box mr={mr || 0}>
      <Button {...rest} disabled={isLoading || disabled} fontSize={fontSize}>
        {!isLoading && children}
        {isLoading && (
          <FlexBox
            width="100%"
            height="100%"
            mr={"-20px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
          >
            <Loader fontSize="4px" />
          </FlexBox>
        )}
      </Button>
    </Box>
  )
}

render.defaultProps = {
  fontSize: "10px",
}

export default render
