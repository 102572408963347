import styled from "styled-components"

import {
  style,
  color,
  space,
  display,
  fontSize,
  textAlign,
  textStyle,
  fontWeight,
  lineHeight,
  letterSpacing,
  width,
  alignItems,
} from "styled-system"

const whiteSpace = style({
  prop: "whiteSpace",
  cssProperty: "whiteSpace",
})

const textDecoration = style({
  prop: "textDecoration",
  cssProperty: "textDecoration",
})

const Text = styled.p<any>`
  font-family: "Geometria", sans-serif;
  font-size: 16px;
  margin: 0;
  text-overflow: ${({ textOverflow }) => textOverflow || ""};
  text-transform: ${({ textTransform }) => textTransform || ""};
  ${display}
  ${alignItems}
  ${textStyle}
  ${color}
  ${space}
  ${fontSize}
  ${fontWeight}
  ${letterSpacing}
  ${lineHeight}
  ${textAlign}
  ${whiteSpace}
  ${textDecoration}
  ${width}
`

export default Text
