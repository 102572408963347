import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"

import {
  Text, Link, OutlinedButton, Icons,
} from "src/components"

import { screenSizes } from "../../../atoms/Enums/screenSizes"

export * from "./wrappers"

export const ProfileIcon = styled(Icons.Profile)`
  width: 30px;
  height: 30px;
  fill: #4a4a4a;
`

export const ProfileLink = styled(NavLink)`
  text-decoration: none;
  color: #4a4a4a;
  justify-content: center;
  align-items: center;
  display: none;

  @media screen and (max-width: 800px) {
    display: flex;
    margin-left: 24px;
  }
`

export const ContinueRegistrationLink = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.primaryBlacks[8]};
  box-sizing: border-box;
  border-radius: 4px;
  width: 135px;
  padding: 12px 0 10px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.625rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryBlack};
  position: relative;
  align-self: baseline;
  margin: 0 0 0 20px;

  @media screen and (max-width: 1000px) {
    width: 115px;
    font-size: 9px;
  }
`

export const ButtonsContainer = styled.div<any>`
  position: relative;
  display: flex;
  align-self: flex-start;
  margin-top: 14px;
  margin-left: auto;

  @media screen and (max-width: 900px) {
    ${({ isHidden }) => isHidden
      && css`
        display: none;
      `};
  }
`

export const NewLoanButton = styled(OutlinedButton)`
  width: 120px;
  box-sizing: border-box;
  padding: 12px 0 10px;
  font-size: 0.625rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mainRed};
  border: 1px solid ${({ theme }) => theme.colors.mainRed};
  position: relative;
  align-self: baseline;

  @media screen and (max-width: 1000px) {
    width: 100px;
    font-size: 9px;
  }

  @media screen and (max-width: 900px) {
    ${({ isHidden }) => isHidden
      && css`
        display: none;
      `};
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;
  font-size: 15px;
  color: #4a4a4a;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 100%;

  &:hover {
    color: #4a4a4a;
  }
`

export const ItemsContainer = styled.div<any>`
  display: flex;
  font-family: Geometria, sans-serif;
  width: 350px;
  justify-content: flex-start;
  padding: 0;
  margin-left: 40px;
  margin-right: 60px;
  text-align: center;
  align-items: center;
  flex-direction: row;
  ${({ vertical }) => vertical
    && css`
      align-items: flex-start;
      text-transform: uppercase;
      flex-direction: column;
      text-align: left;
      width: 100%;
      margin-left: 0 !important;
      
      @media screen and (max-width: 480px) {
        display: block;
      }
      
      & > a {
        margin-bottom: 20px;
        @media screen and (max-width: 480px) {
          margin-bottom: 30px;
          display: block;
        }
        color: #ffffff;
        & > p {
          font-size: 12px;
          @media screen and (max-width: 480px) {
            font-size: 14px;
          }
        }
      }
      & > ${ExternalLink} p {
        color: #ffffff;
        font-size: 12px;
        @media screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    `};

  ${({ vertical }) => !vertical
    && css`
      & > a {
        margin-right: 12px;
      }

      @media screen and (max-width: 1280px) {
        margin-left: 40px;
        margin-right: 40px;
      }

      @media screen and (max-width: 900px) {
        display: none;
      }
    `};
`

export const IconContainer = styled.div<any>`
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 6px;

  svg path {
    fill: ${({ theme, visible }) => (visible ? theme.colors.black : theme.colors.white)};
  }
`

export const NavItemContainer = styled.div<any>`
  display: flex;
  height: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg > path {
    fill: ${({ theme }) => theme.colors.primaryBlack};
  }
`

export const HeaderContainer = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding-top: 18px;
  padding-bottom: 4px;
  margin-bottom: 14px;
  box-sizing: border-box;
  //justify-content: space-between;
  @media screen and (min-width: ${screenSizes.tablet}) and (max-width: 1280px) {
    position: static;
  }

  @media screen and (max-width: 900px) {
    padding-top: 8px;
  }

  ${(props) => props.isScrolling === true
    && css`
      box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
    `}
`

export const HeaderWrap = styled.div`
  width: 1280px;
  margin: 0 auto;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  align-items: center;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 900px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`

export const HeaderAction = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export const LogoContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 900px) {
    ${({ isHidden }) => isHidden
      && css`
        display: none;
      `};
  }
`
export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const LinkLabel = styled(Text)`
  font-size: 14px;
`

export const ActionWrap = styled.div<any>`
  display: flex;
  align-items: center;

  ${({ hasProfiles }) => !hasProfiles
    && css`
      margin-left: auto;
    `};

  @media screen and (max-width: 900px) {
    justify-content: space-between;
    margin-left: 0;
  }
`
