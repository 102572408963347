import React from "react"
import styled from "styled-components"

export const Container = styled.div<any>`
  position: relative;
  z-index: 100;
  background: #ffffff;
  border-radius: 4px;
`

const LocaleButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  /* background-color: #fff; */
  border-radius: 4px;
  color: transparent;
  border: none;
  cursor: pointer;

  & > * + * {
    margin-left: 5px;
  }
`

const LocaleButtonDefault = styled(LocaleButtonBase)`
  height: 60px;
  width: 50px;
`

const LocaleButtonLanding = styled(LocaleButtonBase)<any>`
  width: 60px;
  height: 45px;

  background-color: ${({ open, theme, selectedLanguage }) => {
    if (open && selectedLanguage) return theme.colors.greyDarks[3]
    return open ? theme.colors.greyLight : "transparent"
  }};

  &:hover {
    background-color: ${({ selectedLanguage, theme }) => !selectedLanguage && theme.colors.greyDarks[3]};
  }
`

export const LocaleButton = (props) => {
  const ButtonComponent = props.variant === "landing" ? LocaleButtonLanding : LocaleButtonDefault
  return <ButtonComponent {...props} />
}

export const LocaleList = styled.ul<any>`
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  top: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  ${({ align }) => align === "left" && "left: 0"};
  ${({ align }) => align === "right" && "right: 0"};

  & > li {
    border-top: 1px solid ${({ theme }) => theme.colors.greyDark[5]};
  }
`
