import React from "react"

import { Bank, Modal, InstructionsModal } from "src/components"

class PaymentData extends React.PureComponent<any, any> {
  state = {
    showInstructionsModal: true,
    showBankData: false,
  }

  onClose = (event) => {
    if (event) {
      event.preventDefault()
    }

    this.setState(() => ({
      showBankData: false,
      showInstructionsModal: false,
    }))

    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  onNextStep = (event) => {
    if (event) {
      event.preventDefault()
    }
    this.setState(() => ({
      showBankData: true,
      showInstructionsModal: false,
    }))
  }

  render() {
    return (
      <>
        {this.state.showInstructionsModal && (
          <Modal>
            <InstructionsModal
              type={this.props.type}
              onClose={this.onClose}
              order={this.props.order}
              onNextStep={this.onNextStep}
            />
          </Modal>
        )}
        {this.state.showBankData && (
          <Modal>
            <Bank.modal
              type={this.props.type}
              onClose={this.onClose}
              order={this.props.order}
            />
          </Modal>
        )}
      </>
    )
  }
}

(PaymentData as any).defaultProps = {
  type: "invest",
}

export default PaymentData
