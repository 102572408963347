import React from "react"

import { ProposalSteps } from "./constants"

import Documents from "./Documents"
import Provision from "./Provision"
import Conclusion from "./Conclusion"
import Assessment from "./Assessment"
import PaymentSchedule from "./PaymentSchedule"
import FinancialStatements from "./FinancialStatements"
import ProposalDescription from "./ProposalDescription"

import { BodyContainer } from "./styles"

const Steps = {
  [ProposalSteps.documents]: Documents,
  [ProposalSteps.schedule]: PaymentSchedule,
  [ProposalSteps.financialStatements]: FinancialStatements,
  [ProposalSteps.orderDescription]: ProposalDescription,
  [ProposalSteps.assessment]: Assessment,
  [ProposalSteps.conclusion]: Conclusion,
  [ProposalSteps.provision]: Provision,
}

const render = (props) => {
  const {
    order, viewer, activeStep, isOrderOwner,
  } = props

  const ActiveStep: any = Steps[activeStep]

  return (
    <BodyContainer>
      <ActiveStep order={order} viewer={viewer} isOrderOwner={isOrderOwner} />
    </BodyContainer>
  )
}

export default render
