import React from "react"

import { Box, Heading, Translate } from "src/components"

import {
  List,
  Paragraph,
  IMGcontainer,
  StyledHeading,
  ScrollableWrapper,
} from "../Shared"

export default () => (
  <ScrollableWrapper>
    <Box pb="12px">
      <StyledHeading>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.0" />
      </StyledHeading>
    </Box>
    <Box pt="16px">
      <Heading.h6>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.1" />
      </Heading.h6>
    </Box>
    <List>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.2" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.3" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.4" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.5" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document.6" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document_extra.0" />
      </li>
      <li>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document_extra.1" />
      </li>
    </List>
    <Box pt="16px">
      <Heading.h6>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document_extra.0" />
      </Heading.h6>
    </Box>
    <Box pt="16px">
      <Paragraph>
        <Translate i18n="components:international_profile_form.infoMessageContent.KYC_document_extra.1" />
      </Paragraph>
    </Box>
    <IMGcontainer>
      <img src="/images/kyc/driving.png" />
      <img src="/images/kyc/id.png" />
      <img src="/images/kyc/passport.png" />
    </IMGcontainer>
  </ScrollableWrapper>
)
