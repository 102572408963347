import styled from "styled-components"
import {
  space,
  color,
  height,
  border,
  textAlign,
  borderColor,
} from "styled-system"

const Alert = styled.div<any>`
  width: 100%;
  border-radius: 5px;
  ${height}
  ${space}
  ${color}
  ${textAlign}
  ${border}
  ${borderColor}
`

Alert.defaultProps = {
  bg: "grey",
  p: "20px 40px",
  textAlign: "center",
  border: "none",
}

export default Alert
