import React from "react"

import { ProfileType } from "src/constants"

import {
  Box,
  Text,
  Flex,
  Alert,
  Button,
  Translate,
  VirtualAccountActions,
} from "src/components"

const isBorrower = (profileType) => profileType === ProfileType.juristic

const BorrowerEmpty = React.memo(() => (
  <Alert bg="greyLighter" border="1px solid" borderColor="greyLight">
    <Text fontSize="14px">
      <Translate i18n="components:account.transactions_history.empty.shared" />
    </Text>
  </Alert>
))

const InvestorEmpty = React.memo(({ profileId }: any) => {
  const [shouldShowModal, setShouldShowModal] = React.useState(false)
  const showModal = React.useCallback(() => setShouldShowModal(true), [])
  const hideModal = React.useCallback(() => setShouldShowModal(false), [])

  return (
    <Box mt="100px">
      <Text fontSize="16px" lineHeight="1.5" textAlign="center">
        <Translate i18n="components:account.transactions_history.empty.shared" />
      </Text>
      <Text fontSize="16px" lineHeight="1.5" textAlign="center">
        <Translate i18n="components:account.transactions_history.empty.investor" />
      </Text>

      <Flex mt="35px" justifyContent="center">
        <Button variant="blueWide" height="48px" onClick={showModal}>
          <Translate i18n="modals:debt_payment_requisites.deposit" />
        </Button>
      </Flex>
      {shouldShowModal && (
        <VirtualAccountActions.Deposit.Modal
          onClose={hideModal}
          profileId={profileId}
        />
      )}
    </Box>
  )
})

const Empty = ({ profile }) => {
  const { id, __typename: profileType } = profile

  return isBorrower(profileType) ? (
    <BorrowerEmpty />
  ) : (
    <InvestorEmpty profileId={id} />
  )
}

export default React.memo(Empty)
