import { graphql } from "relay-runtime"
import enhance from "../enhance"

const mutation = graphql`
  mutation CreateIndividualProfileMutation(
    $input: CreateIndividualProfileInput!
  ) {
    createIndividualProfile(input: $input) {
      user {
        ...UpdateProfile_viewer @relay(mask: false)
        ...User_viewer
        profiles {
          ...Header_profile
          ...ProfileBody_profile
        }
      }
      profile {
        name
        id
      }
    }
  }
`
export default enhance({ mutation })
