import React, { useEffect, useCallback } from "react"
import ReactDOM from "react-dom"

import { divideNumber } from "src/utils"

import {
  MobMenuContainer,
  Content,
  Action,
  Menu,
  FooterLink,
  Footer,
  Profile,
  MobMenuInner,
  ButtionPrev,
  ProfileName,
  ProfileWallet,
  ContentFooter,
  Divider,
  Account,
  AccountTitle,
  AccountEmail,
  TabletContainer,
  Wrapper,
  MailIcon,
} from "./styles"

import {
  Prev, Logout, Logo, Burger, Key,
} from "./icons"
import { NavItems, IconLinkList } from ".."

const mountPoint = document.querySelector("#menu-root")

function ProfileInfo(props) {
  const { profiles } = props

  if (!profiles || !profiles.length || !profiles[0]) {
    return <></>
  }

  const activeProfileId = window.localStorage.getItem("activeProfile")
  const profile = profiles.find((el) => el.id === activeProfileId) || profiles[0]

  const { name = "", accountBalanceDetailed = [] } = profile

  const wallet = accountBalanceDetailed.find(
    (el) => el.currency === "RUB" && el.available,
  )
  const balance = wallet?.available || 0

  return (
    <Profile>
      <ProfileName>{name}</ProfileName>
      <ProfileWallet>₽ {divideNumber(balance.toFixed(2))}</ProfileWallet>
    </Profile>
  )
}

export default (props) => {
  const {
    toChangeEmail, handle, viewer, logout, openPasswordModal,
  } = props
  const { profiles, email } = viewer

  useEffect(() => {
    const app: any = document.querySelector("#app-root")
    app.style.overflow = "hidden"
    return () => {
      app.style.overflow = "auto"
    }
  })

  const handleMenuClick = useCallback(
    (event) => {
      const { target } = event
      const link = target instanceof window.HTMLAnchorElement ? target : target.parentNode
      if (handle && link instanceof window.HTMLAnchorElement) {
        handle(false)
      }
    },
    [props],
  )

  const handleLogout = useCallback(() => {
    logout()
    handle(false)
  }, [logout, handle])

  const handleChangePassword = useCallback(() => {
    openPasswordModal()
    handle(false)
  }, [openPasswordModal, handle])

  const handleChangeEmail = useCallback(() => {
    toChangeEmail()
    handle(false)
  }, [toChangeEmail, handle])

  const icons = props.icons || []
  return ReactDOM.createPortal(
    <MobMenuContainer>
      <Wrapper>
        <MobMenuInner>
          <Content>
            <Action>
              <TabletContainer onClick={() => props.handle(false)}>
                <Burger />
              </TabletContainer>
              <TabletContainer>
                <Logo />
              </TabletContainer>
              <ButtionPrev onClick={() => props.handle(false)}>
                <Prev />
                {" Назад"}
              </ButtionPrev>
            </Action>

            <ProfileInfo profiles={profiles} />

            <Menu onClick={handleMenuClick}>
              <NavItems {...props} vertical={true} />
            </Menu>

            <ContentFooter>
              <Divider />

              <Account>
                <AccountTitle>Аккаунт</AccountTitle>
                <AccountEmail>{email}</AccountEmail>
              </Account>

              <FooterLink onClick={handleChangeEmail}>
                <MailIcon />
                <span>Сменить E-mail</span>
              </FooterLink>

              <FooterLink onClick={handleChangePassword}>
                <Key />
                <span>Сменить пароль</span>
              </FooterLink>

              <FooterLink onClick={handleLogout}>
                <Logout />
                <span>Выйти</span>
              </FooterLink>
            </ContentFooter>
          </Content>
        </MobMenuInner>
        <Footer>
          {icons.map((item, i) => (
            <IconLinkList
              title={item.title}
              data={item.data}
              key={`icons-m-${i}`}
              color="#ffffff"
              size={40}
            />
          ))}
        </Footer>
      </Wrapper>
    </MobMenuContainer>,
    mountPoint,
  )
}
