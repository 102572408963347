import React from "react"
import { Route } from "react-router"

import { Button, Template, Translate } from "src/components"
import { isRussianUser } from "src/utils"
import { ViewerDataConsumer } from "src/context"

const handleRoute = (history, viewer) => () => {
  const suffix = isRussianUser(viewer) ? "individual" : "foreign"
  history.push(`/accounts/create/${suffix}`)
}

const Action = () => (
  <ViewerDataConsumer>
    {(viewer) => (
      <Route>
        {({ history }) => (
          <Button onClick={handleRoute(viewer, history)} variant="blueWide">
            <Translate i18n="components:order_list.investments.empty.buttons.action" />
          </Button>
        )}
      </Route>
    )}
  </ViewerDataConsumer>
)

export default (props) => (
  <Template
    layout="empty"
    header="components:order_list.investments.empty.header"
    image="/images/orderLists/pending/investments.svg"
    description="components:order_list.investments.empty.description"
    appendix="components:order_list.investments.empty.appendix"
    action={<Action {...props} />}
  />
)
