import React from "react"

import { Icon, Flex } from "src/components"
import { Clickable, AttachmentLink } from "../styles"

import { ListItem, ListImage } from "./styles"

export default (props) => {
  const {
    url,
    index,
    onRemove,
    filename,
    isDragging,
    attachmentsLength,
  } = props

  return (
    <ListItem isLast={index === attachmentsLength - 1} isDragging={isDragging}>
      <ListImage url={url} />
      <AttachmentLink href={url} target="_blank">
        {filename}
      </AttachmentLink>
      <Flex ml="auto" display="flex" pr="10px">
        <Clickable onClick={onRemove}>
          <Icon>close</Icon>
        </Clickable>
      </Flex>
    </ListItem>
  )
}
