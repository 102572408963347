import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const Title = styled.h2`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0;
`
