const authToken = "karma-auth-token"

/* jslint browser: true */
export const getAuthToken = () => window.localStorage.getItem(authToken)

export const setAuthToken = (value) => new Promise((res) => {
  window.localStorage.setItem(authToken, value)

  res()
})

export const removeAuthToken = () => {
  window.localStorage.removeItem(authToken)
  window.localStorage.removeItem("activeProfile")
  window.localStorage.removeItem("activeProfileType")
}

const redirectPath = "karma-redirect-path"

export const getRedirectPath = () => window.localStorage.getItem(redirectPath)
export const removeRedirectPath = () => window.localStorage.removeItem(redirectPath)
export const setRedirectPath = (pathname) => window.localStorage.setItem(redirectPath, pathname)
