/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfileRoleEnum = "BORROWER" | "INVESTOR" | "%future added value";
export type RequestProfileAccreditationInput = {
    role: ProfileRoleEnum;
    token: string;
    profileId: string;
    analytics?: AnalyticsInput | null;
};
export type AnalyticsInput = {
    cid?: string | null;
    userAgent?: string | null;
    ipAddress?: string | null;
    utms?: string | null;
    urlParams?: string | null;
};
export type RequestForeignProfileAccreditationMutationVariables = {
    input: RequestProfileAccreditationInput;
};
export type RequestForeignProfileAccreditationMutationResponse = {
    readonly requestProfileAccreditation: {
        readonly foreignProfile: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"AccreditationInfoFragment_foreignProfile">;
        } | null;
    };
};
export type RequestForeignProfileAccreditationMutation = {
    readonly response: RequestForeignProfileAccreditationMutationResponse;
    readonly variables: RequestForeignProfileAccreditationMutationVariables;
};



/*
mutation RequestForeignProfileAccreditationMutation(
  $input: RequestProfileAccreditationInput!
) {
  requestProfileAccreditation(input: $input) {
    foreignProfile {
      id
      ...AccreditationInfoFragment_foreignProfile
    }
  }
}

fragment AccreditationInfoFragment_foreignProfile on ForeignIndividualProfile {
  id
  __typename
  firstName
  lastName
  phone
  documents {
    type
    attachments {
      id
      url
      filename
    }
  }
  translatedDocuments {
    id
    url
    filename
  }
  selfie {
    id
    url
    filename
  }
  accreditation(role: INVESTOR) {
    status
    declineReason
  }
  tin
  isRussiaTaxResident
  hasUSACitizenship
  taxResidencyApplication {
    id
    filename
    url
    size
    signature {
      url
    }
  }
  migrationCard {
    number
    dateFrom
    dateTo
    attachments {
      id
      filename
      url
      size
      signature {
        url
      }
    }
  }
  innDocument {
    id
    url
    filename
  }
  residenceDocument {
    id
    url
    filename
  }
  territoryResidenceDocument {
    type
    series
    number
    dateFrom
    dateTo
    attachments {
      id
      filename
      url
      size
      signature {
        url
      }
    }
  }
  registrationAddress {
    country
    region
    area
    locality
    street
    house
    housing
    structure
    flat
    okato
    oktmo
    settlement
    postalCode
    cityDistrict
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RequestProfileAccreditationInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filename",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DocumentSignature",
    "kind": "LinkedField",
    "name": "signature",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateFrom",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateTo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestForeignProfileAccreditationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestProfileAccreditationPayload",
        "kind": "LinkedField",
        "name": "requestProfileAccreditation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "foreignProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccreditationInfoFragment_foreignProfile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestForeignProfileAccreditationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestProfileAccreditationPayload",
        "kind": "LinkedField",
        "name": "requestProfileAccreditation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ForeignIndividualProfile",
            "kind": "LinkedField",
            "name": "foreignProfile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KYCDocument",
                "kind": "LinkedField",
                "name": "documents",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "translatedDocuments",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "selfie",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "role",
                    "value": "INVESTOR"
                  }
                ],
                "concreteType": "Accreditation",
                "kind": "LinkedField",
                "name": "accreditation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "declineReason",
                    "storageKey": null
                  }
                ],
                "storageKey": "accreditation(role:\"INVESTOR\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRussiaTaxResident",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUSACitizenship",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "taxResidencyApplication",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MigrationCard",
                "kind": "LinkedField",
                "name": "migrationCard",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "innDocument",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "residenceDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TerritoryResidenceDocument",
                "kind": "LinkedField",
                "name": "territoryResidenceDocument",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "series",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "registrationAddress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "region",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "area",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locality",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "house",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "housing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "structure",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flat",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "okato",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oktmo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settlement",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cityDistrict",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RequestForeignProfileAccreditationMutation",
    "operationKind": "mutation",
    "text": "mutation RequestForeignProfileAccreditationMutation(\n  $input: RequestProfileAccreditationInput!\n) {\n  requestProfileAccreditation(input: $input) {\n    foreignProfile {\n      id\n      ...AccreditationInfoFragment_foreignProfile\n    }\n  }\n}\n\nfragment AccreditationInfoFragment_foreignProfile on ForeignIndividualProfile {\n  id\n  __typename\n  firstName\n  lastName\n  phone\n  documents {\n    type\n    attachments {\n      id\n      url\n      filename\n    }\n  }\n  translatedDocuments {\n    id\n    url\n    filename\n  }\n  selfie {\n    id\n    url\n    filename\n  }\n  accreditation(role: INVESTOR) {\n    status\n    declineReason\n  }\n  tin\n  isRussiaTaxResident\n  hasUSACitizenship\n  taxResidencyApplication {\n    id\n    filename\n    url\n    size\n    signature {\n      url\n    }\n  }\n  migrationCard {\n    number\n    dateFrom\n    dateTo\n    attachments {\n      id\n      filename\n      url\n      size\n      signature {\n        url\n      }\n    }\n  }\n  innDocument {\n    id\n    url\n    filename\n  }\n  residenceDocument {\n    id\n    url\n    filename\n  }\n  territoryResidenceDocument {\n    type\n    series\n    number\n    dateFrom\n    dateTo\n    attachments {\n      id\n      filename\n      url\n      size\n      signature {\n        url\n      }\n    }\n  }\n  registrationAddress {\n    country\n    region\n    area\n    locality\n    street\n    house\n    housing\n    structure\n    flat\n    okato\n    oktmo\n    settlement\n    postalCode\n    cityDistrict\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e09828bdd65f19be49c61cdaffe8ddd0';
export default node;
