import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 70px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

export const BlockContainer = styled.div`
  position: relative;
  display: contents;
  width: 100%;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: initial;
  }
`
