import React from "react"

import { Tooltip } from "src/components"
import { ElementHoverConsumer } from "src/context"

import { getTooltipLeftOffset } from "./utils"

export default (props) => {
  const { content, minValuePercents } = props

  return (
    <ElementHoverConsumer>
      {({ isHovered }) => (isHovered ? (
          <Tooltip.body
            padding="20px 0"
            top="20px"
            width="325px"
            display="block"
            content={content}
            left={`${getTooltipLeftOffset(minValuePercents)}px`}
          />
      ) : null)
      }
    </ElementHoverConsumer>
  )
}
