import idx from "idx"
import React, { useState } from "react"
import { ProposalPopup } from "src/components"
import { unionDocs } from "src/utils"

import {
  Container,
  Headline,
  List,
  Row,
  ArrowIcon,
  ArrowWithBoardersIcon,
  DocumentIcon,
} from "./styles"

import Portfolio from "./Portfolio"

const ProposalDocuments = ({ data, isInvestor, isOwner }) => {
  const [isVisible, setVisible] = useState(false)

  const otherDocuments = [...data.otherDocuments]
  let financialStatements = [...data.financialStatements]
  let guarantors = [...data.guarantors]
  const investments = idx(data, (_) => _.viewer.investments) || []

  // if we get a cession, combine order documents with cession documents
  if (data.cession && data.cession.originalOrder) {
    guarantors = unionDocs(
      data.guarantors,
      data.cession.originalOrder.guarantors,
    )
    financialStatements = unionDocs(
      data.financialStatements,
      data.cession.originalOrder.financialStatements,
    )
  }

  const handleClick = (e) => {
    if (!isInvestor && !isOwner) {
      e.preventDefault()
      setVisible(true)
    }
  }

  return (
    <Container>
      <ProposalPopup isVisible={isVisible} onClose={() => setVisible(false)} />

      {(otherDocuments.length > 0
        || data.document
        || investments.find((invest) => invest.confirmations)
        || guarantors.length > 0) && (
        <>
          <Headline>
            <h3>Документы проекта</h3>
            <span>Скачать подпись</span>
          </Headline>

          <List>
            {/* Предварительная оферта */}
            {data.document && (
              <Row>
                <span>
                  <DocumentIcon />
                  <a
                    href={data.document.url}
                    onClick={handleClick}
                    target="_blank"
                  >
                    Предварительная оферта
                  </a>
                </span>

                {data.document.signature && (
                  <>
                    <a
                      href={data.document.signature.url}
                      onClick={handleClick}
                      target="_blank"
                    >
                      <span>Скачать подпись</span>
                      <ArrowIcon />
                      <ArrowWithBoardersIcon />
                    </a>
                  </>
                )}
              </Row>
            )}

            {/* Справка об инвестировании */}
            {investments.map(
              ({ number, confirmations }) => confirmations && (
                  <React.Fragment key={number}>
                    {confirmations.map((confirmation, i) => (
                      <Row key={i}>
                        <span>
                          <DocumentIcon />
                          <a
                            href={confirmation.document.url}
                            onClick={handleClick}
                            target="_blank"
                          >
                            Справка об инвестировании №{number}
                            {confirmation.investedAt
                              && `/${confirmation.investedAt
                                .split(".")
                                .reverse()
                                .join("-")}`}
                          </a>
                        </span>

                        {confirmation.signature && (
                          <>
                            <a
                              href={confirmation.document.signature.url}
                              onClick={handleClick}
                              target="_blank"
                            >
                              <span>Скачать подпись</span>
                              <ArrowIcon />
                              <ArrowWithBoardersIcon />
                            </a>
                          </>
                        )}
                      </Row>
                    ))}
                  </React.Fragment>
              ),
            )}

            {guarantors.map((item) => (
              <Row key={item.attachment.id}>
                <span>
                  <DocumentIcon />
                  <a
                    href={item.attachment.url}
                    onClick={handleClick}
                    target="_blank"
                  >
                    {item.name || item.description}
                  </a>
                </span>

                {item.attachment.signature && (
                  <>
                    <a
                      href={item.attachment.signature.url}
                      onClick={handleClick}
                      target="_blank"
                    >
                      <span>Скачать подпись</span>
                      <ArrowIcon />
                      <ArrowWithBoardersIcon />
                    </a>
                  </>
                )}
              </Row>
            ))}

            {/* Остальные Документы проекта */}
            {otherDocuments.map((item) => (
              <Row key={item.attachment.id}>
                <span>
                  <DocumentIcon />
                  <a
                    href={item.attachment.url}
                    onClick={handleClick}
                    target="_blank"
                  >
                    {item.description}
                  </a>
                </span>

                {item.attachment.signature && (
                  <>
                    <a
                      href={item.attachment.signature.url}
                      onClick={handleClick}
                      target="_blank"
                    >
                      <span>Скачать подпись</span>
                      <ArrowIcon />
                      <ArrowWithBoardersIcon />
                    </a>
                  </>
                )}
              </Row>
            ))}
          </List>
        </>
      )}

      {/* Документы компании */}
      {financialStatements.length > 0 && (
        <>
          <Headline>
            <h3>Документы компании</h3>
            <span>Скачать подпись</span>
          </Headline>

          <List>
            {financialStatements.map((item) => (
              <Row key={item.attachment.id}>
                <span>
                  <DocumentIcon />
                  <a
                    href={item.attachment.url}
                    onClick={handleClick}
                    target="_blank"
                  >
                    {item.name}
                  </a>
                </span>

                {item.attachment.signature && (
                  <>
                    <a
                      href={item.attachment.signature.url}
                      onClick={handleClick}
                      target="_blank"
                    >
                      <span>Скачать подпись</span>
                      <ArrowIcon />
                      <ArrowWithBoardersIcon />
                    </a>
                  </>
                )}
              </Row>
            ))}
          </List>
        </>
      )}
      {isOwner && <Portfolio data={data}/>}
    </Container>
  )
}

export default ProposalDocuments
