import memoize from "memoize-one"

export const compose = (...fns) => fns.reduce((f, g) => (...args) => f(g(...args)))
export const pipe = (...fns) => compose.apply(compose, fns.reverse())

// TODO: USE THIS!!!!!
export const createChainedFunction = memoize((...funcs) => funcs
  .filter((f) => f != null)
  .reduce((acc, f) => {
    if (typeof f !== "function") {
      throw new Error(
        "Invalid Argument Type, must only provide functions, undefined, or null.",
      )
    }

    if (acc === null) return f

    return function chainedFunction(...args) {
      acc.apply(this, args)
      f.apply(this, args)
    }
  }, null))
