import React from "react"

const ElementHoverContext = React.createContext({
  isHovered: false,
  freeze: () => {},
  unfreeze: () => {},
  hide: () => {},
  show: () => {},
})

export const ElementHoverProvider = ElementHoverContext.Provider
export const ElementHoverConsumer = ElementHoverContext.Consumer
