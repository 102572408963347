import { useContext } from "react"

import { FormErrorsContext } from "src/context"
import { ErrorForField } from "src/components"
import { getDepsState, getInjectState, createChainedFunction } from "src/utils"

import { RowInputs, CheckboxInputs } from "../../constants"

interface IField {
  name: string;
  value: string;
}

export const handleDefault = (field: EventTarget & HTMLInputElement) => ({ name: field.name, value: field.value })
export const handleMultiply = (fieldsData: IField[]) => (fieldsData)

export const handleCheckbox = (event) => {
  const { name, checked } = event

  return {
    name,
    value: checked,
  }
}

export const handleMaskedValue = ({ name, value }) => ({
  name,
  value: value.replace(/\D/g, ""),
})

export const errorsFromProps = (props) => {
  const {
    name, errors, directErrorPath, purposedmutations,
  } = props

  const { errors: mutationErrors } = errors

  if (!Array.isArray(mutationErrors)) {
    return []
  }

  return mutationErrors.filter((error) => {
    const { path } = error
    const [mutation, ...rest] = path.split(".")
    const errorPath = rest.join(".")

    return (
      purposedmutations.includes(mutation)
      && (errorPath === directErrorPath || rest[0] === name)
    )
  })
}

export const stateFromProps = (props) => ({
  value: props.value[props.name],
  dependencies: { ...getDepsState(props.deps, props.value) },
  injections: { ...getInjectState(props.inject, props.value) },
  errors: errorsFromProps(props),
})

export const processEvent = (inputType) => (obtainedEvent) => {
  let changed = {
    name: "",
    value: "",
  }

  if (CheckboxInputs[inputType]) {
    changed = handleCheckbox(obtainedEvent.target)
  } else if (RowInputs[inputType]) {
    changed = handleDefault(obtainedEvent.target)
  } else if (inputType === "value") {
    changed = handleMaskedValue(obtainedEvent.target)
  } else if (inputType === "bank_autosuggest") {
    const changedBank = handleMultiply(obtainedEvent)
    return changedBank
  } else {
    changed = handleDefault(obtainedEvent)
  }

  return [{ ...changed }]
}

/* TODO: unused - вернуть или удалить окончательно
const Nullable = () => null
*/

const validateNestedLevel = (errors, pathPrefix, directErrorPath) => {
  const currentError = errors[0]
  const pathElements = currentError.path.split(".")

  return directErrorPath ? false : pathElements.length > 2 && !pathPrefix
}

const executeError = (
  errors,
  pathPrefix,
  fieldName,
  fieldNames,
  rootErrorKeywords,
) => {
  if (pathPrefix && (fieldName || fieldNames || rootErrorKeywords)) {
    const filteredErrors = errors.filter(({ path, keyword }) => {
      const [, prefix, field, nestedField] = path.split(".")

      if (
        Array.isArray(rootErrorKeywords)
        && !field
        && rootErrorKeywords.includes(keyword)
      ) {
        return true
      }

      let examinedField = field

      if (nestedField) {
        examinedField = `${examinedField}.${nestedField}`
      }

      if (fieldNames) {
        return prefix === pathPrefix && fieldNames.includes(examinedField)
      }

      return prefix === pathPrefix && examinedField === fieldName
    })

    if (filteredErrors.length > 0) {
      return filteredErrors[0]
    }

    return null
  }

  return errors[0]
}

export const ErrorsExecutor = (props) => {
  const {
    getError: contextErrorGetter,
    clearError: contextErrorCleaner,
  } = useContext(FormErrorsContext)

  const {
    errors,
    onChange,
    children,
    fieldName,
    pathPrefix,
    fieldNames,
    directErrorPath,
    rootErrorKeywords,
  } = props

  let hasError = false
  let chainedOnChange = onChange
  let errorPresentation = null
  let nested = false

  if (Array.isArray(errors) && errors.length > 0) {
    nested = validateNestedLevel(errors, pathPrefix, directErrorPath)

    if (!nested) {
      const currentError = executeError(
        errors,
        pathPrefix,
        fieldName,
        fieldNames,
        rootErrorKeywords,
      )

      if (currentError) {
        const clearError = () => {
          contextErrorCleaner(currentError.path)
        }

        hasError = true
        chainedOnChange = createChainedFunction(clearError, onChange)
        errorPresentation = ErrorForField(
          contextErrorGetter(currentError.path),
          true,
        )
      }
    }
  }

  return children({
    nested,
    errors,
    data: {
      hasError,
      chainedOnChange,
      errorPresentation,
    },
  })
}
