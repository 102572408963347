import styled from "styled-components"
import { maxWidth } from "styled-system"

export const PassportRowWrapper = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  ${maxWidth};

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
`

export const PassportScansContainer = styled.div`
  width: 100%;
  position: relative;
`

export const PassportBlockedOverlay = styled(PassportScansContainer)`
  position: absolute;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.whites[6]};
  z-index: 9;
`

export const Header = styled.h6`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textBlack};
  margin: 0;
`

export const InputBox = styled.div<any>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (min-width: 861px) {
    ${maxWidth};
  }
`

export const Tooltip = styled.p`
  position: relative;
  margin: 0;
  font-family: Geometria;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.textBlacks[7]};
`
