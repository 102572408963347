import styled from "styled-components"
import { color, fontWeight } from "styled-system"

export const List = styled.ul`
  display: block;
  position: relative;
  width: 100%;
  margin: 30px 0;
  padding: 0;
`

export const ListItem = styled.li<any>`
  list-style-type: none;
  margin: 5px 0 0;
  padding: 20px 20px 20px 40px;
  display: flex;
  font-family: Geometria, sans-serif;
  border-radius: 4px;
  width: 100%;
  height: auto;
  min-height: 100px;
  ${color};

  @media screen and (max-width: 800px) {
    padding-left: 30px;
  }

  @media screen and (max-width: 480px) {
    padding-left: 18px;
  }
`

export const Description = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
`

export const Rating = styled(Description)`
  max-width: 120px;

  @media screen and (max-width: 800px) {
    max-width: 80px;
  }

  @media screen and (max-width: 480px) {
    max-width: 55px;
  }
`

export const Text = styled.p<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: auto 0;
  white-space: pre-line;
  ${fontWeight};
`
