import { commitMutation } from "react-relay"

const enhance = ({ mutation, updater }: any) => {
  const commit = (environment, variables, uploadables, callbacks) => {
    const { onError, onCompleted } = callbacks

    return commitMutation(environment, {
      updater,
      mutation,
      variables,
      uploadables,
      onError,
      onCompleted,
    })
  }

  return { commit }
}

export default enhance
