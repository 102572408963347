import React from "react"

import {
  LoaderBox,
  MiniLoader,
  OrderFactory,
  LoadMore,
  CustomTooltip,
} from "src/components"
import { MarketOrdersListHugeQueryCessionContainer } from "src/query"

import { parseHugeOrdersData } from "src/utils"

import {
  List,
  OrderContainerItems,
  HeadTh,
  HeadTr,
  TableHead,
  Table,
  TableLoader,
  DesktopWrapper,
  MobileWrapper,
  TooltipIcon,
  TooltipText,
  TooltipLink,
  NotFoundBlock,
} from "./styles"

import {
  MOCK_ORDERS, MOBILE_MOCK_ORDERS, TABLE, MOBILE,
} from "./constants"

import { Header } from "./Elements/Header"

const TableItem = React.memo((props: { data: any }) => (
  <OrderFactory.tableOrderCession data={props.data} />
))

const MobileItem = React.memo((props: { data: any }) => (
  <OrderFactory.mobileOrderCession data={props.data} />
))

const Render = (props) => {
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    setLoading(false)
  }, [props.data?.orders?.edges])

  const handleLoad = () => {
    setLoading(true)
    props.load.handle()
  }

  const { isFetching } = props

  const orders = parseHugeOrdersData(props.data)

  const hasFilter = Boolean(Object.values(props.filter).find(Boolean))
  const hasOrders = orders.length > 0

  const showTable = isFetching || hasOrders || hasFilter
  const showNotFound = !isFetching && hasFilter && !hasOrders

  return (
    <>
      {showTable && (
        <>
          <Header
            title="Вторичный рынок"
            filter={props.filter}
            setFilter={props.setFilter}
          />
          <OrderContainerItems>
            <DesktopWrapper>
              {isFetching && (
                <TableLoader>
                  <MiniLoader margin="auto" />
                </TableLoader>
              )}

              <Table cellPadding="5">
                <TableHead>
                  <HeadTr>
                    <HeadTh width="18%">Название</HeadTh>
                    <HeadTh width="40px" />
                    <HeadTh>
                      Рейтинг{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div style={{ width: "180px" }}>
                            <TooltipText>
                              Подробную информацию о расчете рейтинга можно
                              узнать{" "}
                              <TooltipLink to="/invest/scoring">
                                здесь
                              </TooltipLink>
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh width="72px" textAlign="center" />
                    <HeadTh>Объём (остаток)</HeadTh>
                    <HeadTh>Цена</HeadTh>
                    <HeadTh>Доходность</HeadTh>
                    <HeadTh>
                      Тип{" "}
                      <CustomTooltip
                        placement="bottom"
                        overlay={
                          <div>
                            <TooltipText>
                              ИГК - исполнение государственного контракта
                            </TooltipText>
                            <TooltipText>
                              ИКК - исполнение коммерческого контракта
                            </TooltipText>
                            <TooltipText>
                              ПОС - пополнение оборотных средств
                            </TooltipText>
                          </div>
                        }
                      >
                        <span>
                          <TooltipIcon />
                        </span>
                      </CustomTooltip>
                    </HeadTh>
                    <HeadTh>Погашение ОД</HeadTh>
                    <HeadTh width="140px">Погашение займа</HeadTh>
                  </HeadTr>
                </TableHead>

                <tbody>
                  {!isFetching
                    && hasOrders
                    && orders.map((data, index) => (
                      <TableItem key={index} data={data} />
                    ))}

                  {isFetching
                    && [...Array(MOCK_ORDERS)].map((item, i) => {
                      const Item = OrderFactory.createMock({}, TABLE, true)
                      return <Item key={i} />
                    })}
                </tbody>
              </Table>
            </DesktopWrapper>

            <MobileWrapper>
              <List>
                {!isFetching
                  && hasOrders
                  && orders.map((data, i) => <MobileItem key={i} data={data} />)}

                {isFetching
                  && Array(MOBILE_MOCK_ORDERS)
                    .fill(0)
                    .map((data, i) => {
                      const Item = OrderFactory.createMobileMock(
                        {},
                        MOBILE,
                        true,
                      )
                      return <Item key={i} />
                    })}
              </List>
            </MobileWrapper>

            {showNotFound && <NotFoundBlock>Не найдено</NotFoundBlock>}
          </OrderContainerItems>

          <LoaderBox>
            {props.load.isNext && !isLoading && (
              <LoadMore onClick={handleLoad}>+ загрузить еще</LoadMore>
            )}
            {props.load.isNext && isLoading && <MiniLoader />}
          </LoaderBox>
        </>
      )}
    </>
  )
}

export default () => (
  <MarketOrdersListHugeQueryCessionContainer render={Render} />
)
