import styled from "styled-components"

export const StatusbarContainer = styled.div<any>`
  width: ${(props) => props.width || "100%"};
  height: 10px;
  position: relative;
  margin-top: ${(props) => (props.margin && "8px") || "0"};
  margin-bottom: ${(props) => (props.margin && "15px") || "0"};
  border-radius: 10px;
  background-color: #e6eaee;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    height: 16px;
  }
`

export const StatusBarFilled = styled.div.attrs<any>((props) => ({
  style: {
    backgroundColor: props.orange ? "#F8A01B" : "#6fa84b",
    width: `${props.value || 0}%`,
  },
}))<any>`
  height: 100%;
  border-radius: 10px;
  position: relative;
  max-height: 10px;

  @media screen and (max-width: 800px) {
    max-height: 16px;
  }
`
