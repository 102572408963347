/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusEnum = "APPROVED" | "COMPLETE" | "CONFIRMED" | "DEAD" | "DECLINED" | "DEFAULT" | "INITIAL" | "PENDING" | "SUCCEEDED" | "TRANSFER" | "%future added value";
export type CompletedGuest_completed = {
    readonly completedOrders: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly chain: {
                    readonly id: string;
                    readonly gatheredAmount: number;
                    readonly investorsCount: number;
                } | null;
                readonly status: OrderStatusEnum | null;
            };
        }>;
    };
    readonly " $refType": "CompletedGuest_completed";
};
export type CompletedGuest_completed$data = CompletedGuest_completed;
export type CompletedGuest_completed$key = {
    readonly " $data"?: CompletedGuest_completed$data;
    readonly " $fragmentRefs": FragmentRefs<"CompletedGuest_completed">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompletedGuest_completed",
  "selections": [
    {
      "alias": "completedOrders",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": [
              "COMPLETE",
              "SUCCEEDED",
              "TRANSFER"
            ]
          }
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderChainInfo",
                  "kind": "LinkedField",
                  "name": "chain",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "gatheredAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "investorsCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orders(filter:{\"status\":[\"COMPLETE\",\"SUCCEEDED\",\"TRANSFER\"]})"
    }
  ],
  "type": "Query"
};
})();
(node as any).hash = '206b2dde539c42d3ae97ff202017cb60';
export default node;
