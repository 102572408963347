/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ProfilePortfolio_investments = {
    readonly investments: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly amount: number;
                readonly order: {
                    readonly confirmedAt: string | null;
                    readonly application: {
                        readonly data: string;
                        readonly shortTitle: string;
                    } | null;
                    readonly offer: {
                        readonly data: string;
                    } | null;
                    readonly chain: {
                        readonly id: string;
                    } | null;
                    readonly paymentScheduleList: ReadonlyArray<{
                        readonly items: ReadonlyArray<{
                            readonly loan: number;
                            readonly state: string | null;
                        }>;
                    }>;
                    readonly profile: {
                        readonly borrower?: {
                            readonly ticker: string | null;
                        } | null;
                    };
                };
            };
        }>;
    };
    readonly " $refType": "ProfilePortfolio_investments";
};
export type ProfilePortfolio_investments$data = ProfilePortfolio_investments;
export type ProfilePortfolio_investments$key = {
    readonly " $data"?: ProfilePortfolio_investments$data;
    readonly " $fragmentRefs": FragmentRefs<"ProfilePortfolio_investments">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter",
      "type": "OrdersFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfilePortfolio_investments",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "InvestmentConnection",
      "kind": "LinkedField",
      "name": "investments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InvestmentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Investment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Order",
                  "kind": "LinkedField",
                  "name": "order",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "confirmedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderApplication",
                      "kind": "LinkedField",
                      "name": "application",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "shortTitle",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderOffer",
                      "kind": "LinkedField",
                      "name": "offer",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderChainInfo",
                      "kind": "LinkedField",
                      "name": "chain",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaymentSchedule",
                      "kind": "LinkedField",
                      "name": "paymentScheduleList",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaymentScheduleItem",
                          "kind": "LinkedField",
                          "name": "items",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "loan",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "state",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "profile",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Borrower",
                              "kind": "LinkedField",
                              "name": "borrower",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "ticker",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "LegalEntityProfile"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
(node as any).hash = '1591a14b57adfbbff96573f05009c520';
export default node;
